import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback } from "react";
import { CLAIM_UPDATE_TRACKING_V2 } from "../../../../graphql/mutations";
import { ResendFormPolaris } from "./ResendFormPolaris";


export const ResendPolaris = (props) => {
  // Props
  const {
    toggleShowModal,
    claim,
    onNotificationChange,
    toggleActive,
    handleError,
    refetch,
    open,
  } = props;

  // Mutation
  const [updateTracking, { loading }] = useMutation(CLAIM_UPDATE_TRACKING_V2, {
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
    onCompleted: () => {
      if (toggleShowModal) {
        toggleShowModal();
      }
      if (onNotificationChange) {
        onNotificationChange({
          message: "Resent successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
  });

  // Handle action
  const handleSubmit = useCallback((input) => {
    if (input) {
      updateTracking({
        variables: {
          input: {
            claimId: claim.id,
            ...input,
          },
        },
      });
      if (toggleActive) {
        toggleActive();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={`Resend for order #${claim?.orderId}`}
      open={open}
      onClose={toggleShowModal}
      sectioned
    >
      <ResendFormPolaris
        onSubmit={handleSubmit}
        toggleShowModal={toggleShowModal}
        loading={loading}
      />
    </Modal>
  );
};
