import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import get from "lodash/get";
import React, { useCallback, useRef, useState } from "react";
import { UPDATE_ORDER_REVENUE } from "../../../graphql/mutations";
import { handleError, NumberToFixed } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function UpdateRevenue({ value, onClose, open, refetch }) {
  const { id, storeId } = value || {};

  const qty = get(value, "quantity");
  const regularPrice = get(value, "product.regularPrice");

  const max = Number.parseFloat(NumberToFixed(regularPrice * qty * 10), 2) || 500;
  // const max = Number.parseFloat(NumberToFixed(baseCost * 3), 2);

  // State
  const [revenue, setRevenue] = useState("");
  const timeRef = useRef(null);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutation
  const [update, { loading }] = useMutation(UPDATE_ORDER_REVENUE, {
    onCompleted: () => {
      setNotify({ msg: "Update revenue success.", err: false });
      if (refetch) {
        if (timeRef.current) clearTimeout(timeRef.current);
        timeRef.current = setTimeout(() => {
          refetch();
        }, 1200);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  // Actions
  const handleSubmit = useCallback(() => {
    if (!id || !storeId || revenue < 0) return;
    const newR = Number.parseFloat(NumberToFixed(revenue, 2));
    toggleToast && toggleToast(true);
    setNotify({ msg: null, err: false });

    update({
      variables: {
        orderId: id,
        storeId,
        revenue: newR,
      },
    });
  }, [id, storeId, revenue]);

  return (
    <Modal
      title="Update Revenue"
      open={open}
      sectioned
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <TextField
        value={revenue}
        onChange={(value) => {
          const tmp = Number.parseFloat(NumberToFixed(value, 2));
          if (tmp > max) return;
          setRevenue(value);
        }}
        label="New revenue"
        placeholder="Enter new revenue"
        max={max}
        min={0}
        type="number"
        // helpText={`Min is: 0, max is: ${max} ( base cost x3 ).`}
        helpText={`Min is: 0, max is: ${max} ( regular price * quantity * 10 ).`}
      />
    </Modal>
  );
}
