import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { ProductBasesPolaris } from "../../components/base/ProductBasesPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ProductBasesPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="Product Bases"
                fullWidth
                primaryAction={{
                    content: "Add Base",
                    url: `/admin/product-bases/add`,
                }}
            >
                <ProductBasesPolaris path={path} />
            </Page>
        </Container>
    );
};
