import React, { useState, useCallback } from "react";
import { Toast, SettingToggle } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_PUSH_CONTINUE = gql`
  mutation updatePushContinue($input: Boolean!, $id: ID!) {
    updatePushContinue(input: $input, id: $id)
  }
`;

export const ContinuePolaris = ({ value, id }) => {
  const [isCountinue, setIsCountinue] = useState(value);
  const [activeToast, setActiveToast] = useState(false);
  const [updatePushContinue, { data, loading, error }] = useMutation(
    UPDATE_PUSH_CONTINUE,
    {
      onError: () => {},
      onCompleted: () => {},
    }
  );

  const handleUpdatePushContinue = useCallback(() => {
    updatePushContinue({
      variables: {
        input: !isCountinue,
        id: id,
      },
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountinue]);

  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleToggle = useCallback(() => {
    setIsCountinue((active) => !active);
    handleUpdatePushContinue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContentToast = () => {
    if (error) {
      return "Went something wrong.";
    }
    if (data && data.updatePushContinue) return "Setting has been saved.";
  };

  const toastMarkup = activeToast
    ? (error || (data && data.updatePushContinue)) && (
        <Toast
          content={handleContentToast()}
          error={error}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  const contentStatus = isCountinue ? "Enabled" : "Disabled";

  return (
    <>
      {toastMarkup}
      <SettingToggle
        action={{
          content: contentStatus,
          onAction: handleToggle,
          loading: loading,
        }}
        enabled={!isCountinue}
      />
    </>
  );
};
