import React from "react";
import styled from "styled-components";
import { Labelled, TextField } from "@shopify/polaris";

const Container = styled.div`
    .personalized-wrap {
        padding-left: 1.6rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
    }
`;

export const FieldPersonalized = ({ product, onChange }) => {
    // State
    const [fields, setFields] = React.useState({});
    const typingTimeoutRef = React.useRef(null);
    const canOnChangeRef = React.useRef(false);

    // Handle actions
    const handleChange = React.useCallback(
        (fieldsValue) => {
            const newValue = Object.entries(fieldsValue).map(
                ([id, { value }]) => ({
                    fieldId: id,
                    value,
                })
            );
            typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
            typingTimeoutRef.current = setTimeout(() => {
                if (onChange) {
                    canOnChangeRef.current = true;
                    onChange(newValue);
                }
            }, 300);
        },
        [onChange]
    );
    const handleFieldsChange = React.useCallback(
        (value, id) => {
            setFields((prev) => {
                const prevValue = { ...prev, [id]: { ...prev[id], value } };
                handleChange({ ...prevValue });
                return prevValue;
            });
        },
        [handleChange]
    );

    // Get data
    React.useEffect(() => {
        if (product?.fields?.length > 0) {
            const fields = product.fields.reduce(
                (acc, { id, name, title }) => ({
                    ...acc,
                    [id]: { label: title ?? name, value: "" },
                }),
                {}
            );
            if (!canOnChangeRef.current) {
                handleChange(fields);
            }
            setFields(fields);
        }
    }, [product]);

    return (
        <Container>
            <Labelled label="Personalized" />
            <div className="personalized-wrap">
                {Object.entries(fields).map(([id, { label, value }]) => (
                    <TextField
                        key={id}
                        id={id}
                        label={label}
                        value={value}
                        placeholder={`Enter ${label}`}
                        onChange={handleFieldsChange}
                    />
                ))}
            </div>
        </Container>
    );
};
