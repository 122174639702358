import React, { useMemo } from "react";
import styled from "styled-components";
import { ReportsPolaris } from "../../components/reports/ReportsPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ReportsPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <ReportsPolaris path={path} />
        </Container>
    );
};
