import React, { useCallback, useState, useEffect } from "react";
import { Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import history from "../../../history";

import { handleError } from "../../../helper";
import { ExportTemplateFormPolaris } from "./ExportTemplateFormPolaris";

const CREATE_TEMPLATE = gql`
  mutation createExportTemplate($input: NewExportTemplate!) {
    createExportTemplate(input: $input) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      columns {
        name
        type
        value
      }
    }
  }
`;

export const AddExportTemplatePolaris = () => {
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isErorr: false,
  });

  // Mutation
  const [createExport, { loading }] = useMutation(CREATE_TEMPLATE, {
    onCompleted: () => {
      setNotification({
        message: "Create export template successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle action
  const handleRedirect = useCallback(() => {
    history.push("/admin/manager-files/export-templates");
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createExport({
        variables: {
          input,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <React.Fragment>
      {toastMarkup}
      <ExportTemplateFormPolaris
        isAdmin
        loading={loading}
        onSubmit={handSubmit}
        handleRedirect={handleRedirect}
      />
    </React.Fragment>
  );
};
