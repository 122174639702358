import React, { createContext, useContext, useMemo, useState } from "react";
import { Toast } from "@shopify/polaris";

import useToggle from "../../../../hooks/useToggle";

const ProductDuplicateContext = createContext(null);
ProductDuplicateContext.displayName = "ProductDuplicateContext";

export function ProductDuplicateProvider({ children, filter }) {
    // State
    const [open, toggleToast] = useToggle(false);
    const [notify, setNotify] = useState({ msg: null, err: false });

    const bag = useMemo(() => ({ toggleToast, setNotify, filter }), [
        toggleToast,
        setNotify,
        filter,
    ]);

    // Markup
    const ToastMarkup = open && notify.msg && (
        <Toast
            content={notify.msg}
            onDismiss={toggleToast}
            error={notify.err}
            duration={1500}
        />
    );

    return (
        <ProductDuplicateContext.Provider value={bag}>
            {ToastMarkup}
            {children}
        </ProductDuplicateContext.Provider>
    );
}

export function useProductDuplicateContext() {
    const context = useContext(ProductDuplicateContext);
    return context;
}
