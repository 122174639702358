import React, { useState, useCallback, useEffect } from "react";
import { Page, Loading, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import history from "../../../history";
import { handleError } from "../../../helper";
import { SupplierFormPolaris } from "./SupplierFormPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUser!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      roles
      phone
      address
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;
const USER_BY_ID = gql`
  query userByID($id: ID!) {
    userByID(id: $id) {
      id
      firstName
      lastName
      email
      phone
      roles
      address
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;
const ROLES = gql`
  query systemRoles {
    systemRoles
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const EditSupplierPolaris = () => {
  const { id } = useParams();

  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Queries
  const { data: dataRoles, error: errorRole } = useQuery(ROLES);
  const { data, loading, error } = useQuery(USER_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
  });

  // Mutation
  const [updateUser, { loading: loadingM }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setNotification({
        message: "Edit user successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      let existEmail = error.toString().includes("uix_users_email");
      let existEmailMess =
        "Email name already in use, please try another name.";

      setNotification({
        message: existEmail ? existEmailMess : handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle actions
  const handleRedirect = useCallback(() => {
    history.push("/admin/suppliers");
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback(
    (input) => {
      setNotification({
        message: null,
        isError: false,
      });
      if (input && id) {
        updateUser({
          variables: {
            input: {
              id,
              ...input,
            },
          },
        });
      }
      toggleActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  // Markup
  const loadingMarkup = loading && <Loading />;
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const dataRole =
    dataRoles?.systemRoles?.length > 0
      ? dataRoles.systemRoles.filter(
          (i) => !["Administrator", "Seller"].includes(i)
        )
      : [];

  return (
    <Container>
      {toastMarkup}
      {loadingMarkup}
      <Page
        title="Edit supplier"
        breadcrumbs={[{ content: "Suppliers", url: "/admin/suppliers" }]}
      >
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : (
          <SupplierFormPolaris
            value={data}
            dataRole={dataRole}
            onSubmit={handleSubmit}
            error={errorRole}
            loading={loadingM}
            editSupplier
            handleRedirect={handleRedirect}
          />
        )}
      </Page>
    </Container>
  );
};
