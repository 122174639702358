import React, { useState, useEffect, useCallback } from "react";
import { Page, Loading, Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import history from "../../../history";
import { handleError } from "../../../helper";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { CarrierFormPolaris } from "./CarrierFormPolaris";

const CARRIER_BY_ID = gql`
    query carrierById($id: ID!) {
        carrierById(id: $id) {
            id
            name
            code
            trackingUrl
            storeShippingOption
        }
    }
`;
const UPDATE_CARRIER = gql`
    mutation updateCarrier($input: EditCarrier!) {
        updateCarrier(input: $input) {
            id
            name
            code
            trackingUrl
            storeShippingOption
        }
    }
`;

const UPDATE_STORE_SHIPPING_OPTION = gql`
    mutation updateStoreShippingOption($input: [NewSettingShippingOption!]) {
        updateStoreShippingOption(input: $input)
    }
`;

const Container = styled.div``;

export const EditCarrierPolaris = () => {
    // Props
    const { id } = useParams();

    //State
    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [notification, setNotification] = useState({
        message: null,
        isError: false,
    });

    // Query
    const { data, loading, error } = useQuery(CARRIER_BY_ID, {
        variables: {
            id,
        },
        skip: !id,
    });

    // Mutation
    const [updateCarrier, { loading: loadingM }] = useMutation(UPDATE_CARRIER, {
        onCompleted: () => {
            setNotification({
                message: "Update carrier successfuly.",
                isError: false,
            });
            const id = setTimeout(() => {
                handleRedirect();
            }, 5000);
            setTimeoutId(id);
        },
        onError: (error) => {
            setNotification({
                message: handleError(error.toString()),
                isError: true,
            });
        },
    });
    const [updateShipping] = useMutation(UPDATE_STORE_SHIPPING_OPTION, {
        onCompleted: () => {},
        onError: () => {},
    });

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle actions
    const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

    const handleSubmit = useCallback(
        (input) => {
            setNotification({
                message: null,
                isError: false,
            });
            if (input != null && id) {
                const { options, ...rest } = input;
                const newOptions = options.split(/\n/g);
                updateCarrier({
                    variables: {
                        input: {
                            ...rest,
                            id,
                        },
                    },
                });

                updateShipping({
                    variables: {
                        input: [
                            {
                                carrierId: id,
                                options: newOptions,
                            },
                        ],
                    },
                });
            }
            toggleActive();
        },
        [id, updateShipping, updateCarrier, toggleActive, setNotification]
    );

    const handleRedirect = useCallback(
        () => history.push("/admin/carriers/"),
        []
    );

    // Markup
    const loadingMarkup = loading && <Loading />;
    const toastMarkup = activeToast
        ? notification &&
          notification.message && (
              <Toast
                  content={notification.message}
                  error={notification.isError}
                  duration={5000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <Container>
            {loadingMarkup}
            {toastMarkup}
            <Page
                title="Edit Carrier"
                breadcrumbs={[{ content: "Carriers", url: "/admin/carriers" }]}
            >
                {loading ? (
                    <Card>
                        <SkeletonPagePolaris />
                    </Card>
                ) : error ? (
                    <Card sectioned>
                        Error: {handleError(error.toString())}
                    </Card>
                ) : (
                    <CarrierFormPolaris
                        value={data?.carrierById}
                        onSubmit={handleSubmit}
                        handleRedirect={handleRedirect}
                        loading={loadingM}
                        isEdit
                    />
                )}
            </Page>
        </Container>
    );
};
