import { DataTable } from "@shopify/polaris";
import get from "lodash/get";
import React from "react";
import styled from "styled-components";
import { PaginationPolaris } from "../shared/PaginationPolaris";

const Container = styled.div`
    .id-wrap {
        width: calc(20rem - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }
    .origin-id-wrap {
        width: calc(30rem - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }
    .status-wrap {
        width: calc(20rem - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }
    .store-wrap {
        width: calc(20rem - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }

    .Polaris-DataTable__Footer {
        overflow-x: scroll;
    }
`;

const ExportTrackingDTPolaris = ({ data, filter, handlePagination }) => {
    // State
    const [rows, setRows] = React.useState([]);

    // Get data
    React.useEffect(() => {
        let newRows =
            data?.getOrderExportConfirmShipmentToDayV2?.nodes?.length > 0
                ? data.getOrderExportConfirmShipmentToDayV2.nodes.map((item) => {
                      // status
                      let status = item?.status;

                      // Id
                      let id = item?.order?.id;
                      let originId = item?.order?.originId;

                      // Store
                      let store = item?.order?.store?.title;
                      return [
                          <div className="id-wrap">
                              <span>{id}</span>
                          </div>,
                          <div className="origin-id-wrap">
                              <span>{originId}</span>
                          </div>,
                          <div className="status-wrap">
                              <span>{status}</span>
                          </div>,
                          <div className="store-wrap">
                              <span>{store}</span>
                          </div>,
                      ];
                  })
                : [];
        setRows(newRows);
    }, [data]);

    // Variables
    const { limit, paged } = filter;
    const total = get(data, "getOrderExportConfirmShipmentToDayV2.total", 0);
    const totalPage = Math.ceil(total / limit);
    const offset = (paged - 1) * limit;
    const aggregation = {
        page: paged,
        limit,
        offset,
        total,
        totalPage,
        onChange: handlePagination,
    };

    return (
        <Container>
            <DataTable
                rows={rows}
                columnContentTypes={["text", "text", "text", "text"]}
                headings={["Order ID", "Order Origin ID", "Status", "Store"]}
                hideScrollIndicator
                verticalAlign="middle"
                footerContent={
                    <PaginationPolaris aggregation={aggregation} showTotal />
                }
            />
        </Container>
    );
};

export default ExportTrackingDTPolaris;
