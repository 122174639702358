import { Frame } from "@shopify/polaris";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../context";
import routes from "../routers";
import SidebarPolaris from "./sidebar/SidebarPolaris";
import TopBarPolaris from "./topbar/TopBarPolaris";
import { ToastContextProvider } from '../components/shared/ToastContext';

const Container = styled.div`
  .Polaris-Frame__Content:has(.iframe-documentations) {
    background-color: #fff;
  }
`;

const FramePolaris = (props) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const skipToContentRef = useRef(null);

  const location = useLocation();
  const context = useContext(AppContext);
  const { currentUser } = context;

  const checkTeamRoles = () => {
    return routes.find(
      (route) =>
        route.customLayout &&
        route.teamRoles &&
        route.teamRoles.includes(
          currentUser && currentUser.teamUser && currentUser.teamUser.role
        )
    );
  };

  let customLayout = false;
  if (checkTeamRoles() && "/" === location.pathname) {
    customLayout = true;
  }

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  return (
    <>
      <Helmet>
        {/* <title> {`${title ? `${title} - ` : ""}`}Merch Bridge</title> */}
        <title>Merch Bridge</title>
      </Helmet>
      <Container className="bm-frame">
        <Frame
          topBar={
            currentUser && (
              <TopBarPolaris
                {...props}
                toggleMobileNavigationActive={toggleMobileNavigationActive}
              />
            )
          }
          navigation={
            !customLayout &&
            currentUser && <SidebarPolaris {...props} location={location} />
          }
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {skipToContentTarget}
          <div className={currentUser && "p-4 sm:p-6"}>
            <ToastContextProvider>
              {props.children}
            </ToastContextProvider>
          </div>
        </Frame>
      </Container>
    </>
  );
};

export default FramePolaris;
