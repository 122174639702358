import React, { useState, useRef, useEffect } from "react";
import {
    Sheet,
    Heading,
    Button,
    Scrollable,
    OptionList,
} from "@shopify/polaris";
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .label-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }
    .Polaris-OptionList__Options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > .Polaris-OptionList-Option {
            width: 50%;
            .Polaris-OptionList-Option__SingleSelectOption {
                padding: 0.8rem 0.5rem;
                height: 100%;
            }
        }
    }
    img {
        width: 160px;
        height: 160px;
        objectfit: cover;
    }
`;

export const MappingSuggestionsPolaris = ({
    sheetActive,
    toggleSheetActive,
    products,
    productSelected,
    onChange,
}) => {
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    toggleSheetActive();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up.
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if (onChange) {
            onChange(selected[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        if (productSelected && productSelected.id) {
            setSelected([productSelected.id]);
        }
    }, [productSelected]);

    useEffect(() => {
        let newOptions = [];
        if (products && products.length) {
            newOptions = products.map((p) => {
                let bases =
                    p.productBases && p.productBases.length > 0
                        ? p.productBases.map((i) => i.title).join(", ")
                        : null;

                // Product image
                const mainImageId = p?.mainImageId;
                const images = p?.images;
                let mainImages = [];
                if (mainImageId || mainImageId !== "undefined") {
                    mainImages =
                        images?.length > 0
                            ? images
                                  .filter(
                                      (img) => img?.file?.id === mainImageId
                                  )
                                  .map((item) => item.file)
                            : [];
                }
                if (mainImages.length === 0) {
                    mainImages =
                        images?.length > 0
                            ? images.filter(Boolean).map((item) => item.file)
                            : [];
                }

                const mainImage = mainImages?.length > 0 ? mainImages[0] : null;
                const src = mainImage?.thumbnailUrl
                    ? mainImage.thumbnailUrl
                    : mainImage?.url
                    ? mainImage.url
                    : null;

                let labelMarkup = (
                    <div className="label-wrap">
                        {p.images && p.images.length ? (
                            <img alt="" src={src} />
                        ) : null}
                        <div className="title">{p.title && p.title.trim()}</div>
                        {bases && <div>{`(${bases})`}</div>}
                        <div>{`(${p.sku})`}</div>
                    </div>
                );
                return {
                    value: p.id,
                    label: labelMarkup,
                };
            });
        }
        setOptions(newOptions);
    }, [products]);

    return (
        <Sheet open={sheetActive} onClose={toggleSheetActive}>
            <Container ref={wrapperRef}>
                <div
                    style={{
                        alignItems: "center",
                        borderBottom: "1px solid #DFE3E8",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1.6rem",
                        width: "100%",
                    }}
                >
                    <Heading>Mapping suggestions</Heading>
                    <Button
                        accessibilityLabel="Cancel"
                        icon={MobileCancelMajorMonotone}
                        onClick={toggleSheetActive}
                        plain
                    />
                </div>
                <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
                    <OptionList
                        selected={selected}
                        onChange={setSelected}
                        options={options}
                    />
                </Scrollable>
            </Container>
        </Sheet>
    );
};
