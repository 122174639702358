import React from "react";

const FulfillmentContext = React.createContext();
export function FulfillmentProvider({ children, ...props }) {
  return (
    <FulfillmentContext.Provider value={props}>
      {children}
    </FulfillmentContext.Provider>
  );
}

export function useFulfillmentContext() {
  return React.useContext(FulfillmentContext);
}
