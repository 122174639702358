import React, { useState, useCallback, useRef } from "react";
import { Page, Loading, Card } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { MediaFilterPolaris } from "./MediaFiltersPolaris";
import { MediaLibraryPolaris } from "./MediaLibraryPolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../components/shared/EmptyStatePolaris";

export const LOAD_MEDIA_QUERY = gql`
  query mediaLibrary($filter: FileFilter) {
    mediaLibrary(filter: $filter) {
      total
      nodes {
        id
        name
        alt
        caption
        thumbnailUrl
        url
        mimeType
        size
        createdAt
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const MediaPagePolaris = () => {
  const [loadMore, setLoadMore] = useState(false);
  const timeoutRef = useRef(null);
  const [filter, setFilter] = useState({
    limit: 30,
    offset: 0,
    prefix: null,
    search: null,
    mimeType: [],
    dateFilter: {
      from: null,
      to: null,
    },
  });

  const { data, loading, error, fetchMore } = useQuery(LOAD_MEDIA_QUERY, {
    variables: { filter },
  });

  const loadingMarkup = loading && <Loading />;

  const handleSroll = useCallback(
    (value) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef);
      }
      setLoadMore(true);
      if (value) {
        timeoutRef.current = setTimeout(() => {
          fetchMore({
            variables: {
              filter: {
                ...filter,
                offset: data.mediaLibrary.nodes.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              setLoadMore(false);
              return {
                ...prev,
                mediaLibrary: {
                  ...prev.mediaLibrary,
                  nodes: [
                    ...prev.mediaLibrary.nodes,
                    ...fetchMoreResult.mediaLibrary.nodes,
                  ],
                },
              };
            },
          });
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filter]
  );

  return (
    <Container>
      {loadingMarkup}
      <Page title="Media" fullWidth>
        <Card sectioned>
          <MediaFilterPolaris
            onChange={({ search, prefix }) => {
              setFilter((prevState) => {
                return {
                  ...prevState,
                  search,
                  prefix,
                };
              });
            }}
          />
        </Card>
        <Card sectioned>
          {error && <div>Error: {error.toString()}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data &&
            data.mediaLibrary &&
            data.mediaLibrary.nodes &&
            data.mediaLibrary.nodes.length ? (
            <MediaLibraryPolaris
              data={data}
              onChange={handleSroll}
              loadMore={loadMore}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
