import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import isEqual from "lodash/isEqual";
import React from "react";
import { GET_TEAM_WITH_PRIVATE_FEATURES } from "../../../graphql/queries";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { Filter } from "./Filter";
import { Table } from "./Table";

export function PrivateFeatures({ path }) {
  const isMatchPathName = React.useMemo(() => matchPathName(path), [path]);

  const initFilter = React.useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, COMMON_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = React.useState({
    limit: +Number(getCookie("perPagePrivateFeatures")) || 20,
    offset: 0,
    search: "",
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(
    GET_TEAM_WITH_PRIVATE_FEATURES,
    {
      variables: {
        filter,
      },
    },
  );

  // Actions
  const handleFilterChange = React.useCallback(({ search }) => {
    setFilter((prev) => {
      if (!isEqual(prev.search, search)) {
        return {
          ...prev,
          offset: 0,
          search,
        };
      }

      return prev;
    });
  }, []);
  const handlePagination = React.useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPagePrivateFeatures", limit, 100);
  }, []);

  React.useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });

    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const loadingMarkup = React.useMemo(() => (loading ? <Loading /> : null), [
    loading,
  ]);

  return (
    <React.Fragment>
      {loadingMarkup}

      <Card sectioned>
        <Filter filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>{handleError(error.toString())}</div>
        ) : data?.teams?.nodes?.length > 0 ? (
          <Table
            data={data}
            filter={filter}
            refetch={refetch}
            handlePagination={handlePagination}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
}
