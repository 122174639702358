import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { USER_ROLE } from "../../../variable";
import { isEmpty } from "../../../helper";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";

const FILTER_QUERY = gql`
  query listUserByRole($role: String!) {
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
    ownerTeam {
      id
      firstName
      lastName
    }
  }
`;

const STATUS_OPTIONS = [
  { value: "All", label: "All" },
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Cancelled", label: "Cancelled" },
];

const Container = styled.div``;

export const DepositsFilterPolaris = ({ role, filter, onChange }) => {
  const selectedProp = [USER_ROLE.Seller].includes(role)
    ? filter.creatorId
    : filter.accountId;

  // State
  const [queryValue, setQueryValue] = useState(filter.search);
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState({
    value: filter.status,
    label: null,
  });
  const [user, setUser] = useState({
    value: selectedProp,
    label: null,
    search: null,
  });
  const typingTimeoutRef = useRef(null);

  // Query
  const { data, loading, error } = useQuery(FILTER_QUERY, {
    variables: {
      role: role,
    },
  });

  // Get data
  useLayoutEffect(() => {
    let newData = [];
    if ([USER_ROLE.Seller].includes(role)) {
      newData = data?.ownerTeam || [];
    } else {
      newData = data?.listUserByRole || [];
    }

    let newOptions =
      newData?.length > 0
        ? newData.map((i) => ({
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          }))
        : [];
    setOptions(newOptions);
    if (selectedProp) {
      const currentUser =
        newOptions?.length > 0
          ? newOptions.find((i) => i.value === selectedProp)
          : null;
      if (currentUser) {
        setUser((prev) => ({
          ...prev,
          value: currentUser.value,
          label: currentUser.label,
        }));
      }
    } else {
      setUser({
        value: null,
        label: null,
      });
    }
  }, [role, data, selectedProp]);

  useEffect(() => {
    if (filter.status) {
      let currentS = STATUS_OPTIONS.find((i) => i.value === filter.status);
      if (currentS) {
        setStatus(() => ({ value: currentS.value, label: currentS.label }));
      }
    } else {
      setStatus({
        value: null,
        label: null,
      });
    }
  }, [filter.status]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          selectId: user.value,
          status: status.value,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, queryValue, status]);

  // Handle actions
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleStatusRemove = useCallback(
    () => setStatus(() => ({ value: null, label: null })),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleUserRemove = useCallback(() => {
    setUser(() => ({ value: null, label: null, search: null }));
  }, []);

  const handleFilterClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleUserRemove();
    handleStatusRemove();
  }, [handleQueryValueRemove, handleUserRemove, handleStatusRemove]);

  const filters = [
    {
      key: [USER_ROLE.Seller].includes(role) ? "seller" : "supplier",
      label: [USER_ROLE.Seller].includes(role) ? "Seller" : "Supplier",
      filter: (
        <FilterNoSearchPolaris
          data={options}
          value={user}
          loading={loading}
          error={error}
          onChange={({ value, label }) => {
            setUser((prev) => ({ ...prev, value, label }));
          }}
          onChangeSearch={({ search }) =>
            setUser((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      filter: (
        <FilterListRadioPolaris
          options={STATUS_OPTIONS}
          value={status.value}
          onChange={({ value, label }) => setStatus({ value, label })}
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (!isEmpty(user.label)) {
    const key = [USER_ROLE.Seller].includes(role) ? "seller" : "supplier";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, user.label),
      onRemove: handleUserRemove,
    });
  }
  if (!isEmpty(status.label)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status.label),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Container>
      <Filters
        queryPlaceholder={`Filter item`}
        queryValue={queryValue}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFilterClearAll}
        filters={filters}
        appliedFilters={appliedFilters}
      />
    </Container>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "seller":
        return `Seller: ${value}`;
      case "supplier":
        return `Supplier: ${value}`;
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
