import { Modal, Spin, Table } from "antd";
import React from "react";
import styled from "styled-components";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";

export function ModalVariantsMissing({
  open,
  onClose,
  variants,
  sku,
  getProductMockupFolder,
  onChange,
  loading,
}) {
  // State
  const [state, setState] = React.useReducer(
    (p, state) => ({ ...p, ...state }),
    {
      selectedRows: [],
      selectedRowKeys: [],
    }
  );
  // Actions
  const handleEnabled = React.useCallback(() => {
    onChange(state.selectedRows);
    onClose();
  }, [state, onClose]);

  // Markup
  const columns = [
    {
      title: "Thumb",
      key: "thumb",
      width: 300,
      render: (_, record) => {
        let images = record.image ? [record.image] : [];

        return (
          <div className="thumb-wrap">
            <MediaSelectorButton
              folder={sku ? getProductMockupFolder(sku) : "undefined"}
              width="100"
              name="Upload"
              size="12"
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: false,
              }}
              value={images}
              onChange={() => {}}
              multiple
              accept={"image/*"}
              listType="picture-card"
            />
          </div>
        );
      },
    },
    {
      title: "Variant",
      key: "variant",
      width: 150,
      render: (_, record) => (
        <div>
          {record.productBaseVariant &&
            record.productBaseVariant.attributes.map((a) => a.option).join("/")}
        </div>
      ),
    },
  ];

  const handleChange = React.useCallback((selectedRowKeys, selectedRows) => {
    setState({ selectedRowKeys, selectedRows });
  }, []);

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    onChange: handleChange,
  };

  return (
    <Modal
      title="Variants Missing"
      visible={open}
      onCancel={onClose}
      onOk={handleEnabled}
      width={600}
    >
      <Wrapper>
        {loading ? (
          <Spin />
        ) : (
          <Table
            pagination={false}
            rowKey={(_, index) => index}
            columns={columns}
            dataSource={variants}
            rowSelection={rowSelection}
          />
        )}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div``;
