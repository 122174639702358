import React, { useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { MARK_PSD_IN_NAS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export const MarkPSDinNas = ({
    open,
    toggleShowModal,
    task,
    onNotificationChange,
    refetch,
    toggleActive,
}) => {
    // Block body scroll
    // useLockBodyScroll(open);
    const productVariantId = task?.order?.productVariant?.id;

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [markPSD, { loading }] = useMutation(MARK_PSD_IN_NAS, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: `Mark this product has PSD in Nas successfully.`,
                    isError: false,
                });
            }
            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
    });

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        if (productVariantId) {
            if (onNotificationChange) {
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            }
            markPSD({
                variables: {
                    productVariantId,
                },
            });
            if (toggleActive) {
                toggleActive();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productVariantId, markPSD]);

    return (
        <Modal
            title="Mark PSD in Nas"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to mark this product has PSD in Nas</p>
        </Modal>
    );
};
