import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { AddDepositPolaris } from "../../components/admin/deposits/AddDepositPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddDepositPagePolaris = () => {
  return (
    <Container>
      <Page
        title="Add deposit"
        breadcrumbs={[
          { content: "All desposit", url: "/admin/billings/deposits" },
        ]}
      >
        <AddDepositPolaris />
      </Page>
    </Container>
  );
};
