import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

export const FilterPlatformPolaris = ({ onChange, platform, options }) => {
  const [selected, serSelected] = useState(["all"]);

  useEffect(() => {
    if (null === platform.value) {
      serSelected(["all"]);
    } else {
      serSelected([`${platform.value}`]);
    }
  }, [platform]);

  const handleSelectedChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedChoices = options.find((choice) => {
        return choice.value === selectedItem;
      });
      return matchedChoices && matchedChoices.label;
    });
    if (onChange) {
      onChange(
        "all" !== _.head(selected) ? _.head(selected) : null,
        "All" !== _.head(selectedValue) ? _.head(selectedValue) : null
      );
    }
    serSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Platform"
      titleHidden={true}
      choices={options}
      selected={selected}
      onChange={handleSelectedChange}
    />
  );
};
