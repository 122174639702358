import React from "react";
import { useAppContext } from "../../../../context";
import { checkRole, handleError, reducerFn } from "../../../../helper";
import { Button, Modal, Select, Stack, TextStyle } from "@shopify/polaris";
import { checkStatusTask } from "../utils";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_TASK_KPI } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import { KPI } from "../../../../constants/task";

export default function DesignKPI({ value, refetchIdea }) {
  const { kpi, defaultKpi, assigneeId } = value || {};
  const mergedKpi = kpi != null ? kpi : defaultKpi;

  const { currentUser } = useAppContext();
  const [state, setState] = React.useReducer(reducerFn, {
    open: false,
    kpi: mergedKpi,
  });

  const {
    isIdea,
    isSeller,
    isMarketplaceManager,
    isStoreManager,
    isDesignLeader,
  } = checkRole(currentUser);
  const isLeader =
    isSeller || isMarketplaceManager || isStoreManager || isDesignLeader;
  const { isDoing, isRejected, isNeedReview, isDenied, isDone } =
    checkStatusTask(value?.status || "");

  const statusCanUpdate =
    isDoing || isRejected || isNeedReview || isDenied || isDone;

  const handleOpenChange = React.useCallback(
    (value) => () => {
      setState({ open: value });
    },
    [],
  );

  if (!assigneeId) return null;
  if (isIdea || !mergedKpi) return null;
  return (
    <Stack alignment="center">
      <Stack.Item fill>
        <TextStyle variation="strong">Design KPI: {mergedKpi}</TextStyle>
      </Stack.Item>

      {isLeader && statusCanUpdate ? (
        <>
          <Button
            children="Update Design KPI"
            size="slim"
            onClick={handleOpenChange(true)}
          />
          <DesignKPIModal
            currentKpi={mergedKpi}
            id={value?.id}
            open={state.open}
            onClose={handleOpenChange(false)}
            refetchIdea={refetchIdea}
          />
        </>
      ) : null}
    </Stack>
  );
}

function DesignKPIModal({ open, onClose, currentKpi, id, refetchIdea }) {
  const [kpi, setKpi] = React.useState(currentKpi + "");
  const { setNotify, toggleToast } = useToastContext();

  React.useEffect(() => {
    setKpi(currentKpi + "");
  }, [currentKpi]);

  const refetchTimeout = React.useRef(null);
  const [updateTaskPKI, { loading }] = useMutation(UPDATE_TASK_KPI, {
    onCompleted: () => {
      setNotify({ msg: "Update Design KPI successfully", err: false });
      onClose && onClose();

      refetchTimeout && window.clearTimeout(refetchTimeout.current);
      refetchTimeout.current = window.setTimeout(() => {
        refetchIdea && refetchIdea();
      }, 1000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!id || currentKpi === kpi) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });

    updateTaskPKI({
      variables: {
        taskID: id,
        newKpi: kpi,
      },
    });
  }, [updateTaskPKI, kpi, id, currentKpi]);

  const options = React.useMemo(() => {
    return KPI.map((i) => {
      const iStr = String(i);

      return {
        value: iStr,
        label: iStr,
        disabled: kpi === iStr,
      };
    });
  }, [kpi]);

  return (
    <Modal
      title="Update Design KPI"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Select
        label="Design KPI"
        options={options}
        value={kpi}
        id="designKpi"
        onChange={setKpi}
      />
    </Modal>
  );
}
