import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { FilterProductDivisionsPolaris } from "./FilterProductDivisionsPolaris";
import { TableProductDivisionsPolaris } from "./TableProductDivisionsPolaris";

export const QUERY_DIVISIONS = gql`
  query listProductDivision($filter: DivisionFilter) {
    listProductDivision(filter: $filter) {
      total
      nodes {
        id
        title
        productBases {
          id
          title
        }
        stores {
          id
          title
          domain
          email
          platform
        }
        total
        totalUsed
        status
      }
    }
  }
`;

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "productBaseId",
  "storeId",
  "tagId",
  "collectionId",
];

export const ProductDivisionsPolaris = (props) => {
  const { currentParam, path } = props;
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageDivisions")) || 20,
    offset: 0,
    search: null,
    productBaseId: null,
    storeId: null,
    tagId: null,
    collectionId: null,
    onlyRecreateMockup: false,
    ...initFilter,
  });
  const { data, loading, error } = useQuery(QUERY_DIVISIONS, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { offset, limit, onlyRecreateMockup, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;
  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <FilterProductDivisionsPolaris
          filter={filter}
          onChange={({
            search,
            productBaseId,
            collectionId,
            tagId,
            storeId,
            onlyRecreateMockup,
          }) =>
            setFilter((prevState) => {
              if (
                !_.isEqual(prevState.search, search) ||
                !_.isEqual(prevState.productBaseId, productBaseId) ||
                !_.isEqual(prevState.collectionId, collectionId) ||
                !_.isEqual(prevState.tagId, tagId) ||
                !_.isEqual(prevState.storeId, storeId) ||
                !_.isEqual(prevState.onlyRecreateMockup, onlyRecreateMockup)
              ) {
                prevState.offset = 0;
              }
              return {
                ...prevState,
                search,
                productBaseId,
                collectionId,
                tagId,
                storeId,
                onlyRecreateMockup,
              };
            })
          }
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : data &&
          data.listProductDivision &&
          data.listProductDivision.nodes &&
          data.listProductDivision.nodes.length > 0 ? (
          <TableProductDivisionsPolaris
            data={data}
            filter={filter}
            currentParam={currentParam}
            setFilter={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageDivisions", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};
