import React, { useCallback, useRef } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";

import { DENY_IDEA_TASK } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";

export function DenyTask({ item, onCloseParent, ...props }) {
    // Props
    const id = item?.id;
    const onCloseRef = useRef(null);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [deny, { loading }] = useMutation(DENY_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: "Deny this task successfully.",
                err: false,
            });
            props.onClose && props.onClose();
            onCloseParent && onCloseParent();

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                refetch && refetch();
                setFilter && setFilter((prev) => ({ ...prev }));
            }, 1000);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            deny({
                variables: {
                    id,
                },
            });
        }
    }, [id, deny, toggleToast, setNotify]);

    return (
        <Modal
            title="Deny this Task"
            sectioned
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            secondaryActions={[
                {
                    content: "Close",
                    onAction: props.onClose,
                },
            ]}
            {...props}
        >
            <p>Are you sure to deny to this task?</p>
        </Modal>
    );
}
