import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button, Toast, TextContainer } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { LOAD_CLAIMS } from "./ListClaimPolaris";
import { handleError } from "../../../helper";

const UPDATE_STATUS = gql`
  mutation updateClaim($id: ID!, $status: String!) {
    updateClaim(id: $id, status: $status)
  }
`;

export const CancelClaimPolaris = ({ claim }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [updateClaim, { data, loading, error, client }] = useMutation(
    UPDATE_STATUS,
    {
      onError: () => {},
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleActive();
        }, 2500);
        setTimeoutId(id);
      },
    }
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => setOpenModal((open) => !open), []);
  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleCancelClaim = useCallback(() => {
    updateClaim({
      variables: {
        id: claim.id,
        status: "Cancelled",
      },
    }).then((res) => {
      if (res) {
        try {
          const variables = {
            filter: {
              limit: 30,
              offset: 0,
              statusl: null,
              search: null,
              type: null,
            },
          };
          const cache = client.readQuery({
            query: LOAD_CLAIMS,
            variables,
          });
          client.writeQuery({
            query: LOAD_CLAIMS,
            variables,
            data: {
              ...cache,
              claims: {
                ...cache.claims,
                nodes: cache.claims.nodes.map((c) =>
                  c.id === claim.id
                    ? {
                        ...c,
                        status: "Cancelled",
                      }
                    : c
                ),
              },
            },
          });
        } catch (_) {}
      }
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.updateClaim)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.updateClaim && "Update status success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Button onClick={toggleActive} size="slim">
        Cancel
      </Button>
      <Modal
        title="Cancel claim"
        open={openModal}
        onClose={toggleActive}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleActive }]}
        primaryAction={{
          content: "Yes",
          onAction: handleCancelClaim,
          loading: loading,
        }}
      >
        <TextContainer>Do you want change status?</TextContainer>
      </Modal>
    </>
  );
};
