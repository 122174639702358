import React, { useState, useCallback, useEffect } from "react";
import { DataTable, TextField, Select, Button } from "@shopify/polaris";
import { CirclePlusMajorMonotone } from "@shopify/polaris-icons";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { PRODUCT_BASE_OPTIONS } from "../../graphql/queries";

import { elementContains } from "../../helper";
import { LABELS_EXPORT_AMAZON, FIELDS_EXPORT_AMAZON } from "../../variable";

const Container = styled.div`
  .Polaris-DataTable__TableRow {
    &.expanded:hover .Polaris-DataTable__Cell {
      background: transparent;
    }
    &.primary-field {
      .Polaris-TextField:not(.Polaris-TextField--focus)
        .Polaris-TextField__Input,
      .Polaris-Select__Backdrop {
        border: var(--p-override-none, 0.2rem solid #5c6ac4);
        border-radius: var(--p-border-radius-base, 3px);
      }
    }
  }

  .product-option {
    width: 100%;
    max-width: 6rem;
    display: flex;
  }
`;

const FIELDS_TEXTAREA = [
  "bullet_point1",
  "bullet_point2",
  "bullet_point3",
  "bullet_point4",
  "bullet_point5",
  "target_audience_base",
  "specific_uses_keywords1",
  "specific_uses_keywords2",
  "specific_uses_keywords3",
  "specific_uses_keywords4",
  "product_description",
  "condition_note",
];

const PO_TYPE = "product_option";
export const TableMappingExportPolaris = (props) => {
  const {
    onChange,
    data: propsData,
    originData,
    onAddColumn,
    checkAllField,
  } = props;
  // State
  const [rows, setRows] = useState([]);
  const [definedValue, setDefinedValue] = useState([]);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState({});
  const [baseOptions, setBaseOptions] = useState([]);

  // Query
  const { data: baseOpts } = useQuery(PRODUCT_BASE_OPTIONS);

  // Get data
  useEffect(() => {
    setData(propsData);
  }, [propsData]);

  useEffect(() => {
    setLabels(LABELS_EXPORT_AMAZON);
  }, []);

  useEffect(() => {
    const opts = (baseOpts?.productBaseOptions || [])
      // .filter((i) => !/^(size|color)$/i.test(i))
      .map((item) => ({
        value: item,
        label: item,
      }));
    setBaseOptions(opts);
  }, [baseOpts]);

  // Handle data
  useEffect(() => {
    let newLabels = [];
    if (labels) {
      for (let [key, value] of Object.entries(labels)) {
        newLabels.push({ label: value, value: key });
      }
    }
    setDefinedValue(newLabels);
  }, [labels]);

  const options = [
    { label: "Custom value", value: "custom" },
    { label: "Defined value", value: "defined" },
  ];

  useEffect(() => {
    let newLabels = labels;

    let newData =
      data && data.length > 0
        ? data.map((item) => {
            let field = item && item.field;
            let type = item && item.type;
            let itemValue = item && item.value;
            const targetField = item.targetField;
            const isProductOption = targetField === PO_TYPE;

            let isDefined = false;
            if ("custom" !== type) {
              type = "defined";
              isDefined = true;
              if (!isProductOption) {
                for (let [key] of Object.entries(newLabels)) {
                  if (key === item.targetField) {
                    itemValue = key;
                  }
                }
              }
            }

            // Aplly text area
            let textAreaField = false;
            if (FIELDS_TEXTAREA.includes(field)) {
              textAreaField = true;
            }

            // Primary field
            let primaryField = false;
            if (FIELDS_EXPORT_AMAZON.includes(field)) {
              primaryField = true;
            }

            return [
              <div className={`name ${primaryField ? "primary-field" : ""}`}>
                <TextField
                  placeholder={field}
                  value={field}
                  onChange={(value) => {
                    let newItem = {
                      ...item,
                      field: value,
                    };
                    if (onChange) {
                      onChange(newItem);
                    }
                  }}
                  disabled={item.newItem ? false : true}
                />
              </div>,
              <div className="type">
                <Select
                  label="type"
                  labelHidden
                  options={options}
                  value={type}
                  onChange={(value) => {
                    let newItem = {
                      ...item,
                      type: value,
                      value: ""
                    };
                    if (onChange) {
                      onChange(newItem);
                    }
                  }}
                />
              </div>,
              <div className="value">
                {isDefined ? (
                  <Select
                    label="target field"
                    labelHidden
                    options={definedValue}
                    value={isProductOption ? PO_TYPE : itemValue}
                    onChange={(value) => {
                      let newItem = {
                        ...item,
                        targetField: value,
                      };

                      if (value === PO_TYPE){
                        newItem.value = (baseOptions || [])[0]?.value;
                      }
                      if (onChange) {
                        onChange(newItem);
                      }
                    }}
                  />
                ) : (
                  <TextField
                    value={itemValue}
                    multiline={textAreaField ? 2 : false}
                    type={field === "fulfillment_latency" ? "number" : "text"}
                    onChange={(value) => {
                      let newItem = {
                        ...item,
                        value,
                      };
                      if (onChange) {
                        onChange(newItem);
                      }
                    }}
                  />
                )}
              </div>,
              isDefined && isProductOption && (
                <div className="product-option">
                  <Select
                    options={baseOptions}
                    value={itemValue}
                    onChange={(value) => {
                      let newItem = {
                        ...item,
                        value: value,
                      };
                      if (onChange) {
                        onChange(newItem);
                      }
                    }}
                  />
                </div>
              ),
            ];
          })
        : [];

    // Button add new column.
    const expandedMarkup = (
      <div className="expanded">
        <Button
          onClick={addNewColumn}
          icon={CirclePlusMajorMonotone}
          children={"Add new column"}
        />
      </div>
    );
    newData = newData.length ? [...newData, [expandedMarkup]] : [...newData];
    setRows(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, labels, definedValue, baseOptions]);

  useEffect(() => {
    const domTRs = document.querySelectorAll(".Polaris-DataTable__TableRow");
    const domExpandeds = document.querySelectorAll(".expanded");
    const domPrimaryFields = document.querySelectorAll(".name.primary-field");

    for (let domTR of domTRs) {
      if (domTR.classList.contains("primary-field")) {
        domTR.classList.remove("primary-field");
      }
      for (let domExpanded of domExpandeds) {
        if (elementContains(domTR, domExpanded)) {
          domTR.classList.add("expanded");
        }
      }
      for (let domPrimaryField of domPrimaryFields) {
        if (elementContains(domTR, domPrimaryField)) {
          domTR.classList.add("primary-field");
        }
      }
    }
  }, [rows, checkAllField]);

  // Handle Actions
  /// Handle add new column
  const addNewColumn = useCallback(() => {
    let length = originData.length;

    let newItem = {
      field: `new_field${length + 1}`,
      originIndex: length + 1,
      active: true,
      targetField: "",
      type: "custom",
      value: "",
      newItem: true,
    };
    if (onAddColumn) {
      onAddColumn(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originData]);

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={["Column Name", "Type", "Value", ""]}
        rows={rows}
        verticalAlign="middle"
      />
    </Container>
  );
};
