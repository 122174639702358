import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal, Stack, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ADMIN_REVERT_MAPPING_ORDER } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { AutoCompleteHasSearchPolaris } from "../../shared/AutoCompleteHasSearchPolaris";
import get from "lodash/get";

const GET_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const RevertMappingPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    value,
    refetch,
    changeStatusCount,
    convertOS,
  } = props;
  const orderId = value?.id;
  const storeProp = get(value, "store", null);

  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({
    value: null,
    label: null,
  });
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: "",
  });

  // query
  const { data: data2, error: error2 } = useQuery(
    GET_STORES,
    {
      variables: {
        filter,
      },
      skip: !open
    }
  );

  useEffect(() => {
    if (storeProp) {
      const { id, title } = storeProp;
      setStore({ value: id, label: title });
    }
  }, [storeProp]);

  useEffect(() => {
    const nodes = data2?.stores?.nodes || [];
    const stores = nodes.filter(Boolean).map((node) => {
      const { id, title } = node || {};
      return {
        value: id,
        label: title,
      };
    });
    setStores(stores);
  }, [data2]);

  const [adminRevertMappingOrder, { data, loading, error }] = useMutation(
    ADMIN_REVERT_MAPPING_ORDER,
    {
      onError: () => {},
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleShowModal(false);
          if (changeStatusCount) {
            changeStatusCount(
              convertOS && [convertOS.PendingDesign, convertOS.Pending]
            );
          }
          if (refetch) {
            refetch();
          }
        }, 5000);
        setTimeoutId(id);
      },
    }
  );

  const handleSubmit = useCallback(() => {
    if (orderId) {
      adminRevertMappingOrder({
        variables: {
          orderId,
          storeId: store.value ? store.value : undefined,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, store.value]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );

  const toastMarkup = activeToast
    ? (error || (data && data.adminRevertMappingOrder)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.adminRevertMappingOrder &&
                `Revert mapping order success.`
          }
          error={error}
          duration={5000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const timeoutRef = useRef(null);
  const handleSearch = useCallback((search) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, search, offset: 0 }));
    }, 500);
  }, []);

  const handleChange = useCallback((value) => {
    setStore(value);
  }, []);

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleShowModal}
        sectioned
        title={`Revert mapping order: #${orderId ? orderId : ""}`}
        secondaryActions={[{ content: "No", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <Stack vertical>
          <AutoCompleteHasSearchPolaris
            data={stores}
            value={store}
            onChangeSearch={handleSearch}
            onChange={handleChange}
            queryError={error2}
            label="Store"
            required
          />
          <p id="revert-mapping_modal">
            Do you want to revert mapping this order?
          </p>
        </Stack>
      </Modal>
    </>
  );
};
