import { gql } from "apollo-boost";
import { TaskCommentFragment } from "../fragments";

export const CREATE_COMMENT = gql`
    mutation createComment($input: NewComment!) {
        createComment(input: $input) {
            ...TaskCommentFragment
        }
    }
    ${TaskCommentFragment}
`;

export const UPDATE_COMMENT = gql`
    mutation updateComment($input: EditComment!) {
        updateComment(input: $input) {
            ...TaskCommentFragment
        }
    }
    ${TaskCommentFragment}
`;

export const DELETE_COMMENT = gql`
    mutation deleteComment($id: ID!) {
        deleteComment(id: $id)
    }
`;
