import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { ListOverviewPolaris } from "../../components/admin/overview/ListOverviewPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const OverviewPagePolaris = () => {
  return (
    <Container>
      <Page title="All Overview" fullWidth>
        <ListOverviewPolaris />
      </Page>
    </Container>
  );
};
