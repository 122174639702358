import { gql } from "apollo-boost";
import { TaskCommentFragment } from "../fragments";

export const GET_COMMENTS = gql`
    query getComments($filter: CommentFilter!) {
        getComments(filter: $filter) {
            total
            nodes {
                ...TaskCommentFragment
            }
        }
    }
    ${TaskCommentFragment}
`;
