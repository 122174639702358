import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

export const TransactionsFilterByStatusPolaris = ({
  options,
  value,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (!value) {
      setSelectedOptions(["All"]);
    } else {
      setSelectedOptions([value]);
    }
  }, [value]);

  const handleSelection = useCallback((selected) => {
    setSelectedOptions(selected);
    if (onChange) {
      onChange(!selected.includes("All") ? _.head(selected) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      choices={options}
      selected={selectedOptions}
      onChange={handleSelection}
      title="status"
      titleHidden
    />
  );
};
