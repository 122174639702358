import React from "react";
import { Select } from "antd";

const options = [
  { key: "top_left", value: "Top Left" },
  { key: "top_center", value: "Top Center" },
  { key: "top_right", value: "Top Right" },
  { key: "center_left", value: "Center Left" },
  { key: "center_center", value: "Center Center" },
  { key: "center_right", value: "Center Right" },
  { key: "bottom_left", value: "Bottom Left" },
  { key: "bottom_center", value: "Bottom Center" },
  { key: "bottom_right", value: "Bottom Right" },
];
export const DesignPositionSelect = (props) => {
  const { value, onChange } = props;
  return (
    <Select
      value={value || null}
      onChange={(v) => {
        if (onChange) {
          onChange(v);
        }
      }}
    >
      <Select.Option value={null}>Default</Select.Option>
      {options.map((option, index) => (
        <Select.Option key={index} value={option.key}>
          {option.value}
        </Select.Option>
      ))}
    </Select>
  );
};
