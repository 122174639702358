import { Labelled } from "@shopify/polaris";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PRINTIFY_SLUG, PRINTWAY_SLUG } from "../../constants";
import { SHIPPING_SERVICE } from "../../variable";
import { AutoCompleteHasSearchPolaris } from "../shared/AutoCompleteHasSearchPolaris";

export function ShippingService({
  item,
  fulfillment,
  shippingOption,
  setShippingOption,
  options: optionsProp = [],
}) {
  // Props
  const shippingService = item?.shippingService;
  const slug = get(fulfillment, "slug", null);
  const fulfillments = Object.keys(SHIPPING_SERVICE);
  const isPrintway = slug === PRINTWAY_SLUG;
  const isPrintify = slug === PRINTIFY_SLUG;
  const hasShipping = fulfillments.includes(slug);

  // State
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({
    value: null,
    label: "Default",
  });
  const optionsRef = useRef([]);

  const setFirstRef = useRef(false);
  useEffect(() => {
    if (setFirstRef.current && !isPrintify) return;
    if (!Array.isArray(optionsProp)) return;
    setOptions([...optionsProp]);
    setFirstRef.current = true;
  }, [optionsProp, isPrintify]);

  useEffect(() => {
    setValue((prev) => ({
      value: shippingOption,
      label: shippingOption == null ? "Default" : prev.label,
    }));
  }, [shippingOption]);

  useEffect(() => {
    if (hasShipping) {
      let options = SHIPPING_SERVICE[slug];
      const df = options.find((val) => val.value === null);
      if (!df) {
        options.unshift({ value: null, label: "Default" });
      }
      setOptions(options);
      optionsRef.current = options;
    }
  }, [slug, hasShipping]);

  // Handle action
  const handleSearch = useCallback((value) => {
    if (!value) {
      setOptions(optionsRef.current);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = optionsRef.current.filter((option) =>
      option.label.match(filterRegex),
    );

    setOptions(resultOptions);
  }, []);

  const handleOptionChange = useCallback(
    ({ value, label }) => {
      setValue({ value, label });
      setShippingOption(value);
    },
    [setShippingOption],
  );

  const shippingServiceMarkup =
    hasShipping || isPrintway || isPrintify ? (
      <div className="shipping-service-option">
        <Labelled
          label="Shipping option"
          helpText={
            shippingService ? `Origin shipping service: ${shippingService}` : ""
          }
        />

        <AutoCompleteHasSearchPolaris
          data={options}
          value={value}
          placeholder={"Select shipping service"}
          onChangeSearch={handleSearch}
          onChange={handleOptionChange}
        />
      </div>
    ) : null;

  return <Wrapper>{shippingServiceMarkup}</Wrapper>;
}

const Wrapper = styled.div``;

export default ShippingService;