import React, { useState, useCallback, useEffect } from "react";
import { Heading, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { get } from "lodash";

import { CustomTimeline } from "../../shared/CustomTimeline";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const HISTORY_ORDER = gql`
  query historyOrder($id: ID!) {
    historyOrder(orderId: $id) {
      total
      nodes {
        id
        title
        createdAt
      }
    }
  }
`;

const Container = styled.div`
  border-top: var(
    --p-thin-border-subdued,
    0.1rem solid var(--p-border-subdued, #dfe3e8)
  );
  padding-top: 1rem;
  margin-top: 2.6rem;
`;

export const OrderDetailTimelinePolaris = (props) => {
  const { id } = props;
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 0,
  });
  const [options, setOptions] = useState([]);

  const [historyOrder, { data, loading }] = useLazyQuery(HISTORY_ORDER);

  useEffect(() => {
    if (id) {
      historyOrder({
        variables: {
          id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, historyOrder]);

  useEffect(() => {
    let newData =
      data &&
      data.historyOrder &&
      data.historyOrder.nodes &&
      data.historyOrder.nodes.length > 0
        ? data.historyOrder.nodes.slice(
            filter.offset,
            filter.offset + filter.limit
          )
        : [];
    setOptions(newData);
  }, [data, filter]);

  const onChange = useCallback((offset, limit) => {
    setFilter((prev) => ({ ...prev, offset, limit }));
  }, []);

  let { limit, offset } = filter;
  const total = get(data, "historyOrder.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;

  const aggregations = {
    page,
    totalPage,
    offset,
    limit,
    onChange,
    total,
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : options && options.length > 0 ? (
        <Container>
          <Heading element="h4" children="History" />
          <CustomTimeline data={options} />
          <div className="pagination">
            <PaginationPolaris aggregation={aggregations} />
          </div>
        </Container>
      ) : null}
    </>
  );
};
