import React from "react";

import { ScreenOptionsPolaris } from "../shared/ScreenOptionsPolaris";

export const TITLES = "titles";
export const SCREEN_OPTIONS = [
  { value: "image", label: "Image", priority: 0, public: true },
  { value: "product", label: "Product", priority: 5, public: true },
  { value: "originDomain", label: "Origin domain", priority: 10, public: true },
  { value: "niche", label: "Niche", priority: 15, public: true },
  { value: "source", label: "Source", priority: 20, public: true },
  { value: "createdAt", label: "Created at", priority: 25, public: true },
  { value: "author", label: "Author", priority: 30, public: true },
  { value: TITLES, label: "Show Titles", priority: 35, public: true}
];

export const ScreenOptionsPAPolaris = (props) => {
  return <ScreenOptionsPolaris {...props} options={SCREEN_OPTIONS} />;
};
