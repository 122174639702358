import React from "react";
import { Spinner } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { handleError } from "../../../../helper";
import { GET_STORES } from "../../../../graphql/queries";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";

import { StoresSelectPolaris } from "../../../store/StoresSelectPolaris";

export function FieldStore({ onChange, error: errorProp, value }) {
  // State
  const [stores, setStores] = React.useState([]);

  // Query
  const { data, loading, error } = useQuery(GET_STORES, {
    variables: {
      filter: {
        limit: -1,
        offset: 0,
        status: "Connected",
        isSuspended: false,
      },
    },
  });

  // Get data
  React.useEffect(() => {
    const newStores =
      data?.stores?.nodes?.length > 0
        ? data.stores.nodes.map((store) => {
            const { id, title } = store;
            return {
              value: id,
              label: title,
            };
          })
        : [];
    setStores(newStores);
  }, [data]);

  return (
    <>
      <ComponentLabelPolaris label="Stores" required />
      {loading ? (
        <Spinner size="small" />
      ) : error ? (
        <div>{handleError(error.toString())}</div>
      ) : (
        <StoresSelectPolaris
          stores={stores}
          onChange={onChange}
          errors={errorProp}
          value={value}
          labelHidden
        />
      )}
    </>
  );
}
