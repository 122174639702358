import { gql } from "apollo-boost";
import { FileFragment } from "../fragments";

export const GET_FILE_BY_ID = gql`
    query fileById($id: ID!) {
        fileById(id: $id) {
            ...FileFragment
        }
    }
    ${FileFragment}
`;

export const GET_PERSONAL_FILE_URL = gql`
    query getPersonalFileUrl($key: String!) {
        getPersonalFileUrl(key: $key)
    }
`;