import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { Select } from "@shopify/polaris";
import styled from "styled-components";
// import ProductBaseSelect from "./ProductBaseSelect";

import { FulfillmentSelectPolaris } from "./FulfillmentSelectPolaris";
import { ProductBaseSelectPolaris } from "./ProductBaseSelectPolaris";
import { VariantsSelectPolaris } from "./VariantsSelectPolaris";
import { OrderSourcePolaris } from "./OrderSourcePolaris";
import { useSwitchFulfillment } from "./context";

const Container = styled.div`
  display: flex;
  .mid {
    margin: 0 10px;
    width: 100%;
    min-width: 30rem;
  }

  .fulfillment-wrap {
    width: 100%;
    min-width: 24rem;
  }

  .variant-wrap {
    width: 100%;
    max-width: 24rem;
  }

  .order-source-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    max-width: 20rem;
    margin-left: 1rem;
  }
`;
const SwitchFulfillmentMapToVariant = ({
  isAllOrderSource,
  item: itemProp,
  allFulfillment,
  onChange,
  originFulfillmentID,
  mapOSByVariant,
}) => {
  // Context
  const ctx = useSwitchFulfillment();

  // Props
  const [fulfillmentId, setFulfillmentId] = useState(
    itemProp
      ? itemProp.targetFulfillmentId
      : allFulfillment.length
      ? allFulfillment[0].id
      : ""
  );
  const [selectVariantId, setSelectVariantId] = useState("");
  const [productBase, setProductBase] = useState(null);
  const variants =
    productBase && productBase.variants ? productBase.variants : [];
  const [item, setItem] = useState(itemProp);
  const [orderSource, setOrderSource] = useState([]);

  useEffect(() => {
    const opt = itemProp?.orderSource?.length > 0 ? itemProp.orderSource : [];
    setOrderSource(() => [...opt]);
  }, [itemProp?.orderSource]);

  useEffect(() => {
    if (itemProp?.targetVariantId) {
      setSelectVariantId(itemProp.targetVariantId);
    }
  }, [itemProp?.targetVariantId]);

  useEffect(() => {
    onChange(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Container>
      {/* <Select
        onChange={(v) => {
          setFulfillmentId(v);
          setProductBase(null);
          setItem((prevState) => {
            return {
              ...prevState,
              targetFulfillmentId: v ? v : null,
              targetProductBaseId: null,
              targetVariantId: null,
            };
          });
        }}
        value={fulfillmentId}
        options={fulfillmentOptions}
      /> */}
      <div className="fulfillment-wrap">
        <FulfillmentSelectPolaris
          value={fulfillmentId}
          labelHidden
          ignored={originFulfillmentID ? [originFulfillmentID] : []}
          onChange={(id, _productBase) => {
            setFulfillmentId(id);
            setProductBase(null);
            setItem((prevState) => {
              return {
                ...prevState,
                targetFulfillmentId: id ? id : null,
                targetProductBaseId: null,
                targetVariantId: null,
              };
            });
          }}
        />
      </div>
      <div className={"mid"}>
        {/* <ProductBaseSelect
          value={itemProp ? itemProp.targetProductBaseId : null}
          hideLabel={true}
          fulfillmentId={fulfillmentId}
          onChange={(bid, b) => {
            setProductBase(b);
            setItem((prevState) => {
              let vid =
                b && b.variants && b.variants.length ? b.variants[0].id : null;
              //if (itemProp && itemProp.targetVariantId) {
								//vid = itemProp.targetVariantId;
							//}
              return {
                ...prevState,
                targetProductBaseId: bid,
                targetVariantId: vid,
              };
            });
          }}
        /> */}

        <ProductBaseSelectPolaris
          value={itemProp ? itemProp.targetProductBaseId : null}
          labelHidden
          fulfillmentId={fulfillmentId}
          onChange={(bid, b) => {
            setProductBase(b);
            setItem((prevState) => {
              let vid =
                b?.variants?.length > 0
                  ? prevState.targetVariantId
                    ? prevState.targetVariantId
                    : b.variants[0].id
                  : null;

              if (bid !== prevState.targetProductBaseId) {
                const [item] = (b.variants || []).filter(Boolean);
                if (item?.id) {
                  vid = item.id;
                }
              }
              return {
                ...prevState,
                targetProductBaseId: bid,
                targetVariantId: vid,
              };
            });
          }}
        />
      </div>
      {/* <Select
        onChange={(v) => {
          setSelectVariantId(v);
          setItem((prevState) => {
            return { ...prevState, targetVariantId: v };
          });
        }}
        value={selectVariantId}
        options={variants.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        })}
      /> */}
      <div className="variant-wrap">
        <VariantsSelectPolaris
          onChange={(v) => {
            setSelectVariantId(v);
            setItem((prevState) => {
              return { ...prevState, targetVariantId: v };
            });
          }}
          value={selectVariantId}
          options={variants.map((v) => {
            return {
              label: v.name,
              value: v.id,
            };
          })}
        />
      </div>
      {isAllOrderSource ? null : (
        <div className="order-source-wrap">
          <OrderSourcePolaris
            value={[...orderSource]}
            labelHidden
            defaultValue={null}
            options={ctx?.orderSource?.length > 0 ? ctx.orderSource : []}
            exclude={mapOSByVariant || []}
            onChange={(v) => {
              setOrderSource(v);
              setItem((p) => ({ ...p, orderSource: v }));
            }}
          />
        </div>
      )}
    </Container>
  );
};

SwitchFulfillmentMapToVariant.propTypes = {
  item: PropTypes.any,
  allFulfillment: PropTypes.array,
  onChange: PropTypes.func,
};

export default SwitchFulfillmentMapToVariant;
