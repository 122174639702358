import { gql } from "apollo-boost";

export const APPS_QUERY = gql`
  query apps {
    apps {
      name
      description
      installUrl
      uninstallUrl
      installed
      settings
    }
  }
`;
