import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Modal,
  Page,
  Spinner,
  Stack,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ActionPaymentAccount } from "../../components/seller/ActionPaymentAccount";
import { PaymentAccountForm } from "../../components/seller/PaymentAccountForm";
import { EmptyStatePolaris } from "../../components/shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { useAppContext } from "../../context";
import { checkRole, reducerFn } from "../../helper";

const PaymentAccountFilter = React.lazy(() => import("./PaymentAccountFilter"));
const Container = styled.div`
  position: relative;
  .Polaris-Page-Header {
    padding-top: 0;
  }
  .pager {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    align-items: center;
  }
  .spinner {
    margin-right: 20px;
  }
  .filter {
    padding: 15px;
  }

  .title-wrap {
    width: calc(40rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .id-wrap {
    width: calc(7rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .balance-wrap,
  .method-wrap {
    width: calc(17rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
    width: calc(18rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
    margin-left: auto;
  }
`;

const GET_PAYMENT_ACCOUNTS = gql`
  query paymentAccounts($filter: PaymentAccountFilter!) {
    paymentAccounts(filter: $filter) {
      total
      nodes {
        id
        title
        method
        apiUsername
        apiPassword
        apiSignature
        appId
        appSecret
        balance
        note
      }
    }
  }
`;

const REFRESH_PAYMENT_ACCOUNT = gql`
  mutation refreshBalance($ids: [ID!]) {
    refreshBalance(ids: $ids) {
      id
      title
      method
      apiUsername
      apiPassword
      apiSignature
      appId
      appSecret
      balance
      note
    }
  }
`;

const CREATE_PAYMENT_ACCOUNT = gql`
  mutation createPaymentAccount($input: NewPaymentAccount!) {
    createPaymentAccount(input: $input) {
      id
      title
      method
      apiUsername
      apiPassword
      apiSignature
      balance
    }
  }
`;

export const paymentMethods = {
  paypal: "Paypal",
  pingPong: "PingPong",
  stripe: "Stripe",
};

export const mapField = {
  [paymentMethods.paypal]: ["apiUsername", "apiPassword", "apiSignature"],
  [paymentMethods.pingPong]: ["appId", "appSecret"],
  [paymentMethods.stripe]: ["apiUsername", "apiPassword"],
};

export const PaymentAccounts = () => {
  // Context
  const { currentUser } = useAppContext();

  // State
  const [toast, setToast] = useState(false);
  const [messageToast, setMessageToast] = useState({
    isError: false,
    message: "",
  });

  const [filter, setFilter] = React.useReducer(reducerFn, {
    search: "",
    paged: 1,
    limit: 1000,
    teamId: null,
    method: null,
  });

  const { data, loading, refetch } = useQuery(GET_PAYMENT_ACCOUNTS, {
    variables: {
      filter: filter,
    },
  });
  const [rows, setRows] = useState([]);

  const [visible, setVisible] = useState(false);
  const [loadingM, setLoadingM] = useState(false);
  const [values, setValues] = useState({
    title: null,
    method: paymentMethods.paypal,
    apiUsername: null,
    apiPassword: null,
    apiSignature: null,
    appId: null,
    appSecret: null,
  });

  const [refreshPaymentAccount] = useMutation(REFRESH_PAYMENT_ACCOUNT);
  const [createPaymentAccount] = useMutation(CREATE_PAYMENT_ACCOUNT);

  const toggleToast = useCallback((message = "", isError = false) => {
    setToast((active) => !active);
    setMessageToast({ message, isError });
  }, []);

  const onChangeFilter = useCallback(({ search, teamId, method }) => {
    setFilter((prev) => {
      if (
        !isEqual(prev.search, search) ||
        !isEqual(prev.teamId, teamId) ||
        !isEqual(prev.method, method)
      ) {
        return {
          ...prev,
          search,
          teamId,
          method,
          paged: 1,
        };
      }

      return prev;
    });
  }, []);

  const handleRefreshBalance = useCallback(
    (ids) => async () => {
      try {
        setLoadingM(true);
        const { data: dataRefreshed } = await refreshPaymentAccount({
          variables: {
            ids,
          },
        });
        setLoadingM(false);
        if (dataRefreshed?.refreshBalance) {
          const mapBalance = {};
          for (const payment of dataRefreshed.refreshBalance) {
            // mapBalance[payment["id"]] = payment["balance"];

            let balance = payment["balance"];
            const isHTML = hasHTMLTag(balance);
            if (isHTML) {
              mapBalance[payment["id"]] = (
                <div
                  className="balance-wrap"
                  dangerouslySetInnerHTML={{ __html: balance }}
                />
              );
            } else {
              const balanceVal = (balance || "").split(/\|/);
              mapBalance[payment["id"]] =
                balance?.length > 0 ? (
                  <Stack vertical spacing="extraTight">
                    {balanceVal.map((v, i) => (
                      <span key={`balance-${i}`}>{v}</span>
                    ))}
                  </Stack>
                ) : (
                  <span>0</span>
                );
            }
            // const balanceVal = (payment["balance"] || "").split(/\|/);
            // mapBalance[payment["id"]] = (
            //   <Stack vertical spacing="extraTight">
            //     {balanceVal.map((v, i) => (
            //       <span key={`balance-${i}`}>{v}</span>
            //     ))}
            //   </Stack>
            // );
          }
          setRows((prev) => {
            for (const i of prev) {
              const { id: paymentId, data } = i;
              if (mapBalance[paymentId]) data[3] = mapBalance[paymentId];
            }
            return prev;
          });
          toggleToast("Refresh balance successfully", false);
        }
      } catch (error) {
        toggleToast(error.message, true);
      }
    },
    [refreshPaymentAccount, toggleToast, setRows],
  );

  const handleChangeValue = useCallback(
    (value, key) => {
      setValues((prev) => {
        const newValues = {
          ...prev,
          [key]: value,
        };
        if (key !== "method") {
          return newValues;
        }
        // if (value === "paypal") {
        //   newValues.appId = null;
        //   newValues.appSecret = null;

        //   return newValues;
        // }
        // newValues.apiUsername = null;
        // newValues.apiPassword = null;
        // newValues.apiSignature = null;
        const fields = mapField[value];
        for (let f of Object.keys(newValues)) {
          if (![...fields, "title", "note", "method"].includes(f)) {
            newValues[f] = undefined;
          }
        }

        return newValues;
      });
    },
    [setValues],
  );

  const handleCreatePayment = useCallback(async () => {
    try {
      setLoadingM(true);
      const { data: created } = await createPaymentAccount({
        variables: {
          input: values,
        },
      });
      setLoadingM(false);
      if (created?.createPaymentAccount) {
        refetch();
        setVisible(false);
        toggleToast("Create payment account successfully", false);
      }
    } catch (error) {
      toggleToast(error.message, true);
    }
  }, [values, setLoadingM, toggleToast, refetch, setVisible]);

  useEffect(() => {
    setRows(
      data
        ? data?.paymentAccounts?.nodes.map((r, index) => {
            let balance = r.balance;
            const isHTML = hasHTMLTag(balance);

            let balanceMarkup = "";
            if (isHTML) {
              balanceMarkup = (
                <div
                  className="balance-wrap"
                  dangerouslySetInnerHTML={{ __html: balance }}
                />
              );
            } else {
              const balanceVal = (balance || "").split(/\|/);
              balanceMarkup = (
                <Stack vertical spacing="extraTight">
                  {balanceVal.map((v, i) => (
                    <span key={`balance-${i}`}>{v}</span>
                  ))}
                </Stack>
              );
            }
            return {
              id: r.id,
              data: [
                <div className="id-wrap">
                  <span>{index + 1}</span>
                </div>,
                <div className="title-wrap">
                  <span>{r.title}</span>
                </div>,
                <div className="method-wrap">
                  <span>{r.method}</span>
                </div>,
                balanceMarkup,
                <div className="note-wrap">
                  <span>{r.note}</span>
                </div>,
                <div className="actions-wrap">
                  <ActionPaymentAccount
                    key={r.id}
                    paymentAccount={r}
                    notification={toggleToast}
                    refetch={refetch}
                    handleRefreshBalance={handleRefreshBalance}
                  />
                </div>,
              ],
            };
          })
        : [],
    );
  }, [data, setRows, handleRefreshBalance]);

  const disableSubmit = validatevalues(values);
  return (
    <Container>
      <Page
        fullWidth
        title="Payment Accounts"
        primaryAction={
          <ButtonGroup>
            <Button
              primary
              onClick={handleRefreshBalance(rows.map((r) => r.id))}
              loading={loadingM}
              disabled={rows.length === 0}
            >
              Refresh Balances
            </Button>
            <Button primary onClick={() => setVisible(true)}>
              Create payment account
            </Button>
          </ButtonGroup>
        }
      >
        <Card sectioned>
          <React.Suspense fallback={<Spinner size="small" />}>
            <PaymentAccountFilter filter={filter} onChange={onChangeFilter} />
          </React.Suspense>
        </Card>
        {loading ? (
          <Card>
            <SkeletonPagePolaris />
          </Card>
        ) : (
          <Card>
            {rows.length > 0 ? (
              <DataTable
                showTotalsInFooter
                verticalAlign={"middle"}
                hideScrollIndicator
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "numeric",
                ]}
                headings={[
                  "ID",
                  "Title",
                  "Payment Gateway",
                  "Balances",
                  "Note",
                  "Actions",
                ]}
                rows={rows.map((row) => row.data)}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        )}
      </Page>

      {/* modal create */}
      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        title="Create new payment account"
        primaryAction={{
          content: "Create",
          disabled: disableSubmit,
          loading: loadingM,
          onAction: handleCreatePayment,
        }}
      >
        <Modal.Section>
          <PaymentAccountForm
            onChangeValue={handleChangeValue}
            value={values}
          />
        </Modal.Section>
      </Modal>
      {toast && messageToast.message && (
        <Toast
          content={messageToast.message}
          error={messageToast.isError}
          onDismiss={toggleToast}
        />
      )}
    </Container>
  );
};

export function validatevalues(values) {
  if (!values.title) return true;
  const fields = mapField[values.method];
  return fields.some((f) => !values[f]);
}

function hasHTMLTag(str) {
  if (typeof str !== "string") return false;
  const pt = /<([a-z]+)(?![^>]*\/>)[^>]*>/gi;

  return !!str.match(pt);
}
