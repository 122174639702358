import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Dropdown, Menu, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";

const MUTATE = gql`
  mutation approveDeposit($id: ID!, $value: Boolean!) {
    approveDeposit(id: $id, value: $value)
  }
`;

class DepositActions extends Component {
  state = {
    loading: false,
  };
  handleOnCLick = (client, m, status) => {
    const { value } = this.props;
    m({
      variables: {
        id: value.id,
        value: status === "Approved",
      },
    })
      .then(() => {
        this.setState({ loading: false });
        this.props.onChange(client, status);
      })
      .catch((e) => {
        this.setState({ loading: false });
        notification.error({ message: handleError(e.toString()) });
      });
  };

  render() {
    return (
      <Mutation mutation={MUTATE}>
        {(approveDeposit, { client }) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  disabled={this.state.loading}
                  onClick={() =>
                    this.setState({ loading: true }, () => {
                      this.handleOnCLick(client, approveDeposit, "Approved");
                    })
                  }
                >
                  Approve
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    this.handleOnCLick(client, approveDeposit, "Cancelled")
                  }
                >
                  Cancel
                </Menu.Item>
              </Menu>
            }
          >
            <Button>
              Action <LegacyIcon type={"down"} />{" "}
            </Button>
          </Dropdown>
        )}
      </Mutation>
    );
  }
}

DepositActions.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default DepositActions;
