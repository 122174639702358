import { Input, Modal, Spin, Table } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { handleError } from "../../../helper";

export function ModalVariantsMissing({
  loading,
  open,
  onClose,
  data,
  handleVariantsMissing,
  error,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const handleUpdateVariants = useCallback(() => {
    handleVariantsMissing(selectedVariants);
    onClose();
  }, [selectedVariants, handleVariantsMissing]);

  const columns = useMemo(() => {
    return [
      {
        title: "Variants",
        key: "variants",
        dataIndeX: "variants",
        render: (_, record) => <span>{getVariantName(record)}</span>,
        width: 150,
      },
      {
        title: "Fulfillment product id",
        key: "fulfillment-product",
        render: (_, record, index) => (
          <Input disabled value={record.fulfillmentProductId} />
        ),
        width: 200,
      },
    ];
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, records) => {
      setSelectedRowKeys(keys);
      setSelectedVariants(records);
    },
  };

  return (
    <Modal
      title="Variants Missing"
      visible={open}
      onCancel={onClose}
      onOk={handleUpdateVariants}
      okButtonProps={{
        disabled: !!error || data?.length === 0,
      }}
      width="70%"
      style={{ maxWidth: "850px" }}
    >
      {loading ? (
        <Spin />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : (
        <Table
          dataSource={data}
          rowKey={(_, index) => index}
          pagination={false}
          columns={columns}
          rowSelection={rowSelection}
        />
      )}
    </Modal>
  );
}

function getVariantName(item) {
  let options = item.attributes.map((attr) => attr.option);
  return options.join("/");
}
