import React, { createContext, useContext, useMemo, useState } from "react";

const UploadFileCtx = createContext(false);

export function UploadFileCtxProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const bag = useMemo(() => ({ loading, setLoading }), []);

  return (
    <UploadFileCtx.Provider value={bag}>
      {children(loading, setLoading)}
    </UploadFileCtx.Provider>
  );
}

export function useUploadFileCtx() {
  return useContext(UploadFileCtx);
}
