import React, { useState, useCallback, useEffect } from "react";
import {
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
  Card,
  ChoiceList,
  InlineError,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import {
  capitalize,
  lowerCase,
  isEqual,
  head,
  size,
  filter,
  values,
} from "lodash";
import styled from "styled-components";

import { isEmail, handleError } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";

const FIELDS_REQUIRED = {
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  password: "Password",
  confirmPassword: "Confirm password",
  roles: "Roles",
};

const Container = styled.div`
  .wrap-icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .roles-wrap {
    .Polaris-ChoiceList__Choices {
      display: flex;
      flex-direction: row;
      column-gap: 1.6rem;
    }
  }
  .avatar-field {
    display: flex;
    flex-direction: column;
    .list-file_wrap {
      height: 120px;
      .file-item {
        max-width: 120px;
        .file_wrap {
          height: 100%;
        }
      }
    }
  }
  .btns-wrap {
    padding-top: 1.6rem;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const SupplierFormPolaris = ({
  dataRole,
  editSupplier,
  onSubmit,
  handleRedirect,
  error,
  loading,
  value,
}) => {
  // State
  const [basicFields, setBasicFields] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
    phone: null,
    address: null,
    roles: [],
    avatar: null,
  });
  const [roles, setRoles] = useState([]);

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
    roles: null,
  });

  const [typePassword, setTypePassword] = useState({
    password: true,
    confirmPassword: true,
  });

  // Get data
  useEffect(() => {
    let newRoles =
      dataRole?.length > 0
        ? dataRole.map((role) => ({ value: role, label: role }))
        : [];
    setRoles(newRoles);
  }, [dataRole]);

  useEffect(() => {
    const userByID = value?.userByID;
    if (userByID) {
      let { avatar, id, __typename, ...rest } = userByID;
      let newAvatar = avatar ? [avatar] : [];
      setBasicFields((prev) => ({
        ...prev,
        ...rest,
        avatar: newAvatar,
      }));
    }
  }, [value]);

  // Handle actions
  const validateFields = useCallback(
    (value, id) => {
      let error = null;
      let label;
      let pw = basicFields?.password;
      for (let [key, value] of Object.entries(FIELDS_REQUIRED)) {
        if ([key].includes(id)) {
          label = value;
        }
      }
      if (!value || !value.length) {
        error = `${label} is required.`;
      } else {
        if ("email" === id) {
          if (!isEmail(value)) {
            error = "Email must be a valid email address.";
          }
        }
        if ("confirmPassword" === id) {
          if (!isEqual(value, pw)) {
            error = "The two passwords that you entered do not match.";
          }
        }
      }
      setErrors((prev) => ({ ...prev, [id]: error }));
    },
    [basicFields]
  );
  const handleInputChange = useCallback(
    (value, id) => {
      validateFields(value, id);
      setBasicFields((prev) => {
        return {
          ...prev,
          [id]: value,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [basicFields]
  );
  const handleSubmitButton = useCallback(() => {}, []);

  const textFiledMarkup = ({ id, placeholder = "", type }) => {
    let hasType = {};
    if (type) {
      hasType = {
        type: !!typePassword[id] ? "password" : "text",
        suffix: (
          <div className="wrap-icon">
            <Button
              plain
              onClick={() =>
                setTypePassword((prev) => ({ ...prev, [id]: !prev[id] }))
              }
              icon={!!typePassword[id] ? HideMinor : ViewMinor}
            />
          </div>
        ),
      };
    }

    return (
      <TextField
        lable="*"
        labelHidden
        id={id}
        onChange={(value, id) => {
          handleInputChange(value, id);
          handleSubmitButton();
        }}
        value={basicFields[id]}
        placeholder={placeholder}
        error={errors[id]}
        {...hasType}
      />
    );
  };

  const createFields = useCallback(
    (array = [], required = false, type = false) => {
      if (!array.length) return [];
      let result = [];

      for (let i = 0; i < array.length; i++) {
        let item = array[i];
        let label = capitalize(lowerCase(item));
        result.push({
          id: item,
          label,
          required,
          render: textFiledMarkup({ id: item, placeholder: label, type }),
        });
      }
      return result;
    },
    [textFiledMarkup]
  );
  const firstRows = createFields(["firstName", "lastName"], true);
  const secondRows = createFields(["password", "confirmPassword"], true, true);
  const thirdRows = createFields(["phone", "address"]);

  const basicFieldsMarkup = [
    {
      isGroup: true,
      render: firstRows,
    },
    {
      id: "email",
      label: "Email",
      required: true,
      render: textFiledMarkup({
        id: "email",
        placeholder: "Email",
      }),
    },
    ...(!editSupplier
      ? [
          {
            isGroup: true,
            render: secondRows,
          },
        ]
      : []),
    {
      isGroup: true,
      render: thirdRows,
    },
  ];

  const handleRoleChange = useCallback((newValue, name) => {
    setBasicFields((prev) => ({ ...prev, [name]: newValue }));
    validateFields(newValue, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAvatarChange = useCallback((value) => {
    setBasicFields((prev) => ({
      ...prev,
      avatar: value?.length > 0 ? value : [],
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    let {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      roles,
      ...rest
    } = basicFields;
    for (let [key,] of Object.entries(FIELDS_REQUIRED)) {
      validateFields(basicFields[key], key);
    }

    let count = size(filter(values(errors), (e) => e !== null));

    // Avatar
    let avatar = basicFields["avatar"];
    let newAvatar =
      avatar && avatar.length > 0
        ? head(
            avatar.map((a) => {
              if (typeof a === "object") {
                return a.id;
              }
              return a;
            })
          )
        : "";

    let input = {
      ...rest,
      firstName,
      lastName,
      email,
      password,
      roles,
      avatar: newAvatar,
    };

    let canSubmit = false;
    if (editSupplier) {
      if (firstName && lastName && email && roles && count === 0) {
        canSubmit = true;
        let { password, ...restInput } = input;
        input = restInput;
      }
    } else {
      if (
        firstName &&
        lastName &&
        email &&
        password &&
        confirmPassword &&
        roles &&
        count === 0
      ) {
        canSubmit = true;
      }
    }

    if (canSubmit) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields, errors, editSupplier]);

  return (
    <Container>
      <Card sectioned>
        <FormLayout>
          {basicFieldsMarkup.map((field, index) => {
            let isGroup = field?.isGroup;
            let render = field?.render;
            if (isGroup && Array.isArray(render) && render.length) {
              return (
                <FormLayout.Group key={index}>
                  {render.map((item, idx) => (
                    <div className="field_wrap" key={idx}>
                      <ComponentLabelPolaris
                        required={item.required}
                        label={item.label}
                      />
                      {item.render}
                    </div>
                  ))}
                </FormLayout.Group>
              );
            }
            return (
              <div className="field_wrap" key={index}>
                <ComponentLabelPolaris
                  required={field.required}
                  label={field.label}
                />
                {field.render}
              </div>
            );
          })}
          <div className="roles-wrap">
            <ComponentLabelPolaris label="Roles" required />
            {error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : (
              <React.Fragment>
                <ChoiceList
                  choices={roles}
                  selected={basicFields["roles"]}
                  onChange={handleRoleChange}
                  name="roles"
                  allowMultiple
                />
                {errors?.roles && <InlineError message={errors.roles} />}
              </React.Fragment>
            )}
          </div>
          <div className="avatar-field">
            <ComponentLabelPolaris label="Avatar" />
            {editSupplier ? (
              <>
                {basicFields["avatar"] ? (
                  <MediaSelectorButtonPolaris
                    accept={"image/*"}
                    singleUpload={true}
                    multiple={false}
                    onChange={(value) => handleAvatarChange(value)}
                    value={basicFields["avatar"]}
                    sizeSmall
                  />
                ) : null}
              </>
            ) : (
              <MediaSelectorButtonPolaris
                sizeSmall
                accept={"image/*"}
                singleUpload={true}
                multiple={false}
                onChange={(value) => handleAvatarChange(value)}
              />
            )}
          </div>
          <div className="btns-wrap">
            <ButtonGroup>
              <Button children="Cancel" onClick={handleRedirect} />
              <Button
                children="Save"
                primary
                onClick={handleSubmit}
                loading={loading}
              />
            </ButtonGroup>
          </div>
        </FormLayout>
      </Card>
    </Container>
  );
};
