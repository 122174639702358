import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

class AccountActivation extends Component {
  componentDidMount() {
    this.props.onMutate();
  }

  render() {
    return <Spin />;
  }
}

AccountActivation.propTypes = {
  onMutate: PropTypes.func
};

export default AccountActivation;
