import React, { useCallback, useRef } from "react";
import { ButtonGroup, Button } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import {
    RESET_REPORT_WIDGETS,
    UPDATE_REPORT_WIDGETS,
} from "../../../graphql/mutations";
import { useWidgetContext, Group, ActionTypes } from "../context";
import { useToastContext } from "../../shared/ToastContext";
import { handleError } from "../../../helper";

export function Actions({ title, toggleOpen }) {
    // Context
    const [state, dispatch] = useWidgetContext();
    const { toggleToast, setNotify } = useToastContext();
    const timeoutRef = useRef(null);

    // Mutations
    const [updateReport, { loading }] = useMutation(UPDATE_REPORT_WIDGETS, {
        onCompleted: () => {
            handleMessage("Update report widgets successfully.");
        },
        onError: (err) => handleMessage(err, true),
    });

    const [resetReport, { loading: loadingR }] = useMutation(
        RESET_REPORT_WIDGETS,
        {
            onCompleted: () => {
                handleMessage("Reest report widgets successfully.");
            },
            onError: (err) => handleMessage(err, true),
        }
    );

    // Handle actions
    const handleCloseModal = useCallback(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            toggleOpen && toggleOpen();
        }, 350);
    }, [toggleOpen]);

    const handleMessage = useCallback(
        (msg, err = false) => {
            const newMsg = err ? handleError(err.toString()) : msg;
            setNotify({ msg: newMsg, err });
            if (!err) {
                handleCloseModal();
            }
        },
        [setNotify, handleCloseModal]
    );

    const handleReset = useCallback(() => {
        if (title != null) {
            toggleToast && toggleToast(true);
            resetReport({
                variables: {
                    page: title,
                },
            }).then((res) => {
                const resetReportWidgets = res?.data?.resetReportWidgets;
                if (resetReportWidgets) {
                    const data = state.data;
                    const col1 = getData(data, 1);
                    const col2 = getData(data, 2);
                    dispatch({
                        type: ActionTypes.ClearCard,
                    });

                    function updateCard(elements, group) {
                        (elements || []).forEach((item) => {
                            dispatch({
                                type: ActionTypes.RegisterCard,
                                group: group,
                                value: item,
                            });
                        });
                    }

                    updateCard(col1, Group.Secondary);
                    updateCard(col2, Group.Third);
                }
            });
        }
    }, [title, state, dispatch, toggleToast, resetReport]);

    const handleUpdate = useCallback(() => {
        const secondary = state.Secondary || [];
        const third = state.Third || [];

        function getValue(data) {
            return data.map((item) => item.value).join();
        }
        const col1Widgets = getValue(secondary);
        const col2Widgets = getValue(third);

        const input = {
            page: title,
            col1Widgets,
            col2Widgets,
        };

        if (title != null && (col1Widgets || col2Widgets)) {
            toggleToast && toggleToast(true);
            updateReport({
                variables: input,
            });
        }
    }, [title, state, toggleToast, updateReport]);

    return (
        <ButtonGroup>
            <Button children="Reset" loading={loadingR} onClick={handleReset} />
            <Button
                children="Update"
                primary
                loading={loading}
                onClick={handleUpdate}
            />
        </ButtonGroup>
    );
}

function getData(data, suffix) {
    return data
        .map((i) => {
            if (i.default === suffix) return i;
            return null;
        })
        .filter(Boolean);
}
