import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const CLONE_TEMPLATE = gql`
  mutation cloneExportTemplate($id: ID!) {
    cloneExportTemplate(id: $id) {
      id
      name
      author {
        id
        firstName
        lastName
        roles
      }
      suppliers {
        id
        firstName
        lastName
      }
    }
  }
`;
export const ExportTemplateCloneActionPolaris = ({
  item,
  open,
  toggleShowModal,
  onNotificationChange,
  toggleActive,
  refetch,
}) => {
  const [cloneTemplate, { loading }] = useMutation(CLONE_TEMPLATE, {
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: "Clone file template successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle actions
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (item?.id) {
      cloneTemplate({
        variables: {
          id: item?.id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  return (
    <Modal
      title="Clone template"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure clone this export file template?</p>
    </Modal>
  );
};
