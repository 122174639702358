import { gql } from "apollo-boost";

export const TrackingConfigFragment = gql`
  fragment TrackingConfigFragment on TrackingConfig {
    teamId
    isEnable
    trackingmoreApi
    timeSubmitTrackingDelay
    timeSubmitTrackingToStoreDelay
  }
`;

export const reportTrackingResultFragment = gql`
  fragment reportTrackingResultFragment on ReportTrackingResult {
    total
    nodes {
      message
      count
    }
  }
`