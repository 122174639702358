import React, { useState, useCallback, useEffect } from "react";
import { TextField, ChoiceList, Icon, Spinner } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import _ from "lodash";

import { handleError } from "../../helper";

const Container = styled.div`
  .choicelist-wrapp {
    margin-top: 1rem;
  }
  .Polaris-ChoiceList__Choices {
    max-height: 280px;
    overflow-y: scroll;
    padding-left: 2px;
  }
`;

export const FilterNoSearchPolaris = ({
  data,
  value,
  loading: loadingQuery,
  error,
  onChange,
  onChangeSearch,
  hasAll = false,
  defaultValue = 'all'
}) => {
  const [options, setoptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let newValues = [];
    if (data && data.length) {
      // newValues = [{ value: "all", label: "All" }, ...data];
      newValues = [...data];
    }

    if (!hasAll) {
      newValues.unshift({value: "all", label: "All"});
    }

    setoptions(newValues);
    setDeselectedOptions(newValues);
    // setSelectedOptions(value.value ? [value.value] : ["all"]);
    setSelectedOptions(value.value ? [value.value] : [defaultValue]);
    setInputValue(value.search);
    if (value.search) {
      const filterRegex = new RegExp(value.search, "i");
      const resultOptions = newValues.filter((option) =>
        option.label.match(filterRegex)
      );
      setoptions(resultOptions);
    }
  }, [data, value, hasAll, defaultValue]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(loadingQuery);
  }, [loadingQuery]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (onChangeSearch) {
        onChangeSearch({ search: value });
      }
      setLoading(true);
      if ("" === value) {
        const id = setTimeout(() => {
          setLoading(false);
          setoptions(deselectedOptions);
        }, 1000);
        setTimeoutId(id);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      let resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      const id = setTimeout(() => {
        setLoading(false);
        setoptions(resultOptions);
      }, 1000);
      setTimeoutId(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedOptions(selected);
      if (onChange) {
        const ob = {
          value: "all" !== _.head(selected) ? _.head(selected) : null,
          label: "All" !== _.head(selectedValue) ? _.head(selectedValue) : null,
        };

        if (hasAll) {
          ob.value = _.head(selected);
          ob.label = _.head(selectedOptions);
        }
        onChange(ob);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, hasAll]
  );

  return (
    <Container>
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        prefix={<Icon source={SearchMinor} color="inkLighter" />}
        autoFocus={true}
        placeholder="Filter item"
        onClearButtonClick={() => {
          setInputValue(null);
          if (onChangeSearch) {
            onChangeSearch({ search: null });
          }
        }}
        clearButton
      />
      <div className="mt-4">
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <div className="flex justify-center">
            <Spinner
              accessibilityLabel="Spinner example"
              color="teal"
              size="small"
            />
          </div>
        ) : (
          <>
            {options.length ? (
              <div className="choicelist-wrapp">
                <ChoiceList
                  title="Choice"
                  titleHidden
                  choices={options}
                  selected={selectedOptions}
                  onChange={handleSelection}
                />
              </div>
            ) : (
              <span>No items found!</span>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
