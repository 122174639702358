import React, { useState, useCallback } from "react";
import { ButtonGroup, Button, Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const DELETE_CARRIER = gql`
  mutation deleteCarrier($id: ID!) {
    deleteCarrier(id: $id)
  }
`;

export const CarrierActionsPolaris = ({
  item,
  refetch,
  toggleActive,
  onNotificationChange,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Mutation
  const [deleteCarrier, { loading }] = useMutation(DELETE_CARRIER, {
    onCompleted: () => {
      toggleShowModal();
      onNotificationChange({
        message: "Delete carrier successfuly.",
        isError: false,
      });
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      onNotificationChange({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  // Handle action
  const toggleShowModal = useCallback(() => setShowModal((prev) => !prev), []);
  const handleDelete = React.useCallback(() => {
    let id = item?.id;
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isErorr: false,
      });
    }
    if (id) {
      deleteCarrier({
        variables: {
          id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);
  return (
    <React.Fragment>
      <ButtonGroup>
        <Button children="Edit" plain url={`/admin/edit-carrier/${item?.id}`} />
        <Button children="Delete" plain destructive onClick={toggleShowModal} />
      </ButtonGroup>
      <Modal
        title={`Delete carrier #${item?.id}`}
        onClose={toggleShowModal}
        sectioned
        open={showModal}
        primaryAction={{
          content: "Submit",
          loading: loading,
          onAction: handleDelete,
        }}
        secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      >
        <p>Are you sure to delete this carrier?</p>
      </Modal>
    </React.Fragment>
  );
};
