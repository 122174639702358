import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import ProductBaseSelect from "../../../seller/ProductBaseSelect";
import { ProductTemplatesPolaris } from "../../../product/templates/ProductTemplatesPolaris";
import { Form } from "./Form";

import { CreateProductProvider, useCreateProduct, Step } from "./context";
import {
    ToastContextProvider,
    useToastContext,
} from "../../../shared/ToastContext";
import { getParamByRole, handleError } from "../../../../helper";
import { AppContext } from "../../../../context";

export const ADD_PRODUCT_MUTATION = gql`
    mutation createProductGen2dMockup($input: NewProductGen2dMockup!) {
        createProductGen2dMockup(input: $input) {
            id
            title
            shortTitle
            amzTitle
            sku
            description
            designStatus
            personalized
            status
            sku
            productBases {
                id
                title
            }
            images {
                id
                productBaseId
                file {
                    id
                    url
                    thumbnailUrl
                }
            }
            tags {
                id
                name
            }
            collections {
                id
                name
            }
            fields {
                id
                title
                name
                type
                extraFee
                configure
                sorting
            }
        }
    }
`;

export function Create() {
    return (
        <CreateProductProvider>
            <ToastContextProvider>
                <CreateWrapper />
            </ToastContextProvider>
        </CreateProductProvider>
    );
}

function CreateWrapper() {
    // Context
    const { state, updateState, clearState } = useCreateProduct();
    const { toggleToast, setNotify } = useToastContext();
    const history = useHistory();
    const { currentUser } = useContext(AppContext);
    let currentParam = getParamByRole(currentUser);

    // State
    const [isMounted, setIsMounted] = React.useState(false);
    const timeRef = useRef(null);

    // Mutation
    const [createProduct, { loading }] = useMutation(ADD_PRODUCT_MUTATION, {
        onCompleted: () => {
            setNotify({ msg: "Product has been saved.", err: false });

            timeRef.current && clearTimeout(timeRef.current);
            timeRef.current = setTimeout(() => {
                history.push(`/${currentParam}/product-from-print-file`);
            }, 1500);
        },
        onError: (error) => {
            setNotify({ msg: handleError(error.toString()), err: true });
        },
    });

    // Handle action
    const handleStepChange = useCallback(
        (newStep) => {
            updateState({ step: newStep });
        },
        [updateState]
    );

    const handleSubmit = useCallback(
        (input) => {
            if (input != null) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });

                createProduct({ variables: { input } });
            }
        },
        [createProduct, toggleToast, setNotify]
    );

    // Markup
    const actionMarkup = useMemo(() => {
        return state.step === Step.ChooseBase
            ? {
                  content: "Continue",
                  onAction: () => handleStepChange(Step.FormProduct),
              }
            : undefined;
    }, [state.step, handleStepChange]);

    const contentMarkup = isMounted
        ? {
              [Step.ChooseBase]: (
                  <ChooseBase updateState={updateState} state={state} />
              ),
              [Step.FormProduct]: (
                  <Form onSubmit={handleSubmit} loading={loading} />
              ),
          }
        : {};

    useEffect(() => {
        setIsMounted(true);
        updateState({ selectedBases: [] });

        return () => {
            clearState();
            setIsMounted(false);
        };
    }, []);

    return state.step === Step.ViewTemplate ? (
        <ProductTemplate updateState={updateState} />
    ) : (
        <Wrapper>
            <Page
                title="Create Product"
                fullWidth
                breadcrumbs={[
                    {
                        content: "Product From Print File",
                        url: `/${currentParam}/product-from-print-file`,
                    },
                ]}
                {...(actionMarkup != null && { primaryAction: actionMarkup })}
            >
                {contentMarkup[state.step]}
            </Page>
        </Wrapper>
    );
}

export function ChooseBase({ inModal }) {
    // Context
    const { state, updateState } = useCreateProduct();

    // Handle action
    const handleFulfillmentChange = useCallback(
        (v) => {
            updateState({ fulfillmentId: v });
        },
        [updateState]
    );

    const handleProductBaseChange = useCallback(
        (selected) => {
            updateState({ selectedBases: selected });
        },
        [updateState]
    );

    const handleTemplateChange = useCallback(() => {
        updateState({ step: Step.ViewTemplate });
    }, [updateState]);

    return (
        <ProductBaseSelect
            onFulfillmentChange={handleFulfillmentChange}
            fulfillmentId={state.fulfillmentId}
            value={state.selectedBases}
            onChange={handleProductBaseChange}
            onViewTemplates={inModal ? null : handleTemplateChange}
            multiple
            noVariantDisabled
            selectedProductBases={state.selectedBases}
        />
    );
}

function ProductTemplate({ updateState }) {
    // Handle actions
    const handleStepChange = useCallback(() => {
        updateState({ step: Step.ChooseBase });
    }, [updateState]);

    const handleTemplateChange = useCallback(
        (template) => {
            const base = template.data.productBases;
            updateState({
                step: Step.FormProduct,
                selectedBases: base,
                template,
            });
        },
        [updateState]
    );

    return (
        <ProductTemplatesPolaris
            onChangeStep={handleStepChange}
            handleUseTemplate={handleTemplateChange}
            allTemplate
        />
    );
}

const Wrapper = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;
