import React, { useCallback } from "react";
import { Button } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";
import history from "../../history";

export const EXPORT_AMAZON_PRODUCTS = gql`
    mutation exportAmazonProducts($exportTemplateId: ID!) {
        exportAmazonProducts(exportTemplateId: $exportTemplateId)
    }
`;

// amazonExportLastFile
export const EXPORT_AMAZON_LAST_FILE = gql`
    mutation amazonExportLastFile($exportTemplateId: ID!) {
        amazonExportLastFile(exportTemplateId: $exportTemplateId)
    }
`;

export const ExportAmazonActionsPolaris = (props) => {
    const { item, toggleActive, setNotification, currentParam } = props;
    const id = item && item.id;

    const [exportAmazonProducts, { loading }] = useMutation(
        EXPORT_AMAZON_PRODUCTS,
        {
            onError: (error) => {
                setNotification({
                    message: handleError(error.toString()),
                    isError: true,
                });
            },
            onCompleted: (res) => {
                let url = res?.exportAmazonProducts;
                if (url) {
                    window.location.href = url;
                }
                setNotification({
                    message: `Download file successfuly.`,
                    isError: false,
                });
            },
        }
    );

    const [amazonExportLastFile, { loading: loadingLF }] = useMutation(
        EXPORT_AMAZON_LAST_FILE,
        {
            onError: (error) => {
                setNotification({
                    message: handleError(error.toString()),
                    isError: true,
                });
            },
            onCompleted: (res) => {
                let url = res?.amazonExportLastFile;
                if (url) {
                    window.location.href = url;
                }
                setNotification({
                    message: `Download file successfuly.`,
                    isError: false,
                });
            },
        }
    );

    // Handle actions
    const handleDownload = useCallback((item) => {
        let id = item?.id;
        let totalProducts = item?.totalProducts;

        setNotification({
            message: null,
            isError: null,
        });
        if (id) {
            if (parseInt(totalProducts) > 0) {
                amazonExportLastFile({
                    variables: {
                        exportTemplateId: id,
                    },
                });
            } else {
                exportAmazonProducts({
                    variables: {
                        exportTemplateId: id,
                    },
                });
            }

            toggleActive();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCopyConfigs = useCallback((id) => {
        if (id) {
            history.push(`/${currentParam}/export-amazon/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleViewConfigs = useCallback((id) => {
        if (id) {
            history.push(`/${currentParam}/export-amazon/view-configs/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleViewProducts = useCallback((id) => {
        if (id) {
            history.push(`/${currentParam}/export-amazon/view-products/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Button
                plain
                children="View products"
                onClick={() => handleViewProducts(id)}
            />
            <Button
                plain
                children="View configs"
                onClick={() => handleViewConfigs(id)}
            />
            <Button
                plain
                children="Copy configs"
                onClick={() => handleCopyConfigs(id)}
            />
            <Button
                plain
                children="Download"
                onClick={() => handleDownload(item)}
                loading={loading || loadingLF}
            />
        </React.Fragment>
    );
};
