import { Page } from "@shopify/polaris";
import React, { useMemo } from "react";
import styled from "styled-components";
import { MembersPolaris } from "../../components/marketplace-manager/MembersPolalris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const MembersPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="All Members"
                fullWidth
                primaryAction={{
                    content: "Add member",
                    url: `/marketplace-manager/add-team-member`,
                }}
            >
                <MembersPolaris path={path} />
            </Page>
        </Container>
    );
};
