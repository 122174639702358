import React, { useEffect, useState, useCallback } from "react";
import { Modal, Icon } from "@shopify/polaris";
import { MaximizeMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";

import { elementContains } from "../../../helper";
import { ViewMockupTabsPolaris } from "./ViewMockupTabsPolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  .content-wrap {
    display: flex;
    width: 50%;
    flex-direction: column;
    row-gap: 1rem;
    .thumbnail-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 1rem;
      position: relative;
      margin-bottom: 2rem;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        cursor: pointer;
        border-radius: 3px;
        box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .orderDesign-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
    .orderDesign-inner {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      justify-content: start;
      width: 100%;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .orderDesign-content,
  .thumbnail-wrap {
    &:hover {
      .view-origin {
        opacity: 1;
      }
    }
    .view-origin {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: right;
      padding-top: 1rem;
      padding-right: 1rem;
      background: linear-gradient(
        180deg,
        rgba(33, 43, 54, 0.55),
        hsla(0, 0%, 100%, 0)
      );
      opacity: 0;
      transition: 0.3s opacity ease-out;
      .img-wrap {
        display: inline-block;
      }
      .Polaris-Icon {
        svg {
          fill: hsla(0, 0%, 100%, 0.8);
        }
      }
    }
  }
  .design-postions-wrap {
    width: 50%;
  }
`;

export const ViewMockupPolaris = ({ open, toggleShowModal, value }) => {
  const [, setShowT] = useState(false);

  // Handle actions
  const toggleShowT = useCallback(
    () => setShowT((prevState) => !prevState),
    []
  );

  // Markup
  useEffect(() => {
    let domCD = document.querySelector("#view-mockup-wrap");
    let domParents = document.querySelectorAll(".Polaris-Modal-Dialog__Modal");
    for (let domParent of domParents) {
      if (elementContains(domParent, domCD)) {
        domParent.setAttribute(
          "style",
          "max-width: calc(100% - 10rem); height: calc(100vh)"
        );
      }
    }
  });

  const images = value?.images || [];
  const designPositions =
    value?.designPositions?.length > 0
      ? value.designPositions.filter((dp) => dp.file)
      : [];

  return (
    <Modal
      title="View Mockups"
      open={open}
      onClose={toggleShowModal}
      sectioned
      primaryAction={{ content: "Done", onAction: toggleShowModal }}
    >
      <Container id="view-mockup-wrap">
        <div className="content-wrap">
          <h3>Mockups</h3>
          {images?.length > 0
            ? images.map((img, index) => {
                let originUrl =
                  img && img.file
                    ? img.file.url
                      ? img.file.url
                      : img.file.thumbnail
                    : null;
                return (
                  <div className="thumbnail-wrap" key={index}>
                    <img
                      alt=""
                      src={originUrl}
                      onClick={toggleShowT}
                      className="shadow-md"
                    />
                    <div className="view-origin">
                      <div className="img-wrap">
                        <CustomLinkPolaris href={originUrl}>
                          <Icon source={MaximizeMajorMonotone}></Icon>
                        </CustomLinkPolaris>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="design-postions-wrap">
          {designPositions.length > 0 ? (
            <div>
              <h3>Designs</h3>
              <ViewMockupTabsPolaris
                designPositions={designPositions}
                productBases={value?.productBases}
              />
            </div>
          ) : null}
        </div>
      </Container>
    </Modal>
  );
};
