import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { AddExportTemplatePolaris } from "../../components/admin/manager-files/AddExportTemplatePolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddExportTemplatePagePolaris = () => {
  return (
    <Container>
      <Page
        title="Add export file Template"
        breadcrumbs={[
          {
            content: "Export templates",
            url: "/admin/manager-files/export-templates",
          },
        ]}
        fullWidth
      >
        <AddExportTemplatePolaris />
      </Page>
    </Container>
  );
};
