import React from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { MARK_CHECKPOINT } from "../../../../graphql/mutations";
import { handleError } from "../../../../helper";
import { FBMContext } from "../context";

export function MarkCheckpointed({ value, toggleOpen, open }) {
    // variable
    const id = value?.id;
    const suspended = value?.suspended;
    const emailStore = value?.email;
    const label = suspended ? "Unmark checkpointed" : "Mark checkpointed";

    // Context
    const { toggleToast, setNotify } = React.useContext(FBMContext);

    // Mutation
    const [markCheckpointed, { loading }] = useMutation(MARK_CHECKPOINT, {
        onCompleted: () => {
            if (setNotify) {
                setNotify({
                    msg: `${label} for account successfully.`,
                    err: false,
                });
            }
            toggleOpen && toggleOpen();
        },
        onError: (error) => {
            if (setNotify) {
                setNotify({
                    msg: handleError(error.toString()),
                    err: true,
                });
            }
        },
    });

    const handleSubmit = React.useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            if (setNotify) {
                setNotify({ msg: null, err: false });
            }
            markCheckpointed({
                variables: {
                    emailStore,
                    isCheckpoint: suspended ? false : true,
                },
            });
        }
    }, [markCheckpointed, id, emailStore, suspended, toggleToast, setNotify]);

    return (
        <Modal
            title={label}
            open={open}
            onClose={toggleOpen}
            sectioned
            secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to {label} for this account?</p>
        </Modal>
    );
}
