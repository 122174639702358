import React from 'react';
import { Page, Toast } from '@shopify/polaris';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useToggle from '../../../../hooks/useToggle';
import { CREATE_ACCOUNT_SALE_CHANNEL } from '../../../../graphql/mutations';
import { handleError } from '../../../../helper';
import { AppContext } from '../../../../context';
import {
    PLATFORM_FACEBOOK as PFacebook,
    USER_ROLE,
    TEAM_ROLE,
    TEAM_ROLE_PARAMS,
} from '../../../../variable';

import { Form } from './Form';

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export function CreateStore() {
    // Context
    const { currentUser } = React.useContext(AppContext);
    const userRole = currentUser?.roles || [];
    const teamRole = currentUser?.teamUser.role || null;

    const isSeller = userRole.includes(USER_ROLE.Seller);
    const isStoreManager =
        isSeller && [TEAM_ROLE.StoreManager].includes(teamRole);
    const isMM = isSeller && [TEAM_ROLE.MarketplaceManager].includes(teamRole);

    // Param
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (isStoreManager) {
        currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }
    if (isMM) {
        currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }

    const url = `/${currentParam}/stores/fb-marketplace`;

    // History
    const history = useHistory();

    // State
    const [toastActive, toggleToast] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const timeoutRef = React.useRef(null);

    // Mutation
    const [createAccount, { loading }] = useMutation(
        CREATE_ACCOUNT_SALE_CHANNEL,
        {
            onCompleted: () => {
                if (setNotify) {
                    setNotify({
                        msg: `Create account successfully.`,
                        err: false,
                    });
                }

                timeoutRef.current && clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    handleRedirect();
                }, 1500);
            },
            onError: (error) => {
                if (setNotify) {
                    setNotify({
                        msg: handleError(error.toString()),
                        err: true,
                    });
                }
            },
        }
    );

    // Handle actions
    const handleRedirect = React.useCallback(() => {
        history.push(url);
    }, [history, url]);

    const handleSubmit = React.useCallback(
        (input) => {
            if (input != null) {
                const { owners, ...rest } = input;
                toggleToast && toggleToast(true);
                if (setNotify) {
                    setNotify({ msg: null, err: false });
                }
                createAccount({
                    variables: {
                        input: {
                            ...rest,
                            platform: PFacebook,
                        },
                    },
                });
            }
        },
        [createAccount, toggleToast, setNotify]
    );

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            duration={1500}
            onDismiss={toggleToast}
        />
    );

    const btnLabel = isStoreManager
        ? 'Create FB Marketplace Account'
        : 'Add Marketplace Account';

    return (
        <Container>
            {toastMarkup}
            <Page
                title={btnLabel}
                fullWidth
                breadcrumbs={[{ content: 'Facebook Marketplace', url: url }]}
            >
                <Form
                    onSubmit={handleSubmit}
                    loading={loading}
                    handleRedirect={handleRedirect}
                />
            </Page>
        </Container>
    );
}
