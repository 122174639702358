import { useMutation } from "@apollo/react-hooks";
import {
  Badge,
  Card,
  Checkbox,
  ChoiceList,
  Collapsible,
  Form,
  FormLayout,
  Heading,
  Modal,
  Stack,
  TextField,
  Toast,
} from "@shopify/polaris";
import get from "lodash/get";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { SWITCH_FULFILLMENT } from "../../../graphql/mutations";
import { handleError, isAccAThien } from "../../../helper";
import { THIRD_PARTY_FULFILLMENTS } from "../../../variable";
// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { FulfillmentSelectPolaris } from "../../fulfillment/FulfillmentSelectPolaris";
import { ProductBaseSelectPolaris } from "../../fulfillment/ProductBaseSelectPolaris";
import { ProductBaseVariantSelectPolaris } from "../../fulfillment/ProductBaseVariantSelectPolaris";
import {
  genShippingOptionKey,
  ShippingOption,
} from "../../switch-fulfillment/ShippingOptions";

const Container = styled.div`
  .ff-info {
    padding-bottom: 2.5rem;
    border-bottom: var(
      --p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8)
    );
    h3 {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .info-inner {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      span {
        opacity: 0.8;
      }
      & + .info-inner {
        margin-top: 0.875rem;
      }
    }
  }
`;

export const SwitchOrderFulfillmentPolaris = (props) => {
  const { open, toggleShowModal, value, refetch } = props;
  const { id: orderId, fulfillmentServiceShippingOption } = value || {};

  const [fulfillmentId, setFulfillmentId] = useState(null);
  const [productBaseId, setProductBaseId] = useState(null);
  const [productBaseVariantId, setProductBaseVariantId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({
    productBaseId: null,
    productBaseVariantId: null,
  });
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [usingShippingOption, setUsingShippingOption] = useState(false);
  const [newShippingOption, setNewShippingOption] = useState([null]);
  const [fulfillment, setFulfillment] = useState(null);
  const [shippingOptionConfigs, setShippingOptionConfigs] = useState(new Map());

  // Check current user
  const { currentUser } = useAppContext();

  const [switchFulfillment, { data, loading, error }] = useMutation(
    SWITCH_FULFILLMENT,
    {
      onError: () => {},
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleShowModal(false);
          if (refetch) refetch();
        }, 2100);
        setTimeoutId(id);
      },
    }
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);

  const [newShippingOpt] = newShippingOption || [];
  const handleSubmit = useCallback(() => {
    let errorPB = null;
    let errorPBV = null;

    if (!productBaseId) {
      errorPB = "Product base is required.";
    }
    if (!productBaseVariantId) {
      errorPBV = "Product base variant is required.";
    }

    setErrors((prevState) => ({
      ...prevState,
      productBaseId: errorPB,
      productBaseVariantId: errorPBV,
    }));

    let baseVariantId = productBaseVariantId;

    let newConfigs = [];
    if (usingShippingOption && typeof shippingOptionConfigs === "object") {
      for (let item of shippingOptionConfigs.values()) {
        const { mappingOptions, ...rest } = item || {};
        const opts = [];
        if (typeof mappingOptions === "object") {
          for (let entry of mappingOptions.values()) {
            opts.push(entry);
          }
        }
        if (opts.length > 0) {
          newConfigs.push({ ...rest, mappingOptions: opts });
        }
      }
    }
    if ((orderId, fulfillmentId, productBaseVariantId)) {
      switchFulfillment({
        variables: {
          input: {
            baseVariantId: baseVariantId,
            fulfillmentId: fulfillmentId,
            orderId: orderId,
            continue: checked,
            usingShippingOption,
            newShippingOption: newShippingOpt,
            shippingOptionConfigs:
              usingShippingOption && newConfigs?.length > 0
                ? newConfigs
                : undefined,
          },
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fulfillmentId,
    productBaseId,
    productBaseVariantId,
    checked,
    orderId,
    usingShippingOption,
    newShippingOpt,
    shippingOptionConfigs,
  ]);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.switchFulfillment)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.switchFulfillment && "Switch fulfillment success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  let productBase = get(
    value,
    "productVariant.productBaseVariant.productBase.title",
    null
  );
  if (!productBase) {
    let newProducBase = get(value, "product.productBases", [])
      .map((item) => item.title)
      .join(", ");
    productBase = newProducBase;
  }

  let attributesMarkup = get(
    value,
    "productVariant.productBaseVariant.attributes",
    []
  ).map((att, idx) => (
    <div key={idx}>
      <span>
        {att.name}: {att.option}
      </span>
    </div>
  ));

  if (!attributesMarkup.length) {
    let newAttributes = get(value, "product.productBases", []).map(
      (pb) =>
        pb.attributes &&
        pb.attributes.map((att, idx) => (
          <div key={idx}>
            <span>
              {att.name} : {att.options.join(", ")}
            </span>
          </div>
        ))
    );
    attributesMarkup = newAttributes;
  }

  const showSaveSetting = isAccAThien(currentUser);

  const originFulfillmentID = get(value, "fulfillment.id");
  const originFulfillment = get(value, "fulfillment.name");
  const originFulfillmentSlug = get(value, "fulfillment.slug");

  // Target fulfillment
  const fulfillmentSlug = get(fulfillment, "slug");
  const fulfillmentName = get(fulfillment, "label");

  const cardTtitle = [originFulfillment, fulfillmentName].join("  ->  ");
  const shippingOptionByFF = THIRD_PARTY_FULFILLMENTS[fulfillmentSlug];

  useEffect(() => {
    if (!showSaveSetting || !checked) return;
    if (originFulfillmentID && fulfillmentId) {
      const key = genShippingOptionKey(originFulfillmentID, fulfillmentId);
      setShippingOptionConfigs((prev) => {
        if (prev.has(key)) return prev;
        const opts =
          (shippingOptionRef.current &&
            shippingOptionRef.current.getOptions()) ||
          [];
        const mappingOptions = new Map(
          opts.map(({ value }) => [
            value,
            {
              originShippingMethod: value,
              targetShippingMethod: null,
            },
          ])
        );
        prev.set(key, {
          targetFulfillmentID: fulfillmentId,
          originFulfillmentID,
          mappingOptions,
        });

        return prev;
      });
    }
  }, [originFulfillmentID, fulfillmentId, showSaveSetting, checked]);

  const handleShippingOptionChange = useCallback(
    (originMethod, targetMethod) => {
      const key = genShippingOptionKey(originFulfillmentID, fulfillmentId);
      setShippingOptionConfigs((prev) => {
        if (!prev.has(key)) return prev;

        const { mappingOptions, ...rest } = prev.get(key);
        if (mappingOptions.size === 0) return prev;
        const currentOption = mappingOptions.get(originMethod);
        if (!currentOption) return prev;

        currentOption.targetShippingMethod = targetMethod;
        mappingOptions.set(originMethod, currentOption);

        prev.set(key, { ...rest, mappingOptions });

        return prev;
      });
    },
    [originFulfillmentID, fulfillmentId]
  );
  const shippingOptionRef = useRef(null);

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleShowModal}
        sectioned
        large
        title={`Switch fulfillment order: #${orderId ? orderId : null}`}
        secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <Container id="switchff-modal">
          <div className="ff-info">
            <h3>Item information</h3>
            <div className="info-inner">
              <label>Product base:</label>
              <span>{productBase}</span>
            </div>
            <div className="info-inner">
              <label>Item atributes:</label>
              <span>{attributesMarkup}</span>
            </div>
            {!!fulfillmentServiceShippingOption && (
              <div className="info-inner">
                <label>Current Fulfillment Shipping Option:</label>
                <div>
                  <Badge children={fulfillmentServiceShippingOption} />
                </div>
              </div>
            )}
          </div>
          <div style={{ paddingTop: "1.6rem" }}>
            <Form>
              <FormLayout>
                <FulfillmentSelectPolaris
                  exclude={get(value, "fulfillmentId", null)}
                  onChange={(v) => setFulfillmentId(v)}
                  setFulfillment={setFulfillment}
                />
                <ProductBaseSelectPolaris
                  fulfillmentId={fulfillmentId}
                  onChange={(v) => {
                    setProductBaseId(v);
                    setErrors((prevState) => ({
                      ...prevState,
                      productBaseId: null,
                    }));
                  }}
                  error={errors.productBaseId}
                />
                <ProductBaseVariantSelectPolaris
                  productBaseId={productBaseId}
                  onChange={(v) => {
                    setProductBaseVariantId(v);
                    setErrors((prevState) => ({
                      ...prevState,
                      productBaseVariantId: null,
                    }));
                  }}
                  error={errors.productBaseVariantId}
                />
                {showSaveSetting ? (
                  <Checkbox
                    checked={checked}
                    label="Save settings"
                    helpText="Apply this settings for future orders of this product base variant."
                    onChange={handleChange}
                  />
                ) : null}
                {!!shippingOptionByFF ? (
                  <ServiceShippingOption
                    ref={shippingOptionRef}
                    shippingOption={shippingOptionByFF}
                    newShippingOption={newShippingOption}
                    isSaveSettings={checked}
                    showSaveSetting={showSaveSetting}
                    cardTtitle={cardTtitle}
                    onChange={handleShippingOptionChange}
                    originFulfillmentSlug={originFulfillmentSlug}
                    fulfillmentSlug={fulfillmentSlug}
                    setNewShippingOption={setNewShippingOption}
                    usingShippingOption={usingShippingOption}
                    setUsingShippingOption={setUsingShippingOption}
                  />
                ) : null}
              </FormLayout>
            </Form>
          </div>
        </Container>
      </Modal>
    </>
  );
};

const ServiceShippingOption = React.forwardRef(function ServiceShippingOption(
  {
    shippingOption,
    usingShippingOption,
    setUsingShippingOption,
    newShippingOption,
    setNewShippingOption,
    showSaveSetting,
    cardTtitle,
    originFulfillmentSlug,
    fulfillmentSlug,
    onChange,
    isSaveSettings,
  },
  ref
) {
  const options = useMemo(() => {
    if (!shippingOption || typeof shippingOption !== "object") return [];
    const opts = Object.entries(shippingOption).map(([value, label]) => ({
      value,
      label,
    }));
    opts.unshift({ value: null, label: "Default" });
    return opts;
  }, [shippingOption]);

  const originOptions = useMemo(() => {
    const originMap = THIRD_PARTY_FULFILLMENTS[originFulfillmentSlug];
    let originOptions = [];
    if (originMap) {
      originOptions = Object.entries(originMap).map(([value, label]) => ({
        value,
        label,
      }));
      originOptions.unshift({ value: null, label: "Default" });
    }
    return originOptions;
  }, [originFulfillmentSlug]);

  const handleShippinOptionChange = useCallback(
    (originMethod) => (newShippingOption) => {
      const [value] = newShippingOption || [];
      onChange(originMethod, value);
    },
    [onChange]
  );

  useImperativeHandle(
    ref,
    () => ({
      getOptions: () => originOptions,
    }),
    [originOptions]
  );

  return (
    <div>
      <Stack vertical>
        <Checkbox
          label="Using Shipping Option"
          checked={usingShippingOption}
          onChange={setUsingShippingOption}
        />
        <div style={{ marginLeft: "1rem" }}>
          <Collapsible open={usingShippingOption}>
            <Stack vertical spacing="loose">
              <ChoiceList
                choices={options}
                selected={newShippingOption}
                onChange={setNewShippingOption}
                title="Shipping Options for this order"
              />

              {showSaveSetting &&
              isSaveSettings &&
              originOptions?.length > 0 ? (
                <Card title="Shipping Options for next orders" sectioned>
                  <Stack vertical>
                    <Heading element="h3" children={cardTtitle} />
                    {originOptions.map((opt, index) => {
                      const textField = (
                        <TextField disabled value={opt.label} />
                      );
                      return (
                        <Stack
                          distribution="fillEvenly"
                          spacing="loose"
                          key={`shipping-options-${index}`}
                        >
                          {textField}
                          <ShippingOption
                            targetSlug={fulfillmentSlug}
                            onChange={handleShippinOptionChange(opt.value)}
                            defaultValue={[]}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </Card>
              ) : null}
            </Stack>
          </Collapsible>
        </div>
      </Stack>
    </div>
  );
});
