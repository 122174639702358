import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import _ from "lodash";

import { isEmpty, formatDataTree, genLabelTree } from "../../helper";
import { AppContext } from "../../context";
import { TEAM_ROLE, USER_ROLE } from "../../variable";
import { LIST_COLLECTION } from "../product/collections/CollectionSelectPolaris";
import { TAGS } from "../product/tags/TagsSelectPolaris";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";

const LIST_STORE = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
      }
    }
  }
`;

export const ProductPushFiltersPolaris = ({ onChange, filter }) => {
  const [inputValue, setInputValue] = useState(filter.search);
  const [collection, setCollection] = useState({
    value: null,
    label: null,
    search: null,
  });
  const [tag, setTag] = useState({
    value: null,
    label: null,
    search: null,
  });
  const [store, setStore] = useState({
    value: null,
    label: null,
    search: null,
  });

  const [dataCollection, setDataCollection] = useState([]);
  const [dataTag, setDataTag] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const typingTimeoutRef = useRef(null);

  const { currentUser } = useContext(AppContext);
  const userRole = _.get(currentUser, "roles", []);
  const teamRole = _.get(currentUser, "teamUser.role", null);

  let isSM = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
      isSM = true;
    }
  }

  const typingRef = useRef(null);

  // Queries
  const {
    data: initialCollections,
    loading: loadingCollections,
    error: errorCollections,
  } = useQuery(LIST_COLLECTION, {
    variables: {
      filter: {
        search: collection.search,
        limit: 20,
        offset: 0,
      },
    },
  });

  const {
    data: initialTags,
    loading: loadingTags,
    error: errorTags,
  } = useQuery(TAGS, {
    variables: {
      filter: {
        search: tag.search,
        limit: 20,
        offset: 0,
      },
    },
  });

  const {
    data: initialStores,
    loading: loadingStores,
    error: errorStores,
  } = useQuery(LIST_STORE, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: store.search,
      },
    },
  });

  // Get data
  useEffect(() => {
    let newData = initialCollections;
    const nodes = newData?.collections?.nodes || [];
    if (nodes.length > 0) {
      let result = formatDataTree(nodes);
      setDataCollection(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCollections]);

  useEffect(() => {
    let newData = initialTags;
    const nodes = newData?.tags?.nodes || [];
    if (nodes.length > 0) {
      let result = formatDataTree(nodes);
      setDataTag(result);
    }
  }, [initialTags]);

  useEffect(() => {
    let newData = initialStores;
    if (newData?.stores?.nodes?.length > 0) {
      newData = newData.stores.nodes.map((store) => {
        return {
          value: store.id,
          label: store.title,
        };
      });
      setDataStore(newData);
    }
  }, [initialStores]);

  useEffect(() => {
    if (filter) {
      if (filter.collections && filter.collections.length) {
        let value = _.head(filter.collections);
        let matched =
          dataCollection && dataCollection.find((c) => c.value === value);
        setCollection((prevState) => ({
          ...prevState,
          value,
          label: matched && matched.label,
        }));
      }
      if (filter.tags && filter.tags.length) {
        let value = _.head(filter.tags);
        let matched = dataTag && dataTag.find((c) => c.value === value);
        setTag((prevState) => ({
          ...prevState,
          value,
          label: matched && matched.label,
        }));
      }
      if (filter.storeId) {
        let value = filter.storeId;
        let matched = dataStore && dataStore.find((c) => c.value === value);
        setStore((prevState) => ({
          ...prevState,
          value,
          label: matched && matched.label,
        }));
      }
    }
  }, [filter, dataCollection, dataTag, dataStore]);

  // Change filter for query
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: inputValue ? inputValue.trim() : inputValue,
          collections: collection.value ? [collection.value] : null,
          tags: tag.value ? [tag.value] : null,
          storeId: store.value,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, collection, tag, store]);

  const handleQueryChange = useCallback((value) => setInputValue(value), []);

  // Clear filters
  const handleQueryRemove = useCallback(() => setInputValue(null), []);
  const handleCollectionRemove = useCallback(
    () => setCollection({ value: null, label: null, search: null }),
    []
  );
  const handleTagRemove = useCallback(
    () => setTag({ value: null, label: null, search: null }),
    []
  );
  const handleStoreRemove = useCallback(
    () => setStore({ value: null, label: null, search: null }),
    []
  );

  const handleFiltersClearAll = useCallback(() => {
    handleQueryRemove();
    handleCollectionRemove();
    handleTagRemove();
    handleStoreRemove();
  }, [
    handleQueryRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleStoreRemove,
  ]);

  const handleSearchStore = useCallback(({ search }) => {
    typingRef.current && clearTimeout(typingRef.current);
    typingRef.current = setTimeout(() => {
      setStore((p) => ({ ...p, search }));
    }, 500);
  }, []);

  const filters = [
    {
      key: "collections",
      label: "Collections",
      filter: (
        <FilterHasSearchPolaris
          options={dataCollection}
          value={collection}
          loading={loadingCollections}
          error={errorCollections}
          onChangeSearch={(search) =>
            setCollection((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setCollection((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    {
      key: "tags",
      label: "Tags",
      filter: (
        <FilterHasSearchPolaris
          options={dataTag}
          value={tag}
          loading={loadingTags}
          error={errorTags}
          onChangeSearch={(search) =>
            setTag((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setTag((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    ...(!isSM
      ? [
          {
            key: "stores",
            label: "Stores",
            filter: (
              <FilterNoSearchPolaris
                value={store}
                error={errorStores}
                loading={loadingStores}
                data={dataStore && dataStore.length > 0 ? dataStore : []}
                onChangeSearch={handleSearchStore}
                onChange={({ value, label }) =>
                  setStore((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),
  ];

  const appliedFilters = [];
  if (!isEmpty(collection.label)) {
    const key = "collections";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection.label),
      onRemove: handleCollectionRemove,
    });
  }
  if (!isEmpty(tag.label)) {
    const key = "tags";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag.label),
      onRemove: handleTagRemove,
    });
  }

  if (!isEmpty(store.label)) {
    const key = "stores";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }

  return (
    <Filters
      queryValue={inputValue}
      queryPlaceholder="Filter item"
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFiltersClearAll}
      filters={filters}
      appliedFilters={appliedFilters}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "collections":
        const newLabel = value ? genLabelTree([value]) : "";
        return `Collection: ${newLabel}`;
      case "tags":
        const newTag = value ? genLabelTree([value]) : "";
        return `Tag: ${newTag}`;
      case "stores":
        return `Store: ${value}`;
      default:
        return value;
    }
  }
};
