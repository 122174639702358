import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const DELETE_MUTATION = gql`
  mutation deleteCrawlItemSelected($id: [ID]!) {
    deleteCrawlItemSelected(id: $id)
  }
`;

const DELETE_PRODUCT_MUTATION = gql`
  mutation deleteProductCrawlItem($id: ID!) {
    deleteProductCrawlItem(id: $id)
  }
`;

export const DeleteProductAssortmentPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    ids,
    toggleActive,
    onNotificationChange,
    onCompleted,
    deleteOneProduct,
  } = props;
  const [deleteProduct, { loading }] = useMutation(DELETE_MUTATION, {
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: `Delete product(s) success.`,
          isError: false,
        });
      }
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  const [deleteProductMutation, { loading: loadingOneProduct }] = useMutation(
    DELETE_PRODUCT_MUTATION,
    {
      onCompleted: () => {
        toggleShowModal();
        if (onNotificationChange) {
          onNotificationChange({
            message: `Delete product success.`,
            isError: false,
          });
        }
        if (onCompleted) {
          onCompleted();
        }
      },
      onError: (error) => {
        if (onNotificationChange) {
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
        }
      },
    }
  );

  const handleSubmit = useCallback(() => {
    if (deleteOneProduct) {
      deleteProductMutation({
        variables: {
          id: ids,
        },
      });
    } else {
      deleteProduct({
        variables: {
          id: ids,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  return (
    <Modal
      open={open}
      title={`Delete product${deleteOneProduct ? "" : "s"} selected`}
      onClose={toggleShowModal}
      sectioned
      primaryAction={{
        content: "Delete",
        onAction: handleSubmit,
        loading: deleteOneProduct ? loadingOneProduct : loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
    >
      <p>Are you sure to delete this product{deleteOneProduct ? "" : "s"}?</p>
    </Modal>
  );
};
