import React from "react";
import { Select } from "antd";

const options = [
  { key: "fit", value: "Fit" },
  { key: "fill", value: "Fill" },
  { key: "file", value: "File" },
];

export const ResizeSelect = (props) => {
  const { value, onChange } = props;

  return (
    <Select
      value={value || null}
      onChange={(v) => {
        if (onChange) {
          onChange(v);
        }
      }}
    >
      <Select.Option value={null}>Default</Select.Option>
      {options.map((option, idx) => (
        <Select.Option key={idx} value={option.key}>
          {option.value}
        </Select.Option>
      ))}
    </Select>
  );
};
