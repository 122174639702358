import { Mutation } from "@apollo/react-components";
import { Button, Modal, notification, Upload } from "antd";
import { gql } from "apollo-boost";
import axios from "axios";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { handleError, loadThumbnailImage, isPsdByKey } from "../../helper";
import { ImageTypes } from "../../variable";
import { CREATE_FILE_MUTATION, REQUEST_MUTATION } from "../file/ImageUpload";
import Media, { LOAD_MEDIA_QUERY } from "../file/Media";
import { GEN_THUMBNAIL_IMAGE } from "../seller/ModalImageClaimPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal {
    top: 30px;
  }
  .ant-btn-primary {
    margin-bottom: 10px;
  }
  .extend {
    text-align: right;
    border-top: 1px solid #cdcdcd;
    padding: 10px 0;
  }
  padding-bottom: 10px;
  padding-top: 10px;
  &.disabled {
    .ant-upload {
      display: none;
    }
  }
  width: ${(props) => (props.width ? "100px" : "auto")};
  .p-size {
    size: ${(props) => (props.size ? "12px" : "auto")};
  }
  .ant-upload.ant-upload-drag {
    background-image: ${(props) =>
      props.placeholderImage
        ? `url(${props.placeholderImage.thumbnailUrl})`
        : "none"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: ${(props) => (props.placeholderImage ? 0.7 : 1)};
  }
  .ant-upload-list-picture-card-container {
    .ant-upload-list-item.main-img {
      border: 2px solid rgb(24, 144, 255);
    }
  }
  .ant-upload-list-item {
    .custom-checkbox {
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 100;
      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
`;

const IMPORT_FILE_URLS_MUTATION = gql`
  mutation importFileUrl($urls: [String!]!, $prefix: String) {
    importFileUrl(urls: $urls, prefix: $prefix) {
      id
      name
      alt
      caption
      thumbnailUrl
      url
      mimeType
      size
      createdAt
    }
  }
`;

class MediaSelectorButton extends Component {
  state = {
    loading: false,
    visible: false,
    fileList: [],
    value: [],
    previewVisible: false,
    previewImage: "",
    previewUrl: "",
    importUrls: "",
    isTypeImage: true,
    currentFile: null,
    mainImg: {},
    okBtnDisabled: false,
    dimensions: {},
  };

  genThumbnail = undefined;
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  handlePreview = async (file) => {
    if (file.file && !file.file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    let currentMimeType = true;
    let currentFile = "";
    if (file && file.file) {
      if (file.file.mimeType) {
        if (!ImageTypes.includes(file.file.mimeType.toLowerCase())) {
          currentMimeType = false;
          currentFile = file.file;
        }
      } else if (isPsdByKey(file.file.key)) {
        currentMimeType = false;
        currentFile = file.file;
      }
    }
    this.setState({
      previewImage: (file.file && file.file.url) || file.preview,
      previewUrl: file?.file?.url,
      previewVisible: true,
      isTypeImage: currentMimeType,
      currentFile: currentFile,
    });
  };

  componentDidMount() {
    const { value } = this.props;
    if (value && value.length) {
      this.setState({
        fileList: this.getFileList(value),
      });

      // regenerates file thumbnail
      if (this.genThumbnail != null) {
        for (let i = 0; i < value.length; i++) {
          let file = value[i];
          if (!file) continue;
          fetch(file.thumbnailUrl).then((res) => {
            if (res.status === 404) {
              this.forceUpdate();
              this.genThumbnail({
                variables: {
                  files: [file.id],
                  fileType: "id",
                },
              });
            }
          });
        }
      }
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    let { fileList, mainImg } = this.state;
    let { isMockup, onChangeMainImg, isCampaign } = this.props;
    let propsMainImg = this.props.mainImg;

    let listIncludeMainImg = null;
    if (fileList && fileList.length) {
      listIncludeMainImg = fileList.find((i) => i.uid === propsMainImg);
    }

    if (!isEqual(_prevProps.fileList, this.props.fileList)) {
      this.setState({ fileList: this.getFileList(this.props.fileList) });
    }

    // Case: edit campaign
    let specialCase = false;
    if (!listIncludeMainImg && isCampaign) {
      specialCase = true;
    }

    // Apply checkbox main image for product
    if (isMockup) {
      if (fileList !== prevState.fileList || mainImg !== prevState.mainImg) {
        let dom = ReactDOM.findDOMNode(this);
        let listUploaded = dom.getElementsByClassName("ant-upload-list-item");
        if (listUploaded) {
          const newListUploaded = Array.from(listUploaded).filter(
            (_i, index) => {
              let item = fileList[index];
              if (isCampaign) {
                if (listIncludeMainImg && propsMainImg) {
                  return item && item.uid !== propsMainImg;
                } else {
                  return true;
                }
              } else {
                if (propsMainImg) {
                  return item && item.uid !== propsMainImg;
                }
                return index !== 0;
              }
            },
          );

          // new File list
          const newFileList =
            fileList?.length > 0
              ? fileList.filter((i) => i?.uid !== propsMainImg)
              : [];
          newListUploaded.forEach((i, index) => {
            // Compare length
            const newIndex =
              newFileList?.length > newListUploaded?.length ? index + 1 : index;

            let currentItem = specialCase
              ? newFileList[index]
              : newFileList[newIndex]; // + 1 index because default value
            let currentId = currentItem && currentItem.uid;
            let nodeWrap = document.createElement("div");
            let node = document.createElement("input");
            node.type = "checkbox";
            node.checked = !!this.state.mainImg[currentId];
            nodeWrap.className = "custom-checkbox";
            nodeWrap.appendChild(node);
            i.appendChild(nodeWrap);
            nodeWrap.onchange = () => {
              this.setState(
                {
                  mainImg: {
                    [currentId]: !this.state.mainImg[currentId],
                  },
                },
                () => {
                  let currentMainImg = this.state.mainImg;
                  let id = null;
                  if (currentMainImg) {
                    for (let [key, value] of Object.entries(currentMainImg)) {
                      if (value) {
                        id = key;
                      }
                    }
                  }
                  if (onChangeMainImg) {
                    onChangeMainImg(id ? id : null);
                  }
                },
              );
            };
          });
        }
      }
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    const { files, fileUpload, fileMediaLibrary } = this.state;
    const fileTotal = [...fileUpload, ...fileMediaLibrary, ...files].filter(
      (e, index) =>
        [...fileUpload, ...fileMediaLibrary, ...files].indexOf(e) === index,
    );
    this.setState(
      {
        files: [],
        visible: false,
        fileList: this.getFileList(fileTotal),
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(fileTotal);
        }
      },
    );
  };

  getFileList = (files = []) => {
    let { mainImg, isMockup, isCompareDesign, designPosition } = this.props;
    return files.filter(Boolean).map((file, index) => {
      let isDone = false;

      if (mainImg) {
        isDone = mainImg === file.id;
      } else {
        isDone = index === 0;
      }

      if (file) {
        this.imageLoadHandler(
          isCompareDesign,
          file.url,
          file.id,
          designPosition,
        );
      }

      return {
        file: file,
        uid: file.id,
        name: file.name,
        status: isMockup && isDone ? "done main-img" : "done",
        url: file.createdAt
          ? loadThumbnailImage(file)
          : file.thumbnailUrl
          ? file.thumbnailUrl
          : file.url,
      };
    });
  };

  customRequest = async (options, client, createUploadUrl) => {
    const { folder, isCompareDesign, designPosition } = this.props;
    const { file, onSuccess, onError } = options;
    try {
      const res = await createUploadUrl({
        variables: {
          input: {
            name: file.name,
            mimeType: file.type,
            size: file.size,
            folder: folder ? folder : null,
          },
        },
      });
      const { key, url } = res.data.createUploadUrl;
      await axios.put(url, file, {
        headers: { "Content-Type": file.type },
        timeout: 1000 * 60 * 30,
      });
      const fileResponse = await client.mutate({
        mutation: CREATE_FILE_MUTATION,
        variables: {
          input: {
            name: file.name,
            key: key,
            size: file.size,
            mimeType: file.type,
            isPrintFile: this.props.isPrintFile,
          },
        },
      });
      onSuccess(fileResponse.data.createFile);

      // Check dimension image
      if (fileResponse?.data?.createFile) {
        let file = fileResponse.data.createFile;
        this.imageLoadHandler(
          isCompareDesign,
          file.url,
          file.id,
          designPosition,
        );
      }
    } catch (e) {
      onError(e);
    }
  };

  setCache = (client, values) => {
    let { userId } = this.props;
    const filter = {
      search: null,
      mimeType: [],
      dateFilter: {
        from: null,
        to: null,
      },
      limit: 20,
      offset: 0,
      prefix: this.props.prefix,
      ...(userId ? { userId } : null),
    };
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total + values.length,
            nodes: [...values, ...cache.mediaLibrary.nodes],
          },
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  handleChangeOkBtnDisabled = (value) => {
    let { activeTab } = this.state;
    this.setState({
      okBtnDisabled: "1" === activeTab ? value : false,
    });
  };

  // Check dimension image
  imageLoadHandler = (isCompareDesign, originUrl, imgIndex, designPosition) => {
    let { onCheckDimensions, onCheckingDimensions } = this.props;
    if (isCompareDesign) {
      let img = new Image();
      img.src = originUrl;
      if (onCheckingDimensions) {
        onCheckingDimensions(true);
      }
      img.onload = () => {
        let newDimensions = this.state.dimensions;
        if (!newDimensions[imgIndex]) {
          let height = img && img.height ? img.height : "";
          let width = img && img.width ? img.width : "";

          newDimensions[imgIndex] = {
            id: imgIndex,
            height: height,
            width: width,
            isRealDimensionOk: this.checkRealDimension(
              designPosition,
              width,
              height,
            ),
          };
          this.setState({ dimensions: newDimensions }, () => {
            if (onCheckDimensions) {
              onCheckDimensions(newDimensions);
            }
          });
        }
        if (img?.complete && img?.naturalHeight !== 0) {
          if (onCheckingDimensions) {
            onCheckingDimensions(false);
          }
        }
      };
    }
  };

  checkRealDimension = (designPosition, realWidth, realHeight) => {
    let realDmOk = false;
    if (
      designPosition &&
      designPosition.description &&
      designPosition.description.length > 0 &&
      realWidth &&
      realHeight
    ) {
      let originDesc = designPosition.description;

      realWidth = parseInt(realWidth);
      realHeight = parseInt(realHeight);

      if (originDesc) {
        let splitDesc = originDesc.split("|");
        if (
          Array.isArray(splitDesc) &&
          splitDesc[0] &&
          splitDesc[0].length > 0
        ) {
          let dimensionDesc = splitDesc[0];

          // Case: no dimension
          const dimensionValid = /px|cm|in/i;
          if (!dimensionValid.test(dimensionDesc)) {
            return true;
          }

          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/px/g, "");
          dimensionDesc = dimensionDesc.replace(/cm/g, "");
          dimensionDesc = dimensionDesc.replace(/\s+/g, "");
          if (dimensionDesc.length > 0) {
            let dmDesc = dimensionDesc.split("x");
            const likeX = "×";
            if (Array.isArray(dmDesc) && 2 !== dmDesc.length) {
              dmDesc = dimensionDesc.split(likeX);
            }
            if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
              let splitWidth = dmDesc && dmDesc[0] ? parseInt(dmDesc[0]) : 0;
              let splitHeight = dmDesc && dmDesc[1] ? parseInt(dmDesc[1]) : 0;
              if (
                splitWidth > 0 &&
                splitHeight > 0 &&
                splitWidth === realWidth &&
                splitHeight === realHeight
              ) {
                realDmOk = true;
              }
            }
          }
        }
      }
    }
    return realDmOk;
  };

  onCancel = () => {
    this.setState({ previewVisible: false });
  };

  render() {
    const {
      buttonType,
      listType,
      multiple,
      singleUpload,
      accept,
      width,
      name,
      size,
      placeholderImage,
      isMockup,
      isUploadDesign,
    } = this.props;

    const {
      visible,
      files,
      previewImage,
      previewUrl,
      previewVisible,
      isTypeImage,
      currentFile,
    } = this.state;
    let disabled = false;
    if (!multiple && this.state.fileList.length) {
      disabled = true;
    }

    return (
      <Container
        size={size}
        width={width}
        files={files}
        isMockup={isMockup}
        className={`container ${disabled ? "disabled" : "enabled"}`}
        placeholderImage={placeholderImage}
      >
        <div className="w-full">
          <Mutation mutation={REQUEST_MUTATION}>
            {(createUploadUrl, { client }) => (
              <React.Fragment>
                <Upload.Dragger
                  style={{ cursor: "default" }}
                  onChange={(e) => {
                    let newValue = this.state.value;

                    // Check dimension image.
                    let { isCompareDesign, designPosition, onCheckDimensions } =
                      this.props;
                    for (let i = 0; i < e.fileList.length; i++) {
                      if (e.fileList[i].response) {
                        newValue.push(e.fileList[i].response);
                        let file = e.fileList[i].response;
                        this.imageLoadHandler(
                          isCompareDesign,
                          file.url,
                          file.id,
                          designPosition,
                        );
                      }
                    }

                    // Check dimension image.
                    if (isUploadDesign) {
                      if (!e.fileList.length) {
                        newValue = [];
                      }
                    }

                    this.setState(
                      { fileList: e.fileList, value: newValue },
                      () => {
                        if (this.props.onChange) {
                          // Check dimension image.
                          let result;
                          if (isUploadDesign) {
                            result = newValue;
                          } else {
                            const files = this.state.fileList
                              .filter((file) => file.status.includes("done"))
                              .map((item) => item.file);

                            result = newValue.length
                              ? [...files, ...newValue]
                              : this.state.fileList
                                  // .filter((file) => file.status === "done")
                                  .filter((file) =>
                                    file.status.includes("done"),
                                  )
                                  .map((item) => item.file);
                          }
                          this.props.onChange(result.filter(Boolean));
                        }
                        if (onCheckDimensions) {
                          onCheckDimensions(this.state.dimensions);
                        }
                      },
                    );
                  }}
                  onPreview={this.handlePreview}
                  fileList={this.state.fileList}
                  listType={listType}
                  openFileDialogOnClick={false}
                  customRequest={(options) =>
                    this.customRequest(options, client, createUploadUrl)
                  }
                  showUploadList={true}
                  accept={accept}
                  singleUpload={singleUpload}
                  multiple={multiple}
                  onRemove={(e) => {
                    let id = e?.response?.id;
                    if (this.props.onRemove && id) {
                      this.props.onRemove(id);
                    }
                  }}
                >
                  {!disabled && (
                    <div className={"description"}>
                      <Button
                        size="small"
                        type={buttonType ? buttonType : "link"}
                        onClick={this.showModal}
                      >
                        {name ? name : "Media Library"}
                      </Button>
                      <p className="p-size">Or drop image to upload</p>
                    </div>
                  )}
                </Upload.Dragger>

                {/* Get mutation gen thumbnail image */}
                <Mutation mutation={GEN_THUMBNAIL_IMAGE}>
                  {(genThumbnail) => {
                    this.genThumbnail = genThumbnail;
                    return null;
                  }}
                </Mutation>

                {visible && (
                  <Modal
                    style={{ top: 30 }}
                    okText={"Set File"}
                    okButtonProps={{
                      loading: this.state.loading,
                      disabled: this.state.okBtnDisabled,
                    }}
                    onOk={() => {
                      let value = this.props.value ? this.props.value : [];
                      const files = this.state.value;
                      let v = [];
                      for (let i = 0; i < files.length; i++) {
                        if (!value.find((f) => f.id === files[i].id)) {
                          v.push(files[i]);
                        }
                      }

                      let newValue = [...value, ...v];
                      if (this.props.hasDefaultMockup) {
                        newValue = [...v, ...value];
                      }
                      // let newValue = [...v];

                      let importUrls = [];
                      let mapUrl = {};
                      this.state.importUrls.split("\n").forEach((line) => {
                        const url = line.trim();
                        if (url) {
                          if (!mapUrl[url]) {
                            importUrls.push(url);
                            mapUrl[url] = true;
                          }
                        }
                      });

                      // isComment
                      let { isComment } = this.props;
                      if (importUrls.length) {
                        this.setState(
                          {
                            loading: true,
                          },
                          () => {
                            client
                              .mutate({
                                mutation: IMPORT_FILE_URLS_MUTATION,
                                variables: {
                                  urls: importUrls,
                                  prefix: this.props.prefix,
                                },
                              })
                              .then((res) => {
                                this.setCache(client, res.data.importFileUrl);
                                const values = [
                                  ...res.data.importFileUrl,
                                  ...newValue,
                                ];
                                this.setState({
                                  loading: false,
                                  importUrls: "",
                                  fileList: this.props.multiple
                                    ? this.getFileList(values)
                                    : this.getFileList(
                                        values.length ? [values[0]] : [],
                                      ),
                                  value: [],
                                  visible: false,
                                });
                                this.props.onChange(
                                  this.props.multiple
                                    ? values
                                    : values.length
                                    ? [values[0]]
                                    : [],
                                );
                              })
                              .catch((err) => {
                                notification.error({
                                  message: handleError(err.toString()),
                                });
                                this.setState({
                                  loading: false,
                                });
                              });
                          },
                        );
                      } else {
                        this.setState(
                          {
                            fileList: this.getFileList(
                              isComment && newValue.length > 1
                                ? [newValue[1]]
                                : newValue,
                            ),
                            value: [],
                            visible: false,
                          },
                          () => {
                            if (this.props.multiple) {
                              this.props.onChange(newValue);
                            } else {
                              if (isComment) {
                                this.props.onChange(
                                  newValue.length > 1
                                    ? [newValue[1]]
                                    : newValue,
                                );
                              } else {
                                this.props.onChange(
                                  newValue && newValue.length
                                    ? [newValue[0]]
                                    : newValue,
                                );
                              }
                            }
                          },
                        );
                      }
                    }}
                    title={"Media"}
                    bodyStyle={{ margin: 0, padding: 0 }}
                    width={"70%"}
                    onCancel={() => {
                      this.setState({ visible: false, importUrls: "" });
                    }}
                    visible={true}
                  >
                    <Media
                      isPrintFile={this.props.isPrintFile}
                      userId={this.props.userId}
                      accept={this.props.accept}
                      prefix={this.props.prefix}
                      folder={this.props.folder}
                      multiple={this.props.multiple}
                      value={this.state.value}
                      handleChangeOkBtnDisabled={this.handleChangeOkBtnDisabled}
                      activeTab={(key) => {
                        this.setState({
                          activeTab: key,
                          okBtnDisabled:
                            key !== "1" ? false : this.state.okBtnDisabled,
                        });
                      }}
                      onChange={(files) => {
                        this.setState({ value: files });
                      }}
                      onImportUrlsChange={(v) => {
                        this.setState({
                          importUrls: v,
                        });
                      }}
                    />
                  </Modal>
                )}
              </React.Fragment>
            )}
          </Mutation>
        </div>

        {/* <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => this.setState({ previewVisible: false })}
        >
          {isTypeImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                alt={""}
                style={{ width: "100%", padding: "15px 5px 5px 5px" }}
                src={previewImage}
              />
              <CustomLinkPolaris href={previewImage}>
                View origin
              </CustomLinkPolaris>
            </div>
          ) : (
            <div style={TagAWrap}>
              <CustomLinkPolaris
                href={
                  currentFile && currentFile.url
                    ? currentFile.url
                    : currentFile.thumbnailUrl
                }
              >
                {currentFile && currentFile.name ? currentFile.name : null}
              </CustomLinkPolaris>
            </div>
          )}
        </Modal> */}
        <PreviewFile
          visible={previewVisible}
          onCancel={this.onCancel}
          isTypeImage={isTypeImage}
          previewImage={previewImage}
          previewUrl={previewUrl}
          currentFile={currentFile}
        />
      </Container>
    );
  }
}

const TagAWrap = {
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function PreviewFile({
  visible,
  onCancel,
  isTypeImage,
  previewImage,
  currentFile,
  previewUrl,
}) {
  const [visibleOrigin, setVisibleOrigin] = React.useState(false);

  // Context
  const { currentUser } = useAppContext();

  const isViewOriginFile = !!currentUser?.viewOriginFile;
  const handleToggleVisibleOrigin = React.useCallback(() => {
    setVisibleOrigin((p) => !p);
  }, []);

  const btnMarkup = isViewOriginFile ? (
    <CustomLinkPolaris href={previewImage}>View origin</CustomLinkPolaris>
  ) : (
    <Button
      children="View origin"
      onClick={handleToggleVisibleOrigin}
      type="link"
    />
  );

  return (
    <>
      <Modal visible={visible} footer={null} onCancel={onCancel}>
        {isTypeImage ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            className="preview-file"
          >
            <img
              alt={""}
              style={{ width: "100%", padding: "15px 5px 5px 5px" }}
              src={previewImage}
            />
            {btnMarkup}
          </div>
        ) : (
          <div style={TagAWrap}>
            <CustomLinkPolaris
              href={
                currentFile && currentFile.url
                  ? currentFile.url
                  : currentFile.thumbnailUrl
              }
            >
              {currentFile && currentFile.name ? currentFile.name : null}
            </CustomLinkPolaris>
          </div>
        )}
      </Modal>
      <ViewOriginFile
        visible={visibleOrigin}
        onCancel={handleToggleVisibleOrigin}
        url={previewUrl}
      />
    </>
  );
}

function ViewOriginFile({ url, visible, onCancel }) {
  return (
    <Modal
      width="100vw"
      visible={visible}
      onCancel={onCancel}
      footer={false}
      style={{
        width: "100vw",
        height: "100vh",
        top: 0,
      }}
      bodyStyle={{
        overflow: "scroll",
      }}
    >
      <div className="view-origin-file">
        <img
          alt="view-original-file"
          style={{ width: "auto", height: "auto", padding: 5 }}
          src={url}
        />
      </div>
    </Modal>
  );
}

MediaSelectorButton.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  buttonType: PropTypes.any,
  listType: PropTypes.string,
  multiple: PropTypes.bool,
  singleUpload: PropTypes.bool,
  folder: PropTypes.string,
  prefix: PropTypes.string,
  accept: PropTypes.string,
  isPrintFile: PropTypes.bool,
};
export default MediaSelectorButton;
