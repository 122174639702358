import React, { createContext, useContext, useMemo, useState } from "react";
import moment from "moment";

const ReportsContext = createContext(null);

export function ReportsContextProvider({ children = null }) {
    // State
    const [range, setRange] = useState({
        from: moment().startOf("day"),
        to: moment().endOf("day"),
    });

    const [filter, setFilter] = useState({
        productBaseIds: null,
        collectionIds: null,
        tagIds: null,
        storeID: null,
        storeManagerID: null,
    });

    const [rangeToCompare, setRangeToCompare] = useState({
        from: null,
        to: null,
    });
    const [isCompare, setIsCompare] = useState(null);
    const [loading, setLoading] = useState(false);
    const [teamID, setTeamID] = useState(null);

    // Provider
    const bag = useMemo(
        () => ({
            range,
            setRange,
            filter,
            setFilter,
            rangeToCompare,
            setRangeToCompare,
            isCompare,
            setIsCompare,
            loading,
            setLoading,
            teamID,
            setTeamID
        }),
        [
            range,
            setRange,
            filter,
            setFilter,
            rangeToCompare,
            setRangeToCompare,
            isCompare,
            setIsCompare,
            loading,
            setLoading,
            teamID,
            setTeamID
        ]
    );

    return (
        <ReportsContext.Provider value={bag}>
            {children}
        </ReportsContext.Provider>
    );
}

export function useReportsContext() {
    return useContext(ReportsContext);
}
