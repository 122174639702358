import { useLazyQuery } from "@apollo/react-hooks";
import { Heading, Modal, Select, Button } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GET_MAPPING_PRODUCT_SUGGEST } from "../../graphql/queries";
import { MapOrderCTPolaris } from "./MapOrderCTPolaris";
import { MapOrderEPPolaris } from "./MapOrderEPPolaris";
import { MapOrderPolaris } from "./MapOrderPolaris";
import { MappingSuggestionsPolaris } from "./MappingSuggestionsPolaris";
import { ProductDataWrap, MetaDataWrap } from "./TableMOPolaris";

const MAP_TO = {
  ProductBase: "Product Base",
  ExistsProduct: "An Exists Product",
  ToCampaignTemplate: "To Campaign Template",
};

const ContainerModal = styled.div`
  .suggestion-wrap {
    margin-bottom: 1.6rem;
    .Polaris-Banner {
      border-radius: var(--p-border-radius-wide, 0 0 3px 3px);
      box-shadow: var(
        --p-banner-border,
        inset 0 3px 0 0 var(--p-override-none, #47c1bf),
        inset 0 0 0 0 transparent,
        0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15)
      );
      .Polaris-Button {
        background: linear-gradient(to bottom, #6371c7, #5563c1);
        border-color: #3f4eae;
        box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
          0 0 0 0 transparent;
        color: white;
        min-height: 3.3rem;
      }
    }
  }

  .meta-data-wrap,
  .product-data-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    padding-left: 0.5rem;
  }

  .origin-data {
    display: flex;
    flex-direction: row;
    row-gap: 0.75rem;
    padding-bottom: 1rem;
    padding-left: 1rem;

    max-height: 82px;
    overflow: hidden;
    box-shadow: inset 0 -2rem 2rem -2rem
      var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1));
    animation-duration: 150ms;
    animation-name: hideOriginData;

    &.view-more {
      box-shadow: none;
      max-height: none;
      animation-name: showOriginData;
      animation-duration: 250ms;
    }

    @keyframes showOriginData {
      0% {
        max-height: 82px;
      }
      25% {
        max-height: 120px;
      }
      50% {
        max-height: 140px;
      }
      75% {
        max-height: 150px;
      }
      100% {
        max-height: none;
      }
    }

    @keyframes hideOriginData {
      0% {
        max-height: none;
      }
      25% {
        max-height: 160px;
      }
      50% {
        max-height: 140px;
      }
      75% {
        max-height: 120px;
      }
      100% {
        max-height: 82px;
      }
    }

    > * {
      width: 50%;
    }
  }
`;

export const MappingOrderActionPolaris = ({
  open,
  toggleModal,
  item,
  onCompleted,
}) => {
  const needVariantMapping = item?.needVariantMapping;
  const needMappingExistProduct = item?.needMappingExistProduct;
  const existProductID = item?.existProductID;

  const [canSetExistProduct, setCanSetExistProduct] = useState(false);

  const [suggestMappingProduct, setSuggestMappingProduct] = useState(null);

  const [selected, setSelected] = useState(MAP_TO.ProductBase);
  const [removeLabel, setRemoveLabel] = useState(false);
  const [dismiss, setDismiss] = useState(false);

  const [mappingSuggestions, setMappingSuggestions] = useState([]);
  const [sheetActive, setSheetActive] = useState(false);
  const [productSelected, setProductSelected] = useState(null);

  const [getProductSuggest, { data: dataSuggest }] = useLazyQuery(
    GET_MAPPING_PRODUCT_SUGGEST,
    {
      variables: {
        mappingId: item.id,
      },
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (open) {
      getProductSuggest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  useEffect(() => {
    const canSetExistProduct =
      !!needMappingExistProduct && existProductID != null;
    setCanSetExistProduct(canSetExistProduct);
  }, [needMappingExistProduct, existProductID]);

  useEffect(() => {
    if (dataSuggest?.getMappingProductSuggest != null) {
      setSuggestMappingProduct(dataSuggest.getMappingProductSuggest);
    }
  }, [dataSuggest]);

  const selectedRef = useRef(false);
  useEffect(() => {
    if (selectedRef.current) return;
    const condition = !!suggestMappingProduct || canSetExistProduct;
    if (condition) {
      setSelected(MAP_TO.ExistsProduct);
      selectedRef.current = true;
    }
  }, [suggestMappingProduct, canSetExistProduct]);

  useEffect(() => {
    if (needVariantMapping) {
      setSelected(() =>
        needVariantMapping ? MAP_TO.ExistsProduct : MAP_TO.ProductBase,
      );
    }
  }, [needVariantMapping]);

  const handleSelectedChange = useCallback((value) => {
    setSelected(value);
  }, []);

  const toggleSheetActive = useCallback(
    () => setSheetActive((prevState) => !prevState),
    [],
  );

  const options = Object.entries(MAP_TO).map(([_key, value]) => ({
    label: value,
    value: value,
  }));

  if (item) {
    item.variants =
      (item.variants &&
        item.variants.map((v) =>
          v.legacyResourceId
            ? {
                ...v,
                id: v.legacyResourceId,
                attributes: v.selectedOptions.map((s) => ({
                  ...s,
                  option: s.value,
                })),
              }
            : v,
        )) ||
      [];
  }

  let totalSuggest = 0;
  let msNodes = [];
  if (mappingSuggestions && mappingSuggestions.getMappingSuggestions) {
    totalSuggest = mappingSuggestions.getMappingSuggestions.total;
    msNodes = mappingSuggestions.getMappingSuggestions.nodes;
    if (dataSuggest?.setSuggestMappingProduct != null) {
      msNodes =
        msNodes &&
        msNodes.filter((i) => i.id !== dataSuggest.setSuggestMappingProduct.id);
      totalSuggest = msNodes && msNodes.length;
    }
  }

  const handleSelected = useCallback(
    (value) => {
      let currentP = msNodes && msNodes.find((i) => i.id === value);
      if (currentP) {
        setProductSelected((prevState) => {
          if ((prevState && prevState.id !== currentP.id) || !prevState) {
            setRemoveLabel(true);
          } else {
            setRemoveLabel(false);
          }
          return currentP;
        });
        setSheetActive(() => false);
        setSuggestMappingProduct(() => ({ ...currentP }));
      }
    },
    [msNodes],
  );

  // Handle action
  const handleDismiss = useCallback((value) => {
    setDismiss(value);
  }, []);

  const handleClose = useCallback(() => {
    if (!dismiss) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismiss]);
  const handleRemoveCurrentProduct = useCallback(() => {
    setCanSetExistProduct(false);
    setSelected(MAP_TO.ExistsProduct);
  }, []);

  const originDataRef = React.useRef(null);
  const [viewMore, setViewMore] = React.useState(false);
  const handleViewMore = React.useCallback(() => {
    setViewMore((p) => !p);
  }, []);

  return (
    <Modal
      title={"Mapping Order" + (item?.orderId ? " #" + item?.orderId : "")}
      open={open}
      onClose={handleClose}
      sectioned
      large
    >
      <ContainerModal>
        {item ? <OriginData item={item} /> : null}
        <MappingSuggestionsPolaris
          sheetActive={sheetActive}
          toggleSheetActive={toggleSheetActive}
          products={msNodes}
          productSelected={productSelected}
          onChange={handleSelected}
        />

        <Select
          label="Map to"
          value={selected}
          options={options}
          onChange={handleSelectedChange}
          disabled={canSetExistProduct}
        />
        {[MAP_TO.ProductBase].includes(selected) && (
          <MapOrderPolaris
            item={item}
            onCompleted={onCompleted}
            mappingSuggestions={mappingSuggestions}
            onDismiss={handleDismiss}
          />
        )}
        {[MAP_TO.ExistsProduct].includes(selected) && (
          <MapOrderEPPolaris
            item={item}
            onCompleted={onCompleted}
            suggestMappingProduct={suggestMappingProduct}
            removeLabel={removeLabel}
            onDismiss={handleDismiss}
            needVariantMapping={needVariantMapping}
            canSetExistProduct={canSetExistProduct}
            handleRemoveCurrentProduct={handleRemoveCurrentProduct}
          />
        )}
        {[MAP_TO.ToCampaignTemplate].includes(selected) && (
          <MapOrderCTPolaris
            item={item}
            onCompleted={onCompleted}
            onDismiss={handleDismiss}
          />
        )}
      </ContainerModal>
    </Modal>
  );
};

function OriginData({ item }) {
  const [viewMore, setViewMore] = React.useState(false);
  const handleViewMore = React.useCallback(() => {
    setViewMore((p) => !p);
  }, []);

  return (
    <>
      <Heading element="h2" children="Origin Data" />
      <div className={"origin-data" + (viewMore ? " view-more" : "")}>
        <div>
          <Heading element="h3">Product Data</Heading>
          <ProductDataWrap node={item} />
        </div>
        <div className="meta-data-wrap">
          <Heading element="h3">Meta Data</Heading>
          <MetaDataWrap data={item.metaData} />
        </div>
      </div>

      <Button disclosure plain children="View more" onClick={handleViewMore} />
      <hr />
    </>
  );
}
