import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  Form,
  FormLayout,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { CREATE_SHOPIFY_STORE } from "../../graphql/mutations";
import { handleError, validateUrl } from "../../helper";
import history from "../../history";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { IgnorePolaris } from "./IgnorePolaris";
import { OwnersPolaris } from "./OwnersPolaris";

const CREATE_STORE = gql`
  mutation createStore($input: NewStore!) {
    createStore(input: $input)
  }
`;

const platforms = [
  {
    label: "Woocommerce",
    value: "woocommerce",
  },
  {
    label: "Shopify",
    value: "shopify",
  },
  {
    label: "Shopbase",
    value: "shopbase",
  },
];

const validateDomain = {
  shopify: ".myshopify.com",
  shopbase: ".onshopbase.com",
};

const Container = styled.div`
  .Polaris-ButtonGroup {
    flex-direction: row-reverse;
  }
  .checkbox-wrap {
    margin-top: -1rem;
  }
`;

export const AddStoreFormPolaris = ({ listUser, error }) => {
  const [title, setTitle] = useState(null);
  const [domain, setDomain] = useState(null);
  const [shopbasePublicDomain, setSBPublicDomain] = useState(null);
  const [shopifyPublicDomain, setSFPublicDomain] = useState(null);
  const [selectDomain, setSelectDomain] = useState("https://");
  const [platform, setPlatform] = useState(["woocommerce"]);
  const [owners, setOwners] = useState([]);
  const [ignoreMapping, setIgnoreMapping] = useState({});
  const [useVariants, setUseVariants] = useState(false);
  const [note, setNote] = useState(null);
  const [errors, setErrors] = useState({
    title: null,
    domain: null,
    shopbasePublicDomain: null,
    shopifyPublicDomain: null,
    accessToken: null,
  });
  const [accessToken, setAccessToken] = useState("");

  const [noti, setNoti] = useState({
    active: false,
    msg: null,
    err: false,
  });

  const timeoutRef = useRef(null);

  const [createStore, { loading }] = useMutation(CREATE_STORE);
  const [createShopifyStore, { loading: loadingS }] = useMutation(
    CREATE_SHOPIFY_STORE,
    {
      onCompleted: () => {
        setNoti((p) => ({ ...p, msg: "Create store success", err: false }));
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          history.push("/seller/stores");
        }, 1500);
      },
      onError: (err) => {
        setNoti((p) => ({ ...p, msg: handleError(err.toString()), err: true }));
      },
    }
  );

  const handleValueTitle = (value) => {
    setErrors((prevState) => {
      let t = null;
      if (!value) {
        t = "Please input title.";
      }
      return {
        ...prevState,
        title: t,
      };
    });
  };

  const handleValueDomain = (value) => {
    setErrors((prevState) => {
      let d = null;
      if (value) {
        if (!validateUrl(value)) {
          d = "Invalidate domain.";
        } else {
          let pl = platform && platform.length > 0 ? platform[0] : null;
          if (pl === "shopify" && !value.includes(validateDomain.shopify)) {
            d = (
              <span>
                Your domain must include <b>{validateDomain.shopify}</b>
              </span>
            );
          }
          if (pl === "shopbase" && !value.includes(validateDomain.shopbase)) {
            d = (
              <span>
                Your domain must include <b>{validateDomain.shopbase}</b>
              </span>
            );
          }
        }
      } else {
        d = "Please enter domain.";
      }
      return {
        ...prevState,
        domain: d,
      };
    });
  };

  const handleValueSBPublicDomain = (value) => {
    setErrors((prevState) => {
      let d = null;
      if (value) {
        if (!validateUrl(value)) {
          d = "Invalidate public domain.";
        }
      }
      return {
        ...prevState,
        shopbasePublicDomain: d,
      };
    });
  };

  const handleValueSFPublicDomain = (value) => {
    setErrors((prevState) => {
      let d = null;
      if (value) {
        if (!validateUrl(value)) {
          d = "Invalidate public domain.";
        }
      }
      return {
        ...prevState,
        shopifyPublicDomain: d,
      };
    });
  };

  const handleValueAccessToken = (value) => {
    setErrors((p) => {
      let v = null;
      if (!value) {
        v = "Access token is required";
      }

      return {
        ...p,
        accessToken: v,
      };
    });
  };

  const handleTitleChange = useCallback((value) => {
    handleValueTitle(value);
    setTitle(value);
  }, []);

  const handleDomainChange = useCallback(
    (value) => {
      handleValueDomain(value);
      setDomain(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [platform]
  );

  const handleSBPublicDomainChange = useCallback((value) => {
    handleValueSBPublicDomain(value);
    setSBPublicDomain(value);
  }, []);

  const handleSFPublicDomainChange = useCallback((value) => {
    handleValueSFPublicDomain(value);
    setSFPublicDomain(value);
  }, []);

  const handleAccessTokenChange = useCallback((val) => {
    setAccessToken(val);
    handleValueAccessToken(val);
  }, []);

  const handleSelectDomainChange = useCallback((value) => {
    setSelectDomain(value);
    setErrors((p) => ({
      ...p,
      shopifyPublicDomain: null,
      accessToken: null,
    }));
  }, []);
  const handlePlatformChange = useCallback((value) => {
    setPlatform(value);
    setDomain(null);
  }, []);
  const handleUseVariants = useCallback(
    () => setUseVariants((useVariants) => !useVariants),
    []
  );
  const handleOwners = useCallback((value) => {
    setOwners(value);
  }, []);

  const handleIgnoreMapping = useCallback((value) => {
    setIgnoreMapping(value);
  }, []);

  const handleNoteChange = useCallback((value) => setNote(value), []);

  const handleSubmit = useCallback(() => {
    handleValueTitle(title);
    handleValueDomain(domain);

    let newPlatform = _.head(platform);
    const isShopify = newPlatform === "shopify";
    if (isShopify) {
      handleValueAccessToken(accessToken);
    }
    let count = _.size(_.filter(_.values(errors), (e) => e !== null));

    if (title && domain && selectDomain && newPlatform && count === 0) {
      let fullDomain = `${selectDomain}${domain}`;
      fullDomain = fullDomain.toLowerCase();
      const domainLength = fullDomain.length;
      if (fullDomain[domainLength - 1] === "/") {
        fullDomain = fullDomain.slice(0, -1);
      }
      let input = {
        title,
        domain: fullDomain,
        shopbasePublicDomain,
        shopifyPublicDomain,
        platform: newPlatform,
        owners,
        note,
        ignoreMapping,
        ...(_.includes(platform, "woocommerce")
          ? { usingFakeVariants: useVariants }
          : null),
        accessToken: isShopify ? accessToken : undefined,
      };
      const variables = {
        input,
      };

      if (isShopify) {
        setNoti((p) => ({ ...p, active: true, msg: null, err: false }));
        createShopifyStore({
          variables,
        });
      } else {
        createStore({
          variables,
        })
          .then((res) => {
            window.location.href = res.data.createStore;
          })
          .catch((_) => {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    domain,
    selectDomain,
    platform,
    owners,
    ignoreMapping,
    errors,
    useVariants,
    shopifyPublicDomain,
    shopbasePublicDomain,
    note,
    accessToken,
  ]);

  const toastMarkup = noti.active && noti.msg && (
    <Toast
      content={noti.msg}
      error={noti.err}
      duration={1400}
      onDismiss={() => setNoti((p) => ({ ...p, active: false }))}
    />
  );

  return (
    <Container>
      {toastMarkup}
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <div>
            <ComponentLabelPolaris label="Title" required />
            <TextField
              // label="Title(*)"
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Title"
              error={errors.title}
            />
          </div>
          <div>
            <ComponentLabelPolaris label="Domain" required />
            <TextField
              // label="Domain(*)"
              type="text"
              value={domain}
              onChange={handleDomainChange}
              error={errors.domain}
              connectedLeft={
                <Select
                  label="Select domain"
                  labelHidden
                  value={selectDomain}
                  onChange={handleSelectDomainChange}
                  options={["http://", "https://"]}
                />
              }
            />
          </div>
          <div>
            <ComponentLabelPolaris label="Platform" required />
            <ChoiceList
              // title="Platform(*)"
              choices={platforms}
              selected={platform}
              onChange={handlePlatformChange}
            />
          </div>

          {_.includes(platform, "woocommerce") && (
            <div className="checkbox-wrap">
              <Checkbox
                label="Store using fake variants"
                checked={useVariants}
                onChange={handleUseVariants}
              />
            </div>
          )}
          {_.includes(platform, "shopify") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.6rem",
              }}
            >
              <div>
                <ComponentLabelPolaris label="Shopify access token" required />
                <TextField
                  type="text"
                  value={accessToken}
                  onChange={handleAccessTokenChange}
                  placeholder="Enter access token"
                  error={errors.accessToken}
                />
              </div>
              <TextField
                label="Shopify public domain"
                type="text"
                value={shopifyPublicDomain}
                onChange={handleSFPublicDomainChange}
                placeholder="Enter public domain"
                error={errors.shopifyPublicDomain}
              />
            </div>
          )}
          {_.includes(platform, "shopbase") && (
            <TextField
              label="Shopbase public domain"
              type="text"
              value={shopbasePublicDomain}
              onChange={handleSBPublicDomainChange}
              placeholder="Enter public domain"
              error={errors.shopbasePublicDomain}
            />
          )}
          <OwnersPolaris
            listUser={listUser}
            error={error}
            onChange={(v) => handleOwners(v)}
          />
          <TextField
            label="Note"
            type="text"
            value={note}
            onChange={handleNoteChange}
            placeholder="Enter note"
            multiline={4}
          />
          <IgnorePolaris onChange={(value) => handleIgnoreMapping(value)} />
          <ButtonGroup>
            <Button primary submit loading={loading || loadingS}>
              Add store
            </Button>
            <Button onClick={() => history.push("/seller/stores")}>
              Cancel
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Form>
    </Container>
  );
};
