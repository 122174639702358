import React from "react";
import {
  Form,
  Loading,
  Page,
  FormLayout,
  TextField,
  ButtonGroup,
  Button,
  Card,
} from "@shopify/polaris";
import { useAppContext } from "../../context";
import { WrapperPage } from "../shared/WrapperPage";
import { checkRole, getParamByRole, handleError } from "../../helper";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { ConnectTiktokModal } from "../tiktok/AddTiktokAccount";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { useToastContext } from "../shared/ToastContext";
import { UPDATE_STORE } from "../../graphql/mutations";
import { useHistory } from "react-router-dom";

const STORE_BY_ID = gql`
  query storeById($id: ID!) {
    storeById(id: $id) {
      id
      title
      domain
    }
  }
`;

export default function SwitchToAPIStorePage({ match }) {
  const { id } = match.params;

  // Context
  const { setNotify, toggleToast } = useToastContext();
  const { currentUser } = useAppContext();
  const { isMarketplaceManager } = checkRole(currentUser);
  const param = getParamByRole(currentUser);
  const history = useHistory();

  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    name: "",
    email: "",
    "shop Code": "",
    useAPI: false,
    openModal: false,
    id: "",
    canRedirect: true,
  });
  const [errors, setErrors] = React.useReducer((p, s) => ({ ...p, ...s }), {});

  const { data, loading } = useQuery(STORE_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [updateAccount, { loading: l2 }] = useMutation(UPDATE_STORE, {
    onCompleted: () => {
      setNotify({ msg: "Update Tiktok account success", err: false });
      setState({ openModal: true, canRedirect: true });
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
      setState({ openModal: false, canRedirect: false });
    },
  });

  React.useEffect(() => {
    if (data?.storeById) {
      const { title, id } = data.storeById;
      setState({ name: title, id });
    }
  }, [data?.storeById]);

  const redirectRef = React.useRef(null);
  const redirectFn = React.useCallback(() => {
    redirectRef.current && clearTimeout(redirectRef.current);
    redirectRef.current = setTimeout(() => {
      history.push(`/${param}/stores/tiktok`);
    }, 2000);
  }, [history, param]);

  const validateField = React.useCallback((value, id) => {
    let error = null;
    let label = id.charAt(0).toUpperCase() + id.slice(1);

    if (!value || !value.length) {
      error = `${label} is required.`;
    }
    setErrors({ [id]: error });
  }, []);

  const inputRef = React.useRef(null);
  const handleFieldChange = React.useCallback((value, id) => {
    setState({ [id]: value });

    inputRef.current && clearTimeout(inputRef.current);
    inputRef.current = setTimeout(() => {
      validateField(value, id);
    }, 350);
  }, []);

  const onCloseModal = React.useCallback(() => {
    setState({ openModal: false });

    if (state.canRedirect) {
      redirectFn();
    }
  }, [state.canRedirect]);

  const handleSubmit = React.useCallback(() => {
    const { name, id } = state;

    const domain = state["shop Code"];
    validateField(name, "name");
    validateField(domain, "shop Code");

    if (name && domain) {
      toggleToast && toggleToast();
      setNotify && setNotify({ msg: null, err: false });
      setState({ openModal: false, canRedirect: false });

      updateAccount({
        variables: {
          input: {
            id,
            title: name,
            domain,
          },
        },
      });
    }
  }, [state, validateField, updateAccount, toggleToast, setNotify]);

  React.useEffect(
    () => () => {
      inputRef.current && clearTimeout(inputRef.current);
    },
    [],
  );

  const loadingMarkup = loading && <Loading />;
  return (
    <WrapperPage>
      <Page
        title="Switch To API Store"
        breadcrumbs={
          isMarketplaceManager
            ? []
            : [{ content: "Stores", url: `/${param}/stores/tiktok` }]
        }
      >
        {loadingMarkup}
        <Card sectioned>
          <Wrapper>
            <Form onSubmit={handleSubmit} name="add_tiktok_account">
              <FormLayout>
                <div>
                  <ComponentLabelPolaris label="Store name" required />
                  <TextField
                    value={state.name}
                    id="name"
                    onChange={handleFieldChange}
                    error={errors["name"]}
                    placeholder="Enter store name"
                  />
                </div>
                <div>
                  <ComponentLabelPolaris label={"Shop Code"} required />
                  <TextField
                    value={state["shop Code"]}
                    id={"shop Code"}
                    onChange={handleFieldChange}
                    error={errors["shop Code"]}
                    placeholder={`Enter shop code`}
                  />
                </div>

                <ButtonGroup>
                  <Button children="Cancel" url={`/${param}/stores/tiktok`} />
                  <Button children="Add Account" primary loading={l2} submit />
                </ButtonGroup>
              </FormLayout>
            </Form>

            <ConnectTiktokModal open={state.openModal} onClose={onCloseModal} />
          </Wrapper>
        </Card>
      </Page>
    </WrapperPage>
  );
}

const Wrapper = styled.div`
  a:hover {
    color: inherit;
  }
`;
