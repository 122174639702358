import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    useContext,
} from "react";
import { Filters } from "@shopify/polaris";
import { isEmpty, get } from "lodash";
import { useQuery } from "@apollo/react-hooks";

import { AppContext } from "../../../context";
import { USER_ROLE, TEAM_ROLE } from "../../../variable";
import { COMPLEX_PRODUCT_ASSORTMENT_DOMAINS } from "../../../graphql/queries";
import { FilterCreatedTimePolaris } from "../../product/FilterCreatedTimePolaris";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

import {
    QUERY_ASSORTMENT_AUTHORS,
    QUERY_ASSORTMENT_STORES,
} from "../../assortment/FilterAssortmentPolaris";

export function Filter({ filter, onChange }) {
    // Props
    const searchProp = filter.search;

    // State
    const [search, setSearch] = useState("");
    const timeoutRef = useRef(null);
    const [filterTime, setFilterTime] = useState({
        range: null,
        rangeLabel: null,
    });

    const [domains, setDomains] = useState([]);
    const [domain, setDomain] = useState({
        value: filter.domain,
        label: null,
        search: null,
    });

    const [authors, setAuthors] = useState([]);
    const [author, setAuthor] = useState({
        value: filter.authorId,
        label: null,
        search: null,
    });

    const [stores, setStores] = useState([]);
    const [store, setStore] = useState({
        value: filter.storeId || null,
        label: null,
        search: null,
    });

    // Context
    const { currentUser } = useContext(AppContext);
    const userRole = get(currentUser, "roles", []);
    const teamRole = get(currentUser, "teamUser.role", null);
    let leadTeam = false;
    if (userRole.includes(USER_ROLE.Seller)) {
        if (
            [
                TEAM_ROLE.Admin,
                TEAM_ROLE.MarketplaceManager,
                TEAM_ROLE.StoreManager,
                TEAM_ROLE.Supporter,
            ].includes(teamRole)
        ) {
            // TODO: disabled
            leadTeam = false; // true
        }
    }

    // Queries
    const { data, loading, error } = useQuery(
        COMPLEX_PRODUCT_ASSORTMENT_DOMAINS
    );
    const { data: dataAuth, loading: loadingAuth, error: errorAuth } = useQuery(
        QUERY_ASSORTMENT_AUTHORS,
        {
            skip: true,
        }
    );

    const {
        data: dataStore,
        loading: loadingStore,
        error: errorStore,
    } = useQuery(QUERY_ASSORTMENT_STORES, { skip: true });

    //
    useEffect(() => {
        let newDomains =
            data?.complexProductAssortmentDomains?.nodes?.length > 0
                ? data.complexProductAssortmentDomains.nodes
                      .filter((i) => i.domain)
                      .map((i) => ({
                          value: i.domain,
                          label: i.domain,
                      }))
                : [];
        setDomains(newDomains);
    }, [data]);

    useEffect(() => {
        let newAuth =
            dataAuth?.getAssortmentAuthors?.nodes?.length > 0
                ? dataAuth.getAssortmentAuthors.nodes.map((a) => {
                      let user = a && a.user;
                      let currentId = currentUser && currentUser.id;
                      let suffix = "";
                      if (currentId === user.id) {
                          suffix = "(current)";
                      }
                      return {
                          value: user ? user.id : null,
                          label: user
                              ? `${user.firstName} ${user.lastName} ${suffix}`
                              : "",
                      };
                  })
                : [];

        setAuthors(newAuth);
    }, [dataAuth, currentUser]);

    useEffect(() => {
        let newStores =
            dataStore?.getAssortmentStores?.nodes?.length > 0
                ? dataStore.getAssortmentStores.nodes.map(({ id, title }) => ({
                      value: id,
                      label: title,
                  }))
                : [];
        setStores(newStores);
    }, [dataStore]);

    // Handle action
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if (onChange) {
                onChange({
                    search: search?.trim(),
                    createdTime: filterTime.range,
                    domain: domain.value,
                    authorId: author.value,
                    storeId: store.value,
                });
            }
        }, 350);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [
        onChange,
        search,
        filterTime.range,
        domain.value,
        store.value,
        author.value,
    ]);

    const handleSearchChange = useCallback((value) => {
        setSearch(value);
    }, []);

    const handleSearchRemove = useCallback(() => setSearch(""), []);
    const handleFilterTimeRemove = useCallback(
        () => setFilterTime({ range: null, rangeLabel: null }),
        []
    );
    const handleDomainRemove = useCallback(
        () => setDomain(() => ({ value: null, label: null, search: null })),
        []
    );

    const handleAuthorRemove = useCallback(() => {
        setAuthor(() => ({ value: null, label: null, search: null }));
    }, []);
    const handleStoreRemove = useCallback(
        () => setStore({ value: null, label: null, search: null }),
        []
    );

    const filtersRemove = useMemo(
        () => [
            handleSearchRemove,
            handleFilterTimeRemove,
            handleDomainRemove,
            handleAuthorRemove,
            handleStoreRemove,
        ],
        [
            handleSearchRemove,
            handleFilterTimeRemove,
            handleDomainRemove,
            handleStoreRemove,
            handleAuthorRemove
        ]
    );

    const handleFiltersClearAll = useCallback(() => {
        for (let fn of filtersRemove) {
            fn && fn();
        }
    }, [...filtersRemove]);

    // Get data
    useEffect(() => {
        if (searchProp) {
            setSearch(searchProp);
        }
    }, [searchProp]);

    useEffect(() => {
        if (filter.domain) {
            let newDomain =
                domains?.length > 0
                    ? domains.find((d) => d.value === filter.domain)
                    : null;
            if (newDomain) {
                setDomain(() => ({
                    value: newDomain.value,
                    label: newDomain.label,
                }));
            }
        }
    }, [filter.domain, domains]);

    useEffect(() => {
        if (filter.authorId) {
            let newAuthor =
                authors?.length > 0
                    ? authors.find((a) => a.value === filter.authorId)
                    : null;
            if (newAuthor) {
                setAuthor(() => ({
                    value: newAuthor.value,
                    label: newAuthor.label,
                }));
            }
        }
    }, [filter.authorId, authors]);

    useEffect(() => {
        if (filter.storeId) {
            let newStore =
                stores?.length > 0
                    ? stores.find((a) => a.value === filter.storeId)
                    : null;
            if (newStore) {
                setStore(() => ({
                    value: newStore.value,
                    label: newStore.label,
                }));
            }
        }
    }, [filter.storeId, stores]);

    const filters = [
        {
            key: "domain",
            label: "Domain",
            filter: (
                <FilterNoSearchPolaris
                    data={domains}
                    value={domain}
                    loading={loading}
                    error={error}
                    onChange={({ value, label }) =>
                        setDomain((prevState) => ({
                            ...prevState,
                            value,
                            label,
                        }))
                    }
                    onChangeSearch={({ search }) =>
                        setDomain((prevState) => ({ ...prevState, search }))
                    }
                />
            ),
        },
        // TODO: show for lead
        ...(leadTeam
            ? [
                  {
                      key: "author",
                      label: "Author",
                      filter: (
                          <FilterNoSearchPolaris
                              value={author}
                              data={authors}
                              loading={loadingAuth}
                              error={errorAuth}
                              onChange={({ value, label }) =>
                                  setAuthor((prev) => ({
                                      ...prev,
                                      value,
                                      label,
                                  }))
                              }
                              onChangeSearch={({ search }) =>
                                  setAuthor((prevState) => ({
                                      ...prevState,
                                      search,
                                  }))
                              }
                          />
                      ),
                  },
                  {
                      key: "store",
                      label: "Stores",
                      filter: (
                          <FilterNoSearchPolaris
                              data={stores}
                              value={store}
                              loading={loadingStore}
                              error={errorStore}
                              onChange={({ value, label }) =>
                                  setStore((prevState) => ({
                                      ...prevState,
                                      value,
                                      label,
                                  }))
                              }
                              onChangeSearch={({ search }) =>
                                  setStore((prevState) => ({
                                      ...prevState,
                                      search,
                                  }))
                              }
                          />
                      ),
                  },
              ]
            : []),
        {
            key: "createdTime",
            label: "Created Time",
            filter: (
                <FilterCreatedTimePolaris
                    filterTime={filterTime}
                    onChangeRange={(range, rangeLabel) => {
                        setFilterTime((prev) => ({
                            ...prev,
                            range,
                            rangeLabel,
                        }));
                    }}
                />
            ),
        },
    ];

    const appliedFilters = [];
    if (!isEmpty(domain.label)) {
        const key = "domain";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, domain.label),
            onRemove: handleDomainRemove,
        });
    }
    if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
        const key = "createdTime";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, filterTime.rangeLabel),
            onRemove: handleFilterTimeRemove,
        });
    }

    if (!isEmpty(author.label)) {
        const key = "author";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, author.label),
            onRemove: handleAuthorRemove,
        });
    }
    if (!isEmpty(store.label)) {
        const key = "store";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, store.label),
            onRemove: handleStoreRemove,
        });
    }

    return (
        <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={search}
            queryPlaceholder="Filter item"
            onQueryChange={handleSearchChange}
            onQueryClear={handleSearchRemove}
            onClearAll={handleFiltersClearAll}
        />
    );

    function disambiguateLabel(key, value) {
        switch (key) {
            case "domain":
                return `Domain: ${value}`;
            case "createdTime":
                return `Created time: ${value}`;
            case "author":
                return `Author: ${value}`;
            case "store":
                return `Store: ${value}`;
            default:
                return value;
        }
    }
}
