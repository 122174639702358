import { gql } from "apollo-boost";
import { TrackingConfigFragment } from "../fragments";

export const CREATE_TRACKING_CONFIG = gql`
  mutation createTrackingConfig($input: NewTrackingConfig!) {
    createTrackingConfig(input: $input) {
      ...TrackingConfigFragment
    }
  }
  ${TrackingConfigFragment}
`;

export const UPDATE_TRACKING_CONFIG = gql`
  mutation updateTrackingConfig($input: EditTrackingConfig!) {
    updateTrackingConfig(input: $input) {
      ...TrackingConfigFragment
    }
  }
  ${TrackingConfigFragment}
`;

export const CREATE_TRACKING_V2 = gql`
  mutation createTrackingCode($input: NewTrackingCode!) {
    createTrackingCode(input: $input) {
      id
      trackingCode
    }
  }
`;

export const ADMIN_CREATE_TRACKING_V2 = gql`
  mutation adminCreateTrackingCode($input: NewTrackingCode!) {
    adminCreateTrackingCode(input: $input) {
      id
      status
      trackingCode
    }
  }
`;

export const RE_TRACK_TRACKING_MORE = gql`
  mutation reTrackTrackingMore($id: ID!) {
    reTrackTrackingMore(id: $id)
  }
`

export const UPDATE_TRACKING_STATUS_V2 = gql`
  mutation updateTrackingStatusV2($input: UpdateTrackingCode!) {
    updateTrackingStatusV2(input: $input)
  }
`;

export const SUBMIT_TO_TRACKING_MORE_V2 = gql`
  mutation submitToTrackingMoreV2($id: ID!) {
    submitToTrackingMoreV2(id: $id)
  }
`;

export const SUBMIT_TRACKING_TO_STORE_V2 = gql`
  mutation submitTrackingToStoreV2($id: ID!) {
    submitTrackingToStoreV2(id: $id)
  }
`;

export const UPDATE_ORDER_TRACKING_CODE_V2 = gql`
  mutation updateOrderTrackingV2($orderId: ID!, $codes: [String!]!) {
    updateOrderTrackingV2(orderId: $orderId, codes: $codes)
  }
`;

export const AMAZON_EXPORT_CONFIRM_SHIPMENT_V2 = gql`
  mutation amazonExportConfirmShipmentV2($storeId: ID!) {
    amazonExportConfirmShipmentV2(storeId: $storeId)
  }
`;

export const CLAIM_UPDATE_TRACKING_V2 = gql`
  mutation updateTrackingV2($input: UpdateTracking!) {
    updateTrackingV2(input: $input) {
      id
      orderId
      type
      comment
      status
    }
  }
`;