import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REPORT_SALE_MARKETPLACE_MANAGER } from "../../../graphql/queries";
import { formatter, getUnique } from "../../../helper";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";
import ShowCard, { conditionByFilter } from "./ShowCard";

const collapseKey = "marketplaceManager";
const styleColumn = {
  width: "10rem",
  display: "inline-block",
};
export function MarketplaceManager({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, error, fetchMore } = useQuery(
    REPORT_SALE_MARKETPLACE_MANAGER,
    {
      variables: {
        filter,
      },
    },
  );
  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_SALE_MARKETPLACE_MANAGER);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  const nodes = data?.reportSaleByMarketplaceManagers?.nodes;
  useEffect(() => {
    if (filterCtx != null) {
      let { viewWholeTeamReport, storeID, storeManagerID, ...ignoredFilter } =
        filterCtx;
      setFilter((prev) => ({ ...prev, ...ignoredFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const total = data?.reportSaleByMarketplaceManagers?.total;
    const totalSale = data?.reportSaleByMarketplaceManagers?.totalSale;
    let totalCost = 0;
    let totalRev = 0;
    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              const totalSale = node?.totalSale;
              if (!totalSale) return null;
              const totalBaseCost = node?.totalBaseCost || 0;
              const totalRevenues = node?.totalRevenues || 0;
              const user = node?.user;
              const name = [user.firstName, user.lastName]
                .filter(Boolean)
                .join(" ");

              totalCost += totalBaseCost;
              totalRev += totalRevenues;
              return {
                totalSale,
                totalBaseCost,
                name,
                totalRevenues,
              };
            })
            .filter(Boolean)
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalSale,
      totalCost: totalCost.toFixed(2),
      totalRevenues: totalRev.toFixed(2),
    }));
  }, [data, nodes]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportSaleByMarketplaceManagers?.totalSale;
      const totalRevenues =
        dataC?.reportSaleByMarketplaceManagers?.totalRevenues;
      const totalBaseCost =
        dataC?.reportSaleByMarketplaceManagers?.totalBaseCost;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
        totalCost: totalBaseCost,
        totalRevenues,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportSaleByMarketplaceManagers.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportSaleByMarketplaceManagers =
              fetchMoreResult.reportSaleByMarketplaceManagers;
            const nodes = reportSaleByMarketplaceManagers.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [
              ...prev.reportSaleByMarketplaceManagers.nodes,
              ...nodes,
            ];
            const result = getUnique(newNodes, "userId");
            const totalCost = (result || []).reduce((acc, item) => {
              if (item?.totalBaseCost > 0) acc += item.totalBaseCost;
              return acc;
            }, 0);
            const totalRevenues = (result || []).reduce((acc, item) => {
              if (item?.totalRevenues > 0) acc += item.totalRevenues;
              return acc;
            }, 0);

            setState((p) => ({
              ...p,
              totalCost: totalCost.toFixed(2),
              totalRevenues: totalRevenues.toFixed(2),
            }));

            return {
              ...prev,
              reportSaleByMarketplaceManagers: {
                ...prev.reportSaleByMarketplaceManagers,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => (
          <Stack key={`marketplace-manager-${index}`} wrap={false}>
            <span className="index-wrap">{index + 1}</span>
            <Stack.Item fill>{item.name}</Stack.Item>
            <span>{item.totalSale}</span>
            <span style={styleColumn}>
              {formatter.format(item.totalBaseCost)}
            </span>
            <span style={styleColumn}>
              {formatter.format(item.totalRevenues)}
            </span>
          </Stack>
        ))
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Name</TextStyle>
      </Stack.Item>
      <span>Sales</span>
      <span style={styleColumn}>Total Cost</span>
      <span style={styleColumn}>Total Revenue</span>
    </Stack>
  );

  const subTitle = useMemo(() => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <span>{state.totalSale} Line items</span>
          <ComparedPercent
            originalVal={state.totalSale}
            newVal={stateCompare.totalSale}
          />
        </span>

        <span>
          <span>Total cost: {formatter.format(state.totalCost)}</span>
          <ComparedPercent
            originalVal={state.totalCost}
            newVal={stateCompare.totalCost}
          />
        </span>
        <span>
          <span>Total revenue: {formatter.format(state.totalRevenues)}</span>
          <ComparedPercent
            originalVal={state.totalRevenues}
            newVal={stateCompare.totalRevenues}
          />
        </span>
      </div>
    );
  }, [
    state.totalSale,
    stateCompare.totalSale,
    state.totalCost,
    state.totalRevenues,
    stateCompare.totalRevenues,
    stateCompare.totalCost,
  ]);

  const rootError = error || errorC;
  const con = conditionByFilter(filterCtx, ["storeID", "storeManagerID"]);

  return (
    <ShowCard show={con}>
      <CardBox
        collapseKey={collapseKey}
        title="Marketplace managers"
        subTitle={subTitle}
        headMarkup={headMarkup}
        contentMarkup={contentMarkup}
        filter={filter}
        total={state.total}
        loading={mergedLoading}
        error={rootError}
        setFilter={setFilter}
        handleScroll={handleScroll}
        loadMore={loadMore}
      />
    </ShowCard>
  );
}
