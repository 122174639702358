import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
    Stack,
    Spinner,
    Labelled,
    TextField,
    Button,
    TextStyle,
    Checkbox,
    Card,
    InlineError,
} from "@shopify/polaris";
import {
    CancelSmallMinor,
    CircleCancelMajorMonotone,
    EditMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";

import { handleError, toSlug } from "../../../helper";
import { FULFILLMENT_COLOR_MAPPING, PME_TEAM_ID } from "../../../variable";
import useToggle from "../../../hooks/useToggle";

import { AutoCompletePolaris } from "../../shared/AutoCompletePolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { CollectionSelectPolaris } from "../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../product/tags/TagsSelectPolaris";
import { SimpleCustomizePolaris } from "../../customize/SimpleCustomizePolaris";
import { useAppContext } from "../../../context";

const FULFILLMENTS = gql`
    query fulfillments {
        fulfillments {
            id
            name
        }
    }
`;

const PRODUCT_BASES_FOR_ASSORTMENT = gql`
    query productBasesForAssortment {
        productBasesForAssortment {
            id
            title
            slug
            attributes {
                name
                slug
                options
            }
            fulfillment {
                fulfillmentId
            }
        }
    }
`;

const AUTO_CHOOSE_OPTIONS = ["Color", "Type", "Shape", "Pack"];

export function FormAssign({ value, onSubmit, loading }) {
    // Context
    const { currentUser } = useAppContext();
    const { teamUser } = currentUser || {};
    const { team } = teamUser || {};
    const { id: teamId } = team || {};
    // State
    const [state, setState] = useState({
        id: null,
        title: "",
        collections: PME_TEAM_ID === teamId ? ["hGypw"] : [],
        tags: [],
        personalized: false,
        fields: [],
        attr: null,
        optionByAttr: [],
        optionsCrawl: [],
        mockupUrls: {},
        mainImageUrl: null,
        variantImages: [],
        productBaseId: null,
    });

    const [errors, setErrors] = useState({});

    // Get data
    useEffect(() => {
        if (value != null) {
            const { id, title } = value;
            setState((prev) => ({ ...prev, id, title }));
        }
    }, [value]);

    useEffect(() => {
        const attrLower = state.attr?.toLowerCase();
        if (value != null) {
            const { variantAttributes } = value;

            if (attrLower != null) {
                const newOptions = (variantAttributes || [])
                    .map((attr) => {
                        const { attribute, image } = attr;
                        if (attribute == null || attribute.length === 0)
                            return null;

                        const [first] = attribute;
                        return {
                            value: toSlug(first.value),
                            label: first.value,
                            image,
                        };
                    })
                    .filter(Boolean);

                const images = newOptions.reduce((acc, { value, image }) => {
                    const [first] = (image || []).filter(Boolean);
                    return {
                        ...acc,
                        [value]: first,
                    };
                }, {});

                setState((prev) => {
                    let variantImages = prev.variantImages || [];
                    variantImages = variantImages.map((item) => {
                        let matched;
                        if (Array.isArray(item.options)) {
                            matched = newOptions.find((node) =>
                                item.options.includes(node.value)
                            );
                        } else if (typeof item.options === "string") {
                            matched = newOptions.find(
                                (node) => node.value === item.options
                            );
                        }

                        let newItem = {
                            ...item,
                            imageUrl: [],
                            mainImageUrl: null,
                        };

                        if (matched != null) {
                            newItem = {
                                ...item,
                                value: matched.value,
                                imageUrl: matched.image,
                                mainImageUrl:
                                    matched.image?.length > 0
                                        ? matched.image[0]
                                        : null,
                            };
                        }
                        return newItem;
                    });

                    return {
                        ...prev,
                        optionsCrawl: newOptions,
                        mockupUrls: images,
                        variantImages,
                    };
                });
            }
        }
    }, [value, state.attr, state.productBaseId]);

    const FIELD_REQUIRED = useMemo(
        () => ({
            collections: "Collections",
            tags: "Tags",
            productBaseId: "Product base",
        }),
        []
    );

    // Hand actions
    const validateField = useCallback(
        (value, id) => {
            let err = null;
            let label = FIELD_REQUIRED[id];
            if ((!value || value.length === 0) && label) {
                err = `${label} is required`;
            }

            setErrors((prev) => ({ ...prev, [id]: err }));
        },
        [FIELD_REQUIRED]
    );

    const handleFieldChange = useCallback(
        (value, id) => {
            setState((prev) => ({ ...prev, [id]: value }));
            validateField(value, id);
        },
        [validateField]
    );

    const handleCheckboxChange = useCallback((value) => {
        setState((prev) => ({
            ...prev,
            personalized: value,
            fields: !value ? [] : prev.fields,
        }));
    }, []);

    const handleFieldsChange = useCallback((value) => {
        setState((prev) => ({ ...prev, fields: [...value] }));
    }, []);

    const handleSubmit = useCallback(() => {
        for (let key of Object.keys(FIELD_REQUIRED)) {
            validateField(state[key], key);
        }

        const {
            productBaseId,
            collections,
            tags,
            variantImages,
            id,
            title,
            personalized,
            fields,
            mockupUrls,
            mainImageUrl,
        } = state;

        const noError = Object.values(errors).every((i) => i == null);

        // Format data
        const newVariant = variantImages
            .filter((i) => i.mainImageUrl)
            .map(({ attribute, imageUrl, mainImageUrl }) => ({
                attribute,
                imageUrl,
                mainImageUrl,
            }));

        const canSubmit =
            productBaseId != null &&
            collections?.length > 0 &&
            tags?.length > 0 &&
            newVariant?.length > 0 &&
            mockupUrls != null &&
            mainImageUrl != null;

        if (canSubmit && noError) {
            const newMockups = Object.values(mockupUrls).filter(Boolean);
            const [newMain] = Object.values(mainImageUrl).filter(Boolean);

            const input = {
                id,
                title,
                productBaseId,
                collections,
                tags,
                personalized,
                fields,
                variantImages: newVariant,
                mockupUrls: newMockups,
                mainImageUrl: newMain,
            };

            if (onSubmit) {
                onSubmit(input);
            }
        }
    }, [state, FIELD_REQUIRED, errors, onSubmit, validateField]);

    useEffect(() => {
        if (state.variantImages?.length > 0) {
            const validateVariantImages = state.variantImages.filter(
                (i) => i.mainImageUrl
            );

            let err = null;
            if (validateVariantImages?.length === 0) {
                err = "Please select at least one variant.";
            }

            setErrors((prev) => ({
                ...prev,
                mapping: err,
            }));
        }
    }, [state.variantImages]);

    return (
        <Wrapper>
            <Stack vertical spacing="loose">
                <ProductTitle value={state.title} setState={setState} />
                <ProductBase
                    setState={setState}
                    errors={errors}
                    onChange={(value) =>
                        handleFieldChange(value, "productBaseId")
                    }
                />
                <CollectionSelectPolaris
                    label="Collections"
                    required
                    allowMultiple
                    hasPopular
                    haveQuickAdd
                    value={state.collections}
                    errorMessage={errors.collections}
                    isAssortment
                    onChange={(value) =>
                        handleFieldChange(value, "collections")
                    }
                />
                <TagsSelectPolaris
                    label="Tags"
                    required
                    allowMultiple
                    haveQuickAdd
                    value={state.tags}
                    errorMessage={errors.tags}
                    onChange={(value) => handleFieldChange(value, "tags")}
                />
                <ProductImage stateRoot={state} setStateRoot={setState} />
                <MappingComp
                    options={state.optionByAttr}
                    optionsCrawl={state.optionsCrawl}
                    setStateRoot={setState}
                    stateRoot={state}
                    error={errors?.mapping}
                />
                <div>
                    <Checkbox
                        label="This is a personalized product"
                        checked={state.personalized}
                        onChange={handleCheckboxChange}
                    />
                    {state.personalized && (
                        <SimpleCustomizePolaris
                            value={state.fields}
                            onChange={handleFieldsChange}
                        />
                    )}
                </div>

                <Button
                    fullWidth
                    primary
                    loading={loading}
                    children="Submit"
                    onClick={handleSubmit}
                />
            </Stack>
        </Wrapper>
    );
}

function ProductTitle({ value, setState }) {
    const [edit, toggleEdit] = useToggle(false);
    const timeoutRef = useRef(null);

    const handleValueChange = useCallback(
        (value) => {
            if (setState) {
                setState((prev) => ({ ...prev, title: value }));
            }
        },
        [setState]
    );

    useEffect(() => () => clearTimeout(timeoutRef.current));

    return (
        <div className="product-title-wrap">
            <span>Product Title:</span>
            {edit ? (
                <div className="input-wrap">
                    <TextField
                        value={value}
                        onChange={handleValueChange}
                        placeholder="Product title"
                    />
                </div>
            ) : (
                <TextStyle variation="strong">{value}</TextStyle>
            )}
            <Button
                icon={edit ? CancelSmallMinor : EditMajorMonotone}
                plain
                onClick={toggleEdit}
            />
        </div>
    );
}

function ProductBase({ setState: setStateRoot, onChange, errors }) {
    // State
    const [fulfillments, setFulfillment] = useState([]);
    const [bases, setBases] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [state, setState] = useState({
        fulfillmentId: null,
        baseId: null,
        currentBase: null,
        attributeId: null,
        fulfillment: null,
    });

    // Queries
    const { data, loading, error } = useQuery(FULFILLMENTS);
    const { data: dataB, loading: loadingB, error: errorB } = useQuery(
        PRODUCT_BASES_FOR_ASSORTMENT
    );

    // Get data
    useEffect(() => {
        const newData =
            data?.fulfillments?.length > 0
                ? data.fulfillments.map((node) => {
                      return {
                          value: node.id,
                          label: node.name,
                      };
                  })
                : [];
        setFulfillment([{ value: null, label: "MerchBridge" }, ...newData]);
    }, [data]);

    // Handle action
    const handleOptionByAttr = useCallback(
        (value, attr = null) => {
            if (setStateRoot) {
                const newVI =
                    value?.length > 0
                        ? value.map((item) => {
                              const result = fulfillmentMap(
                                  state.fulfillment,
                                  item
                              );
                              return {
                                  attribute: item,
                                  options: result ? result : null,
                              };
                          })
                        : [];

                setStateRoot((prev) => ({
                    ...prev,
                    optionByAttr: value,
                    attr,
                    variantImages: [...newVI],
                }));
            }
        },
        [setStateRoot, state.fulfillment]
    );

    useEffect(() => {
        const ffId = state.fulfillmentId;

        const newData =
            dataB?.productBasesForAssortment?.length > 0
                ? dataB.productBasesForAssortment
                      .filter((node) => {
                          const nodeFFId = node?.fulfillment?.fulfillmentId;

                          if (ffId != null) {
                              if (nodeFFId === ffId) {
                                  return true;
                              }
                              return false;
                          } else if (node.fulfillment === null) {
                              return true;
                          }
                          return false;
                      })
                      .map((node) => {
                          return {
                              ...node,
                              value: node.id,
                              label: node.title,
                          };
                      })
                : [];
        setBases(newData);
        handleOptionByAttr([]);
    }, [dataB, state.fulfillmentId, handleOptionByAttr]);

    const handleFFChange = useCallback((value) => {
        const id = value?.value;
        const name = value?.label;

        setState((prev) => ({
            ...prev,
            fulfillmentId: id,
            attributeId: null,
            fulfillment: name,
        }));
    }, []);

    const handleBaseChange = useCallback(
        (value) => {
            onChange && onChange(value);
            setState((prev) => ({ ...prev, baseId: value }));

            if (value != null) {
                const currentBase =
                    bases?.length > 0
                        ? bases.find((node) => node.id === value)
                        : null;
                if (currentBase != null) {
                    setState((prev) => ({
                        ...prev,
                        currentBase,
                        attributeId: null,
                    }));
                    const { attributes } = currentBase;
                    const newAttr = (attributes || []).map((node) => ({
                        value: node.slug,
                        label: node.name,
                    }));
                    setAttributes(newAttr);

                    const autoChoose =
                        attributes?.length > 0
                            ? attributes.find((node) => {
                                  const slugLower = node?.slug
                                      ?.trim()
                                      ?.toLowerCase();
                                  const options = AUTO_CHOOSE_OPTIONS.map((i) =>
                                      i.toLowerCase()
                                  );

                                  return options.includes(slugLower);
                              })
                            : null;

                    if (autoChoose != null) {
                        setState((prev) => ({
                            ...prev,
                            attributeId: autoChoose.slug,
                        }));
                        handleOptionByAttr(autoChoose.options, autoChoose.slug);
                    } else {
                        handleOptionByAttr([]);
                    }
                }
            }
        },
        [bases, handleOptionByAttr, onChange]
    );

    const handleAttrChange = useCallback(
        (value) => {
            setState((prev) => ({ ...prev, attributeId: value }));

            const base = state.currentBase;

            if (base != null && value != null) {
                const { attributes } = base;
                const currentAttr = (attributes || []).find(
                    (node) => node.slug === value
                );

                if (currentAttr != null) {
                    handleOptionByAttr(currentAttr.options, value);
                }
            }
        },
        [state.currentBase, handleOptionByAttr]
    );

    return (
        <Stack vertical spacing="tight">
            <ComponentLabelPolaris label="Product Base" required />
            <Stack distribution="fill">
                <div>
                    <Labelled label="Fulfillment" />
                    {loading ? (
                        <Spinner size="small" />
                    ) : error ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : (
                        <AutoCompletePolaris
                            options={fulfillments}
                            value={state.fulfillmentId}
                            placeholder={"Filter fulfillment"}
                            onChange={handleFFChange}
                            isFilter
                        />
                    )}
                </div>
                <div>
                    <Labelled label="Product base" />
                    {loadingB ? (
                        <Spinner size="small" />
                    ) : errorB ? (
                        <div>Error: {handleError(errorB.toString())}</div>
                    ) : (
                        <AutoCompletePolaris
                            options={bases}
                            value={state.baseId}
                            placeholder={"Filter product base"}
                            onChange={handleBaseChange}
                            error={errors.productBaseId}
                        />
                    )}
                </div>
                <div>
                    <Labelled label="Attributes" />
                    <AutoCompletePolaris
                        options={attributes}
                        value={state.attributeId}
                        placeholder={"Filter attribute"}
                        onChange={handleAttrChange}
                    />
                </div>
            </Stack>
        </Stack>
    );
}

function fulfillmentMap(fulfillment, attr) {
    if (!fulfillment) return false;

    const ffLowerCase = fulfillment.toLowerCase();
    const newAttr = attr?.replace(/\s/g, "");
    const fulfillmentMap = FULFILLMENT_COLOR_MAPPING[ffLowerCase];

    if (fulfillmentMap == null) {
        return false;
    }
    const value = fulfillmentMap[newAttr];
    let result = null;

    if (Array.isArray(value)) {
        result = value.map((node) => toSlug(node));
    } else if (typeof value === "string") {
        result = toSlug(value);
    }

    return result;
}

function MappingComp({
    options,
    optionsCrawl,
    setStateRoot,
    stateRoot,
    error,
}) {
    return (
        <MappingWrap>
            <Labelled label="Mapping" />
            <Card sectioned>
                <div className="header">
                    <span>Attributes</span>
                    <span>Crawl variants</span>
                    <div className="image-wrap"></div>
                </div>
                <Stack vertical spacing="loose">
                    {(options || []).map((item, index) => (
                        <div className="option-wrap" key={`option-${index}`}>
                            <MappingItem
                                item={item}
                                index={index}
                                option={optionsCrawl}
                                setStateRoot={setStateRoot}
                                stateRoot={stateRoot}
                            />
                        </div>
                    ))}
                </Stack>
            </Card>
            {error && <InlineError message={error} fieldID="mapping" />}
        </MappingWrap>
    );
}

function MappingItem({
    item,
    option: optionsCrawlProp,
    setStateRoot,
    stateRoot,
    index,
}) {
    // State
    const [optionsCrawl, setOptionsCrawl] = useState([]);
    const [value, setValue] = useState(null);
    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState(null);

    // Get data
    useEffect(() => {
        if (optionsCrawlProp != null) {
            setOptionsCrawl(optionsCrawlProp);
        }
    }, [optionsCrawlProp]);

    useEffect(() => {
        const current = stateRoot?.variantImages
            ? stateRoot.variantImages.find((node) => node.attribute === item)
            : null;

        if (current != null) {
            setImages(current.imageUrl);
            setValue(current.value);
        }
    }, [stateRoot, selected, index, item]);

    // Handle actions
    const handleOptionChange = useCallback(
        (value) => {
            setValue(value);
            const matched = (optionsCrawl || []).find(
                (node) => node.value === value
            );
            if (matched != null) {
                setSelected(matched);
                setStateRoot((prev) => ({
                    ...prev,
                    variantImages: prev.variantImages.map((item, idx) => {
                        if (idx === index) {
                            return {
                                ...item,
                                value: matched.value,
                                imageUrl: matched.image,
                                mainImageUrl:
                                    matched.image?.length > 0
                                        ? matched.image[0]
                                        : null,
                            };
                        }
                        return item;
                    }),
                }));
            }
        },
        [optionsCrawl, setStateRoot, index]
    );

    const handleRemove = useCallback(
        (indexToRemove) => {
            if (setStateRoot) {
                setStateRoot((prev) => ({
                    ...prev,
                    variantImages: prev.variantImages.map((item, idx) => {
                        if (idx === index) {
                            let indexMain = item?.imageUrl?.indexOf(
                                item.mainImageUrl
                            );

                            if (indexMain === indexToRemove) {
                                indexMain -= 1;
                            }
                            indexMain -= 1;
                            indexMain = indexMain < 0 ? 0 : indexMain;

                            const newImg = item.imageUrl.filter(
                                (_, currentIdx) => indexToRemove !== currentIdx
                            );
                            const newMain = newImg[indexMain];

                            return {
                                ...item,
                                imageUrl: newImg,
                                mainImageUrl: newMain,
                            };
                        }

                        return item;
                    }),
                }));
            }
        },
        [setStateRoot, index]
    );

    const chooseMainImg = useCallback(
        (img) => {
            if (setStateRoot && selected != null) {
                const key = selected.value;
                setStateRoot((prev) => ({
                    ...prev,
                    variantImages: prev.variantImages.map((item, idx) => {
                        if (index === idx) {
                            return {
                                ...item,
                                mainImageUrl: img,
                            };
                        }

                        return item;
                    }),
                    mockupUrls: {
                        ...prev.mockupUrls,
                        [key]: img,
                    },
                }));
            }
        },
        [index, setStateRoot, selected]
    );

    const imagesMarkup = useMemo(() => {
        return (images || []).map((img, idx, arr) => {
            if (!img) return null;
            const mainImg =
                stateRoot["variantImages"]?.[index]?.["mainImageUrl"];
            const isActive = arr.indexOf(mainImg) === idx;

            const canRemove = arr.length > 1;
            return (
                <ImgComp
                    image={img}
                    key={`img-${idx}`}
                    onClick={() => handleRemove(idx)}
                    chooseMainImg={() => chooseMainImg(img)}
                    isActive={isActive}
                    canRemove={canRemove}
                />
            );
        });
    }, [images, stateRoot, handleRemove, chooseMainImg, index]);

    const handleClear = useCallback(() => {
        setValue(null);
        setStateRoot((prev) => ({
            ...prev,
            variantImages: prev.variantImages.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        imageUrl: [],
                        mainImageUrl: null,
                    };
                }
                return item;
            }),
        }));
    }, [setStateRoot, index]);

    return (
        <Fragment>
            <TextField value={item} disabled />
            <AutoCompletePolaris
                options={optionsCrawl}
                value={value}
                onChange={handleOptionChange}
                handleClear={handleClear}
                clearButton
            />
            <div className="image-wrap">
                {imagesMarkup?.length > 0 ? imagesMarkup : <span>&nbsp;</span>}
            </div>
        </Fragment>
    );
}

function ProductImage({ stateRoot, setStateRoot }) {
    const handleClick = useCallback(
        (key) => {
            setStateRoot((prev) => {
                return {
                    ...prev,
                    mockupUrls: {
                        ...prev.mockupUrls,
                        [key]: null,
                    },
                    mainImageUrl: {
                        ...prev.mainImageUrl,
                        [key]: null,
                    },
                };
            });
        },
        [setStateRoot]
    );

    const chooseMainImg = useCallback(
        (item) => {
            setStateRoot((prev) => ({ ...prev, mainImageUrl: item }));
        },
        [setStateRoot]
    );

    useEffect(() => {
        if (stateRoot.mockupUrls != null) {
            const [first] = Object.entries(stateRoot.mockupUrls);
            if (first != null) {
                const [key, value] = first;

                setStateRoot((prev) => ({
                    ...prev,
                    mainImageUrl: { [key]: value },
                }));
            }
        }
    }, [stateRoot.mockupUrls]);

    const imagesMarkup = useMemo(() => {
        const images = stateRoot.mockupUrls;
        return Object.entries(images).map(([key, value], index, arr) => {
            if (!value) return null;

            const isActive = stateRoot.mainImageUrl?.[key] === value;
            const canRemove = arr.length > 1;
            return (
                <ImgComp
                    image={value}
                    key={`product-image-${index}`}
                    onClick={() => handleClick(key)}
                    chooseMainImg={() => chooseMainImg({ [key]: value })}
                    isActive={isActive}
                    canRemove={canRemove}
                />
            );
        });
    }, [
        stateRoot.mockupUrls,
        stateRoot.mainImageUrl,
        handleClick,
        chooseMainImg,
    ]);

    return (
        <Fragment>
            <Labelled label="Product Images" />
            <Card sectioned>
                <ProductImagWrap>{imagesMarkup}</ProductImagWrap>
            </Card>
        </Fragment>
    );
}

function ImgComp({ image, onClick, chooseMainImg, isActive, canRemove }) {
    const activeCls = isActive ? "active" : "";
    const canRemoveCls = canRemove ? "can-remove" : "";
    return (
        <ImageWrap className={[activeCls, canRemoveCls].join(" ")}>
            <img src={image} alt="img" onClick={chooseMainImg} />
            <Button plain icon={CircleCancelMajorMonotone} onClick={onClick} />
        </ImageWrap>
    );
}

const Wrapper = styled.div`
    .product-title-wrap {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 1rem;
        justify-content: start;

        .input-wrap {
            flex: 1 1 auto;
        }

        .Polaris-Icon {
            height: 1.75rem;
            width: 1.75rem;
        }
    }
`;

const MappingWrap = styled.div`
    .Polaris-InlineError {
        margin-top: 1rem;
    }

    .option-wrap,
    .header {
        display: flex;
        flex-direction: row;
        justify-content: start;
        column-gap: 1.6rem;
        align-items: center;

        > * {
            flex: 1 1 20%;
        }
        .image-wrap {
            flex: 1 1 60%;
        }
    }

    .image-wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0.785rem;
    }

    .header {
        margin-bottom: 1.6rem;
    }
`;

const ImageWrap = styled.div`
    position: relative;
    border-radius: 3px;
    border: 2.5px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;

    &.active {
        border: 2.5px solid #3f4eae;
    }

    img {
        width: 10rem;
        height: 10rem;
        border-radius: 3px;
        object-fit: cover;
        cursor: pointer;
    }

    .Polaris-Button {
        visibility: hidden;
        transition: 0.3 all ease-in-out;
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
        padding: 0;
        min-height: 0;
        min-width: 0;

        svg {
            fill: #2f3138;
        }
    }

    &.can-remove {
        &:hover {
            img {
                opacity: 0.7;
            }

            .Polaris-Button {
                visibility: visible;
                min-height: 2rem;
                min-width: 2rem;
            }
        }
    }
`;

const ProductImagWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.6rem;
`;
