import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputNumber } from "antd";

class Price extends Component {
  state = {
    value: this.props.value,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  render() {
    const { onChange, min } = this.props;
    const { value } = this.state;
    return (
      <InputNumber
        {...this.props}
        min={min ? min : 0}
        style={{ width: "100%" }}
        value={value}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        onChange={(e) => {
          onChange(e);
          this.setState({ value: e });
        }}
        placeholder="$"
      />
    );
  }
}

Price.propTypes = {
  min: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
};

export default Price;
