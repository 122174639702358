import React, {
    Fragment,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from "react";
import { DataTable, Checkbox } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import noImg from "../../../assets/images/unnamed.jpeg";
import useToggle from "../../../hooks/useToggle";

import { GEN_THUMBNAIL_IMAGE } from "../../seller/ModalImageClaimPolaris";
import { ViewModalImage } from "../ViewModalImage";
import { Actions } from "./Actions";
import { BulkActions } from "../../shared/BulkActions";

const Container = styled.div`
    .item {
        white-space: normal;
        word-break: break-word;
    }

    .image-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
        cursor: pointer;
    }

    .image-wrap {
        width: 20rem;
        height: auto;
    }
`;

export function DataTableComp({ data }) {
    // State
    const [rows, setRows] = useState([]);
    const [columnContentTypes, setColumnContentTypes] = useState([]);
    const [headings, setHeadings] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [itemsChecked, setItemsChecked] = useState({});
    const [idsChecked, setIdsChecked] = useState([]);

    // Handle actions
    const handleIdsChecked = useCallback((object) => {
        object = Object(object);
        let result = [];

        for (let [key, value] of Object.entries(object)) {
            if (value) {
                result.push(key);
            }
        }
        return result;
    }, []);

    const handleCheckedAll = useCallback(
        (newChecked) => {
            setCheckedAll(newChecked);
            let propsT = data.productsByTitles?.nodes
                ? data.productsByTitles.nodes
                : [];

            let idsChecked = [];
            if (newChecked) {
                let item = {};
                for (let i = 0; i < propsT.length; i++) {
                    item = {
                        ...item,
                        [propsT[i].id]: true,
                    };
                }
                setItemsChecked(item);
                idsChecked = handleIdsChecked(item);
            } else {
                setItemsChecked({});
                idsChecked = [];
            }
            setIdsChecked(idsChecked);
        },
        [data, handleIdsChecked]
    );

    const checkAllMarkup = useMemo(
        () => (
            <Checkbox
                label=""
                checked={checkedAll}
                onChange={(newChecked) => handleCheckedAll(newChecked)}
            />
        ),
        [checkedAll, handleCheckedAll]
    );
    const handleChecked = useCallback(
        (id, newChecked) => {
            let countItemsChecked;
            let idsChecked = [];

            setItemsChecked((prev) => {
                let result = { ...prev, [id]: newChecked };
                countItemsChecked = Object.values(result).filter((i) => i)
                    .length;

                // onSelect
                idsChecked = handleIdsChecked(result);
                return result;
            });

            if (newChecked) {
                setCheckedAll(true);
            } else if (!countItemsChecked) {
                setCheckedAll(false);
            }
            setIdsChecked(idsChecked);
        },
        [handleIdsChecked]
    );

    // Get column, headings
    useEffect(() => {
        let newCol = [];
        let newHeadings = ["ID", "Image", "SKU", "Title"];
        newCol = newHeadings.map(() => "text");

        newCol = ["text", ...newCol];
        newHeadings = [checkAllMarkup, ...newHeadings];

        setColumnContentTypes(newCol);
        setHeadings(newHeadings);
    }, [checkAllMarkup]);

    const handleCompleted = useCallback(() => {
        setCheckedAll(false);
        setItemsChecked({});
        setIdsChecked([]);
    }, []);

    // Get data
    useEffect(() => {
        const newData =
            data?.productsByTitles?.nodes?.length > 0
                ? data.productsByTitles.nodes.map((node) => {
                      // Id
                      const id = node?.id;
                      const idMarkup = (
                          <div className="item id-wrap">
                              <span>{id}</span>
                          </div>
                      );

                      // Image
                      const imageMarkup = (
                          <div className="item image-wrap">
                              <ImgComp node={node} />
                          </div>
                      );

                      // Sku
                      const sku = node?.sku;
                      const skuMarkup = (
                          <div className="item sku-wrap">
                              <span>{sku}</span>
                          </div>
                      );

                      // Title
                      const title = node?.title;
                      const titleMarkup = (
                          <div className="item title-wrap">
                              <span>{title}</span>
                          </div>
                      );

                      // Checkbox
                      const checkbox = (
                          <div className="check-box-wrap">
                              <Checkbox
                                  id={id}
                                  checked={!!itemsChecked[id]}
                                  onChange={(newChecked, id) =>
                                      handleChecked(id, newChecked)
                                  }
                              />
                          </div>
                      );
                      return [
                          checkbox,
                          idMarkup,
                          imageMarkup,
                          skuMarkup,
                          titleMarkup,
                      ];
                  })
                : [];

        setRows(newData);

        return () => {
            setRows([]);
        };
    }, [data, itemsChecked, handleChecked]);

    // Markup
    return (
        <Fragment>
            <BulkActions
                idsChecked={idsChecked}
                onClose={() => handleCheckedAll(false)}
            >
                <Actions
                    ids={idsChecked}
                    data={data}
                    onCompleted={handleCompleted}
                />
            </BulkActions>
            <Container>
                <DataTable
                    rows={rows}
                    headings={headings}
                    columnContentTypes={columnContentTypes}
                    verticalAlign="middle"
                    hideScrollIndicator
                />
            </Container>
        </Fragment>
    );
}

function ImgComp({ node }) {
    const source = getMainImage(node);
    const id = source?.id;

    // Mutation
    const [genThumbnail] = useMutation(GEN_THUMBNAIL_IMAGE);

    // State
    const [url, setUrl] = useState(source.thumbnailUrl || source.url);
    const [open, toggleShowModal] = useToggle(false);

    useEffect(() => {
        if (source != null) {
            setUrl(source.thumbnailUrl || source.url);
        }
    }, [source]);

    // Handle action
    const handleGenThumbnail = useCallback(() => {
        if (id != null) {
            genThumbnail({
                variables: {
                    files: [id],
                    fileType: "id",
                },
            });
        }
    }, [id, genThumbnail]);

    return (
        <Fragment>
            <img
                src={url || noImg}
                alt=""
                onClick={toggleShowModal}
                onError={() => {
                    if (source?.thumbnailUrl) {
                        fetch(source.thumbnailUrl)
                            .then((res) => {
                                if (res?.status === 404) {
                                    handleGenThumbnail();
                                }
                            })
                            .catch(() => {});
                    }
                    setUrl(source.url || source.thumbnailUrl);
                }}
            />
            <ViewModalImage
                open={open}
                item={node}
                toggleShowModal={toggleShowModal}
            />
        </Fragment>
    );
}

function getMainImage(item) {
    if (!item || item.images?.length === 0) return null;
    const mainImage = item.mainImage;

    if (mainImage) return mainImage;

    const newImage = item.images.find((i) => i?.file?.id === item.mainImageId);
    if (newImage) return newImage.file;

    return item.images.map((i) => i.file).filter(Boolean)[0];
}
