import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    useLayoutEffect,
} from "react";
import { TextStyle, Button, Modal, Checkbox } from "@shopify/polaris";
import { PlusMinor } from "@shopify/polaris-icons";
import { get } from "lodash";
import styled from "styled-components";

import { formatTime, elementContains } from "../../../helper";
import noImg from "../../../assets/images/unnamed.jpeg";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

export function SelectTemplate({
    onChange,
    value,
    open,
    toggleOpen,
    data,
    error,
    filter,
    handlePagination,
    loading,
}) {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const ids =
            value?.length > 0
                ? // ? value.map((node) => node.id).filter(Boolean)
                  value.filter(Boolean)
                : [];
        setSelected(ids);
    }, [value]);

    // Handle actions
    const handleSelect = useCallback(() => {
        if (onChange) {
            const nodes = data?.nodes?.length > 0 ? data.nodes : [];
            let templates = selected
                .reduce((acc, cur) => {
                    const mapItem = nodes.find((node) => node.id === cur.id);
                    return [...acc, { ...mapItem, isDefault: cur.isDefault }];
                }, [])
                .filter(Boolean);

            const isDefault = templates.find((i) => i.isDefault);
            if (isDefault == null) {
                templates = templates.map((i, index) => {
                    i.isDefault = false;
                    if (!index) {
                        return {
                            ...i,
                            isDefault: true,
                        };
                    }
                    return i;
                });
            }
            toggleOpen();
            onChange(templates);
        }
    }, [onChange, selected, toggleOpen, data]);

    // Variables
    const { limit, page } = filter;
    const total = get(data, "total", 0);
    const totalPage = Math.ceil(total / limit);
    const offset = (page - 1) * limit;
    const aggregation = {
        page,
        limit,
        offset,
        total,
        totalPage,
        onChange: handlePagination,
    };

    useEffect(() => {
        let domT = document.querySelector("#templates-wrap");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );
        for (let domParent of domParents) {
            if (elementContains(domParent, domT)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100% - 20rem); height: calc(100vh)"
                );
            }
        }
    });

    return (
        <Wrapper>
            <div className="btn-select-template">
                <Button icon={PlusMinor} onClick={toggleOpen} size="large" />
            </div>

            <Modal
                open={open}
                onClose={toggleOpen}
                title="Mockup Templates"
                sectioned
                large
                primaryAction={{
                    content: "Select Template",
                    onAction: handleSelect,
                }}
                secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            >
                <ModalWrap id="templates-wrap">
                    <div className="media-items-wrap">
                        {loading ? (
                            <SkeletonPagePolaris />
                        ) : error ? (
                            <div>Error:{error.toString()}</div>
                        ) : data?.nodes?.length > 0 ? (
                            data.nodes.map((node, index) => (
                                <Item
                                    key={`template-${index}`}
                                    item={node}
                                    setSelected={setSelected}
                                    selected={selected}
                                />
                            ))
                        ) : (
                            <EmptyStatePolaris />
                        )}
                    </div>
                    <PaginationPolaris
                        aggregation={aggregation}
                        showTotal
                        widthSmall
                    />
                </ModalWrap>
            </Modal>
        </Wrapper>
    );
}

function Item({ item, setSelected, selected }) {
    // Props
    const id = item?.id;

    // State
    const [height, setHeight] = useState(0);
    const itemRef = useRef(null);

    // Markup
    const descMarkup = useMemo(() => {
        const createdAt = formatTime(item.created_at);
        function formatValue(arr, join) {
            return arr.filter(Boolean).join(join);
        }
        const dimensions = formatValue([item.width, item.height], "x");

        return formatValue([createdAt, dimensions], " - ");
    }, [item]);

    const src = useMemo(() => {
        let file = item?.file;
        let result = file?.thumbnail_url
            ? file?.thumbnail_url
            : file?.url
            ? file.url
            : noImg;

        return result;
    }, [item]);

    // Handle actions
    const handleClick = useCallback(() => {
        setSelected((prev) => {
            const index = prev.findIndex((i) => i.id === id);

            if (index === -1) {
                prev.push(item);
            } else {
                prev.splice(index, 1);
            }

            return [...prev];
        });
    }, [id, item, setSelected]);

    useLayoutEffect(() => {
        if (!itemRef.current) return;

        const rect = itemRef.current.getBoundingClientRect();
        if (rect?.width) {
            setHeight(Number(rect.width));
        }
    }, []);

    const ids = selected?.length > 0 ? selected.map((i) => i.id) : [];

    return (
        <div className="item-wrap" onClick={handleClick}>
            <div className="img-wrap" ref={itemRef}>
                <img src={src} alt="" style={height ? { height } : {}} />
            </div>
            <div className="item-info">
                <TextStyle variation="strong">{item.name}</TextStyle>
                <div className="desc">
                    <TextStyle variation="subdued">{descMarkup}</TextStyle>
                </div>
            </div>
            {ids.includes(id) && (
                <div className="checkbox-wrap">
                    <Checkbox checked={true} />
                </div>
            )}
        </div>
    );
}

const Wrapper = styled.div``;

const ModalWrap = styled.div`
    .item-wrap {
        position: relative;

        .checkbox-wrap {
            position: absolute;
            top: 0;
            right: 0;

            .Polaris-Choice {
                padding: 0;
            }

            .Polaris-Choice__Control {
                margin-top: 0;
                margin-right: 0;
                width: 2rem;
                height: 2rem;
            }
        }

        .item-info {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }

        .action-wrap {
            display: flex;
            flex-direction: row-reverse;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--p-border-radius-wide, 3px);
        }
    }

    .info-pagination strong {
        display: initial;
    }

    .control-pagination {
        .Polaris-Button--primary {
            position: relative;
            background: #008060;
            border-width: 0;
            border-color: transparent;
        }
    }
`;
