import React, { useState, useCallback, useEffect } from "react";
import { Select, TextField, Button, Icon, InlineError } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import _ from "lodash";

import { ProductBaseSelectPolaris } from "../../base/ProductBaseSelectPolaris";
import { SuppliersSelectPolaris } from "../../members/SuppliersSelectPolaris";
import { TagsSelectPolaris } from "../tags/TagsSelectPolaris";

const Container = styled.div`
  .condition-content {
    display: flex;
    .condition-content-inner {
      display: grid;
      flex-grow: 1;
      grid-gap: 0.8rem;
      grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    }
    .btn-delete {
      margin-left: 0.8rem;
    }
  }
  .error-wrap {
    margin-top: 0.5rem;
  }
`;

export const CollectionConditionsPolaris = ({
  condition,
  showBtnDelete,
  handleClickRemove,
  index,
  onChange,
}) => {
  const [selectedField, setSelectedField] = useState("title");
  const [selectedOperators, setSelectedOperators] = useState("Equal");
  const [value, setValue] = useState("");
  const [errorValue, setErrorValue] = useState(null);
  const [placeholder, setPlaceholder] = useState(null);

  useEffect(() => {
    setSelectedField(condition.field);
    setSelectedOperators(condition.operator);
    setValue(condition.value);
  }, [condition]);

  useEffect(() => {
    if (onChange) {
      onChange(
        {
          field: selectedField,
          operator: selectedOperators,
          value,
        },
        index
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, selectedOperators, value]);

  useEffect(() => {
    const matchedOptions = optionsField.find((option) => {
      return option.value.match(selectedField);
    });
    if (matchedOptions) {
      setPlaceholder(`Enter ${_.toLower(matchedOptions.label)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  const optionsField = [
    { label: "Product title", value: "title" },
    { label: "Product base", value: "product_base_id" },
    { label: "Supplier", value: "user_id" },
    { label: "Product tag", value: "tag_id" },
    { label: "Product regular price", value: "regular_price" },
    { label: "Product sale price", value: "sale_price" },
  ];

  const prices = ["regular_price", "sale_price"];
  const flexible = ["product_base_id", "user_id", "tag_id"];
  const notEqualDisabled = ["tag_id"];
  const startWithDisabled = [...flexible, ...prices];
  const endWithDisabled = [...flexible, ...prices];
  const containDisabled = [...flexible, ...prices];
  const notContainDisabled = [...flexible, ...prices];
  const greaterThanDisabled = ["title", ...flexible];
  const lessThanDisabled = ["title", ...flexible];

  const optionsOperators = [
    {
      value: "Equal",
      label: "is equal to",
    },
    {
      value: "NotEqual",
      label: "is not equal to",
      disabled: notEqualDisabled.includes(selectedField),
    },
    {
      value: "StartWith",
      label: "starts with",
      disabled: startWithDisabled.includes(selectedField),
    },
    {
      value: "EndWith",
      label: "ends with",
      disabled: endWithDisabled.includes(selectedField),
    },
    {
      value: "Contain",
      label: "contains",
      disabled: containDisabled.includes(selectedField),
    },
    {
      value: "NotContain",
      label: "does not contain",
      disabled: notContainDisabled.includes(selectedField),
    },
    {
      value: "GreaterThan",
      label: "is greater than",
      disabled: greaterThanDisabled.includes(selectedField),
    },
    {
      value: "LessThan",
      label: "is less than",
      disabled: lessThanDisabled.includes(selectedField),
    },
  ];

  const handleFieldChange = useCallback((value) => {
    setSelectedField(value);
    handleValueChange(prices.includes(value) ? "0" : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOperatorsChange = useCallback(
    (value) => setSelectedOperators(value),
    []
  );

  const handleInputValue = (value) => {
    if (!value) {
      setErrorValue(true);
    } else {
      setErrorValue(false);
    }
  };
  const handleValueChange = useCallback((value) => {
    setValue(value);
    handleInputValue(value);
  }, []);

  return (
    <Container>
      <div className="condition-content">
        <div className="condition-content-inner">
          <Select
            label="field"
            labelHidden
            options={optionsField}
            onChange={handleFieldChange}
            value={selectedField}
          />
          <Select
            labelHidden
            label="operators"
            options={optionsOperators}
            onChange={handleOperatorsChange}
            value={selectedOperators}
          />
          {!flexible.includes(selectedField) && (
            <TextField
              label="value"
              labelHidden
              value={value}
              onChange={handleValueChange}
              type={prices.includes(selectedField) ? "number" : "text"}
              placeholder={placeholder}
            />
          )}
          {"product_base_id" === selectedField && (
            <ProductBaseSelectPolaris
              onChange={(v) => handleValueChange(v)}
              labelHidden
            />
          )}
          {"user_id" === selectedField && (
            <SuppliersSelectPolaris onChange={(v) => handleValueChange(v)} />
          )}
          {"tag_id" === selectedField && (
            <TagsSelectPolaris
              onChange={(v) => handleValueChange(v)}
              labelHidden
            />
          )}
        </div>
        {showBtnDelete && (
          <div className="btn-delete">
            <Button
              accessibilityLabel="Delete collection"
              icon={<Icon source={DeleteMinor} />}
              onClick={() => handleClickRemove(index)}
            />
          </div>
        )}
      </div>
      <div className="error-wrap">
        {errorValue && (
          <InlineError message="Please enter input." fieldID="myFieldID" />
        )}
      </div>
    </Container>
  );
};
