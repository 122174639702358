import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  TextContainer,
  TextStyle,
  TextField,
  Heading,
  Button,
  Toast,
  DataTable,
  Badge,
} from "@shopify/polaris";
import { EditMajorMonotone, CircleCancelMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import styled, { css } from "styled-components";

import {
  USER_ROLE,
  ORDER_STATUS,
  ORDER_STATUS_COLORS_POLARIS,
} from "../../../variable";
import { checkRole, handleError } from "../../../helper";
import no_image from "../../../assets/images/unnamed.jpeg";

import { CountrySelectPolaris } from "./CountrySelectPolaris";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { OrderDetailTimelinePolaris } from "../actions/OrderDetailTimelinePolaris";

import {
  UPDATE_ORDER,
  UPDATE_EMAIL_CUSTOMER,
} from "../../../graphql/mutations";
// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import useToggle from "../../../hooks/useToggle";
import { useAppContext } from "../../../context";

const QUERY_ORDER_DETAIL = gql`
  query order($id: ID!, $productBaseId: ID!) {
    getOrderDesigns(id: $id, productBaseId: $productBaseId) {
      orderDesigns {
        id
        designPosition {
          id
          name
        }
        file {
          id
          thumbnailUrl
          url
        }
      }
    }
    order(id: $id) {
      id
      quantity
      baseCost
      supplierPrice
      status
      carrierPrice
      fulfillmentStatus
      tracking {
        id
        code
      }
      productVariant {
        id
        image {
          id
          url
        }
        regularPrice
        productBaseVariant {
          id
          productBase {
            id
          }
        }
      }
      product {
        id
        title
        status
        regularPrice
        sku
        mainImageId
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        designPositions {
          id
          file {
            id
            url
          }
        }
      }
      originId
      createdAt
      shippingAddress {
        id
        firstName
        lastName
        company
        phone
        address1
        address2
        city
        country
        state
        postalCode
        cpfOrCnpjNumber
      }
      note
      personalizedPreview
      customer {
        id
        email
      }
      transactionStoreId
    }
  }
`;

const Container = styled.div`
  .shipping-label-wrap {
    font-size: 14px;
    font-weight: 500;
  }
  .shipping-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -0.6rem;
    .shipping-item {
      width: 49%;
      margin-top: 1rem;
    }
  }
  .heading-SA {
    &.edit {
      display: flex;
      .Polaris-Button {
        min-width: 1.75rem;
        min-height: 1.75rem;
        .Polaris-Icon {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
    }
    .Polaris-Button {
      margin-left: 1rem;
      padding: 0;
      min-width: 1.75rem;
      min-height: 1.75rem;
      .Polaris-Icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  .save-change-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
  .product-wrap {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: calc(300px - 3.2rem);
  }
  .history-wrap {
    border-top: var(
      --p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8)
    );
    padding-top: 1rem;
    margin-top: 2.6rem;
  }
  .data-wrap {
    margin-top: 2rem;
    border-top: var(
      --p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8)
    );
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;

export const OrderDetailPolaris = (props) => {
  const { open, toggleShowModal, value, role } = props;
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    company: "",
    postalCode: "",
    address1: "",
    country: "",
    address2: "",
    note: "",
    phone: "",
    cpfOrCnpjNumber: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [rows, setRows] = useState([]);
  const [personalizedPreview, setPersonalizedPreview] = useState(null);

  const productBaseId =
    value &&
    value.productVariant &&
    value.productVariant.productBaseVariant &&
    value.productVariant.productBaseVariant.productBase
      ? value.productVariant.productBaseVariant.productBase.id
      : value && value.product.productBases && value.product.productBases[0].id;

  const [orderDetail, { data, loading, error }] =
    useLazyQuery(QUERY_ORDER_DETAIL);

  const [updateOrder, { data: dataM, loading: loadingM, error: errorM }] =
    useMutation(UPDATE_ORDER, {
      onError: () => {},
      onCompleted: () => {
        toggleEdit();
      },
    });

  useEffect(() => {
    if (productBaseId && value && open) {
      orderDetail({
        variables: {
          id: value.id,
          productBaseId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBaseId, value, data, open]);

  useEffect(() => {
    let order = data && data.order ? data.order : null;
    let orderDesigns =
      data && data.getOrderDesigns && data.getOrderDesigns.orderDesigns
        ? data.getOrderDesigns.orderDesigns
        : [];
    const newOrderDesigns = designPositionsSorting(orderDesigns);

    if (order) {
      let {
        note,
        shippingAddress,
        product,
        quantity,
        fulfillmentStatus,
        status,
        tracking,
        personalizedPreview,
      } = order;

      setPersonalizedPreview(personalizedPreview);
      setState((prevState) => {
        let item = {};
        for (let [key, value] of Object.entries(shippingAddress)) {
          if (key === "country" && value === "United States") {
            value = "US";
          }
          item[key] = value;
        }
        return {
          ...prevState,
          ...item,
          note,
        };
      });
      let title = product && product.title ? product.title : null;
      let sku = product && product.sku ? product.sku : null;

      let baseCost = [USER_ROLE.Supplier].includes(role)
        ? `$${(order.supplierPrice + order.carrierPrice) * order.quantity}`
        : `$${(order.baseCost + order.carrierPrice) * order.quantity}`;
      let newStatus =
        [USER_ROLE.Seller, USER_ROLE.Admin].includes(role) &&
        [
          ORDER_STATUS.ReadyToPrint,
          ORDER_STATUS.Printing,
          ORDER_STATUS.PendingPayment,
        ].includes(status)
          ? ORDER_STATUS.InProduction
          : status;

      const resultStatus =
        newStatus &&
        ORDER_STATUS_COLORS_POLARIS.find((o) => o.name === newStatus);
      let ffStatus;
      if (fulfillmentStatus) {
        ffStatus = ORDER_STATUS_COLORS_POLARIS.find(
          (o) => o.name === fulfillmentStatus,
        );
      }

      // Product Markup
      const mainImageId = product?.mainImageId;
      const images = product?.images;
      let mainImages = [];
      const isCampaign = product?.productBases?.length > 1;
      const haveMainImageId = mainImageId || mainImageId !== "undefined";

      if (productBaseId && isCampaign) {
        mainImages =
          images?.length > 0
            ? images
                .filter((img) => img?.productBaseId === productBaseId)
                .map((item) => item.file)
            : [];
        if (mainImages.length > 1 && haveMainImageId) {
          mainImages =
            images?.length > 0
              ? images
                  .filter((img) => img?.file?.id === mainImageId)
                  .map((item) => item.file)
              : [];
        }
      } else if (haveMainImageId) {
        mainImages =
          images?.length > 0
            ? images
                .filter((img) => img?.file?.id === mainImageId)
                .map((item) => item.file)
            : [];
      }
      if (mainImages.length === 0) {
        mainImages =
          images?.length > 0
            ? images.filter(Boolean).map((item) => item.file)
            : [];
      }
      let newRows = [
        <div className="product-wrap">
          {product && product.images ? (
            <ModalImageClaimPolaris
              files={{
                files: mainImages,
              }}
              limit={1}
              width={120}
              height={120}
            />
          ) : (
            <img alt="" src={no_image} style={{ width: 50, marginRight: 5 }} />
          )}
          <span>{title ? title : ""}</span>
        </div>,
        // ...(personalizedPreview
        //   ? [
        //       <div className="PR-wrap">
        //         <a href={personalizedPreview} alt="" target="_blanket">
        //           <img
        //             src={personalizedPreview}
        //             style={{
        //               width: 100,
        //               height: 100,
        //               objectFit: "cover",
        //             }}
        //           />
        //         </a>
        //       </div>,
        //     ]
        //   : []),
        <div className="sku-wrap">
          <span>{sku}</span>
        </div>,
        <div className="qty-wrap">
          <span>{quantity}</span>
        </div>,
        <div className="design-wrap">
          {newOrderDesigns ? (
            <ModalImageClaimPolaris
              key={value.id}
              files={{
                files: newOrderDesigns,
              }}
              limit={1}
              width={120}
              height={120}
            />
          ) : (
            <img alt="" src={no_image} style={{ width: 50 }} />
          )}
        </div>,
        <div className="cost-wrap">
          <span>{baseCost}</span>
        </div>,
        <div className="status-wrap">
          {resultStatus && !fulfillmentStatus ? (
            <div>
              <Badge
                children={resultStatus.name}
                status={resultStatus.status}
                progress={resultStatus.progress ? resultStatus.progress : null}
              />
            </div>
          ) : (
            <div>
              <Badge
                status={ffStatus?.status}
                children={fulfillmentStatus || status}
              />
            </div>
          )}
        </div>,
        <div className="tracking-code-wrap">
          <span>{tracking && tracking.code ? tracking.code : null}</span>
        </div>,
      ];
      setRows([newRows]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, personalizedPreview]);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const order = data && data.order ? data.order : null;

  const handleChangeInput = useCallback((value, id) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);
  const toggleEdit = useCallback(
    () => setIsEdit((prevState) => !prevState),
    [],
  );

  const handleSubmit = useCallback(() => {
    let id = value && value.id;
    let { __typename, ...rest } = state;
    updateOrder({
      variables: {
        input: {
          ...rest,
          id,
        },
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, value]);

  const toastMarkup = activeToast
    ? (errorM || (dataM && dataM.updateOrder)) && (
        <Toast
          content={
            errorM
              ? handleError(errorM.toString())
              : dataM && dataM.updateOrder && "Update shipping address success."
          }
          error={errorM}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const textField = (id, type) => (
    <TextField
      label="*"
      labelHidden
      id={id}
      onChange={handleChangeInput}
      value={state[id]}
      multiline={type ? 4 : null}
    />
  );
  const shippingForm = [
    {
      id: "firstName",
      label: "First name",
      textField: textField("firstName"),
    },
    {
      id: "city",
      label: "City",
      textField: textField("city"),
    },
    {
      id: "lastName",
      label: "Last name",
      textField: textField("lastName"),
    },
    {
      id: "state",
      label: "State",
      textField: textField("state"),
    },
    {
      id: "company",
      label: "Company",
      textField: textField("company"),
    },
    {
      id: "postalCode",
      label: "Postal code",
      textField: textField("postalCode"),
    },
    {
      id: "address1",
      label: "Address 1",
      textField: textField("address1"),
    },
    {
      id: "country",
      label: "Country",
      textField: (
        <CountrySelectPolaris
          value={state["country"]}
          onChange={(value) =>
            setState((prevState) => ({
              ...prevState,
              country: value,
            }))
          }
        />
      ),
    },
    {
      id: "address2",
      label: "Address 2",
      textField: textField("address2"),
    },
    {
      id: "phone",
      label: "Phone",
      textField: textField("phone"),
    },
    {
      id: "note",
      label: "Custom provided note",
      textField: textField("note", "textArea"),
    },
    {
      id: "cpfOrCnpjNumber",
      label: "CPF/CNPJ number",
      textField: textField("cpfOrCnpjNumber"),
    },
  ];

  const headingSA = (
    <span className={`heading-SA ${isEdit ? "edit" : ""}`}>
      Shipping Address
      {![USER_ROLE.Supplier].includes(role) ? (
        <Button
          icon={isEdit ? CircleCancelMinor : EditMajorMonotone}
          onClick={toggleEdit}
          plain
        />
      ) : null}
    </span>
  );

  let defaultCCT = ["text", "text", "text", "text", "text", "text", "text"];
  let defaultH = [
    "Product",
    "SKU",
    "Qty",
    "Design",
    "Cost",
    "Status",
    "Tracking code",
  ];

  // if (personalizedPreview) {
  //   defaultCCT = [...defaultCCT, "text"];
  //   defaultH = ["Product", "Personalized preview", ...defaultH.slice(1)];
  // }

  let billingEmail = order?.customer?.email;
  let transaction = order?.transactionStoreId;
  if (transaction && transaction.length) {
    transaction = transaction.join(", ");
  }

  return (
    <>
      {toastMarkup}
      <Modal
        title={`Order detail: ${order?.id ? "#" + order.id : ""}`}
        open={open}
        onClose={toggleShowModal}
        sectioned
        large
        primaryAction={{ content: "Done", onAction: toggleShowModal }}
      >
        <Container>
          {loading ? (
            <SkeletonPagePolaris />
          ) : (
            <>
              {error ? (
                <div>Error: {handleError(error.toString())}</div>
              ) : (
                <TextContainer>
                  <div>
                    <div>
                      <TextStyle variation="strong">Order number: </TextStyle>
                      <span>{order && order.originId}</span>
                    </div>
                    <div style={{ marginTop: "0.5rem" }}>
                      <TextStyle variation="strong">Order date: </TextStyle>
                      <span>
                        {order &&
                          moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </div>
                    <BillingEmail value={billingEmail} orderId={value?.id} />
                    <div style={{ marginTop: "0.5rem" }}>
                      <TextStyle variation="strong">Transaction ID: </TextStyle>
                      <span>{transaction}</span>
                    </div>
                  </div>
                  <Heading element="h3" children={headingSA} />
                  <div className="shipping-wrap">
                    {shippingForm.map((s, idx) => (
                      <div key={idx} className="shipping-item">
                        <span className="shipping-label-wrap">
                          <label>{s.label}: </label>
                        </span>
                        {isEdit ? (
                          <>{s.textField}</>
                        ) : (
                          <span>{state[s.id]}</span>
                        )}
                      </div>
                    ))}
                  </div>
                  {isEdit ? (
                    <div className="save-change-wrap">
                      <Button
                        children="Save changes"
                        primary
                        onClick={handleSubmit}
                        loading={loadingM}
                      />
                    </div>
                  ) : null}
                  <div className="data-wrap">
                    <DataTable
                      columnContentTypes={defaultCCT}
                      headings={defaultH}
                      rows={rows}
                      verticalAlign="middle"
                    />
                  </div>
                  <OrderDetailTimelinePolaris id={value.id} />
                </TextContainer>
              )}
            </>
          )}
        </Container>
      </Modal>
    </>
  );
};

function BillingEmail({ value, orderId }) {
  // Status
  const [open, toggleOpen] = useToggle(false);
  const [toastActive, toggleActive] = useToggle(false);
  const [notify, setNotify] = useState({ msg: null, err: false });
  const [email, setEmail] = useState(value);

  // Mutation
  const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL_CUSTOMER, {
    onCompleted: () => {
      setNotify({ msg: "Update email successfully.", err: false });
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  // Handle actions
  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (orderId != null && email != null) {
      toggleActive(true);
      updateEmail({
        variables: {
          orderId,
          email,
        },
      }).then((res) => {
        if (res?.data?.updateEmailCustomer != null) {
          toggleOpen(false);
        }
      });
    }
  }, [updateEmail, toggleOpen, toggleActive, email, orderId]);

  // Markup
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      duration={1200}
      onDismiss={toggleActive}
    />
  );

  return (
    <Wrapper open={open}>
      {toastMarkup}
      <TextStyle variation="strong">Billing email: </TextStyle>
      {open ? (
        <TextField
          value={email}
          id="email"
          onChange={handleEmailChange}
          placeholder="Enter email"
        />
      ) : (
        <span>{value}</span>
      )}
      <Button
        plain
        icon={open ? CircleCancelMinor : EditMajorMonotone}
        onClick={toggleOpen}
      />
      {open && (
        <Button
          primary
          children="Save"
          onClick={handleSubmit}
          loading={loading}
        />
      )}
    </Wrapper>
  );
}

const DESIGN_POSITION_SORTING = [
  "Master",
  "Pocket",
  "Sleeve Left",
  "Sleeve Right",
  "Front",
  "Back",
  "Hood",
  "Full Print",
  "Full Print Back",
  "Sleeves",
];
function designPositionsSorting(orderDesigns) {
  if (!orderDesigns || orderDesigns.length === 0) return [];
  const d = orderDesigns.filter(({ file }) => file != null);
  const res = [];

  for (let reg of DESIGN_POSITION_SORTING) {
    const pt = new RegExp(`^${reg}$`, "i");
    const matched = d.find(({ designPosition }) => {
      const { name } = designPosition || {};
      return pt.test(name);
    });

    if (matched) {
      res.push(matched);
    }
  }

  const ids = res.map(({ id }) => id);
  for (let item of d) {
    if (!ids.includes(item.id)) {
      res.push(item);
    }
  }

  return res.map(({ file }) => file);
}

const Wrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 0.5rem;

  > div {
    max-width: 35rem;
    width: 100%;
  }

  ${({ open }) =>
    !open &&
    css`
      .Polaris-Button {
        min-width: 1.75rem;
        min-height: 1.75rem;

        .Polaris-Icon {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    `}
`;
