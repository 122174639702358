import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

const Container = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
  }
  .ant-checkbox-group-item {
    width: 15%;
    padding: 10px;
  }
  @media only screen and (max-width: 768px) {
    .ant-checkbox-group-item {
      width: 20%;
    }
  }
  @media only screen and (max-width: 768px) {
    .ant-checkbox-group-item {
      width: 20%;
    }
  }
  @media only screen and (max-width: 640px) {
    .ant-checkbox-group-item {
      width: 40%;
    }
  }
`;

export default class ScreenOptionsFA extends React.Component {
  onChange = (v) => {
    if (this.props.onChange) {
      this.props.onChange(v);
    }
  };

  comparePriority = (a, b) => {
    const priorityA = a && a.priority ? a.priority : 0;
    const priorityB = b && b.priority ? b.priority : 0;
    let comparison = 0;
    if (priorityA > priorityB) {
      comparison = 1;
    } else if (priorityA < priorityB) {
      comparison = -1;
    }
    return comparison;
  };

  render() {
    let { defaultValue } = this.props;
    let options = [
      { label: "Product", value: "product", priority: 10 },
      { label: "Origin Domain", value: "originDomain", priority: 15 },
      { label: "Niche", value: "niche", priority: 20 },
      { label: "Source", value: "source", priority: 25 },
      { label: "Created At", value: "createdAt", priority: 30 },
      { label: "Author", value: "author", priority: 35 },
    ];
    options = options.sort(this.comparePriority);

    return (
      <Container className={this.props.className}>
        <Checkbox.Group
          options={options}
          onChange={this.onChange}
          value={defaultValue}
        />
      </Container>
    );
  }
}
