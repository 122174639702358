import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Sheet,
  Scrollable,
  Heading,
  Button,
  Banner,
  Checkbox,
  Icon,
  Collapsible,
} from "@shopify/polaris";
import {
  ChevronUpMinor,
  ChevronDownMinor,
  MobileCancelMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import _ from "lodash";

import { toSlug } from "../../helper";
import { ColorsComponent } from "../seller/ColorsComponent";

const Container = styled.div``;

const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .mb-simple-choice {
    .mb-simple-label {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgb(223, 227, 232);
    }
  }
  .variants-wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
    > div:first-child {
      margin-top: -1.6rem;
    }
    .label-wrap {
      display: flex;
      align-items: center;
      padding: 1.4rem 1.6rem;
      margin: 0 -1.6rem;
      &.open {
        background-image: linear-gradient(
          rgba(223, 227, 232, 0.3),
          rgba(223, 227, 232, 0.3)
        );
      }
    }
    .Polaris-Filters__FilterTrigger {
      padding: 0 0.5rem 0 1.2rem;
      .label-inner {
        display: flex;
        justify-content: space-between;
      }
      &:focus,
      &:hover {
        box-shadow: none;
        background-image: none;
        background-color: none;
      }
    }
    .Polaris-Collapsible {
      &.Polaris-Collapsible--open {
        &:after {
          content: "";
          bottom: 0;
          position: relative;
          left: 0;
          width: 100%;
          height: 0.1rem;
          background-color: #dfe3e8;
          background-color: var(--p-border-subdued, #dfe3e8);
          display: block;
        }
      }
    }
    .content-wrap {
      display: grid;
      padding: 0.75rem 1rem;
      grid-template-columns: 50% 50%;
    }
  }
  .pb-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.6rem;
    display: inline-block;
  }
`;

export const SetAttributesCTPolaris = (props) => {
  const {
    productBases,
    activateVariants,
    onChange,
    simpleChecked,
    simpleItems,
    groupChecked,
    groupItems,
  } = props;
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [pBaseAttributes, setPBaseAttributes] = useState([]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleOpen();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up.
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    let attributes = [];
    if (productBases && productBases.length) {
      productBases.forEach((pb) => {
        if (pb.attributes && pb.attributes.length) {
          pb.attributes.forEach((i) => {
            attributes.push(i);
          });
        }
      });
    }
    setPBaseAttributes(attributes);
  }, [productBases]);

  const toggleOpen = useCallback(() => setOpen((prevState) => !prevState), []);
  const handleToggle = useCallback((value) => {
    setIsActive(value);
  }, []);

  let validColorAttrs = ["color", "colors"];
  const MaybeDisplayColor = (attributeSlug, valSlug) => {
    if (attributeSlug && valSlug) {
      attributeSlug = attributeSlug.toLowerCase();
      if (validColorAttrs.includes(attributeSlug)) {
        return <ColorsComponent optionSlug={valSlug} />;
      }
    }
    return null;
  };

  const onSimpleActivateItemChanged = (id) => {
    let activeVariants = activateVariants ? activateVariants : [];
    if (id) {
      if (activeVariants.includes(id)) {
        activeVariants = activeVariants.filter((el) => {
          return el !== id;
        });
      } else {
        activeVariants.push(id);
      }
    }

    if (onChange) {
      onChange({ activeVariants });
    }
  };

  const onSimpleBulkActivateItemsChanged = (value, productBase, slug) => {
    let isChecked = value;
    let activatedIds = [];
    let newSimpleChecked = [];
    let newSimpleItems = simpleItems ? simpleItems : [];
    if (simpleChecked && simpleChecked.length > 0) {
      newSimpleChecked = simpleChecked;
    }

    if (productBase && productBase.variants) {
      let productbaseVariants = productBase.variants;
      if (productbaseVariants.length > 0) {
        let variantIds =
          productbaseVariants && productbaseVariants.length > 0
            ? productbaseVariants.map((i) => i.id)
            : [];
        if (isChecked) {
          if (!newSimpleChecked.includes(slug)) {
            newSimpleChecked.push(slug);
            newSimpleItems[slug] = variantIds;
            activatedIds = [...variantIds, ...activateVariants];
          }
        } else {
          if (newSimpleChecked.includes(slug)) {
            newSimpleChecked = newSimpleChecked.filter((el) => {
              return el !== slug;
            });
            activatedIds = activateVariants.filter(
              (i) => !variantIds.includes(i),
            );
            newSimpleItems[slug] = [];
          }
        }
      }
    }

    // Simplge checked
    if (onChange) {
      onChange({
        activeVariants: activatedIds,
        simpleChecked: newSimpleChecked,
        simpleItems: newSimpleItems,
        groupItems,
        groupChecked,
      });
    }
  };

  const onComplexActivateItemChanged = (id, groupSlug) => {
    let newActivateVariants = activateVariants ? activateVariants : [];
    let newGroupItems = groupItems ? groupItems : [];
    let stateGroupItems =
      newGroupItems &&
      newGroupItems[groupSlug] &&
      newGroupItems[groupSlug].length > 0
        ? newGroupItems[groupSlug]
        : [];
    let newGroupChecked = [];
    if (groupChecked && groupChecked.length > 0) {
      newGroupChecked = groupChecked;
    }

    if (id) {
      if (newActivateVariants.includes(id)) {
        newActivateVariants = newActivateVariants.filter((el) => {
          return el !== id;
        });
        stateGroupItems = stateGroupItems.filter((el) => {
          return el !== id;
        });
      } else {
        newActivateVariants.push(id);
        stateGroupItems.push(id);
      }
    }
    //let state
    newGroupItems[groupSlug] = stateGroupItems;

    if (stateGroupItems.length > 0) {
      if (!newGroupChecked.includes(groupSlug)) {
        newGroupChecked.push(groupSlug);
      }
    } else {
      if (newGroupChecked.includes(groupSlug)) {
        newGroupChecked = newGroupChecked.filter((el) => {
          return el !== groupSlug;
        });
      }
    }
    if (onChange) {
      onChange({
        activeVariants: newActivateVariants,
        groupChecked: newGroupChecked,
        groupItems: newGroupItems,
      });
    }
  };

  const onComplexBulkActivateItemsChanged = (slug, innerGroupItems, value) => {
    let isChecked = value;
    let newGroupChecked = [];
    let newActivateVariants = activateVariants ? activateVariants : [];
    let newGroupItems = groupItems ? groupItems : [];
    if (groupChecked && groupChecked.length > 0) {
      newGroupChecked = groupChecked;
    }
    if (Array.isArray(innerGroupItems) && innerGroupItems.length > 0) {
      if (isChecked) {
        if (!newGroupChecked.includes(slug)) {
          newGroupChecked.push(slug);
          newActivateVariants = newActivateVariants.concat(innerGroupItems);
          newGroupItems[slug] = innerGroupItems;
        }
      } else {
        if (newGroupChecked.includes(slug)) {
          newGroupChecked = newGroupChecked.filter((el) => {
            return el !== slug;
          });
          newActivateVariants = newActivateVariants.filter((el) => {
            return !innerGroupItems.includes(el);
          });
          newGroupItems[slug] = [];
        }
      }

      if (onChange) {
        onChange({
          activeVariants: newActivateVariants,
          groupChecked: newGroupChecked,
          groupItems: newGroupItems,
          simpleChecked,
        });
      }
    }
  };

  const ActiveVariants = ({ productBase }) => {
    let displayCase = "simple";
    let panels = [];
    let pBTitle = productBase.title;

    if (
      productBase &&
      productBase.attributes &&
      productBase.attributes.length > 0
    ) {
      let pBVariants = productBase.variants;
      let pBAttrs = productBase.attributes;
      let activeVariants = [];

      if (activateVariants) {
        activeVariants = activateVariants;
      }

      let countAttrs = pBAttrs.length;
      let attributeInfo = pBAttrs[0];

      if (countAttrs > 1) {
        // more than 1 attributes
        _.map(pBAttrs, function (item) {
          let attName = item.name;
          attName = attName.toLowerCase();
          if (
            validColorAttrs.includes(attName) &&
            item.options &&
            item.options.length > 0
          ) {
            attributeInfo = item;
          }
        });
      }

      let mainSlug = attributeInfo.slug;
      let mainName = attributeInfo.name;

      if (countAttrs > 1) {
        displayCase = "complex";
        _.map(attributeInfo.options, function (value) {
          let groupItems = [];
          let valSlug = toSlug(value);
          let slugMultiPB = toSlug(`${value} ${pBTitle}`);
          let aviableOptions = [];

          if (pBVariants.length > 0) {
            _.map(pBVariants, function (val) {
              if (val.attributes && val.attributes.length > 0) {
                let itemLabel = [];
                let compareLabel = "";
                let compareId = "";
                _.map(val.attributes, function (v) {
                  let varOption = v.option;
                  if (v.slug === mainSlug && value === varOption) {
                    // Exactly attribute to compare
                    compareLabel = varOption;
                    compareId = val.id;
                  } else {
                    // get name
                    itemLabel.push(varOption);
                  }
                });
                if (
                  compareLabel &&
                  compareLabel.length > 0 &&
                  itemLabel.length > 0
                ) {
                  let optionComp = (
                    <div key={compareId} className="mb-w-50">
                      <Checkbox
                        onChange={() =>
                          onComplexActivateItemChanged(compareId, slugMultiPB)
                        }
                        checked={
                          activateVariants.length > 0 &&
                          activateVariants.includes(compareId)
                            ? true
                            : false
                        }
                        label={itemLabel.join(" / ")}
                      />
                    </div>
                  );
                  aviableOptions.push(optionComp);
                  groupItems.push(compareId);
                }
              }
            });
          }
          let onePanel = (
            <div key={`option-${value}`}>
              <div
                className={`label-wrap ${
                  expanded[valSlug] && valSlug === isActive ? "open" : ""
                }`}
              >
                <Checkbox
                  onChange={(value) =>
                    onComplexBulkActivateItemsChanged(
                      slugMultiPB,
                      groupItems,
                      value,
                    )
                  }
                  checked={groupChecked && groupChecked.includes(slugMultiPB)}
                  label={"checkbox"}
                  labelHidden
                />
                <button
                  id={`${valSlug}ToggleButton`}
                  onClick={() => {
                    handleToggle(valSlug);
                    setExpanded((prevState) => {
                      let value = null;
                      if (undefined !== prevState[valSlug]) {
                        value = !prevState[valSlug];
                      } else {
                        value = true;
                      }

                      return {
                        [valSlug]: value,
                      };
                    });
                  }}
                  aria-expanded={expanded[valSlug]}
                  aria-controls={`${valSlug}ToggleButton`}
                  className={"Polaris-Filters__FilterTrigger"}
                >
                  <div className="label-inner">
                    <h2 style={{ display: "flex", alignItems: "center" }}>
                      {MaybeDisplayColor(mainName, valSlug)}
                      {value}
                    </h2>
                    <span>
                      <Icon
                        source={
                          expanded[valSlug] && valSlug === isActive
                            ? ChevronUpMinor
                            : ChevronDownMinor
                        }
                        color="inkLightest"
                      />
                    </span>
                  </div>
                </button>
              </div>
              <Collapsible
                id={`collap -${valSlug}`}
                open={expanded[valSlug] && valSlug === isActive}
                transition={{ duration: "150ms", timingFunction: "ease" }}
              >
                <div className="content-wrap">{aviableOptions}</div>
              </Collapsible>
            </div>
          );
          panels.push(onePanel);
        });
      } else {
        let aviableOptions = [];
        let slug = pBAttrs[0].slug;
        slug = toSlug(`${slug} ${pBTitle}`);

        if (pBVariants.length > 0) {
          pBVariants = _.sortBy(pBVariants, [
            function (item) {
              return item.attributes[0].option;
            },
          ]);
          _.map(pBVariants, function (val) {
            let variableAttr = val.attributes[0];
            let optionComp = (
              <div key={val.id}>
                <Checkbox
                  onChange={() => onSimpleActivateItemChanged(val.id)}
                  checked={
                    activeVariants.length > 0 && activeVariants.includes(val.id)
                      ? true
                      : false
                  }
                  label={
                    <>
                      {MaybeDisplayColor(mainName, toSlug(variableAttr.option))}
                      {variableAttr.option}
                    </>
                  }
                />
              </div>
            );
            aviableOptions.push(optionComp);
          });
        }

        let onePanel = (
          <div className="mb-simple-choice" key="simple-panel">
            <div className="mb-simple-label">
              <Checkbox
                checked={simpleChecked && simpleChecked.includes(slug)}
                onChange={(value) =>
                  onSimpleBulkActivateItemsChanged(value, productBase, slug)
                }
                label={mainName}
              />
            </div>

            <div className="mb-simple-options">{aviableOptions}</div>
          </div>
        );
        panels.push(onePanel);
      }
    }
    if ("simple" === displayCase) {
      return (
        <div>
          <label className="pb-title">{pBTitle}</label>
          {panels}
        </div>
      );
    } else {
      return (
        <div className="complex-wrap">
          <label className="pb-title">{pBTitle}</label>
          <div className={"variants-wrap"}>{panels}</div>
        </div>
      );
    }
  };

  return (
    <Container>
      {pBaseAttributes && pBaseAttributes.length > 0 ? (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <Button plain onClick={toggleOpen}>
              Set more active items
            </Button>
          </div>
          <div>
            {activateVariants && activateVariants.length > 0 ? null : (
              <Banner status="critical">
                <p>Please choose at least one active item</p>
              </Banner>
            )}
          </div>
        </>
      ) : null}
      <Sheet open={open} onClose={toggleOpen}>
        <SheetContainer ref={wrapperRef}>
          <div
            style={{
              alignItems: "center",
              borderBottom: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.6rem",
              width: "100%",
            }}
          >
            <Heading>Set more active items</Heading>
            <Button
              accessibilityLabel="Cancel"
              icon={MobileCancelMajorMonotone}
              onClick={toggleOpen}
              plain
            />
          </div>
          <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
            {productBases && productBases.length > 0
              ? productBases.map((pb, idx) => (
                  <React.Fragment key={idx}>
                    <ActiveVariants productBase={pb} />
                  </React.Fragment>
                ))
              : null}
          </Scrollable>
        </SheetContainer>
      </Sheet>
    </Container>
  );
};

export default SetAttributesCTPolaris;
