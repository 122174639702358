import React, { memo, useCallback, useEffect, useState } from "react";
import { Card } from "@shopify/polaris";
import { useDrop } from "react-dnd";
import update from "immutability-helper";

import { useWidgetContext, ActionTypes, Group } from "../context";
import { Type } from "../index";
import { Item } from "./Item";

export const Section = memo(function Section({ group, header }) {
    // Context
    const [state, dispatch] = useWidgetContext();

    // Props
    const currentGroup = state[group];

    // State
    const [cards, setCards] = useState([]);

    // Get data;
    useEffect(() => {
        if (currentGroup != null) {
            setCards(currentGroup);
        }
    }, [currentGroup]);

    const handleDrop = useCallback(
        (item) => {
            if (item.group === group) return;
            const value = {
                value: item.id,
                label: item.label,
                component: item.component,
            };

            dispatch({
                type: ActionTypes.RegisterCard,
                group: group,
                value,
            });

            dispatch({
                type: ActionTypes.UnregisterCard,
                group: item.group,
                value: item.id,
            });
        },
        [group, dispatch]
    );

    const [{ isOver, item }, drop] = useDrop({
        accept: Type,
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            item: monitor.getItem(),
        }),
    });

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = cards[dragIndex];
            const updated = update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });

            dispatch({
                type: ActionTypes.Update,
                group: group,
                values: updated,
            });
        },
        [cards, group, dispatch]
    );

    const handleClick = useCallback(
        (card) => {
            const value = {
                value: card.value,
                label: card.label,
                component: card.component,
            };

            dispatch({
                type: ActionTypes.RegisterCard,
                group: Group.All,
                value,
            });

            dispatch({
                type: ActionTypes.UnregisterCard,
                group: group,
                value: card.value,
            });
        },
        [group, dispatch]
    );

    const renderCard = (card, index) => {
        if (!card) return null;
        return (
            <Item
                type={Type}
                key={card.value}
                index={index}
                id={card.value}
                label={card.label}
                moveCard={moveCard}
                group={group}
                component={card.component}
                handleClick={() => handleClick(card)}
            />
        );
    };

    const skeletonMarkup = <div style={{ paddingTop: "4rem" }}></div>;
    const showSkeleton = isOver && item?.group !== group;

    return (
        <div className="Polaris-Card Polaris-Card--subdued" ref={drop}>
            {header && <Card.Header title={header} />}
            <div className="Polaris-Card__Section">
                {cards.map((card, i) => renderCard(card, i))}
                {showSkeleton && skeletonMarkup}
            </div>
        </div>
    );
});
