import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { handleError } from "../../../helper";
import history from "../../../history";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { FulfillmentFormPolaris } from "./FulfillmentFormPolaris";

const FULFILLMENT_BY_ID = gql`
  query fulfillmentById($id: ID!, $filter: FulfillmentProductByIDFilter!) {
    fulfillmentById(id: $id) {
      id
      name
      slug
    }
    fulfillmentProductById(filter: $filter) {
      total
      nodes {
        id
        title
        originId
        status
      }
    }
  }
`;

const UPDATE_FULFILLMENT = gql`
  mutation updateFulfillment($input: EditFulfillment!) {
    updateFulfillment(input: $input) {
      id
      name
      slug
      products {
        originId
        title
        status
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const EditFulfillmentPolaris = () => {
  // Props
  const { id } = useParams();

  // State
  const [filter, setFilter] = useState({
    limit: 200,
    offset: 0,
    search: "",
  });
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Query
  const { data, loading, error } = useQuery(FULFILLMENT_BY_ID, {
    variables: {
      id,
      filter: {
        ...filter,
        id,
      },
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  const [updateFulfillment, { loading: loadingU }] = useMutation(
    UPDATE_FULFILLMENT,
    {
      onCompleted: () => {
        setNotification({
          message: "Update fulfillment successfuly.",
          isError: false,
        });
        const id = setTimeout(() => {
          handleRedirect();
        }, 2100);
        setTimeoutId(id);
      },
      onError: () => {
        setNotification({
          message: "Something is wrong.",
          isError: true,
        });
      },
    },
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle action
  const handleRedirect = useCallback(() => {
    history.push("/admin/fulfillment");
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback(
    (input) => {
      setNotification({
        message: null,
        isError: false,
      });
      if (input) {
        updateFulfillment({
          variables: {
            input: {
              id,
              ...input,
            },
          },
        });
      }
      toggleActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Edit fulfillment"
        breadcrumbs={[
          { content: "Manage fulfillment", url: "/admin/fulfillment" },
        ]}
      >
        {false ? (
          <Card>
            <SkeletonPagePolaris />
          </Card>
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : (
          <FulfillmentFormPolaris
            onSubmit={handleSubmit}
            handleRedirect={handleRedirect}
            data={data}
            filter={filter}
            loading={loadingU}
            isEditFF
            loadingQuery={loading}
            total={data?.fulfillmentProductById?.total || 0}
            setFilter={setFilter}
            onChange={(offset, limit) =>
              setFilter((prev) => ({ ...prev, offset, limit }))
            }
          />
        )}
      </Page>
    </Container>
  );
};
