import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import isEqual from "lodash/isEqual";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { FilterProductBasePolaris } from "../../admin/FilterProductBasePolaris";
import { ProductBasesTableDataPolaris } from "../../base/ProductBasesTableDataPolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { GET_PRODUCT_BASES } from "./graphqls";

const FIELD_FILTER = [...COMMON_FILTER, "fulfillmentId", "status"];

export function ProductBases({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initF = {
      ...convertStringToObject(history.location.search),
    };
    if (initF) {
      let limit = initF.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initF.limit = limit;

      if (initF.paged) {
        initF.offset = convertToOffset(initF.limit, Math.round(initF.paged));
      }
      if (initF.status) {
        if (initF.status === "Publish") {
          initF.status = true;
        } else if (initF.status === "Draft") {
          initF.status = false;
        }
      }
      initF = removeFieldWithoutFilter(initF, FIELD_FILTER);
    }
    return initF;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductBase")) || 20,
    offset: 0,
    search: null,
    fulfillmentId: null,
    status: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(GET_PRODUCT_BASES, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });
  // let data, loading, error;

  useEffect(() => {
    let { offset, limit, status, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    const convertS =
      typeof status === "boolean" ? (status ? "Publish" : "Draft") : null;
    params = convertObjectToParams({
      limit,
      paged,
      status: convertS,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = React.useCallback(
    ({ search, fulfillmentId, status }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.fulfillmentId, fulfillmentId) ||
          !isEqual(prev.status, status)
        ) {
          return {
            ...prev,
            search,
            fulfillmentId,
            status,
            offset: 0,
          };
        }
        return prev;
      });
    },
    [],
  );

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <FilterProductBasePolaris
          filter={filter}
          onChange={handleFilterChange}
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.productBases?.nodes?.length > 0 ? (
          <ProductBasesTableDataPolaris
            data={data}
            filter={filter}
            refetch={refetch}
            setFilter={(offset, limit) => {
              setFilter((prev) => ({ ...prev, offset, limit }));
              setCookie("perPageProductBase", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
}
