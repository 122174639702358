import React, { useState, useCallback } from "react";
import {
  Card,
  TextField,
  ChoiceList,
  Button,
  Form,
  FormLayout,
} from "@shopify/polaris";
import { trim, get, split } from "lodash";
import styled from "styled-components";

import { validateUrl, decodeCSV } from "../../helper";
import { CrawlerFilePolaris } from "./CrawlerFilePolaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { XPathSelectorPolaris } from "./XPathSelectorPolaris";

const Container = styled.div`
  .Polaris-ChoiceList__Choices {
    display: flex;
    column-gap: 2rem;
  }
`;

export const ProductCrawlerFormPolaris = (props) => {
  // Props
  const { onSubmit, loading, onNotificationChange, toggleActive } = props;

  //State
  const [title, setTitle] = useState(null);
  const [type, setType] = useState(["Struct"]);
  const [files, setFiles] = useState([]);
  const [titleXPath, setTitleXPath] = useState(null);
  const [imageXPath, setImageXPath] = useState(null);
  const [items, setItems] = useState([]);
  const [crawler, setCrawler] = useState({});
  const [errors, setErrors] = useState({
    title: null,
    file: null,
    titleXPath: null,
    imageXPath: null,
  });

  // Handle action
  /// Handle field value
  const handleTitleValue = useCallback((value) => {
    let t = null;
    if (!value) {
      t = "Title is required.";
    }
    setErrors((prev) => ({
      ...prev,
      title: t,
    }));
  }, []);
  const handleFileValue = useCallback((value) => {
    let f = null;
    if (!value.length) {
      f = "Crawler is required.";
    }
    setErrors((prev) => ({
      ...prev,
      file: f,
    }));
  }, []);
  const handleTitleXPathValue = useCallback((value) => {
    let t = null;
    if (!value) {
      t = "TitleXPath is required.";
    }
    setErrors((prev) => ({
      ...prev,
      titleXPath: t,
    }));
  }, []);
  const handleImageXPathValue = useCallback((value) => {
    let i = null;
    if (!value) {
      i = "ImageXPath is required.";
    }
    setErrors((prev) => ({ ...prev, imageXPath: i }));
  }, []);

  const handleTitleChange = useCallback((value) => {
    setTitle(value);
    handleTitleValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = useCallback(
    (selected) => {
      setType(selected);
      // calculateItems(files);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  const handleSubmit = useCallback(() => {
    handleTitleValue(title);
    handleFileValue(files);
    handleTitleXPathValue(titleXPath);
    handleImageXPathValue(imageXPath);
    if (toggleActive) {
      toggleActive();
    }

    let theItems = calculateItems(files);
    if (!theItems || theItems.length === 0) {
      if (onNotificationChange) {
        onNotificationChange({
          message: "No urls for crawl. Please check crawler file again.",
          isError: true,
        });
      }
      return;
    } else {
      if (onNotificationChange) {
        onNotificationChange({
          message: null,
          isError: false,
        });
      }
    }

    let newType = type[0];
    let isTypeUrl = newType === "Url";
    theItems =
      theItems.length > 0 ? theItems.map((i) => ({ ...i, ...crawler })) : [];

    let input = {
      title,
      description: null,
      type: newType,
      domain: isTypeUrl ? theItems[0]?.url : theItems[0]?.imageUrl,
      items: theItems,
      files: files.map((f) => f?.file?.id),
    };

    // Check can submit
    let canSubmit = false;
    if (isTypeUrl) {
      if (title && files.length && titleXPath && imageXPath) {
        canSubmit = true;
      }
    } else {
      if (title && files.length) {
        canSubmit = true;
      }
    }
    if (canSubmit) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, title, titleXPath, imageXPath, type, files, crawler]);

  /// Handle process url
  const processUrls = useCallback(
    (files) => {
      let items = [];
      let mapByUrl = {};

      for (let i = 0; i < files.length; i++) {
        const text = files[i].data;
        const lines = text.split(/[\r\n]+/g);
        let hasItem = false;
        for (let j = 0; j < lines.length; j++) {
          const url = lines[j].trim().toLowerCase();
          if (validateUrl(url)) {
            if (typeof mapByUrl[url] === "undefined") {
              mapByUrl[url] = true;
              hasItem = true;
              items.push({
                url: url,
                title: null,
                titleXpath: titleXPath,
                imageXpath: imageXPath,
                imageUrl: null,
                productBaseSlug: null,
                type: "Url",
              });
            }
          }
        }
        if (!hasItem) {
          if (onNotificationChange) {
            onNotificationChange({
              message: `File ${files[i]?.file?.name} contain wrong urls`,
              isError: true,
            });
          }
        }
      }
      setItems(() => items);
      return items;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titleXPath, imageXPath]
  );

  const rocessStructUrls = useCallback((files) => {
    let items = [];
    let mapByUrl = {};
    for (let i = 0; i < files.length; i++) {
      const text = files[i].data;
      if (text) {
        let moreItems = getStructItemByText(text, mapByUrl);
        if (moreItems.length === 0) {
          if (onNotificationChange) {
            onNotificationChange({
              message: `File ${files[i]?.file?.name} contain wrong urls`,
              isError: true,
            });
          }
        }
        items = [...items, ...moreItems];
      }
    }
    setItems(() => items);
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStructItemByText = (text, mapByUrl) => {
    let items = [];
    let arr = decodeCSV(text);
    for (let i = 0; i < arr.length; i++) {
      const line = arr[i];
      const title = trim(get(line, "[0]", null));
      const imageUrl = trim(get(line, "[1]", null));
      if (typeof mapByUrl[imageUrl] !== "undefined" || !validateUrl(imageUrl)) {
        continue;
      }
      const productBaseSlug = get(line, "[2]", "");
      const templateSlug = get(line, "[3]");
      const collections = split(get(line, "[4]", []), ",").map((c) => trim(c));
      const tags = split(get(line, "[5]", []), ",").map((t) => trim(t));
      let storeUrl = get(line, "[6]", null);
      if (storeUrl) {
        storeUrl = trim(storeUrl);
      }
      if (storeUrl === "site_crawler") {
        storeUrl = null;
      }
      let storeProductId = get(line, "[7]", null);
      if (storeProductId) {
        storeProductId = trim(storeProductId);
      }
      if (storeProductId === "0") {
        storeProductId = null;
      }
      let designFiles = get(line, "[8]", null);
      const originSku = get(line, "[9]", null);
      const relateSku = get(line, "[10]", null);
      if (designFiles) {
        designFiles = JSON.parse(designFiles);
      }

      if (title && imageUrl && (productBaseSlug || templateSlug)) {
        mapByUrl[imageUrl] = imageUrl;
        items.push({
          title,
          imageUrl,
          productBaseSlug,
          templateSlug,
          type: "Struct",
          tags,
          collections,
          storeUrl,
          storeProductId,
          designFiles: designFiles ? designFiles : null,
          originSku,
          relateSku,
        });
      }
    }
    return items;
  };

  const calculateItems = useCallback(
    (files) => {
      if (files && files.length) {
        if (type[0] === "Url") {
          return processUrls(files);
        } else {
          return rocessStructUrls(files);
        }
      } else {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, titleXPath, imageXPath]
  );

  // Variables
  let url = items && items.length > 0 ? items[0].url : null;

  return (
    <Container>
      <Card sectioned>
        <Form>
          <FormLayout>
            <div>
              <ComponentLabelPolaris label="Title" required />
              <TextField
                label="Title(*)"
                labelHidden
                placeholder="Enter crawler title"
                value={title}
                onChange={handleTitleChange}
                error={errors.title}
              />
            </div>
            <ChoiceList
              title="Type"
              selected={type}
              onChange={handleTypeChange}
              choices={[
                { value: "Struct", label: "Product Base Struct" },
                { value: "Url", label: "Only Urls" },
              ]}
            />
            <CrawlerFilePolaris
              onChange={(newFiles) => {
                calculateItems(newFiles);
                setFiles(() => newFiles);
                handleFileValue(newFiles);
                if (toggleActive) {
                  toggleActive();
                }
              }}
              error={errors.file}
              onNotificationChange={onNotificationChange}
              toggleActive={toggleActive}
              label={"Crawler file"}
              isCrawler={props.isCrawler}
            />
            {type[0] === "Url" && (
              <div>
                <ComponentLabelPolaris label="Title XPath" required />
                <XPathSelectorPolaris
                  value={titleXPath}
                  url={url}
                  crawler={crawler}
                  error={errors.titleXPath}
                  onChange={(value) => {
                    setTitleXPath(value);
                    handleTitleXPathValue(value);
                  }}
                  onChangeAttribute={(v) => {
                    setCrawler((prev) => ({
                      ...prev,
                      titleXpathAttribute: v,
                    }));
                  }}
                  onChangeItems={(items) => {
                    setCrawler((prev) => ({
                      ...prev,
                      titleReplacement: items,
                    }));
                  }}
                  onNotificationChange={onNotificationChange}
                  toggleActive={toggleActive}
                />
              </div>
            )}
            {type[0] === "Url" && (
              <div>
                <ComponentLabelPolaris label="Image XPath" required />
                <XPathSelectorPolaris
                  isImage
                  crawler={crawler}
                  url={url}
                  value={imageXPath}
                  error={errors.imageXPath}
                  onChange={(value) => {
                    setImageXPath(value);
                    handleImageXPathValue(value);
                  }}
                  onChangeAttribute={(v) => {
                    setCrawler((prev) => ({
                      ...prev,
                      imageXpathAttribute: v,
                    }));
                  }}
                  onChangeItems={(items) => {
                    setCrawler((prev) => ({
                      ...prev,
                      imageReplacement: items,
                    }));
                  }}
                  onNotificationChange={onNotificationChange}
                  toggleActive={toggleActive}
                />
              </div>
            )}
            <Button
              children="Process now"
              primary
              onClick={handleSubmit}
              loading={loading}
            />
          </FormLayout>
        </Form>
      </Card>
    </Container>
  );
};
