import React, { useCallback, useState, useEffect } from "react";
import { Toast, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import history from "../../../history";

import { handleError } from "../../../helper";
import { ExportTemplateFormPolaris } from "./ExportTemplateFormPolaris";

const EDIT_EXPORT_TEMPLATE = gql`
  mutation editExportTemplate($input: EditExportTemplate!) {
    editExportTemplate(input: $input) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      author {
        id
      }
    }
  }
`;

const EXPORT_TEMPLATE_DEFAULT = gql`
  query exportTemplateById($id: ID!) {
    exportTemplateById(id: $id) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      columns {
        name
        type
        value
      }
      author {
        id
      }
    }
  }
`;

export const EditExportTemplatePolaris = () => {
  // Prop
  const { id } = useParams();

  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isErorr: false,
  });

  // Query
  const { loading: loadingQ, data } = useQuery(EXPORT_TEMPLATE_DEFAULT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  const [editExportTemplate, { loading }] = useMutation(EDIT_EXPORT_TEMPLATE, {
    onCompleted: () => {
      setNotification({
        message: "Update export template successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle action
  const handleRedirect = useCallback(() => {
    history.push("/admin/manager-files/export-templates");
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handSubmit = useCallback(
    (input) => {
      setNotification({
        message: null,
        isError: false,
      });
      if (input && id) {
        editExportTemplate({
          variables: {
            input: {
              id,
              ...input,
            },
          },
        });
      }
      toggleActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  // Markup
  const loadingMarkup = loadingQ && <Loading />;
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <React.Fragment>
      {toastMarkup}
      {loadingMarkup}
      <ExportTemplateFormPolaris
        value={data?.exportTemplateById}
        isAdmin
        loading={loading}
        onSubmit={handSubmit}
        handleRedirect={handleRedirect}
      />
    </React.Fragment>
  );
};
