import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Tag, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { getUnique } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export const QUERY_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
        email
        domain
        suspended
      }
    }
  }
`;

const LabelContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export const AccountSelectPolaris = (props) => {
  const { onChange, error, value } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);

  const { data: dataAmazon, loading: loadingAmazon } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        limit: 3000,
        offset: 0,
        platformSelected: ["amazon"],
      },
    },
  });
  // const { data: dataEbay, loading: loadingEbay } = useQuery(QUERY_STORES, {
  //   variables: {
  //     filter: {
  //       limit: 1000,
  //       offset: 0,
  //       platformSelected: ["ebay"],
  //     },
  //   },
  // });
  // const { data: dataOS, loading: loadingOS } = useQuery(QUERY_STORES, {
  //   variables: {
  //     filter: {
  //       limit: 1000,
  //       offset: 0,
  //       platformSelected: ["woocommerce", "shopify", "shopbase"],
  //     },
  //   },
  // });

  useEffect(() => {
    // Get data amazon store
    let originOptions = [
      // {
      //   value: "amazon",
      //   label: <LabelContainer>--- Amazon ---</LabelContainer>,
      //   disabled: true,
      // },
    ];
    let newAmazon =
      dataAmazon &&
      dataAmazon.stores &&
      dataAmazon.stores.nodes &&
      dataAmazon.stores.nodes.length > 0
        ? dataAmazon.stores.nodes.map((s) => ({
            value: s.id,
            label: (
              <LabelContainer>
                {s.title} {s.email ? `(${s.email})` : ""}
              </LabelContainer>
            ),
          }))
        : [];

    // Get data ebay store
    // let labelEbay = [
    //   {
    //     value: "ebay",
    //     label: <LabelContainer>--- Ebay ---</LabelContainer>,
    //     disabled: true,
    //   },
    // ];
    // let newEbay =
    //   dataEbay &&
    //   dataEbay.stores &&
    //   dataEbay.stores.nodes &&
    //   dataEbay.stores.nodes.length > 0
    //     ? dataEbay.stores.nodes.map((s) => {
    //         if (!s.suspended) {
    //           return {
    //             value: s.id,
    //             label: <LabelContainer>{s.title}</LabelContainer>,
    //           };
    //         }
    //         return null;
    //       })
    //     : [];
    // newEbay = newEbay.filter(Boolean);

    // // Get data online store
    // let labelOS = [
    //   {
    //     value: "onlineStore",
    //     label: <LabelContainer>--- Online store ---</LabelContainer>,
    //     disabled: true,
    //   },
    // ];
    // let newOS =
    //   dataOS &&
    //   dataOS.stores &&
    //   dataOS.stores.nodes &&
    //   dataOS.stores.nodes.length > 0
    //     ? dataOS.stores.nodes.map((s) => ({
    //         value: s.id,
    //         label: (
    //           <LabelContainer>
    //             {s.title} {s.domain ? `(${s.domain})` : ""}
    //           </LabelContainer>
    //         ),
    //       }))
    //     : [];

    // set Otpions list.
    originOptions = [
      // ...originOptions,
      ...newAmazon,
      // ...labelEbay,
      // ...newEbay,
      // ...labelOS,
      // ...newOS,
    ];
    setOptions(originOptions);
    setDeselectedOptions(originOptions);
  }, [dataAmazon]); //, dataEbay, dataOS

  useEffect(() => {
    if (value && value.length && deselectedOptions.length) {
      setSelectedOptions(value);

      // Handle show tag
      let couple =
        deselectedOptions.length > 0
          ? deselectedOptions.filter(
              (d) => _.isArray(value) && value.includes(d.value)
            )
          : [];
      couple =
        couple && couple.length > 0
          ? couple.map((i) => {
              let label = _.get(i, "label.props.children", null);
              if (_.isArray(label)) {
                label = label.join("");
              }
              return {
                value: i.value,
                label,
              };
            })
          : [];
      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...couple], "value");
        return result;
      });
    }
  }, [value, deselectedOptions]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => {
        let label = _.get(option, "label.props.children");
        if (Array.isArray(label)) {
          label = label.join("");
        }
        return label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);

      // Show tag.
      let couple =
        deselectedOptions && deselectedOptions.length > 0
          ? deselectedOptions
              .filter((d) => selected.includes(d.value))
              .map((i) => {
                let label = _.get(i, "label.props.children", null);
                if (Array.isArray(label)) {
                  label = label.join("");
                }
                return {
                  value: i.value,
                  label,
                };
              })
          : [];

      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...couple], "value");
        result = result.filter((i) => selected.includes(i.value));
        return result;
      });

      if (onChange) {
        onChange(selected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, deselectedOptions]
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.value !== value
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptionsShow]
  );

  const textField = (
    <Autocomplete.TextField
      label="Accounts"
      labelHidden
      value={inputValue}
      onChange={handleInputValue}
      placeholder="Search for account"
      error={error}
    />
  );

  return (
    <>
      <ComponentLabelPolaris label="Accounts" required />
      {loadingAmazon ? ( //|| loadingEbay || loadingOS
        <Spinner size="small" />
      ) : (
        <>
          <Autocomplete
            options={options}
            onSelect={handleSelection}
            selected={selectedOptions}
            textField={textField}
            allowMultiple
            emptyState={<span>No items found!</span>}
            id="account-select"
          />
          <TagContainer>
            {deselectedOptionsShow && deselectedOptionsShow.length > 0
              ? deselectedOptionsShow.map((item) => (
                  <Tag
                    children={item.label}
                    onRemove={() => handleRemove(item.value)}
                    key={item.value}
                  />
                ))
              : null}
          </TagContainer>
        </>
      )}
    </>
  );
};
