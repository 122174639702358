import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import useToggle from "../../../../hooks/useToggle";
import {
  CREATE_IDEA_TASK,
  EDIT_IDEA_TASK,
} from "../../../../graphql/mutations";
import { GET_IDEA_TASK } from "../../../../graphql/queries";
import { handleError } from "../../../../helper";
import { SkeletonPagePolaris } from "../../../shared/SkeletonPagePolaris";
import { useToastContext } from "../../../shared/ToastContext";
import { Layout } from "./Layout";
import { ModalComp } from "./ModalComp";
import { useAppContext } from "../../../../context";
import { TEAM_ROLE, USER_ROLE } from "../../../../variable";
import { checkStatusTask, TASK_STATUS } from "../utils";
import {
  AssignToDesigner,
  Decline,
  DenyTask,
  ReviewTask,
  StartTask,
  SubmitToDraft,
  TakeTask,
  AssignToTeam,
} from "./actions";
import { LivePreviewMockup } from "./actions/LivePreviewMockup";
import { SubmitToReview2 } from "./actions/SubmitToReview2";
import { Publish } from "./actions/Publish";
import { CancelIdeaTask } from "./actions/Cancel";
import { UpdatePsd } from "./actions/UpdatePsd";
import ReAssignToDesignTeam from "./actions/ReAssignToDesignTeam";

export const ACTIONS = {
  assign: "assign",
  assignToDS: "assignToDesignTeam",
  reAssignToDS: "re-assignToDesignTeam",
  review: "review",
  reject: "reject",
  approve: "approve",
  rejectAll: "rejectAll",
  approveAll: "approveAll",
  reReview: "reReview",
  deny: "deny",
  start: "start",
  publish: "publish",
  clone: "clone",
  cancel: "cancel",
  decline: "decline",
  submitToDraft: "submitToDraft",
  takeTask: "takeTask",
  updatePsd: "updatePsd",
};

export function Update({ open, onClose, item, isClone }) {
  // Props
  const id = item?.id;

  // Ref
  const modalRef = useRef(null);
  const submitTimeoutRef = useRef(null);

  // Context
  const { setNotify, toggleToast, refetch, setFilter } = useToastContext();
  const { currentUser } = useAppContext();
  const { teamUser, roles, id: userId } = currentUser || {};
  const { role: teamRole } = teamUser || {};
  const isSeller = (roles || []).includes(USER_ROLE.Seller);
  const isTeamAdmin = [TEAM_ROLE.Admin].includes(teamRole);
  const isSM = [TEAM_ROLE.StoreManager].includes(teamRole);
  const isDesignLeader = [TEAM_ROLE.DesignLeader].includes(teamRole);
  const isAdminSeller = isSeller && (isTeamAdmin || isSM);
  const isDesigner = [TEAM_ROLE.Designer].includes(teamRole);
  const isIdea = [TEAM_ROLE.Idea].includes(teamRole);
  const isIdeaLeader = [TEAM_ROLE.IdeaLeader].includes(teamRole);
  const isRoleIdea = isIdea || isIdeaLeader;

  // State
  const [active, toggleActive] = useToggle(false);
  const [action, setAction] = useState(null);
  const [productChecked, setProductChecked] = useState(false);
  const [changed, setChanged] = useState(false);

  // Query
  const {
    data,
    loading,
    refetch: refetchIdea,
  } = useQuery(GET_IDEA_TASK, {
    variables: {
      id,
    },
    skip: !id || !open,
    fetchPolicy: "no-cache",
  });

  // Mutations
  const [updateIdea, { loading: loadingU }] = useMutation(EDIT_IDEA_TASK, {
    onCompleted: () => {
      setNotify({ msg: "Update Idea Task successfully.", err: false });
      onClose && onClose();

      submitTimeoutRef.current && clearTimeout(submitTimeoutRef.current);
      submitTimeoutRef.current = setTimeout(() => {
        refetch && refetch();
        setFilter && setFilter((prev) => ({ ...prev }));
      }, 1000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const [createIdea, { loading: loadingC }] = useMutation(CREATE_IDEA_TASK, {
    onCompleted: () => {
      setNotify({ msg: "Create Idea Task successfully.", err: false });
      onClose && onClose();

      submitTimeoutRef.current && clearTimeout(submitTimeoutRef.current);
      submitTimeoutRef.current = setTimeout(() => {
        refetch && refetch();
        setFilter && setFilter((prev) => ({ ...prev }));
      }, 1000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  // Actions
  const handleSave = useCallback(
    (event) => {
      event.stopPropagation();
      const modalRefCur = modalRef.current;
      const input = modalRefCur && modalRefCur.submit();

      if (input != null) {
        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });
        const { baseGroups, ...rest } = input;

        // Remove field product
        const newBaseGroups = (baseGroups || []).map(
          ({ product, ...rest }) => ({ ...rest }),
        );

        updateIdea({
          variables: {
            input: {
              ...rest,
              baseGroups: newBaseGroups,
              id,
            },
          },
        });
      }
    },
    [id, updateIdea, toggleToast, setNotify],
  );

  const handleClone = useCallback(
    (event) => {
      event.stopPropagation();
      const modalRefCur = modalRef.current;
      const input = modalRefCur && modalRefCur.submit();

      if (input != null) {
        const { baseGroups } = input;

        // Remove `id` field in `baseGroups`, `baseConfigs`.
        const newBaseGroups = (baseGroups || []).map(
          ({ id, product, ...base }) => {
            const { baseConfigs } = base || {};
            const newBaseConfigs = (baseConfigs || []).map(({ id, ...cf }) => {
              return cf;
            });

            return {
              ...base,
              baseConfigs: newBaseConfigs,
            };
          },
        );

        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });
        createIdea({
          variables: {
            input: {
              ...input,
              baseGroups: newBaseGroups,
            },
          },
        });
      }
    },
    [createIdea, toggleToast, setNotify],
  );

  const handleClose = useCallback(() => {
    if (changed) {
      const confirm = window.confirm(
        "You have unsaved changes, are you sure you want to leave?",
      );
      if (!confirm) return;
    }

    onClose && onClose();
  }, [onClose, changed]);

  const onAction = useCallback(
    (act) => {
      return () => {
        toggleActive(() => true);
        setAction(() => act);
      };
    },
    [toggleActive],
  );

  // Markup
  const ideaTask = data?.ideaTaskById;
  const status = ideaTask?.status;
  const assignee = item?.assignee;
  const assigneeId = assignee?.id;
  const noAssign = assignee == null;
  const noAssignToDesignTeam = !item?.designTeamID;
  const dLeaderTakeTask = isDesignLeader && userId === assigneeId;

  const { isFileGenerating } = checkStatusTask(status);
  const overrides = {
    primary: true,
  };

  useEffect(() => {
    if (isFileGenerating && (isDesigner || isIdea)) {
      toggleActive(true);
      setAction(ACTIONS.review);
    }
  }, [isFileGenerating, isDesigner, isIdea]);

  const filterByStatusAndRole = (act) =>
    act.status.some((s) => [status, "*"].includes(s)) &&
    act.roles.some((r) => ["*", r].includes(teamRole));

  const actions = [
    {
      content: "Save Changes",
      onAction: handleSave,
      loading: loadingU,
      status: [TASK_STATUS.Pending, TASK_STATUS.Draft],
      roles: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.Idea,
        TEAM_ROLE.IdeaLeader,
      ],
    },
    {
      content: "Save Changes",
      onAction: handleSave,
      loading: loadingU,
      status: [TASK_STATUS.Declined],
      roles: [TEAM_ROLE.IdeaLeader, TEAM_ROLE.Idea],
    },
    {
      content: "Reject Selected",
      onAction: onAction(ACTIONS.reject),
      status: [TASK_STATUS["Need Review"]],
      roles: [TEAM_ROLE.Admin, TEAM_ROLE.StoreManager],
    },
    {
      content: "Approve Selected",
      onAction: onAction(ACTIONS.approve),
      status: [TASK_STATUS["Need Review"]],
      roles: [TEAM_ROLE.Admin, TEAM_ROLE.StoreManager],
      overrides,
    },
    productChecked && {
      content: "Reject Selected",
      onAction: onAction(ACTIONS.reject),
      status: [TASK_STATUS["Partially Completed"]],
      roles: [TEAM_ROLE.Admin, TEAM_ROLE.StoreManager],
    },
    productChecked && {
      content: "Approve Selected",
      onAction: onAction(ACTIONS.approve),
      status: [TASK_STATUS["Partially Completed"]],
      roles: [TEAM_ROLE.Admin, TEAM_ROLE.StoreManager],
      overrides,
    },
    productChecked && {
      content: "Reject Selected",
      onAction: onAction(ACTIONS.reject),
      status: [TASK_STATUS["Need Review"], TASK_STATUS["Partially Completed"]],
      roles: [TEAM_ROLE.DesignLeader, TEAM_ROLE.IdeaLeader],
    },
    productChecked && {
      content: "Approve Selected",
      onAction: onAction(ACTIONS.approve),
      status: [TASK_STATUS["Need Review"], TASK_STATUS["Partially Completed"]],
      roles: [TEAM_ROLE.DesignLeader, TEAM_ROLE.IdeaLeader],
      overrides,
    },
    noAssign && {
      content: "Take Idea Task",
      onAction: onAction(ACTIONS.takeTask),
      status: [TASK_STATUS.Pending],
      roles: [TEAM_ROLE.DesignLeader],
    },
    noAssign && {
      content: "Assign to Designer",
      onAction: onAction(ACTIONS.assign),
      status: [TASK_STATUS.Pending, TASK_STATUS.Denied],
      roles: [
        TEAM_ROLE.DesignLeader,
        TEAM_ROLE.IdeaLeader,
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
      ],
      // overrides,
    },
    {
      content: "Assign to Designer",
      onAction: onAction(ACTIONS.assign),
      status: [TASK_STATUS["Partially Completed"], TASK_STATUS.Denied],
      roles: [
        TEAM_ROLE.DesignLeader,
        TEAM_ROLE.IdeaLeader,
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
      ],
      // overrides,
    },
    noAssignToDesignTeam
      ? {
          content: "Assign to Design Team",
          onAction: onAction(ACTIONS.assignToDS),
          roles: [
            TEAM_ROLE.Admin,
            TEAM_ROLE.StoreManager,
            TEAM_ROLE.Idea,
            TEAM_ROLE.IdeaLeader,
          ],
          status: [
            TASK_STATUS.Pending,
            TASK_STATUS.Denied,
            TASK_STATUS["Partially Completed"],
          ],
          overrides,
        }
      : noAssign && {
          content: "Re-assign to Design Team",
          onAction: onAction(ACTIONS.reAssignToDS),
          roles: [
            TEAM_ROLE.Admin,
            TEAM_ROLE.StoreManager,
            TEAM_ROLE.Idea,
            TEAM_ROLE.IdeaLeader,
          ],
          status: [
            TASK_STATUS.Pending,
            TASK_STATUS.Denied,
            TASK_STATUS["Partially Completed"],
          ],
        },
    {
      content: "Reject All",
      onAction: onAction(ACTIONS.rejectAll),
      status: [TASK_STATUS["Need Review"]],
      roles: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.DesignLeader,
        TEAM_ROLE.IdeaLeader,
      ],
    },
    {
      content: "Approve All",
      onAction: onAction(ACTIONS.approveAll),
      status: [TASK_STATUS["Need Review"]],
      roles: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.DesignLeader,
        TEAM_ROLE.IdeaLeader,
      ],
      overrides,
    },

    // =========== roles: [TEAM_ROLE.DesignLeader, TEAM_ROLE.IdeaLeader] =========== //
    {
      content: "Submit to review",
      onAction: onAction(ACTIONS.review),
      status: [TASK_STATUS.Doing],
      roles: [TEAM_ROLE.DesignLeader],
      overrides,
    },
    {
      content: "Re-generate mockup",
      onAction: onAction(ACTIONS.reReview),
      status: [TASK_STATUS.Rejected, TASK_STATUS["Partially Completed"]],
      roles: [TEAM_ROLE.DesignLeader, TEAM_ROLE.Designer],
      overrides,
    },

    // =========== roles: [TEAM_ROLE.Designer] =========== //
    {
      content: "Deny this task",
      onAction: onAction(ACTIONS.deny),
      status: [TASK_STATUS.Pending],
      roles: [TEAM_ROLE.Designer],
    },
    dLeaderTakeTask && {
      content: "Deny this task",
      onAction: onAction(ACTIONS.deny),
      status: [TASK_STATUS.Pending],
      roles: [TEAM_ROLE.DesignLeader],
    },
    {
      content: "Start this task",
      onAction: onAction(ACTIONS.start),
      status: [TASK_STATUS.Pending],
      roles: [TEAM_ROLE.Designer],
      overrides,
    },
    dLeaderTakeTask && {
      content: "Start this task",
      onAction: onAction(ACTIONS.start),
      status: [TASK_STATUS.Pending],
      roles: [TEAM_ROLE.DesignLeader],
      overrides,
    },
    {
      content: "Submit to generate mockup",
      onAction: onAction(ACTIONS.review),
      status: [TASK_STATUS.Doing, TASK_STATUS["File Generating"]],
      roles: [TEAM_ROLE.Designer],
      overrides,
    },
    {
      content: "Publish Idea",
      onAction: onAction(ACTIONS.publish),
      status: [TASK_STATUS.Draft, TASK_STATUS.Declined],
      roles: [TEAM_ROLE.IdeaLeader],
      overrides,
    },
    {
      content: "Cancel",
      onAction: onAction(ACTIONS.cancel),
      status: [TASK_STATUS.Denied],
      roles: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.Idea,
        TEAM_ROLE.IdeaLeader,
      ],
    },
    {
      content: "Update PSD File",
      onAction: onAction(ACTIONS.updatePsd),
      status: [TASK_STATUS.Done],
      roles: [
        TEAM_ROLE.Designer,
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.IdeaLeader,
        TEAM_ROLE.DesignLeader,
      ],
    },
  ]
    .filter(Boolean)
    .filter(filterByStatusAndRole);

  const secondaryActions = [
    {
      content: "Decline",
      onAction: onAction(ACTIONS.decline),
      status: [TASK_STATUS.Draft],
      roles: [TEAM_ROLE.IdeaLeader],
    },
    {
      content: "Submit to Draft",
      onAction: onAction(ACTIONS.submitToDraft),
      status: [TASK_STATUS.Declined],
      roles: [TEAM_ROLE.Idea],
    },
  ]
    .filter(Boolean)
    .filter(filterByStatusAndRole);

  const actionsClone = [
    {
      content: "Clone Idea",
      onAction: handleClone,
      status: ["*"],
      roles: [TEAM_ROLE.Admin, TEAM_ROLE.Idea, TEAM_ROLE.IdeaLeader],
      overrides,
      loading: loadingC,
    },
  ];

  const props = useMemo(
    () => ({
      open: active,
      onClose: toggleActive,
      item: ideaTask,
      onCloseParent: () => {
        onClose();
        refetch && refetch();
      },
      isFileGenerating,
    }),
    [active, toggleActive, item, onClose, refetch, isFileGenerating, ideaTask],
  );

  const ids = ideaTask?.id != null ? [ideaTask.id] : [];
  const actionsMarkup = {
    [ACTIONS.assign]: <AssignToDesigner {...props} ids={ids} />,
    [ACTIONS.assignToDS]: <AssignToTeam {...props} ids={ids} />,
    [ACTIONS.reAssignToDS]: <ReAssignToDesignTeam {...props} ids={ids} />,
    [ACTIONS.deny]: <DenyTask {...props} />,
    [ACTIONS.start]: <StartTask {...props} />,
    [ACTIONS.review]: <SubmitToReview2 {...props} />,
    [ACTIONS.reReview]: <SubmitToReview2 {...props} type={ACTIONS.reReview} />,
    [ACTIONS.approve]: <ReviewTask {...props} type={ACTIONS.approve} />,
    [ACTIONS.approveAll]: <ReviewTask {...props} type={ACTIONS.approveAll} />,
    [ACTIONS.reject]: <ReviewTask {...props} type={ACTIONS.reject} />,
    [ACTIONS.rejectAll]: <ReviewTask {...props} type={ACTIONS.rejectAll} />,
    [ACTIONS.publish]: <Publish {...props} />,
    [ACTIONS.cancel]: <CancelIdeaTask {...props} />,
    [ACTIONS.decline]: <Decline {...props} />,
    [ACTIONS.submitToDraft]: <SubmitToDraft {...props} />,
    [ACTIONS.takeTask]: <TakeTask {...props} />,
    [ACTIONS.updatePsd]: <UpdatePsd {...props} />,
  };

  return (
    <ModalComp
      open={open}
      title={isClone ? "Clone Idea Task" : "View detail"}
      primaryAction={isClone ? actionsClone : actions}
      handleClose={handleClose}
      domId={`idea-task-${id}`}
      isUpdate
      setProductChecked={setProductChecked}
      isClone={isClone}
      setChanged={setChanged}
      secondaryActions={secondaryActions}
    >
      {loading ? (
        <SkeletonPagePolaris sizeSmall />
      ) : (
        <Layout
          ref={modalRef}
          value={ideaTask}
          isAdminSeller={isAdminSeller}
          isRoleIdea={isRoleIdea}
          isIdeaLeader={isIdeaLeader}
          onCloseParent={onClose}
          refetchIdea={refetchIdea}
        />
      )}
      {actionsMarkup[action]}
      {isFileGenerating &&
        (isAdminSeller || isDesignLeader || isIdeaLeader) && (
          <TaskFileGenerate value={ideaTask} onClose={handleClose} />
        )}
    </ModalComp>
  );
}

function TaskFileGenerate({ value, onClose }) {
  // State
  const [open, toggleOpen] = useToggle(true);

  const handleBackToDetail = useCallback(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  return (
    <LivePreviewMockup
      open={open}
      toggleOpen={toggleOpen}
      onClose={onClose}
      taskBaseGroups={value?.taskBaseGroups}
      id={value?.id}
      assignee={value?.assignee}
      handleBackToDetail={handleBackToDetail}
      isFileGenerating
    />
  );
}
