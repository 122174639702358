import React, { useState, useCallback } from "react";
import { Button, ButtonGroup, Checkbox } from "@shopify/polaris";
import styled from "styled-components";
import useToggle from "../../hooks/useToggle";

import { DeleteBasePolaris } from "./actions/DeleteBasePolaris";
import { PublishBase } from "./components/PublishBase";
import { useAppContext } from "../../context";
import { checkRole } from "../../helper";

const Container = styled.div`
  .Polaris-Choice {
    padding: 0;
    .Polaris-Choice__Control {
      margin-top: 0.1rem;
    }
  }
`;

export const BulkActionsPolaris = (props) => {
  // Props
  const { idsChecked, onCheckAll } = props;

  const { currentUser } = useAppContext();
  const { isAdministrator } = checkRole(currentUser);
  // State
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [openPublish, toggleOpenPublish] = useToggle(false);

  const toggleShowModal = useCallback((value) => {
    setOpen((prev) => !prev);
    setCurrentAction(value);
  }, []);

  return (
    <Container>
      <ButtonGroup segmented>
        <Button
          children={
            <Checkbox label={`${idsChecked.length} selected`} checked={true} />
          }
          onClick={onCheckAll}
        />
        {isAdministrator && (
          <Button children="Publish" onClick={toggleOpenPublish} />
        )}
        <Button
          children={"Delete"}
          destructive
          onClick={() => toggleShowModal("delete")}
        />
      </ButtonGroup>
      <DeleteBasePolaris
        open={open && "delete" === currentAction}
        toggleShowModal={toggleShowModal}
        {...props}
      />
      <PublishBase
        open={openPublish}
        onClose={toggleOpenPublish}
        ids={idsChecked}
        {...props}
      />
    </Container>
  );
};
