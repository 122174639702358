import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";

import { REPORT_SALE_STORE_MANAGERS } from "../../../graphql/queries";
import { CardBox } from "./CardBox";
import { useReportsContext } from "../context";
import { formatter, getUnique } from "../../../helper";
import { ComparedPercent } from "./ComparedPercent";
import ShowCard, { conditionByFilter } from "./ShowCard";

const collapseKey = "storeManagers";
const styleColumn = {
  width: "10rem",
  display: "inline-block",
};
export function StoreManagers({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, error, fetchMore } = useQuery(
    REPORT_SALE_STORE_MANAGERS,
    {
      variables: {
        filter,
      },
    },
  );
  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_SALE_STORE_MANAGERS);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      let { storeID, storeManagerID, ...ignoredFilter } = filterCtx;
      setFilter((prev) => ({ ...prev, ...ignoredFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportSaleByStoreManagers?.nodes;
    const total = data?.reportSaleByStoreManagers?.total;
    const totalSale = data?.reportSaleByStoreManagers?.totalSale;
    let totalCost = 0;
    let totalRev = 0;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            const totalSale = node?.totalSale;
            const totalBaseCost = node?.totalBaseCost || 0;
            const totalRevenues = node?.totalRevenues || 0;
            const user = node?.user;
            const name = [user.firstName, user.lastName]
              .filter(Boolean)
              .join(" ");

            totalCost += totalBaseCost;
            totalRev += totalRevenues;
            return {
              totalSale,
              name,
              totalBaseCost,
              totalRevenues,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalSale,
      totalCost: totalCost.toFixed(2),
      totalRevenues: totalRev.toFixed(2),
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportSaleByStoreManagers?.totalSale;
      const totalBaseCost = dataC?.reportSaleByStoreManagers?.totalBaseCost;
      const totalRevenues = dataC?.reportSaleByStoreManagers?.totalRevenues;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
        totalCost: totalBaseCost,
        totalRevenues,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportSaleByStoreManagers.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportSaleByStoreManagers =
              fetchMoreResult.reportSaleByStoreManagers;
            const nodes = reportSaleByStoreManagers.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;
            setLoadMore(false);
            if (nodes.length < limit) {
              setCantLoad(true);
            }

            const newNodes = [
              ...prev.reportSaleByStoreManagers.nodes,
              ...nodes,
            ];

            const result = getUnique(newNodes, "userId");
            const totalCost = (result || []).reduce((acc, item) => {
              if (item?.totalBaseCost > 0) acc += item.totalBaseCost;
              return acc;
            }, 0);
            const totalRevenues = (result || []).reduce((acc, item) => {
              if (item?.totalRevenues > 0) acc += item.totalRevenues;
              return acc;
            }, 0);

            setState((p) => ({
              ...p,
              totalCost: totalCost.toFixed(2),
              totalRevenues: totalRevenues.toFixed(2),
            }));

            return {
              ...prev,
              reportSaleByStoreManagers: {
                ...prev.reportSaleByStoreManagers,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => (
          <Stack key={`store-manager-${index}`} wrap={false}>
            <span className="index-wrap">{index + 1}</span>
            <Stack.Item fill>{item.name}</Stack.Item>
            <span>{item.totalSale}</span>
            <span style={styleColumn}>
              {formatter.format(item.totalBaseCost)}
            </span>
            <span style={styleColumn}>
              {formatter.format(item.totalRevenues)}
            </span>
          </Stack>
        ))
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Name</TextStyle>
      </Stack.Item>
      <span>Sales</span>
      <span style={styleColumn}>Total Cost</span>
      <span style={styleColumn}>Total Revenue</span>
    </Stack>
  );

  const subTitle = useMemo(() => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <span>{state.totalSale} Line items</span>
          <ComparedPercent
            originalVal={state.totalSale}
            newVal={stateCompare.totalSale}
          />
        </span>

        <span>
          <span>Total cost: {formatter.format(state.totalCost)}</span>
          <ComparedPercent
            originalVal={state.totalCost}
            newVal={stateCompare.totalCost}
          />
        </span>
        <span>
          <span>Total revenue: {formatter.format(state.totalRevenues)}</span>
          <ComparedPercent
            originalVal={state.totalRevenues}
            newVal={stateCompare.totalRevenues}
          />
        </span>
      </div>
    );
  }, [
    state.totalSale,
    stateCompare.totalSale,
    state.totalCost,
    stateCompare.totalCost,
    state.totalRevenues,
    stateCompare.totalRevenues,
  ]);

  const rootError = error || errorC;
  const con = conditionByFilter(filterCtx, ["storeID", "storeManagerID"]);

  return (
    <ShowCard show={con}>
      <CardBox
        title="Store managers"
        // subTitle={subTitle} // case: manage store for multiple acc => dup
        headMarkup={headMarkup}
        contentMarkup={contentMarkup}
        filter={filter}
        total={state.total}
        loading={mergedLoading}
        error={rootError}
        setFilter={setFilter}
        handleScroll={handleScroll}
        loadMore={loadMore}
        collapseKey={collapseKey}
        footerMarkup={
          <p style={{ marginTop: "0.5rem" }}>
            <i>
              Because a store can have multiple store managers, the figures in
              this section may differ from the actual numbers
            </i>
          </p>
        }
      />
    </ShowCard>
  );
}
