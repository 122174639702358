import React from "react";
import { Select } from "@shopify/polaris";

const options = [
    { value: "false", label: "False" },
    { value: "true", label: "True" },
];

export const IdentifierExistsPolaris = ({ value, onChange }) => {
    return (
        <Select
            options={options}
            value={value}
            label="Identifier exists"
            onChange={onChange}
        />
    );
};
