import { useMutation } from "@apollo/react-hooks";
import {
  Badge,
  Button,
  ButtonGroup,
  Link,
  List,
  Modal,
  ResourceItem,
  Tag,
  TextStyle,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get } from "lodash";
import React, { Fragment, useCallback } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { arrInvalid, getParamByRole, handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import { ContinuePolaris } from "./ContinuePolaris";
import { LIST_PRODUCT_PUSH } from "./ProductPushPolaris";

const PAUSE_PUSH = gql`
  mutation pausePush($id: ID!, $input: Boolean!) {
    pausePush(id: $id, input: $input)
  }
`;

const Container = styled.div`
  .stores-wrap {
    display: flex;
    .stores-inner {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
    }
  }
  .info-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-right: 2rem;
  }
`;

const Done = "Done";

export const PushProductRenderItemPolaris = (
  item,
  filter,
  toggleActive,
  setNotify
) => {
  let stores = get(item, "stores", []);
  let storesId = stores.map((s) => s.title);
  let done = get(item, "info.done", 0);
  let total = get(item, "info.total", 0);
  let error = get(item, "info.error", 0);
  let paused = get(item, "paused", false);
  const generate = get(item, "onlyGenerateCsv", false);
  const generateCsvStatus = get(item, "generateCsvStatus", "");
  const csvFiles = get(item, "csvFiles", "");

  const statusBadge = paused ? "success" : "critical";
  const statusMsg = paused ? "Yes" : "No";

  const generateProps = {
    status: generate ? "success" : "critical",
    children: generate ? "Yes" : "No",
  };

  // Context
  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  const viewCSVFile =
    generate && generateCsvStatus === Done && csvFiles?.length > 0;

  return (
    <Container>
      <ResourceItem id={item.id}>
        <div className="content-wrap">
          <TextStyle>{item.title}</TextStyle>
          <div className="stores-wrap">
            <div className="stores-inner">
              {storesId && storesId.length
                ? storesId.map((s) => (
                    <React.Fragment key={`stores-${s}`}>
                      <Tag>{s}</Tag>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </div>
          <div className="info-wrap">
            <span>Done: {done}</span>
            <span>Total: {total}</span>
            <span>Error / Already Pushed: {error}</span>
          </div>
          <div className="continue-wrap">
            <ContinuePolaris value={item.continue} id={item.id} />
          </div>
          <div className="status-wrap">
            <Badge status={statusBadge} children={statusMsg} />
          </div>

          <div className="generate-wrap">
            <Badge {...generateProps} />
          </div>
          <div className="generate-status-wrap">
            <span>{generateCsvStatus}</span>
          </div>
          <ButtonGroup>
            <Link url={`/${param}/products/product-push/${item.id}`}>Edit</Link>
            <PauseOrResume
              paused={paused}
              item={item}
              filter={filter}
              setNotify={setNotify}
              toggleActive={toggleActive}
            />
            {viewCSVFile && <ViewCSVFiles files={csvFiles} />}
          </ButtonGroup>
        </div>
      </ResourceItem>
    </Container>
  );
};

function PauseOrResume({ paused, item, filter, setNotify, toggleActive }) {
  const id = item?.id;
  const label = paused ? "Resume" : "Pause";

  // State
  const [open, toggleOpen] = useToggle(false);

  // Mutation
  const [pausePush, { loading, client }] = useMutation(PAUSE_PUSH, {
    onCompleted: (res) => {
      if (res.pausePush) {
        setNotify({
          msg: `${label} this product push successfully.`,
          err: false,
        });
        toggleOpen(false);
        const variables = {
          filter,
        };
        const cache = client.readQuery({
          query: LIST_PRODUCT_PUSH,
          variables,
        });

        client.writeQuery({
          query: LIST_PRODUCT_PUSH,
          variables,
          data: {
            ...cache,
            pushList: {
              ...cache.pushList,
              nodes: (cache.pushList.nodes || []).map((n) => {
                if (n.id === id) {
                  return {
                    ...n,
                    paused: !paused,
                  };
                }
                return n;
              }),
            },
          },
        });
      }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = useCallback(() => {
    if (id != null) {
      toggleActive && toggleActive(true);
      setNotify && setNotify({ msg: null, err: false });
      pausePush({
        variables: {
          id,
          input: !paused,
        },
      });
    }
  }, [pausePush, id, paused, setNotify, toggleActive]);

  return (
    <Fragment>
      <Button children={label} onClick={toggleOpen} plain />
      <Modal
        title={`${label} product push`}
        open={open}
        onClose={toggleOpen}
        sectioned
        primaryAction={{
          content: label,
          onAction: handleSubmit,
          loading: loading,
        }}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      >
        <p>Are you sure to {label} to this product push?</p>
      </Modal>
    </Fragment>
  );
}

const ViewCSVFiles = ({ files }) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Button onClick={toggleOpen} children="View CSV Files" plain />
      <Modal
        title="CSV Files"
        sectioned
        open={open}
        onClose={toggleOpen}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      >
        <CSVFiles files={files} />
      </Modal>
    </>
  );
};

export const CSVFiles = ({ files, hasHeader = false }) => {
  if (arrInvalid(files)) return null;

  return (
    <div>
      {hasHeader && <h3>CSV Files:</h3>}
      <List type="number">
        {files.map((f, key) => {
          const { url, name } = f || {};
          const pt = /part_\d+/gi;
          const matched = (name || "").match(pt);
          let newName = "";
          if (matched) {
            const [name] = matched;
            const [, stt] = (name || "").split(/\_/);
            newName = "Part " + stt;
          }
          return (
            <List.Item key={`file-${key}`}>
              <a href={url} className="Polaris-Link">
                {newName}
              </a>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};
