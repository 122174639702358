import { useMutation } from "@apollo/react-hooks";
import { Button, Labelled, Modal, Stack, Toast } from "@shopify/polaris";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { UPDATE_PRODUCT_TEMPLATE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { UPLOAD_FOLDER } from "../../../variable";
import { useFileById } from "../../file/hooks";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import Wysiwyg from "../../Wysiwyg";
import { Button as BtnAntd } from "antd";

export function Update({ value, isAntd }) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const [toast, toggleActive] = useToggle(false);
    const [notify, setNotify] = useState({ msg: null, err: false });

    // Markup
    const toastMarkup = toast && notify.msg && (
        <Toast content={notify.msg} duration={2000} onDismiss={toggleActive} />
    );

    return (
        <Fragment>
            {toastMarkup}
            {isAntd ? (
                <BtnAntd
                    children="Update content"
                    type="link"
                    onClick={() => toggleOpen()}
                />
            ) : (
                <Button children="Update content" onClick={toggleOpen} plain />
            )}
            <ModalUpdate
                open={open}
                onClose={toggleOpen}
                value={value}
                toggleActive={toggleActive}
                setNotify={setNotify}
            />
        </Fragment>
    );
}

function ModalUpdate({ open, onClose, value, toggleActive, setNotify }) {
    // Props
    const { defaultMockupIds, id, data } = value || {};
    const { products } = data || {};
    const [product] = products || [];
    const { description } = product || {};

    // State
    const [state, setState] = useState({
        defaultMockups: [],
        newContent: "",
    });
    // Mutation
    const [update, { loading }] = useMutation(UPDATE_PRODUCT_TEMPLATE, {
        onCompleted: (res) => {
            setNotify({
                msg: "Update template content successfully",
                err: false,
            });
            if (res?.updateTemplate) {
                const { data, defaultMockupIds } = res.updateTemplate;
                const { products } = data;
                const [product] = products || [];
                const { description } = product;

                value.defaultMockupIds = defaultMockupIds;
                if (value.data.products?.length > 0) {
                    value.data.products = (value.data.products || []).map(
                        (p) => {
                            return {
                                ...p,
                                description,
                            };
                        }
                    );
                }
            }
            onClose();
        },
        onError: (error) => {
            setNotify({ msg: handleError(error?.toString), err: true });
        },
    });

    // Hooks
    const [getFiles] = useFileById(defaultMockupIds);

    // Get data
    useEffect(() => {
        if (description) {
            setState((prev) => ({ ...prev, newContent: description }));
        }
    }, [description]);

    useEffect(() => {
        if (!open) return;
        (async function () {
            const files = await getFiles();
            if (files?.length > 0) {
                setState((prev) => ({ ...prev, defaultMockups: files }));
            }
        })();
    }, [getFiles, open]);

    // Actions
    const handleSubmit = useCallback(() => {
        const { defaultMockups, newContent } = state;
        const defaultMockupIds = (defaultMockups || [])
            .map((i) => i.id)
            .filter(Boolean);

        if (id != null) {
            toggleActive && toggleActive(true);
            setNotify && setNotify({ msg: null, err: false });
            update({
                variables: {
                    id,
                    defaultMockupIds,
                    newContent: newContent || null,
                },
            });
        }
    }, [state, update, toggleActive, setNotify]);

    const handleMockupChange = useCallback((value) => {
        setState((prev) => ({
            ...prev,
            defaultMockups: value,
        }));
    }, []);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Update Template Content"
            sectioned
            large
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        >
            <Stack vertical>
                <div>
                    <Labelled label="Template Content" />
                    <Wysiwyg
                        value={state.newContent}
                        onChange={(value) =>
                            setState((prev) => ({ ...prev, newContent: value }))
                        }
                    />
                </div>
                <div>
                    <Labelled label="Default Mockup Files" />
                    <MediaSelectorButton
                        prefix="mockups/"
                        folder={UPLOAD_FOLDER.MOCKUPS}
                        multiple={true}
                        listType={"picture-card"}
                        buttonType="primary"
                        accept={"image/*"}
                        fileList={state.defaultMockups}
                        onChange={handleMockupChange}
                    />
                </div>
            </Stack>
        </Modal>
    );
}
