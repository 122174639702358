import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { DataTableSellerPolaris } from "../seller/DataTableSellerPolaris";
import { SuppliersFilterPolaris } from "./SuppliersFilterPolaris";

export const LIST_SUPPLIERS = gql`
  query users($filter: FindFilter) {
    users(filter: $filter) {
      total
      nodes {
        id
        firstName
        lastName
        email
        roles
        phone
        status
        verified
        address
        avatar {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status"];

export const SuppliersPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.status) {
        if ("active" === initialFilter.status) {
          initialFilter.status = true;
        }
        if ("inactive" === initialFilter.status) {
          initialFilter.status = false;
        }
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageSupplier")) || 20,
    offset: 0,
    search: null,
    status: null,
    role: "Supplier",
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(LIST_SUPPLIERS, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  // Handle action
  useEffect(() => {
    let { offset, limit, status, role, ...rest } = filter;
    if (true === status) {
      status = "active";
    }
    if (false === status) {
      status = "inactive";
    }

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      status,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleFilterChange = useCallback(({ search, status }) => {
    setFilter((prev) => {
      if (!isEqual(prev.search, search) || !isEqual(prev.status, status)) {
        return {
          ...prev,
          offset: 0,
          search,
          status,
        };
      }
      return prev;
    });
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <SuppliersFilterPolaris filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.users?.nodes?.length > 0 ? (
          <DataTableSellerPolaris
            data={data}
            filter={filter}
            urlEdit={"/admin/supplier/edit"}
            refetch={refetch}
            setFilter={(offset, limit) => {
              setFilter((prev) => {
                if (
                  !isEqual(prev.offset, offset) ||
                  !isEqual(prev.limit, limit)
                ) {
                  return {
                    ...prev,
                    offset,
                    limit,
                  };
                }
                return prev;
              });
              setCookie("perPageSupplier", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
