import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER, TEAM_ROLE } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { AssortmentContext } from "./context/AssortmentContextPolaris";
import { FilterAssortmentPolaris } from "./FilterAssortmentPolaris";
import {
  ScreenOptionsPAPolaris,
  SCREEN_OPTIONS,
} from "./ScreenOptionsPAPolaris";
import { TableProductAssortmentPolaris } from "./TableProductAssortmentPolaris";

export const QUERY_PRODUCT_ASSORTMENT = gql`
  query productAssortment($filter: ProductAssortmentFilter) {
    productAssortment(filter: $filter) {
      total
      nodes {
        id
        title
        fbTitle
        amzTitle
        url
        niche
        source
        createdAt
        author {
          id
          firstName
          lastName
        }
        images {
          id
          file {
            id
            url
            thumbnailUrl
          }
        }
        similarProducts {
          id
          distance
          product {
            id
            title
            images {
              id
              productBaseId
              file {
                id
                thumbnailUrl
                url
              }
            }
          }
        }
        similarItems {
          id
          distance
          similarItem {
            id
            title
            images {
              id
              file {
                id
                thumbnailUrl
                url
              }
            }
          }
        }
        designUrls
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "authorId",
  "createdTime",
  "type",
  "domain",
  "storeId",
  "collection",
  "tag",
  "niche",
];

export const ProductAssortmentPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // Context
  const { store, currentUser } = React.useContext(AppContext);
  const storeId = store?.id && ("all" === store.id ? null : store.id);
  const teamRole = currentUser?.teamUser?.role;

  const isManager = [
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
  ].includes(teamRole);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductAssortment")) || 20,
    offset: 0,
    search: null,
    authorId: null,
    createdTime: null,
    type: null,
    domain: null,
    storeId: isManager ? storeId : null,
    collection: null,
    tag: null,
    niche: null,
    ...initFilter,
  });
  const [userId, setUserId] = useState(null);
  const [screenOptions, setScreenOptions] = useState([]);

  const { data, loading, error, refetch } = useQuery(QUERY_PRODUCT_ASSORTMENT, {
    variables: {
      filter: {
        ...filter,
      },
    },
    // fetchPolicy: "no-cache",
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let id = getCookie("userId");
    let options = getCookie(`screenOption_PA_${id}`);
    if (options) {
      options = options.split(",");
    } else {
      options = SCREEN_OPTIONS.map((i) => i.value);
    }
    setScreenOptions(options);
    setUserId(id);
  }, []);

  useEffect(() => {
    if (userId) {
      setCookie(`screenOption_PA_${userId}`, screenOptions, 100);
    }
  }, [userId, screenOptions]);

  useEffect(() => {
    let { offset, limit, createdTime, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });

    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleFilterChange = useCallback(
    ({
      search,
      domain,
      type,
      authorId,
      createdTime,
      storeId,
      collection,
      tag,
      niche,
    }) => {
      setFilter((prevState) => {
        if (
          !isEqual(prevState.search, search) ||
          !isEqual(prevState.type, type) ||
          !isEqual(prevState.domain, domain) ||
          !isEqual(prevState.authorId, authorId) ||
          !isEqual(prevState.createdTime, createdTime) ||
          !isEqual(prevState.storeId, storeId) ||
          !isEqual(prevState.collection, collection) ||
          !isEqual(prevState.tag, tag) ||
          !isEqual(prevState.niche, niche)
        ) {
          return {
            ...prevState,
            search,
            type,
            domain,
            authorId,
            createdTime,
            storeId,
            collection,
            tag,
            niche,
            offset: 0,
          };
        }
        return prevState;
      });
    },
    [],
  );

  const loadingMarkup = loading && <Loading />;

  // Context provider
  const bag = useMemo(() => ({ filter, screenOptions }), [
    filter,
    screenOptions,
  ]);

  return (
    <Container>
      {loadingMarkup}
      <div>
        <ScreenOptionsPAPolaris
          defaultValue={screenOptions}
          onChange={(value) => setScreenOptions(value)}
        />
      </div>
      <Page title="Product Assortment" fullWidth>
        <Card sectioned>
          <FilterAssortmentPolaris
            filter={filter}
            onChange={handleFilterChange}
          />
        </Card>
        <Card>
          {loading ? (
            <SkeletonPagePolaris />
          ) : (
            <>
              {error ? (
                <div>Error: {handleError(error.toString())}</div>
              ) : data?.productAssortment?.nodes?.length > 0 ? (
                <AssortmentContext.Provider value={bag}>
                  <TableProductAssortmentPolaris
                    data={data}
                    filter={filter}
                    screenOptions={screenOptions}
                    refetch={refetch}
                    setFilter={(offset, limit) => {
                      setFilter((prevState) => ({
                        ...prevState,
                        offset,
                        limit,
                      }));
                      setCookie("perPageProductAssortment", limit, 100);
                    }}
                  />
                </AssortmentContext.Provider>
              ) : (
                <EmptyStatePolaris />
              )}
            </>
          )}
        </Card>
      </Page>
    </Container>
  );
};
