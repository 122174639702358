import React from "react";
export const AppContext = React.createContext({
  currentUser: null,
  logout: () => {},
  authenticate: (obj) => {},
  setCurrentUser: (user) => {},
  store: null,
  setStore: (store) => {},
  isMobile: false,
  totalMappingOrder: undefined,
  setTotalMappingOrder: () => {},
  backgroundImage: undefined,
  setBackgroundImage: () => {},
});

export function useAppContext() {
  return React.useContext(AppContext);
}

const createContext = (name, defaultValue) => {
  const Context = React.createContext(defaultValue || {});

  function Provider(props) {
    const { children, ...context } = props;

    const value = React.useMemo(() => context, Object.values(context));

    return <Context.Provider value={value}> {children}</Context.Provider>;
  }

  function useContext() {
    const context = React.useContext(Context);
    if (context) return context;
    if (defaultValue !== undefined) return context;
    return {};
  }

  Provider.displayName = name + "Provider";

  return [Provider, useContext];
};

const createContextScope = (scopeName, deps = []) => {
  let defaultContexts = [];

  /**
   * createContext
   */
  function createContext(rootComponentName, defaultContext) {
    const BaseContext = React.createContext(defaultContext);
    const index = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];

    function Provider(props) {
      const { scope, children, ...context } = props;
      const Context = scope?.[scopeName][index] || BaseContext;
      // Only re-memoize when prop values change
      const value = React.useMemo(() => context, Object.values(context));
      return <Context.Provider value={value}>{children}</Context.Provider>;
    }

    function useContext(consumerName, scope) {
      const Context = scope?.[scopeName][index] || BaseContext;
      const context = React.useContext(Context);
      if (context) return context;
      if (defaultContext !== undefined) return defaultContext;
      throw Error(
        `\`${consumerName}\` must be used within \`${rootComponentName}\``,
      );
    }

    Provider.displayName = rootComponentName + "Provider";
    return [Provider, useContext];
  }

  return [createContext];
};

export { createContext, createContextScope };
