import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  RadioButton,
  Toast,
  TextContainer,
  Stack,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "./ProductsPolaris";

export const DELETE_PRODUCT_SELECT = gql`
  mutation deleteProductSelected($id: [ID!], $removeStore: Boolean!) {
    deleteProductSelected(id: $id, removeStore: $removeStore) {
      deleted
    }
  }
`;

export const DeleteProductsPolaris = ({
  open,
  toggleModal,
  selectedItem,
  filter,
  onCompleted,
}) => {
  const [removeStore, setRemoveStore] = useState("true");
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [deleteProductSelected, { data, loading, error, client }] = useMutation(
    DELETE_PRODUCT_SELECT,
    {
      onError: () => {},
    }
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (_checked, newValue) => setRemoveStore(newValue),
    []
  );
  const handleAction = useCallback(() => {
    deleteProductSelected({
      variables: {
        id: selectedItem,
        removeStore: "true" === removeStore ? true : false,
      },
    })
      .then((res) => {
        const id = setTimeout(() => {
          toggleModal();
          onCompleted();
          setRemoveStore("true");
          if (
            res &&
            res.data &&
            res.data.deleteProductSelected &&
            res.data.deleteProductSelected.deleted
          ) {
            try {
              const cache = client.readQuery({
                query: LIST_SELLER_PRODUCTS_QUERY,
                variables: { filter },
              });
              client.writeQuery({
                query: LIST_SELLER_PRODUCTS_QUERY,
                variables: { filter },
                data: {
                  ...cache,
                  products: {
                    ...cache.products,
                    total: cache.products.total - selectedItem.length,
                    nodes: cache.products.nodes.filter(
                      (u) => !selectedItem.includes(u.id)
                    ),
                  },
                },
              });
            } catch (e) {}
          }
        }, 2500);
        setTimeoutId(id);
      })
      .catch(() => {});
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, removeStore]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );

  const toastMarkup = activeToast
    ? (error || (data && data.deleteProductSelected)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.deleteProductSelected &&
                data.deleteProductSelected.deleted &&
                `Deleted ${data.deleteProductSelected.deleted.length}/${selectedItem.length} products success.`
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleModal}
        sectioned
        title="Delete selected product"
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <TextContainer>
          <Stack vertical>
            <RadioButton
              label="Are you sure to delete this selected?"
              checked={removeStore === "false"}
              id="false"
              name="removeStore"
              onChange={handleChange}
            />
            <RadioButton
              label="Are you sure to delete this selected in store?"
              checked={removeStore === "true"}
              id="true"
              name="removeStore"
              onChange={handleChange}
            />
          </Stack>
        </TextContainer>
      </Modal>
    </>
  );
};
