import React from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import FieldPreview from "./FieldPreview";
import { Card } from "antd";
import update from "immutability-helper";
import { ACCEPT_TYPE } from "./Customize";
const Container = styled.div`
  height: 100%;
  overflow: hidden;
  &.is-active {
    .ant-card {
      background: rgba(223, 230, 1237, 0.5);
    }
  }
`;
const Preview = ({
  fields,
  onSelected,
  onDeleted,
  onChange,
  selected,
  onDragged,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ACCEPT_TYPE,
    drop: () => ({ name: "Preview" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  const moveField = (dragIndex, hoverIndex) => {
    const dragField = fields[dragIndex];
    const newFields = update(fields, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragField],
      ],
    });
    onDragged(newFields);
  };

  return (
    <Container ref={drop} className={`${isActive ? "is-active" : "inactive"}`}>
      <Card bordered={false} style={{ height: "100%" }} title={"Preview"}>
        {fields.map((field, index) => (
          <FieldPreview
            index={index}
            moveField={moveField}
            onChange={(v) => {
              if (onChange) {
                onChange(v, index);
              }
            }}
            selected={selected === index}
            onDeleted={() => {
              onDeleted(field, index);
            }}
            onSelected={() => {
              onSelected(field, index);
            }}
            field={field}
            key={index}
          />
        ))}
      </Card>
    </Container>
  );
};
export default Preview;
