import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { STATUS_BADGE_MOCKUP_2D } from "../../../constants";
import { formatDataTree, genLabelTree } from "../../../helper";
import { FilterProductTypePolaris } from "../../../pages/seller/FilterProductTypePolaris";
import {
  COLLECTIONS_QUERY,
  TAGS_QUERY,
} from "../../../pages/seller/ProductFiltersPolaris";
import { PRODUCT_BASES } from "../../base/ProductBaseSelectPolaris";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

const All = "All";
let MOCKUP_GEN_STATUS = [{ value: All, label: All }];
const MOCKUP_GEN_STATUS_OPTIONS = Object.keys(STATUS_BADGE_MOCKUP_2D).map(
  (item) => ({
    value: item,
    label: item,
  })
);
MOCKUP_GEN_STATUS = [...MOCKUP_GEN_STATUS, ...MOCKUP_GEN_STATUS_OPTIONS];

export function Filter({ filter, onChange }) {
  const productBaseId = filter?.productBaseId;
  const collectionsProp = filter?.collections;
  const tagsProp = filter?.tags;
  const search = filter?.search;
  const statusProp = filter?.status;
  const personalizedProp = filter?.personalized;
  const personalizedLabel =
    true === personalizedProp
      ? "Personalized"
      : false === personalizedProp
      ? "Normal"
      : personalizedProp;

  // State
  const [queryValue, setQueryValue] = useState("");
  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState({
    value: productBaseId,
    label: null,
    search: null,
  });
  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState({
    value: collectionsProp,
    label: [],
    search: null,
  });
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({
    value: tagsProp,
    label: [],
    search: null,
  });
  const [productType, setProductType] = useState({
    value: personalizedProp,
    label: personalizedLabel,
  });
  const [status, setStatus] = useState({
    value: statusProp,
    label: null,
  });

  // Ref
  const timeoutRef = useRef(null);

  // Query
  const { data, loading, error } = useQuery(PRODUCT_BASES);
  const { data: dataC, loadingC, errorC } = useQuery(COLLECTIONS_QUERY, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: collection.search,
      },
    },
  });
  const { data: dataT, loadingT, errorT } = useQuery(TAGS_QUERY, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: tag.search,
      },
    },
  });

  // Handle actions
  const handleQueryValueChange = useCallback((value) => {
    setQueryValue(value);
  }, []);
  const updateValue = useCallback((state, setState) => {
    setState((prev) => ({ ...prev, ...state }));
  }, []);

  // newState = { value, label }
  const handleBaseChange = useCallback(
    (newState) => updateValue(newState, setProductBase),
    [updateValue]
  );
  const handleBaseSearchChange = useCallback(
    ({ search }) => updateValue({ search }, setProductBase),
    [updateValue]
  );

  const handleCollectionChange = useCallback(
    (newState) => updateValue(newState, setCollection),
    [updateValue]
  );
  const handleCollectionSearch = useCallback(
    (search) => updateValue({ search }, setCollection),
    [updateValue]
  );

  const handleTagChange = useCallback(
    (newState) => updateValue(newState, setTag),
    [updateValue]
  );
  const handleTagSearch = useCallback(
    (search) => updateValue({ search }, setTag),
    [updateValue]
  );

  const handleProductTypeChange = useCallback(
    (value, label) => updateValue({ value, label }, setProductType),
    [updateValue]
  );

  const handleStatusChange = useCallback(
    (newState) => updateValue(newState, setStatus),
    [updateValue]
  );

  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const initValue = {
    value: null,
    label: null,
  };
  const handleBaseRemove = useCallback(
    () => updateValue({ ...initValue, search: null }, setProductBase),
    [updateValue, initValue]
  );

  const initArr = {
    value: [],
    label: [],
    search: null,
  };
  const handleCollectionRemove = useCallback(
    () => updateValue(initArr, setCollection),
    [updateValue, initArr]
  );
  const handleTagRemove = useCallback(() => updateValue(initArr, setTag), [
    updateValue,
    initArr,
  ]);

  const handleProductTypeRemove = useCallback(
    () => updateValue(initValue, setProductType),
    [updateValue, initValue]
  );
  const handleStatusRemove = useCallback(
    () => updateValue(initValue, setStatus),
    [updateValue, initValue]
  );

  const clearFilterAll = useMemo(
    () => [
      handleQueryValueRemove,
      handleBaseRemove,
      handleCollectionRemove,
      handleTagRemove,
      handleProductTypeRemove,
      handleStatusRemove,
    ],
    [
      handleQueryValueRemove,
      handleBaseRemove,
      handleCollectionRemove,
      handleTagRemove,
      handleProductTypeRemove,
      handleStatusRemove,
    ]
  );

  const handleClearFilterAll = useCallback(() => {
    for (let fn of clearFilterAll) {
      fn && fn();
    }
  }, clearFilterAll);

  // Get data
  useEffect(() => {
    const newBases =
      data?.productBasesForSeller?.nodes?.length > 0
        ? data.productBasesForSeller.nodes.map((node) => ({
            value: node.id,
            label: node.title,
          }))
        : [];
    setProductBases(newBases);
  }, [data]);

  useEffect(() => {
    const nodes = dataC?.collections?.nodes;
    const collections = nodes?.length > 0 ? nodes : [];
    const newC = formatDataTree(collections);
    setCollections(newC);
  }, [dataC]);

  useEffect(() => {
    const nodes = dataT?.tags?.nodes;
    const tags = nodes?.length > 0 ? nodes : [];
    const newT = formatDataTree(tags);
    setTags(newT);
  }, [dataT]);

  useEffect(() => {
    setQueryValue(search);
  }, [search]);

  useEffect(() => {
    if (productBaseId != null && productBases?.length > 0) {
      const currentBase = productBases.find(
        (node) => node.value === productBaseId
      );
      if (currentBase != null) {
        updateValue(currentBase, setProductBase);
      }
    }
  }, [productBaseId, productBases, updateValue]);

  useEffect(() => {
    if (productBaseId != null && productBases?.length > 0) {
      const currentBase = productBases.find(
        (node) => node.value === productBaseId
      );
      if (currentBase != null) {
        updateValue(currentBase, setProductBase);
      }
    }
  }, [productBaseId, productBases, updateValue]);

  useEffect(() => {
    if (collectionsProp?.length > 0 && collections?.length > 0) {
      const currentCol = collections.find((col) =>
        collectionsProp.includes(col.value)
      );
      if (currentCol != null) {
        const { value, label } = currentCol;
        updateValue({ value, label }, setCollection);
      }
    }
  }, [collectionsProp, collections, updateValue]);

  useEffect(() => {
    if (tagsProp?.length > 0 && tags?.length > 0) {
      const currentCol = tags.find((tag) => tagsProp.includes(tag.value));
      if (currentCol != null) {
        const { value, label } = currentCol;
        updateValue({ value, label }, setTag);
      }
    }
  }, [tagsProp, tags, updateValue]);

  useEffect(() => {
    let item = {};
    if (statusProp == null) {
      item = { value: All, label: All };
    } else if (statusProp) {
      item = MOCKUP_GEN_STATUS.find((node) => node.value === statusProp);
    }

    if (item != null) {
      updateValue(item, setStatus);
    }
  }, [statusProp, updateValue]);

  useEffect(() => {
    const search = queryValue ? queryValue.trim() : "";
    const productBaseId = productBase.value ? productBase.value : null;
    const newCol = getValue(collection.value);
    const newTag = getValue(tag.value);
    const statusVal = status.value !== All ? status.value : null;

    if (onChange) {
      timeoutRef.current = setTimeout(() => {
        onChange({
          search,
          productBaseId,
          collections: newCol,
          tags: newTag,
          personalized: productType.value,
          status: statusVal,
        });
      }, 350);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [queryValue, productBase, collection, tag, productType, status]);

  // Markup
  const filters = [
    {
      key: "productBases",
      label: "Product Bases",
      tagLabel: productBase.label,
      onRemove: handleBaseRemove,
      filter: (
        <FilterNoSearchPolaris
          data={productBases}
          value={productBase}
          loading={loading}
          error={error}
          onChange={handleBaseChange}
          onChangeSearch={handleBaseSearchChange}
        />
      ),
    },

    {
      key: "collection",
      label: "Collections",
      tagLabel: collection.label,
      onRemove: handleCollectionRemove,
      filter: (
        <FilterHasSearchPolaris
          options={collections}
          value={collection}
          loading={loadingC}
          error={errorC}
          onChangeSearch={handleCollectionSearch}
          onChange={handleCollectionChange}
        />
      ),
    },
    {
      key: "tag",
      label: "Tags",
      tagLabel: tag.label,
      onRemove: handleTagRemove,
      filter: (
        <FilterHasSearchPolaris
          options={tags}
          value={tag}
          loading={loadingT}
          error={errorT}
          onChangeSearch={handleTagSearch}
          onChange={handleTagChange}
        />
      ),
    },
    {
      key: "productType",
      label: "Product Type",
      tagLabel: productType.label,
      onRemove: handleProductTypeRemove,
      filter: (
        <FilterProductTypePolaris
          productType={productType}
          setProductType={handleProductTypeChange}
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      tagLabel: status.label,
      onRemove: handleStatusRemove,
      filter: (
        <FilterListRadioPolaris
          options={MOCKUP_GEN_STATUS}
          value={status.value}
          onChange={handleStatusChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  for (let { key, label, onRemove, tagLabel } of filters) {
    if (!isEmpty(tagLabel) && All !== tagLabel) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(label, tagLabel),
        onRemove,
      });
    }
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder="Filter product..."
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearFilterAll}
    />
  );

  function disambiguateLabel(label, value) {
    if (!label) {
      return value;
    }
    if (["collections", "tags"].includes(label.trim().toLowerCase())) {
      value = value ? genLabelTree([value]) : "";
    }
    return `${label}: ${value}`;
  }

  function getValue(value) {
    if (!value || value.length === 0) return null;

    return Array.isArray(value) ? value : [value];
  }
}
