import React, { useState, useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { Button, ButtonGroup } from "@shopify/polaris";

import { getParamByUserRole, handleError } from "../../../helper";
import { useAppContext } from "../../../context";

const DELETE_CATEGORY = gql`
  mutation deleteProductBaseCategory($id: ID!) {
    deleteProductBaseCategory(id: $id)
  }
`;

export const CategoryActionsPolaris = ({
  item,
  hasChildren,
  refetch,
  setNotificationChange,
  toggleActive,
}) => {
  const id = item?.id;
  // State
  const [showModal, setShowModal] = useState(false);

  // Context
  const { currentUser } = useAppContext();
  const param = getParamByUserRole(currentUser);

  // Mutation
  const [deleteCategory, { loading }] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      toggleShowModal();
      if (setNotificationChange) {
        setNotificationChange({
          message: "Delete category successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (setNotificationChange) {
        setNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle action
  const toggleShowModal = useCallback(() => setShowModal((prev) => !prev), []);
  const handleSubmit = useCallback(() => {
    if (setNotificationChange) {
      setNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (id) {
      deleteCategory({
        variables: {
          id,
        },
      });
    }
    if (toggleActive) toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <ButtonGroup>
        <Button children="Edit" plain url={`/${param}/edit-category/${id}`} />
        {hasChildren ? (
          <div style={{ width: "5rem", height: 1 }}></div>
        ) : (
          <Button
            children="Delete"
            plain
            primary
            destructive
            onClick={toggleShowModal}
          />
        )}
      </ButtonGroup>
      <Modal
        title={`Delete category #${id}`}
        open={showModal}
        onClose={toggleShowModal}
        sectioned
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p>Are you sure to delete this category?</p>
      </Modal>
    </React.Fragment>
  );
};
