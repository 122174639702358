import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  Toast,
  TextContainer,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";

const CRETATE_FULFILLMENT = gql`
  mutation createFulfillmentConfigure($input: NewFulfillmentConfig!) {
    createFulfillmentConfigure(input: $input) {
      id
      fulfillmentId
      apiKey
    }
  }
`;

const UNINSTALL = gql`
  mutation deleteFulfillmentConfigure($slug: String!) {
    deleteFulfillmentConfigure(slug: $slug)
  }
`;

export const PrintwayFulfillmentConfigurePolaris = ({
  refetch,
  installed: installedProps,
}) => {
  const [open, setOpen] = useState(false);
  const [actionCurrent, setActionCurrent] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [installed, setInstalled] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const [createFulfillmentConfigure, { data, loading, error }] = useMutation(
    CRETATE_FULFILLMENT,
    {
      onError: () => {},
      onCompleted: () => {
        toggleInstall();
        if (refetch) refetch();
        const id = setTimeout(() => {
          toggleModal();
        }, 250);
        setTimeoutId(id);
      },
    }
  );
  const [
    deleteFulfillmentConfigure,
    { data: dataDelete, loading: loadingDelete, error: errorDelete },
  ] = useMutation(UNINSTALL, {
    onError: () => {},
    onCompleted: () => {
      toggleInstall();
      if (refetch) refetch();
      const id = setTimeout(() => {
        toggleModal();
      }, 250);
      setTimeoutId(id);
    },
  });

  useEffect(() => {
    setInstalled(installedProps);
  }, [installedProps]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toggleModal = useCallback((value) => {
    setOpen((open) => !open);
    setActionCurrent(value);
  }, []);
  const toggleInstall = useCallback(
    () => setInstalled((installed) => !installed),
    []
  );

  const handleApiKeyValue = useCallback((value) => {
    if (!value) {
      setApiKeyError("API Key is required.");
    } else {
      setApiKeyError(null);
    }
  }, []);

  const handleApiKeyChange = useCallback((value) => {
    setApiKey(value);
    handleApiKeyValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCreate = useCallback(() => {
    handleApiKeyValue(apiKey);
    if (apiKey && !apiKeyError) {
      createFulfillmentConfigure({
        variables: {
          input: {
            apiKey,
            fulfillmentId: "printway",
          },
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeyError, apiKey]);

  const handleDelete = useCallback(() => {
    deleteFulfillmentConfigure({
      variables: {
        slug: "printway",
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess = data && data.createFulfillmentConfigure;
  let toastSuccessDelete = dataDelete && dataDelete.deleteFulfillmentConfigure;

  const handleContentToast = () => {
    if (error) return handleError(error.toString());
    if (errorDelete) return handleError(errorDelete.toString());
    if (toastSuccess) return "Printway fulfillment config has been saved successfully.";
    if (toastSuccessDelete) return "Uninstall Printway fulfillment successfully.";
  };

  const toastMarkup = activeToast
    ? (error || toastSuccess || toastSuccessDelete || errorDelete) && (
        <Toast
          content={handleContentToast()}
          error={error || errorDelete}
          duration={8000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      {!installed ? (
        <Button primary onClick={() => toggleModal("install")}>
          Install
        </Button>
      ) : (
        <Button
          primary
          onClick={() => toggleModal("uninstall")}
          children="Uninstall"
        />
      )}
      <Modal
        title="Install Printway"
        open={open && "install" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleModal }]}
        primaryAction={{
          content: "Install",
          onAction: handleCreate,
          loading: loading,
        }}
      >
        <TextField
          label="API Key(*)"
          value={apiKey}
          onChange={handleApiKeyChange}
          placeholder="Enter your API Key"
          error={apiKeyError}
        />
		<p>You can go to your <a target="_blank" href="https://pro.printway.io/users/profile">Profile page</a> and get <b>API Key</b> in <b>Setting</b> tab.</p>
      </Modal>
      <Modal
        title="Uninstall Printway"
        open={open && "uninstall" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleDelete,
          loading: loadingDelete,
        }}
      >
        <TextContainer>
          Are you sure uninstall this config fulfillment?
        </TextContainer>
      </Modal>
    </>
  );
};
