import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { StatisticPolaris } from "../../components/tasks/statistic";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const StatisticPagePolaris = (...props) => {
    const path = getPathFromRouter(props);
    return (
        <Container>
            <Page title="Statistic" fullWidth>
                <StatisticPolaris path={path} />
            </Page>
        </Container>
    );
};
