import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Button, Checkbox, Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REPORT_SALE_DESIGNERS } from "../../../graphql/queries";
import { getUnique } from "../../../helper";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";

const collapseKey = "designers";
export function Designers({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    doneDate: null,
    storeManagerID: null,
    storeID: null,
  });

  const [isDoneDate, setIsDoneDate] = useState(false);
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalSale: 0,
    totalDoneKpis: 0,
    totalDoneTasks: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalDoneKpis: 0,
    totalDoneTasks: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, error, fetchMore } = useQuery(REPORT_SALE_DESIGNERS, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });
  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_SALE_DESIGNERS);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      let ignoredFilter = filterCtx;
      if ("viewWholeTeamReport" in filterCtx) {
        let viewWholeTeamReport;
        ({ viewWholeTeamReport, ...ignoredFilter } = filterCtx);
      }
      setFilter((prev) => ({ ...prev, ...ignoredFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportKpiByDesigners?.nodes;
    const total = data?.reportKpiByDesigners?.total;
    const totalDoneKpis = data?.reportKpiByDesigners?.totalDoneKpis;
    const totalDoneTasks = data?.reportKpiByDesigners?.totalDoneTasks;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            const totalDoneKpi = node?.totalDoneKpi;
            const totalDoneTasks = node?.totalDoneTasks;
            const rejectedTimeCount = node?.rejectedTimeCount;
            const assignee = node?.assignee;
            const firstName = assignee?.firstName;
            const lastName = assignee?.lastName;
            const name = [firstName, lastName].filter(Boolean).join(" ");

            return {
              name,
              totalDoneKpi,
              totalDoneTasks,
              rejectedTimeCount,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalDoneKpis,
      totalDoneTasks,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalDoneKpis = dataC?.reportKpiByDesigners?.totalDoneKpis;
      const totalDoneTasks = dataC?.reportKpiByDesigners?.totalDoneTasks;
      setStateCompare((prev) => ({
        ...prev,
        totalDoneKpis,
        totalDoneTasks,
      }));
    } else {
      setStateCompare({ totalDoneKpis: 0, totalDoneTasks: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportKpiByDesigners.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportKpiByDesigners = fetchMoreResult.reportKpiByDesigners;
            const nodes = reportKpiByDesigners.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [...prev.reportKpiByDesigners.nodes, ...nodes];
            const result = getUnique(newNodes, "assigneeId");

            return {
              ...prev,
              reportKpiByDesigners: {
                ...prev.reportKpiByDesigners,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const styleSpan = {
    display: "inline-block",
    width: "8rem",
    textAlign: "right",
  };
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <Stack
              key={`assignee-${index}`}
              distribution="equalSpacing"
              wrap={false}
            >
              <span className="index-wrap">{index + 1}</span>
              <Stack.Item fill>{item.name}</Stack.Item>
              <span className="column-wrap">{item.totalDoneTasks}</span>
              <span className="column-wrap">{item.totalDoneKpi}</span>
              <span style={styleSpan}>{item.rejectedTimeCount}</span>
            </Stack>
          );
        })
      : null;
  }, [state.items, styleSpan]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Name</TextStyle>
      </Stack.Item>
      <span className="column-wrap">
        <TextStyle variation="strong">Done Task</TextStyle>
      </span>
      <span className="column-wrap">
        <TextStyle variation="strong">Done KPI</TextStyle>
      </span>
      <span style={styleSpan}>
        <TextStyle variation="strong">Reject time</TextStyle>
      </span>
    </Stack>
  );

  const handleFilterDoneDate = React.useCallback((value) => {
    setIsDoneDate(value);
    setFilter((p) => ({ ...p, doneDate: value ? p.filterByTime : null }));
  }, []);

  const subTitleMarkup = useMemo(
    () => (
      <Stack>
        <Checkbox
          label="Done date"
          checked={isDoneDate}
          onChange={handleFilterDoneDate}
        />
        <Stack vertical spacing="extraTight">
          <span>
            <span>{state.totalDoneKpis} KPIs</span>
            <ComparedPercent
              originalVal={state.totalDoneKpis}
              newVal={stateCompare.totalDoneKpis}
            />
          </span>
          <span>
            <span>{state.totalDoneTasks} Tasks</span>
            <ComparedPercent
              originalVal={state.totalDoneTasks}
              newVal={stateCompare.totalDoneTasks}
            />
          </span>
        </Stack>
      </Stack>
    ),
    [
      state.totalDoneKpis,
      state.totalDoneTasks,
      stateCompare.totalDoneKpis,
      stateCompare.totalDoneTasks,
      isDoneDate,
      handleFilterDoneDate,
    ],
  );

  return (
    <CardBox
      title="Designers"
      subTitle={subTitleMarkup}
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      total={state.total}
      loading={mergedLoading}
      error={error || errorC}
      setFilter={setFilter}
      handleScroll={handleScroll}
      loadMore={loadMore}
      collapseKey={collapseKey}
    />
  );
}
