import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Badge, Toast } from "@shopify/polaris";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";

import noImage from "../../../assets/images/unnamed.jpeg";
import { CLAIMS_STATUS_COLORS_POLARIS } from "../../../variable";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { ClaimsActionsPolaris } from "./ClaimsActionsPolaris";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";

const Container = styled.div`
  .order_wrap {
    width: calc(220px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .type_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .status_wrap {
    width: calc(180px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .store_wrap {
    width: calc(17rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .evidence_wrap {
    width: calc(120px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .note_wrap {
    width: calc(230px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .actions_wrap {
    width: calc(400px - 3.2rem);
    white-space: normal;
    word-break: break-word;
    display: flex;
    justify-content: flex-end;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const ClaimsTableDataPolaris = (props) => {
  // Props
  const { data, filter, setFilter, refetch } = props;

  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Get data
  useEffect(() => {
    let newData =
      data && data.claims && data.claims.nodes && data.claims.nodes.length > 0
        ? data.claims.nodes.map((c) => {
            let orderId = c?.orderId;
            let type = c?.type;
            let created = c?.createdAt;
            let storeTitle = c?.order?.store?.title;
            let note = c?.comment;
            let files = c?.files;
            const rejectReason = c?.rejectReason;

            // Status
            let status = c?.status;
            const resultStatus = CLAIMS_STATUS_COLORS_POLARIS.find(
              (o) => o.name === status
            );
            return [
              <div className="order_wrap">
                <span>{orderId}</span>
              </div>,
              <div className="type_wrap">
                <span>{type}</span>
              </div>,
              <div className="create_wrap">
                <span>{moment(created).format("YYYY-MM-DD HH:mm:ss")}</span>
              </div>,
              <div className="status_wrap">
                <Badge
                  children={resultStatus?.name}
                  status={resultStatus?.status}
                  progress={resultStatus?.progress}
                />
              </div>,
              <div className="reject-reason">
                <span>{rejectReason}</span>
              </div>,
              <div className="store_wrap">
                <span>{storeTitle}</span>
              </div>,
              <div className="evidence_wrap">
                {files.length > 0 ? (
                  <ModalImageClaimPolaris files={{ files }} limit={1} />
                ) : (
                  <img
                    alt=""
                    src={noImage}
                    style={{ width: 60, height: 60, objectFit: "cover" }}
                  />
                )}
              </div>,
              <div className="note_wrap">
                <span>{note}</span>
              </div>,
              <div className="actions_wrap">
                <ClaimsActionsPolaris
                  claim={c}
                  refetch={refetch}
                  toggleActive={toggleActive}
                  onNotificationChange={({ message, isError }) =>
                    setNotification(() => ({ message, isError }))
                  }
                />
              </div>,
            ];
          })
        : [];
    setRows(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  let { limit, offset } = filter;
  const total = get(data, "claims.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "Order",
          "Type",
          "Created",
          "Status",
          "Reject reason",
          "Store",
          "Evidence",
          "Note",
          "Actions",
        ]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
