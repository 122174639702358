import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import Price from "../Price";
import { Radio } from "antd";
import styled from "styled-components";

const Container = styled.div`
  .carrier-pricing-radio-group {
    > label {
      display: flex;
      span.ant-radio {
        margin-top: 14px;
      }
    }
  }
  .ant-radio-checked::after {
    height: 14px !important;
  }
`;
class CarrierPricing extends Component {
  render() {
    const { carriers } = this.props;
    let { value } = this.props;
    let mapCarrierById = {};
    for (let i = 0; i < carriers.length; i++) {
      mapCarrierById[carriers[i].id] = carriers[i];
    }
    let defaultIndex = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i].default) {
        defaultIndex = i;
      }
    }

    return (
      <Container>
        <Radio.Group
          className={"carrier-pricing-radio-group"}
          onChange={(v) => {
            for (let i = 0; i < value.length; i++) {
              value[i].default = false;
            }
            value[v.target.value].default = true;
            if (this.props.onChange) {
              this.props.onChange(value);
            }
          }}
          value={defaultIndex}
        >
          {value.map((v, index) => {
            const carrier = mapCarrierById[v.carrierId];
            return (
              <Radio key={index} value={index}>
                <Form.Item key={index} label={`${carrier.name}`}>
                  <Price
                    value={v.price ? v.price : 0}
                    onChange={(newValue) => {
                      value[index].price = newValue;
                      if (this.props.onChange) {
                        this.props.onChange(value);
                      }
                    }}
                  />
                </Form.Item>
              </Radio>
            );
          })}
        </Radio.Group>
      </Container>
    );
  }
}

CarrierPricing.propTypes = {
  carriers: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default CarrierPricing;
