import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import ProductCrawlForm from "../../components/seller/ProductCrawlForm";
import { Button, Modal } from "antd";
import QuickGuide from "./QuickGuide";

class ProductCrawler extends Component {
  state = {
    visible: false,
  };
  render() {
    return (
      <div>
        <PageTitle
          title={"Product Crawler"}
          subTitle={"All Crawlers"}
          link="/tools/product-crawlers"
          action={
            <Button onClick={() => this.setState({ visible: true })}>
              Quick Guide
            </Button>
          }
        />
        <ProductCrawlForm />
        <Modal
          title="Quick Guide"
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          width="70%"
          footer={null}
        >
          <QuickGuide />
        </Modal>
      </div>
    );
  }
}

export default ProductCrawler;
