export const ORDER_STATUS = {
  PendingDesign: "Pending Design",
  Pending: "Pending",
  OnHold: "On Hold",
  ReadyToPrint: "Ready to Print",
  InProduction: "In Production",
  Printing: "Printing",
  Fulfilled: "Fulfilled",
  Cancelled: "Cancelled",
  Error: "Error",
  PendingPayment: "Pending Payment",
  DesignRejected: "Design Rejected",
  Refunded: "Refunded",
  Processing: "Processing",
};

export const USER_ROLE = {
  Admin: "Admin",
  Seller: "Seller",
  Supplier: "Supplier",
  Owner: "Owner",
  StoreManager: "Store Manager",
  Supporter: "Supporter",
};

export const TEAM_ROLE = {
  StoreManager: "Store Manager",
  Supporter: "Supporter",
  Designer: "Designer",
  Admin: "Admin",
  DesignLeader: "Design Leader",
  Supplier: "Supplier",
  MarketplaceManager: "Marketplace Manager",
  Idea: "Idea",
  IdeaLeader: "IdeaLeader",
  Accountant: "Accountant",
  Artist: "Artist",
  ArtistLeader: "Artist Leader",
};

export const Administrator = "Administrator";

export const UPLOAD_FOLDER = {
  ASSETS: "assets",
  AVATARS: "avatars",
  MOCKUPS: "mockups",
  PRINT_FILES: "print_files",
  SUPPLIER_IMPORT: "supplier_import_files",
  PSD_RESOURCE: "product_resouces",
};

export const TASK_STATUS = {
  Pending: "Pending",
  Denied: "DENIED",
  Done: "Done",
};

export const TASK_LABEL = {
  RejectedByBuyer: "Rejected by Buyer",
  AwaitingArtistFix: "Awaiting Artist fix",
};

export const TASK_TYPE = {
  ArtistTask: "Artist Task",
  IdeaTask: "Idea Task",
};

export const ImageTypes = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
];

export const PLATFORMS = {
  Ebay: "ebay",
  Amazon: "amazon",
  WooCommerce: "woocommerce",
  Shopify: "shopify",
  ShopBase: "shopbase",
  Etsy: "etsy",
  Facebook: "facebook",
  Tiktok: "tiktok",
};
export const PLATFORM_ETSY = "etsy";
export const PLATFORM_FACEBOOK = "facebook";

export const CLAIM_STATUS = {
  Approved: "Approved",
  Cancelled: "Cancelled",
  InReview: "In Review",
  Resent: "Resent",
  Rejected: "Rejected",
  Refunded: "Refunded",
  NeedMoreEvidence: "Need more evidence",
};

export const TEAM_ROLE_PARAMS = {
  MarketplaceManager: "marketplace-manager",
  StoreManager: "store-manager",
  Seller: "seller",
  DesignLeader: "design-leader",
  Designer: "designer",
  IdeaLeader: "idea-leader",
  Idea: "idea",
  SellerSupporter: "seller-supporter",
  Supplier: "supplier",
  Artist: "artist",
  ArtistLeader: "artist-leader",
};

export const USER_ROLE_PARAMS = {
  Administrator: "admin",
  Seller: "seller",
  Supporter: "supporter",
  Supplier: "supplier",
};

export const CUSTOMCAT_SHIPPING_RATE = [
  {
    title: "Supper Lightweight",
    value: "Supper Lightweight",
    shippingCost: {
      usFirstFee: 3.99,
      usAdditionalFee: 0.75,
      canadaFirstFee: 3.99, //4.5,
      canadaAdditionalFee: 0.75, //1.0,
      internationalFirstFee: 6.25, //5.5,
      internationalAdditionalFee: 1.0,
    },
  },
  {
    title: "LightWeight",
    value: "LightWeight",
    shippingCost: {
      usFirstFee: 4.99,
      usAdditionalFee: 1.5,
      canadaFirstFee: 4.99, //6.5,
      canadaAdditionalFee: 1.5, //3.0,
      internationalFirstFee: 9.25, //8.5,
      internationalAdditionalFee: 3.0,
    },
  },
  {
    title: "HeavyWeight",
    value: "HeavyWeight",
    shippingCost: {
      usFirstFee: 7.99,
      usAdditionalFee: 1.5,
      canadaFirstFee: 7.99, //10.5,
      canadaAdditionalFee: 1.5, //6.0,
      internationalFirstFee: 12.25, //12.0,
      internationalAdditionalFee: 8.0,
    },
  },
  {
    title: "LightWeight DrinkWare",
    value: "LightWeight DrinkWare",
    shippingCost: {
      usFirstFee: 4.99,
      usAdditionalFee: 1.5,
      canadaFirstFee: 4.99, //8.5,
      canadaAdditionalFee: 1.5, //5.0,
      internationalFirstFee: 10.0,
      internationalAdditionalFee: 6.0,
    },
  },
  {
    title: "HeavyWeight DrinkWare",
    value: "HeavyWeight DrinkWare",
    shippingCost: {
      usFirstFee: 6.99, //5.99,
      usAdditionalFee: 1.5,
      canadaFirstFee: 6.99, //10.5,
      canadaAdditionalFee: 1.5, //5.0,
      internationalFirstFee: 12.25, //12.0,
      internationalAdditionalFee: 6.0,
    },
  },
];

export const GEARMENT_SHIPPING_RATE = {
  shippingCost: {
    usFirstFee: 4.99,
    usAdditionalFee: 1.5,
    canadaFirstFee: 7.5,
    canadaAdditionalFee: 5.95,
    internationalFirstFee: 7.5,
    internationalAdditionalFee: 5.95,
  },
};

export const META_DATA = "merchbridge.com/personalized";

export const DEFAULT_COLLECTIONS_LIVE = {
  hGypw: "NO-TM",
  da1Jg: "TM",
  rTMb7neFvk: "Animal",
  uHWGE1Ahic: "Hobbies",
  m55RHzFk9m: "People",
  TIJrLGarfW: "Movies",
  p7CPRxL8O7: "Books",
  "6r3vVNTrYm": "Drink And Food",
  id9TDw77Bo: "Events",
  DR0os: "Jobs",
  TcEKaMPkid: "Games",
  OnCIa7Qvqw: "Family",
  aa1Jk: "Sports",
  X1qcK0de9d: "TV Shows",
  PxlDloxjZ6: "Fictional Characters",
};

export const SCREEN_OPTIONS_PA = [
  "product",
  "originDomain",
  "niche",
  "source",
  "createdAt",
  "author",
];

export const ORDER_STATUS_COLORS_POLARIS = [
  { name: "Pending Design", status: "attention" },
  { name: "Pending", status: "info", progress: "incomplete" },
  { name: "On Hold", status: "info" },
  { name: "In Production", status: "success", progress: "incomplete" },
  { name: "Fulfilled", status: "success", progress: "complete" },
  { name: "Cancelled", status: "critical" },
  { name: "Error", status: "warning" },
  { name: "Design Rejected", status: "warning", progress: "complete" },
  { name: "Refunded", status: "default" },
  { name: "Pending Payment", status: "new" },
  {
    name: "Ready to Print",
    status: "success",
    progress: "partiallyComplete",
  },
  { name: "Printing", status: "attention" },
  { name: "Processing", status: "success", progress: "incomplete" },
];

export const TASK_STATUS_COLORS_POLARIS = [
  { name: "Doing", status: "success", progress: "incomplete" },
  { name: "Cancel", status: "critical" },
  { name: "Pending", status: "info", progress: "incomplete" },
  { name: "Artwork Configurable", status: "info", progress: "complete" },
  { name: "Need Review", status: "attention" },
  { name: "Need Buyer Confirm", status: "attention" },
  { name: "Awaiting Buyer Confirm", status: "attention", progress: "complete" },
  { name: "Denied", status: "warning" },
  { name: "Declined", status: "warning", progress: "partiallyComplete" },
  { name: "Done", status: "success", progress: "complete" },
  { name: "Rejected", status: "warning", progress: "complete" },
  { name: "In Review", status: "attention", progress: "incomplete" },
  { name: "Expired", status: "critical", progress: "incomplete" },
  {
    name: "Partially Completed",
    status: "success",
    progress: "partiallyComplete",
  },
  { name: "File Generating", status: "info" },
  { name: "Draft", status: "default" },
];

export const COMMON_FILTER = ["limit", "offset", "search"];

export const ACCOUNT_SPECIAL = "pm_online_designer@gmail.com";

export const FIELDS_EXPORT_AMAZON = [
  "feed_product_type",
  "item_sku",
  "brand_name",
  "item_name",
  "external_product_id",
  "item_type",
  "standard_price",
  "quantity",
  "main_image_url",
  "other_image_url1",
  "other_image_url2",
  "other_image_url3",
  "other_image_url4",
  "other_image_url5",
  "other_image_url6",
  "other_image_url7",
  "other_image_url8",
  "update_delete",
  "product_description",
  "bullet_point1",
  "bullet_point2",
  "bullet_point3",
  "bullet_point4",
  "bullet_point5",
  "target_audience_base",
  "specific_uses_keywords1",
  "specific_uses_keywords2",
  "specific_uses_keywords3",
  "specific_uses_keywords4",
  "specific_uses_keywords5",
  "generic_keywords",
  "color_name",
  "color_map",
  "size_name",
  "material_type",
  "mfg_minimum",
  "website_shipping_weight",
  "website_shipping_weight_unit_of_measure",
  "fulfillment_latency",
  "condition_note",
  "relationship_type",
  "manufacturer",
  "thesaurus_attribute_keywords1",
  "thesaurus_attribute_keywords2",
  "thesaurus_attribute_keywords3",
  "thesaurus_attribute_keywords4",
  "variation_theme",
  "country_as_labeled",
  "cpsia_cautionary_statement",
  "import_designation",
  "country_string",
  "country_of_origin",
  "merchant_shipping_group_name",
  "condition_type",
  "product_tax_code",
  "parent_sku",
  "target_audience_keywords1",
  "target_audience_keywords2",
  "target_audience_keywords3",
  "thesaurus_subject_keywords1",
  "thesaurus_subject_keywords2",
  "thesaurus_subject_keywords3",
  "platinum_keywords1",
  "platinum_keywords2",
  "platinum_keywords3",
  "platinum_keywords4",
  "platinum_keywords5",
  "special_features1",
  "special_features2",
  "special_features3",
  "special_features4",
  "special_features5",
  "special_features6",
  "length_longer_edge",
  "width_shorter_edge",
];

export const LABELS_EXPORT_AMAZON = {
  color: "Product Option: Color",
  other_image_url_1: "Product 1st gallery image",
  other_image_url_2: "Product 2nd gallery image",
  other_image_url_3: "Product 3rd gallery image",
  other_image_url_4: "Product 4th gallery image",
  other_image_url_5: "Product 5th gallery image",
  other_image_url_6: "Product 6th gallery image",
  other_image_url_7: "Product 7th gallery image",
  other_image_url_8: "Product 8th gallery image",
  product_base: "Product base",
  product_description: "Product description",
  product_mockup: "Product mockup",
  product_parent_child: "Product Parent: Child",
  product_price: "Product price",
  product_title: "Product title",
  sku: "Variant SKU",
  relationship_type: "Relationship Type",
  size: "Product Option: Size",
  parent_sku: "Parent SKU",
  product_option: "Product Option",
};

export const SCALABLEPRESS_DESIGN_TYPE = {
  // screenprint: "Screen Print",
  dtg: "DTG",
  case: "Case",
  mug: "Mug",
  poster: "Poster",
};

export const SCALABLEPRESS_HORIZONTAL_POSITION = {
  C: "Center of garment",
  RC: "Center of right side of garment / right chest",
  LC: "Center of left side of garment / left chest",
  R: "Right aligned to garment",
  L: "Left aligned to garment",
};

export const TEMPLATES_EXPORT_AMAZON = {
  blanket: {
    feed_product_type: "blanket", //"bedandbath",
    item_type: "bed-blankets",
    material_type: "Fleece",
    bullet_point1: `SPECIAL GIFT: Click "Customize Now" to select your favorite sizes, recipient, sender, content, which you would like before Adding to Your Cart.`,
    bullet_point2: `PRINTED & ASSEMBLED IN USA: All of our Fleece and Sherpa blankets are handcrafted to the highest quality standards and shipped out from USA. A Personalized Custom Mom Mother Dad Father Name Message To My Daughter Overwhelmed Letter Airmail Postcard Postage Stamp Envelope is a unique sentimental gifts for certain special occasions such as Birthday, Wedding Anniversary, Appreciation, Graduation, Christmas, Halloween, Valentine's Day, Mother's Day, Father's day, etc.`,
    bullet_point3: `PREMIUM MATERIAL Each fleece blanket features a premium 100 % suede polyester print for beautiful color vibrancy, even after washing. High-quality thread; Modern and decorative pattern; printed edge to edge on one side. Lightweight, durable, Cozy underside is constructed from an ultra-soft micro fleece fabric to keep you warm and comfortable.`,
    bullet_point4: `MACHINE WASHABLE: Wash separately in cold water and then either let them air dry without heat. Microfiber dries quickly and it will fluffier than before. It's better wash microfiber fleece blankets in a mesh laundry bag.`,
    bullet_point5: `CUSTOMER SATISFACTION: If you have any issue, please send the email that comes with the product for a REPLACEMENT or REFUND with 30-Day Warranty. It is our intention to offer the best items and customer service on Amazon.`,
    fulfillment_latency: "3",
    length_longer_edge: "60", //Item Length Head to Toe
    width_shorter_edge: "50", //Item Width Side to Side
  },
  mug: {
    feed_product_type: "drinkingcup", //"kitchen",
    item_type: "novelty-coffee-mugs",
    material_type: "Ceramic",
    bullet_point1: `Our customizable product will be an exclusive mug belonging to you and your beloved family. Please click 'CUSTOMIZE NOW' to choose cup sizes and customize your own message.`,
    bullet_point2: `This mug is proudly made in the USA, fast fulfilled and delivered. It would be shipped with care in a sturdy box which ensures its arrival in perfect condition.`,
    bullet_point3: ` It is a classical high-capacity mug made of durable ceramic material which is safe for drinking, dishwasher and microwave safe.`,
    bullet_point4: `This novelty personalised mug would be the unique gift idea for dog lovers, your family, friends and yourself on Birthday, Christmas, Easter, Anniversary, Thanksgiving, Halloween, Holidays, New Year, Mother Day, Father Day, House-warming,...`,
    bullet_point5: `All our products are 100% satisfation guarateed. In case you have any problem or query, please feel free to contact us. our staff will do our best to offer the most pleasing item as possible.`,
    fulfillment_latency: "",
  },
  poster: {
    feed_product_type: "home", //"home",
    item_type: "prints",
    material_type: "Paper",
    bullet_point1: `SPECIAL GIFT: Please click 'Customize Now' to choose among three available sizes: 12" x 18"/16" x 24"/24" x 36" for verticle portrait poster and 18" x 12"/24" x 16"/36" x 24 for horizontal landscape.`,
    bullet_point2: `PROFESSIONAL DESIGN: The item is conscientiously designed and produced. No-framed poster is a piece of paper artprint without wooden frame, which is easy to be glued and removed without any nail driven onto wall or other surfaces. It may be mixed with frame at customers' will.`,
    bullet_point3: `%100 SATISFACTION GUARANTEED: This product is proudly made in the USA; fast fulfilled and delivered. It will be shipped with care in a sturdy box ensuring arrival in perfect condition. We also ensure 100% quality guarantee and offer refund/resend for any defected item.`,
    bullet_point4: `MULTI-PURPOSE PRODUCT: The no frame wall art may be put into wood frame and display on desk/table (for small sizes) or hung onto wall as wall art. It will be a great choice for living room, bedroom, kitchen, bathroom and entryway walls which becomes a nice gift to someone special.`,
    bullet_point5: `PREMIUM MATERIAL: The poster will come with great visual quality because of its high-resolution digital oil painting giclee printed on shiny high quality resin-coated thick paper with fade resistant environmentally friendly ink.`,
    fulfillment_latency: "3",
  },
  tumbler: {
    feed_product_type: "drinkingcup", //"kitchen",
    item_type: "travel-mugs-&-tumblers",
    material_type: "Steel",
    bullet_point1: `UNIQUE TUMBLER & TRULLY ARTISTS : this design was drawn and heat printed with pride by our skilled illustrators and printers in an industrial building with vibrant interior walls decorated with the compelling work of well-known culture, shades, colorful. A premium tumbler cup made by creative, young workers.`,
    bullet_point2: `STAINLESS STEEL TUMBLER & LARGER CAPACITY: with the body made from 18/8 food grade stainless steel, which is durable, electro polished on the inside to ensure your cups remain rust-free, and colored powder coated finish on the outside make it super easy-grip.`,
    bullet_point3: `DOUBLE-WALLED VACUUM INSULATION: prevent heat transfer to the outer walls of the tumbler. This means your drink still keeps its temperature no matter how much of a beating this cup takes. so you could enjoy your beverage cold by the pool, take your cup of joe on the go. so as to maintain its thermal insulation, hand wash recommended. INSULATED COFFEE TRAVEL MUG.`,
    bullet_point4: `MEANINGFUL GIFTS: Perfect for office workers, drivers, students, teachers, BBQ experts, party favors, firefighter, nurse, police...Larger capacity tumbler 20oz,is designed for everyday use at home, in the office, outdoor parties and events, such as sports, traveling, camping and driving so why not choose ones for your family and friends as birthday gifts or Christmas gifts.`,
    bullet_point5: `100% CUSTOMER SATISFACTION: We value every customer response and offer friendly customer service. In any case you are unsatisfied with the purchase of our product, just feel free to reach us. We are willing to help you in every possible way. We sincerely hope you could enjoy the purchase and enjoy your life carry with our tumbler. Finally, it comes in a variety of stunning ARTWORK. Come to choose a color of you!`,
    fulfillment_latency: "4",
    product_description: `The versatile and stylish tumbler is an ideal gift to celebrate holidays, birthdays, weddings and many more so gorgeous. No matter it is for your families and friends, or for your colleagues and customers, this present is sure to be enjoyed by everyone.`,
  },
  "t-shirts": {
    feed_product_type: "T-Shirt",
    quantity: "999",
    variation_theme: "Size",
    update_delete: "Update",
    country_as_labeled: "United States",
    size_name: "Small",
    material_type: "Cotton",
    website_shipping_weight: "1",
    website_shipping_weight_unit_of_measure: "LB",
    cpsia_cautionary_statement: "NoWarningApplicable",
    import_designation: "Printed in USA",
    country_of_origin: "United States",
    condition_type: "New",
    product_tax_code: "A_GEN_NOTAX",
    fulfillment_latency: "3",
    merchant_shipping_group_name: "Migrated Template",
  },
  ornament: {
    feed_product_type: "hangingornament",
    item_type: "decorative-hanging-ornaments",
    unit_count_type: "ounce",
    unit_count: "0.5",
    quantity: "99",
    country_of_origin: "United States",
    fulfillment_latency: "3",
    condition_type: "New",
    size_name: "one size",
    material_type: "Durable MDF with a high-gloss plastic finish",
    bullet_point1: `Crafted from durable MDF with a high-gloss plastic finish so your designs last a lifetime, Vibrantly printed from edge-to-edge via Sublimation, Ships with a festive gold cord for easy hanging, Available in 4 Shapes: Circle, Oval, Heart, & Star, Product Dimensions: Circle & Heart, 2.75” tall; Oval & Star, 3.25” tall`,
    bullet_point2: `LOVELY & CREATIVE DESIGNS: 2021 is a tough year. However, The supreme happiness of life is the conviction that we are loved. Celebrate the Christmas holidays together in style with this lovely & creative, unique Christmas tree ornaments design. Personalized Tree Christmas Ornament Comes with a Ribbon Loop for Easy Hanging on Your Christmas Tree.`,
    bullet_point3: `HIGH-QUALITY MATERIAL: This high-quality high-gloss plastic Christmas ornament has a premium hard that provides crisp and vibrant color reproduction sure to last for years. Product with dimensions 2.75” tall, 3.25” tall available in 4 shapes: Circle, Oval, Heart, & Star. Perfect for all Christmas tree decorations.`,
    bullet_point4: `PERFECT CHRISTMAS GIFTS: Christmas 2021 will definitely be a year to remember with our ornaments because it is perfect memorable gift for your family and friends on soon Christmas!`,
    bullet_point5: `100% HAPPY GUARANTEED: Our ornaments come with 10 Day %100 Money Back and Customer Satisfaction Guarantee. Thank you very much for purchasing in Store. If you have any questions about the use process, please contact our professional customer service. Please click on our brand to see more products.`,
  },
};

export const CLAIMS_STATUS_COLORS_POLARIS = [
  { name: "Approved", status: "success" },
  { name: "Cancelled", status: "critical" },
  { name: "In Review", status: "info" },
  { name: "Resent", status: "attention" },
  { name: "Rejected", status: "warning" },
  { name: "Refunded", status: "warning", progress: "incomplete" },
  { name: "Need more evidence", status: "default" },
];

// Reuse class Shopify polaris.
const DATA_TABLE = "Polaris-DataTable";
const DT_CELL = DATA_TABLE + "__Cell";

export const DATA_TABLE_POLARIS = {
  dataTable: DATA_TABLE,
  dTCondensed: DATA_TABLE + "--condensed",
  dTScrollContainer: DATA_TABLE + "__ScrollContainer",
  dTTable: DATA_TABLE + "__Table",
  dTCell: DT_CELL,
  dTCellFirstColumn: DT_CELL + "--firstColumn",
  dTCellHeader: DT_CELL + "--header",
  dTCellVerticalAlignMiddle: DT_CELL + "--verticalAlignMiddle",
  dTCellNumeric: DT_CELL + "--numeric",
  dTRow: DATA_TABLE + "__TableRow",
};

export const DESIGNER_HAVE_RECREATE_MOCKUP = ["pp11t7kxjy", "2Z8k3H8DVB"];

export const STORE_MANAGE_SHOW_ALL_PRODUCTS = ["rrXwa4o0Yk"];

export const MAX_LENGTH = 255;

export const TITLE_LENGTH_MESS = "The title must be less than 255 characters.";

export const SHIRT_BASE_IDS = ["GHevXo5NW1"];

export const TUMBLER_BASE_IDS = [
  "ptuFtbN7nR",
  "BQszpDb8kT",
  "DEIlX6Smfo",
  "IILAha0cGl",
  "OwYPUhXXKC",
];
export const PME = "PME";

export const OREFER_URL = "https://0refer.com/?";

// Thuy, Ly
export const DESIGNER_SPECIAL = {
  accIds: ["vHzPLObif8", "VRksM7NNV3", "ZeXbgxfug1"],
  role: ["Designer"],
  accLy: "vHzPLObif8",
  accDung: "ZeXbgxfug1",
};

export const ACCS_HAVE_COLOR_PICKER = [
  "OUmYdbUfVn",
  "Zs0svJ9omN",
  "GQIylA4Xax",
  "G7PHfYjvjp",
  "yMz4dfN2hc", // lethuha9825@gmail.com"
  "t7dcCKKqcE", // Ngochuyen@gmail.com
  "CE55ydNfOk", // Quynhmo@gmail.com
  "fS2AfKEaBA", // ThaomyAMZ@gmail.com
  "wwQL8JVCNM", // QuynhNguyen@gmail.com
]; //Thuha@gmail.com, thutran@gmail.com, hoanganh.thieuhoa@gmail.com, lanpham@merchbridge.com

export const ACC_HR_ID = "FdadyMIK6W"; // Account @yenhr

export const IGNORE_PERSONALIZED_KEYS = [
  "color",
  "colors",
  "size",
  "sizes",
  "shape",
  "shapes",
  "type",
  "types",
  "charm",
  "charms",
  "box",
  "boxs",
  "Frame Material",
  "Material",
  "Note",
  "Option Dropdown",
];

export const META_DATA_KEY_MB = "mb_personalized";

export const PRODUCT_SKU_SYNTAX = "{{PRODUCT_SKU}}";
export const PRODUCT_BASE_TITLE_SYNTAX = "{{PRORUCT_BASE_TITLE}}";
export const ATTR_SIZE_SYNTAX = "{{ATTR_SIZE}}";
export const NAS_FILE_NAME_SYNTAX = `${PRODUCT_SKU_SYNTAX}_${PRODUCT_BASE_TITLE_SYNTAX}`;

export const FULFILLMENT_COLOR_MAPPING = {
  gearment: {
    Ash: "Ash",
    Black: "Black",
    Charcoal: "Charcoal Grey",
    DarkHeather: "",
    ForestGreen: ["Forest Green", "Forest"],
    Heliconia: "Fuchsia",
    Maroon: "Maroon",
    Orange: ["Burnt Orange", "Orange"],
    Purple: "",
    SafetyPink: "Cyber Pink",
    White: "White",
    LightBlue: ["Light Blue", "Baby Blue"],
    Red: "",
    YellowHaze: "Sunshine",
    DarkChocolate: "Charcoal Grey",
    IrishGreen: "Kelly",
    Navy: "J Navy",
    Royal: "Royal",
    Cardinal: "",
    Gravel: "Asphalt",
    Azalea: "Heliconia",
    BrownSavana: "Chocolate",
    CarolinaBlue: "Carolina Blue",
    IndigoBlue: "",
    Kiwi: "",
    LightPink: "Classic Pink",
    Lime: "Kiwi",
    Sapphire: "",
    Sky: "",
    Violet: "",
    SportGrey: "Sports Grey",
    Daisy: "Gold",
    TropicalBlue: "",
    Gold: "",
  },
  customcat: {
    LightBlue: "Light Blue",
    White: "White",
    ElectricGreen: "Kiwi",
    TurfGreen: "",
    Daisy: "Gold",
    Kiwi: "",
    MilitaryGreen: "",
    Garnet: "",
    LightPink: "Classic Pink",
    Lime: "",
    Maroon: "",
    Navy: "Navy",
    OldGold: "",
    Orange: "Orange",
    Purple: "",
    Red: "True Red",
    Royal: "Royal",
    SportGrey: "Sport Grey",
    TexasOrange: "",
    Ash: "Ash",
    Black: "Black",
    Cardinal: "",
    CarolinaBlue: "Carolina Blue",
    DarkChocolate: "Dark Chocolate",
    DarkHeather: "",
    Forest: "",
    Gold: "",
    Heliconia: "Cyber Pink",
    IrishGreen: ["Kelly", "Irish Green"],
  },
  dreamship: {
    Black: "Black",
    White: "White",
    Navy: "Navy",
    Royal: "Royal",
    HeatherGrey: "",
    Red: "Red",
    Kelly: "Kelly",
    Forest: "Forest",
    Orange: "Orange",
    Purple: "Purple",
    DarkHeatherGrey: ["Heather", "Charcoal Grey"],
    SafetyPink: ["Cyber Pink", "Fuchsia"],
    Azalea: "Heliconia",
    LightPink: "Light Pink",
    Heliconia: "Fuchsia",
    LightBlue: "Light Blue",
    CarolinaBlue: "Carolina Blue",
    Ash: "Ash",
    Gold: "Gold",
    SmokeGrey: "Asphalt",
    DarkRed: "",
    Creme: "",
    Brown: "Brown",
    MilitaryGreen: "",
    IceGrey: "Ash",
    Charcoal: "Asphalt",
    TurfGreen: "Forest Green",
  },
};

export const SHIPPING_SERVICE = {
  customcat: [
    {
      value: "Economy",
      label: "Economy",
    },
    {
      value: "Ground",
      label: "Ground",
    },
    {
      value: "2 Day",
      label: "2 Day",
    },
    {
      value: "Standard Overnight",
      label: "Standard Overnight",
    },
  ],
  gearment: [
    {
      value: "Standard",
      label: "Standard",
    },
    {
      value: "Fastship 2days",
      label: "Fastship 2 Days",
    },
    {
      value: "Ground",
      label: "Ground",
    },
  ],
  dreamship: [
    {
      value: "economy",
      label: "Economy",
    },
    {
      value: "express",
      label: "Express",
    },
    {
      value: "ground",
      label: "Ground",
    },
    {
      value: "two_day",
      label: "Two days",
    },
    {
      value: "prepaid",
      label: "Prepaid",
    },
  ],
  "scalable-press": [
    {
      value: "US-FC",
      label: "USPS - First Class",
    },
    {
      value: "US-PP",
      label: "USPS - Parcel Post",
    },
    {
      value: "US-PS",
      label: "USPS - Parcel Select",
    },
    {
      value: "US-PM",
      label: "USPS - Priority Mail",
    },
    {
      value: "US-FCI",
      label: "USPS - First Class International",
    },
    {
      value: "US-PMI",
      label: "USPS - Priority Mail International",
    },
    {
      value: "UPS-1DA",
      label: "UPS - Next Day Air",
    },
    {
      value: "UPS-1DP",
      label: "UPS - Next Day Air Saver",
    },
    {
      value: "UPS-2DA",
      label: "UPS - 2 Day",
    },
    {
      value: "UPS-3DS",
      label: "UPS - 3 Day Select",
    },
    {
      value: "UPS-GND",
      label: "UPS - Ground",
    },
    {
      value: "FDX-FO",
      label: "FEDEX - First Overnight",
    },
    {
      value: "FDX-PO",
      label: "FEDEX - Priority Overnight",
    },
    {
      value: "FDX-SO",
      label: "FEDEX - Standard Overnight",
    },
    {
      value: "FDX-2DA",
      label: "FEDEX - 2 Day AM",
    },
    {
      value: "FDX-2D",
      label: "FEDEX - 2 Day",
    },
    {
      value: "FDX-ES",
      label: "FEDEX - Express Saver",
    },
    {
      value: "FDX-GND",
      label: "FEDEX - Ground",
    },
    {
      value: "DHL-SM",
      label:
        "DHL - Smart Mail (SM) Parcels standard or SM Parcels plus standard",
    },
    {
      value: "DHL-SME",
      label:
        "DHL - Smart Mail (SM) Parcels expedited or SM Parcels plus expedited",
    },
    {
      value: "DHL-GM",
      label: "DHL - GM Packet Plus or GM Parcel Priority",
    },
    {
      value: "DHL-GME",
      label:
        "DHL - Global Mail (GM) Packet Priority PKY or GM Packet Priority PLY",
    },
  ],
};

export const PRINTIFY_SHIPPING_SERVICES = [
  {
    value: "1",
    label: "Standard",
  },
  {
    value: "2",
    label: "Priority",
  },
  {
    value: "3",
    label: "Express",
  },
  {
    value: "4",
    label: "Economy",
  },
];

export const SHIPPING_SERVICE_VALUE = [
  "Ground",
  "2 Day",
  "Standard Overnight",
  "Fastship 2days",
  "express",
  "US-FC",
  "US-PP",
  "US-PS",
  "US-PM",
  "US-FCI",
  "US-PMI",
  "UPS-1DA",
  "UPS-1DP",
  "UPS-2DA",
  "UPS-3DS",
  "UPS-GND",
  "FDX-FO",
  "FDX-PO",
  "FDX-SO",
  "FDX-2DA",
  "FDX-2D",
  "FDX-ES",
  "FDX-GND",
  "DHL-SM",
  "DHL-SME",
  "DHL-GM",
  "DHL-GME",
];

export const MAX_TITLE_CHARACTERS = {
  fbTitle: 99,
  amzTitle: 200,
};

export const WHICH_FIELD = {
  ShortTitle: "short_title",
  AmzTitle: "amz_title",
};

export const AMZ_MESSAGE_ERROR =
  "The Amazon title is already exists. Please choose other title!";

export const THIRD_PARTY_FULFILLMENTS = {
  customcat: {
    Ground: "Ground",
    "2 Day": "2 Day",
    "Standard Overnight": "Standard Overnight",
  },
  dreamship: {
    express: "Express",
    ground: "Ground",
    two_day: "Two Days",
    prepaid: "Prepaid",
    "Standard Overnight": "Standard Overnight",
  },
  gearment: {
    "Fastship 2days": "Fastship 2 Days",
    Ground: "Ground",
  },
  scalablepress: {
    "US-FC": "US-FC",
    "US-PP": "US-PP",
    "US-PS": "US-PS",
    "US-PM": "US-PM",
    "US-FCI": "US-FCI",
    "US-PMI": "US-PMI",
    "UPS-1DA": "UPS-1DA",
    "UPS-1DP": "UPS-1DP",
    "UPS-2DA": "UPS-2DA",
    "UPS-3DS": "UPS-3DS",
    "UPS-GND": "UPS-GND",
    "FDX-FO": "FDX-FO",
    "FDX-PO": "FDX-PO",
    "FDX-SO": "FDX-SO",
    "FDX-2DA": "FDX-2DA",
    "FDX-2D": "FDX-2D",
    "FDX-ES": "FDX-ES",
    "FDX-GND": "FDX-GND",
    "DHL-SM": "DHL-SM",
    "DHL-SME": "DHL-SME",
    "DHL-GM": "DHL-GM",
    "DHL-GME": "DHL-GME",
  },
};

export const DEV_TEAM_IDS = ["etHA", "WEya0", "VhSJbW4gTF", "3GIqNspTW5"];

export const PME_TEAM_ID = "Xeya4";
export const ACCOUNT_ATHIEN = "thomas.whittingham2012@gmail.com";
export const ACCOUNT_THUONG = "thuongharry@onemercegroup.com";
export const MerchBridge = "MerchBridge";
export const FILE_SIZE_50MB = 52428800;
export const FILE_SIZE_200MB = 209715200;

export const BASE_IDS_SHOW_ATTRIBUTES = [
  "kTk25xkwFG",
  "x4VS4wo1uo",
  "6U8nIX5xL4",
  "hcDIQlRYON",
  "8JZ8P8JC9O",
  "fZ1xIusrCQ",
  "6OOzXMOTzV",
  "UGxT2id1MN",
  "UGLuQH68bA",
  "oU3axXTE1o",
  "qBtnv4gjZI",
  "pFQvtMVXbJ",
  "WiBSLC2R17",
  "GDjLc1vXzq",
  "Y6qHNLF6l8",
  "GHevXo5NW1",
  "NuxpM",
  "9YEZpC8TNw",
  "vVfYjVLhGr",
  "WJurNkWYfg",
  "Swke1UhNc1",
  "yazf3FC6BZ",
  "Wyr0Uo3zNb",
  "JQGxjyuRQ5",
  "0LNezje3Bx",
  "r0E53dZfpw",
  "0e0Rzp8RYe",
  "HpgzEW3Hsn",
  "xkzrz5V6iQ",
  "hDveujJaaN",
  "8lYBUNZwZv",
  "4inz7hxS6Z",
];

export const TIKTOK_ACTIVITY_STATUS = {
  DRAFT: "DRAFT",
  NOT_START: "NOT_START",
  ONGOING: "ONGOING",
  EXPIRED: "EXPIRED",
  DEACTIVATED: "DEACTIVATED",
  NOT_EFFECTIVE: "NOT_EFFECTIVE",

  ERROR: "ERROR",
  PROCESSING: "PROCESSING",
};

export const TIKTOK_ACTIVITY_PRODUCT_STATUS = {
  Pending: "Pending",
  Running: "Running",
  Error: "Error",
  Done: "Done",
  Pushed: "Pushed",
};
