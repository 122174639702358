import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";

class AdminProducts extends Component {
  render() {
    return (
      <div>
        <PageTitle title={"Products"} />
        <p>Admin products...</p>
      </div>
    );
  }
}

export default AdminProducts;
