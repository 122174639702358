import React, { useState, useCallback } from "react";
import { Modal, Checkbox, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { LIST_PRODUCT_PUSH } from "./ProductPushPolaris";

const DELETE_SELECT_PUSH = gql`
  mutation deletePushesSelected($id: [ID!], $option: DeletePushOption) {
    deletePushesSelected(id: $id, option: $option) {
      successed
      failed
    }
  }
`;

export const DeleteSelectPushPolaris = ({
  open,
  toggleOpen,
  selectedItem,
  filter,
  setSelectedItem,
}) => {
  const [removeRelation, setRemoveRelation] = useState(false);
  const [deleteStoreProducts, setDeleteStoreProducts] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [deletePushesSelected, { data, loading, error, client }] = useMutation(
    DELETE_SELECT_PUSH,
    {
      onError: () => {},
      onCompleted: () => {
        const cache = client.readQuery({
          query: LIST_PRODUCT_PUSH,
          variables: {
            filter,
          },
        });
        client.writeQuery({
          query: LIST_PRODUCT_PUSH,
          variables: {
            filter,
          },
          data: {
            ...cache,
            pushList: {
              ...cache.pushList,
              total: cache.pushList.total - selectedItem.length,
              nodes: cache.pushList.nodes.filter(
                (p) => !selectedItem.includes(p.id)
              ),
            },
          },
        });
        toggleOpen();
        setSelectedItem([]);
      },
    }
  );

  const handleRemoveRelation = useCallback((value) => {
    setRemoveRelation(value);
  }, []);
  const handleDeleteStoreProducts = useCallback((value) => {
    setDeleteStoreProducts(value);
  }, []);
  const handleDelete = useCallback(() => {
    deletePushesSelected({
      variables: {
        id: selectedItem,
        option: {
          removeRelation,
          deleteStoreProducts,
        },
      },
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, removeRelation, deleteStoreProducts]);

  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleContentToast = () => {
    if (error) {
      return "Went something wrong.";
    }
    if (data && data.deletePushesSelected)
      return "Delete push product success.";
  };

  const toastMarkup = activeToast
    ? (error || (data && data.deletePushesSelected)) && (
        <Toast
          content={handleContentToast()}
          error={error}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        title="Are you sure to delete this selected?"
        open={open}
        onClose={toggleOpen}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleOpen }]}
        primaryAction={{
          content: "Yes",
          onAction: handleDelete,
          loading: loading,
        }}
      >
        <Checkbox
          label="Also remove relation between MB products and store"
          checked={removeRelation}
          onChange={handleRemoveRelation}
        />
        <Checkbox
          label="Also delete pushed products in store"
          checked={deleteStoreProducts}
          onChange={handleDeleteStoreProducts}
        />
      </Modal>
    </>
  );
};
