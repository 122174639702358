import React, { Component } from "react";
import { Form, Card, Input, Divider, Button, Select, DatePicker } from "antd";
import DesignerSelect from "./DesignerSelect";
import OrderSelect from "./OrderSelect";
// import MediaSelectorButton from "../supplier/MediaSelectorButton";
import styled from "styled-components";
import moment from "moment";

import { KPI } from "../../constants/task";

const Container = styled.div`
  &.remove-style {
    .ant-card-bordered {
      box-shadow: none;
    }
    .ant-card-body {
      padding: 0;
    }
  }
`;

export default class DesignTaskForm extends Component {
  render() {
    const {
      initialValues,
      onSubmit,
      loading,
      isModal,
      orderId,
      isUpdate,
      isReassign,
      isBulkActions,
    } = this.props;
    return (
      <Container className={isModal ? "remove-style" : ""}>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="flex w-full flex-wrap"
          initialValues={{
            kpi: 0.5,
            ...initialValues,
          }}
          onFinish={(e) => {
            let values = { ...e };
            // if (values.resources) {
            //   values.resources = values.resources.map((r) => r.id);
            // }
            if (orderId) {
              values.orderId = orderId;
            }
            onSubmit(values);
          }}
        >
          <div className={isModal ? "w-full" : "w-full md:w-2/3"}>
            <Card className="m-2">
              <Form.Item
                name="assigneeId"
                label="Assign to designer"
                rules={[{ required: true, message: "Designer is required." }]}
              >
                <DesignerSelect />
              </Form.Item>
              {orderId || isBulkActions ? null : (
                <Form.Item
                  name="orderId"
                  label="Order ID"
                  rules={[{ required: true, message: "Designer is required." }]}
                >
                  <OrderSelect />
                </Form.Item>
              )}
              <Form.Item
                name="kpi"
                label={"KPI"}
                rules={[{ required: true, message: "KPI is required." }]}
              >
                <Select>
                  {KPI.map((option, idx) => (
                    <Select.Option value={option} key={idx}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="deadline" label={"Deadline"}>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </Form.Item>
              <Form.Item name="requirement" label="Requirements">
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item name="description" label="Resources">
                <Input.TextArea rows={5} />
              </Form.Item>
            </Card>
          </div>
          {/* <div className={isModal ? "w-full" : "w-full md:w-1/3"}>
            <Card className="m-2">
              <Form.Item name="resources" label="Resources">
                <MediaSelectorButton
                  multiple={true}
                  singleUpload={false}
                  accept={"image/*"}
                  listType={"picture-card"}
                />
              </Form.Item>
            </Card>
          </div> */}
          <Divider
            className="mx-2"
            style={{ border: "1px solid #e2e2e2", marginTop: "15px" }}
          />
          <div className="w-full text-right mr-2">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {isUpdate
                  ? "Update Task"
                  : isReassign
                  ? "Re-assign"
                  : "Publish Task"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Container>
    );
  }
}
