import { Checkbox, DataTable } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import get from "lodash/get";

import { PRIVATE_FEATURES } from "../../../constants";
import { Actions } from "./Actions";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

export function Table({ data, filter, refetch, handlePagination }) {
  const [rows, setRows] = React.useState([]);
  const [privateFeaturesByTeam, setPrivateFeaturesByTeam] = React.useState(
    new Map()
  );

  // Actions
  const handleCheckedChange = React.useCallback(
    (id, key) => (value) => {
      if (privateFeaturesByTeam.has(id)) {
        const privateF = privateFeaturesByTeam.get(id);
        if (key === "all") {
          if (value) {
            for (let k of Object.keys(PRIVATE_FEATURES)) {
              privateF.add(k);
            }
          } else {
            privateF.clear();
          }
        }

        privateF.delete(key);
        if (value) {
          privateF.add(key);
        }

        privateFeaturesByTeam.set(id, privateF);
        setPrivateFeaturesByTeam(new Map(privateFeaturesByTeam));
      }
    },
    [privateFeaturesByTeam]
  );

  React.useEffect(() => {
    const nodes = data?.teams?.nodes;
    if (nodes?.length > 0) {
      const rows = nodes.map((node) => {
        const { id, name, privateFeatures } = node;
        if (!privateFeaturesByTeam.has(id)) {
          privateFeaturesByTeam.set(
            id,
            new Set((privateFeatures || []).map((i) => i))
          );
        }

        const privateFeaturesTmp = privateFeaturesByTeam.get(id) || new Set();
        const idMarkup = (
          <div className="id-wrap">
            <span>{id}</span>
          </div>
        );

        const nameMarkup = (
          <div className="name-wrap">
            <span>{name}</span>
          </div>
        );

        const actionCols = Object.keys(PRIVATE_FEATURES).map((key) => {
          return (
            <Checkbox
              label={`action-${key}`}
              labelHidden
              checked={privateFeaturesTmp.has(key)}
              onChange={handleCheckedChange(id, key)}
            />
          );
        });

        const selectAll = (
          <Checkbox
            label="select-all"
            labelHidden
            checked={
              privateFeaturesTmp.size ===
                Object.keys(PRIVATE_FEATURES).length ||
              privateFeaturesTmp.has("all")
            }
            onChange={handleCheckedChange(id, "all")}
          />
        );

        const actionsMarkup = (
          <div className="actions-wrap">
            <Actions
              item={node}
              features={privateFeaturesTmp}
              refetch={refetch}
            />
          </div>
        );

        return [idMarkup, nameMarkup, ...actionCols, selectAll, actionsMarkup];
      });

      setPrivateFeaturesByTeam(privateFeaturesByTeam);
      setRows(rows);
    }
  }, [data, handleCheckedChange, privateFeaturesByTeam, refetch]);

  const { limit, offset } = filter;
  const total = get(data, "teams.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handlePagination,
    total,
  };

  return (
    <React.Fragment>
      <Wrapper>
        <DataTable
          rows={rows}
          columnContentTypes={Array.from({
            length: 3 + Object.keys(PRIVATE_FEATURES).length,
          }).fill("text")}
          headings={[
            "ID",
            "Name",
            ...Object.values(PRIVATE_FEATURES),
            "Select All",
            "Actions",
          ]}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Wrapper>

      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__Cell--header {
    white-space: nowrap;
  }

  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: initial;
    }
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;
