import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import { GET_COUNT_TASK_REJECT_FOR_USER } from "../../../graphql/queries";
import {
  convertObjectToParams,
  convertStringToObject,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { StatisticDT } from "./StatisticDT";
import { StatisticFilter } from "./StatisticFilter";

const FIELD_FILTER = [...COMMON_FILTER, "userId"];

export const StatisticPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      let paged = initialFilter.paged;
      paged = Number(paged);
      if (!paged) {
        paged = 1;
      }
      initialFilter.paged = paged;
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = React.useState({
    limit: Number(getCookie("perStatisticForTask")) || 20,
    paged: 1,
    search: null,
    userId: null,
    fieldByTime: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error } = useQuery(GET_COUNT_TASK_REJECT_FOR_USER, {
    variables: { filter },
  });

  // Handle actions
  const handleFilterChange = React.useCallback(
    ({ search, userId, fieldByTime }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.userId, userId) ||
          !isEqual(prev.fieldByTime, fieldByTime)
        ) {
          return {
            ...prev,
            paged: 1,
            search,
            userId,
            fieldByTime,
          };
        }
        return prev;
      });
    },
    [],
  );

  React.useEffect(() => {
    let { paged, limit, fieldByTime, ...rest } = filter;
    let params = null;

    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handlePagination = React.useCallback(({ paged, limit }) => {
    setFilter((prev) => {
      if (!isEqual(prev.limit, limit)) {
        return {
          ...prev,
          limit,
          paged: 1,
        };
      }
      return { ...prev, paged };
    });
    setCookie("perStatisticForTask", limit, 100);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <StatisticFilter filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error:{handleError(error.toString())}</div>
        ) : data?.getCountTaskRejectForUser?.nodes?.length > 0 ? (
          <StatisticDT
            data={data}
            filter={filter}
            setFilter={handlePagination}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
