import React, { memo, useEffect, useRef } from "react";
import { Button, Layout, Collapsible, Stack } from "@shopify/polaris";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import styled from "styled-components";

import useToggle from "../../hooks/useToggle";
import { Section, Actions } from "./components";
import { Group } from "./context";

const Wrapper = styled.div`
    .element-wrap {
        background-color: white;
        outline: 0.1rem solid transparent;
        padding: 1rem 1.6rem;
        border-radius: 3px;
        cursor: pointer;
        box-shadow: 0 0 0 1px rgb(63 63 68 / 5%),
            0 1px 3px 0 rgb(63 63 68 / 15%);

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }
`;

export const Type = "Card";

export function WidgetComp({ title, toggleOpen: toggleOpenProp }) {
    // State
    const [open, toggleOpen] = useToggle(true);

    return (
        <DndProvider backend={HTML5Backend}>
            <Wrapper>
                <Layout>
                    <Layout.Section fullWidth>
                        <Stack alignment="center">
                            <Stack.Item fill>
                                <Button
                                    children="Add Widget"
                                    primary
                                    disclosure
                                    onClick={() => toggleOpen()}
                                />
                            </Stack.Item>
                            <Actions
                                title={title}
                                toggleOpen={toggleOpenProp}
                            />
                        </Stack>
                    </Layout.Section>
                    <WidgetElement open={open} />
                    <Layout.Section oneThird>
                        <Section group={Group.Secondary} header={"Column Left"} />
                    </Layout.Section>
                    <Layout.Section oneThird>
                        <Section group={Group.Third} header={"Column Right"} />
                    </Layout.Section>
                </Layout>
            </Wrapper>
        </DndProvider>
    );
}

const WidgetElement = memo(function WidgetElement({ open }) {
    const ref = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (open) {
            if (ref.current != null) {
                ref.current.classList.add(
                    "Polaris-Layout__Section",
                    "Polaris-Layout__Section--oneThird"
                );
            }
        } else {
            if (ref.current != null) {
                timeoutRef.current = setTimeout(() => {
                    ref.current.classList.remove(
                        "Polaris-Layout__Section",
                        "Polaris-Layout__Section--oneThird"
                    );
                }, 500);
            }
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [open]);

    return (
        <div ref={ref}>
            <Collapsible
                open={open}
                id="add-widget"
                transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                }}
                expandOnPrint
            >
                <Section group={Group.All} />
            </Collapsible>
        </div>
    );
});
