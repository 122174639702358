import { Page } from "@shopify/polaris";
import React, { useMemo } from "react";
import styled from "styled-components";
import { DepositsPolaris } from "../../components/billings/DepositsPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const DepositsPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="All Deposits"
                fullWidth
                primaryAction={{
                    content: "Add Deposit",
                    url: "/seller/billings/deposits/add",
                }}
            >
                <DepositsPolaris path={path} />
            </Page>
        </Container>
    );
};
