import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import moment from "moment";
import _ from "lodash";

import { FilterCustomTimePolaris } from "../tasks/FilterCustomTimePolaris";
import PreDefinedMonth, { MONTH_NAMES } from "../filters/PreDefinedMonth";

const preDefinedLabel = "Pre-defined month";

export const FilterOrderByTimePolaris = (props) => {
  const {
    onChangeRange,
    filterTime: { range, rangeLabel },
  } = props;
  const [choiceValue, setChoiceValue] = useState([]);
  const [customTime, setCustomTime] = useState({
    range: range,
    rangeLabel: rangeLabel,
  });

  const renderChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <FilterCustomTimePolaris
          customTime={customTime}
          isFilter
          onChange={({ from, to, fromLabel, toLabel }) => {
            setCustomTime({
              rangeLabel: `${fromLabel} - ${toLabel}`,
              range: {
                from,
                to,
              },
            });
            if (onChangeRange) {
              onChangeRange({ from, to }, `${fromLabel} - ${toLabel}`);
            }
          }}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [choiceValue],
  );

  const handlePreDefinedChange = React.useCallback((range, label) => {
    if (onChangeRange) {
      onChangeRange(range, label);
    }
  }, []);

  const renderPreDefined = useCallback(
    (selected) => {
      return (
        selected && (
          <PreDefinedMonth
            onChange={handlePreDefinedChange}
            value={rangeLabel}
          />
        )
      );
    },
    [choiceValue, handlePreDefinedChange, rangeLabel],
  );

  const options = [
    ...props.options,
    { value: "Custom", label: "Custom", renderChildren },
    {
      value: preDefinedLabel,
      label: preDefinedLabel,
      renderChildren: renderPreDefined,
    },
  ];

  useEffect(() => {
    if (
      null === range &&
      "Custom" !== rangeLabel &&
      preDefinedLabel !== rangeLabel
    ) {
      setChoiceValue("All");
    } else {
      let checkValue = _.values(_.mapValues(options, "value"));

      let val = "";
      if (checkValue.includes(rangeLabel)) {
        val = `${rangeLabel}`;
      } else if (MONTH_NAMES.includes(rangeLabel)) {
        val = preDefinedLabel;
      } else {
        val = `Custom`;
      }
      setChoiceValue(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, rangeLabel]);

  const handleChoiceValue = useCallback((selected) => {
    let range = {
      from: null,
      to: null,
    };
    let label = selected[0];
    if (selected[0]) {
      switch (selected[0]) {
        case "Today":
          range.from = moment().startOf("day");
          range.to = moment().endOf("day");
          break;
        case "Yesterday":
          range.from = moment().subtract(1, "days").startOf("day");
          range.to = moment().subtract(1, "days").endOf("day");
          break;
        case "Last 7 days":
          range.from = moment().subtract(7, "days").startOf("day");
          range.to = moment();
          break;
        case "This month":
          range.from = moment().startOf("month");
          range.to = moment();
          break;
        case "Last month":
          range.from = moment().subtract(1, "months").startOf("months");
          range.to = moment().subtract(1, "months").endOf("months");
          break;
        case "pre_defined":
          range = null;
          break;
        default:
          range = null;
          break;
      }
    }
    if (onChangeRange) {
      onChangeRange(range, label);
    }
    setChoiceValue(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Filter time"
      titleHidden
      choices={options}
      selected={choiceValue}
      onChange={handleChoiceValue}
    />
  );
};
