import { useMutation, useQuery } from "@apollo/react-hooks";
import { ChoiceList, Stack, TextField } from "@shopify/polaris";
import { notification } from "antd";
import React from "react";
import { SETTING_VARIABLES } from "../../../constants";
import { useAppContext } from "../../../context";
import { SET_VARIABLE } from "../../../graphql/mutations";
import { GET_VARIABLES } from "../../../graphql/queries";
import { getTeamID, handleError } from "../../../helper";

export default React.forwardRef(function SetOnHoldTime({ setLoading }, ref) {
  const [selected, setSelected] = React.useState(["false"]);
  const [inputVal, setInputVal] = React.useState("30");

  const { currentUser } = useAppContext();
  const teamId = getTeamID(currentUser);

  const variableKey = React.useMemo(
    () => (teamId ? `${teamId}${SETTING_VARIABLES.SOHT}` : null),
    [teamId],
  );

  const { data } = useQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
    skip: !variableKey,
  });

  const [setVariables] = useMutation(SET_VARIABLE, {
    onCompleted: () => {
      notification.success({ message: "Update set on-hold time success" });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  const changed = React.useRef(false);

  React.useEffect(() => {
    const val = data?.variables?.length > 0 ? data.variables[0] : null;
    if (val?.value) {
      const value = String(val.value).trim();
      if (value.length > 0) {
        setSelected(["true"]);
        setInputVal(value);
      }
    }
  }, [data]);

  const handleSubmit = React.useCallback(async () => {
    if (!changed.current) return;

    let val = selected.includes("true") ? inputVal : "";
    setLoading(true);
    await setVariables({
      variables: {
        input: {
          key: variableKey,
          value: val,
        },
      },
    }).finally(() => {
      setLoading(false);
      changed.current = false;
    });
  }, [variableKey, setVariables, setLoading, inputVal, selected]);

  const onInputChange = React.useCallback((value) => {
    setInputVal(value);
    changed.current = true;
  }, []);

  const onSelectChange = React.useCallback((value) => {
    setSelected(value);
    changed.current = true;
  }, []);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <Stack vertical>
      <ChoiceList
        title="Set on-hold time (default 30 minutes) for all On Hold orders before submit to third party fulfillemnt."
        choices={[
          { label: "Enable", value: "true" },
          { label: "Disable", value: "false" },
        ]}
        selected={selected}
        onChange={onSelectChange}
      />
      {selected.includes("true") && (
        <TextField
          min={1}
          type="number"
          helpText="Unit is minute"
          value={inputVal}
          onChange={onInputChange}
        />
      )}
    </Stack>
  );
});
