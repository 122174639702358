import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { FilterProductBasePolaris } from "../admin/FilterProductBasePolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ProductBasesTableDataPolaris } from "./ProductBasesTableDataPolaris";

const QUERY_BASES = gql`
  query productBases($filter: ProductBaseFilter) {
    productBases(filter: $filter) {
      total
      nodes {
        id
        title
        baseCostDescription
        status
        team {
          id
          name
        }
        images {
          id
          name
          url
          thumbnailUrl
        }
        suppliers {
          id
          firstName
          lastName
        }
        supplierPricing {
          id
          price
          userId
        }
        fulfillment {
          fulfillmentId
          fulfillmentTitle
        }
      }
    }
  }
`;

const Container = styled.div``;

const FIELD_FILTER = [...COMMON_FILTER, "fulfillmentId", "status"];

export const ProductBasesPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }

      if (initialFilter.status) {
        if (initialFilter.status === "Publish") {
          initialFilter.status = true;
        } else if (initialFilter.status === "Draft") {
          initialFilter.status = false;
        } else {
          initialFilter.status = null;
        }
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductBase")) || 20,
    offset: 0,
    search: null,
    fulfillmentId: null,
    status: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(QUERY_BASES, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let { offset, limit, status, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);

    const convertS =
      typeof status === "boolean" ? (status ? "Publish" : "Draft") : null;
    params = convertObjectToParams({
      limit,
      paged,
      status: convertS,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = React.useCallback(
    ({ search, fulfillmentId, status }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.fulfillmentId, fulfillmentId) ||
          !isEqual(prev.status, status)
        ) {
          return {
            ...prev,
            search,
            fulfillmentId,
            status,
            offset: 0,
          };
        }
        return prev;
      });
    },
    [],
  );

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Card sectioned>
        <FilterProductBasePolaris
          filter={filter}
          onChange={handleFilterChange}
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.productBases?.nodes?.length > 0 ? (
          <ProductBasesTableDataPolaris
            data={data}
            filter={filter}
            refetch={refetch}
            setFilter={(offset, limit) => {
              setFilter((prev) => ({ ...prev, offset, limit }));
              setCookie("perPageProductBase", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Container>
  );
};
