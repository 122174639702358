import React, { useState, useCallback, useEffect } from "react";
import { TextField, FormLayout, Button, ButtonGroup } from "@shopify/polaris";
import styled from "styled-components";

import { getProductMockupFolder } from "../../../helper";
import { UPLOAD_FOLDER } from "../../../variable";

import { CollectionSelectPolaris } from "../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../product/tags/TagsSelectPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";

const Container = styled.div`
  .bases-wrap {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    .base-wrap {
      width: 48%;
    }
  }
  .btns-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const UploadPMFormPolaris = ({
  value,
  onSubmit,
  toggleShowModal,
  loading,
}) => {
  const [basicFields, setBasicFields] = useState({
    title: null,
    collections: null,
    tags: null,
    mockups: null,
    mainImageId: null,
  });
  const [pBase, setPBase] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);

  // Get data
  useEffect(() => {
    if (value) {
      let { title, collections, tags, images, productBases } = value;

      // Collections
      let newCollections = collections || [];
      newCollections =
        newCollections?.length > 0
          ? newCollections.map(({ id, name }) => ({ id, name }))
          : [];

      // Tags
      let newTags = tags || [];
      newTags =
        newTags?.length > 0
          ? newTags.map(({ id, name }) => ({ id, name }))
          : [];

      // Product bases
      let newBase = [];
      for (let item of productBases) {
        let id = item?.id;
        let imageInBase =
          images?.length > 0
            ? images.filter((img) => img.productBaseId === id)
            : [];
        newBase.push({
          ...item,
          images: imageInBase,
        });
      }

      setPBase(newBase);
      setBasicFields((prev) => ({
        ...prev,
        title,
        collections: newCollections,
        tags: newTags,
      }));
    }
  }, [value]);

  // Handle actions
  const handleSubmit = useCallback(() => {
    // Mockups
    let mockups = [];
    pBase.forEach((base) => {
      let { id, images } = base;
      images.forEach((image) => {
        let { file } = image;
        let item = {
          productBaseId: id,
          fileId: file ? file.id : image.id,
        };
        mockups.push(item);
      });
    });
    let { title, collections, tags, mainImageId } = basicFields;

    // Collections
    let newCollections =
      collections && collections.length > 0
        ? collections.map((i) => {
            if (typeof i === "object") {
              return i.id;
            }
            return i;
          })
        : [];

    // Tags
    let newTags =
      tags && tags.length > 0
        ? tags.map((i) => {
            if (typeof i === "object") {
              return i.id;
            }
            return i;
          })
        : [];

    let input = {
      title,
      collections: newCollections,
      tags: newTags,
      mockups,
      mainImageId,
    };
    if (onSubmit) {
      onSubmit(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields, pBase]);

  const handleImageChange = useCallback((id, value) => {
    if (value.length) {
      setDisabledBtn(false);
      let imgId = value[0]?.id;
      setBasicFields((prev) => ({
        ...prev,
        mainImageId: imgId ? imgId : null,
      }));
    }

    setPBase((prev) => {
      for (let item of prev) {
        if (item.id === id) {
          item.images = value;
        }
      }
      return [...prev];
    });
  }, []);

  const sku = value?.sku;

  return (
    <Container>
      <FormLayout>
        <TextField
          value={basicFields["title"]}
          label="Title"
          placeholder="Enter title"
          onChange={(value) =>
            setBasicFields((prev) => ({ ...prev, title: value }))
          }
        />
        <CollectionSelectPolaris
          value={basicFields["collections"]}
          allowMultiple
          label="Collections"
          onChange={(value) =>
            setBasicFields((prev) => ({ ...prev, collections: value }))
          }
        />
        <TagsSelectPolaris
          value={basicFields["tags"]}
          allowMultiple
          label="Tags"
          onChange={(value) =>
            setBasicFields((prev) => ({ ...prev, tags: value }))
          }
        />
        <div>
          <ComponentLabelPolaris label="Mockup for:" />
          <div className="bases-wrap">
            {pBase?.length > 0
              ? pBase.map((base, index) => {
                  return (
                    <div className="base-wrap" key={index}>
                      <span>{base?.title}</span>
                      <MediaSelectorButtonPolaris
                        prefix="mockups/"
                        sizeSmall
                        folder={
                          sku
                            ? getProductMockupFolder(sku)
                            : UPLOAD_FOLDER.MOCKUPS
                        }
                        multiple={true}
                        accept={"image/*"}
                        onChange={(value) => handleImageChange(base?.id, value)}
                        // value={newImages}
                        mainImageId={basicFields["mainImageId"]}
                        hasMainImg
                        setMainImage={(value) =>
                          setBasicFields((prev) => ({
                            ...prev,
                            mainImageId: value,
                          }))
                        }
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div className="btns-wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={toggleShowModal} />
            <Button
              children="Submit"
              primary
              onClick={handleSubmit}
              loading={loading}
              disabled={disabledBtn}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
