import {
  Badge,
  Button,
  Checkbox,
  DataTable,
  Link,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { get } from "lodash";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { getParam } from "../../../helper";
import { PersonalizedMarkup } from "../../product/RenderProductPolaris";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";
import { BulkActions } from "../../shared/BulkActions";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { checkImages } from "../OrdersDTPolaris";
import { BulkActionOrderStats } from "./BulkActionOrderStats";

export default function OrderStatsTable({ filter, onChange, data }) {
  const [rows, setRows] = React.useState([]);

  // Context
  const { currentUser } = useAppContext();
  const mergeParam = getParam(currentUser);
  const [itemsChecked, setItemsChecked] = React.useState({});
  const [idsChecked, setIdsChecked] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);

  const nodes = data?.platformSaleStats?.nodes || [];
  React.useEffect(() => {
    if (nodes?.length > 0) {
      const rows = nodes.map((record) => {
        const product = get(record, "product");
        let productSku = get(product, "sku", "");
        let productID = get(product, "id", "");
        let productTitle = get(product, "title", "");
        let personalized = get(product, "personalized", "");
        let mainImages = checkImages(product);
        const topStores = get(record, "topStores") || [];

        const topStoresMarkup = topStores.map((item, index) => (
          <TextContainer key={`${item.id}_${index}`}>
            <TextStyle variation="strong">
              {item?.store?.title}
              {": "}
            </TextStyle>
            <TextStyle>{item?.totalOrders} </TextStyle>
          </TextContainer>
        ));

        let productUrl = null;
        // originalProductIsCampaign
        if (product && product.productBases) {
          const link =
            product.productBases.length > 1
              ? `/${mergeParam}/products/campaign`
              : `/${mergeParam}/products/edit`;
          productUrl = `${link}/${productID}`;
        }

        const totalOrders = get(record, "totalOrders");
        const collections = get(product, "collections");

        return [
          <div className="selection-wrap">
            <Checkbox
              label=""
              checked={!!itemsChecked[record.productID]}
              onChange={(newChecked) =>
                handleChecked(record.productID, newChecked)
              }
            />
          </div>,
          <div className="product-wrap item">
            {product ? (
              <>
                <div>
                  <ModalImageClaimPolaris
                    files={{
                      files: mainImages,
                    }}
                    limit={1}
                    width={120}
                    height={120}
                  />
                </div>
                <div className="product-detail item flex-custom">
                  <Link children={productTitle} url={productUrl} />

                  {personalized ? <PersonalizedMarkup item={product} /> : null}
                  <div>
                    <Badge children={productSku} />
                  </div>
                </div>
              </>
            ) : null}
          </div>,
          <div className="collection-wrap itme">
            {(collections || [])
              .map((col) => col?.name)
              .filter(Boolean)
              .map((c, idx) => (
                <Badge key={`collection-${idx}`}>{c}</Badge>
              ))}
          </div>,
          <div className="item top-orders">{topStoresMarkup}</div>,
          <div className="item total-orders">
            <Badge status="success">{totalOrders}</Badge>
          </div>,
        ];
      });

      setRows(rows);
    }
  }, [nodes, itemsChecked]);

  React.useEffect(() => {
    let ids = [];
    if (itemsChecked) {
      for (let [key, value] of Object.entries(itemsChecked)) {
        if (value) {
          ids.push(key);
        }
      }
    }
    setIdsChecked(ids);
  }, [itemsChecked]);

  const handleChecked = React.useCallback(
    (productID, newChecked) => {
      let countItemsChecked;
      setItemsChecked((prevState) => {
        let result = { ...prevState, [productID]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;
        return result;
      });
      if (newChecked) {
        setCheckedAll(true);
      } else if (countItemsChecked === 0) {
        setCheckedAll(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsChecked],
  );
  const handleCheckedAll = React.useCallback(
    (newChecked) => {
      setCheckedAll(newChecked);
      if (newChecked) {
        let items = {};
        for (let i = 0; i < nodes.length; i++) {
          if (!nodes[i] || !nodes[i].productID) continue;

          items = {
            ...items,
            [nodes[i].productID]: true,
          };
        }
        setItemsChecked(items);
      } else {
        setItemsChecked({});
      }
    },
    [nodes],
  );

  const handleCompleted = React.useCallback(() => {
    setIdsChecked(() => []);
    setCheckedAll(false);
    setItemsChecked({});
  }, []);

  const total = get(data, "platformSaleStats.total", 0);
  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange,
    total,
  };

  return (
    <>
      <BulkActions
        idsChecked={idsChecked}
        onClose={() => handleCheckedAll(false)}
      >
        <BulkActionOrderStats
          ids={idsChecked}
          onCompleted={handleCompleted}
          filter={filter}
          total={total}
        />
      </BulkActions>
      <Wrapper>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <Checkbox
              label=""
              checked={checkedAll}
              onChange={handleCheckedAll}
            />,
            "Product Info",
            "Collections",
            "Top Stores",
            "Total Orders",
          ]}
          rows={rows}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Wrapper>
      <PaginationWrapper>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationWrapper>
    </>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__Cell--header {
    white-space: nowrap;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .product-wrap {
    display: flex;
    column-gap: 20px;
    align-items: center;
    width: calc(80rem - 3.2rem);
    white-space: normal;
  }

  .collection-wrap {
    width: calc(30rem - 3.2rem);
    white-space: normal;
  }

  .top-orders {
    width: calc(45rem - 3.2rem);
    white-space: normal;
  }

  .total-orders {
    width: calc(15rem - 3.2rem);
    white-space: normal;
  }

  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: initial;
    }
  }
`;
const PaginationWrapper = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;
