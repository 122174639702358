import React, { useState, useEffect, useRef } from "react";
import { DataTable, Badge } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";
import moment from "moment";

import noImage from "../../../assets/images/unnamed.jpeg";
import { CLAIM_STATUS } from "../../../variable";
import { elementContains, throttle } from "../../../helper";

import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { CancelClaimPolaris } from "./CancelClaimPolaris";
import { UpdateEvidencesPolaris } from "./UpdateEvidencesPolaris";

const Container = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .actions_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }
  .Polaris-DataTable__ScrollContainer {
    overflow-x: initial;
  }
  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;
      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.custom-cell {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }
  }
  .note_wrap {
    width: calc(20rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .Polaris-DataTable__Cell--header {
    .actions_wrap {
      justify-content: center;
    }
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export const TableClaimPolaris = ({
  data,
  filter,
  setFilter,
  refetch,
  fixedColumn,
}) => {
  const [rows, setRows] = useState([]);
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);

  useEffect(() => {
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domChilds = document.querySelectorAll(".actions_wrap");
    let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

    for (let domParent of domParents) {
      for (let domChild of domChilds) {
        if (elementContains(domParent, domChild)) {
          if (fixedColumn) {
            if (addClass) {
              domParent.classList.add("custom-cell");
            } else {
              domParent.classList.remove("custom-cell");
            }
          } else {
            domParent.classList.remove("custom-cell");
          }
        }
      }
    }
  }, [rows, coordinates, fixedColumn]);

  // Handle scroll
  useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (wrapRef.current) {
          setCoordinates({
            scrollLeft: wrapRef.current.scrollLeft,
            scrollWidth: wrapRef.current.scrollWidth,
            offsetWidth: wrapRef.current.offsetWidth,
          });
        }
      })
    };

    if (wrapRef.current) {
      wrapRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRef.current.removeEventListener("scroll", handler);
      }
    };
  }, [wrapRef]);

  useEffect(() => {
    let newData = data.claims.nodes.map((claim) => {
      let { status } = claim;
      let statusColor = null;
      if ([CLAIM_STATUS.Approved].includes(status)) {
        statusColor = "success";
      }
      if ([CLAIM_STATUS.Cancelled].includes(status)) {
        statusColor = "critical";
      }
      if ([CLAIM_STATUS.InReview].includes(status)) {
        statusColor = "info";
      }
      if ([CLAIM_STATUS.Resent].includes(status)) {
        statusColor = "attention";
      }
      if ([CLAIM_STATUS.Rejected].includes(status)) {
        statusColor = "warning";
      }
      if ([CLAIM_STATUS.Refunded].includes(status)) {
        statusColor = "warning";
      }

      let storeTitle = claim?.order?.store?.title;
      return [
        _.get(claim, "order.id"),
        claim.type,
        <p>{moment(claim.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>,
        <Badge status={statusColor}>{claim.status}</Badge>,
        <div className="store_wrap">{storeTitle}</div>,
        <>
          {claim.files && claim.files.length > 0 ? (
            <ModalImageClaimPolaris limit={1} files={claim} />
          ) : (
            <img src={noImage} style={{ width: 70 }} alt="" />
          )}
        </>,
        <div className="note_wrap">
          <span>{claim.comment}</span>
        </div>,
        <div className="actions_wrap">
          {[
            CLAIM_STATUS.InReview,
            CLAIM_STATUS.NeedMoreEvidence,
            CLAIM_STATUS.Approved,
          ].includes(claim?.status) && (
            <UpdateEvidencesPolaris
              evidence={claim}
              filter={filter}
              refetch={refetch}
            />
          )}
          {claim && claim.status === "In Review" && (
            <CancelClaimPolaris claim={claim} refetch={refetch} />
          )}
        </div>,
      ];
    });
    setRows(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let { limit, offset } = filter;
  const total = _.get(data, "claims.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <React.Fragment>
      <Container ref={wrapRef}>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "numeric",
          ]}
          headings={[
            "Order",
            "Type",
            "Created",
            "Status",
            "Store",
            "Evidence",
            "Note",
            <div className="actions_wrap">Actions</div>,
          ]}
          rows={rows}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </React.Fragment>
  );
};
