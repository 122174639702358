import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { REPORT_ORDER_TRACKING_OVERVIEW } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";

const collapseKey = "trackingStatus";
export function TrackingStatus({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    search: null,
    filterByTime: range,
    collectionIds: null,
    tagIds: null,
    productBaseIds: null,
    saleChanel,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    totalSale: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
  });
  const [mergedLoading, setMergedLoading] = useState(true);

  // Queries
  const { data, loading, error } = useQuery(REPORT_ORDER_TRACKING_OVERVIEW, {
    variables: {
      filter,
    },
  });

  const [
    lazyReport,
    { data: dataC, loading: loadingC, error: errorC },
  ] = useLazyQuery(REPORT_ORDER_TRACKING_OVERVIEW);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      setFilter((prev) => ({ ...prev, ...filterCtx }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportOrderTrackingOverview?.trackingStatusOverview;
    const totalSale = data?.reportOrderTrackingOverview?.totalSale;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            const { countRes, trackStatus } = node || {};

            return {
              name: trackStatus,
              count: countRes,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      totalSale,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportOrderTrackingOverview?.totalSale;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <Stack
              key={`assignee-${index}`}
              distribution="equalSpacing"
              wrap={false}
            >
              <span className="index-wrap">{index + 1}</span>
              <Stack.Item fill>{item.name}</Stack.Item>
              <span className="column-wrap">{item.count}</span>
            </Stack>
          );
        })
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Tracking status</TextStyle>
      </Stack.Item>
      <span className="column-wrap">
        <TextStyle variation="strong">Total</TextStyle>
      </span>
    </Stack>
  );

  const subTitleMarkup = useMemo(
    () => (
      <Stack vertical spacing="tight">
        <span>
          <span>{state.totalSale} Units</span>
          <ComparedPercent
            originalVal={state.totalSale}
            newVal={stateCompare.totalSale}
          />
        </span>
      </Stack>
    ),
    [state.totalSale, stateCompare.totalSale]
  );

  return (
    <CardBox
      title="Tracking info status"
      subTitle={subTitleMarkup}
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      total={state.total}
      loading={mergedLoading}
      error={error || errorC}
      setFilter={setFilter}
      collapseKey={collapseKey}
    />
  );
}
