import React from "react";
import { Spinner } from "@shopify/polaris";

import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

export const TemplateSelectPolaris = (props) => {
  const { options, error, onChange, loading } = props;
  return (
    <>
      <ComponentLabelPolaris label="Template" required />
      {loading ? (
        <Spinner size="small" />
      ) : (
        <AutoCompletePolaris
          options={options}
          onChange={onChange}
          error={error}
          labelHidden
        />
      )}
    </>
  );
};
