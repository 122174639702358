import React, { useState, useCallback, useLayoutEffect } from "react";
import { Icon, TextField, Select, Button } from "@shopify/polaris";
import {
  MobileVerticalDotsMajorMonotone,
  DeleteMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";

import { DATA_TABLE_POLARIS } from "../../../variable";
import { TableDragPolaris, DragableRow } from "../../shared/TableDragPolaris";

const { dTRow, dTCell, dTCellFirstColumn, dTCellNumeric } = DATA_TABLE_POLARIS;
const Container = styled.div`
  .Polaris-Icon {
    height: 1.75rem;
    width: 1.75rem;
    margin: 0;
  }
`;

const TYPE_OPTIONS = [
  { value: "defined_value", label: "Defined value" },
  { value: "custom_value", label: "Custom value" },
];

const hasCellNumeric = true;

export const TemplateConfigPolaris = ({ data, onChange: onChangeProp }) => {
  // State
  const [options, setOptions] = useState([]);

  // Life cycle
  useLayoutEffect(() => {
    let newOptions =
      data?.length > 0
        ? data?.map(({ value, label }) => ({ value, label }))
        : [];
    if (!options.length) {
      setOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handle actions
  const handleCallback = useCallback(
    (dragIndex, hoverIndex) => {
      const newOptions = data;
      [newOptions[dragIndex], newOptions[hoverIndex]] = [
        newOptions[hoverIndex],
        newOptions[dragIndex],
      ];
      onChange(newOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const onChange = useCallback((value) => {
    if (onChangeProp) {
      onChangeProp(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headings = ["Column name", "Type", "Value", "Actions"];

  return (
    <Container>
      <TableDragPolaris headings={headings} hasCellNumeric={hasCellNumeric}>
        {data?.length > 0
          ? data.map((item, index) => {
              const isDefinedValue = ["defined_value"].includes(item?.type);

              const classNumeric = hasCellNumeric
                ? `${dTCell} ${dTCellNumeric}`
                : dTCell;
              return (
                <DragableRow
                  key={index}
                  index={index}
                  className={dTRow}
                  moveRow={(dagIndex, hoverIndex) => {
                    handleCallback(dagIndex, hoverIndex);
                  }}
                >
                  <td className={`${dTCell} ${dTCellFirstColumn}`}>
                    <Icon source={MobileVerticalDotsMajorMonotone} />
                  </td>
                  <td className={dTCell}>
                    <TextField
                      value={item?.label}
                      onChange={(newValue) => {
                        data[index].label = newValue;
                        onChange(data);
                      }}
                    />
                  </td>
                  <td className={dTCell}>
                    <Select
                      options={TYPE_OPTIONS}
                      value={item?.type}
                      onChange={(newValue) => {
                        data[index].type = newValue;
                        onChange(data);
                      }}
                    />
                  </td>
                  <td className={dTCell}>
                    {isDefinedValue ? (
                      <Select
                        options={options}
                        value={item?.value}
                        onChange={(newValue) => {
                          data[index].value = newValue;
                          onChange(data);
                        }}
                      />
                    ) : (
                      <TextField
                        value={item?.value}
                        onChange={(newValue) => {
                          data[index].value = newValue;
                          onChange(data);
                        }}
                      />
                    )}
                  </td>
                  <td className={classNumeric}>
                    <Button
                      icon={DeleteMajorMonotone}
                      onClick={() => {
                        data.splice(index, 1);
                        onChange(data);
                      }}
                    />
                  </td>
                </DragableRow>
              );
            })
          : null}
      </TableDragPolaris>
    </Container>
  );
};
