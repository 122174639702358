import { useQuery } from "@apollo/react-hooks";
import {
  Badge,
  Checkbox,
  DataTable,
  Link,
  Spinner,
  Stack,
  Toast,
  Tooltip,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { capitalize, get } from "lodash";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { STATUS_DESIGN_TASK } from "../../constants/task";
import { useAppContext } from "../../context";
import {
  arrInvalid,
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  elementContains,
  formatTime,
  getParamByRole,
  isPsdByKey,
  strToSlug,
  throttle,
  urlify,
} from "../../helper";
import history from "../../history";
import {
  ATTR_SIZE_SYNTAX,
  DESIGNER_SPECIAL,
  NAS_FILE_NAME_SYNTAX,
  ORDER_STATUS,
  ORDER_STATUS_COLORS_POLARIS,
  PRODUCT_BASE_TITLE_SYNTAX,
  PRODUCT_SKU_SYNTAX,
  SHIPPING_SERVICE_VALUE,
  TASK_LABEL,
  TASK_STATUS_COLORS_POLARIS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import { checkImages } from "../order/OrdersDTPolaris";
import { PersonalizedPolaris } from "../order/actions/PersonalizedPolaris";
import ModalImageClaimPolaris from "../seller/ModalImageClaimPolaris";
import CopyBox from "../shared/CopyBox";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { BulkActionsPolaris } from "./BulkActionsPolaris";
import { TasksActionsPolaris } from "./TasksActionsPolaris";
import { TASK_STATUS } from "./idea/utils";
import { SuggestFileName } from "./actions/SuggestFileNasPolaris";
import { MoreActions } from "./idea/components";
import { formatFiles, isTaskLabel, splitTaskLabel } from "./utils";
import SuspenseComp from "../shared/SuspenseComp";

const TaskResource = React.lazy(() => import("./design-task/TaskResource"));

export const STATUS_NEED_REVIEW = "Need Review";
const STATUS_PD = "Pending Design";

const sizeSlug = "size";
const replaceArr = ["+", "/", "small", "medium", "large"];
const IDEA_TYPE = "Idea Task";

/**
 * Check conditions if `true` then show label `Has PB Artwork`, otherwise no show.
 *
 * @param {Object} product The product value in task
 * @param {Object} order The order value in task
 * @param {*} productVariantId The productVariantId value in task
 * @returns {Boolean}
 */
function checkHasPBArtwork(product, order, productVariantId) {
  if (!product || !order) return null;

  // Product fields
  const pBaseLength = product.productBases?.length;
  const hasPBArtwork = product.hasPBArtwork;
  const basesHasArtwork = product.basesHasArtwork;
  const baseVariantsHasArtwork = product.baseVariantsHasArtwork;

  /* Check product campaign by base length */
  const isCampaign = pBaseLength > 1;

  // Order fields
  const productBaseVariant = order.productVariant?.productBaseVariant;
  const productBaseVariantID = productBaseVariant?.id;
  const productBaseIdInVariant = productBaseVariant?.productBase?.id;

  /**
   * Check `productVariantId` == null => check `baseId` in `basesHasArtwork`,
   * otherwise check `baseVariantId` in `baseVariantsHasArtwork`
   */
  const hasArtwork =
    productVariantId == null
      ? (basesHasArtwork || []).includes(productBaseIdInVariant)
      : (baseVariantsHasArtwork || []).includes(productBaseVariantID);

  // Markup
  return isCampaign ? hasArtwork : !!hasPBArtwork;
}

export const TableTasksPolaris = (props) => {
  const {
    data,
    screenOptions,
    dataSO,
    filter,
    setFilter,
    isDesigner,
    currentUser,
    fixedColumn,
    refetch,
    dataTotal,
    handlePaginationChange,
    isDesignOnline,
  } = props;

  // Check user role
  const userRole = get(currentUser, "teamUser.role", null);
  const userId = get(currentUser, "id", null);
  const currentParam = getParamByRole(currentUser);
  const { isSeller } = checkRole(currentUser);

  const configDesignRoles = [
    TEAM_ROLE.StoreManager,
    TEAM_ROLE.DesignLeader,
    TEAM_ROLE.Designer,
    TEAM_ROLE.Admin,
    USER_ROLE.Seller,
    TEAM_ROLE.MarketplaceManager,
  ];

  const isLeader = [
    TEAM_ROLE.StoreManager,
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.Admin,
    TEAM_ROLE.DesignLeader,
  ].includes(userRole);
  let isDesignLeader = [TEAM_ROLE.DesignLeader].includes(userRole);
  const specialUser = DESIGNER_SPECIAL.accIds.includes(userId);

  // State
  const [columnContentTypes, setColumnContentTypes] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [rows, setRows] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [itemsChecked, setItemsChecked] = useState({});
  const [idsChecked, setIdsChecked] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);

  // Context
  const { isMobile } = useAppContext();

  // Show check all
  let showCheckbox = false;
  let filterStatus = filter?.status;
  if (
    isDesigner &&
    filterStatus &&
    [STATUS_DESIGN_TASK.Pending].includes(filterStatus)
  ) {
    showCheckbox = true;
  } else if (
    isLeader &&
    filterStatus &&
    [
      "Pending Design",
      STATUS_DESIGN_TASK.Pending,
      STATUS_DESIGN_TASK.Doing,
      STATUS_DESIGN_TASK.NeedReview,
      STATUS_DESIGN_TASK.Rejected,
      STATUS_DESIGN_TASK.Expired,
      STATUS_DESIGN_TASK.ExpiredSoon,
      STATUS_DESIGN_TASK.NeedBuyerConfirm,
      STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
      STATUS_DESIGN_TASK.ArtworkConfigurable,
    ].includes(filterStatus)
  ) {
    showCheckbox = true;
  }

  // Get column, headings
  useEffect(() => {
    let newHeadings = [];
    let newCol = [];
    let originSO = dataSO || [];
    let currentHeadings = originSO.filter(
      (i) => screenOptions.length && screenOptions.includes(i.value),
    );
    newHeadings = currentHeadings.map((i) => i.label);
    newCol = currentHeadings.map(() => "text");

    newCol = ["text", ...newCol];

    // Show check all
    if (showCheckbox) {
      newHeadings = [
        <Checkbox
          label=""
          checked={checkedAll}
          onChange={(newChecked) => handleCheckedAll(newChecked)}
        />,
        ...newHeadings,
      ];
    }

    setColumnContentTypes(newCol);
    setHeadings(newHeadings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenOptions, dataSO, isDesigner, checkedAll, filter, isLeader]);

  const { assignessByTask, loading } = useGetSimilarTaskAssignee(
    data?.tasksV2?.nodes,
  );

  useEffect(() => {
    if (data) {
      let newRows = data.tasksV2.nodes.map((task) => {
        // Order id
        let orderFake = {
          id: task && task.orderId,
          product: task?.product,
        };

        // Platform
        let platform = get(task, "order.store.platform", null);
        platform = capitalize(platform);
        if ("Woocommerce" === platform) {
          platform = "WooCommerce";
        }
        if ("Shopbase" === platform) {
          platform = "ShopBase";
        }

        // Store
        let store = get(task, "order.store.title", null);

        // Product
        const taskType = task.taskType;
        const isIdeaType = [IDEA_TYPE].includes(taskType);

        let product = task && task.product;
        const ideaTaskId = product?.ideaTaskId;
        let productSku = product && product.sku;
        let productUrl = null;
        let order = task && task.order;
        const originOrderId = order?.originId;
        let originProductId = order && order.originalProductID;
        let originProduct = order && order.originalProduct;
        let originProductSku = originProduct && originProduct.sku;
        let originProductTitle = originProduct && originProduct.title;
        const isCannotConfigInPB = product?.isCannotConfigInPB;
        const cannotConfigInPBNote = product?.cannotConfigInPBNote;
        let productCollections = product?.collections;

        let originalTitle;
        let originalId;
        if (product) {
          originalId = originProductId ? originProductId : product.id;
          originalTitle = originProductTitle
            ? originProductTitle
            : product.title;
        }

        let originalSku = originProductSku ? originProductSku : productSku;
        let productBases = product?.productBases;

        // originalProductIsCampaign
        const originalProductIsCampaign = order?.originalProductIsCampaign;
        const linkEdit = `/${currentParam}/products/edit`;
        const linkCampaign = `/${currentParam}/products/campaign`;

        if (originalProductIsCampaign) {
          productUrl = `${linkCampaign}/${originalId}`;
        } else {
          if (productBases) {
            const link = productBases.length > 1 ? linkCampaign : linkEdit;
            productUrl = `${link}/${originalId}`;
          }
        }

        // Product variant
        const productVariant = order?.productVariant;
        let pBaseVariantAttributes =
          productVariant?.productBaseVariant?.attributes;

        pBaseVariantAttributes = pBaseVariantAttrMatchSize(
          pBaseVariantAttributes,
        );
        let pBaseVAOption = pBaseVAOptionFn(pBaseVariantAttributes);

        const pBase = productVariant?.productBaseVariant?.productBase;
        let pBaseName = pBase?.title;
        pBaseName = pBaseName ? strToSlug(pBaseName) : "";

        // hasPsdInNas
        const parentHasPsdInNas = product?.hasPsdInNas;
        const childHasPsdInNas = productVariant?.hasPsdInNas;
        const originProductHasPsdInNas = originProduct?.hasPsdInNas;
        const originProductVariant = order?.originalProductVariant;
        let originProductBase =
          originProductVariant?.productBaseVariant?.productBase;
        let originProductBaseTitle = originProductBase?.title;
        originProductBaseTitle = originProductBaseTitle
          ? strToSlug(originProductBaseTitle)
          : "";
        let suggestNasFileNameSyntax = originProductBase?.nasFileNameSyntax;
        let suggestPBaseVariantAttributes =
          originProductVariant?.productBaseVariant?.attributes;
        suggestPBaseVariantAttributes = pBaseVariantAttrMatchSize(
          suggestPBaseVariantAttributes,
        );

        let suggestPBaseVAOption = pBaseVAOptionFn(
          suggestPBaseVariantAttributes,
        );
        //originProductSku
        // originProductBaseTitle

        let hasPsdInNasMarkup = null;
        let hasSuggest = false;
        let similarHasPSD = false;
        const hasPSDMarkup = (
          <Badge children="Has PSD in Nas" status="success" />
        );
        const similarPSDMarkup = (
          <Badge children="Similar PSD in Nas" status="attention" />
        );
        const configPBErrorBadge = (
          <Badge children="Config PB Error" status="critical" />
        );
        const configPBErrorMarkup =
          cannotConfigInPBNote != null ? (
            <Tooltip
              content={
                <div style={{ maxWidth: "35rem" }}>{cannotConfigInPBNote}</div>
              }
            >
              {configPBErrorBadge}
            </Tooltip>
          ) : (
            configPBErrorBadge
          );

        // Condition show info
        const isCampaign = productBases?.length > 1;
        const designPositionInProductVariant = pBase?.designPositions;
        const pBaseVariantNotEmpty =
          designPositionInProductVariant?.length > 0
            ? designPositionInProductVariant.every(
                (item) => item?.productBaseVariants?.length > 0,
              )
            : false;

        if (childHasPsdInNas) {
          hasPsdInNasMarkup = hasPSDMarkup;
        } else if (!childHasPsdInNas && parentHasPsdInNas) {
          if (isCampaign || pBaseVariantNotEmpty) {
            hasPsdInNasMarkup = similarPSDMarkup;
            similarHasPSD = true;
          } else {
            hasPsdInNasMarkup = hasPSDMarkup;
          }
        } else if (originProductHasPsdInNas) {
          hasSuggest = true;
          hasPsdInNasMarkup = similarPSDMarkup;
        }

        // Nas file name
        let nasFileNameSyntax = pBase?.nasFileNameSyntax;
        if (nasFileNameSyntax == null) {
          nasFileNameSyntax = NAS_FILE_NAME_SYNTAX;
        }
        let nasFileName = renderNasFile(
          nasFileNameSyntax,
          productSku,
          pBaseName,
          pBaseVAOption,
        );

        let suggestFileName = hasSuggest
          ? renderNasFile(
              suggestNasFileNameSyntax,
              originProductSku,
              originProductBaseTitle,
              suggestPBaseVAOption,
            )
          : null;

        // Kpi
        let kpi = task && task.kpi;
        let defaultKpi = task && task.defaultKpi;
        if (isDesigner) {
          kpi = kpi ? kpi : defaultKpi;
        }

        const pattern = specialUser ? "YYYY-MM-DD" : undefined;
        // Created at
        let createdAtMarkup = formatTime(task?.createdAt, pattern);
        // Assign date
        let assignDateMarkup = formatTime(task?.assignDate, pattern);
        // Done date
        let doneDateMarkup = formatTime(task?.doneDate, pattern);
        // Dead line
        let deadlineMarkUp = formatTime(task?.deadline, pattern);

        // Order status
        let orderStatus = task?.order?.status;
        let newOrderStatus = [
          ORDER_STATUS.ReadyToPrint,
          ORDER_STATUS.Printing,
          ORDER_STATUS.PendingPayment,
        ].includes(orderStatus)
          ? ORDER_STATUS.InProduction
          : orderStatus;

        const resultOrderStatus =
          newOrderStatus &&
          ORDER_STATUS_COLORS_POLARIS.find((o) => o.name === newOrderStatus);

        // Status
        let status = task && task.status;
        if ("Need Review" === status && isDesigner) {
          status = "In Review";
        }
        const isDeniedByCurrentDesigner =
          currentUser?.id !== task?.assignee?.id;

        if (isDeniedByCurrentDesigner && isDesigner) {
          status = "Denied";
        }

        const resultStatus =
          status && TASK_STATUS_COLORS_POLARIS.find((o) => o.name === status);

        // Designer
        let designer = task?.assignee;
        let designerMarkup = [designer?.firstName, designer?.lastName]
          .filter(Boolean)
          .join(" ");
        // Designer
        // let similarTaskAssignees = task?.similarTaskAssignees;
        // let similarTaskAssigneeMarkup = similarTaskAssignees
        //    ? similarTaskAssignees
        //         .map((item) =>
        //            [item?.firstName, item?.lastName]
        //               .filter(Boolean)
        //               .join(" ")
        //         )
        //         .join(", ")
        //    : "";

        const designOnlineTeamMarkup = task?.designOnlineTeam?.name || "";

        let similarTaskAssigneeMarkup = loading ? (
          <Spinner size="small" />
        ) : (
          assignessByTask[task.id]
        );

        // Actions
        const noShowActions =
          ("Denied" === status || isDeniedByCurrentDesigner) && isDesigner;
        let showActions = !noShowActions;

        // Task resource
        const resources = task?.resources;
        const newResource = formatFiles(resources);

        let taskResourceMarkup = (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{task?.description}</span>
            {newResource?.length > 0 ? (
              <ModalImageClaimPolaris
                files={{
                  files: newResource,
                }}
                limit={1}
                width={80}
                height={80}
              />
            ) : null}
          </div>
        );

        // Design resource
        let psds = task?.product?.psd;
        const psdPattern = /[|\n]/g;
        if (psds) {
          psds = psds.split(psdPattern).filter(Boolean);
        }

        const psdContentMarkup =
          psds?.length > 0 ? (
            <div>
              {psds.map((psd, idx) => (
                <div className="design-resources-urls" key={`psd-${idx}`}>
                  {urlify(psd) ? (
                    <CustomLinkPolaris href={psd}>{psd}</CustomLinkPolaris>
                  ) : (
                    <span>{psd}</span>
                  )}
                </div>
              ))}
            </div>
          ) : null;

        // Product Markup
        const pBaseId = pBase?.id;
        const productInOrder = order?.product;
        let mainImages = checkImages(productInOrder, pBaseId);

        const productImageMarkup = (
          <div>
            <ModalImageClaimPolaris
              files={{
                files: mainImages,
              }}
              limit={1}
              width={80}
              height={80}
            />
          </div>
        );
        const productLink =
          isLeader && !isDesignOnline ? (
            <Link url={productUrl}>{originalTitle}</Link>
          ) : (
            <span>{originalTitle}</span>
          );

        // Has PB artwork
        const productVariantId = task.productVariantId;
        let hasPBArtworkMarkup;
        const hasPBArtwork = checkHasPBArtwork(
          product,
          order,
          productVariantId,
        );
        const canShowTaskConfigurable = task.personalized && !hasPBArtwork;

        if (hasPBArtwork) {
          hasPBArtworkMarkup = (
            <Badge
              status="success"
              progress="incomplete"
              children="Has PB Artwork"
            />
          );
        }

        let groupBadge = (
          <div className="has-artwork">
            {hasPBArtworkMarkup}
            {isCannotConfigInPB && configPBErrorMarkup}
          </div>
        );

        const hasPsdInNasWrap = hasPsdInNasMarkup ? (
          <> | {hasPsdInNasMarkup}</>
        ) : null;

        const suggestMarkup = !!suggestFileName ? (
          <SuggestFileName fileName={suggestFileName} sku={originProductSku} />
        ) : null;

        const productSkuMarkup = (
          <div className="sku-wrap">
            <Badge>{originalSku}</Badge>
            {hasPsdInNasWrap}
            {similarHasPSD ? <SuggestFileName sku={productSku} /> : null}
            {suggestMarkup}
          </div>
        );

        const productMarkup = (
          <div className="product_wrap">
            {product ? (
              <>
                {productImageMarkup}
                <div>
                  {productLink}
                  {productSkuMarkup}
                  {groupBadge}
                  {ideaTaskId && (
                    <Badge children="Created via Idea" status="success" />
                  )}
                  <div className="slug-special">
                    <CopyBox text={nasFileName} />
                    {/* slugSpecialMarkup */}
                  </div>
                  <div className="product-collections">
                    {productCollections &&
                      productCollections.length > 0 &&
                      productCollections.map((item, i) => {
                        return item && item.name ? (
                          <Badge key={`collection_${item.name}_i`}>
                            {item.name}
                          </Badge>
                        ) : (
                          ""
                        );
                      })}
                  </div>
                </div>
              </>
            ) : !isIdeaType ? (
              <span>Product does not exists(maybe is deleted)</span>
            ) : null}
          </div>
        );

        // Bulk accept design
        const usingTaskBulkDesign = order?.usingTaskBulkDesign;
        const labelAD = usingTaskBulkDesign ? "Yes" : "No";
        const statusAD = usingTaskBulkDesign ? "success" : "critical";
        const bulkADMarkup =
          usingTaskBulkDesign != null ? (
            <div className="bulk-accept-design">
              <Badge children={labelAD} status={statusAD} />
            </div>
          ) : null;

        // Status
        const needReviewOrPD = [STATUS_NEED_REVIEW, STATUS_PD].includes(
          filter.status,
        );

        const note = task?.note;
        let showNote = isLeader && note;
        // if ([STATUS_PD].includes(filter.status)) {
        //   showNote = filter.hasAssign === false;
        // } else
        if (task?.isAwaitingApproval && needReviewOrPD) {
          showNote = true;
        }

        const hasWaitApprovalCls = showNote && note ? "waitApproval" : "";

        const hasRejected = task?.rejectedTimeCount >= 1;
        const rejectedCls =
          hasRejected && needReviewOrPD && isLeader ? "rejected" : "";

        const search = history.location.search;
        let params = { ...convertStringToObject(search) };
        if (params != null) {
          params.search = originProductSku ? originProductSku : null;
        }

        params = convertObjectToParams({ ...params });
        const originalProductMarkup = (
          <div className="original-product">
            {originProductSku ? (
              <CustomLinkPolaris
                href={`${history.location.pathname}?${params}`}
                children={originProductSku}
              />
            ) : null}
          </div>
        );

        // OrderId
        const ShippingOption = order?.fulfillmentServiceShippingOption;
        let expressShippingMarkup;
        if (SHIPPING_SERVICE_VALUE.includes(ShippingOption)) {
          expressShippingMarkup = (
            <div>
              <Badge children="Express Shipping" status="info" />
            </div>
          );
        }

        // originOrderId
        const originOrderIdMarkup = (
          <div className="origin-id-wrap item">
            <span>{originOrderId}</span>
          </div>
        );

        const columns = [
          {
            key: "",
            show: showCheckbox,
            render: (
              <div
                className={`checked_wrap ${hasWaitApprovalCls} ${rejectedCls}`}
                data-awaiting-note={note}
              >
                <Checkbox
                  label=""
                  id={task.id}
                  checked={!!itemsChecked[task.id]}
                  onChange={(newChecked, id) => handleChecked(id, newChecked)}
                />
              </div>
            ),
          },
          {
            key: "id",
            show: screenOptions.includes("id"),
            render: (
              <div className="id_wrap">
                <span>{task.id}</span>
              </div>
            ),
          },
          {
            key: "orderID",
            show: screenOptions.includes("orderID"),
            render: (
              <div className="order-id_wrap">
                <span>{task.orderId}</span>
                {task.personalized && <PersonalizedPolaris order={orderFake} />}
                {expressShippingMarkup}
              </div>
            ),
          },
          {
            key: "originId",
            show: screenOptions.includes("originId"),
            render: originOrderIdMarkup,
          },
          {
            key: "platform",
            show: screenOptions.includes("platform"),
            render: (
              <div className="platform_wrap">
                <span>{platform}</span>
              </div>
            ),
          },
          {
            key: "store",
            show: screenOptions.includes("store"),
            render: (
              <div className="store_wrap">
                <span>{store}</span>
              </div>
            ),
          },
          {
            key: "product",
            show: screenOptions.includes("product"),
            render: productMarkup,
          },
          {
            key: "defaultKPI",
            show: screenOptions.includes("defaultKPI"),
            render: (
              <div className="default-kpi_wrap">
                <span>{task.defaultKpi}</span>
              </div>
            ),
          },
          {
            key: "kpi",
            show: screenOptions.includes("kpi"),
            render: (
              <div className="kpi_wrap">
                <span>{kpi}</span>
              </div>
            ),
          },
          {
            key: "createdAt",
            show: screenOptions.includes("createdAt"),
            render: (
              <div className="createdAt_wrap">
                <span>{createdAtMarkup}</span>
              </div>
            ),
          },
          {
            key: "assignDate",
            show: screenOptions.includes("assignDate"),
            render: (
              <div className="assignDate_wrap">
                <span>{assignDateMarkup}</span>
              </div>
            ),
          },
          ...([TASK_STATUS.Done].includes(filter.status)
            ? [
                {
                  key: "doneDate",
                  show: screenOptions.includes("doneDate"),
                  render: (
                    <div className="done-date_wrap">
                      <span>{doneDateMarkup}</span>
                    </div>
                  ),
                },
              ]
            : []),
          {
            key: "originalProduct",
            show: screenOptions.includes("originalProduct"),
            render: originalProductMarkup,
          },
          {
            key: "deadline",
            show: screenOptions.includes("deadline"),
            render: (
              <div className="deadline_wrap">
                <span>{deadlineMarkUp}</span>
              </div>
            ),
          },
          {
            key: "taskResources",
            show: screenOptions.includes("taskResources"),
            render: (
              <div className="taskResources_wrap">
                {/* <span>{taskResourceMarkup}</span> */}
                <SuspenseComp>
                  <TaskResource task={task} />
                </SuspenseComp>
              </div>
            ),
          },
          {
            key: "designResourceUrls",
            show: screenOptions.includes("designResourceUrls"),
            render: (
              <div className="design-resource-url-wrap">{psdContentMarkup}</div>
            ),
          },
          ...(([ORDER_STATUS.PendingDesign].includes(filter.status) ||
            null === filter.status) &&
          !isDesigner
            ? [
                {
                  key: "orderStatus",
                  show: screenOptions.includes("orderStatus"),
                  render: (
                    <div className="order-status_wrap">
                      {resultOrderStatus && (
                        <div>
                          <Badge
                            children={
                              isSeller &&
                              resultOrderStatus.name === "Processing"
                                ? "Supplier Processing"
                                : resultOrderStatus.name
                            }
                            status={resultOrderStatus.status}
                            progress={
                              resultOrderStatus.progress
                                ? resultOrderStatus.progress
                                : null
                            }
                          />
                        </div>
                      )}
                    </div>
                  ),
                },
              ]
            : []),
          {
            key: "status",
            show: screenOptions.includes("status"),
            render: (
              <div className="status_wrap">
                {resultStatus && (
                  <div>
                    <Badge
                      children={resultStatus.name}
                      status={resultStatus.status}
                      progress={
                        resultStatus.progress ? resultStatus.progress : null
                      }
                    />
                  </div>
                )}
                <TaskLabel task={task} />
              </div>
            ),
          },
          {
            key: "designer",
            show: screenOptions.includes("designer"),
            render: (
              <div className="designer_wrap">
                <span>{designerMarkup}</span>
              </div>
            ),
          },
          {
            key: "designOnlineTeam",
            show: screenOptions.includes("designOnlineTeam"),
            render: (
              <div className="design-online-team_wrap">
                <span>{designOnlineTeamMarkup}</span>
              </div>
            ),
          },
          {
            key: "similarTaskAssignee",
            show: screenOptions.includes("similarTaskAssignee"),
            render: (
              <div className="task-assignee_wrap">
                <span>{similarTaskAssigneeMarkup}</span>
              </div>
            ),
          },
          {
            key: "bulkAcceptDesign",
            show: screenOptions.includes("bulkAcceptDesign"),
            render: bulkADMarkup,
          },
          {
            key: "actions",
            render: (
              <div className="actions_wrap">
                {isIdeaType ? (
                  <MoreActions item={task} />
                ) : showActions ? (
                  <TasksActionsPolaris
                    task={task}
                    isLeader={isLeader}
                    filter={filter}
                    isDesigner={isDesigner}
                    isDesignLeader={isDesignLeader}
                    toggleActive={toggleActive}
                    refetch={refetch}
                    user={currentUser}
                    canShowTaskConfigurable={canShowTaskConfigurable}
                    configDesignRoles={configDesignRoles}
                    onNotificationChange={({ message, isError }) =>
                      setNotification(() => ({
                        message,
                        isError,
                      }))
                    }
                  />
                ) : null}
              </div>
            ),
          },
        ];
        let columnsChecked = [];
        columnsChecked = columns.filter((i) => i.show).map((i) => i.render);

        if (!isMobile) {
          columnsChecked = [
            ...columnsChecked,
            columns[columns.length - 1].render,
          ];
        }
        return columnsChecked.length > (showCheckbox ? 2 : 1)
          ? [...columnsChecked]
          : [];
      });
      setRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    screenOptions,
    itemsChecked,
    isDesigner,
    userRole,
    filter,
    isLeader,
    specialUser,
    isMobile,
    loading,
    assignessByTask,
    isSeller,
    isDesignOnline,
    isDesignLeader,
  ]);

  const handleCheckedAll = useCallback(
    (newChecked) => {
      setCheckedAll(newChecked);
      let propsT = data?.tasksV2?.nodes ? data.tasksV2.nodes : [];

      let idsChecked = [];
      if (newChecked) {
        let item = {};
        for (let i = 0; i < propsT.length; i++) {
          item = {
            ...item,
            [propsT[i].id]: true,
          };
        }
        setItemsChecked(item);
        idsChecked = handleIdsChecked(item);
      } else {
        setItemsChecked({});
        idsChecked = [];
      }
      setIdsChecked(idsChecked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleChecked = useCallback(
    (id, newChecked) => {
      let countItemsChecked;
      let idsChecked = [];

      setItemsChecked((prev) => {
        let result = { ...prev, [id]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;

        // onSelect
        idsChecked = handleIdsChecked(result);
        return result;
      });

      if (newChecked) {
        setCheckedAll(true);
      } else if (!countItemsChecked) {
        setCheckedAll(false);
      }
      setIdsChecked(idsChecked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsChecked],
  );

  const handleIdsChecked = useCallback((object) => {
    object = Object(object);
    let result = [];

    for (let [key, value] of Object.entries(object)) {
      if (value) {
        result.push(key);
      }
    }
    return result;
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleCompleted = useCallback(() => {
    setIdsChecked(() => []);
    setCheckedAll(false);
    setItemsChecked({});
    if (refetch) {
      refetch();
    }

    setFilter((filter) => ({ ...filter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domChilds = document.querySelectorAll(".actions_wrap");
    let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

    for (let domParent of domParents) {
      for (let domChild of domChilds) {
        if (elementContains(domParent, domChild)) {
          if (fixedColumn) {
            if (addClass) {
              domParent.classList.add("custom-cell");
            } else {
              domParent.classList.remove("custom-cell");
            }
          } else {
            domParent.classList.remove("custom-cell");
          }
        }
      }
    }

    // Awaiting approval
    let domRows = document.querySelectorAll(".Polaris-DataTable__TableRow");
    let domAwaitApproval = document.querySelectorAll(".waitApproval");
    let domRejects = document.querySelectorAll(".rejected");
    let domNotes = document.querySelectorAll("[data-awaiting-note]");
    for (let domRow of domRows) {
      for (let domAA of domAwaitApproval) {
        if (elementContains(domRow, domAA)) {
          domRow.classList.add("waitApproval");
          break;
        } else {
          domRow.classList.remove("waitApproval");
        }
      }
      for (let domR of domRejects) {
        if (elementContains(domRow, domR)) {
          domRow.classList.add("rejected");
          break;
        } else {
          domRow.classList.remove("rejected");
        }
      }
      for (let domNote of domNotes) {
        let span = domRow.querySelector(".data-awaiting-note");
        if (elementContains(domRow, domNote)) {
          if (!span) {
            span = document.createElement("span");
          }
          const domValue = domNote.getAttribute("data-awaiting-note");
          span.innerText = domValue ? `Note: ${domValue}` : "";
          span.className = "data-awaiting-note";
          domRow.appendChild(span);
          break;
        } else {
          if (span) {
            span.remove();
          }
        }
      }
    }
  }, [rows, coordinates, fixedColumn]);

  // Handle scroll
  useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (wrapRef.current) {
          setCoordinates({
            scrollLeft: wrapRef.current.scrollLeft,
            scrollWidth: wrapRef.current.scrollWidth,
            offsetWidth: wrapRef.current.offsetWidth,
          });
        }
      });
    };

    if (wrapRef.current) {
      wrapRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRef.current.removeEventListener("scroll", handler);
      }
    };
  }, []);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  let { limit, offset } = filter;
  const total = get(data, "tasksV2.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handlePaginationChange,
    total,
  };

  const totalKpi = dataTotal?.totalKpi;
  const totalKpiAssignTask = dataTotal?.totalKpiAssignTask;
  let hasTotalKpi = {};
  if (totalKpi && [TASK_STATUS.Done].includes(filter.status)) {
    hasTotalKpi = {
      ...hasTotalKpi,
      totalKpi,
    };
  }
  if (totalKpiAssignTask) {
    hasTotalKpi = {
      ...hasTotalKpi,
      totalKpiAssignTask,
    };
  }

  // Markup
  const bulkActionsMarkup =
    idsChecked.length > 0 ? (
      <div className="bulk-actions_wrap">
        <BulkActionsPolaris
          idsChecked={idsChecked}
          isDesigner={isDesigner}
          isLeader={isLeader}
          filterStatus={filterStatus}
          toggleActive={toggleActive}
          onNotificationChange={({ message, isError }) =>
            setNotification(() => ({ message, isError }))
          }
          onCompleted={handleCompleted}
          onCheckAll={() => {
            handleCheckedAll(false);
          }}
        />
      </div>
    ) : null;

  let columnCTMarkup = ["text"];
  let headingsMarkup = [
    "Please choose at least one column above to visible data table",
  ];
  let paginationMarkup;

  if (headings.length > (showCheckbox ? 1 : 0)) {
    columnCTMarkup = columnContentTypes;
    headingsMarkup = headings;
    if (!isMobile) {
      columnCTMarkup = [...columnContentTypes, "numeric"];
      headingsMarkup = [
        ...headings,
        <div className="actions_wrap">Actions</div>,
      ];
    }
    paginationMarkup = (
      <PaginationContainer>
        <PaginationPolaris
          aggregation={aggregation}
          showTotal
          gotoPage={isDesignLeader}
          {...hasTotalKpi}
        />
      </PaginationContainer>
    );
  }

  return (
    <React.Fragment>
      {toastMarkup}
      <Container ref={wrapRef}>
        {bulkActionsMarkup}
        <DataTable
          columnContentTypes={columnCTMarkup}
          headings={headingsMarkup}
          rows={rows}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      {paginationMarkup}
    </React.Fragment>
  );
};

const isNeedReviewOrRejected = (status) =>
  [STATUS_DESIGN_TASK.NeedBuyerConfirm, STATUS_DESIGN_TASK.Rejected].includes(
    status,
  );

function TaskLabel({ task }) {
  const label = splitTaskLabel(get(task, "label"));
  const status = get(task, "status");
  if (!isNeedReviewOrRejected(status) || arrInvalid(label)) return null;

  return (
    <div style={{ marginTop: "0.6rem" }}>
      <Stack vertical spacing="tight">
        {isTaskLabel(label, TASK_LABEL.RejectedByBuyer) && (
          <Badge children={TASK_LABEL.RejectedByBuyer} status="attention" />
        )}

        {isTaskLabel(label, TASK_LABEL.AwaitingArtistFix) && (
          <Badge children={TASK_LABEL.AwaitingArtistFix} status="info" />
        )}
      </Stack>
    </div>
  );
}

const GET_TASK_SIMILAR = gql`
  query getTaskSimilarAssigneesByIds($taskIds: [String!]!) {
    getTaskSimilarAssigneesByIds(taskIds: $taskIds) {
      taskId
      assignees {
        id
        firstName
        lastName
      }
    }
  }
`;

function useGetSimilarTaskAssignee(tasks) {
  const [assignessByTask, setAssignessByTask] = useState({});

  const taskIds = tasks?.length > 0 ? tasks.map(({ id }) => id) : null;
  const { loading, data } = useQuery(GET_TASK_SIMILAR, {
    variables: {
      taskIds,
    },
    skip: taskIds.length === 0,
  });

  useEffect(() => {
    if (!data || data.getTaskSimilarAssigneesByIds?.length === 0) return;

    const assignessByTask = data.getTaskSimilarAssigneesByIds.reduce(
      (acc, { taskId, assignees }) => {
        if (!Array.isArray(assignees) || assignees.length === 0) return acc;
        const fullNames = assignees
          .map(({ firstName, lastName }) =>
            [firstName, lastName].filter(Boolean).join(" "),
          )
          .join(", ");
        acc[taskId] = fullNames;
        return acc;
      },
      {},
    );

    setAssignessByTask(assignessByTask);
  }, [data]);

  return { assignessByTask, loading };
}

function renderNasFile(
  nasFileNameSyntax,
  productSku,
  pBaseName,
  pBaseVAOption,
) {
  let nasFileName = nasFileNameSyntax;
  let otherProductSku = productSku;
  replaceArr.forEach((i) => {
    otherProductSku = otherProductSku ? otherProductSku.replace(i, "") : "";
  });

  let otherPBaseName = pBaseName;
  replaceArr.forEach((i) => {
    otherPBaseName = otherPBaseName ? otherPBaseName.replace(i, "") : "";
  });

  let otherPBaseVAOption = pBaseVAOption;
  replaceArr.forEach((i) => {
    otherPBaseVAOption = otherPBaseVAOption
      ? otherPBaseVAOption.replace(i, "")
      : "";
  });

  if (nasFileName != null) {
    nasFileName = nasFileName.replace(PRODUCT_SKU_SYNTAX, otherProductSku);
    nasFileName = nasFileName.replace(
      PRODUCT_BASE_TITLE_SYNTAX,
      otherPBaseName,
    );
    nasFileName = nasFileName.replace(ATTR_SIZE_SYNTAX, otherPBaseVAOption);
    nasFileName = nasFileName.split("-").join("_");
  }
  return nasFileName;
}

function pBaseVariantAttrMatchSize(attr = []) {
  return attr.filter((i) => [sizeSlug].includes(i.slug));
}

function pBaseVAOptionFn(arr = []) {
  return arr
    .map((i) => {
      let option = i && i.option;
      replaceArr.forEach((ii) => {
        option = option?.length > 0 ? option.toLowerCase() : "";
        option = option?.length > 0 ? option.replace(ii, "") : "";
      });
      return option ? strToSlug(option.trim()) : "";
    })
    .filter(Boolean)
    .join("_");
}

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

const Container = styled.div`
  overflow-x: auto;

  .id_wrap,
  .default-kpi_wrap,
  .done-date_wrap,
  .designer_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }

  .order-status_wrap,
  .design-online-team_wrap {
    width: calc(200px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }

  .task-assignee_wrap {
    width: calc(45rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }

  .default-kpi_wrap {
    text-align: center;
  }

  .order-id_wrap {
    width: calc(17rem - 3.2rem);
    display: flex;
    white-space: normal;
    word-break: break-word;
    flex-direction: column;
  }

  .platform_wrap,
  .store_wrap,
  .taskResources_wrap,
  .product-resources_wrap,
  .status_wrap {
    width: calc(220px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }

  .product_wrap {
    width: calc(47rem - 3.2rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.75rem;
    white-space: normal;
    word-break: break-word;

    .modal-image {
      width: max-content;
    }
  }

  .actions_wrap {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: initial;
    }
  }

  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;

      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }

  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.custom-cell {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }

    .data-awaiting-note {
      display: none;
    }

    &.waitApproval {
      color: #202223;
      position: relative;

      .Polaris-DataTable__Cell {
        background-color: #f1ccca;
      }

      &:hover {
        .Polaris-DataTable__Cell {
          &.custom-cell {
            background-color: #f1ccca;
          }
        }
      }

      .data-awaiting-note {
        position: absolute;
        display: block;
        left: 2rem;
        top: 1rem;
        font-size: 1.5em;
        color: #e81e1e;
      }
    }

    &.rejected {
      position: relative;

      .Polaris-DataTable__Cell {
        background-color: #f3efdc;
      }

      &:hover {
        .Polaris-DataTable__Cell {
          &.custom-cell {
            background-color: #f3efdc;
          }
        }
      }
    }

    .product_wrap {
      .sku-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.35rem;
      }

      .suggest-wrap {
        display: inline-block;

        .Polaris-Button {
          padding: 0;
          margin: 0;
          margin-left: -0.5rem;
        }
      }
      .has-artwork {
        .Polaris-Badge {
          font-size: 0.8em;
          &.Polaris-Badge--statusSuccess {
            background: #001833;
            color: #2daaff;
          }
        }
      }
    }
    .slug-special {
      .copy-box {
        font-size: 0.8em;
        padding: 2px;
        padding-left: 5px;
        border-radius: var(--p-border-radius-base, 3px);
        margin-top: 0.5rem;
        border-color: #b7c1ca;

        input {
          background: transparent;
        }
      }
    }
    .product-collections {
      margin-top: 5px;
    }
  }

  // bulk actions
  .bulk-actions_wrap {
    padding: 1.6rem;
  }
  .Polaris-DataTable__Cell--header {
    border-top: 0.1rem solid #dbdee0;
  }

  .has-artwork {
    margin: 0.5rem 0;
  }

  .design-resource-url-wrap {
    width: calc(30rem - 3.2rem);
    white-space: normal;
    word-break: break-all;
  }

  .design-resources-urls:not(:first-child) {
    margin-top: 0.785rem;
  }
`;
