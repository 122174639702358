import { Stack } from "@shopify/polaris";
import React from "react";
import { CollectionSelectPolaris } from "../../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../../product/tags/TagsSelectPolaris";
import { genTagMarkup, ItemWrap } from "./IdeaSection";

const labelCol = "Collections";
const labelTag = "Tags";
export function ProductTaxonomy({
    canEdit,
    collectionIds,
    tagIds,
    handleCollectionChange,
    handleTagChange,
    errors,
}) {
    return (
        <Stack vertical>
            {canEdit ? (
                <CollectionSelectPolaris
                    label={labelCol}
                    required
                    allowMultiple
                    value={collectionIds}
                    haveQuickAdd
                    getCoupleValue
                    onChange={handleCollectionChange}
                    errorMessage={errors["collectionIds"]}
                    limit={2000}
                />
            ) : (
                <ItemWrap label={labelCol}>
                    <span>{genTagMarkup(collectionIds)}</span>
                </ItemWrap>
            )}
            {canEdit ? (
                <TagsSelectPolaris
                    label={labelTag}
                    required
                    allowMultiple
                    value={tagIds}
                    haveQuickAdd
                    getCoupleValue
                    onChange={handleTagChange}
                    errorMessage={errors["tagIds"]}
                    limit={2000}
                />
            ) : (
                <ItemWrap label={labelTag}>
                    <span>{genTagMarkup(tagIds)}</span>
                </ItemWrap>
            )}
        </Stack>
    );
}
