import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextStyle, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { handleError } from "../../helper";
import { CollectionSelectPolaris } from "./collections/CollectionSelectPolaris";

const ADD_COLLECTION = gql`
  mutation addCollectionsToProducts(
    $productIds: [ID!]!
    $collectionIds: [ID!]
    $add: Boolean!
  ) {
    addCollectionsToProducts(
      productIds: $productIds
      collectionIds: $collectionIds
      add: $add
    )
  }
`;

const Container = styled.div`
  div[role="combobox"]:focus {
    outline: none;
  }
`;

export const AddCollectionsProductsPolaris = ({
  open,
  toggleModal,
  selectedItem,
  onCompleted,
  refetch,
  items,
}) => {
  const [collections, setCollections] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [resource, setResource] = useState([]);

  const [addCollectionsToProducts, { data, loading, error }] = useMutation(
    ADD_COLLECTION,
    {
      onError: () => {},
    },
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedItem?.length !== 1 ||
      !items ||
      !Array.isArray(items) ||
      items.length === 0
    ) {
      setResource([]);
      return;
    }

    const item = items.find(({ id }) => selectedItem.includes(id));
    if (
      !item ||
      !item.collections ||
      !Array.isArray(item.collections) ||
      item.collections.length === 0
    )
      return;
    setResource(item.collections);

    return () => {
      setResource([]);
    };
  }, [selectedItem]);

  const handleAction = useCallback(() => {
    const collectionIds =
      collections?.length > 0 ? Array.from(new Set(collections).values()) : [];

    addCollectionsToProducts({
      variables: {
        productIds: selectedItem,
        add: true,
        collectionIds: collectionIds,
      },
    });
    toggleActive();
    const id = setTimeout(() => {
      toggleModal();
      onCompleted();
      setCollections([]);
      if (refetch) {
        refetch();
      }
    }, 2500);
    setTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, collections]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const toastMarkup = activeToast
    ? (error || (data && data.addCollectionsToProducts)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.addCollectionsToProducts &&
                "Add collections success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        title="Add collections"
        open={open}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleModal }]}
        primaryAction={{
          content: "Save",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <Container>
          <CollectionSelectPolaris
            value={collections}
            addCollectionsToProducts
            label="Collections"
            onChange={(value) => setCollections(value)}
            // getCoupleValue
            allowMultiple
            resource={resource}
          />
          <div style={{ marginTop: 5 }}>
            <TextStyle
              variation="strong"
              children="Existing collections will be replaced by the newly selected collections"
            />
          </div>
        </Container>
      </Modal>
    </>
  );
};
