import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { GroupFilterPolaris } from "./GroupFilterPolaris";
import { GroupsDataTablePolaris } from "./GroupsDataTablePolaris";

const Container = styled.div`
  .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
    .Polaris-Filters-ConnectedFilterControl__CenterContainer
    * {
    border-radius: var(--p-border-radius-base, 3px) !important;
  }
`;

export const QUERY_GROUPS = gql`
  query groups($filter: GroupFilter) {
    groups(filter: $filter) {
      total
      nodes {
        id
        name
        description
        teamId
        stores {
          id
          title
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER];

export const GroupsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = React.useState({
    limit: Number(getCookie("perPageGroups")) || 20,
    offset: 0,
    search: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(QUERY_GROUPS, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  // Handle actions

  React.useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleFilterChange = React.useCallback(({ search }) => {
    setFilter((prev) => {
      if (!isEqual(prev.search, search)) {
        return {
          ...prev,
          search,
          offset: 0,
        };
      }
      return prev;
    });
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  return (
    <Container>
      {loadingMarkup}
      <Card sectioned>
        <GroupFilterPolaris filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div style={{ padding: "1.6rem" }}>
            Error: {handleError(error.toString())}
          </div>
        ) : data?.groups?.nodes?.length > 0 ? (
          <GroupsDataTablePolaris
            data={data}
            refetch={refetch}
            filter={filter}
            setFilter={(offset, limit) => {
              setFilter((prev) => {
                if (
                  !isEqual(prev.offset, offset) ||
                  !isEqual(prev.limit, limit)
                ) {
                  return {
                    ...prev,
                    offset,
                    limit,
                  };
                }
                return prev;
              });
              setCookie("perPageGroups", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Container>
  );
};
