import React from "react";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { WrapperPage } from "../../components/shared/WrapperPage";

const OnlineDesignTeams = React.lazy(() =>
  import("../../components/members/OnlineDesignTeams"),
);

export default function OnlineDesignTeamPage() {
  return (
    <WrapperPage>
      <React.Suspense fallback={<SkeletonPagePolaris />}>
        <OnlineDesignTeams />
      </React.Suspense>
    </WrapperPage>
  );
}
