import React, { useState, useCallback } from "react";
import { Tabs } from "@shopify/polaris";
import styled from "styled-components";

import { GetTaskLogPolaris } from "./GetTaskLogPolaris";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  padding: 1.5rem 0;
  .column-left,
  .column-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .content_wrap {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;

    .content_item {
      word-break: break-all;
    }
    
    &.special {
      flex-direction: column;
      .content_item {
        padding: 0.5rem;
      }
    }

    &.avaiable-design-position {
      .content_item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1.5rem;
        row-gap: 1.5rem;
        padding: 1rem;
        .design-position_item {
          border: 1px dotted rgb(145, 158, 171);
          border-radius: 5px;
          padding: 10px;
          width: 48%;
        }
      }
    }
    &.personalized-info,
    &.attributes {
      .content_item {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        flex-wrap: wrap;
      }
    }
    &.related-designs {
      .related-design > div {
        display: flex;
        flex-flow: row wrap;
        column-gap: 1rem;
        img {
          box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
            rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
        }
      }
    }
    &.product-title {
      .content_item {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
      }
    }
  }
  .content_item {
    h4 {
      display: inline-block;
      font-weight: 600;
      font-style: italic;
      margin-right: 0.5rem;
      color: rgba(0, 0, 0, 0.75);
    }
  }
`;

export const TabDetailPolaris = (props) => {
  // Props
  const { task, taskInfo } = props;

  // State
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle actions
  const handleTabChange = useCallback(
    (selectedIndex) => setSelectedTab(selectedIndex),
    []
  );

  const tabs = [
    {
      id: "task-information",
      content: "Task Information",
    },
    {
      id: "task-timelines",
      content: "Task Timelines",
    },
  ];
  let columnLeft = [];
  let columnRight = [];
  if (taskInfo && taskInfo.length) {
    taskInfo.forEach((i) => {
      if (i.left) {
        columnLeft.push(i);
      } else {
        columnRight.push(i);
      }
    });
  }
  return (
    <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
      {0 === selectedTab ? (
        <Container>
          <div className="column-left">
            {columnLeft && columnLeft.length > 0
              ? columnLeft.map((i, index) => {
                  return (
                    <div
                      className={`content_wrap ${i.className} ${
                        !i.normal ? "special" : ""
                      }`}
                      key={`left-${index}`}
                    >
                      <h3>{i.term}:</h3>
                      <div className="content_item">{i.description}</div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="column-right">
            {columnRight && columnRight.length > 0
              ? columnRight.map((i, index) => {
                  return (
                    <div
                      className={`content_wrap ${i.className} ${
                        !i.normal ? "special" : ""
                      }`}
                      key={`right-${index}`}
                    >
                      <h3>{i.term}:</h3>
                      <div className="content_item">{i.description}</div>
                    </div>
                  );
                })
              : null}
          </div>
        </Container>
      ) : (
        <GetTaskLogPolaris taskId={task?.id} />
      )}
    </Tabs>
  );
};
