import { get } from "lodash";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../../../context";
import { checkRole } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { WidgetCompProvider } from "../../widget/context";
import { ELEMENTS_DATA, LayoutSection, TITLE_PAGE } from "./LayoutSection";
import { TEAM_STATISTIC_ELEMENT } from "./OverviewsAdmin";

export const ELEMENTS = [
  {
    ...ELEMENTS_DATA.overviews,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.marketplaceManager,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.storeManagers,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.productBase,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.fulfillCounter,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.products,
    default: 2,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager],
  },
  {
    ...ELEMENTS_DATA.stores,
    default: 2,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.groups,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.trackingStatus,
    default: 2,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager, TEAM_ROLE.Accountant],
  },
];

const StoreVal = "stores";
export function EtsyChannel() {
  // Context
  const { currentUser } = useContext(AppContext);
  const { isAdministrator } = checkRole(currentUser);
  const userRole = get(currentUser, "teamUser.role", null);
  const isMarketplaceM = [TEAM_ROLE.MarketplaceManager].includes(userRole);

  const ELEMENTS_PROPS = useMemo(() => {
    return ELEMENTS.map((el) => {
      if (isMarketplaceM && el.value === StoreVal) {
        el.default = 1;
      }
      return el;
    }).filter((el) => el.roles.includes(userRole));
  }, [userRole, isMarketplaceM]);

  return (
    <WidgetCompProvider
      title={TITLE_PAGE.Etsy}
      elements={isAdministrator ? [TEAM_STATISTIC_ELEMENT, ...ELEMENTS] : ELEMENTS_PROPS}
    >
      <LayoutSection title={TITLE_PAGE.Etsy} />
    </WidgetCompProvider>
  );
}
