import React, { useState, useEffect } from "react";
import { DataTable, Button, Link } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { PaginationPolaris } from "../../shared/PaginationPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .file-name-wrap {
    width: calc(40rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const ImportsDataTablePolaris = ({ data, filter, setFilter }) => {
  // State
  const [rows, setRows] = useState([]);

  // Get data
  useEffect(() => {
    let newRows =
      data?.imports?.nodes?.length > 0
        ? data?.imports.nodes.map((item) => {
            // File name
            const files = item?.files;

            // User
            const user = item?.supplier;
            // Created
            const createdAt = moment(item?.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return [
              <div className="file-name-wrap">
                {files?.length > 0
                  ? files.map((file, index) => (
                      <Link children={file.name} url={file.url} key={index} />
                    ))
                  : null}
              </div>,
              <div className="author-wrap">
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
              </div>,
              <div className="import-data-wrap">
                <span>{createdAt}</span>
              </div>,
              <div className="action-wrap">
                <Button
                  plain
                  children="Download"
                  url={files.length > 0 ? files[0].url : null}
                />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
  }, [data]);

  let { limit, offset } = filter;
  const total = get(data, "imports.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  const colType = [];
  for (let i = 0; i < 3; i++) {
    colType.push("text");
  }
  colType.push("numeric");

  return (
    <Container>
      <DataTable
        columnContentTypes={colType}
        headings={["File name", "Author", "Import data", "Action"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
