import { useMutation } from "@apollo/react-hooks";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React from "react";
import styled from "styled-components";
import ExportTracking from "../../components/export-tracking/ExportTrackingPolaris";
import { useAppContext } from "../../context";
import { AMAZON_EXPORT_CONFIRM_SHIPMENT_V2 } from "../../graphql/mutations";
import {
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  getCookie,
  getParamByRole,
  getPathFromRouter,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";

const EXPORT_CONFIRM_SHIPMENT = gql`
  mutation amazonExportConfirmShipment($storeId: ID!) {
    amazonExportConfirmShipment(storeId: $storeId)
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "storeId"];
export const COOKIE_EXPORT_TRACKING = "click_export_tracking";
export const ExportTrackingPagePolaris = (...props) => {
  // Props
  const path = React.useMemo(() => getPathFromRouter(props), [props]);
  const isMatchPathName = React.useMemo(() => matchPathName(path), [path]);
  const initFilter = React.useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    let limit = initialFilter.limit;
    limit = Number(limit);
    if (!limit) {
      limit = 20;
    }
    initialFilter.limit = limit;

    if (initialFilter.paged) {
      initialFilter.paged = parseInt(initialFilter.paged);
    }
    initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [toastActive, setToastActive] = React.useState(false);
  const [notification, setNotification] = React.useState({
    message: null,
    error: false,
  });
  const [disabled, setDisabled] = React.useState(true);
  const [filter, setFilter] = React.useState({
    limit: Number(getCookie("perPageExportTracking")) || 20,
    paged: 1,
    storeId: null,
    ...initFilter,
  });

  // Context
  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  // Mutation
  const [amazonExportConfirmShipment, { loading }] = useMutation(
    AMAZON_EXPORT_CONFIRM_SHIPMENT_V2,
    {
      onCompleted: (res) => {
        let isExported = res?.amazonExportConfirmShipmentV2;
        if (isExported) {
          // window.open(url, "_blank");
          // window.location.href = url;
          history.push(`/${param}/export-tracking-history`);
        }
        setNotification({
          message: "Export tracking successfully.",
          error: false,
        });
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          error: true,
        });
      },
    },
  );

  // Handle action
  React.useEffect(() => {
    let { paged, limit, ...rest } = filter;

    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  React.useEffect(() => {
    if (!filter.storeId) {
      setDisabled(true);
    }
  }, [filter]);

  const toggleToastActive = React.useCallback(
    () => setToastActive((prev) => !prev),
    [],
  );
  const handleExport = React.useCallback(() => {
    toggleToastActive();
    let storeId = filter?.storeId;

    if (storeId) {
      const expiredClick = new Date().getTime() + 10 * 60 * 1000; // 10 minutes
      setCookie(COOKIE_EXPORT_TRACKING + storeId, expiredClick, 1);
    }

    amazonExportConfirmShipment({
      variables: {
        storeId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  React.useEffect(() => {
    if (filter?.storeId) {
      const expiredClick = getCookie(COOKIE_EXPORT_TRACKING + filter.storeId);
      if (expiredClick && parseInt(expiredClick) > new Date().getTime()) {
        setDisabled(true);
      }
    }
  }, [filter?.storeId]);

  const handleBtnDisabeld = React.useCallback(
    (value) => setDisabled(value),
    [],
  );
  const handleFilterChange = React.useCallback(({ storeId }) => {
    setFilter((prev) => {
      if (!isEqual(storeId, prev.storeId)) {
        return {
          ...prev,
          storeId,
        };
      }
      return prev;
    });
  }, []);

  // Markup
  const toastMarkup = toastActive && notification.message && (
    <Toast
      content={notification.message}
      error={notification.error}
      onDismiss={toggleToastActive}
      duration={1500}
    />
  );

  const handlePagination = React.useCallback((offset, limit) => {
    const paged = offset / limit + 1;
    setFilter((prev) => {
      if (!isEqual(prev.limit, limit)) {
        return {
          ...prev,
          limit,
          paged: 1,
        };
      }

      if (!isEqual(prev.paged, paged)) {
        return { ...prev, paged: paged };
      }
      return prev;
    });

    setCookie("perPageExportTracking", limit, 100);
  }, []);

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Export Trackings"
        fullWidth
        primaryAction={{
          content: "Export Tracking",
          onAction: handleExport,
          loading: loading,
          disabled: disabled || !filter.storeId,
        }}
      >
        <ExportTracking
          handleBtnDisabeld={handleBtnDisabeld}
          handleFilterChange={handleFilterChange}
          filter={filter}
          handlePagination={handlePagination}
        />
      </Page>
    </Container>
  );
};
