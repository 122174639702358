import { Button, Checkbox, FormLayout, TextField } from "@shopify/polaris";
import _, { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { sortAttributeForMap } from "../../helper";
import { META_DATA_KEY_MB } from "../../variable";
import { useMappingOrdeCtx } from "./context";
import { VariantsSelectPolaris } from "./VariantsSelectPolaris";

const Container = styled.div`
  padding-top: 1rem;
  .label-mapping {
    font-weight: 600;
    font-size: 16px;
  }
  .mapping-wrap {
    margin-left: -2rem;
    > .Polaris-FormLayout--grouped:first-child {
      .Polaris-FormLayout__Item {
        margin-top: 1rem;
      }
    }
  }
  .has-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MappingVariantsPolaris = ({
  item,
  productBaseId,
  variants,
  productBaseVariants,
  onChange,
  forceMapping,
  setForceMapping = () => {},
  setMapToBaseVariantId = () => {},
  autoMapMarkup = null,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);

  // Context
  // const ctx = useMappingOrderType();
  const metaDataName = React.useMemo(() => {
    if (!item || !item.metaData || item.metaData.length === 0) return "";
    let value = item.metaData
      .filter(
        ({ key, value }) =>
          ![META_DATA_KEY_MB].includes(key) && typeof value === "string",
      )
      .map(({ value }) => value);
    value = sortAttrName(value);
    return value;
  }, [item]);

  useEffect(() => {
    setDataSource(getDataSource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBaseId]);

  useEffect(() => {
    handleChange();
    let data = dataSource;
    let selectedV =
      data && data.length > 0
        ? data
            .filter((i) => i.productBaseVariant)
            .map((i) => i.productBaseVariant.id)
        : [];
    setSelectedVariants(selectedV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  // get data source
  const getDataSource = useCallback(() => {
    return variants.map((variant) => {
      return {
        variant: variant,
        productBaseVariant: getProductBaseVariantValue(variant),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants, productBaseVariants]);

  // find all permutations pair of attributes and then return suggestion depend of left column
  const getProductBaseVariantValue = useCallback(
    (wooVariant) => {
      if (productBaseVariants.length === 0) {
        return null;
      }

      const wooVariantsPermutation = wooVariant.attributes
        ? variantsPermutation(wooVariant.attributes.map((att) => att.option))
        : [];

      let mapOfWooPermutation = {};
      for (let i = 0; i < wooVariantsPermutation.length; i++) {
        mapOfWooPermutation[
          wooVariantsPermutation[i].join("-").toLowerCase()
        ] = true;
      }
      for (let i = 0; i < productBaseVariants.length; i++) {
        const productBaseVariantPermutation = variantsPermutation(
          productBaseVariants[i].attributes.map((v) => v.option),
        );
        if (productBaseVariantPermutation.length === 0) {
          continue;
        }
        for (let j = 0; j < productBaseVariantPermutation.length; j++) {
          if (
            mapOfWooPermutation[
              productBaseVariantPermutation[j].join("-").toLowerCase()
            ]
          ) {
            return productBaseVariants[i];
          }
        }
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productBaseVariants],
  );

  // get all variants pair
  const variantsPermutation = useCallback((attributes = []) => {
    return [attributes.sort((a, b) => a < b)];

    if (attributes.length === 1) {
      return [attributes];
    }
    const permutations = [];
    const smallerPermutations = variantsPermutation(attributes.slice(1));
    const firstOption = attributes[0];

    for (
      let permIndex = 0;
      permIndex < smallerPermutations.length;
      permIndex += 1
    ) {
      const smallerPermutation = smallerPermutations[permIndex];
      for (
        let positionIndex = 0;
        positionIndex <= smallerPermutation.length;
        positionIndex += 1
      ) {
        const permutationPrefix = smallerPermutation.slice(0, positionIndex);
        const permutationSuffix = smallerPermutation.slice(positionIndex);
        permutations.push(
          permutationPrefix.concat([firstOption], permutationSuffix),
        );
      }
    }

    return permutations;
  }, []);

  // return title of attribute and sort a-z
  const getAttributeName = useCallback((attributes = []) => {
    if (!attributes) {
      return "";
    }
    // const options = attributes
    //   .filter(({ name }) => name !== "_WCPA_order_meta_data")
    //   .map((att) => att.option);

    // return sortAttrName(options);

    const attrsValid = attributes.filter(
      ({ name }) => name !== "_WCPA_order_meta_data",
    );

    return sortAttributeForMap(attrsValid);
  }, []);

  const handleChange = useCallback(() => {
    let value = [];
    for (let i = 0; i < dataSource.length; i++) {
      const dsItem = dataSource[i];
      if (dsItem.variant && dsItem.productBaseVariant) {
        value.push({
          variantId: dsItem.variant.id,
          productBaseVariantId: dsItem.productBaseVariant.id,
          sorting: dsItem.productBaseVariant.sorting,
        });
      }
    }
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const handleChangeVariants = useCallback(
    (value, idx) => {
      let newDataSource = [];
      if (dataSource && dataSource.length) {
        newDataSource = dataSource.map((ds, index) => {
          if (index === idx) {
            return (ds = value);
          }
          return ds;
        });
      }
      setDataSource(newDataSource);
      handleChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource],
  );

  const handleReset = useCallback(() => {
    let data = dataSource;
    data = data.length
      ? data.map((i) => ({
          variant: i.variant,
          productBaseVariant: null,
        }))
      : [];
    setDataSource(data);
  }, [dataSource]);

  const currVariantId = React.useMemo(() => {
    return _.get(item, "variantId", null);
  }, [item]);

  const dataSourceMarkup =
    dataSource && dataSource.length > 0 ? (
      dataSource.map((ds, idx) => {
        const name = getAttributeName(ds.variant.attributes);
        const nameLow = typeof name === "string" ? name?.toLowerCase() : "";
        const metaDataLow =
          typeof metaDataName === "string" ? metaDataName?.toLowerCase() : "";
        const id = ds.variant?.id;
        let cbMapToBaseVariantId = () => {};
        // if (ctx?.isOnlineStore && !!forceMapping) {
        if (!!forceMapping) {
          if (currVariantId != null) {
            if (String(id) !== String(currVariantId)) return null;
          } else {
            if (nameLow !== metaDataLow) {
              return null;
            }
          }
        }

        if (currVariantId === id || nameLow === metaDataLow) {
          cbMapToBaseVariantId = setMapToBaseVariantId;

          // set default when checked force
          const baseVariantId = get(ds, "productBaseVariant.id") || null;
          cbMapToBaseVariantId(baseVariantId);
        }

        return (
          <React.Fragment key={`variants-${idx}`}>
            <FormLayout.Group>
              <TextField
                label="Origin product"
                labelHidden
                value={name ? name : _.get(item, "product.name", "")}
              />
              <VariantsSelectPolaris
                productBaseVariants={productBaseVariants}
                value={ds}
                onChange={(value) => {
                  handleChangeVariants(value, idx);
                  const { productBaseVariant } = value || {};
                  const { id } = productBaseVariant || {};
                  cbMapToBaseVariantId(id);
                }}
                selectedVariants={selectedVariants}
                enabled={!!forceMapping}
              />
            </FormLayout.Group>
          </React.Fragment>
        );
      })
    ) : (
      <p style={{ marginTop: "1rem", marginLeft: "2rem" }}>No variant found.</p>
    );

  let resetBtn = null;
  if (
    productBaseVariants &&
    productBaseVariants.length &&
    selectedVariants &&
    selectedVariants.length
  ) {
    if (productBaseVariants.length === selectedVariants.length) {
      resetBtn = (
        <Button children="Reset variant" onClick={handleReset} plain />
      );
    }
  }

  return (
    <Container>
      <label className="label-mapping">Mapping</label>
      {/* {ctx?.isOnlineStore ? ( */}
      <div>
        <Checkbox
          checked={!!forceMapping}
          onChange={setForceMapping}
          label="Force Mapping for this order item (only map this item without saving the mapping reuseable info)"
          disabled={!dataSource || dataSource.length === 1}
        />
      </div>
      {autoMapMarkup}
      <div className="mapping-wrap" style={{ marginLeft: "-2rem" }}>
        <FormLayout.Group>
          <span>Origin product</span>
          <div className="has-reset">
            <span>Product base variant</span>
            {resetBtn}
          </div>
        </FormLayout.Group>
        {dataSourceMarkup}
      </div>
    </Container>
  );
};

export function sortAttrName(arr = []) {
  return arr
    .sort((a, b) => {
      const al = a.toLowerCase();
      const bl = b.toLowerCase();
      if (al < bl) {
        return -1;
      }
      if (al > bl) {
        return 1;
      }
      return 0;
    })
    .join("-");
}

export default MappingVariantsPolaris;
