import React, { useState, useEffect } from "react";

import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";

export const ProductSelectByFFPolaris = (props) => {
  const { data, fulfillmentId, onChange, error } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let newData =
      data &&
      data.productBasesForAssortment &&
      data.productBasesForAssortment.length > 0
        ? data.productBasesForAssortment.filter((p) => {
            if (fulfillmentId) {
              if (
                p.fulfillment &&
                p.fulfillment.fulfillmentId === fulfillmentId
              ) {
                return true;
              }
            } else {
              if (p.fulfillment === null) {
                return true;
              }
            }
            return false;
          })
        : [];
    newData = newData
      .filter(Boolean)
      .map((i) => ({ value: i.id, label: i.title }));

    setOptions(() => [...newData]);
  }, [data, fulfillmentId]);

  return (
    <AutoCompletePolaris
      options={options}
      labelHidden
      value={null}
      onChange={onChange}
      error={error}
    />
  );
};
