import React from "react";
import { Filters } from "@shopify/polaris";

export const GroupFilterPolaris = ({ filter, onChange }) => {
  // State
  const [queryValue, setQueryValue] = React.useState(filter.search);
  const typingTimeRef = React.useRef(null);

  // Handle actions
  const handleQueryValueChange = React.useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleQueryValueRemove = React.useCallback(
    () => setQueryValue(null),
    []
  );
  const handleFiltersClearAll = React.useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  React.useEffect(() => {
    if (typingTimeRef.current) {
      clearTimeout(typingTimeRef.current);
    }
    typingTimeRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
        });
      }
    }, 300);
  }, [queryValue, onChange]);

  return (
    <Filters
      filters={[]}
      appliedFilters={[]}
      queryValue={queryValue}
      queryPlaceholder={"Filter group"}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFiltersClearAll}
    />
  );
};
