import { useMutation } from "@apollo/react-hooks";
import {
    Badge,
    Button,
    Collapsible,
    Link,
    Modal,
    ResourceItem,
    Stack,
    TextStyle,
    Tooltip,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import styled from "styled-components";
import amazonLogo from "../../assets/images/amazon-logo.png";
import ebayLogo from "../../assets/images/ebay-logo.png";
import etsyLogo from "../../assets/images/etsy-logo.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import shopbaseLogo from "../../assets/images/shopbase.png";
import shopifyLogo from "../../assets/images/shopify.png";
import noImg from "../../assets/images/unnamed.jpeg";
// Logos
import woocommerceLogo from "../../assets/images/wooCommerce.png";
import { GET_PRODUCT_BY_TITLE } from "../../graphql/queries";
import { handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import {
    MARK_AS_PUSHED,
    SHORT_TITLE,
    useProductsPolarisContext,
} from "../../pages/seller/ProductsPagePolaris";
import {
    AMZ_MESSAGE_ERROR,
    MAX_TITLE_CHARACTERS,
    PLATFORMS,
    WHICH_FIELD,
} from "../../variable";
import FieldsLiveView from "../customize/FieldsLiveView";
import { GEN_THUMBNAIL_IMAGE } from "../seller/ModalImageClaimPolaris";
import { ShortTitleComp } from "./components/ShortTitle";
import { MarkAsPushed } from "./MarkAsPushed";

const Container = styled.div`
    .collection-wrap,
    .tag-wrap {
        margin-left: -0.7rem;
        margin-top: -0.9rem;
        > span {
            margin-top: 1rem;
            margin-left: 1rem;
        }
        .Polaris-Badge {
            max-width: 100%;
            .Polaris-Badge__Content {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .sku-wrap {
        overflow-wrap: break-word;
        .Polaris-Badge {
            font-size: 1.175rem;
        }
    }
    .product-inner {
        margin-top: 0.375rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.875rem;
    }

    .personalized-control {
        cursor: pointer;
        display: inline-block;
    }
`;

const ImgContainer = styled.img`
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 3px;
`;

export const RenderProductPolaris = (
    item,
    currentParam,
    toggleShowModal,
    pushedSaleChannels,
    refetch
) => {
    const mainImage = item?.mainImage;
    let source;
    if (mainImage != null) {
        source = mainImage;
    } else {
        source =
            item && item.images && item.images.length > 0
                ? _.head(item.images.map((i) => i.file))
                : null;
    }

    let pb =
        item && item.productBases && item.productBases.length > 0
            ? item.productBases.map((item) => item.title).join(", ")
            : null;

    let collections =
        item && item.collections && item.collections.length > 0
            ? item.collections.map((c) => c.name)
            : [];
    let collectionsMarkup = collections.map((c, idx) => (
        <Badge key={`collection-${idx}`}>{c}</Badge>
    ));

    let tags =
        item && item.tags && item.tags.length > 0
            ? item.tags.map((t) => t.name)
            : [];
    let tagsMarkup = tags.map((t, idx) => (
        <Badge key={`tag-${idx}`}> {t}</Badge>
    ));

    const redirectPage = (item) => {
        const link =
            item && item.productBases && item.productBases.length > 1
                ? `/${currentParam}/products/campaign`
                : `/${currentParam}/products/edit`;
        return `${link}/${item.id}`;
    };

    const url = redirectPage(item);

    // Design status
    let designStatus = item?.designStatus;
    let textDS = "No";
    let statusDS = "default";
    if (["Designed"].includes(designStatus)) {
        textDS = "Yes";
        statusDS = "success";
    }

    const media = (
        <div
            onClick={() => toggleShowModal(item)}
            style={{ cursor: "pointer" }}
        >
            <ImagWrap file={source} />
        </div>
    );

    // Total orders
    const totalOrderMarkup = item?.totalOrders ? (
        <div className="total-order-wrap">
            <Badge status="success">{item.totalOrders}</Badge>
        </div>
    ) : (
        <div></div>
    );

    return (
        <Container>
            <ProductItemProvider item={item}>
                <ResourceItem id={item.id} media={media}>
                    <div className="content-wrap">
                        <div>
                            {/* <Link url={url} children={item.title} /> */}
                            <MainTitle value={item} url={url} />
                            <ShortTitle value={item} url={url} />
                            <div className="product-inner">
                                {item && item.personalized && (
                                    <PersonalizedMarkup item={item} />
                                )}
                                <div className="sku-wrap">
                                    <Badge children={item.sku} />
                                </div>
                            </div>
                            <ShortTitleBtn />
                            <MarkAsPushedBtn value={item} refetch={refetch} />
                        </div>
                        {pushedSaleChannels && (
                            <PushedSaleChannels value={item} />
                        )}
                        <div>
                            <span>{pb}</span>
                        </div>
                        {totalOrderMarkup}
                        <div className="has-design-wrap">
                            <Badge children={textDS} status={statusDS} />
                        </div>
                        <div className="collection-wrap">
                            {collectionsMarkup}
                        </div>
                        <div className="tag-wrap">{tagsMarkup}</div>
                    </div>
                </ResourceItem>
            </ProductItemProvider>
        </Container>
    );
};

const SALE_CHANNELS_DATA = {
    [PLATFORMS.WooCommerce]: {
        src: woocommerceLogo,
        title: "WooCommerce",
    },
    [PLATFORMS.Shopify]: {
        src: shopifyLogo,
        title: "Shopify",
    },
    [PLATFORMS.ShopBase]: { src: shopbaseLogo, title: "Shopbase" },
    [PLATFORMS.Ebay]: { src: ebayLogo, title: "Ebay" },
    [PLATFORMS.Etsy]: { src: etsyLogo, title: "Etsy" },
    [PLATFORMS.Amazon]: { src: amazonLogo, title: "Amazon" },
    [PLATFORMS.Facebook]: { src: facebookLogo, title: "Facebook" },
};

function PushedSaleChannels({ value }) {
    const { productStores } = value || {};
    const platforms = (productStores || []).reduce((acc, cur) => {
        const { store } = cur || {};
        const { platform } = store || {};
        if (platform != null) {
            acc.push(platform);
        }

        return acc;
    }, []);

    const uniquePlatforms = getUniquePlatforms(platforms);
    return (
        <div className="platform-wrap">
            {(uniquePlatforms || []).map((pl, index) => (
                <SaleChannel key={`platform-${index}`} value={pl} />
            ))}
        </div>
    );
}

function SaleChannel({ value }) {
    const { src, title } = SALE_CHANNELS_DATA[value];
    let customStyle = {
        width: 50,
        marginRight: "0.785rem",
    };

    if (customWidth(value)) {
        customStyle["width"] = 40;
        customStyle["marginRight"] = "1.7rem";
    }

    return (
        <Tooltip content={`Pushed to ${title}`} preferredPosition="above">
            <img alt={title} src={src} style={customStyle} />
        </Tooltip>
    );
}

function customWidth(platform) {
    return [
        PLATFORMS.WooCommerce,
        PLATFORMS.Amazon,
        PLATFORMS.ShopBase,
        PLATFORMS.Facebook,
        PLATFORMS.Etsy,
    ].includes(platform);
}

function getUniquePlatforms(data) {
    if (!(data instanceof Array) || data.length === 0) return [];
    return data.filter((el, i, arr) => arr.lastIndexOf(el) === i);
}

const UPDATE_SHORT_TITLE = gql`
    mutation updateProductShortTitle(
        $id: ID!
        $shortTitle: String!
        $amzTitle: String
        $mainTitle: String
    ) {
        updateProductShortTitle(
            id: $id
            shortTitle: $shortTitle
            amzTitle: $amzTitle
            mainTitle: $mainTitle
        ) {
            id
            shortTitle
            amzTitle
            title
        }
    }
`;

const ProductItemContext = createContext(null);

function ProductItemProvider({ children, item }) {
    const [edit, toggleEdit] = useToggle(false);
    const { shortTitle, amzTitle } = item || {};
    const btnLabel =
        !!shortTitle || !!amzTitle ? "Update Titles" : "Add Titles";

    // Context
    const { screenOptions } = useProductsPolarisContext();
    const so = screenOptions || [];
    const showShortTitle = so.includes(SHORT_TITLE);
    const showMarkPushed = so.includes(MARK_AS_PUSHED);

    // Provider
    const bag = useMemo(
        () => ({
            edit,
            toggleEdit,
            btnLabel,
            showShortTitle,
            showMarkPushed,
        }),
        [edit, toggleEdit, btnLabel, showShortTitle, showMarkPushed]
    );

    return (
        <ProductItemContext.Provider value={bag}>
            {children}
        </ProductItemContext.Provider>
    );
}

function ShortTitleBtn() {
    const { toggleEdit, btnLabel, showShortTitle } = useContext(
        ProductItemContext
    );

    return (
        showShortTitle && (
            <div style={{ marginTop: "1rem" }}>
                <Button children={btnLabel} size="slim" onClick={toggleEdit} />
            </div>
        )
    );
}

function MarkAsPushedBtn({ value, refetch }) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const { showMarkPushed } = useContext(ProductItemContext);

    return (
        showMarkPushed && (
            <div style={{ marginTop: "1rem" }}>
                <Button
                    children="Mark as Pushed"
                    size="slim"
                    onClick={toggleOpen}
                />
                <MarkAsPushed
                    open={open}
                    toggleOpen={toggleOpen}
                    value={value}
                    refetch={refetch}
                />
            </div>
        )
    );
}

function MainTitle({ value, url }) {
    const { title } = value;
    const { edit } = useContext(ProductItemContext);

    return !edit && <Link url={url} children={title} />;
}

function ShortTitle({ value }) {
    const { shortTitle, id, amzTitle, title: mainTitle } = value || {};
    const { btnLabel, toggleEdit, edit, showShortTitle } = useContext(
        ProductItemContext
    );

    // Context
    const { toggleActive, setNotify } = useProductsPolarisContext();

    // State
    const [sTitle, setSTitle] = useState(shortTitle);
    const [aTitle, setATitle] = useState(amzTitle);
    const [mTitle, setMTitle] = useState(mainTitle);
    const [loading, setLoading] = useState(false);

    // Mutation
    const [updateShortTitle, { client }] = useMutation(UPDATE_SHORT_TITLE, {
        onCompleted: () => {
            setNotify({ msg: `${btnLabel} successfully.`, err: false });
            setLoading(false);
            toggleEdit();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err.toString()), err: true });
            setLoading(false);
        },
    });

    // Actions
    const handleChange = useCallback((value) => {
        setSTitle(value);
    }, []);

    const handleAMZTitle = useCallback((value) => {
        setATitle(value);
    }, []);
    const handleMTitle = useCallback((value) => {
        setMTitle(value);
    }, []);

    const preventSubmit =
        sTitle === shortTitle && aTitle === amzTitle && mTitle === mainTitle;

    const handleSubmit = useCallback(async () => {
        if (id != null) {
            if (preventSubmit) return;

            setLoading(true);
            toggleActive && toggleActive(true);
            setNotify && setNotify({ msg: null, err: false });

            if (aTitle && aTitle !== amzTitle) {
                const { data } = await client.query({
                    query: GET_PRODUCT_BY_TITLE,
                    variables: {
                        title: aTitle,
                        whichField: WHICH_FIELD.AmzTitle,
                        productID: id,
                    },
                });

                if (data?.getProductsByTitle?.total > 0) {
                    setNotify({ msg: AMZ_MESSAGE_ERROR, err: true });
                    setLoading(false);
                    return;
                }
            }

            updateShortTitle({
                variables: {
                    id,
                    shortTitle: sTitle || "",
                    amzTitle: aTitle,
                    mainTitle: mTitle,
                },
            });
        }
    }, [
        id,
        sTitle,
        aTitle,
        amzTitle,
        mTitle,
        preventSubmit,
        updateShortTitle,
        toggleActive,
        setNotify,
        client,
    ]);

    return (
        showShortTitle && (
            <span
                style={{
                    marginTop: ".5rem",
                    marginBottom: "1rem",
                    display: "block",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Stack spacing="tight" vertical>
                    {!edit && shortTitle && (
                        <div>
                            <TextStyle
                                children="FB Title: "
                                variation="strong"
                            />
                            <span>{shortTitle}</span>
                        </div>
                    )}
                    {!edit && amzTitle && (
                        <div>
                            <TextStyle
                                children="AMZ Title: "
                                variation="strong"
                            />
                            <span>{amzTitle}</span>
                        </div>
                    )}
                    <Collapsible
                        id={`short-title-${id}`}
                        open={edit}
                        transition={{
                            duration: "550ms",
                            timingFunction: "ease",
                        }}
                    >
                        <Stack vertical spacing="tight">
                            <ShortTitleComp
                                value={mTitle}
                                onChange={handleMTitle}
                                multiline={4}
                                autoFocus
                                placeholder="Enter product title"
                                label={<LabelComp label="Title: " />}
                            />
                            <ShortTitleComp
                                value={sTitle}
                                onChange={handleChange}
                                multiline={4}
                                maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                                placeholder="facebook title"
                                label={<LabelComp label="FB Title: " />}
                            />
                            <ShortTitleComp
                                value={aTitle}
                                onChange={handleAMZTitle}
                                multiline={4}
                                maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                                placeholder="amazon title"
                                label={<LabelComp label="AMZ Title: " />}
                            />
                            <Button
                                children="Submit"
                                primary
                                disabled={preventSubmit}
                                onClick={handleSubmit}
                                size="slim"
                                loading={loading}
                            />
                        </Stack>
                    </Collapsible>
                </Stack>
            </span>
        )
    );
}

function LabelComp({ label }) {
    return <TextStyle variation="strong" children={label} />;
}

export function PersonalizedMarkup({ item }) {
    // State
    const [open, toggleOpen] = useToggle(false);

    // Handle actions
    const handleClick = React.useCallback(
        (e) => {
            e.preventDefault();
            toggleOpen();
        },
        [toggleOpen]
    );

    return (
        <React.Fragment>
            <div>
                <div onClick={handleClick} className="personalized-control">
                    <Badge status="success" children="Personalized" />
                </div>
            </div>
            <Modal
                title="Personalized Options"
                open={open}
                onClose={toggleOpen}
                sectioned
            >
                <FieldsLiveView
                    fields={item?.fields || []}
                    personalizedPreview
                />
            </Modal>
        </React.Fragment>
    );
}

export function ImagWrap({ file }) {
    const id = file?.id;

    // Mutation
    const [genThumbnail] = useMutation(GEN_THUMBNAIL_IMAGE);

    // State
    const [url, setUrl] = useState(file?.thumbnailUrl || file?.url);

    useEffect(() => {
        if (file != null) {
            setUrl(file.thumbnailUrl || file.url);
        }
    }, [file]);

    // Handle action
    const handleGenThumbnail = useCallback(() => {
        if (id != null) {
            genThumbnail({
                variables: {
                    files: [id],
                    fileType: "id",
                },
            });
        }
    }, [id, genThumbnail]);

    return (
        <ImgContainer
            src={url || noImg}
            key={file?.id}
            onError={() => {
                if (file?.thumbnailUrl) {
                    fetch(file.thumbnailUrl)
                        .then((res) => {
                            if (res?.status === 404) {
                                handleGenThumbnail();
                            }
                        })
                        .catch(() => {});
                }
                setUrl(file?.url || file?.thumbnailUrl);
            }}
        />
    );
}
