import React, { useState, useCallback, useEffect } from "react";
import {
    Card,
    Button,
    ButtonGroup,
    TextField,
    FormLayout,
} from "@shopify/polaris";
import { size, filter, values } from "lodash";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

const Container = styled.div`
    .btns-wrap {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 3rem;
    }
`;

export const CarrierFormPolaris = ({
    onSubmit,
    loading,
    handleRedirect,
    value,
    isEdit,
}) => {
    // State
    const [basicFields, setBasicFields] = useState({
        name: null,
        code: null,
        url: null,
        options: null,
    });

    const [errors, setErrors] = useState({});

    // Get data
    useEffect(() => {
        if (value) {
            let { name, code, trackingUrl, storeShippingOption } = value;
            const options =
                storeShippingOption?.length > 0
                    ? storeShippingOption.join("\n")
                    : "";

            setBasicFields({
                name,
                code,
                url: trackingUrl,
                options,
            });
        }
    }, [value]);

    // Handle actions
    const validateField = useCallback((value, id) => {
        let error = null;
        let label = id.charAt(0).toUpperCase() + id.slice(1);

        if (!value || !value.length) {
            error = `${label} is required.`;
        }
        setErrors((prev) => ({ ...prev, [id]: error }));
    }, []);

    const handleInputChange = useCallback(
        (value, id) => {
            setBasicFields((prev) => ({ ...prev, [id]: value }));
            if (["name", "code"].includes(id)) {
                validateField(value, id);
            }
        },
        [validateField]
    );

    const handleSubmit = useCallback(() => {
        for (let [key, value] of Object.entries(basicFields)) {
            if (["name", "code"].includes(key)) {
                validateField(value, key);
            }
        }
        const count = size(filter(values(errors), (e) => e !== null));
        const { url, name, code, options } = basicFields;
        const input = {
            name,
            code,
            trackingUrl: url,
            options,
        };
        if (code && name && count === 0) {
            if (onSubmit) {
                onSubmit(input);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basicFields]);

    return (
        <Container>
            <Card sectioned>
                <FormLayout>
                    <FormLayout.Group>
                        <div>
                            <ComponentLabelPolaris label="Name" required />
                            <TextField
                                value={basicFields["name"]}
                                id="name"
                                error={errors["name"]}
                                onChange={handleInputChange}
                                placeholder="Enter carrier name"
                            />
                        </div>
                        <div>
                            <ComponentLabelPolaris label="Code" required />
                            <TextField
                                value={basicFields["code"]}
                                id="code"
                                error={errors["code"]}
                                onChange={handleInputChange}
                                placeholder="Enter carrier code"
                            />
                        </div>
                    </FormLayout.Group>
                    <div>
                        <ComponentLabelPolaris label="Url" />
                        <TextField
                            value={basicFields["url"]}
                            id="url"
                            error={errors["url"]}
                            onChange={handleInputChange}
                            placeholder="Enter carrier url"
                        />
                    </div>
                    {isEdit && (
                        <div>
                            <TextField
                                label="Mapping store shipping options"
                                placeholder="Enter store shipping options"
                                value={basicFields["options"]}
                                id="options"
                                onChange={handleInputChange}
                                multiline={4}
                                helpText={"Enter each store shipping per line"}
                            />
                        </div>
                    )}
                </FormLayout>
                <div className="btns-wrap">
                    <ButtonGroup>
                        <Button children="Cancel" onClick={handleRedirect} />
                        <Button
                            children="Save"
                            primary
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </ButtonGroup>
                </div>
            </Card>
        </Container>
    );
};
