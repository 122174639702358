import React, { useState, useCallback } from "react";
import { FormLayout, TextField, Button, ButtonGroup } from "@shopify/polaris";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";

const Container = styled.div`
  .btns_wrap {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const RejectDesignFormPolaris = (props) => {
  // Prop
  const { toggleShowModal, task, onSubmit, loading } = props;

  // State
  const [reason, setReason] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState({
    reason: null,
  });

  // Handle action
  const handleReasonValue = useCallback((value) => {
    let r = null;
    if (!value) {
      r = "Reason is required.";
    }
    setErrors((prev) => ({
      ...prev,
      reason: r,
    }));
  }, []);

  const handleReasonChange = useCallback((value) => {
    handleReasonValue(value);
    setReason(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttachmentsChange = useCallback((value) => {
    let fileIds = value && value.length > 0 ? value.map((file) => file.id) : [];
    if (fileIds?.length > 0) {
      setReason((v) => v === "" ? " " : v)
    }
    setAttachments(fileIds);
  }, []);

  const handleSubmit = useCallback(() => {
    handleReasonValue(reason);
    let input = {
      id: task?.id,
      rejectReason: reason,
      rejectResources: attachments,
    };

    if (reason) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason, attachments, task]);

  return (
    <Container>
      <FormLayout>
        <div>
          <ComponentLabelPolaris label="Reason" required />
          <TextField
            value={reason}
            onChange={handleReasonChange}
            multiline={2}
            error={errors.reason}
          />
        </div>
        <div>
          <ComponentLabelPolaris label="Attachments" />
          <MediaSelectorButtonPolaris
            multiple
            onChange={handleAttachmentsChange}
          />
        </div>
        <div className="btns_wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={toggleShowModal} />
            <Button
              children="Submit"
              primary
              onClick={handleSubmit}
              loading={loading}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
