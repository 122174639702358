import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { handleError } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const fulfillmentQuery = gql`
  query fulfillments {
    fulfillments {
      id
      name
      slug
    }
  }
`;

export const FulfillmentSelectPolaris = (props) => {
  const { data, loading, error } = useQuery(fulfillmentQuery);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  const nodes = data && data.fulfillments ? data.fulfillments : [];

  useEffect(() => {
    let { value, ignored = [] } = props;
    let formatData = [{ value: "All", label: "Merchbridge" }];
    if (data && data.fulfillments && data.fulfillments.length) {
      let newData = data.fulfillments.map((ff) => ({
        label: ff.name,
        value: ff.id,
        disabled: ignored.includes(ff.id),
      }));
      formatData = [...formatData, ...newData];
      if (props.onLoaded) {
        props.onLoaded(data.fulfillments);
      }
    }
    if (!value) {
      value = "All";
    }
    let matchedOptions = formatData.find((item) => item.value === value);
    setSelectedOptions(matchedOptions && [matchedOptions.value]);
    setInputValue(matchedOptions && matchedOptions.label);

    setOptions(formatData);
    setDeselectedOptions(formatData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props.value]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
      let firstSelected = _.head(selected);
      if (props.onChange) {
        props.onChange(
          firstSelected,
          nodes.find((n) => n.id === firstSelected)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, options]
  );

  const textField = (
    <Autocomplete.TextField
      label="Select a Fulfillment"
      labelHidden={true}
      placeholder="Select fulfillment"
      value={inputValue}
      onChange={handleInputValue}
      error={props.error}
    />
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {!props.labelHidden ? (
        <ComponentLabelPolaris label={"Select a Fulfillment"} required />
      ) : null}
      <Autocomplete
        options={options}
        onSelect={handleSection}
        selected={selectedOptions}
        textField={textField}
        loading={loading}
      />
    </>
  );
};
