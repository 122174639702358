import React, { useState, useCallback } from "react";
import { Spinner } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { LIST_CATEGORY } from "./CategoriesPolaris";
import { AutoCompletePolaris } from "../../shared/AutoCompletePolaris";

export const CategorySelectPolaris = ({ onChange, value }) => {
  // State
  const [data, setData] = useState([]);

  // Query
  const { loading, error } = useQuery(LIST_CATEGORY, {
    onCompleted: (res) => {
      handleDataChange(res);
    },
    fetchPolicy: "no-cache",
  });

  // Handle actions
  const handleDataChange = useCallback((data) => {
    let result = buildCategoryData(data?.productBaseCategories);
    setData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildCategoryData = useCallback((data) => {
    const result = [];
    const buildData = (data, prefix = "") => {
      if (!data || data.length === 0) return; 
      data.forEach((input) => {
        let item = {
          label: prefix ? `${prefix} ${input.name}` : input.name,
          value: input.id,
        };
        result.push(item);
        if (input?.children?.length) {
          buildData(input.children, prefix + "--");
        }
      });
    };
    buildData(data);
    return result;
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Spinner size="small" />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : (
        <AutoCompletePolaris
          options={data}
          value={value}
          onChange={onChange}
          label="Parent category"
          placeholder="Choose parent"
        />
      )}
    </React.Fragment>
  );
};
