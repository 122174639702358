import React, { Fragment } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../hooks/useToggle";
import BulkDeleteStore from "./components/BulkDeleteStore";

export const BulkActionStore = () => {
  // State
  const [open, toggleOpen] = useToggle(false);

  return (
    <Fragment>
      <Button children="Delete" destructive onClick={toggleOpen} />
      <BulkDeleteStore onClose={toggleOpen} open={open} />
    </Fragment>
  );
};
