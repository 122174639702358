import React, { useMemo } from "react";
import { ProductAssortmentPolaris } from "../../components/assortment/ProductAssortmentPolaris";
import { getPathFromRouter } from "../../helper";

export const ProductAssortmentPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return <ProductAssortmentPolaris path={path} />;
};
