import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppContext } from "../../../context";
import { PRODUCTS_USING_GEN2D_MOCKUPS } from "../../../graphql/queries";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  getParamByRole,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { Filter } from "./Filter";
import { Table } from "./Table";
import { TabsComp } from "./Tabs";

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "productBaseId",
  "tags",
  "collections",
  "personalized",
  "status",
];

const productTypeVal = {
  normal: false,
  personalized: true,
};

export function ProductFromPrintFile({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter != null) {
      const { productType, limit, paged, collections, tags } = initialFilter;
      const typeValue = productTypeVal[productType] || null;

      if (collections) {
        initialFilter.collections = [collections];
      } else {
        initialFilter.collections = null;
      }

      if (tags) {
        initialFilter.tags = [tags];
      } else {
        initialFilter.tags = null;
      }
      initialFilter.personalized = typeValue;

      let newLimit = Number(limit) || 10;
      initialFilter.limit = newLimit;

      let newPaged = paged
        ? convertToOffset(initialFilter.limit, Math.round(paged))
        : 0;
      initialFilter.offset = newPaged;

      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // Context
  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perProductFPrintFile")) || 20,
    offset: 0,
    search: "",
    productBaseId: null,
    tags: null,
    collections: null,
    personalized: null,
    status: null,
    ...initFilter,
  });
  const [aggregations, setAggregations] = useState({});
  // const countRef = useRef(false);

  // Queries
  const { data, loading, error, refetch } = useQuery(
    PRODUCTS_USING_GEN2D_MOCKUPS,
    {
      variables: {
        filter,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const [
    getProducts,
    { data: dataCount, refetch: refetchCount },
  ] = useLazyQuery(PRODUCTS_USING_GEN2D_MOCKUPS);

  useEffect(() => {
    let aggeration =
      dataCount?.productUsingGen2dMockups?.aggeration?.aggeration;
    setAggregations(aggeration);
  }, [dataCount]);

  useEffect(() => {
    getProducts({
      variables: {
        filter: {
          ...filter,
          status: null,
        },
      },
    });
  }, [filter, getProducts]);

  useEffect(() => {
    let { limit, offset, personalized, ...rest } = filter;
    let productType = null;
    if (personalized === false) {
      productType = "normal";
    } else if (personalized === true) {
      productType = "personalized";
    }

    let params = null;
    const paged = convertToPaged(limit, offset);

    params = convertObjectToParams({
      limit,
      paged,
      productType,
      ...rest,
    });

    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const redirectToCreateNew = useCallback(() => {
    history.push(`/${currentParam}/product-from-print-file/create`); //
  }, [history, currentParam]);

  const handleFilterChange = useCallback(
    ({ search, productBaseId, collections, tags, personalized, status }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.productBaseId, productBaseId) ||
          !isEqual(prev.collections, collections) ||
          !isEqual(prev.tags, tags) ||
          !isEqual(prev.personalized, personalized) ||
          !isEqual(prev.status, status)
        ) {
          return {
            ...prev,
            offset: 0,
            search,
            productBaseId,
            collections,
            tags,
            personalized,
            status,
          };
        }

        return prev;
      });
    },
    [],
  );
  const handlePagination = useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
    setCookie("perProductFPrintFile", limit, 100);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Page
      title="Product From Print File"
      fullWidth
      breadcrumbs={[{ url: `/${currentParam}/products`, content: "Products" }]}
      primaryAction={{
        content: "Create New",
        onAction: redirectToCreateNew,
      }}
    >
      {loadingMarkup}
      <Card sectioned>
        <Filter filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        <TabsComp
          aggregations={aggregations}
          defaultValue={filter.status}
          onChange={({ status }) =>
            setFilter((prev) => ({
              ...prev,
              status,
              offset: status !== prev.status ? 0 : prev.offset,
            }))
          }
        />
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.productUsingGen2dMockups?.nodes?.length > 0 ? (
          <Table
            filter={filter}
            setFilter={handlePagination}
            data={data}
            refetch={refetch}
            refetchCount={refetchCount}
            currentParam={currentParam}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Page>
  );
}
