import React from "react";
import { REPORT_ORDERS_PENDING_OR_PENDING_DESIGN } from "../../../graphql/queries";
import { ReportOrders } from "./ReportOrders";

// Report orders pending or pending design
export function ReportPOrPD({ filter }) {
  return (
    <ReportOrders
      title="Can't charge Orders"
      documentNode={REPORT_ORDERS_PENDING_OR_PENDING_DESIGN}
      filter={filter}
    />
  );
}
