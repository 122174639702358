import React from "react";
import { TiktokChannel } from "../../components/reports";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function TiktokChannelPage() {
  return (
    <WrapperPage>
      <TiktokChannel />
    </WrapperPage>
  );
}
