import React, { useState, useEffect } from "react";
import { DataTable } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { ExportAmazonActionsPolaris } from "./ExportAmazonActionsPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";

const Container = styled.div`
  padding-top: 0.6rem;
  .actions_wrap {
    display: flex;
    justify-content: flex-end;
    column-gap: 1.5rem;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .file-name_wrap {
    width: calc(25rem - 3.2rem);
    white-space: break-spaces;
  }
  .created-by_wrap {
    width: calc(20rem - 3.2rem);
    white-space: break-spaces;
  }
  .created-at_wrap {
    width: calc(25rem - 3.2rem);
    white-space: break-spaces;
  }
  .divisions_wrap {
    width: calc(20rem - 3.2rem);
    white-space: break-spaces;
  }
  .product-total_wrap {
    width: calc(10rem - 3.2rem);
    white-space: break-spaces;
  }
`;

export const TableExportAamzonPolaris = (props) => {
  const { data, filter, setFilter } = props;

  // State
  const [rows, setRows] = useState([]);

  // Get data
  useEffect(() => {
    let newRows =
      data &&
      data.amazonExports &&
      data.amazonExports.nodes &&
      data.amazonExports.nodes.length > 0
        ? data.amazonExports.nodes.map((item) => {
            // Created by
            let createdBy = item?.createdBy;
            let fullName = createdBy
              ? `${createdBy.firstName} ${createdBy.lastName}`
              : "";
            // Created at
            let createdAt = item?.createdAt;
            if (createdAt) {
              createdAt = moment(createdAt).format("LLL");
            }

            // Divisions
            let divisions = item?.divisions;
            let divisionsMarkup =
              divisions?.length > 0
                ? divisions.map((v) => v.title).join(", ")
                : null;

            // Product total
            let totalProducts = item?.totalProducts;

            return [
              <div className="file-name_wrap">
                <span>{item.title}</span>
              </div>,
              <div className="created-by_wrap">
                <p>{fullName}</p>
              </div>,
              <div className="created-at_wrap">
                <span>{createdAt}</span>
              </div>,
              <div className="divisions_wrap">
                <span>{divisionsMarkup}</span>
              </div>,
              <div className="product-total_wrap">
                <span>{totalProducts}</span>
              </div>,
              <div className="actions_wrap">
                <ExportAmazonActionsPolaris item={item} {...props} />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let { limit, offset } = filter;
  const total = get(data, "amazonExports.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "numeric"]}
        headings={[
          "File name",
          "Created by",
          "Created at",
          "Product divisions",
          "Product total",
          "Actions",
        ]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
