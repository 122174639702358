import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { CampaignProductBaseItemDrag } from "./CampaignProductBaseItemDrag";

const Container = styled.div`
  .ant-card {
    margin-bottom: 20px;
    border: 2px solid #e2e8f0;
    &.selected {
      border-color: #40a9ff;
      background-color: rgba(247, 249, 250, 1);
    }
    .ant-card-head {
      border-bottom: 0 none;
    }
  }
  .danger-btn {
    color: rgba(211, 69, 91, 1);
  }
`;
class CampaignProductBaseItems extends Component {
  constructor() {
    super();
    this.state = {
      value: [],
      isChangeDrag: false,
    };
  }

  componentDidMount() {
    let { value } = this.props;
    this.setState({
      value: value,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  movePBase = (dragIndex, hoverIndex) => {
    let { value } = this.state;
    [value[dragIndex], value[hoverIndex]] = [
      value[hoverIndex],
      value[dragIndex],
    ];
    this.setState(
      {
        value: [...value],
        isChangeDrag: !this.state.isChangeDrag,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange([...value]);
        }
      }
    );
  };

  render() {
    const {
      // value,
      onSelected,
      onDelete,
      selectedIndex,
      grouped,
      sku,
      onChangeValue,
      template,
      updatePrices,
      isEditProduct,
      noAction,
      productId,
    } = this.props;

    const { value, isChangeDrag } = this.state;

    // Drap and drop
    return (
      <Container>
        <DndProvider backend={HTML5Backend}>
          {value.map((v, index) => {
            let pBaseId = v && v.productBase && v.productBase.id;
            return (
              <CampaignProductBaseItemDrag
                productId={productId}
                isEditProduct={isEditProduct}
                template={template}
                noAction={noAction}
                sku={sku}
                updatePrices={updatePrices}
                key={index}
                value={v}
                grouped={grouped}
                selected={selectedIndex === pBaseId}
                index={index}
                pBaseId={pBaseId}
                onChange={(values) => {
                  if (this.props.onChange) {
                    value[index] = { ...value[index], ...values };
                    this.props.onChange(value);
                  }
                }}
                onDelete={() => {
                  if (onDelete) {
                    onDelete(index);
                  }
                }}
                onSelected={() => {
                  if (onSelected) {
                    onSelected(pBaseId);
                  }
                }}
                onChangeValue={(v) => {
                  onChangeValue(v);
                }}
                movePBase={this.movePBase}
                isChangeDrag={isChangeDrag}
              />
            );
          })}
        </DndProvider>
      </Container>
    );
  }
}

CampaignProductBaseItems.propTypes = {
  value: PropTypes.array,
  onSelected: PropTypes.func,
  onDelete: PropTypes.func,
  selectedIndex: PropTypes.string,
  grouped: PropTypes.bool,
  onChange: PropTypes.func,
  sku: PropTypes.string,
};

export default CampaignProductBaseItems;
