import React, { Component } from "react";
import { Button, notification, Modal } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";

export const UPDATE_STATUS_CLAIM = gql`
  mutation refundClaim($id: ID!) {
    refundClaim(id: $id) {
      id
      orderId
      type
      status
      order {
        id
        originId
      }
      comment
    }
  }
`;

class ActionRefund extends Component {
  state = {
    loading: false
  };

  render() {
    const { claim } = this.props;
    return (
      <Mutation
        mutation={UPDATE_STATUS_CLAIM}
        onCompleted={() => {
          this.setState({ loading: false });
          notification.success({ message: "Refund claim success!" });
        }}
        onError={err => {
          this.setState({ loading: false });
          notification.error({ message: err.toString() });
        }}
      >
        {refundClaim => (
          <div>
            <Button
              className="mx-1"
              onClick={() => {
                Modal.confirm({
                  title: `Are you sure to refund for order #${claim.order.id}?`,
                  onOk: () => {
                    refundClaim({ variables: { id: claim.id } });
                  },
                  centered: true
                });
              }}
            >
              Refund
            </Button>
          </div>
        )}
      </Mutation>
    );
  }
}

export default ActionRefund;
