import React, { useState, useCallback } from "react";
import { TextField, Button, ButtonGroup, FormLayout } from "@shopify/polaris";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";

const Container = styled.div`
  .btns_wrap {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ResendFormPolaris = (props) => {
  // Props
  const { toggleShowModal, onSubmit, loading } = props;

  // State
  const [trackingCode, setTrackingCode] = useState(null);
  const [note, setNote] = useState(null);
  const [errors, setErrors] = useState({
    trackingCode: null,
    note: null,
  });

  // Handle action
  const handleSubmit = useCallback(() => {
    handleTrackingCodeValue(trackingCode);
    handleNoteValue(note);
    if (trackingCode && note) {
      let input = {
        newCode: trackingCode,
        note,
      };
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingCode, note]);

  const handleTrackingCodeValue = useCallback((value) => {
    let t = null;
    if (!value) {
      t = "Tracking code is required.";
    }
    setErrors((prev) => ({ ...prev, trackingCode: t }));
  }, []);

  const handleNoteValue = useCallback((value) => {
    let n = null;
    if (!value) {
      n = "Note is required.";
    }
    setErrors((prev) => ({ ...prev, note: n }));
  }, []);

  const handleTrackingCodeChange = useCallback((value) => {
    setTrackingCode(value);
    handleTrackingCodeValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNoteChange = useCallback((value) => {
    setNote(value);
    handleNoteValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormLayout>
        <div>
          <ComponentLabelPolaris label="Tracking code" required />
          <TextField
            value={trackingCode}
            onChange={handleTrackingCodeChange}
            error={errors.trackingCode}
          />
        </div>
        <div>
          <ComponentLabelPolaris label="Note" required />
          <TextField
            value={note}
            onChange={handleNoteChange}
            error={errors.note}
            multiline={3}
          />
        </div>
        <div className="btns_wrap">
          <ButtonGroup>
            <Button children={"Cancel"} onClick={toggleShowModal} />
            <Button
              children={"Submit"}
              onClick={handleSubmit}
              primary
              loading={loading}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
