import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { AutoComplete, Input, notification, Tag, Button } from "antd";
import { ApolloConsumer } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";
import styled from "styled-components";
import _ from "lodash";
import QuickAddCollection from "./QuickAddCollection";
import { DEFAULT_COLLECTIONS_LIVE } from "../../variable";

const COLLECTIONS_QUERY = gql`
  fragment collection on Collection {
    id
    name
    private
  }
  query collections($filter: CollectionFilter!) {
    collections(filter: $filter) {
      total
      nodes {
        ...collection
        children {
          ...collection
          children {
            ...collection
            children {
              ...collection
              children {
                ...collection
              }
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  .labelButtons-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .labelButtons-container {
    display: block;
    padding: 2rem 1rem 1rem 1.5rem;
    border: 1px solid #c3cbd6;
    margin: 1rem 0 1.5rem;
    border-radius: 3px;
    position: relative;
    label {
      display: inline-block;
      position: absolute;
      left: 15px;
      top: -10px;
      padding: 0 10px;
      background: #fff;
    }
  }
`;

class CollectionsAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      loading: false,
      result: [],
      currentIds: [],
    };
    this.searchDebounce = _.debounce(this.onSearch, 500);
    this.onSearch = this.onSearch.bind(this);
  }
  onSearch = (client, search) => {
    this.setState({ loading: true }, async () => {
      try {
        const variables = {
          filter: {
            search: search,
            limit: search ? 500 : 20,
            offset: 0,
          },
        };
        const res = await client.query({
          query: COLLECTIONS_QUERY,
          fetchPolicy: "network-only",
          variables,
        });

        const result =
          res.data && res.data.collections && res.data.collections.nodes
            ? res.data.collections.nodes
            : [];

        this.setState({
          loading: false,
          result: [...result],
        });
      } catch (e) {
        this.setState(
          {
            loading: false,
          },
          () => {
            notification.error({ message: handleError(e.toString()) });
          }
        );
      }
    });
  };

  handleSelect = (collection) => {
    const { value } = this.props;
    if (this.props.onChange) {
      this.props.onChange([...value, collection]);
    }
  };

  renderOptions = (result, level = 0) => {
    const { value } = this.props;
    let options = [];
    result
      .filter((r) => !value.find((v) => v.id === r.id))
      .forEach((item, index) => {
        options.push(
          <AutoComplete.Option value={item.id} key={item.id}>
            <span
              style={{ marginLeft: `${level * 10}px` }}
              className={`level level-${level}`}
            >
              {item.name}
            </span>
          </AutoComplete.Option>
        );
        if (item.children && item.children.length) {
          options = [
            ...options,
            ...this.renderOptions(item.children, level + 1),
          ];
        }
      });
    let { loading, search } = this.state;
    let { disabledQuickAdd } = this.props;
    if (
      !loading &&
      search &&
      (result.length === 0 || !this.resultContains(result, search)) &&
      !disabledQuickAdd
    ) {
      options.push(
        <AutoComplete.Option key={"add-new"} value={"add new"} className="p-0">
          <QuickAddCollection
            onCompleted={(collection) => {
              this.setState(
                {
                  search: "",
                  result: [],
                },
                () => {
                  this.handleSelect(collection);
                }
              );
            }}
            value={search}
          />
        </AutoComplete.Option>
      );
    }
    const tmp = new Map(options.map((item) => [item.key, item]));
    options = Array.from(tmp.values());
    const addNewIndex = options.findIndex(({ key}) => key === "add-new");
    if (addNewIndex > -1) {
      [options[0], options[addNewIndex]] = [options[addNewIndex], options[0]]
    }
    return options;
  };

  findById = (result, id) => {
    for (let i = 0; i < result.length; i++) {
      if (result[i].id === id) {
        return result[i];
      }
      if (result[i].children && result[i].children.length) {
        const found = this.findById(result[i].children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  resultContains = (result, search) => {
    if (!result || result.length === 0) {
      return false;
    }
    for (let i = 0; i < result.length; i++) {
      const item = result[i];
      if (item.name.toLowerCase() === search.toLowerCase()) {
        return true;
      }
      if (this.resultContains(item.children, search)) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    let defaultCollections = DEFAULT_COLLECTIONS_LIVE;
    const lBMarkup = [];
    for (let [id, name] of Object.entries(defaultCollections)) {
      let item = { id, name };
      lBMarkup.push(item);
    }
    this.setState({
      lBMarkup,
    });
  }

  handleClickLabelBtn = (item) => {
    let { value } = this.props;
    let { lBMarkup } = this.state;
    let currentIds = value && value.length > 0 ? value.map((i) => i.id) : [];
    let isTM =
      lBMarkup && lBMarkup.length > 0
        ? lBMarkup.find((i) => i.name === "TM")
        : null;
    let isNotTM =
      lBMarkup && lBMarkup.length > 0
        ? lBMarkup.find((i) => i.name === "NO-TM")
        : null;

    if (currentIds.includes(item.id)) {
      if (this.props.onChange) {
        this.props.onChange(value.filter((i) => i.id !== item.id));
      }
    } else {
      let newValue = value;
      if (item.id === isTM.id) {
        newValue = newValue.filter((i) => i.id !== isNotTM.id);
      }
      if (item.id === isNotTM.id) {
        newValue = newValue.filter((i) => i.id !== isTM.id);
      }
      if (this.props.onChange) {
        this.props.onChange([...newValue, item]);
      }
    }
  };

  render() {
    const { value, isAssign } = this.props;
    const { result, loading, search, lBMarkup } = this.state;
    let currentIds = value && value.length > 0 ? value.map((i) => i.id) : [];

    return (
      <Container>
        {isAssign && (
          <div className="labelButtons-container">
            <label>Popular Collections</label>
            <div className="labelButtons-wrap">
              {lBMarkup && lBMarkup.length > 0
                ? lBMarkup.map((i, index) => (
                    <Button
                      key={index}
                      size="small"
                      onClick={() => this.handleClickLabelBtn(i)}
                      type={currentIds.includes(i.id) ? "primary" : "default"}
                    >
                      {i.name}
                    </Button>
                  ))
                : null}
            </div>
          </div>
        )}

        <ApolloConsumer>
          {(client) => {
            this.client = client;
            return (
              <AutoComplete
                style={{ width: "100%" }}
                onSelect={(selected) => {
                  if ("add new" === selected) {
                    return;
                  }
                  const collection = this.findById(result, selected);
                  this.handleSelect(collection, 0);
                  this.setState({
                    search: "",
                  });
                }}
                filterOption={false}
                value={search}
                dataSource={this.renderOptions(result)}
                onSearch={(s) => {
                  if (this.state.search !== s) {
                    this.setState({ search: s, loading: true }, () => {
                      this.searchDebounce(client, s);
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
              >
                <Input.Search
                  onFocus={(e) => {
                    this.searchDebounce(client, e.target.value);
                  }}
                  placeholder={"Search for collection"}
                  prefix={<LegacyIcon type={loading ? "loading" : "search"} />}
                />
              </AutoComplete>
            );
          }}
        </ApolloConsumer>
        {/* {!loading &&
          search &&
          (result.length === 0 || !this.resultContains(result, search)) &&
          !disabledQuickAdd && (
            <QuickAddCollection
              onCompleted={(collection) => {
                this.setState(
                  {
                    search: "",
                    result: [],
                  },
                  () => {
                    this.handleSelect(collection);
                  }
                );
              }}
              value={search}
            />
          )} */}
        <div>
          {value.map((collection, index) => (
            <Tag
              style={{ marginTop: 8, marginRight: 8 }}
              closable={true}
              onClose={() => {
                value.splice(index, 1);
                if (isAssign) {
                  this.searchDebounce(this.client, "");
                }
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}
              key={collection.id}
            >
              {collection.name}
            </Tag>
          ))}
        </div>
      </Container>
    );
  }
}
CollectionsAutoComplete.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabledQuickAdd: PropTypes.bool,
};

export default CollectionsAutoComplete;
