import { Spinner } from "@shopify/polaris";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { getDesignPositionName, sortDesignPosition } from "../../helper";
import { UPLOAD_FOLDER } from "../../variable";
import { checkDimensions } from "../order/_utils";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import SuspenseComp from "../shared/SuspenseComp";
import MediaSelectorButton from "../supplier/MediaSelectorButton";

const DimensionsNotify = React.lazy(() =>
  import("../order/components/DimensionsNotify"),
);

const Container = styled.div`
  .description {
    font-size: 12px;
    word-wrap: break-word;
  }

  .title {
    font-weight: bold;
  }
`;
class ProductPrintFiles extends Component {
  state = {
    disableSubmit: false,
    dimensions: {},
    currentDesignId: {},
    checkingDimensions: {},
  };

  componentDidMount() {
    const { value } = this.props;
    if (Array.isArray(value) && value?.length > 0) {
      const currentDesignId = {};
      for (let i = 0; i < value.length; i++) {
        const item = value[i];
        if (!item || typeof item !== "object") continue;
        if (!item.designPosition || !item.designPosition.id) continue;
        const fileId = item.file?.id || null;

        currentDesignId[i] = fileId;
      }
      this.setState({ currentDesignId });
    }
  }

  handleChange = (p, index) => (files) => {
    const { value, isProductForm, onChange } = this.props;
    p.fileId = files.length ? files[0].id : isProductForm ? "" : null;
    value[index] = p;
    onChange && onChange(value);

    let fileId = null;
    const dimensions = {};
    if (files?.length > 0) {
      fileId = files[0] ? files[0].id : null;
    } else {
      dimensions[index] = null;
    }

    this.setState((p) => ({
      ...p,
      currentDesignId: {
        ...p.currentDesignId,
        [index]: fileId,
      },
      dimensions: {
        ...p.dimensions,
        ...dimensions,
      },
    }));
  };

  handleCheckDimensions = (index) => (value) => {
    if (value === true) {
      this.setState({ disableSubmit: false });
      return;
    }

    this.setState((p) => {
      const res = checkDimensions({
        dimensions: value,
        index,
        designId: p.currentDesignId,
      });

      return {
        ...p,
        dimensions: {
          ...p.dimensions,
          ...res,
        },
      };
    });
  };

  handleCheckingDimensions = (index) => (value) => {
    this.setState((p) => ({
      checkingDimensions: { ...p.checkingDimensions, [index]: value },
    }));
  };

  render() {
    const { value, uploadFolder } = this.props;
    if (!value || value.length === 0) {
      return <p>No design positions defined.</p>;
    }
    // const index = value.findIndex(
    //   (v) => v.designPosition.name.toLowerCase() === "master",
    // );
    // if (index > 0) {
    //   [value[0], value[index]] = [value[index], value[0]];
    // }

    const {
      handleChange,
      handleCheckDimensions,
      handleCheckingDimensions,
    } = this;
    const { checkingDimensions, dimensions } = this.state;

    return (
      <Container>
        <Row gutter={16}>
          {sortDesignPosition(value).map((p, index) => {
            if (!p) return null;
            const designPosition = p.designPosition;
            const { name, description, artworkGuidelines, image, displayName } =
              designPosition || {};

            const currentMsg = checkingDimensions[index];
            const currentDimension = dimensions[index];
            const dpName = getDesignPositionName(designPosition);
            return (
              <Col
                lg={{ span: 24 }}
                xl={{
                  span: name.toLowerCase() === "master" ? 24 : 12,
                }}
                key={index}
                className="mb-3"
              >
                <div className={"title"}>{dpName}</div>
                <MediaSelectorButton
                  isPrintFile={true}
                  prefix="print_files/"
                  folder={
                    uploadFolder ? uploadFolder : UPLOAD_FOLDER.PRINT_FILES
                  }
                  value={p.file ? [p.file] : []}
                  // onChange={(files) => {
                  //   p.fileId = files.length
                  //     ? files[0].id
                  //     : isProductForm
                  //     ? ""
                  //     : null;
                  //   value[index] = p;
                  //   if (this.props.onChange) {
                  //     this.props.onChange(value);
                  //   }
                  // }}
                  listType={"picture-card"}
                  multiple={false}
                  buttonType={"link"}
                  singleUpload={true}
                  placeholderImage={image}
                  onChange={handleChange(p, index)}
                  isCompareDesign
                  designPosition={designPosition}
                  onCheckDimensions={handleCheckDimensions(index)}
                  onCheckingDimensions={handleCheckingDimensions(index)}
                />
                <SuspenseComp fallback={<Spinner size="small" />}>
                  <DimensionsNotify
                    loading={currentMsg}
                    msg={currentDimension?.message}
                    type={currentDimension?.type}
                  />
                </SuspenseComp>
                <div className={"description"}>{description}</div>
                {artworkGuidelines && artworkGuidelines.length ? (
                  <div>
                    <strong style={{ display: "inline-block" }}>
                      Artguides:{" "}
                    </strong>
                    {artworkGuidelines.map((ag, idx) => (
                      <div key={`artworkGuidelines-${idx}`}>
                        {ag.file && (
                          <CustomLinkPolaris
                            href={`${ag.file.url || ag.file.thumbnailUrl}`}
                            style={{ wordBreak: "break-all" }}
                          >
                            {` ${ag.file.name} `}
                          </CustomLinkPolaris>
                        )}
                        {idx !== artworkGuidelines.length - 1 ? "|" : ""}
                      </div>
                    ))}
                  </div>
                ) : null}
                {value.length > 1 && name.toLowerCase() === "master" && (
                  <div className={"title"}>Or</div>
                )}
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }
}

ProductPrintFiles.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  productBase: PropTypes.object,
  uploadFolder: PropTypes.string,
};

const ProductPrintFilesComp = React.forwardRef((props, ref) => (
  <ProductPrintFiles {...props} ref={ref} />
));
export default ProductPrintFilesComp;
