import React, { useState, useLayoutEffect, useCallback } from "react";
import { DataTable, Toast } from "@shopify/polaris";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { CarrierActionsPolaris } from "./CarrierActionsPolaris";

const Container = styled.div`
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
    width: calc(18rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
    margin-left: auto;
  }
  .name-wrap,
  .code-wrap {
    width: calc(17rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .url-wrap {
    width: calc(40rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const CarriersDataTablePolaris = ({ data, refetch }) => {
  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 0,
  });

  // Handle actions
  useLayoutEffect(() => {
    const { limit, offset } = filter;
    let newRows =
      data?.carriers?.length > 0
        ? data.carriers.map((item) => {
            return [
              <div className="name-wrap">
                <span>{item?.name}</span>
              </div>,
              <div className="code-wrap">
                <span>{item?.code}</span>
              </div>,
              <div className="url-wrap">
                <span>{item?.trackingUrl}</span>
              </div>,
              <div className="actions-wrap">
                <CarrierActionsPolaris
                  item={item}
                  toggleActive={toggleActive}
                  refetch={refetch}
                  onNotificationChange={({ message, isError }) =>
                    setNotification({ message, isError })
                  }
                />
              </div>,
            ];
          })
        : [];
    newRows = newRows?.length > 0 ? newRows.slice(offset, offset + limit) : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  const handleFilterChange = useCallback((offset, limit) => {
    setFilter({ limit, offset });
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  let { limit, offset } = filter;
  const total = data?.carriers?.length;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handleFilterChange,
    total,
  };

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={5000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <DataTable
        headings={["Name", "Code", "Url", "Actions"]}
        columnContentTypes={["text", "text", "text", "numeric"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={<PaginationPolaris aggregation={aggregation} />}
      />
    </Container>
  );
};
