import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Avatar, Badge, Toast } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { SellerActionsPolaris } from "./SellerActionsPolaris";

const Container = styled.div`
  overflow: hidden;
  @media (min-width: 992px) {
    .Polaris-DataTable--condensed tr {
      th {
        border-top: 1px solid #dfe5ed;
      }
      th:last-child,
      td:last-child {
        right: 0;
        position: sticky;
        z-index: 2;
        background: #fff;
        :after {
          box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
          position: absolute;
          top: 0;
          bottom: -1px;
          width: 30px;
          transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
          content: "";
          pointer-events: none;
          left: 0;
          transform: translateX(-100%);
        }
      }
    }
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .actions-wrap {
    display: flex;
    justify-content: flex-end;
    width: calc(14rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const DataTableSellerPolaris = ({
  data,
  filter,
  setFilter,
  refetch,
  urlEdit,
}) => {
  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  useEffect(() => {
    let newRows =
      data.users.nodes.map((user) => {
        // Avatar
        let avatar = user?.avatar?.url;
        let charAtFN = user?.firstName?.charAt(0);
        let charAtLN = user?.lastName?.charAt(0);

        // Status
        let status = user?.status;

        // Team
        let teamName = get(user, "teamUser.team.name", "");
        let teamId = get(user, 'teamUser.team.id', '');
        if (!teamName) {
          teamName = get(user, "teams[0].name", "");
          teamId = get(user, 'teams[0].id', '');
        }

        return [
          <div className="avatar-wrap">
            <Avatar source={avatar} initials={charAtFN || charAtLN} />
          </div>,
          <div className="name-wrap">
            <span>
              {user?.firstName} {user?.lastName}
            </span>
          </div>,
          <div className="email-wrap">
            <span>{user?.email}</span>
          </div>,
          <span className="team-id-wrap">
            <span>{teamId}</span>
          </span>,
          <div className="team-name-wrap">
            <span>{teamName}</span>
          </div>,
          // <div className="role-wrap">{role && <Badge children={role} />}</div>,
          <div className="status-wrap">
            <Badge
              children={status ? "Active" : "Inactive"}
              status={status ? "success" : "critical"}
            />
          </div>,
          <div className="phone-wrap">
            <span>{user?.phone}</span>
          </div>,
          <div className="address-wrap">
            <span>{user?.address}</span>
          </div>,
          <div className="actions-wrap">
            <SellerActionsPolaris
              item={user}
              urlEdit={urlEdit}
              refetch={refetch}
              toggleActive={toggleActive}
              onNotificationChange={({ message, isError }) =>
                setNotification(() => ({ message, isError }))
              }
            />
          </div>,
        ];
      }) || [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handle action
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Variable
  let { limit, offset } = filter;
  const total = get(data, "users.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "Avatar",
          "Name",
          "Email",
          "Team ID",
          "Team Name",
          // "Role",
          "Status",
          "Phone",
          "Address",
          "Actions",
        ]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
