import React, { Component } from "react";
import ReactDOM from "react-dom";
import PageTitle from "../../components/shared/PageTitle";
import styled from "styled-components";
import { CaretDownOutlined } from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Table,
  Modal,
  Carousel,
  Button,
  Popover,
  Skeleton,
  Popconfirm,
  notification,
  // Card,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Query, Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import {
  handleError,
  setCookie,
  getCookie,
  convertStringToObject,
  convertObjectToParams,
  getDomainFromUrl,
} from "../../helper";
import AssignProduct from "../../components/seller/AssignProduct";
import AssignProductButton from "../../components/shared/AssignProductButton";
import { ApolloConsumer } from "@apollo/react-hooks";
import ProductThumbnailImage from "../../components/shared/ProductThumbnailImage";
import DeleteProductCrawlItemButton from "../../components/seller/DeleteProductCrawlItemButton";
import CrawlTitle from "../../components/seller/CrawlTitle";
import AssignProductTemplate from "../../components/templates/AssignProductTemplate";
import AssignTemplateButton from "../../components/templates/AssignTemplateButton";
import {
  DEFAULT_PRODUCT_LIST_FILTER,
  LIST_SELLER_PRODUCTS_QUERY,
} from "./Products";
import history from "../../history";
import _ from "lodash";
import ModalImageClaim from "../../components/seller/ModalImageClaim";

import moment from "moment";
import { Card } from "@shopify/polaris";
import { FilterAssortmentPolaris } from "../../components/assortment/FilterAssortmentPolaris";
import ScreenOptionsFA from "../../components/assortment/ScreenOptionsFA";
import { SCREEN_OPTIONS_PA } from "../../variable";

const Wrapper = styled.div`
  margin-top: -1rem;
  @media (min-width: 640px) {
    margin-top: -1.5rem;
  }
`;
const Container = styled.div`
  .filter-container {
    display: flex;
    margin-bottom: 20px;
  }
  .actions {
    button {
      margin-left: 10px;
    }
  }
  .assortment-actions {
    margin-bottom: 10px;
  }
  .similar-products {
    display: flex;
    font-size: 80%;
    .similar-product {
      text-align: center;
      max-width: 300px;
    }
  }
  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row:hover {
    background: transparent;
  }
  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0 none;
  }
  .ant-table-tbody {
    .ant-table-expanded-row {
      td {
        border-bottom: 1px solid #e2e8f0;
      }
    }
  }
  .ant-table-pagination-right {
    width: 100%;
    display: flex;
    padding: 15px;
    margin: 0 !important;
    .ant-pagination-total-text {
      margin-left: 0px;
      margin-right: auto;
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation deleteCrawlItemSelected($id: [ID]!) {
    deleteCrawlItemSelected(id: $id)
  }
`;

const LOAD_QUERY = gql`
  query productAssortment($filter: ProductAssortmentFilter) {
    productAssortment(filter: $filter) {
      total
      nodes {
        id
        title
        url
        niche
        source
        createdAt
        author {
          id
          firstName
          lastName
        }
        images {
          id
          file {
            id
            url
            thumbnailUrl
          }
        }
        similarProducts {
          id
          distance
          product {
            id
            title
            images {
              id
              productBaseId
              file {
                id
                thumbnailUrl
                url
              }
            }
          }
        }
        similarItems {
          id
          distance
          similarItem {
            id
            title
            images {
              id
              file {
                id
                thumbnailUrl
                url
              }
            }
          }
        }
      }
    }
  }
`;

class ProductAssortment extends Component {
  state = {
    previewImage: null,
    selectedRows: [],
    showAssignProduct: false,
    showAssignProductSingle: false,
    showAssignProductTemplate: false,
    currentPage: 1,
    filter: {
      search: null,
      limit: +getCookie("perPageAssortment") || 30,
      offset: 0,
      authorId: null,
      createdTime: null,
      ...convertStringToObject(history.location.search),
    },
    visibleScreenOptions: false,
    optionsChecked: [],
  };

  typingTimeoutRef = React.createRef();

  componentDidMount() {
    this.setState({
      widthTable: ReactDOM.findDOMNode(this).offsetWidth,
    });

    // Screen options.
    let id = localStorage.getItem("userId");
    let options = getCookie(`screenOption_ProductAssort_${id}`);
    if (options) {
      options = options.split(",");
    } else {
      options = SCREEN_OPTIONS_PA;
    }
    this.setState({
      userId: id,
      optionsChecked: options,
    });
  }

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      if (
        !_.isEqual(nextState.filter.domain, this.state.filter.domain) ||
        !_.isEqual(nextState.filter.type, this.state.filter.type) ||
        !_.isEqual(nextState.filter.search, this.state.filter.search)
      ) {
        nextState.filter.offset = 0;
      }
      const { createdTime, ...rest } = nextState.filter;
      const params = convertObjectToParams(rest);
      history.push(history.location.pathname + "?" + params);
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    let { optionsChecked, userId } = this.state;
    if (prevState.optionsChecked !== optionsChecked) {
      setCookie(`screenOption_ProductAssort_${userId}`, optionsChecked, 100);
    }
  }

  handleRemoveItems = (client, selected = []) => {
    try {
      const cache = client.readQuery({
        query: LOAD_QUERY,
        variables: {
          filter: this.state.filter,
        },
      });
      this.setState({ selectedRows: [] });

      client.writeQuery({
        query: LOAD_QUERY,
        variables: {
          filter: this.state.filter,
        },
        data: {
          ...cache,
          productAssortment: {
            ...cache.productAssortment,
            total: cache.productAssortment.total - selected.length,
            // nodes: cache.productAssortment.nodes.filter(
            //   (n) => !selected.find((i) => i.id === n.id)
            // ),
          },
        },
      });
      client.resetStore();
    } catch (_) {
      // console.log(e);
    }
  };

  handleAddProductsToCache = (client, products) => {
    try {
      const variables = {
        filter: DEFAULT_PRODUCT_LIST_FILTER,
      };
      const cache = client.readQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
      });
      client.writeQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
        data: {
          ...cache,
          products: {
            ...cache.products,
            total: cache.products.total + 1,
            nodes: [...products, ...cache.products.nodes],
          },
        },
      });
    } catch (_) {
      // console.log(e);
    }
  };

  // timer = null;
  // handleResize = () => {
  //   this.setState({
  //     widthTable: ReactDOM.findDOMNode(this).offsetWidth,
  //   });
  // };

  // componentDidUpdate() {
  //   window.addEventListener("resize", this.handleResize);
  //   this.timer = setTimeout(() => {
  //     let itemExpand = document.getElementsByClassName("inner-expand");
  //     for (let i = 0; i < itemExpand.length; i++) {
  //       if (
  //         this.state.widthTable > itemExpand[i].getBoundingClientRect().width
  //       ) {
  //         itemExpand[i].style.width = `${this.state.widthTable}px`;
  //       }
  //     }
  //   }, 1000);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleResize);
  //   if (this.timer) {
  //     clearTimeout(this.timer);
  //   }
  // }

  handleScreenOptions = () => {
    this.setState({
      visibleScreenOptions: !this.state.visibleScreenOptions,
    });
  };

  render() {
    const { filter, visibleScreenOptions, optionsChecked } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRows.map((r) => r && r.id),
      onChange: (_, selectedRows) => {
        this.setState({
          selectedRows,
        });
      },
    };
    const columns = [
      {
        title: "Image",
        dataIndex: "images",
        width: 250,
        key: "image",
        render: (_, record) => (
          <ProductThumbnailImage limit={1} product={record} isSpecial />
        ),
      },
      {
        title: "Product",
        dataIndex: "title",
        width: 500,
        key: "product",
        render: (_, record) => (
          <ApolloConsumer>
            {(client) => (
              <CrawlTitle
                onChange={(title) => {
                  const cache = client.readQuery({
                    query: LOAD_QUERY,
                    variables: {
                      filter,
                    },
                  });
                  client.writeQuery({
                    query: LOAD_QUERY,
                    variables: {
                      filter,
                    },
                    data: {
                      ...cache,
                      productAssortment: {
                        ...cache.productAssortment,
                        nodes: cache.productAssortment.nodes.map((node) => {
                          if (node.id === record.id) {
                            node = { ...node, title };
                          }
                          return node;
                        }),
                      },
                    },
                  });
                }}
                value={record}
              />
            )}
          </ApolloConsumer>
        ),
      },
      {
        title: "Origin Domain",
        dataIndex: "url",
        width: 120,
        key: "originDomain",
        render: (_, record) => {
          let output = "";
          if (record && record.url) {
            let originUrl = record.url;
            if (
              !originUrl.includes(".jpg") &&
              !originUrl.includes(".png") &&
              !originUrl.includes(".gif")
            ) {
              output = getDomainFromUrl(originUrl);
            }
          }
          if (output.length > 0) {
            return <span>{output}</span>;
          }
          return null;
        },
      },
      {
        title: "Niche",
        dataIndex: "niche",
        width: 100,
        key: "niche",
      },
      {
        title: "Source",
        dataIndex: "source",
        width: 120,
        key: "source",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        width: 130,
        key: "createdAt",
        render: (text) => {
          let createdAt = moment(text).format("YYYY-MM-DD HH:mm:ss");
          return <span>{createdAt}</span>;
        },
      },
      {
        title: "Author",
        dataIndex: "author",
        width: 200,
        key: "author",
        render: (text) => {
          if (text) {
            let fullName = `${text.firstName ? text.firstName : ""} ${
              text.lastName ? text.lastName : ""
            }`;
            return <span>{fullName}</span>;
          } else {
            return "";
          }
        },
      },
      {
        title: "Actions",
        width: 320,
        key: "actions",
        render: (_, record) => (
          <div className={"actions"}>
            <ApolloConsumer>
              {(client) => (
                <div style={{ display: "flex" }}>
                  <AssignTemplateButton
                    value={record}
                    onCompleted={() => {
                      this.handleRemoveItems(client, [record]);
                    }}
                  />
                  <AssignProductButton
                    onCompleted={() => {
                      this.handleRemoveItems(client, [record]);
                    }}
                    value={record}
                  />
                  <DeleteProductCrawlItemButton
                    value={record}
                    onCompleted={() => {
                      this.handleRemoveItems(client, [record]);
                    }}
                  />
                </div>
              )}
            </ApolloConsumer>
          </div>
        ),
      },
    ];

    let styleBtnActive = "";
    let show = "";
    if (visibleScreenOptions) {
      styleBtnActive = "btn-active";
      show = "show";
    }

    return (
      <Wrapper>
        <ScreenOptionsFA
          defaultValue={optionsChecked}
          className={`screen-options ${show}`}
          onChange={(v) => {
            this.setState({
              optionsChecked: v,
            });
          }}
        />
        <Button
          onClick={this.handleScreenOptions}
          className={`block btn-screen-options ${styleBtnActive}`}
          icon={visibleScreenOptions ? <UpOutlined /> : <DownOutlined />}
        >
          Screen Options
        </Button>
        <ApolloConsumer>
          {(client) => (
            <Container>
              <PageTitle title={"Product Assortment"} />
              <Card sectioned>
                <FilterAssortmentPolaris
                  filter={filter}
                  onChange={({ search, type, domain, authorId, createdTime }) =>
                    this.setState({
                      filter: {
                        ...filter,
                        search,
                        type,
                        domain,
                        authorId,
                        createdTime,
                      },
                    })
                  }
                />
              </Card>
              <Card bodyStyle={{ padding: 0 }}>
                {/* <div className={"p-4"}>
                <Input.Search
                  defaultValue={filter.search}
                  onSearch={(s) => {
                    this.setState({
                      filter: {
                        ...filter,
                        search: s,
                        offset: 0,
                      },
                    });
                  }}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (this.typingTimeoutRef.current) {
                      clearTimeout(this.typingTimeoutRef.current);
                    }
                    this.typingTimeoutRef.current = setTimeout(() => {
                      this.setState({
                        filter: {
                          ...this.state.filter,
                          search: value ? value : null,
                          offset: 0,
                        },
                      });
                    }, 500);
                  }}
                  placeholder={"Search..."}
                />
              </div> */}
                {this.state.selectedRows.length > 0 && (
                  <div className="px-4 p4-3 my-4">
                    <Button.Group>
                      <Button>{`${this.state.selectedRows.length} selected`}</Button>
                      <Popover
                        title={"Assign Product"}
                        placement="bottom"
                        onVisibleChange={(bool) =>
                          this.setState({ showAssignProduct: bool })
                        }
                        visible={this.state.showAssignProduct}
                        trigger={["click"]}
                        content={
                          <AssignProduct
                            onCompleted={(products) => {
                              this.setState(
                                { showAssignProduct: false },
                                () => {
                                  this.handleRemoveItems(
                                    client,
                                    this.state.selectedRows
                                  );
                                  this.handleAddProductsToCache(
                                    client,
                                    products
                                  );
                                }
                              );
                            }}
                            value={this.state.selectedRows}
                          />
                        }
                      >
                        <Button>
                          Assign <CaretDownOutlined />
                        </Button>
                      </Popover>
                      <Popover
                        title={"Assign Product use Template"}
                        onVisibleChange={(bool) => {
                          this.setState({ showAssignProductTemplate: bool });
                        }}
                        visible={this.state.showAssignProductTemplate}
                        content={
                          <AssignProductTemplate
                            onCompleted={(products) => {
                              this.setState(
                                { showAssignProductTemplate: false },
                                () => {
                                  this.handleRemoveItems(
                                    client,
                                    this.state.selectedRows
                                  );
                                  this.handleAddProductsToCache(
                                    client,
                                    products
                                  );
                                }
                              );
                            }}
                            value={this.state.selectedRows}
                          />
                        }
                        placement="bottom"
                        trigger={["click"]}
                      >
                        <Button>
                          Assign use template <LegacyIcon type={"caret-down"} />
                        </Button>
                      </Popover>
                      <Mutation
                        mutation={DELETE_PRODUCT}
                        onCompleted={() => {
                          if (this.refetch) this.refetch();
                          this.setState({ selectedRows: [] });
                          notification.success({
                            message: "Delete product success!",
                          });
                        }}
                        onError={(err) => {
                          notification.error({ message: err.toString() });
                        }}
                      >
                        {(deleteProduct) => (
                          <Popconfirm
                            placement="top"
                            title="Are you sure to delete this products?"
                            onConfirm={() => {
                              deleteProduct({
                                variables: {
                                  id: this.state.selectedRows
                                    .filter(Boolean)
                                    .map((item) => item.id),
                                },
                              });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button>Delete</Button>
                          </Popconfirm>
                        )}
                      </Mutation>
                    </Button.Group>
                  </div>
                )}

                <Query
                  fetchPolicy="network-only"
                  query={LOAD_QUERY}
                  variables={{ filter }}
                >
                  {({ error, loading, data, refetch }) => {
                    if (error)
                      return <div>Error: {handleError(error.toString())}</div>;
                    if (loading)
                      return (
                        <div className="p-4">
                          <Skeleton active />
                        </div>
                      );
                    this.refetch = refetch;
                    const dataSource = data ? data.productAssortment.nodes : [];

                    const columnsCheckes = [columns[0]];
                    optionsChecked.forEach((i) => {
                      columns.forEach((ii) => {
                        if (ii.key === i) {
                          columnsCheckes.push(ii);
                        }
                      });
                    });
                    columnsCheckes.push(columns[columns.length - 1]);

                    const tableWidth = _.sum(
                      columnsCheckes.map((c) => c.width)
                    );
                    return (
                      <Table
                        bordered={false}
                        expandIcon={() => null}
                        defaultExpandAllRows={true}
                        className={"p-assortment"}
                        scroll={{ x: tableWidth }}
                        expandedRowRender={(record) => {
                          if (
                            (record.similarProducts &&
                              record.similarProducts.length > 0) ||
                            (null !== record.similarItems &&
                              record.similarItems.length > 0)
                          ) {
                            return (
                              <>
                                <span className="pseudo">&nbsp;</span>
                                <div
                                  className={"flex wrapper-expand"}
                                  // style={{ width: `${this.state.widthTable}px` }}
                                >
                                  <div className={"inner-expand"}>
                                    {record.similarProducts &&
                                      record.similarProducts.length > 0 && (
                                        <div className={"similar-products"}>
                                          {record.similarProducts.map(
                                            (sp, key) => (
                                              <div
                                                key={key}
                                                className={"similar-product"}
                                                style={{ padding: "0 16px" }}
                                              >
                                                <span>
                                                  Distance:{sp.distance}
                                                </span>
                                                <ProductThumbnailImage
                                                  product={sp.product}
                                                  isSimilarProducts
                                                />
                                                <span>{sp.product.title}</span>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}

                                    {record.similarItems &&
                                      record.similarItems.length > 0 && (
                                        <div className={"similar-products"}>
                                          {record.similarItems
                                            .filter((si) => si.similarItem)
                                            .map((sp, key) => (
                                              <div
                                                key={key}
                                                className={
                                                  "flex flex-col items-center"
                                                }
                                                style={{ padding: "0 16px" }}
                                              >
                                                <span>
                                                  Distance:{sp.distance}
                                                </span>
                                                <ModalImageClaim
                                                  files={{
                                                    files:
                                                      sp.similarItem &&
                                                      sp.similarItem.images
                                                        ? sp.similarItem.images.map(
                                                            (s) => s.file
                                                          )
                                                        : [],
                                                  }}
                                                  limit={1}
                                                  width={200}
                                                  height={200}
                                                />
                                                <span>
                                                  {sp.similarItem &&
                                                    sp.similarItem.title}
                                                </span>
                                              </div>
                                            ))}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        }}
                        rowSelection={rowSelection}
                        rowKey={(item, index) => item.id}
                        columns={columnsCheckes}
                        dataSource={dataSource}
                        pagination={{
                          pageSize: filter.limit,
                          total: data.productAssortment.total,
                          current: filter.offset / filter.limit + 1,
                          showTotal: (total, range) => {
                            return (
                              <span>{`${range.join(" to ")} of ${total} ${
                                total > 1 ? "items" : "item"
                              }.`}</span>
                            );
                          },
                          onShowSizeChange: (_, size) => {
                            this.setState({
                              currentPage: 1,
                              filter: {
                                ...filter,
                                limit: size,
                                offset: 0,
                              },
                            });
                            setCookie("perPageAssortment", size, 100);
                          },
                          onChange: (page) => {
                            let offset = (page - 1) * filter.limit;
                            this.setState({
                              currentPage: page,
                              filter: {
                                ...filter,
                                offset,
                              },
                            });
                          },
                        }}
                      />
                    );
                  }}
                </Query>
                <Modal
                  footer={null}
                  onCancel={() => this.setState({ previewImage: null })}
                  title={
                    this.state.previewImage
                      ? this.state.previewImage.title
                      : null
                  }
                  visible={!!this.state.previewImage}
                >
                  <Carousel>
                    {this.state.previewImage &&
                      this.state.previewImage.images.map((image, index) => (
                        <div key={index}>
                          <img src={image.url} alt={""} />
                        </div>
                      ))}
                  </Carousel>
                </Modal>
              </Card>
            </Container>
          )}
        </ApolloConsumer>
      </Wrapper>
    );
  }
}

export default ProductAssortment;
