import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

export const TransactionsFilterByActionPolaris = ({
  action,
  onChange,
  options,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (action && !action.value) {
      setSelectedOptions(["All"]);
    } else {
      setSelectedOptions([action.value]);
    }
  }, [action]);

  const handleSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      if (onChange) {
        onChange({
          value: !_.includes(selected, "All") ? _.head(selected) : null,
          label: !_.includes(selectedValue, "All")
            ? _.head(selectedValue)
            : null,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <ChoiceList
      choices={options}
      selected={selectedOptions}
      onChange={handleSelection}
      title="status"
      titleHidden
    />
  );
};
