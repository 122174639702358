import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { GroupsPolaris } from "../../components/store/group/GroupsPolaris";
import { useAppContext } from "../../context";
import { getParamByRole, getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const GroupPagePolaris = (...props) => {
    const { currentUser } = useAppContext();
    const currentParam = getParamByRole(currentUser);
    const path = getPathFromRouter(props);

    return (
        <Container>
            <Page
                title="All Groups"
                primaryAction={{
                    content: "New Group",
                    url: `/${currentParam}/stores/groups/add`,
                }}
                fullWidth
            >
                <GroupsPolaris path={path} />
            </Page>
        </Container>
    );
};
