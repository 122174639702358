import { Select } from "antd";
import React from "react";
import { WAREHOUSE_VALUES } from "../../../constants";

const OTPIONS = WAREHOUSE_VALUES.map((item) => ({
  value: item,
  label: item,
}));

export default function SelectAwarehouses({ value, onChange }) {
  return (
    <Select
      mode="tags"
      value={value}
      onChange={onChange}
      options={OTPIONS}
      style={{ minWidth: 100, width: "100%" }}
    />
  );
}
