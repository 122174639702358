import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";

export const ExportTemplateFilterPolaris = ({ filter, onChange }) => {
  // State
  const [queryValue, setQueryValue] = useState(filter.search);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  // Handle action
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );

  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const handleFilterClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  return (
    <Filters
      queryPlaceholder={`Filter item`}
      queryValue={queryValue}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFilterClearAll}
      filters={[]}
    />
  );
};
