import { gql } from 'apollo-boost'

export const CREATE_TIKTOK_PROMOTION = gql`
  mutation createTiktokPromotion($input: CreateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const UPDATE_TIKTOK_PROMOTION = gql`
  mutation updateTiktokPromotion($input: UpdateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const DELETE_TIKTOK_PROMOTION = gql`
  mutation deleteTiktokPromotion($promotionID: ID!) {
    createTiktokPromotion(promotionID: $promotionID)
  }
`;

export const TIKTOK_FETCH_PRODUCTS_BY_STORE = gql`
  mutation tiktokFetchProductsByStore($storeID: String!) {
    tiktokFetchProductsByStore(storeID: $storeID)
  }
`;

export const FETCH_TIKTOK_PROMOTION_PRODUCT = gql`
  mutation fetchTiktokPromotionProduct($promotionID: ID!) {
    fetchTiktokPromotionProduct(promotionID: $promotionID)
  }
`;

export const TIKTOK_DEACTIVATE_PROMOTION = gql`
  mutation deactiveTiktokPromotion($promotionID: ID!) {
    deactiveTiktokPromotion(promotionID: $promotionID)
  }
`;

export const TIKTOK_UPDATE_TIKTOK_PROMOTION_RENEW = gql`
  mutation updateTiktokPromotionRenew($promotionID: ID!, $isRenew: Boolean!) {
    updateTiktokPromotionRenew(promotionID: $promotionID, isRenew: $isRenew)
  }
`;
