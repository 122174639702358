import { gql } from "apollo-boost";

export const FileFragment = gql`
    fragment FileFragment on File {
        id
        createdAt
        bucket
        key
        name
        url
        thumbnailUrl
        alt
        caption
        mimeType
    }
`;
