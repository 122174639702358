import { matchValue } from "../../../../../helper";
import { ActionTypes } from "./actions";
import { Step } from "./index";

const reducers = {
    [ActionTypes.SetState](state, action) {
        return {
            ...state,
            ...action.payload,
        };
    },
    [ActionTypes.ClearState](state) {
        return {
            ...state,
            step: Step.ChooseBase,
            fulfillmentId: null,
            selectedBases: [],
            template: null,
        };
    },
    [ActionTypes.UpdateFieldsValue](state, action) {
        return {
            ...state,
            fieldsValue: {
                ...state.fieldsValue,
                ...action.payload,
            },
        };
    },
};

export function stateReducer(state, action) {
    return matchValue(action.type, reducers, state, action);
}
