import React, { useState, useCallback } from "react";
import { Popover, ActionList, Button, Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const APPROVE_DEPOSIT = gql`
  mutation approveDeposit($id: ID!, $value: Boolean!) {
    approveDeposit(id: $id, value: $value)
  }
`;

export const DepositsActionsPolaris = ({
  item,
  refetch,
  onNotificationChange,
  toggleActive,
}) => {
  const id = item?.id;
  // State
  const [activePopover, setActivePopover] = useState(false);
  const [loading, setLoading] = useState({});
  const [showModal, setShowModal] = useState({});

  // Mutation
  const [approveDeposit] = useMutation(APPROVE_DEPOSIT);

  // Handle action
  const toggleShowModal = useCallback((action) => {
    setShowModal((prev) => ({ ...prev, [action]: !prev[action] }));
  }, []);
  const togglePopoverActive = useCallback(
    () => setActivePopover((prev) => !prev),
    []
  );
  const handleUpdate = useCallback(
    (status) => {
      let load;
      if (status) {
        load = "approve";
      } else {
        load = "cancel";
      }
      setLoading((prev) => ({ ...prev, [load]: true }));
      if (onNotificationChange) {
        onNotificationChange({ message: null, isError: false });
      }
      if (id) {
        approveDeposit({
          variables: {
            id,
            value: status,
          },
        })
          .then(() => {
            setLoading((prev) => ({ ...prev, [load]: false }));
            if (refetch) {
              refetch();
            }
            if (onNotificationChange) {
              onNotificationChange({
                message: "Update status successfuly.",
                isError: false,
              });
            }
          })
          .catch((error) => {
            if (onNotificationChange) {
              onNotificationChange({
                message: handleError(error.toString()),
                isError: true,
              });
            }
            setLoading((prev) => ({ ...prev, [load]: false }));
          });
      }
      if (toggleActive) {
        toggleActive();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const activator = (
    <Button children="Actions" onClick={togglePopoverActive} disclosure />
  );

  return (
    <Popover
      activator={activator}
      active={activePopover}
      fullWidth
      onClose={togglePopoverActive}
    >
      <ActionList
        items={[
          {
            content: "Approve",
            onAction: () => toggleShowModal("approve"),
          },
          {
            content: "Cancel",
            onAction: () => toggleShowModal("cancel"),
            destructive: true,
          },
        ]}
      />
      <Modal
        open={!!showModal["approve"]}
        title="Change status"
        onClose={() => toggleShowModal("approve")}
        sectioned
        secondaryActions={[
          { content: "Cancel", onAction: () => toggleShowModal("approve") },
        ]}
        primaryAction={{
          content: "Submit",
          onAction: () => handleUpdate(true),
          loading: loading["approve"],
        }}
      >
        <p>Are your sure to change status to Approved?</p>
      </Modal>
      <Modal
        open={!!showModal["cancel"]}
        title="Change status"
        onClose={() => toggleShowModal("cancel")}
        sectioned
        secondaryActions={[
          { content: "Cancel", onAction: () => toggleShowModal("cancel") },
        ]}
        primaryAction={{
          content: "Submit",
          onAction: () => handleUpdate(false),
          loading: loading["cancel"],
        }}
      >
        <p>Are your sure to change status to Cancelled?</p>
      </Modal>
    </Popover>
  );
};
