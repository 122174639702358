import React, { useState, useCallback, useEffect, useContext } from "react";
import { Page, Loading, Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import history from "../../../history";
import { TEAM_ROLE } from "../../../variable";
import { AppContext } from "../../../context";
import { handleError } from "../../../helper";

import { ProductFeedFormPolaris } from "./ProductFeedFormPolaris";

const LOAD = gql`
  query load {
    stores(filter: { limit: -1, offset: 0 }) {
      nodes {
        id
        title
      }
    }
  }
`;

const CREATE_FEED_MUTATION = gql`
  mutation createFeed($input: NewFeed!) {
    createFeed(input: $input)
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddProductFeedPolaris = () => {
  const { data, loading, error } = useQuery(LOAD);
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const { currentUser } = useContext(AppContext);
  const role = _.get(currentUser, "teamUser.role", null);
  const isStoreManager = [TEAM_ROLE.StoreManager].includes(role);
  const urlStoreManager = isStoreManager ? "store-manager" : "seller";

  const [
    createFeed,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(CREATE_FEED_MUTATION, {
    onCompleted: () => {
      const id = setTimeout(() => {
        history.push(`/${urlStoreManager}/products/feeds`);
      }, 2500);
      setTimeoutId(id);
    },
    onError: () => {},
  });

  const loadingMarkup = loading && <Loading />;

  const handleSubmit = useCallback((input) => {
    createFeed({
      variables: {
        input,
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (errorMutation || (dataMutation && dataMutation.createFeed)) && (
        <Toast
          content={
            errorMutation
              ? handleError(errorMutation.toString())
              : dataMutation &&
                dataMutation.createFeed &&
                "Your feed is processing. Please wait couple minutes."
          }
          error={errorMutation}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Add Product Feed"
        breadcrumbs={[
          {
            content: "Product feeds",
            url: `/${urlStoreManager}/products/feeds`,
          },
        ]}
      >
        <Card sectioned>
          <ProductFeedFormPolaris
            data={data}
            btnLabel="Create Feed"
            onSubmit={handleSubmit}
            loading={loadingMutation}
            error={error}
            urlStoreManager={urlStoreManager}
          />
        </Card>
      </Page>
    </Container>
  );
};
