import ChangePassword from "../components/ChangePassword";
import { EditProfilePolaris } from "../components/EditProfilePolaris";
import MainLayout from "../layout/MainLayout";
import Activation from "../pages/Activation";
import ArtworkPreview from "../pages/ArtworkPreview";
import Billings from "../pages/Billings";
import { DownloadPage } from "../pages/download/DownloadPage";
import { ForgotPasswordFormPolaris } from "../pages/ForgotPasswordFormPolaris";
import { ForgotPasswordPolaris } from "../pages/ForgotPasswordPolaris";
import Home from "../pages/Home";
import { LoginPolaris } from "../pages/LoginPolaris";
import Privacy from "../pages/Privacy";
import RedirectOrder from "../pages/RedirectOrder";
import { RegisterPolaris } from "../pages/RegisterPolaris";

export default [
  {
    path: "/",
    exact: true,
    component: Home,
    layout: MainLayout,
  },
  {
    path: "/login",
    exact: true,
    // component: Login,
    component: LoginPolaris,
    layout: null,
  },
  {
    path: "/register",
    exact: true,
    // component: Register,
    component: RegisterPolaris,
    layout: null,
  },
  {
    path: "/forgot-password",
    exact: true,
    // component: ForgotPassword,
    component: ForgotPasswordPolaris,
    layout: null,
  },
  {
    path: "/forgot-password/:token",
    exact: true,
    // component: ForgotPasswordForm,
    component: ForgotPasswordFormPolaris,
    layout: null,
  },
  {
    path: "/edit-profile",
    exact: true,
    // component: EditProfile,
    component: EditProfilePolaris,
    layout: MainLayout,
    // roles: ["Authenticated"],
  },
  {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
    layout: MainLayout,
  },
  {
    path: "/billings",
    exact: true,
    component: Billings,
    layout: MainLayout,
  },
  {
    path: "/activation/:token",
    exact: true,
    component: Activation,
    layout: null,
  },
  {
    path: "/redirect/order/:orderId",
    exact: true,
    component: RedirectOrder,
    layout: null,
  },
  {
    path: "/privacy",
    exact: true,
    component: Privacy,
    layout: null,
  },
  {
    path: "/download/:id",
    exact: true,
    component: DownloadPage,
    title: "Download Page",
    layout: MainLayout,
    roles: ["Seller"],
  },
  {
    path: "/artwork_preview/:id",
    exact: true,
    component: ArtworkPreview,
    title: "Artwork Preview",
    layout: null,
  },
];
