import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppContext } from "../../context";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import {
  COMMON_FILTER,
  DESIGNER_HAVE_RECREATE_MOCKUP,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ProductsRMFilterPolaris } from "./ProductsRMFilterPolaris";
import { ProductsRMTablePolaris } from "./ProductsRMTablePolaris";

const QUERY_PRODUCTS_RM = gql`
  query getListProductRecreateMockup($filter: ProductRecreateMockupFilter) {
    getListProductRecreateMockup(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        personalized
        mockupUpdatedBy {
          id
          firstName
          lastName
        }
        mockupUpdatedAt
        productBases {
          id
          title
          designPositions {
            id
            name
            image {
              id
              bucket
              key
              name
              size
              mimeType
              url
              thumbnailUrl
              alt
              caption
            }
          }
        }
        collections {
          id
          name
        }
        tags {
          id
          name
        }
        images {
          id
          file {
            id
            name
            mimeType
            url
            thumbnailUrl
            alt
          }
          productBaseId
        }
        divisions {
          id
          ignored
          reason
          used
        }
        designPositions {
          id
          designPosition {
            name
            productBaseId
          }
          file {
            id
            name
            mimeType
            url
            thumbnailUrl
          }
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "recreateMockupId", "mockupUpdatedBy"];

export const ProductsRMPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductsRM")) || 20,
    offset: 0,
    search: null,
    recreateMockupId: null,
    mockupUpdated: false,
    sortOrder: "DESC",
    sortBy: "productcreated",
    mockupUpdatedBy: null,
    mockupUpdatedAt: null,
    ...initFilter,
  });

  // Context
  const { currentUser } = useContext(AppContext);
  const userRole = currentUser?.roles || [];
  const teamRole = currentUser?.teamUser?.role;
  const userId = currentUser?.id;

  let designerSpecial = true;

  if (userRole.includes(USER_ROLE.Seller)) {
    if (
      [TEAM_ROLE.Designer].includes(teamRole) &&
      DESIGNER_HAVE_RECREATE_MOCKUP.includes(userId)
    ) {
      designerSpecial = false;
    }
  }

  // Query
  const { data, loading, error, refetch } = useQuery(QUERY_PRODUCTS_RM, {
    variables: {
      filter: {
        ...filter,
        mockupUpdatedBy: designerSpecial ? filter.mockupUpdatedBy : userId,
      },
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let { offset, limit, sortOrder, mockupUpdatedAt, sortBy, ...rest } = filter;
    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = useCallback(
    ({
      search,
      mockupUpdated,
      recreateMockupId,
      sortOrder,
      sortBy,
      mockupUpdatedBy,
      range,
    }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.mockupUpdated, mockupUpdated) ||
          !isEqual(prev.recreateMockupId, recreateMockupId) ||
          !isEqual(prev.sortOrder, sortOrder) ||
          !isEqual(prev.sortBy, sortBy) ||
          !isEqual(prev.mockupUpdatedBy, mockupUpdatedBy) ||
          !isEqual(prev.mockupUpdatedAt, range)
        ) {
          return {
            ...prev,
            search,
            mockupUpdated,
            recreateMockupId,
            offset: 0,
            sortOrder,
            sortBy,
            mockupUpdatedBy,
            mockupUpdatedAt: range,
          };
        }
        return prev;
      });
    },
    [],
  );
  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Fragment>
      {loadingMarkup}
      <Card sectioned>
        <ProductsRMFilterPolaris
          designerSpecial={designerSpecial}
          filter={filter}
          onChange={handleFilterChange}
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.getListProductRecreateMockup?.nodes?.length > 0 ? (
          <ProductsRMTablePolaris
            filter={filter}
            data={data}
            refetch={refetch}
            setFilter={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageProductsRM", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Fragment>
  );
};
