import React, {
    Fragment,
    useCallback,
    useState,
    useMemo,
    useEffect,
} from "react";
import { Button, Modal, Banner, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import noImg from "../../../../assets/images/unnamed.jpeg";
import useToggle from "../../../../hooks/useToggle";
import { REVIEW_PRODUCT_GEN_2D_MOCKUP } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import { handleError } from "../../../../helper";
import { CancelSmallMinor } from "@shopify/polaris-icons";

function ImageComp({ file, oneItem, handleDismiss }) {
    // Props
    const url = file?.url;
    const thumbnailUrl = file?.thumbnailUrl;

    const src = url || thumbnailUrl;
    const thumbnailSrc = thumbnailUrl || url;

    // State
    const [hover, setHover] = useState(false);
    const [open, toggleOpen] = useToggle(false);

    // Handle actions
    const handleMouseOver = useCallback(() => setHover(true), []);
    const handleMouseLeave = useCallback(() => setHover(false), []);

    // Markup
    const visibleCls = hover ? "visible" : "";

    return (
        <Fragment>
            <div
                className="image-wrap"
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                onClick={toggleOpen}
            >
                {oneItem ? null : (
                    <div className={`dismiss ${visibleCls}`}>
                        <Button
                            plain
                            size="slim"
                            icon={CancelSmallMinor}
                            accessibilityLabel="Dismiss image"
                            onClick={() => handleDismiss()}
                        />
                    </div>
                )}
                <img src={thumbnailSrc || noImg} alt="" />
            </div>
            <Modal
                sectioned
                title="Image Product"
                open={open}
                onClose={toggleOpen}
                large
            >
                <ImagWrapper>
                    <img src={src || noImg} alt="" />
                </ImagWrapper>
            </Modal>
        </Fragment>
    );
}

export function ReviewAction({ item, open, toggleOpen }) {
    const id = item?.id;
    const imagesProp = item?.images;

    // State
    const [isApprove, setIsApprove] = useState(null);
    const [images, setImages] = useState([]);
    const msg =
        typeof isApprove === "boolean" &&
        `${isApprove ? "Accept" : "Reject"} Design successfully.`;

    // Context
    const { toggleToast, setNotify } = useToastContext();

    // Mutation
    const [reviewProduct, { loading }] = useMutation(
        REVIEW_PRODUCT_GEN_2D_MOCKUP,
        {
            onCompleted: () => {
                setNotify({
                    msg,
                    err: false,
                });
                toggleOpen();
            },
            onError: (err) => {
                setNotify({ msg: handleError(err.toString()), err: true });
                toggleOpen();
            },
        }
    );

    // Get data
    useEffect(() => {
        const images =
            imagesProp?.length > 0
                ? imagesProp.filter((i) => i.file).map((i) => i.file)
                : [];
        setImages(images);
    }, [imagesProp]);

    // Handle actions
    const handleSubmit = useCallback(
        (value) => {
            if (id != null) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });

                setIsApprove(value);

                const fileIds =
                    images?.length > 0 ? images.map((i) => i.id) : [];
                const input = {
                    id,
                    isApprove: value,
                    fileIds,
                };

                reviewProduct({
                    variables: input,
                });
            }
        },
        [id, reviewProduct, images, toggleToast, setNotify]
    );

    const handleAccept = useCallback(() => {
        handleSubmit(true);
    }, [handleSubmit]);

    const handleReject = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);
    const handleDismiss = useCallback(
        (index) => {
            const clone = [...images];
            const idxToRemove = clone.findIndex((_i, i) => i === index);
            if (idxToRemove === -1) return;

            clone.splice(idxToRemove, 1);
            setImages(clone);
        },
        [images]
    );

    // Markup
    const imagesMarkup = useMemo(() => {
        return images.map((file, index, arr) => {
            const oneItem = arr.length === 1;
            return (
                <ImageComp
                    file={file}
                    oneItem={oneItem}
                    handleDismiss={() => handleDismiss(index)}
                    key={`image-${index}`}
                />
            );
        });
    }, [images, handleDismiss]);

    const primaryAction = {
        content: "Accept",
        onAction: handleAccept,
        loading: isApprove === true && loading,
    };
    const secondaryActions = {
        content: "Reject",
        onAction: handleReject,
        loading: isApprove === false && loading,
    };

    return (
        <Fragment>
            <Modal
                title="Review"
                open={open}
                onClose={toggleOpen}
                sectioned
                large
                primaryAction={primaryAction}
                secondaryActions={[secondaryActions]}
            >
                <Stack vertical spacing="loose">
                    <Banner
                        title="You must keep at least one image for product"
                        status="warning"
                    />
                    <Wrapper>{imagesMarkup}</Wrapper>
                </Stack>
            </Modal>
        </Fragment>
    );
}

const ImagWrapper = styled.div`
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;

    .image-wrap {
        width: 20rem;
        height: 20rem;
        position: relative;
        cursor: pointer;

        .dismiss {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding-top: 1rem;
            padding-right: 1rem;
            display: flex;
            justify-content: flex-end;
            align-items: start;
            flex-direction: row;
            column-gap: 1.5rem;
            visibility: hidden;
            border-radius: 5px;

            background: linear-gradient(
                180deg,
                rgba(33, 43, 54, 0.55),
                hsla(0, 0%, 100%, 0)
            );

            .Polaris-Icon {
                svg {
                    fill: hsla(0, 0%, 100%, 0.8);
                }
            }

            &.visible {
                visibility: visible;
            }
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }
`;
