import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Checkbox,
  Col,
  notification,
  Row,
  Select,
  Form,
  Skeleton,
} from "antd";
import { gql } from "apollo-boost";
import { Query, Mutation } from "@apollo/react-components";
import { handleError } from "../../helper";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import SimpleCustomize from "../customize/SimpleCustomize";
import styled from "styled-components";
import MainImageSelector from "../assortment/MainImageSelector";
import { get } from "lodash";

const LOAD_QUERY = gql`
  query productBasesForAssortment {
    fulfillments {
      id
      name
    }
    productBasesForAssortment {
      id
      title
      fulfillment {
        fulfillmentId
        productId
      }
    }
  }
`;
const ASSIGN_PRODUCT_MUTATION = gql`
  mutation assignProduct($input: NewAssignProduct!) {
    assignProduct(input: $input) {
      products {
        id
        title
        sku
        description
        designStatus
        personalized
        status
        productBases {
          id
          title
        }
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
        fields {
          id
          title
          name
          type
          extraFee
          configure
          sorting
        }
      }
      success
      failed
    }
  }
`;

const Container = styled.div`
  width: 480px;
  @media (min-width: 768px) {
    width: 720px;
  }
`;

class AssignProduct extends Component {
  state = {
    fulfillmentId: null,
    checked: false,
    loading: false,
    deleteImg: [],
  };
  formRef = React.createRef();

  render() {
    const { onError, onCompleted } = this.props;
    const { fulfillmentId } = this.state;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Container>
        <div>
          <Query query={LOAD_QUERY}>
            {({ error, loading, data }) => {
              if (error)
                return <div>Error: {handleError(error.toString())}</div>;
              if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );
              let productBases = data.productBasesForAssortment;
              productBases = productBases.filter((p) => {
                if (fulfillmentId) {
                  if (
                    p.fulfillment &&
                    p.fulfillment.fulfillmentId === fulfillmentId
                  ) {
                    return true;
                  }
                } else {
                  if (p.fulfillment === null) {
                    return true;
                  }
                }
                return false;
              });
              return (
                <Mutation
                  onCompleted={(res) => {
                    this.setState({ loading: false });
                    notification.success({
                      message: `Total ${res.assignProduct.success} items assigned completed!`,
                    });
                    if (onCompleted) {
                      onCompleted(res.assignProduct.products);
                    }
                  }}
                  onError={(err) => {
                    this.setState({ loading: false });

                    notification.error({
                      message: handleError(err.toString()),
                    });
                    if (onError) {
                      onError(err);
                    }
                  }}
                  mutation={ASSIGN_PRODUCT_MUTATION}
                >
                  {(assignProduct) => (
                    <Form
                      ref={this.formRef}
                      {...layout}
                      initialValues={{
                        collections: [{ id: "hGypw", name: "NO-TM" }],
                        tags: [],
                        personalized: false,
                        fields: [],
                        mainImageId: get(
                          this.props.value,
                          "[0].images[0][file].id",
                          null
                        ),
                      }}
                      onFinish={(e) => {
                        if (e) {
                          let { deleteImg } = this.state;
                          deleteImg = deleteImg.filter(
                            (i) => i !== e.mainImageId
                          );
                          this.setState({ loading: true });
                          assignProduct({
                            variables: {
                              input: {
                                productBaseId: e.base,
                                productCrawlItems: this.props.value.map(
                                  (v) => v.id
                                ),
                                tags: e.tags ? e.tags.map((t) => t.id) : [],
                                collections: e.collections
                                  ? e.collections.map((c) => c.id)
                                  : [],
                                personalized: e.personalized,
                                fields: e.fields,
                                mainImageId: e.mainImageId,
                                deleteImages: deleteImg,
                              },
                            },
                          });
                        }
                      }}
                    >
                      <Form.Item label={"Product base"}>
                        <Row gutter={15}>
                          <Col span={12}>
                            <Select
                              onChange={(v) => {
                                this.setState(
                                  {
                                    fulfillmentId: v,
                                  },
                                  () => {
                                    this.formRef.current.setFieldsValue({
                                      base: null,
                                    });
                                  }
                                );
                              }}
                              value={fulfillmentId}
                            >
                              <Select.Option value={null}>
                                MerchBridge
                              </Select.Option>
                              {data.fulfillments.map((f, index) => (
                                <Select.Option key={index} value={f.id}>
                                  {f.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                          <Col
                            span={12}
                            name="base"
                            rules={[
                              {
                                required: true,
                                message: "Product base is required!",
                              },
                            ]}
                          >
                            <Form.Item
                              name="base"
                              rules={[
                                {
                                  required: true,
                                  message: "Product base is required!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                filterOption={(input, option) => {
                                  if (option.children) {
                                    return (
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }
                                }}
                              >
                                {productBases.map((base, index) => (
                                  <Select.Option key={index} value={base.id}>
                                    {base.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                        label={"Collections"}
                        name="collections"
                        rules={[
                          {
                            required: true,
                            message: "Collections is required",
                          },
                        ]}
                      >
                        <CollectionsAutoComplete isAssign />
                      </Form.Item>
                      <Form.Item
                        label={"Tags"}
                        name="tags"
                        rules={[
                          {
                            required: true,
                            message: "Tags is required",
                          },
                        ]}
                      >
                        <TagsAutoComplete />
                      </Form.Item>
                      {this.props.value.length === 1 &&
                        get(this.props.value, "[0].images[0]", null) && (
                          <Form.Item label={"Main image"} name={"mainImageId"}>
                            <MainImageSelector
                              images={this.props.value[0].images.map(
                                (f) => f.file
                              )}
                              deleteImg={this.state.deleteImg}
                              onChangeDeleteImg={(ids) =>
                                this.setState({ deleteImg: ids })
                              }
                            />
                          </Form.Item>
                        )}
                      <Form.Item name="personalized" valuePropName="checked">
                        <Checkbox
                          onChange={(e) =>
                            this.setState({
                              checked: e.target.checked,
                            })
                          }
                        >
                          This is a personalized product
                        </Checkbox>
                      </Form.Item>
                      {this.state.checked === true ? (
                        <Form.Item name="fields">
                          <SimpleCustomize />
                        </Form.Item>
                      ) : null}
                      <Form.Item>
                        <Button
                          style={{ width: "100%" }}
                          type={"primary"}
                          htmlType={"submit"}
                          loading={this.state.loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                </Mutation>
              );
            }}
          </Query>
        </div>
      </Container>
    );
  }
}

AssignProduct.propTypes = {
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
  value: PropTypes.array,
};

export default AssignProduct;
