import React, { useState, useCallback, useEffect } from "react";
import { Card, Tabs, Loading } from "@shopify/polaris";
import { isEqual } from "lodash";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { USER_ROLE } from "../../../variable";
import {
  handleError,
  getCookie,
  setCookie,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  removeFieldWithoutFilter,
} from "../../../helper";
import history from "../../../history";

import { OverviewFilterPolaris } from "./OverviewFilterPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { DataTableOverViewPolaris } from "./DataTableOverViewPolaris";

const QUERY_OVERVIEW = gql`
  query overview($filter: OverviewFilter, $viewSeller: Boolean!) {
    overview(filter: $filter, viewSeller: $viewSeller) {
      total
      nodes {
        balance
        pendingCost
        paidAmount
        pendingDeposit
        totalDeposit
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const FIELD_FILTER = ["limit", "offset", "selectId"];

let initialFilter = { ...convertStringToObject(history.location.search) };
if (initialFilter) {
  let limit = initialFilter.limit;
  limit = Number(limit);
  if (!limit) {
    limit = 20;
  }
  initialFilter.limit = limit;

  if (initialFilter.paged) {
    initialFilter.offset = convertToOffset(
      initialFilter.limit,
      Math.round(initialFilter.paged)
    );
  }
  initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
}

export const ListOverviewPolaris = () => {
  // State
  const [selected, setSelected] = useState(0);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageOverview")) || 20,
    offset: 0,
    selectId: null,
    ...initialFilter,
  });

  // Query
  const { data, loading, error } = useQuery(QUERY_OVERVIEW, {
    variables: {
      filter,
      viewSeller: selected ? false : true,
    },
  });

  // Handle action
  useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleTabChange = useCallback((selectedIndex) => {
    setSelected(selectedIndex);
    setFilter((prev) => ({ ...prev, selectId: null }));
  }, []);

  const tabs = [
    {
      id: "seller-overview",
      content: "Seller overview",
      accessibilityLabel: "Seller overview",
    },
    {
      id: "supplier-overview",
      content: "Supplier overview",
      accessibilityLabel: "Supplier overview",
    },
  ];

  const loadingMarkup = loading && <Loading />;
  const role = selected ? USER_ROLE.Supplier : USER_ROLE.Seller;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <OverviewFilterPolaris
          role={role}
          filter={filter}
          onChange={({ selectId }) => {
            setFilter((prev) => {
              if (!isEqual(prev.selectId, selectId)) {
                return {
                  ...prev,
                  selectId,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        {error && (
          <div style={{ padding: "1.6rem" }}>
            {handleError(error.toString())}
          </div>
        )}
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {loading ? (
            <SkeletonPagePolaris />
          ) : data?.overview?.nodes?.length > 0 ? (
            <DataTableOverViewPolaris
              data={data}
              role={role}
              filter={filter}
              setFilter={(offset, limit) => {
                setFilter((prev) => {
                  if (
                    !isEqual(prev.offset, offset) ||
                    !isEqual(prev.limit, limit)
                  ) {
                    return {
                      ...prev,
                      offset,
                      limit,
                    };
                  }
                  return prev;
                });
                setCookie("perPageOverview", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Tabs>
      </Card>
    </React.Fragment>
  );
};
