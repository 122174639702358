import React, { useState, useCallback } from "react";
import { ResourceList, ResourceItem, Thumbnail } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import noImg from "../../../assets/images/unnamed.jpeg";

const Container = styled.div`
  .Polaris-ResourceList__HeaderWrapper {
    border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  }
  .Polaris-ResourceItem {
    cursor: default;
  }
`;

export const ResourceProductsPolaris = ({
  products,
  onSelection,
  loading,
  options,
}) => {
  const [selected, setSelected] = useState(options);

  const handleSelectedChange = useCallback((selected) => {
    setSelected(selected);
    if (onSelection) {
      onSelection(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resourceName = {
    singular: "product",
    plural: "products",
  };

  const renderItem = (item) => {
    const { id, title, images } = item;
    const media = (
      <Thumbnail
        source={
          images ? _.get(_.head(images), "file.thumbnailUrl", null) : noImg
        }
        alt=""
      />
    );
    return (
      <ResourceItem
        id={id}
        accessibilityLabel={title}
        media={media}
        verticalAlignment="center"
      >
        <div>{title}</div>
      </ResourceItem>
    );
  };

  return (
    <Container>
      <ResourceList
        items={products}
        renderItem={renderItem}
        selectedItems={selected}
        onSelectionChange={(value) => handleSelectedChange(value)}
        resourceName={resourceName}
        selectable
        loading={loading}
      />
    </Container>
  );
};
