import React, { useState, useCallback } from "react";
import {
  Form,
  FormLayout,
  TextField,
  Button,
  Link,
  Toast,
  Icon,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

import { handleError } from "../helper";
import bgForgot from "../assets/images/Pug.png";
import history from "../history";

const NEW_PASSWORD = gql`
  mutation newPasswordAccount($token: String!, $newPassword: String!) {
    newPasswordAccount(token: $token, newPassword: $newPassword)
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #edf2f7;
  align-items: center;
  .Polaris-Link + .Polaris-Link {
    margin-top: 1rem;
  }
  .Polaris-FormLayout {
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  .Polaris-FormLayout__Item {
    margin-top: 2.5rem;
  }
`;

export const ForgotPasswordFormPolaris = (props) => {
  const { token } = props.match.params;
  const [iconView, setIconView] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const [newPasswordAccount, { loading, error, data }] = useMutation(
    NEW_PASSWORD,
    {
      onCompleted: () => {
        history.push("/login");
        window.location.reload(false);
      },
      onError: () => {},
    }
  );

  const handleValuePassword = (value) => {
    setErrors((prevState) => {
      let pw = null;
      if (!value) {
        pw = "Please choose a password.";
      }
      return {
        ...prevState,
        password: pw,
      };
    });
  };

  const hanldeValueConfirmPassword = (value) => {
    setErrors((prevState) => {
      let cpw = null;
      if (!value) {
        cpw = "Please confirm your password.";
      } else {
        if (!_.isEqual(value, password)) {
          cpw = "The two passwords that you entered do not match.";
        }
      }
      return {
        ...prevState,
        confirmPassword: cpw,
      };
    });
  };

  const hanldePasswordChange = useCallback((value) => {
    handleValuePassword(value);
    setPassword(value);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConfirmPassword = useCallback((value) => {
    hanldeValueConfirmPassword(value);
    setConfirmPassword(value);
  });

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback(() => {
    handleValuePassword(password);
    hanldeValueConfirmPassword(confirmPassword);
    let count = _.size(_.filter(_.values(errors), (e) => e !== null));
    if (password && count === 0) {
      newPasswordAccount({
        variables: {
          token: token,
          newPassword: password,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword, errors, token]);

  const toastMarkup = activeToast
    ? (error || (data && data.newPasswordAccount)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.newPasswordAccount &&
                "Reset password successfully."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  return (
    <Container>
      <div className="container mx-auto">
        <div className="flex justify-center px-8">
          <div className="container bg-white register-card w-full xl:w-3/4 lg:w-11/12 flex rounded-l-lg rounded-r-lg">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
              style={{ backgroundImage: `url(${bgForgot})` }}
            />
            <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <div className="px-8 mb-4 text-center">
                    <h3
                      className="pt-4 mb-2 font-medium"
                      style={{ fontSize: "2.25rem" }}
                    >
                      Reset Password?
                    </h3>
                    <p className="mb-4 text-gray-700">
                      You can reset your password using this form
                    </p>
                  </div>
                  <TextField
                    value={password}
                    onChange={hanldePasswordChange}
                    label="Password(*)"
                    type={!iconView ? "password" : "text"}
                    placeholder="***************"
                    suffix={iconViewMarkup}
                    error={errors.password}
                  />
                  <TextField
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    label="Confirm Password(*)"
                    type={!iconView ? "password" : "text"}
                    placeholder="***************"
                    suffix={iconViewMarkup}
                    error={errors.confirmPassword}
                  />
                  <Button fullWidth primary submit loading={loading}>
                    Reset My Password
                  </Button>
                  <hr className="mb-6 border-t" />
                  <div className="flex flex-col items-center">
                    <Link url="/register">Create an Account!</Link>
                    <Link url="/login">Already have an account? Login!</Link>
                  </div>
                </FormLayout>
              </Form>
            </div>
          </div>
          {toastMarkup}
        </div>
      </div>
    </Container>
  );
};
