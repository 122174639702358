import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const DATASETS = [
  {
    label: "",
    data: [],
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

export function LineChart({ data }) {
  // State
  const [dataResources, setDataResources] = useState({
    labels: [],
    datasets: DATASETS,
  });

  // Get data
  useEffect(() => {
    const chartValues = data || [];
    const newData = [];

    const labels = chartValues.reduce((acc, cur) => {
      // const label = moment(cur.formatedDate).format("HH:mm");
      const label = cur.formatedDate;
      newData.push(cur.totalUnits);
      return [...acc, label];
    }, []);

    setDataResources((prev) => {
      const datasets = prev.datasets[0];
      return {
        ...prev,
        labels,
        datasets: [
          {
            ...datasets,
            data: newData,
          },
        ],
      };
    });
  }, [data]);

  const chartValues = data?.length === 0;

  return chartValues ? (
    <EmptyStatePolaris noData />
  ) : (
    <Line options={options} data={dataResources} />
  );
}
