import React from "react";
import { Modal } from "@shopify/polaris";

import { AssignTemplatePolaris } from "./AssignTemplatePolaris";

export const AssignTemplateButtonPolaris = (props) => {
  const { open, toggleShowModal } = props;

  return (
    <Modal
      open={open}
      onClose={toggleShowModal}
      fluidContent
      sectioned
      title="Assign product use template"
      large
    >
      <AssignTemplatePolaris {...props} />
    </Modal>
  );
};
