import { gql } from "apollo-boost";
import { TeamItemStatisticsFragment } from "../fragments";

export const GET_TEAMS = gql`
  query teams($filter: TeamFilter) {
    teams(filter: $filter) {
      total
      nodes {
        id
        name
      }
    }
  }
`;

export const GET_TEAM_STATISTICS = gql`
  query teamStatistics($filter: TeamStatisticsFilter!) {
    teamStatistics(filter: $filter) {
      total
      nodes {
        ...TeamItemStatisticsFragment
      }
    }
  }
  ${TeamItemStatisticsFragment}
`;

export const GET_TEAM_WITH_PRIVATE_FEATURES = gql`
  query teams($filter: TeamFilter) {
    teams(filter: $filter) {
      total
      nodes {
        id
        name
        privateFeatures
      }
    }
  }
`;

export const GET_DESIGN_ONLINE_TEAMS = gql`
  query getDesignOnlineTeams($filter: DesignOnlineTeamFilter) {
    getDesignOnlineTeams(filter: $filter) {
      total
      nodes {
        id
        createdAt
        updatedAt
        name
        status
      }
    }
  }
`;


export const GET_DESIGN_TEAMS = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
  }
  query getDesignTeams($filter: DesignTeamFilter!) {
    getDesignTeams(filter: $filter) {
      total
      nodes {
        id
        createdAt
        name
        status
        leaders {
          ...UserFragment
        }
        members {
          ...UserFragment
        }
      }
    }
  }
`;


export const GET_USER_FREE_BY_ROLES = gql`
  query getUserFreeByRoles($roles: [String!]!) {
    getUserFreeByRoles(roles: $roles) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ALL_TEAM_USERS_WITH_ROOT_USER = gql`
  query getAllTeamUsersWithRootUser($filter: TeamUserWithRootUserFilter!){
    getAllTeamUsersWithRootUser(filter: $filter) {
      id
      name
      createdBy
    }
  }
`;