import { useQuery } from "@apollo/react-hooks";
import { Navigation } from "@shopify/polaris";
import React, { useContext, useMemo } from "react";
import { GET_CONNECTED_APP } from "../../components/app/apps";
import { AppContext } from "../../context";
import {
  checkRole,
  getPrivateFeatures,
  mapPathByExtraMenu,
} from "../../helper";
import { usePrivileges } from "../../hooks/usePrivileges";
import {
  ACC_HR_ID,
  DESIGNER_HAVE_RECREATE_MOCKUP,
  DESIGNER_SPECIAL,
  PME_TEAM_ID,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import {
  ACCOUNTANT_MENU,
  ADMIN_MENU,
  DESIGNER_MENU,
  DESIGN_LEADER_MENU,
  IDEA_LEADER_MENU,
  IDEA_MENU,
  MARKETPLACE_MANAGER_MENU,
  SELLER_MENU,
  SELLER_SUPPLIER_MENU,
  STORE_MANAGER_MENU,
  SUPPLIER_MENU,
  SUPPORTER_ADMIN_MENU,
  SUPPORTER_SELLER_MENU,
  ARTIST_LEADER_MENU,
  ARTIST_MENU,
} from "../navigations";

const menuForHR = ["seller-products", "team-members", "tools"];
const IdeaTasks = "idea-tasks";
const RD = /RD/gi; // Design online;

const IDEA_LEADER_MENU_KEY = ["dashboard", "tasks", "team-members"];
const IDEA_LEADER_PREFIX = "idea-leader";

const IDEA_MENU_KEY = ["dashboard", "tasks"];
const IDEA_PREFIX = "idea";

const DESIGNER_MENU_KEY = [
  "dashboard",
  "design-tasks",
  "personalized",
  "documentations",
];
const DESIGNER_PREFIX = "designer";

const DESIGN_LEADER_MENU_KEY = [...DESIGNER_MENU_KEY, "media", "team-members"];
const DESIGN_LEADER_PREFIX = "design-leader";

const SUPPORTER_PREFIX = "seller-supporter";
//
const RE_MOCKUPS = "re-mockups";

const ACC_A_TINH = "hwai24Ofwg"; // tinh@merchbridge.com

const SidebarPolaris = (props) => {
  const context = useContext(AppContext);

  const { currentUser, showPaymentAccount } = context;

  const {
    roles: userRoles,
    teamUser,
    firstName,
    lastName,
    extraMenus,
  } = currentUser || {};
  const teamRole = teamUser ? teamUser.role : null;
  const fName = [firstName, lastName].filter(Boolean).join(" ");
  const {
    isSeller,
    isAdministrator,
    isStoreManager,
    isMarketplaceManager,
    isSupporter,
    isDesignLeader,
    isOnline,
  } = checkRole(currentUser);
  const isManager = isStoreManager || isMarketplaceManager;
  const teamId = teamUser?.team?.id;
  const isPMETeam = [PME_TEAM_ID, "IyqWIGOsAe"].includes(teamId);
  const { canCreate } = usePrivileges();
  const teamRoot = teamUser?.team?.isRoot;
  // const userId = currentUser?.id;

  const privateFeatures = React.useMemo(() => {
    return getPrivateFeatures(currentUser);
  }, [currentUser]);

  // Query
  const { data } = useQuery(GET_CONNECTED_APP, {
    skip: !isSeller,
  });
  // const { data: dataT } = useQuery(
  //   GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM,
  //   {
  //     skip: !isPMETeam,
  //   }
  // );

  // const userIdsAccessTeam = (
  //   dataT?.getUserHasPermissionAcceptAccessInTeam || []
  // )
  //   .map(({ id }) => id)
  //   .filter(Boolean);
  // const showTMMenu = isPMETeam
  //   ? isPMETeam &&
  //     (userRoles || []).includes("Seller") &&
  //     userIdsAccessTeam.includes(userId)
  //   : true; // Show team member menu

  const connectedApp = data?.getConnectedApp;
  const isDesignOnline = useMemo(() => RD.test(fName), [fName]);

  const renderMenu = ({
    theMenu,
    isDesigner = false,
    isDesignLead = false,
  }) => {
    let { pathname } = props.location;
    const userId = currentUser?.id;

    if (isOnline) {
      theMenu = theMenu
        .map((item) => {
          if (matchKey(["re-mockups", "personalized"], item?.key)) return null;

          if (matchKey(["design-tasks"], item?.key)) {
            const { subNavigationItems } = item;
            if (subNavigationItems?.length > 0) {
              item.subNavigationItems = subNavigationItems
                .map((subM) => {
                  if (subM?.url?.includes("design-tasks/ideas")) {
                    return null;
                  }

                  return subM;
                })
                .filter(Boolean);
            }
          }
          return item;
        })
        .filter(Boolean);
    }

    if (isDesigner) {
      if (!DESIGNER_HAVE_RECREATE_MOCKUP.includes(userId)) {
        theMenu = theMenu.map((item) => {
          const { subNavigationItems } = item || {};

          const newSub = (subNavigationItems || []).filter(
            (i) => i?.key?.toLowerCase() !== `${DESIGNER_PREFIX}_${RE_MOCKUPS}`,
          );
          return {
            ...item,
            subNavigationItems: newSub,
          };
        });
      }

      if (isDesignOnline) {
        theMenu = theMenu.map((item) => {
          const { subNavigationItems } = item;
          const newSubNavigationItems = (subNavigationItems || []).filter(
            (i) => i?.key?.toLowerCase() !== IdeaTasks,
          );

          return {
            ...item,
            subNavigationItems: newSubNavigationItems,
          };
        });
      }
    }

    if (
      isDesignLead &&
      !DESIGNER_SPECIAL.accIds.includes(userId) &&
      !canCreate
    ) {
      theMenu = theMenu.filter(
        (item) =>
          item?.key?.toLowerCase() !== `${DESIGN_LEADER_PREFIX}_team-members`,
      );
    }
    // hidden personalized menu
    let customMenu = theMenu;
    if (!isManager) {
      customMenu = theMenu
        .filter(
          (item) => !(!connectedApp?.length && item.key === "personalized"),
        )
        .map((i) => ({
          ...i,
          selected: i.url === pathname,
        }));
    }

    // Menu by HR account
    if (userId === ACC_HR_ID) {
      customMenu = customMenu.filter((item) => menuForHR.includes(item.key));
    }

    // Check private menu
    if (!isAdministrator) {
      customMenu = customMenu
        .map((menu) => {
          const { subNavigationItems } = menu || {};
          if (!subNavigationItems || subNavigationItems.length === 0)
            return menu;

          const newSub = subNavigationItems
            .map((subMenu) => {
              if (subMenu.private) {
                const lastPath = getPathName(subMenu.url);
                if ((privateFeatures || []).includes(lastPath)) {
                  return subMenu;
                }
                return null;
              }

              return subMenu;
            })
            .filter(Boolean);

          if (newSub.length === 0) return null;
          return {
            ...menu,
            subNavigationItems: newSub,
          };
        })
        .filter(Boolean);
    }

    // Team PME
    if (isPMETeam) {
      customMenu = customMenu.filter(({ key }) => {
        return key !== "seller-product-bases";
      });

      customMenu = customMenu.map((item) => {
        if (item.label !== "Members") return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (!canCreate) {
                if (subM?.url?.includes("add-team-member")) {
                  return null;
                }
              }

              return subM;
            })
            .filter(Boolean);
        }
        return item;
      });
    }

    // Design Leader
    if (isDesignLeader) {
      customMenu = customMenu.map((item) => {
        if ("design-leader_orders" !== item.key) return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (subM?._extra) {
                if (
                  (extraMenus || [])
                    .map((i) => `${DESIGN_LEADER_PREFIX}-${i}`)
                    .includes(subM?.key)
                )
                  return subM;
                return null;
              }

              return subM;
            })
            .filter(Boolean);
          return item;
        }
      });
    }
    // Design Leader
    if (isStoreManager || isMarketplaceManager) {
      customMenu = customMenu.map((item) => {
        if (
          ![
            "store-manager_design-tasks",
            "marketplace-manager_design-tasks",
          ].includes(item.key)
        )
          return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (subM?._extra) {
                if (
                  (extraMenus || []).some((i) => (subM?.key || "").includes(i))
                )
                  return subM;
                return null;
              }

              return subM;
            })
            .filter(Boolean);
          return item;
        }
      });
    }

    if (isDesignLeader || isDesigner) {
      customMenu = customMenu.map((item) => {
        if (
          !["designer_design-tasks", "design-leader_design-tasks"].includes(
            item.key,
          )
        )
          return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          let prefix = isDesigner ? "/designer" : undefined;
          const { tasksMenu, pathMatch } = mapPathByExtraMenu(
            extraMenus,
            prefix,
          );

          let rootUrl = "";
          if (pathMatch.length === 0) return item;
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (subM?._extra) {
                if (pathMatch.includes(subM.url)) {
                  if (!rootUrl) {
                    rootUrl = subM.url;
                  }
                  return subM;
                }
                return null;
              }

              return subM;
            })
            .filter(Boolean);

          item.url = rootUrl || item.url;
          return item;
        }
        return item;
      });

      // remove online-design-teams url for designer offline
      customMenu = customMenu.map((item) => {
        if (item.key !== "design-leader_team-members") return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (isOnline) {
                if (subM?.url?.includes("online-design-teams")) {
                  return null;
                }
              }

              return subM;
            })
            .filter(Boolean);
        }
        return item;
      });
    }

    // Supporter
    if (isSupporter) {
      customMenu = customMenu.map((item) => {
        if (item.key !== "seller-supporter-products") return item;

        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems
            .map((subM) => {
              if (subM?._extra) {
                if (
                  (extraMenus || [])
                    .map((i) => `${SUPPORTER_PREFIX}-${i}`)
                    .includes(subM?.key)
                )
                  return subM;
                return null;
              }

              return subM;
            })
            .filter(Boolean);
        }
        return item;
      });
    }

    // Payment account
    if (!showPaymentAccount) {
      customMenu = customMenu.map((item) => {
        const { subNavigationItems } = item;
        if (subNavigationItems?.length > 0) {
          item.subNavigationItems = subNavigationItems.filter(
            (subM) => !/payment-accounts/gi.test(subM.url),
          );
        }
        return item;
      });
    }

    // Account a Tinh
    if (userId !== ACC_A_TINH) {
      customMenu = customMenu.map((item) => {
        const { subNavigationItems } = item || {};
        const newSubs = (subNavigationItems || []).filter(
          (i) => i?.url !== "/seller/order-stats",
        );

        return {
          ...item,
          subNavigationItems: newSubs,
        };
      });
    }

    // team other
    if (!teamRoot) {
      const pt = "/seller/tiktok-hub";
      customMenu = customMenu
        .map((item) => {
          const { subNavigationItems, url } = item || {};
          const newSubs = (subNavigationItems || []).filter(
            (i) => i?.url !== "/seller/report-total-cost",
          );

          if (!!(url || "").match(pt)) return;

          return {
            ...item,
            subNavigationItems: newSubs,
          };
        })
        .filter(Boolean);
    }

    return (
      <Navigation location={pathname}>
        <Navigation.Section items={customMenu} />
      </Navigation>
    );
  };

  const NEW_IDEA_LEADER_MENU = getMenuVioExtraMenu(
    extraMenus,
    IDEA_LEADER_MENU_KEY,
    IDEA_LEADER_PREFIX,
    IDEA_LEADER_MENU,
  );

  const NEW_IDEA_MENU = getMenuVioExtraMenu(
    extraMenus,
    IDEA_MENU_KEY,
    IDEA_PREFIX,
    IDEA_MENU,
  );

  const NEW_DESIGNER_MENU = getMenuVioExtraMenu(
    extraMenus,
    DESIGNER_MENU_KEY,
    DESIGNER_PREFIX,
    DESIGNER_MENU,
  );

  const NEW_DESIGN_LEADER_MENU = getMenuVioExtraMenu(
    extraMenus,
    [DESIGNER_SPECIAL.accLy, DESIGNER_SPECIAL.accDung].includes(currentUser.id)
      ? [...DESIGN_LEADER_MENU_KEY, "orders"]
      : DESIGN_LEADER_MENU_KEY,
    DESIGN_LEADER_PREFIX,
    DESIGN_LEADER_MENU,
  );

  const userRolesMarkup =
    userRoles &&
    userRoles.map((userRole) => {
      const userRoles = {
        Administrator: renderMenu({ theMenu: ADMIN_MENU }),
        [USER_ROLE.Supporter]: renderMenu({
          theMenu: SUPPORTER_ADMIN_MENU,
        }),
        Seller: {
          [USER_ROLE.StoreManager]: context.store
            ? renderMenu({ theMenu: STORE_MANAGER_MENU })
            : null,
          [TEAM_ROLE.Supporter]: renderMenu({
            theMenu: SUPPORTER_SELLER_MENU,
          }),
          [USER_ROLE.Admin]: renderMenu({
            theMenu: SELLER_MENU,
          }),
          [TEAM_ROLE.Designer]: renderMenu({
            theMenu: NEW_DESIGNER_MENU,
            isDesigner: true,
          }),
          [TEAM_ROLE.DesignLeader]: renderMenu({
            theMenu: NEW_DESIGN_LEADER_MENU,
            isDesignLead: true,
          }),
          [TEAM_ROLE.MarketplaceManager]: renderMenu({
            theMenu: MARKETPLACE_MANAGER_MENU,
          }),
          [TEAM_ROLE.Idea]: renderMenu({
            theMenu: NEW_IDEA_MENU,
          }),
          [TEAM_ROLE.IdeaLeader]: renderMenu({
            theMenu: NEW_IDEA_LEADER_MENU,
          }),
          [TEAM_ROLE.Supplier]: renderMenu({
            theMenu: SELLER_SUPPLIER_MENU,
          }),
          [TEAM_ROLE.Accountant]: renderMenu({
            theMenu: ACCOUNTANT_MENU,
          }),
          [TEAM_ROLE.Artist]: renderMenu({
            theMenu: ARTIST_MENU,
          }),
          [TEAM_ROLE.ArtistLeader]: renderMenu({
            theMenu: ARTIST_LEADER_MENU,
          }),
        },
        Supplier: renderMenu({ theMenu: SUPPLIER_MENU }),
      };
      return (
        <div key={`${userRole}`}>
          {teamRole ? userRoles[userRole][teamRole] : userRoles[userRole]}
        </div>
      );
    });

  return teamRole === USER_ROLE.StoreManager && !context.store
    ? null
    : userRolesMarkup;
};

function getMenuVioExtraMenu(extraMenus, keys, prefix, menu) {
  let newExtraMenus = extraMenus || [];
  if (prefix === DESIGN_LEADER_PREFIX) {
    if (!(newExtraMenus || []).includes("products")) {
      newExtraMenus.push(RE_MOCKUPS);
    } else {
      newExtraMenus.filter((i) => i === RE_MOCKUPS);
    }
  }

  const newKeys = (
    newExtraMenus instanceof Array && newExtraMenus?.length > 0
      ? [...keys, ...newExtraMenus]
      : keys
  ).map((i) => `${prefix}_${i}`);

  const newMenu = menu.filter((i) => {
    const { key } = i || {};
    return newKeys.includes(key);
  });

  return newMenu;
}

function getPathName(url) {
  if (!url) return "";
  const splitted = url.split(/\//);
  return splitted[splitted.length - 1];
}

function matchKey(keys, key) {
  const genKey = (i) => key && !!key.match(new RegExp(i, "gi"));

  return keys.some(genKey);
}

export default SidebarPolaris;
