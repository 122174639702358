import { useMutation } from "@apollo/react-hooks";
import { Checkbox, Modal, Spinner, Stack } from "@shopify/polaris";
import { gql } from "apollo-boost";
import * as React from "react";
import { useAppContext } from "../context";
import { RE_GEN_2FA_CODE, VERIFY_UPDATE_2FA_USER } from "../graphql/mutations";
import { handleError } from "../helper";
import history from "../history";
import { useFingerprint } from "../hooks/useFingerprint";
import { useToastContext } from "./shared/ToastContext";
import { Verify2FA } from "./shared/Verify2FA";

const LOG_OUT = gql`
  mutation logout {
    logout
  }
`;

function Update2FA({ open, onClose }) {
  const [code, setCode] = React.useState("");
  const [twoFA, setTwoFA] = React.useState({
    url: "",
    secret: "",
  });
  const [checked, setChecked] = React.useState(false);
  const fingerprint = useFingerprint();

  const { toggleToast, setNotify } = useToastContext();
  const { setStore, setCurrentUser, logout: logoutContext } = useAppContext();

  let timeoutId = React.useRef(null);
  const [regen, { data, loading, error }] = useMutation(RE_GEN_2FA_CODE);
  const [logout, { client }] = useMutation(LOG_OUT);
  const [verify, { loading: l2 }] = useMutation(VERIFY_UPDATE_2FA_USER, {
    onCompleted: () => {
      setNotify({ msg: "Update 2FA Code", err: false });
      timeoutId.current && clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(async () => {
        await logout();
        localStorage.clear();
        setStore(null);
        setCurrentUser(null);
        client.resetStore();
        logoutContext();
        history.push(`/login`);
        window.location.reload(false);
      }, 2000);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  React.useEffect(() => {
    if (data?.reGen2faCode) {
      setTwoFA({
        url: data.reGen2faCode.Url,
        secret: data.reGen2faCode.Secret,
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (checked) {
      regen();
    }
  }, [regen, checked]);

  const handleVerify = React.useCallback(() => {
    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });
    if (code) {
      verify({
        variables: {
          input: {
            code,
            secret: twoFA.secret,
            qrCodeUrl: twoFA.url,
            fingerprint,
          },
        },
      });
    }
  }, [code, verify, toggleToast, setNotify, twoFA.secret, fingerprint]);

  React.useEffect(() => {
    function handler(event) {
      if (!checked) return;
      event.preventDefault();
      return (event.returnValue = "");
    }
    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [checked]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Update 2FA"
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{
        content: "Update",
        onAction: handleVerify,
        loading: l2,
        disabled: !code,
      }}
    >
      <Stack vertical>
        <Checkbox
          label="Are you sure to update 2FA Code?"
          checked={checked}
          onChange={setChecked}
        />
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>{handleError(error?.toString())}</div>
        ) : twoFA.url ? (
          <Verify2FA code={code} onChange={setCode} url={twoFA.url} />
        ) : null}
      </Stack>
    </Modal>
  );
}

export { Update2FA };
