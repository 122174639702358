import React, { Component } from "react";
import PropTypes from "prop-types";

class XPathIframe extends Component {
  constructor(props) {
    super(props);
    this.listenMessage = this.listenMessage.bind(this);
  }
  componentDidMount() {
    window.addEventListener("message", this.listenMessage, false);
  }
  listenMessage = e => {
    if (e.data && e.data.type === "xpath") {
      this.setState({ xpath: e.data.path, visible: false });
      if (this.props.onChange) {
        this.props.onChange(e.data.path);
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("message", this.listenMessage, false);
  }

  render() {
    const { src } = this.props;
    return (
      <iframe
        title={src}
        width={"100%"}
        height={"500px"}
        src={src}
        name={"ifr"}
      />
    );
  }
}

XPathIframe.propTypes = {
  src: PropTypes.string,
  onChange: PropTypes.func
};

export default XPathIframe;
