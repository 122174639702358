import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextField, Icon, Scrollable } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { ResourceProductsPolaris } from "./ResourceProductsPolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const SearchWrap = styled.div`
  padding: 1.6rem;
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
`;

const ProductsWrap = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 6.8rem;
  margin-bottom: 6.8rem;
  .Polaris-Scrollable {
    height: 550px;
    max-height: inherit;
  }
`;

const PaginationWrap = styled.div`
  padding: 1.6rem;
  position: absolute;
  z-index: 20;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
`;

export const ModalProductsPolaris = ({
  open,
  handleShowModal,
  products,
  filter,
  setFilter,
  total,
  onQueryChange,
  loading,
  onAction,
  matched,
  query,
}) => {
  const [queryValue, setQueryValue] = useState(query);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(matched);
  }, [matched]);

  const handleOptionsChange = useCallback((value) => setOptions(value), []);
  const handleAction = useCallback(() => {
    if (onAction) {
      onAction(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleQueryChange = useCallback((value) => {
    setQueryValue(value);
    if (onQueryChange) {
      onQueryChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
  };

  return (
    <Modal
      open={open}
      title="Edit collection products"
      onClose={handleShowModal}
      primaryAction={{
        content: "Done",
        onAction: () => {
          handleAction();
          handleShowModal();
        },
      }}
      secondaryActions={[
        { content: "Cancel", onAction: () => handleShowModal() },
      ]}
    >
      <SearchWrap>
        <TextField
          label="Search product"
          labelHidden
          value={queryValue}
          onChange={handleQueryChange}
          placeholder="Search product"
          focused
          prefix={<Icon source={SearchMinor} color="inkLighter" />}
        />
      </SearchWrap>
      <ProductsWrap>
        <Scrollable shadow>
          <ResourceProductsPolaris
            products={products}
            onSelection={(value) => handleOptionsChange(value)}
            loading={loading}
            options={options}
          />
        </Scrollable>
      </ProductsWrap>
      <PaginationWrap>
        <PaginationPolaris aggregation={aggregation} />
      </PaginationWrap>
    </Modal>
  );
};
