import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Mutation } from "@apollo/react-components";
import { Button, Card, notification } from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { checkRole, decodeCSV } from "../../helper";
import history from "../../history";
import { IMPORTS } from "./Imports";
import ImportTrackingUpload from "./ImportTrackingUpload";

const Container = styled.div`
  max-width: 70%;
  margin: auto;
  .btn-import {
    float: right;
    background: darkcyan;
    color: white;
    margin-top: 10px;
  }
`;

const IMPORT = gql`
  mutation saveImportData($input: NewImport!) {
    saveImportData(input: $input) {
      id
      files {
        id
        url
        name
        mimeType
      }
      createdAt
    }
  }
`;

class ImportTracking extends Component {
  state = {
    orders: [],
    files: [],
    loading: false,
  };
  handleFiles = (files) => {
    let orders = [];
    let mapOrderById = {};
    this.setState({ files });
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const data = files[i].data;
        let items = decodeCSV(data);
        if (!items) {
          return;
        }
        if (items.length <= 1) {
          return;
        }
        const index = items[0].findIndex(
          (item) =>
            (item && item.toLowerCase().trim()) === "order number" ||
            (item && item.toLowerCase().trim()) === "order id"
        );
        const index1 = items[0].findIndex(
          (item) => (item && item.toLowerCase().trim()) === "tracking code"
        );
        const index2 = items[0].findIndex(
          (item) =>
            (item && item.toLowerCase().trim()) === "new tracking code" ||
            (item && item.toLowerCase().trim()) === "tracking code 2"
        );

        if (index === -1 || index1 === -1) {
          return;
        }

        for (let j = 1; j < items.length; j++) {
          const item = items[j];
          const orderId = _.get(item, index);
          const trackingCode1 = _.get(item, index1, "");
          const trackingCode2 = _.get(item, index2, "");

          if (!trackingCode1) {
            continue;
          }
          if (_.size(trackingCode1) < 8) {
            continue;
          }
          if (mapOrderById[orderId]) {
            const index = mapOrderById[orderId];
            if (trackingCode1) {
              orders[index].trackingCode = trackingCode1;
            }
            if (trackingCode2) {
              orders[index].trackingCode2 = trackingCode2;
              if (!orders[index].trackingCode) {
                orders[index].trackingCode = trackingCode2;
              }
            }
          } else {
            mapOrderById[orderId] = orders.length;
            orders.push({
              id: orderId,
              trackingCode: trackingCode1,
              trackingCode2,
            });
          }
        }
      }
    }

    this.setState({ orders });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { currentUser } = this.context;
    const { isSeller } = checkRole(currentUser);

    return (
      <Container className="container import-tracking">
        <Mutation
          mutation={IMPORT}
          onCompleted={() => {
            this.setState({ loading: false });
            if (!isSeller) {
              history.push("/supplier/imports");
            } else {
              history.push("/seller/imports");
            }
            notification.success({ message: "Import success!" });
          }}
          onError={(err) => {
            this.setState({ loading: false });
            notification.error({ message: err.toString() });
          }}
        >
          {(saveImportData, { client }) => {
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.props.form.validateFields((err, values) => {
                    if (!this.state.orders.length) {
                      notification.error({ message: "Orders not found!" });
                      return;
                    }
                    const input = {
                      files: this.state.files.map((v) => v.file.id),
                      orders: this.state.orders,
                    };
                    this.setState({ loading: true });
                    saveImportData({
                      variables: {
                        input,
                      },
                    }).then((res) => {
                      if (res && res.data) {
                        const data = res.data;
                        try {
                          const variables = {
                            filter: {
                              limit: 20,
                              offset: 0,
                              search: null,
                              supplierId: null,
                            },
                          };
                          const cache = client.readQuery({
                            query: IMPORTS,
                            variables,
                          });

                          client.writeQuery({
                            query: IMPORTS,
                            variables,
                            data: {
                              ...cache,
                              imports: {
                                ...cache.imports,
                                total: cache.imports.total + 1,
                                nodes: [
                                  data.saveImportData,
                                  ...cache.imports.nodes,
                                ],
                              },
                            },
                          });
                        } catch (error) {}
                      }
                    });
                  });
                }}
              >
                <Card>
                  <h4>Tracking file</h4>
                  <p className="mb-2">
                    Your import file must have these fields: Order Number or
                    Order ID, Tracking Code, New Tracking Code or Tracking Code
                    2.
                  </p>
                  {getFieldDecorator("files", {
                    rules: [
                      {
                        required: true,
                        message: "File is required!",
                      },
                    ],
                  })(
                    <ImportTrackingUpload
                      onChange={async (files) => {
                        this.handleFiles(files);
                      }}
                    />
                  )}
                </Card>
                <Button
                  className="mt-4"
                  style={{ float: "right" }}
                  htmlType={"submit"}
                  type="primary"
                  loading={this.state.loading}
                  disabled={!this.state.files.length}
                >
                  Import
                </Button>
              </Form>
            );
          }}
        </Mutation>
      </Container>
    );
  }
}

ImportTracking.contextType = AppContext;

export default Form.create({ name: "form" })(ImportTracking);
