import { Button, Modal } from "antd";
import React from "react";
import { objectInvalid } from "../../../helper";

export default function MapWarehouse({ onChange }) {
  const [state, setState] = React.useState(false);

  const handleChange = async (evt) => {
    try {
      const data = await handleFile(evt);
      // Color __ Size: [{warehouse_code}]
      // {
      //   "Antique Cherry Red__2XL": ["NJ"],
      // }
      onChange && onChange(data);
      setState(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <span style={{ marginLeft: "1rem"}}>
      <Button children="Update Warehouse" onClick={setState} />

      <Modal
        title="Update Warehouse for Variants"
        open={state}
        onCancel={() => setState(false)}
        footer={null}
      >
        <input type="file" onChange={handleChange} />
      </Modal>
    </span>
  );
}

async function handleFile(event) {
  const { target } = event || {};
  const { files } = target || {};
  if (objectInvalid(files)) return "";

  return await new Promise((res, rej) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target.result;
      if (result && typeof result === "string" && result.length > 0) {
        const obj = JSON.parse(result);
        res(obj);
      }

      rej("could not read file");
    };

    reader.readAsText(file);
  });
}
