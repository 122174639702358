import { useMutation } from "@apollo/react-hooks";
import { Modal, TextContainer } from "@shopify/polaris";
import React from "react";
import {
  MARK_STORE_ACTIVATED,
  MARK_STORE_DEACTIVATED,
} from "../../graphql/mutations";
import { handleError } from "../../helper";
import { useToastContext } from "../shared/ToastContext";

export default function MarkStoreStatus({
  onClose,
  open,
  refetch,
  storeId,
  isDeactivated,
}) {
  const { setNotify, toggleToast } = useToastContext();

  const timerRef = React.useRef(null);

  const DOC_NODE = !isDeactivated
    ? MARK_STORE_DEACTIVATED
    : MARK_STORE_ACTIVATED;

  const label = !isDeactivated ? "Deactivated" : "Connected";
  const [markStoreStatus, { loading }] = useMutation(DOC_NODE, {
    onCompleted: () => {
      setNotify({
        msg: `Mark store's status to ${label} success`,
        err: false,
      });
      onClose();
      if (refetch) {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    markStoreStatus({
      variables: { storeId },
    });
  }, [markStoreStatus, toggleToast, setNotify, storeId]);

  return (
    <Modal
      title={`Mask as ${label}`}
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        loading: loading,
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Are you sure to mask status as {label}?</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
