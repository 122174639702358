import React, { useCallback, useState, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { FORCE_PAUSE_ORDER } from "../../../graphql/mutations";

export const ForcePausePolaris = (props) => {
    const {
        open,
        toggleShowModal,
        orderId,
        changeStatusCount,
        convertOS,
        refetch,
    } = props;

    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    // useLockBodyScroll(open)

    const [forcePauseOrder, { data, loading, error }] = useMutation(
        FORCE_PAUSE_ORDER,
        {
            onError: () => {
                toggleShowModal(false);
            },
            onCompleted: () => {
                toggleShowModal(false);
                const id = setTimeout(() => {
                    if (changeStatusCount) {
                        changeStatusCount(
                            convertOS && [convertOS.PendingPayment]
                        );
                    }
                    if (refetch) {
                        refetch();
                    }
                }, 2100);
                setTimeoutId(id);
            },
        }
    );

    const handleSubmit = useCallback(() => {
        forcePauseOrder({
            variables: { orderId },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(
        () => setActiveToast((activeToast) => !activeToast),
        []
    );

    const toastMarkup = activeToast
        ? (error || (data && data.forcePauseOrder)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data &&
                            data.forcePauseOrder &&
                            `Force pause order success.`
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            <Modal
                title="Force pause order"
                open={open}
                onClose={toggleShowModal}
                sectioned
                secondaryActions={[
                    { content: "No", onAction: toggleShowModal },
                ]}
                primaryAction={{
                    content: "Yes",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <p id="force-pause_modal">
                    Are you sure to force pause this order?
                </p>
            </Modal>
        </>
    );
};
