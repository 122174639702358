import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Form,
  FormLayout,
  InlineError,
  TextField,
} from "@shopify/polaris";
import { CircleCancelMajorMonotone } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";
import { REPLACE_FEED } from "../../graphql/mutations";
import { handleError } from "../../helper";
import history from "../../history";
import { UploadTemplateFilePolaris } from "../export-amazon/UploadTemplateFilePolaris";
import { useToastContext } from "../shared/ToastContext";
import { StoreAutocomplete } from "../store/StoreAutocomplete";

const newValue = { oldValue: "", newValue: "", column: "" };

export function ReplaceFeed({ pathname }) {
  const [errors, setErrors] = React.useState({});
  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    fileId: null,
    values: [newValue],
  });

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutation
  const [replaceFeedFn, { loading, data }] = useMutation(REPLACE_FEED, {
    onCompleted: () => {
      setNotify({ msg: "Replace product feed success.", err: false });
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  React.useEffect(() => {
    if (data?.replaceFeed) {
      window.location.href = data.replaceFeed;
    }
  }, [data]);

  const handleSubmit = React.useCallback(() => {
    const { fileId, values, storeId } = state;
    const newValues = (values || []).filter((item) => {
      if (!item || typeof item !== "object") return false;
      return item.oldValue && item.newValue;
    });

    for (let [key, value] of Object.entries({
      file: fileId,
      store: storeId,
      values: newValues,
    })) {
      validateField(value, key);
    }

    if (storeId && fileId && newValues.length > 0) {
      toggleToast && toggleToast();
      setNotify && setNotify({ err: false, msg: null });
      replaceFeedFn({
        variables: {
          input: {
            fileId,
            storeId,
            values: newValues,
          },
        },
      });
    }
  }, [state]);

  const validateField = React.useCallback((value, id) => {
    let error = null;
    let label = id.charAt(0).toUpperCase() + id.slice(1);

    if (!value) {
      error = `${label} is required.`;
    } else if (Array.isArray(value) && value.filter(Boolean).length === 0) {
      error = `${label} is required.`;
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);

  const handleTemplateFileChange = React.useCallback((fileId) => {
    setState({ fileId });
    validateField(fileId, "file");
  }, []);

  const handleAddNew = React.useCallback(() => {
    setState({ values: [...(state.values || []), newValue] });
  }, [state.values]);

  const handleFieldChange = React.useCallback(
    (index) => (val, key) => {
      const newValues = (state.values || []).map((value, idx) => {
        if (idx !== index) return value;
        return { ...value, [key]: val };
      });

      setState({ values: newValues });
      validateField(newValues, "values");
    },
    [state.values]
  );

  const handleRemove = React.useCallback(
    (index) => () => {
      const newValues = state.values;
      newValues.splice(index, 1);
      setState({ values: newValues });
    },
    [state.values]
  );

  const handleRedirect = React.useCallback(() => {
    history.push(pathname);
  }, [pathname]);

  const handleStoreChange = React.useCallback((value) => {
    const [storeId] = value || [];
    validateField(storeId, "store");
    setState({ storeId });
  }, []);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <StoreAutocomplete
            required
            error={errors["store"]}
            onChange={handleStoreChange}
          />
          <div>
            <UploadTemplateFilePolaris onChange={handleTemplateFileChange} />
            {errors["file"] && <InlineError message={errors["file"]} />}
          </div>

          <div className="values-wrapper">
            {(state.values || []).map((value, index, arr) => (
              <FormLayout.Group key={`values-${index}`}>
                <TextField
                  label="Column"
                  id="column"
                  value={value.column}
                  placeholder="Enter column"
                  onChange={handleFieldChange(index)}
                />
                <TextField
                  label="Old Value"
                  id="oldValue"
                  value={value.oldValue}
                  placeholder="Enter old value"
                  onChange={handleFieldChange(index)}
                />
                <TextField
                  label="New Value"
                  id="newValue"
                  value={value.newValue}
                  placeholder="Enter new value"
                  onChange={handleFieldChange(index)}
                />
                <div style={{ marginTop: 30 }}>
                  {arr.length > 1 && (
                    <Button
                      plain
                      icon={CircleCancelMajorMonotone}
                      onClick={handleRemove(index)}
                    />
                  )}
                </div>
              </FormLayout.Group>
            ))}
            {errors["values"] && <InlineError message={errors["values"]} />}
          </div>
          <Button children="Add row" onClick={handleAddNew} size="slim" />
          <div style={{ padding: 1 }} />
          <Button children="Submit" submit primary loading={loading} />
        </FormLayout>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .values-wrapper {
    .Polaris-FormLayout--grouped {
      margin-left: -2rem;
      margin-bottom: 0.6rem;
    }
  }
`;
