import React, { Component } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Col, Input, InputNumber, Row, Select } from "antd";
import styled from "styled-components";

const Container = styled.div`
  .item {
    display: flex;
    margin-bottom: 10px;
    .simple-option {
      flex-grow: 1;
    }
    .advance-option {
      display: flex;
      flex-grow: 1;
    }
  }
`;
class FieldConfigureOptions extends Component {
  render() {
    const { value } = this.props;
    const { type } = value;
    const { settings } = value.configure;
    const { options } = settings;
    const isSimpleOption = type === "checkbox" || type === "radio";

    return (
      <Container>
        {type === "number" && (
          <React.Fragment>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item label={"Min"} labelCol={{ span: 24 }}>
                  <InputNumber
                    onChange={(v) => {
                      settings.min = v;
                      this.props.onChange(value);
                    }}
                    style={{ width: "100%" }}
                    value={settings.min}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Max"} labelCol={{ span: 24 }}>
                  <InputNumber
                    onChange={(v) => {
                      settings.max = v;
                      this.props.onChange(value);
                    }}
                    style={{ width: "100%" }}
                    value={settings.max}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {type === "dropdown" && (
          <Form.Item label={"Mode"}>
            <Select
              value={settings.mode}
              onChange={(v) => {
                settings.mode = v;
                this.props.onChange(value);
              }}
            >
              <Select.Option value={"default"}>Default</Select.Option>
              <Select.Option value={"multiple"}>Multiple</Select.Option>
            </Select>
          </Form.Item>
        )}
        {(type === "dropdown" || type === "radio" || type === "checkbox") && (
          <React.Fragment>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={"Options"}
            >
              {options.map((option, index) => (
                <div className={"item"} key={index}>
                  {isSimpleOption ? (
                    <div className={"simple-option"}>
                      <Input
                        placeholder={`Option ${index + 1}`}
                        onChange={(e) => {
                          options[index] = e.target.value;
                          this.props.onChange(value);
                        }}
                        value={option}
                      />
                    </div>
                  ) : (
                    <div className={"advance-option"}>
                      <Input
                        onChange={(e) => {
                          option.value = e.target.value;
                          this.props.onChange(value);
                        }}
                        value={option.value}
                        style={{ marginRight: 5 }}
                        placeholder={`Key ${index + 1}`}
                      />
                      <Input
                        onChange={(e) => {
                          option.label = e.target.value;
                          this.props.onChange(value);
                        }}
                        value={option.label}
                        style={{ marginLeft: 5 }}
                        placeholder={`Label ${index + 1}`}
                      />
                    </div>
                  )}
                  <Button
                    onClick={() => {
                      options.splice(index, 1);
                      this.props.onChange(value);
                    }}
                    className={"btn-danger"}
                    icon={<DeleteOutlined />}
                    type="link"
                  />
                </div>
              ))}
            </Form.Item>

            <Button
              onClick={() => {
                const len = options.length + 1;
                if (isSimpleOption) {
                  options.push(`Option ${len}`);
                } else {
                  options.push({
                    key: `key-${len}`,
                    label: `Label ${len}`,
                  });
                }
                this.props.onChange(value);
              }}
              size="small"
              style={{ width: "100%" }}
              className={"action"}
            >
              Add New Option
            </Button>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

FieldConfigureOptions.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FieldConfigureOptions;
