import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
  Spinner,
  Toast,
} from "@shopify/polaris";
import { filter, size, values } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";
import { LIST_TEAM } from "../../components/members/TeamMembersPolaris";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { ProductBaseSelectPolaris } from "../base/ProductBaseSelectPolaris";
import { ProductDivisionSelectPolaris } from "../product/divisions/ProductDivisionSelectPolaris";
import { AddDivisionPolaris } from "./actions/AddDivisionPolaris";

const FIELDS_REQUIRED = {
  title: "Title",
  divisionId: "Division",
};

const Container = styled.div`
  .btns-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3rem;
  }
  .divisions-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 1.6rem;
    .division-control {
      flex: 1 1;
    }
  }
`;

export const RecreateMockupFormPolaris = ({
  onSubmit,
  loading,
  handleRedirect,
  value,
  isEditRM,
}) => {
  // State
  const [basicFields, setBasicFields] = useState({
    title: null,
    productBaseIds: [],
    divisionId: null,
    totalOrder: 1,
    authorId: null,
    // limit: 0,
  });
  const [hasDesign, setHasDesign] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [showAD, setShowAD] = useState(false);
  const [newDivisions, setNewDivisions] = useState([]);
  const [errors, setErrors] = useState({});
  const [toastActive, setToastActive] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Query
  const { loading: loadingAuth } = useQuery(LIST_TEAM, {
    onCompleted: (res) => {
      handleAuthorData(res);
    },
    onError: () => {},
    variables: {
      filter: {
        limit: 1000,
        offset: 0,
      },
    },
  });

  // Get data
  const handleAuthorData = useCallback((data) => {
    let newAuthors =
      data?.teamMembers?.hits?.length > 0
        ? data.teamMembers.hits
            .map((item) => {
              let user = item?.user;
              if (user) {
                return {
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                };
              }
              return null
            })
            .filter(Boolean)
        : [];
    setAuthors(newAuthors);
  }, []);

  useEffect(() => {
    if (value) {
      let { __typename, divisionId, hasDesign, ...rest } = value;
      let newDivisionId = Array.isArray(divisionId) ? divisionId : [divisionId];
      setBasicFields((prev) => ({
        ...prev,
        ...rest,
        divisionId: newDivisionId,
      }));

      setHasDesign(hasDesign);
    }
  }, [value]);

  // Handle action
  const toggleActive = useCallback(() => setToastActive((prev) => !prev), []);
  const validateField = useCallback((value, id) => {
    let error = null;
    let label;

    for (let [key, value] of Object.entries(FIELDS_REQUIRED)) {
      if ([key].includes(id)) {
        label = value;
      }
    }
    if ((!value || !value.length) && label) {
      error = `${label} is required.`;
    }

    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);
  const handleInputChange = useCallback((value, id) => {
    validateField(value, id);
    setBasicFields((prev) => ({ ...prev, [id]: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductBaseChange = useCallback((value) => {
    handleInputChange(value, "productBaseIds");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleProductDivision = useCallback((value) => {
    handleInputChange(value, "divisionId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(() => {
    for (let key of Object.keys(FIELDS_REQUIRED)) {
      if (!isEditRM) {
        validateField(basicFields[key], key);
      } else {
        if (![key].includes("divisionId")) {
          validateField(basicFields[key], key);
        }
      }
    }

    let { title, divisionId, totalOrder, authorId } = basicFields;
    totalOrder = +totalOrder;
    let count = size(filter(values(errors), (e) => e !== null));

    let newdivisionId = Array.isArray(divisionId) ? divisionId[0] : divisionId;
    if (title && newdivisionId && count === 0) {
      if (onSubmit) {
        onSubmit({
          ...basicFields,
          divisionId: newdivisionId,
          totalOrder,
          hasDesign,
          authorId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields, isEditRM, hasDesign]);

  const toggleShowAD = useCallback(() => setShowAD((prev) => !prev), []);

  // Markup
  const textFieldMarkup = ({ id, placeholder = "", type = "text" }) => {
    let value = "text" === type ? basicFields[id] : basicFields[id].toString();
    return (
      <TextField
        id={id}
        onChange={handleInputChange}
        value={value}
        placeholder={placeholder}
        error={errors[id]}
        type={type}
        min={0}
      />
    );
  };

  const title = textFieldMarkup({ id: "title", placeholder: "Enter title" });
  const totalOrder = textFieldMarkup({
    id: "totalOrder",
    type: "number",
  });
  // const limit = textFieldMarkup({
  //   id: "limit",
  //   type: "number",
  // });

  const toastMarkup = toastActive && notification.message && (
    <Toast
      content={notification.message}
      error={notification.isError}
      onDismiss={toggleActive}
      duration={2000}
    />
  );

  return (
    <Container>
      {toastMarkup}
      <Card sectioned>
        <FormLayout>
          <div>
            <ComponentLabelPolaris label="Title" required />
            {title}
          </div>
          <ProductBaseSelectPolaris
            value={basicFields["productBaseIds"]}
            allowMultiple
            onChange={handleProductBaseChange}
          />
          {loadingAuth ? (
            <Spinner size="small" />
          ) : (
            <AutoCompletePolaris
              options={authors || []}
              label="Author"
              placeholder="Choose author"
              value={basicFields["authorId"]}
              onChange={(value) =>
                setBasicFields((prev) => ({ ...prev, authorId: value }))
              }
            />
          )}

          {/* {!isEditRM && ( */}
          <div className="divisions-wrap">
            <div className="division-control">
              <ProductDivisionSelectPolaris
                value={basicFields["divisionId"]}
                onChange={handleProductDivision}
                error={errors["divisionId"]}
                isRecreateMockup
                newDivisions={newDivisions}
              />
            </div>
            <div>
              <Button children="Add Division" onClick={toggleShowAD} />
            </div>
            <AddDivisionPolaris
              open={showAD}
              toggleShowModal={toggleShowAD}
              toggleActive={toggleActive}
              onNotificationChange={({ message, isError }) => {
                setNotification((prev) => ({ ...prev, message, isError }));
              }}
              onCompleted={(value) => {
                let divisionId = value?.length > 0 ? value[0].value : null;
                if (divisionId) {
                  setBasicFields((prev) => ({
                    ...prev,
                    divisionId: [divisionId],
                  }));
                }
                setNewDivisions((prev) => [...prev, ...value]);
              }}
            />
          </div>
          {/* )} */}
          <div>
            <ComponentLabelPolaris label="Min order" required />
            {totalOrder}
          </div>
          {/* <div>
            <ComponentLabelPolaris label="Limit" />
            {limit}
          </div> */}
          {/* <Checkbox
            label="Products must have designs"
            checked={hasDesign}
            onChange={(newValue) => setHasDesign(newValue)}
          /> */}
          <div className="btns-wrap">
            <ButtonGroup>
              <Button children="Cancel" onClick={handleRedirect} />
              <Button
                children="Submit"
                primary
                onClick={handleSubmit}
                loading={loading}
              />
            </ButtonGroup>
          </div>
        </FormLayout>
      </Card>
    </Container>
  );
};
