import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { Input, Skeleton, Table, Form, Card } from "antd";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import {
  handleError,
  getCookie,
  setCookie,
  convertStringToObject,
  convertObjectToParams,
  NumberToFixed,
} from "../../helper";
import DepositActions from "../../components/shared/DepositActions";
import Stats, { LOAD_STATS } from "../../components/billings/Stats";
import SupplierDepositsFilter from "../../components/supplier/SupplierDepositsFilter";
import _ from "lodash";
import history from "../../history";

const LOAD = gql`
  query transactons($filter: TransactionFilter) {
    transactions(filter: $filter) {
      total
      nodes {
        id
        amount
        createdAt
        journal {
          id
          refId
          comment
          status
        }
        account {
          id
          firstName
          lastName
        }
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const DEFAULT_FILTER = {
  limit: 30,
  offset: 0,
  status: null,
  search: null,
  kind: ["Deposit"],
  accountId: null,
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
class SupplierDepositsPage extends Component {
  state = {
    filter: {
      ...DEFAULT_FILTER,
      limit: +getCookie("perPageDeposits") || 30,
      ...convertStringToObject(history.location.search),
    },
    search: "",
    page: 1,
    account: null,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      const params = convertObjectToParams({
        limit: nextState.filter.limit,
        offset: nextState.filter.offset,
        search: nextState.filter.search,
      });
      history.push(history.location.pathname + "?" + params);
    }
  }

  handleUpdateStats = (client, status, amount) => {
    try {
      const cache = client.readQuery({
        query: LOAD_STATS,
      });
      client.writeQuery({
        query: LOAD_STATS,
        data: {
          ...cache.statistic,
          statistic: {
            ...cache.statistic,
            balance:
              status === "Approved"
                ? cache.statistic.balance + amount
                : cache.statistic.balance,
            pendingDeposit: cache.statistic.pendingDeposit - amount,
          },
        },
      });
    } catch (_) {
      // console.log(e);
    }
  };

  render() {
    const { filter } = this.state;
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 120,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount) => {
          let result = NumberToFixed(amount, 2);
          return <span>${result}</span>;
        },
        width: 100,
      },
      {
        title: "Transaction ID",
        dataIndex: ["journal", "refId"],
        width: 120,
      },
      {
        title: "Note",
        dataIndex: ["journal", "comment"],
        width: 250,
      },
      {
        title: "From",
        dataIndex: "creator",
        width: 160,
        render: (_, record) => (
          <div>{record.creator ? record.creator.firstName : "MerchBridge"}</div>
        ),
      },
      {
        title: "Status",
        dataIndex: ["journal", "status"],
        width: 120,
      },
      {
        title: "Created",
        width: 120,
        render: (_, record) => (
          <div>{moment(record.createdAt).format("LL")}</div>
        ),
      },
      {
        title: "",
        width: 120,
        align: "right",
        fixed: "right",
        render: (_, record) =>
          record.journal.status === "Pending" && (
            <DepositActions
              onChange={(client, status) => {
                this.handleUpdateStats(client, status, record.amount);
                try {
                  const cache = client.readQuery({
                    query: LOAD,
                    variables: { filter },
                  });
                  client.writeQuery({
                    query: LOAD,
                    variables: {
                      filter,
                    },
                    data: {
                      ...cache,
                      transactions: {
                        ...cache.transactions,
                        nodes: cache.transactions.nodes.map((n) => {
                          if (n.id === record.id) {
                            return {
                              ...n,
                              journal: {
                                ...n.journal,
                                status: status,
                              },
                              journals: {},
                            };
                          }
                          return n;
                        }),
                      },
                    },
                  });
                } catch (_) {
                  // console.log(e);
                }
              }}
              value={record}
            />
          ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div>
        <PageTitle title={"All Deposits"} />
        <Form {...layout} style={{ marginBottom: 15 }} className={"filter"}>
          <Stats />
        </Form>

        <Card bodyStyle={{ padding: 0 }}>
          <Form.Item className="px-4 pt-4">
            <Input.Search
              defaultValue={filter.search}
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      search: null,
                    },
                  });
                }
              }}
              onSearch={(v) =>
                this.setState({ filter: { ...filter, search: v } })
              }
              placeholder={"Search transactions"}
              addonBefore={
                <SupplierDepositsFilter
                  value={{
                    status: this.state.filter.status,
                    supplier: this.state.account,
                  }}
                  onChange={(v) => {
                    this.setState({
                      filter: {
                        ...this.state.filter,
                        status: v.status,
                        accountId: v.supplier ? v.supplier.id : null,
                      },
                      account: v.supplier,
                    });
                  }}
                />
              }
            />
          </Form.Item>
          <Query
            query={LOAD}
            variables={{
              filter,
            }}
          >
            {({ error, loading, data }) => {
              if (error)
                return <div>Error: {handleError(error.toString())}</div>;
              if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );
              return (
                <Table
                  pagination={{
                    current: filter.offset / filter.limit + 1,
                    pageSize: filter.limit,
                    total: data.transactions.total,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page,
                        filter: {
                          ...filter,
                          offset: pageSize * (page - 1),
                        },
                      });
                    },
                    onShowSizeChange: (_, size) => {
                      this.setState({
                        page: 1,
                        filter: {
                          ...filter,
                          limit: size,
                          offset: 0,
                        },
                      });
                      setCookie("perPageDeposits", size, 100);
                    },
                  }}
                  dataSource={data.transactions.nodes}
                  rowKey={(_, index) => index}
                  columns={columns}
                  scroll={{ x: tableWidth }}
                />
              );
            }}
          </Query>
        </Card>
      </div>
    );
  }
}

export default SupplierDepositsPage;
