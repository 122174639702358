import React, { lazy, Suspense } from "react";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { WrapperPage } from "../../components/shared/WrapperPage";

const DesignTeams = lazy(() =>
  import("../../components/design-team/DesignTeams"),
);

export default function DesignTeamsPage() {
  return (
    <WrapperPage>
        <Suspense fallback={SkeletonPagePolaris}>
          <DesignTeams />
        </Suspense>
    </WrapperPage>
  );
}
