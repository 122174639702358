import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { REMOVE_TASK_FROM_CONFIGURABLE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export default function BulkRemoveTaskConfigurable({
  open,
  toggleShowModal,
  toggleActive,
  onNotificationChange,
  idsChecked,
  onCompleted,
}) {
  let timeoutRef = React.useRef(null);
  const [removeTaskConfigurable, { loading }] = useMutation(
    REMOVE_TASK_FROM_CONFIGURABLE,
    {
      onError: (error) => {
        onNotificationChange &&
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
      },
      onCompleted: () => {
        onNotificationChange &&
          onNotificationChange({
            message: "Remove from task configurable success.",
            isError: false,
          });

        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          onCompleted && onCompleted();
        }, 2000);
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    onNotificationChange &&
      onNotificationChange({
        message: null,
        isError: false,
      });

    toggleActive && toggleActive();

    removeTaskConfigurable({
      variables: {
        taskIDs: idsChecked,
      },
    });
  }, [
    removeTaskConfigurable,
    idsChecked,
    toggleActive,
    toggleShowModal,
  ]);

  return (
    <Modal
      title="Remove Task From Configurable"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure want to remove Task from Configurable?</p>
    </Modal>
  );
}
