import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@shopify/polaris";
const MediaImportUrls = (props) => {
  const [value, setValue] = useState("");
  return (
    <div style={{ minHeight: "60rem" }}>
      <TextField
        value={value}
        onChange={(v) => {
          setValue(v);
          props.onChange(v);
        }}
        helpText={"Enter each image url per line"}
        multiline={4}
      />
    </div>
  );
};

MediaImportUrls.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default MediaImportUrls;
