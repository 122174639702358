import React from "react";
import { OrdersPolaris } from "../../components/order/OrdersPolaris";
import { ORDER_FILTER_SUPPLIER } from "../../constants";
import { getPathFromRouter } from "../../helper";
import { USER_ROLE } from "../../variable";

export const SupplierOrdersPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <OrdersPolaris
            role={USER_ROLE.Supplier}
            ordersFilter={ORDER_FILTER_SUPPLIER}
            path={path}
        />
    );
};
