import { useMutation, useQuery } from "@apollo/react-hooks";
import { ColorPicker, Stack } from "@shopify/polaris";
import { Input, notification } from "antd";
import { get } from "lodash";
import React from "react";
import { SETTING_VARIABLES } from "../../../constants";
import { useAppContext } from "../../../context";
import { SET_VARIABLE } from "../../../graphql/mutations";
import { GET_VARIABLES } from "../../../graphql/queries";
import { getTeamID, handleError, hsbToRgb, rgbToHsbl } from "../../../helper";

export default React.forwardRef(function BackgroundImage({ setLoading }, ref) {
  const { currentUser, setBackgroundImage } = useAppContext();
  const teamId = getTeamID(currentUser);

  const [color, setColor] = React.useState({
    hue: 0,
    brightness: 100,
    saturation: 0,
  });
  const [inputVal, setInputVal] = React.useState("");

  const variableKey = React.useMemo(
    () => (teamId ? `${teamId}${SETTING_VARIABLES.BG}` : null),
    [teamId],
  );

  const { data } = useQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
    skip: !variableKey,
  });

  const [setVariables] = useMutation(SET_VARIABLE, {
    onCompleted: (res) => {
      notification.success({ message: "Update background image success" });
      const val = get(res, "setVariable.value");
      if (setBackgroundImage) {
        setBackgroundImage(val ? val : inputVal);
      }
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  const colorRef = React.useRef(false);

  React.useEffect(() => {
    const val = data?.variables?.length > 0 ? data.variables[0] : null;
    if (val?.value) {
      setInputVal(val.value);
      const color = String(val.value).replace(/rgb\(|\)/gi, "");
      if (color?.length > 0) {
        const [red, green, blue] = color
          .split(",")
          .map((i) => i.trim())
          .filter(Boolean);
        if (red && green && blue) {
          const { hue, brightness, saturation } = rgbToHsbl({
            red,
            green,
            blue,
          });

          setColor({
            hue,
            brightness,
            saturation,
          });
        }
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (color != null && colorRef.current) {
      const res = hsbToRgb(color);
      if (res != null) {
        const { green, red, blue } = res;
        const result = `rgb(${red},${green},${blue})`;
        setInputVal(result);
      }
    }
  }, [color]);

  const handleSubmit = React.useCallback(async () => {
    if (!colorRef.current) return;

    setLoading(true);
    await setVariables({
      variables: {
        input: {
          key: variableKey,
          value: inputVal,
        },
      },
    }).finally(() => {
      setLoading(false);
      colorRef.current = false;
    });
  }, [variableKey, setVariables, setLoading, inputVal]);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <Stack vertical>
      <ColorPicker
        color={color}
        onChange={(...args) => {
          setColor(...args);
          colorRef.current = true;
        }}
      />

      <div>
        <label>Color code</label>
        <Input value={inputVal} readOnly disabled />
      </div>
    </Stack>
  );
});
