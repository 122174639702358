import { Mutation } from "@apollo/react-components";
import { Button, notification } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import { AppContext } from "../../../context";
import { productBaseFragment } from "../../../fragments";
import { getParamByRole, handleError } from "../../../helper";
import history from "../../../history";
import ProductBaseSelect from "../../seller/ProductBaseSelect";
import PageTitle from "../../shared/PageTitle";
import AddForCampaignForm from "./AddForCampaignForm";
import { LIST_TEMPLATE } from "./TemplatesPolaris";

const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: NewTemplate!) {
    createTemplate(input: $input) {
      id
      name
      slug
      grouping
      data {
        personalized
        productBases {
          ...ProductBasse
        }
        ProductBaseIds
        fields {
          name
          title
          type
          extraFee
          configure
          sorting
        }
        products {
          title
          description
          productbaseId
          shortDescription
          tagIds
          tags {
            id
            name
          }
          variants {
            salePrice
            regularPrice
            productBaseVariantID
          }
          collectionIds
          collections {
            id
            name
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;
export class AddForCampaign extends Component {
  static contextType = AppContext;
  state = {
    step: 1,
    selectedProductBases: [],
    fulfillmentId: null,
    loading: false,
  };
  handleOnProductBaseChange = (selectedProductBases) => {
    this.setState({
      selectedProductBases,
      step: selectedProductBases.length === 0 ? 1 : this.state.step,
    });
  };

  render() {
    const { step, selectedProductBases } = this.state;

    const { currentUser } = this.context;
    const currentParam = getParamByRole(currentUser);

    return (
      <div>
        {step === 1 && (
          <div>
            <PageTitle
              action={
                <Button
                  onClick={() => {
                    if (selectedProductBases.length === 0) {
                      notification.error({
                        message: "Product base is required!",
                      });
                      return;
                    }
                    this.setState({ step: 2 });
                  }}
                  type="primary"
                  disabled={!selectedProductBases.length || selectedProductBases.length === 1} // required length more than one
                >
                  Continue
                </Button>
              }
              link={`/${currentParam}/templates`}
              subTitle={"Templates"}
              title={"Add Template for Campaign"}
            />
            <ProductBaseSelect
              selectedProductBases={this.state.selectedProductBases}
              onFulfillmentChange={(v) => this.setState({ fulfillmentId: v })}
              fulfillmentId={this.state.fulfillmentId}
              noVariantDisabled={true}
              value={selectedProductBases}
              onChange={this.handleOnProductBaseChange}
              multiple={true}
              onViewTemplates={() => {
                this.setState({
                  step: 3,
                });
              }}
            />
          </div>
        )}
        {step === 2 && (
          <Mutation
            mutation={CREATE_TEMPLATE}
            onError={(e) => {
              notification.error({
                message: handleError(e.toString()),
              });
              this.setState({ loading: false });
            }}
            onCompleted={(res) => {
              const template = res.createTemplate;
              try {
                const client = window.__apolloClient__;
                if (client == null) return;
                const variables = {
                  filter: {
                    limit: 20,
                    offset: 0,
                    product: null,
                  },
                };
                const cache = client.readQuery({
                  query: LIST_TEMPLATE,
                  variables,
                });
                client.writeQuery({
                  query: LIST_TEMPLATE,
                  variables,
                  data: {
                    ...cache,
                    templates: {
                      ...cache.templates,
                      total: cache.templates.total + 1,
                      nodes: [template, ...cache.templates.nodes],
                    },
                  },
                });
              } catch (_) {}
              notification.success({
                message: "Template has been saved!",
              });
              history.push(`/${currentParam}/templates`);
            }}
          >
            {(createTemplate) => (
              <AddForCampaignForm
                currentParam={currentParam}
                loading={this.state.loading}
                onViewTemplates={() => {
                  this.setState({ step: 3 });
                }}
                onAddMore={() => {
                  this.setState({
                    step: 1,
                  });
                }}
                onChange={this.handleOnProductBaseChange}
                productBases={selectedProductBases}
                onSubmit={(input) => {
                  this.setState({ loading: true }, () => {
                    createTemplate({
                      variables: {
                        input,
                      },
                    });
                  });
                }}
              />
            )}
          </Mutation>
        )}
      </div>
    );
  }
}
