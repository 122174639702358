import React, { useState, useCallback, useEffect } from "react";
import {
    Button,
    TextStyle,
    DropZone,
    Stack,
    Spinner,
    Icon,
    Toast,
} from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import axios from "axios";
import styled from "styled-components";

import { MediaPolaris, LOAD_MEDIA_QUERY } from "./MediaPolaris";
import { PreviewFilePolaris } from "./PreviewFilePolaris";
import {
    CREATE_FILE_MUTATION,
    REQUEST_MUTATION,
} from "../file/UploadFilePolaris";
// import { useMutation } from "@apollo/react-hooks";
import { TUMBLER_BASE_IDS } from "../../variable";
// import { DELETE_FILE } from "../../graphql/mutations";

const IMPORT_FILE_URLS_MUTATION = gql`
    mutation importFileUrl($urls: [String!]!, $prefix: String) {
        importFileUrl(urls: $urls, prefix: $prefix) {
            id
            name
            alt
            caption
            thumbnailUrl
            url
            mimeType
            size
            createdAt
        }
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;

    .list-file_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        height: 28rem;

        &.multiple {
            height: auto;
            flex-direction: row;
            column-gap: 2rem;
            justify-content: flex-start;
            flex-wrap: wrap;

            .file_wrap {
                height: ${({ height }) => (height ? `${height}px` : "auto")};
                width: 100%;
            }

            .file-item {
                width: auto;
                width: ${({ width }) => (width ? `${width}px` : "auto")};
                overflow: hidden;
                object-fit: cover;
                position: relative;
                padding: 0.2rem;

                .Polaris-Choice {
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    z-index: 10;
                    padding: 0;

                    .Polaris-Choice__Control {
                        margin: 0;
                    }
                }
            }
        }
    }

    .dropzone_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        .dropzone_inner {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }

    .uploaded-wrap {
        display: block;
        margin-top: 2rem;
    }

    .image-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .image-title-wrap {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
        }
    }

    .design-position-name {
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

    .dropzone_container {
        .Polaris-DropZone {
            min-height: ${(props) => (props.sizeSmall ? "10rem" : "")};
        }
    }
`;

const MIME_TYPE_SPECs = ["application/pdf", "application/postscript"];

export const MediaSelectorButton2Polaris = (props) => {
    const {
        multiple,
        showViewOrigin,
        isUpdateDesign,
        dPName,
        accept,
        type,
        folder,
        isPrintFile,
        typeSpecial,
        placeholderImage,

        isCompareDesign,
        designPosition,
        sizeSmall,
        mainImageId,
        setMainImage: setMainImageProp,
        hasMainImg,
        productBaseId,
        onChange: onChangeProp,
        value: valueProp,
        hideBtnRemove,
        showTitle,
        width = 100,
        height = 100,
        fromIdeaTask = false,
    } = props;
    // State
    const [value, setValue] = useState([]);
    const [importUrls, setImportUrls] = useState("");
    const [showLibrary, setShowLibrary] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState({});
    const [isRemoved, setIsRemoved] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);

    const [, setMainImage] = useState({});
    const [dimensions, setDimensions] = useState({});
    const [toastActive, setToastActive] = useState(false);
    const [notify, setNotify] = useState({
        msg: null,
    });

    const onChangeRef = React.useRef(null);

    // Mutation
    // const [deleteFile] = useMutation(DELETE_FILE, {
    //     onError: () => {},
    // });

    useEffect(() => {
        if (Array.isArray(valueProp)) {
            setFileList(() => [...valueProp]);

            if (valueProp.length === 0) {
                setFiles([]);
            }
        }
    }, [valueProp]);

    useEffect(() => {
        if (!fileList.length) {
            if (showViewOrigin) {
                showViewOrigin();
            }
        }
    }, [fileList, showViewOrigin]);

    useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

    // Handle actions
    const toggleToastActive = useCallback(
        () => setToastActive((prev) => !prev),
        []
    );
    const toggleShowLibrary = useCallback(
        () => setShowLibrary((prev) => !prev),
        []
    );

    const setCache = useCallback(
        (client, values) => {
            let { userId } = props;
            const filter = {
                search: null,
                mimeType: [],
                dateFilter: {
                    from: null,
                    to: null,
                },
                limit: 20,
                offset: 0,
                prefix: props.prefix,
                ...(userId ? { userId } : null),
            };
            try {
                const cache = client.readQuery({
                    query: LOAD_MEDIA_QUERY,
                    variables: {
                        filter,
                    },
                });
                client.writeQuery({
                    query: LOAD_MEDIA_QUERY,
                    variables: {
                        filter,
                    },
                    data: {
                        ...cache,
                        mediaLibrary: {
                            ...cache.mediaLibrary,
                            total: cache.mediaLibrary.total + values.length,
                            nodes: [...values, ...cache.mediaLibrary.nodes],
                        },
                    },
                });
            } catch (e) {}
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.userId, props.prefix]
    );

    const handleSetFile = useCallback(
        (client) => {
            let { prefix, multiple } = props;
            let propsValue = valueProp ? valueProp : [];
            let files = value;
            let v = [];
            for (let i = 0; i < files.length; i++) {
                if (!propsValue.find((f) => f.id === files[i].id)) {
                    v.push(files[i]);
                }
            }

            const newValue = multiple ? [...propsValue, ...v] : [...v];
            let newImportUrls = [];
            let mapUrl = {};
            importUrls.split("\n").forEach((line) => {
                const url = line && line.trim();
                if (url) {
                    if (!mapUrl[url]) {
                        newImportUrls.push(url);
                        mapUrl[url] = true;
                    }
                }
            });

            if (newImportUrls.length) {
                setLoadingImport(true);
                client
                    .mutate({
                        mutation: IMPORT_FILE_URLS_MUTATION,
                        variables: {
                            urls: newImportUrls,
                            prefix: prefix,
                        },
                    })
                    .then((res) => {
                        setCache(client, res.data.importFileUrl);
                        const values = [...res.data.importFileUrl, ...newValue];
                        setImportUrls("");
                        setLoadingImport(false);

                        const id = setTimeout(() => {
                            setFileList(props.multiple ? values : [values[0]]);
                            setValue([]);
                            toggleShowLibrary();
                            if (onChangeProp) {
                                onChangeProp(
                                    props.multiple ? values : [values[0]]
                                );
                            }
                        }, 1000);
                        setTimeoutId(id);
                    })
                    .catch(() => {
                        setLoadingImport(false);
                    });
            } else {
                setLoadingImport(false);
                setFileList(newValue);
                if (onChangeProp) {
                    onChangeProp(newValue);
                }
                setValue([]);
                toggleShowLibrary();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props, value, importUrls]
    );

    const handleRemove = useCallback(
        (id) => {
            setIsRemoved(true);
            setFileList((prev) => {
                let newFiles = prev.filter((i) => i.id !== id);
                if (onChangeProp) {
                    onChangeProp(newFiles);
                }
                return newFiles;
            });
            // deleteFile({
            //   variables: {
            //     id,
            //     deleteAmz: true,
            //   },
            // });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onChangeProp]
    );

    // Handle drop
    const handleDrop = useCallback(
        (
            _droppedFiles,
            acceptedFiles,
            _rejectedFiles,
            createUploadUrl,
            client
        ) => {
            setFiles((files) => {
                if (multiple) {
                    return [...files, ...acceptedFiles];
                } else {
                    return [...acceptedFiles];
                }
            });
            setIsRemoved(false);

            let newFiles = acceptedFiles;
            for (let i = 0; i < newFiles.length; i++) {
                customRequest(newFiles[i], i, createUploadUrl, client);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productBaseId]
    );

    const customRequest = useCallback(
        (file, index, createUploadUrl, client) => {
            setLoading((prevState) => ({ ...prevState, [index]: true }));
            toggleToastActive(true);
            checkMimeType(file, async (mime) => {
                let fileType = file.type;
                let fileName = file.name;
                if (mime) {
                    fileType = mime;
                    switch (mime) {
                        case "image/webp":
                            fileName = fileName.replace(/\.[^/.]+$/, ".webp");
                            break;
                        case "image/gif":
                            fileName = fileName.replace(/\.[^/.]+$/, ".gif");
                            break;
                        case "image/jpeg":
                            fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
                            break;
                        case "image/png":
                            fileName = fileName.replace(/\.[^/.]+$/, ".png");
                            break;
                        default:
                            break;
                    }
                }
                try {
                    let isTumbler = TUMBLER_BASE_IDS.includes(productBaseId);
                    let canCreateFile = false;
                    if (isTumbler) {
                        if ("image/png" === fileType) {
                            canCreateFile = true;
                        }
                    } else {
                        canCreateFile = true;
                    }
                    if (canCreateFile) {
                        setNotify(() => ({ msg: null }));
                        const res = await createUploadUrl({
                            variables: {
                                input: {
                                    name: fileName,
                                    mimeType: fileType,
                                    size: file.size,
                                    folder: folder ? folder : null,
                                },
                            },
                        });
                        const { key, url } = res.data.createUploadUrl;
                        await axios.put(url, file, {
                            headers: { "Content-Type": fileType },
                            timeout: 1000 * 60 * 30,
                        });
                        const fileResponse = await client.mutate({
                            mutation: CREATE_FILE_MUTATION,
                            variables: {
                                input: {
                                    name: fileName,
                                    key: key,
                                    size: file.size,
                                    mimeType: fileType,
                                    isPrintFile: isPrintFile,
                                },
                            },
                        });
                        let response = [];

                        if (fileResponse) {
                            response.push(fileResponse.data.createFile);
                            setLoading((prevState) => ({
                                ...prevState,
                                [index]: false,
                            }));
                        }
                        const id = setTimeout(() => {
                            setFileList((prevState) => {
                                let newFiles = [...prevState, ...response];
                                onChangeRef.current &&
                                    clearTimeout(onChangeRef.current);
                                onChangeRef.current = setTimeout(() => {
                                    if (onChangeProp) {
                                        onChangeProp(newFiles);
                                    }
                                }, 0);
                                return newFiles;
                            });
                        }, 1000);
                        setTimeoutId(id);
                    } else {
                        setLoading(false);
                        setNotify(() => ({ msg: "Allow file type is .png" }));
                        setFiles(() => []);
                    }
                } catch (e) {}
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productBaseId, onChangeProp]
    );

    const checkMimeType = (file, cb) => {
        const mimes = [
            {
                mime: "image/png",
                pattern: [0x89, 0x50, 0x4e, 0x47],
            },
            {
                mime: "image/jpeg",
                pattern: [0xff, 0xd8, 0xff],
            },
            {
                mime: "image/gif",
                pattern: [0x47, 0x49, 0x46, 0x38],
            },
            {
                mime: "image/webp",
                pattern: [
                    0x52,
                    0x49,
                    0x46,
                    0x46,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    0x57,
                    0x45,
                    0x42,
                    0x50,
                    0x56,
                    0x50,
                ],
            },
        ];
        const isMime = (bytes, mime) => {
            return mime.pattern.every((p, i) => !p || bytes[i] === p);
        };
        const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
        const blob = file.slice(0, numBytesNeeded);
        const reader = new FileReader();
        reader.onloadend = (e) => {
            if (!e || !reader.result) return cb(null);
            const bytes = new Uint8Array(reader.result);
            const mime = mimes.find((m) => isMime(bytes, m));
            return cb(mime ? mime.mime : null);
        };
        reader.readAsArrayBuffer(blob);
    };

    // Check dimension image
    useEffect(() => {
        for (let i = 0; i < fileList.length; i++) {
            let file = fileList[i];
            if (file) {
                imageLoadHandler(
                    isCompareDesign,
                    file.url,
                    file.id,
                    designPosition,
                    file.mimeType
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList, isCompareDesign, designPosition]);

    const imageLoadHandler = useCallback(
        (isCompareDesign, originUrl, imgIndex, designPosition, mimeType) => {
            const { onCheckDimensions, onCheckingDimensions } = props;
            if (MIME_TYPE_SPECs.includes(mimeType)) {
                // Disable check dimension.
                if (onCheckingDimensions) {
                    onCheckingDimensions(false);
                }
                if (onCheckDimensions) {
                    onCheckDimensions(true);
                }
            } else {
                if (isCompareDesign) {
                    let img = new Image();
                    img.src = originUrl;
                    if (onCheckingDimensions) {
                        onCheckingDimensions(true);
                    }
                    img.onload = () => {
                        let newDimensions = dimensions;
                        if (!newDimensions[imgIndex]) {
                            let height = img?.height;
                            let width = img.width;

                            newDimensions[imgIndex] = {
                                id: imgIndex,
                                height,
                                width,
                                isRealDimensionOk: checkRealDimension(
                                    designPosition,
                                    width,
                                    height
                                ),
                            };
                            setDimensions(() => {
                                if (onCheckDimensions) {
                                    onCheckDimensions(newDimensions, imgIndex);
                                }
                                return { ...newDimensions };
                            });
                        }
                        if (img?.complete && img?.naturalHeight !== 0) {
                            if (onCheckingDimensions) {
                                onCheckingDimensions(false);
                            }
                        }
                    };
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dimensions]
    );

    const checkRealDimension = useCallback(
        (designPosition, realWidth, realHeight) => {
            let realDmOk = false;
            if (
                designPosition &&
                designPosition.description &&
                designPosition.description.length > 0 &&
                realWidth &&
                realHeight
            ) {
                let originDesc = designPosition.description;

                realWidth = parseInt(realWidth);
                realHeight = parseInt(realHeight);

                if (originDesc) {
                    let splitDesc = originDesc.split("|");
                    if (
                        Array.isArray(splitDesc) &&
                        splitDesc[0] &&
                        splitDesc[0].length > 0
                    ) {
                        let dimensionDesc = splitDesc[0];

                        // Case: no dimension
                        const dimensionValid = /px|cm|in/i;
                            if (!dimensionValid.test(dimensionDesc)) {
                            return true;
                        }
                        
                        dimensionDesc = dimensionDesc.replace(/,/g, "");
                        dimensionDesc = dimensionDesc.replace(/,/g, "");
                        dimensionDesc = dimensionDesc.replace(/px/g, "");
                        dimensionDesc = dimensionDesc.replace(/cm/g, "");
                        dimensionDesc = dimensionDesc.replace(/\s+/g, "");
                        if (dimensionDesc.length > 0) {
                            const likeX = "×";
                            let dmDesc = dimensionDesc.split("x");
                            if (Array.isArray(dmDesc) && 2 !== dmDesc.length) {
                                dmDesc = dimensionDesc.split(likeX);
                            }
                            if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
                                let splitWidth =
                                    dmDesc && dmDesc[0]
                                        ? parseInt(dmDesc[0])
                                        : 0;
                                let splitHeight =
                                    dmDesc && dmDesc[1]
                                        ? parseInt(dmDesc[1])
                                        : 0;
                                if (
                                    splitWidth > 0 &&
                                    splitHeight > 0 &&
                                    splitWidth === realWidth &&
                                    splitHeight === realHeight
                                ) {
                                    realDmOk = true;
                                }
                            }
                        }
                    }
                }
            }
            return realDmOk;
        },
        []
    );

    // Drop markup
    const uploadedFiles =
        files.length > 0 ? (
            <Stack vertical>
                {files.map((file, index) => (
                    <div className="image-wrap" key={`image-${index}`}>
                        <div className="image-title-wrap">
                            {loading[index] ? (
                                <Spinner size="small" />
                            ) : (
                                <Icon source={TickMinor} />
                            )}
                            <span>{file.name}</span>
                        </div>
                    </div>
                ))}
            </Stack>
        ) : null;

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={true}
            duration={2000}
            onDismiss={toggleToastActive}
        />
    );

    return (
        <Container sizeSmall={sizeSmall} width={width} height={height}>
            {toastMarkup}
            <Mutation mutation={REQUEST_MUTATION}>
                {(createUploadUrl, { client }) => (
                    <>
                        {multiple ||
                        typeSpecial ||
                        (!multiple && !fileList.length > 0) ? (
                            <div className="dropzone_container">
                                {dPName && (
                                    <div className="design-position-name">
                                        {dPName}
                                    </div>
                                )}
                                <DropZone
                                    accept={accept}
                                    type={type}
                                    allowMultiple={multiple}
                                    onClick={() => {}}
                                    onDrop={(
                                        droppedFiles,
                                        acceptedFiles,
                                        rejectedFiles
                                    ) =>
                                        handleDrop(
                                            droppedFiles,
                                            acceptedFiles,
                                            rejectedFiles,
                                            createUploadUrl,
                                            client
                                        )
                                    }
                                >
                                    <div
                                        className="dropzone_wrap"
                                        style={{
                                            backgroundImage: `url("${
                                                placeholderImage?.thumbnailUrl ||
                                                placeholderImage?.url
                                            }")`,
                                            opacity: placeholderImage ? 0.7 : 1,
                                        }}
                                    >
                                        <div className="dropzone_inner">
                                            <Button
                                                children={`Media Library`}
                                                plain
                                                onClick={toggleShowLibrary}
                                            />
                                            <TextStyle>
                                                Or drop image to upload
                                            </TextStyle>
                                            {!isRemoved ? (
                                                <div className="uploaded-wrap">
                                                    {uploadedFiles}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </DropZone>
                                <MediaPolaris
                                    {...props}
                                    value={value}
                                    open={showLibrary}
                                    toggleShowModal={() => {
                                        toggleShowLibrary();
                                        setImportUrls(() => "");
                                    }}
                                    onChange={(files) => setValue(() => files)}
                                    onImportUrlsChange={(v) =>
                                        setImportUrls(() => v)
                                    }
                                    handleSetFile={handleSetFile}
                                    loadingImport={loadingImport}
                                />
                            </div>
                        ) : null}
                        {fileList && fileList.length > 0 ? (
                            <div
                                className={`list-file_wrap ${
                                    multiple || typeSpecial ? "multiple" : ""
                                }`}
                            >
                                {fileList.map((file, index) => {
                                    let isMainImg = mainImageId === file.id;
                                    return (
                                        <PreviewFilePolaris
                                            filesHaveFiles={(
                                                fileList || []
                                            ).map((f) => ({ file: f }))}
                                            fromIdeaTask={fromIdeaTask}
                                            file={file}
                                            index={index}
                                            key={index}
                                            handleRemove={handleRemove}
                                            isUpdateDesign={isUpdateDesign}
                                            dPName={dPName}
                                            isMainImg={isMainImg}
                                            hasMainImg={hasMainImg}
                                            mainImage={{ [file.id]: isMainImg }}
                                            hideBtnRemove={hideBtnRemove}
                                            showTitle={showTitle}
                                            setMainImage={(value) => {
                                                setMainImage({
                                                    [file.id]: value,
                                                });
                                                if (setMainImageProp) {
                                                    setMainImageProp(file.id);
                                                }
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                )}
            </Mutation>
        </Container>
    );
};
