import React from "react";
import { Stack, Heading, Icon } from "@shopify/polaris";
import { CashDollarMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { NumberToFixed } from "../../helper";

export const LOAD_STATS = gql`
  query statistic {
    statistic {
      balance
      paidAmount
      pendingCost
      pendingDeposit
    }
  }
`;

const Container = styled.div`
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .inner-box {
    background: #f9fafb;
    border-radius: 3px;
    padding: 1.6rem;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    transition: box-shadow 0.2s;
  }
  .icon-wrap {
    margin-right: 0.75rem;
    .Polaris-Icon {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const StatsPolaris = () => {
  const { data, error } = useQuery(LOAD_STATS, {
    fetchPolicy: "cache-and-network",
  });

  const dataStatistic = data && data.statistic;
  if (dataStatistic && dataStatistic.__typename) {
    delete dataStatistic.__typename;
  }

  const statsMarkup =
    dataStatistic &&
    Object.entries(dataStatistic).map(([property, count], idx) => {
      let heading = null;
      if ("balance" === property) {
        heading = "Account Balance";
      } else {
        heading = _.startCase(property);
      }
      count = NumberToFixed(count, 2);
      return (
        <div className="inner-box" key={`stats-${idx}`}>
          <Stack alignment="baseline" wrap={false}>
            <Stack.Item fill>
              <Stack spacing="tight" alignment="center" wrap={false}>
                <Stack.Item fill>
                  <Stack vertical spacing="tight">
                    <Stack.Item>
                      <Heading>{heading}</Heading>
                    </Stack.Item>
                    <Stack.Item>
                      <Stack spacing="tight" alignment="center">
                        <p
                          style={{
                            fontSize: "1.55rem",
                            fontWeight: "500",
                          }}
                        >
                          ${count}
                        </p>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <div className="icon-wrap">
                    <Icon source={CashDollarMajorMonotone} />
                  </div>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      );
    });

  if (error) return <div>Error: {error.toString()}</div>;

  return (
    <>
      <Stack vertical>
        <Container>{statsMarkup}</Container>
      </Stack>
    </>
  );
};
