import React, { useState, useEffect, useCallback, useRef } from "react";
import { Filters } from "@shopify/polaris";

import { isEmpty } from "../../helper";

import { MediaFilterByPrefixPolaris } from "./MediaFilterByPrefixPolaris";

export const MediaFilterPolaris = ({ onChange }) => {
  const [queryValue, setQueryValue] = useState(null);
  const [prefix, setPrefix] = useState({
    value: null,
    label: null,
  });
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          prefix: prefix.value,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, prefix]);

  const handleQueryValue = useCallback((value) => setQueryValue(value), []);
  const hanldeQueryRemove = useCallback(() => setQueryValue(null), []);

  const handlePrefixRemove = useCallback(() => {
    setPrefix({ value: null, label: null });
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    hanldeQueryRemove();
    handlePrefixRemove();
  }, [hanldeQueryRemove, handlePrefixRemove]);

  const filters = [
    {
      label: "Prefix",
      key: "prefix",
      filter: (
        <MediaFilterByPrefixPolaris
          prefix={prefix}
          onChange={({ value, label }) => {
            setPrefix((prevState) => {
              return {
                ...prevState,
                value: value,
                label: label,
              };
            });
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(prefix && prefix.label)) {
    let key = "prefix";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, prefix.label),
      onRemove: handlePrefixRemove,
    });
  }

  return (
    <Filters
      queryValue={queryValue}
      queryPlaceholder="Filter image"
      appliedFilters={appliedFilters}
      filters={filters}
      onQueryChange={handleQueryValue}
      onClearAll={handleFiltersClearAll}
      onQueryClear={hanldeQueryRemove}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "prefix":
        return `Prefix: ${value}`;
      default:
        return value;
    }
  }
};
