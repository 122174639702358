import React, { useCallback } from "react";
import { genMediaChild, ItemWrap, MediaComp } from "./IdeaSection";
import styled from "styled-components";
import { Heading, Labelled, Stack, TextField } from "@shopify/polaris";
import { UPLOAD_FOLDER } from "../../../../variable";
import { MediaSelectorButton2Polaris } from "../../../file/MediaSelectorButton2Polaris";
import { useAppContext } from "../../../../context";
import { checkRole } from "../../../../helper";

export function ProductDesignResources({
    canUpdate,
    item,
    setChanged,
    updateProduct,
    designerCanView,
    index,
    taskDone,
}) {
    // Props
    let { status, psdFiles = [], psd } = item;
   
    const psdFilesHaveFile = (psdFiles || []).map((f) => ({ file: f }));
    const psdFilesMarkup = genMediaChild(psdFiles);
    const itemStatusNull = status === null;

    // Context
    const { currentUser } = useAppContext();
    const {
        isDesigner,
        isDesignLeader,
        isIdeaLeader,
        isSeller,
        isStoreManager,
    } = checkRole(currentUser);

    // Actions
    const handleUpdate = useCallback(
        (newValue) => {
            setChanged && setChanged(true);
            updateProduct && updateProduct(index, newValue);
        },
        [index, setChanged, updateProduct]
    );

    const handleDRFChange = useCallback(
        (value) => {
            handleUpdate({ psdFiles: value });
        },
        [handleUpdate]
    );
    const handleChange = useCallback(
        (value, id) => {
            handleUpdate({ [id]: value });
        },
        [handleUpdate]
    );

    const roleDesign = isDesigner || isDesignLeader;
    const roleDesignUpdate = roleDesign && designerCanView; // is role designer and idea task status = "Done" & "Doing";
    const roleLeader = (isSeller || isStoreManager || isIdeaLeader) && taskDone;

    return !roleDesign || (roleDesign && designerCanView) ? (
        <Wrapper>
            <Heading children="Design Resources" />
            {canUpdate || itemStatusNull ? (
                <Stack distribution="fill">
                    <TextField
                        label="Design Resource Urls"
                        multiline={4}
                        id="psd"
                        value={psd}
                        onChange={handleChange}
                        placeholder="Enter design resource url"
                    />
                    <div className="files-wrap">
                        <Heading children="Design Resource Files" />
                        <MediaSelectorButton2Polaris
                            prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                            folder={UPLOAD_FOLDER.PSD_RESOURCE}
                            singleUpload={true}
                            multiple
                            // accept={"image/*"}
                            sizeSmall
                            value={psdFiles}
                            onChange={handleDRFChange}
                            showTitle
                            width={120}
                            height={120}
                            fromIdeaTask
                        />
                    </div>
                </Stack>
            ) : (
                <Stack distribution="fill">
                    {roleDesignUpdate || roleLeader ? (
                        <TextField
                            label="Design Resource Urls"
                            multiline={4}
                            id="psd"
                            value={psd}
                            onChange={handleChange}
                            placeholder="Enter design resource url"
                        />
                    ) : (
                        <ItemWrap label="Design Resource Urls">
                            <span
                                style={{
                                    wordBreak: "break-all",
                                    whiteSpace: "break-spaces",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: psd,
                                }}
                            />
                        </ItemWrap>
                    )}
                    <div>
                        <Labelled label="Design Resource Files:" />
                        {roleDesignUpdate || roleLeader ? (
                            <MediaSelectorButton2Polaris
                                prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                                folder={UPLOAD_FOLDER.PSD_RESOURCE}
                                singleUpload={true}
                                multiple
                                // accept={"image/*"}
                                sizeSmall
                                value={psdFiles}
                                onChange={handleDRFChange}
                                showTitle
                                width={120}
                                height={120}
                                fromIdeaTask
                            />
                        ) : (
                            <MediaComp files={psdFilesHaveFile}>
                                {psdFilesMarkup}
                            </MediaComp>
                        )}
                    </div>
                </Stack>
            )}
        </Wrapper>
    ) : null;
}

const Wrapper = styled.div`
    .files-wrap {
        .Polaris-Heading {
            margin-bottom: 0;
        }
    }

    .list-file_wrap {
        &.multiple {
            flex-direction: row;
            align-items: start;

            .file {
                &-item {
                    flex-direction: column;
                    row-gap: 0.5rem;
                    height: auto;
                }

                &-name {
                    display: -webkit-box;
                    max-width: 200px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    word-break: break-all;
                }
            }
        }
    }
`;
