import { useMutation, useQuery } from "@apollo/react-hooks";
import { ChoiceList, Modal, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React from "react";
import { UPDATE_CARRIER_FOR_SUP_CHINA } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

const ORDER_CARRIER_PRICINGS = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      carrier {
        id
      }
      productVariant {
        productBaseVariant {
          id
          carrierPricing {
            id
            price
            carrier {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export function UpdateCarrier({ value, onClose = () => {}, open, refetch }) {
  const { id } = value || {};

  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  // Context
  const { toggleToast, setNotify } = useToastContext();

  const { data, loading, error } = useQuery(ORDER_CARRIER_PRICINGS, {
    variables: {
      id,
    },
    skip: !id || !open,
  });

  const timeRef = React.useRef(null);
  const [updateCarrier, { loading: loadingM }] = useMutation(
    UPDATE_CARRIER_FOR_SUP_CHINA,
    {
      onCompleted: () => {
        setNotify({ msg: "Update carrier success.", err: false });
        if (refetch) {
          if (timeRef.current) clearTimeout(timeRef.current);
          timeRef.current = setTimeout(() => {
            refetch();
          }, 1200);
        }

        onClose && onClose(false);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err.toString()), err: true });
      },
    },
  );

  React.useEffect(() => {
    const opts =
      data?.order?.productVariant?.productBaseVariant?.carrierPricing || [];
    if (opts.length === 0) return;

    let defaultOpt = data.order?.carrier?.id;
    const options = opts
      .map((item) => {
        if (!item || !item.carrier) return;
        return {
          value: item.carrier.id,
          label: item.carrier.name,
        };
      })
      .filter(Boolean);

    if (defaultOpt) {
      setSelected([defaultOpt]);
    } else {
      if (options?.length > 0) {
        const [opt] = options;
        if (opt?.value) {
          setSelected([opt.value]);
        }
      }
    }
    setOptions(options);
  }, [data]);

  const handleSubmit = React.useCallback(() => {
    if (!id || !selected[0]) return null;
    toggleToast && toggleToast(true);
    setNotify({ msg: null, err: false });

    updateCarrier({
      variables: {
        orderID: id,
        carrierID: selected[0],
      },
    });
  }, [id, selected, updateCarrier]);

  return (
    <Modal
      title="Update Carrier"
      sectioned
      open={open}
      onClose={onClose}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loadingM,
      }}
    >
      {loading ? (
        <Spinner size="small" />
      ) : error ? (
        <div>Error:{error?.toString()}</div>
      ) : (
        <ChoiceList
          title="Carriers"
          choices={options}
          onChange={setSelected}
          selected={selected}
        />
      )}
    </Modal>
  );
}
