import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    useContext,
} from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { isEmpty, checkRole } from "../../helper";
import { AppContext } from "../../context";
import { TEAM_ROLE, USER_ROLE } from "../../variable";
import { FilterCreatedTimePolaris } from "../product/FilterCreatedTimePolaris";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { GET_PRODUCT_ASSORTMENT_TAXONOMY } from "../../graphql/queries";

const QUERY_ASSORTMENT_DOMAIN = gql`
    query productAssortmentDomains {
        productAssortmentDomains {
            total
            nodes {
                domain
            }
        }
    }
`;

export const QUERY_ASSORTMENT_AUTHORS = gql`
    query getAssortmentAuthors {
        getAssortmentAuthors {
            nodes {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const QUERY_ASSORTMENT_STORES = gql`
    query getAssortmentStores {
        getAssortmentStores {
            total
            nodes {
                id
                title
            }
        }
    }
`;

const TYPE_OPTIONS = [
    { value: "All", label: "All" },
    { value: "Api", label: "Api" },
    { value: "Url", label: "Url" },
    { value: "Struct", label: "Struct" },
];

const Collections = "Collections";
const Tags = "Tags";
const Niches = "Niches";

export const FilterAssortmentPolaris = (props) => {
    const { filter, onChange } = props;
    const [queryValue, setQueryValue] = useState(filter.search);
    const [type, setType] = useState({
        value: filter.type,
        label: null,
    });
    const [domains, setDomains] = useState([]);
    const [domain, setDomain] = useState({
        value: filter.domain,
        label: null,
        search: null,
    });
    const typingTimeoutRef = useRef(null);

    const [authors, setAuthors] = useState([]);
    const [author, setAuthor] = useState({
        value: filter.authorId,
        label: null,
        search: null,
    });
    const [filterTime, setFilterTime] = useState({
        range: null,
        rangeLabel: null,
    });

    const collectionProp = filter.collection;
    const [collections, setCollections] = useState([]);
    const [collection, setCollection] = useState({
        value: collectionProp,
        label: null,
    });

    const tagProp = filter.tag;
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState({
        value: tagProp,
        label: null,
    });

    const nicheProp = filter.niche;
    const [niches, setNiches] = useState([]);
    const [niche, setNiche] = useState({
        value: nicheProp,
        label: null,
    });

    // Context
    const { currentUser } = useContext(AppContext);
    const userRole = _.get(currentUser, "roles", []);
    const teamRole = _.get(currentUser, "teamUser.role", null);
    const { isIdea, isIdeaLeader } = checkRole(currentUser);
    const isRoleIdea = isIdea || isIdeaLeader;

    // Assortment stores
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState({
        value: filter.storeId || null,
        label: null,
        search: null,
    });

    let leadTeam = false;
    if (userRole.includes(USER_ROLE.Seller)) {
        if (
            [
                TEAM_ROLE.Admin,
                TEAM_ROLE.MarketplaceManager,
                TEAM_ROLE.StoreManager,
                TEAM_ROLE.Supporter,
            ].includes(teamRole)
        ) {
            leadTeam = true;
        }
    }

    // Queries
    const { data, loading, error } = useQuery(QUERY_ASSORTMENT_DOMAIN);
    const { data: dataAuth, loading: loadingAuth, error: errorAuth } = useQuery(
        QUERY_ASSORTMENT_AUTHORS
    );

    const {
        data: dataStore,
        loading: loadingStore,
        error: errorStore,
    } = useQuery(QUERY_ASSORTMENT_STORES);
    const { data: dataTaxonomy } = useQuery(GET_PRODUCT_ASSORTMENT_TAXONOMY);

    // Get data
    useEffect(() => {
        if (dataTaxonomy) {
            const newData = dataTaxonomy?.getProductAssortmentTaxonomy;
            const newTaxonomy = getTaxonomies(newData);
            const collections = newTaxonomy[Collections];
            const tags = newTaxonomy[Tags];
            const niches = newTaxonomy[Niches];

            setCollections(collections);
            setTags(tags);
            setNiches(niches);
        }
    }, [dataTaxonomy]);

    useEffect(() => {
        if (filter.domain) {
            let newDomain =
                domains && domains.length > 0
                    ? domains.find((d) => d.value === filter.domain)
                    : null;
            if (newDomain) {
                setDomain(() => ({
                    value: newDomain.value,
                    label: newDomain.label,
                }));
            }
        }
        if (filter.type) {
            let newType =
                TYPE_OPTIONS && TYPE_OPTIONS.length > 0
                    ? TYPE_OPTIONS.find((d) => d.value === filter.type)
                    : null;
            if (newType) {
                setType(() => ({ value: newType.value, label: newType.label }));
            }
        }
        if (filter.authorId) {
            let newAuthor =
                authors && authors.length > 0
                    ? authors.find((a) => a.value === filter.authorId)
                    : null;
            if (newAuthor) {
                setAuthor(() => ({
                    value: newAuthor.value,
                    label: newAuthor.label,
                }));
            }
        }
        if (filter.storeId) {
            let newStore =
                stores && stores.length > 0
                    ? stores.find((a) => a.value === filter.storeId)
                    : null;
            if (newStore) {
                setStore(() => ({
                    value: newStore.value,
                    label: newStore.label,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, domains, TYPE_OPTIONS, authors, stores]);

    useEffect(() => {
        if (collectionProp != null && collections?.length > 0) {
            const matchValue = collections.find(
                (i) => i.value === collectionProp
            );
            if (matchValue != null) {
                const { value, label } = matchValue;
                setCollection({ value, label });
            }
        }
    }, [collections, collectionProp]);

    useEffect(() => {
        if (tagProp != null && tags?.length > 0) {
            const matchValue = tags.find((i) => i.value === tagProp);
            if (matchValue != null) {
                const { value, label } = matchValue;
                setTag({ value, label });
            }
        }
    }, [tags, tagProp]);

    useEffect(() => {
        if (nicheProp != null && niches?.length > 0) {
            const matchValue = niches.find((i) => i.value === nicheProp);
            if (matchValue != null) {
                const { value, label } = matchValue;
                setNiche({ value, label });
            }
        }
    }, [niches, nicheProp]);

    useEffect(() => {
        let newDomains =
            data &&
            data.productAssortmentDomains &&
            data.productAssortmentDomains.nodes &&
            data.productAssortmentDomains.nodes.length > 0
                ? data.productAssortmentDomains.nodes
                      .filter((i) => i.domain)
                      .map((i) => ({
                          value: i.domain,
                          label: i.domain,
                      }))
                : [];
        setDomains(newDomains);
    }, [data]);

    useEffect(() => {
        let newAuth =
            dataAuth &&
            dataAuth.getAssortmentAuthors &&
            dataAuth.getAssortmentAuthors.nodes &&
            dataAuth.getAssortmentAuthors.nodes.length > 0
                ? dataAuth.getAssortmentAuthors.nodes.map((a) => {
                      let user = a && a.user;
                      let currentId = currentUser && currentUser.id;
                      let suffix = "";
                      if (currentId === user.id) {
                          suffix = "(current)";
                      }
                      return {
                          value: user ? user.id : null,
                          label: user
                              ? `${user.firstName} ${user.lastName} ${suffix}`
                              : "",
                      };
                  })
                : [];

        setAuthors(newAuth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataAuth]);

    useEffect(() => {
        let newStores =
            dataStore?.getAssortmentStores?.nodes?.length > 0
                ? dataStore.getAssortmentStores.nodes.map(({ id, title }) => ({
                      value: id,
                      label: title,
                  }))
                : [];
        setStores(newStores);
    }, [dataStore]);

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        if (onChange) {
            typingTimeoutRef.current = setTimeout(() => {
                onChange({
                    search: queryValue ? queryValue.trim() : queryValue,
                    type: type.value,
                    domain: domain.value,
                    authorId: author.value,
                    createdTime: filterTime.range,
                    storeId: store.value,
                    collection: collection.value,
                    tag: tag.value,
                    niche: niche.value,
                });
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        type,
        domain,
        queryValue,
        author,
        filterTime,
        store,
        collection.value,
        tag.value,
        niche.value,
    ]);

    const handleQueryChange = useCallback((value) => setQueryValue(value), []);
    const handleQueryRemove = useCallback(() => setQueryValue(null), []);
    const handleDomainRemove = useCallback(
        () => setDomain(() => ({ value: null, label: null, search: null })),
        []
    );
    const handleTypeRemove = useCallback(
        () => setType(() => ({ value: null, label: null })),
        []
    );
    const handleAuthorRemove = useCallback(() => {
        setAuthor(() => ({ value: null, label: null, search: null }));
    }, []);
    const handleFilterTimeRemove = useCallback(
        () => setFilterTime({ range: null, rangeLabel: null }),
        []
    );
    const handleStoreRemove = useCallback(
        () => setStore({ value: null, label: null, search: null }),
        []
    );
    const handleCollectionRemove = useCallback(
        () => setCollection({ value: null, label: null, search: null }),
        []
    );
    const handleTagRemove = useCallback(
        () => setCollection({ value: null, label: null, search: null }),
        []
    );
    const handleNicheRemove = useCallback(
        () => setNiche({ value: null, label: null, search: null }),
        []
    );

    const filterFns = [
        handleQueryRemove,
        handleTypeRemove,
        handleDomainRemove,
        handleAuthorRemove,
        handleFilterTimeRemove,
        handleStoreRemove,
        handleCollectionRemove,
        handleTagRemove,
        handleNicheRemove,
    ];
    const handleFiltersClearAll = useCallback(() => {
        for (let fn of filterFns) {
            fn && fn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...filterFns]);

    const filters = [
        {
            key: "domain",
            label: "Domain",
            filter: (
                // <FilterListRadioPolaris
                //   value={domain && domain.value}
                //   options={domains}
                //   onChange={({ value, label }) =>
                //     setDomain((prevState) => ({ ...prevState, value, label }))
                //   }
                // />
                <FilterNoSearchPolaris
                    data={domains}
                    value={domain}
                    loading={loading}
                    error={error}
                    onChange={({ value, label }) =>
                        setDomain((prevState) => ({
                            ...prevState,
                            value,
                            label,
                        }))
                    }
                    onChangeSearch={({ search }) =>
                        setDomain((prevState) => ({ ...prevState, search }))
                    }
                />
            ),
        },
        {
            key: "type",
            label: "Type",
            filter: (
                <FilterListRadioPolaris
                    value={type && type.value}
                    options={TYPE_OPTIONS}
                    onChange={({ value, label }) =>
                        setType((prevState) => ({ ...prevState, value, label }))
                    }
                />
            ),
        },
        ...(leadTeam || isRoleIdea
            ? [
                  {
                      key: "author",
                      label: "Author",
                      filter: (
                          // <FilterListRadioPolaris
                          //   value={author && author.value}
                          //   options={authors}
                          //   onChange={({ value, label }) =>
                          //     setAuthor((prev) => ({ ...prev, value, label }))
                          //   }
                          // />
                          <FilterNoSearchPolaris
                              value={author}
                              data={authors}
                              loading={loadingAuth}
                              error={errorAuth}
                              onChange={({ value, label }) =>
                                  setAuthor((prev) => ({
                                      ...prev,
                                      value,
                                      label,
                                  }))
                              }
                              onChangeSearch={({ search }) =>
                                  setAuthor((prevState) => ({
                                      ...prevState,
                                      search,
                                  }))
                              }
                          />
                      ),
                  },
              ]
            : []),
        {
            key: "createdTime",
            label: "Created Time",
            filter: (
                <FilterCreatedTimePolaris
                    filterTime={filterTime}
                    onChangeRange={(range, rangeLabel) => {
                        setFilterTime((filterTime) => ({
                            ...filterTime,
                            range,
                            rangeLabel,
                        }));
                    }}
                />
            ),
        },
        ...(leadTeam
            ? [
                  {
                      key: "store",
                      label: "Stores",
                      filter: (
                          <FilterNoSearchPolaris
                              data={stores}
                              value={store}
                              loading={loadingStore}
                              error={errorStore}
                              onChange={({ value, label }) =>
                                  setStore((prevState) => ({
                                      ...prevState,
                                      value,
                                      label,
                                  }))
                              }
                              onChangeSearch={({ search }) =>
                                  setStore((prevState) => ({
                                      ...prevState,
                                      search,
                                  }))
                              }
                          />
                      ),
                  },
              ]
            : []),
        {
            key: "collection",
            label: "Collection",
            filter: (
                <FilterNoSearchPolaris
                    value={collection}
                    data={collections}
                    onChange={({ value, label }) =>
                        setCollection((prev) => ({
                            ...prev,
                            value,
                            label,
                        }))
                    }
                    onChangeSearch={({ search }) =>
                        setCollection((prevState) => ({
                            ...prevState,
                            search,
                        }))
                    }
                />
            ),
        },
        {
            key: "tag",
            label: "Tag",
            filter: (
                <FilterNoSearchPolaris
                    value={tag}
                    data={tags}
                    onChange={({ value, label }) =>
                        setTag((prev) => ({
                            ...prev,
                            value,
                            label,
                        }))
                    }
                    onChangeSearch={({ search }) =>
                        setTag((prevState) => ({
                            ...prevState,
                            search,
                        }))
                    }
                />
            ),
        },
        {
            key: "niche",
            label: "Niche",
            filter: (
                <FilterNoSearchPolaris
                    value={niche}
                    data={niches}
                    onChange={({ value, label }) =>
                        setNiche((prev) => ({
                            ...prev,
                            value,
                            label,
                        }))
                    }
                    onChangeSearch={({ search }) =>
                        setNiche((prevState) => ({
                            ...prevState,
                            search,
                        }))
                    }
                />
            ),
        },
    ];

    const appliedFilters = [];
    if (!isEmpty(type.label)) {
        const key = "type";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, type.label),
            onRemove: handleTypeRemove,
        });
    }
    if (!isEmpty(domain.label)) {
        const key = "domain";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, domain.label),
            onRemove: handleDomainRemove,
        });
    }
    if (!isEmpty(author.label)) {
        const key = "author";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, author.label),
            onRemove: handleAuthorRemove,
        });
    }
    if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
        const key = "createdTime";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, filterTime.rangeLabel),
            onRemove: handleFilterTimeRemove,
        });
    }
    if (!isEmpty(store.label)) {
        const key = "store";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, store.label),
            onRemove: handleStoreRemove,
        });
    }
    if (!isEmpty(collection.label)) {
        const key = "collection";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, collection.label),
            onRemove: handleCollectionRemove,
        });
    }
    if (!isEmpty(tag.label)) {
        const key = "tag";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, tag.label),
            onRemove: handleTagRemove,
        });
    }
    if (!isEmpty(niche.label)) {
        const key = "niche";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, niche.label),
            onRemove: handleNicheRemove,
        });
    }

    return (
        <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryPlaceholder={"Search..."}
            queryValue={queryValue}
            onQueryChange={handleQueryChange}
            onQueryClear={handleQueryRemove}
            onClearAll={handleFiltersClearAll}
        />
    );

    function disambiguateLabel(key, value) {
        switch (key) {
            case "type":
                return `Type: ${value}`;
            case "domain":
                return `Domain: ${value}`;
            case "author":
                return `Author: ${value}`;
            case "createdTime":
                return `Created time: ${value}`;
            case "store":
                return `Store: ${value}`;
            case "collection":
                return `Collection: ${value}`;
            case "tag":
                return `Tag: ${value}`;
            case "niche":
                return `Niche: ${value}`;
            default:
                return value;
        }
    }
};

function getTaxonomies(terms) {
    if (!(terms instanceof Array) || terms.length === 0) return {};

    return terms.reduce((acc, cur) => {
        const { term, taxonomies } = cur || {};
        const newTaxonomies = (Array.isArray(taxonomies) &&
        taxonomies?.length > 0
            ? taxonomies.filter(Boolean)
            : []
        ).map((i) => ({ value: i, label: i }));

        return {
            ...acc,
            [term]: newTaxonomies,
        };
    }, {});
}
