import React from "react";

import { AmazonChannel } from "../../components/reports";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function AmazonChannelPage() {
    return (
        <WrapperPage>
            <AmazonChannel />
        </WrapperPage>
    );
}
