import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Popover } from "antd";
import AssignProductTemplate from "./AssignProductTemplate";

class AssignTemplateButton extends Component {
  state = {
    visible: false,
  };

  render() {
    const { visible } = this.state;
    return (
      <Popover
        title={"Assign use Template"}
        visible={visible}
        onVisibleChange={(bool) => this.setState({ visible: bool })}
        placement="bottom"
        trigger={["click"]}
        content={
          <AssignProductTemplate
            onCompleted={(products) => {
              this.setState({ visible: false });
              if (this.props.onCompleted) {
                this.props.onCompleted(products);
              }
            }}
            value={[this.props.value]}
          />
        }
      >
        <Button size="small" type={"primary"}>
          Assign use Template
        </Button>
      </Popover>
    );
  }
}

AssignTemplateButton.propTypes = {
  value: PropTypes.object,
  onCompleted: PropTypes.func,
};

export default AssignTemplateButton;
