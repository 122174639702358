import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Checkbox, Toast } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { elementContains, getDomainFromUrl } from "../../helper";
import { SCREEN_OPTIONS, TITLES } from "./ScreenOptionsPAPolaris";

import { BulkActionsPolaris } from "./BulkActionsPolaris";
import { ProductAssortmentImagePolaris } from "./ProductAssortmentImagePolaris";
import { ProductAssortmentTitlePolaris } from "./ProductAssortmentTitlePolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { ProductAssortmentActionsPolaris } from "./ProductAssortmentActionsPolaris";

const Container = styled.div`
  .image_wrap {
    width: 200px;
  }
  .product_wrap {
    width: calc(500px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .origin-domain_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .niche_wrap {
    width: calc(20rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .source_wrap {
    width: calc(120px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .create-at_wrap {
    width: calc(130px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .author_wrap {
    width: calc(200px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .actions-wrap {
    width: calc(350px - 3.2rem);
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  // bulk actions
  .bulk-actions_wrap {
    padding: 1.6rem;
    border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  }

  // Similar
  .custom-row {
    .similar_wrap {
      position: relative;
      .similar_inner {
        display: flex;
      }
      .pseudo {
        position: absolute;
        width: 24px;
        height: 24px;
        top: -2.8rem;
        left: 129px;
        transform: rotate(45deg);
        background: var(--p-surface, #f4f6f8);
      }
      .similar-products,
      .similar-items {
        display: flex;
        flex-direction: row;
        .similar-product,
        .similar-item {
          display: flex;
          flex-direction: column;
          width: 250px;
          justify-content: center;
          text-align: center;
          align-self: flex-start;
          .title_inner {
            margin: 0 1rem;
            display: inline-block;
          }
        }
      }
    }
  }
  .Polaris-DataTable__Footer {
    border-top: 0.1rem solid #e2e6ea;
    overflow: scroll;
  }
`;

export const TableProductAssortmentPolaris = (props) => {
  const { data, filter, screenOptions, refetch, setFilter } = props;
  const [columnContentTypes, setColumnContentTypes] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [rows, setRows] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [itemsChecked, setItemsChecked] = useState({});
  const [idsChecked, setIdsChecked] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const typingRef = React.useRef(null);

  useEffect(() => {
    let newHeadings = [];
    let newCol = [];
    let originSO = SCREEN_OPTIONS || [];
    let currentHeadings = originSO.filter(
      (i) => screenOptions.length && screenOptions.includes(i.value) && TITLES !== i.value
    );
    newHeadings = currentHeadings.map((i) => i.label);
    newCol = currentHeadings.map(() => "text");

    newHeadings =
      screenOptions.length > 0
        ? [
            <Checkbox
              label=""
              checked={checkedAll}
              onChange={(newChecked) => handleCheckedAll(newChecked)}
            />,
            ...newHeadings,
          ]
        : [...newHeadings];
    newCol = ["text", ...newCol];

    setColumnContentTypes(newCol);
    if (!newHeadings.length) {
      handleCheckedAll(false);
    }
    setHeadings(newHeadings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenOptions, checkedAll]);

  useEffect(() => {
    if (data) {
      let newRows = data.productAssortment.nodes.reduce(
        (accumulator, currentValue) => {
          // Format url
          let url = currentValue.url;
          let urlMarkup = "";
          if (url) {
            if (
              !url.includes(".jpg") &&
              !url.includes(".png") &&
              !url.includes(".gif")
            ) {
              urlMarkup = getDomainFromUrl(url);
            }
          }

          // Format Created at
          let createdAt = moment(currentValue.createdAt).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          // Format Author
          let author = currentValue.author;
          let authorMarkup = author
            ? `${author?.firstName} ${author?.lastName}`
            : "";

          // Expanded (similar product, items);
          let haveSimilarItems = currentValue.similarItems;
          let haveSimilarProducts = currentValue.similarProducts;

          let similarContent = null;
          if (
            (haveSimilarItems && haveSimilarItems !== null) ||
            (haveSimilarProducts && haveSimilarProducts.length)
          ) {
            similarContent = (
              <div className="similar_wrap">
                <span className="pseudo">&nbsp;</span>
                <div className="similar_inner">
                  {haveSimilarProducts && haveSimilarProducts.length > 0 ? (
                    <div className="similar-products">
                      {haveSimilarProducts.map((sp, index) => (
                        <div key={index} className="similar-product">
                          <span>Distance: {sp.distance}</span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ProductAssortmentImagePolaris
                              product={sp.product}
                            />
                          </div>
                          <span className="title_inner">
                            {sp?.product.title}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {haveSimilarItems && haveSimilarItems.length > 0 ? (
                    <div className="similar-items">
                      {haveSimilarItems
                        .filter((si) => si.similarItem)
                        .map((si, index) => {
                          let fakeProduct = {
                            images: si?.similarItem?.images.filter(
                              (s) => s.file
                            ),
                          };

                          return (
                            <div key={index} className="similar-item">
                              <span>Distance: {si.distance}</span>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ProductAssortmentImagePolaris
                                  product={fakeProduct}
                                />
                              </div>
                              <span className="title_inner">
                                {si.similarItem.title}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          }

          const similarMarkup = [
            <div className="custom-row">{similarContent}</div>,
          ];

          const columns = [
            {
              key: "",
              show: screenOptions.length,
              render: (
                <div className="checked_wrap">
                  <Checkbox
                    label=""
                    id={currentValue.id}
                    checked={!!itemsChecked[currentValue.id]}
                    onChange={(newChecked, id) => handleChecked(id, newChecked)}
                  />
                </div>
              ),
            },
            {
              key: "image",
              show: screenOptions.includes("image"),
              render: (
                <div className="image_wrap">
                  <ProductAssortmentImagePolaris
                    product={currentValue}
                    isSpecial
                  />
                </div>
              ),
            },
            {
              key: "product",
              show: screenOptions.includes("product"),
              render: (
                <div className="product_wrap">
                  <ProductAssortmentTitlePolaris
                    product={currentValue}
                    filter={filter}
                    toggleActive={toggleActive}
                    onNotificationChange={({ message, isError }) =>
                      setNotification(() => ({ message, isError }))
                    }
                  />
                </div>
              ),
            },
            {
              key: "originDomain",
              show: screenOptions.includes("originDomain"),
              render: (
                <div className="origin-domain_wrap">
                  {urlMarkup.length > 0 ? <span>{urlMarkup}</span> : null}
                </div>
              ),
            },
            {
              key: "niche",
              show: screenOptions.includes("niche"),
              render: (
                <div className="niche_wrap">
                  <span>{currentValue.niche}</span>
                </div>
              ),
            },
            {
              key: "source",
              show: screenOptions.includes("source"),
              render: (
                <div className="source_wrap">
                  <span>{currentValue.source}</span>
                </div>
              ),
            },
            {
              key: "createdAt",
              show: screenOptions.includes("createdAt"),
              render: (
                <div className="create-at_wrap">
                  <span>{createdAt}</span>
                </div>
              ),
            },
            {
              key: "author",
              show: screenOptions.includes("author"),
              render: (
                <div className="author_wrap">
                  <span>{authorMarkup}</span>
                </div>
              ),
            },
            {
              key: "actions",
              render: (
                <div className="actions-wrap">
                  <ProductAssortmentActionsPolaris
                    currentValue={currentValue}
                    currentChecked={currentValue}
                    toggleActive={toggleActive}
                    onCompleted={handleCompleted}
                    onNotificationChange={({ message, isError }) =>
                      setNotification(() => ({ message, isError }))
                    }
                  />
                </div>
              ),
            },
          ];

          let columnsChecked = [];
          columnsChecked = columns.filter((i) => i.show).map((i) => i.render);
          columnsChecked = [
            ...columnsChecked,
            columns[columns.length - 1].render,
          ];
          return columnsChecked.length > 1
            ? haveSimilarItems || haveSimilarProducts
              ? [...accumulator, columnsChecked, similarMarkup]
              : [...accumulator, columnsChecked]
            : [];
        },
        []
      );
      setRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, screenOptions, itemsChecked]);

  useEffect(() => {
    // Colspan th
    const domTHs = document.querySelectorAll(".Polaris-DataTable__Cell");
    const domCustomRows = document.querySelectorAll(".custom-row");

    for (let domTH of domTHs) {
      for (let domCustomRow of domCustomRows) {
        if (elementContains(domTH, domCustomRow)) {
          domTH.style.background = "var(--p-surface, #f4f6f8)";
          domTH.setAttribute("colspan", screenOptions.length + 2);
        } else {
          let attr = domTH.getAttribute("colspan");
          if (attr && !!domTH.nextElementSibling) {
            domTH.removeAttribute("style");
            domTH.removeAttribute("colspan");
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, data, idsChecked]);

  useEffect(() => {
    if (data) {
      if (idsChecked.length === 1) {
        let currentChecked = data.productAssortment.nodes.find(
          (i) => i.id === idsChecked[0]
        );
        setCurrentChecked(currentChecked);
      } else {
        setCurrentChecked(null);
      }
    }
  }, [idsChecked, data]);

  const handleCheckedAll = useCallback(
    (newChecked) => {
      setCheckedAll(newChecked);
      let propsPA =
        data && data.productAssortment && data.productAssortment.nodes
          ? data.productAssortment.nodes
          : [];

      let idsChecked = [];
      if (newChecked) {
        let item = {};
        for (let i = 0; i < propsPA.length; i++) {
          item = {
            ...item,
            [propsPA[i].id]: true,
          };
        }
        setItemsChecked(item);
        idsChecked = handleIdsChecked(item);
      } else {
        setItemsChecked({});
        idsChecked = [];
      }
      setIdsChecked(idsChecked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleChecked = useCallback(
    (id, newChecked) => {
      let countItemsChecked;
      let idsChecked = [];

      setItemsChecked((prev) => {
        let result = { ...prev, [id]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;

        // onSelect
        idsChecked = handleIdsChecked(result);
        return result;
      });

      if (newChecked) {
        setCheckedAll(true);
      } else if (!countItemsChecked) {
        setCheckedAll(false);
      }
      setIdsChecked(idsChecked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsChecked]
  );

  const handleIdsChecked = useCallback((object) => {
    object = Object(object);
    let result = [];

    for (let [key, value] of Object.entries(object)) {
      if (value) {
        result.push(key);
      }
    }
    return result;
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleCompleted = useCallback(() => {
    typingRef && clearTimeout(typingRef.current);
    typingRef.current = setTimeout(() => {
      setIdsChecked(() => []);
      setCheckedAll(false);
      setItemsChecked({});
      if (refetch) {
        refetch();
      }
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={3000}
          onDismiss={toggleActive}
        />
      )
    : null;

  let { limit, offset } = filter;
  const total = get(data, "productAssortment.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      {toastMarkup}
      {idsChecked.length > 0 ? (
        <div className="bulk-actions_wrap">
          <BulkActionsPolaris
            idsChecked={idsChecked}
            toggleActive={toggleActive}
            onNotificationChange={({ message, isError }) =>
              setNotification(() => ({ message, isError }))
            }
            onCompleted={handleCompleted}
            currentChecked={currentChecked}
            onCheckAll={() => {
              handleCheckedAll(false);
            }}
            data={data}
          />
        </div>
      ) : null}

      <DataTable
        columnContentTypes={[...columnContentTypes, "numeric"]}
        headings={
          headings.length > 0
            ? [...headings, "Actions"]
            : ["Please choose at least one column above to visible data table"]
        }
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          headings.length > 0 ? (
            <PaginationPolaris aggregation={aggregation} showTotal />
          ) : null
        }
      />
    </Container>
  );
};
