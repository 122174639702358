import React from "react";
import { Modal } from "@shopify/polaris";

import { FieldTitlePolaris } from "../components/FieldTitlePolaris";

export const ChangeTitlePolaris = ({ open, toggleShowModal, ...props }) => {
    return (
        <Modal
            open={open}
            onClose={toggleShowModal}
            sectioned
            title="Change titles"
        >
            <React.Fragment>
                <FieldTitlePolaris {...props} />
            </React.Fragment>
        </Modal>
    );
};
