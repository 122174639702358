import React from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { CarriersDataTablePolaris } from "./CarriersDataTablePolaris";

export const LOAD_CARRIERS = gql`
  query carriers {
    carriers {
      id
      code
      name
      trackingUrl
    }
  }
`;

export const CarriersPolaris = () => {
  // Query
  const { data, loading, error, refetch } = useQuery(LOAD_CARRIERS);

  // Markup
  const loadingMarkup = loading && <Loading />;
  return (
    <Card>
      {loadingMarkup}
      {loading ? (
        <SkeletonPagePolaris />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : data?.carriers?.length > 0 ? (
        <CarriersDataTablePolaris data={data} refetch={refetch}/>
      ) : (
        <EmptyStatePolaris />
      )}
    </Card>
  );
};
