import React, { Component } from "react";
import { ListCollectionPolaris } from "../../components/product/collections/ListCollectionPolaris";
import { getPathFromRouter } from "../../helper";

class Collections extends Component {
    render() {
        const path = getPathFromRouter(this.props != null ? [this.props] : []);
        return <ListCollectionPolaris path={path} />;
    }
}

export default Collections;
