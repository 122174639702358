import React, { useCallback, useMemo, useState } from "react";

import {
    Button,
    Form as FormPolaris,
    FormLayout,
    Stack,
    TextField,
} from "@shopify/polaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { StoreOfMeSelect } from "../../StoreOfMeSelect";

export function Form({ onSubmit, loading, ids }) {
    // State
    const [state, setState] = useState({
        originIds: "",
        storeId: null,
    });
    const [errors, setErrors] = useState({});

    // Variables
    const FIELDS_REQUIRED = useMemo(
        () => ({
            originIds: "Original Order IDs",
            storeId: "Store ID",
        }),
        []
    );

    // Actions
    const validateField = useCallback(
        (value, id) => {
            let err, label;
            for (let [key, value] of Object.entries(FIELDS_REQUIRED)) {
                if (key === id) {
                    label = value;
                }
            }

            if ((!value || value.length === 0) && label) {
                err = `${label} is required`;
            }

            setErrors((prev) => ({ ...prev, [id]: err }));
        },
        [FIELDS_REQUIRED]
    );

    const handleFieldsChange = useCallback(
        (value, id) => {
            validateField(value, id);
            setState((prev) => ({ ...prev, [id]: value }));
        },
        [validateField]
    );
    const handleStoreChange = useCallback(
        (value) => {
            handleFieldsChange(value, "storeId");
        },
        [handleFieldsChange]
    );

    const handleSubmit = useCallback(() => {
        for (let [key, value] of Object.entries(state)) {
            validateField(value, key);
        }

        const { originIds, storeId } = state;
        const originIdsSplitted = (originIds || "")
            .split(/\n/)
            .map((i) => i?.trim())
            .filter(Boolean);
        const newOriginIds = ids?.length > 0 ? ids : originIdsSplitted;
        const canSubmit = Object.values(errors).every(
            (el) => typeof el === "undefined" || el === null
        );

        if (storeId && newOriginIds?.length > 0 && canSubmit) {
            if (onSubmit) {
                onSubmit({ originIds: newOriginIds, storeId });
            }
        }
    }, [state, errors, onSubmit, validateField, ids]);

    return (
        <FormPolaris onSubmit={handleSubmit}>
            <FormLayout>
                {!ids && (
                    <div>
                        <ComponentLabelPolaris
                            label="Original Order IDs"
                            required
                        />
                        <TextField
                            value={state.originIds}
                            onChange={handleFieldsChange}
                            multiline={4}
                            error={errors["originIds"]}
                            id="originIds"
                            placeholder="Enter original order ids"
                            helpText="Enter order ids with each order id separated by a new line."
                        />
                    </div>
                )}
                <StoreOfMeSelect
                    onChange={handleStoreChange}
                    error={errors["storeId"]}
                />
                <Stack distribution="trailing">
                    <Button
                        submit
                        primary
                        children="Submit"
                        loading={loading}
                    />
                </Stack>
            </FormLayout>
        </FormPolaris>
    );
}
