import React, { useState, useCallback, useEffect } from "react";
import { Modal, ChoiceList, Toast } from "@shopify/polaris";
import { Mutation } from "@apollo/react-components";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { CANCEL_ORDER } from "../../../graphql/mutations";

export const AdminCancelOrderPolaris = (props) => {
    const {
        open,
        toggleShowModal,
        refetch,
        value,
        changeStatusCount,
        convertOS,
    } = props;
    const orderId = value?.id;
    const orderOriginId = value?.originId;
    // useLockBodyScroll(open);

    const [selected, setSelected] = useState([1]);
    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [notifications, setNotifications] = useState({
        message: null,
        isError: null,
    });

    const handleChange = useCallback((value) => setSelected(value), []);

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(() => {
        setActiveToast((activeToast) => !activeToast);
    }, []);

    const toastMarkup = activeToast
        ? notifications &&
          notifications.message && (
              <Toast
                  content={notifications.message}
                  error={notifications.isError}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            <Mutation
                mutation={CANCEL_ORDER}
                onCompleted={() => {
                    setNotifications(() => ({
                        message: "Cancel order success",
                        isError: false,
                    }));
                    const id = setTimeout(() => {
                        toggleShowModal(false);
                        if (refetch) refetch();
                        if (changeStatusCount) {
                            changeStatusCount(
                                convertOS && [
                                    convertOS.PendingDesign,
                                    convertOS.PendingPayment,
                                    convertOS.OnHold,
                                    convertOS.Pending,
                                    convertOS.InProduction,
                                    convertOS.Fulfilled,
                                    convertOS.ReadyToPrint,
                                    convertOS.Printing,
                                    convertOS.DesignRejected,
                                ]
                            );
                        }
                    }, 2100);
                    setTimeoutId(id);
                }}
                onError={(err) => {
                    setNotifications(() => ({
                        message: err.toString(),
                        isError: true,
                    }));
                    const id = setTimeout(() => {
                        toggleShowModal(false);
                    }, 2100);
                    setTimeoutId(id);
                }}
            >
                {(cancelOrder, { loading }) => (
                    <Modal
                        title={`Are you sure to cancel order #${
                            orderId ? orderId : ""
                        }`}
                        open={open}
                        onClose={toggleShowModal}
                        sectioned
                        // primaryAction={[{ content: "Cancel", onAction: toggleShowModal }]}
                        primaryAction={{
                            content: "Cancel now",
                            onAction: () => {
                                let newOrderId = null;
                                let originId = null;
                                let related = false;
                                let cancelOrderStore = false;
                                let value =
                                    selected && selected.length > 0
                                        ? selected[0]
                                        : 1;
                                switch (value) {
                                    case 1:
                                        newOrderId = orderId;
                                        cancelOrderStore = true;
                                        break;
                                    case 2:
                                        newOrderId = orderId;
                                        break;
                                    case 3:
                                        related = true;
                                        originId = orderOriginId;
                                        cancelOrderStore = true;
                                        break;
                                    case 4:
                                        related = true;
                                        originId = orderOriginId;
                                        break;
                                    default:
                                        break;
                                }
                                cancelOrder({
                                    variables: {
                                        orderId: newOrderId,
                                        originId,
                                        related,
                                        cancelOrderStore,
                                    },
                                });
                                toggleActive();
                            },
                            loading: loading,
                        }}
                    >
                        <div id="admin-cancel_modal">
                            <ChoiceList
                                title={`Are you sure to cancel order #${
                                    orderId ? orderId : ""
                                }`}
                                titleHidden
                                choices={[
                                    {
                                        label:
                                            "Cancel this order and cancel in store",
                                        value: 1,
                                    },
                                    {
                                        label:
                                            "Cancel this order, without cancel in store",
                                        value: 2,
                                    },
                                    {
                                        label:
                                            "Cancel this order & related orders and cancel in store",
                                        value: 3,
                                    },
                                    {
                                        label:
                                            "Cancel this order & related orders, without cancel in store",
                                        value: 4,
                                    },
                                ]}
                                selected={selected}
                                onChange={handleChange}
                            />
                        </div>
                    </Modal>
                )}
            </Mutation>
        </>
    );
};
