import React from "react";
import { calculatePercent } from "../../../helper";
import { ArrowUpMinor, ArrowDownMinor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import styled from "styled-components";

const BADGE = {
    equal: {
        color: "#349dd7",
    },
    increment: {
        color: "#6bc959",
        icon: ArrowUpMinor,
    },
    decrement: {
        color: "#dd6a4f",
        icon: ArrowDownMinor,
    },
};

export function ComparedPercent({ originalVal, newVal }) {
    const percent = calculatePercent(newVal, originalVal);

    let badge = BADGE.equal;
    if (percent > 100) {
        badge = BADGE.increment;
    } else if (percent < 100) {
        badge = BADGE.decrement;
    }
    let isIncrement = percent > 100;

    return newVal ? (
        <Wrapper isIncrement={isIncrement}>
            {badge.icon && (
                <div>
                    <Icon source={badge.icon} />
                </div>
            )}
            <span style={{ color: badge.color }}>{percent}%</span>
        </Wrapper>
    ) : null;
}

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    column-gap: 0.2rem;
    align-items: center;
    margin-left: 0.5rem;
    transform: translateY(0.2rem);

    .Polaris-Icon {
        height: 1.4rem;
        width: 1.4rem;

        .Polaris-Icon__Svg {
            fill: ${({ isIncrement }) => (isIncrement ? `#6bc959` : `#dd6a4f`)};
        }
    }
`;
