import { gql } from "apollo-boost";

export const FieldFragment = gql`
    fragment FieldFragment on Field {
        id
        name
        title
        type
        extraFee
        configure
        sorting
    }
`;
