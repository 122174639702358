import React, { useState, useEffect, useCallback, useRef } from "react";
import { Filters } from "@shopify/polaris";

import { isEmpty } from "../../../helper";
import { FilterBoolStatusPolaris } from "../../shared/FilterBoolStatusPolaris";

export const SuppliersFilterPolaris = ({ filter, onChange }) => {
  // State
  const [queryValue, setQueryValue] = useState(filter.search);
  const [status, setStatus] = useState({
    value: filter.status,
    label:
      true === filter.status
        ? "Active"
        : false === filter.status
        ? "Inactive"
        : null,
  });
  const typingTimeoutRef = useRef(null);

  // OnChange
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          status: status.value,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, status]);

  // Handle action
  const handleQueryValueChange = useCallback(
    (newValue) => setQueryValue(newValue),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleStatusRemove = useCallback(
    () => setStatus({ value: null, label: null }),
    []
  );

  const handleFilterClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleStatusRemove();
  }, [handleQueryValueRemove, handleStatusRemove]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <FilterBoolStatusPolaris
          status={status && status.value}
          onChange={(value, label) =>
            setStatus((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (!isEmpty(status.label)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status.label),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder={"Filter supplier"}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFilterClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
