import React, { useEffect, useState } from "react";
import { Icon, DataTable, TextField } from "@shopify/polaris";
import { LinkMinor } from "@shopify/polaris-icons";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { formatTime } from "../../../helper";
import { ActionGroup } from "../actions/ActionGroup";
import { ProductAssortmentImagePolaris } from "../../assortment/ProductAssortmentImagePolaris";

export function DataTableComp({ data, setFilter, filter }) {
    // State
    const [rows, setRows] = useState([]);

    // Get data
    useEffect(() => {
        const newData =
            data?.complexProductAssortment?.nodes?.length > 0
                ? data.complexProductAssortment.nodes
                      .map((node) => {
                          if (!node) return null;
                          const {
                              id,
                              title,
                              source,
                              createdAt,
                              author,
                              url,
                              variantAttributes,
                          } = node;

                          // id
                          const idWrap = (
                              <div className="item id-wrap">
                                  <span>{id}</span>
                              </div>
                          );

                          // images
                          const first =
                              variantAttributes?.length > 0
                                  ? variantAttributes.find(
                                        (i) => i?.image?.length > 0
                                    )
                                  : [];
                          const images =
                              first?.image?.length > 0
                                  ? first.image.filter(Boolean).map((i) => ({
                                        file: {
                                            url: i,
                                        },
                                    }))
                                  : [];

                          const imagesWrap = (
                              <div className="images-wrap">
                                  <ProductAssortmentImagePolaris
                                      product={{ images }}
                                      isSpecial
                                  />
                              </div>
                          );

                          // Title
                          const suffixMarkup = (
                              <CustomLinkPolaris href={url || ""}>
                                  <Icon source={LinkMinor} />
                              </CustomLinkPolaris>
                          );

                          const titleWrap = (
                              <div className="item title-wrap">
                                  <span>{title}</span>
                                  {url && (
                                      <TextField
                                          disabled
                                          value={url}
                                          suffix={suffixMarkup}
                                      />
                                  )}
                              </div>
                          );

                          // Source
                          const sourceWrap = (
                              <div className="item source-wrap">
                                  <span>{source}</span>
                              </div>
                          );

                          // Author
                          const firstName = author?.firstName;
                          const lastName = author?.lastName;
                          const fullName = [firstName, lastName]
                              .filter(Boolean)
                              .join(" ");
                          const authorWrap = (
                              <div className="item author-wrap">
                                  <span>{fullName}</span>
                              </div>
                          );

                          // Created at
                          const createdAtFormat = formatTime(
                              createdAt,
                              "YYYY-MM-DD HH:mm:ss"
                          );
                          const createdAtWrap = (
                              <div className="item created-at-wrap">
                                  <span>{createdAtFormat}</span>
                              </div>
                          );

                          // Actions
                          const actionWrap = (
                              <div className="actions-wrap">
                                  <ActionGroup item={node} />
                              </div>
                          );

                          return [
                              idWrap,
                              imagesWrap,
                              titleWrap,
                              sourceWrap,
                              authorWrap,
                              createdAtWrap,
                              actionWrap,
                          ];
                      })
                      .filter(Boolean)
                : [];

        setRows(newData);
    }, [data]);

    // Markup
    const headings = [
        "ID",
        "Images",
        "Title",
        "Source",
        "Author",
        "Created At",
        "Actions",
    ];
    const colsType = Array(headings.length - 1)
        .fill("text")
        .concat("numeric");

    let { limit, offset } = filter;
    const total = get(data, "complexProductAssortment.total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: setFilter,
        total,
    };

    return (
        <Wrapper>
            <DataTable
                verticalAlign="middle"
                columnContentTypes={colsType}
                headings={headings}
                rows={rows}
                hideScrollIndicator
                footerContent={
                    <PaginationPolaris aggregation={aggregation} showTotal />
                }
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .Polaris-DataTable__Footer {
        border-top: 0.1rem solid #e2e6ea;
        overflow: scroll;
    }

    .actions-wrap {
        display: flex;
        flex-direction: row-reverse;
        min-width: calc(19rem - 3.2rem);
    }

    .title-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        width: calc(40rem - 3.2rem);
        white-space: break-spaces;

        .Polaris-TextField__Input {
            border-right: 0.1rem solid var(--p-border, #c4cdd5);
        }

        .Polaris-TextField__Suffix {
            margin-left: 0.6em;
        }
    }
`;
