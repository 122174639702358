import { Button, Checkbox, FormLayout, TextField } from "@shopify/polaris";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { reducerFn, toSlug } from "../../helper";
import {
  IGNORE_PERSONALIZED_KEYS,
  MAX_LENGTH,
  META_DATA,
  PLATFORMS,
  PLATFORM_ETSY,
  PME_TEAM_ID,
  TITLE_LENGTH_MESS,
} from "../../variable";
import { SimpleCustomizePolaris } from "../customize/SimpleCustomizePolaris";
import { CollectionSelectPolaris } from "../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../product/tags/TagsSelectPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { ChooseTemplatePolaris } from "./ChooseTemplatePolaris";
import { MappingVariantsCTPolaris } from "./MappingVariantsCTPolaris";
import { SetAttributesCTPolaris } from "./SetAttributesCTPolaris";
import { ShippingService } from "./ShippingService";
import { checkFulfillment } from "../order/ManualOrder/components/Form";
import { getPrintifyShippingMethod } from "../base/utils";
import SuspenseComp from "../shared/SuspenseComp";

const OrderLabel = React.lazy(() => import("./components/TOrderLabel"));

const Container = styled.div`
  .personalized-wrap {
    .label {
      height: 23px;
      font-weight: 500;
      display: inline-flex;
      position: relative;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
    .list-item {
      list-style-type: none;
      padding: 0 0 0 0.5rem;
      color: rgba(0, 0, 0, 0.85);
      .key {
        font-weight: 500;
      }
    }
  }
`;

export const MapOrderCTFormPolaris = (props) => {
  const { item, data, onSubmit, loading } = props;
  // Context
  const { currentUser } = useAppContext();
  const { teamUser } = currentUser || {};
  const { team } = teamUser || {};
  const { id: teamId } = team || {};
  // const ctx = useMappingOrderType();

  const [template, setTemplate] = useState(null);
  const [productTitle, setProductTitle] = useState(null);
  const [activateVariants, setActivateVariants] = useState([]);
  const [values, setValues] = useState([]);
  const [simpleChecked, setSimpleChecked] = useState([]);
  const [simpleItems, setSimpleItems] = useState([]);
  const [groupChecked, setGroupChecked] = useState([]);
  const [groupItems, setGroupItems] = useState([]);
  const [mappingVariant, setMappingVariant] = useState([]);
  const [collections, setCollections] = useState(
    PME_TEAM_ID === teamId ? ["hGypw"] : [],
  );
  const [tags, setTags] = useState([]);
  const [ignorePersonalizedKey, setIgnorePersonalizedKey] = useState("");
  const [customField, setCustomField] = useState(true);
  const [fields, setFields] = useState([]);
  const [shippingOption, setShippingOption] = useState(null);

  const [errors, setErrors] = useState({});
  const [forceMapping, setForceMapping] = useState(false);
  const [mapToBaseVariantId, setMapToBaseVariantId] = useState(null);
  const [state, setState] = React.useReducer(reducerFn, {
    autoMapLaterOrders: true,
    originTemplate: null,
    warehouse: [],
  });

  const [shippingMethods, setShippingMethods] = React.useReducer(reducerFn, {
    isPrintway: false,
    isPrintify: false,
    options: [],
    baseID: null,
  });

  // Variables
  let productBases =
    template && template.data && template.data.productBases
      ? template.data.productBases
      : [];

  const platforms = ["amazon", PLATFORM_ETSY];
  const platform = _.get(item, "store.platform");
  const showPersonalizedInfo = platforms.includes(platform);
  const isPlatformEtsy = [PLATFORM_ETSY].includes(platform);
  const showAutoMap = [PLATFORMS.Tiktok].includes(platform);

  useEffect(() => {
    if (item) {
      let title = _.get(item, "product.name");
      handleProductTitle(title);
      const personalizedNames =
        item && item.personalized && item.personalized.length > 0
          ? item.personalized
              .filter((i) => {
                let matches = false;
                IGNORE_PERSONALIZED_KEYS.forEach((ig) => {
                  let nameLower = i.name.toLowerCase();
                  const idLowerCase = ig.toLowerCase();
                  if (nameLower.match(idLowerCase)) {
                    matches = true;
                  }
                });
                return matches;
              })
              .map((i) => i.name)
              .join("\n")
          : null;
      setIgnorePersonalizedKey(personalizedNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const validateTitle = useCallback((value) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    setErrors((prev) => ({ ...prev, productTitle: error }));
  }, []);

  const handleProductTitle = useCallback((value) => {
    setProductTitle(value);
    validateTitle(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollections = useCallback((value) => {
    setCollections(value);
  }, []);
  const handleTags = useCallback((value) => setTags(value), []);
  const handleIgnorePersonalized = useCallback(
    (value) => setIgnorePersonalizedKey(value),
    [],
  );
  const handleTemplateChange = useCallback(
    (templateId) => {
      setShippingOption(null);
      let templates = data?.templates?.nodes || [];
      let currentTemplate = templates.find((i) => i.id === templateId);
      if (currentTemplate) {
        setState({ originTemplate: currentTemplate });
        setTemplate(currentTemplate);
      }
    },
    [data],
  );

  const handleFieldsChange = useCallback((value) => {
    setFields(() => [...value]);
  }, []);

  const handleSubmit = useCallback(() => {
    const mappingOrderId = item && item.id;
    const templateId = template && template.id;
    const newMappingVariant = mappingVariant;
    const newIgnorePersonalizedKey =
      ignorePersonalizedKey && ignorePersonalizedKey.length
        ? ignorePersonalizedKey.split("\n")
        : null;
    const collectionIds = collections;
    const tagIds = tags;
    const activeVariants = activateVariants;

    // Check title length
    validateTitle(productTitle);
    let countErr = _.size(_.filter(_.values(errors), (e) => e !== null));

    let canSubmit = productTitle && countErr === 0;

    // Custom value for Etsy platform
    let customValues = [];
    let newFields = [];
    if (customField) {
      newFields =
        fields?.length > 0
          ? fields.map((item, index) => {
              const { fieldValue, ...rest } = item;
              customValues.push({
                value: fieldValue?.value,
                index,
              });
              return rest;
            })
          : [];
    }

    customValues = customValues?.length > 0 ? customValues : undefined;
    newFields = newFields?.length > 0 ? newFields : undefined;

    const input = {
      mappingOrderId,
      templateId,
      mappingVariant: forceMapping ? [] : newMappingVariant,
      ignorePersonalizedKey: newIgnorePersonalizedKey,
      productTitle,
      collectionIds,
      tagIds,
      activeVariants: forceMapping ? [] : activateVariants,
      usingCustomField: isPlatformEtsy ? customField : false,
      customValues,
      fields: newFields,
      shippingOption,
      forceMapping,
      mapToBaseVariantId: forceMapping ? mapToBaseVariantId : undefined,
      autoMapLaterOrders: showAutoMap ? state.autoMapLaterOrders : undefined,
      fulfillmentWarehouse: state.warehouse ? state.warehouse[0] : undefined,
    };
    if (onSubmit && !!canSubmit) {
      onSubmit(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item,
    mappingVariant,
    template,
    collections,
    tags,
    productTitle,
    ignorePersonalizedKey,
    activateVariants,
    isPlatformEtsy,
    fields,
    customField,
    shippingOption,
    forceMapping,
    mapToBaseVariantId,

    state.autoMapLaterOrders,
    state.warehouse,
    showAutoMap,
  ]);

  // const mergeConditionForceMapping = React.useMemo(
  //     () => ctx?.isOnlineStore && forceMapping,
  //     [ctx?.isOnlineStore, forceMapping]
  // );

  const disabled = React.useMemo(() => {
    let check1 = item.variants && item.variants.length === values.length;
    if (forceMapping) {
      check1 = true;
    }

    let res = true;
    if (check1) {
      res = false;

      if (
        activateVariants &&
        activateVariants.length > 0 &&
        collections.length &&
        tags.length
      ) {
        res = false;
      } else {
        res = true;
      }
    }

    return res;
  }, [item, values, forceMapping, activateVariants, collections, tags]);

  // Etsy custom

  const usingCFMarkup = useMemo(() => {
    if (isPlatformEtsy) {
      return (
        <Checkbox
          label="Using custom field"
          checked={customField}
          onChange={(value) => setCustomField(value)}
        />
      );
    }
    return null;
  }, [isPlatformEtsy, customField]);

  //selectedItem
  const fulfillment = useMemo(() => {
    if (!template) return null;

    const data = template?.data;
    const pBases = data?.productBases;
    let base = pBases?.length > 0 ? pBases.find((f) => f.fulfillment) : null;
    let result;
    if (base != null) {
      result = {
        slug: toSlug(base.fulfillment.fulfillmentTitle),
      };
    }

    const fulfillmentInfo = checkFulfillment(result?.slug);
    setShippingMethods(fulfillmentInfo);
    return result;
  }, [template]);

  const handleAutoMapLaterOrders = React.useCallback((val) => {
    setState({ autoMapLaterOrders: val });
  }, []);

  const handleBaseIDChange = React.useCallback(
    (variant) => {
      if (!variant) return;
      if (variant.baseID) {
        (async function (baseID) {
          const shippingServices = await getPrintifyShippingMethod(baseID);
          if (shippingServices?.length > 0) {
            setShippingMethods({ options: shippingServices });
          }
        })(variant.baseID);
      } else {
        setShippingMethods({ options: [] });
      }
    },
    [shippingMethods],
  );

  const { isMerchize } = checkFulfillment(fulfillment?.slug);
  return (
    <Container>
      <FormLayout>
        <ChooseTemplatePolaris {...props} onChange={handleTemplateChange} />
        <TextField
          label="Product title"
          placeholder="Enter product title"
          value={productTitle}
          onChange={handleProductTitle}
          error={errors["productTitle"]}
        />
        {!!isMerchize && (
          <SuspenseComp>
            <OrderLabel
              warehouse={state.warehouse}
              setState={setState}
              originTemplate={state.originTemplate}
              setTemplate={setTemplate}
            />
          </SuspenseComp>
        )}
        <MappingVariantsCTPolaris
          item={item}
          templateId={template && template.id}
          variants={item.variants ? item.variants : []}
          productBases={productBases ? productBases : []}
          forceMapping={forceMapping}
          setForceMapping={setForceMapping}
          setMapToBaseVariantId={setMapToBaseVariantId}
          onChange={(newValues) => {
            const values = [];
            handleBaseIDChange(newValues[0]);
            for (let i = 0; i < newValues.length; i++) {
              const { baseID, ...rest } = newValues[i];
              values.push(rest);
            }

            setMappingVariant(values);
            if (values && values.length > 0) {
              let newAV = [];
              // if (activateVariants && activateVariants.length > 0) {
              //   newAV = activateVariants;
              // }
              _.map(values, function (v) {
                newAV.push(v.productBaseVariantId);
              });

              //get unique id
              let newActiveVariants = newAV
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter((e) => newAV[e])
                .map((e) => newAV[e]);
              if (newActiveVariants.length > 0) {
                setActivateVariants(() => [...newActiveVariants]);
              }
            } else {
              setActivateVariants([]);
            }
            setValues(values);
          }}
          autoMapMarkup={
            showAutoMap && (
              <Checkbox
                label="Auto mapping for later orders"
                checked={!!state.autoMapLaterOrders}
                onChange={handleAutoMapLaterOrders}
              />
            )
          }
        />
        <SetAttributesCTPolaris
          productBases={productBases}
          activateVariants={activateVariants}
          onChange={({
            activeVariants,
            simpleChecked,
            simpleItems,
            groupChecked,
            groupItems,
          }) => {
            setActivateVariants(() => [...activeVariants]);
            if (undefined !== simpleChecked) {
              setSimpleChecked(simpleChecked);
            }
            if (undefined !== simpleItems) {
              setSimpleItems(simpleItems);
            }
            if (undefined !== groupChecked) {
              setGroupChecked(groupChecked);
            }
            if (undefined !== groupItems) {
              setGroupItems(() => [...groupItems]);
            }
          }}
          simpleChecked={simpleChecked}
          simpleItems={simpleItems}
          groupChecked={groupChecked}
          groupItems={groupItems}
        />
        <CollectionSelectPolaris
          label="Collections"
          allowMultiple
          value={collections}
          onChange={handleCollections}
          haveQuickAdd
          required
          hasPopular
          limit={200}
        />
        <TagsSelectPolaris
          label="Tags"
          allowMultiple
          value={tags}
          onChange={handleTags}
          haveQuickAdd
          required
          limit={200}
        />
        {showPersonalizedInfo &&
        item &&
        item.personalized &&
        item.personalized.length > 0 ? (
          <div className="personalized-wrap">
            <label className="label">Personalized info</label>
            <ul className="list-item">
              {item.personalized.map((md, idx) => {
                let hasImage = _.includes(md.value, META_DATA);
                return (
                  <li key={`personalized-${idx}`}>
                    <span className="key" style={{ wordBreak: "break-all" }}>
                      {md.name && md.name.trim()}: {""}
                    </span>{" "}
                    {hasImage ? (
                      <div>
                        <CustomLinkPolaris href={md.value}>
                          <img
                            src={md.value}
                            alt=""
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                            }}
                          />
                        </CustomLinkPolaris>
                      </div>
                    ) : (
                      <span style={{ wordBreak: "break-all" }}>{md.value}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {usingCFMarkup}
        {isPlatformEtsy && customField ? (
          <SimpleCustomizePolaris
            value={fields}
            personalized={item?.personalized}
            isMapOrderTemplate
            onChange={handleFieldsChange}
          />
        ) : (
          <TextField
            helpText="Enter values with each value separated by a new line."
            label="Ignore Personalized"
            value={ignorePersonalizedKey}
            onChange={handleIgnorePersonalized}
            multiline={4}
          />
        )}
        <ShippingService
          item={item}
          fulfillment={fulfillment}
          shippingOption={shippingOption}
          setShippingOption={setShippingOption}
          options={shippingMethods.options}
        />
        <Button
          primary
          fullWidth
          onClick={handleSubmit}
          children="Submit"
          disabled={disabled}
          loading={loading}
        />
      </FormLayout>
    </Container>
  );
};
