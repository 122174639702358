import { gql } from "apollo-boost";

export const UPDATE_PRIVATE_FEATURES = gql`
  mutation updateTeams($input: EditTeamInput!) {
    updateTeams(input: $input)
  }
`;

export const CREATE_DESIGN_ONLINE_TEAM = gql`
  mutation createDesignOnlineTeam($input: NewDesignOnlineTeam!) {
    createDesignOnlineTeam(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_DESIGN_ONLINE_TEAM = gql`
  mutation updateDesignOnlineTeam($input: EditDesignOnlineTeam!) {
    updateDesignOnlineTeam(input: $input) {
      id
      name
    }
  }
`;
export const CREATE_DESIGN_TEAM = gql`
  mutation createDesignTeam($input: CreateDesignTeam!) {
    createDesignTeam(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_DESIGN_TEAM = gql`
  mutation updateDesignTeam($input: UpdateDesignTeam!) {
    updateDesignTeam(input: $input) {
      id
      name
    }
  }
`;

