import { Page } from "@shopify/polaris";
import React from "react";
import { SwitchStoreForOrder } from "../../components/order/switch-store";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function SwitchStoreForOrderPage({}) {
    return (
        <WrapperPage>
            <Page title="Switch Store for Order" fullWidth>
                <SwitchStoreForOrder />
            </Page>
        </WrapperPage>
    );
}
