import { gql } from "apollo-boost";
import { get } from "lodash";
import { CUSTOM_FULFILLMENT } from "../../../constants";

const GET_FULFILLMENT_BY_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      fulfillment {
        id
        slug
      }
    }
  }
`;

// case: file design size < 50MB if is third party
export async function isChinaOrCustomFulfill(orderId) {
  const { __apolloClient__: client } = window;
  if (!orderId || !client) return;

  try {
    const { data } = await client.query({
      query: GET_FULFILLMENT_BY_ORDER,
      variables: {
        id: orderId,
      },
    });

    const fulfillment = get(data, "order.fulfillment");
    if (!fulfillment) return true;
    return fulfillment.slug === CUSTOM_FULFILLMENT;
  } catch {}
  return;
}

export function checkDimensions({ dimensions, index, designId }) {
  const res = {};
  if (Object.keys(designId).length) {
    for (let [key, value] of Object.entries(dimensions)) {
      if (key === designId[index]) {
        let isRealDimensionOk = value && value.isRealDimensionOk;
        let message = isRealDimensionOk
          ? "You can upload this file."
          : "The dimensions of file uploaded is not correct.";

        const type = isRealDimensionOk ? "success" : "error";
        res[index] = {
          message,
          type,
        };
      }
    }
  }

  return res;
}

export function checkDimensionsType(dimensions) {
  let val = true;
  const values = Object.values(dimensions).filter((i) => !!i?.message);
  let someTruthy =
    values?.length > 0 ? values.every((i) => i?.type === "success") : null;
  val = someTruthy ? !someTruthy : val;

  return val;
}
