import React, { useState, useCallback, useRef, useEffect } from "react";
import { Filters } from "@shopify/polaris";
import styled from "styled-components";

import { isEmpty } from "../../helper";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterCreatedTimePolaris } from "../product/FilterCreatedTimePolaris";

const Container = styled.div``;

const PLATFORM_OPTIONS = [
  { label: "All", value: "All" },
  {
    label: "Amazon",
    value: "amazon",
  },
  {
    label: "Ebay",
    value: "ebay",
  },
];

export const ReportsFilterPolaris = (props) => {
  //Props
  const { onChange, filter } = props;
  //State
  const [queryValue, setQueryValue] = useState(null);
  const [platform, setPlatform] = useState({
    value: filter.platform,
    label: null,
  });
  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });
  const typingTimeoutRef = useRef(null);

  // Handle data
  useEffect(() => {
    if (filter.platform) {
      let currentP = PLATFORM_OPTIONS.find((i) => i.value === filter.platform);
      if (currentP) {
        setPlatform({
          value: currentP.value,
          label: currentP.label,
        });
      }
    }
  }, [filter.platform]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          platform: platform.value,
          range: filterTime.range,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, platform, filterTime]);

  // Handle actions
  /// Chang values
  const handleQueryChange = useCallback((value) => setQueryValue(value), []);

  /// Remove values
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handlePlatformRemove = useCallback(
    () => setPlatform({ value: null, label: null }),
    []
  );
  const handleFilterTimeRemove = useCallback(
    () => setFilterTime({ range: null, rangeLabel: null }),
    []
  );
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handlePlatformRemove();
    handleFilterTimeRemove();
  }, [handleQueryValueRemove, handlePlatformRemove, handleFilterTimeRemove]);

  // Markup
  const filters = [
    {
      key: "platform",
      label: "Platform",
      filter: (
        <FilterListRadioPolaris
          options={PLATFORM_OPTIONS}
          value={platform && platform.value}
          onChange={({ value, label }) =>
            setPlatform((prev) => ({ ...prev, value, label }))
          }
        />
      ),
    },
    {
      key: "createdTime",
      label: "Filter Time",
      filter: (
        <FilterCreatedTimePolaris
          filterTime={filterTime}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(platform.label)) {
    const key = "platform";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, platform.label),
      onRemove: handlePlatformRemove,
    });
  }
  if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
    const key = "createdTime";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filterTime.rangeLabel),
      onRemove: handleFilterTimeRemove,
    });
  }

  return (
    <Container>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        queryValue={queryValue}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    </Container>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "platform":
        return `Platform: ${value}`;
      case "createdTime":
        return `Time: ${value}`;
      default:
        return value;
    }
  }
};
