import React, { useState, useCallback, useEffect } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { handleError } from "../../../helper";
import { CarrierFormPolaris } from "./CarrierFormPolaris";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

const ADD_CARRIER = gql`
    mutation createCarrier($input: NewCarrier!) {
        createCarrier(input: $input) {
            id
            code
            name
            trackingUrl
        }
    }
`;

export const AddCarrierPolaris = () => {
    //State
    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [notification, setNotification] = useState({
        message: null,
        isError: false,
    });

    // Mutation
    const [createCarrier, { loading }] = useMutation(ADD_CARRIER, {
        onCompleted: () => {
            setNotification({
                message: "Create carrier successfuly.",
                isError: false,
            });
            const id = setTimeout(() => {
                handleRedirect();
            }, 3500);
            setTimeoutId(id);
        },
        onError: (error) => {
            setNotification({
                message: handleError(error.toString()),
                isError: true,
            });
        },
    });

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle actions
    const handleSubmit = useCallback((input) => {
        setNotification({
            message: null,
            isError: false,
        });

        if (input != null) {
            const { options, ...rest } = input;
            createCarrier({
                variables: {
                    input: rest,
                },
            });
        }
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = useCallback(
        () => history.push("/admin/carriers/"),
        []
    );
    const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

    // Markup
    const toastMarkup = activeToast
        ? notification &&
          notification.message && (
              <Toast
                  content={notification.message}
                  error={notification.isError}
                  duration={5000}
                  onDismiss={toggleActive}
              />
          )
        : null;
    return (
        <Container>
            {toastMarkup}
            <Page
                title="Add Carrier"
                breadcrumbs={[{ content: "Carriers", url: "/admin/carriers" }]}
            >
                <CarrierFormPolaris
                    onSubmit={handleSubmit}
                    handleRedirect={handleRedirect}
                    loading={loading}
                />
            </Page>
        </Container>
    );
};
