import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Select, Stack } from "@shopify/polaris";

import { KPI } from "../../../../../constants/task";
import {
  GET_MEMBER_BY_DESIGN_TEAM,
  GET_USER_BY_ROLES,
} from "../../../../../graphql/queries";
import { TEAM_ROLE } from "../../../../../variable";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { AutoCompletePolaris } from "../../../../shared/AutoCompletePolaris";
import { ComponentLabelPolaris } from "../../../../shared/ComponentLabelPolaris";
import { ASSIGN_IDEA_TASK_TO_DESIGNER } from "../../../../../graphql/mutations";
import { useToastContext } from "../../../../shared/ToastContext";
import { arrInvalid, handleError, objectInvalid } from "../../../../../helper";

export const KPI_OPTIONS = KPI.map((i) => ({
  value: String(i),
  label: String(i),
}));

export function AssignToDesigner({
  item,
  ids: ideaTaskIds,
  onCloseParent,
  ...props
}) {
  // Props
  const assigneeId = item?.assignee?.id;
  const ideaKpi = item?.ideaKpi != null ? String(item.ideaKpi) : "0.5";

  // State
  const [designerId, setDesignerId] = useState(null);
  const [designers, setDesigners] = useState([]);
  const [kpi, setKpi] = useState("0.5");
  const onCloseRef = useRef(null);

  // Query
  const { data } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [TEAM_ROLE.Designer, TEAM_ROLE.DesignLeader],
    },
    skip: !props.open || !item || item.designTeamID,
  });

  const { data: dataM } = useQuery(GET_MEMBER_BY_DESIGN_TEAM, {
    variables: {
      designTeamId: item?.designTeamID,
    },
    skip: !props.open || !item || !item.designTeamID,
  });

  // Context
  const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

  // Mutation
  const [assignIdeaTask, { loading }] = useMutation(
    ASSIGN_IDEA_TASK_TO_DESIGNER,
    {
      onCompleted: () => {
        setNotify({
          msg: "Assigned idea task to designer successfully.",
          err: false,
        });
        props.onClose && props.onClose();
        onCloseParent && onCloseParent();

        // onClose parent modal
        onCloseRef.current && clearTimeout(onCloseRef.current);
        onCloseRef.current = setTimeout(() => {
          setFilter && setFilter((prev) => ({ ...prev }));
          refetch && refetch();
        }, 1000);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  // Get data
  useEffect(() => {
    setDesignerId(assigneeId);
  }, [assigneeId]);

  useEffect(() => {
    if (ideaKpi) {
      setKpi(() => ideaKpi);
    }
  }, [ideaKpi]);

  useEffect(() => {
    const nodes = dataM?.getMemberByDesignTeam || [];
    const users = getUsersInfo(nodes);
    setDesigners(users);
  }, [dataM]);

  useEffect(() => {
    const nodes = data?.listUserTeamByRoles;

    let newData = nodes?.length > 0 ? getUsersInfo(nodes) : [];
    setDesigners(newData);
  }, [data]);

  // Actions
  const handleDesigner = useCallback((value) => setDesignerId(value), []);
  const handleKpi = useCallback((value) => setKpi(value), []);
  const handleSubmit = useCallback(() => {
    if (ideaTaskIds?.length > 0 && kpi) {
      const newKpi = parseFloat(kpi);
      toggleToast && toggleToast(true);
      setNotify && setNotify({ msg: null, err: false });
      assignIdeaTask({
        variables: {
          input: {
            ideaTaskIds,
            designerId,
            kpi: newKpi,
          },
        },
      });
    }
  }, [designerId, kpi, ideaTaskIds, assignIdeaTask, setNotify, toggleToast]);

  return (
    <Modal
      {...props}
      sectioned
      title="Assign to Designer"
      large
      secondaryActions={[{ content: "Close", onAction: props.onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        disabled: designerId == null,
        loading: loading,
      }}
    >
      <Stack vertical>
        <AutoCompletePolaris
          options={designers}
          onChange={handleDesigner}
          label="Assign to designer"
          required
          value={designerId}
        />
        <div>
          <ComponentLabelPolaris label="Design KPI" required />
          <Select options={KPI_OPTIONS} value={kpi} onChange={handleKpi} />
        </div>
      </Stack>
    </Modal>
  );
}

function getUsersInfo(users) {
  if (arrInvalid(users)) return [];

  return users
    .map((user) => {
      if (objectInvalid(user)) return null;
      let newUser = 'user' in user ? user.user : user;
      const { id, firstName, lastName } = newUser;
      return {
        value: id,
        label: [firstName, lastName].filter(Boolean).join(" "),
      };
    })
    .filter(Boolean);
}


