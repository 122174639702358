import { useMutation } from "@apollo/react-hooks";
import {
    Button,
    Collapsible,
    Stack,
    TextField,
    TextStyle,
    Toast,
} from "@shopify/polaris";
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { BULK_CHANGE_TITLE_PRODUCT_CRAWL_ITEM } from "../../../graphql/mutations";
import { GET_PRODUCT_BY_TITLE } from "../../../graphql/queries";
import { handleError } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import {
    AMZ_MESSAGE_ERROR,
    MAX_LENGTH,
    MAX_TITLE_CHARACTERS,
    TITLE_LENGTH_MESS,
    WHICH_FIELD,
} from "../../../variable";
import { ShortTitleComp } from "../../product/components/ShortTitle";
import { AssortmentContext } from "../context/AssortmentContextPolaris";
import { QUERY_PRODUCT_ASSORTMENT } from "../ProductAssortmentPolaris";
import { UPDATE_PRODUCT_TITLE } from "../ProductAssortmentTitlePolaris";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap-gap: 1rem;

    .Polaris-Button--plain.Polaris-Button--iconOnly:focus,
    .Polaris-Button--plain.Polaris-Button--iconOnly:active {
        background: transparent;
    }

    .input-field {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 1rem;
    }
`;

export const FieldTitlePolaris = ({
    currentValue,
    isBulkActions,
    bulkSpecial,
    onCompleted,
    ids,
    detectSubmit,
    handAssign = () => {},
    toggleDetectSubmit = () => {},
}) => {
    // Props
    const { id, title, fbTitle, amzTitle } = currentValue || {};

    // Context
    const { filter } = useContext(AssortmentContext);
    // State
    const [edit, toggleEdit] = useToggle(!!title ? false : true);
    const [errors, setErrors] = useState({});
    const [toastActive, toggleToastActive] = useToggle(false);
    const [state, setState] = useState({
        title,
        fbTitle,
        amzTitle,
    });
    const [notify, setNotify] = useState({
        msg: null,
        err: false,
    });
    const typingRef = useRef(null);

    // Mutations
    const [updateProductCrawlItemTitle, { loading, client }] = useMutation(
        UPDATE_PRODUCT_TITLE
    );
    const [
        updateTitleForMultiItem,
        { loading: loadingB, client: clientB },
    ] = useMutation(BULK_CHANGE_TITLE_PRODUCT_CRAWL_ITEM);

    // Get data
    useEffect(() => {
        if (bulkSpecial) {
            toggleEdit(true);
        }
    }, [bulkSpecial, toggleEdit]);

    // Handle actions
    const validateTitle = useCallback((title) => {
        let error = null;
        if (title === "") {
            error = "Product title is required.";
        } else if (title.length > MAX_LENGTH) {
            error = TITLE_LENGTH_MESS;
        }
        setErrors((prev) => ({ ...prev, title: error }));
    }, []);

    const handleTitleChange = useCallback(
        (value, id) => {
            if (id === "title") {
                validateTitle(value);
            }
            setState((prev) => ({ ...prev, [id]: value }));
        },
        [validateTitle]
    );

    const handleUpdateCache = useCallback(
        ({ id, title, fbTitle, amzTitle }) => {
            if (id) {
                const variables = {
                    filter,
                };
                const cache = client.readQuery({
                    query: QUERY_PRODUCT_ASSORTMENT,
                    variables,
                });

                client.writeQuery({
                    query: QUERY_PRODUCT_ASSORTMENT,
                    variables,
                    data: {
                        ...cache,
                        productAssortment: {
                            ...cache.productAssortment,
                            nodes: cache.productAssortment.nodes.map((node) => {
                                if (node.id === id) {
                                    return {
                                        ...node,
                                        title,
                                        fbTitle,
                                        amzTitle,
                                    };
                                }
                                return node;
                            }),
                        },
                    },
                });
            }
        },
        [filter, client]
    );
    const disabled =
        title === state.title &&
        fbTitle === state.fbTitle &&
        amzTitle === state.amzTitle;

    const handleSubmit = useCallback(async () => {
        if (id && !disabled && state.title) {
            toggleToastActive && toggleToastActive();
            toggleDetectSubmit(false);

            if (setNotify) {
                setNotify({
                    msg: null,
                    err: false,
                });
            }

            if (state.amzTitle && amzTitle !== state.amzTitle) {
                const { data } = await client.query({
                    query: GET_PRODUCT_BY_TITLE,
                    variables: {
                        title: state.amzTitle,
                        whichField: WHICH_FIELD.AmzTitle,
                    },
                });

                if (data?.getProductsByTitle?.total > 0) {
                    setNotify({ msg: AMZ_MESSAGE_ERROR, err: true });
                    return;
                }
            }

            updateProductCrawlItemTitle({
                variables: {
                    id,
                    ...state,
                },
            })
                .then(() => {
                    if (setNotify) {
                        setNotify({
                            msg: "Update title successfully.",
                            err: false,
                        });
                    }
                    toggleEdit();
                    handleUpdateCache({ id, ...state });
                    handAssign();
                })
                .catch((error) => {
                    if (setNotify) {
                        setNotify({
                            msg: handleError(error.toString()),
                            err: true,
                        });
                    }
                });
        } else {
            handAssign();
            toggleDetectSubmit(false);
        }
    }, [
        id,
        state,
        disabled,
        client,
        amzTitle,
        handAssign,
        toggleDetectSubmit,
        handleUpdateCache,
        toggleEdit,
        toggleToastActive,
        updateProductCrawlItemTitle,
    ]);

    const handleUpdateCacheMultiItem = useCallback(
        ({ ids, title, fbTitle, amzTitle }) => {
            if (ids.length > 0) {
                const variables = {
                    filter,
                };
                const cache = client.readQuery({
                    query: QUERY_PRODUCT_ASSORTMENT,
                    variables,
                });

                client.writeQuery({
                    query: QUERY_PRODUCT_ASSORTMENT,
                    variables,
                    data: {
                        ...cache,
                        productAssortment: {
                            ...cache.productAssortment,
                            nodes: cache.productAssortment.nodes.map((node) => {
                                if (ids.includes(node.id)) {
                                    return {
                                        ...node,
                                        title,
                                        fbTitle,
                                        amzTitle,
                                    };
                                }
                                return node;
                            }),
                        },
                    },
                });
            }
        },
        [clientB, filter, client]
    );

    const handleBulkSubmit = useCallback(async () => {
        if (ids.length > 0 && !disabled && state.title) {
            toggleToastActive && toggleToastActive();
            toggleDetectSubmit(false);

            if (setNotify) {
                setNotify({
                    msg: null,
                    err: false,
                });
            }

            if (state.amzTitle && amzTitle !== state.amzTitle) {
                const { data } = await client.query({
                    query: GET_PRODUCT_BY_TITLE,
                    variables: {
                        title: state.amzTitle,
                        whichField: WHICH_FIELD.AmzTitle,
                    },
                });

                if (data?.getProductsByTitle?.total > 0) {
                    setNotify({ msg: AMZ_MESSAGE_ERROR, err: true });
                    return;
                }
            }

            updateTitleForMultiItem({
                variables: {
                    ids,
                    ...state,
                },
            })
                .then(() => {
                    if (setNotify) {
                        setNotify({
                            msg: "Update title for list item successfully.",
                            err: false,
                        });
                    }
                    toggleEdit();
                    handleUpdateCacheMultiItem({ ids, ...state });
                    handAssign();

                    typingRef.current && clearTimeout(typingRef.current);
                    typingRef.current = setTimeout(() => {
                        onCompleted && onCompleted();
                    }, 1500);
                })
                .catch((error) => {
                    if (setNotify) {
                        setNotify({
                            msg: handleError(error.toString()),
                            err: true,
                        });
                    }
                });
        } else {
            handAssign();
            toggleDetectSubmit(false);
        }
    }, [
        ids,
        state,
        disabled,
        client,
        amzTitle,
        onCompleted,
        handAssign,
        toggleEdit,
        toggleToastActive,
        toggleDetectSubmit,
        updateTitleForMultiItem,
        handleUpdateCacheMultiItem,
    ]);

    useEffect(() => {
        if (detectSubmit) {
            let fn = isBulkActions ? handleBulkSubmit : handleSubmit;
            fn();
        }
    }, [detectSubmit, isBulkActions, handleBulkSubmit, handleSubmit]);

    // Markup
    const loadingOrigin = isBulkActions ? loadingB : loading;
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            onDismiss={toggleToastActive}
            duration={3000}
        />
    );
    const handleClickOrigin = isBulkActions ? handleBulkSubmit : handleSubmit;

    return (
        <Container>
            {toastMarkup}
            <div onClick={(e) => e.stopPropagation()} style={{ width: "100%" }}>
                <Stack spacing="tight" vertical>
                    {!edit && (
                        <div>
                            <TextStyle
                                children="Origin Title: "
                                variation="strong"
                            />
                            <span>{isBulkActions ? state.title : title}</span>
                        </div>
                    )}
                    {!edit && (fbTitle || state.fbTitle) && (
                        <div>
                            <TextStyle
                                children="FB Title: "
                                variation="strong"
                            />
                            <span>
                                {isBulkActions ? state.fbTitle : fbTitle}
                            </span>
                        </div>
                    )}
                    {!edit && (amzTitle || state.amzTitle) && (
                        <div>
                            <TextStyle
                                children="AMZ Title: "
                                variation="strong"
                            />
                            <span>
                                {isBulkActions ? state.amzTitle : amzTitle}
                            </span>
                        </div>
                    )}
                    <Collapsible
                        id={`assign-template-titles-${id}`}
                        open={edit}
                        transition={{
                            duration: "550ms",
                            timingFunction: "ease",
                        }}
                    >
                        <Stack vertical spacing="tight" distribution="fill">
                            <TextField
                                value={state.title}
                                id="title"
                                onChange={handleTitleChange}
                                placeholder="Enter origin title"
                                error={errors.title}
                                multiline={4}
                                autoFocus
                                label={
                                    <TextStyle
                                        variation="strong"
                                        children="Origin Title: "
                                    />
                                }
                            />
                            <ShortTitleComp
                                value={state.fbTitle}
                                onChange={handleTitleChange}
                                id="fbTitle"
                                multiline={4}
                                maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                                placeholder="facebook title"
                                label={
                                    <TextStyle
                                        variation="strong"
                                        children="FB Title: "
                                    />
                                }
                            />
                            <ShortTitleComp
                                value={state.amzTitle}
                                onChange={handleTitleChange}
                                id="amzTitle"
                                multiline={4}
                                maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                                placeholder="amazon title"
                                label={
                                    <TextStyle
                                        variation="strong"
                                        children="AMZ Title: "
                                    />
                                }
                            />
                            {bulkSpecial && (
                                <Button
                                    children="Submit"
                                    primary
                                    disabled={disabled}
                                    onClick={handleClickOrigin}
                                    size="slim"
                                    loading={loadingOrigin}
                                />
                            )}
                        </Stack>
                    </Collapsible>
                </Stack>
            </div>
            {!bulkSpecial && (
                <div style={{ marginTop: "1.6rem" }}>
                    <Button
                        children="Update Titles"
                        onClick={toggleEdit}
                        size="slim"
                    />
                </div>
            )}
        </Container>
    );
};
