import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Checkbox, Input, Popover, Select, Table } from "antd";
import { ELEMENTS, initNewField } from "./Customize";
import styled from "styled-components";
import FieldConfigureOptions from "./FieldConfigureOptions";

const Container = styled.div`
  .actions {
    display: flex;
    flex-direction: row-reverse;
    &.simple-customize {
      margin-top: 0px;
    }
    button.add-field {
      width: auto;
    }
    .btn-default {
      color: #4a5568;
      margin-right: 10px;
    }
  }
  .btn-close {
    color: #4a5568;
  }
  .ant-table-thead > tr > th {
    font-weight: normal;
    background: none;
  }
  .ant-table-default .ant-table-thead > tr > th {
    padding: 5px 10px;
    border: 0 none;
  }
  .ant-table-tbody > tr > td {
    border: 0 none;
  }
  .ant-table-default .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
  .ant-table-placeholder {
    border: 0 none;
  }
  .ant-table-default {
    border: 0 none;
  }
  .input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    justify-content: space-between;
    row-gap: 1rem;
    > * {
      width: 48%;
    }
  }
`;
class SimpleCustomize extends Component {
  state = {
    editFieldIndex: -1,
    checked: true,
  };
  onChange = () => {
    const { value } = this.props;
    this.props.onChange(value);
  };
  handleAddNewField = () => {
    const { value, mapOrder } = this.props;
    const element = ELEMENTS[0];
    const index = value.length + 1;
    let newValue = initNewField(element, index);
    if (mapOrder) {
      newValue["fieldValue"] = "";
    }
    this.props.onChange([...value, { ...newValue }]);
  };
  render() {
    const { value, mapOrder, personalized } = this.props;
    const columns = [
      {
        title: "Type",
        width: 120,
        render: (_, record, idx) => (
          <Select
            onChange={(type) => {
              record.type = type;
              const element = ELEMENTS.find((e) => e.type === type);
              const field = initNewField(element, idx);
              record.configure = field.configure;
              this.props.onChange(
                value.map((v, i) => (i === idx ? record : v))
              );
            }}
            value={record.type}
            style={{ width: "100%" }}
          >
            {ELEMENTS.map((e, elementIndex) => (
              <Select.Option value={e.type} key={`e-${elementIndex}`}>
                {e.title}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Title",
        render: (_, record, idx) => (
          <Input
            onChange={(e) => {
              record.title = e.target.value;
              if (mapOrder && personalized) {
                let fieldValue = "";
                personalized.length &&
                  personalized.forEach((p) => {
                    if (
                      p.name &&
                      p.name.toLowerCase().trim() ===
                        e.target.value.toLowerCase().trim()
                    ) {
                      fieldValue = p.value;
                    }
                  });
                record["fieldValue"] = fieldValue;
              }
              this.props.onChange(
                value.map((v, i) => (i === idx ? record : v))
              );
            }}
            value={record.title}
            placeholder={"Title"}
          />
        ),
      },
      {
        title: "Placeholder",
        render: (_, record, idx) => (
          <Input
            onChange={(e) => {
              record.configure.placeholder = e.target.value;
              this.props.onChange(
                value.map((v, i) => (i === idx ? record : v))
              );
            }}
            value={record.configure.placeholder}
            placeholder={"Placeholder"}
          />
        ),
      },
      {
        title: "Default Value",
        render: (_, record, idx) => (
          <Input
            onChange={(e) => {
              record.configure.settings.defaultValue = e.target.value;
              this.props.onChange(
                value.map((v, i) => (i === idx ? record : v))
              );
            }}
            value={record.configure.settings.defaultValue}
            placeholder={"Default Value"}
          />
        ),
      },
      {
        title: "Required",
        width: 80,
        render: (_, record, idx) => (
          <Checkbox
            defaultChecked={this.state.checked}
            onChange={(e) => {
              record.configure.required = e.target.checked;
              this.props.onChange(
                value.map((v, i) => (i === idx ? record : v))
              );
            }}
            // checked={record.configure.required}
          />
        ),
      },
      {
        title: "Actions",
        render: (_, record, idx) => (
          <div style={{ display: "flex" }}>
            <Button
              onClick={() => {
                this.props.onChange(value.filter((v, i) => idx !== i));
              }}
              className={"btn-close"}
              type="link"
              icon={<LegacyIcon type={"close"} />}
            />
            {record.type !== "text" && record.type !== "textarea" && (
              <Popover
                title={value[idx].title}
                trigger="click"
                onVisibleChange={(bool) => {
                  this.setState({ editFieldIndex: bool ? idx : -1 });
                }}
                visible={this.state.editFieldIndex === idx}
                content={
                  <FieldConfigureOptions
                    onChange={(v) => {
                      this.props.onChange(
                        value.map((vv, i) => (i === idx ? v : vv))
                      );
                    }}
                    value={value[idx]}
                  />
                }
              >
                <Button
                  onClick={() => {
                    this.setState({ editFieldIndex: idx });
                  }}
                  type={"link"}
                >
                  Edit
                </Button>
              </Popover>
            )}
          </div>
        ),
      },
    ];
    return (
      <Container>
        <Table
          bordered={false}
          pagination={false}
          locale={{ emptyText: "Empty custom fields" }}
          columns={columns}
          dataSource={value}
          rowKey={(_, idx) => idx}
        />
        <div className="actions simple-customize">
          <Button onClick={this.handleAddNewField} className="add-field">
            Add Field
          </Button>
        </div>
        <div>
          {mapOrder && value && value.length > 0 ? (
            <>
              <label>Personalized values</label>
              <div className="input-group">
                {value.map((v, idx) => (
                  <div key={idx}>
                    <label>{v.title}</label>
                    <Input
                      value={value[idx]["fieldValue"]}
                      onChange={(e) => {
                        let input = e.target.value;
                        v["fieldValue"] = input;
                        this.props.onChange(
                          value.map((i, index) => (index === idx ? v : i))
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </Container>
    );
  }
}

SimpleCustomize.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default SimpleCustomize;
