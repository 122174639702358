import React, { useCallback } from "react";
import { ChoiceList } from "@shopify/polaris";
import PropTypes from "prop-types";

export const FilterBoolValuePolaris = ({ options, value, onChange }) => {
  // Handle actions
  const handleOnChange = useCallback(
    (newValue) => {
      let currentValue =
        options?.length > 0
          ? options.find((option) => newValue.includes(option.value))
          : null;
      if (currentValue) {
        if (onChange) {
          onChange({
            value: currentValue.value,
            label: currentValue.label,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <ChoiceList
      selected={value.value}
      choices={options}
      onChange={handleOnChange}
    />
  );
};

FilterBoolValuePolaris.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
