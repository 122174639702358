import React from "react";
import { DataTable } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import {
    convertToOffset,
    convertToPaged,
    NumberToFixed,
} from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const PaginationContainer = styled.div`
    padding: 1.6rem;
    overflow-x: scroll;
    background: var(--p-surface-subdued, #f4f6f8);
`;

const Container = styled.div`
    .designer-wrap {
        width: calc(25rem - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }
`;

export const StatisticDT = ({ data, filter, setFilter }) => {
    //State
    const [rows, setRows] = React.useState([]);

    // Get data
    React.useEffect(() => {
        const newRows =
            data?.getCountTaskRejectForUser?.nodes?.length > 0
                ? data.getCountTaskRejectForUser.nodes
                      .map((node) => {
                          if (!node) {
                              return null;
                          }
                          const {
                              reject,
                              firstName,
                              lastName,
                              kpi,
                              kpiByRejectTimes,
                          } = node;

                          // Designer
                          const designerMarkup = [firstName, lastName]
                              .filter(Boolean)
                              .join(" ");

                          // Done tasks rejected times
                          //   const rejectedTime = doneTaskRejectedTimes;
                          //   const rejectedTimesDone = (
                          //       <div className="rejected-time-done-wrap">
                          //           <span>{rejectedTime}</span>
                          //       </div>
                          //   );

                          // Done task rejected percentage
                          // TODO: (rejected times / total kpi) * 100
                          let percent =
                              kpi && kpiByRejectTimes
                                  ? (kpiByRejectTimes / kpi) * 100
                                  : null;
                          percent = percent ? NumberToFixed(percent, 2) : null;
                          const percentMarkup = (
                              <div className="percent-wrap">
                                  <span>{percent ? `${percent}%` : ""}</span>
                              </div>
                          );

                          return [
                              <div className="designer-wrap">
                                  <span>{designerMarkup}</span>
                              </div>,
                              <div className="reject-times-wrap">
                                  <span>{reject}</span>
                              </div>,
                              //   rejectedTimesDone,
                              percentMarkup,
                          ];
                      })
                      .filter(Boolean)
                : [];
        setRows(newRows);
    }, [data]);

    const handleFilter = React.useCallback((offset, limit) => {
        const paged = convertToPaged(limit, offset);
        if (setFilter) {
            setFilter({ paged, limit });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Variable
    const cols = [];
    const n = 10;
    for (let i = 0; i < n; i++) {
        cols.push("text");
    }

    const headings = [
        "Designer",
        "Total reject times",
        // "Done tasks rejected times",
        "Reject times percentage",
    ];

    let { limit, paged } = filter;
    let offset = convertToOffset(limit, paged);
    const total = get(data, "getCountTaskRejectForUser.total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: handleFilter,
        total,
    };

    return (
        <React.Fragment>
            <Container>
                <DataTable
                    rows={rows}
                    columnContentTypes={cols}
                    headings={headings}
                    verticalAlign="middle"
                    hideScrollIndicator
                />
            </Container>
            <PaginationContainer>
                <PaginationPolaris aggregation={aggregation} showTotal />
            </PaginationContainer>
        </React.Fragment>
    );
};
