import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import Price from "../Price";

class SupplierPricing extends Component {
  render() {
    const { suppliers } = this.props;
    let { value } = this.props;
    let mapUserById = {};
    for (let i = 0; i < suppliers.length; i++) {
      mapUserById[suppliers[i].id] = suppliers[i];
    }
    return (
      <div>
        {value.map((v, index) => {
          const supplier = mapUserById[v.userId];
          if (!supplier) {
            return null;
          }
          return (
            <Form.Item
              key={index}
              label={`${supplier.firstName} ${supplier.lastName}`}
            >
              <Price
                min={0}
                value={v.price ? v.price : 0}
                onChange={(newValue) => {
                  value[index].price = newValue;
                  if (this.props.onChange) {
                    this.props.onChange(value);
                  }
                }}
              />
            </Form.Item>
          );
        })}
      </div>
    );
  }
}

SupplierPricing.propTypes = {
  suppliers: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default SupplierPricing;
