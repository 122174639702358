import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  Card,
  Checkbox,
  Collapsible,
  Scrollable,
  Spinner,
  Stack,
} from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { REPORT_IDEA_TASKS_BY_DESIGNER } from "../../../graphql/queries";
import { checkRole, getUnique } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";
import { ComparedPercent } from "./ComparedPercent";
import { headerProps, tdProps, trProps } from "./IdeaTasks";
import { useCollapseContext } from "./LayoutSection";

const Denied = "Denied";
const Rejected = "Rejected";
const AssignToMe = "Assign To Me";
const Done = "Done";

const collapseKey = "ideaTasksByDesigner";
export function IdeaTasksByDesigner() {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();
  const { collectionIds, tagIds } = filterCtx || {};
  const { currentUser } = useAppContext();
  const { isDesignLeader } = checkRole(currentUser);
  const { collapseActive = {}, setCollapseActive = () => {} } =
    useCollapseContext() || {};
  const open = !!collapseActive[collapseKey];

  const btnRef = useRef(null);

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    filterByTime: range,
    tagIds,
    collectionIds,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalTask: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalTask: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const [isDoneDate, setIsDoneDate] = useState(false);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, fetchMore } = useQuery(REPORT_IDEA_TASKS_BY_DESIGNER, {
    variables: {
      filter,
    },
  });
  const [lazyReport, { data: dataC, loading: loadingC }] = useLazyQuery(
    REPORT_IDEA_TASKS_BY_DESIGNER,
  );

  useEffect(() => {
    if (filterCtx != null) {
      let ignoreFilter = filterCtx;
      if ("productBaseIds" in filterCtx || "viewWholeTeamReport" in filterCtx) {
        let productBaseIds, viewWholeTeamReport;
        ({ productBaseIds, viewWholeTeamReport, ...ignoreFilter } = filterCtx);
      }
      setFilter((prev) => ({ ...prev, ...ignoreFilter }));
    }
  }, [filterCtx]);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      teamId: teamID ?? undefined,
      filterByTime:
        range != null ? { ...(prev.filterByTime || {}), ...range } : null,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportIdeaTasksByDesigner?.nodes;

    const total = data?.reportIdeaTasksByDesigner?.total;
    const totalTask = data?.reportIdeaTasksByDesigner?.totalTask;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            // Created by
            const designer = node?.designer;
            const firstName = designer?.firstName;
            const lastName = designer?.lastName;
            const name = [firstName, lastName].filter(Boolean).join(" ");

            // Status
            const { taskByStatusItems } = node;
            const denied = (taskByStatusItems || []).find(
              (i) => i?.status === Denied,
            );
            const rejected = (taskByStatusItems || []).find(
              (i) => i?.status === Rejected,
            );
            const done = (taskByStatusItems || []).find(
              (i) => i?.status === Done,
            );
            const assignToMe = (taskByStatusItems || []).find(
              (i) => i?.status === AssignToMe,
            );

            return {
              name,
              denied,
              done,
              rejected,
              assignToMe,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalTask,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalTask = dataC?.reportIdeaTasksByDesigner?.totalTask;
      setStateCompare((prev) => ({
        ...prev,
        totalTask,
      }));
    } else {
      setStateCompare({ totalTask: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportIdeaTasksByDesigner.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportIdeaTasksByDesigner =
              fetchMoreResult.reportIdeaTasksByDesigner;
            const nodes = reportIdeaTasksByDesigner.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [
              ...prev.reportIdeaTasksByDesigner.nodes,
              ...nodes,
            ];
            const result = getUnique(newNodes, "designerId");

            return {
              ...prev,
              reportIdeaTasksByDesigner: {
                ...prev.reportIdeaTasksByDesigner,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const textCenter = {
    textAlign: "center",
    width: "15rem",
  };

  const headMarkup = (
    <thead>
      <tr>
        <th
          rowSpan="2"
          {...headerProps}
          style={{ textAlign: "center", width: "7rem" }}
        >
          #
        </th>
        <th
          rowSpan="2"
          {...headerProps}
          style={{ ...textCenter, width: "20rem" }}
        >
          Name
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Denied
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Rejected
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Assign To Me
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Done
        </th>
      </tr>
      <tr>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
      </tr>
    </thead>
  );

  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          const denied = item?.denied;
          const rejected = item?.rejected;
          const done = item?.done;
          const assignToMe = item?.assignToMe;

          return (
            <tr {...trProps} key={`item-${index}`}>
              <td {...tdProps}>{index + 1}</td>
              <td {...tdProps}>{item.name}</td>
              <td {...tdProps}>{denied?.totalIdeas}</td>
              <td {...tdProps}>{denied?.totalKpis}</td>
              <td {...tdProps}>{rejected?.totalIdeas}</td>
              <td {...tdProps}>{rejected?.totalKpis}</td>
              <td {...tdProps}>{assignToMe?.totalIdeas}</td>
              <td {...tdProps}>{assignToMe?.totalKpis}</td>
              <td {...tdProps}>{done?.totalIdeas}</td>
              <td {...tdProps}>{done?.totalKpis}</td>
            </tr>
          );
        })
      : null;
  }, [state.items]);

  const handleDoneDateChange = useCallback((value) => {
    if (value) {
      setFilter((prev) => ({
        ...prev,
        filterByTime: prev.filterByTime
          ? { ...prev.filterByTime, field: "done_date" }
          : null,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        filterByTime: prev.filterByTime
          ? { ...prev.filterByTime, field: undefined }
          : null,
      }));
    }

    setIsDoneDate(value);
  }, []);

  const subTitleMarkup = useMemo(
    () => (
      <Stack vertical spacing="none">
        <span>
          <span>{state.totalTask} Tasks</span>
          <ComparedPercent
            originalVal={state.totalTask}
            newVal={stateCompare.totalTask}
          />
        </span>
        {filter.filterByTime != null && (
          <Checkbox
            checked={isDoneDate}
            onChange={handleDoneDateChange}
            label="For Done Date"
          />
        )}
      </Stack>
    ),
    [
      state.totalTask,
      stateCompare.totalTask,
      filter.filterByTime,
      isDoneDate,
      handleDoneDateChange,
    ],
  );

  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);
  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  const headerMarkup = useMemo(
    () => (
      <Stack spacing="loose">
        <Stack.Item fill>
          <HeadingWrap>
            <span className="btn-wrap">
              <CaretRightOutlined
                rotate={open ? 90 : 0}
                style={{ color: "#000" }}
                ref={btnRef}
                onClick={handleToggle}
              />
            </span>
            <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
              {`Report for ${isDesignLeader ? "Idea Task" : "Designer"}`}
            </h2>
          </HeadingWrap>
        </Stack.Item>
        <span>{subTitleMarkup}</span>
      </Stack>
    ),
    [subTitleMarkup, isDesignLeader],
  );

  const spinnerMarkup = (
    <Stack distribution="center">
      <Spinner size="small" />
    </Stack>
  );

  const loadMoreMarkup = loadMore && (
    <div style={{ marginTop: "1rem" }}>{spinnerMarkup}</div>
  );

  return (
    <Card>
      <Card.Header title={headerMarkup} />
      <Card.Section>
        <Collapsible
          open={open}
          id={collapseKey}
          transition={{
            duration: "500ms",
            timingFunction: "ease-in-out",
          }}
          expandOnPrint
        >
          <Wrapper>
            {mergedLoading ? (
              spinnerMarkup
            ) : contentMarkup ? (
              <Scrollable
                shadow
                id={`scrollable-idea-task`}
                style={{
                  maxHeight: "48rem",
                }}
                onScrolledToBottom={handleScroll}
              >
                <table>
                  {headMarkup}
                  <tbody>{contentMarkup}</tbody>
                </table>
                {loadMoreMarkup}
              </Scrollable>
            ) : (
              <EmptyStatePolaris noData slim />
            )}
          </Wrapper>
        </Collapsible>
      </Card.Section>
    </Card>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;

  table {
    table-layout: fixed;
    width: 100%;
    min-width: 65rem;

    tr,
    th,
    td {
      border: 0.1rem solid var(--p-border, #c4cdd5);
      white-space: normal;
      word-break: break-word;
    }
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;
