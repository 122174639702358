import React, { useState, useCallback, useEffect } from 'react';
import { FormLayout, Button, Checkbox, Spinner } from '@shopify/polaris';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import styled from 'styled-components';

import { handleError, getUnique } from '../../../helper';
import useToggle from '../../../hooks/useToggle';
import { ProductBasesSelectPolaris } from '../ProductBasesSelectPolaris';
import { ProductSelectByFFPolaris } from '../ProductSelectByFFPolaris';
import { CollectionSelectPolaris } from '../../product/collections/CollectionSelectPolaris';
import { TagsSelectPolaris } from '../../product/tags/TagsSelectPolaris';
import { MainImageSelectorPolaris } from '../MainImageSelectorPolaris';
import { SimpleCustomizePolaris } from '../../customize/SimpleCustomizePolaris';
// import { ComponentLabelPolaris } from '../../shared/ComponentLabelPolaris';
import { UsingProductDesignFilePolaris } from './UsingProductDesignFilePolaris';
import { FieldTitlePolaris } from '../components/FieldTitlePolaris';
import { useAppContext } from '../../../context';
import { PME_TEAM_ID } from '../../../variable';

const LOAD_QUERY = gql`
  query productBasesForAssortment {
    fulfillments {
      id
      name
    }
    fulfillmentConfigs {
      id
      fulfillmentId
    }
    productBasesForAssortment {
      id
      title
      fulfillment {
        fulfillmentId
        productId
      }
      designPositions {
        id
        name
      }
    }
  }
`;

const ASSIGN_PRODUCT_MUTATION = gql`
  mutation assignProduct($input: NewAssignProduct!) {
    assignProduct(input: $input) {
      products {
        id
        title
        sku
        description
        designStatus
        personalized
        status
        productBases {
          id
          title
        }
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
        fields {
          id
          title
          name
          type
          extraFee
          configure
          sorting
        }
      }
      success
      failed
    }
  }
`;

const Container = styled.div`
  .product-base_wrap {
    padding-top: 2.4rem;
  }
  .title_wrap {
    span.title-content {
      opacity: 0.8;
    }
  }
`;

const MERCHKING = 'MerchKing';

export const AssignButtonFormPolaris = (props) => {
  const {
    ids,
    currentChecked,
    toggleActive,
    onNotificationChange,
    onCompleted,
    toggleShowModal,
    currentValue,
    bulkActions,
    dataCheckeds,
  } = props;

    // Context
    const { currentUser } = useAppContext();
    const { teamUser } = currentUser || {};
    const { team } = teamUser || {};
    const { id: teamId } = team || {};

  const { data, loading } = useQuery(LOAD_QUERY);
  const [fulfillmentId, setFulfillmentId] = useState(null);
  const [pBaseId, setPBaseId] = useState(null);
  const [collections, setCollections] = useState(PME_TEAM_ID === teamId ? ['hGypw']: []);
  const [tags, setTags] = useState([]);
  const [mainImageId, setMainImageId] = useState(null);
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [checkPersonalized, setCheckPersonalized] = useState(false);
  const [fields, setFields] = useState([]);
  const [designPositions, setDesignPositions] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [designUrls, setDesignUrls] = useState([]);
  const [detectSubmit, toggleDetectSubmit] = useToggle(false);

  const [errors, setErrors] = useState({
    pBase: null,
    collections: null,
    tags: null,
  });

  const [assignProduct, { loading: loadingA }] = useMutation(
    ASSIGN_PRODUCT_MUTATION,
    {
      onError: (error) => {
        if (onNotificationChange) {
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
        }
        toggleDetectSubmit(false);
      },
      onCompleted: (res) => {
        toggleShowModal();
        if (onNotificationChange) {
          onNotificationChange({
            message: `Total ${res.assignProduct.success} items assigned success.`,
            isError: false,
          });
        }
        if (onCompleted) {
          onCompleted();
        }
      },
    }
  );

  // Get data
  useEffect(() => {
    if (dataCheckeds?.length) {
      const newDesignUrls = dataCheckeds
        .map((item) => {
          const isMerchKing = [MERCHKING].includes(item?.source);

          let designUrl =
            item?.designUrls?.length > 0
              ? item.designUrls.map((i) => {
                  const splitted = i.split('||');
                  if (splitted.length > 1) {
                    return splitted[1];
                  }
                  return splitted[0];
                })
              : [];
          if (isMerchKing) {
            return {
              productCrawlItemId: item?.id,
              designUrl,
            };
          }
          return null;
        })
        .filter(Boolean);
      setDesignUrls(newDesignUrls);
    }
  }, [dataCheckeds]);

  useEffect(() => {
    let currentPB =
      data &&
      data.productBasesForAssortment &&
      data.productBasesForAssortment.length > 0
        ? data.productBasesForAssortment.find((i) => i.id === pBaseId)
        : [];
    if (currentPB) {
      let newDP =
        currentPB && currentPB.designPositions ? currentPB.designPositions : [];
      let masterArr = [];
      let frontArr = [];
      let restArr = [];

      newDP.forEach((dp) => {
        if (dp.name) {
          let lowerName = dp.name.toLowerCase();
          if ('master' === lowerName) {
            masterArr.push(dp);
          } else if ('front' === lowerName) {
            frontArr.push(dp);
          } else {
            restArr.push(dp);
          }
        }
      });
      let originDP = [...masterArr, ...frontArr, ...restArr];
      let dpByBase =
        originDP?.length > 0
          ? originDP
              .map((i) => ({ value: i.id, label: i.name }))
              .filter(Boolean)
          : [];
      setDesignPositions(dpByBase);
    }
  }, [data, pBaseId]);

  useEffect(() => {
    if (currentChecked) {
      setMainImageId(get(currentChecked, 'images[0][file].id'));
    }
  }, [currentChecked]);

  // Handle actions
  const handleFieldsChange = useCallback((value) => {
    setFields(() => [...value]);
  }, []);

  const handleBaseValue = useCallback((value) => {
    let pb = null;
    if (!value) {
      pb = 'Product base is required.';
    }
    setErrors((prev) => ({
      ...prev,
      pBase: pb,
    }));
  }, []);
  const handleCollectionsValue = useCallback((value) => {
    let c = null;
    if (!value.length) {
      c = 'Collections is required.';
    }
    setErrors((prev) => ({
      ...prev,
      collections: c,
    }));
  }, []);
  const handleTagsValue = useCallback((value) => {
    let t = null;
    if (!value.length) {
      t = 'Tags is required.';
    }
    setErrors((prev) => ({
      ...prev,
      tags: t,
    }));
  }, []);

  const handAssign = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }

    handleBaseValue(pBaseId);
    handleCollectionsValue(collections);
    handleTagsValue(tags);

    let newDesigns =
      designs?.length > 0 ? designs.filter((i) => i.designPositionId) : [];
    newDesigns = getUnique(newDesigns, 'designPositionId');

    let input = {
      productBaseId: pBaseId,
      productCrawlItems: ids,
      collections,
      tags,
      personalized: checkPersonalized,
      fields,
      mainImageId,
      deleteImages: deleteImgs,
      designs: newDesigns,
      designUrls: designUrls?.length > 0 ? designUrls : undefined,
    };
    if (pBaseId && collections.length && tags.length) {
      assignProduct({
        variables: {
          input,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pBaseId,
    collections,
    tags,
    mainImageId,
    deleteImgs,
    fields,
    checkPersonalized,
    ids,
    designs,
    designUrls,
  ]);

  const handleSubmit = useCallback(() => {
    toggleDetectSubmit(true);
  }, [toggleDetectSubmit]);

  return (
    <Container>
      <FormLayout>
        <div className='title_wrap'>
          <div className='title-content'>
            <FieldTitlePolaris
              currentValue={currentValue}
              toggleActive={toggleActive}
              onNotificationChange={onNotificationChange}
              isBulkActions={bulkActions}
              ids={ids}
              detectSubmit={detectSubmit}
              handAssign={handAssign}
              toggleDetectSubmit={toggleDetectSubmit}
            />
          </div>
        </div>
        {loading ? (
          <Spinner size='small' />
        ) : (
          <FormLayout.Group>
            <ProductBasesSelectPolaris
              data={data}
              onChange={(value) => {
                setFulfillmentId(() => value);
                setPBaseId(() => null);
                handleBaseValue(null);
              }}
            />
            <div className='product-base_wrap'>
              <ProductSelectByFFPolaris
                data={data}
                fulfillmentId={fulfillmentId}
                value={pBaseId}
                onChange={(value) => {
                  setPBaseId(() => value);
                  handleBaseValue(value);
                }}
                error={errors.pBase}
              />
            </div>
          </FormLayout.Group>
        )}

        <CollectionSelectPolaris
          label='Collections'
          required
          allowMultiple
          value={collections}
          hasPopular
          errorMessage={errors.collections}
          haveQuickAdd
          limit={200}
          isAssortment
          onChange={(value) => {
            setCollections(value);
            handleCollectionsValue(value);
          }}
        />
        <TagsSelectPolaris
          label='Tags'
          required
          allowMultiple
          value={tags}
          errorMessage={errors.tags}
          haveQuickAdd
          limit={200}
          onChange={(value) => {
            setTags(value);
            handleTagsValue(value);
          }}
        />
        {currentChecked && get(currentChecked, 'images[0]', null) && (
          <MainImageSelectorPolaris
            images={currentChecked.images.map((i) => i.file)}
            value={mainImageId}
            deleteImgs={deleteImgs}
            onChangeDeleteImgs={(ids) => setDeleteImgs(ids)}
            onChange={(value) => setMainImageId(value)}
          />
        )}
        <Checkbox
          label='This is a personalized product'
          checked={checkPersonalized}
          onChange={(newChecked) => {
            setCheckPersonalized(newChecked);
            if (!newChecked) {
              setFields([]);
            }
          }}
        />
        {checkPersonalized ? (
          <SimpleCustomizePolaris
            value={fields}
            onChange={handleFieldsChange}
          />
        ) : null}
        {!bulkActions && currentChecked?.designUrls?.length > 0 ? (
          <UsingProductDesignFilePolaris
            crawlId={currentChecked.id}
            designUrls={currentChecked.designUrls}
            designPositions={designPositions}
            onChange={(newValue) => {
              newValue =
                newValue?.length > 0
                  ? newValue.map((i) => {
                      let { thumbnailUrl, ...rest } = i;
                      return rest;
                    })
                  : [];
              setDesigns(newValue);
            }}
          />
        ) : null}
        <Button
          children='Submit'
          primary
          onClick={handleSubmit}
          loading={loadingA}
          fullWidth
        />
      </FormLayout>
    </Container>
  );
};
