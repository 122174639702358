import React, { useEffect } from "react";
import { Modal, Button } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import { elementContains } from "../../../helper";
import { useAppContext } from "../../../context";

import { WidgetComp } from "../../widget";
import { ActionTypes, useWidgetContext } from "../context";

export function ConfigWidgets({ title }) {
    const [open, toggleOpen] = useToggle(false);

    // Context
    const { isMobile } = useAppContext();
    const [, dispatch] = useWidgetContext();

    useEffect(() => {
        if (isMobile) return;
        let domCD = document.querySelector("#config-widgets");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );

        for (let domParent of domParents) {
            if (elementContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100% - 10rem); height: calc(100vh);"
                );
            }
        }
    });

    useEffect(() => {
        dispatch({
            type: ActionTypes.ToggleOpen,
            payload: open,
        });
    }, [open, dispatch]);

    return (
        <>
            <Button
                ariaExpanded={open}
                ariaControls={"config-widgets"}
                onClick={toggleOpen}
                children="Config Widgets"
                disclosure={open ? "up" : "down"}
            />
            <Modal
                open={open}
                onClose={toggleOpen}
                title="Config Widgets"
                sectioned
                large
            >
                <div id="config-widgets">
                    <WidgetComp title={title} toggleOpen={toggleOpen} />
                </div>
            </Modal>
        </>
    );
}
