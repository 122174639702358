import React, { Component } from "react";
import DesignTaskForm from "./DesignTaskForm";
import { Mutation } from "@apollo/react-components";
import { notification } from "antd";
import history from "../../history";

import { CREATE_DESIGN_TASK } from "../../graphql/mutations";

export default class AddDesignTask extends Component {
  state = {
    loading: false,
  };
  render() {
    const { redirect, isModal, onCancel, orderId, refetch } = this.props;

    return (
      <Mutation
        mutation={CREATE_DESIGN_TASK}
        onCompleted={(res) => {
          this.setState({ loading: false });
          notification.success({ message: "Create design task success!" });
          if (redirect) history.push(redirect);
          if (refetch) refetch();
          if (onCancel) onCancel();
        }}
        onError={(err) => {
          this.setState({ loading: false });
          notification.error({ message: err.toString() });
        }}
      >
        {(createDesignTask) => (
          <DesignTaskForm
            isModal={isModal}
            loading={this.state.loading}
            orderId={orderId}
            onSubmit={({ deadline, ...rest }) => {
              let newInput = {
                ...rest,
              };
              if ("object" === typeof deadline) {
                newInput = {
                  ...newInput,
                  deadline,
                };
              }
              this.setState({ loading: true });
              createDesignTask({ variables: { input: newInput } });
            }}
          />
        )}
      </Mutation>
    );
  }
}
