import React, { Component } from "react";
import { Button, notification, Popover, Radio, Spin } from "antd";
import { gql } from "apollo-boost";
import { Mutation, Query } from "@apollo/react-components";
import { handleError } from "../../helper";
import { ArrowDownOutlined } from "@ant-design/icons";
import { ORDER_STATUS } from "../../variable";

const EXPORT_MUTATION = gql`
  mutation exportOrders($filter: ExportOrderFilter!) {
    exportOrders(filter: $filter) {
      id
      file {
        id
        url
      }
      createdAt
    }
  }
`;

const LIST_EXPORT_TEMPLATE = gql`
  query exportTemplates {
    exportTemplates {
      id
      name
    }
  }
`;

class ExportButton extends Component {
  state = { loading: false, templateId: undefined };
  render() {
    const { exportAllResendClaims } = this.props;
    return (
      <Mutation mutation={EXPORT_MUTATION}>
        {(createExport) => (
          <Popover
            content={
              <Query
                query={LIST_EXPORT_TEMPLATE}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error }) => {
                  if (loading) return <Spin />;
                  if (error) return error.toString();
                  return (
                    <div className="flex flex-col">
                      <Radio.Group
                        value={this.state.templateId}
                        className="flex flex-col"
                        onChange={(e) => {
                          this.setState({ templateId: e.target.value });
                        }}
                      >
                        <Radio value={undefined} defaultChecked>
                          Default Template
                        </Radio>
                        {data &&
                          data.exportTemplates &&
                          data.exportTemplates.map((tmp) => (
                            <Radio key={tmp.id} value={tmp.id}>
                              {tmp.name}
                            </Radio>
                          ))}
                      </Radio.Group>
                      <Button
                        className="mt-4"
                        type="primary"
                        size="small"
                        onClick={() => {
                          this.setState({ loading: true }, () => {
                            let filterValue = {
                              templateId: this.state.templateId,
                              status: ORDER_STATUS.ReadyToPrint,
                              supplierId: this.props.supplierId,
                            };
                            if (exportAllResendClaims) {
                              filterValue = {
                                templateId: this.state.templateId,
                                supplierId: this.props.supplierId,
                                exportAllResendClaims: true,
                              };
                            }
                            createExport({
                              variables: {
                                filter: filterValue,
                              },
                            })
                              .then((res) => {
                                this.setState({ loading: false }, () => {
                                  window.location.href =
                                    res.data.exportOrders.file.url;
                                });
                              })
                              .catch((e) => {
                                this.setState({ loading: false });
                                notification.error({
                                  message: handleError(e.toString()),
                                });
                              });
                          });
                        }}
                        loading={this.state.loading}
                      >
                        Export Order
                      </Button>
                    </div>
                  );
                }}
              </Query>
            }
            title="Choose export template"
            trigger={["click"]}
          >
            <Button type="primary" icon={<ArrowDownOutlined />}>
              {exportAllResendClaims
                ? "Export all Resend Claims"
                : "Export all Ready to Print"}
            </Button>
          </Popover>
        )}
      </Mutation>
    );
  }
}

ExportButton.propTypes = {};

export default ExportButton;
