import { Labelled } from "@shopify/polaris";
import React from "react";

export function RejectReason({ bases }) {
    if (!bases || bases.length === 0) return null;

    const msg = bases
        .map((b) => {
            const { taskBaseSubmissions } = b || {};
            const subReject = (taskBaseSubmissions || []).find(
                (sub) => sub.rejectReason
            );
            return subReject?.rejectReason;
        })
        .filter(Boolean)[0];

    return msg ? (
        <div>
            <Labelled label="Reject Reason:" />
            <span>{msg}</span>
        </div>
    ) : null;
}
