import React, { useState, useEffect, useRef } from "react";
import { DataTable, Badge } from "@shopify/polaris";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";

import { elementContains, throttle } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { FeedActionsPolaris } from "./FeedActionsPolaris";

const Container = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__ScrollContainer {
    overflow-x: initial;
  }
  .Polaris-DataTable__Cell {
    &.custom-cell {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: #fff;
      &:after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
        position: absolute;
        top: 0px;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
        content: "";
        pointer-events: none;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
  .Polaris-DataTable__TableRow {
    &:hover {
      .Polaris-DataTable__Cell {
        &.custom-cell {
          background-color: var(--p-surface-hovered, #f9fafb);
        }
      }
    }
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export const TableProductFeedsPolaris = ({
  data,
  filter,
  setFilter,
  client,
  urlStoreManager,
  fixedColumn,
}) => {
  // State
  const [coordinates, setCoordinates] = useState({
    scrollLeft: null,
    scrollWidth: null,
    offsetWidth: null,
  });
  const wrapRef = useRef(null);

  useEffect(() => {
    // Box-shadow
    let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
    let addClass = offsetWidth + scrollLeft < scrollWidth;

    let domChilds = document.querySelectorAll(".actions_wrap");
    let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

    for (let domParent of domParents) {
      for (let domChild of domChilds) {
        if (elementContains(domParent, domChild)) {
          if (fixedColumn) {
            if (addClass) {
              domParent.classList.add("custom-cell");
            } else {
              domParent.classList.remove("custom-cell");
            }
          } else {
            domParent.classList.remove("custom-cell");
          }
        }
      }
    }
  }, [coordinates, fixedColumn]);

  // Handle scroll
  useEffect(() => {
    const handler = () => {
      throttle(() => {
        if (wrapRef.current) {
          setCoordinates({
            scrollLeft: wrapRef.current.scrollLeft,
            scrollWidth: wrapRef.current.scrollWidth,
            offsetWidth: wrapRef.current.offsetWidth,
          });
        }
      })
    };

    if (wrapRef.current) {
      wrapRef.current.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (wrapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wrapRef.current.removeEventListener("scroll", handler);
      }
    };
  }, [wrapRef]);

  let newRows = [];
  if (data) {
    data.feeds.nodes.forEach((f) => {
      let hasDesign = f.hasDesign;
      hasDesign = hasDesign ? "Yes" : "No";
      const hasSale = f.hasSale ? "Yes" : "No";

      newRows.push([
        <span>{f.name}</span>,
        <span>{"Processing" === f.status ? "Processing" : f.total}</span>,
        <p>{moment(f.updatedAt).format("LLL")}</p>,
        <Badge
          status={
            f.status && "Ready" === f.status
              ? "success"
              : "Processing" === f.status
              ? "attention"
              : null
          }
        >
          {f.status}
        </Badge>,
        <span>{f.useUpc ? "Yes" : "No"}</span>,
        <div>
          <span>{hasDesign}</span>
        </div>,
        <div>
          <span>{hasSale}</span>
        </div>,
        <div className="actions_wrap">
          <FeedActionsPolaris
            feed={f}
            client={client}
            filter={filter}
            urlStoreManager={urlStoreManager}
          />
        </div>,
      ]);
    });
  }

  let { limit, offset } = filter;
  const total = _.get(data, "feeds.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregations = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <React.Fragment>
      <Container ref={wrapRef}>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "numeric",
          ]}
          headings={[
            "Name",
            "Total Products",
            "Last Update At",
            "Status",
            "UPC",
            "Has Designs",
            "Has Sale",
            <div className="actions_wrap">Actions</div>,
          ]}
          rows={newRows}
          verticalAlign={"middle"}
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregations} showTotal />
      </PaginationContainer>
    </React.Fragment>
  );
};
