import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { EditExportTemplatePolaris } from "../../components/admin/manager-files/EditExportTemplatePolaris";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const EditExportTemplatePagePolaris = () => {
    return (
        <Container>
            <Page
                title="Edit export file Template"
                breadcrumbs={[
                    {
                        content: "Export templates",
                        url: "/admin/manager-files/export-templates",
                    },
                ]}
                fullWidth
            >
                <EditExportTemplatePolaris />
            </Page>
        </Container>
    );
};
