import React, { createContext, useContext, useReducer, useEffect } from "react";
import { Loading } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { matchValue, getUnique } from "../../../helper";
import { GET_REPORT_WIDGETS } from "../../../graphql/queries";

export const WidgetContext = createContext(null);
WidgetContext.displayName = "WidgetContext";

export const ActionTypes = {
    RegisterCard: "RegisterCard",
    UnregisterCard: "UnregisterCard",
    Update: "Update",
    ToggleOpen: "ToggleOpen",
    ClearCard: "ClearCard",
};

export const Group = {
    All: "All",
    Secondary: "Secondary",
    Third: "Third",
};

const reducers = {
    [ActionTypes.RegisterCard](state, action) {
        if (action.group) {
            let values = [...state[action.group], action.value].filter(Boolean);
            values = values.filter((node) => node.value);
            values = getUnique(values, "value");
            return {
                ...state,
                [action.group]: [...values],
            };
        }

        return {
            ...state,
        };
    },
    [ActionTypes.Update](state, action) {
        return {
            ...state,
            [action.group]: action.values,
        };
    },
    [ActionTypes.UnregisterCard](state, action) {
        if (action.group) {
            const currentOptions = state[action.group];
            const clone = currentOptions?.slice();

            const idx = clone.findIndex((i) => i.value === action.value);
            if (idx !== -1) clone.splice(idx, 1);

            return {
                ...state,
                [action.group]: clone,
            };
        }
    },
    [ActionTypes.ToggleOpen](state, action) {
        if (state.open === action.payload) return state;

        return {
            ...state,
            open: action.payload,
        };
    },
    [ActionTypes.ClearCard](state) {
        return {
            ...state,
            [Group.All]: [],
            [Group.Secondary]: [],
            [Group.Third]: [],
        };
    },
};

function stateReducer(state, action) {
    return matchValue(action.type, reducers, state, action);
}

export function WidgetCompProvider({ children, title, elements }) {
    const reducerBag = useReducer(stateReducer, {
        [Group.All]: [],
        [Group.Secondary]: [],
        [Group.Third]: [],
        data: [...elements],
        open: false,
    });

    const [, dispatch] = reducerBag;

    // Query
    const { data, loading, refetch } = useQuery(GET_REPORT_WIDGETS, {
        variables: {
            page: title,
        },
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        dispatch({
            type: ActionTypes.ClearCard,
        });
    }, []);

    useEffect(() => {
        if (data != null) {
            const getReportWidgets = data?.getReportWidgets;
            const col1 = getReportWidgets?.col1;
            const col2 = getReportWidgets?.col2;
            let col1Splitted = getData(col1, elements, 1, col2);
            let col2Splitted = getData(col2, elements, 2, col1);

            const Secondary = filterData(elements, col1Splitted);
            const Third = filterData(elements, col2Splitted);

            const cols = [...col1Splitted, ...col2Splitted];
            const All = elements.filter((item) => !cols.includes(item.value));

            function updateCard(elements, group) {
                (elements || []).forEach((item) => {
                    dispatch({
                        type: ActionTypes.RegisterCard,
                        group: group,
                        value: item,
                    });
                });
            }

            updateCard(Secondary, Group.Secondary);
            updateCard(Third, Group.Third);
            updateCard(All, Group.All);
        }
    }, [data, elements, dispatch, refetch]);

    const loadingMarkup = loading && <Loading />;

    return (
        <WidgetContext.Provider value={reducerBag}>
            {loadingMarkup}
            {children}
        </WidgetContext.Provider>
    );
}

function getData(col, data, suffix, colOther) {
    const splitted = col ? col.split(/,/g) : getDataBySuffix(data, suffix);
    const splittedOther = colOther ? colOther.split(/,/g) : [];

    return splitted.filter((i) => !splittedOther.includes(i));
}

function getDataBySuffix(data, suffix) {
    return data
        .map((i) => {
            if (i.default === suffix) return i.value;
            return null;
        })
        .filter(Boolean);
}

function filterData(data, matches) {
    return data.filter((i) => matches.includes(i.value));
}

export function useWidgetContext() {
    const context = useContext(WidgetContext);
    return context;
}
