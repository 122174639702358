import React, { useState, useCallback, useEffect } from "react";
import { ResourceList, Toast } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import { PushProductRenderItemPolaris } from "./PushProductRenderItemPolaris";
import { DeleteSelectPushPolaris } from "./DeleteSelectPushPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";

const Container = styled.div`
  .pagination-wrap {
    padding: 1.6rem;
    background: var(--p-surface-subdued, #ebeef1);
    text-align: center;
    border-radius: var(--p-border-radius-wide, 3px);
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-x: scroll;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }

  .header-wrap,
  .content-wrap {
    grid-template-columns: minmax(20%, 1fr) 15% 10% 10% 10% 10% 15% 10%;
  }
`;

export const TablePushProductPolaris = ({ data, filter, setFilter }) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [active, toggleActive] = useState(false);
  const [notify, setNotify] = useState({ msg: null, err: false });

  useEffect(() => {
    if (data) {
      setItems(data.pushList.nodes);
    }
  }, [data]);

  const toggleOpenModal = useCallback(() => setOpenModal((open) => !open), []);
  const promotedBulkActions = [
    {
      content: "Delete select push",
      onAction: toggleOpenModal,
    },
  ];

  let { limit, offset } = filter;
  const total = _.get(data, "pushList.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  // Markup
  const toastMarkup = active && notify.msg != null && (
    <Toast
      content={notify.msg}
      error={notify.err}
      duration={1500}
      onDismiss={toggleActive}
    />
  );

  return (
    <Container className="pm_table-has-select">
      {toastMarkup}
      <ResourceList
        items={items}
        renderItem={(item) =>
          PushProductRenderItemPolaris(item, filter, toggleActive, setNotify)
        }
        selectedItems={selectedItem}
        onSelectionChange={setSelectedItem}
        promotedBulkActions={promotedBulkActions}
        alternateTool={
          <div className="header-wrap">
            <dt>Push Name</dt>
            <dt>Target</dt>
            <dt>Progress</dt>
            <dt>Continuous</dt>
            <dt>Paused</dt>
            <dt>Generate CSV</dt>
            <dt>Generate CSV Status</dt>
            <dt>Actions</dt>
          </div>
        }
      />
      <div className="pagination-wrap">
        <PaginationPolaris aggregation={aggregation} showTotal />
      </div>
      <DeleteSelectPushPolaris
        open={openModal}
        toggleOpen={toggleOpenModal}
        selectedItem={selectedItem}
        filter={filter}
        setSelectedItem={setSelectedItem}
      />
    </Container>
  );
};
