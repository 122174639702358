import React, { useState } from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { setCookie, getCookie } from "../../helper";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { TableProductCrawlersPolaris } from "./TableProductCrawlersPolaris";

export const LIST_CRAWLER_QUERY = gql`
  query product($filter: ProductCrawlFilter) {
    productCrawlers(filter: $filter) {
      total
      nodes {
        id
        title
        description
        domain
        info {
          total
          completedCount
          errorCount
          runningCount
        }
        files {
          id
          url
        }
        type
        status
        createdAt
      }
    }
  }
`;

export const ProductCrawlersPolaris = () => {
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductCrawlers")) || 20,
    offset: 0,
    search: null,
  });
  const { data, loading, error } = useQuery(LIST_CRAWLER_QUERY, {
    variables: {
      filter,
    },
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <Card>
      {loadingMarkup}
      {error && <div>Error: {error.toString()}</div>}
      {loading ? (
        <SkeletonPagePolaris />
      ) : data &&
        data.productCrawlers &&
        data.productCrawlers.nodes &&
        data.productCrawlers.nodes.length ? (
        <TableProductCrawlersPolaris
          data={data}
          filter={filter}
          setFilter={(offset, limit) => {
            setFilter((prevState) => ({
              ...prevState,
              offset,
              limit,
            }));
            setCookie("perPageProductCrawlers", limit, 100);
          }}
        />
      ) : (
        <EmptyStatePolaris />
      )}
    </Card>
  );
};
