import { Mutation, Query } from "@apollo/react-components";
import { notification, Skeleton } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../context";
import history from "../../history";
import ExportTemplateForm from "./ExportTemplateForm";

const EDIT_EXPORT_TEMPLATE = gql`
  mutation editExportTemplate($input: EditExportTemplate!) {
    editExportTemplate(input: $input) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      author {
        id
      }
    }
  }
`;

const EXPORT_TEMPLATE_DEFAULT = gql`
  query exportTemplateById($id: ID!) {
    exportTemplateById(id: $id) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      columns {
        name
        type
        value
      }
      author {
        id
      }
    }
  }
`;

class EditExportTemplate extends Component {
  state = {
    loading: false,
  };

  render() {
    const { isAdmin, link } = this.props;
    const id = this.props.match.params.id;
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <Mutation
            mutation={EDIT_EXPORT_TEMPLATE}
            onCompleted={() => {
              this.setState({ loading: false });
              notification.success({
                message: "Update export template success.",
              });
              history.push(link);
            }}
            onError={(err) => {
              notification.error({ message: err.toString() });
              this.setState({ loading: false });
            }}
          >
            {(editExportTemplate) => (
              <Query
                query={EXPORT_TEMPLATE_DEFAULT}
                variables={{ id }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error }) => {
                  if (loading) return <Skeleton active />;
                  if (error) return error.toString();
                  return (
                    <ExportTemplateForm
                      isAdmin={isAdmin}
                      disabled={
                        !isAdmin &&
                        (currentUser && currentUser.id) !==
                          (data &&
                            data.exportTemplateById &&
                            data.exportTemplateById.author.id)
                      }
                      loading={this.state.loading}
                      exportTemplate={data.exportTemplateById}
                      onSubmit={(values) => {
                        this.setState({ loading: true });
                        values.columns = values.columns.map((column) => {
                          delete column.__typename;
                          return column;
                        });
                        editExportTemplate({
                          variables: { input: { id, ...values } },
                        });
                      }}
                    />
                  );
                }}
              </Query>
            )}
          </Mutation>
        )}
      </AppContext.Consumer>
    );
  }
}
export default withRouter(EditExportTemplate);
