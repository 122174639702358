import { RangeSlider } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { checkRealDimension, copyUrlImage } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { ImageTypes } from "../../../variable";
import { ColorPickerComp } from "../../seller/ModalImageClaimPolaris";
import ModalWithImageZoomInOut from "../../shared/ModalWithImageZoomInOut";
import SuspenseComp from "../../shared/SuspenseComp";

const AuthorConfig = React.lazy(() => import("./AuthorConfig"));
const DimensionsInfo = React.lazy(() => import("./DimensionsInfo"));

export default function CompareDesignItem({
  designPosition,
  files,
  userConfigDesign,
  isDLead,
}) {
  const images = files.files ? files.files : [];

  const [open, toggleOpen] = useToggle(false);
  const [inputSlider, setInputSlider] = React.useState(100);
  const [dimension, setDimension] = React.useState(null);
  const [showCP, setShowCP] = React.useState(false);
  const [bg, setBg] = React.useState("transparent");
  const imgRef = React.useRef(null);

  const timeoutRef = React.useRef(null);
  const handleChangeSlider = React.useCallback((value) => {
    setInputSlider(value);

    if (!imgRef.current) return;

    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      imgRef.current.style.opacity = `${value * 0.01}`;
    }, 500);
  }, []);

  const handleImageLoadHandler = React.useCallback(
    (url) => () => {
      setShowCP(true);

      let img = new Image();
      img.src = url;
      img.onload = () => {
        let newDimension = dimension;
        if (!newDimension) {
          let height = img && img.height ? img.height : "";
          let width = img && img.width ? img.width : "";

          const isRealDimensionOk = checkRealDimension(
            designPosition,
            width,
            height,
          );

          newDimension = {
            height: height,
            width: width,
            isRealDimensionOk,
          };
          setDimension(newDimension);
        }
      };
    },
    [designPosition, dimension],
  );

  const firstImage = images && images.filter((i) => i?.url).filter(Boolean)[0];
  const imageUrl = firstImage?.url;

  const artworkGuideUrl = React.useMemo(
    () => getArtworkGuideUrl(designPosition?.artworkGuidelines),
    [designPosition?.artworkGuidelines],
  );

  const hanldeClickImage = React.useCallback(() => {
    copyUrlImage(firstImage);
    toggleOpen();
  }, [firstImage]);

  return (
    <Wrapper>
      {isDLead && showCP && <ColorPickerComp setBg={setBg} />}
      <div className="change-opacity">
        <div className="change-opacity-inner">
          <RangeSlider
            label={"Change opacity"}
            min={1}
            max={100}
            onChange={handleChangeSlider}
            value={typeof inputSlider === "number" ? inputSlider : 0}
          />
        </div>
      </div>
      <div className="imgs-wrap" id="imgs-wrap">
        <div style={{ position: "relative" }}>
          <div>
            <img alt="" src={artworkGuideUrl} className="img_item artwork" />
          </div>
          <div>
            <img
              alt=""
              ref={imgRef}
              src={imageUrl}
              className={`img_item design`}
              onLoad={handleImageLoadHandler(imageUrl)}
              onClick={hanldeClickImage}
              style={{
                backgroundColor: bg ? bg : "transparent",
              }}
            />
          </div>
        </div>
      </div>
      <SuspenseComp>
        <DimensionsInfo designPosition={designPosition} dimension={dimension} />
        <AuthorConfig value={userConfigDesign} />
      </SuspenseComp>

      <ModalWithImageZoomInOut
        open={open}
        imageUrl={imageUrl}
        onClose={toggleOpen}
        bg={bg}
        inputSlider={inputSlider}
      />
    </Wrapper>
  );
}

export function getArtworkGuideUrl(artworkGuidelines) {
  let artworkGuide = {};

  if (artworkGuidelines?.length > 0) {
    artworkGuide = artworkGuidelines
      .filter((i) => i.file)
      .filter(Boolean)
      .find((i) => ImageTypes.includes(i.file.mimeType));
  }
  return artworkGuide?.file?.url ? artworkGuide.file.url : "";
}

const Wrapper = styled.div`
  .imgs-wrap {
    img {
      max-width: 100%;
    }

    .img_item {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 8900px;
      max-height: 10000px;
    }
  }

  .design {
    position: absolute;
    z-index: 1;
    top: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .artwork {
    position: static;
    opacity: 0.25;
    z-index: 0;
  }

  .change-opacity-inner {
    margin-bottom: 10px;

    > div {
      display: flex;
      column-gap: 1rem;
      justify-content: flex-end;
    }

    .Polaris-RangeSlider-SingleThumb {
      width: 300px;
    }
  }

  .color-picker-wrap {
    position: absolute;
    top: 8rem;
    right: 0;

    .Polaris-ColorPicker__MainColor {
      height: 12rem;
      width: 12rem;
    }

    .Polaris-ColorPicker__HuePicker,
    .Polaris-ColorPicker__AlphaPicker {
      height: 12rem;
      width: 1.8rem;
    }
  }
`;
