import React from "react";
import { Card, TextField, Button, Toast, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { filter, size, values } from "lodash";
import styled from "styled-components";

import { handleError } from "../../../helper";
import { BULK_SWITCH_FULFILLMENT } from "../../../graphql/mutations";
import useToggle from "../../../hooks/useToggle";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { FulfillmentSelectPolaris } from "../../fulfillment/FulfillmentSelectPolaris";
import { ProductBaseSelectPolaris } from "../../fulfillment/ProductBaseSelectPolaris";
import { ProductBaseVariantSelectPolaris } from "../../fulfillment/ProductBaseVariantSelectPolaris";

const Container = styled.div`
    .content-wrap {
        h3 {
            margin-bottom: 1rem;
        }
        .mes-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;
            .mes-item {
                display: flex;
                flex-direction: row;
                column-gap: 2rem;
                strong {
                    margin-right: 0.5rem;
                }
            }
        }
    }
`;

export const SwitchFulfillmentPolaris = () => {
    // State
    const [fields, setFields] = React.useState({
        orderIds: null,
        fulfillmentId: null,
        baseVariantId: null,
        productBaseId: null,
    });
    const [toastActive, toggleToastActive] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const [message, setMessage] = React.useState([]);
    const [errors, setErrors] = React.useState({});

    // Mutation
    const [bulkSwitchFulfillment, { loading }] = useMutation(
        BULK_SWITCH_FULFILLMENT,
        {
            onCompleted: (res) => {
                const mess = res?.bulkSwitchFulfillment?.Messages;
                if (mess?.length > 0) {
                    setMessage(mess);
                }
                setFields(() => ({
                    orderIds: null,
                }));
                setNotify({
                    msg: "Switch fulfillment successfully.",
                    err: false,
                });
            },
            onError: (error) => {
                setNotify({
                    msg: handleError(error.toString()),
                    err: true,
                });
            },
        }
    );

    // Variables
    const FIELDS_REQUIRED = React.useMemo(
        () => ({
            orderIds: "Order IDs",
            baseVariantId: "Product Base Variant ID",
            productBaseId: "Product Base ID",
        }),
        []
    );
    // Handle actions
    const validateFields = React.useCallback(
        (value, id) => {
            let error = null;
            let label;
            for (let [key, value] of Object.entries(FIELDS_REQUIRED)) {
                if ([key].includes(id)) {
                    label = value;
                }
            }
            if ((!value || !value.length) && label) {
                error = `${label} is required.`;
            }

            setErrors((prev) => ({ ...prev, [id]: error }));
        },
        [FIELDS_REQUIRED]
    );
    const handleFieldsChange = React.useCallback((value, id) => {
        setFields((prev) => ({ ...prev, [id]: value }));
        validateFields(value, id);
    }, [validateFields]);

    const handleSubmit = React.useCallback(() => {
        for (let [key] of Object.entries(FIELDS_REQUIRED)) {
            validateFields(fields[key], key);
        }

        let { orderIds, baseVariantId, fulfillmentId } = fields;
        orderIds =
            orderIds?.length > 0 ? orderIds.split("\n").filter(Boolean) : [];
        const input = {
            orderIds,
            baseVariantId,
            fulfillmentId,
        };

        const countErr = size(filter(values(errors), (e) => e !== null)) === 0;
        const canSubmit = orderIds.length > 0 && baseVariantId && countErr;

        if (!!canSubmit) {
            toggleToastActive(true);
            setNotify({ msg: null, err: false });
            bulkSwitchFulfillment({
                variables: {
                    input,
                },
            });
        }
    }, [
        fields,
        errors,
        FIELDS_REQUIRED,
        setNotify,
        validateFields,
        toggleToastActive,
        bulkSwitchFulfillment,
    ]);

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            duration={1500}
            onDismiss={toggleToastActive}
        />
    );

    const messageMarkup =
        message?.length > 0 ? (
            <Card sectioned>
                <div className="content-wrap">
                    <h3>Message: </h3>
                    <div className="mes-wrap">
                        {message.map((mes, index) => {
                            const { id, error } = mes;
                            return (
                                <div className="mes-item" key={index}>
                                    <span>
                                        <strong>ID:</strong>
                                        {id}
                                    </span>
                                    <span>
                                        <strong>Error:</strong>
                                        {error}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Card>
        ) : null;

    return (
        <Container>
            {toastMarkup}
            <Card sectioned>
                <Stack spacing="loose" vertical>
                    <div>
                        <ComponentLabelPolaris label="Order IDs" required />
                        <TextField
                            id="orderIds"
                            value={fields["orderIds"]}
                            error={errors["orderIds"]}
                            multiline={4}
                            onChange={handleFieldsChange}
                        />
                    </div>
                    <div>
                        <FulfillmentSelectPolaris
                            exclude={fields["fulfillmentId"]}
                            onChange={(value) =>
                                handleFieldsChange(value, "fulfillmentId")
                            }
                        />
                    </div>
                    <ProductBaseSelectPolaris
                        fulfillmentId={fields["fulfillmentId"]}
                        onChange={(v) => handleFieldsChange(v, "productBaseId")}
                        error={errors["productBaseId"]}
                    />
                    <ProductBaseVariantSelectPolaris
                        productBaseId={fields["productBaseId"]}
                        onChange={(v) => handleFieldsChange(v, "baseVariantId")}
                        error={errors["baseVariantId"]}
                    />
                    <div className="actions-wrap">
                        <Button
                            primary
                            children="Submit"
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </div>
                </Stack>
            </Card>
            {messageMarkup}
        </Container>
    );
};
