import { Badge, Spinner, Tabs } from "@shopify/polaris";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const IN_PRODUCTION = "in_production";
const IN_PRODUCTION_SUM = ["pending_payment", "printing", "ready_to_print"];

export const FiltersOrderPolaris = ({
  value,
  aggregations,
  total,
  loading,
  defaultValue,
  onChange,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (value && value.length) {
      let newTabs = value.map((item, index) => {
        let slug = getMachineName(item && item.key);
        return {
          id: slug,
          content: (
            <BadgeWrapper>
              <span style={{ marginRight: "0.5rem" }}>{item.name}</span>
              {loading && !index ? (
                <Spinner size="small" />
              ) : loading ? null : aggregations ? (
                <Badge>
                  {!item.key ? total || 0 : getCount(aggregations, slug)}
                </Badge>
              ) : null}
            </BadgeWrapper>
          ),
          accessibilityLabel: item.key,
          panelID: slug,
        };
      });
      setTabs(newTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, total, aggregations, loading]);

  useEffect(() => {
    let defaultV = defaultValue;
    if (!defaultV) {
      setSelectedTab(0);
      return;
    }

    if ("Ready to Print" === defaultV) {
      defaultV = "Ready To Print";
    }
    if (value && value.length) {
      let index = value.findIndex(
        (tab) => tab.name === defaultV || tab.key === defaultV,
      );
      setSelectedTab(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = useCallback(
    (value) => {
      setSelectedTab(value);

      let isTab =
        tabs && tabs.length ? tabs.find((_, idx) => idx === value) : null;
      let content = isTab && isTab.accessibilityLabel;
      if (onChange) {
        onChange({ status: "All" !== content ? content : null });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs],
  );

  const getMachineName = useCallback((s) => {
    if (!s) return "";
    return s.toLowerCase().replace(/ /g, "_");
  }, []);

  const getCount = useCallback((aggregations, slug) => {
    const count = (slug) => _.get(aggregations, `${slug}.doc_count`, 0);
    const newCount = count(slug);

    /**
     * In status `in_production`, if `count` = 0 then changes is sum of "pending_payment", "printing", "ready_to_print"
     */
    const isInProduction = [IN_PRODUCTION].includes(slug);
    const inProductionSum = IN_PRODUCTION_SUM.reduce(
      (acc, curr) => acc + count(curr),
      0,
    );

    let result = isInProduction && newCount === 0 ? inProductionSum : newCount;
    return result;
  }, []);

  return <Tabs tabs={tabs} onSelect={handleTabChange} selected={selectedTab} />;
};

const BadgeWrapper = styled.span`
  .Polaris-Spinner {
    display: inline-block;
    transform: translateY(3px);
  }
`;
