import { useQuery } from "@apollo/react-hooks";
import { Card, Layout, Loading } from "@shopify/polaris";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { LOAD_SELLER_DEPOSIT_QUERY } from "./DepositsPolaris";
import { StatsPolaris } from "./StatsPolaris";
import { TableTransactionPolaris } from "./TableTransactionPolaris";
import { TranscactionsFiltersPolaris } from "./TransactionsFiltersPolaris";

const FIELD_FILTER = [...COMMON_FILTER, "accountId", "status", "kind"];

export const TransactionsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.kind) {
        initialFilter.kind = [initialFilter.kind];
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageTransactions")) || 20,
    offset: 0,
    accountId: null,
    status: null,
    search: null,
    kind: null,
    ...initFilter,
  });

  const { data, loading, error } = useQuery(LOAD_SELLER_DEPOSIT_QUERY, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Layout>
        <Layout.Section>
          <StatsPolaris />
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <TranscactionsFiltersPolaris
              filter={filter}
              onChange={({ search, status, action }) =>
                setFilter((prevState) => {
                  if (
                    !_.isEqual(prevState.search, search) ||
                    !_.isEqual(prevState.status, status) ||
                    !_.isEqual(prevState.kind, action)
                  ) {
                    prevState.offset = 0;
                  }
                  return {
                    ...prevState,
                    search,
                    status,
                    kind: action ? [action] : null,
                  };
                })
              }
            />
          </Card>
          <Card>
            {error && <div>Error: {error.toString()}</div>}
            {loading ? (
              <SkeletonPagePolaris />
            ) : data &&
              data.transactions &&
              data.transactions.nodes &&
              data.transactions.nodes.length ? (
              <TableTransactionPolaris
                isTransactions
                data={data}
                filter={filter}
                setFilter={(offset, limit) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    offset,
                    limit,
                  }));
                  setCookie("perPageTransactions", limit, 100);
                }}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
};
