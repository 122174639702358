import { gql } from "apollo-boost";
import { mailchimpFragment } from "../fragments";

export const GET_MAILCHIMP = gql`
  query getMailchimp {
    getMailchimp {
      ...mailchimpFragment
    }
  }
  ${mailchimpFragment}
`;

export const GET_WARNING_EMAIL = gql`
  query getWarningEmail {
    getWarningEmail {
      receiverEmails
      address
    }
  }
`;

export const GET_VIEW_ORIGIN_FILE = gql`
  query getViewOriginFile {
    getViewOriginFile {
      roles
      userIDs
    }
  }
`;

export const ENABLE_STORE_FULFILLMENT = gql`
  query enableStoreFulfillment {
    enableStoreFulfillment {
      id
      name
    }
  }
`;

export const GET_USERS_PAYMENT_ACCOUNT_ACCESS = gql`
  query getUsersPaymentAccountAccess {
    getUsersPaymentAccountAccess {
      id
      firstName
      lastName
    }
  }
`;

export const GET_ALL_TEAM_USERS_BY_ROLE = gql`
  query getAllTeamUsersByRole($role: String!, $includeAdministrator: Boolean) {
    getAllTeamUsersByRole(role: $role, includeAdministrator: $includeAdministrator) {
      firstName
      lastName
      id
    }
  }
`;

export const GET_VARIABLES = gql`
  query variables($filter: VariableFilter) {
    variables(filter: $filter) {
      id
      key
      value
    }
  }
`;