import React, { useState, useCallback, useEffect, useRef } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { AutoCompleteHasSearchPolaris } from "../../shared/AutoCompleteHasSearchPolaris";

export const QUERY_PRODUCT_DIVISION = gql`
  query listProductDivision($filter: DivisionFilter) {
    listProductDivision(filter: $filter) {
      nodes {
        id
        title
        stores {
          id
          email
          title
        }
      }
    }
  }
`;

export const ProductDivisionSelectPolaris = (props) => {
  // Props
  const {
    onChange,
    value,
    onChangeStores,
    error,
    isRecreateMockup,
    newDivisions,
  } = props;
  // State
  const [divisions, setDivisions] = useState([]);
  const [division, setDivision] = useState({
    value: null,
    label: null,
  });
  const [filter, setFilter] = useState({
    limit: 1000,
    offset: 0,
    search: null,
    onlyRecreateMockup: isRecreateMockup ? true : false,
  });
  const typingTimeoutRef = useRef(null);

  // Query
  const { data, error: queryError } = useQuery(QUERY_PRODUCT_DIVISION, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  // Get data
  useEffect(() => {
    let currentD =
      divisions && divisions.length > 0
        ? divisions.find((i) => Array.isArray(value) && value.includes(i.value))
        : null;
    if (currentD) {
      setDivision(() => ({
        value: currentD.value,
        label: currentD.label,
      }));
    }
    let currentV = value && value.length > 0 ? value[0] : null;
    if (currentV) {
      handleChangeStores(currentV);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisions, value]);

  useEffect(() => {
    let newData =
      data &&
      data.listProductDivision &&
      data.listProductDivision.nodes &&
      data.listProductDivision.nodes.length > 0
        ? data.listProductDivision.nodes
            .map((i) => ({
              value: i.id,
              label: i.title,
            }))
            .filter(Boolean)
        : [];
    if (newDivisions?.length) {
      newData = [...newDivisions, ...newData];
    }
    setDivisions(() => [...newData]);
  }, [data, filter.search, newDivisions]);

  const handleSearch = useCallback((value) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setFilter((prev) => {
        if (prev.search !== value) {
          return { ...prev, search: value };
        }
        return prev;
      });
    }, 300);
  }, []);

  const handleChangeStores = useCallback(
    (value) => {
      let currentD =
        data &&
        data.listProductDivision &&
        data.listProductDivision.nodes &&
        data.listProductDivision.nodes.length > 0
          ? data.listProductDivision.nodes.find((i) => i.id === value)
          : [];
      if (currentD) {
        let stores = currentD.stores;
        if (onChangeStores) {
          onChangeStores(stores);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <AutoCompleteHasSearchPolaris
      data={divisions}
      label="Product division"
      value={division}
      required
      // loading={loading}
      queryError={queryError}
      error={error}
      placeholder={"Choose product division"}
      onChange={({ value, label }) => {
        setDivision(() => ({ value, label }));
        handleChangeStores(value);
        if (onChange) {
          onChange(value);
        }
      }}
      onChangeSearch={handleSearch}
    />
  );
};
