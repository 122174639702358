import React, { useContext } from "react";
import { DataTable, Thumbnail, Badge, Button } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

import { AppContext } from "../../../context";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { getParamByRole } from "../../../helper";

const Container = styled.div`
  .product_wrap {
    width: calc(25rem - 3.2rem);
    white-space: break-spaces;
  }
  .reason_wrap {
    width: calc(20rem - 3.2rem);
    white-space: break-spaces;
  }
  .collection_wrap,
  .tag_wrap {
    margin-left: -1rem;
    margin-top: -0.9rem;
    max-width: calc(20rem - 3.2rem);
    display: flex;
    flex-wrap: wrap;
    > span {
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .Polaris-Badge {
      max-width: 100%;
      .Polaris-Badge__Content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const TableProductsIgnoredPolaris = (props) => {
  const { data, filter, setFilter, total } = props;

  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const rows =
    data && data.length > 0
      ? data.map((product) => {
          const title = _.get(product, "title", null);
          const images = _.get(product, "images", []);
          const divisions = _.get(product, "divisions", []);
          const reason =
            divisions && divisions.length > 0
              ? divisions
                  .filter((d) => d.ignored)
                  .map((d) => d.reason)
                  .join(", ")
              : null;

          let collections =
            product && product.collections && product.collections.length > 0
              ? product.collections.map((c) => c.name)
              : [];
          let collectionsMarkup = collections.map((c, idx) => (
            <Badge key={`collection-${idx}`}>{c}</Badge>
          ));

          let tags =
            product && product.tags && product.tags.length > 0
              ? product.tags.map((t) => t.name)
              : [];
          let tagsMarkup = tags.map((t, idx) => (
            <Badge key={`tag-${idx}`}> {t}</Badge>
          ));

          const redirectPage = (item) => {
            const link =
              item && item.productBases && item.productBases.length > 1
                ? `/${currentParam}/products/campaign`
                : `/${currentParam}/products/edit`;
            return `${link}/${item.id}?productIgnored=true`;
          };

          const url = redirectPage(product);

          let src = _.head(images);
          src =
            src &&
            src.file &&
            (src.file.thumbnailUrl
              ? src.file.thumbnailUrl
              : src.file.url
              ? src.file.url
              : null);

          return [
            <div>
              <Thumbnail size="large" alt="" source={src} />
            </div>,
            <div className="product_wrap">
              <span>{title}</span>
            </div>,
            <div className="reason_wrap">
              <span>{reason}</span>
            </div>,
            <div className="collection_wrap">{collectionsMarkup}</div>,
            <div className="tag_wrap">{tagsMarkup}</div>,
            <div>
              <Button plain children={"Edit"} url={url} />
            </div>,
          ];
        })
      : [];

  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "number"]}
        headings={[
          "Image",
          "Product",
          "Reason",
          "Collections",
          "Tags",
          "Action",
        ]}
        rows={rows}
        verticalAlign={"middle"}
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
