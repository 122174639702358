import React, { useState, useCallback, useEffect, useRef } from "react";
import { Autocomplete, Tag, Stack, Icon, TextStyle } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
  .btn-add {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
    min-height: 3.6rem;
    padding: 0.8rem 1.6rem;
    text-align: left;
    overflow-y: hidden;
    :hover {
      background-image: linear-gradient(
        rgba(223, 227, 232, 0.3),
        rgba(223, 227, 232, 0.3)
      );
    }
    :focus {
      outline: none;
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export const ExcludeWordsPolaris = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    let data =
      value && value.length ? value.map((v) => ({ label: v, value: v })) : [];
    setOptions(data);
    setSelectedOptions(value ? value : []);
  }, [value]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      const matchedOptions = options.find((option) => {
        return option.label === value;
      });

      if (!matchedOptions && value) {
        setShowAction(true);
      } else {
        setShowAction(false);
      }
    },
    [options]
  );

  const handleSelected = useCallback(
    (selected) => {
      setSelectedOptions(selected);
      if (onChange) {
        onChange(selected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const handleClickAdd = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setOptions((prevState) => {
      let item = {
        value: inputValue,
        label: inputValue,
      };
      return [...prevState, item];
    });
    setSelectedOptions((prevState) => {
      return [...prevState, inputValue];
    });
    if (onChange) {
      onChange([...selectedOptions, inputValue]);
    }
    timeoutRef.current = setTimeout(() => {
      setShowAction(false);
      setInputValue(null);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions]
  );

  const contentBeforeMarkup = showAction && (
    <Container>
      <button className="btn-add" onClick={handleClickAdd}>
        <Stack spacing="extraTight" wrap={false}>
          <Icon source={CirclePlusMinor} color="indigo" />
          <TextStyle variation="strong">Add </TextStyle>
          <TextStyle>{inputValue}</TextStyle>
        </Stack>
      </button>
    </Container>
  );

  const textField = (
    <Autocomplete.TextField
      onChange={handleInputChange}
      label={"Exclude words"}
      value={inputValue}
      placeholder="Words"
    />
  );

  return (
    <>
      <Autocomplete.ComboBox
        options={options}
        selected={selectedOptions}
        onSelect={handleSelected}
        textField={textField}
        allowMultiple
        contentBefore={contentBeforeMarkup}
      />
      <TagContainer>
        {selectedOptions && selectedOptions.length
          ? selectedOptions.map((s) => {
              let matchedOptions = options.find((option) => option.value === s);
              return (
                <Tag
                  children={matchedOptions && matchedOptions.label}
                  onRemove={() => handleRemove(s)}
                  key={s}
                />
              );
            })
          : null}
      </TagContainer>
    </>
  );
};
