import React, { useState, useEffect } from "react";
import { FormLayout, Stack } from "@shopify/polaris";

import { FilterCustomTimeInnerPolaris } from "./FilterCustomTimeInnerPolaris";
import CustomTimeInner from "../filters/CustomTimeInner";
import styled from "styled-components";

export const FilterCustomTimePolaris = ({
  customTime: { range: rangeProps, rangeLabel },
  onChange,
  isField,
  isExportAmazon,
  canSelectOne,
  isFilter,
  isVertical = true,
}) => {
  let fromLabelProps = null;
  let toLabelProps = null;
  if (rangeLabel) {
    let idx = rangeLabel.indexOf(" - ");
    fromLabelProps = rangeLabel.substring(0, idx);
    toLabelProps = rangeLabel.substring(idx + 3, rangeLabel.length);
  }

  const [range, setRange] = useState({
    from: (rangeProps && rangeProps.from) || null,
    to: (rangeProps && rangeProps.to) || null,
    fromLabel: fromLabelProps,
    toLabel: toLabelProps,
  });
  const [haveShow, setHaveShow] = useState(false);

  useEffect(() => {
    const sendValue = canSelectOne ? true : range.from && range.to && haveShow;

    if (sendValue) {
      if (onChange) {
        onChange(range);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, haveShow, canSelectOne]);

  return isFilter ? (
    <Wrapper>
      <Stack vertical={isVertical}>
        <Stack.Item fill>
          <CustomTimeInner
            label="Starting"
            value={rangeProps?.from}
            haveShow={(value) => setHaveShow(value)}
            onChange={(value, label) => {
              setRange((prevState) => ({
                ...prevState,
                from: value,
                fromLabel: label,
              }));
            }}
          />
        </Stack.Item>
        <Stack.Item fill>
          <CustomTimeInner
            label="Ending"
            isTo
            value={rangeProps?.to}
            haveShow={(value) => setHaveShow(value)}
            onChange={(value, label) => {
              setRange((prevState) => ({
                ...prevState,
                to: value,
                toLabel: label,
              }));
            }}
          />
        </Stack.Item>
      </Stack>
    </Wrapper>
  ) : (
    <FormLayout>
      <Stack vertical>
        <FilterCustomTimeInnerPolaris
          canSelectOne={canSelectOne}
          labelName={"Starting"}
          value={rangeProps?.from?._i}
          isField={isField}
          isExportAmazon={isExportAmazon}
          onChange={(value, label) => {
            setRange((prevState) => ({
              ...prevState,
              from: value,
              fromLabel: label,
            }));
          }}
          haveShow={(value) => setHaveShow(value)}
        />
      </Stack>
      <Stack vertical>
        <FilterCustomTimeInnerPolaris
          canSelectOne={canSelectOne}
          labelName={"Ending"}
          value={rangeProps?.to?._i}
          isField={isField}
          isExportAmazon={isExportAmazon}
          onChange={(value, label) => {
            setRange((prevState) => {
              return {
                ...prevState,
                to: value,
                toLabel: label,
              };
            });
          }}
          haveShow={(value) => setHaveShow(value)}
        />
      </Stack>
    </FormLayout>
  );
};

const Wrapper = styled.div`
  margin-top: 12px;
  margin-left: -10px;
`;
