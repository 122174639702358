import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Modal,
  Popover,
  Spinner,
  Stack,
} from "@shopify/polaris";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppContext } from "../../../context";
import { EXPORT_ORDERS } from "../../../graphql/mutations";
import { handleError, getParam, checkRole, reducerFn } from "../../../helper";
import history from "../../../history";
import useToggle from "../../../hooks/useToggle";
import { useToastContext } from "../../shared/ToastContext";
import { LIST_EXPORT_TEMPLATE } from "./AdminExportOrdersPolaris";

export function ExportOrdersProcessing({ data, refetch, supplierId }) {
  // Props
  const orderIds = (data?.orders?.nodes || []).map(({ id }) => id);
  const [active, toggleActive] = useToggle(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(["default"]);
  const timeoutRef = useRef(null);
  const [open, toggleOpen] = useToggle(false);
  const [state, setState] = React.useReducer(reducerFn, {
    splitPrintUrl: true,
  });

  // Context
  const { toggleToast, setNotify } = useToastContext();
  const { currentUser } = useAppContext();
  const { isSupplier } = checkRole(currentUser);
  const param = getParam(currentUser);

  // Query
  const {
    data: data2,
    loading: loading2,
    error,
  } = useQuery(LIST_EXPORT_TEMPLATE, {
    fetchPolicy: "cache-and-network",
  });

  // Mutation

  let link = !isSupplier
    ? `/${param}/export-files`
    : `/${param}/export-tracking`;

  const [exportOrders, { loading }] = useMutation(EXPORT_ORDERS, {
    onCompleted: () => {
      setNotify({ msg: "Export orders success.", err: false });

      // if (refetch) {
      timeoutRef.current = clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        // refetch();
        history.push(link);
      }, 1500);
      // }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  useEffect(() => {
    const newOptions =
      data2?.exportTemplates?.length > 0
        ? data2.exportTemplates.map((t) => ({
            value: t.id,
            label: t.name,
          }))
        : [];
    setOptions([
      { value: "default", label: "Default Template" },
      ...newOptions,
    ]);
  }, [data2]);

  const handleSubmit = useCallback(() => {
    if (!orderIds || orderIds.length === 0) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });

    const templateId = selected[0] === "default" ? null : selected[0];
    exportOrders({
      variables: {
        filter: {
          orderIds,
          status: "Processing",
          templateId,
          supplierId,
          splitPrintUrl: state.splitPrintUrl,
        },
      },
    });
  }, [
    orderIds,
    toggleToast,
    toggleToast,
    selected,
    supplierId,
    state.splitPrintUrl,
  ]);

  const handleStateChange = React.useCallback((value, id) => {
    setState({ [id]: value });
  }, []);

  const btn = (
    <Button
      primary
      children="Export all Processing"
      onClick={toggleActive}
      disclosure
    />
  );
  return (
    <Fragment>
      <Popover
        active={active}
        activator={btn}
        onClose={toggleActive}
        preferredAlignment="right"
        fluidContent
      >
        <Card sectioned>
          <Stack vertical spacing="loose">
            {loading2 ? (
              <Spinner size="small" />
            ) : error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : (
              <ChoiceList
                title="Choose export template"
                choices={options}
                selected={selected}
                onChange={setSelected}
              />
            )}

            <Checkbox
              id="splitPrintUrl"
              label="Split Print Url"
              checked={state.splitPrintUrl}
              onChange={handleStateChange}
            />
            <Button
              children="Export Orders"
              fullWidth
              primary
              onClick={toggleOpen}
              // loading={loading}
            />
          </Stack>
        </Card>
      </Popover>
      <Modal
        title="Export Orders"
        sectioned
        open={open}
        onClose={toggleOpen}
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      >
        <p>
          Are you sure to export these orders? All exported orders will be
          changed to In Productions
        </p>
      </Modal>
    </Fragment>
  );
}
