import React, { createContext, useContext } from "react";
import useToggle from "../../../hooks/useToggle";

const ThumbnailFileContext = createContext(null);

export function ThumbnailFileProvider({ children }) {
    // State
    const [active, toggleActive] = useToggle(false);

    return (
        <ThumbnailFileContext.Provider value={{ active, toggleActive }}>
            {children}
        </ThumbnailFileContext.Provider>
    );
}

export function useThumbnailFileCtx() {
    return useContext(ThumbnailFileContext);
}
