import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { createContext } from "../../../context";
import { GET_TASK_ASSIGNEE_BY_ORDER_IDS } from "../../../graphql/queries";

const ORDER_ASSIGNEE = "OrderAssigneeContext";
const [OrderAssigneeProvider, userOrderAssigneeCtx] =
  createContext(ORDER_ASSIGNEE);

export {userOrderAssigneeCtx}
export default function OrderAssigneeContext({
  data,
  screenOptions,
  children,
}) {
  const orderIds = (data || []).map((item) => item?.id).filter(Boolean);
  const [assigneeByOrder, setAssignByOrder] = React.useState({});

  useQuery(GET_TASK_ASSIGNEE_BY_ORDER_IDS, {
    variables: {
      orderIds,
    },
    skip: !orderIds || !screenOptions.includes("assignee"),
    onCompleted: (res) => {
      if (res?.getTaskAssigneeByOrderIDs?.length > 0) {
        const result = (res.getTaskAssigneeByOrderIDs || []).reduce(
          (acc, item) => {
            if (
              !item ||
              typeof item !== "object" ||
              !item.orderID ||
              !item.assignee ||
              typeof item.assignee !== "object"
            )
              return acc;

            const fName = [item.assignee.firstName, item.assignee.lastName]
              .filter(Boolean)
              .join(" ");
            acc[item.orderID] = fName;
            return acc;
          },
          {},
        );

        setAssignByOrder(result);
      }
    },
  });

  return (
    <OrderAssigneeProvider value={assigneeByOrder}>
      {children}
    </OrderAssigneeProvider>
  );
}
