import React, { useState, useCallback, useEffect } from "react";
import { Card, Stack, TextField, Icon, Button, Select } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { TableProductPolaris } from "./TableProductPolaris";
import { ModalProductsPolaris } from "./ModalProductsPolaris";

export const PRODUCTS_BY_COLLECTION = gql`
    query productsByCollection($filter: ProductCollectionFilter!) {
        productsByCollection(filter: $filter) {
            total
            nodes {
                id
                title
                sku
                productBases {
                    id
                }
                images {
                    id
                    productBaseId
                    file {
                        id
                        thumbnailUrl
                    }
                }
            }
        }
    }
`;

const options = [
    {
        label: "Best selling",
        value: "best_selleing",
    },
    {
        label: "Product title A-Z",
        value: "title-asc",
    },
    {
        label: "Product title Z-A",
        value: "title-desc",
    },
    {
        label: "Highest price",
        value: "regular_price-asc",
    },
    {
        label: "Lowest price",
        value: "regular_price-desc",
    },
    {
        label: "Newest",
        value: "created_at-desc",
    },
    {
        label: "Oldest",
        value: "created_at-asc",
    },
];

const ProductWrap = styled.div`
    padding: 2rem;
    position: relative;
    border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
`;

export const ProductByCollectionPolaris = ({ collectionId }) => {
    const [sort, setSort] = useState("best_selleinge");
    const [showModal, setShowModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [choiceProducts, setChoiceProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [matched, setMatched] = useState([]);
    const [totalMatched, setTotalMatched] = useState(0);
    const [query, setQuery] = useState(null);
    const [filter, setFilter] = useState({
        limit: 10,
        offset: 0,
        search: null,
        collectionId: null,
        sortBy: null,
        sortOrder: null,
    });
    const { data, loading } = useQuery(PRODUCTS_BY_COLLECTION, {
        variables: {
            filter: { ...filter, collectionId },
        },
    });

    useEffect(() => {
        if (
            data &&
            data.productsByCollection &&
            data.productsByCollection.nodes &&
            data.productsByCollection.nodes.length
        ) {
            let newData = data.productsByCollection;
            setProducts(newData.nodes);
            setChoiceProducts(newData.nodes);
            setTotal(newData.total);
            setTotalMatched(newData.total);
        }
    }, [data]);

    useEffect(() => {
        if (!showModal) {
            setQuery(null);
        }
    }, [showModal]);

    const handleSortChange = useCallback((value) => {
        setSort(value);
        let arr = value.split("-");
        if (1 === arr.length) {
            setFilter((prevState) => {
                return {
                    ...prevState,
                    sortBy: null,
                    sortOrder: null,
                };
            });
        } else if (2 === arr.length) {
            setFilter((prevState) => {
                return {
                    ...prevState,
                    sortBy: _.head(arr),
                    sortOrder: arr[1],
                };
            });
        }
    }, []);

    const handleShowModal = useCallback(() => setShowModal((m) => !m), []);
    const handleAction = useCallback(
        (newProducts) => {
            setMatched(newProducts);
            let matchedProducts = products.filter((p) =>
                newProducts.includes(p.id)
            );
            setChoiceProducts(matchedProducts);
            setTotalMatched(matchedProducts.length);
        },
        [products]
    );

    const handleQueryChange = useCallback((value) => {
        setQuery(value ? value.trim() : value);
        handleShowModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePaginationChange = useCallback((offset, limit) => {
        setFilter((prevState) => ({
            ...prevState,
            offset,
            limit,
        }));
    }, []);

    return (
        <>
            <Card.Header title="Products" />
            <Card.Section>
                <Stack distribution="fill">
                    <TextField
                        label="Search product"
                        labelHidden
                        prefix={<Icon source={SearchMinor} />}
                        connectedRight={
                            <Button onClick={handleShowModal}>Browse</Button>
                        }
                        onChange={handleQueryChange}
                    />
                    <Stack.Item fill>
                        <Select
                            label="Sort:"
                            labelInline
                            options={options}
                            onChange={handleSortChange}
                            value={sort}
                        />
                    </Stack.Item>
                </Stack>
            </Card.Section>
            <ProductWrap>
                {loading ? (
                    <SkeletonPagePolaris />
                ) : choiceProducts.length > 0 ? (
                    <TableProductPolaris
                        data={choiceProducts}
                        total={totalMatched}
                        filter={filter}
                        collectionId={collectionId}
                        setFilter={handlePaginationChange}
                    />
                ) : (
                    <EmptyStatePolaris />
                )}
            </ProductWrap>
            {showModal && (
                <ModalProductsPolaris
                    loading={loading}
                    open={showModal}
                    handleShowModal={handleShowModal}
                    products={products}
                    matched={matched}
                    total={total}
                    filter={filter}
                    onAction={(products) => handleAction(products)}
                    query={query}
                    onQueryChange={(search) => {
                        setFilter((prevState) => {
                            return {
                                ...prevState,
                                search: search ? search.trim() : search,
                            };
                        });
                    }}
                    setFilter={(v) => {
                        setFilter((prevState) => {
                            return {
                                ...prevState,
                                offset: v,
                            };
                        });
                    }}
                />
            )}
        </>
    );
};
