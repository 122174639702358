import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { ImportFilesFilterPolaris } from "./ImportFilesFilterPolaris";
import { ImportsDataTablePolaris } from "./ImportsDataTablePolaris";

export const IMPORTS = gql`
  query imports($filter: ImportFilter) {
    imports(filter: $filter) {
      total
      nodes {
        id
        files {
          id
          url
          name
          mimeType
        }
        supplier {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "supplierId"];

export const ImportFilesPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.search) {
        initialFilter.search = initialFilter.search.split(",");
      }
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageImports")) || 20,
    offset: 0,
    search: null,
    supplierId: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error } = useQuery(IMPORTS, {
    variables: {
      filter,
    },
  });

  // Handle action
  useEffect(() => {
    let { offset, limit, search, ...rest } = filter;

    let newSearch = search ? search.join(",") : null;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      search: newSearch,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <ImportFilesFilterPolaris
          filter={filter}
          onChange={({ search, supplierId }) => {
            setFilter((prev) => {
              if (
                !isEqual(prev.search, search) ||
                !isEqual(prev.supplierId, supplierId)
              ) {
                return {
                  ...prev,
                  search: search ? [search] : null,
                  supplierId,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div style={{ padding: "1.6rem" }}>
            {handleError(error.toString())}
          </div>
        ) : data?.imports?.nodes?.length > 0 ? (
          <ImportsDataTablePolaris
            data={data}
            filter={filter}
            setFilter={(offset, limit) => {
              setFilter((prev) => {
                if (
                  !isEqual(prev.offset, offset) ||
                  !isEqual(prev.limit, limit)
                ) {
                  return {
                    ...prev,
                    offset,
                    limit,
                  };
                }
                return prev;
              });
              setCookie("perPageImports", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
