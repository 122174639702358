import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  img {
    width: 100%;
  }
  .guide-image {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export default class QuickGuide extends Component {
  render() {
    return (
      <Container>
        <div className="crawler-quick-guide">
          <h2>Hướng dẫn sử dụng Tool Crawl</h2>
          <div className="guide-section">
            <h3>Tổng quan</h3>
            <p>
              <b>Tool Crawl</b> sẽ giúp người dùng crawl product từ website khác
              thông qua 2 cấu trúc file csv nhau: "Only URLs" và "Definded
              Structure"
            </p>
            <p>
              <b>Product Base:</b> là loại sản phẩm mà MerchBridge hỗ trợ. VD:
              3D Shirts, Quilt Blanket, Fleece Blanket...{" "}
            </p>
            <p>
              <b>Template:</b> Khi tạo sản phẩm, người dùng có thể lưu các thông
              tin cấu hình cho sản phẩm đó dưới dạng một template, những lần tạo
              sản phẩm tiếp theo người dùng có thể cấu hình lại thông tin cho
              sản phẩm hoặc dùng lại cấu hình cũ từ template.{" "}
            </p>
          </div>
          <div className="guide-section">
            <h3>1. Tạo product thông qua file csv struct</h3>
            <p>
              Với option này, người dùng cần chuẩn bị 1 file csv chứa các thông
              tin cơ bản của product: Title, Images, Product Base, Template,
              Collections, Tags
            </p>
            <p>
              File mẫu:{" "}
              <a
                href="https://res.cloudinary.com/mbmedia/raw/upload/v1590112585/defined-structure_cdjjlq.csv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Defined-structure.csv
              </a>
            </p>
            <p>
              <strong>Lưu ý:</strong> để lấy được product base slug người dùng
              có thể vào menu add product/add campaign để xem:
            </p>

            <div className="guide-image">
              <img
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
                src="https://res.cloudinary.com/mbmedia/image/upload/v1590114564/add-product-campaign_xzxy8j.png"
              />
            </div>
            <div className="guide-image">
              <img
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
                src="https://res.cloudinary.com/mbmedia/image/upload/v1590112101/850f3ef5-56a9-45af-89c4-36a9cb5e5886_f043od.png"
              />
            </div>

            <p>
              Để lấy được template slug, người dùng có thể vào add campaign,
              chọn All templates:
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590112692/b9e62849-1b18-4383-9adb-6ae79f0f67e9_vcorcn.png" />
            </div>
            <p>
              Tại mục All templates sẽ hiển thị tất cả các template đã được tạo
              kèm theo tên và slug:
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590112729/e21fb62f-d825-45e3-a977-865db34fb34b_at4mkj.png" />
            </div>
            <h4>Các bước tạo crawl với file "defined-structure.csv"</h4>
            <p>
              <b>Bước 1:</b> Truy cập menu: <b>Tools -> Product Crawlers</b>, ấn
              chọn <b>New Product Crawler</b>{" "}
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590112786/buoc1_cygopg.png" />
            </div>
            <p>
              <b>Bước 2:</b> Điền title, chọn type: <b>Product base struct</b>,
              upload file csv theo mẫu trên, sau đó ấn <b>Process Now</b>, hệ
              thống sẽ tự động tạo product dựa trên thông tin của file.
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590112805/buoc2_lfumf2.png" />
            </div>
          </div>

          <div className="guide-section">
            <h3>2. Tạo product thông qua file csv only urls</h3>
            <p>Với option này, người dùng có 2 thao tác:</p>
            <ul>
              <li>
                Chuẩn bị file csv chỉ chứa các url của product cần crawl, theo
                mẫu sau:
                <a
                  href="https://res.cloudinary.com/mbmedia/raw/upload/v1590112866/only-urls_vrbms5.csv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Only-URLs.csv
                </a>
              </li>
              <li>
                Khi crawl product về, người dùng cần tiếp tục assign product vào
                các product base hoặc template hay xoá bỏ nếu không phù hợp.
              </li>
            </ul>
            <p>
              <b>Bước 1:</b> Truy cập menu: <b> Tools -> Product Crawlers</b>,
              ấn chọn <b>New Product Crawler</b>
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590113070/b1_l9wy8g.png" />
            </div>
            <p>
              <b>Bước 2:</b> Nhập title cho crawler, chọn file csv đã chuẩn bị,
              chọn type: <b>Only urls</b>, cài đặt xpath cho title, xpath cho
              thumbs. Thao tác select expath sẽ chọn chính xác thẻ HTML chứa dữ
              liệu cần crawl về.
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590113099/b2_pysonk.png" />
            </div>
            <p>
              Thao tác setup xpath cần chuẩn xác để lấy được dữ liệu mong muốn:
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590113135/xpath_fego02.png" />
            </div>
            <p>
              Để kiểm tra phần xpath, người dùng có thể dùng button preview để
              kiểm tra, nếu setup chuẩn xác thì khi preview người dùng sẽ thấy
              dữ liệu mình mong muốn được hiển thị ra, ngược lại người dùng cần
              setup lại để có thể lấy chính xác dữ liệu mình mong muốn.
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590113163/check-result_uaenp8.png" />
            </div>
            <p>
              <b>Bước 3:</b> Assign product vào product base hoặc template:
              người dùng cần assign product đã được crawl vào product base hoặc
              template. Thao tác crawl chỉ lưu tạm thời dữ liệu, khi được assign
              vào product base hoặc template thì product mới được tạo theo dữ
              liệu mặc định của product base hoặc template.
            </p>
            <div className="guide-image">
              <img alt="" src="https://res.cloudinary.com/mbmedia/image/upload/v1590113195/assign_pqw3lp.png" />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
