import React, { Component } from "react";
import PropTypes from "prop-types";
import "@ant-design/compatible/assets/index.css";
import { Button, Select, Form, notification, Skeleton, Checkbox } from "antd";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import { handleError } from "../../helper";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import { get } from "lodash";
import MainImageSelector from "../assortment/MainImageSelector";

import styled from "styled-components";
import SimpleCustomize from "../customize/SimpleCustomize";

const LOAD = gql`
  query templates($filter: TemplateFilter) {
    templates(filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

const ASSIGN_MUTATION = gql`
  mutation assignToTemplate($input: NewAssignToTemplate!) {
    assignToTemplate(input: $input) {
      products {
        id
        title
        sku
        description
        designStatus
        personalized
        status
        productBases {
          id
          title
        }
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
        fields {
          id
          title
          name
          type
          extraFee
          configure
          sorting
        }
      }
      success
      failed
    }
  }
`;

const Container = styled.div`
  width: 480px;
  @media (min-width: 768px) {
    width: 720px;
  }
`;

class AssignProductTemplate extends Component {
  state = {
    loading: false,
    deleteImg: [],
    checked: false,
  };
  render() {
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Container>
        <Query query={LOAD} variables={{ filter: { grouping: true } }}>
          {({ loading, error, data, client }) => {
            if (loading)
              return (
                <div className="p-4">
                  <Skeleton active />
                </div>
              );

            if (error) return <div>Error: {handleError(error.toString())}</div>;
            return (
              <Query query={LOAD} variables={{ filter: { product: true } }}>
                {({
                  loading: loadingProductTemplate,
                  error: errorProductTemplate,
                  data: dataProductTemplate,
                }) => {
                  if (loadingProductTemplate)
                    return (
                      <div className="p-4">
                        <Skeleton active />
                      </div>
                    );
                  if (errorProductTemplate)
                    return <div>Error: {handleError(error.toString())}</div>;
                  return (
                    <Form
                      {...layout}
                      initialValues={{
                        collections: [{ id: "hGypw", name: "NO-TM" }],
                        tags: [],
                        mainImageId: get(
                          this.props.value,
                          "[0].images[0][file].id",
                          null
                        ),
                        fields: [],
                      }}
                      onFinish={(e) => {
                        if (e) {
                          let { deleteImg } = this.state;
                          deleteImg = deleteImg.filter(
                            (i) => i !== e.mainImageId
                          );
                          this.setState({ loading: true });
                          const data = {
                            productCrawlItems: this.props.value.map(
                              (v) => v.id
                            ),
                            templateId: e.templateId,
                            tags: e.tags.map((t) => t.id),
                            collections: e.collections.map((c) => c.id),
                            mainImageId: e.mainImageId,
                            deleteImages: deleteImg,
                            fields: e.fields,
                          };
                          client
                            .mutate({
                              mutation: ASSIGN_MUTATION,
                              variables: {
                                input: data,
                              },
                            })
                            .then((res) => {
                              this.setState({ loading: false });
                              if (res) {
                                notification.success({
                                  message: `Total ${res.data.assignToTemplate.success} items assigned completed!`,
                                });
                              }
                              if (this.props.onCompleted) {
                                this.props.onCompleted(
                                  res.data.assignToTemplate
                                );
                              }
                            })
                            .catch((e) => {
                              this.setState({ loading: false });
                              if (this.props.onError) {
                                this.props.onError(e);
                              }
                            });
                        }
                      }}
                    >
                      <Form.Item
                        label={"Template"}
                        name="templateId"
                        rules={[
                          { required: true, message: "Template is required!" },
                        ]}
                      >
                        <Select
                          className={"w-full"}
                          showSearch
                          filterOption={(input, option) => {
                            if (option.children) {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }
                          }}
                        >
                          <Select.OptGroup label="Product Template">
                            {dataProductTemplate.templates.nodes.map(
                              (t, index) => (
                                <Select.Option
                                  key={`product-${index}`}
                                  value={t.id}
                                >
                                  {t.name}
                                </Select.Option>
                              )
                            )}
                          </Select.OptGroup>
                          <Select.OptGroup label="Campaign Template">
                            {data.templates.nodes.map((t, index) => (
                              <Select.Option
                                key={`campaign-${index}`}
                                value={t.id}
                              >
                                {t.name}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        </Select>
                      </Form.Item>
                      {this.props.value.length === 1 &&
                        get(this.props.value, "[0].images[0]", null) && (
                          <Form.Item label={"Main image"} name={"mainImageId"}>
                            <MainImageSelector
                              images={this.props.value[0].images.map(
                                (f) => f.file
                              )}
                              deleteImg={this.state.deleteImg}
                              onChangeDeleteImg={(ids) =>
                                this.setState({ deleteImg: ids })
                              }
                            />
                          </Form.Item>
                        )}
                      <Form.Item
                        label={"Collections"}
                        name="collections"
                        rules={[
                          {
                            required: true,
                            message: "Collections is required.",
                          },
                        ]}
                      >
                        <CollectionsAutoComplete isAssign />
                      </Form.Item>
                      <Form.Item
                        label={"Tags"}
                        name="tags"
                        rules={[
                          { required: true, message: "Tags is required." },
                        ]}
                      >
                        <TagsAutoComplete />
                      </Form.Item>
                      <Form.Item valuePropName="checked">
                        <Checkbox
                          onChange={(e) =>
                            this.setState({ checked: e.target.checked })
                          }
                        >
                          This is a personalized product
                        </Checkbox>
                      </Form.Item>
                      {this.state.checked === true ? (
                        <Form.Item name="fields">
                          <SimpleCustomize />
                        </Form.Item>
                      ) : null}
                      <Form.Item>
                        <Button
                          htmlType={"submit"}
                          className={"w-full"}
                          type="primary"
                          loading={this.state.loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </Container>
    );
  }
}

AssignProductTemplate.propTypes = {
  value: PropTypes.any,
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default AssignProductTemplate;
