import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Icon, Modal, Spinner, Toast } from "@shopify/polaris";
import { MaximizeMajorMonotone } from "@shopify/polaris-icons";
import { get, head } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { PRINTIFY_SLUG } from "../../../constants";
import { UPDATE_ORDER_DESIGN } from "../../../graphql/mutations";
import { GET_ORDER_DESIGNS } from "../../../graphql/queries";
import { arrInvalid, getDesignPositionName, handleError, reducerFn } from "../../../helper";
import { FILE_SIZE_200MB, FILE_SIZE_50MB } from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { UploadFileCtxProvider } from "../hooks/useUploadFile";
import {
  checkDimensions,
  checkDimensionsType,
  isChinaOrCustomFulfill,
} from "../_utils";

const DimensionsNotify = React.lazy(() =>
  import("../components/DimensionsNotify"),
);

export const UpdateDesignPolaris = (props) => {
  const { open, toggleShowModal, value, refetch } = props;
  const orderId = value && value.id;
  const productBaseId =
    value &&
    value.productVariant &&
    value.productVariant.productBaseVariant &&
    value.productVariant.productBaseVariant.productBase
      ? value.productVariant.productBaseVariant.productBase.id
      : value.product.productBases[0].id;

  const [dataSource, setDataSource] = useState([]);
  const [designPosition, setDesignPosition] = useState({});
  const [activeToast, setActiveToast] = useState(false);
  const [maxSize, setMaxSize] = useState(FILE_SIZE_50MB);

  const [state, setState] = React.useReducer(reducerFn, {
    disableSubmit: false,
    dimensions: {},
    currentDesignId: {},
    checkingDimensions: {},
  });

  React.useEffect(() => {
    if (!value || !value.id || !open) return;

    (async () => {
      let maxSize = FILE_SIZE_50MB;
      const isChinaOrCF = await isChinaOrCustomFulfill(value.id);
      if (isChinaOrCF) {
        maxSize = FILE_SIZE_200MB;
      }

      setMaxSize(maxSize);
    })();
  }, [value?.id, open]);

  // useLockBodyScroll(open);

  const handleSubmit = useCallback(
    (setLoading) => () => {
      setLoading(true);
      let newDS = dataSource;
      let input =
        newDS && newDS.length > 0
          ? newDS
              .map((ds, index) => {
                if (designPosition[index][0]) {
                  return {
                    designPositionId: ds.designPosition.id,
                    fileId: designPosition[index][0].id,
                  };
                }
                return undefined;
                //   return {
                //       designPositionId: ds.designPosition.id,
                //       fileId: null,
                //   };
              })
              .filter(Boolean)
          : [];

      updateOrderDesign({
        variables: {
          id: orderId,
          input,
        },
      }).finally(() => {
        setLoading(false);
      });
      toggleActive();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [designPosition, dataSource],
  );

  const [getOrderDesigns, { data }] = useLazyQuery(GET_ORDER_DESIGNS, {
    fetchPolicy: "no-cache",
  });
  const [updateOrderDesign, { data: dataM, loading: loadingM, error: errorM }] =
    useMutation(UPDATE_ORDER_DESIGN, {
      onError: () => {
        toggleShowModal(false);
      },
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleShowModal(false);
          if (refetch) {
            refetch();
          }
        }, 2100);
        setTimeout(id);
      },
    });

  useEffect(() => {
    if (orderId && productBaseId && open) {
      getOrderDesigns({
        variables: {
          id: orderId,
          productBaseId,
        },
      });
    }
  }, [orderId, productBaseId, open, getOrderDesigns]);

  useEffect(() => {
    let newData = data?.getOrderDesigns?.orderDesigns || [];
    let designPositions = value?.product?.designPositions || [];
    const ffSlug = get(value, "fulfillment.slug");
    const isPrintify = ffSlug === PRINTIFY_SLUG;
    let currentDP = (newData || []).map((item) => item?.designPosition?.id);
    if (currentDP && !isPrintify && designPositions?.length > 0) {
      let dpWithoutData = designPositions.filter(
        (dp) => !currentDP.includes(dp?.designPosition?.id),
      );
      if (dpWithoutData?.length > 0) {
        newData = newData.concat(dpWithoutData);
      }
    }
    newData = newData.filter((dp) => {
      return dp?.designPosition?.productBaseId === productBaseId;
    });

    newData = sortDataSource(newData);
    setDataSource(newData);
  }, [data, value, productBaseId]);

  useEffect(() => {
    if (typeof state.dimensions !== "object") return;

    const val = checkDimensionsType(state.dimensions);
    setState({ disableSubmit: val });
  }, [state.dimensions]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const handleDesignChange = useCallback(
    (index) => (value) => {
      setDesignPosition((prevState) => ({
        ...prevState,
        [index]: value,
      }));

      let fileId = null;
      const dimensions = {};
      if (value?.length > 0) {
        fileId = value[0] ? value[0].id : null;
      } else {
        dimensions[index] = null;
      }

      const currentDesignId = {
        ...state.currentDesignId,
        [index]: fileId,
      };

      setState((p) => {
        return {
          ...p,
          currentDesignId,
          dimensions: {
            ...p.dimensions,
            ...dimensions,
          },
        };
      });
    },
    [state.currentDesignId],
  );

  const checkDimensionsRef = React.useRef(null);
  const handleCheckDimensions = useCallback(
    (index) => (value) => {
      checkDimensionsRef.current && clearTimeout(checkDimensionsRef.current);
      checkDimensionsRef.current = setTimeout(() => {
        if (value === true) {
          setState((p) => ({ ...p, disableSubmit: false }));
          return;
        }

        setState((p) => {
          const res = checkDimensions({
            dimensions: value,
            index,
            designId: p.currentDesignId,
          });

          return {
            ...p,
            dimensions: { ...p.dimensions, ...res },
          };
        });
      }, 150);
    },
    [],
  );

  const handleCheckingDimensions = useCallback(
    (index) => (value) => {
      setState({
        checkingDimensions: { ...state.checkingDimensions, [index]: value },
      });
    },
    [state.checkingDimensions],
  );

  const toastMarkup = activeToast
    ? (errorM || (dataM && dataM.updateOrderDesign)) && (
        <Toast
          content={
            errorM
              ? handleError(errorM.toString())
              : dataM && dataM.updateOrderDesign && `Update design success.`
          }
          error={errorM}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Main image
  const product = get(value, "product", null);
  const mainImageId = product?.mainImageId;
  const images = product?.images;
  let mainImages = [];
  const isCampaign = product?.productBases?.length > 1;
  const haveMainImageId = mainImageId || mainImageId !== "undefined";

  if (productBaseId && isCampaign) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.productBaseId === productBaseId)
            .map((item) => item.file)
        : [];
    if (mainImages.length > 1 && haveMainImageId) {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.file?.id === mainImageId)
              .map((item) => item.file)
          : [];
    }
  } else if (haveMainImageId) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.file?.id === mainImageId)
            .map((item) => item.file)
        : [];
  }
  if (mainImages.length === 0) {
    mainImages =
      images?.length > 0 ? images.filter(Boolean).map((item) => item.file) : [];
  }

  let firstImage = mainImages.length && head(mainImages);

  let originUrl = firstImage
    ? firstImage.url
      ? firstImage.url
      : firstImage.thumbnailUrl
      ? firstImage.thumbnailUrl
      : null
    : null;

  let originThumbnail = firstImage
    ? firstImage.thumbnailUrl
      ? firstImage.thumbnailUrl
      : firstImage.url
      ? firstImage.url
      : null
    : null;

  return (
    <>
      {toastMarkup}
      <UploadFileCtxProvider>
        {(loading, setLoading) => {
          return (
            <Modal
              title="Update design"
              open={open}
              onClose={toggleShowModal}
              sectioned
              secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
              ]}
              primaryAction={{
                content: "OK",
                onAction: handleSubmit(setLoading),
                loading: loadingM,
                disabled: loading || state.disableSubmit,
              }}
            >
              <Container id="update-design_modal">
                <div className="content_wrap">
                  <div className="thumbnail_wrap">
                    <h3>Thumbnail</h3>
                    <div className="thumbnail_content">
                      <img src={originThumbnail} alt="" />
                      <div className="view-origin">
                        <div className="img-wrap">
                          <CustomLinkPolaris href={originUrl}>
                            <Icon source={MaximizeMajorMonotone}></Icon>
                          </CustomLinkPolaris>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="design-position_wrap">
                    <div className="design-position_inner">
                      <h3>Design</h3>
                      <>
                        {dataSource?.length > 0
                          ? dataSource.map((ds, idx) => {
                              const dsPo = get(ds, "designPosition");
                              let displayName = getDesignPositionName(dsPo)
                              let dpD = get(dsPo, "description");
                              if (designPosition) {
                                displayName = `${displayName} (${dpD})`;
                              }

                              const currentMsg = state.checkingDimensions[idx];
                              const currentDimension = state.dimensions[idx];
                              return (
                                <div
                                  key={idx}
                                  className="design-position-content"
                                >
                                  <div className="design-position_item">
                                    <MediaSelectorButtonPolaris
                                      value={ds && (ds.file ? [ds.file] : [])}
                                      isPrintFile={true}
                                      prefix="print_files/"
                                      folder={"undefined"}
                                      singleUpload={true}
                                      multiple={false}
                                      isUpdateDesign
                                      dPName={displayName}
                                      maxSize={maxSize}
                                      // onChange={(value) =>
                                      //   setDesignPosition((prevState) => ({
                                      //     ...prevState,
                                      //     [idx]: value,
                                      //   }))
                                      // }
                                      onChange={handleDesignChange(idx)}
                                      isCompareDesign
                                      designPosition={dsPo}
                                      onCheckDimensions={handleCheckDimensions(
                                        idx,
                                      )}
                                      onCheckingDimensions={handleCheckingDimensions(
                                        idx,
                                      )}
                                    />
                                  </div>
                                  <SuspenseComp
                                    fallback={<Spinner size="small" />}
                                  >
                                    <DimensionsNotify
                                      loading={currentMsg}
                                      msg={currentDimension?.message}
                                      type={currentDimension?.type}
                                    />
                                  </SuspenseComp>
                                  {dataSource.length > 1 &&
                                    ds.designPosition.name.toLowerCase() ===
                                      "master" && (
                                      <div
                                        style={{
                                          marginTop: "1rem",
                                        }}
                                      >
                                        Or
                                      </div>
                                    )}
                                </div>
                              );
                            })
                          : null}
                      </>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </Container>
            </Modal>
          );
        }}
      </UploadFileCtxProvider>
      {/* <Modal
        title="Update design"
        open={open}
        onClose={toggleShowModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
        primaryAction={{
          content: "OK",
          onAction: handleSubmit,
          loading: loadingM,
        }}
      >
        <Container id="update-design_modal">
          <div className="content_wrap">
            <div className="thumbnail_wrap">
              <h3>Thumbnail</h3>
              <div className="thumbnail_content">
                <img src={originThumbnail} alt="" />
                <div className="view-origin">
                  <div className="img-wrap">
                    <CustomLinkPolaris href={originUrl}>
                      <Icon source={MaximizeMajorMonotone}></Icon>
                    </CustomLinkPolaris>
                  </div>
                </div>
              </div>
            </div>
            <div className="design-position_wrap">
              <div className="design-position_inner">
                <h3>Design</h3>
                <>
                  {dataSource?.length > 0
                    ? dataSource.map((ds, idx) => {
                        const dsPo = get(ds, "designPosition");
                        let dpName = get(dsPo, "name");
                        let dpD = get(dsPo, "description");
                        if (designPosition) {
                          dpName = `${dpName} (${dpD})`;
                        }
                        return (
                          <div key={idx} className="design-position-content">
                            <div className="design-position_item">
                              <MediaSelectorButtonPolaris
                                value={ds && (ds.file ? [ds.file] : [])}
                                isPrintFile={true}
                                prefix="print_files/"
                                folder={"undefined"}
                                singleUpload={true}
                                multiple={false}
                                isUpdateDesign
                                dPName={dpName}
                                onChange={(value) =>
                                  setDesignPosition((prevState) => ({
                                    ...prevState,
                                    [idx]: value,
                                  }))
                                }
                              />
                            </div>
                            {dataSource.length > 1 &&
                              ds.designPosition.name.toLowerCase() ===
                                "master" && (
                                <div
                                  style={{
                                    marginTop: "1rem",
                                  }}
                                >
                                  Or
                                </div>
                              )}
                          </div>
                        );
                      })
                    : null}
                </>
              </div>
            </div>
          </div>
        </Container>
      </Modal> */}
    </>
  );
};

export const sortDataSource = (data) => {
  if (arrInvalid(data)) return data;

  data.sort(
    (a, b) => a?.designPosition?.sorting - b?.designPosition?.sorting || 0,
  );

  const index = data.findIndex(
    (v) => v?.designPosition?.name?.toLowerCase() === "master",
  );
  if (index > 0) {
    [data[0], data[index]] = [data[index], data[0]];
  }
  return data;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .content_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    .thumbnail_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 50%;
      label {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
    .thumbnail_content {
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 0.75rem;
      position: relative;
      &:hover {
        .view-origin {
          opacity: 1;
        }
      }
      .view-origin {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: right;
        padding-top: 1rem;
        padding-right: 1rem;
        background: linear-gradient(
          180deg,
          rgba(33, 43, 54, 0.55),
          hsla(0, 0%, 100%, 0)
        );
        opacity: 0;
        transition: 0.3s opacity ease-out;
        .img-wrap {
          display: inline-block;
        }
        .Polaris-Icon {
          svg {
            fill: hsla(0, 0%, 100%, 0.8);
          }
        }
      }
      img {
        width: 100%;
        height: 28rem;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px 2px rgba(0, 0, 0, 0.1);
      }
    }
    .design-position_wrap {
      width: 50%;
      .design-position_item {
        display: flex;
      }
      .design-position_inner {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
        .design-position-content {
          & + .design-position-content {
            padding-top: 1rem;
          }
        }
        .loading_wrap {
          display: flex;
          justify-content: center;
        }
        .artwork_inner {
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .design-position-info {
          display: flex;
          flex-direction: column;
          row-gap: 0.75rem;
          margin-top: 1rem;
          > div {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            text-align: center;
          }
        }
      }
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
`;
