import React from "react";
import PropTypes from "prop-types";
import { Button, DataTable } from "@shopify/polaris";
const SwitchFulfillmentBaseVariant = (props) => {
  const rows = props.variants.map((v) => {
    return [
      <div>{v.name}</div>,
      <Button
        size="slim"
        onClick={() => {
          props.onAdd(v);
        }}
      >
        Add to configure
      </Button>,
    ];
  });
  return (
    <div>
      <DataTable
        columnContentTypes={["text", "text"]}
        headings={["Variant", "Action"]}
        rows={rows}
      />
    </div>
  );
};

SwitchFulfillmentBaseVariant.propTypes = {
  variants: PropTypes.array,
  onAdd: PropTypes.func,
};

export default SwitchFulfillmentBaseVariant;
