import React from "react";
import { Page, Card, Loading, Toast } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { getParamByRole, handleError } from "../../../helper";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { GroupFormPolaris } from "./GroupFormPolaris";
import { useAppContext } from "../../../context";

export const QUERY_GROUP_BY_ID = gql`
    query group($id: ID!) {
        group(id: $id) {
            id
            name
            description
            stores {
                id
                title
                platform
            }
        }
    }
`;

export const MUTATION_UPDATE_GROUP = gql`
    mutation updateGroup($input: EditGroup!) {
        updateGroup(input: $input) {
            id
            name
            description
            stores {
                id
                title
            }
        }
    }
`;
const Container = styled.div``;

export const GroupEditPolaris = () => {
    // Params
    const { id } = useParams();

    // Context
    const { currentUser } = useAppContext();
    const currentParam = getParamByRole(currentUser);

    // State
    const [toastActive, setToastActive] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);
    const [notification, setNotification] = React.useState({
        message: null,
        error: false,
    });

    // Query
    const { data, loading, error } = useQuery(QUERY_GROUP_BY_ID, {
        variables: {
            id,
        },
        skip: !id,
    });

    // Mutation
    const [updateGroup, { loading: loadingM }] = useMutation(
        MUTATION_UPDATE_GROUP,
        {
            onCompleted: () => {
                setNotification({
                    message: "Update group successfully,",
                    error: false,
                });
                const id = setTimeout(() => {
                    onRedirect();
                }, 2100);
                setTimeoutId(id);
            },
            onError: (error) => {
                setNotification({
                    message: handleError(error.toString()),
                    error: true,
                });
            },
        }
    );

    // Handle actions
    React.useEffect(() => {
        return () => clearTimeout(timeoutId);
    }, [timeoutId]);

    const toggleToastActive = React.useCallback(
        () => setToastActive((prev) => !prev),
        []
    );
    const onRedirect = React.useCallback(() => {
        history.push(`/${currentParam}/stores/groups`);
    }, []);

    const handleSubmit = React.useCallback(
        (input) => {
            if (input) {
                if (toggleToastActive) toggleToastActive();
                setNotification({
                    message: null,
                    error: false,
                });
                updateGroup({
                    variables: {
                        input: {
                            ...input,
                            id,
                        },
                    },
                });
            }
        },
        [id, updateGroup, toggleToastActive]
    );

    // Markup
    const loadingMarkup = loading && <Loading />;
    const toastMarkup = toastActive && notification.message && (
        <Toast
            content={notification.message}
            error={notification.error}
            onDismiss={toggleToastActive}
            duration={2000}
        />
    );

    return (
        <Container>
            {toastMarkup}
            {loadingMarkup}
            <Page
                title="Update Group"
                breadcrumbs={[
                    {
                        content: "Groups",
                        url: `/${currentParam}/stores/groups`,
                    },
                ]}
            >
                <Card sectioned>
                    {loading ? (
                        <SkeletonPagePolaris />
                    ) : error ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : (
                        <GroupFormPolaris
                            onRedirect={onRedirect}
                            onSubmit={handleSubmit}
                            loading={loadingM}
                            btnSubmitLabel="Update Group"
                            isEditGroup
                            value={data?.group}
                        />
                    )}
                </Card>
            </Page>
        </Container>
    );
};
