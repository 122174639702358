import { get } from "lodash";
import React from "react";
import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";

export const ProductVariantSelectPolaris = ({ product, onChange, error }) => {
  // State
  const [variants, setVariants] = React.useState([]);

  // Handle actions
  const getProductVariants = React.useCallback((product) => {
    const newVariant = product?.variants?.length > 0 ? product.variants : [];
    const baseLength = product?.productBases?.length;

    if (baseLength === 1) {
      return newVariant.map((variant, index) => {
        const fulfillmentSku = get(
          variant,
          "productBaseVariant.fulfillmentSku",
        );
        const baseID = get(variant, "productBaseVariant.productBase.id");
        return {
          fulfillmentSku,
          baseID,
          value: variant.id,
          label: variant.productBaseVariant
            ? getAttributeName(variant.productBaseVariant.attributes)
            : getAttributeName(
                get(product.productBases[0].variants[index], "attributes"),
              ),
        };
      });
    }

    if (baseLength > 1) {
      return newVariant.map((variant) => {
        const baseVariant = variant.productBaseVariant;
        const fulfillmentSku = get(
          variant,
          "productBaseVariant.fulfillmentSku",
        );

        const baseID = get(variant, "productBaseVariant.productBase.id");
        return {
          fulfillmentSku,
          baseID,
          value: variant.id,
          label:
            baseVariant &&
            getAttributeName([
              ...baseVariant.attributes,
              {
                option: baseVariant?.productBase?.title,
                id: 0,
                name: "Type",
              },
            ]),
        };
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return title of attribute and sort a-z
  const getAttributeName = React.useCallback(
    (attributes = []) => {
      if (!attributes) {
        return "";
      }
      return attributes
        .map((att) => att.option && att.option.trim())
        .sort((a, b) => {
          const al = a && a.toLowerCase();
          const bl = b && b.toLowerCase();
          if (al < bl) {
            return -1;
          }
          if (al > bl) {
            return 1;
          }
          return 0;
        })
        .join("-");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product],
  );

  // Get data
  React.useEffect(() => {
    if (product != null) {
      const pVariants = getProductVariants(product);
      setVariants(pVariants);
    } else {
      setVariants([]);
    }
  }, [product, getProductVariants]);

  return (
    <AutoCompletePolaris
      options={variants}
      label="Product variants"
      required
      error={error}
      onChange={(val) => {
        const match = (variants || []).find(({ value }) => value === val);
        const { fulfillmentSku, baseID } = match || {};
        onChange(val, { fulfillmentSku, baseID });
      }}
      placeholder="Search product variant"
    />
  );
};
