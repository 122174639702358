import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextStyle, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { handleError } from "../../helper";
import { TagsSelectPolaris } from "./tags/TagsSelectPolaris";

const ADD_TAGS = gql`
  mutation addTagsToProducts(
    $productIds: [ID!]!
    $tagIds: [ID!]
    $add: Boolean!
  ) {
    addTagsToProducts(productIds: $productIds, tagIds: $tagIds, add: $add)
  }
`;

const Container = styled.div`
  div[role="combobox"]:focus {
    outline: none;
  }
`;

export const AddTagsProductsPolaris = ({
  open,
  toggleModal,
  selectedItem,
  refetch,
  onCompleted,
  items,
}) => {
  const [tags, setTags] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [resource, setResource] = useState([]);
  const [addTagsToProducts, { data, loading, error }] = useMutation(ADD_TAGS, {
    onError: () => {},
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setTags([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    if (
      selectedItem?.length !== 1 ||
      !items ||
      !Array.isArray(items) ||
      items.length === 0
    ) {
      setResource([]);
      return;
    }

    const item = items.find(({ id }) => selectedItem.includes(id));
    if (
      !item ||
      !item.tags ||
      !Array.isArray(item.tags) ||
      item.tags.length === 0
    )
      return;
    setResource(item.tags);

    return () => {
      setResource([]);
    };
  }, [selectedItem]);

  const handleAction = useCallback(() => {
    const tagIds = tags?.length > 0 ? Array.from(new Set(tags).values()) : [];

    addTagsToProducts({
      variables: {
        productIds: selectedItem,
        tagIds: tagIds,
        add: true,
      },
    });
    toggleActive();
    const id = setTimeout(() => {
      toggleModal();
      onCompleted();
      setTags([]);
      if (refetch) {
        refetch();
      }
    }, 2500);
    setTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, tags]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const toastMarkup = activeToast
    ? (error || (data && data.addTagsToProducts)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.addTagsToProducts && "Add tags success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        title="Add tags"
        open={open}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleModal }]}
        primaryAction={{
          content: "Save",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <Container>
          <TagsSelectPolaris
            value={tags}
            allowMultiple
            label="Tags"
            onChange={(value) => setTags(value)}
            resource={resource}
            // getCoupleValue
          />
          <div style={{ marginTop: 5 }}>
            <TextStyle
              variation="strong"
              children="Existing tags will be replaced by the newly selected tags"
            />
          </div>
        </Container>
      </Modal>
    </>
  );
};
