import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAppContext } from "../context";
import React from "react";
import history from "../history";

const LOG_OUT = gql`
  mutation logout {
    logout
  }
`;

const useLogout = () => {
  const [logout, { client }] = useMutation(LOG_OUT);
  const { setStore, setCurrentUser, logout: logoutCtx } = useAppContext() || {};

  const actionLogout = React.useCallback(async () => {
    await logout();
    localStorage.clear();
    setStore(null);
    setCurrentUser(null);
    client.resetStore();
    logoutCtx();
    history.push(`/login`);
    window.location.reload(false);
  }, [logout, setStore, setCurrentUser, client, logoutCtx]);

  return {
    logout: actionLogout,
  };
};

export { useLogout };
