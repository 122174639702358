import { Button, ButtonGroup, Checkbox } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { STATUS_DESIGN_TASK } from "../../constants/task";
import { BulkActionAcceptDesignPolaris } from "./actions/BulkActionAcceptDesignPolaris";
import BulkChangeStatus from "./actions/BulkChangeStatus";
import BulkMarkTaskConfigurable from "./actions/BulkMarkTaskConfigurable";
import BulkRejectDesign from "./actions/BulkRejectDesign";
import BulkRemoveTaskConfigurable from "./actions/BulkRemoveTaskConfigurable";
import { DenyActionPolaris } from "./actions/DenyActionPolaris";
import ExportDesignURL from "./actions/ExportDesignURL";
import { StartActionPolaris } from "./actions/StartActionPolaris";
import { UpdateTaskBulkActionsPolaris } from "./actions/UpdateTaskBulkActionsPolaris";

const Container = styled.div`
  .Polaris-Choice {
    padding: 0;
    .Polaris-Choice__Control {
      margin-top: 0.1rem;
    }
  }

  .Polaris-ButtonGroup--segmented {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
`;

const STATUS_CAN_CHANGE = {
  needBuyerConfirm: STATUS_DESIGN_TASK.NeedBuyerConfirm,
  awaitingBuyerConfirm: STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
  needReview: STATUS_DESIGN_TASK.NeedReview,
};

export const BulkActionsPolaris = (props) => {
  const { idsChecked, onCheckAll, isLeader, isDesigner, filterStatus } = props;
  const isNRStatus = [STATUS_DESIGN_TASK.NeedReview].includes(filterStatus);
  const isPending = [STATUS_DESIGN_TASK.Pending].includes(filterStatus);
  const isArtworkConfigurable = [
    STATUS_DESIGN_TASK.ArtworkConfigurable,
  ].includes(filterStatus);

  const showExportDesign = [
    STATUS_DESIGN_TASK.AwaitingBuyerConfirm,
    STATUS_DESIGN_TASK.NeedBuyerConfirm,
  ].includes(filterStatus);

  const canChangeStatus = Object.values(STATUS_CAN_CHANGE).includes(
    filterStatus,
  );
  const changeStatus = React.useMemo(
    () =>
      Object.entries(STATUS_CAN_CHANGE)
        .map(([key, value]) => (value !== filterStatus ? key : null))
        .filter(Boolean),
    [filterStatus],
  );

  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const toggleShowModal = useCallback((value) => {
    setOpen((prev) => !prev);
    setCurrentAction(value);
  }, []);

  const actions = [
    {
      show: isDesigner,
      render: (
        <Button
          children="Start now"
          onClick={() => toggleShowModal("startNow")}
          disclosure
        />
      ),
    },
    {
      show: isDesigner,
      render: (
        <Button
          children="Deny"
          onClick={() => toggleShowModal("deny")}
          disclosure
        />
      ),
    },
    {
      show: isLeader,
      render: (
        <Button
          children="Update tasks"
          onClick={() => toggleShowModal("updateTasks")}
          disclosure
        />
      ),
    },
    {
      show: showExportDesign && isLeader,
      render: (
        <Button
          children="Export Design URL"
          onClick={() => toggleShowModal("exportDesignURL")}
          disclosure
        />
      ),
    },
    ...changeStatus.map((status) => ({
      show: canChangeStatus && isLeader,
      render: (
        <Button
          children={`Change to ${STATUS_CAN_CHANGE[status]}`}
          disclosure
          onClick={() => toggleShowModal(status)}
        />
      ),
    })),
    {
      show: isLeader && isPending,
      render: (
        <Button
          children="Mark as Configurable"
          disclosure
          onClick={() => toggleShowModal("markAsConfigurable")}
        />
      ),
    },
    {
      show: isLeader && isArtworkConfigurable,
      render: (
        <Button
          children="Remove From Configurable"
          disclosure
          onClick={() => toggleShowModal("removeFromConfigurable")}
        />
      ),
    },
    {
      show: isLeader && isNRStatus,
      render: (
        <Button
          children="Accept designs"
          onClick={() => toggleShowModal("acceptDesign")}
          disclosure
        />
      ),
    },
    {
      show: isLeader && isNRStatus,
      render: (
        <Button
          children="Reject designs"
          onClick={() => toggleShowModal("rejectDesign")}
          disclosure
        />
      ),
    },
  ];

  const actionsActive = actions.filter((i) => i.show);

  const propActions = {
    open,
    toggleShowModal,
  };

  const actionsMarkup = {
    startNow: <StartActionPolaris {...props} {...propActions} />,
    deny: <DenyActionPolaris {...props} {...propActions} />,
    updateTasks: <UpdateTaskBulkActionsPolaris {...props} {...propActions} />,
    acceptDesign: <BulkActionAcceptDesignPolaris {...props} {...propActions} />,
    exportDesignURL: <ExportDesignURL {...props} {...propActions} />,
    rejectDesign: <BulkRejectDesign {...props} {...propActions} />,
    awaitingBuyerConfirm: (
      <BulkChangeStatus
        {...props}
        {...propActions}
        label={`Change to ${STATUS_CAN_CHANGE.awaitingBuyerConfirm}`}
        newStatus={STATUS_CAN_CHANGE.awaitingBuyerConfirm}
      />
    ),
    needBuyerConfirm: (
      <BulkChangeStatus
        {...props}
        {...propActions}
        label={`Change to ${STATUS_CAN_CHANGE.needBuyerConfirm}`}
        newStatus={STATUS_CAN_CHANGE.needBuyerConfirm}
      />
    ),
    needReview: (
      <BulkChangeStatus
        {...props}
        {...propActions}
        label={`Change to ${STATUS_CAN_CHANGE.needReview}`}
        newStatus={STATUS_CAN_CHANGE.needReview}
      />
    ),
    markAsConfigurable: (
      <BulkMarkTaskConfigurable {...props} {...propActions} />
    ),
    removeFromConfigurable: (
      <BulkRemoveTaskConfigurable {...props} {...propActions} />
    ),
  };

  return (
    <Container>
      <ButtonGroup segmented>
        <Button
          children={
            <Checkbox label={`${idsChecked.length} selected`} checked={true} />
          }
          onClick={onCheckAll}
        />
        {actionsActive?.length > 0
          ? actionsActive.map((i, index) => {
              return <React.Fragment key={index}>{i.render}</React.Fragment>;
            })
          : null}
      </ButtonGroup>
      {actionsMarkup[currentAction]}
    </Container>
  );
};
