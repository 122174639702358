import {
  Badge,
  Checkbox,
  DataTable,
  Link,
  Tag,
  Thumbnail,
  Toast,
} from "@shopify/polaris";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import noImg from "../../assets/images/unnamed.jpeg";
import { useAppContext } from "../../context";
import { checkRole, getParamByUserRole } from "../../helper";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { BulkActionsPolaris } from "./BulkActionsPolaris";

const Container = styled.div`
  overflow-x: auto;
  .Polaris-DataTable {
    .Polaris-DataTable__ScrollContainer {
      overflow-x: inherit;
      -webkit-overflow-scrolling: touch;
    }
  }
  .product-base_wrap {
    width: calc(300px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .supplier_wrap {
    width: calc(200px - 3.2rem);
    white-space: normal;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .supplier-cost_wrap {
    width: calc(200px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .prices_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .image_wrap {
    width: calc(130px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .bulk-actions_wrap {
    padding: 1.6rem;
  }
  .Polaris-DataTable__Cell--header {
    border-top: 0.1rem solid #dbdee0;
  }
  .Polaris-DataTable__Footer {
    overflow-y: scroll;
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export const ProductBasesTableDataPolaris = (props) => {
  // Props
  const { data, filter, setFilter, refetch } = props;

  // State;
  const [rows, setRows] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [itemsChecked, setItemsChecked] = useState({});
  const [idsChecked, setIdsChecked] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Context
  const { currentUser } = useAppContext();
  const { isAdministrator } = checkRole(currentUser);
  const param = getParamByUserRole(currentUser);

  // Get data
  useEffect(() => {
    let newData =
      data &&
      data.productBases.nodes.map((base) => {
        // Product base
        let hasTeam = get(base, "team", {});
        hasTeam = JSON.stringify(!!hasTeam);
        let link =
          base.length > 1
            ? `/${param}/product-bases`
            : `/${param}/product-bases/edit`;
        let productBaseUrl = `${link}/${base.id}?hasTeam=${hasTeam}`;
        let title = base?.title;

        // Image
        let images = base?.images;

        // Prices
        let prices = base?.baseCostDescription;

        // Supplier cost
        let mapSupplierById = {};
        let suppliers = base?.suppliers;
        let supplierPricing = base?.supplierPricing;
        let supplierPricingMarkup;

        if (suppliers && suppliers.length) {
          for (let i = 0; i < suppliers.length; i++) {
            mapSupplierById[suppliers[i]?.id] = suppliers[i];
          }
        }
        if (supplierPricing && supplierPricing.length) {
          supplierPricingMarkup = supplierPricing.map((sp, index) => {
            const supplier = mapSupplierById[sp.userId];
            if (!supplier) {
              return null;
            }
            return (
              <div key={index}>
                {`${supplier.firstName} ${supplier.lastName}`}: ${sp.price}
              </div>
            );
          });
        }

        // Suppiler
        let suppilerMarkup;
        if (suppliers && suppliers.length) {
          suppilerMarkup = suppliers.map((s, index) => (
            <div key={index}>
              <Tag children={`${s.firstName} ${s.lastName}`} />
            </div>
          ));
        }

        // fulfillment
        const fulfillment = base?.fulfillment?.fulfillmentId
          ? base.fulfillment.fulfillmentTitle
          : "MerchBridge";
        const ffMarkup = (
          <div className="ff-wrap">
            <span>{fulfillment}</span>
          </div>
        );

        // let canEdit = false;
        // if (isAdministrator) {
        //   canEdit = hasTeam;
        // }
        const imgMarkup =
          images?.length > 0 ? (
            <Thumbnail
              source={images[0]?.thumbnailUrl || null}
              size={"large"}
            />
          ) : (
            <Thumbnail source={noImg} size="large" />
          );
        const teamName = get(base, "team.name", "");

        const status =
          typeof base.status === "boolean"
            ? base.status
              ? "Publish"
              : "Draft"
            : "";
        const statusBadge = status === "Publish" ? "Success" : null;
        const statusMarkup = status ? (
          <div className="status-wrap">
            <span>
              <Badge children={status} status={statusBadge} />
            </span>
          </div>
        ) : null;

        return [
          <div className="checkbox_wrap">
            <Checkbox
              label=""
              id={base.id}
              checked={!!itemsChecked[base.id]}
              onChange={handleChecked}
            />
          </div>,
          <div className="image_wrap">
            {/* {canEdit ? (
              imgMarkup
            ) : ( */}
            <Link url={productBaseUrl}>{imgMarkup}</Link>
            {/* )} */}
          </div>,
          <div className="id_wrap">
            <span>{base.id}</span>
          </div>,
          <div className="product-base_wrap">
            {/* {canEdit ? (
              <span>{title}</span>
            ) : ( */}
            <Link url={productBaseUrl} children={title} />
            {/* )} */}
          </div>,
          <div className="prices_wrap">
            <span>{prices}</span>
          </div>,
          ffMarkup,
          <div className="team_wrap">
            <span>{teamName}</span>
          </div>,
          <div className="supplier-cost_wrap">
            {supplierPricingMarkup && <div>{supplierPricingMarkup}</div>}
          </div>,
          <div className="supplier_wrap">{suppilerMarkup}</div>,
          statusMarkup,
        ];
      });
    setRows(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemsChecked, param, isAdministrator]);

  // Handle actions
  const handleCheckedAll = useCallback((newChecked) => {
    setCheckedAll(newChecked);
    let baseProp = data?.productBases?.nodes || [];
    let idsChecked = [];
    if (newChecked) {
      let item = {};
      for (let i = 0; i < baseProp.length; i++) {
        item = {
          ...item,
          [baseProp[i]?.id]: true,
        };
      }
      setItemsChecked(item);
      idsChecked = handleIdsChecked(item);
    } else {
      setItemsChecked({});
      idsChecked = [];
    }
    setIdsChecked(idsChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChecked = useCallback(
    (newChecked, id) => {
      let countItemsChecked;
      let idsChecked = [];

      setItemsChecked((prev) => {
        let result = { ...prev, [id]: newChecked };
        countItemsChecked = Object.values(result).filter((i) => i).length;

        idsChecked = handleIdsChecked(result);
        return result;
      });

      if (newChecked) {
        setCheckedAll(true);
      } else if (!countItemsChecked) {
        setCheckedAll(false);
      }
      setIdsChecked(idsChecked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsChecked]
  );

  const handleIdsChecked = useCallback((object) => {
    object = Object(object);
    let result = [];

    for (let [key, value] of Object.entries(object)) {
      if (value) {
        result.push(key);
      }
    }
    return result;
  }, []);

  const handleCompletedAction = useCallback(() => {
    setIdsChecked(() => []);
    setCheckedAll(false);
    setItemsChecked({});
    if (refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Variable
  let { limit, offset } = filter;
  let total = get(data, "productBases.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <>
      <Container>
        {toastMarkup}
        {idsChecked.length > 0 ? (
          <div className="bulk-actions_wrap">
            <BulkActionsPolaris
              idsChecked={idsChecked}
              toggleActive={toggleActive}
              onCompleted={handleCompletedAction}
              onNotificationChange={({ message, isError }) =>
                setNotification(() => ({ message, isError }))
              }
              onCheckAll={() => {
                handleCheckedAll(false);
              }}
            />
          </div>
        ) : null}
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            <Checkbox
              label=""
              checked={checkedAll}
              onChange={handleCheckedAll}
            />,
            "Image",
            "ID",
            "Product Base",
            "Prices",
            "Fulfillment",
            "Team",
            "Supplier Cost",
            "Supplier",
            "Status",
          ]}
          rows={rows}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </>
  );
};
