import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import React from "react";
import { GET_TASK_SUBMISSION_BY_ORDER_IDS } from "../../../graphql/queries";
import { arrInvalid, objectInvalid } from "../../../helper";

export function useAddAcceptedBy({ screenOptions, data, wrapRef }) {
  const canRender = screenOptions.includes("acceptedBy");
  const orderIDs =
    data?.orders?.nodes?.length > 0
      ? data.orders.nodes.map(({ id }) => id).filter(Boolean)
      : [];

  const { data: data2 } = useQuery(GET_TASK_SUBMISSION_BY_ORDER_IDS, {
    skip: !canRender || orderIDs.length === 0,
    variables: {
      orderIDs,
    },
  });

  const timeoutRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (!wrapRef) return;
    const nodes = data2?.getTaskSubmissionByOrderIDs || [];
    if (arrInvalid(nodes)) return;
    const dataValid = getAcceptedByOrders(nodes);
    if (objectInvalid(dataValid)) return;
   
    timeoutRef.current = setTimeout(() => {
      for (let el of Array.from(wrapRef.querySelectorAll(".accepted-by-wrap"))) {
        if (!objectInvalid(el)) {
          const orderId = el.getAttribute("data-order-id");
          if (orderId && dataValid[orderId]) {
            const span = document.createElement("span");
            const { fullName } = dataValid[orderId].checker || {};
            span.textContent = fullName;
            span.className = "accepted-value";
            fullName && el.appendChild(span);
          }
        }
      }
    }, 0);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    }
  }, [data2, wrapRef]);

  React.useLayoutEffect(() => {
    if (!wrapRef) return;

    for (let el of Array.from(wrapRef.querySelectorAll(".accepted-value"))) {
      if (!objectInvalid(el)) {
        el.remove();
      }
    }
  }, [canRender, wrapRef]);

  return null;
}

function getAcceptedByOrders(data) {
  const result = data.filter((item) => {
    const status = get(item, "taskSubmission.status");
    return status === "Accept";
  });

  const res = result.reduce((acc, item) => {
    if (objectInvalid(item)) return acc;
    acc[item["orderID"]] = item;
    return acc;
  }, {});

  return res;
}
