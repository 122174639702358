import { gql } from "apollo-boost";

export const SaleByStoreManagerFragment = gql`
    fragment SaleByStoreManagerFragment on SaleByStoreManager {
        userId
        totalSale
        totalBaseCost
        totalRevenues
        user {
            id
            firstName
            lastName
        }
    }
`;

export const SaleByMarketplaceManagerFragment = gql`
    fragment SaleByMarketplaceManagerFragment on SaleByMarketplaceManager {
        userId
        totalSale
        totalBaseCost
        totalRevenues
        user {
            id
            firstName
            lastName
        }
    }
`;

export const SaleByStoreFragment = gql`
    fragment SaleByStoreFragment on SaleByStore {
        storeId
        totalSale
        totalBaseCost
        totalRevenues
        store {
            id
            title
        }
    }
`;

export const KpiByDesignerFragment = gql`
    fragment KpiByDesignerFragment on KpiByDesigner {
        assigneeId
        rejectedTimeCount
        totalDoneKpi
        totalDoneTasks
        assignee {
            id
            firstName
            lastName
        }
    }
`;

export const CreatedProductByUserItemFragment = gql`
    fragment CreatedProductByUserItemFragment on CreatedProductByUserItem {
        userId
        user {
            id
            firstName
            lastName
        }
        totalProducts
    }
`;

export const SaleByProductItemFragment = gql`
    fragment SaleByProductItemFragment on SaleByProductItem {
        productId
        totalSale
        product {
            id
            title
            sku
            personalized
            productBases {
                id
                title
            }
            mainImageId
            mainImage {
                id
                thumbnailUrl
                url
                mimeType 
            }
            images {
                id
                file {
                    id
                    thumbnailUrl
                    url
                    mimeType
                }
            }
        }
    }
`;

export const SaleChanelOverviewResultFragment = gql`
    fragment SaleChanelOverviewResultFragment on SaleChanelOverviewResult {
        revenues
        totalUnits
        totalOrders
        etsy
        ebay
        amazon
        tiktok
        facebookMarketplace
        onlineStore
        totalBaseCost
        chartValues {
            date
            totalUnits
            formatedDate
        }
    }
`;
export const SaleChannelOverviewByProductIDtResultFragment = gql`
    fragment SaleChannelOverviewByProductIDtResultFragment on SaleChannelOverviewByProductIDtResult {
        revenues
        totalUnits
        totalOrders
        etsy
        ebay
        amazon
        tiktok
        facebookMarketplace
        onlineStore
        totalBaseCost
        chartValues {
            date
            totalUnits
            formatedDate
        }
    }
`;

export const SaleByGroupItemFragment = gql`
    fragment SaleByGroupItemFragment on SaleByGroupItem {
        groupId
        totalSale
        totalBaseCost
        totalRevenues
        group {
            id
            name
        }
    }
`;
