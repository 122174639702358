import React, { Component } from "react";
import { Popconfirm, notification, Radio } from "antd";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const DELETE_PRODUCT_SELECT = gql`
  mutation deleteProductSelected($id: [ID!], $removeStore: Boolean!) {
    deleteProductSelected(id: $id, removeStore: $removeStore) {
      deleted
    }
  }
`;
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
class DeleteSelectProduct extends Component {
  state = {
    loading: false,
    removeStore: true,
    onPopOverClose: this.props.onPopOverClose,
  };

  render() {
    const { selectedRows, onCompleted, onDelete } = this.props;
    return (
      <Mutation
        mutation={DELETE_PRODUCT_SELECT}
        onCompleted={(res) => {
          this.setState({ loading: false }, () => {
            if (
              res &&
              res.deleteProductSelected &&
              res.deleteProductSelected.deleted
            ) {
              notification.success({
                message: `Deleted ${res.deleteProductSelected.deleted.length}/${selectedRows.length} products success!`,
              });
            }
            if (onCompleted) {
              onCompleted(res.deleteProductSelected);
            }
            if (
              res.deleteProductSelected &&
              res.deleteProductSelected.deleted &&
              onDelete
            ) {
              onDelete(
                res.deleteProductSelected && res.deleteProductSelected.deleted
              );
            }
          });
        }}
      >
        {(deleteProductSelected, { client }) => (
          <Popconfirm
            okText={"Yes"}
            cancelText="No"
            icon={null}
            onCancel={this.state.onPopOverClose}
            title={
              <div>
                <Radio.Group
                  defaultValue={true}
                  onChange={(e) => {
                    this.setState({ removeStore: e.target.value });
                  }}
                  options={[
                    {
                      label: "Are you sure to delete this selected?",
                      value: false,
                      style: radioStyle,
                    },
                    {
                      label: "Are you sure to delete this selected in store?",
                      value: true,
                      style: radioStyle,
                    },
                  ]}
                />
              </div>
            }
            placement={"right"}
            onConfirm={() => {
              deleteProductSelected({
                variables: {
                  id: selectedRows,
                  removeStore: this.state.removeStore,
                },
              }).then((res) => {
                if (
                  res &&
                  res.data &&
                  res.data.deleteProductSelected &&
                  res.data.deleteProductSelected.deleted
                ) {
                  try {
                    const { filter } = this.props;
                    const variable = {
                      filter: filter,
                    };
                    const cache = client.readQuery({
                      query: LIST_SELLER_PRODUCTS_QUERY,
                      variables: variable,
                    });
                    client.writeQuery({
                      query: LIST_SELLER_PRODUCTS_QUERY,
                      variables: variable,
                      data: {
                        ...cache,
                        products: {
                          ...cache.products,
                          total: cache.products.total - selectedRows.length,
                          nodes: cache.products.nodes.filter(
                            (u) => !selectedRows.includes(u.id)
                          ),
                        },
                      },
                    });
                  } catch (e) {}
                  this.setState({ onClose: this.state.onPopOverClose });
                }
              });
            }}
          >
            <div style={{ cursor: "pointer" }}>Delete Selected product</div>
          </Popconfirm>
        )}
      </Mutation>
    );
  }
}

export default DeleteSelectProduct;
