import React, { Component } from "react";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Button, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import axios from "axios";
import styled from "styled-components";

export const REQUEST_MUTATION = gql`
  mutation createUploadUrl($input: RequestFileUpload!, $uniqueFolder: Boolean) {
    createUploadUrl(input: $input, uniqueFolder: $uniqueFolder) {
      key
      url
    }
  }
`;

export const CREATE_FILE_MUTATION = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      id
      name
      key
      mimeType
      size
      url
      thumbnailUrl
      createdAt
    }
  }
`;

const Container = styled.div`
  .ant-upload.ant-upload-drag {
    border: 2px dashed gray;
    display: ${(props) => (props.comment ? "none" : "block")};
  }
`;

class ImageUpload extends Component {
  state = {
    disabled: false,
    fileList: [],
  };

  componentDidMount() {
    const { value } = this.props;
    if (value && value.length) {
      const fileList = value.map((v, index) => {
        return {
          uid: `${index}`,
          name: v.name,
          status: "done",
          url: v.thumbnailUrl ? v.thumbnailUrl : v.url,
          id: value.id,
        };
      });
      this.setState({ fileList });
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({ message: "You can only upload JPG/PNG file!" });
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      notification.error({ message: "Image must smaller than 20MB!" });
    }
    return isJpgOrPng && isLt2M;
  };

  checkMimeType = (file, cb) => {
    const mimes = [
      {
        mime: "image/png",
        pattern: [0x89, 0x50, 0x4e, 0x47],
      },
      {
        mime: "image/jpeg",
        pattern: [0xff, 0xd8, 0xff],
      },
      {
        mime: "image/gif",
        pattern: [0x47, 0x49, 0x46, 0x38],
      },
      {
        mime: "image/webp",
        pattern: [
          0x52,
          0x49,
          0x46,
          0x46,
          undefined,
          undefined,
          undefined,
          undefined,
          0x57,
          0x45,
          0x42,
          0x50,
          0x56,
          0x50,
        ],
      },
    ];
    const isMime = (bytes, mime) => {
      return mime.pattern.every((p, i) => !p || bytes[i] === p);
    };
    const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
    const blob = file.slice(0, numBytesNeeded);
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (!e || !reader.result) return cb(null);
      const bytes = new Uint8Array(reader.result);
      const mime = mimes.find((m) => isMime(bytes, m));
      return cb(mime ? mime.mime : null);
    };
    reader.readAsArrayBuffer(blob);
  };
  customRequest = async (options, client, createUploadUrl) => {
    const { folder, handleChangeOkBtnDisabled } = this.props;
    const { file, onSuccess, onError } = options;
    if (handleChangeOkBtnDisabled) {
      handleChangeOkBtnDisabled(true);
    }
    this.checkMimeType(file, async (mime) => {
      let fileType = file.type;
      let fileName = file.name;
      if (mime) {
        fileType = mime;
        switch (mime) {
          case "image/webp":
            fileName = fileName.replace(/\.[^/.]+$/, ".webp");
            break;
          case "image/gif":
            fileName = fileName.replace(/\.[^/.]+$/, ".gif");
            break;
          case "image/jpeg":
            fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
            break;
          case "image/png":
            fileName = fileName.replace(/\.[^/.]+$/, ".png");
            break;
          default:
            break;
        }
      }
      try {
        const res = await createUploadUrl({
          variables: {
            input: {
              name: fileName,
              mimeType: fileType,
              size: file.size,
              folder: folder ? folder : null,
            },
          },
        });
        const { key, url } = res.data.createUploadUrl;
        await axios.put(url, file, {
          headers: { "Content-Type": fileType },
          timeout: 1000 * 60 * 30,
        });
        const fileResponse = await client.mutate({
          mutation: CREATE_FILE_MUTATION,
          variables: {
            input: {
              name: fileName,
              key: key,
              size: file.size,
              mimeType: fileType,
              isPrintFile: this.props.isPrintFile,
            },
          },
        });
        if (fileResponse) {
          if (handleChangeOkBtnDisabled) {
            handleChangeOkBtnDisabled(false);
          }
        }
        onSuccess(fileResponse.data.createFile);
      } catch (e) {
        onError(e);
      }
    });
  };
  onChange = (v) => {
    let files = [];
    this.setState({ fileList: v.fileList });

    if (v.fileList && v.fileList.length) {
      for (let i = 0; i < v.fileList.length; i++) {
        if (v.fileList[i].status === "done") {
          files.push(v.fileList[i].response);
        }
      }
    }
    if (this.props.onChange) {
      this.props.onChange(files);
    }
  };

  render() {
    const { fileList } = this.state;
    const {
      multiple,
      listType,
      hidePlaceholder,
      singleUpload,
      accept,
      comment,
      content,
      direction,
      extend,
      uploadDescription,
      uploadButtonTitle,
    } = this.props;
    let limit = this.props.limit;
    if (singleUpload) {
      limit = 1;
    }
    let disabled = false;
    if (limit && fileList && fileList.length >= limit) {
      disabled = true;
    }
    return (
      <Container comment={comment}>
        <Mutation mutation={REQUEST_MUTATION}>
          {(createUploadUrl, { client }) =>
            singleUpload ? (
              <Upload
                showUploadList={this.props.showUploadList}
                fileList={this.state.fileList}
                beforeUpload={this.beforeUpload}
                accept={accept ? accept : null}
                listType="picture-card"
                onChange={this.onChange}
                customRequest={(options) =>
                  this.customRequest(options, client, createUploadUrl)
                }
                name={"file"}
              >
                {!disabled && (
                  <div>
                    <PlusOutlined />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                )}
              </Upload>
            ) : (
              <Upload.Dragger
                accept={accept ? accept : null}
                disabled={this.state.disabled}
                listType={listType}
                onChange={this.onChange}
                fileList={this.state.fileList}
                customRequest={(options) =>
                  this.customRequest(options, client, createUploadUrl)
                }
                name={"file"}
                multiple={multiple}
                showUploadList={{ showRemoveIcon: !comment }}
              >
                {!disabled && !hidePlaceholder && !comment && (
                  <div>
                    <p className="pb-2">{direction ? direction : null}</p>
                    <Button className="mb-2" type={"primary"}>
                      {" "}
                      {content
                        ? content
                        : uploadButtonTitle
                        ? uploadButtonTitle
                        : "Add images"}
                    </Button>
                    {extend ? (
                      <p>{extend}</p>
                    ) : (
                      <p className="ant-upload-text">
                        {uploadDescription
                          ? uploadDescription
                          : "Or drop image to upload"}
                      </p>
                    )}
                  </div>
                )}
              </Upload.Dragger>
            )
          }
        </Mutation>
      </Container>
    );
  }
}

ImageUpload.propTypes = {
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  limit: PropTypes.number,
  value: PropTypes.array,
  listType: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  singleUpload: PropTypes.bool,
  accept: PropTypes.any,
  showUploadList: PropTypes.any,
  uploadDescription: PropTypes.any,
  uploadButtonTitle: PropTypes.string,
  folder: PropTypes.string,
  isPrintFile: PropTypes.bool,
};

export default ImageUpload;
