import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";
import { useSwitchFulfillment } from "./context";
import { THIRD_PARTY_FULFILLMENTS } from "../../variable";
import get from "lodash/get";
import {
  Autocomplete,
  Card,
  Checkbox,
  Collapsible,
  Heading,
  Stack,
  TextField,
} from "@shopify/polaris";

export const genShippingOptionKey = (originId, targetId) =>
  `${originId}-${targetId}`;

export function ShippingOptions({ items }) {
  const {
    shippingOption,
    setShippingOption,
    fulfillment,
    allFulfillment,
  } = useSwitchFulfillment();

  // Props
  const allFulfillmentByID = useMemo(() => {
    return (allFulfillment || []).reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  }, [allFulfillment]);
  const itemsRef = useRef([]);

  const ffHasShippingOptions = useMemo(() => {
    const obShippingOptions = (items || []).reduce(
      (acc, { targetFulfillmentId, ...props }) => {
        const matched = allFulfillmentByID[targetFulfillmentId];
        if (!matched) return acc;
        const { slug } = matched;
        const map = THIRD_PARTY_FULFILLMENTS[slug];

        if (!map) return acc;
        acc[slug] = {
          ...props,
          ...matched,
          targetFulfillmentId,
        };
        return acc;
      },
      {}
    );
    return Object.values(obShippingOptions);
  }, [items, allFulfillment]);

  const fulfillmentId = fulfillment?.id;
  useEffect(() => {
    setShippingOption((prev) => {
      const match = prev.shippingOptionConfigs;

      const shippingOptionConfigs = (ffHasShippingOptions || []).reduce(
        (acc, { targetFulfillmentId }, index) => {
          const key = genShippingOptionKey(fulfillmentId, targetFulfillmentId);

          const currentRef = itemsRef.current[index];
          let mappingOptions = new Map();
          if (
            currentRef &&
            typeof currentRef.getMappingOptions === "function"
          ) {
            const opts = currentRef.getMappingOptions() || [];

            const currentConfig = match.get(key);
            let prevConfig = new Map();
            if (currentConfig) {
              prevConfig = currentConfig.mappingOptions;
            }
            mappingOptions = new Map(
              opts.map(({ value }) => {
                const matchMappingOption = prevConfig.get(value);

                return [
                  value,
                  {
                    originShippingMethod: value,
                    targetShippingMethod: matchMappingOption
                      ? matchMappingOption.targetShippingMethod
                      : null,
                  },
                ];
              })
            );
          }

          acc[key] = {
            targetFulfillmentID: targetFulfillmentId,
            originFulfillmentID: fulfillmentId,
            mappingOptions,
          };

          return acc;
        },
        {}
      );

      if (ffHasShippingOptions.length === 0) {
        return {
          ...prev,
          usingShippingOption: false,
        };
      }

      Object.entries(shippingOptionConfigs).forEach(([key, value]) => {
        match.set(key, value);
      });
      return {
        ...prev,
        shippingOptionConfigs: match,
      };
    });
  }, [ffHasShippingOptions, fulfillmentId]);

  // Actions
  const handleUsingOption = useCallback((value) => {
    setShippingOption((prev) => ({
      ...prev,
      usingShippingOption: value,
    }));
  }, []);

  const handleShippingOptionChange = useCallback(
    (targetFulfillmentID) => (originMethod, targetMethod) => {
      setShippingOption((prev) => {
        const clone = prev.shippingOptionConfigs;
        if (!clone) return prev;

        const key = genShippingOptionKey(fulfillmentId, targetFulfillmentID);
        const match = clone.get(key);
        if (!match) return prev;

        const op = match.mappingOptions.get(originMethod);
        if (!op) {
          match.mappingOptions.set(originMethod, {
            originShippingMethod: originMethod,
            targetShippingMethod: targetMethod,
          });
        } else {
          match.mappingOptions.set(originMethod, {
            ...op,
            targetShippingMethod: targetMethod,
          });
        }

        clone.set(key, match);
        return {
          ...prev,
          shippingOptionConfigs: clone,
        };
      });
    },
    [fulfillmentId]
  );

  // Markup
  const fulfillmentName = get(fulfillment, "name");
  const fulfillmentSlug = get(fulfillment, "slug");
  const fulfillmentMarkup = useMemo(() => {
    const options = THIRD_PARTY_FULFILLMENTS[fulfillmentSlug] || {};
    const opts = Object.entries(options).map(([value, label]) => ({
      value,
      label,
      disabled: true,
    }));

    const textField = (
      <Autocomplete.TextField
        readOnly
        label={`Origin Fulfillment (${fulfillmentName})`}
      />
    );
    return <Autocomplete options={opts} selected={[]} textField={textField} />;
  }, [fulfillment]);

  return ffHasShippingOptions?.length > 0 ? (
    <Card sectioned>
      <Stack vertical spacing="loose">
        <Checkbox
          label="Using Shipping Option"
          checked={shippingOption.usingShippingOption}
          onChange={handleUsingOption}
        />
        <Collapsible open={shippingOption.usingShippingOption}>
          <Stack vertical spacing="loose">
            {ffHasShippingOptions.map((item, index) => {
              const { name: targetName, slug, targetFulfillmentId } =
                item || {};
              const title = `${fulfillmentName}  ->  ${targetName}`;
              const key = genShippingOptionKey(
                fulfillmentId,
                targetFulfillmentId
              );
              const currentConfig = shippingOption.shippingOptionConfigs.get(
                key
              );
              return (
                <Card
                  title={title}
                  sectioned
                  key={`target-fulfillment-${index}`}
                >
                  <OriginShippingOptions
                    ref={(el) => (itemsRef.current[index] = el)}
                    fulfillment={fulfillment}
                    fulfillmentSlug={fulfillmentSlug}
                    targetSlug={slug}
                    currentConfig={currentConfig}
                    onChange={handleShippingOptionChange(targetFulfillmentId)}
                  />
                </Card>
              );
            })}
          </Stack>
        </Collapsible>
      </Stack>
    </Card>
  ) : null;
}

const OriginShippingOptions = React.forwardRef(function OriginShippingOptions(
  { fulfillmentSlug, targetSlug, onChange, currentConfig },
  ref
) {
  const options = useMemo(() => {
    const shippingOpts = THIRD_PARTY_FULFILLMENTS[fulfillmentSlug] || {};
    const options = Object.entries(shippingOpts).map(([value, label]) => ({
      value,
      label,
    }));
    if (options.length > 0) {
      options.unshift({ label: "Default", value: null });
    }

    return options;
  }, [fulfillmentSlug]);

  // Actions
  const handleShippingOptionChange = useCallback(
    (originMethod) => (newShippingOption) => {
      const [value] = newShippingOption || [];
      onChange(originMethod, value);
    },
    [onChange]
  );

  const getDefaultValue = useCallback((mappingOptions, optVal) => {
    let defaultValue = [null];
    const isOb = (ob) => typeof ob === "object";
    const mappingOption = isOb(mappingOptions)
      ? mappingOptions.get(optVal)
      : null;

    if (mappingOption) {
      const { targetShippingMethod } = mappingOption;
      if (targetShippingMethod) defaultValue = [targetShippingMethod];
    }
    return defaultValue;
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getMappingOptions: () => options,
    }),
    []
  );

  return options?.length > 0 ? (
    <Stack vertical spacing="tight">
      <Stack distribution="fillEvenly" spacing="loose">
        <Heading element="h3" children="Origin Fulfillment" />
        <Heading element="h3" children="Target Fulfillment" />
      </Stack>
      <Stack spacing="loose" vertical>
        {options.map((opt, index) => {
          const textField = <TextField disabled value={opt.label} />;
          const { mappingOptions } = currentConfig || {};
          let defaultValue = getDefaultValue(mappingOptions, opt.value);
          return (
            <Stack
              distribution="fillEvenly"
              spacing="loose"
              key={`shipping-options-${index}`}
            >
              {textField}
              <ShippingOption
                targetSlug={targetSlug}
                defaultValue={defaultValue}
                onChange={handleShippingOptionChange(opt.value)}
              />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  ) : null;
});

export function ShippingOption({ targetSlug, onChange, defaultValue }) {
  // State
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  //
  useEffect(() => {
    const deselectedOptions = Object.entries(
      THIRD_PARTY_FULFILLMENTS[targetSlug] || {}
    ).map(([value, label]) => ({ value, label }));

    deselectedOptions.unshift({ value: null, label: "Default" });
    setOptions(deselectedOptions);
    setDeselectedOptions(deselectedOptions);
  }, [targetSlug]);

  useEffect(() => {
    if (!defaultValue || defaultValue.length === 0) return;
    const inputVal = deselectedOptions.find(({ value }) =>
      defaultValue.includes(value)
    );
    if (inputVal?.label) {
      setInputValue(inputVal.label);
    }
    setSelectedOptions(defaultValue);
  }, [defaultValue, deselectedOptions]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter(({ label }) =>
        label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectionChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((item) => {
        const matched = options.find(({ value }) => value === item);
        return matched && matched.label;
      });

      setSelectedOptions(selected);
      onChange(selected);
      setInputValue(selectedValue[0]);
    },
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={handleInputChange}
      value={inputValue}
      placeholder="Search shipping option"
    />
  );

  return (
    <Autocomplete
      options={options}
      selected={selectedOptions}
      onSelect={handleSelectionChange}
      textField={textField}
    />
  );
}
