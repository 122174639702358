import { Scrollable } from "@shopify/polaris";
import { Timeline } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import styled from "styled-components";
import amazonLogo from "../../assets/images/amazon-logo.png";
import ebayLogo from "../../assets/images/ebay-logo.png";
import etsyLogo from "../../assets/images/etsy-logo.png";
import facebookLogo from "../../assets/images/facebook-logo.png";
import shopbaseLogo from "../../assets/images/shopbase.png";
import shopifyLogo from "../../assets/images/shopify.png";
import tiktokLogo from "../../assets/images/tiktok-logo.png";
import woocommerceLogo from "../../assets/images/wooCommerce.png";
import { OREFER_URL } from "../../variable";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const ImgWrap = styled.div`
  display: inline-block;
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 7px;
  }
`;

export default class PushHistory extends Component {
  render() {
    const { value } = this.props;
    return (
      <Scrollable shadow style={{ maxHeight: 740, paddingTop: 10 }} focusable>
        <Timeline>
          {value &&
            value.length > 0 &&
            value.map((v) => {
              let productUrl = v.permalink ? v.permalink : "";
              let platform = _.get(v, "store.platform", null);
              let srcLogoStore = {
                woocommerce: woocommerceLogo,
                shopify: shopifyLogo,
                ebay: ebayLogo,
                amazon: amazonLogo,
                shopbase: shopbaseLogo,
                etsy: etsyLogo,
                facebook: facebookLogo,
                tiktok: tiktokLogo,
              };
              const isShopBase = "shopbase" === platform;
              if ("amazon" === platform) {
                const amazonURL = "https://www.amazon.com";
                if (v.originId && v.originId.length > 0) {
                  productUrl = `${amazonURL}/dp/` + v.originId;
                } else {
                  productUrl = amazonURL;
                }
              }

              let newProductUrl = productUrl;
              if (
                !newProductUrl.includes("https://") &&
                !newProductUrl.includes("http://") &&
                !newProductUrl.includes("www.")
              ) {
                newProductUrl = `//${newProductUrl}`;
              }

              return (
                <Timeline.Item key={v.id}>
                  <>
                    <ImgWrap>
                      <img src={srcLogoStore[platform]} alt="" />
                    </ImgWrap>
                    Pushed to: {v.store ? v.store.title : "Store deleted"}
                    {v.createdAt
                      ? " on " + moment(v.createdAt).format("LL")
                      : null}
                    <p>
                      <CustomLinkPolaris
                        href={
                          isShopBase
                            ? newProductUrl
                            // : OREFER_URL + newProductUrl
                            : newProductUrl
                        }
                      >
                        View product
                      </CustomLinkPolaris>
                    </p>
                  </>
                </Timeline.Item>
              );
            })}
        </Timeline>
      </Scrollable>
    );
  }
}
