import React from "react";
import { Modal } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  display: flex:
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    objectFit: cover;
  }
`;

export const ModalImagePolaris = (props) => {
    const { open, toggleShowModal, source, title } = props;
    return (
        <Modal
            title={title}
            open={open}
            onClose={toggleShowModal}
            sectioned
            large
        >
            <Container>
                <img src={source} alt="" />
            </Container>
        </Modal>
    );
};
