import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import styled from "styled-components";
import history from "../../history";
import { Card, Page } from "@shopify/polaris";
import {
  handleError,
  getCookie,
  setCookie,
  convertStringToObject,
  convertObjectToParams,
} from "../../helper";
import _ from "lodash";
import FilterMemberPolaris from "../../pages/seller/FilterMemberPolaris";
import { TableMemberPolaris } from "../members/TableMemberPolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;
export const LIST_TEAM = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      count
      hits {
        id
        role
        team {
          id
        }
        user {
          id
          firstName
          lastName
          roles
          email
          phone
          address
          status
          avatar {
            id
            url
          }
        }
      }
    }
  }
`;

let filterInit = convertStringToObject(history.location.search);

const FILTER = {
  search: "",
  limit: Number(getCookie("perPageMembers")) || 20,
  offset: 0,
  status: null,
  role: null,
};

class ListTeamMember extends Component {
  state = {
    loading: false,
    data: [],
    filter: {
      ...FILTER,
      ...convertStringToObject(history.location.search),
    },
    page: 1,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      let { status } = nextState.filter;
      if (true === status) {
        status = "Active";
      }
      if (false === status) {
        status = "Inactive";
      }
      const params = convertObjectToParams({
        limit: nextState.filter.limit,
        offset: nextState.filter.offset,
        status,
        search: nextState.filter.search,
        role: nextState.filter.role,
      });
      history.push(history.location.pathname + "?" + params);
    }
  }
  componentWillMount() {
    const url = convertStringToObject(window.location.search);
    if (url && url.status) {
      this.setState({
        filter: {
          ...this.state.filter,
          status:
            filterInit && filterInit.status === "Active"
              ? true
              : filterInit && filterInit.status === "Inactive"
              ? false
              : null,
        },
      });
    }
  }

  onChangeActive = (value, role, search) => {
    this.setState({
      filter: {
        ...this.state.filter,
        search: search,
        status: value === "true" ? true : value === "false" ? false : value,
        role: role,
      },
    });
  };
  render() {
    const { filter } = this.state;

    return (
      <Container>
        <Page title="All Member" fullWidth={true}>
          <Card sectioned>
            <FilterMemberPolaris
              filter={filter}
              onChange={(status, role, search) => {
                this.onChangeActive(status, role, search);
              }}
            />
          </Card>
          <Card bodyStyle={{ padding: 0 }}>
            <Query
              query={LIST_TEAM}
              variables={{ filter: filter }}
              fetchPolicy="cache-and-network"
            >
              {({ data, error, loading }) => {
                if (error)
                  return <div>Error:{handleError(error.toString())}</div>;
                if (loading) return <SkeletonPagePolaris />;
                if (data.teamMembers.hits.length === 0) {
                  return <EmptyStatePolaris />;
                } else {
                  return (
                    <TableMemberPolaris
                      data={data}
                      filter={filter}
                      setValuePage={(offset, limit) => {
                        this.setState({
                          filter: {
                            ...filter,
                            offset: parseInt(offset),
                            limit,
                          },
                        });
                        setCookie("perPageMembers", limit, 100);
                      }}
                    />
                  );
                }
              }}
            </Query>
          </Card>
        </Page>
      </Container>
    );
  }
}
export default ListTeamMember;
