import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import moment from "moment";
import _ from "lodash";

import { FilterCustomTimePolaris } from "../tasks/FilterCustomTimePolaris";

export const FilterCreatedTimePolaris = (props) => {
  const {
    filterTime: { range, rangeLabel },
    onChangeRange,
    isExportAmazon
  } = props;
  const [choiceValue, setChoiceValue] = useState(["All Time"]);
  const [customTime, setCustomTime] = useState({
    range: range,
    rangeLabel: rangeLabel,
  });

  const renderChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <FilterCustomTimePolaris
          isExportAmazon={isExportAmazon}
          customTime={customTime}
          onChange={({ from, to, fromLabel, toLabel }) => {
            setCustomTime({
              rangeLabel: `${fromLabel} - ${toLabel}`,
              range: {
                from,
                to,
              },
            });
            if (onChangeRange) {
              onChangeRange({ from, to }, `${fromLabel} - ${toLabel}`);
            }
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [choiceValue]
  );

  const options = [
    { value: "All Time", label: "All Time" },
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 days", label: "Last 7 days" },
    { value: "This month", label: "This month" },
    { value: "Last month", label: "Last month" },
    { value: "Custom", label: "Custom", renderChildren },
  ];

  useEffect(() => {
    if (null === range && "Custom" !== rangeLabel) {
      setChoiceValue("All Time");
    } else {
      let checkValue = _.values(_.mapValues(options, "value"));
      if (checkValue.includes(rangeLabel)) {
        setChoiceValue(`${rangeLabel}`);
      } else {
        setChoiceValue(`Custom`);
      }
    }

    // Custom time
    setCustomTime(() => ({
      range: range,
      rangeLabel: rangeLabel,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, rangeLabel]);

  const handleChoiceValue = useCallback((selected) => {
    let range = {
      from: null,
      to: null,
    };
    if (selected[0]) {
      switch (selected[0]) {
        case "Today":
          range.from = moment().startOf("day");
          range.to = moment().endOf("day");
          break;
        case "Yesterday":
          range.from = moment().subtract(1, "days").startOf("day");
          range.to = moment().subtract(1, "days").endOf("day");
          break;
        case "Last 7 days":
          range.from = moment().subtract(7, "days").startOf("day");
          range.to = moment();
          break;
        case "This month":
          range.from = moment().startOf("month");
          range.to = moment();
          break;
        case "Last month":
          range.from = moment().subtract(1, "months").startOf("months");
          range.to = moment().subtract(1, "months").endOf("months");
          break;
        default:
          range = null;
          break;
      }
    }

    if (onChangeRange) {
      onChangeRange(range, selected[0]);
    }
    setChoiceValue(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Value"
      titleHidden
      choices={options}
      selected={choiceValue}
      onChange={handleChoiceValue}
    />
  );
};
