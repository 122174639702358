import React from "react";
import { ReportOrders } from "./ReportOrders";
import { REPORT_ORDERS_ON_HOLD } from "../../../graphql/queries/order";

export function ReportOnHold({ filter }) {
  return (
    <ReportOrders
      filter={filter}
      title="Fulfilllment Orders have no fulfillment_submit_id"
      documentNode={REPORT_ORDERS_ON_HOLD}
    />
  );
}
