import React, { useCallback, useContext, useState } from "react";
import { Modal, Checkbox } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { MOrderContext } from "../context";
import { handleError } from "../../../helper";

import { GET_TOTAL } from "../../order/TotalOrderMapping";
import { LIST_MAPPING } from "../MappingOrdersPolaris";

export const DELETE_MAPPING = gql`
  mutation deleteMappingOrderSelected($ids: [ID!]!, $ignoreForever: Boolean) {
    deleteMappingOrderSelected(ids: $ids, ignoreForever: $ignoreForever) {
      deleted
      messages {
        id
        error
      }
    }
  }
`;

export const Trash = ({ open, onClose, ids, onCompleted, isMoreAction }) => {
  // Context
  const { notifyChange, toggleToast, filter, setAgg } =
    useContext(MOrderContext);
  const storeId = filter.storeId;
  const type = filter.type;

  // State
  const [checked, setChecked] = useState(false);

  // Mutation
  const [deleteMappingOrder, { loading, client }] = useMutation(
    DELETE_MAPPING,
    {
      onError: (error) => {
        if (notifyChange) {
          notifyChange({
            msg: handleError(error.toString()),
            err: true,
          });
        }
      },
      onCompleted: (res) => {
        if (notifyChange) {
          notifyChange({
            msg: "Delete order mapping selected successfully.",
            err: false,
          });
        }

        let countDeleted = res?.deleteMappingOrderSelected?.deleted;
        handleUpdateCache(countDeleted);
        onCompleted && onCompleted();
        onClose && onClose();
      },
    },
  );

  // Handle action
  const handleUpdateCache = useCallback(
    (res) => {
      if (res != null) {
        try {
          const deletedLen = res.length;

          // Query total mapping order
          //   const variablesTMO = { storeId };
          //   const queryOptionTMO = {
          //     query: GET_TOTAL,
          //     variables: variablesTMO,
          //   };

          //   const cacheTMO = client.readQuery(queryOptionTMO);
          //   client.writeQuery({
          //     ...queryOptionTMO,
          //     data: {
          //       ...cacheTMO,
          //       totalMappingOrder: cacheTMO.totalMappingOrder - deletedLen,
          //     },
          //   });

          //   Query list mapping order
          const variablesMO = {
            filter,
          };
          const queryOptionMO = {
            query: LIST_MAPPING,
            variables: variablesMO,
          };
          const cacheMO = client.readQuery(queryOptionMO);
          client.writeQuery({
            ...queryOptionMO,
            data: {
              ...cacheMO,
              listMappingOrder: {
                ...cacheMO.listMappingOrder,
                nodes: (cacheMO.listMappingOrder.nodes || []).filter(
                  (i) => !res.includes(i.id),
                ),
              },
            },
          });

          // Type
          const newType = "online store" === type ? "online_stores" : type;
          setAgg((prev) => {
            return {
              ...prev,
              total: prev.total - deletedLen,
              ...(newType != null
                ? {
                    [newType]: {
                      ...prev[newType],
                      doc_count: prev[newType].doc_count - deletedLen,
                    },
                  }
                : {}),
            };
          });
        } catch (err) {}
      }
    },
    [storeId, client, filter, setAgg, type],
  );

  const handleSubmit = useCallback(() => {
    if (ids?.length > 0) {
      toggleToast && toggleToast(true);
      notifyChange && notifyChange({ msg: null, err: false });

      const variables = {
        ids,
        ignoreForever: checked,
      };
      deleteMappingOrder({
        variables,
      });
    }
  }, [ids, deleteMappingOrder, toggleToast, notifyChange, checked]);

  const handleChange = useCallback((value) => setChecked(value), []);

  return (
    <Modal
      title="Delete mapping orders"
      open={open}
      onClose={onClose}
      sectioned
      secondaryActions={[{ content: "No", onAction: onClose }]}
      primaryAction={{
        content: "Yes",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to delete mapping orders selected?</p>
      {/* {isMoreAction && ( */}
      <Checkbox
        label="Ignore forever"
        checked={checked}
        onChange={handleChange}
      />
      {/* )} */}
    </Modal>
  );
};
