import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { EXPORT_DESIGN_URL } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export default function ExportDesignURL({
  open,
  toggleShowModal,
  toggleActive,
  onNotificationChange,
  idsChecked,
  onCompleted,
}) {
  const [exportDesignURL, { loading }] = useMutation(EXPORT_DESIGN_URL, {
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
    onCompleted: (res) => {
      const url = res?.exportDesignURL;
      if (url?.length) {
        if (onNotificationChange) {
          onNotificationChange({
            message: "Export design urls successfully.",
            isError: false,
          });
        }
        if (typeof url === "string") {
          window.location.href = url;
        }
        if (onCompleted) {
          onCompleted();
        }
      }
    },
  });

  const handleSubmit = React.useCallback(() => {
    onNotificationChange &&
      onNotificationChange({
        message: null,
        isError: false,
      });

    toggleActive && toggleActive();

    exportDesignURL({
      variables: {
        taskIDs: idsChecked,
      },
    });
  }, [exportDesignURL, idsChecked, toggleActive, toggleShowModal]);

  return (
    <Modal
      title="Export Design URL"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to export design urls?</p>
    </Modal>
  );
}
