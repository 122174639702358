import React from "react";
import { OrdersPolaris } from "../../components/order/OrdersPolaris";
import { ORDER_FILTER_SELLER } from "../../constants";
import { getPathFromRouter } from "../../helper";
import { USER_ROLE } from "../../variable";

export const OrdersPagePolaris = (...props) => {
    const path = getPathFromRouter(props);
    return (
        <OrdersPolaris
            role={USER_ROLE.Seller}
            ordersFilter={ORDER_FILTER_SELLER}
            path={path}
        />
    );
};
