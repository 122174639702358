import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@shopify/polaris";
import { DuplicateMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
  padding: 5px 10px;
  border: 1px solid #c4cdd5;
  display: flex;
  border-radius: 3px;

  input {
    border: 0 none;
    flex-grow: 1;
    outline: 0 none;
    &:active,
    &:focus {
      outline: 0 none;
      border: 0 none;
    }
  }
`;
class CopyBox extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  render() {
    const { text, type } = this.props;
    return (
      <Container className={"copy-box"}>
        <input ref={this.inputRef} value={text} onChange={() =>{}} type={type || "text"}/>
        <Button
          title={"Copy to clipboard"}
          onClick={() => {
            this.inputRef.current.focus();
            this.inputRef.current.setSelectionRange(
              0,
              this.inputRef.current.value.length
            );
            document.execCommand("copy");
          }}
          plain
          icon={<Icon source={DuplicateMinor} />}
        />
      </Container>
    );
  }
}

CopyBox.propTypes = {
  text: PropTypes.string,
};

export default CopyBox;
