import React, { Component } from "react";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { Popconfirm, notification } from "antd";
import { EXPORT_TEMPLATE } from "./ListExportTemplates";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const REMOVE_EXPORT_FILE_TEMPLATE = gql`
  mutation deleteExportTemplate($id: ID!) {
    deleteExportTemplate(id: $id)
  }
`;
export default class ActionRemoveExportTemplate extends Component {
  state = {
    loading: false,
  };

  render() {
    const { id } = this.props;
    return (
      <Mutation mutation={REMOVE_EXPORT_FILE_TEMPLATE}>
        {(removeTemplate, { client }) => (
          <Popconfirm
            title="Are you sure remove this export file template?"
            onConfirm={() => {
              this.setState({ loading: true });
              removeTemplate({ variables: { id } })
                .then((res) => {
                  notification.success({
                    message: "Remove file template success.",
                  });
                  this.setState({ loading: false });

                  try {
                    const cache = client.readQuery({
                      query: EXPORT_TEMPLATE,
                      variables: { search: null },
                    });

                    client.writeQuery({
                      query: EXPORT_TEMPLATE,
                      variables: { search: null },
                      data: {
                        ...cache,
                        exportTemplates: cache.exportTemplates.filter(
                          (tem) => tem.id !== id
                        ),
                      },
                    });
                  } catch (_) {}
                })
                .catch((err) => {
                  notification.error({ message: err.toString() });
                  this.setState({ loading: false });
                });
            }}
          >
            <Button
              loading={this.state.loading}
              type="dashed"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </Mutation>
    );
  }
}
