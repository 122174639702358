import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useRef } from "react";
import { MARK_AWAITING_APPROVAL } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function DeleteNote({
    open,
    toggleShowModal,
    task,
    toggleActive,
    onNotificationChange,
    refetch,
}) {
    const taskId = task?.id;

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [awaitApproval, { loading }] = useMutation(MARK_AWAITING_APPROVAL, {
        onCompleted: () => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: `Delete note successfully.`,
                    isError: false,
                });
            }
            toggleShowModal && toggleShowModal();

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
    });

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        if (taskId) {
            toggleActive && toggleActive();
            if (onNotificationChange) {
                onNotificationChange({ message: null, isError: false });
            }

            const input = {
                taskId,
                isWaiting: true,
                note: "",
            };
            awaitApproval({
                variables: {
                    ...input,
                },
            });
        }
    }, [onNotificationChange, toggleActive, taskId, awaitApproval]);

    return (
        <Modal
            title={"Delete note for this task."}
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to delete note?</p>
        </Modal>
    );
}
