import React, { useState, useCallback } from "react";
import { Button, Popover, ActionList } from "@shopify/polaris";
import styled from "styled-components";

import { CLAIM_STATUS } from "../../../variable";
import { handleError } from "../../../helper";

import { ActionsPolaris } from "./actions/ActionsPolaris";
import { ResendPolaris } from "./actions/ResendPolaris";
import { UpdateTypePolaris } from "./actions/UpdateTypePolaris";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.8rem;
`;

export const ClaimsActionsPolaris = (props) => {
  // Prop
  const { claim, refetch, toggleActive, onNotificationChange } = props;
  const status = claim?.status;

  // State
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [active, setActive] = useState(false);

  // Hanlde action
  const toggleShowModal = useCallback((value) => {
    setShowModal((prev) => !prev);
    setCurrentAction(value);
  }, []);
  const toggleActiveMore = useCallback(() => setActive((prev) => !prev), []);

  // Markup
  const activator = (
    <Button children="More" size="slim" disclosure onClick={toggleActiveMore} />
  );

  console.log("status", status);
  console.log("CLAIM_STATUS.InReview", CLAIM_STATUS.InReview);

  return (
    <Container>
      {[
        CLAIM_STATUS.InReview,
        CLAIM_STATUS.Approved,
        CLAIM_STATUS.NeedMoreEvidence,
      ].includes(status) && (
        <Button
          children={`Change type`}
          size="slim"
          onClick={() => toggleShowModal("changeType")}
        />
      )}
      {[CLAIM_STATUS.InReview].includes(status) && (
        <React.Fragment>
          <Button
            children="Approve"
            size="slim"
            onClick={() => toggleShowModal("approve")}
          />
          <Button
            children="Reject"
            size="slim"
            destructive
            onClick={() => toggleShowModal("reject")}
          />
          <Popover
            active={active}
            activator={activator}
            onClose={toggleActiveMore}
            preferredAlignment={"right"}
          >
            <ActionList
              items={[
                {
                  content: "Resend",
                  onAction: () => toggleShowModal("resend"),
                },
                {
                  content: "Refund",
                  onAction: () => toggleShowModal("refund"),
                },
                {
                  content: "Need more evidence",
                  onAction: () => toggleShowModal("evidence"),
                },
              ]}
            />
          </Popover>
        </React.Fragment>
      )}
      <ActionsPolaris
        claim={claim}
        open={showModal && "approve" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
        status={CLAIM_STATUS.Approved}
      />
      <ActionsPolaris
        claim={claim}
        open={showModal && "reject" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
        status={CLAIM_STATUS.Rejected}
        isReject
      />
      <ActionsPolaris
        claim={claim}
        open={showModal && "refund" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
        status={CLAIM_STATUS.Refunded}
      />
      <ActionsPolaris
        claim={claim}
        open={showModal && "evidence" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
        status={CLAIM_STATUS.NeedMoreEvidence}
      />
      <ResendPolaris
        claim={claim}
        open={showModal && "resend" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
      />
      <UpdateTypePolaris
        claim={claim}
        open={showModal && "changeType" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
        handleError={handleError}
      />
    </Container>
  );
};
