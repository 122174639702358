import {
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  Collapsible,
  Form,
  FormLayout,
  Icon,
  InlineError,
  Loading,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import _, { get, reduceRight } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import {
  DESIGN_TASK_MENU,
  EXTRA_TASKS_KEYS,
  IDEA_TASK_MENU,
  TASK_MENU_BOTH,
} from "../../constants/task";
import { PRIVILEGES_KEYS } from "../../constants";
import { AppContext } from "../../context";
import {
  checkRole,
  handleError,
  isEmail,
  reducerFn,
  uniqueArr,
} from "../../helper";
import { TEAM_ROLE, USER_ROLE } from "../../variable";
import { MediaSelectorButtonPolaris } from "../file/MediaSelectorButtonPolaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { FieldExtraPolaris } from "./FieldExtraPolaris";

const OnlineDesignTeamsSelect = React.lazy(() =>
  import("./components/OnlineDesignTeamsSelect"),
);

const Container = styled.div`
  .Polaris-ChoiceList__Choices {
    display: flex;
    gap: 0 2rem;
    flex-wrap: wrap;
  }
  .wrap-icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .Polaris-FormLayout__Item {
    margin-top: 2.5rem;
  }
  .avatar-field {
    display: flex;
    flex-direction: column;
    .list-file_wrap {
      height: 120px;
      .file-item {
        max-width: 120px;
        .file_wrap {
          height: 100%;
        }
      }
    }
  }
  .Polaris-ButtonGroup {
    flex-direction: row-reverse;
  }
  .field-extra {
    margin-top: 1.5rem;
  }
  .change-password {
    padding: 0;
    .Polaris-FormLayout__Item {
      margin-top: 1.5rem;
      :first-child {
        margin-left: 0;
      }
    }
  }
`;

const EXTRA_MENUS = [
  {
    value: "products", // path in menu
    label: "Products",
  },
  { value: "orders", label: "Orders" },
  { value: "product-assortment", label: "Product Assortment" },
];

const SUPPORTER_EXTRA_MENUS = [
  { value: "products-product-push", label: "Push products" },
];

// Design Leader
const DL_EXTRA_MENUS = [
  ...EXTRA_MENUS,
  { value: "mapping", label: "Mapping order" },
];

const EXTRA_MENUS_ALL = {
  Supporter: SUPPORTER_EXTRA_MENUS,
  "Design Leader": DL_EXTRA_MENUS,
};

const ROLES_IDEA_DESIGNER = ["Idea", "Designer"];

export const MemberFormPolaris = ({
  btnName,
  onCompleted,
  loadingMutation,
  choices,
  loading,
  errorQuery,
  teamRoles,
  dataUserTeam,
  editMember,
  handleRedirect,
  hasActiveAcc,
}) => {
  const { currentUser } = useContext(AppContext);
  const {
    isMarketplaceManager,
    isIdeaLeader,
    isSeller: isAdminSeller,
    isDesignLeader,
    isOnline: isOnlineCtx, // design online team
  } = checkRole(currentUser);
  const designOnlineTeamID = get(currentUser, "teamUser.designOnlineTeamID");

  // State
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [address, setAddress] = useState(null);
  const [role, setRole] = useState(
    isDesignLeader && isOnlineCtx ? [TEAM_ROLE.Designer] : [],
  );
  const [avatar, setAvatar] = useState(null);
  const [iconView, setIconView] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [status, setStatus] = useState(["active"]);
  const [isOnline, setIsOnline] = useState(
    isDesignLeader && isOnlineCtx ? true : false,
  );
  const [extraMenus, setExtraMenus] = useState([]);
  const [ignoreTwoFA, setIgnoreTwoFA] = useState(false);
  const [ipWhitelist, setIpWhitelist] = useState("");
  const [privileges, setPrivileges] = useState([]);

  const [state, setState] = React.useReducer(reducerFn, {
    extraTasksMenu: ["design_idea_task"],
    viewWholeTeamReport: false,
  });

  const { isRootUser } = currentUser || {};
  const privilegesUser = _.get(currentUser, "privileges") || [];
  let canSetPrivileges =
    privilegesUser.includes("set_user_privilege") || isRootUser;
  const isLeader =
    isAdminSeller || isMarketplaceManager || isDesignLeader || isIdeaLeader;

  if ((role || []).includes(TEAM_ROLE.Designer)) {
    canSetPrivileges = false;
  }

  let hideSetUserPrivileges =
    isDesignLeader && (role || []).includes(TEAM_ROLE.DesignLeader);

  const showViewWholeTeamReport = [
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
  ].some((r) => role.includes(r));

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
    role: null,
  });

  useEffect(() => {
    if (isMarketplaceManager) {
      setRole([TEAM_ROLE.StoreManager]);
    } else if (isIdeaLeader) {
      setRole([TEAM_ROLE.Idea]);
    }
  }, [isMarketplaceManager, isIdeaLeader]);

  useEffect(() => {
    if (dataUserTeam) {
      if (dataUserTeam.user) {
        let user = dataUserTeam.user;
        let avatar = user.avatar ? [user.avatar] : [];
        let status = user.status;
        if (status) {
          status = "active";
        } else {
          status = "inActive";
        }
        setStatus(status ? [status] : ["active"]);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setPhone(user.phone);
        setAddress(user.address);
        setEmail(user.email);
        setAvatar(avatar);
        setIsOnline(user.isOnline);

        const { extraMenus, ignoreTwoFA, ipWhitelist, privileges } = user || {};
        let newExtraMenus = [];
        let newExtraTaskMenus = [];
        if (extraMenus?.length > 0) {
          newExtraMenus = extraMenus.filter(
            (i) => !EXTRA_TASKS_KEYS.includes(i),
          );
          newExtraMenus = uniqueArr(newExtraMenus);

          newExtraTaskMenus = extraMenus.filter((i) =>
            EXTRA_TASKS_KEYS.includes(i),
          );
          newExtraTaskMenus = uniqueArr(newExtraTaskMenus);
        }
        newExtraTaskMenus =
          newExtraTaskMenus?.length > 0
            ? newExtraTaskMenus
            : ["design_idea_task"];

        setExtraMenus(newExtraMenus);
        setState({ extraTasksMenu: newExtraTaskMenus });
        setIgnoreTwoFA(!!ignoreTwoFA);
        const ipWhiteL = (ipWhitelist || []).filter(Boolean).join("\n");
        setIpWhitelist(ipWhiteL);

        const newPrivileges = (privileges || []).filter(Boolean);
        setPrivileges(newPrivileges);

        if (
          newPrivileges?.length > 0 &&
          newPrivileges.includes(PRIVILEGES_KEYS.VWTR)
        ) {
          setState({ viewWholeTeamReport: true });
        }
      }
      setRole([dataUserTeam.role]);
      let storeIds = dataUserTeam.managerStores.map((ms) => ms.id);
      setStoreIds(storeIds);
    }
  }, [dataUserTeam]);

  const handleValueFirstName = (value) => {
    setErrors((prevState) => {
      let fn = null;
      if (!value) {
        fn = "Please enter first name.";
      }
      return {
        ...prevState,
        firstName: fn,
      };
    });
  };

  const handleValueLastName = (value) => {
    setErrors((prevState) => {
      let ln = null;
      if (!value) {
        ln = "Please enter last name.";
      }
      return {
        ...prevState,
        lastName: ln,
      };
    });
  };

  const handleValueEmail = (value) => {
    setErrors((prevState) => {
      let e = null;
      if (value) {
        if (!isEmail(value)) {
          e = "Email must be a valid email address.";
        }
      } else {
        e = "Please enter email.";
      }
      return {
        ...prevState,
        email: e,
      };
    });
  };

  const handleValuePassword = (value) => {
    setErrors((prevState) => {
      let pw = null;
      if (!value) {
        pw = "Please choose a password.";
      }
      return {
        ...prevState,
        password: pw,
      };
    });
  };

  const handleValueConfirmPassword = (value, newPassword) => {
    let newP = newPassword ? newPassword : password;
    setErrors((prevState) => {
      let cpw = null;
      if (!value) {
        cpw = "Please confirm your password.";
      } else {
        if (!_.isEqual(value, newP)) {
          cpw = "The two passwords that you entered do not match.";
        }
      }
      return {
        ...prevState,
        confirmPassword: cpw,
      };
    });
  };

  const handleValueRole = (value) => {
    setErrors((prevState) => {
      let r = null;
      if (!value) {
        r = true;
      }
      return {
        ...prevState,
        role: r,
      };
    });
  };

  const handleFirstNameChange = useCallback((value) => {
    handleValueFirstName(value);
    setFirstName(value);
  }, []);

  const handleLastNameChange = useCallback((value) => {
    handleValueLastName(value);
    setLastName(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    handleValueEmail(value);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback(
    (value) => {
      handleValuePassword(value);
      setPassword(value);
      handleValueConfirmPassword(confirmPassword, value);
    },
    [confirmPassword],
  );

  const handleConfirmPassword = useCallback(
    (value) => {
      handleValueConfirmPassword(value);
      setConfirmPassword(value);
    },
    [password],
  );

  const handleRoleChange = useCallback(
    (value) => {
      const newRole = _.head(value);
      handleValueRole(newRole);
      setRole(value);

      if (newRole === TEAM_ROLE.DesignLeader && isDesignLeader) {
        setIsOnline(true);
      }
    },
    [isDesignLeader],
  );

  const handleStatusChange = useCallback((value) => {
    setStatus(value);
  }, []);

  const handlePhoneChange = useCallback((value) => setPhone(value), []);
  const handleAddressChange = useCallback((value) => setAddress(value), []);
  const handleStoreIds = useCallback((value) => setStoreIds(value), []);

  const handleAvatarChange = useCallback(
    (value) => setAvatar(value && value.length ? value : []),
    [],
  );

  const handleCheckboxChange = useCallback((value) => setCheckbox(value), []);
  const handleIsOnlineChange = useCallback((value) => setIsOnline(value), []);
  const handleExtraMenuChange = useCallback(
    (value) => setExtraMenus(value),
    [],
  );

  const teamIdRef = React.useRef(null);
  const handleSubmit = useCallback(() => {
    let newRole = _.head(role);
    let newAvatar =
      avatar && avatar.length > 0
        ? _.head(
            avatar.map((a) => {
              if (typeof a === "object") {
                return a.id;
              }
              return a;
            }),
          )
        : "";

    let newStatus = _.head(status);
    if ("active" === newStatus) {
      newStatus = true;
    } else {
      newStatus = false;
    }

    handleValueRole(newRole);
    handleValueFirstName(firstName);
    handleValueLastName(lastName);
    handleValueEmail(email);
    handleValuePassword(password);
    handleValueConfirmPassword(confirmPassword);
    let count = _.size(_.filter(_.values(errors), (e) => e !== null));

    const ipWhiteL = (ipWhitelist || "")
      .split(/\,|\n/)
      .filter(Boolean)
      .map((i) => i?.trim())
      .slice(0, 3);

    let designOnlineTeamIDValue;
    const isDesign = [TEAM_ROLE.Designer, TEAM_ROLE.DesignLeader].includes(
      newRole,
    );
    if (isDesign && isOnline) {
      designOnlineTeamIDValue = teamIdRef.current?.getTeamId() || undefined;
    }

    let isOnlineValue = isDesign ? isOnline : undefined;
    let privilegesValue =
      canSetPrivileges && privileges.filter(Boolean).length > 0
        ? privileges
        : undefined;

    if (isDesignLeader) {
      if (isOnlineCtx) {
        isOnlineValue = true;
        designOnlineTeamIDValue = designOnlineTeamID;

        /**
         * current_user = Design Leader
         *  - new user role = Design Leader
         *  - isOnline
         */
      } else if (newRole === TEAM_ROLE.DesignLeader) {
        privilegesValue = ["create_user", "edit_user"];
        isOnlineValue = true;
      }
    }

    // add extra task to extra menu
    let newExtraMenus = [...extraMenus];
    if (state.extraTasksMenu.length > 0) {
      newExtraMenus.push(...state.extraTasksMenu);
    }
    newExtraMenus = uniqueArr(newExtraMenus);

    if (state.viewWholeTeamReport) {
      privilegesValue = [...(privilegesValue || []), PRIVILEGES_KEYS.VWTR];
    } else {
      privilegesValue = (privilegesValue || []).filter(
        (i) => i !== PRIVILEGES_KEYS.VWTR,
      );
    }


    privilegesValue = Array.from(new Set(privilegesValue).values());

    let input = {
      firstName,
      lastName,
      email,
      password,
      phone,
      address,
      ...(editMember ? { status: newStatus } : null),
      role: newRole,
      ...(storeIds.length ? { storeIds: storeIds } : null),
      avatar: newAvatar,
      isOnline: isOnlineValue,
      extraMenus: isAdminSeller ? newExtraMenus : undefined,
      ignoreTwoFA: isAdminSeller ? ignoreTwoFA : undefined,
      ipWhitelist: isLeader ? ipWhiteL : undefined,
      privileges: privilegesValue,
      designOnlineTeamID: designOnlineTeamIDValue,
    };

    if (editMember) {
      if (email && firstName && lastName && newRole && count === 0) {
        onCompleted(input);
      }
    } else {
      if (
        email &&
        password &&
        firstName &&
        lastName &&
        confirmPassword &&
        newRole &&
        count === 0
      ) {
        onCompleted(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    password,
    firstName,
    lastName,
    confirmPassword,
    phone,
    address,
    role,
    storeIds,
    avatar,
    status,
    isOnline,
    isOnlineCtx,
    isAdminSeller,
    extraMenus,
    ignoreTwoFA,
    ipWhitelist,
    isLeader,
    privileges,
    canSetPrivileges,
    designOnlineTeamID,
    state.extraTasksMenu,
    state.viewWholeTeamReport,
  ]);

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  const loadingMarkup = loading && <Loading />;

  const showExtraMenu = role.some((i) =>
    [
      TEAM_ROLE.Idea,
      TEAM_ROLE.IdeaLeader,
      TEAM_ROLE.Designer,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.Supporter,
    ].includes(i),
  );

  const isMarketOrStore = role.some((i) =>
    [TEAM_ROLE.MarketplaceManager, TEAM_ROLE.StoreManager].includes(i),
  );

  const rolesOptions = useMemo(() => {
    if (isMarketplaceManager) return [];
    if (isIdeaLeader)
      return choices.filter((opt) => ROLES_IDEA_DESIGNER.includes(opt.value));

    if (isDesignLeader) {
      if (isOnlineCtx) return filterRole(choices, [TEAM_ROLE.Designer], false);

      return filterRole(
        choices,
        [TEAM_ROLE.Designer, TEAM_ROLE.DesignLeader],
        false,
      );
    }
    return choices;
  }, [
    choices,
    isMarketplaceManager,
    isIdeaLeader,
    isDesignLeader,
    isOnlineCtx,
  ]);

  useEffect(() => {
    const doms = document.querySelectorAll(".wrap-icon");

    for (let dom of doms) {
      const btn = dom.querySelector(".Polaris-Button");
      btn.setAttribute("tabIndex", "-1");
    }
  }, []);

  const [fRole] = role || [];
  const MERGER_EXTRA_MENU = React.useMemo(() => {
    const val = EXTRA_MENUS_ALL[fRole];
    return val || EXTRA_MENUS;
  }, [fRole]);

  // case: new_role is design_leader
  // config can access to design-task, idea-task or both
  // default: both
  const newRoleIsDL = isRole(role, [
    TEAM_ROLE.DesignLeader,
    TEAM_ROLE.Designer,
  ]);
  const handleExtraTaskMenuChange = React.useCallback((newValues) => {
    const len = newValues.length;
    setState((prev) => {
      const bothIndex = newValues.findIndex((i) => i === TASK_MENU_BOTH);
      // last index => push to arr => remove other
      if (bothIndex === len - 1) {
        newValues = [TASK_MENU_BOTH];
      } else if (len > 1 && bothIndex > -1) {
        newValues.splice(bothIndex, 1);
      }

      return {
        ...prev,
        extraTasksMenu: newValues,
      };
    });
  }, []);

  const handleViewWholeTeamReport = React.useCallback((value) => {
    setState({ viewWholeTeamReport: value });
  }, []);

  return (
    <Container>
      {loadingMarkup}
      {/* {toastMarkup} */}
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <ComponentLabelPolaris label="First name" required />
              <TextField
                // label="First Name(*)"
                type="text"
                onChange={handleFirstNameChange}
                value={firstName}
                placeholder="First name"
                error={errors.firstName}
              />
            </div>
            <div>
              <ComponentLabelPolaris label="Last name" required />
              <TextField
                // label="Last Name(*)"
                type="text"
                onChange={handleLastNameChange}
                value={lastName}
                placeholder="Last name"
                error={errors.lastName}
              />
            </div>
          </FormLayout.Group>
          <div>
            <ComponentLabelPolaris label="Email" required />
            <TextField
              value={email}
              onChange={handleEmailChange}
              // label="Email(*)"
              type="email"
              placeholder="Email"
              error={errors.email}
            />
          </div>
          {editMember ? (
            <div className="change-password">
              <Checkbox
                label="Change Password"
                checked={checkbox}
                onChange={handleCheckboxChange}
              />
              <Collapsible
                open={checkbox}
                id="basic-collapsible"
                transition={{
                  duration: "150ms",
                  timingFunction: "ease",
                }}
              >
                <FormLayout.Group>
                  <div>
                    <ComponentLabelPolaris label="New password" required />
                    <TextField
                      value={password}
                      onChange={handlePasswordChange}
                      // label="New Password(*)"
                      type={!iconView ? "password" : "text"}
                      placeholder="***************"
                      suffix={iconViewMarkup}
                      error={errors.password}
                    />
                  </div>
                  <div>
                    <ComponentLabelPolaris
                      label="Confirm new password"
                      required
                    />
                    <TextField
                      value={confirmPassword}
                      onChange={handleConfirmPassword}
                      // label="Confirm New Password(*)"
                      type={!iconView ? "password" : "text"}
                      placeholder="***************"
                      suffix={iconViewMarkup}
                      error={errors.confirmPassword}
                    />
                  </div>
                </FormLayout.Group>
              </Collapsible>
            </div>
          ) : (
            <FormLayout.Group>
              <div>
                <ComponentLabelPolaris label="Password" required />
                <TextField
                  value={password}
                  onChange={handlePasswordChange}
                  // label="Password(*)"
                  type={!iconView ? "password" : "text"}
                  placeholder="***************"
                  suffix={iconViewMarkup}
                  error={errors.password}
                />
              </div>
              <div>
                <ComponentLabelPolaris label="Confirm password" required />
                <TextField
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  // label="Confirm Password(*)"
                  type={!iconView ? "password" : "text"}
                  placeholder="***************"
                  suffix={iconViewMarkup}
                  error={errors.confirmPassword}
                />
              </div>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <TextField
              value={phone}
              onChange={handlePhoneChange}
              label="Phone"
              placeholder="Phone"
            />
            <TextField
              value={address}
              onChange={handleAddressChange}
              label="Address"
              placeholder="Address"
            />
          </FormLayout.Group>
          {errorQuery ? (
            <div>Error:{handleError(errorQuery.toString())}</div>
          ) : (
            <>
              <div>
                <ComponentLabelPolaris label="Role" required />
                <ChoiceList
                  // title="Role(*)"
                  selected={role}
                  titleHidden={isMarketplaceManager}
                  choices={rolesOptions}
                  onChange={handleRoleChange}
                />
              </div>

              {errors.role && (
                <InlineError message="Please select role." fieldID="roleId" />
              )}

              <div className="field-extra">
                {!isOnlineCtx && (
                  <>
                    {role?.length > 0 &&
                      [TEAM_ROLE.Designer].includes(_.head(role)) && (
                        <Checkbox
                          label="Is online designer?"
                          checked={isOnline}
                          onChange={handleIsOnlineChange}
                        />
                      )}
                    <Collapsible
                      open={isOnline}
                      id="is-online-collapsible"
                      transition={{
                        duration: "150ms",
                        timingFunction: "ease",
                      }}
                    >
                      <React.Suspense fallback={<Spinner size="small" />}>
                        <OnlineDesignTeamsSelect ref={teamIdRef} />
                      </React.Suspense>
                    </Collapsible>
                  </>
                )}
                {[USER_ROLE.StoreManager, TEAM_ROLE.Supporter].includes(
                  _.head(role),
                ) && (
                  <FieldExtraPolaris
                    data={teamRoles}
                    role={role}
                    storeIds={storeIds}
                    isMarketplaceManager={isMarketplaceManager}
                    label={
                      USER_ROLE.StoreManager === _.head(role)
                        ? "Manager Stores"
                        : "Supporter Stores"
                    }
                    onChange={(value) => handleStoreIds(value)}
                  />
                )}
              </div>
            </>
          )}
          {isAdminSeller && showExtraMenu && (
            <ChoiceList
              allowMultiple
              choices={MERGER_EXTRA_MENU}
              selected={extraMenus}
              title="Extra Menus"
              onChange={handleExtraMenuChange}
            />
          )}
          {isAdminSeller && (newRoleIsDL || isMarketOrStore) && (
            <ChoiceList
              allowMultiple
              choices={
                isMarketOrStore
                  ? [{ value: IDEA_TASK_MENU, label: "Idea Task" }]
                  : EXTRA_TASKS_MENU
              }
              selected={state.extraTasksMenu}
              title="Access Tasks Menu"
              onChange={handleExtraTaskMenuChange}
            />
          )}
          {editMember && hasActiveAcc && (
            <ChoiceList
              title="Status"
              selected={status}
              onChange={handleStatusChange}
              choices={[
                {
                  label: "Active",
                  value: "active",
                },
                { label: "Inactive", value: "inActive" },
              ]}
            />
          )}
          {isAdminSeller ? (
            <Checkbox
              label="Ignore Two-Factor Authentication (2FA)"
              checked={!!ignoreTwoFA}
              onChange={setIgnoreTwoFA}
            />
          ) : null}

          {canSetPrivileges ? (
            <div>
              <ChoiceList
                choices={[
                  { value: "create_user", label: "Create user" },
                  { value: "edit_user", label: "Edit user" },
                  {
                    value: "active_deactive_user",
                    label: "Active/Deactive user",
                  },
                  !isOnlineCtx &&
                    !hideSetUserPrivileges && {
                      value: "set_user_privilege",
                      label: "Set user privilege",
                    },
                ].filter(Boolean)}
                selected={privileges}
                title="Privileges"
                onChange={setPrivileges}
                allowMultiple
              />
            </div>
          ) : null}

          {showViewWholeTeamReport ? (
            <Checkbox
              label="View whole team report"
              checked={state.viewWholeTeamReport}
              onChange={handleViewWholeTeamReport}
            />
          ) : null}
          {isLeader ? (
            <TextField
              multiline={3}
              type="text"
              value={ipWhitelist}
              onChange={setIpWhitelist}
              label="IP Whitelist"
              helpText="Only accept maximum 3 ip address"
            />
          ) : null}
          <div className="avatar-field">
            <label>Avatar</label>
            {editMember ? (
              <>
                {avatar ? (
                  <MediaSelectorButtonPolaris
                    accept={"image/*"}
                    singleUpload={true}
                    multiple={false}
                    onChange={(value) => handleAvatarChange(value)}
                    value={avatar}
                    sizeSmall
                  />
                ) : null}
              </>
            ) : (
              <MediaSelectorButtonPolaris
                sizeSmall
                accept={"image/*"}
                singleUpload={true}
                multiple={false}
                onChange={(value) => handleAvatarChange(value)}
              />
            )}
          </div>
          <ButtonGroup>
            <Button primary submit loading={loadingMutation}>
              {btnName ? btnName : "Save"}
            </Button>
            <Button onClick={handleRedirect}>Cancel</Button>
          </ButtonGroup>
        </FormLayout>
      </Form>
    </Container>
  );
};

function filterRole(opts, roles, disabled) {
  return opts
    .filter((opt) => roles.includes(opt.value))
    .map((i) => ({ ...i, disabled }));
}

function isRole(roles, mapRoles) {
  return (
    roles?.length > 0 && mapRoles?.length > 0 && mapRoles.includes(roles[0])
  );
}

const EXTRA_TASKS_MENU = [
  { value: TASK_MENU_BOTH, label: "Both" },
  { value: DESIGN_TASK_MENU, label: "Design Task" },
  { value: IDEA_TASK_MENU, label: "Idea Task" },
];
