import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM } from "../../graphql/queries";
import {
  getParamByRole,
  isAccAThien,
  isAccThuong,
  isPME_TEAM,
} from "../../helper";
import history from "../../history";
import { usePrivileges } from "../../hooks/usePrivileges";
import { MemberFormPolaris } from "./MemberFormPolaris";

export const UPDATE_USER_TEAM = gql`
  mutation updateUserTeam($input: EditUserTeam!) {
    updateUserTeam(input: $input) {
      id
      role
      team {
        id
      }
      user {
        id
        firstName
        lastName
        roles
        email
        phone
        address
        ignoreTwoFA
        ipWhitelist
        avatar {
          id
          url
          thumbnailUrl
        }
        status
      }
    }
  }
`;

export const LOAD_QUERY = gql`
  query loadData($id: ID!) {
    userTeamByID(id: $id) {
      id
      user {
        lastName
        firstName
        email
        phone
        address
        isOnline
        extraMenus
        ignoreTwoFA
        ipWhitelist
        privileges
        avatar {
          id
          url
          thumbnailUrl
        }
        status
      }
      role
      managerStores {
        id
        title
      }
    }
    teamRoles
    stores(filter: { limit: 5000, offset: 0 }) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const EditMemberPolaris = (props) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const { id } = props.match.params;
  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const { data: dataT } = useQuery(
    GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM,
    {
      onError: () => {},
    }
  );

  const users = dataT?.getUserHasPermissionAcceptAccessInTeam || [];
  const ids = users.map(({ id }) => id);
  let hasActiveAcc = ids.includes(currentUser?.id);

  const { data, loading, error, client } = useQuery(LOAD_QUERY, {
    variables: {
      id: id,
    },
    onError: () => {},
    fetchPolicy: "no-cache",
  });

  const [
    updateUserTeam,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(UPDATE_USER_TEAM, {
    onCompleted: () => {
      const id = setTimeout(() => {
        handleRedirect();
      }, 2500);
      setTimeoutId(id);
    },
    onError: () => {},
  });

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push(`/${currentParam}/team-members`);
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataUserTeam = data && data.userTeamByID;
  const choices =
    data && data.teamRoles && data.teamRoles.length
      ? _.map(data.teamRoles, (v) => {
          return {
            value: v,
            label: v,
          };
        })
      : // .filter(({ value }) => value !== "Supplier")
        [];

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleCompleted = useCallback((input) => {
    updateUserTeam({
      variables: {
        input: {
          id,
          ...input,
        },
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess =
    dataMutation && dataMutation.updateUserTeam && "Edit user success";
  let newError;
  if (errorMutation) {
    if (errorMutation.toString().includes("uix_users_email")) {
      newError = "Email name already in use, please try another name.";
    } else {
      newError = "Something wrong! Please try again.";
    }
  }

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const isPMETeam = isPME_TEAM(currentUser);
  const isAThien = isAccAThien(currentUser);
  const isThuong = isAccThuong(currentUser);

  const { canEdit } = usePrivileges();
  if (!canEdit && isPMETeam) return null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Edit Member"
        breadcrumbs={[
          {
            content: "Members",
            url: `/${currentParam}/team-members`,
          },
        ]}
      >
        <Card sectioned>
          <MemberFormPolaris
            onCompleted={(input) => handleCompleted(input)}
            dataUserTeam={dataUserTeam}
            loading={loading}
            errorQuery={error}
            choices={choices}
            teamRoles={data}
            editMember
            errorMutation={errorMutation}
            loadingMutation={loadingMutation}
            toastSuccess={toastSuccess}
            handleRedirect={handleRedirect}
            hasActiveAcc={hasActiveAcc}
          />
        </Card>
      </Page>
    </Container>
  );
};
