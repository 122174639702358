import React from "react";
import { DataTable, Link, Badge } from "@shopify/polaris";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";

import { PaginationPolaris } from "../shared/PaginationPolaris";
import { ProductCrawlerActionsPolaris } from "./ProductCrawlerActionsPolaris";

const Container = styled.div`
  overflow: hidden;
  .info-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .title-wrap {
    width: 200px;
  }
  .date-wrap {
    width: 150px;
    white-space: break-spaces;
  }
`;

export const TableProductCrawlersPolaris = ({ data, filter, setFilter }) => {
  const columnContent = ["text", "text", "text", "text", "numeric"];
  const headings = ["Name", "Created Date", "Status", "Info", "Actions"];

  const isCompleted = (record) => {
    const info = record.info;
    if (info.completedCount + info.errorCount === info.total) {
      return true;
    }
    return false;
  };

  const rows = data
    ? data.productCrawlers.nodes.map((p) => {
        let statusMarkup = null;
        if ("Stopped" === p.status) {
          statusMarkup = <Badge status="critical">Stopped</Badge>;
        } else if ("Running" === p.status) {
          statusMarkup = <Badge status="attention">Running</Badge>;
        } else {
          if (isCompleted(p)) {
            statusMarkup = <Badge status="success">Done</Badge>;
          } else if (p.info.runningCount > 0) {
            statusMarkup = <Badge status="attention">Running</Badge>;
          } else {
            statusMarkup = <Badge>Pending</Badge>;
          }
        }
        const infoMarkup = (
          <div className="info-wrap">
            <span>Total URLS: {_.get(p, "info.total")}</span>
            <span>Completed URLS: {_.get(p, "info.completedCount")}</span>
            <span>Error URLS: {_.get(p, "info.errorCount")}</span>
          </div>
        );

        return [
          <div className="title-wrap">
            <Link url={`/tools/product-crawlers/${p.id}`}>{p.title}</Link>
          </div>,
          <div className="date-wrap">{moment(p.createdAt).format("LLL")}</div>,
          statusMarkup,
          infoMarkup,
          <ProductCrawlerActionsPolaris
            value={p}
            isCompleted={isCompleted(p)}
          />,
        ];
      })
    : [];

  let { limit, offset } = filter;
  const total = _.get(data, "productCrawlers.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={columnContent}
        headings={headings}
        rows={rows}
        hideScrollIndicator
        verticalAlign="middle"
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
