import React from "react";
import { Page } from "@shopify/polaris";
import { WrapperPage } from "../../components/shared/WrapperPage";
import { PrivateFeatures } from "../../components/admin/private-features";
import { getPathFromRouter } from "../../helper";

export function PrivateFeaturesPage(...props) {
  const path = React.useMemo(() => {
    return getPathFromRouter(props);
  }, [props]);

  return (
    <WrapperPage>
      <Page title="Private Features" fullWidth>
        <PrivateFeatures path={path} />
      </Page>
    </WrapperPage>
  );
}
