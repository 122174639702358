import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const REMOVE_EXPORT_FILE_TEMPLATE = gql`
  mutation deleteExportTemplate($id: ID!) {
    deleteExportTemplate(id: $id)
  }
`;

export const ExportTemplateRemoveActionPolaris = ({
  item,
  open,
  toggleShowModal,
  onNotificationChange,
  toggleActive,
  refetch,
}) => {
  const [deleteTemplate, { loading }] = useMutation(
    REMOVE_EXPORT_FILE_TEMPLATE,
    {
      onCompleted: () => {
        toggleShowModal();
        if (onNotificationChange) {
          onNotificationChange({
            message: "Delete file template successfuly.",
            isError: false,
          });
        }
        if (refetch) {
          refetch();
        }
      },
      onError: (error) => {
        if (onNotificationChange) {
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
        }
      },
    }
  );

  // Handle actions
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (item?.id) {
      deleteTemplate({
        variables: {
          id: item?.id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  return (
    <Modal
      title="Delete template"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure remove this export file template?</p>
    </Modal>
  );
};
