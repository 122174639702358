import React, { useCallback, useState, useEffect } from "react";
import { Page, Loading, Toast } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import styled from "styled-components";
import _ from "lodash";

import { USER_ROLE } from "../../variable";
import { handleError } from "../../helper";
import history from "../../history";
import { AddDepositFormPolaris } from "./AddDepositFormPolaris";
import { LOAD_SELLER_DEPOSIT_QUERY } from "./DepositsPolaris";

const SUPPLIER = gql`
  query listUserTeamByRole($role: String!) {
    listUserTeamByRole(role: $role) {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const ADD_DEPOSIT = gql`
  mutation createDeposit($input: NewDeposit!) {
    createDeposit(input: $input) {
      id
      amount
      journal {
        refId
        type
        comment
        status
      }
      account {
        firstName
        lastName
      }
      creator {
        firstName
        lastName
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddDepositPagePolaris = () => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [created, setCreated] = useState(null);
  const { data, loading, erorr } = useQuery(SUPPLIER, {
    variables: {
      role: USER_ROLE.Supplier,
    },
  });

  const [
    createDeposit,
    { loading: loadingMutation, error: errorMutation, client },
  ] = useMutation(ADD_DEPOSIT, {
    onError: () => {},
    onCompleted: (res) => {
      setCreated(true);
      const id = setTimeout(() => {
        handleRedirect();
      }, 2500);
      setTimeoutId(id);
      const data = res.data.createDeposit;

      const filter = {
        limit: 30,
        offset: 0,
        accountId: null,
        status: null,
        search: null,
        kind: ["Deposit"],
      };

      const cache = client.readQuery({
        query: LOAD_SELLER_DEPOSIT_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_SELLER_DEPOSIT_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          transactions: {
            ...(cache.transactions.total + 1),
            nodes: [data, ...cache.transactions.nodes],
          },
        },
      });
    },
  });

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push("/seller/billings/deposits");
  }, []);

  const suppliers =
    data && data.listUserTeamByRole && data.listUserTeamByRole.length
      ? data.listUserTeamByRole.map(({ user }) => user)
      : [];

  const handleSubmit = useCallback((value) => {
    if (value) {
      createDeposit({
        variables: {
          input: value,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  let newError =
    errorMutation && !_.includes(errorMutation.toString(), "createDeposit")
      ? errorMutation
      : null;

  const toastMarkup = activeToast
    ? (newError || created) && (
        <Toast
          content={
            newError
              ? handleError(newError.toString())
              : created && "Add deposit success."
          }
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Add Deposit"
        breadcrumbs={[
          { content: "Billings", url: "/seller/billings/deposits" },
        ]}
      >
        <AddDepositFormPolaris
          suppliers={suppliers}
          onSubmit={handleSubmit}
          erorr={erorr}
          loading={loadingMutation}
          handleRedirect={handleRedirect}
        />
      </Page>
    </Container>
  );
};
