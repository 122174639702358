import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import {
  CancelSmallMinor,
  EditMajorMonotone,
  TickMinor,
} from "@shopify/polaris-icons";
import { get } from "lodash";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { AppContext } from "../../../context";
import { REJECT_DESIGN } from "../../../graphql/mutations";
import {
  GET_ORDER_DESIGNS,
  GET_TASK_SUBMISSION,
} from "../../../graphql/queries";
import { checkRole, elementContains, handleError } from "../../../helper";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import useToggle from "../../../hooks/useToggle";
import { ACCS_HAVE_COLOR_PICKER, TEAM_ROLE } from "../../../variable";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { useToastContext } from "../../shared/ToastContext";
import { ThumbnailFileProvider } from "../context/ThumbnailFileContext";
import { AcceptDesignPolaris } from "./AcceptDesignPolaris";
import { CompareDesignTabsPolaris } from "./CompareDesignTabsPolaris";
import { RejectDesignPolaris } from "./RejectDesignPolaris";
import { ThumbnailFileControl } from "./ThumbnailFile";

const MockupsPreview = React.lazy(() => import("../components/MockupsPreview"));
const AttributesComp = React.lazy(() => import("../components/AttributesComp"));
const RequirementComp = React.lazy(() =>
  import("../components/RequirementComp"),
);
const PersonalizedInfo = React.lazy(() =>
  import("../../shared/PersonalizedInfo"),
);

const BASE_IDS_SHOW_TITLE = [
  "JzoMUIr9Q2",
  "xkzrz5V6iQ",
  "hDveujJaaN",
  "8lYBUNZwZv",
  "psjAGaSu5X",
  "nZ4xPgu1El",
  "9oeNm8Y4NW",
];

const rejectResourceStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 7,
  marginTop: 10,
  maxWidth: "50%",
  overflow: "hidden",
};

export const CompareDesignPolaris = (props) => {
  // Props
  const {
    task,
    status,
    open,
    toggleShowModal,
    modalTitle,
    isViewRejectedDesign,
    hasCompareDesign,
    refetch,
    toggleActive,
    onNotificationChange,
    hasAcceptDesign,
    hasRejectDesign,
  } = props;
  // Block body scroll
  useLockBodyScroll(open);

  const { currentUser } = useContext(AppContext);
  const { isOnline: isDesignOnline } = checkRole(currentUser);
  const teamRole = currentUser?.teamUser?.role;
  const isDLead = [TEAM_ROLE.DesignLeader].includes(teamRole);
  const userId = currentUser?.id;
  const userSpecial = ACCS_HAVE_COLOR_PICKER.includes(userId);

  const orderId = task?.orderId;
  const taskId = task?.id;
  const personalized = task?.personalized;

  // Improve view order design
  const order = task?.order;
  const productBaseId = order?.productVariant?.productBaseVariant?.productBase
    ? order.productVariant.productBaseVariant.productBase.id
    : order?.product?.productBases[0]?.id;

  // Queries
  const { data, loading, error } = useQuery(GET_TASK_SUBMISSION, {
    variables: {
      taskId,
      status,
    },
    fetchPolicy: "cache-and-network",
    skip: !taskId || !open,
  });

  const { data: dataOrder } = useQuery(GET_ORDER_DESIGNS, {
    variables: {
      id: order?.id,
      productBaseId,
    },
    skip: "Done" !== status,
  });

  // Markup
  useEffect(() => {
    let domCD = document.querySelector("#compare-design_wrap");
    let domParents = document.querySelectorAll(".Polaris-Modal-Dialog__Modal");
    for (let domParent of domParents) {
      if (elementContains(domParent, domCD)) {
        domParent.setAttribute(
          "style",
          "max-width: calc(100% - 10rem); height: calc(100vh)",
        );
      }
    }
  });

  let designs = error
    ? get(dataOrder, "getOrderDesigns.orderDesigns", [])
    : get(data, "getTaskSubmission.designs", []);
  const index = designs.findIndex(
    (d) => get(d, "designPosition.name", "").toLowerCase() === "master",
  );
  if (index > 0) {
    [designs[0], designs[index]] = [designs[index], designs[0]];
  }

  const rejectReason = get(data, "getTaskSubmission.rejectReason", null);
  const rejectResources = get(data, "getTaskSubmission.rejectResources", []);
  const rejectResourcesUrl = (rejectResources || []).map(
    ({ url, thumbnailUrl }) => url || thumbnailUrl,
  );

  // Configured by
  const configByUser = get(data, "getTaskSubmission.configByUser", {});
  const { firstName, lastName } = configByUser || {};
  const userConfigDesign = [firstName, lastName].filter(Boolean).join(" ");

  // Main image
  const productInOrder = get(order, "product", null);
  const mainImageId = productInOrder?.mainImageId;
  const images = productInOrder?.images;
  let mainImages = [];
  const isCampaign = productInOrder?.productBases?.length > 1;
  const haveMainImageId = mainImageId || mainImageId !== "undefined";

  if (productBaseId && isCampaign) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.productBaseId === productBaseId)
            .map((item) => item.file)
        : [];
    if (mainImages.length > 1 && haveMainImageId) {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.file?.id === mainImageId)
              .map((item) => item.file)
          : [];
    }
  } else if (haveMainImageId) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.file?.id === mainImageId)
            .map((item) => item.file)
        : [];
  }
  if (mainImages.length === 0) {
    mainImages =
      images?.length > 0 ? images.filter(Boolean).map((item) => item.file) : [];
  }

  // Attributes
  const attributes =
    order?.productVariant?.productBaseVariant?.attributes?.length > 0
      ? order.productVariant.productBaseVariant.attributes
      : [];

  // Task requirement
  const taskRequirement = get(task, "requirement", null);

  // let attributeMarkup;
  // const showAttributes = BASE_IDS_SHOW_ATTRIBUTES.includes(productBaseId);
  // if (Array.isArray(attributes) && attributes.length) {
  //   attributeMarkup = attributes.map((att, index) => {
  //     return (
  //       <React.Fragment key={index}>
  //         <div className="attribute-item" key={index}>
  //           <TextStyle variation="strong">{att.name}: </TextStyle>
  //           <span>{att.option}</span>
  //         </div>
  //       </React.Fragment>
  //     );
  //   });
  // }

  // Show product base title - Dreamship.
  const productBase = get(
    order,
    "productVariant.productBaseVariant.productBase",
    {},
  );
  const baseSlug = get(productBase, "slug", "");
  const baseTitle = get(productBase, "title", "");
  const baseId = get(productBase, "id", null);
  const dreamshipPT = /dreamship/i;
  const showBaseTitle =
    BASE_IDS_SHOW_TITLE.includes(baseId) ||
    dreamshipPT.test(baseSlug) ||
    dreamshipPT.test(baseTitle);

  return (
    <Modal
      title={modalTitle}
      open={open}
      onClose={toggleShowModal}
      sectioned
      large
    >
      {loading ? (
        <SkeletonPagePolaris />
      ) : (
        <div>
          <Container id="compare-design_wrap" isDLead={isDLead}>
            <div className="order-wrap">
              <div className="order-id">
                <span>
                  <TextStyle variation="strong">Order ID: </TextStyle>
                  <span>{task?.order?.id}</span>
                </span>
                <span>
                  <TextStyle variation="strong">Order Origin ID: </TextStyle>
                  <span>{task?.order?.originId}</span>
                </span>
              </div>
              <SuspenseComp>
                <PersonalizedInfo
                  orderId={orderId}
                  personalized={personalized}
                />
                <AttributesComp order={order} />
                <RequirementComp taskRequirement={taskRequirement} />
              </SuspenseComp>
              <div className="mockup_wrap">
                <Heading element="h3">Mockup</Heading>
                {/* <ModalImageClaimPolaris
                  width="auto"
                  maxWidth="100%"
                  height="auto"
                  files={{ files: mainImages }}
                  limit={1}
                  hasShadow
                  isCompare
                /> */}
                <SuspenseComp>
                  <MockupsPreview
                    width="auto"
                    maxWidth="100%"
                    height="auto"
                    files={{ files: mainImages }}
                    originImages={images}
                    hasShadow
                    isCompare
                  />
                </SuspenseComp>
              </div>
            </div>

            <div className="design_wrap">
              <ThumbnailFileProvider>
                <div className="heading-action-wrap">
                  <div className="heading-wrap">
                    <Heading element="h3">Designs</Heading>
                    <ThumbnailFileControl />
                  </div>
                  {!isDesignOnline && (hasAcceptDesign || hasRejectDesign) ? (
                    <div className="action-wrap">
                      <AcceptOrReject
                        taskId={taskId}
                        orderId={orderId}
                        refetch={refetch}
                        toggleModalParent={toggleShowModal}
                        onNotificationChange={onNotificationChange}
                        toggleActive={toggleActive}
                      />
                    </div>
                  ) : null}
                </div>
                {showBaseTitle && (
                  <div className="product-title">
                    <h3
                      className="Polaris-Heading"
                      style={{
                        display: "inline-block",
                        marginRight: "1rem",
                        marginTop: "1.6rem",
                      }}
                      children="Product Base: "
                    />
                    <span data-base-id={baseId}>{baseTitle}</span>
                  </div>
                )}
                {/* {showAttributes && (
                  <div className="attributes-wrap">
                    <Heading element="h3">Attributes:</Heading>
                    {attributeMarkup}
                  </div>
                )} */}
                {(isDLead || userSpecial) && (
                  <div style={{ paddingTop: "11rem" }} />
                )}
                <CompareDesignTabsPolaris
                  designs={designs}
                  orderId={orderId}
                  taskId={taskId}
                  hasCompareDesign={hasCompareDesign}
                  refetch={refetch}
                  onNotificationChange={onNotificationChange}
                  toggleActive={toggleActive}
                  hasAcceptDesign={hasAcceptDesign}
                  hasRejectDesign={hasRejectDesign}
                  toggleModalParent={toggleShowModal}
                  attributes={attributes}
                  taskRequirement={taskRequirement}
                  isDLead={isDLead || userSpecial}
                  userConfigDesign={userConfigDesign}
                  isViewRejectedDesign={isViewRejectedDesign}
                  isDesignOnline={isDesignOnline}
                />
              </ThumbnailFileProvider>

              {/* {!isDesignOnline &&
              designLeaderIds &&
              (hasAcceptDesign || hasRejectDesign) ? (
              <AcceptOrReject
                taskId={taskId}
                orderId={orderId}
                refetch={refetch}
                toggleModalParent={toggleShowModal}
                onNotificationChange={onNotificationChange}
                toggleActive={toggleActive}
              />
              ) : null} */}
            </div>
          </Container>
          {isViewRejectedDesign && (
            <>
              <div style={{ marginTop: "1.6rem", width: "50%" }}>
                <h3 style={{ fontWeight: 500, fontSize: "1.1em" }}>
                  Reject reason:
                </h3>
                {/* <span>{rejectReason}</span> */}
                <RejectComp rejectReason={rejectReason} value={task} />
              </div>
              {rejectResourcesUrl?.length > 0 ? (
                <div style={{ marginTop: "1.6rem" }}>
                  <h3 style={{ fontWeight: 500, fontSize: "1.1em" }}>
                    Reject resource:
                  </h3>
                  <div style={rejectResourceStyle}>
                    {rejectResourcesUrl.map((url) => {
                      const [prefix] = (url || "").split(/\?/);
                      return (
                        <a target="_blank" href={url}>
                          {prefix}
                        </a>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

function AcceptOrReject({
  taskId,
  orderId,
  refetch,
  toggleModalParent,
  onNotificationChange,
  toggleActive,
}) {
  const [open, toggleOpen] = useToggle(false);
  const [openReject, toggleOpenReject] = useToggle(false);

  const task = React.useMemo(
    () => ({ id: taskId, orderId }),
    [taskId, orderId],
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ButtonGroup>
        <Button children="Accept Design" onClick={toggleOpen} />
        <Button
          children="Reject Design"
          onClick={toggleOpenReject}
          destructive
        />
      </ButtonGroup>
      <AcceptDesignPolaris
        open={open}
        toggleShowModal={toggleOpen}
        task={task}
        refetch={refetch}
        toggleModalParent={toggleModalParent}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
      />
      <RejectDesignPolaris
        open={openReject}
        toggleShowModal={toggleOpenReject}
        task={task}
        refetch={refetch}
        toggleModalParent={toggleModalParent}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
      />
    </div>
  );
}

function RejectComp({ rejectReason, value }) {
  const [reason, setReason] = React.useState(rejectReason || "");
  const [open, toggleOpen] = useToggle(false);

  // Context
  const { setFilter, toggleToast, setNotify, refetch } = useToastContext();

  // Mutation
  const onCloseRef = React.useRef(null);
  const [rejectDesign, { loading }] = useMutation(REJECT_DESIGN, {
    onCompleted: () => {
      setNotify &&
        setNotify({
          msg: `Reject design success.`,
          err: false,
        });

      toggleOpen();
      // onClose parent modal
      onCloseRef.current && clearTimeout(onCloseRef.current);
      onCloseRef.current = setTimeout(() => {
        setFilter && setFilter((prev) => ({ ...prev }));
        refetch && refetch();
      }, 1000);
    },
    onError: (error) => {
      setNotify &&
        setNotify({
          msg: handleError(error.toString()),
          err: true,
        });
    },
  });

  const id = value?.id;
  const handleSubmit = React.useCallback(() => {
    if (!id) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    rejectDesign({
      variables: {
        input: {
          id,
          rejectReason: reason,
        },
      },
    });
  }, [toggleToast, setNotify, rejectDesign, reason, id]);

  return (
    <RejectReasonWrap open={open}>
      {open ? (
        <div className="input-wrap">
          <TextField
            value={reason}
            onChange={setReason}
            placeholder="Enter reason"
            multiline
          />
        </div>
      ) : (
        <span>{reason}</span>
      )}

      {open && (
        <Button
          icon={TickMinor}
          onClick={handleSubmit}
          primary
          size="small"
          loading={loading}
        />
      )}
      <Button
        plain
        icon={open ? CancelSmallMinor : EditMajorMonotone}
        onClick={toggleOpen}
        size="small"
      />
    </RejectReasonWrap>
  );
}

const RejectReasonWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 10px;

  .input-wrap {
    flex: 1;
  }

  .Polaris-Icon {
    height: ${({ open }) => (open ? "2rem" : "1.7rem")};
    width: ${({ open }) => (open ? "2rem" : "1.7rem")};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;

  .order-id {
    display: flex;
    flex-direction: column;
  }

  .order-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .design_wrap {
    width: 50%;
    position: relative;

    .heading-action-wrap {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    > .heading-wrap {
      padding-top: ${({ isDLead }) => (isDLead ? "3rem" : "")};
    }
    .modal-image-item {
      display: flex;
      align-items: start !important;
      flex-direction: column;
      row-gap: 1.5rem;
    }
    .Polaris-Tabs__TabContainer {
      &:first-child {
        button.Polaris-Tabs__Tab {
          padding-left: 0px;
        }
      }
    }
    .design_inner {
      margin-left: -5px;
    }

    .heading-wrap {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      column-gap: 1rem;
    }

    .attributes-wrap {
      margin-top: 1rem;
    }
  }
`;
