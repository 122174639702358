import {
  Banner,
  Button,
  Checkbox,
  FormLayout,
  Spinner,
  TextField,
} from "@shopify/polaris";
import _, { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { sortAttributeForMap } from "../../helper";
import { META_DATA_KEY_MB } from "../../variable";
import { VariantsSelectEPPolaris } from "./VariantsSelectEPPolaris";

const Container = styled.div`
  .label-mapping {
    font-weight: 600;
    font-size: 16px;
  }
  .mapping-wrap {
    margin-left: -2rem;
    > .Polaris-FormLayout--grouped:first-child {
      .Polaris-FormLayout__Item {
        margin-top: 1rem;
      }
    }
  }
  .has-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .notify-wrap {
    margin-top: 1rem;
  }
`;

const TYPE = "Type";

export const MappingVariantsEPPolaris = ({
  item,
  product,
  onChange,
  productNeedMapping,
  forceMapping,
  loadingVariant,
  setForceMapping = () => {},
  setMapToVariantId = () => {},
  autoMapMarkup = null,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [warningVariant, setWarningVariant] = useState(false);

  // Context
  // const ctx = useMappingOrderType();
  const metaDataName = React.useMemo(() => {
    if (!item || !item.metaData || item.metaData.length === 0) return "";
    let value = item.metaData
      .filter(
        ({ key, value }) =>
          ![META_DATA_KEY_MB].includes(key) && typeof value === "string",
      )
      .map(({ value }) => value);
    value = sortAttrName(value).join("-");
    return value;
  }, [item]);

  useEffect(() => {
    setDataSource(getDataSource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    handleChange();
    let data = dataSource;
    let selectedV =
      data && data.length > 0
        ? data
            .filter((i) => i.productBaseVariant)
            .map((i) => i.productBaseVariant.id)
        : [];
    setSelectedVariants(selectedV);

    const haveVariantStore =
      data?.length > 0
        ? data.some((i) => i?.productBaseVariant?.haveVariantStore)
        : false;
    setWarningVariant(haveVariantStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  // return title of attribute and sort a-z
  const getAttributeName = useCallback(
    (attributes = []) => {
      if (!attributes) {
        return "";
      }

      let baseName = [];
      const remainAttributes = [];

      for (let i = 0; i < attributes.length; i++) {
        const attr = attributes[i];
        if (attr?.name === TYPE) {
          baseName.push(attr);
        } else {
          remainAttributes.push(attr);
        }
      }

      baseName = baseName
        .filter(({ name }) => name !== "_WCPA_order_meta_data")
        .map((a) => a?.option?.trim())
        .filter(Boolean);
      // let result = remainAttributes
      //   .filter(({ name }) => name !== "_WCPA_order_meta_data")
      //   .map((att) => att.option && att.option.trim());

      // result = sortAttrName(result);

      let attrsValid = remainAttributes.filter(
        ({ name }) => name !== "_WCPA_order_meta_data",
      );
      const newOpts = sortAttributeForMap(attrsValid);
      return [...baseName, newOpts].join("-");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product],
  );

  const getDataSource = useCallback(() => {
    // Need mapping
    const originVariantNeedMapping =
      productNeedMapping?.originVariantNeedMapping?.length > 0
        ? productNeedMapping.originVariantNeedMapping
        : [];

    let newVariants = item && item.variants;
    if (originVariantNeedMapping?.length > 0) {
      newVariants = newVariants.filter((variant) =>
        originVariantNeedMapping.includes(variant.id),
      );
    }

    const productBaseVariants = getProductBase(product);

    return (
      newVariants &&
      newVariants.map((variant) => {
        return {
          variant: variant,
          productBaseVariant: productBaseVariants.find(
            (v) =>
              v.attribute &&
              v.attribute.toLowerCase() ===
                getAttributeName(variant.attributes).toLowerCase(),
          ),
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productNeedMapping]);

  const getProductBase = useCallback(
    (product) => {
      const newVariant =
        productNeedMapping != null
          ? productNeedMapping.variantAvailableMapping
          : product?.variants;

      if (product && product.productBases.length === 1) {
        return newVariant?.length > 0
          ? newVariant.map((variant, index) => {
              const haveVariantStore = variant?.variantStores?.length > 0;
              return {
                id: variant.id,
                disabled: variant.disabled,
                attribute: variant.productBaseVariant
                  ? getAttributeName(variant.productBaseVariant.attributes)
                  : getAttributeName(
                      _.get(
                        product.productBases[0].variants[index],
                        "attributes",
                      ),
                    ),
                haveVariantStore,
              };
            })
          : [];
      }

      if (product && product.productBases.length > 1) {
        return newVariant?.length > 0
          ? newVariant.map((variant) => {
              return {
                id: variant.id,
                disabled: variant.disabled,
                attribute:
                  variant.productBaseVariant &&
                  getAttributeName([
                    ...variant.productBaseVariant.attributes,
                    {
                      option:
                        variant.productBaseVariant &&
                        variant.productBaseVariant.productBase &&
                        variant.productBaseVariant.productBase.title,
                      id: 0,
                      name: TYPE,
                    },
                  ]),
              };
            })
          : [];
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [productNeedMapping, getAttributeName],
  );

  const productBaseVariants = getProductBase(product);
  const handleChange = useCallback(() => {
    let data = dataSource;
    const value =
      data && data.length
        ? data
            .map((item) =>
              item.variant && item.productBaseVariant
                ? {
                    variantId: item.variant.id,
                    productVariantId: item.productBaseVariant.id,
                  }
                : null,
            )
            .filter(Boolean)
        : [];
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const handleChangeVariants = useCallback(
    (value, idx) => {
      let newDataSource = [];
      if (dataSource && dataSource.length) {
        newDataSource = dataSource.map((ds, index) => {
          if (index === idx) {
            return (ds = value);
          }
          return ds;
        });
      }
      setDataSource(newDataSource);
      handleChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource],
  );

  const handleReset = useCallback(() => {
    let data = dataSource;
    data = data.length
      ? data.map((i) => ({
          variant: i.variant,
          productBaseVariant: null,
        }))
      : [];
    setDataSource(data);
  }, [dataSource]);

  const currVariantId = React.useMemo(() => {
    return _.get(item, "variantId", null);
  }, [item]);

  const dataSourceMarkup =
    dataSource && dataSource.length > 0 ? (
      dataSource.map((ds, idx) => {
        let name = getAttributeName(ds.variant.attributes);
        const nameLow = name?.toLowerCase();
        const metaDataLow = metaDataName?.toLowerCase();
        const id = ds.variant?.id;

        let cbMapToBaseVariantId = () => {};
        // if (ctx?.isOnlineStore && !!forceMapping) {
        if (!!forceMapping) {
          if (currVariantId != null) {
            if (String(id) !== String(currVariantId)) return null;
          } else {
            if (nameLow !== metaDataLow) {
              return null;
            }
          }
        }

        if (currVariantId === id + "" || nameLow === metaDataLow) {
          cbMapToBaseVariantId = setMapToVariantId;

          // set default when checked force
          const baseVariantId = get(ds, "productBaseVariant.id") || null;
          cbMapToBaseVariantId(baseVariantId);
        }
        return (
          <React.Fragment key={`variants-${idx}`}>
            <FormLayout.Group>
              <TextField
                label="Origin product"
                labelHidden
                value={name ? name : _.get(item, "product.name", "")}
              />
              <VariantsSelectEPPolaris
                productBaseVariants={productBaseVariants}
                value={ds}
                onChange={(value) => {
                  handleChangeVariants(value, idx);
                  const { productBaseVariant } = value || {};
                  const { id } = productBaseVariant || {};

                  cbMapToBaseVariantId(id);
                }}
                selectedVariants={selectedVariants}
                enabled={!!forceMapping}
              />
            </FormLayout.Group>
          </React.Fragment>
        );
      })
    ) : (
      <p style={{ marginTop: "1rem", marginLeft: "2rem" }}>No variant found.</p>
    );

  let resetBtn = null;
  if (
    productBaseVariants &&
    productBaseVariants.length &&
    selectedVariants &&
    selectedVariants.length
  ) {
    if (productBaseVariants.length === selectedVariants.length) {
      resetBtn = (
        <Button children="Reset variant" onClick={handleReset} plain />
      );
    }
  }

  return (
    <Container>
      <label className="label-mapping">Mapping</label>
      {/* {ctx?.isOnlineStore ? ( */}
      <div>
        <Checkbox
          checked={!!forceMapping}
          onChange={setForceMapping}
          label="Force Mapping for this order item (only map this item without saving the mapping reuseable info)"
          disabled={!dataSource || dataSource.length === 1}
        />
      </div>
      {autoMapMarkup}
      {/* ) : null} */}
      <div className="mapping-wrap" style={{ marginLeft: "-2rem" }}>
        {loadingVariant ? (
          <div className="pl-8 pt-4">
            <Spinner size="small" />
          </div>
        ) : (
          <>
            <FormLayout.Group>
              <span>Origin product</span>
              <div className="has-reset">
                <span>Product variant</span>
                {resetBtn}
              </div>
            </FormLayout.Group>
            {dataSourceMarkup}
          </>
        )}
      </div>
      {warningVariant && (
        <div className="notify-wrap">
          <Banner status="critical">
            <p>Note! This action will modify old product store configs.</p>
          </Banner>
        </div>
      )}
    </Container>
  );
};

function sortAttrName(arr = []) {
  return arr.sort((a, b) => {
    const al = a && a.toLowerCase();
    const bl = b && b.toLowerCase();
    if (al < bl) {
      return -1;
    }
    if (al > bl) {
      return 1;
    }
    return 0;
  });
}
