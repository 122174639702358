import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextContainer, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "./ProductsPolaris";

const DELETE_COLLECTIONS = gql`
  mutation addCollectionsToProducts($productIds: [ID!]!, $add: Boolean!) {
    addCollectionsToProducts(productIds: $productIds, add: $add)
  }
`;

export const DeleteCollectionsProductsPolaris = ({
  open,
  toggleModal,
  selectedItem,
  filter,
  onCompleted,
}) => {
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [
    addCollectionsToProducts,
    { data, loading, error, client },
  ] = useMutation(DELETE_COLLECTIONS, {
    onError: () => {},
  });

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = useCallback(() => {
    addCollectionsToProducts({
      variables: {
        productIds: selectedItem,
        add: false,
      },
    })
      .then(() => {
        try {
          const cache = client.readQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables: { filter },
          });
          client.writeQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables: { filter },
            data: {
              ...cache,
              products: {
                ...cache.products,
                nodes: cache.products.nodes.map((p) => {
                  if (selectedItem.find((s) => s === p.id)) {
                    p.collections = [];
                  }
                  return p;
                }),
              },
            },
          });
        } catch (e) {}
      })
      .catch(() => {});
    toggleActive();
    const id = setTimeout(() => {
      toggleModal();
      onCompleted();
    }, 2500);
    setTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );

  const toastMarkup = activeToast
    ? (error || (data && data.addCollectionsToProducts)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.addCollectionsToProducts &&
                "Remove collections success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleModal}
        title="Remove collections"
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <TextContainer>Are you sure to remove collections?</TextContainer>
      </Modal>
    </>
  );
};
