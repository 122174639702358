import React, { useState, useCallback } from "react";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { LOAD_QUERY, DELETE_USERS } from "../ListUser";

import { handleError } from "../../../helper";
import history from "../../../history";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

export const DeleteUserAccountAdminPolaris = ({ user, filter }) => {
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [deleteUser, { data, error, client, loading }] = useMutation(
    DELETE_USERS,
    {
      onCompleted: () => {
        handleModalChange();
      },
    }
  );

  const handleClick = useCallback(() => {
    history.push(`/admin/users/edit/${user.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalChange = useCallback(() => setOpen((open) => !open), []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.deleteUser)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.deleteUser && "Delete user success!"
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const deleteUserState = (users, id) => {
    for (let i = 0; i < users.length; i++) {
      const c = users[i];
      if (c.id === id) {
        c.status = !c.status;
      }
      if (c.children && c.children.length) {
        c.children = deleteUserState(c.children, id);
      }
    }
    return users;
  };

  return (
    <Container>
      <Button onClick={handleClick} plain>
        Edit
      </Button>
      <Button onClick={handleModalChange} plain>
        Delete
      </Button>
      <Modal
        title={"Delete user"}
        open={open}
        onClose={handleModalChange}
        primaryAction={{
          content: "Delete",
          loading: loading,
          onAction: () => {
            deleteUser({
              variables: { id: user.id },
            })
              .then((res) => {
                if (res) {
                  try {
                    const cache = client.readQuery({
                      query: LOAD_QUERY,
                      variables: {
                        filter: { ...filter },
                      },
                    });
                    const cat = deleteUserState(
                      JSON.parse(JSON.stringify(cache.users.nodes)),
                      user.id
                    );
                    client.writeQuery({
                      query: LOAD_QUERY,
                      variables: {
                        filter: { ...filter },
                      },
                      data: {
                        ...cache,
                        users: {
                          ...cache.users,
                          nodes: cat,
                        },
                      },
                    });
                  } catch (_) {}
                }
              })
              .catch(() => {});
            toggleActive();
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure to delete this user?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Container>
  );
};
