import React, { useEffect } from "react";
import { Modal, TextStyle } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import styled from "styled-components";

import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { TRACKING_STATUS } from "../../../constants";
import { handleError } from "../../../helper";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";

const FULFILLMENT_QUERY = gql`
  query getFulfillmentInfo($orderId: ID!) {
    getFulfillmentInfo(orderId: $orderId) {
      tracking {
        id
        status
        code
        newCode
        comment
        createdAt
        updatedAt
      }
      export {
        id
        file {
          id
          url
          name
        }
        createdAt
      }
    }
  }
`;

const Container = styled.div`
  .loading-wrap {
    margin: 0 -3rem;
  }
  .fulfillment-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ViewFulfillmentInfoPolaris = (props) => {
  const { orderId, open, toggleShowModal } = props;
  useLockBodyScroll(open);

  const [getFulfillment, { data, loading, error }] = useLazyQuery(
    FULFILLMENT_QUERY
  );

  useEffect(() => {
    if (orderId && open) {
      getFulfillment({
        variables: {
          orderId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, open]);

  const fulfillment = (data && data.getFulfillmentInfo) || null;
  const trackingStatus =
    fulfillment &&
    fulfillment.tracking &&
    TRACKING_STATUS.find((t) => t.key === fulfillment.tracking.status);

  return (
    <Modal
      title={`Fulfillment info: #${orderId}`}
      open={open}
      onClose={toggleShowModal}
      sectioned
    >
      <Container id="viewff_modal">
        {loading ? (
          <div className="loading-wrap">
            <SkeletonPagePolaris />
          </div>
        ) : (
          <>
            {error ? (
              <div>Erorr: {handleError(error.toString())}</div>
            ) : (
              <>
                {fulfillment ? (
                  <div className="fulfillmentInfo-wrap">
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">Tracking code:</TextStyle>
                      <span>
                        {fulfillment.tracking && fulfillment.tracking.code
                          ? fulfillment.tracking.code
                          : null}
                      </span>
                    </div>
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">Print date:</TextStyle>
                      <span>
                        {moment(fulfillment.export.createdAt).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </span>
                    </div>
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">Tracking status:</TextStyle>
                      <span>{trackingStatus && trackingStatus.name}</span>
                    </div>
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">Tracking added:</TextStyle>
                      <span>
                        {fulfillment.tracking && fulfillment.tracking.createdAt
                          ? moment(fulfillment.tracking.createdAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : null}
                      </span>
                    </div>
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">
                        Tracking note sent:
                      </TextStyle>
                      <span>
                        {fulfillment.tracking && fulfillment.tracking.comment
                          ? fulfillment.tracking.comment
                          : null}
                      </span>
                    </div>
                    <div className="fulfillment-item">
                      <TextStyle variation="strong">In file:</TextStyle>
                      <span>
                        {fulfillment.export && fulfillment.export.file && (
                          <CustomLinkPolaris href={fulfillment.export.file.url}>
                            {fulfillment.export.file.name}
                          </CustomLinkPolaris>
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <EmptyStatePolaris />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};
