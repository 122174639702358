import React, { useState, useCallback, useEffect } from "react";
import { DataTable, Badge, Toast } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { USER_ROLE } from "../../../variable";
import { NumberToFixed } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { DepositsActionsPolaris } from "./DepositsActionsPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;
const STATUS_BADGE = {
  Pending: "info",
  Approved: "success",
  Cancelled: "critical",
};

export const DataTableDepositsPolaris = ({
  data,
  role,
  filter,
  setFilter,
  refetch,
}) => {
  // State
  const [rows, setRows] = useState([]);
  const [columnContentTypes, setColumnContentTypes] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Get data
  useEffect(() => {
    const isSeller = [USER_ROLE.Seller].includes(role);

    // Column type
    const colType = [];
    for (let i = 0; i < 8; i++) {
      colType.push("text");
    }

    // Heading
    const headings = [
      "ID",
      "Amount",
      "Transaction ID",
      ...(isSeller
        ? ["Note", USER_ROLE.Seller]
        : ["Message", USER_ROLE.Supplier]),
      "Status",
      "Created",
    ];

    if (isSeller) {
      colType.push("numeric");
      headings.push("Actions");
    }

    setColumnContentTypes(colType);
    setHeadings(headings);

    let newRows =
      data?.transactions?.nodes?.length > 0
        ? data?.transactions.nodes.map((item) => {
            // journal
            const journal = item?.journal;
            const journalId = journal?.refId;
            const note = journal?.comment;
            const status = journal?.status;

            let statusColor;
            for (let [key, value] of Object.entries(STATUS_BADGE)) {
              if (status === key) {
                statusColor = value;
              }
            }

            // User
            const user = isSeller ? item?.creator : item?.account;

            // Created
            const createdAt = moment(item?.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            return [
              <div className="id-wrap">
                <span>{item?.id}</span>
              </div>,
              <div className="amount-wrap">
                <span>${NumberToFixed(item?.amount, 2)}</span>
              </div>,
              <div className="transaction-wrap">
                <span>{journalId}</span>
              </div>,
              <div className="note-wrap">
                <span>{note}</span>
              </div>,
              <div className="user-wrap">
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
              </div>,
              <div className="status-wrap">
                <Badge children={status} status={statusColor} />
              </div>,
              <div className="created-wrap">
                <span>{createdAt}</span>
              </div>,
              ...(isSeller
                ? [
                    <div className="actions-wrap">
                      {"Pending" === status && (
                        <DepositsActionsPolaris
                          item={item}
                          refetch={refetch}
                          toggleActive={toggleActive}
                          onNotificationChange={({ message, isError }) =>
                            setNotification(() => ({ message, isError }))
                          }
                        />
                      )}
                    </div>,
                  ]
                : []),
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, role]);

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  let { limit, offset } = filter;
  const total = get(data, "transactions.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
