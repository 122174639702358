import { useMutation } from "@apollo/react-hooks";
import { Card, Toast } from "@shopify/polaris";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context";
import { CREATE_MANUAL_ORDER } from "../../../graphql/mutations";
import { getParamByRole, handleError } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { Form } from "./components";

export function ManualOrder() {
    const history = useHistory();
    // Context
    const { currentUser } = React.useContext(AppContext);
    let currentParam = getParamByRole(currentUser);

    // State
    const [toastActive, toggleToastActive] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const timeoutRef = React.useRef(null);

    // Mutation
    const [createManualOrder, { loading }] = useMutation(CREATE_MANUAL_ORDER, {
        onCompleted: () => {
            setNotify({ msg: "Create order successfully.", err: false });
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                history.push(`/${currentParam}/orders`);
            }, 1500);
        },
        onError: (error) => {
            setNotify({ msg: handleError(error.toString()), err: true });
        },
    });

    // Handle actions
    const handleSubmit = React.useCallback(
        (input) => {
            toggleToastActive(true);
            setNotify({ msg: null, err: false });
            if (input != null) {
                createManualOrder({
                    variables: {
                        input,
                    },
                });
            }
        },
        [createManualOrder, toggleToastActive, setNotify]
    );

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            onDismiss={toggleToastActive}
            duration={1500}
        />
    );

    return (
        <React.Fragment>
            {toastMarkup}
            <Card sectioned title="Create Order">
                <Form onSubmit={handleSubmit} loading={loading} />
            </Card>
        </React.Fragment>
    );
}
