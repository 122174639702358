import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { SuppliersPolaris } from "../../components/admin/supplier/SuppliersPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const SuppliersPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="All Supplier"
                fullWidth
                primaryAction={{
                    content: "Add supplier",
                    url: "/admin/supplier/add",
                }}
            >
                <SuppliersPolaris path={path} />
            </Page>
        </Container>
    );
};
