import React, { useCallback, useState, useEffect } from "react";
import { InlineError, Modal, Stack, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { RE_SUBMIT_ORDER_TO_FF } from "../../../graphql/mutations";

export const ResubmitToCustomCatPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    orderId,
    changeStatusCount,
    convertOS,
    refetch,
    labelFF,
  } = props;

  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  // Lock body scroll
  // useLockBodyScroll(open)

  const [reSubmitOrderToFulfillment, { data, loading, error }] = useMutation(
    RE_SUBMIT_ORDER_TO_FF,
    {
      onError: () => {
        toggleShowModal(false);
      },
      onCompleted: () => {
        toggleShowModal(false);
        const id = setTimeout(() => {
          if (changeStatusCount) {
            changeStatusCount(convertOS && [convertOS.Error]);
          }
          if (refetch) {
            refetch();
          }
        }, 2100);
        setTimeoutId(id);
      },
    }
  );

  const handleSubmit = useCallback(() => {
    reSubmitOrderToFulfillment({
      variables: { orderId },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );

  const toastMarkup = activeToast
    ? (error || (data && data.reSubmitOrderToFulfillment)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.reSubmitOrderToFulfillment &&
                `Resubmit order to Customcat success.`
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        title={`Resubmit to ${labelFF}`}
        open={open}
        onClose={toggleShowModal}
        secondaryActions={[{ content: "No", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleSubmit,
          loading: loading,
        }}
        sectioned
      >
        <Stack>
          <p id="resubmit_modal">
            Do you want to re-submit this order to Fulfillment?
          </p>
          <InlineError message="Make sure that you cancelled this order in Fulfillment service" />
        </Stack>
      </Modal>
    </>
  );
};
