import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { TIKTOK_FETCH_ORDERS_BY_ID } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function FetchOrder({ open, onClose, store }) {
  const [ids, setIds] = useState("");
  const [fetchOrdersByIds, { loading }] = useMutation(
    TIKTOK_FETCH_ORDERS_BY_ID,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!store.id || !ids) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ err: false, msg: null });

    const newIds = Array.from(
      new Set(
        (ids || "")
          .split("\n")
          .map((i) => (i ? i.trim() : i))
          .filter(Boolean),
      ).values(),
    );

    if (newIds.length === 0) {
      setNotify({ err: true, msg: "order ids is reqiored" });
      return;
    }

    fetchOrdersByIds({
      variables: {
        storeID: store.id,
        ids: newIds,
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Fetch orders success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [fetchOrdersByIds, store.id, onClose, toggleToast, setNotify, ids]);

  useEffect(() => {
    setIds("");
  }, [open]);

  const trackInputRef = React.useRef(null);

  return (
    <Modal
      title="Fetch orders by ids"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Fetch", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <TextField
        label="Order IDs"
        multiline={4}
        value={ids}
        onChange={(...args) => {
          setIds(...args);
          trackInputRef.current = true;
        }}
        error={trackInputRef.current && !ids && "Order IDs is required"}
        helpText="Enter order ids with each order id separated by a new line."
      />
    </Modal>
  );
}
