import { useMutation, useQuery } from "@apollo/react-hooks";
import { Select, Space } from "antd";
import { gql } from "apollo-boost";
import React from "react";
import { SET_VIEW_ORIGIN_FILE } from "../../../graphql/mutations";
import {
  GET_TEAM_MEMBERS,
  GET_TEAM_ROLES,
  GET_VIEW_ORIGIN_FILE,
} from "../../../graphql/queries";

const USER_BY_ID = gql`
  query userByID($id: ID!) {
    userByID(id: $id) {
      firstName
      lastName
      email
      id
    }
  }
`;

function ViewOriginFileImpl({ setLoading }, ref) {
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [memberOptions, setMemberOptions] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [filter, setFilter] = React.useReducer((p, s) => ({ ...p, ...s }), {
    limit: 20,
    offset: 0,
    search: null,
  });

  const { data, loading, error } = useQuery(GET_TEAM_ROLES);
  const { data: vo } = useQuery(GET_VIEW_ORIGIN_FILE);
  const { data: tm, loading: lTM, error: eTM } = useQuery(GET_TEAM_MEMBERS, {
    variables: { filter },
  });

  const changed = React.useRef(false);
  const [update] = useMutation(SET_VIEW_ORIGIN_FILE);

  React.useEffect(() => {
    const nodes = data?.teamRoles || [];
    const rows = nodes.map((value) => ({ label: value, value }));
    setRoleOptions(rows);
  }, [data]);

  React.useEffect(() => {
    const nodes = tm?.teamMembers?.hits || [];
    const rows = getUsers(nodes);
    setMemberOptions(rows);
  }, [tm]);

  React.useEffect(() => {
    async function updateData() {
      if (vo?.getViewOriginFile) {
        const { roles, userIDs } = vo.getViewOriginFile || {};

        const r = (roles || []).filter((role) =>
          (roleOptions || []).some(({ value }) => value === role)
        );
        setRoles(r);

        // `id2` without current list memeber (limit 20)
        const { id1, id2 } = (userIDs || []).reduce(
          (acc, id) => {
            const inArr = (members || []).some(({ value }) => value === id);
            if (inArr) {
              acc.id1.push(id);
            } else {
              acc.id2.push(id);
            }

            return acc;
          },
          { id1: [], id2: [] }
        );

        if (window.__apolloClient__) {
          let data = await Promise.allSettled(
            id2.map(async (id) => {
              const data = await window.__apolloClient__.query({
                query: USER_BY_ID,
                variables: {
                  id,
                },
              });
              return data;
            })
          ).then((res) =>
            res
              .map(({ status, value }) => {
                if (status !== "fulfilled") return null;

                return { user: value?.data?.userByID };
              })
              .filter(Boolean)
          );

          const newUsers = getUsers(data);
          const newIds = (newUsers || []).map(({ value }) => value);
          id1.push(...newIds);

          setMemberOptions((prev) => {
            prev.push(...newUsers);
            return [...prev];
          });
        }
        setMembers(id1);
      }
    }

    updateData();
  }, [vo]);

  const handleSubmit = React.useCallback(() => {
    if (!changed.current) return;
    setLoading(true);

    update({
      variables: {
        input: {
          roles,
          userIDs: members,
        },
      },
    }).finally(() => {
      setLoading(false);
    });
  }, [members, roles, update, setLoading]);

  const timeId = React.useRef(null);
  const handleMemberSearch = React.useCallback((search) => {
    timeId.current && clearTimeout(timeId.current);
    timeId.current = setTimeout(() => {
      setFilter({ search });
    }, 500);
  }, []);

  const handleRoleChange = React.useCallback((value) => {
    setRoles(value);
    changed.current = true;
  }, []);

  const handleMemberChange = React.useCallback((value) => {
    setMembers(value);
    changed.current = true;
  }, []);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div>
        <label>Roles</label>
        {error ? (
          <div>{error?.toString()}</div>
        ) : (
          <Select
            options={roleOptions}
            value={roles}
            allowClear
            aria-multiline="true"
            mode="multiple"
            placeholder={"Select roles"}
            onChange={handleRoleChange}
            loading={loading}
            style={{ width: "100%", marginTop: "0.5rem" }}
          />
        )}
      </div>

      <div>
        <label>Members</label>
        {eTM ? (
          <div>{eTM?.toString()}</div>
        ) : (
          <Select
            options={memberOptions}
            value={members}
            allowClear
            aria-multiline="true"
            mode="multiple"
            placeholder={"Select accounts"}
            loading={lTM}
            onChange={handleMemberChange}
            style={{ width: "100%", marginTop: "0.5rem" }}
            showSearch
            onSearch={handleMemberSearch}
            filterOption={false}
          />
        )}
      </div>
    </Space>
  );
}

/*** ----  */
function getUsers(arr) {
  if (!arr || !Array.isArray(arr) || arr.length === 0) return [];
  return arr.map(({ user }) => {
    const { firstName, lastName, email, id } = user || {};
    const fName = [firstName, lastName].filter(Boolean).join(" ");
    const label = `${fName} (${email})`;
    return { label, value: id };
  });
}

const ViewOriginFile = React.forwardRef(ViewOriginFileImpl);

export { ViewOriginFile };
