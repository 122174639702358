import { useMutation } from "@apollo/react-hooks";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { getParamByUserRole, handleError } from "../../../helper";
import history from "../../../history";
import { CategoryFormPolaris } from "./CategoryFormPolaris";

const ADD_CATEGORY = gql`
  mutation createProductBaseCategory($input: NewProductBaseCategory!) {
    createProductBaseCategory(input: $input) {
      id
      name
      description
      parentId
      image {
        id
        name
        thumbnailUrl
        url
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddCategoryPolaris = () => {
  //State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Context
  const { currentUser } = useAppContext();
  const param = getParamByUserRole(currentUser);

  // Mutation
  const [createCategory, { loading }] = useMutation(ADD_CATEGORY, {
    onCompleted: () => {
      setNotification({
        message: "Create category successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 5000);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createCategory({
        variables: {
          input,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(
    () => history.push(`/${param}/categories/`),
    [param],
  );

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={5000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Add Category"
        breadcrumbs={[{ content: "Categories", url: `/${param}/categories` }]}
      >
        <CategoryFormPolaris
          onSubmit={handleSubmit}
          loading={loading}
          handleRedirect={handleRedirect}
        />
      </Page>
    </Container>
  );
};
