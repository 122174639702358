import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Select } from "@shopify/polaris";

import { KPI } from "../../../../../constants/task";
import { useMutation } from "@apollo/react-hooks";
import { SELLER_UPDATE_KPI_IDEA_TASK } from "../../../../../graphql/mutations";
import { useToastContext } from "../../../../shared/ToastContext";
import { handleError } from "../../../../../helper";

export function UpdateKpi({ item, open, onClose, refetchIdea }) {
    const id = item?.id;
    const ideaKpi = item?.ideaKpi != null ? String(item.ideaKpi) : "";

    // State
    const [kpi, setKpi] = useState("0.5");

    // Context
    const { setNotify, toggleToast, refetch } = useToastContext();

    // Mutation
    const [updateKpi, { loading }] = useMutation(SELLER_UPDATE_KPI_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: "Update idea kpi successfully.",
                err: false,
            });

            onClose && onClose();
            refetch && refetch();
            refetchIdea && refetchIdea();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Get data
    useEffect(() => {
        setKpi(() => ideaKpi);
    }, [ideaKpi]);

    // Actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify({ msg: null, err: false });

            updateKpi({
                variables: {
                    id,
                    kpi: parseFloat(kpi),
                },
            });
        }
    }, [updateKpi, id, kpi, toggleToast, setNotify]);

    // Markup
    const options = useMemo(() => {
        return KPI.map((i) => {
            const iStr = String(i);

            return {
                value: iStr,
                label: iStr,
                disabled: kpi === iStr,
            };
        });
    }, [kpi]);

    return (
        <Modal
            title="Update Idea KPI"
            sectioned
            open={open}
            onClose={onClose}
            secondaryActions={[{ content: "Close", onAction: onClose }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Select
                label="Idea KPI"
                options={options}
                value={kpi}
                id="ideaKpi"
                onChange={setKpi}
            />
        </Modal>
    );
}
