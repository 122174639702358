import React from 'react';

// Mapping order context
export const MOrderContext = React.createContext({
    toggleToast: () => Boolean,
    notifyChange: () => {},
    filter: {},
});

export const useMappingOrdeCtx = () => React.useContext(MOrderContext);

// Action context
const MapActionContext = React.createContext(null);
export function MapActionProvider({ children, ...props}) {
  const bag = React.useMemo(() => props, [props]);

  return <MapActionContext.Provider value={bag}>{children}</MapActionContext.Provider>
}

export function useMapActionContext() {
  return React.useContext(MapActionContext);
}
