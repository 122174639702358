import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

const SUPPLIERS = gql`
  query suppliers {
    suppliers {
      id
      firstName
      lastName
    }
  }
`;

export const SuppliersSelectPolaris = ({ onChange }) => {
  const { data, loading } = useQuery(SUPPLIERS);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  useEffect(() => {
    let newData =
      data && data.suppliers && data.suppliers.length
        ? data.suppliers.map((s) => {
            return {
              value: s.id,
              label: `${s.firstName} ${s.lastName}`,
            };
          })
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [data]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectedChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setInputValue(_.head(selectedValue));
      setSelectedOptions(selected);
      if (onChange) {
        onChange(_.head(selected));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      label="supplier"
      labelHidden
      placeholder="Search for supplier"
      value={inputValue}
      onChange={handleInputChange}
    />
  );

  return (
    <Autocomplete
      options={options}
      selected={selectedOptions}
      onSelect={handleSelectedChange}
      textField={textField}
      loading={loading}
      emptyState={<span>No items found!</span>}
    />
  );
};
