import React from "react";
import { REPORT_ORDERS_ERROR_FROM_MERCHIZE } from "../../../graphql/queries";
import { ReportOrders } from "./ReportOrders";

export function ReportErrorFromMerchize({ filter }) {
  return (
    <ReportOrders
      filter={filter}
      title="Merchize Orders Error"
      documentNode={REPORT_ORDERS_ERROR_FROM_MERCHIZE}
    />
  );
}
