import React, { useCallback, useState, useEffect } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { handleError } from "../../../helper";
import { SupplierFormPolaris } from "./SupplierFormPolaris";

const Container = styled.div``;

const MUTATION = gql`
  mutation createUser($input: NewUser!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      email
      roles
      phone
      status
      verified
      address
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;

const QUERY_ROLES = gql`
  query systemRoles {
    systemRoles
  }
`;

export const AddSupplierPolaris = () => {
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Mutation
  const [createUser, { loading: loadingM }] = useMutation(MUTATION, {
    onCompleted: () => {
      setNotification({
        message: "Add user successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      let existEmail = error.toString().includes("uix_users_email");
      let existEmailMess =
        "Email name already in use, please try another name.";

      setNotification({
        message: existEmail ? existEmailMess : handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, error } = useQuery(QUERY_ROLES);

  // Handle actions
  const handleRedirect = useCallback(() => {
    history.push("/admin/suppliers");
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createUser({
        variables: {
          input,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Add supplier"
        breadcrumbs={[{ content: "Suppliers", url: "/admin/suppliers" }]}
      >
        <SupplierFormPolaris
          dataRole={data?.systemRoles}
          onSubmit={handleSubmit}
          error={error}
          loading={loadingM}
          handleRedirect={handleRedirect}
        />
      </Page>
    </Container>
  );
};
