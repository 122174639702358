import { gql } from "apollo-boost";
import { ProductGen2dMockupsFragment } from "../fragments";

export const REVIEW_PRODUCT_GEN_2D_MOCKUP = gql`
  mutation reviewProductGen2dMockup(
    $id: ID!
    $isApprove: Boolean!
    $fileIds: [ID!]!
  ) {
    reviewProductGen2dMockup(
      id: $id
      isApprove: $isApprove
      fileIds: $fileIds
    ) {
      ...ProductGen2dMockupsFragment
    }
  }
  ${ProductGen2dMockupsFragment}
`;

export const BULK_REVIEW_PRODUCT_GEN_2D_MOCKUP = gql`
  mutation bulkReviewProductGen2dMockup($ids: [ID!]!, $isApprove: Boolean!) {
    bulkReviewProductGen2dMockup(ids: $ids, isApprove: $isApprove) {
      ...ProductGen2dMockupsFragment
    }
  }
  ${ProductGen2dMockupsFragment}
`;

export const RE_GEN_2D_MOCKUP = gql`
  mutation regen2dMockup($ids: [ID!]!) {
    regen2dMockup(ids: $ids) {
      ...ProductGen2dMockupsFragment
    }
  }
  ${ProductGen2dMockupsFragment}
`;

export const DELETE_2D_MOCKUP = gql`
  mutation deleteProductGen2dMockup($ids: [ID!]!) {
    deleteProductGen2dMockup(ids: $ids)
  }
`;

export const UPDATE_PRODUCT_GEN2D_MOCKUP = gql`
  mutation updateProductGen2dMockup($input: UpdateProductGen2dMockup!) {
    updateProductGen2dMockup(input: $input) {
      ...ProductGen2dMockupsFragment
    }
  }
  ${ProductGen2dMockupsFragment}
`;

export const CREATE_NICHE = gql`
  mutation createNiche($input: NewNiche!) {
    createNiche(input: $input) {
      id
      name
      parentId
    }
  }
`;

export const UPDATE_PRODUCT_TEMPLATE = gql`
  mutation updateTemplate(
    $id: ID!
    $newContent: String
    $defaultMockupIds: [ID!]
  ) {
    updateTemplate(
      id: $id
      newContent: $newContent
      defaultMockupIds: $defaultMockupIds
    ) {
      id
      name
      slug
      defaultMockupIds
      data {
        products {
          description
        }
      }
    }
  }
`;

export const CREATE_PUSH_PRODUCT = gql`
  mutation createPush($input: NewPush!) {
    createPush(input: $input) {
      id
      title
      continue
      tags {
        id
        tag {
          id
          name
        }
        included
      }
      collections {
        id
        collection {
          id
          name
        }
        included
      }
      stores {
        id
        title
      }
      specialProducts
      productBases
    }
  }
`;

export const EXPORT_PRODUCTS = gql`
  mutation exportProducts($filter: ProductFilter!) {
    exportProducts(filter: $filter)
  }
`;