import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TEAM_ROLE } from "../../variable";
import { FilterBoolValuePolaris } from "../filters/FilterBoolValuePolaris";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterOrderByTimePolaris } from "../order/FilterOrderByTimePolaris";
import { FILTER_TIME } from "../order/FilterOrdersControlPolaris";
import { QUERY_AUTHORS } from "../product/divisions/AuthorsSelectPolaris";

const MOCKUP_UPDATE = [
  { value: false, label: "False" },
  { value: true, label: "True" },
];

const SORT_BY_OPTIONS = [
  { value: "productcreated", label: "Product created time" },
  { value: "totalorder", label: "Total order" },
];
const SORT_ORDER_OPTIONS = [
  { value: "DESC", label: "Desc" },
  { value: "ASC", label: "Asc" },
];

const QUREY_RECREATE_MOCKUP = gql`
  query recreateMockups($filter: RecreateMockupFilter) {
    recreateMockups(filter: $filter) {
      nodes {
        id
        title
      }
    }
  }
`;

const filterAuth = {
  limit: 20,
  offset: 0,
};

export const ProductsRMFilterPolaris = ({
  filter,
  onChange,
  designerSpecial,
}) => {
  // State
  const [queryValue, setQueryValue] = useState(filter.search);
  const [isUpdated, setIsUpdated] = useState({
    value: [false],
    label: "False",
  });
  const [recreateMockups, setRecreateMockups] = useState([]);
  const [recreateMockup, setRecreateMockup] = useState({
    value: filter.recreateMockupId,
    label: null,
    search: null,
  });
  const [sortBy, setSortBy] = useState({
    value: filter.sortBy,
    label: null,
  });
  const [sortOrder, setSortOrder] = useState({
    value: filter.sortOrder,
    label: null,
  });
  const typingTimeoutRef = useRef(null);

  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({
    value: filter.mockupUpdatedBy?.length > 0 ? filter.mockupUpdatedBy : [],
    label: null,
    search: null,
  });

  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });

  // Query
  const { loading, error } = useQuery(QUREY_RECREATE_MOCKUP, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: recreateMockup.search ? recreateMockup.search : null,
      },
    },
    onCompleted: (res) => {
      handleRMData(res);
    },
  });

  const { loading: loadingAuth, error: errorAuth } = useQuery(QUERY_AUTHORS, {
    onCompleted: (res) => {
      handleAuthorData(res);
    },
    onError: () => {},
    variables: {
      filter: {
        ...filterAuth,
        search: author.search,
        teamRoles: [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader],
      },
    },
  });

  // Get data
  const handleAuthorData = useCallback(
    (data) => {
      let newData =
        data?.teamMembers?.hits?.length > 0
          ? data.teamMembers.hits.map((tm) => {
              if (!tm || !tm.user) return null;
              const user = tm && tm.user;

              return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              };

              // const userRole = user?.roles || [];
              // const teamRole = user?.teamUser?.role || null;
              // const userId = user?.id;
              // if (user) {
              //   if (
              //     (userRole.includes(USER_ROLE.Seller) &&
              //       [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader].includes(
              //         teamRole
              //       )) ||
              //     DESIGNER_HAVE_RECREATE_MOCKUP.includes(userId)
              //   ) {
              //     return {
              //       value: user.id,
              //       label: `${user.firstName} ${user.lastName}`,
              //     };
              //   }
              // }
              // return null;
            })
          : [];
      newData = newData.filter(Boolean);

      // Current user
      let author = filter.mockupUpdatedBy;
      let currentAuthor = newData.find((i) => [i.value].includes(author));
      if (currentAuthor) {
        let { value, label } = currentAuthor;
        setAuthor({
          value,
          label,
        });
      }
      setAuthors(newData);
    },
    [filter.mockupUpdatedBy],
  );

  useEffect(() => {
    if (filter.recreateMockupId) {
      let mockupId = filter.recreateMockupId;
      let currentMockupId = recreateMockups.find((m) => m.value === mockupId);
      if (currentMockupId) {
        setRecreateMockup({
          value: currentMockupId.value,
          label: currentMockupId.label,
        });
      }
    }
  }, [filter, recreateMockups]);

  const handleRMData = useCallback((data) => {
    let newData =
      data?.recreateMockups?.nodes?.length > 0
        ? data.recreateMockups.nodes.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        : [];
    setRecreateMockups(newData);
  }, []);
  // Handle actions
  const handleQueryValueChange = useCallback((value) => {
    value = value ? value : null;
    setQueryValue(value);
  }, []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleIsUpdatedRemove = useCallback(
    () =>
      setIsUpdated({
        value: [false],
        label: "False",
      }),
    [],
  );
  const handleRMRemove = useCallback(
    () => setRecreateMockup({ value: null, label: null, search: null }),
    [],
  );

  const handleSortByRemove = useCallback(
    () => setSortBy({ value: filter.sortBy, label: null }),
    [filter.sortBy],
  );
  const handleSortOrderRemove = useCallback(
    () => setSortOrder({ value: filter.sortOrder, label: null }),
    [filter.sortOrder],
  );
  const handleAuthorRemove = useCallback(
    () => setAuthor(() => ({ value: null, label: null, search: null })),
    [],
  );

  const handleFitlerTimeRemove = useCallback(
    () => setFilterTime(() => ({ range: null, rangeLabel: null })),
    [],
  );

  const filterClears = [
    handleQueryValueRemove,
    handleIsUpdatedRemove,
    handleRMRemove,
    handleSortByRemove,
    handleSortOrderRemove,
    handleAuthorRemove,
    handleFitlerTimeRemove,
  ];
  const handleFilterClearAll = useCallback(() => {
    for (let fn of filterClears) {
      if (fn) fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filterClears]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      let mockupUpdated = Array.isArray(isUpdated.value)
        ? isUpdated.value[0]
        : isUpdated.value;

      let authorIds = author.value?.length > 0 ? author.value : null;
      let range = filterTime.range;

      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          mockupUpdated,
          recreateMockupId: recreateMockup.value,
          sortOrder: sortOrder.value,
          sortBy: sortBy.value,
          mockupUpdatedBy: authorIds,
          range,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryValue,
    isUpdated,
    recreateMockup,
    sortOrder,
    sortBy,
    author,
    filterTime,
  ]);

  const filters = [
    {
      key: "recreateMockups",
      label: "Recreate Mockups",
      filter: (
        <FilterHasSearchPolaris
          value={recreateMockup}
          options={recreateMockups}
          loading={loading}
          error={error}
          onChangeSearch={(search) =>
            setRecreateMockup((prevState) => ({
              ...prevState,
              search,
            }))
          }
          onChange={({ value, label }) =>
            setRecreateMockup((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    {
      key: "updated",
      label: "Mockup updated",
      filter: (
        <FilterBoolValuePolaris
          options={MOCKUP_UPDATE}
          value={isUpdated}
          onChange={({ value, label }) => {
            setIsUpdated((prev) => ({
              ...prev,
              value: [value],
              label,
            }));
          }}
        />
      ),
    },
    ...(designerSpecial
      ? [
          {
            key: "author",
            label: "Mockup updated by",
            filter: (
              <FilterHasSearchPolaris
                value={author}
                options={authors}
                loading={loadingAuth}
                error={errorAuth}
                onChange={({ value, label }) =>
                  setAuthor((prev) => ({
                    ...prev,
                    value,
                    label,
                  }))
                }
                onChangeSearch={(search) =>
                  setAuthor((prevState) => ({
                    ...prevState,
                    search,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "filter-time",
      label: "Mockup updated at",
      filter: (
        <FilterOrderByTimePolaris
          options={FILTER_TIME}
          filterTime={filterTime}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((prevState) => ({
              ...prevState,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "sortBy",
      label: "Sort by",
      filter: (
        <FilterListRadioPolaris
          options={SORT_BY_OPTIONS}
          value={sortBy.value}
          onChange={({ value, label }) => setSortBy({ value, label })}
        />
      ),
    },
    {
      key: "sortOrder",
      label: "Sort order",
      filter: (
        <FilterListRadioPolaris
          options={SORT_ORDER_OPTIONS}
          value={sortOrder.value}
          onChange={({ value, label }) => setSortOrder({ value, label })}
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (isUpdated != null) {
    const key = "updated";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, isUpdated.label),
      onRemove: handleIsUpdatedRemove,
    });
  }
  if (!isEmpty(recreateMockup.value)) {
    const key = "recreateMockups";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, recreateMockup.label),
      onRemove: handleRMRemove,
    });
  }
  if (!isEmpty(sortBy.label)) {
    const key = "sortBy";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sortBy.label),
      onRemove: handleSortByRemove,
    });
  }
  if (!isEmpty(sortOrder.label)) {
    const key = "sortOrder";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sortOrder.label),
      onRemove: handleSortOrderRemove,
    });
  }
  if (!isEmpty(author.label)) {
    const key = "author";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, author.label),
      onRemove: handleAuthorRemove,
    });
  }
  if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
    const key = "filter-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filterTime.rangeLabel),
      onRemove: handleFitlerTimeRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryPlaceholder={"Filter item"}
      queryValue={queryValue}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFilterClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "updated":
        return `Mockup updated: ${value}`;
      case "recreateMockups":
        return `Recreate Mockup: ${value}`;
      case "sortBy":
        return `Sort by: ${value}`;
      case "sortOrder":
        return `Sort order: ${value}`;
      case "author":
        return `Updated by: ${value}`;
      case "filter-time":
        return `Time: ${value}`;
      default:
        return value;
    }
  }
};
