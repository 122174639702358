import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";

export const FilterOrderByPolaris = ({ onChange, orderBy, options }) => {
  const [selected, setSelected] = useState(["created_at"]);

  useEffect(() => {
    if (null === orderBy) {
      setSelected(["tasks.created_at"]);
    } else {
      setSelected([orderBy]);
    }
  }, [orderBy]);

  const handleChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      let matchedOption = null;
      matchedOption = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOption && matchedOption.label;
    });
    if (onChange) {
      let value = null;
      value = "tasks.created_at" !== selected[0] ? selected[0] : null;
      onChange(value, selectedValue[0]);
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Order by"
      titleHidden
      choices={options}
      selected={selected}
      onChange={handleChange}
    />
  );
};
