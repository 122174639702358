// import SupplierOrdersPage from "../pages/supplier/Orders";
import MainLayout from "../layout/MainLayout";
import SupplierClaimsPage from "../pages/supplier/Claims";
import ImportTracking from "../components/supplier/ImportTracking";
import SupplierDepositsPage from "../pages/supplier/Deposits";
import SupplierTransactionsPage from "../pages/supplier/Transactions";
import ExportsPage from "../pages/supplier/Exports";
import ImportPage from "../pages/supplier/Import";
import ExportTemplatesPage from "../pages/supplier/ExportTemplate";
import AddTemplate from "../pages/supplier/AddTemplate";
import EditTemplatePage from "../pages/supplier/EditTemplate";
import { SupplierOrdersPagePolaris } from "../pages/supplier/OrdersPolaris";
import { DocumentationsPage } from "../components/documentations";

export default [
  {
    path: "/supplier/orders",
    exact: true,
    // component: SupplierOrdersPage,
    component: SupplierOrdersPagePolaris,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/claims",
    exact: true,
    component: SupplierClaimsPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/export-tracking",
    exact: true,
    component: ExportsPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/import-tracking",
    exact: true,
    component: ImportTracking,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/imports",
    exact: true,
    component: ImportPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/billings/deposits",
    exact: true,
    component: SupplierDepositsPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/billings/transactions",
    exact: true,
    component: SupplierTransactionsPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/manager-files/export-templates",
    exact: true,
    component: ExportTemplatesPage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/manager-files/add-template",
    exact: true,
    component: AddTemplate,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/manager-files/edit-template/:id",
    exact: true,
    component: EditTemplatePage,
    layout: MainLayout,
    roles: ["Supplier"],
  },
  {
    path: "/supplier/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Supplier"],
  },
];
