import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import MainLayout from "../layout/MainLayout";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import { IdeaTaskPagePolaris } from "../pages/seller/IdeaTaskPagePolaris";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import { ProductAssortmentPagePolaris } from "../pages/seller/ProductAssortmentPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";

export default [
  {
    path: "/idea/tasks",
    exact: true,
    component: IdeaTaskPagePolaris,
    title: "Idea Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products",
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/orders",
    exact: true,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/product-assortment",
    exact: true,
    component: ProductAssortmentPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
    private: true,
  },
  {
    path: "/idea/products/add",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/add/:id",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/clone",
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/products/edit/:id",
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Idea",
  },
  {
    path: "/idea/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
    private: true,
  },
  {
    path: "/idea/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
    private: true,
  },
  {
    path: "/idea/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
    private: true,
  },
  {
    path: "/idea/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Idea",
    private: true,
  },
];
