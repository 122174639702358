import React, { useMemo } from "react";
import { ComplexAssortment } from "../../components/complex-assortment";
import { getPathFromRouter } from "../../helper";

export function ComplexAssortmentPage(...props) {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);
    return <ComplexAssortment path={path} />;
}
