import get from "lodash/get";
import { isPsdByKey } from "../../../helper";

export function getArtistFile(item, isReject) {
  let name = get(item, "Name");
  const key = get(item, "Key");
  const thumbnailUrl = get(item, "thumbnail_url");
  const url = get(item, "url");
  const id = get(item, "Model.id");

  const res = {
    name,
    key,
    thumbnailUrl,
    url: isPsdByKey(key) ? thumbnailUrl : url,
    originUrl: url,
    id,
  };

  if (typeof isReject === "bool" && isReject) {
    res.style = {};
    res.style.color = "red";
  }

  if (item?.rejectReason) {
    res.rejectReason = item.rejectReason;
    res.style = {};
    res.style.color = "red";
  }

  if (item?.reject_reason) {
    res.rejectReason = item.reject_reason;
    res.style = {};
    res.style.color = "red";
  }

  return res;
}
