import {
  Badge,
  Button,
  DataTable,
  Thumbnail,
  Toast,
  Tooltip,
} from "@shopify/polaris";
import { CircleMinusMinor, CirclePlusMinor } from "@shopify/polaris-icons";
import React, { useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import noImg from "../../../assets/images/unnamed.jpeg";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { CategoryActionsPolaris } from "./CategoryActionsPolaris";

const Container = styled.div`
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  padding-left: ${(props) => `${props.level * 1.5}rem`};
  .expaned-wrap {
    margin-right: 0.5rem;
  }
`;

export const CategoryDataTablePolaris = ({ data, refetch }) => {
  // State
  const [rows, setRows] = useState([]);
  const [currentParent, setCurrentParent] = useState({});
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
  });

  const handleCurrentParentChange = useCallback((parent) => {
    setCurrentParent((prev) => ({
      ...prev,
      [parent]: !prev[parent],
    }));
  }, []);

  // Get data
  useLayoutEffect(() => {
    let { limit, offset } = filter;
    let dataSliced =
      data?.productBaseCategories?.length > 0
        ? data.productBaseCategories.slice(offset, offset + limit)
        : [];
    const newCategories = buildCategoryData(dataSliced);

    let newData =
      newCategories?.length > 0
        ? newCategories
            .map((item) => {
              const hasChildren = item?.children?.length;

              if (item?.parentId && !!!currentParent[item.parentId]) {
                return [];
              }

              const countMarkup = (
                <Tooltip content="Count of product used">
                  <Badge status="info">{item?.count || 0}</Badge>
                </Tooltip>
              );

              return [
                <ImageContainer level={item?.level}>
                  <span className="row-indent"></span>
                  {hasChildren ? (
                    <div className="expaned-wrap">
                      <Button
                        plain
                        icon={
                          !!currentParent[item?.id]
                            ? CircleMinusMinor
                            : CirclePlusMinor
                        }
                        onClick={() => handleCurrentParentChange(item?.id)}
                      />
                    </div>
                  ) : (
                    <div style={{ width: "2.5rem" }}></div>
                  )}
                  <Thumbnail source={item?.image?.url || noImg} size="medium" />
                </ImageContainer>,
                <div className="name-wrap">
                  <span>{item?.name}</span>
                </div>,
                countMarkup,
                <div className="actions-wrap">
                  <CategoryActionsPolaris
                    item={item}
                    hasChildren={hasChildren}
                    toggleActive={toggleActive}
                    refetch={refetch}
                    setNotificationChange={({ message, isError }) =>
                      setNotification({ message, isError })
                    }
                  />
                </div>,
              ];
            })
            .filter(Boolean)
        : [];
    setRows(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentParent, filter]);

  const buildCategoryData = useCallback((array = []) => {
    const result = [];
    const buildData = (array = [], level = 0) => {
      for (let item of array) {
        let { __typename, ...rest } = item;
        let build = {
          ...rest,
          level,
        };
        result.push(build);
        let newLevel = level + 1;
        if (item?.children?.length) {
          buildData(item.children, newLevel);
        }
      }
    };

    buildData(array);
    return result;
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  const handleFilterChange = useCallback(
    (offset, limit) => setFilter({ offset, limit }),
    [],
  );

  let { limit, offset } = filter;
  const total = data?.productBaseCategories?.length;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handleFilterChange,
    total,
  };

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={5000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={["text", "text", "text", "numeric"]}
        headings={["Image", "Name", "Count", "Actions"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={<PaginationPolaris aggregation={aggregation} />}
      />
    </Container>
  );
};
