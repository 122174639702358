import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { ImportFilesPolaris } from "../../components/admin/manager-files/ImportFilesPolaris";
import { useAppContext } from "../../context";
import { getParamByUserRole, getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ImportFilesPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);
    // Context
    const { currentUser } = useAppContext();
    const param = getParamByUserRole(currentUser);

    return (
        <Container>
            <Page
                title="Import Files"
                fullWidth
                primaryAction={{
                    content: "Import Tracking",
                    url: `/${param}/manager-files/import-tracking`,
                }}
            >
                <ImportFilesPolaris path={path} />
            </Page>
        </Container>
    );
};
