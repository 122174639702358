import { gql } from 'apollo-boost';

export const StoreFragment = gql`
    fragment StoreFragment on Store {
        id
        team {
            id
            name
        }
        title
        domain
        platform
        verified
        status
        owners {
            user {
                id
                firstName
                lastName
                email
                avatar {
                    id
                    thumbnailUrl
                }
            }
            store_id
            role
        }
        createdAt
        email
        apiKey
        suspended
        usingFakeVariants
        shopbasePublicDomain
        shopifyPublicDomain
        note
        ignoreCronFetch
        group {
            id
            name
        }
        groupId
        facebookUid
    }
`;
