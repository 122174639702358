import React, { useState, useCallback } from "react";
import { gql } from "apollo-boost";
import {
  Form,
  FormLayout,
  TextField,
  Card,
  ButtonGroup,
  Button,
  Toast,
  Checkbox,
  Collapsible,
  Icon,
  Labelled,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import { useAppContext } from '../../../context';
import QRCode from "qrcode";

import styled from "styled-components";
import { Query } from "@apollo/react-components";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import history from "../../../history";
import { checkRole, isEmail } from "../../../helper";
import { useMutation } from "@apollo/react-hooks";
import { handleError } from "../../../helper";
import { USER_ROLE, TEAM_ROLE } from "../../../variable";

const Container = styled.div`
  .Polaris-ButtonGroup {
    flex-direction: row-reverse;
  }
  .icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .Polaris-ChoiceList__Choices {
    display: flex;
    gap: 0 2rem;
    flex-wrap: wrap;
  }
  .role-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 1.6rem;
  }
  .change-password {
    padding: 0;
    .Polaris-FormLayout__Item {
      margin-top: 1.5rem;
      :first-child {
        margin-left: 0;
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUser!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      roles
      phone
      address
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;
const USERBYID = gql`
  query userByID($id: ID!) {
    userByID(id: $id) {
      id
      firstName
      lastName
      email
      phone
      roles
      ignoreTwoFA
      verified
      address
      qRCodeUrl
      verifiedTwoFA
      teamUser {
        id
        role
      }
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;
export const EditUserFormPolaris = ({ redirect, id, getUser }) => {
  const { currentUser } = useAppContext();
  const { isAdministrator } = checkRole(currentUser)
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [address, setAddress] = useState(null);
  const [role] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [ignoreTwoFA, setIgnoreTwoFA] = useState(false);

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [iconView, setIconView] = useState(false);
  const [isChangePw, setIsChangePw] = useState(false);
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const handleFirstNameChange = useCallback((value) => setFirstName(value), []);
  const handlLastNameChange = useCallback((value) => setLastName(value), []);
  const handlEmailChange = useCallback((value) => setEmail(value), []);
  const handlPhoneChange = useCallback((value) => setPhone(value), []);
  const handlAddressChange = useCallback((value) => setAddress(value), []);

  const [updateUser, { data, error, loading }] = useMutation(UPDATE_USER);
  const [activeToast, setActiveToast] = useState(false);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleValuePassword = (value) => {
    setErrors((prevState) => {
      let pw = null;
      if (!value) {
        pw = "Please choose a password.";
      }
      return {
        ...prevState,
        password: pw,
      };
    });
  };

  const handleValueConfirmPassword = (value) => {
    setErrors((prevState) => {
      let cpw = null;
      if (!value) {
        cpw = "Please confirm your password.";
      } else {
        if (!_.isEqual(value, password)) {
          cpw = "The two passwords that you entered do not match.";
        }
      }
      return {
        ...prevState,
        confirmPassword: cpw,
      };
    });
  };

  const hanldePasswordChange = useCallback((value) => {
    handleValuePassword(value);
    setPassword(value);
  }, []);

  const handleConfirmPassword = useCallback((value) => {
    handleValueConfirmPassword(value);
    setConfirmPassword(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  const toastMarkup = activeToast
    ? (error || (data && data.updateUser)) && (
      <Toast
        content={
          error
            ? handleError(
              error.toString().includes("uix_users_email")
                ? "Email name already in use, please try another name."
                : ""
            )
            : data && data.updateUser && "Update user success!"
        }
        error={error}
        duration={2000}
        onDismiss={toggleActive}
      />
    )
    : null;

  const handleGetData = React.useCallback((res) => {
    const ignoreTwoFA = !!res?.userByID?.ignoreTwoFA;
    getUser(res?.userByID || null);
    setIgnoreTwoFA(ignoreTwoFA);
  }, [getUser]);

  return (
    <Container>
      <Card sectioned>
        <Query
          query={USERBYID}
          variables={{
            id: id,
          }}
          onCompleted={handleGetData}
        >
          {({
            error: errorMutation,
            loading: loadingMutation,
            data: dataMutation,
          }) => {
            if (loadingMutation) return <SkeletonPagePolaris />;
            if (errorMutation) return <EmptyStatePolaris />;
            const dataUserId = dataMutation.userByID;

            let dataUserRole =
              dataUserId.teamUser !== null ? dataUserId.teamUser : "null";
            let teamRole = dataUserRole === "null" ? null : dataUserRole.role;
            let isRole = teamRole;
            if (teamRole) {
              if (teamRole === TEAM_ROLE.Admin) {
                isRole = USER_ROLE.Seller;
              } else if (
                teamRole === USER_ROLE.Supplier &&
                dataUserId.roles.includes(USER_ROLE.Seller)
              ) {
                isRole = `Seller's ${USER_ROLE.Supplier}`;
              } else if (
                teamRole === USER_ROLE.Supporter &&
                dataUserId.roles.includes(USER_ROLE.Seller)
              ) {
                isRole = `Seller's ${USER_ROLE.Supporter}`;
              }
            } else {
              if (dataUserId.roles.includes(USER_ROLE.Supporter)) {
                isRole = `MB ${USER_ROLE.Supporter}`;
              } else if (dataUserId.roles.includes(USER_ROLE.Supplier)) {
                isRole = USER_ROLE.Supplier;
              } else {
                isRole = _.head(dataUserId.roles);
              }
            }

            return (
              <Form
                onSubmit={(_event) => {
                  if (isChangePw) {
                    handleValuePassword(password);
                    handleValueConfirmPassword(confirmPassword);
                  }
                  let count = _.size(
                    _.filter(_.values(errors), (e) => e !== null)
                  );
                  if (
                    firstName !== "" &&
                    lastName !== "" &&
                    email !== "" &&
                    ((role && role.length !== 0) || role === null) &&
                    (isChangePw
                      ? password && confirmPassword && count === 0
                      : true)
                  ) {
                    let input = {
                      firstName:
                        firstName && firstName.length > 0
                          ? firstName
                          : dataUserId.firstName,
                      lastName:
                        lastName && lastName.length > 0
                          ? lastName
                          : dataUserId.lastName,
                      email:
                        email && email.length > 0 ? email : dataUserId.email,
                      phone:
                        phone && phone.length > 0
                          ? phone
                          : phone === ""
                            ? ""
                            : dataUserId.phone,
                      address:
                        address && address.length > 0
                          ? address
                          : address === ""
                            ? ""
                            : dataUserId.address,
                      roles: role && role.length > 0 ? role : dataUserId.roles,
                      avatar:
                        avatar && avatar[0] && avatar[0].id
                          ? avatar[0].id
                          : avatar && avatar.length === 0
                            ? ""
                            : dataUserId.avatar && dataUserId.avatar.id,
                      newPassword: password,
                      ignoreTwoFA,
                    };
                    updateUser({
                      variables: {
                        id: id,
                        input: { ...input, id: id },
                      },
                    })
                      .then((res) => {
                        const timer = setTimeout(() => {
                          history.push(redirect ? redirect : "/admin/users");
                        }, 1000);
                        return () => {
                          clearTimeout(timer);
                        };
                      })
                      .catch((err) => { });
                    toggleActive();
                  }
                }}
              >
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label="First Name(*)"
                      placeholder="First name"
                      type="text"
                      onChange={handleFirstNameChange}
                      value={
                        firstName !== null ? firstName : dataUserId.firstName
                      }
                      error={firstName === "" && "Please input first name!"}
                    />
                    <TextField
                      label="Last Name(*)"
                      placeholder="Last name"
                      type="text"
                      onChange={handlLastNameChange}
                      value={lastName !== null ? lastName : dataUserId.lastName}
                      error={lastName === "" && "Please input last name!"}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="email"
                      label="Email(*)"
                      placeholder="Email"
                      onChange={handlEmailChange}
                      value={email !== null ? email : dataUserId.email}
                      error={
                        (email === "" && "Please input  E-mail!") ||
                        (email &&
                          !isEmail(email) &&
                          "Email must be a valid email address.")
                      }
                    />
                  </FormLayout.Group>

                  {/* Update change password */}
                  <div className="change-password">
                    <Checkbox
                      label="Change Password"
                      checked={isChangePw}
                      onChange={() => setIsChangePw((prev) => !prev)}
                    />
                    <Collapsible
                      open={isChangePw}
                      transition={{ duration: "150ms", timingFunction: "ease" }}
                    >
                      <FormLayout.Group>
                        <TextField
                          value={password}
                          onChange={hanldePasswordChange}
                          label="New Password(*)"
                          type={!iconView ? "password" : "text"}
                          placeholder="***************"
                          suffix={iconViewMarkup}
                          error={errors.password}
                        />
                        <TextField
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                          label="Confirm New Password(*)"
                          type={!iconView ? "password" : "text"}
                          placeholder="***************"
                          suffix={iconViewMarkup}
                          error={errors.confirmPassword}
                        />
                      </FormLayout.Group>
                    </Collapsible>
                  </div>

                  <FormLayout.Group>
                    <TextField
                      label="Phone"
                      placeholder="Phone"
                      type="text"
                      onChange={handlPhoneChange}
                      value={phone !== null ? phone : dataUserId.phone}
                    />
                    <TextField
                      label="Address"
                      placeholder="Address"
                      type="text"
                      onChange={handlAddressChange}
                      value={address !== null ? address : dataUserId.address}
                    />
                  </FormLayout.Group>
                  <FormLayout>
                    <div className="role-wrap">
                      <label>Role(*)</label>
                      <Checkbox checked={true} disabled label={isRole} />
                    </div>
                  </FormLayout>
                  {/* <FormLayout.Group title="Roles(*)" key="role">
                    <Query query={ROLES}>
                      {({
                        error: errorRole,
                        loading: LoadingRole,
                        data: dataRoles,
                      }) => {
                        if (LoadingRole)
                          return (
                            <Spinner
                              accessibilityLabel="Spinner example"
                              size="small"
                              color="teal"
                            />
                          );
                        if (errorRole) return <EmptyStatePolaris />;
                        const dataRole = dataRoles.systemRoles
                          ? dataRoles.systemRoles.filter(
                              (r) => r !== "Administrator"
                            )
                          : [];
                        const dataRoleNoSeller = dataRoles.systemRoles
                          ? dataRoles.systemRoles.filter(
                              (r) => r !== "Administrator" && r !== "Seller"
                            )
                          : [];
                        const dataRoleValue = [];
                        const dataRoleValueNoSeller = [];
                        dataRole.forEach((i) => {
                          let item = {};
                          item["value"] = i;
                          item["label"] = i;
                          dataRoleValue.push(item);
                        });
                        dataRoleNoSeller.forEach((i) => {
                          let item = {};
                          item["value"] = i;
                          item["label"] = i;
                          dataRoleValueNoSeller.push(item);
                        });
                        return (
                          <div>
                            {dataUserId.roles.includes("Seller") ? (
                              <ChoiceList
                                disabled={true}
                                allowMultiple
                                choices={dataRoleValue}
                                onChange={handleRoleChange}
                                selected={role ? role : dataUserId.roles}
                                error={
                                  (role === "" ||
                                    (role && role.length === 0)) &&
                                  "Please select role!"
                                }
                              />
                            ) : (
                              <ChoiceList
                                allowMultiple
                                choices={dataRoleValueNoSeller}
                                onChange={handleRoleChange}
                                selected={role ? role : dataUserId.roles}
                                error={
                                  (role === "" ||
                                    (role && role.length === 0)) &&
                                  "Please select role!"
                                }
                              />
                            )}
                          </div>
                        );
                      }}
                    </Query>
                  </FormLayout.Group> */}
                  <FormLayout.Group>
                    <Checkbox
                      label="Ignore Two-Factor Authentication (2FA)"
                      checked={!!ignoreTwoFA}
                      onChange={setIgnoreTwoFA}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group title="Avatar" name="avatar">
                    {dataUserId.avatar ? (
                      <MediaSelectorButton
                        accept={"image/*"}
                        listType={"picture-card"}
                        singleUpload={true}
                        multiple={false}
                        onChange={(newValue) => {
                          setAvatar(newValue);
                        }}
                        value={avatar ? avatar : [dataUserId.avatar]}
                      />
                    ) : (
                      <MediaSelectorButton
                        accept={"image/*"}
                        listType={"picture-card"}
                        singleUpload={true}
                        multiple={false}
                        onChange={(newValue) => {
                          setAvatar(newValue);
                        }}
                      />
                    )}
                  </FormLayout.Group>
                  {isAdministrator ? <FormLayout.Group>
                    <ScanQR qRCodeUrl={dataUserId.qRCodeUrl} />
                  </FormLayout.Group> : null}
                  <FormLayout.Group>
                    <ButtonGroup>
                      <Button
                        submit="submit"
                        primary="primary"
                        loading={loading}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          history.push(redirect ? redirect : "/admin/users");
                        }}
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            );
          }}
        </Query>
      </Card>
      {toastMarkup}
    </Container>
  );
};

const ScanQR = ({ qRCodeUrl }) => {
  const [qrcodeUrl, setqrCodeUrl] = React.useState("");

  React.useEffect(() => {
    if (!qRCodeUrl) return;

    QRCode.toDataURL(qRCodeUrl).then(setqrCodeUrl);
  }, [qRCodeUrl]);

  return qrcodeUrl ? (
    <div>
      <Labelled children="Scan QR Code" />
      <img
        alt="Two-Factor Authentication"
        src={qrcodeUrl}
        style={{ display: "block", marginLeft: "-16px" }}
      />
    </div>
  ) : null
}