import getBrowserFingerPrint from "get-browser-fingerprint";
import React from "react";

function useFingerprint() {
  const res = React.useRef("");

  React.useEffect(() => {
    res.current = (getBrowserFingerPrint() || "").toString();
  }, []);

  return res.current;
}

export { useFingerprint };
