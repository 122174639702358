// import ProductBases from "../pages/admin/ProductBases";
import AddProductBase from "../components/admin/AddProductBase";
import AddUser from "../components/admin/AddUser";
import { AddCarrierPolaris } from "../components/admin/carriers/AddCarrierPolaris";
import { EditCarrierPolaris } from "../components/admin/carriers/EditCarrierPolaris";
import { AddCategoryPolaris } from "../components/admin/category/AddCategoryPolaris";
import { EditCategoryPolaris } from "../components/admin/category/EditCategoryPolaris";
import EditUser from "../components/admin/EditUser";
import { AddFulfillmentPolaris } from "../components/admin/fulfillment/AddFulfillmentPolaris";
import { EditFulfillmentPolaris } from "../components/admin/fulfillment/EditFulfillmentPolaris";
import { ImportTrackingPolaris } from "../components/admin/manager-files/ImportTrackingPolaris";
import { AddSupplierPolaris } from "../components/admin/supplier/AddSupplierPolaris";
import { EditSupplierPolaris } from "../components/admin/supplier/EditSupplierPolaris";
import { DocumentationsPage } from "../components/documentations";
import MainLayout from "../layout/MainLayout";
import { AddDepositPagePolaris } from "../pages/admin/AddDepositPagePolaris";
import { AddExportTemplatePagePolaris } from "../pages/admin/AddExportTemplatePagePolaris";
import { CarriersPagePolaris } from "../pages/admin/CarriersPagePolaris";
import { CategoriesPagePolaris } from "../pages/admin/CategoriesPagePolaris";
import { ClaimsPagePolaris } from "../pages/admin/ClaimsPagePolaris";
import CronJobs from "../pages/admin/CronJobs";
import { DepositsPagePolaris } from "../pages/admin/DepositsPagePolaris";
import { DevStatisticsPage } from "../pages/admin/DevStatistics";
import { EditExportTemplatePagePolaris } from "../pages/admin/EditExportTemplatePagePolaris";
import EditProductBase from "../pages/admin/EditProductBase";
import { ExportFilesPagePolaris } from "../pages/admin/ExportFilesPagePolaris";
import { ExportTemplatePagePolaris } from "../pages/admin/ExportTemplatePagePolaris";
import { FulfillmentPagePolaris } from "../pages/admin/FulfillmentPagePolaris";
import { ImportFilesPagePolaris } from "../pages/admin/ImportFilesPagePolaris";
import { AdminOrdersPagePolaris } from "../pages/admin/OrdersPolaris";
import { OverviewPagePolaris } from "../pages/admin/OverviewPagePolaris";
import { PrivateFeaturesPage } from "../pages/admin/PrivateFeaturesPage";
import { ProductBasesPagePolaris } from "../pages/admin/ProductBasesPagePolaris";
import AdminProducts from "../pages/admin/Products";
import AdminReportsPage from "../pages/admin/Reports";
import { SellerPagePolaris } from "../pages/admin/SellerPagePolaris";
import AdminSettingsPage from "../pages/admin/Settings";
import { SupplierOrdersPagePolaris } from "../pages/admin/SupplierOrdersPagePolaris";
import { SuppliersPagePolaris } from "../pages/admin/SuppliersPagePolaris";
import { SwitchFulfillmentPage } from "../pages/admin/SwitchFulfillmentPage";
import { TransactionsPagePolaris } from "../pages/admin/TransactionsPagePolaris";
import Users from "../pages/admin/Users";
import { AmazonChannelPage, EbayChannelPage, EtsyChannelPage, FacebookMKPage, OnlineStoresPage, TiktokChannelPage } from "../pages/reports";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import { PaymentAccounts } from "../pages/seller/PaymentAccount";
import PersonalizedPage from "../pages/seller/PersonalizedPage";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";

export default [
  {
    path: "/admin/product-bases",
    exact: true,
    title: "Product bases",
    // component: ProductBases,
    component: ProductBasesPagePolaris,
    roles: ["Administrator"],
    layout: MainLayout,
  },
  // {
  //   path: "/admin/products",
  //   exact: true,
  //   title: "All Products",
  //   component: AdminProducts,
  //   roles: ["Administrator"],
  //   layout: MainLayout,
  // },
  {
    path: "/admin/orders",
    exact: true,
    title: "Orders",
    // component: AdminOrdersPage,
    component: AdminOrdersPagePolaris,
    roles: ["Administrator"],
    layout: MainLayout,
  },
  {
    path: "/admin/orders/supplier",
    exact: true,
    title: "Supplier Orders",
    // component: SupplierOrdersPage,
    component: SupplierOrdersPagePolaris,
    roles: ["Administrator"],
    layout: MainLayout,
  },
  {
    path: "/admin/claims",
    exact: true,
    title: "Claims",
    // component: AdminClaimsPage,
    component: ClaimsPagePolaris,
    roles: ["Administrator"],
    layout: MainLayout,
  },
  {
    path: "/admin/reports",
    exact: true,
    title: "Reports",
    component: AdminReportsPage,
    roles: ["Administrator"],
    layout: MainLayout,
  },
  {
    path: "/admin/product-bases/add",
    exact: true,
    component: AddProductBase,
    // component: AddProductBasePolaris,
    layout: MainLayout,
    title: "Add Product Base",
    roles: ["Administrator"],
  },
  {
    path: "/admin/product-bases/edit/:id",
    exact: true,
    component: EditProductBase,
    // component: EditProductBasePolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/users",
    exact: true,
    component: Users,
    layout: MainLayout,
    title: "Users manager",
    roles: ["Administrator"],
  },
  {
    path: "/admin/users/edit/:id",
    exact: true,
    component: EditUser,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/suppliers",
    exact: true,
    // component: Suppliers,
    component: SuppliersPagePolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/sellers",
    exact: true,
    // component: Sellers,
    component: SellerPagePolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/supplier/edit/:id",
    exact: true,
    // component: EditSupplier,
    component: EditSupplierPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/supplier/add",
    exact: true,
    // component: AddSupplier,
    component: AddSupplierPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/carriers",
    exact: true,
    // component: Carriers,
    component: CarriersPagePolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/add-carriers",
    exact: true,
    // component: AddCarrier,
    component: AddCarrierPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/edit-carrier/:id",
    exact: true,
    // component: EditCarrier,
    component: EditCarrierPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/categories",
    exact: true,
    // component: Categories,
    component: CategoriesPagePolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/add-category",
    exact: true,
    // component: AddCategory,
    component: AddCategoryPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/edit-category/:id",
    exact: true,
    // component: EditCategory,
    component: EditCategoryPolaris,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/system/cron-jobs",
    exact: true,
    component: CronJobs,
    layout: MainLayout,
    roles: ["Administrator"],
    title: "Cron Jobs",
  },
  {
    path: "/admin/system/settings",
    exact: true,
    component: AdminSettingsPage,
    layout: MainLayout,
    roles: ["Administrator"],
    title: "System Settings",
  },
  {
    path: "/admin/system/private-features",
    exact: true,
    component: PrivateFeaturesPage,
    layout: MainLayout,
    roles: ["Administrator"],
    title: "Private Features",
  },
  {
    path: "/admin/fulfillment",
    exact: true,
    // component: FulfillmentPage,
    component: FulfillmentPagePolaris,
    layout: MainLayout,
    title: "Fulfillment",
    roles: ["Administrator"],
  },
  {
    path: "/admin/fulfillment/add",
    exact: true,
    // component: AddFulfillmentPage,
    component: AddFulfillmentPolaris,
    layout: MainLayout,
    title: "Add Fulfillment",
    roles: ["Administrator"],
  },
  {
    path: "/admin/fulfillment/:id",
    exact: true,
    // component: EditFulfillment,
    component: EditFulfillmentPolaris,
    layout: MainLayout,
    title: "Edit Fulfillment",
    roles: ["Administrator"],
  },
  {
    path: "/admin/billings/overview",
    exact: true,
    // component: OverviewPage,
    component: OverviewPagePolaris,
    layout: MainLayout,
    title: "Overview",
    roles: ["Administrator"],
  },
  {
    path: "/admin/billings/deposits",
    exact: true,
    // component: AdminDepositsPage,
    component: DepositsPagePolaris,
    layout: MainLayout,
    title: "All Deposits",
    roles: ["Administrator"],
  },
  {
    path: "/admin/billings/deposits/add",
    exact: true,
    // component: AdminAddDepositPage,
    component: AddDepositPagePolaris,
    layout: MainLayout,
    title: "Add New Deposit",
    roles: ["Administrator"],
  },
  {
    path: "/admin/billings/transactions",
    exact: true,
    // component: AdminTransactionsPage,
    component: TransactionsPagePolaris,
    layout: MainLayout,
    title: "All Transactions",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/import",
    exact: true,
    // component: ImportFilesPage,
    component: ImportFilesPagePolaris,
    layout: MainLayout,
    title: "Import Files",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/export",
    exact: true,
    // component: ExportFilesPage,
    component: ExportFilesPagePolaris,
    layout: MainLayout,
    title: "Export Files",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/export-templates",
    exact: true,
    // component: ExportTemplates,
    component: ExportTemplatePagePolaris,
    layout: MainLayout,
    title: "Export Templates",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/add-template",
    exact: true,
    // component: AddExportTemplatePage,
    component: AddExportTemplatePagePolaris,
    layout: MainLayout,
    title: "Export Templates",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/import-tracking",
    exact: true,
    // component: ImportTracking,
    component: ImportTrackingPolaris,
    layout: MainLayout,
    title: "Export Templates",
    roles: ["Administrator"],
  },
  {
    path: "/admin/manager-files/edit-template/:id",
    exact: true,
    // component: EditExportTemplatePage,
    component: EditExportTemplatePagePolaris,
    layout: MainLayout,
    title: "Export Templates",
    roles: ["Administrator"],
  },
  {
    path: "/admin/system/switch-fulfillment",
    exact: true,
    component: SwitchFulfillmentPage,
    layout: MainLayout,
    title: "Switch fullfillment",
    roles: ["Administrator"],
  },
  {
    path: "/admin/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/users/add",
    exact: true,
    component: AddUser,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/dev-statistics",
    exact: true,
    component: DevStatisticsPage,
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/add",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/add/:id",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/clone",
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products",
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Administrator"],
  },
  {
    path: "/admin/products/edit/:id",
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/amazon",
    exact: true,
    component: AmazonChannelPage,
    title: "Amazon Channel Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/tiktok",
    exact: true,
    component: TiktokChannelPage,
    title: "Titkok Channel Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/facebook-mk",
    exact: true,
    component: FacebookMKPage,
    title: "Facebook Marketplace Channel Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/online-stores",
    exact: true,
    component: OnlineStoresPage,
    title: "Online stores Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/ebay",
    exact: true,
    component: EbayChannelPage,
    title: "Ebay Channel Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/reports/etsy",
    exact: true,
    component: EtsyChannelPage,
    title: "Etsy Channel Page",
    layout: MainLayout,
    roles: ["Administrator"],
  },
  {
    path: "/admin/system/payment-accounts",
    exact: true,
    component: PaymentAccounts,
    title: "Payment Accounts",
    layout: MainLayout,
    roles: ["Administrator"],
  },
];
