import { TextField } from "@shopify/polaris";
import React from "react";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { ItemWrap } from "./IdeaSection";

const label = "Title";
export function ProductTitle({ canEdit, value, error, onChange }) {
    return canEdit ? (
        <div>
            <ComponentLabelPolaris label={label} required />
            <TextField
                id="title"
                placeholder="Idea title"
                onChange={onChange}
                value={value}
                error={error}
            />
        </div>
    ) : (
        <ItemWrap label={label}>
            <span title={value}>{value}</span>
        </ItemWrap>
    );
}
