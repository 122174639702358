import { Labelled, TextField } from "@shopify/polaris";
import QRCode from "qrcode";
import * as React from "react";

function Verify2FA({ url, code, onChange }) {
  const [qrcodeUrl, setqrCodeUrl] = React.useState("");

  React.useEffect(() => {
    if (!url) return;

    QRCode.toDataURL(url).then(setqrCodeUrl);
  }, [url]);

  return (
    <>
      {qrcodeUrl ? (
        <div>
          <Labelled children="Scan QR Code" />
          <img alt="Two-Factor Authentication" src={qrcodeUrl} />
        </div>
      ) : null}
      <TextField
        value={code}
        onChange={onChange}
        label="Verify Code"
        placeholder="Code"
      />
    </>
  );
}

export { Verify2FA };
