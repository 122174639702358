import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Card,
  Button,
  FormLayout,
  Loading,
  ButtonGroup,
} from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { size, filter, values } from "lodash";
import styled from "styled-components";

import { USER_ROLE } from "../../../variable";
import { handleError } from "../../../helper";

import { AutoCompleteMultiplePolaris } from "../../shared/AutoCompleteMultiplePolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { TemplateConfigPolaris } from "./TemplateConfigsPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

const EXPORT_TEMPLATE_COLUMN_ADMIN = gql`
  query exportTemplateColumns($role: String!) {
    exportTemplateColumns {
      label
      value
    }
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

const EXPORT_TEMPLATE_COLUMN_SUP = gql`
  query exportTemplateColumns {
    exportTemplateColumns {
      label
      value
    }
  }
`;

const Container = styled.div`
  .btns-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3rem;
  }
`;

export const ExportTemplateFormPolaris = ({
  isAdmin,
  onSubmit,
  loading: loadingProp,
  handleRedirect,
  value,
}) => {
  // State
  const [basicFields, setBasicFields] = useState({
    name: null,
    suppliers: [],
  });
  const [suppliers, setSuppliers] = useState([]);
  const [templateColumns, setTemplateColumns] = useState([]);

  const [errors, setErrors] = useState({});

  // Query
  const { loading, error } = useQuery(
    isAdmin ? EXPORT_TEMPLATE_COLUMN_ADMIN : EXPORT_TEMPLATE_COLUMN_SUP,
    {
      variables: {
        role: isAdmin ? USER_ROLE.Supplier : undefined,
      },
      onCompleted: (res) => {
        handleData(res);
      },
    }
  );

  // Get data
  useEffect(() => {
    if (value) {
      let { name, suppliers, columns } = value;
      let newSuppliers =
        suppliers?.length > 0 ? suppliers.map((i) => i.id) : [];

      let newColumns =
        columns?.length > 0
          ? columns.map((col) => {
              let { __typename, name, ...rest } = col;
              return { ...rest, label: name };
            })
          : [];

      setBasicFields((prev) => ({
        ...prev,
        name,
        suppliers: newSuppliers,
      }));

      setTemplateColumns(() => [...newColumns]);
    }
  }, [value]);

  // Handle actions
  const validateField = useCallback((value, id) => {
    let error = null;
    let label = id.charAt(0).toUpperCase() + id.slice(1);
    if (!value || !value.length) {
      error = `${label} is required.`;
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);

  const handleInputChange = useCallback((value, id) => {
    setBasicFields((prev) => ({
      ...prev,
      [id]: value,
    }));
    validateField(value, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = useCallback(
    (data) => {
      let newSupplier =
        data?.listUserByRole?.length > 0
          ? data.listUserByRole.map((s) => ({
              value: s.id,
              label: `${s.firstName} ${s.lastName}`,
            }))
          : [];
      setSuppliers(newSupplier);

      // Template columns
      if (!value?.columns) {
        let newTC = data?.exportTemplateColumns;
        newTC =
          newTC?.length > 0
            ? newTC.map((item) => {
                let { __typename, ...rest } = item;
                return { ...rest, type: "defined_value" };
              })
            : [];
        setTemplateColumns(newTC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value?.columns]
  );

  const handleAddColumn = useCallback(() => {
    const newData = [
      ...templateColumns,
      { value: "", type: "custom_value", label: "" },
    ];
    setTemplateColumns(() => [...newData]);
  }, [templateColumns]);

  const handleSubmit = useCallback(() => {
    for (let [key, value] of Object.entries(basicFields)) {
      validateField(value, key);
    }
    const { name, suppliers } = basicFields;
    const count = size(filter(values(errors), (e) => e !== null));

    let input = {
      name,
      suppliers,
      columns: templateColumns
        .filter((i) => i.label)
        .map((i) => {
          let { label, ...rest } = i;
          return {
            ...rest,
            name: label,
          };
        }),
    };

    if (suppliers?.length && name && count === 0) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields, templateColumns, error]);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      {loading ? (
        <Card>
          <SkeletonPagePolaris />
        </Card>
      ) : error ? (
        <Card sectioned>Error: {handleError(error.toString())}</Card>
      ) : templateColumns?.length > 0 ? (
        <React.Fragment>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <div>
                  <ComponentLabelPolaris label="Template name" required />
                  <TextField
                    value={basicFields["name"]}
                    onChange={handleInputChange}
                    error={errors["name"]}
                    id="name"
                    placeholder="Enter template name"
                  />
                </div>
                <AutoCompleteMultiplePolaris
                  data={suppliers}
                  value={basicFields["suppliers"]}
                  label="Supplier"
                  required
                  placeholder="Search for supplier"
                  onChange={(value) => handleInputChange(value, "suppliers")}
                  error={errors["suppliers"]}
                />
              </FormLayout.Group>
              <div>
                <ComponentLabelPolaris label="Template configs" required />
                <TemplateConfigPolaris
                  data={templateColumns}
                  onChange={(newValue) => {
                    setTemplateColumns(() => [...newValue]);
                    validateField([...newValue], "template");
                  }}
                />
              </div>
              <div className="add-wrap">
                <Button
                  children="Add new Column"
                  onClick={handleAddColumn}
                  icon={CirclePlusMinor}
                />
              </div>
            </FormLayout>
          </Card>
          <div className="btns-wrap">
            <ButtonGroup>
              <Button children="Cancel" onClick={handleRedirect} />
              <Button
                children="Create template"
                primary
                loading={loadingProp}
                onClick={handleSubmit}
              />
            </ButtonGroup>
          </div>
        </React.Fragment>
      ) : (
        <Card>
          <EmptyStatePolaris noData />
        </Card>
      )}
    </Container>
  );
};
