import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { MappingOrdersPolaris } from "../../components/mapping_order/MappingOrdersPolaris";
import { AppContext } from "../../context";
import { getParamByRole, getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const MappingOrderPagePolaris = (...props) => {
    const { currentUser } = React.useContext(AppContext);
    let currentParam = getParamByRole(currentUser);

    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="Mapping Orders"
                fullWidth
                breadcrumbs={[
                    { content: "Orders", url: `/${currentParam}/orders` },
                ]}
            >
                <MappingOrdersPolaris path={path}/>
            </Page>
        </Container>
    );
};
