import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .Polaris-EmptyState__Details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

function NotFoundPage() {
  return (
    <Page>
      <Container>
        <div className="Polaris-EmptyState__DetailsContainer">
          <div className="Polaris-EmptyState__Details">
            <div className="Polaris-TextContainer">
              <p className="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
                Ooops! We can't find what you're looking for.
              </p>
              <div className="Polaris-EmptyState__Content">
                <p>
                  It's probably better you head back to the dashboard and start
                  over.
                </p>
              </div>
            </div>
            <div className="Polaris-EmptyState__Actions">
              <div className="Polaris-Stack Polaris-Stack--alignmentCenter">
                <div className="Polaris-Stack__Item">
                  <a
                    data-polaris-unstyled="true"
                    className="Polaris-Button Polaris-Button--primary Polaris-Button--sizeMedium"
                    href="/"
                  >
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">
                        Back to dashboard
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Page>
  );
}

export default NotFoundPage;
