import React, { useEffect, useState } from "react";
import { AutoCompleteMultiplePolaris } from "../shared/AutoCompleteMultiplePolaris";

const OPTIONS = [
  { value: "nil", label: "All Sources" },
  { value: "ebay", label: "Ebay" },
  { value: "amazon", label: "Amazon" },
  { value: "shopbase", label: "Shopbase" },
  { value: "shopify", label: "Shopify" },
  { value: "woocommerce", label: "WooCommerce" },
  { value: "etsy", label: "Etsy" },
  { value: "facebook", label: "Facebook" },
  { value: "tiktok", label: "Tiktok" },
];

export const OrderSourcePolaris = ({
  value,
  onChange,
  labelHidden,
  options,
  defaultValue = "nil",
  exclude = [],
}) => {
  const [mergeOptions, setMergeOptions] = useState(OPTIONS);
  useEffect(() => {
    if (options?.length > 0) {
      let opts = OPTIONS.filter(({ value }) => options.includes(value));
      if (exclude?.length > 0) {
        opts = opts.map((item) => ({
          ...item,
          disabled:
            exclude.includes(item.value) &&
            (!value || value.length === 0 ? true : !value.includes(item.value)),
        }));
      }

      setMergeOptions(opts);
    }
  }, [options, value, exclude]);

  return (
    <AutoCompleteMultiplePolaris
      data={mergeOptions}
      label="Order source"
      labelHidden={labelHidden}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      placeholder={"Choose sources"}
    />
  );
};
