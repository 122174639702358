import { useApolloClient } from "@apollo/react-hooks";
import { useCallback } from "react";
import { GET_FILE_BY_ID } from "../../../graphql/queries";

export const FULFILLED = "fulfilled";
export function useFileById(ids) {
    const client = useApolloClient();

    // Actions
    const getFiles = useCallback(async () => {
        if (!ids || ids.length === 0) return;
        try {
            const res = await Promise.allSettled(
                ids.map(async (id) => {
                    const value = await client.query({
                        query: GET_FILE_BY_ID,
                        variables: { id },
                    });
                    return value;
                })
            );

            return res
                .filter(
                    (item) =>
                        item.status === FULFILLED &&
                        item.value?.data?.fileById != null
                )
                .map((item) => item.value.data.fileById);
        } catch (err) {
            console.error(err?.toString());
        }
    }, [ids, client]);

    return [getFiles];
}
