import React, { useRef } from "react";
import { Modal, TextField, Checkbox, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { MARK_AWAITING_APPROVAL } from "../../../graphql/mutations";
import { STATUS_NEED_REVIEW } from "../TableTasksPolaris";
import { useToastContext } from "../../shared/ToastContext";

export function AwaitApproval({
    open,
    toggleShowModal,
    task,
    toggleActive,
    onNotificationChange,
    refetch,
}) {
    const taskId = task?.id;
    const status = task?.status;
    const isAwaitingApproval = task?.isAwaitingApproval;
    const noteProp = task?.note;

    const isNeedReview = [STATUS_NEED_REVIEW].includes(status);
    const title = isNeedReview ? "Mark await approval" : "Note";

    // State
    const [note, setNote] = React.useState("");
    const [isWaiting, setIsWaiting] = React.useState(false);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [awaitApproval, { loading }] = useMutation(MARK_AWAITING_APPROVAL, {
        onCompleted: () => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: `${
                        isNeedReview ? "Mark awaiting approval" : "Note"
                    } successfully.`,
                    isError: false,
                });
            }
            toggleShowModal && toggleShowModal();

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
    });

    // Get data
    React.useEffect(() => {
        if (isAwaitingApproval != null) {
            setIsWaiting(isAwaitingApproval);
            if (isAwaitingApproval) {
                setNote(noteProp);
            }
        }
    }, [isAwaitingApproval, noteProp]);

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        if (taskId) {
            toggleActive && toggleActive();
            if (onNotificationChange) {
                onNotificationChange({ message: null, isError: false });
            }

            const input = {
                taskId,
                isWaiting,
                note,
            };
            awaitApproval({
                variables: {
                    ...input,
                },
            });
        }
    }, [
        onNotificationChange,
        toggleActive,
        taskId,
        isWaiting,
        note,
        awaitApproval,
    ]);

    return (
        <Modal
            title={title}
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Stack vertical spacing="tight">
                {isNeedReview && (
                    <Checkbox
                        checked={isWaiting}
                        onChange={setIsWaiting}
                        label="Waiting approval"
                    />
                )}
                <TextField
                    label="Note"
                    value={note}
                    onChange={setNote}
                    multiline={4}
                />
            </Stack>
        </Modal>
    );
}
