import React, { useCallback, useContext, useRef } from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { SheetPolaris } from "../../shared/SheetPolaris";
import { LIST_TAG } from "./ListTagPolaris";
import { FormTagPolaris } from "./FormTagPolaris";
import { TagContext } from "./TagContext";

export const TagFragment = gql`
    fragment TagFragment on Tag {
        id
        name
        count
    }
`;

const ADD_TAG = gql`
    mutation createTag($name: String!) {
        createTag(name: $name) {
            ...TagFragment
        }
    }
    ${TagFragment}
`;

export const AddTagPolaris = ({ handleSheetChange, filter }) => {
    // Context
    const { toggleToast, notifyChange } = useContext(TagContext);
    // Ref
    const timeoutRef = useRef(null);

    const [createTag, { loading }] = useMutation(ADD_TAG, {
        onCompleted: () => {
            if (notifyChange) {
                notifyChange({ msg: "Add tag successfully.", err: false });
            }
        },
        onError: (err) => {
            if (notifyChange) {
                notifyChange({ msg: handleError(err.toString()), err: true });
            }
        },
    });

    // Actions
    const handleSubmit = useCallback(
        (value) => {
            if (toggleToast) {
                toggleToast(true);
            }
            if (notifyChange) {
                notifyChange({ msg: null, err: false });
            }
            createTag({
                variables: {
                    name: value,
                },
                update: (cache, { data }) => {
                    const newTag = data.createTag;
                    if (newTag != null) {
                        const variables = {
                            filter,
                        };
                        const { tags } = cache.readQuery({
                            query: LIST_TAG,
                            variables,
                        });

                        cache.writeQuery({
                            query: LIST_TAG,
                            variables,
                            data: {
                                tags: {
                                    ...tags,
                                    total: tags.total + 1,
                                    nodes: [newTag, ...tags.nodes],
                                },
                            },
                        });
                    }
                },
            })
                .then(() => {
                    timeoutRef.current && clearTimeout(timeoutRef.current);
                    timeoutRef.current = setTimeout(() => {
                        handleSheetChange();
                    }, 2000);
                })
                .catch((_) => {});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter, toggleToast, notifyChange]
    );

    return (
        <SheetPolaris title="Add Tag" handleSheetChange={handleSheetChange}>
            <FormTagPolaris
                handleSubmitProps={(value) => handleSubmit(value)}
                handleSheetChange={handleSheetChange}
                loading={loading}
                buttonLabel="Add"
            />
        </SheetPolaris>
    );
};
