import React, {
    forwardRef,
    Fragment,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import moment from "moment";
import { Button, Checkbox, Select, Stack } from "@shopify/polaris";
import { useReportsContext } from "../context";
import { cloneDeep } from "lodash";

const PREVIOUS_PERIOD = "previousPeriod";
const AllTime = "All time";

function CompareToDatesInner({ activatorLabel, isCompare, setIsCompare }, ref) {
    // Props
    const isAllTime = (activatorLabel || []).includes(AllTime);

    // Context
    const {
        setRangeToCompare,
        range: rangeCtx,
        setIsCompare: setIsCompareCtx,
        setLoading,
        loading,
    } = useReportsContext();

    // State
    const [range, setRange] = useState(rangeCtx || null);
    const [selected, setSelected] = useState(PREVIOUS_PERIOD);
    const loadingRef = useRef(null);
    const [options, setOptions] = useState([
        {
            label: "Previous period (Yesterday)",
            value: PREVIOUS_PERIOD,
        },
        {
            label: `Previous year (${moment()
                .subtract(1, "years")
                .format("DD-MM-YYYY")})`,
            value: "previousYear",
        },
    ]);

    // Effects
    useEffect(() => {
        if (
            rangeCtx &&
            Object.prototype.hasOwnProperty.call(rangeCtx, "from")
        ) {
            let newRange = JSON.parse(JSON.stringify(rangeCtx));
            const isPrevPeriod = selected === PREVIOUS_PERIOD;
            let label = { period: "", year: "" };

            const rangePrevPeriod =
                getTime(cloneDeep(newRange), activatorLabel) || {};
            label.period = getLabel(rangePrevPeriod, activatorLabel);

            const rangePrevYear =
                getTime(cloneDeep(newRange), activatorLabel, true) || {};
            label.year = getLabel(rangePrevYear, activatorLabel, true);

            const currentRange = isPrevPeriod ? rangePrevPeriod : rangePrevYear;
            setRange(currentRange);

            setOptions((prev) =>
                prev.map((opt) => {
                    if (opt.value === PREVIOUS_PERIOD) {
                        return {
                            ...opt,
                            label: `Previous period (${label.period})`,
                        };
                    }
                    return {
                        ...opt,
                        label: `Previous year (${label.year})`,
                    };
                })
            );
        }
    }, [rangeCtx, selected, activatorLabel]);

    // Actions
    const handleCompareChange = useCallback(
        (value) => {
            setIsCompare(value);
        },
        [setIsCompare]
    );

    const handleSelectChange = useCallback((value) => {
        setSelected(value);
    }, []);

    const handleApply = useCallback(() => {
        setIsCompareCtx(isCompare);

        if (isCompare) {
            setRangeToCompare(range);
        }

        setLoading(() => true);
        loadingRef.current = setTimeout(() => {
            setLoading(() => false);
        }, 1000);
    }, [range, isCompare, setLoading, setIsCompareCtx, setRangeToCompare]);

    const handleTriggerCompare = useCallback(() => {
        if (!isCompare) return;

        if (isAllTime) {
            setIsCompareCtx(null);
            setRangeToCompare(null);

            setLoading(() => true);
            loadingRef.current = setTimeout(() => {
                setLoading(() => false);
            }, 1000);
            return;
        }
        setIsCompareCtx(isCompare);
        setRangeToCompare(range);
    }, [isAllTime, isCompare, range, setRangeToCompare, setLoading]);

    useImperativeHandle(
        ref,
        () => ({
            onCompare: handleTriggerCompare,
        }),
        [handleTriggerCompare]
    );

    useEffect(
        () => () => {
            if (loadingRef.current) clearTimeout(loadingRef.current);
        },
        []
    );

    return (
        <Fragment>
            <Stack vertical>
                <Checkbox
                    label="Compare to Dates"
                    checked={isCompare}
                    onChange={handleCompareChange}
                    disabled={isAllTime}
                />
                <Select
                    options={options}
                    value={selected}
                    onChange={handleSelectChange}
                    disabled={isAllTime}
                />
                <Button
                    primary
                    children="Apply"
                    onClick={handleApply}
                    loading={loading}
                    disabled={isCompare == null || isAllTime}
                />
            </Stack>
        </Fragment>
    );
}

function getLabel(range, selected, year) {
    let label = year
        ? moment().subtract(1, "years").format("DD-MM-YYYY")
        : "Yesterday";
    if (selected[0] !== "Today" && range != null) {
        const from = moment(range.from).format("YYYY-MM-DD");
        const to = moment(range.to).format("YYYY-MM-DD");

        label = [from, to].filter(Boolean).join(" - ");
    }

    return label;
}

const SPLIT_PT = /\s+\-\s+/;
function getTime(range, selected, prevYear) {
    if (prevYear) {
        range.from = moment(range.from).subtract(1, "years");
        range.to = moment(range.to).subtract(1, "years");
    } else {
        if (Array.isArray(selected)) {
            switch (selected[0]) {
                case "Today":
                case "Yesterday":
                    range.from = moment(range.from)
                        .subtract(1, "days")
                        .startOf("day");
                    range.to = moment(range.to)
                        .subtract(1, "days")
                        .endOf("day");
                    break;
                case "Last 7 days":
                    range.from = moment(range.from)
                        .subtract(7, "days")
                        .startOf("day");
                    range.to = moment(range.to)
                        .subtract(7, "days")
                        .endOf("day");
                    break;
                case "This month":
                case "Last month":
                    range.from = moment(range.from)
                        .subtract(1, "months")
                        .startOf("months");
                    range.to = moment(range.to)
                        .subtract(1, "months")
                        .endOf("months");
                    break;
                default:
                    range = null;
            }
        } else if (SPLIT_PT.test(selected)) {
            const [first, second] = selected.split(SPLIT_PT).filter(Boolean);
            const diff = moment(second).diff(moment(first));
            const duration = moment.duration(diff);

            const days = duration.asDays();
            if (second != null) {
                const newFrom = moment(first).subtract(1, "days").endOf("day");
                range.to = newFrom;
                range.from = moment(newFrom)
                    .subtract(Math.max(days, 0), "days")
                    .startOf("day");
            }
        }
    }

    return range;
}

export const CompareToDates = forwardRef(CompareToDatesInner);
