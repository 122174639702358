import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { AutoComplete, Input, notification, Tag, Button } from "antd";
import { ApolloConsumer, Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";
import _ from "lodash";
import { AppContext } from "../../context";

const STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const PUSH_PRODUCT = gql`
  mutation pushProductToStore($id: ID!, $storeId: ID!) {
    pushProductToStore(id: $id, storeId: $storeId)
  }
`;

class StoresAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      loading: false,
      result: [],
      loadingPushProduct: false,
    };
    this.searchDebounce = _.debounce(this.onSearch, 250);
    this.onSearch = this.onSearch.bind(this);
  }
  onSearch = (client, search) => {
    let { isMarketplaceManager } = this.props;
    this.setState({ loading: true }, async () => {
      try {
        const res = await client.query({
          query: STORES,
          fetchPolicy: "network-only",
          variables: {
            filter: {
              search: search,
              platformSelected: isMarketplaceManager
                ? ["amazon", "ebay"]
                : ["woocommerce", "shopify", "shopbase"],
            },
          },
        });
        this.setState({
          loading: false,
          result: _.get(res, "data.stores.nodes", []),
          // result: res.data && res.data.products ? res.data.products.nodes : []
        });
      } catch (e) {
        this.setState(
          {
            loading: true,
          },
          () => {
            notification.error({ message: handleError(e.soString()) });
          }
        );
      }
    });
  };
  handleSelect = (p) => {
    const { value, isEditProduct } = this.props;
    if (this.props.onChange) {
      if (isEditProduct) {
        this.props.onChange([p]);
      } else {
        this.props.onChange([...value, p]);
      }
    }
  };
  render() {
    const {
      value,
      productId,
      isEditProduct,
      refetch,
      isStoreManager,
      isMarketplaceManager,
    } = this.props;
    const { result, loading, search, loadingPushProduct } = this.state;
    const options = result
      .filter((r) => !value.find((v) => v.id === r.id))
      .map((item, index) => (
        <AutoComplete.Option value={item.id} key={index}>
          {item.title}
        </AutoComplete.Option>
      ));
    return (
      <AppContext.Consumer>
        {({ store }) =>
          store && !isStoreManager && !isMarketplaceManager ? (
            <Input value={store.title} />
          ) : (
            <>
              <ApolloConsumer>
                {(client) => (
                  <AutoComplete
                    style={{ width: "100%" }}
                    onSelect={(selected) => {
                      const p = result.find((r) => r.id === selected);
                      this.handleSelect(p);
                      this.setState({
                        search: "",
                        result: [],
                      });
                    }}
                    value={search}
                    dataSource={options}
                    onSearch={(s) => {
                      if (this.state.search !== s) {
                        this.setState({ search: s, loading: true }, () => {
                          this.searchDebounce(client, s);
                        });
                      }
                    }}
                    onFocus={() => {
                      this.setState({ result: [] });
                      this.searchDebounce(client);
                    }}
                  >
                    <Input.Search
                      placeholder={"Search for stores"}
                      prefix={
                        <LegacyIcon type={loading ? "loading" : "search"} />
                      }
                    />
                  </AutoComplete>
                )}
              </ApolloConsumer>
              {value.map((p, index) => (
                <Tag
                  style={{ margin: 5, marginLeft: 0, marginTop: 7 }}
                  closable={true}
                  onClose={() => {
                    value.splice(index, 1);
                    if (this.props.onChange) {
                      this.props.onChange(value);
                    }
                  }}
                  key={index}
                >
                  {p.title}
                </Tag>
              ))}
              {value.length && isEditProduct ? (
                <Mutation
                  mutation={PUSH_PRODUCT}
                  onError={(e) => {
                    notification.error({
                      message: handleError(e.toString()),
                    });
                    this.setState({ loadingPushProduct: false });
                  }}
                  onCompleted={() => {
                    this.setState({ loadingPushProduct: false }, () => {
                      notification.success({
                        message: "Your push product has been saved!",
                      });
                    });
                    if (refetch) return refetch();
                  }}
                >
                  {(pushProductToStore) => (
                    <Button
                      className="mt-8 block"
                      loading={loadingPushProduct}
                      type="primary"
                      onClick={() => {
                        this.setState({ loadingPushProduct: true });
                        pushProductToStore({
                          variables: {
                            id: productId,
                            storeId: value[0].id,
                          },
                        });
                      }}
                    >
                      Push Product
                    </Button>
                  )}
                </Mutation>
              ) : null}
            </>
          )
        }
      </AppContext.Consumer>
    );
  }
}
StoresAutoComplete.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default StoresAutoComplete;
