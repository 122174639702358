import React, { useState, useCallback, useEffect } from "react";
import { Icon, Spinner, TextField, ChoiceList } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

export default function FilterStorePolaris(props) {
  const {
    setStore,
    error,
    setInputValue,
    searchStore,
    storeId,
    dataStore,
  } = props;

  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataStore && dataStore.length) {
      setDeselectedOptions(dataStore);
      if (searchStore) {
        const filterRegex = new RegExp(searchStore, "i");
        const resultOptions = dataStore.filter((option) =>
          option.label.match(filterRegex)
        );
        setOptions(resultOptions.slice(0, 10));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStore]);

  useEffect(() => {
    if (storeId && dataStore) {
      const resultOptions = dataStore.filter(
        (option) => option.value === storeId
      );
      setOptions(resultOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const handleChangeInput = useCallback(
    (newValue) => {
      if (setInputValue) {
        setInputValue(newValue);
      }
      setLoading(true);

      if ("" === newValue) {
        const id = setTimeout(() => {
          setLoading(false);
          setOptions([]);
        }, 1000);
        setTimeoutId(id);
        return;
      }

      const filterRegex = new RegExp(newValue, "i");
      let resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      resultOptions = resultOptions.slice(0, 10);
      const id = setTimeout(() => {
        setLoading(false);
        setOptions(resultOptions);
      }, 1000);

      setTimeoutId(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      if (setStore) {
        setStore(selected[0], selectedValue[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <>
      <TextField
        value={searchStore}
        onChange={handleChangeInput}
        prefix={<Icon source={SearchMinor} color="inkLighter" />}
        autoFocus={true}
        placeholder="Filter stores"
      />
      <div className="mt-4">
        {loading && (
          <div className="flex justify-center">
            <Spinner
              accessibilityLabel="Spinner example"
              color="teal"
              size="small"
            />
          </div>
        )}
        {error && <div>{error.toString()}</div>}
        {!loading && !error && options && (
          <ChoiceList
            title="Stores"
            choices={options}
            selected={storeId || []}
            onChange={updateSelection}
            titleHidden={true}
          />
        )}
      </div>
    </>
  );
}
