import { Filters } from "@shopify/polaris";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { FilterCreatedTimePolaris } from "../../product/FilterCreatedTimePolaris";

const All = "All";
const ORDER_OPTIONS = [
  { value: All, label: All },
  { value: "total_orders", label: "Total Orders" },
  { value: "total_cost", label: "Total Cost" },
  { value: "order_created_at_str", label: "Order Created" },
];

const ORDER_BY_OPTIONS = [
  { value: All, label: All },
  { value: "ASC", label: "Ascending" },
  { value: "DESC", label: "Descending" },
];

const initValue = {
  value: null,
  label: null,
};

export default function ReportTotalCostFilter({ filter, onChange }) {
  const [inputValue, setInputValue] = useState(filter.search);
  const orderProp = filter?.order;
  const orderByProp = filter?.orderBy;

  const [order, setOrder] = useState({
    value: orderProp,
    label: null,
  });

  const [orderBy, setOrderBy] = useState({
    value: orderByProp,
    label: null,
  });

  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });

  // Change filter for query
  const typingTimeoutRef = React.useRef(null);
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    const orderVal = order.value !== All ? order.value : null;
    const orderByVal = orderBy.value !== All ? orderBy.value : null;
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: inputValue ? inputValue.trim() : inputValue,
          order: orderVal,
          orderBy: orderByVal,
          filterByTime: filterTime.range,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, order.value, orderBy.value, filterTime.range]);

  const handleQueryChange = useCallback((value) => setInputValue(value), []);

  const handleQueryRemove = useCallback(() => setInputValue(""), []);
  const handleFilterTimeRemove = useCallback(
    () => setFilterTime({ range: null, rangeLabel: null }),
    [],
  );

  const updateValue = useCallback((state, setState) => {
    setState((prev) => ({ ...prev, ...state }));
  }, []);

  const handleOrderChange = useCallback(
    (newState) => updateValue(newState, setOrder),
    [updateValue],
  );

  const handleOrderByChange = useCallback(
    (newState) => updateValue(newState, setOrderBy),
    [updateValue],
  );

  const handleOrderRemove = useCallback(
    () => updateValue(initValue, setOrder),
    [updateValue],
  );

  const handleOrderByRemove = useCallback(
    () => updateValue(initValue, setOrderBy),
    [updateValue],
  );

  const clearFilterAll = useMemo(
    () => [handleOrderRemove, handleOrderByRemove, handleFilterTimeRemove],
    [handleOrderRemove, handleOrderByRemove, handleFilterTimeRemove],
  );

  const handleFiltersClearAll = useCallback(() => {
    for (let fn of clearFilterAll) {
      fn && fn();
    }
  }, [...clearFilterAll]);

  useEffect(() => {
    let item = {};
    if (orderProp == null) {
      item = { value: All, label: All };
    } else if (orderProp) {
      item = ORDER_OPTIONS.find((node) => node.value === orderProp);
    }

    if (item != null) {
      updateValue(item, setOrder);
    }
  }, [orderProp, updateValue]);

  useEffect(() => {
    let item = {};
    if (orderByProp == null) {
      item = { value: All, label: All };
    } else if (orderByProp) {
      item = ORDER_BY_OPTIONS.find((node) => node.value === orderByProp);
    }

    if (item != null) {
      updateValue(item, setOrderBy);
    }
  }, [orderByProp, updateValue]);

  // Markup
  const filters = React.useMemo(() => {
    return [
      {
        key: "order",
        label: "Order",
        tagLabel: order.label,
        onRemove: handleOrderRemove,
        filter: (
          <FilterListRadioPolaris
            options={ORDER_OPTIONS}
            value={order.value}
            onChange={handleOrderChange}
          />
        ),
      },
      {
        key: "orderBy",
        label: "Order By",
        tagLabel: orderBy.label,
        onRemove: handleOrderByRemove,
        filter: (
          <FilterListRadioPolaris
            options={ORDER_BY_OPTIONS}
            value={orderBy.value}
            onChange={handleOrderByChange}
          />
        ),
      },
      {
        key: "filterTime",
        label: "Filter Time",
        tagLabel: filterTime.rangeLabel,
        onRemove: handleFilterTimeRemove,
        filter: (
          <FilterCreatedTimePolaris
            filterTime={filterTime}
            onChangeRange={(range, rangeLabel) => {
              setFilterTime((filterTime) => ({
                ...filterTime,
                range,
                rangeLabel,
              }));
            }}
          />
        ),
      },
    ];
  }, [
    order,
    orderBy,
    filterTime,
    handleOrderChange,
    handleOrderRemove,
    handleOrderByRemove,
    handleOrderByChange,
    handleFilterTimeRemove,
  ]);

  const appliedFilters = [];
  for (let { key, onRemove, tagLabel } of filters) {
    if (!isEmpty(tagLabel) && All !== tagLabel) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, tagLabel),
        onRemove,
      });
    }
  }

  return (
    <Wrapper>
      <Filters
        queryValue={inputValue}
        queryPlaceholder="Search .."
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryRemove}
        onClearAll={handleFiltersClearAll}
      />
    </Wrapper>
  );
}

function disambiguateLabel(key, value) {
  switch (key) {
    case "order":
      return `Order: ${value}`;
    case "orderBy":
      return `Order By: ${value}`;
    case "filterTime":
      return `Filter time: ${value}`;
    default:
      return value;
  }
}

const Wrapper = styled.div``;
