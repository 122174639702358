import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";

class SellerProductBasesPage extends Component {
  render() {
    return (
      <div>
        <PageTitle title={"Products"} />
        <p>Product bases for Seller</p>
      </div>
    );
  }
}

export default SellerProductBasesPage;
