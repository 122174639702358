import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { CREATE_ORDER_LABEL_FOR_CUSTOM_FULFILLMENT } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";
import { handleError } from "../../../helper";

export default function BuyTiktokLabel({
  orderIDs,
  open,
  onClose,
  handleCompleted,
}) {
  const { setNotify, toggleToast } = useToastContext();

  const [buyLabel, { loading }] = useMutation(
    CREATE_ORDER_LABEL_FOR_CUSTOM_FULFILLMENT,
    {
      onCompleted: () => {
        setNotify({ msg: "Buy Tiktok Label successfully.", err: false });

        onClose && onClose();
        handleCompleted && handleCompleted();
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    if (!orderIDs || orderIDs.length === 0) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: true });
    buyLabel({
      variables: {
        orderIDs: orderIDs,
      },
    });
  }, [orderIDs, setNotify, toggleToast, buyLabel]);

  return (
    <Modal
      title="Buy Tiktok Label"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to buy Tiktok Label?</p>
    </Modal>
  );
}
