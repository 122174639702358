import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Loading, Page, Sheet, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import useToggle from "../../../hooks/useToggle";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { AddTagPolaris, TagFragment } from "./AddTagPolaris";
import { FilterTagPolaris } from "./FilterTagPolaris";
import { TableTagsPolaris } from "./TableTagsPolaris";
import { TagContext } from "./TagContext";

export const LIST_TAG = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      total
      nodes {
        ...TagFragment
      }
    }
  }
  ${TagFragment}
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const FIELD_FILTER = [...COMMON_FILTER];

export const ListTagPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageTags")) || 20,
    offset: 0,
    search: null,
    ...initFilter,
  });
  const [sheetActive, setSheetActive] = useState(false);
  const [toastActive, toggleToast] = useToggle(false);
  const [notify, notifyChange] = useState({ msg: null, err: false });

  // Query
  const { data, loading, error } = useQuery(LIST_TAG, {
    variables: { filter },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { offset, limit, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleSheetChange = useCallback(() => {
    setSheetActive((s) => !s);
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      onDismiss={toggleToast}
      duration={1500}
      error={notify.err}
    />
  );

  // Context value
  const provider = {
    toggleToast,
    notifyChange,
  };

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <TagContext.Provider value={provider}>
        <Page
          title="All Tags"
          fullWidth
          primaryAction={
            <Button primary onClick={handleSheetChange}>
              New Tag
            </Button>
          }
        >
          <Card sectioned>
            <FilterTagPolaris
              filter={filter}
              onChange={(rest) => {
                let { search } = rest;
                setFilter((prevState) => {
                  if (!_.isEqual(prevState.search, search)) {
                    prevState.offset = 0;
                  }
                  return {
                    ...prevState,
                    ...rest,
                  };
                });
              }}
            />
          </Card>
          <Card>
            {error && <div>Error: {error.toString()}</div>}
            {loading ? (
              <SkeletonPagePolaris />
            ) : data?.tags?.nodes?.length > 0 ? (
              <TableTagsPolaris
                data={data}
                filter={filter}
                setFilter={(offset, limit) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    offset,
                    limit,
                  }));
                  setCookie("perPageTags", limit, 100);
                }}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
          <Sheet onClose={handleSheetChange} open={sheetActive}>
            <AddTagPolaris
              handleSheetChange={handleSheetChange}
              filter={filter}
            />
          </Sheet>
        </Page>
      </TagContext.Provider>
    </Container>
  );
};
