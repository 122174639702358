import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  ChoiceList,
  Collapsible,
  DataTable,
  Form,
  FormLayout,
  Heading,
  Icon, Scrollable,
  Select,
  SettingToggle,
  Spinner,
  Stack,
  TextField
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { arrInvalid, getUnique } from "../../helper";
import history from "../../history";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { CSVFiles } from "./PushProductRenderItemPolaris";

const StoreSelect = React.lazy(() => import("../store/components/StoreSelect"));
const BaseSelect = React.lazy(() => import("../base/components/BaseSelect"));
const TagSelect = React.lazy(() => import("../tag/components/TagSelect"));
const CollectionSelect = React.lazy(() =>
  import("../collection/components/CollectionSelect")
);
const ProductSelect = React.lazy(() =>
  import("../product/components/ProductSelectV2")
);

const Container = styled.div`
  div[role="combobox"]:focus {
    outline: none;
  }
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
  .label-continue {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
  }

  .advance-settings-wrap {
    padding-top: 1.6rem;
    margin-top: 3.2rem;
    border-top: 0.2rem solid rgb(223, 229, 237);
  }

  .list-type .Polaris-ChoiceList__Choices {
    display: flex;
    column-gap: 2rem;
  }
`;

const VariantWrapper = styled.div`
  margin-top: 1rem;

  .search-wrap {
    padding-bottom: 1.6rem;
  }

  .content-wrap {
    margin-top: -1.6rem;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .base-title-wrap,
  .variation-wrap {
    max-width: 40rem;
  }
`;

const MODIFY_VARIANT_TYPE = {
  Add: "Add",
  Subtract: "Subtract",
  Customize: "Customize",
};

export const NewPushProductsFormPolaris = ({
  btnLabel,
  onSubmit,
  loading,
  value,
  param,
}) => {
  const [title, setTitle] = useState(null);
  const [productBaseIds, setProductBaseIds] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [includeCollections, setIncludeCollections] = useState([]);
  const [excludeCollections, setExcludeCollections] = useState([]);
  const [includeTags, setIncludeTags] = useState([]);
  const [excludeTags, setExcludeTags] = useState([]);
  const [storeId, setStoreId] = useState([]);
  const [limit, setLimit] = useState("0");
  const [order, setOrder] = useState("none");
  const [orderBy, setOrderBy] = useState("none");
  const [isContinue, setIsContinue] = useState(false);
  const [isPersonalize, setIsPersonalize] = useState(false);
  const [onlyPushHasSale, setOnlyPushHasSale] = useState(false);
  const [matchAnyCollections, setMatchAnyCollections] = useState(false);
  const [matchAnyTags, setMatchAnyTags] = useState(false);
  const [errors, setErrors] = useState({
    title: null,
    store: null,
    limit: null,
  });
  const [isPrompt, setIsPrompt] = useState(false);
  const [baseSelected, setBaseSelected] = useState([]);
  const [limitType, setLimitType] = useState([]);

  const [settings, setSettings] = useState({
    ignoreSalePrice: false,
    modifyVariantPrice: false,
    modifyVariantType: [MODIFY_VARIANT_TYPE.Add],
    baseVariants: [],
  });
  const [fixedPrice, setFixedPrice] = useState({
    rPrice: "",
    sPrice: "",
  });
  const baseSelectedRef = useRef(false);
  const [onlyGenerateCsv, setOnlyGenerateCsv] = useState(false);

  // State
  const REQUIRED_FIELDS = useMemo(
    () => ({
      rPrice: "Regular price",
      sPrice: "Sale price",
    }),
    []
  );

  // Action
  const handleValidateValue = useCallback(
    (value, id) => {
      let err, label;
      for (let [key, value] of Object.entries(REQUIRED_FIELDS)) {
        if (key === id) {
          label = value;
        }
      }

      if (!value && label) {
        err = `${label} is required.`;
      }
      setErrors((prev) => ({ ...prev, [id]: err }));
    },
    [REQUIRED_FIELDS]
  );

  const showFixed = settings.modifyVariantType.some((i) =>
    [MODIFY_VARIANT_TYPE.Add, MODIFY_VARIANT_TYPE.Subtract].includes(i)
  );
  useEffect(() => {
    if (value) {
      let collections = _.get(value, "collections", []);
      let tags = _.get(value, "tags", []);
      let specialProducts = (_.get(value, "specialProducts", []) || []).filter(
        Boolean
      );
      let iCol = filterTaxonomies(collections, true, "collection");
      let eCol = filterTaxonomies(collections, false, "collection");
      let includeTags = filterTaxonomies(tags, true, "tag");
      let excludeTags = filterTaxonomies(tags, false, "tag");
      let storeIds = _.get(value, "stores", []).map((i) => i.id);
      let productBaseIds = _.get(value, "productBases", []);
      productBaseIds =
        productBaseIds && productBaseIds.length ? productBaseIds : [];

      setTitle(_.get(value, "title", null));
      setProductBaseIds(productBaseIds);
      setSpecialProducts(specialProducts);
      setIncludeCollections(iCol);
      setExcludeCollections(eCol);
      setIncludeTags(includeTags);
      setExcludeTags(excludeTags);
      setStoreId(storeIds);
      setLimit(String(_.get(value, "limit", 0)));
      setOrder(_.get(value, "order", "none"));
      setOrderBy(_.get(value, "orderBy", "none"));
      setIsContinue(_.get(value, "continue", false));
      setIsPersonalize(_.get(value, "isPersonalize", false));
      setOnlyPushHasSale(_.get(value, "onlyPushHasSale", false));
      setMatchAnyCollections(value?.matchAnyCollections || false);
      setMatchAnyTags(value?.matchAnyTags || false);
      setOnlyGenerateCsv(value?.onlyGenerateCsv || false);
      if (value?.limitType) {
        setLimitType([value.limitType]);
      }

      // Settings
      const { setting } = value;
      if (setting != null) {
        let {
          ignoreSalePrice,
          modifyVariantPrice,
          modifyVariantType,
          baseVariants,
        } = setting;

        const bases = mapBases(baseVariants);
        const newVariants = genVariants(bases);
        setSettings((prev) => ({
          ...prev,
          ignoreSalePrice,
          modifyVariantPrice,
          baseVariants: newVariants,
          modifyVariantType: modifyVariantType
            ? [modifyVariantType]
            : prev.modifyVariantType,
        }));

        if (showFixed) {
          const [first] = baseVariants || [];
          const { regularPrice, salePrice } = first || {};

          const rPrice = regularPrice ? String(regularPrice) : regularPrice;
          const sPrice = salePrice ? String(salePrice) : salePrice;
          setFixedPrice((prev) => ({ ...prev, rPrice, sPrice }));
        }
      }
    }
  }, [value]);

  /** Case: modifyVariantPrice = false, => gen Variants by base */
  useEffect(() => {
    if (!baseSelectedRef.current && baseSelected?.length > 0) {
      const newBases = (baseSelected || []).map((i) => {
        const newVars = (i.variants || []).map((va) => {
          return {
            ...va,
            productBaseVariantId: va.id,
            id: undefined,
          };
        });

        return {
          ...i,
          variants: newVars,
        };
      });

      const variants = genVariants(newBases);
      const currentVariants = settings.baseVariants;

      const key = "mergedId";
      let mergedVars = [...currentVariants, ...variants].map((i) => ({
        ...i,
        [key]: `${i.productBaseId}-${i.productBaseVariantId}`,
      }));
      mergedVars = getUnique(mergedVars, key);

      setSettings((prev) => ({ ...prev, baseVariants: mergedVars }));
      baseSelectedRef.current = true;
    }
  }, [baseSelected]);

  const handleTitleChange = useCallback((value) => {
    setTitle(value);
    handleTitleValue(value);
    setIsPrompt(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductBase = useCallback(
    (_value, bases) => {
      // Set bases selected.
      const fn = ({ data }) => data;
      const value = (bases || []).filter(fn).map(fn);
      const newValue = formatValue(value);
      setProductBaseIds(newValue);

      const newBases = (value || []).map((i) => {
        const newVars = (i.variants || []).map((va) => {
          return {
            ...va,
            productBaseVariantId: va.id,
            id: undefined,
          };
        });

        return {
          ...i,
          variants: newVars,
        };
      });

      const variants = genVariants(newBases);
      const currentVariants = settings.baseVariants;

      const key = "mergedId";
      let mergedVars = [...currentVariants, ...variants].map((i) => ({
        ...i,
        [key]: `${i.productBaseId}-${i.productBaseVariantId}`,
      }));
      mergedVars = getUnique(mergedVars, key);

      setSettings((prev) => ({ ...prev, baseVariants: mergedVars }));
      setBaseSelected(value);
      setIsPrompt(true);
      baseSelectedRef.current = true;
    },
    [settings.baseVariants]
  );

  const handleSpecialProducts = useCallback((value) => {
    setSpecialProducts(value);
    setIsPrompt(true);
  }, []);
  const handleIncludeCollections = useCallback((value) => {
    setIncludeCollections(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeCollections = useCallback((value) => {
    setExcludeCollections(value);
    setIsPrompt(true);
  }, []);
  const handleIncludeTags = useCallback((value) => {
    setIncludeTags(value);
    setIsPrompt(true);
  }, []);
  const handleExcludeTags = useCallback((value) => {
    setExcludeTags(value);
    setIsPrompt(true);
  }, []);
  const handleStore = useCallback((value) => {
    setStoreId(value);
    handleStoreValue(value);
    setIsPrompt(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLimit = useCallback((value) => {
    setLimit(value);
    handleLimitValue(value);

    if (Number(value) < 1) {
      setLimitType([]);
    }
    setIsPrompt(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOrder = useCallback((value) => {
    setOrder(value);
    setIsPrompt(true);
  }, []);
  const handleOrderBy = useCallback((value) => {
    setOrderBy(value);
    setIsPrompt(true);
  }, []);

  const handleToggle = useCallback(() => {
    setIsContinue((active) => !active);
    setIsPrompt(true);
  }, []);

  const hanleOnlyGenerateCsv = useCallback((val) => {
    setOnlyGenerateCsv(val);
    setIsPrompt(true);
  }, []);

  const handleTitleValue = useCallback((value) => {
    setErrors((prevState) => ({
      ...prevState,
      title: !value ? "Title is required." : null,
    }));
  }, []);

  const handleStoreValue = useCallback((value) => {
    setErrors((prevState) => ({
      ...prevState,
      store: value && value.length === 0 ? "Store is required." : null,
    }));
  }, []);

  const handleLimitValue = useCallback((value) => {
    setErrors((prevState) => ({
      ...prevState,
      limit: !value ? "Limit is required." : null,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    handleLimitValue(limit);
    handleTitleValue(title);
    handleStoreValue(storeId);
    setIsPrompt(false);

    let newSpecialProducts = specialProducts.map((p) =>
      typeof p === "object" ? p.id : p
    );
    let newIncludeCollections = includeCollections.map((ic) => ({
      collectionId: typeof ic === "object" ? ic.id : ic,
      included: true,
    }));
    let newExcludeCollections = excludeCollections.map((ic) => ({
      collectionId: typeof ic === "object" ? ic.id : ic,
      included: false,
    }));
    let collections = [...newIncludeCollections, ...newExcludeCollections];
    let newIncludeTags = includeTags.map((it) => ({
      tagId: typeof it === "object" ? it.id : it,
      included: true,
    }));
    let newExcludeTags = excludeTags.map((it) => ({
      tagId: typeof it === "object" ? it.id : it,
      included: false,
    }));
    let tags = [...newIncludeTags, ...newExcludeTags];
    let newOrder = "none" === order ? null : order;
    let newOrderBy = "none" === orderBy ? null : orderBy;

    const requires = {
      title,
      storeId,
      limit,
    };
    // let canSubmit = Object.values(errors).every((err) => !err);

    // Advance settings
    const { modifyVariantPrice, baseVariants, modifyVariantType } = settings;

    let newVariants = baseVariants?.length > 0 ? [...baseVariants] : [];
    if (modifyVariantPrice) {
      newVariants = newVariants
        .filter(Boolean)
        .map(
          (
            {
              id,
              productBaseId,
              productBaseVariantId,
              regularPrice,
              salePrice,
            },
            index
          ) => ({
            id,
            productBaseId,
            productBaseVariantId,
            regularPrice: parseFloatFn(regularPrice),
            salePrice: parseFloatFn(salePrice),
            sorting: index,
          })
        );
      if (showFixed) {
        if (newVariants?.length > 0) {
          const { rPrice, sPrice } = fixedPrice;

          Object.entries(fixedPrice).forEach(([id, value]) => {
            handleValidateValue(value, id);
          });

          requires.rPrice = rPrice;
          requires.sPrice = sPrice;

          const newR = parseFloatFn(rPrice);
          const newS = parseFloatFn(sPrice);
          newVariants = newVariants.map((va) => ({
            ...va,
            regularPrice: newR,
            salePrice: newS,
          }));
        }
      } else {
        errors.rPrice = null;
        errors.sPrice = null;

        delete requires.rPrice;
        delete requires.sPrice;
      }
    }

    // Check value requires.
    // If value `isNaN` = true  => value > 0, if values isArray => length > 0, otherwise value != empty;
    let canSubmit =
      Object.values(errors).every((err) => !err) &&
      Object.values(requires).every((value) => {
        // if (!isNaN(value)) return value > 0;
        return Array.isArray(value) ? value.length > 0 : value !== "";
      });

    const input = {
      title,
      productBaseIds,
      specialProducts: newSpecialProducts,
      collections,
      tags,
      stores: storeId,
      limit: Number(limit),
      order: newOrder,
      orderBy: newOrderBy,
      continue: isContinue,
      isPersonalize: isPersonalize,
      onlyPushHasSale,
      matchAnyCollections,
      matchAnyTags,
      onlyGenerateCsv: !!onlyGenerateCsv,
      limitType: Number(limit) < 1 ? undefined : limitType[0],
      setting: {
        ...settings,
        modifyVariantType: modifyVariantType[0],
        baseVariants: modifyVariantPrice ? newVariants : [],
      },
    };

    if (canSubmit) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors,
    title,
    limit,
    storeId,
    includeCollections,
    excludeCollections,
    includeTags,
    excludeTags,
    productBaseIds,
    specialProducts,
    order,
    orderBy,
    isContinue,
    isPersonalize,
    matchAnyCollections,
    matchAnyTags,
    settings,
    fixedPrice,
    showFixed,
    handleValidateValue,
    onlyPushHasSale,
    onlyGenerateCsv,
    limitType,
  ]);

  const contentStatus = isContinue ? "Enabled" : "Disabled";
  const csvFiles = _.get(value, "csvFiles", null);

  const handleLimitType = useCallback((val) => {
    setLimitType(val);
    setIsPrompt(true);
  }, []);

  return (
    <React.Fragment>
      <Prompt
        when={isPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Container>
        <Card sectioned>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <div>
                <ComponentLabelPolaris label="Title" required />
                <TextField
                  // label="Title(*)"
                  value={title}
                  placeholder="Enter title"
                  onChange={handleTitleChange}
                  error={errors.title}
                />
              </div>
              <React.Suspense fallback={<Spinner size="small" />}>
                <BaseSelect
                  allowMultiple
                  value={productBaseIds}
                  onChange={handleProductBase}
                  fragment={baseFragment}
                  setBaseSelected={setBaseSelected}
                />
              </React.Suspense>
              <React.Suspense fallback={<Spinner size="small" />}>
                <ProductSelect
                  label="Special product"
                  value={specialProducts}
                  allowMultiple
                  onChange={handleSpecialProducts}
                />
              </React.Suspense>
              <React.Suspense fallback={<Spinner size="small" />}>
                <CollectionSelect
                  multiple
                  label="Include collections"
                  onChange={handleIncludeCollections}
                  value={getIds(includeCollections)}
                />
              </React.Suspense>
              <Checkbox
                label="Match any collections"
                checked={matchAnyCollections}
                onChange={(newValue) => setMatchAnyCollections(newValue)}
              />
              <React.Suspense fallback={<Spinner size="small" />}>
                <CollectionSelect
                  multiple
                  label="Exclude collections"
                  onChange={handleExcludeCollections}
                  value={getIds(excludeCollections)}
                />
              </React.Suspense>
              <React.Suspense fallback={<Spinner size="small" />}>
                <TagSelect
                  multiple
                  label="Include tags"
                  value={getIds(includeTags)}
                  onChange={handleIncludeTags}
                />
              </React.Suspense>
              <Checkbox
                label="Match any tags"
                checked={matchAnyTags}
                onChange={(newValue) => setMatchAnyTags(newValue)}
              />
              <React.Suspense fallback={<Spinner size="small" />}>
                <TagSelect
                  multiple
                  label="Exclude tags"
                  value={getIds(excludeTags)}
                  onChange={handleExcludeTags}
                />
              </React.Suspense>
              <Checkbox
                checked={isPersonalize}
                onChange={(newValue) => {
                  setIsPrompt(true);
                  setIsPersonalize(newValue);
                }}
                label="Only personalized products"
              />
              <Checkbox
                checked={onlyPushHasSale}
                onChange={(newValue) => {
                  setIsPrompt(true);
                  setOnlyPushHasSale(newValue);
                }}
                label="Only push products have sales"
              />
              <React.Suspense fallback={<Spinner size="small" />}>
                <StoreSelect
                  allowMultiple
                  value={storeId}
                  onChange={handleStore}
                  error={errors.store}
                />
              </React.Suspense>
              <div>
                <ComponentLabelPolaris label="Limit" />
                <TextField
                  // label="Limit(*)"
                  type="number"
                  value={limit}
                  onChange={handleLimit}
                  min={0}
                  error={errors.limit}
                />
              </div>
              <div className="list-type">
                <Collapsible open={limit > 0}
                  id="modify-variant-collapse"
                  transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                >
                  <ComponentLabelPolaris label="Limit Type" />
                  <ChoiceList
                    choices={[{ value: 'Daily', label: "Daily" }, { value: "Lifetime", label: "Lifetime" }]}
                    selected={limitType}
                    onChange={handleLimitType}
                    id="listType"
                  />
                </Collapsible>
              </div>
              <Select
                label="Order"
                options={[
                  { value: "none", label: "None" },
                  { value: "ASC", label: "ASC" },
                  { value: "DESC", label: "DESC" },
                ]}
                value={order}
                onChange={handleOrder}
              />
              <Select
                label="Order by"
                options={[
                  { value: "none", label: "None" },
                  { value: "id", label: "Product ID" },
                  { value: "title", label: "Product Title" },
                  { value: "created_at", label: "Date" },
                  { value: "updated_at", label: "Modified" },
                  { value: "random", label: "Random" },
                ]}
                value={orderBy}
                onChange={handleOrderBy}
              />
              <div>
                <label className="label-continue">Continue push</label>
                <div className="continue-wrap form_push-product">
                  <SettingToggle
                    action={{
                      content: contentStatus,
                      onAction: handleToggle,
                    }}
                    enabled={!isContinue}
                  />
                </div>
              </div>
              <AdvanceSettings
                settings={settings}
                setSettings={setSettings}
                baseSelected={baseSelected}
                errors={errors}
                fixedPrice={fixedPrice}
                setFixedPrice={setFixedPrice}
                showFixed={showFixed}
                handleValidateValue={handleValidateValue}
                setIsPrompt={setIsPrompt}
              />
              <Checkbox
                label="Only generate CSV"
                checked={onlyGenerateCsv}
                onChange={hanleOnlyGenerateCsv}
              />
              <CSVFiles files={csvFiles} hasHeader />
              <div className="btn-wrap">
                <ButtonGroup>
                  <Button
                    onClick={() =>
                      history.push(`/${param}/products/product-push`)
                    }
                  >
                    Cancel
                  </Button>
                  <Button submit primary loading={loading} disabled={!isPrompt}>
                    {btnLabel ? btnLabel : "Save"}
                  </Button>
                </ButtonGroup>
              </div>
            </FormLayout>
          </Form>
        </Card>
      </Container>
    </React.Fragment>
  );
};

function AdvanceSettings({
  settings,
  setSettings,
  errors,
  fixedPrice,
  setFixedPrice,
  showFixed,
  handleValidateValue,
  setIsPrompt,
}) {
  const changePrompt = useCallback(() => {
    setIsPrompt(true);
  }, [setIsPrompt]);

  const handleUpdate = useCallback(
    (value, id) => {
      changePrompt();
      setSettings((prev) => ({ ...prev, [id]: value }));
    },
    [setSettings, changePrompt]
  );

  const handleFixedPrice = useCallback(
    (value, id) => {
      changePrompt();
      handleValidateValue(value, id);
      setFixedPrice((prev) => ({ ...prev, [id]: value }));
    },
    [handleValidateValue, changePrompt, setFixedPrice]
  );

  const isModify = !!settings.modifyVariantPrice;
  const showVariants = settings.modifyVariantType.includes(
    MODIFY_VARIANT_TYPE.Customize
  );

  return (
    <div className="advance-settings-wrap">
      <Stack vertical spacing="tight">
        <Heading children="Advance settings" />
        <Checkbox
          label="Ignore the sale price in store"
          checked={settings.ignoreSalePrice}
          id="ignoreSalePrice"
          onChange={handleUpdate}
        />
        <Checkbox
          label="Modify variant prices"
          checked={settings.modifyVariantPrice}
          id="modifyVariantPrice"
          onChange={handleUpdate}
        />
        <Collapsible
          open={isModify}
          id="modify-variant-collapse"
          transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        >
          <ChoiceList
            choices={[
              {
                value: MODIFY_VARIANT_TYPE.Add,
                label: "Add the original price by a fixed amount",
              },
              {
                value: MODIFY_VARIANT_TYPE.Subtract,
                label: "Subtract the original price by a fixed amount",
              },
              {
                value: MODIFY_VARIANT_TYPE.Customize,
                label: "Customize price for each variants",
              },
            ]}
            selected={settings.modifyVariantType}
            name="modifyVariantType"
            onChange={handleUpdate}
          />
        </Collapsible>
        {isModify && showFixed && (
          <Stack distribution="fill">
            <TextField
              label="Regular price fixed amount"
              value={fixedPrice.rPrice}
              onChange={handleFixedPrice}
              id="rPrice"
              error={errors["rPrice"]}
              placeholder="10"
            />
            <TextField
              label="Sale price fixed amount"
              value={fixedPrice.sPrice}
              onChange={handleFixedPrice}
              id="sPrice"
              error={errors["sPrice"]}
              placeholder="10"
            />
          </Stack>
        )}
        {showVariants && (
          <VariantsComp
            setSettings={setSettings}
            variants={settings.baseVariants}
            changePrompt={changePrompt}
          />
        )}
      </Stack>
    </div>
  );
}

function VariantsComp({ variants, setSettings, changePrompt }) {
  // State
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const typingTimeoutRef = useRef(null);

  // Action
  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(variants);
        return;
      }

      typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        const filterRegex = new RegExp(value, "i");
        const resultOptions = variants.filter((option) =>
          option.name.match(filterRegex)
        );
        setOptions(resultOptions);
      }, 350);
    },
    [variants]
  );

  // Get data
  useEffect(() => {
    setOptions(variants);
  }, [variants]);

  const handlePrice = useCallback(
    (id, type) => {
      return (value) => {
        const newValue = value;
        changePrompt();

        setSettings((prev) => {
          const { baseVariants } = prev;
          const newVariants = (baseVariants || []).map((va) => {
            if (va.productBaseVariantId === id) {
              return {
                ...va,
                [type]: newValue,
              };
            }
            return va;
          });

          return {
            ...prev,
            baseVariants: newVariants,
          };
        });
      };
    },
    [setSettings, changePrompt]
  );
  // Markup
  useEffect(() => {
    const newRows = (options || []).map((opt) => {
      if (!opt) return null;
      const {
        name,
        regularPrice,
        salePrice,
        baseTitle,
        productBaseVariantId,
      } = opt;
      const rPrice = regularPrice ? String(regularPrice) : regularPrice;
      const sPrice = salePrice ? String(salePrice) : salePrice;

      // Base title
      const titleWrap = (
        <div className="base-title-wrap item">
          <span>{baseTitle}</span>
        </div>
      );

      const variationWrap = (
        <div className="variation-wrap item">
          <span>{name}</span>
        </div>
      );

      const regularWrap = (
        <TextField
          placeholder="50"
          value={rPrice}
          onChange={handlePrice(productBaseVariantId, "regularPrice")}
        />
      );

      const saleWrap = (
        <TextField
          placeholder="50"
          value={sPrice}
          onChange={handlePrice(productBaseVariantId, "salePrice")}
        />
      );
      return [titleWrap, variationWrap, regularWrap, saleWrap];
    });

    setRows(newRows);
  }, [options, handlePrice]);

  return (
    <VariantWrapper>
      <Stack vertical spacing="tight">
        <Heading children="Variants" element="h3" />
        <div>
          <div className="search-wrap">
            <TextField
              onChange={handleInputChange}
              value={inputValue}
              prefix={<Icon source={SearchMinor} color="base" />}
              placeholder="Search"
            />
          </div>
          <Scrollable
            shadow
            hint
            style={{ maxHeight: "120rem", height: "100%" }}
          >
            <div className="content-wrap">
              <DataTable
                rows={rows}
                columnContentTypes={["text", "text", "text", "text"]}
                headings={[
                  "Product base",
                  "Variation",
                  "Regular Price",
                  "Sale Price",
                ]}
                verticalAlign={"middle"}
              />
            </div>
          </Scrollable>
        </div>
      </Stack>
    </VariantWrapper>
  );
}

function parseFloatFn(value) {
  if (!value || isNaN(value)) return undefined;

  return parseFloat(value);
}

function formatValue(arr) {
  if (!arr || arr.length === 0) return [];

  return arr
    .map((item) => {
      if (typeof item === "object" && "id" in item) {
        return item.id;
      }
      return item;
    })
    .filter(Boolean);
}

function mapBases(arr) {
  if (!arr || arr.length === 0) return [];

  const uniqueBases = getUnique(arr, "productBaseId");

  const newBases = uniqueBases.map((b) => {
    const { productBase } = b || {};
    const { id, title } = productBase || {};

    const mapVariantByBase = arr
      .filter((item) => item.productBaseId === id)
      .map((item) => {
        const { id, productBaseVariant, sorting, regularPrice, salePrice } =
          item || {};
        const { id: variantId, attributes } = productBaseVariant || {};

        return {
          id,
          productBaseVariantId: variantId,
          attributes,
          regularPrice,
          salePrice,
          sorting,
        };
      })
      .sort((a, b) => a.sorting - b.sorting);

    return {
      id,
      title,
      variants: mapVariantByBase,
    };
  });
  return newBases;
}

function genVariants(bases) {
  if (!bases || bases.length === 0) return [];

  return bases.reduce((acc, cur) => {
    const { variants, id: productBaseId, title } = cur || {};
    const items = (variants || [])
      .map((va) => {
        const {
          productBaseVariantId,
          sorting,
          attributes,
          regularPrice,
          salePrice,
          id,
        } = va || {};
        const name = (attributes || []).map((att) => att.option).join("/");

        return {
          id,
          productBaseId,
          productBaseVariantId,
          sorting,
          name,
          baseTitle: title,
          regularPrice: regularPrice || 0,
          salePrice: salePrice || 0,
          rPrice: 0,
          sPrice: 0,
        };
      })
      .sort((a, b) => a.sorting - b.sorting);
    return [...acc, ...items];
  }, []);
}

const filterTaxonomies = (arr, included, key) => {
  if (arrInvalid(arr)) return [];

  return arr.filter((t) => t.included === included).map((t) => t[key]);
};

const getIds = (arr) => {
  if (arrInvalid(arr)) return [];
  return arr.map((t) => {
    if (typeof t === "object" && "id" in t) {
      return t.id;
    }
    return t;
  });
};

const baseFragment = `
  id
  title
  teamId
  variants {
    id
    sorting
    regularPrice
    salePrice
    attributes {
      name
      slug
      option
    }
  }
`;
