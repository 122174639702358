import React from "react";

import { Overviews } from "../../components/reports";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function OverviewsPage() {
    return (
        <WrapperPage>
            <Overviews />
        </WrapperPage>
    );
}
