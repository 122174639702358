import { useApolloClient } from "@apollo/react-hooks";
import { Button, notification } from "antd";
import get from "lodash/get";
import React, { useCallback } from "react";
import {
  PRODUCT_BASE_HAS_ORDER,
  PRODUCT_VARIANT_HAS_ORDER,
} from "../../../graphql/queries";
import { handleError } from "../../../helper";

export function DeleteBase({ onDelete, value, productId }) {
  const client = useApolloClient();
  const productBaseId = get(value, "productBase.id", null);

  // Actions
  const handleDelete = useCallback(async () => {
    // if (!productId || !productBaseId) return;
    // const { __apolloClient__: client } = window;
    // if (client != null) {
    //   try {
    //     const { data } = await client.query({
    //       query: PRODUCT_BASE_HAS_ORDER,
    //       variables: {
    //         productId,
    //         productBaseId,
    //       },
    //       skip: !productId || !productBaseId,
    //     });

    //     if (!data || data.productProductBaseHasOrder) {
    //       throw Error(
    //         "Can't delete this product base because it already have data in orders."
    //       );
    //     }
    //   } catch (err) {
    //     notification.error({ message: handleError(err.toString()) });
    //     return;
    //   }
    // }

    if (value?.variants?.length > 0) {
      const productVariantIds = value.variants.map((i) => i.id);
      const { data } = await client.query({
        query: PRODUCT_VARIANT_HAS_ORDER,
        variables: { productVariantIds },
      });

      if (data?.productVariantHasOrder) {
        notification.error({
          message:
            "Can't delete this base, because product's variant has order.",
        });
        return;
      }
    }
    onDelete && onDelete();
  }, [onDelete, value, client]);

  return (
    <Button
      className="danger-btn"
      onClick={handleDelete}
      size="small"
      type="link"
    >
      Delete
    </Button>
  );
}
