import React, { useCallback, useRef, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import { CREATE_IDEA_TASK } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import { getUnique, handleError } from "../../../../helper";
import { Layout } from "./Layout";
import { ModalComp } from "./ModalComp";
import { GET_PRODUCT_BY_TITLE } from "../../../../graphql/queries";
import { AMZ_MESSAGE_ERROR, WHICH_FIELD } from "../../../../variable";

export function Create({ open, onClose }) {
    // Ref
    const modalRef = useRef(null);
    const timeoutRef = useRef(null);

    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [createIdea, { client }] = useMutation(CREATE_IDEA_TASK, {
        onCompleted: () => {
            setNotify({ msg: "Create Idea Task successfully.", err: false });
            onClose && onClose();
            setLoading(false);

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
        onError: (err) => {
            setLoading(false);
            setNotify({ msg: handleError(err.toString()), err: true });
        },
    });

    // Actions
    const handleSave = useCallback(async () => {
        const modalRefCur = modalRef.current;
        const input = modalRefCur && modalRefCur.submit();

        if (input != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            setLoading(true);

            const { baseGroups } = input;
            let productTitles = (baseGroups || [])
                .map((base) => ({
                    amzTitle: base.amzTitle,
                }))
                .filter((i) => i.amzTitle);
            productTitles = getUnique(productTitles, "amzTitle");

            if (productTitles?.length > 0) {
                const data = await Promise.all(
                    productTitles.map(async ({ amzTitle }) => {
                        return await client.query({
                            query: GET_PRODUCT_BY_TITLE,
                            variables: {
                                title: amzTitle,
                                whichField: WHICH_FIELD.AmzTitle,
                            },
                        });
                    })
                );

                if (!data || data.length === 0) return;
                const amzTitlesExists = data.some(
                    (res) => res?.data?.getProductsByTitle?.total > 0
                );
                if (amzTitlesExists) {
                    setNotify({ msg: AMZ_MESSAGE_ERROR, err: true });
                    setLoading(false);
                    return;
                }
            }

            createIdea({
                variables: {
                    input,
                },
            });
        }
    }, [createIdea, setNotify, toggleToast, client]);

    const handleClose = useCallback(() => {
        if (changed) {
            const confirm = window.confirm(
                "You have unsaved changes, are you sure you want to leave?"
            );
            if (!confirm) return;
        }
        onClose();
        const modalRefCur = modalRef.current;
        modalRefCur && modalRefCur.close();
    }, [changed, onClose]);

    const primaryAction = [
        {
            content: "Create idea task",
            onAction: handleSave,
            loading,
        },
    ];

    return (
        <ModalComp
            open={open}
            title="Add new idea task"
            primaryAction={primaryAction}
            handleClose={handleClose}
            domId={"idea-task-id"}
            setChanged={setChanged}
        >
            <Layout ref={modalRef} isAdminSeller />
        </ModalComp>
    );
}
