import { Modal, Spinner } from "@shopify/polaris";
import React from "react";

const DreamshipShop = React.lazy(() => import("./DreamshipShop"));

export default function DreamshipShopModal({ open, onClose, item }) {
  return (
    <Modal title="Choose Default Shop" sectioned open={open} onClose={onClose}>
      {open ? (
        <React.Suspense fallback={<Spinner size="small" />}>
          <DreamshipShop item={item} onClose={onClose} />
        </React.Suspense>
      ) : null}
    </Modal>
  );
}
