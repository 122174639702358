import React, { useState, useCallback, useContext, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { get, head } from "lodash";
import { Autocomplete } from "@shopify/polaris";

import { AppContext } from "../../../../context";
import { handleError } from "../../../../helper";
import { TEAM_ROLE } from "../../../../variable";
import imagePlaceholder from "../../../../assets/images/image.jpg";

import { PRODUCTS_QUERY } from "../../../product/ProductsSelectPolaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";

export const FieldProduct = ({ error: errorProp, onChange }) => {
    // Context
    const { store, currentUser } = useContext(AppContext);
    const teamRole = currentUser?.teamUser?.role;

    const isMM = [TEAM_ROLE.MarketplaceManager].includes(teamRole);

    // State
    let newStore = store ? (isMM && store.id === "all" ? null : store.id) : null;
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filter, setFilter] = useState({
        limit: 20,
        offset: 0,
        storeId: newStore,
        search: null,
    });
    const typingTimeoutRef = useRef(null);

    // Query
    const { data, error } = useQuery(PRODUCTS_QUERY, {
        variables: {
            filter,
        },
    });
    React.useEffect(() => {
        let formatData = [];
        if (data?.products?.nodes?.length > 0) {
            let newData = data.products.nodes;
            formatData = newData.filter(Boolean).map((p) => {
                let ffTitle = "";
                const [firstImg] = p.images?.length > 0 ? p.images : [];
                const imgSrc = firstImg?.file?.thumbnailUrl
                    ? firstImg.file.thumbnailUrl
                    : firstImg?.file?.url
                    ? firstImg.file.url
                    : imagePlaceholder;

                if (p.productBases?.length > 0) {
                    ffTitle = p.productBases
                        .map((pb) => {
                            let fTitle = pb.fulfillment?.fulfillmentTitle
                                ? pb.fulfillment.fulfillmentTitle
                                : "";
                            return `${
                                fTitle ? fTitle + " - " : "Merch Bridge - "
                            }${pb.title}`;
                        })
                        .filter(Boolean)
                        .join(", ");
                }
                let labelMarkup = (
                    <div className="label-wrap">
                        <img alt="" src={imgSrc} />
                        <div className="title">{p.title && p.title.trim()}</div>
                        <div>{`(${ffTitle ? ffTitle : "Merch Bridge"})`}</div>
                        <div>{`(${p.sku})`}</div>
                    </div>
                );
                return {
                    value: p.id,
                    label: labelMarkup,
                    data: p,
                };
            });
        }
        setOptions(formatData);
    }, [data]);

    // Handle actions
    const handleSelection = useCallback(
        (selected) => {
            let dataSelected = null;
            const selectedValue = selected.map((selectedItem) => {
                const matchedOptions = options.find((option) => {
                    return option.value === selectedItem;
                });
                dataSelected = matchedOptions;
                let children = get(
                    matchedOptions,
                    "label.props.children",
                    null
                );
                let label = "";
                for (let i = 0; i < children.length; i++) {
                    if ("title" === children?.[i].props?.className) {
                        label = children[i].props.children;
                    }
                }
                return label;
            });
            setSelectedOptions(selected);
            setInputValue(head(selectedValue));

            typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
            typingTimeoutRef.current = setTimeout(() => {
                onChange(head(selected), dataSelected);
            }, 10);
        },
        [options, onChange]
    );

    const handleInputChange = useCallback((value) => {
        setInputValue(value);
        typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
            setFilter((prev) => ({ ...prev, search: value }));
        }, 400);
    }, []);

    // Markup
    const textField = (
        <Autocomplete.TextField
            placeholder={"Search product"}
            value={inputValue}
            onChange={handleInputChange}
            error={errorProp}
        />
    );

    return (
        <React.Fragment>
            <ComponentLabelPolaris label="Product" required />
            {error ? (
                <div>Error: {handleError(error.toString())}</div>
            ) : (
                <Autocomplete
                    options={options}
                    onSelect={handleSelection}
                    selected={selectedOptions}
                    textField={textField}
                    emptyState={<span>No item found!</span>}
                    id="mp__choose-product-3"
                    willLoadMoreResults
                />
            )}
        </React.Fragment>
    );
};
