import { DataTable, Stack, TextStyle } from "@shopify/polaris";
import { get } from "lodash";
import React from "react";
import styled from "styled-components";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { formatter } from "../../../helper";

export default function ReportTotalCostTable({ filter, onChange, data }) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const nodes = data?.reportTotalCostByFulfillment?.nodes || [];
    if (nodes?.length > 0) {
      const rows = nodes.map((record) => {
        const { totalCost, totalOrders, orderCreatedAtStr } = record || {};

        return [
          <div className="order-created-at item">
            <span>{orderCreatedAtStr}</span>
          </div>,
          <div className="item total-orders">
            <span>{totalOrders}</span>
          </div>,
          <div className="item total-cost">{formatter.format(totalCost)}</div>,
        ];
      });

      setRows(rows);
    }
  }, [data]);

  const values = get(data, "reportTotalCostByFulfillment");
  const total = get(values, "total", 0);
  const totalCost = get(values, "totalCost", 0);
  const totalAllCost = get(values, "totalAllCost", 0);
  const totalOrders = get(values, "totalOrders", 0);
  const totalAllOrders = get(values, "totalAllOrders", 0);
  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange,
    total,
  };

  return (
    <Wrapper>
      <DataTable
        columnContentTypes={["text", "text", "text"]}
        headings={["Order Created At", "Total Orders", "Total Cost"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
      />
      <PaginationWrapper>
        <PaginationPolaris
          showTotal
          aggregation={aggregation}
          extraFooter={
            <Stack vertical spacing="extraTight">
              <Stack distribution="equalSpacing">
                <Stack>
                  <TextStyle children="Current showing Total:" />
                  <TextStyle children={totalOrders} variation="strong" />
                </Stack>
                <Stack>
                  <TextStyle children="Total Order:" />
                  <TextStyle children={totalAllOrders} variation="strong" />
                </Stack>
              </Stack>
              <Stack distribution="equalSpacing">
                <Stack>
                  <TextStyle children="Current showing Total:" />
                  <TextStyle
                    children={formatter.format(totalCost)}
                    variation="strong"
                  />
                </Stack>
                <Stack>
                  <TextStyle children="Total Cost:" />
                  <TextStyle
                    children={formatter.format(totalAllCost)}
                    variation="strong"
                  />
                </Stack>
              </Stack>
            </Stack>
          }
        />
      </PaginationWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__Cell--header {
    white-space: nowrap;
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .product-wrap {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
`;
const PaginationWrapper = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;
