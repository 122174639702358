import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const IFrame = styled.iframe`
  width: 100%;
  height: calc(100vh - 61px);
  border: none;
`;

// const DOMAIN = `https://docs.onemercegroup.com`;
const URL = "https://merchbridge.com/docs?is_iframe=true";

export const DocumentationsPage = () => {
  // context
  // const { currentUser } = useContext(AppContext);

  // // Params
  // const email = currentUser?.email ?? "";
  // const preferer_source = Production
  //     ? `api.merchbridge.com`
  //     : `api-dev.merchbridge.com`;

  // const URL = `${DOMAIN}/?user_email=${email}&preferer_source=${preferer_source}`;

  return (
    <Container className="iframe-documentations">
      <IFrame title="Documentations" src={URL} />
    </Container>
  );
};
