import React from "react";
import { Button, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import history from "../../history";

import useToggle from "../../hooks/useToggle";
import { handleError } from "../../helper";

const RE_DOWNLOAD = gql`
  mutation reDownloadExportConfirmShipment($id: ID!) {
    reDownloadExportConfirmShipment(id: $id)
  }
`;

export const HistoryETActionPolaris = ({ id, labelLinkExport, disabled, isErrorStatus, refetch }) => {
  const [active, toggleActive] = useToggle(false);
  const [notify, setNotify] = React.useState({
    msg: null,
    err: false,
  });
  // Mutation
  const [reDownload, { loading }] = useMutation(RE_DOWNLOAD, {
    onCompleted: (res) => {
      const url = res?.reDownloadExportConfirmShipment;
      if (url) {
        // window.location.href = url;
        history.push(`/download/url=?${url}`);
      } else if (isErrorStatus) {
        refetch && refetch();
      }
      setNotify({
        msg: "Download file successfully.",
        err: false,
      });
    },
    onError: (error) => {
      setNotify({
        msg: handleError(error.toString()),
        err: true,
      });
    },
  });
  // Handle action
  const handleReDownload = React.useCallback(() => {
    if (id) {
      toggleActive(true);
      reDownload({
        variables: {
          id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Markup
  const toastMarkup = active && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      onDismiss={toggleActive}
      duration={1500}
    />
  );

  return (
    <React.Fragment>
      {toastMarkup}
      <Button
        disabled={disabled}
        children={labelLinkExport}
        onClick={handleReDownload}
        plain
        loading={loading}
      />
    </React.Fragment>
  );
};
