import React, { Fragment, useCallback, useRef, useState } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { QC_MARK_PRODUCT_DESIGN } from "../../../graphql/mutations";
import useToggle from "../../../hooks/useToggle";

export const QCMarkChecked = ({
    open,
    value,
    onClose = () => {},
    refetch = () => {},
}) => {
    // Props
    const orderId = value?.id;

    // State
    const [active, toggleActive] = useToggle(false);
    const [notify, setNotify] = useState({ msg: null, err: false });
    const timeoutRef = useRef(null);

    // Mutation
    const [mark, { loading }] = useMutation(QC_MARK_PRODUCT_DESIGN, {
        onError: (err) => {
            setNotify({ msg: handleError(err.toString()), err: true });
            onClose(false);
        },
        onCompleted: () => {
            setNotify({ msg: "Mark product design successfully.", err: false });
            onClose(false);

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                refetch();
            }, 1600);
        },
    });

    // Handle action
    const handleSubmit = useCallback(() => {
        if (orderId != null) {
            toggleActive(true);
            setNotify({ msg: null, err: false });

            mark({
                variables: {
                    orderId,
                },
            });
        }
    }, [orderId, toggleActive, setNotify, mark]);

    // Markup
    const toastMarkup = active && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            duration={1500}
            onDismiss={toggleActive}
        />
    );

    return (
        <Fragment>
            {toastMarkup}
            <Modal
                title="Mark checked for product"
                open={open}
                onClose={onClose}
                sectioned
                secondaryActions={[{ content: "Cancel", onAction: onClose }]}
                primaryAction={{
                    content: "Submit",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <p>Are you sure to mark checked for this product?</p>
            </Modal>
        </Fragment>
    );
};
