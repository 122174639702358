import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextContainer, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "./ProductsPolaris";

const CHANGE = gql`
  mutation changeProductSelected($id: [ID!], $status: String!) {
    changeProductSelected(id: $id, status: $status) {
      failed
      successed
    }
  }
`;

export const AvailableProductsPolaris = ({
  open,
  toggleModal,
  selectedItem,
  filter,
  onCompleted,
}) => {
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [changeProductSelected, { data, loading, error, client }] = useMutation(
    CHANGE,
    {
      onError: () => {},
    }
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = useCallback(() => {
    changeProductSelected({
      variables: {
        id: selectedItem,
        status: "Available",
      },
    })
      .then(() => {
        try {
          const cache = client.readQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables: {
              filter,
            },
          });
          client.writeQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables: {
              filter,
            },
            data: {
              ...cache,
              products: {
                ...cache.products,
                nodes: [
                  ...cache.products.nodes.map(
                    (p) => (
                      selectedItem.find((s) => s === p.id)
                        ? (p.status = "Available")
                        : null,
                      p
                    )
                  ),
                ],
              },
            },
          });
        } catch (e) {}
      })
      .catch(() => {});
    toggleActive();
    const id = setTimeout(() => {
      toggleModal();
      onCompleted();
    }, 2500);
    setTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );

  const toastMarkup = activeToast
    ? (error || (data && data.changeProductSelected)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.changeProductSelected && "Change status success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        title="Make products available"
        open={open}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <TextContainer>Are you sure it will change?</TextContainer>
      </Modal>
    </>
  );
};
