import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList } from "@shopify/polaris";

const options = [
  {
    value: "All",
    label: "All",
  },
  {
    value: false,
    label: "Normal",
  },
  {
    value: true,
    label: "Personalized",
  },
];

export const FilterProductTypePolaris = ({ productType, setProductType }) => {
  const [selected, setSelected] = useState(["All"]);

  useEffect(() => {
    if (null === productType.value) {
      setSelected(["All"]);
    } else {
      setSelected([productType.value]);
    }
  }, [productType]);

  const handleChoiceChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOptions = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOptions && matchedOptions.label;
    });
    if (setProductType) {
      setProductType(
        "All" !== selected[0] ? selected[0] : null,
        "All" !== selectedValue[0] ? selectedValue[0] : null
      );
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="product type"
      titleHidden={true}
      selected={selected}
      choices={options}
      onChange={handleChoiceChange}
    />
  );
};
