import React, { useState, useEffect, useCallback, useRef } from "react";
import { Filters } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
    .Polaris-Filters-ConnectedFilterControl__CenterContainer
    * {
    border-radius: var(--p-border-radius-base, 3px) !important;
  }
`;

export const ClaimsFilterPolaris = (props) => {
  // Props
  const { filter, onChange } = props;

  // State
  const [queryValue, setQueryValue] = useState(filter.search);
  const typingTimeoutRef = useRef(null);

  // Get data
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({ search: queryValue ? queryValue.trim() : queryValue });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  // Handle active
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleClearFilterAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  return (
    <Container>
      <Filters
        filters={[]}
        queryValue={queryValue}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearFilterAll}
      />
    </Container>
  );
};
