import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "@shopify/polaris";
import React from "react";
import { UPDATE_PRIVATE_FEATURES } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { useToastContext } from "../../shared/ToastContext";

export function Actions({ item, features, refetch }) {
  const { id } = item || {};
  const [open, toggleOpen] = useToggle(false);
  const timeoutRef = React.useRef(null);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutation
  const [update, { loading }] = useMutation(UPDATE_PRIVATE_FEATURES, {
    onCompleted: () => {
      setNotify({ msg: "Update private features success.", err: false });
      if (refetch) {
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  React.useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  // Actions
  const handleSubmit = React.useCallback(() => {
    if (!id) return;

    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });
    const privateFeatures =
      typeof features === "object"
        ? Array.from(features.values()).filter((i) => i !== "all")
        : [];
    update({
      variables: {
        input: {
          teams: [{ id, privateFeatures }],
        },
      },
    }).finally(() => {
      toggleOpen();
    });
  }, [id, update, features]);

  return (
    <React.Fragment>
      <Button children="Update" onClick={toggleOpen} size="slim" />
      <Modal
        title="Update Private Features"
        open={open}
        onClose={toggleOpen}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p>Are you sure to update to private features?</p>
      </Modal>
    </React.Fragment>
  );
}
