import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

export const FilterStatusPolaris = ({ status, onChange, options }) => {
  const [selected, setSelected] = useState(["all"]);

  useEffect(() => {
    if (null === status) {
      setSelected(["all"]);
    } else {
      setSelected([`${status}`]);
    }
  }, [status]);

  const handleChoicesChange = useCallback((selected) => {
    if (onChange) {
      onChange("all" !== _.head(selected) ? _.head(selected) : null);
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Status"
      titleHidden={true}
      choices={options}
      selected={selected}
      onChange={handleChoicesChange}
    />
  );
};
