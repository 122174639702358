import { useQuery } from "@apollo/react-hooks";
import { Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ExportAmazonFilterPolaris } from "./ExportAmazonFilterPolaris";
import { TableExportAamzonPolaris } from "./TableExportAmazonPolaris";

const Container = styled.div``;

const QUERY_EXPORT_AMAZON = gql`
  query amazonExports($filter: AmazonExportFilter) {
    amazonExports(filter: $filter) {
      total
      nodes {
        id
        title
        totalProducts
        divisions {
          id
          title
        }
        createdAt
        createdBy {
          firstName
          lastName
          id
        }
      }
    }
  }
`;

const PRIVATE_FILTER = ["authorIds", "productBaseIds", "divisionIds"];
const FIELD_FILTER = [...COMMON_FILTER, ...PRIVATE_FILTER];

export const ExportAmazonPolaris = (props) => {
  // Props
  const { currentParam, path } = props;
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      for (let i = 0; i < PRIVATE_FILTER.length; i++) {
        let field = PRIVATE_FILTER[i];
        if (initialFilter[field]) {
          initialFilter[field] = initialFilter[field].split(",");
        }
      }
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  //State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageExportAmazon")) || 20,
    offset: 0,
    search: null,
    authorIds: null,
    productBaseIds: null,
    divisionIds: null,
    range: null,
    ...initFilter,
  });
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [activeToast, setActiveToast] = useState(false);

  // Query
  const { data, loading, error } = useQuery(QUERY_EXPORT_AMAZON, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  // Handle action
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  useEffect(() => {
    let { offset, limit, range, ...rest } = filter;
    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      <Card sectioned>
        <ExportAmazonFilterPolaris
          filter={filter}
          onChange={({
            search,
            authorIds,
            productBaseIds,
            divisionIds,
            range,
          }) => {
            setFilter((prev) => {
              if (
                !isEqual(prev.search, search) ||
                !isEqual(prev.authorIds, authorIds) ||
                !isEqual(prev.productBaseIds, productBaseIds) ||
                !isEqual(prev.divisionIds, divisionIds) ||
                !isEqual(prev.range, range)
              ) {
                return {
                  ...prev,
                  search,
                  authorIds,
                  productBaseIds,
                  divisionIds,
                  range,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        {toastMarkup}
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data &&
          data.amazonExports &&
          data.amazonExports.nodes &&
          data.amazonExports.nodes.length > 0 ? (
          <TableExportAamzonPolaris
            currentParam={currentParam}
            data={data}
            filter={filter}
            setNotification={setNotification}
            toggleActive={toggleActive}
            setFilter={(offset, limit) => {
              setFilter((prev) => ({
                ...prev,
                offset,
                limit,
              }));
              setCookie("perPageExportAmazon", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Container>
  );
};
