import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";

const options = [
  { value: "DESC", label: "DESC" },
  { value: "ASC", label: "ASC" },
];

export const FilterSortOrderPolaris = ({ onChange, order }) => {
  const [selected, setSelected] = useState(["DESC"]);

  useEffect(() => {
    if (null === order) {
      setSelected(["DESC"]);
    } else {
      setSelected([order]);
    }
  }, [order]);

  const handleChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOption && matchedOption.label;
    });
    if (onChange) {
      onChange("DESC" !== selectedValue[0] ? selectedValue[0] : null);
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Order"
      titleHidden
      choices={options}
      selected={selected}
      onChange={handleChange}
    />
  );
};
