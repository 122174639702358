import React from "react";
import { ShortTitleComp } from "../../../product/components/ShortTitle";
import { ItemWrap } from "./IdeaSection";

import { MAX_TITLE_CHARACTERS } from '../../../../variable';

const label = "FB Title";
export function FBTitle({ canEdit, value, onChange }) {
    return canEdit ? (
        <ShortTitleComp
            value={value}
            onChange={onChange}
            label={label}
            maxLength={MAX_TITLE_CHARACTERS.fbTitle}
            placeholder="facebook title"
            id="shortTitle"
        />
    ) : (
        <ItemWrap label={label}>
            <span title={value}>{value}</span>
        </ItemWrap>
    );
}
