import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import FilterOrders from "../../components/order/FilterOrders";
import { Query } from "@apollo/react-components";
import { Table, Skeleton, Input, Card } from "antd";
import { gql } from "apollo-boost";
import {
  CLAIM_FILTER_SUPPLIER,
  CLAIM_TYPE,
  CLAIM_STATUS,
} from "../../constants";
import ActionsClaim from "../../components/supplier/ActionsClaim";
import ModalImageClaim from "../../components/seller/ModalImageClaim";
import no_image from "../../../src/assets/images/unnamed.jpeg";
import _ from "lodash";
import {
  setCookie,
  getCookie,
  convertStringToObject,
  convertObjectToParams,
} from "../../helper";
import history from "../../history";
import ExportButton from "../../components/supplier/ExportButton";

import moment from "moment";

const CLAIMS = gql`
  query claims($filter: ClaimFilter) {
    claims(filter: $filter) {
      total
      nodes {
        id
        orderId
        type
        status
        order {
          id
          originId
        }
        files {
          id
          url
          thumbnailUrl
        }
        comment
        createdAt
      }
    }
  }
`;

const initFilter = convertStringToObject(history.location.search);
class SupplierClaimsPage extends Component {
  state = {
    filter: {
      limit: +getCookie("perPageClaims") || 20,
      offset: 0,
      status: null,
      type: null,
      search: null,
      ...initFilter,
    },
    current: 1,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      const params = convertObjectToParams(nextState.filter);
      history.push(history.location.pathname + "?" + params);
    }
  }

  onChange = (data) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, ...data },
    });
  };

  handleChangeFilter = (item) => {
    this.setState({
      filter: {
        ...this.state.filter,
        status: Object.values(CLAIM_STATUS).includes(item) ? item : null,
        type: Object.values(CLAIM_TYPE).includes(item) ? item : null,
      },
    });
  };

  render() {
    const { filter, current } = this.state;
    const { role } = this.props;

    const ordersFilter = CLAIM_FILTER_SUPPLIER;

    const columns = [
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        width: 220,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 150,
      },
      {
        title: "Created",
        dataIndex: "createdAt",
        key: "created",
        width: 150,
        render: (value) => (
          <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>
        ),
      },
      {
        title: "File",
        dataIndex: "files",
        key: "files",
        width: 120,
        render: (_, record) => {
          return record && record.files && record.files.length > 0 ? (
            <ModalImageClaim files={record} limit={1} />
          ) : (
            <img alt="" src={no_image} style={{ width: 70 }} />
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Note",
        dataIndex: "comment",
        key: "comment",
        width: 230,
        render: (text) => text.substring(0, 50),
      },
      {
        title: "Actions",
        key: "action",
        align: "right",
        width: 120,
        render: (_, record) => <ActionsClaim claim={record} />,
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));

    return (
      <div>
        <PageTitle
          title={"Claims"}
          action={
            _.get(filter, "type", "") === "Need Resend" ? (
              <ExportButton exportAllResendClaims={true} />
            ) : null
          }
        />
        <Card bodyStyle={{ padding: 0 }}>
          <FilterOrders
            key={role}
            value={ordersFilter}
            defaultValue={filter.status || filter.type}
            onChange={this.handleChangeFilter}
          />
          <div className="px-4 pb-4">
            <Input.Search
              defaultValue={filter.search}
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      search: null,
                      offset: 0,
                    },
                  });
                }
              }}
              onSearch={(v) =>
                this.setState({ filter: { ...filter, search: v, offset: 0 } })
              }
            />
          </div>
          <Query query={CLAIMS} variables={{ filter }}>
            {({ data, error, loading }) => {
              if (loading)
                return (
                  <div className="p-5">
                    <Skeleton active />
                  </div>
                );
              if (error) return error.toString();
              const orders = (data && data.claims && data.claims.nodes) || [];
              return (
                <Table
                  columns={columns}
                  dataSource={orders}
                  scroll={{ x: tableWidth }}
                  rowKey={(claim) => claim.id}
                  pagination={{
                    total: (data && data.claims.total) || 0,
                    pageSize: filter.limit,
                    current: current,
                    onShowSizeChange: (_, size) => {
                      this.setState({
                        current: 1,
                        filter: {
                          ...filter,
                          limit: size,
                          offset: 0,
                        },
                      });
                      setCookie("perPageClaims", size, 100);
                    },
                  }}
                  onChange={(pagination) => {
                    this.setState({
                      current: pagination.current,
                      filter: {
                        ...filter,
                        offset: filter.limit * (pagination.current - 1),
                      },
                    });
                  }}
                />
              );
            }}
          </Query>
        </Card>
      </div>
    );
  }
}

export default SupplierClaimsPage;
