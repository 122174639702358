import { gql } from "apollo-boost";
import React, { Component } from "react";
import get from "lodash/get";
import { AutoComplete, Input, notification, Tag } from "antd";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { handleError } from "../../helper";
import styled from "styled-components";

const LOAD = gql`
  fragment cat on Category {
    id
    name
    description
    image {
      id
      name
      thumbnailUrl
      url
    }
  }

  query categories {
    productBaseCategories {
      ...cat
      children {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
            }
          }
        }
      }
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createProductBaseCategory($input: NewProductBaseCategory!) {
    createProductBaseCategory(input: $input) {
      id
      name
      description
      parentId
      image {
        id
        name
        thumbnailUrl
        url
      }
    }
  }
`;

export class CategoryAutocomplete extends Component {
  state = {
    search: "",
    loading: false,
    result: [],
    dataTmp: [],
  };

  renderOptions = (result, level = 0) => {
    let options = [];
    for (let item of result) {
      const itemMarkup = (
        <AutoComplete.Option value={item.id} key={item.id}>
          <span
            style={{ marginLeft: `${level * 10}px` }}
            className={`level level-${level}`}
          >
            {item.name}
          </span>
        </AutoComplete.Option>
      );
      options.push(itemMarkup);
      if (item.children?.length > 0) {
        options.push(...this.renderOptions(item.children, level + 1));
      }
    }

    return options;
  };

  resultContains = (result, search) => {
    if (!result || result.length === 0) return false;
    for (let item of result) {
      if (item.name.toLowerCase() === search.toLowerCase()) return true;

      if (this.resultContains(item.children, search)) return true;
    }
    return false;
  };

  showAdd = (search) => {
    if (!search) return false;
    const { dataTmp } = this.state;
    let matched = dataTmp.find(
      ({ name }) => name.toLowerCase() === search.toLowerCase()
    );
    if (!dataTmp || !matched) return true;
    return false;
  };

  handleSearch = (search) => {
    // const { data } = this.props;
    const { dataTmp } = this.state;

    const val = {
      search,
    };
    if (search) {
      let opt = [];
      if (this.showAdd(search)) {
        opt = [
          <AutoComplete.Option
            key="add-new"
            value="add-new"
            className="pt-2 pl-4"
          >
            <span style={{ marginLeft: `0` }} className={`level level-0`}>
              <strong>Add: </strong>
              {search}
            </span>
          </AutoComplete.Option>,
        ];
      }
      const pt = new RegExp(search, "i");
      const newD = dataTmp.filter(({ name }) => name.match(pt));
      val["result"] = [...opt, ...this.renderOptions(newD)];
    } else {
      val["result"] = this.renderOptions(dataTmp);
    }

    this.setState(val);
  };

  addCategory = async () => {
    const { __apolloClient__: client } = window;
    const { search, dataTmp } = this.state;
    if (!search || typeof search !== "string") return;
    try {
      const { data } = await client.mutate({
        mutation: CREATE_CATEGORY,
        variables: {
          input: { name: search.toString() },
        },
      });

      const item = data?.createProductBaseCategory;
      if (item) {
        const d = this.renderOptions([...dataTmp, item]);
        this.setState({ result: d }, () => {
          if (this.props.onChange) {
            this.props.onChange([item]);
          }
        });
      }

      notification.success({ message: "Add category success." });
    } catch (err) {
      notification.error({ message: handleError(err.toString()) });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    const { data } = this.props;
    const result = this.renderOptions(data);

    this.setState({ result, dataTmp: data });
  }

  render() {
    const { value } = this.props;
    const { search, result, dataTmp } = this.state;
    return (
      <Wrapper>
        <AutoComplete
          style={{ width: "100%" }}
          filterOption={false}
          value={search}
          dataSource={result}
          onSearch={this.handleSearch}
          showSearch
          onSelect={(selected) => {
            if (selected !== "add-new") {
              if (this.props.onChange) {
                const selectedItem = (dataTmp || []).find(
                  ({ id }) => selected === id
                );
                this.props.onChange(selectedItem ? [selectedItem] : []);
              }
              return;
            }

            this.addCategory();
          }}
        >
          <Input.Search
            // onFocus={this.handleSearch}
            placeholder="Search for category"
          />
        </AutoComplete>
        <div>
          {value.map((collection, index) => (
            <Tag
              style={{ marginTop: 8, marginRight: 8 }}
              closable={true}
              onClose={() => {
                value.splice(index, 1);
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}
              key={collection.id}
            >
              {collection.name}
            </Tag>
          ))}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;
