import { Mutation, Query } from "@apollo/react-components";
import { Checkbox, Icon, Modal, Spinner } from "@shopify/polaris";
import { MaximizeMajorMonotone } from "@shopify/polaris-icons";
import { get, isEqual } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import {
  DELETE_FILE_AMZ,
  UPLOAD_ORDER_DESIGN,
} from "../../../graphql/mutations";
import { GET_ORDER_DESIGNS } from "../../../graphql/queries";
import {
  getDesignPositionName,
  handleError,
  sortDesignPosition,
} from "../../../helper";
import {
  FILE_SIZE_200MB,
  FILE_SIZE_50MB,
  SHIRT_BASE_IDS,
} from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { isChinaOrCustomFulfill } from "../../order/_utils";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .content_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    .thumbnail_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 50%;
      label {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
    .thumbnail_content {
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 0.75rem;
      position: relative;
      &:hover {
        .view-origin {
          opacity: 1;
        }
      }
      .view-origin {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: right;
        padding-top: 1rem;
        padding-right: 1rem;
        background: linear-gradient(
          180deg,
          rgba(33, 43, 54, 0.55),
          hsla(0, 0%, 100%, 0)
        );
        opacity: 0;
        transition: 0.3s opacity ease-out;
        .img-wrap {
          display: inline-block;
        }
        .Polaris-Icon {
          svg {
            fill: hsla(0, 0%, 100%, 0.8);
          }
        }
      }
    }
      img {
        width: 100%;
        height: 28rem;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px 2px rgba(0, 0, 0, 0.1);
      }
    }
    .design-position_wrap {
      width: 50%;
      .design-position_item {
        display: flex;
      }
      .design-position_inner {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
        .design-position-content + .design-position-content {
          border-top: var(
            --p-thin-border-subdued,
            0.1rem solid var(--p-border-subdued, #dfe3e8)
          );
          padding-top: 1rem;
        }
        .loading_wrap {
          display: flex;
          justify-content: center;
        }
        label {
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 1.09rem;
          display: inline-block;
        }
        .artwork_inner {
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .design-position-info {
          display: flex;
          flex-direction: column;
          row-gap: 0.75rem;
          margin-top: 1rem;
          > div {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
          }
        }
      }
    }
  }
  .message_wrap {
    &.success {
      color: #2abd0e;
    }
    &.error {
      color: #bd3b2f;
    }
  }
`;

class UploadDesignByLeadPolaris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelOtherTask: true,
      designPosition: {},
      designs: [],

      currentDesignId: {},
      disableSubmit: true,
      dimensions: {},
      checkingDimensions: {},
      maxSize: FILE_SIZE_50MB,
    };
  }

  componentDidMount() {
    (async () => {
      let maxSize = this.state.maxSize;
      const { task } = this.props;
      const isChinaOrCF = await isChinaOrCustomFulfill(task?.orderId);
      if (isChinaOrCF) {
        maxSize = FILE_SIZE_200MB;
      }
      this.setState({ maxSize });
    })();
  }
  componentDidUpdate(_prevProps, prevState) {
    // Check dimension image
    let { currentDesignId, dimensions, designs, checkingDimensions } =
      this.state;
    if (prevState.currentDesignId !== currentDesignId) {
      for (let [key, value] of Object.entries(currentDesignId)) {
        if (value === null) {
          this.setState((prev) => {
            return {
              dimensions: {
                ...prev.dimensions,
                [key]: {
                  message: null,
                },
              },
            };
          });
        }
      }
    }

    // Set disabled button
    if (prevState.dimensions !== dimensions) {
      let disabledBtn = Object.values(dimensions).filter((i) => i.message);
      if (disabledBtn.length) {
        disabledBtn = disabledBtn.every((i) => i.type === "success");
      } else {
        disabledBtn = false;
      }
      this.setState({
        disableSubmit: !disabledBtn,
      });
    }

    if (prevState.checkingDimensions !== checkingDimensions) {
      let disabledBtn = true;
      let someTruthy = Object.values(checkingDimensions).some(
        (i) => i === true,
      );
      if (!someTruthy) {
        disabledBtn = this.state.disableSubmit;
      }
      this.setState({
        disableSubmit: disabledBtn,
      });
    }

    // Apply disable button
    if (!isEqual(prevState.designs, designs)) {
      let haveDs =
        designs?.length > 0
          ? designs.find((i) => {
              if (i.designPosition.description) {
                return i;
              }
              return null;
            })
          : null;
      if (!haveDs) {
        this.setState({
          disableSubmit: false,
        });
      }
    }
  }

  handleSubmit = (uploadOrderDesign) => {
    let { designPosition, designs, cancelOtherTask } = this.state;
    let { task, toggleActive } = this.props;
    let orderId = task?.order?.id;

    let input =
      designs && designs.length > 0
        ? designs
            .map((ds, index) => {
              if (designPosition[index] && designPosition[index][0]) {
                return {
                  designPositionId: ds.designPosition.id,
                  fileId: designPosition[index][0].id,
                };
              }
              return {
                designPositionId: ds.designPosition.id,
                fileId: null,
              };
            })
            .filter(Boolean)
        : [];
    uploadOrderDesign({
      variables: {
        id: orderId,
        input,
        cancelOtherTask,
      },
    });
    if (toggleActive) {
      toggleActive();
    }
  };

  // Check dimension image
  handleCheckDimensions = (dimensions, idx) => {
    let { currentDesignId } = this.state;
    if (Object.keys(currentDesignId).length) {
      for (let [key, value] of Object.entries(dimensions)) {
        if (key === currentDesignId[idx]) {
          let isRealDimensionOk = value && value.isRealDimensionOk;
          let message = isRealDimensionOk
            ? "You can upload this file."
            : "The dimensions of file uploaded is not correct.";
          this.setState({
            dimensions: {
              ...this.state.dimensions,
              [idx]: {
                message,
                type: isRealDimensionOk ? "success" : "error",
              },
            },
          });
        }
      }
    }
  };

  deleteFileOutAmz = (id, client) => {
    if (id) {
      client
        .mutate({
          mutation: DELETE_FILE_AMZ,
          variables: {
            id,
            deleteAmz: true,
          },
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  render() {
    const { open, toggleShowModal, onNotificationChange, task, refetch } =
      this.props;

    // State
    const { cancelOtherTask, disableSubmit, dimensions, disableSubmitSpec } =
      this.state;

    const order = task?.order;
    const id = order?.id;

    const productBaseId =
      order &&
      order.productVariant &&
      order.productVariant.productBaseVariant &&
      order.productVariant.productBaseVariant.productBase
        ? order.productVariant.productBaseVariant.productBase.id
        : order?.product?.productBases[0]?.id;

    // const image = get(order, "product.images[0].file", null);

    // Main image
    const product = get(order, "product", null);
    const mainImageId = product?.mainImageId;
    const images = product?.images;
    let mainImages = [];
    const isCampaign = product?.productBases?.length > 1;
    const haveMainImageId = mainImageId || mainImageId !== "undefined";

    if (productBaseId && isCampaign) {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.productBaseId === productBaseId)
              .map((item) => item.file)
          : [];
      if (mainImages.length > 1 && haveMainImageId) {
        mainImages =
          images?.length > 0
            ? images
                .filter((img) => img?.file?.id === mainImageId)
                .map((item) => item.file)
            : [];
      }
    } else if (haveMainImageId) {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.file?.id === mainImageId)
              .map((item) => item.file)
          : [];
    }
    if (mainImages.length === 0) {
      mainImages =
        images?.length > 0
          ? images.filter(Boolean).map((item) => item.file)
          : [];
    }

    const image = mainImages?.length > 0 ? mainImages[0] : null;

    return (
      <>
        <Mutation
          mutation={UPLOAD_ORDER_DESIGN}
          onError={(error) => {
            toggleShowModal();
            if (onNotificationChange) {
              onNotificationChange({
                message: handleError(error.toString()),
                isError: true,
              });
            }
          }}
          onCompleted={() => {
            toggleShowModal();
            if (onNotificationChange) {
              onNotificationChange({
                message: `Upload design successful.`,
                isError: false,
              });
            }
            if (refetch) {
              refetch();
            }
          }}
        >
          {(uploadOrderDesign, { loading, client }) => (
            <Modal
              title="Upload design"
              open={open}
              onClose={toggleShowModal}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: () => {
                    let currentDesignId = this.state.currentDesignId;
                    for (let [, value] of Object.entries(currentDesignId)) {
                      if (value) {
                        this.deleteFileOutAmz(value, client);
                      }
                    }
                    toggleShowModal();
                  },
                },
              ]}
              primaryAction={{
                content: "Submit",
                onAction: () => this.handleSubmit(uploadOrderDesign),
                loading: loading,
                disabled: disableSubmitSpec === true ? false : disableSubmit,
              }}
              sectioned
            >
              <Query
                onCompleted={(res) => {
                  if (res) {
                    this.setState({
                      designs: res.getOrderDesigns.orderDesigns,
                    });
                  }
                }}
                variables={{ id, productBaseId }}
                fetchPolicy="no-cache"
                query={GET_ORDER_DESIGNS}
              >
                {({ error, loading, data }) => {
                  if (error) {
                    return <div>Error: {handleError(error.toString())}</div>;
                  }
                  if (loading) {
                    return <SkeletonPagePolaris />;
                  }

                  let dataSource = data?.getOrderDesigns?.orderDesigns;
                  if (!dataSource) {
                    return <EmptyStatePolaris noData />;
                  }

                  const index = dataSource.findIndex(
                    (v) => v.designPosition.name.toLowerCase() === "master",
                  );
                  // if (index > 0) {
                  //   [dataSource[0], dataSource[index]] = [
                  //     dataSource[index],
                  //     dataSource[0],
                  //   ];
                  // }

                  dataSource = sortDesignPosition(dataSource);

                  // Front first
                  const indexFront = dataSource.findIndex(
                    (v) => v.designPosition.name.toLowerCase() === "front",
                  );

                  let newIndex = 0;
                  if (indexFront > 0) {
                    if (index > 0) {
                      newIndex = 1;
                    }
                  }
                  if (
                    indexFront > 0 &&
                    SHIRT_BASE_IDS.includes(productBaseId)
                  ) {
                    [dataSource[newIndex], dataSource[indexFront]] = [
                      dataSource[indexFront],
                      dataSource[newIndex],
                    ];
                  }

                  const href =
                    image &&
                    (image.url
                      ? image.url
                      : image.thumbnailUrl
                      ? image.thumbnailUrl
                      : null);
                  return (
                    <Container>
                      <div>
                        <Checkbox
                          label="Cancel related Tasks"
                          checked={cancelOtherTask}
                          onChange={(newValue) =>
                            this.setState({
                              cancelOtherTask: newValue,
                            })
                          }
                        />
                      </div>
                      <div className="content_wrap">
                        <div className="thumbnail_wrap">
                          <label>Thumbnail</label>
                          <div className="thumbnail_content">
                            <img
                              src={
                                image &&
                                (image.thumbnailUrl ? image.thumbnailUrl : null)
                              }
                              alt=""
                            />
                            <div className="view-origin">
                              <div className="img-wrap">
                                <CustomLinkPolaris href={href}>
                                  <Icon source={MaximizeMajorMonotone}></Icon>
                                </CustomLinkPolaris>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="design-position_wrap">
                          <div className="design-position_inner">
                            {loading ? (
                              <div className="loading_wrap">
                                <Spinner />
                              </div>
                            ) : (
                              <>
                                {dataSource && dataSource.length > 0
                                  ? dataSource
                                      .filter(Boolean)
                                      .map((ds, idx) => (
                                        <div
                                          key={idx}
                                          className="design-position-content"
                                        >
                                          <label>
                                            {ds?.designPosition &&
                                              getDesignPositionName(
                                                ds.designPosition,
                                              )}
                                          </label>
                                          <div className="design-position_item">
                                            <MediaSelectorButtonPolaris
                                              value={
                                                ds && (ds.file ? [ds.file] : [])
                                              }
                                              isPrintFile={true}
                                              prefix="print_files/"
                                              folder={"print_files"}
                                              singleUpload={true}
                                              multiple={false}
                                              productBaseId={productBaseId}
                                              placeholderImage={
                                                ds?.designPosition?.image
                                              }
                                              maxSize={this.state.maxSize}
                                              onChange={(value) => {
                                                if (value.length) {
                                                  this.setState((prev) => ({
                                                    designPosition: {
                                                      ...prev.designPosition,
                                                      [idx]: value,
                                                    },
                                                    currentDesignId: {
                                                      ...prev.currentDesignId,
                                                      [idx]: value[0]?.id,
                                                    },
                                                  }));
                                                } else {
                                                  this.setState((prev) => ({
                                                    currentDesignId: {
                                                      ...prev.currentDesignId,
                                                      [idx]: null,
                                                    },
                                                  }));
                                                }
                                              }}
                                              // Check dimensions
                                              isCompareDesign
                                              isUploadDesign
                                              designPosition={ds.designPosition}
                                              onCheckDimensions={(value) => {
                                                // Disable check dimension.
                                                if (value === true) {
                                                  this.setState({
                                                    disableSubmitSpec: false,
                                                  });
                                                  return;
                                                }
                                                return this.handleCheckDimensions(
                                                  value,
                                                  idx,
                                                );
                                              }}
                                              onCheckingDimensions={(value) =>
                                                this.setState({
                                                  checkingDimensions: {
                                                    ...this.state
                                                      .checkingDimensions,
                                                    [idx]: value,
                                                  },
                                                })
                                              }
                                              onRemove={(id) =>
                                                this.deleteFileOutAmz(
                                                  id,
                                                  client,
                                                  idx,
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="design-position-info">
                                            {this.state.checkingDimensions[
                                              idx
                                            ] ? (
                                              <Spinner size="small" />
                                            ) : dimensions[idx] &&
                                              dimensions[idx].message ? (
                                              <p
                                                className={`message_wrap ${
                                                  dimensions[idx].type ===
                                                  "success"
                                                    ? "success"
                                                    : "error"
                                                }`}
                                              >
                                                {dimensions[idx].message}
                                              </p>
                                            ) : null}
                                            {ds.designPosition &&
                                              ds.designPosition.description && (
                                                <span>{`(${ds.designPosition.description})`}</span>
                                              )}
                                            {ds.designPosition
                                              .artworkGuidelines &&
                                            ds.designPosition.artworkGuidelines
                                              .length ? (
                                              <div>
                                                <strong
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  Artguides:{" "}
                                                </strong>
                                                {ds.designPosition.artworkGuidelines.map(
                                                  (ag, idx) => (
                                                    <div
                                                      key={`artworkGuidelines-${idx}`}
                                                      className="artwork_inner"
                                                    >
                                                      <CustomLinkPolaris
                                                        href={`${
                                                          ag.file.url ||
                                                          ag.file.thumbnailUrl
                                                        }`}
                                                      >
                                                        {` ${ag.file.name} `}
                                                      </CustomLinkPolaris>
                                                      {idx !==
                                                      ds.designPosition
                                                        .artworkGuidelines
                                                        .length -
                                                        1
                                                        ? "|"
                                                        : ""}
                                                    </div>
                                                  ),
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                          {dataSource.length > 1 &&
                                            ds.designPosition.name.toLowerCase() ===
                                              "master" && (
                                              <div
                                                style={{
                                                  marginTop: "1rem",
                                                }}
                                              >
                                                Or
                                              </div>
                                            )}
                                        </div>
                                      ))
                                  : null}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Container>
                  );
                }}
              </Query>
            </Modal>
          )}
        </Mutation>
      </>
    );
  }
}

export default UploadDesignByLeadPolaris;
