import { formatter } from "../../../helper";

function genRows(rows, fn) {
  const res = [];
  for (let row of rows) {
    const tr = document.createElement("tr");
    tr.className = "Polaris-DataTable__TableRow";
    const { totalSale, totalBaseCost, totalRevenue } = row;

    const trData = [
      getSpan(""),
      fn(row),
      getSpan(totalSale || 0, "total-cost", true),
      getSpan(totalBaseCost || 0, "total-cost"),
      getSpan(totalRevenue || 0, "total-revenue"),
    ];
    for (let i = 0; i < trData.length; i++) {
      const value = trData[i];
      let td = document.createElement("td");
      td.className =
        "Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop";

      if (typeof value === "object" && value) {
        td.appendChild(value);
      } else {
        td.textContent = value;
      }
      tr.appendChild(td);
    }

    res.push(tr);
  }

  return res;
}

function getSpan(val = "", className = "", isTotal) {
  let span = "";
  if (val !== "") {
    span = document.createElement("span");
    span.className = className;
    span.textContent = isTotal ? val : formatter.format(val);
  } else {
    span = document.createElement("span");
    span.innerHTML = "&nbsp;&nbsp;";
    span.className = "col-index";
  }

  return span;
}

export function genTable(element, items, fn) {
  let table = element.querySelector("table");
  if (!table) {
    table = document.createElement("table");
    table.className = "Polaris-DataTable__Table";
  }

  let tbody = element.querySelector("body");
  if (!tbody) {
    tbody = document.createElement("tbody");
  }

  const rows = genRows(items, fn);
  if (rows.length > 0) {
    for (let r of rows) {
      tbody.appendChild(r);
    }

    table.appendChild(tbody);
    element.appendChild(table);
  }
}
