import { Badge, Collapsible, DataTable } from "@shopify/polaris";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { strToSlug } from "../../../helper";
import { useProductVariants } from "../../../hooks/useProductVariants";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { ColActionsPolaris } from "./ColActionsPolaris";

const Container = styled.div`
  .bases-wrap {
    display: flex;
    flex-direction: row;
    margin: 2rem;
  }
  .img-wrap {
    margin-right: 3rem;
  }
  .attribute-title,
  .variant-total {
    margin-top: 1rem;
  }
  .Polaris-Tag + .Polaris-Tag {
    margin-left: 1rem;
  }
  .attr-option-val {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
    height: auto;
    margin-right: 8px;
    margin-top: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: default;
    opacity: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .attr-option-val.pm-tag-enabled {
    background-color: rgb(24, 144, 255);
    border-color: transparent;
    color: #fff;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;

export const TableTemplatesPolaris = ({
  data,
  handleUseTemplate,
  filter,
  setFilter,
  allTemplate,
  selectedTab,
}) => {
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(0);
  const [mergeData, setMergeData] = useState(data);

  useEffect(() => {
    const newData = data;
    if (newData?.templates?.nodes?.length > 0) {
      const newTemplates = newData.templates.nodes.map(async (node) => {
        const templateData = node.data;
        let { products } = templateData || {};
        if (products?.length > 0) {
          products = await Promise.all(
            products.map(async (p) => {
              if (p.id) {
                return await getVariants(p);
              }
              return p;
            })
          );
        }
      });
    }
    setMergeData(data);
  }, [data]);

  // Hooks
  const [getVariants] = useProductVariants();

  const handleActive = useCallback((v) => {
    setActive((a) => !a);
    setIndex(v);
  }, []);

  function getActivatedProductAttrs(variants) {
    let activatedAttrs = {};

    _.forOwn(variants, function (el, key) {
      if (
        !el.disabled &&
        el.productBaseVariant &&
        el.productBaseVariant.attributes &&
        el.productBaseVariant.attributes.length > 0
      ) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (!activatedAttrs[v.slug]) {
            activatedAttrs[v.slug] = [];
          }
          activatedAttrs[v.slug].push(v.option);
        });
      }
    });
    return activatedAttrs;
  }

  function getProductBaseAttrs(productBaseAttrs, activateAttrs, variants) {
    let pBaseAttrs = {};

    let disabledAll = false;
    if (variants) {
      let countActivated = this.countActivatedVariants(variants);
      if (0 === countActivated) {
        disabledAll = true;
      }
    }

    if (productBaseAttrs && productBaseAttrs.length > 0) {
      productBaseAttrs.forEach((val) => {
        let attrOptions = {};
        val.options.forEach((opt) => {
          let disableVal = false;
          if (disabledAll) {
            disableVal = true;
          }
          if (
            activateAttrs &&
            activateAttrs[val.slug] &&
            activateAttrs[val.slug].length > 0
          ) {
            disableVal = true;
            if (_.includes(activateAttrs[val.slug], opt)) {
              disableVal = false;
            }
          }
          attrOptions[strToSlug(opt)] = {
            label: opt,
            disabled: disableVal,
          };
        });
        pBaseAttrs[val.slug] = {
          name: val.name,
          slug: val.slug,
          options: attrOptions,
        };
      });
    }
    return pBaseAttrs;
  }

  function reBuildProductData(productVariants, templatePBaseVars) {
    let buildProductData = [];

    if (!productVariants || productVariants.length === 0) {
      return buildProductData;
    }
    productVariants.forEach((val) => {
      let pBaseId = val.productBaseVariantID;
      (templatePBaseVars || []).forEach((v) => {
        if (v.id === pBaseId) {
          let pVariant = {
            attributes: v.attributes,
          };
          val["productBaseVariant"] = pVariant;
          val["productBaseVariantId"] = v.id;
          val["image"] = null;
        }
      });
      buildProductData.push(val);
    });
    return buildProductData;
  }

  function countState(templateProductVariants) {
    let counEnabled = 0;
    let counDisabled = 0;
    if (!templateProductVariants || templateProductVariants.length === 0) {
      return {
        counEnabled,
        counDisabled,
      };
    }

    templateProductVariants.forEach((val) => {
      if (!val.disabled) {
        counEnabled++;
      } else {
        counDisabled++;
      }
    });
    return {
      counEnabled,
      counDisabled,
    };
  }

  let newRows = [];
  if (data) {
    data.templates.nodes.forEach((temp, idx) => {
      let templateData = temp.data;
      // let templateProductVariants = templateData.products[0].variants;
      // let count = countState(templateProductVariants);

      // let templateProduct = templateData.products[0];
      // let productVariants = templateProduct.variants;

      // let templatePBase = templateData.productBases[0];
      // let templatePBaseVars = templatePBase["variants"];
      // let templatePBaseAttr = templatePBase["attributes"];

      // let buildProductData = reBuildProductData(
      //   productVariants,
      //   templatePBaseVars
      // );
      // let productAttrs = getProductBaseAttrs(
      //   templatePBaseAttr,
      //   getActivatedProductAttrs(buildProductData)
      // );

      const bases = (templateData?.productBases || []).reduce((acc, item) => {
        if (!item || typeof item !== "object") return acc;
        acc[item.id] = item;
        return acc;
      }, {});
      const baseIds = templateData?.ProductBaseIds || [];

      newRows.push([
        <div className="flex flex-col">
          <span>
            <strong>{`#${++idx} `}</strong>
            {temp.name}
            {"  "}
            {2 === selectedTab && temp.grouping ? (
              <Badge status="success">Grouping</Badge>
            ) : (
              ""
            )}
          </span>
          <span>{temp.slug}</span>
          <Collapsible
            open={true === active ? index === idx : active}
            id={`${idx}`}
            transition={{ duration: "150ms", timingFunction: "ease" }}
          >
            {baseIds?.length > 0
              ? baseIds.map((id, index) => {
                  const pb = bases[id];
                  if (!pb) return null;
                  let product =
                    templateData.products &&
                    templateData.products.length &&
                    templateData.products.find(
                      (p) => p.productbaseId === pb.id
                    );

                  let count = countState(product.variants);
                  let templateProduct = product;
                  let productVariants = templateProduct.variants;

                  let templatePBase = pb;
                  let templatePBaseVars = templatePBase["variants"];
                  let templatePBaseAttr = templatePBase["attributes"];

                  let buildProductData = reBuildProductData(
                    productVariants,
                    templatePBaseVars
                  );
                  let productAttrs = getProductBaseAttrs(
                    templatePBaseAttr,
                    getActivatedProductAttrs(buildProductData)
                  );
                  const images = pb.images;
                  return (
                    <div key={`${id}-${index}`} className="bases-wrap">
                      <div className="img-wrap">
                        {images.map((file, idx) => (
                          <div key={`${id}-${idx}`} style={{ width: 200 }}>
                            <img
                              src={
                                file.thumbnailUrl ? file.thumbnailUrl : file.url
                              }
                              alt={""}
                              style={{ width: "100%" }}
                            />
                          </div>
                        ))}
                        <span
                          style={{ marginTop: "1rem", display: "inline-block" }}
                        >
                          {pb.baseCostDescription}
                        </span>
                      </div>
                      <div>
                        <span>{pb.title}</span>
                        {productAttrs &&
                          Object.keys(productAttrs).map((attribute, index) => {
                            return (
                              <div key={`${idx}-attribute-${attribute}`}>
                                <div className={"attribute-title"}>
                                  {productAttrs[attribute] &&
                                    productAttrs[attribute].name}
                                  :
                                </div>
                                <div>
                                  {productAttrs[attribute] &&
                                    productAttrs[attribute]["options"] &&
                                    Object.keys(
                                      productAttrs[attribute]["options"]
                                    ).map((option, key) => {
                                      let tagClassName =
                                        "attr-option-val pm-tag-enabled";
                                      if (
                                        productAttrs[attribute]["options"][
                                          option
                                        ]["disabled"]
                                      ) {
                                        tagClassName =
                                          "attr-option-val pm-tag-disabled";
                                      }
                                      return (
                                        <div
                                          key={`${idx}-attr-option-${option}`}
                                          className={tagClassName}
                                        >
                                          {productAttrs[attribute]["options"] &&
                                            productAttrs[attribute]["options"][
                                              option
                                            ] &&
                                            productAttrs[attribute]["options"][
                                              option
                                            ]["label"]}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}

                        <div className={"variant-total"}>
                          {count && count.counDisabled >= 0 && (
                            <div>
                              Total enabled: <span>{count.counEnabled}</span>
                            </div>
                          )}
                          {count && count.counDisabled >= 0 && (
                            <div>
                              Total disabled: <span>{count.counDisabled}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </Collapsible>
        </div>,
        <ColActionsPolaris
          template={temp}
          idx={idx}
          onSelect={handleActive}
          handleUseTemplate={handleUseTemplate}
          filter={filter}
          allTemplate={allTemplate}
          selectedTab={selectedTab}
        />,
      ]);
    });
  }

  let { limit, offset } = filter;
  const total = _.get(data, "templates.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "numeric"]}
        headings={["Name", "Actions"]}
        rows={newRows}
        verticalAlign={"top"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
