import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  InlineError,
  Page,
  Spinner,
  TextField,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { countIterate } from "../../components/switch-fulfillment/AddSwitchFulfillment";
import { SwitchFulfillmentProvider } from "../../components/switch-fulfillment/context";
import { OrderSourcePolaris } from "../../components/switch-fulfillment/OrderSourcePolaris";
import { genShippingOptionKey } from "../../components/switch-fulfillment/ShippingOptions";
import SwitchFulfillmentForm from "../../components/switch-fulfillment/SwitchFulfillmentForm";
import { handleError } from "../../helper";
import history from "../../history";

const loadQuery = gql`
  query load($id: ID!) {
    fulfillments {
      id
      name
      slug
    }
    switchFulfillmentById(id: $id) {
      id
      title
      usingShippingOption
      shippingOptionConfigs
      productBase {
        id
        name
        variants {
          id
          name
        }
      }
      fulfillment {
        id
        name
        slug
      }
      orderSource
      items {
        id
        orderSource
        variant {
          id
          name
        }
        targetProductBase {
          id
          name
        }
        targetFulfillment {
          id
          name
          slug
        }
        targetVariant {
          id
          name
        }
      }
    }
  }
`;

const updateMutation = gql`
  mutation updateSwitchFulfillment(
    $id: ID!
    $input: CreateSwitchFulfillmentInput!
  ) {
    updateSwitchFulfillment(id: $id, input: $input) {
      id
      title
    }
  }
`;
const EditSwitchFulfillment = () => {
  const { id } = useParams();
  const [updateItems, setUpdateItems] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [title, setTitle] = useState("");
  const [err, setErr] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  const { data, loading } = useQuery(loadQuery, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  const [message, setMessage] = useState(null);
  const valueFirstRef = useRef(false);
  const [shippingOption, setShippingOption] = useState({
    usingShippingOption: false,
    shippingOptionConfigs: new Map(),
  });

  const [orderSource, setOrderSource] = useState(["nil"]);

  // Mutation
  const [update, { loading: loadingM }] = useMutation(updateMutation, {
    onCompleted: () => {
      history.push(`/seller/fulfillment/switch`);
    },
    onError: (err) => {
      setMessage(handleError(err.toString()));
    },
  });

  const value =
    data && data.switchFulfillmentById ? data.switchFulfillmentById : {};
  const items =
    value && value.items
      ? value.items.map((v) => {
          return {
            variantId: v.variant.id,
            targetFulfillmentId: v.targetFulfillment
              ? v.targetFulfillment.id
              : null,
            targetProductBaseId: v.targetProductBase.id,
            targetVariantId: v.targetVariant.id,
            orderSource: v.orderSource,
          };
        })
      : [];

  useEffect(() => {
    if (data) {
      setTitle(data.switchFulfillmentById.title);
    }
    let value = data?.switchFulfillmentById;
    if (value?.orderSource) {
      setOrderSource(value.orderSource);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(value).length === 0 || valueFirstRef.current) return;
    const { usingShippingOption, shippingOptionConfigs } = value;
    let configs = new Map();
    if (
      Array.isArray(shippingOptionConfigs) &&
      shippingOptionConfigs.length > 0
    ) {
      configs = new Map(
        shippingOptionConfigs.map(
          ({ originFulfillmentID, targetFulfillmentID, mappingOptions }) => {
            let opts = new Map();
            const key = genShippingOptionKey(
              originFulfillmentID,
              targetFulfillmentID
            );
            if (Array.isArray(mappingOptions) && mappingOptions.length > 0) {
              opts = new Map(
                mappingOptions.map(
                  ({ originShippingMethod, targetShippingMethod }) => {
                    return [
                      originShippingMethod,
                      { targetShippingMethod, originShippingMethod },
                    ];
                  }
                )
              );
            }
            return [
              key,
              {
                originFulfillmentID,
                targetFulfillmentID,
                mappingOptions: opts,
              },
            ];
          }
        )
      );
    }

    setShippingOption((prev) => ({
      ...prev,
      usingShippingOption,
      shippingOptionConfigs: configs,
    }));
    valueFirstRef.current = true;
  }, [value]);

  const shippingOptionConfigs = [];
  if (typeof shippingOption.shippingOptionConfigs === "object") {
    for (let item of shippingOption.shippingOptionConfigs.values()) {
      const { mappingOptions, ...rest } = item || {};
      const opts = [];
      if (typeof mappingOptions === "object") {
        for (let entry of mappingOptions.values()) {
          opts.push(entry);
        }
      }
      if (opts.length > 0) {
        shippingOptionConfigs.push({ ...rest, mappingOptions: opts });
      }
    }
  }

  const handleSubmit = useCallback(() => {
    if (!title) {
      setErr({ title: "Title is required" });
      return;
    }
    const haveDup = countIterate(updateItems);
    if (haveDup) {
      setMessage("Only keep one setting per target variant");
      return;
    } else {
      setMessage(null);
    }

    const newItems = updateItems.map(({ orderSource, ...rest }) => {
      if (
        !orderSource ||
        orderSource.length === 0 ||
        orderSource[0] === "nil"
      ) {
        return {
          ...rest,
          orderSource: undefined,
        };
      }

      return {
        ...rest,
        orderSource,
      };
    });

    update({
      variables: {
        id: value.id,
        input: {
          title: title,
          productBaseId: value.productBase.id,
          fulfillmentId: value.fulfillment ? value.fulfillment.id : null,
          items: newItems,
          orderSource:
            orderSource?.length && orderSource[0] === "nil"
              ? null
              : orderSource,
          usingShippingOption: shippingOption.usingShippingOption,
          shippingOptionConfigs:
            shippingOption.usingShippingOption &&
            shippingOptionConfigs?.length > 0
              ? shippingOptionConfigs
              : undefined,
        },
      },
    });
  }, [
    value,
    title,
    updateItems,
    orderSource,
    shippingOption,
    shippingOptionConfigs,
    update,
  ]);

  if (loading)
    return <Spinner accessibilityLabel="Loading" size="small" color="teal" />;
  return (
    <div>
      <Page
        fullWidth
        primaryAction={
          <Button
            disabled={disabled}
            primary
            loading={loadingM}
            onClick={handleSubmit}
          >
            Save
          </Button>
        }
        breadcrumbs={[
          {
            content: "Switch Fulfillment",
            url: "/seller/fulfillment/",
          },
        ]}
        title="Switch Fulfillment"
      >
        <div style={{ marginBottom: 20 }}>
          <Card title="Update Switch Fulfillment" sectioned>
            <TextField
              error={!!get(err, "title", null)}
              value={title}
              helpText={<InlineError message={get(err, "title", "")} />}
              onChange={(v) => setTitle(v)}
              label="Switch Title"
              type="text"
            />
            <div style={{ marginTop: "1.6rem" }}>
              <OrderSourcePolaris
                value={orderSource}
                onChange={(newValue) => {
                  setDisabled(false);
                  setOrderSource(() => [...newValue]);
                }}
              />
            </div>
          </Card>
        </div>
        <SwitchFulfillmentProvider
          shippingOption={shippingOption}
          setShippingOption={setShippingOption}
          fulfillment={value?.fulfillment || {}}
          allFulfillment={data && data.fulfillments ? data.fulfillments : []}
          orderSource={orderSource}
        >
          <SwitchFulfillmentForm
            onChange={(v) => {
              setUpdateItems(v);
              let isValid = true;
              for (let i = 0; i < v.length; i++) {
                const item = v[i];
                if (
                  !item.variantId ||
                  !item.targetProductBaseId ||
                  !item.targetVariantId
                ) {
                  isValid = false;
                  break;
                }
              }
              setDisabled(!isValid);
            }}
            allFulfillment={data && data.fulfillments ? data.fulfillments : []}
            value={items}
            title={value.title}
            productBase={value.productBase}
            fulfillment={value.fulfillment}
          />
        </SwitchFulfillmentProvider>
        {message && (
          <Toast
            error
            content={message}
            onDismiss={() => {
              setMessage(null);
            }}
          />
        )}
      </Page>
    </div>
  );
};

export default EditSwitchFulfillment;
