import React, { useState, useCallback } from "react";
import { Card, Loading, Select, Heading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ProductCrawlerDetailPolaris } from "./ProductCrawlerDetailPolaris";

const Container = styled.div`
  .filter-wrap {
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: -1rem;
  }
`;

export const LOAD_QUERY = gql`
  query crawlItems($crawlId: ID!, $limit: Int, $offset: Int, $status: String) {
    crawlItems(
      crawlId: $crawlId
      limit: $limit
      offset: $offset
      status: $status
    ) {
      total
      nodes {
        id
        title
        url
        titleXpath
        imageUrl
        imageXpath
        productBaseSlug
        type
        status
      }
    }
  }
`;

export const ProductCrawlerPolaris = ({ id }) => {
  const [selected, setSelected] = useState("all");
  const [filter, setFilter] = useState({
    crawlId: id,
    limit: 20,
    offset: 0,
    status: null,
  });

  const { data, loading } = useQuery(LOAD_QUERY, {
    variables: { ...filter },
    fetchPolicy: "network-only",
  });

  const loadingMarkup = loading && <Loading />;
  const options = [
    { value: "all", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Error", label: "Error" },
  ];

  const handleSelectChange = useCallback((value) => {
    setSelected(value);
    setFilter((prevState) => ({
      ...prevState,
      status: "all" === value ? null : value,
    }));
  }, []);

  return (
    <Container>
      <div className="filter-wrap">
        <Heading element="h4">Filter status:</Heading>
        <Select
          options={options}
          value={selected}
          onChange={handleSelectChange}
        />
      </div>
      <Card>
        {loadingMarkup}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data &&
          data.crawlItems &&
          data.crawlItems.nodes &&
          data.crawlItems.nodes.length ? (
          <ProductCrawlerDetailPolaris
            data={data}
            filter={filter}
            crawlId={id}
            onChange={({ status }) =>
              setFilter((prevState) => ({ ...prevState, status }))
            }
            setFilter={(v) =>
              setFilter((prevState) => ({ ...prevState, offset: v }))
            }
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Container>
  );
};
