import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import get from "lodash/get";

import {
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Scrollable,
  Spinner,
  Stack,
} from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { REPORT_SALE_STORES } from "../../../graphql/queries";
import { formatter, getUnique, reducerFn, removeEl } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";
import { useOwnerStore } from "../hooks/useOwnerStore";
import { ComparedPercent } from "./ComparedPercent";
import { useCollapseContext } from "./LayoutSection";
import ShowCard, { conditionByFilter } from "./ShowCard";
import { genTable } from "../utils";

const collapseKey = "stores";
export default function StoreV2({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  const { collapseActive = {}, setCollapseActive = () => {} } =
    useCollapseContext() || {};
  const open = !!collapseActive[collapseKey];

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    saleChanel: saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    storeManagerID: null,
  });
  const [state, setState] = useReducer(reducerFn, {
    rows: [],
    total: 0,
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,

    loadMore: false,
    cantLoad: false,
    loadOwnerStore: false,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
    totalCost: 0,
    totalRevenues: 0,
  });
  const timeoutRef = useRef(null);

  const [getOwnerStores, ownerStoreState] = useOwnerStore(filter);

  // Queries
  const { data, loading, fetchMore } = useQuery(REPORT_SALE_STORES, {
    variables: {
      filter,
    },
  });

  useQuery(REPORT_SALE_STORES, {
    variables: {
      filter: {
        ...filter,
        ...(filter.filterByTime ? { limit: 1000, offset: 0 } : {}),
      },
    },
    onCompleted: (res) => {
      const total = getTotal(res);
      setState((p) => ({ ...p, ...total }));
    },
  });

  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_SALE_STORES);

  // Get data

  useEffect(() => {
    if (filterCtx != null) {
      let { storeID, ...ignoredFilter } = filterCtx;
      setFilter((prev) => ({ ...prev, ...ignoredFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
    setState({ cantLoad: false });
  }, [range, teamID]);

  const rowsRef = useRef(new Set());
  useEffect(() => {
    if (state.loadOwnerStore) {
      const storeIDs = getStoreIDs(data);
      if (storeIDs.length > 0) {
        getOwnerStores(storeIDs);
      }
    }
  }, [state.loadOwnerStore, data]);

  const tableRef = useRef(null);
  useEffect(() => {
    const nodes = data?.reportSaleByStores?.nodes;
    const total = data?.reportSaleByStores?.total;

    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node, index) => {
              const totalSale = node?.totalSale;
              const totalBaseCost = node?.totalBaseCost || 0;
              const totalRevenues = node?.totalRevenues || 0;
              const store = node?.store;
              const title = store?.title;
              const storeId = node?.storeId;

              const newRows = [
                <div className="col-index">{index + 1}</div>,
                <div className="store-name">
                  <span>{title || storeId}</span>
                </div>,
                <div className="total-sale">
                  <span>{totalSale}</span>
                </div>,
                <div className="total-cost">
                  <span>{formatter.format(totalBaseCost)}</span>
                </div>,
                <div className="total-revenue">
                  <span>{formatter.format(totalRevenues)}</span>
                </div>,
              ];
              return [
                newRows,
                [
                  <div
                    data-store-id={`${storeId}`}
                    style={{ display: "none" }}
                    className="owner-store-slot"
                    ref={(node) => rowsRef.current.add(node)}
                  />,
                ],
              ];
            })
            .flat()
        : [];

    setState((prev) => ({
      ...prev,
      rows: newItems,
      total,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useLayoutEffect(() => {
    if (rowsRef.current.size) {
      for (let row of Array.from(rowsRef.current.values())) {
        if (!row || !row.parentNode) continue;
        const storeId = row.getAttribute("data-store-id");

        if (row.parentNode && !row.parentNode.classList.contains("slot")) {
          row.parentNode.classList.add("slot");
          row.parentNode.setAttribute("colspan", 5);
        }

        const mapStores = ownerStoreState.data[storeId];
        if (state.loadOwnerStore) {
          if (mapStores?.length > 0) {
            genTable(row.parentNode, mapStores, genOwnerInfo);
          }
        } else {
          if (row.parentNode) {
            const table = row.parentNode.querySelector("table");
            if (table) {
              removeEl(table);
            }
          }
        }
      }
    }
  }, [rowsRef.current.size, ownerStoreState.data, state.loadOwnerStore]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportSaleByStores?.totalSale;
      const totalBaseCost = dataC?.reportSaleByStores?.totalBaseCost;
      const totalRevenues = dataC?.reportSaleByStores?.totalRevenues;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
        totalCost: totalBaseCost,
        totalRevenues,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  // Handles
  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!state.cantLoad) {
      setState({ loadMore: true });
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportSaleByStores.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportSaleByStores = fetchMoreResult.reportSaleByStores;
            const nodes = reportSaleByStores.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;
            setState({ loadMore: false });
            if (nodes.length < limit) {
              setState({ cantLoad: true });
            }

            const newNodes = [...prev.reportSaleByStores.nodes, ...nodes];

            const result = getUnique(newNodes, "storeId");

            const totalCost = (result || []).reduce((acc, item) => {
              if (item?.totalBaseCost > 0) acc += item.totalBaseCost;
              return acc;
            }, 0);

            const totalRevenues = (result || []).reduce((acc, item) => {
              if (item?.totalRevenues > 0) acc += item.totalRevenues;
              return acc;
            }, 0);

            setState((p) => ({
              ...p,
              totalCost: totalCost.toFixed(2),
              totalRevenues: totalRevenues.toFixed(2),
            }));

            return {
              ...prev,
              reportSaleByStores: {
                ...prev.reportSaleByStores,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, state.cantLoad, fetchMore]);

  const con = conditionByFilter(filterCtx, ["storeID"]);

  const btnRef = useRef(null);
  const spinnerMarkup = (
    <Stack distribution="center">
      <Spinner size="small" />
    </Stack>
  );

  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);

  const handleLoadOwnerStore = useCallback((val) => {
    setState({ loadOwnerStore: val });
  }, []);

  const subTitleMarkup = useMemo(
    () => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <span>{state.totalSale} Line items</span>
          <ComparedPercent
            originalVal={state.totalSale}
            newVal={stateCompare.totalSale}
          />
        </span>

        <span>
          <span>Total cost: {formatter.format(state.totalCost)}</span>
          <ComparedPercent
            originalVal={state.totalCost}
            newVal={stateCompare.totalCost}
          />
        </span>
        <span>
          <span>Total revenue: {formatter.format(state.totalRevenues)}</span>
          <ComparedPercent
            originalVal={state.totalRevenues}
            newVal={stateCompare.totalRevenues}
          />
        </span>
      </div>
    ),
    [
      state.totalSale,
      stateCompare.totalSale,
      state.totalCost,
      state.totalRevenues,
      stateCompare.totalRevenues,
      stateCompare.totalCost,
    ],
  );

  const headerMarkup = useMemo(
    () => (
      <Stack spacing="loose">
        <Stack.Item fill>
          <div>
            <HeadingWrap>
              <span className="btn-wrap">
                <CaretRightOutlined
                  rotate={open ? 90 : 0}
                  style={{ color: "#000" }}
                  ref={btnRef}
                  onClick={handleToggle}
                />
              </span>
              <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
                Stores
              </h2>
            </HeadingWrap>
            <Checkbox
              label="Breakdown by Store Manager"
              onChange={handleLoadOwnerStore}
              checked={state.loadOwnerStore}
            />
          </div>
        </Stack.Item>
        <span>{subTitleMarkup}</span>
      </Stack>
    ),
    [subTitleMarkup, open, state.loadOwnerStore],
  );

  return (
    <ShowCard show={con}>
      <Card>
        <Container>
          <Card.Header title={headerMarkup} />
          <Card.Section>
            <div>
              <Collapsible
                open={open}
                id={collapseKey}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                {loading || loadingC || loadingRoot ? (
                  spinnerMarkup
                ) : state.rows?.length > 0 ? (
                  <Scrollable
                    shadow
                    id={`scrollable-stores`}
                    style={{
                      maxHeight: "43rem",
                      paddingRight: "1.6rem",
                      marginRight: "-1.6rem",
                    }}
                    onScrolledToBottom={handleScroll}
                  >
                    <Stack vertical spacing="loose" wrap={false}>
                      <div ref={tableRef}>
                        <DataTable
                          rows={state.rows}
                          columnContentTypes={[
                            "text",
                            "text",
                            "text",
                            "text",
                            "text",
                          ]}
                          headings={[
                            "#",
                            "Name",
                            "Sales",
                            "Total Cost",
                            "Total Revenue",
                          ]}
                          hideScrollIndicator
                        />
                      </div>
                      {state.loadMore && spinnerMarkup}
                    </Stack>
                  </Scrollable>
                ) : (
                  <EmptyStatePolaris noData slim />
                )}
              </Collapsible>
            </div>
          </Card.Section>
        </Container>
      </Card>
    </ShowCard>
  );
}

//
function getStoreIDs(data) {
  const res = [];
  if (data?.reportSaleByStores?.nodes?.length > 0) {
    res.push(
      ...data.reportSaleByStores.nodes.map((i) => i.storeId).filter(Boolean),
    );
  }

  return res;
}

function genTimeMarkup(data, isCreated) {
  const b = document.createElement("b");
  b.textContent = isCreated ? "Created at: " : "Deleted at: ";

  let span = "";
  const key = isCreated ? "createdAt" : "deletedAt";
  if (data[key]) {
    span = document.createElement("span");
    span.appendChild(b);

    const val = document.createTextNode(data[key]);
    span.appendChild(val);
  }
  return span;
}

function genOwnerInfo(data) {
  const createdAtMarkup = genTimeMarkup(data, true);
  const deletedAtMarkup = genTimeMarkup(data);

  const times = [createdAtMarkup, deletedAtMarkup].filter(Boolean);
  const i = document.createElement("i");
  i.style = "font-size:12px;";
  for (let t of times) {
    i.appendChild(t);
    i.appendChild(t);
  }

  if (times.length > 1) {
    i.insertBefore(document.createTextNode(" - "), deletedAtMarkup);
  }

  if (times.length > 0) {
    i.insertBefore(document.createTextNode(" ("), i.firstElementChild);
    i.appendChild(document.createTextNode(") "));
  }

  const userDiv = document.createElement("div");
  userDiv.innerHTML = `<span role="img" aria-label="minus" class="anticon anticon-minus">
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="minus"
    width="10px"
    height="10px"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
  </svg>
</span> ${data.user || ""}`;
  userDiv.appendChild(i);
  userDiv.className = "store-name store-owner";
  return userDiv;
}

function getSpan(val = "", className = "", isTotal) {
  let span = "";
  if (val !== "") {
    span = document.createElement("span");
    span.className = className;
    span.textContent = isTotal ? val : formatter.format(val);
  } else {
    span = document.createElement("span");
    span.innerHTML = "&nbsp;&nbsp;";
  }

  return span;
}

function getTotal(res) {
  const data = get(res, "reportSaleByStores");
  const totalSale = get(data, "totalSale");
  const nodes = get(data, "nodes") || [];
  let totalCost = 0;
  let totalRev = 0;

  for (let item of nodes) {
    const totalBaseCost = item?.totalBaseCost || 0;
    const totalRevenues = item?.totalRevenues || 0;
    totalCost += totalBaseCost;
    totalRev += totalRevenues;
  }

  return {
    totalSale,
    totalCost: totalCost.toFixed(2),
    totalRevenues: totalRev.toFixed(2),
  };
}

const Container = styled.div`
  .item {
    white-space: normal;
    word-break: break-word;
  }

  .col-index {
    width: 20px;
    display: inline-block;
  }

  .store-name {
    white-space: break-spaces;
    width: 300px;
  }

  .total-sale,
  .total-cost,
  .total-revenue {
    width: 100px;
    display: inline-block;
  }

  .Polaris-DataTable__Cell {
    &.slot {
      padding: 0;
      display: table-cell !important;

      .Polaris-DataTable__Cell {
        background-color: #f9fafb;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .store-owner {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        white-space: break-spaces;
        padding-left: 16px;
        width: 300px;
      }
    }

    &:has(.owner-store-slot) {
      display: none;
    }
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;
