import React, { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Stack, TextStyle, Icon } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { CREATE_NICHE } from "../../../graphql/mutations";

export function QuickAdd({ value, onCompleted }) {
    // Mutation
    const [create] = useMutation(CREATE_NICHE, { onError: () => {} });

    const handleSubmit = useCallback(
        (event) => {
            event.stopPropagation();

            const input = {
                name: value,
            };

            create({
                variables: { input },
            })
                .then((res) => {
                    const data = res.data.createNiche;
                    if (onCompleted) onCompleted(data);
                })
                .catch(() => {});
        },
        [create, onCompleted, value]
    );

    return (
        <Wrapper>
            <button className="btn-add" onClick={handleSubmit}>
                <Stack spacing="extraTight" wrap={false}>
                    <Icon source={CirclePlusMinor} color="indigo" />
                    <TextStyle variation="strong">Add </TextStyle>
                    <TextStyle>{value}</TextStyle>
                </Stack>
            </button>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .btn-add {
        appearance: none;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        font-size: inherit;
        line-height: inherit;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        display: block;
        width: 100%;
        min-height: 3.6rem;
        padding: 0.8rem 1.4rem;
        text-align: left;
        overflow-y: hidden;

        :hover {
            background-image: linear-gradient(
                rgba(223, 227, 232, 0.3),
                rgba(223, 227, 232, 0.3)
            );
        }

        :focus {
            outline: none;
        }

        .Polaris-Icon {
            margin-right: 3px;
        }
    }
`;
