import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Modal, Spinner, Icon, Heading } from "@shopify/polaris";
import { MaximizeMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import _, { includes, get } from "lodash";

import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { elementContains, getDesignPositionName, isURLDriveLink, replaceSizeForDriveLink } from "../../../helper";
import { META_DATA } from "../../../variable";
import {
    GET_VALUE_FOR_ORDER,
    GET_ORDER_DESIGNS,
} from "../../../graphql/queries";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { ColorPickerComp } from "../../seller/ModalImageClaimPolaris";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    .content-wrap {
        display: flex;
        width: 50%;
        flex-direction: column;
        row-gap: 1rem;
        .thumbnail-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            row-gap: 1rem;
            position: relative;
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                cursor: pointer;
                border-radius: 3px;
                box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
                    0 4px 6px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
    .orderDesign-wrap {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 1rem;
        .orderDesign-inner {
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;
            justify-content: start;
            width: 100%;
            transition: all .3s ease-out;
        }
        .orderDesign-item {
            & + .orderDesign-item {
                margin-top: 1.5rem;
            }
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            width: 100%;
            box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px 2px rgba(0, 0, 0, 0.1);
            .orderDesign-content {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100%;
                row-gap: 0.75rem;
                position: relative;
                overflow: hidden;
                .label {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.65);
                    padding: 0.5rem;
                    font-weight: 500;
                    transform: translateY(0);
                    transition: 0.3s all ease-out;
                }
                img {
                    border-radius: 3px;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    cursor: pointer;
                }
                &:hover {
                    .label {
                        transform: translateY(5rem);
                    }
                }
            }
        }
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .orderDesign-content,
    .thumbnail-wrap {
        &:hover {
            .view-origin {
                opacity: 1;
            }
        }
        .view-origin {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: right;
            padding-top: 1rem;
            padding-right: 1rem;
            background: linear-gradient(
                180deg,
                rgba(33, 43, 54, 0.55),
                hsla(0, 0%, 100%, 0)
            );
            opacity: 0;
            transition: 0.3s opacity ease-out;
            .img-wrap {
                display: inline-block;
            }
            .Polaris-Icon {
                svg {
                    fill: hsla(0, 0%, 100%, 0.8);
                }
            }
        }
    }

    .personalized-info {
        margin-top: 2rem;
        h4 {
            font-weight: 500;
            font-size: 1.2em;
            display: inline-block;
        }
        .personalized-inner {
            display: flex;
            flex-direction: row;
            column-gap: 2rem;
            row-gap: 2rem;
            flex-wrap: wrap;
            justify-content: flex-start;
            label.label {
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                font-size: 1em;
                margin-right: 1rem;
            }
        }
    }
`;

const ColorPickerContainer = styled.div`
    .color-picker-wrap {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .Polaris-ColorPicker__MainColor {
            height: 12rem;
            width: 12rem;
        }

        .Polaris-ColorPicker__HuePicker,
        .Polaris-ColorPicker__AlphaPicker {
            height: 12rem;
            width: 1.8rem;
        }
    }
`;

const OrderDesignContainer = styled.div`
    ${({ showColorPicker }) =>
        showColorPicker &&
        `
        margin-top: 7rem;
    `}
`;

export const ViewODPolaris = (props) => {
    const { open, toggleShowModal, value } = props;

    const orderId = value?.id;
    // const [showDS, setShowDS] = useState(false);
    // const [currentShowDS, setCurrentShowDS] = useState(null);
    const [showT, setShowT] = useState(false);
    useLockBodyScroll(open);

    // queries
    const [getOrderDesigns, { data, loading }] = useLazyQuery(
        GET_ORDER_DESIGNS,
        {
            fetchPolicy: "no-cache"
        }
    );

    const { data: dataV } = useQuery(GET_VALUE_FOR_ORDER, {
        variables: {
            orderId,
        },
        skip: !open || !orderId,
        fetchPolicy: "no-cache"
    });

    const productBaseId = value?.productVariant?.productBaseVariant?.productBase
        ? value.productVariant.productBaseVariant.productBase.id
        : value.product.productBases[0].id;

    useEffect(() => {
        if (orderId && productBaseId && open) {
            getOrderDesigns({ variables: { id: orderId, productBaseId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, productBaseId, open]);

    // const toggleShowDS = useCallback(
    //     () => setShowDS((prevState) => !prevState),
    //     []
    // );
    const toggleShowT = useCallback(
        () => setShowT((prevState) => !prevState),
        []
    );

    // Main image

    const product = get(value, "product", null);
    const mainImageId = product?.mainImageId;
    const images = product?.images;
    let mainImages = [];
    const isCampaign = product?.productBases?.length > 1;
    const haveMainImageId = mainImageId || mainImageId !== "undefined";

    if (productBaseId && isCampaign) {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.productBaseId === productBaseId)
                      .map((item) => item.file)
                : [];
        if (mainImages.length > 1 && haveMainImageId) {
            mainImages =
                images?.length > 0
                    ? images
                          .filter((img) => img?.file?.id === mainImageId)
                          .map((item) => item.file)
                    : [];
        }
    } else if (haveMainImageId) {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.file?.id === mainImageId)
                      .map((item) => item.file)
                : [];
    }
    if (mainImages.length === 0) {
        mainImages =
            images?.length > 0
                ? images.filter(Boolean).map((item) => item.file)
                : [];
    }

    let varThumbnail = mainImages.length && _.head(mainImages);

    let originUrl = varThumbnail
        ? varThumbnail.url
            ? varThumbnail.url
            : varThumbnail.thumbnailUrl
            ? varThumbnail.thumbnailUrl
            : null
        : null;

    

    // Markup
    useEffect(() => {
        let domCD = document.querySelector("#view-order-design_modal");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );
        for (let domParent of domParents) {
            if (elementContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100% - 10rem); height: calc(100vh)"
                );
            }
        }
    });

    return (
        <>
            <Modal
                title={`Order design`}
                open={open}
                onClose={toggleShowModal}
                sectioned
                large
            >
                <Container id="view-order-design_modal">
                    <div className="content-wrap">
                        <h3>Thumbnail</h3>
                        <div className="thumbnail-wrap">
                            <img
                                alt=""
                                src={originUrl}
                                onClick={toggleShowT}
                                className="shadow-md"
                            />
                            <div className="view-origin">
                                <div className="img-wrap">
                                    <CustomLinkPolaris href={originUrl}>
                                        <Icon source={MaximizeMajorMonotone} />
                                    </CustomLinkPolaris>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="orderDesign-wrap">
                        {/* {dataSourceMarkup ? (
                            <>
                                <h3>Design</h3>
                                {loading ? (
                                    <Spinner size="small" />
                                ) : (
                                    <div className="orderDesign-inner">
                                        {dataSourceMarkup}
                                    </div>
                                )}
                            </>
                        ) : null} */}

                        {loading ? (
                            <Spinner size="small" />
                        ) : (
                            <DesignMarkup data={data} dataV={dataV} />
                        )}
                    </div>
                    <Modal
                        open={showT}
                        onClose={toggleShowT}
                        title={"Thumbnail"}
                        sectioned
                    >
                        <div>
                            <img
                                style={{
                                    height: "auto",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                                src={originUrl}
                                alt=""
                            />
                        </div>
                    </Modal>
                </Container>
            </Modal>
        </>
    );
};

function PersonalizedItem({ item }) {
    let { field, value } = item;
    let label = field
        ? field.title
            ? field.title
            : field.name
            ? field.name
            : null
        : null;
    let hasImg = includes(value?.length && value[0], META_DATA);
    value = value && Array.isArray(value) && value.join(", ");

    return (
        <div>
            <label className="label">{label}:</label>
            {!hasImg ? <span style={{wordBreak: "break-all"}}>{value}</span> : null}
        </div>
    );
}

function PersonalizedInfo({ data }) {
    const valueForOrder = data?.getFieldValueForOrder;

    return valueForOrder?.length > 0 ? (
        <div className="personalized-info">
            <Heading element="h3">Personalized info:</Heading>
            <div className="personalized-inner">
                {valueForOrder.map((item, index) => {
                    if (item == null) return null;
                    return (
                        <PersonalizedItem
                            key={`personal-${index}`}
                            item={item}
                        />
                    );
                })}
            </div>
        </div>
    ) : null;
}

function DesignItem({ item, onClick, dataV, setShowColorPicker, bg }) {
    const { file, designPosition } = item;
    let thumbnailUrl = file?.thumbnailUrl;
    if (thumbnailUrl) {
        thumbnailUrl = replaceSizeForDriveLink(thumbnailUrl);
    }
    
    let originUrl = file.thumbnail
        ? file.thumbnail
        : file.url
        ? file.url
        : null;
    let dsName = designPosition?.name || "";
    let displayName = getDesignPositionName(designPosition);

    originUrl = isURLDriveLink(thumbnailUrl) ? thumbnailUrl : originUrl;

    return (
        <Fragment>
            <div className="orderDesign-item">
                <div className="orderDesign-content">
                    <img
                        src={originUrl}
                        alt=""
                        style={{
                            background: bg ? bg : "transparent",
                        }}
                        onClick={onClick({
                            ...item,
                            name: dsName,
                            originUrl,
                        })}
                        onLoad={() => {
                            setShowColorPicker(true);
                        }}
                    />
                    <label className="label">{displayName}</label>
                    <div className="view-origin">
                        <div className="img-wrap">
                            <CustomLinkPolaris href={originUrl}>
                                <Icon source={MaximizeMajorMonotone} />
                            </CustomLinkPolaris>
                        </div>
                    </div>
                </div>
            </div>
            <PersonalizedInfo data={dataV} />
        </Fragment>
    );
}

function DesignMarkup({ data, dataV }) {
    // Props
    const getOrderDesigns = data?.getOrderDesigns;
    let dataSource = getOrderDesigns?.orderDesigns
        ? getOrderDesigns.orderDesigns
        : [];
    dataSource = sortDataSource(dataSource);

    // State
    const [currentView, setCurrentView] = useState(null);
    const [bg, setBg] = useState("transparent");
    const [showColorPicker, setShowColorPicker] = useState(false);

    // Actions
    const handleClick = useCallback(
        (newValue) => () => {
            setCurrentView((prev) => {
                if (prev?.id === newValue.id) return null;
                return newValue;
            });
        },
        []
    );
    const handleClose = useCallback(() => setCurrentView(null), []);

    const modalUrl = currentView?.originUrl;
    const modalTitle = currentView?.name;

    return (
        <Fragment>
            {dataSource?.length > 0 ? (
                <Fragment>
                    <h3>Design</h3>
                    <ColorPickerContainer>
                        {showColorPicker && <ColorPickerComp setBg={setBg} />}
                    </ColorPickerContainer>
                    <OrderDesignContainer
                        className="orderDesign-inner"
                        showColorPicker={showColorPicker}
                    >
                        {dataSource.map((ds, index) => {
                            if (ds == null) return null;
                            return (
                                <DesignItem
                                    item={ds}
                                    key={`design-${index}`}
                                    onClick={handleClick}
                                    dataV={dataV}
                                    setShowColorPicker={setShowColorPicker}
                                    bg={bg}
                                />
                            );
                        })}
                    </OrderDesignContainer>
                </Fragment>
            ) : null}
            <Modal
                open={!!currentView}
                onClose={handleClose}
                sectioned
                title={modalTitle}
            >
                <div>
                    <img
                        style={{
                            height: "auto",
                            width: "100%",
                            objectFit: "cover",
                        }}
                        src={modalUrl}
                        alt=""
                    />
                </div>
            </Modal>
        </Fragment>
    );
}

function sortDataSource(data) {
    if (!(data instanceof Array) || data.length === 0) return [];

    data.sort((a, b) => a?.designPosition?.sorting - b?.designPosition?.sorting)
    const index = data.findIndex(
        (v) => v.designPosition.name.toLowerCase() === "master"
    );
    if (index > 0) {
        [data[0], data[index]] = [data[index], data[0]];
    }
    return data.filter((i) => i.file);
}
