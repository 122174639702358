import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { REPORT_KPI_TASK_BY_STORE_PLATFORM } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";

const collapseKey = "kpiOfTask";
const styleColumn = {
  width: "12rem",
  display: "inline-block",
};

function KPIOfTask({ saleChanel }) {
  // Context
  const {
    range,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
    filter: filterCtx,
  } = useReportsContext();

  // State
  const [filter, setFilter] = React.useState({
    filterByTime: range,
    saleChanel,
  });
  const [state, setState] = React.useReducer((p, s) => ({ ...p, ...s }), {
    items: [],
    total: 0,
    compareTotal: 0,
    loading: true,
  });

  // Queries
  const { data, loading, error } = useQuery(REPORT_KPI_TASK_BY_STORE_PLATFORM, {
    variables: {
      filter,
    },
  });

  const [lazyReport, { data: d2, loading: l2, error: e2 }] = useLazyQuery(
    REPORT_KPI_TASK_BY_STORE_PLATFORM,
  );

  React.useEffect(() => {
    const mergedL = loading || l2 || loadingRoot;
    setState({ loading: mergedL });
  }, [loading, l2, loadingRoot]);

  React.useEffect(() => {
    if (filterCtx != null && "fulfillmentID" in filterCtx) {
      let { fulfillmentID } = filterCtx;
      setFilter((prev) => ({ ...prev, fulfillmentID }));
    }
  }, [filterCtx]);

  React.useEffect(() => {
    setFilter((p) => ({
      ...p,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
  }, [range, teamID]);

  React.useEffect(() => {
    if (data?.reportKpiTaskByStorePlatform == null) return;

    const items = data.reportKpiTaskByStorePlatform;
    setState({ items });
  }, [data]);

  React.useEffect(() => {
    if (!isCompare) return;
    let filterByTime = null;
    if (rangeToCompare?.from != null) {
      filterByTime = {
        ...filter.filterByTime,
        ...rangeToCompare,
      };
    }
    lazyReport({
      variables: {
        filter: {
          ...filter,
          filterByTime,
        },
      },
    });
  }, [rangeToCompare, filter, isCompare]);

  // Markup
  const contentMarkup = React.useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <Stack
              key={`api-task-${index}`}
              distribution="equalSpacing"
              wrap={false}
            >
              <span className="index-wrap">{index + 1}</span>
              <span style={{ width: "16rem", display: "inline-block" }}>
                {item.platform}
              </span>
              <span style={styleColumn}>
                {(item.designerOffline || 0).toFixed(2)}
              </span>
              <span style={styleColumn}>
                {(item.designerOnline || 0).toFixed(2)}
              </span>
              <span style={{ width: "15rem", display: "inline-block" }}>
                {(item.designerTeamOnline || 0).toFixed(2)}
              </span>
              <span style={styleColumn}>{(item.bot || 0).toFixed(2)}</span>
              <span style={styleColumn}>
                {(item.etsyDesigner || 0).toFixed(2)}
              </span>
              <span style={styleColumn}>
                {(item.etsyOnline || 0).toFixed(2)}
              </span>
              <span style={styleColumn}>
                {(item.pmeArtist || 0).toFixed(2)}
              </span>
            </Stack>
          );
        })
      : null;
  }, [state.items]);

  const headMarkup = React.useMemo(
    () => (
      <Stack wrap={false}>
        <span className="index-wrap">
          <TextStyle variation="strong">#</TextStyle>
        </span>
        <span style={{ width: "16rem", display: "inline-block" }}>
          <TextStyle variation="strong">Platform</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">Designer Offline</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">Designer Online</TextStyle>
        </span>
        <span style={{ width: "15rem", display: "inline-block" }}>
          <TextStyle variation="strong">Designer Team Online</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">Bot</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">Etsy Designer</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">Etsy Online</TextStyle>
        </span>
        <span style={styleColumn}>
          <TextStyle variation="strong">PME Artist</TextStyle>
        </span>
      </Stack>
    ),
    [],
  );

  return (
    <CardBox
      title="Task's KPI"
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      loading={state.loading}
      error={error || e2}
      setFilter={setFilter}
      collapseKey={collapseKey}
    />
  );
}

export { KPIOfTask };
