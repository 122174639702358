import React, { Component } from "react";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import {
  Table,
  Skeleton,
  Button,
  Input,
  Select,
  Popover,
  Spin,
  Card,
} from "antd";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import moment from "moment";
import { USER_ROLE } from "../../variable";
import {
  handleError,
  setCookie,
  getCookie,
  convertStringToObject,
  convertObjectToParams,
  checkRole,
} from "../../helper";
import _ from "lodash";
import history from "../../history";
import { AppContext } from "../../context";

const FILTER_QUERY = gql`
  query filter($role: String!) {
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

const EXPORTS = gql`
  query exports($filter: ExportFilter) {
    exports(filter: $filter) {
      total
      nodes {
        id
        fileId
        file {
          id
          url
          name
          mimeType
        }
        template {
          id
          name
        }
        supplier {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

export default class Exports extends Component {
  state = {
    filter: {
      limit: +getCookie("perPageExports") || 20,
      offset: 0,
      search: null,
      supplierId: null,
      ...convertStringToObject(history.location.search),
    },
    page: 1,
  };

  componentDidMount() {
    const { currentUser } = this.context;
    if (!currentUser) return;

    const { isSupplier } = checkRole(currentUser);
    if (isSupplier) {
      this.setState((p) => ({
        ...p,
        filter: { ...p.filter, supplierId: currentUser.id },
      }));
    }
  }

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      const params = convertObjectToParams({
        limit: nextState.filter.limit,
        offset: nextState.filter.offset,
        search: nextState.filter.search,
      });
      history.push(history.location.pathname + "?" + params);
    }
  }

  onChange = (type, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [type]: value,
      },
    });
  };

  render() {
    const { filter } = this.state;
    const { role } = this.props;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(
        //   `selectedRowKeys: ${selectedRowKeys}`,
        //   "selectedRows: ",
        //   selectedRows
        // );
      },
    };
    const columns = [
      {
        title: "File Name",
        dataIndex: ["file", "name"],
        key: "file.name",
        width: 350,
      },
      ...([USER_ROLE.Admin].includes(role)
        ? [
            {
              title: "Supplier",
              dataIndex: "supplier",
              key: "supplier",
              render: (supplier) =>
                `${supplier.firstName} ${supplier.lastName}`,
            },
          ]
        : []),
      {
        title: "Template",
        dataIndex: ["template", "name"],
        key: "template",
        width: 150,
      },
      {
        title: "Export Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 150,
        render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "Actions",
        key: "action",
        width: 100,
        align: "right",
        fixed: "right",
        render: (_, record) => (
          <Button href={record.file.url}>
            <DownloadOutlined />
          </Button>
        ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Card bodyStyle={{ padding: 0 }}>
        <div className="flex p-4">
          <Input.Search
            defaultValue={filter.search}
            addonBefore={
              [USER_ROLE.Admin].includes(role) && (
                <Query
                  query={FILTER_QUERY}
                  variables={{ role: USER_ROLE.Supplier }}
                >
                  {({ loading, data, err }) => {
                    if (loading) return <Spin size="small" />;
                    if (err)
                      return <div>Error: {handleError(err.toString())}</div>;
                    return (
                      <Popover
                        title="Filter"
                        placement="bottom"
                        trigger="click"
                        content={
                          <Select
                            onChange={(v) => {
                              this.setState({
                                filter: { ...filter, supplierId: v },
                              });
                            }}
                            defaultValue={null}
                            className={"w-full"}
                          >
                            <Select.Option value={null}>All</Select.Option>
                            {data &&
                              data.listUserByRole.map((user, index) => (
                                <Select.Option
                                  key={index}
                                  value={user.id}
                                >{`${user.firstName} ${user.lastName}`}</Select.Option>
                              ))}
                          </Select>
                        }
                      >
                        <div className="cursor-pointer">
                          Filter <DownOutlined />
                        </div>
                      </Popover>
                    );
                  }}
                </Query>
              )
            }
            onChange={(e) => {
              if (!e.target.value) {
                this.setState({
                  filter: {
                    ...this.state.filter,
                    search: null,
                  },
                });
              }
            }}
            onSearch={(v) =>
              this.setState({ filter: { ...filter, search: v } })
            }
          />
        </div>
        <Query query={EXPORTS} variables={{ filter }}>
          {({ data, error, loading }) => {
            if (loading)
              return (
                <div className="p-4">
                  <Skeleton active />
                </div>
              );
            if (error) return error.toString();
            const dataSource = data && data.exports.nodes;
            return (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                scroll={{ x: tableWidth }}
                rowKey={(record) => record.id}
                dataSource={dataSource}
                pagination={{
                  pageSize: filter.limit,
                  current: filter.offset / filter.limit + 1,
                  total: data && data.exports.total,
                  onChange: (page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * filter.limit,
                      },
                    });
                  },
                  onShowSizeChange: (_, size) => {
                    this.setState({
                      page: 1,
                      filter: { ...filter, offset: 0, limit: size },
                    });
                    setCookie("perPageExports", size, 100);
                  },
                }}
              />
            );
          }}
        </Query>
      </Card>
    );
  }
}

Exports.contextType = AppContext;
