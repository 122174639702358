import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "antd";
import Element from "./Element";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import Preview from "./Preview";
import FieldSettings from "./FieldSettings";

export const ACCEPT_TYPE = [
  "text",
  "textarea",
  "number",
  "checkbox",
  "radio",
  "dropdown",
];
const Container = styled.div`
  .ant-card {
    .ant-card-head {
      border: 0 none;
      min-height: 20px;
      text-align: center;
      .ant-card-head-title {
        padding: 5px;
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
`;

export const ELEMENTS = [
  {
    title: "Text",
    icon: "edit",
    type: "text",
  },
  {
    title: "Textarea",
    icon: "align-left",
    type: "textarea",
  },
  {
    title: "Number",
    icon: "ordered-list",
    type: "number",
  },
  {
    title: "Checkbox",
    icon: "check-square",
    type: "checkbox",
  },
  {
    title: "Radio",
    icon: "unordered-list",
    type: "radio",
  },
  {
    title: "Dropdown",
    icon: "caret-down",
    type: "dropdown",
  },
];

const getFieldSettings = (element) => {
  switch (element.type) {
    case "checkbox":
      return {
        options: ["Apple", "Orange", "Mango"],
        defaultValue: ["Orange"],
      };
    case "dropdown":
      return {
        options: [
          { value: "Apple", label: "Apple" },
          { value: "Orange", label: "Orange" },
        ],
        mode: "default",
        defaultValue: "Apple",
      };

    case "radio":
      return {
        options: ["Apple", "Orange"],
        defaultValue: "Orange",
      };
    case "number":
      return {
        min: 0,
        max: null,
      };
    default:
      return {
        defaultValue: null,
      };
  }
};
export const initNewField = (element, index) => {
  return {
    title: `Custom area ${index}`,
    name: `area-${index}`,
    type: element.type,
    extraFee: 0,
    configure: {
      placeholder: "",
      required: false, //true
      errorMessage: null,
      description: null,
      settings: getFieldSettings(element),
      visibility: {
        enabled: false,
        type: "show",
        match: "all",
        rules: [],
      },
    },
  };
};

// cache
const numPT = /\d+/g;
function getIndex(arr) {
  if (!arr || arr.length === 0) return 0;
  const names = arr.map((i) => i.name).filter(Boolean);

  const index = names.reduce((acc, cur) => {
    const nums = cur.match(numPT);
    const count = parseInt(nums[nums.length - 1] || 0);

    if (count > acc) return count;
    return acc;
  }, 0)

  return index;
}

class Customize extends Component {
  state = {
    fields: [],
    selected: -1,
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({
        fields: value,
        selected: 0,
      });
    }
  }
  onChange = () => {
    const { fields } = this.state;
    const { onChange } = this.props;
    onChange(fields);
  };
  onDrop = (element) => {
    let { fields } = this.state;
    const index = fields.length + 1;

    const newField = initNewField(element, index);
    const newFields = genFieldsByName(fields, newField);
    this.setState(
      {
        selected: index - 1,
        // fields: [...fields, newField],
        fields: fields?.length > 0 ? newFields : [newField],
      },
      () => this.onChange()
    );
  };

  render() {
    const { fields, selected } = this.state;
    const selectedField = fields[selected];

    return (
      <DndProvider backend={Backend}>
        <Container>
          <Row type="flex" gutter={16}>
            <Col style={{ maxWidth: 250 }} span={5}>
              <Card
                bodyStyle={{ paddingLeft: 15, paddingRight: 15 }}
                bordered={false}
                title="Elements"
              >
                <Row type="flex" className={"elements"} gutter={15}>
                  {ELEMENTS.map((element, index) => (
                    <Col
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={12}
                      key={index}
                    >
                      <Element
                        onDrop={() => this.onDrop(element)}
                        data={element}
                        key={index}
                      />
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
            <Col
              span={12}
              style={{
                borderLeft: "1px solid #e2e8f0",
                borderRight: "1px solid #e2e8f0",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Preview
                onDragged={(updater) => {
                  this.setState({ fields: updater }, () => this.onChange());
                }}
                onChange={(field, index) => {
                  this.setState(
                    {
                      fields: fields.map((f, idx) =>
                        idx === index ? field : f
                      ),
                    },
                    () => this.onChange()
                  );
                }}
                selected={selected}
                onDeleted={(field, index) => {
                  const fields__ = fields.filter((_, idx) => idx !== index);
                  let newSelectedIndex = index - 1;
                  if (index === 0 && fields__.length > 0) {
                    newSelectedIndex = index + 1;
                  }
                  this.setState(
                    {
                      fields: fields__,
                      selected: newSelectedIndex,
                    },
                    () => this.onChange()
                  );
                }}
                onSelected={(field, index) => {
                  this.setState({
                    selected: index,
                  });
                }}
                fields={fields}
              />
            </Col>
            {selectedField && (
              <Col span={7} style={{ paddingLeft: 0 }}>
                <Card title="Settings" bordered={false}>
                  <FieldSettings
                    index={selected}
                    fields={fields}
                    onChange={(d) => {
                      this.setState(
                        {
                          fields: fields.map((field, idx) =>
                            idx === selected ? d : field
                          ),
                        },
                        () => this.onChange()
                      );
                    }}
                    data={selectedField}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </DndProvider>
    );
  }
}

export function genFieldsByName(fields, newField, suffix = "") {
  if (!fields || fields.length === 0) return [];

  const field = fields.find((f) => f?.name === newField?.name);
  if (field) {
    return genFieldsByName(fields, {...newField, name: newField?.name + "1"}, suffix + "1")
  }

  return [...fields, newField]
}

Customize.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default Customize;
