import React, { Component } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { productBaseFragment } from "../../fragments";
import { Query } from "@apollo/react-components";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Carousel,
  Col,
  notification,
  Row,
  Skeleton,
  Tag,
  Modal,
} from "antd";
import { handleError } from "../../helper";
import { Collapse } from "antd";
import styled from "styled-components";
import { Update } from '../product/templates/Update'

const { Panel } = Collapse;

export const LOAD_TEMPLATES = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      total
      nodes {
        id
        name
        slug
        grouping
        defaultMockupIds
        data {
          personalized
          productBases {
            ...ProductBasse
          }
          ProductBaseIds
          fields {
            name
            title
            type
            extraFee
            configure
            sorting
          }
          products {
            title
            description
            productbaseId
            shortDescription
            tagIds
            tags {
              id
              name
            }
            variants {
              salePrice
              regularPrice
              productBaseVariantID
              disabled
            }
            collectionIds
            collections {
              id
              name
            }
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

const customPanelStyle = {
  background: "none",
  borderRadius: 0,
  marginBottom: 0,
  borderBottom: "1px solid #e8e8e8",
  overflow: "hidden",
  cursor: "default",
};

const Container = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    cursor: default;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }
`;

class Templates extends Component {
  state = {
    active: [],
    visible: false,
    loadingRemove: false,
  };

  handleModalChange = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        visible: !prevState.visible,
      };
    });
  };

  handleDelete = (client, id) => {
    this.setState({
      loadingRemove: true,
    });
    client
      .mutate({
        mutation: gql`
          mutation deleteTemplate($id: ID!) {
            deleteTemplate(id: $id)
          }
        `,
        variables: { id },
      })
      .then((res) => {
        if (res) {
          try {
            const cache = client.readQuery({
              query: LOAD_TEMPLATES,
              variables: {
                filter: { grouping: true },
              },
            });
            client.writeQuery({
              query: LOAD_TEMPLATES,
              variables: {
                filter: { grouping: true },
              },
              data: {
                ...cache,
                templates: {
                  ...cache.templates,
                  total: cache.templates.total - 1,
                  nodes: cache.templates.nodes.filter((t) => t.id !== id),
                },
                // templates: cache.templates.filter((t) => t.id !== id),
              },
            });
            this.setState({
              loadingRemove: false,
            });
            this.handleModalChange();
          } catch (_) {
            // console.log(e);
          }
        }
      })
      .catch((e) => {
        notification.error({ message: e.toString() });
        this.setState({
          loadingRemove: false,
        });
        this.handleModalChange();
      });
  };
  render() {
    const { active, visible, loadingRemove } = this.state;
    return (
      <Query query={LOAD_TEMPLATES} variables={{ filter: { grouping: true } }}>
        {({ loading, error, data, client }) => {
          if (loading) return <Skeleton active />;
          if (error) return <div>Error: {handleError(error.toString())}</div>;

          return (
            <Container>
              <Card bodyStyle={{ padding: 0 }}>
                <Collapse
                  style={{ background: "none" }}
                  bordered={false}
                  activeKey={this.state.active}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  {data.templates.nodes.map((temp, index) => {
                    return (
                      <Panel
                        showArrow={false}
                        extra={
                          <div>
                            <Update value={temp} isAntd/>
                            <Button
                              type="link"
                              onClick={() => {
                                this.setState({
                                  active: active.includes(`${index}`)
                                    ? []
                                    : [`${index}`],
                                });
                              }}
                            >
                              {active.includes(`${index}`)
                                ? "Hide Details"
                                : "Show Details"}
                            </Button>

                            <Button
                              onClick={() => {
                                this.props.onSelect(temp);
                              }}
                              className={"text-green-600 ml-2 mr-2"}
                              type="link"
                            >
                              Use Template
                            </Button>

                            <Button
                              onClick={this.handleModalChange}
                              className={"text-red-500"}
                              type="link"
                            >
                              Remove
                            </Button>
                            <Modal
                              title="Remove Template"
                              visible={visible}
                              onOk={() => this.handleDelete(client, temp.id)}
                              onCancel={this.handleModalChange}
                              confirmLoading={loadingRemove}
                            >
                              <p>Are you sure to remove this template?</p>
                            </Modal>
                          </div>
                        }
                        header={
                          <div style={{ flexGrow: 1 }}>
                            {`#${index + 1} ${temp.name}`}
                            <br />
                            <span>{temp.slug}</span>
                          </div>
                        }
                        key={`${index}`}
                        style={customPanelStyle}
                      >
                        <div>
                          Grouping:{" "}
                          {temp.grouping
                            ? "Group product into 1 product"
                            : "Split products into multiple products"}
                        </div>
                        {temp.data.productBases.map((productBase, index) => {
                          const images = productBase.images;
                          return (
                            <Card key={index} className={"mb-2"}>
                              <Row gutter={16}>
                                <Col span={4}>
                                  <Carousel>
                                    {images.map((file, index) => (
                                      <div key={index}>
                                        <img
                                          src={
                                            file.thumbnailUrl
                                              ? file.thumbnailUrl
                                              : file.url
                                          }
                                          alt={""}
                                        />
                                      </div>
                                    ))}
                                  </Carousel>
                                  <div>{productBase.baseCostDescription}</div>
                                </Col>
                                <Col span={20}>
                                  <div className={"title"}>
                                    {productBase.title}
                                  </div>
                                  <div className={"attributes"}>
                                    {productBase.attributes.map(
                                      (attribute, index) => (
                                        <div key={`attribute-${index}`}>
                                          <div className={"attribute-title"}>
                                            {attribute.name}:
                                          </div>
                                          <div>
                                            {attribute.options.map(
                                              (option, key) => (
                                                <Tag key={`option-${key}`}>
                                                  {option}
                                                </Tag>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className={"variant-total"}>
                                    Variants:{" "}
                                    <span>{productBase.variants.length}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          );
                        })}
                      </Panel>
                    );
                  })}
                </Collapse>
              </Card>
            </Container>
          );
        }}
      </Query>
    );
  }
}

Templates.propTypes = {
  onSelect: PropTypes.func,
};

export default Templates;
