import React, { useState, useCallback, useEffect } from "react";
import {
  DropZone,
  Stack,
  Button,
  Spinner,
  Icon,
  InlineError,
  TextStyle,
} from "@shopify/polaris";
import { TickMinor, DeleteMajorMonotone } from "@shopify/polaris-icons";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import axios from "axios";
import styled from "styled-components";

import { DELETE_FILE_AMZ } from "../../graphql/mutations";

export const REQUEST_MUTATION = gql`
  mutation createUploadUrl($input: RequestFileUpload!, $uniqueFolder: Boolean) {
    createUploadUrl(input: $input, uniqueFolder: $uniqueFolder) {
      key
      url
    }
  }
`;

export const CREATE_FILE_MUTATION = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      id
      name
      key
      mimeType
      size
      url
      thumbnailUrl
    }
  }
`;

const Container = styled.div`
  .uploaded-wrap {
    display: block;
    margin-top: 2rem;
  }
  .image-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .image-title-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }
`;

export const CrawlerFilePolaris = (props) => {
  // Props
  const { onChange, error, onNotificationChange, toggleActive, label, isCrawler } = props;

  //State
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState({});
  const [fileList, setFileList] = useState([]);
  const [isChangeFile, setIsChangeFile] = useState(false);

  // Mutation
  const [deleteFile] = useMutation(DELETE_FILE_AMZ, {
    onError: () => {},
  });

  useEffect(() => {
    if (onChange && isChangeFile) {
      onChange(fileList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, isChangeFile]);

  const handleDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, createUploadUrl, client) => {
      setFiles(() => [...acceptedFiles]);

      let newFiles = acceptedFiles;
      for (let i = 0; i < newFiles.length; i++) {
        customRequest(newFiles[i], i, createUploadUrl, client);
      }
    },
    []
  );

  const customRequest = (file, index, createUploadUrl, client) => {
    setLoading((prev) => ({ ...prev, [index]: true }));
    try {
      const fileReader = new FileReader();
      fileReader.onloadend = async (e) => {
        const data = e.target.result;
        const res = await createUploadUrl({
          variables: {
            input: {
              name: file.name,
              mimeType: file.type,
              size: file.size,
            },
          },
        });
        const { key, url } = res.data.createUploadUrl;
        await axios.put(url, file, {
          headers: { "Content-Type": file.type },
          timeout: 1000 * 60 * 30,
        });
        const fileResponse = await client.mutate({
          mutation: CREATE_FILE_MUTATION,
          variables: {
            input: {
              name: file.name,
              key: key,
              size: file.size,
              mimeType: file.type,
            },
          },
        });
        let respon = [];
        if (fileResponse) {
          respon.push({ data, file: fileResponse.data.createFile });
          setLoading((prevState) => ({ ...prevState, [index]: false }));
        }
        setIsChangeFile(true);
        setFileList((prevState) => [...prevState, ...respon]);
      };
      fileReader.readAsText(file, "UTF-8");
    } catch (e) {}
  };

  /// Handle action
  const handleDelete = useCallback(
    (index) => {
      setIsChangeFile(true);
      setFiles((prevState) => prevState.filter((_item, idx) => index !== idx));
      setFileList((prevState) =>
        prevState.filter((_item, idx) => index !== idx)
      );

      const currentFile =
        fileList.length > 0
          ? fileList.find((_value, idx) => index === idx)
          : null;
      let id = currentFile?.file?.id;
      if (id) {
        deleteFile({
          variables: {
            id,
            deleteAmz: true,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileList]
  );

  const uploadedFiles =
    files.length > 0 ? (
      <Stack vertical>
        {files.map((file, index) => (
          <div className="image-wrap" key={`image-${index}`}>
            <div className="image-title-wrap">
              {loading[index] ? (
                <Spinner size="small" />
              ) : (
                <Icon source={TickMinor} />
              )}
              <span>{file.name}</span>
            </div>
            <Button
              plain
              icon={DeleteMajorMonotone}
              onClick={() => handleDelete(index, file)}
            />
          </div>
        ))}
      </Stack>
    ) : null;

  return (
    <Container>
      <Mutation mutation={REQUEST_MUTATION}>
        {(createUploadUrl, { client }) => (
          <div>
            <ComponentLabelPolaris label={label} required />
            <p className="mb-2">
              <TextStyle
                variation="subdued"
                children={isCrawler ? "" : "Your import file must have these fields: Order Number or Order ID, Tracking Code, New Tracking Code or Tracking Code 2"}
              />
            </p>
            <DropZone
              // accept={"text/plain,text/csv"}
              customValidator={(file) => {
                if (toggleActive) {
                  toggleActive();
                }
                if (onNotificationChange) {
                  onNotificationChange({
                    message: null,
                    isError: false,
                  });
                }
                let ext = "";
                if (!["csv", "plain"].includes(file.type) && file.name) {
                  ext = file.name.split(".").pop();
                }
                if (
                  file.type !== "text/csv" &&
                  file.type !== "text/plain" &&
                  ext !== "csv"
                ) {
                  if (onNotificationChange) {
                    onNotificationChange({
                      message: "Allow .txt or .csv",
                      isError: true,
                    });
                  }
                  return false;
                } else {
                  return true;
                }
              }}
              allowMultiple={false}
              onDrop={(droppedFiles, acceptedFiles, rejectedFiles) =>
                handleDrop(
                  droppedFiles,
                  acceptedFiles,
                  rejectedFiles,
                  createUploadUrl,
                  client
                )
              }
            >
              <DropZone.FileUpload />
            </DropZone>
          </div>
        )}
      </Mutation>
      <div className="uploaded-wrap">{uploadedFiles}</div>
      {error && (
        <div className="error_wrap">
          <InlineError message={error} />
        </div>
      )}
    </Container>
  );
};
