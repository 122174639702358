import React, { useState, useRef } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";
import FieldConfigureOptions from "./FieldConfigureOptions";
import classNames from "classnames";

const Container = styled.div`
  padding: 10px;
  &.is-dragging {
    opacity: 1;
  }
  .form-item-label {
    display: flex;
    margin-bottom: 5px;
    min-height: 30px;
    .label {
      flex-grow: 1;
      font-weight: 700;
    }
  }
  .btn-danger {
    color: rgba(211, 70, 92, 1);
  }
  &:hover {
    background: rgba(111, 124, 136, 0.05);
  }
  &.is-selected {
    background: rgba(111, 124, 136, 0.1);
  }
`;

const FieldPreview = ({
  field,
  index,
  selected,
  onSelected,
  onDeleted,
  onChange,
  moveField,
  form,
}) => {
  const [visible, setVisible] = useState(false);
  const { getFieldDecorator } = form;
  const { title, type } = field;
  const { placeholder, settings } = field.configure;
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "field",
    canDrop: () => false,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveField(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "field", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <Container
      style={{ opacity: isDragging ? 0.3 : 1 }}
      ref={ref}
      onClick={() => {
        if (onSelected) {
          onSelected();
        }
      }}
      className={classNames("form-item", {
        "is-selected": selected,
      })}
    >
      <div className={"form-item-label"}>
        <div className={"label"}>{title}</div>
        {selected && (
          <div className={"form-item-extra"}>
            {type !== "text" && type !== "textarea" && (
              <Button
                onClick={() => {
                  setVisible(true);
                }}
                size="small"
                type="link"
              >
                Edit
              </Button>
            )}
            <Button
              onClick={() => {
                if (onDeleted) {
                  onDeleted();
                }
              }}
              size="small"
              type="link"
              className={"btn-danger"}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
      <div className={"form-item-content"}>
        {type === "text" && (
          <Input
            defaultValue={settings.defaultValue}
            placeholder={placeholder}
          />
        )}
        {type === "textarea" && (
          <Input.TextArea
            defaultValue={settings.defaultValue}
            placeholder={placeholder}
          />
        )}
        {type === "checkbox" && (
          <Checkbox.Group
            options={settings.options}
            defaultValue={settings.defaultValue}
          />
        )}
        {type === "number" && (
          <InputNumber
            min={settings.min !== null ? settings.min : undefined}
            max={settings.max ? settings.max : undefined}
            defaultValue={settings.defaultValue}
            style={{ width: "100%" }}
          />
        )}
        {type === "dropdown" && (
          <Select
            defaultValue={settings.defaultValue}
            style={{ width: "100%" }}
            mode={settings.mode}
          >
            {settings.options.map((op, idx) => (
              <Select.Option key={idx} value={op.value}>
                {op.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {type === "radio" && (
          <Radio.Group
            defaultValue={settings.defaultValue}
            options={settings.options}
          />
        )}
      </div>

      {visible && (
        <Modal
          onOk={() => {
            form.validateFields((errors, values) => {
              if (!errors) {
                setVisible(false);
                onChange(values.field);
              }
            });
          }}
          onCancel={() => setVisible(false)}
          visible={true}
          title={`${title}`}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {getFieldDecorator("field", {
              initialValue: JSON.parse(JSON.stringify(field)),
            })(<FieldConfigureOptions />)}
          </Form>
        </Modal>
      )}
    </Container>
  );
};

export default Form.create({ name: "field-configure-form" })(FieldPreview);
