import { useQuery } from "@apollo/react-hooks";
import { Card, Layout, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { DepositsFiltersPolaris } from "./DepositsFiltersPolaris";
import { StatsPolaris } from "./StatsPolaris";
import { TableTransactionPolaris } from "./TableTransactionPolaris";

export const LOAD_SELLER_DEPOSIT_QUERY = gql`
  query transactons($filter: TransactionFilter) {
    transactions(filter: $filter) {
      total
      nodes {
        id
        amount
        journal {
          id
          refId
          comment
          status
          type
        }
        createdAt
        account {
          id
          firstName
          lastName
          teamUser {
            id
            role
          }
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "accountId", "status"];

export const DepositsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageDeposits")) || 20,
    offset: 0,
    accountId: null,
    status: null,
    search: null,
    kind: ["Deposit"],
    ...initFilter,
  });

  const { data, loading, error } = useQuery(LOAD_SELLER_DEPOSIT_QUERY, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { kind, ...rest } = filter;
    let newFilter = rest;
    let { offset, limit, ...speard } = newFilter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...speard,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Layout>
        <Layout.Section>
          <StatsPolaris />
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <DepositsFiltersPolaris
              filter={filter}
              onChange={({ search, status, accountId }) =>
                setFilter((prevState) => {
                  if (
                    !_.isEqual(prevState.search, search) ||
                    !_.isEqual(prevState.status, status) ||
                    !_.isEqual(prevState.accountId, accountId)
                  ) {
                    prevState.offset = 0;
                  }
                  return {
                    ...prevState,
                    search,
                    status,
                    accountId,
                  };
                })
              }
            />
          </Card>
          <Card>
            {error && <div>Error: {error.toString()}</div>}
            {loading ? (
              <SkeletonPagePolaris />
            ) : data &&
              data.transactions &&
              data.transactions.nodes &&
              data.transactions.nodes.length ? (
              <TableTransactionPolaris
                data={data}
                filter={filter}
                setFilter={(offset, limit) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    offset,
                    limit,
                  }));
                  setCookie("perPageDeposits", limit, 100);
                }}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
};
