import React, { Component } from "react";
import { AppContext } from "../context";
import history from "../history";
import { USER_ROLE, TEAM_ROLE } from "../variable";

class Activation extends Component {
  render() {
    const { match } = this.props;
    return (
      <AppContext.Consumer>
        {({ currentUser }) => {
          let orderId = "";
          let url = "";
          if (
            match &&
            match.params &&
            match.params.orderId &&
            match.params.orderId.length > 0
          ) {
            orderId = match.params.orderId;
          }

          if (match && match.url && match.url.length > 0) {
            url = match.url;
          }

          if (currentUser) {
            let userRoles = currentUser.roles ? currentUser.roles : [];
            let teamUser =
              currentUser.teamUser && currentUser.teamUser.role
                ? currentUser.teamUser.role
                : "";

            let routePath = "/seller";
            if (userRoles.includes(USER_ROLE.Admin)) {
              //Administrator
              routePath = "/admin";
            } else if (userRoles.includes(USER_ROLE.Seller)) {
              // Other seller roles
              if (teamUser === TEAM_ROLE.StoreManager) {
                routePath = "/store-manager";
              } else if (teamUser === TEAM_ROLE.MarketplaceManager) {
                routePath = "/marketplace-manager";
              }
            }
            let searchOrderPath =
              routePath + "/orders?limit=20&search=" + orderId;
            history.push(searchOrderPath);
          } else {
            history.push(`/login/?origin_url=` + url);
          }
        }}
      </AppContext.Consumer>
    );
  }
}

export default Activation;
