import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { REJECT_DESIGN } from "../../../graphql/mutations";
// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";
import { RejectDesignFormPolaris } from "./RejectDesignFormPolaris";

export const RejectDesignPolaris = (props) => {
  // Prop
  const {
    open,
    toggleShowModal,
    toggleActive,
    onNotificationChange,
    refetch,
    task,
    toggleModalParent,
  } = props;
  // Block body scroll
  // useLockBodyScroll(open);

  // Ref
  const onCloseRef = useRef(null);

  // Context
  const { setFilter } = useToastContext();

  // Mutation
  const [rejectDesign, { loading }] = useMutation(REJECT_DESIGN, {
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: `Reject design success.`,
          isError: false,
        });
      }

      if (toggleModalParent) {
        toggleModalParent();
      }

      // onClose parent modal
      onCloseRef.current && clearTimeout(onCloseRef.current);
      onCloseRef.current = setTimeout(() => {
        setFilter && setFilter((prev) => ({ ...prev }));
        refetch && refetch();
      }, 1000);
    },
  });

  // Handle action
  const handleSubmit = useCallback((input) => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: true,
      });
    }
    if (toggleActive) {
      toggleActive();
    }

    if (input) {
      rejectDesign({
        variables: {
          input,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={`Reject design for order #${task.orderId}`}
      open={open}
      onClose={toggleShowModal}
      sectioned
    >
      <RejectDesignFormPolaris
        onSubmit={handleSubmit}
        toggleShowModal={toggleShowModal}
        task={task}
        loading={loading}
      />
    </Modal>
  );
};
