import React, { Component } from "react";
import PropTypes from "prop-types";
import AssignProduct from "../seller/AssignProduct";
import { Button, Popover } from "antd";

class AssignProductButton extends Component {
  state = {
    visible: false,
  };
  render() {
    const { visible } = this.state;
    return (
      <Popover
        title={"Assign Product"}
        visible={visible}
        onVisibleChange={(bool) => this.setState({ visible: bool })}
        placement="bottom"
        trigger={["click"]}
        content={
          <AssignProduct
            onCompleted={(products) => {
              this.setState({ visible: false });
              if (this.props.onCompleted) {
                this.props.onCompleted(products);
              }
            }}
            value={[this.props.value]}
          />
        }
      >
        <Button size="small" type={"primary"}>
          Assign
        </Button>
      </Popover>
    );
  }
}

AssignProductButton.propTypes = {
  value: PropTypes.object,
  onCompleted: PropTypes.func,
};

export default AssignProductButton;
