import React, { useState, useCallback, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { ORDERS } from "../OrdersPolaris";
import { handleError } from "../../../helper";
import { PAUSE_ORDER } from "../../../graphql/mutations";

export const PauseOrResumePolaris = (props) => {
  const { pause, open, toggleShowModal, filter, orderId, refetch } = props;
  // Lock body scroll
  // useLockBodyScroll(open)
  
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pauseOrder, { data, loading, error, client }] = useMutation(
    PAUSE_ORDER,
    {
      onError: () => {},
    }
  );

  const handleSubmit = useCallback(() => {
    const paused = !!pause;

    pauseOrder({
      variables: {
        id: orderId,
        pause: paused,
      },
    }).then(() => {
      try {
        const cache = client.readQuery({
          query: ORDERS,
          variables: {
            filter,
          },
        });

        client.writeQuery({
          query: ORDERS,
          variables: {
            filter,
          },
          data: {
            ...cache,
            orders: {
              ...cache.orders,
              nodes: cache.orders.nodes.map(
                (node) => (
                  node.id === orderId
                    ? ((node.paused = paused), (node.pausedd = paused))
                    : null,
                  node
                )
              ),
            },
          },
        });
      } catch (error) {}
    });
    toggleActive();
    const id = setTimeout(() => {
      toggleShowModal(false);
      if (refetch) {
        refetch();
      }
    }, 2100);
    setTimeoutId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, pause]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.pauseOrder)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.pauseOrder && "Update order success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleShowModal}
        title={pause ? "Pause order" : "Resume order"}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p id="pause-resume_modal">
          Are you sure {pause ? "pause" : "resume"} this order?
        </p>
      </Modal>
    </>
  );
};
