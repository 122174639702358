import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";

import { REVIEW_IDEA_TASK } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";
import { useCreateIdea } from "../../context/createIdea";
import { ACTIONS } from "../Update";
import { TASK_STATUS } from "../../utils";

export const STATUS = {
    Accept: "Accept",
    Reject: "Reject",
};

export function ReviewTask({ type, item, onCloseParent, ...props }) {
    const ideaTaskId = item?.id;
    const isReject = [ACTIONS.reject, ACTIONS.rejectAll].includes(type);
    const actionsAll = [ACTIONS.rejectAll, ACTIONS.approveAll].includes(type);
    const status = isReject ? STATUS.Reject : STATUS.Accept;

    // State
    const [rejectReason, setRejectReason] = useState("");
    const onCloseRef = useRef(null);

    // Context
    const { products } = useCreateIdea();
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [reviewTask, { loading }] = useMutation(REVIEW_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: `${
                    isReject ? "Rejected" : "Approved"
                } to this tasks successfully.`,
                err: false,
            });
            props.onClose && props.onClose();
            onCloseParent && onCloseParent();

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                refetch && refetch();
                setFilter && setFilter((prev) => ({ ...prev }));
            }, 1000);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Actions
    const handleSubmit = useCallback(() => {
        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });

        const cloneProducts = [...products]
            .filter((i) => {
                if (actionsAll) return true;
                return i.checked;
            })
            .filter((i) => i.status !== TASK_STATUS.Approved);

        const baseGroups = genInput(
            cloneProducts,
            isReject ? rejectReason : undefined
        );

        if (baseGroups?.length === 0) {
            setNotify({
                msg: "Select least at one item to handle.",
                err: true,
            });
            return;
        }

        if (ideaTaskId != null) {
            const input = {
                ideaTaskId,
                baseGroups,
                status,
            };

            reviewTask({ variables: { input } });
        }
    }, [
        status,
        rejectReason,
        isReject,
        products,
        actionsAll,
        ideaTaskId,
        toggleToast,
        setNotify,
        reviewTask,
    ]);

    const handleReasonChange = useCallback((value) => {
        setRejectReason(value);
    }, []);

    // Did mount
    useEffect(() => setRejectReason(""), [props.open]);

    return (
        <Modal
            title={`${isReject ? "Reject" : "Approve"} selected tasks`}
            sectioned
            large
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            secondaryActions={[{ content: "Close", onAction: props.onClose }]}
            {...props}
        >
            {isReject ? (
                <TextField
                    value={rejectReason}
                    multiline={4}
                    label="Reason"
                    placeholder="Enter reason reject"
                    onChange={handleReasonChange}
                />
            ) : (
                <p>Are you sure to approve to this tasks?</p>
            )}
        </Modal>
    );
}

function genInput(products, rejectReason) {
    if (!products || products.length === 0) return [];

    const result = products.map((p) => {
        const { id, bases } = p || {};

        const taskBaseGroupId = id;
        const baseConfigs = (bases || [])
            .map((b) => {
                const { baseConfigId, taskBaseSubmissions } = b || {};
                const [taskBaseSubmission] = taskBaseSubmissions || [];
                const submissionId = taskBaseSubmission?.id;

                if (taskBaseSubmission?.status !== "Pending") return null;

                return {
                    taskBaseConfigId: baseConfigId,
                    submissionId,
                    rejectReason,
                };
            })
            .filter(Boolean)
            .filter((i) => i.submissionId != null);

        return {
            taskBaseGroupId,
            baseConfigs,
        };
    });

    return result;
}
