import { ImportTrackingPolaris } from "../components/admin/manager-files/ImportTrackingPolaris";
import MainLayout from "../layout/MainLayout";
import { ClaimsPagePolaris } from "../pages/admin/ClaimsPagePolaris";
import { ExportFilesPagePolaris } from "../pages/admin/ExportFilesPagePolaris";
import { ImportFilesPagePolaris } from "../pages/admin/ImportFilesPagePolaris";
import { AdminOrdersPagePolaris } from "../pages/admin/OrdersPolaris";
import { SupplierOrdersPagePolaris } from "../pages/admin/SupplierOrdersPagePolaris";

const routes = [
    {
        path: "/supporter/orders",
        exact: true,
        title: "Orders",
        component: AdminOrdersPagePolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
    {
        path: "/supporter/orders/supplier",
        exact: true,
        title: "Supplier Orders",
        component: SupplierOrdersPagePolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
    {
        path: "/supporter/claims",
        exact: true,
        title: "Claims",
        component: ClaimsPagePolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
    {
        path: "/supporter/manager-files/import",
        exact: true,
        title: "Import Files",
        component: ImportFilesPagePolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
    {
        path: "/supporter/manager-files/export",
        exact: true,
        title: "Export Files",
        component: ExportFilesPagePolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
    {
        path: "/supporter/manager-files/import-tracking",
        exact: true,
        title: "Export Templates",
        component: ImportTrackingPolaris,
        roles: ["Administrator"],
        layout: MainLayout,
    },
];

export default routes;
