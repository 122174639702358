import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Card, Page } from "@shopify/polaris";
import { get, isEqual } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../../../context";
import { GET_TASKS_V2, TASK_AGGS_V2 } from "../../../../graphql/queries";
import {
  canViewWholeTeamReport,
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  formatAggregations,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../../helper";
import history from "../../../../history";
import useToggle from "../../../../hooks/useToggle";
import {
  COMMON_FILTER,
  DESIGNER_SPECIAL,
  ORDER_STATUS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../../../variable";
import { EmptyStatePolaris } from "../../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../../shared/SkeletonPagePolaris";
import { ToastContextProvider } from "../../../shared/ToastContext";
import {
  FilterControlPolaris,
  IDEA_TASK_INCLUDES,
} from "../../FilterControlPolaris";
import { TabsDesignTasksPolaris } from "../../TabsDesignTasksPolaris";
import { TASK_STATUS } from "../utils";
import { Create } from "./Create";
import { Table } from "./Table";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "assigneeIds",
  "status",
  "order",
  "orderBy",
  "fieldByTime",
  "hasAssign",
  "storeIds",
  "createdById",
];

export function Ideas({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.hasAssign) {
        initialFilter.status = null;
        if (initialFilter.hasAssign === "Assigned") {
          initialFilter.hasAssign = true;
        }
        if (initialFilter.hasAssign === "Un-assigned") {
          initialFilter.hasAssign = false;
        }
      }

      const status = initialFilter.status;
      if (status) {
        if (!IDEA_TASK_INCLUDES.includes(status)) {
          initialFilter.status = null;
        }
      }

      if ("Yes" === initialFilter.usingExpressShipping) {
        initialFilter.usingExpressShipping = true;
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // Context
  const { currentUser, store } = useContext(AppContext);
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);
  const currentUserId = get(currentUser, "id", null);
  const viewWholeTeamReport = canViewWholeTeamReport(currentUser);

  let isLeader = false;
  let hasStoreFilter = false;
  let isSellerAdmin = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    isLeader = [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.IdeaLeader,
    ].includes(teamRole);

    isSellerAdmin = [TEAM_ROLE.Admin].includes(teamRole);

    hasStoreFilter = [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader].includes(
      teamRole,
    );
  }
  const {
    isDesigner,
    isStoreManager,
    isDesignLeader,
    isIdea: isRoleIdea,
    isIdeaLeader: isRoleIdeaLeader,
  } = checkRole(currentUser);

  const ideaRoles = isRoleIdea || isRoleIdeaLeader;
  const isWriter = isSellerAdmin || ideaRoles || isStoreManager;
  const isTeamLeader = isSellerAdmin || isRoleIdeaLeader;

  const isDesignerSpecial = [
    DESIGNER_SPECIAL.accLy,
    DESIGNER_SPECIAL.accDung,
  ].includes(currentUserId);
  // Default status
  let defaultStatus = null;
  if (isDesignerSpecial) {
    defaultStatus = ORDER_STATUS.PendingDesign;
  } else if (isDesigner) {
    defaultStatus = ORDER_STATUS.Pending;
  }

  // State
  const [open, toggleOpen] = useToggle(false);
  const [dataCountV2, setDataCountV2] = useState(null);
  const [totalKpi, setTotalKpi] = useState(null);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageIdeaTasks")) || 20,
    offset: 0,
    search: null,
    assigneeIds: null,
    status: defaultStatus,
    storeIds: null,
    order: null,
    orderBy: null,
    fieldByTime: null,
    hasAssign: isDesignerSpecial ? false : null,
    isIdeaTask: true,
    collections: null,
    tags: null,
    niches: null,
    createdById: null,
    viewWholeTeamReport: viewWholeTeamReport,
    ...initFilter,
  });

  // Queries
  const { data, loading, error, refetch } = useQuery(GET_TASKS_V2, {
    variables: {
      filter: {
        ...filter,
        storeIds: store
          ? "all" !== store.id
            ? [store.id]
            : null
          : filter.storeIds,
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const agg = res?.tasksV2?.aggregations;
      if (agg != null) {
        const newAgg = formatAggregations(agg, true);
        setDataCountV2((prev) => ({ ...prev, ...newAgg }));
      }
    },
  });

  const [getTaskAgg, { data: dataAgg }] = useLazyQuery(TASK_AGGS_V2, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const agg = res?.taskAggsV2;
      if (agg != null) {
        const newAgg = formatAggregations(agg, true);
        setDataCountV2((prev) => ({ ...prev, ...newAgg }));
      }
    },
  });
  const { data: dataTotal } = useQuery(TASK_AGGS_V2, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        ...filter,
        storeIds: store
          ? "all" !== store.id
            ? [store.id]
            : null
          : filter.storeIds,
      },
    },
    skip: filter.status !== TASK_STATUS.Done,
  });

  useEffect(() => {
    const agg = dataAgg?.taskAggsV2;
    if (agg != null) {
      const value = formatAggregations(agg, false);
      setDataCountV2((prev) => ({ ...prev, ...value }));
    }
  }, [dataAgg]);

  useEffect(() => {
    const agg = dataTotal?.taskAggsV2;
    if (agg != null) {
      const value = formatAggregations(agg, true);
      setTotalKpi((prev) => ({ ...prev, ...value }));
    }
  }, [dataTotal]);

  useEffect(() => {
    if (!isRoleIdea) {
      setFilter((prev) => {
        let status = prev.status;
        if (status === "Draft") {
          status = null;
        }

        return {
          ...prev,
          status,
        };
      });
    }
  }, [isRoleIdea]);

  useEffect(() => {
    getTaskAgg({
      variables: {
        filter: {
          ...filter,
          status: null,
          hasAssign: null,
        },
      },
    });
  }, [filter, getTaskAgg]);

  useEffect(() => {
    let {
      offset,
      limit,
      hasAssign,
      fieldByTime,
      collections,
      tags,
      niches,
      assigneeIds,
      // status,
      ...rest
    } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    if (hasAssign != null) {
      if (hasAssign) {
        hasAssign = "Assigned";
      } else {
        hasAssign = "Un-assigned";
      }
    }

    // if (
    //     assigneeIds === currentUserId &&
    //     isDesignLeader &&
    //     status === "Pending"
    // ) {
    //     status = assignToMe;
    // }

    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
      hasAssign,
      // status,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = React.useCallback((value) => {
    const {
      search,
      designer,
      status,
      order,
      orderBy,
      filterTime,
      stateAssign,
      storeIds,
      collections,
      tags,
      niches,
      createdById,
    } = value;
    let hasAssign = stateAssign;
    let assigneeIds = designer;
    let fieldByTime =
      filterTime.from && filterTime.to && filterTime.field ? filterTime : null;

    setFilter((prevState) => {
      if (
        !isEqual(prevState.search, search) ||
        !isEqual(prevState.assigneeIds, assigneeIds) ||
        !isEqual(prevState.status, status) ||
        !isEqual(prevState.hasAssign, hasAssign) ||
        !isEqual(prevState.order, order) ||
        !isEqual(prevState.orderBy, orderBy) ||
        !isEqual(prevState.fieldByTime, fieldByTime) ||
        !isEqual(prevState.storeIds, storeIds) ||
        !isEqual(prevState.collections, collections) ||
        !isEqual(prevState.tags, tags) ||
        !isEqual(prevState.niches, niches) ||
        !isEqual(prevState.createdById, createdById)
      ) {
        return {
          ...prevState,
          offset: 0,
          search,
          assigneeIds,
          status,
          hasAssign,
          order,
          orderBy,
          fieldByTime,
          storeIds,
          collections,
          tags,
          niches,
          createdById,
        };
      }
      return prevState;
    });
  }, []);

  const handleTabChange = React.useCallback(({ status }) => {
    let custom = {
      status,
    };
    if (status) {
      if (status === "Assigned") {
        custom["status"] = null;
        custom["hasAssign"] = true;
      } else if (status === "Un-assigned") {
        custom["status"] = null;
        custom["hasAssign"] = false;
      } else {
        custom["hasAssign"] = null;
      }

      // if (isDesignLeader) {
      //     if (status === assignToMe) {
      //         custom["assigneeIds"] = currentUserId;
      //         custom["status"] = Pending;
      //     } else {
      //         custom["assigneeIds"] = null;
      //     }
      // }
    } else {
      custom["hasAssign"] = null;
    }
    setFilter((prev) => {
      const isUpdateFilter =
        prev.status !== status || prev.hasAssign !== custom["hasAssign"];

      if (isUpdateFilter) {
        return {
          ...prev,
          ...custom,
          offset: 0,
        };
      }
      return prev;
    });
  }, []);
  const handlePaginationChange = React.useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
    setCookie("perPageIdeaTasks", limit, 100);
  }, []);

  const showCreate =
    isSellerAdmin || isStoreManager || isRoleIdea || isRoleIdeaLeader;

  const pageProps = showCreate
    ? {
        primaryAction: {
          content: "Create new idea",
          onAction: toggleOpen,
        },
      }
    : {};

  return (
    <Container>
      <ToastContextProvider refetch={refetch} setFilter={setFilter}>
        <Page fullWidth {...pageProps} title="All Idea Tasks">
          <FilterControlPolaris
            filters={filter}
            isDesigner={isDesigner}
            isLeader={isLeader}
            hasStoreFilter={hasStoreFilter}
            onChangeFilter={handleFilterChange}
            isIdeaTask
            ideaRoles={ideaRoles}
          />
          <Card>
            <TabsDesignTasksPolaris
              dataCountV2={dataCountV2}
              value={filter.status}
              hasAssign={filter.hasAssign}
              isDesigner={isDesigner}
              isLeader={isLeader}
              onChange={handleTabChange}
              isIdeaTask
              ideaRoles={ideaRoles}
              // isDesignLeader={isDesignLeader}
              // assigneeIds={filter.assigneeIds}
              // userId={currentUserId}
            />
            <Card.Section>
              {loading ? (
                <SkeletonPagePolaris />
              ) : error ? (
                <div>Error: {handleError(error.toString())}</div>
              ) : data?.tasksV2?.nodes?.length > 0 ? (
                <Table
                  data={data}
                  filter={filter}
                  setFilter={handlePaginationChange}
                  isDesignLeader={isDesignLeader}
                  isRoleIdeaLeader={isRoleIdeaLeader}
                  isWriter={isWriter}
                  isTeamLeader={isTeamLeader}
                  dataTotal={totalKpi}
                />
              ) : (
                <EmptyStatePolaris />
              )}
            </Card.Section>
          </Card>
        </Page>
        <Create open={open} onClose={toggleOpen} />
      </ToastContextProvider>
    </Container>
  );
}
