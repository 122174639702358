import React, { Component } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Select, Popover, Form } from "antd";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
class SupplierDepositsFilter extends Component {
  state = {
    status: null,
  };
  onChange = () => {
    const { status } = this.state;
    if (this.props.onChange) {
      this.props.onChange({
        status,
      });
    }
  };
  render() {
    const { status } = this.props.value;
    return (
      <Popover
        placement="bottomLeft"
        content={
          <Form {...layout} style={{ minWidth: "200px" }}>
            <Form.Item label="Show by status">
              <Select
                style={{ width: "100%" }}
                onChange={(v) => this.setState({ status: v }, this.onChange)}
                value={status}
              >
                <Select.Option value={null}>All statuses</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Cancelled">Cancelled</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        }
      >
        <div className="cursor-pointer">
          Filter <CaretDownOutlined />
        </div>
      </Popover>
    );
  }
}

export default SupplierDepositsFilter;
