import { gql } from "apollo-boost";

const CrawlItemVariantAttributesFragment = gql`
    fragment CrawlItemVariantAttributesFragment on CrawlItemVariantAttributes {
        name
        value
    }
`;

const ProductCrawlItemVariantFragment = gql`
    fragment ProductCrawlItemVariantFragment on ProductCrawlItemVariant {
        attribute {
            ...CrawlItemVariantAttributesFragment
        }
        image
    }
    ${CrawlItemVariantAttributesFragment}
`;

export const ComplexProductCrawlItemFragment = gql`
    fragment ComplexProductCrawlItemFragment on ComplexProductCrawlItem {
        id
        createdAt
        author {
            id
            firstName
            lastName
        }
        teamId
        url
        title
        status
        itemId
        source
        productSku
        variantAttributes {
            ...ProductCrawlItemVariantFragment
        }
    }
    ${ProductCrawlItemVariantFragment}
`;
