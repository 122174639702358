import { useMutation } from "@apollo/react-hooks";
import { Checkbox, Modal, Stack, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import {
  MARK_USED_CALC_ORDER_COST,
  UPDATE_ORDER_COST
} from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function UpdateOrderCost({ value, onClose, open, refetch }) {
  const { id, storeId } = value || {};
  const [state, setState] = useReducer((p, state) => ({ ...p, ...state }), {
    baseCost: null,
    shippingCost: null,
    used: false,
  });

  useEffect(() => {
    if (!value) return;
    const { baseCost, shippingCost, usedCalcCost } = value;
    setState({
      baseCost: baseCost + "",
      shippingCost: shippingCost + "",
      used: !!usedCalcCost,
    });
  }, [value?.baseCost, value?.shippingCost, value?.usedCalcCost]);

  // Context
  const { setNotify, toggleToast } = useToastContext();

  // Mutation
  const timeoutRef = useRef(null);
  const [update, { loading }] = useMutation(UPDATE_ORDER_COST, {
    onCompleted: () => {
      setNotify({ msg: "Update order's cost success.", err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const [markUsed] = useMutation(MARK_USED_CALC_ORDER_COST, {
    onError: () => {},
    onCompleted: () => {
      onClose && onClose(false);
    }
  });

  const handleSubmit = useCallback(() => {
    if (!id || !storeId) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });

    const { used, ...rest } = state;
    update({
      variables: {
        input: {
          orderId: id,
          storeId,
          ...rest,
        },
      },
    });

    const ids = [id];
    markUsed({
      variables: {
        ids,
        used,
      },
    });
  }, [toggleToast, setNotify, id, storeId, state, update, markUsed]);

  const handleStateChange = useCallback((value, id) => {
    setState({ [id]: value });
  }, []);

  return (
    <Modal
      title="Update Cost"
      sectioned
      open={open}
      onClose={onClose}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <Stack vertical>
        <Checkbox
          id="used"
          label="Apply this cost to report"
          checked={state.used}
          onChange={handleStateChange}
        />
        <TextField
          label="Base cost"
          min={0}
          type="number"
          placeholder="Enter base cost"
          onChange={handleStateChange}
          id="baseCost"
          value={state.baseCost}
        />
        <TextField
          label="Shipping cost"
          min={0}
          type="number"
          placeholder="Enter shipping cost"
          onChange={handleStateChange}
          id="shippingCost"
          value={state.shippingCost}
        />
      </Stack>
    </Modal>
  );
}
