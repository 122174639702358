import React, { Fragment } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import Delete from "./actions/Delete";

export const Actions = ({ ids, onCompleted }) => {
    // State
    const [open, toggleOpen] = useToggle(false);

    return (
        <Fragment>
            <Button
                children="Delete Selected Product"
                onClick={toggleOpen}
                destructive
            />
            <Delete
                open={open}
                onClose={toggleOpen}
                ids={ids}
                onCompleted={onCompleted}
            />
        </Fragment>
    );
};
