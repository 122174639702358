import React from "react";
import { Page, Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { GroupFormPolaris } from "./GroupFormPolaris";
import { getParamByRole, handleError } from "../../../helper";
import { useAppContext } from "../../../context";

const MUTATION_CREATE_GROUP = gql`
    mutation createGroup($input: NewGroup!) {
        createGroup(input: $input) {
            id
            name
        }
    }
`;
const Container = styled.div``;

export const GroupAddPolaris = () => {
    // Context
    const { currentUser } = useAppContext();
    const currentParam = getParamByRole(currentUser);

    // State
    const [toastActive, setToastActive] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);
    const [notification, setNotification] = React.useState({
        message: null,
        error: false,
    });
    // Mutation
    const [createGroup, { loading }] = useMutation(MUTATION_CREATE_GROUP, {
        onCompleted: () => {
            setNotification({
                message: "Create group successfully,",
                error: false,
            });
            const id = setTimeout(() => {
                onRedirect();
            }, 2100);
            setTimeoutId(id);
        },
        onError: (error) => {
            setNotification({
                message: handleError(error.toString()),
                error: true,
            });
        },
    });

    // Handle actions
    React.useEffect(() => {
        return () => clearTimeout(timeoutId);
    }, [timeoutId]);

    const toggleToastActive = React.useCallback(
        () => setToastActive((prev) => !prev),
        []
    );
    const handleSubmit = React.useCallback(
        (input) => {
            if (input) {
                toggleToastActive();
                createGroup({
                    variables: {
                        input,
                    },
                });
            }
        },
        [createGroup, toggleToastActive]
    );
    const onRedirect = React.useCallback(() => {
        history.push(`/${currentParam}/stores/groups`);
    }, []);

    // Markup
    const toastMarkup = toastActive && notification.message && (
        <Toast
            content={notification.message}
            error={notification.error}
            onDismiss={toggleToastActive}
            duration={2000}
        />
    );

    return (
        <Container>
            {toastMarkup}
            <Page
                title="Add Group"
                breadcrumbs={[
                    {
                        content: "Groups",
                        url: `/${currentParam}/stores/groups`,
                    },
                ]}
            >
                <Card sectioned>
                    <GroupFormPolaris
                        btnSubmitLabel={"Submit"}
                        onSubmit={handleSubmit}
                        onRedirect={onRedirect}
                        loading={loading}
                    />
                </Card>
            </Page>
        </Container>
    );
};
