import React from "react";
import { Mutation, Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, notification, Card, Form } from "antd";
import styled from "styled-components";
import { handleError } from "../helper";
import { AppContext } from "../context";
import history from "../history";
import MediaSelectorButton from "../components/supplier/MediaSelectorButton";
import PageTitle from "./shared/PageTitle";
import { UPLOAD_FOLDER } from "../variable";

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: EditProfile!) {
    updateProfile(input: $input) {
      id
      firstName
      lastName
      email
      phone
      address
      avatar {
        id
        name
        url
        thumbnailUrl
      }
      teamUser {
        team {
          id
        }
      }
      roles
    }
  }
`;

export const currentUser = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      phone
      address
      avatar {
        id
        name
        url
        thumbnailUrl
      }
      teamUser {
        role
        team {
          id
        }
      }
      roles
    }
  }
`;

const Container = styled.div`
  max-width: 700px;
  .ant-form-item {
    margin-bottom: 15px;
  }
  .password {
    width: 50%;
  }
  .border-t {
    margin-top: 6px;
  }
  .submit-btn {
    height: 40px;
  }
`;

class EditProfile extends React.Component {
  state = {
    loading: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
  };

  render() {
    const { onCompleted } = this.props;
    const { fileList } = this.state;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Container className="custom-form">
        <PageTitle title="Edit Profile" subTitle="Profile" />
        <Query
          query={currentUser}
          onCompleted={(data) => {
            const image = data.me.avatar;
            this.setState({
              fileList: [
                {
                  uid: image ? image.id : null,
                  name: image ? image.name : null,
                  url: image ? image.url : null,
                },
              ],
            });
          }}
        >
          {({ error, loading, data }) => {
            if (loading) return <div>{loading}</div>;
            if (error) return <div>{error.toString()}</div>;
            const dataUserId = data.me;
            return (
              <AppContext.Consumer>
                {({ setCurrentUser }) => (
                  <Mutation
                    onError={(e) => {
                      notification.error({
                        message: handleError(e.toString()),
                      });
                      this.setState({ loading: false });
                    }}
                    onCompleted={(res) => {
                      this.setState({ loading: false }, () => {
                        notification.success({
                          message: "Your profile has been saved!",
                        });
                        setCurrentUser(res.updateProfile);
                        if (onCompleted) {
                          onCompleted(res.updateProfile);
                        }
                      });
                      history.push("/");
                    }}
                    mutation={UPDATE_PROFILE}
                  >
                    {(updateProfile) => {
                      return (
                        <Card>
                          <Form
                            {...layout}
                            onFinish={(e) => {
                              const avatar =
                                e.avatar && e.avatar.length
                                  ? e.avatar[0].id
                                  : null;
                              if (e) {
                                this.setState({ loading: true }, () => {
                                  updateProfile({
                                    variables: {
                                      input: {
                                        ...e,
                                        avatar,
                                      },
                                    },
                                  });
                                });
                              }
                            }}
                            initialValues={{
                              avatar: data.me.avatar ? [data.me.avatar] : [],
                              firstName: dataUserId
                                ? dataUserId.firstName
                                : null,
                              lastName: dataUserId ? dataUserId.lastName : null,
                              email: dataUserId ? dataUserId.email : null,
                              phone: dataUserId ? dataUserId.phone : null,
                              address: dataUserId ? dataUserId.address : null,
                            }}
                          >
                            <Form.Item
                              label="First Name"
                              name="firstName"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter first name!",
                                },
                              ]}
                            >
                              <Input placeholder="First name" />
                            </Form.Item>
                            <Form.Item
                              label="Last Name"
                              name="lastName"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter last name!",
                                },
                              ]}
                            >
                              <Input placeholder="Last name" />
                            </Form.Item>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                {
                                  type: "email",
                                  message: "The input is not valid E-mail!",
                                },
                                {
                                  required: true,
                                  message: "Please input your E-mail!",
                                },
                              ]}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="Phone" name="phone">
                              <Input placeholder="Phone" />
                            </Form.Item>
                            <Form.Item label="Address" name="address">
                              <Input placeholder="Address" />
                            </Form.Item>
                            <Form.Item label="Avatar" name="avatar">
                              <MediaSelectorButton
                                folder={UPLOAD_FOLDER.AVATARS}
                                accept={"image/*"}
                                listType={"picture-card"}
                                singleUpload={true}
                                multiple={false}
                                fileList={fileList.length > 0 ? fileList : null}
                              />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "right" }}>
                              <Button onClick={() => history.push("/")}>
                                Cancel
                              </Button>
                              <Button
                                className="ml-2"
                                type="primary"
                                loading={this.state.loading}
                                htmlType="submit"
                                style={{ marginRight: 10 }}
                              >
                                Update My Profile
                              </Button>
                            </Form.Item>
                          </Form>
                        </Card>
                      );
                    }}
                  </Mutation>
                )}
              </AppContext.Consumer>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default EditProfile;
