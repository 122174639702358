import React, { useCallback, useMemo, useState } from "react";
import { ActionList, Button, Popover } from "@shopify/polaris";
import { VocabularyMajorMonotone, EditMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import useToggle from "../../../../hooks/useToggle";
import { Update } from "./Update";
import { TEAM_ROLE } from "../../../../variable";
import { useAppContext } from "../../../../context";
import { UpdateKpi } from "./actions/UpdateKpi";
const ALL = "*";

export function MoreActions({ item }) {
    // Props
    const id = item?.id;

    // Context
    const { currentUser } = useAppContext();
    const teamRole = currentUser?.teamUser?.role;

    // State
    const [open, toggleOpen] = useToggle(false);
    const [showModal, toggleShowModal] = useToggle(false);
    const [action, setAction] = useState(null);

    // Actions
    const onAction = useCallback(
        (action) => {
            return () => {
                toggleShowModal(true);
                setAction(action);
            };
        },
        [toggleShowModal]
    );

    const actions = useMemo(() => {
        const options = [
            {
                content: "View detail",
                icon: VocabularyMajorMonotone,
                status: [ALL],
                roles: [ALL],
                onAction: onAction("view"),
            },
            {
                content: "Update KPI",
                icon: EditMinor,
                status: [ALL],
                roles: [TEAM_ROLE.Admin],
                onAction: onAction("updateKpi"),
            },
        ];

        return options.filter((act) =>
            act.status.some(
                (s) =>
                    [ALL].includes(s) &&
                    act.roles.some((r) => [ALL, teamRole].includes(r))
            )
        );
    }, [onAction, teamRole]);

    // Markup
    const activator = (
        <Button children="Actions" onClick={toggleOpen} disclosure />
    );

    const props = {
        open: showModal,
        onClose: toggleShowModal,
        item,
        id,
    };

    const actionsMarkup = {
        view: <Update {...props} />,
        updateKpi: <UpdateKpi {...props} />,
    };

    return (
        <React.Fragment>
            <Popover
                activator={activator}
                active={open}
                onClose={toggleOpen}
                preferredAlignment="right"
            >
                <Wrapper>
                    <ActionList items={actions} />
                </Wrapper>
            </Popover>
            {actionsMarkup[action]}
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    .Polaris-ActionList__Image {
        .Polaris-Icon {
            max-width: 1.7rem;
            max-height: 1.7rem;
        }
    }
`;
