import React from "react";
import PropTypes from "prop-types";
import { InlineError } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 20px;
`;

const Error = ({ error }) => {
  return (
    <Container>
      {error &&
        error.graphQLErrors.map(({ message }, i) => (
          <InlineError
		    key={ i }
            message={
              message.includes('stores_domain_key')
                ? "Email already exist!"
                : message
            }
          />
        ))}
    </Container>
  );
};

Error.propTypes = {
  error: PropTypes.any,
};

export default Error;
