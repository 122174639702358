import React, { useState, useEffect } from "react";
import { DataTable, Badge, Button } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../shared/PaginationPolaris";
const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .product-bases-wrap {
    width: calc(30rem - 3.2rem);
    white-space: break-spaces;
  }
  .title-wrap {
    width: calc(25rem - 3.2rem);
    white-space: break-spaces;
  }
`;

export const RecreateMockupTablePolaris = ({ data, filter, setFilter }) => {
  // State
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      let newRows =
        data?.recreateMockups?.nodes?.length > 0
          ? data.recreateMockups.nodes.map((item) => {
              let {
                id,
                title,
                productBases,
                division,
                totalOrder,
                status,
              } = item;

              // Product bases
              productBases =
                productBases?.length > 0
                  ? productBases.map((i) => i.title)
                  : [];
              let productBasesMarkup = productBases.join(", ");

              // Status
              let statusColor;
              switch (status) {
                case "Error":
                  statusColor = "critical";
                  break;
                case "Done":
                  statusColor = "success";
                  break;
                default:
                  statusColor = "default";
                  break;
              }

              return [
                <div className="title-wrap">
                  <span>{title}</span>
                </div>,
                <div className="product-bases-wrap">
                  <span>{productBasesMarkup}</span>
                </div>,
                <div className="division-wrap">
                  <span>{division?.title}</span>
                </div>,
                <div className="total-product-wrap">
                  <span>{totalOrder}</span>
                </div>,
                <div className="status-wrap">
                  <Badge children={status} status={statusColor} />
                </div>,
                <div className="actions-wrap">
                  <Button
                    plain
                    children="Edit"
                    url={`/seller/re-mockups/edit/${id}`}
                  />
                </div>,
              ];
            })
          : [];
      setRows(newRows);
    }
  }, [data]);

  let colsType = [];
  for (let i = 0; i < 6; i++) {
    colsType.push("text");
  }
  colsType.push("numeric");

  let { limit, offset } = filter;
  const total = get(data, "recreateMockups.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        rows={rows}
        columnContentTypes={colsType}
        headings={[
          "Title",
          "Product bases",
          "Division",
          "Min order",
          "Status",
          "Actions",
        ]}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
