import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { ExportTemplatePolaris } from "../../components/admin/manager-files/ExportTemplatePolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ExportTemplatePagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);
    return (
        <Container>
            <Page
                title="Export Templates"
                primaryAction={{
                    content: "Add Export Template",
                    url: "/admin/manager-files/add-template",
                }}
                fullWidth
            >
                <ExportTemplatePolaris path={path} />
            </Page>
        </Container>
    );
};
