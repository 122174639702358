import React, { Component } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import { ControlOutlined } from "@ant-design/icons";
import { Menu, Select, Dropdown } from "antd";
import { AppContext } from "../../context";
import { USER_ROLE } from "../../variable";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

export const LIST_STORE = gql`
  query storeListOfMe {
    storeListOfMe {
      total
      nodes {
        id
        title
        domain
      }
    }
  }
`;

class StoreSwitcher extends Component {
  state = {
    current: "",
  };

  render() {
    const { current } = this.state;
    return (
      <Query query={LIST_STORE}>
        {({ loading, error, data }) => {
          if (loading || error) return null;
          const stores = data.storeListOfMe.nodes
            ? data.storeListOfMe.nodes
            : null;
          if (!stores || (stores && !stores.length)) return <div></div>;
          return (
            <AppContext.Consumer>
              {({ setStore, store, currentUser }) => {
                if (
                  stores &&
                  currentUser.teamUser.role === USER_ROLE.StoreManager
                ) {
                  return store ? (
                    <Select
                      style={{ width: "150px" }}
                      onChange={(_, option) => {
                        setStore(option.data);
                      }}
                      value={store && store.id}
                    >
                      {stores.map((item) => (
                        <Select.Option key={item.id} data={item}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : null;
                }
                return (
                  stores &&
                  currentUser.teamUser.role === USER_ROLE.Admin && (
                    <Dropdown
                      overlay={
                        <Menu
                          selectedKeys={[current]}
                          // mode="horizontal"
                          // className="header-menu bg-hover rounded hidden lg:inline-block"
                        >
                          {stores.map((store, index) => (
                            <Menu.Item key={index}>
                              <CustomLinkPolaris href={`${store.domain}`}>
                                {store.title}
                              </CustomLinkPolaris>
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <span className="submenu-title-wrapper-avatar cursor-pointer">
                        <ControlOutlined
                          style={{ fontSize: "22px" }}
                          className="mr-1"
                        />
                        Teesight Store
                      </span>
                    </Dropdown>
                  )
                );
              }}
            </AppContext.Consumer>
          );
        }}
      </Query>
    );
  }
}

StoreSwitcher.propTypes = {
  onChange: PropTypes.func,
};

export default StoreSwitcher;
