import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  getCookie,
  handleError,
  matchPathName,
  setCookie,
} from "../../helper";
import history from "../../history";
import { FiltersMembersPolaris } from "../members/FiltersMembersPolaris";
import { TableMemberPolaris } from "../members/TableMemberPolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";

export const LIST_TEAM = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      count
      hits {
        id
        role
        team {
          id
        }
        user {
          id
          firstName
          lastName
          roles
          email
          phone
          address
          status
          avatar {
            id
            url
          }
        }
      }
    }
  }
`;

export const MembersPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    const initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter && initialFilter.status) {
      if ("active" === initialFilter.status) {
        initialFilter.status = true;
      }
      if ("inactive" === initialFilter.status) {
        initialFilter.status = false;
      }
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageMembers")) || 20,
    offset: 0,
    search: null,
    status: null,
    role: null,
    createdByMe: true,
    ...initFilter,
  });

  const { data, loading, error, refetch } = useQuery(LIST_TEAM, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { offset, limit, status, createdByMe, ...rest } = filter;
    if (true === status) {
      status = "active";
    }
    if (false === status) {
      status = "inactive";
    }

    let params = null;
    if (!filter.offset) {
      params = convertObjectToParams({ ...rest, status });
    } else {
      params = convertObjectToParams({ ...filter, status });
    }
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <FiltersMembersPolaris
          filter={filter}
          onChange={({ search, status, role }) =>
            setFilter((prevState) => ({
              ...prevState,
              search,
              status,
              role,
            }))
          }
        />
      </Card>
      <Card>
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data &&
          data.teamMembers &&
          data.teamMembers.hits &&
          data.teamMembers.hits.length > 0 ? (
          <TableMemberPolaris
            data={data}
            filter={filter}
            refetch={refetch}
            setValuePage={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageMembers", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};
