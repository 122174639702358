import { Modal } from "@shopify/polaris";
import React from "react";

const ImageZoomInOut = React.lazy(() => import("./ImageZoomInOut"));

export default function ModalWithImageZoomInOut({
  open,
  onClose,
  imageUrl,
  title,
  bg,
  inputSlider,
}) {
  const handleSpanRender = React.useCallback((node) => {
    if (!node) return;
    let domParent = document.querySelector(
      ".Polaris-Modal-Dialog__Modal:has(#moda-zoom-in-out)",
    );

    if (!domParent) return;
    domParent.setAttribute(
      "style",
      "max-width: calc(100% - 10rem); height: calc(100vh)",
    );
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sectioned
      title={title || "View Design"}
    >
      <span id="moda-zoom-in-out" ref={handleSpanRender} />
      <ImageZoomInOut imageUrl={imageUrl} bg={bg} inputSlider={inputSlider} />
    </Modal>
  );
}
