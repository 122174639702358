import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Modal, Select, Form } from "antd";

class DesignPositionVariantsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickOpen: false,
      showBtn: false,
      quickOptions: [],
      mapVariantsByKey: {},
    };

    this.quickSelect = this.quickSelect.bind(this);
  }

  keyFromAttributes = (attributes) => {
    let keys = [];
    if (attributes && attributes.length) {
      for (let i = 0; i < attributes.length; i++) {
        keys.push(attributes[i].option);
      }
    }
    return keys.join("/");
  };

  quickSelect() {
    const { variants } = this.props;
    const mapVariantsByKey = {};

    if (!variants || variants.length == 0) return;

    const keys = new Set();
    for (let i = 0; i < variants.length; i++) {
      const key = this.keyFromAttributes(variants[i].attributes);
      mapVariantsByKey[key] = variants[i];

      const attrs = variants[i].attributes || [];
      let attr = attrs.find((attr) => !!attr?.slug.match(/colors?/gi));
      if (!attr) {
        attr = attrs[0];
      }
      if (!attr || !attr.option) return;
      keys.add(attr.option);
    }

    this.setState({
      showBtn: true,
      quickOptions: Array.from(keys),
      mapVariantsByKey,
    });
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (e.altKey && e.key == "†") {
        this.quickSelect();
        e.preventDefault();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", function (e) {}, false);
  }

  handleCloseQuick = () => {
    this.setState({ quickOpen: false });
  };

  handleSelection = (selected) => {
    const { mapVariantsByKey } = this.state;
    if (!selected || selected.length === 0) return;

    const values = [];
    for (let [key, value] of Object.entries(mapVariantsByKey)) {
      if (!key || !selected.some((re) => key.includes(re))) continue;
      values.push({ id: value.id, attributes: value.attributes });
    }

    this.props.onChange(values);
  };

  render() {
    const { value, variants } = this.props;
    let options = ["*"];
    let defaultValue = ["*"];
    let mapVariantsByKey = {};
    if (value && value.length) {
      defaultValue = [];
      for (let i = 0; i < value.length; i++) {
        defaultValue.push(this.keyFromAttributes(value[i].attributes));
      }
    }
    if (variants && variants.length) {
      for (let i = 0; i < variants.length; i++) {
        const key = this.keyFromAttributes(variants[i].attributes);
        options.push(key);
        mapVariantsByKey[key] = variants[i];
      }
    }

    const { quickOpen, quickOptions, showBtn } = this.state;
    return (
      <div>
        <Select
          onChange={(v) => {
            if (v.length === 0) {
              this.props.onChange([]);
            }
            if (v && v.length === 1 && v[0] === "*") {
              this.props.onChange([]);
              return;
            }
            let changeValues = [];
            for (let i = 0; i < v.length; i++) {
              if (v[i] === "*") {
                continue;
              }
              const variantFromMap = mapVariantsByKey[v[i]];
              let variant = {
                id: variantFromMap.id,
                attributes: variantFromMap.attributes,
              };
              changeValues.push(variant);
            }
            this.props.onChange(changeValues);
          }}
          mode={"tags"}
          value={defaultValue}
        >
          {options.map((option, index) => (
            <Select.Option key={index} value={option}>
              {option === "*" ? "All variants" : option}
            </Select.Option>
          ))}
        </Select>

        {showBtn ? (
          <Button
            onClick={() => this.setState({ quickOpen: true })}
            style={{ marginTop: 16 }}
          >
            Select group
          </Button>
        ) : null}

        {quickOpen ? (
          <QuickSelectByAttr
            options={quickOptions}
            open={quickOpen}
            onClose={this.handleCloseQuick}
            onSelection={this.handleSelection}
          />
        ) : null}
      </div>
    );
  }
}

function QuickSelectByAttr({ options, open, onClose, onSelection }) {
  const [form] = Form.useForm();

  const handleChange = () => {
    const attributes = form.getFieldValue("attribute");
    onSelection && onSelection(attributes);
    onClose && onClose();
  };

  const opts = (options || []).map((value) => ({ value, label: value }));

  return (
    <Modal
      title="Quick select"
      open={open}
      onCancel={onClose}
      onOk={handleChange}
    >
      <Form form={form}>
        <Form.Item name="attribute">
          <Checkbox.Group options={opts} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

DesignPositionVariantsSelect.propTypes = {
  variants: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DesignPositionVariantsSelect;
