import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function CustomTimeInner({
  label,
  onChange,
  value,
  haveShow,
  isTo,
}) {
  const handleDateChange = React.useCallback(
    (v) => {
      let newVal = v;
      if (isTo) {
        newVal = dayjs(v).add(1, "day").subtract(1, "second");
      }
      onChange(newVal, dayjs(v).format("YYYY-MM-DD"));
      haveShow && haveShow(true);
    },
    [haveShow, onChange, isTo],
  );

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month", "day"]}
          label={label}
          value={value}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
  );
}
