import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAppContext } from "../../../context";
import { checkRole } from "../../../helper";

const LOAD_QUERY = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        designPositions {
          id
          file {
            id
            thumbnailUrl
            url
          }
          designPosition {
            id
            name
          }
        }
        productBases {
          id
          title
          slug
          fulfillment {
            fulfillmentTitle
          }
          variants {
            id
            attributes {
              name
              slug
              option
            }
          }
        }
        mainImageId
        images {
          id
          file {
            id
            url
            thumbnailUrl
          }
        }
        variants {
          id
          disabled
          productBaseVariantId
          productBaseVariant {
            id
            attributes {
              name
              slug
              option
            }
            productBase {
              id
              title
            }
          }
          variantStores {
            id
            originId
            storeId
          }
        }
        personalized
        fields {
          id
          title
        }
      }
    }
  }
`;

export function useFetchProducts(filter, skip = false) {
  const { currentUser } = useAppContext();
  const { isStoreManager, isMarketplaceManager } = checkRole(currentUser);

  const { data, loading, error } = useQuery(LOAD_QUERY, {
    variables: {
      filter: {
        ...filter,
        loadDesignPosition: true,
        ...(isStoreManager ? { storeId: "all" } : null),
        ...(isMarketplaceManager ? { storeId: null } : null),
      },
    },
    skip: skip,
    fetchPolicy: "no-cache",
  });

  return { data, loading, error };
}
