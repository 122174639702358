import React, { useCallback, useState, useEffect } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { handleError } from "../../../helper";
import { FulfillmentFormPolaris } from "./FulfillmentFormPolaris";

const CREATE_FULFILLMENT = gql`
  mutation createFulfillment($input: NewFulfillment!) {
    createFulfillment(input: $input) {
      id
      name
      slug
      products {
        title
        originId
        status
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddFulfillmentPolaris = () => {
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Mutation
  const [createFulfillment, { loading }] = useMutation(CREATE_FULFILLMENT, {
    onCompleted: () => {
      setNotification({
        message: "Add fulfillment successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle action
  const handleRedirect = useCallback(() => {
    history.push("/admin/fulfillment");
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createFulfillment({
        variables: {
          input,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Add fulfillment"
        breadcrumbs={[{ content: "Manage fulfillment", url: "/admin/fulfillment" }]}
      >
        <FulfillmentFormPolaris
          loading={loading}
          onSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      </Page>
    </Container>
  );
};
