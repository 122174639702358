import { Checkbox, Heading, Stack } from "@shopify/polaris";
import React from "react";
import { SimpleCustomizePolaris } from "../../../customize/SimpleCustomizePolaris";

export function ProductPersonalized({
    canUpdate,
    handleIsPersonalizedChange,
    handleFieldsChange,
    item,
}) {
    // Props
    const { fields, isPersonalized, status } = item || {};
    const itemStatusNull = status === null;

    return (
        <div>
            <Heading children="Personalized" />
            <Stack vertical>
                <Checkbox
                    label="This is a personalized product"
                    checked={isPersonalized}
                    onChange={handleIsPersonalizedChange}
                    disabled={!canUpdate && !itemStatusNull}
                />
                {isPersonalized && (
                    <SimpleCustomizePolaris
                        value={fields}
                        onChange={handleFieldsChange}
                        canUpdate={canUpdate || itemStatusNull}
                    />
                )}
            </Stack>
        </div>
    );
}
