import {
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Heading,
  Stack,
  TextField,
} from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { toSlug } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { DataTableProductPolaris } from "./DataTableProductPolaris";

const Container = styled.div`
  .add-product-wrap {
    padding: 1.6rem;
  }
  .btns-wrap {
    padding-top: 3rem;
    display: flex;
    flex-direction: row-reverse;
  }
  .pagination-wrap {
    padding: 0.5rem 1.6rem 1.6rem;
    overflow-x: scroll;
  }
`;

export const FulfillmentFormPolaris = ({
  loading,
  onSubmit,
  handleRedirect,
  data,
  total,
  filter,
  setFilter,
  isEditFF,
  onChange,
  loadingQuery,
}) => {
  // State
  const [basicFields, setBaseFields] = useState({
    name: null,
    slug: null,
  });
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({
    name: null,
    slug: null,
  });
  const [state, setState] = useReducer((p, state) => ({ ...p, ...state }), {
    search: "",
  });

  // Get data
  useEffect(() => {
    let ffById = data?.fulfillmentById;
    let name = ffById?.name;
    let slug = ffById?.slug;

    setBaseFields({
      name,
      slug,
    });

    // Products
    let ffProductById = data?.fulfillmentProductById?.nodes;
    let newProducts =
      ffProductById?.length > 0
        ? ffProductById.map((product) => {
            let { __typename, ...rest } = product;
            return rest;
          })
        : [];
    setProducts(newProducts);
  }, [data]);
  // Handle action
  const handleFieldChange = useCallback((value, id) => {
    setBaseFields((prev) => {
      let item = {};
      if (id === "name") {
        item = {
          name: value,
          slug: toSlug(value),
        };
      }
      return {
        ...prev,
        ...item,
        [id]: value,
      };
    });
    validateValue(value, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddProduct = useCallback(() => {
    let mergeProducts = [];
    setProducts((prev) => {
      mergeProducts = [
        ...prev,
        {
          title: "",
          originId: "",
          status: false,
        },
      ];
      return mergeProducts;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    let { name, slug } = basicFields;
    validateValue(name, "name");
    validateValue(slug, "slug");

    // Products
    let newProducts =
      products?.length > 0 ? products.filter((p) => p.title) : [];

    let input = {
      name,
      slug,
      products: newProducts,
    };
    if (name && slug) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields, products]);

  const validateValue = useCallback((value, id) => {
    let e = null;
    let nameUpperCase = id.charAt(0).toUpperCase() + id.substring(1);
    if (!value) {
      e = `${nameUpperCase} is required.`;
    }
    setErrors((prev) => ({
      ...prev,
      [id]: e,
    }));
  }, []);

  const searchRef = useRef(null);
  const handleSearch = useCallback(
    (value) => {
      setState({ search: value });

      searchRef.current && clearTimeout(searchRef.current);
      searchRef.current = setTimeout(() => {
        setFilter((p) => ({ ...p, search: value, offset: 0 }));
      }, 500);
    },
    [products]
  );

  const searchMarkup = useMemo(() => {
    return (
      <TextField
        value={state.search}
        onChange={handleSearch}
        placeholder="Search product"
        onClearButtonClick={() => {
          setState({ search: "" });
        }}
      />
    );
  }, [state.search, handleSearch]);

  // Variable
  let limit = filter?.limit;
  let offset = filter?.offset;
  let newTotal = total ? total : products.length;
  const totalPage = Math.ceil(newTotal / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: onChange,
    total: newTotal,
  };

  return (
    <Container>
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <ComponentLabelPolaris label="Name" required />
              <TextField
                value={basicFields.name}
                id="name"
                placeholder="Enter fulfillment name"
                onChange={handleFieldChange}
                error={errors.name}
              />
            </div>
            <div>
              <ComponentLabelPolaris label="Slug" required />
              <TextField
                value={basicFields.slug}
                id="slug"
                placeholder="Enter fulfillment slug"
                onChange={handleFieldChange}
                error={errors.slug}
              />
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <Card
        title={
          <Stack alignment="center" distribution="equalSpacing">
            <Heading element="h2">Products</Heading>
            {searchMarkup}
          </Stack>
        }
      >
        {loadingQuery ? (
          <SkeletonPagePolaris sizeSmall />
        ) : products?.length > 0 ? (
          <DataTableProductPolaris
            value={products}
            onChange={(value) => setProducts(() => [...value])}
            disabled={basicFields.slug === "customcat"}
          />
        ) : null}
        <div className="add-product-wrap">
          <Button
            icon={CirclePlusMinor}
            onClick={handleAddProduct}
            children={
              products?.length > 0 ? "Add another product" : "Add product"
            }
            disabled={basicFields.slug === "customcat"}
          />
        </div>
        {isEditFF && products?.length > 0 ? (
          <div className="pagination-wrap">
            <PaginationPolaris aggregation={aggregation} showTotal />
          </div>
        ) : null}
      </Card>
      <div className="btns-wrap">
        <ButtonGroup>
          <Button children="Cancel" onClick={handleRedirect} />
          <Button
            children="Save"
            primary
            onClick={handleSubmit}
            loading={loading}
          />
        </ButtonGroup>
      </div>
    </Container>
  );
};
