import React from "react";
import { reducerFn } from "../../../helper";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_STATE_BY_PRODUCT_BASE_IDS } from "../../../graphql/queries";

export function useStateByBase(filter) {
  const [state, setState] = React.useReducer(reducerFn, {
    data: {},
    loading: false,
  });

  const [getState] = useLazyQuery(GET_STATE_BY_PRODUCT_BASE_IDS, {
    onCompleted: (res) => {
      const result = formatState(res);
      setState({ loading: false, data: result });
    },
    onError: () => {
      setState({ loading: false });
    },
  });

  const handle = React.useCallback(
    (baseIDs) => {
      setState({ loading: true });
      getState({
        variables: {
          filter: {
            baseIDs,
            saleChannel: filter.saleChannel,
            filterByTime: filter.filterByTime,
          },
        },
      });
    },
    [filter],
  );

  return [handle, state];
}

function formatState(data) {
  const res = {};
  if (data?.getStateByProductBaseIDs?.length > 0) {
    for (let item of data.getStateByProductBaseIDs) {
      if (!item || !item.states || item.states.length === 0) continue;

      res[item.baseID] = item.states;
    }
  }

  return res;
}
