import { DocumentationsPage } from "../components/documentations";
import { AddCollectionPolaris } from "../components/product/collections/AddCollectionPolaris";
import { EditCollectionPolaris } from "../components/product/collections/EditCollectionPolaris";
import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import MainLayout from "../layout/MainLayout";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import { SellerClaimsPagePolaris } from "../pages/seller/ClaimsPolaris";
import Collections from "../pages/seller/Collections";
import { CreateManualOrderPagePolaris } from "../pages/seller/CreateManualOrderPagePolaris";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import { MappingOrderPagePolaris } from "../pages/seller/MappingOrdersPagePolaris";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsIgnoredPagePolaris } from "../pages/seller/ProductsIgnoredPagePolaris";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";
import Tags from "../pages/seller/Tags";
import { AddForCampaign as AddTemplateForCampaign } from "../components/product/templates/AddForCampaign";
import { AddForProduct as AddTemplateForProduct } from "../components/product/templates/AddForProduct";
import { ProductAssortmentPagePolaris } from "../pages/seller/ProductAssortmentPagePolaris";
import { ProductsPushPagePolaris } from "../pages/seller/ProductsPushPagePolaris";
import { NewPushProductsPolaris } from "../components/tools/NewPushProductsPolaris";
import { EditPushProductsPolaris } from "../components/tools/EditPushProductsPolaris";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import { AmazonChannelPage, EbayChannelPage, EtsyChannelPage, FacebookMKPage, OnlineStoresPage } from "../pages/reports";

const routes = [
  {
    path: `/seller-supporter/products`,
    exact: true,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/edit/:id`,
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products-ignored`,
    exact: true,
    component: ProductsIgnoredPagePolaris,
    title: "Product Ignored",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: `/seller-supporter/products/add`,
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/add/:id`,
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/clone`,
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/add-campaign`,
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/add-campaign/:id`,
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/clone-campaign`,
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: `/seller-supporter/products/campaign/:id`,
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/templates/for-product",
    exact: true,
    component: AddTemplateForProduct,
    layout: MainLayout,
    title: "Add Template for Product",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/templates/for-campaign",
    exact: true,
    component: AddTemplateForCampaign,
    layout: MainLayout,
    title: "Add Template for Campaign",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/collections",
    exact: true,
    component: Collections,
    layout: MainLayout,
    title: "Collections",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/add/collection",
    exact: true,
    component: AddCollectionPolaris,
    layout: MainLayout,
    title: "Add Collection",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/edit/collection/:id",
    exact: true,
    component: EditCollectionPolaris,
    layout: MainLayout,
    title: "Edit Collection",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/tags",
    exact: true,
    component: Tags,
    layout: MainLayout,
    title: "Tags",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/orders",
    exact: true,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/mapping",
    exact: true,
    component: MappingOrderPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/claims",
    exact: true,
    component: SellerClaimsPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/create-manual-order",
    exact: true,
    component: CreateManualOrderPagePolaris,
    title: "Create Manual Order",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/tools/product-assortment",
    exact: true,
    component: ProductAssortmentPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/products/product-push",
    exact: true,
    component: ProductsPushPagePolaris,
    title: "Product Push",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/products/product-push/add",
    exact: true,
    component: NewPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/products/product-push/:id",
    exact: true,
    component: EditPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/reports/amazon",
    exact: true,
    component: AmazonChannelPage,
    title: "Amazon Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/reports/facebook-mk",
    exact: true,
    component: FacebookMKPage,
    title: "Facebook Marketplace Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
    private: true,
  },
  {
    path: "/seller-supporter/reports/online-stores",
    exact: true,
    component: OnlineStoresPage,
    title: "Online stores Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/reports/etsy",
    exact: true,
    component: EtsyChannelPage,
    title: "Etsy Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
  {
    path: "/seller-supporter/reports/ebay",
    exact: true,
    component: EbayChannelPage,
    title: "Ebay Channel Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supporter",
  },
];

export default routes;
