import React, { useRef } from "react";
import { Icon } from "@shopify/polaris";
import { MobileHamburgerMajorMonotone } from "@shopify/polaris-icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import { DATA_TABLE_POLARIS } from "../../variable";

const {
  dataTable,
  dTCondensed,
  dTScrollContainer,
  dTTable,
  dTCell,
  dTCellHeader,
  dTCellVerticalAlignMiddle,
  dTCellFirstColumn,
  dTCellNumeric,
} = DATA_TABLE_POLARIS;
const classHeader = `${dTCell} ${dTCellHeader} ${dTCellVerticalAlignMiddle}`;

const itemType = "card";

export const TableDragPolaris = (props) => {
  // Props
  const { headings, children, hasCellNumeric } = props;

  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <div className={`${dataTable} ${dTCondensed}`}>
          <div className={dTScrollContainer}>
            <table className={dTTable}>
              <thead>
                <tr>
                  <th className={`${classHeader} ${dTCellFirstColumn}`}>
                    <Icon source={MobileHamburgerMajorMonotone} />
                  </th>
                  {headings?.length > 0
                    ? headings.map((heading, index) => {
                        let className = classHeader;
                        if (hasCellNumeric && index === headings.length - 1) {
                          className += ` ${dTCellNumeric}`;
                        }

                        return (
                          <th className={className} key={`heading-${index}`}>
                            {heading}
                          </th>
                        );
                      })
                    : null}
                </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
        </div>
      </DndProvider>
    </React.Fragment>
  );
};

export const DragableRow = (props) => {
  const { index, moveRow, children, className } = props;
  const wrapRef = useRef(null);
  const [, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      if (!wrapRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = wrapRef.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { type: itemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(wrapRef));
  return (
    <tr ref={wrapRef} className={className} style={{ cursor: "move" }}>
      {children}
    </tr>
  );
};
