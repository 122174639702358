import React, { useRef, useState } from "react";
import { Modal, TextField } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { MARK_PRODUCT_CANNOT_CONFIG_IN_PB } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export const MarkPBError = ({
    open,
    toggleShowModal,
    onNotificationChange,
    refetch,
    toggleActive,
    product,
}) => {
    const productId = product?.id;

    // State
    const [note, setNote] = useState("");

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [markPBError, { loading }] = useMutation(
        MARK_PRODUCT_CANNOT_CONFIG_IN_PB,
        {
            onError: (error) => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: handleError(error.toString()),
                        isError: true,
                    });
                }
            },
            onCompleted: () => {
                toggleShowModal();
                if (onNotificationChange) {
                    onNotificationChange({
                        message: `Mark Facebook error successfully.`,
                        isError: false,
                    });
                }

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    setFilter && setFilter((prev) => ({ ...prev }));
                    refetch && refetch();
                }, 1000);
            },
        }
    );

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        if (productId) {
            if (toggleActive) {
                toggleActive();
            }
            if (onNotificationChange) {
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            }
            markPBError({
                variables: {
                    productId,
                    note,
                },
            });
        }
    }, [productId, markPBError, note, toggleActive, onNotificationChange]);

    return (
        <Modal
            title="Mark Facebook Error"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <TextField
                label="Note"
                onChange={setNote}
                value={note}
                multiline={3}
            />
        </Modal>
    );
};
