import React, { useState, useCallback, useEffect } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import history from "../../history";
import { handleError } from "../../helper";
import { RecreateMockupFormPolaris } from "./RecreateMockupFormPolaris";

const RECREATE_MOCKUPS = gql`
  mutation createProductRecreateMockup($input: NewProductRecreateMockup!) {
    createProductRecreateMockup(input: $input) {
      id
      title
    }
  }
`;

export const RecreateMockupAddPolaris = () => {
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Mutation
  const [createProductRecreateMockup, { loading }] = useMutation(
    RECREATE_MOCKUPS,
    {
      onCompleted: () => {
        setNotification({
          message: "Create product recreate mockup successfully.",
          isError: false,
        });
        const id = setTimeout(() => {
          handleRedirect();
        }, 2100);
        setTimeoutId(id);
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          isError: true,
        });
      },
    }
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle actions
  const handleSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createProductRecreateMockup({
        variables: {
          input,
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRedirect = useCallback(
    () => history.push("/seller/re-mockups"),
    []
  );
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Page
      title="Re-Mockup Condition"
      breadcrumbs={[
        {
          content: "Product Re-Mockup Conditions",
          url: "/seller/re-mockups/",
        },
      ]}
    >
      {toastMarkup}
      <RecreateMockupFormPolaris
        onSubmit={handleSubmit}
        loading={loading}
        handleRedirect={handleRedirect}
      />
    </Page>
  );
};
