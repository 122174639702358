import React from "react";
import ReportTotalCost from "../../components/reports/ReportTotalCost";
import { WrapperPage } from "../../components/shared/WrapperPage";

export default function ReportTotalCostByFulfillment() {
  return (
    <WrapperPage>
      <ReportTotalCost />
    </WrapperPage>
  );
}
