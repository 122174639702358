import React, { useState, useCallback, useEffect } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Checkbox,
  ButtonGroup,
  Button,
  Toast,
  Collapsible,
  Stack,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";

import history from "../../history";
import { handleError } from "../../helper";
import { EXPORT_AMAZON_PRODUCTS } from "./ExportAmazonActionsPolaris";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { ProductDivisionSelectPolaris } from "../product/divisions/ProductDivisionSelectPolaris";
import { AccountsSelectPolaris } from "./AccountsSelectPolaris";
import { ProductBaseSelectPolaris } from "../base/ProductBaseSelectPolaris";
import { CollectionSelectPolaris } from "../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../product/tags/TagsSelectPolaris";
import { AuthorsSelectPolaris } from "../product/divisions/AuthorsSelectPolaris";
import { CreatedTimeFieldPolaris } from "./CreatedTimeFieldPolaris";
import { MappingExportFieldsPolaris } from "./MappingExportFieldsPolaris";
import { PreviewMatchedProductsPolaris } from "./PreviewMatchedProductsPolaris";
import { ExportModePolaris } from "../product/feeds/ExportModePolaris";

const Container = styled.div`
  .btns_wrap {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }
`;

const CREATE_AMAZON_EXPORT = gql`
  mutation createAmazonExport($input: CreateAmazonExportInput!) {
    createAmazonExport(input: $input) {
      id
      title
    }
  }
`;

// useProductDivision === false
// stores === query platform amazon

export const ExportAmazonFormPolaris = (props) => {
  // Props
  const { value, viewConfig } = props;

  // State
  const [title, setTitle] = useState(null);
  const [divisionIds, setDivisionIds] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [productBaseIds, setProductBaseIds] = useState([]);

  const [includeCollections, setIncludeCollections] = useState([]);
  const [excludeCollections, setExcludeCollections] = useState([]);
  const [includeTags, setIncludeTags] = useState([]);
  const [excludeTags, setExcludeTags] = useState([]);

  const [authorIds, setAuthorIds] = useState([]);
  const [createdTime, setCreatedTime] = useState({
    label: null,
    from: null,
    to: null,
  });
  const [limitProduct, setLimitProduct] = useState("0");
  const [markProductUsed, setMarkProductUsed] = useState(true);
  const [
    excludePersonalizedProducts,
    setExcludePersonalizedProducts,
  ] = useState(false);

  const [templateFileId, setTemplateFileId] = useState(null);
  const [templateFile, setTemplateFile] = useState(null);
  const [mapping, setMapping] = useState([]);

  const [errors, setErrors] = useState({
    title: null,
    storeId: null,
    divisionIds: null,
  });
  const [step, setStep] = useState(1);
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(true);
  const [notification, setNotification] = useState({
    message: null,
    isError: null,
  });
  const [activeToast, setActiveToast] = useState(false);
  const [stores, setStores] = useState([]);
  const [useProductDivision, setUseProductDivision] = useState(true);
  const [mode, setMode] = useState("all");

  // Get data
  useEffect(() => {
    // Product base
    let pbaseIds = get(value, "productBases", []);
    pbaseIds = pbaseIds && pbaseIds.length > 0 ? pbaseIds.map((b) => b.id) : [];

    // Include collections
    let newIC = get(value, "includeCollections", []);
    newIC = getObj(newIC);

    // Include tags
    let newIT = get(value, "includeTags", []);
    newIT = getObj(newIT);

    // Exclude collections
    let newEC = get(value, "excludeCollections", []);
    newEC = getObj(newEC);

    // Exclude tags
    let newET = get(value, "excludeTags", []);
    newET = getObj(newET);

    // Author
    let authorIds = get(value, "authors", []);
    const authFn = ({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    });
    authorIds = getObj(authorIds, authFn);

    // created Time
    let createdTimeFilter = get(value, "createdTimeFilter", null);
    let from = get(value, "from", null);
    from = getTime(from);

    let to = get(value, "to", null);
    to = getTime(to);

    setTitle(get(value, "title", null));
    setDivisionIds(get(value, "divisionIds", []));
    setStoreId(get(value, "storeId", null));
    setProductBaseIds(pbaseIds);
    setMarkProductUsed(get(value, "markProductUsed", true));
    setExcludePersonalizedProducts(value?.excludePersonalizedProducts);
    setTemplateFileId(get(value, "templateFileId", null));
    setTemplateFile(get(value, "templateFile", null));
    setMapping(get(value, "mapping", []));

    setIncludeCollections(newIC);
    setIncludeTags(newIT);
    setExcludeCollections(newEC);
    setExcludeTags(newET);
    setAuthorIds(authorIds);
    setCreatedTime(() => ({
      label: createdTimeFilter,
      from: from ? from : null,
      to: to ? to : null,
    }));
    setUseProductDivision(get(value, "useProductDivision", true));
    setMode(get(value, "mode") || "all");
  }, [value]);

  // Mutation
  const [createAmazonExport, { loading }] = useMutation(CREATE_AMAZON_EXPORT, {
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
    onCompleted: (res) => {
      let id = res?.createAmazonExport?.id;
      if (id) {
        exportAmazonProducts({
          variables: {
            exportTemplateId: id,
          },
        });
      }
      setNotification({
        message: "Exported for amazon success.",
        isError: false,
      });
      setTimeout(() => {
        history.push(`/seller/export-amazon`);
      }, 2100);
    },
  });

  const [exportAmazonProducts] = useMutation(EXPORT_AMAZON_PRODUCTS, {
    onError: () => {},
    onCompleted: (res) => {
      let url = res?.exportAmazonProducts;
      if (url) {
        window.open(url, "_blank");
      }
    },
  });

  // Handle Change
  /// Handle fields is required.
  const handleTitleValue = useCallback((value) => {
    let t = null;
    if (!value) {
      t = "Export title is required.";
    }
    setErrors((prev) => ({ ...prev, title: t }));
  }, []);

  const handleDivisionValue = useCallback((value) => {
    let d = null;
    if (!value?.length) {
      d = "Product divison is required.";
    }
    setErrors((prev) => ({ ...prev, divisionIds: d }));
  }, []);

  const handleStoreValue = useCallback((value) => {
    let s = null;
    if (!value) {
      s = "Products are divided is required.";
    }
    setErrors((prev) => ({ ...prev, storeId: s }));
  }, []);

  /// Handle fields value.
  const handleStoreId = useCallback((value) => {
    setStoreId(value);
    handleStoreValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleIC = useCallback((value) => {
    setIncludeCollections(value);
  }, []);
  const handleIT = useCallback((value) => {
    setIncludeTags(value);
  }, []);
  const handleEC = useCallback((value) => {
    setExcludeCollections(value);
  }, []);
  const handleET = useCallback((value) => {
    setExcludeTags(value);
  }, []);
  const handleAuthorChange = useCallback((value) => {
    setAuthorIds(value);
  }, []);

  const onCreatedTimeChange = useCallback((range, rangeLabel) => {
    let label = rangeLabel;

    if (range) {
      setCreatedTime((prev) => ({
        ...prev,
        label: label,
        from: range.from,
        to: range.to,
      }));
    } else if (label === "AllTime") {
      setCreatedTime((prev) => ({
        ...prev,
        label,
        from: null,
        to: null,
      }));
    }
  }, []);

  useEffect(() => {
    if (title && templateFileId && storeId && mapping.length) {
      setDisableBtnSubmit(() => false);
    }
  }, [title, templateFileId, mapping, storeId]);

  // On submit
  const handleSubmit = useCallback(() => {
    setNotification({
      message: null,
      isError: null,
    });

    // Collections
    let newIC = genIds(includeCollections);
    let newEC = genIds(excludeCollections);

    // Tags
    let newIT = genIds(includeTags);
    let newET = genIds(excludeTags);

    // Author
    let newAuth = genIds(authorIds, "value");

    // From
    let from = createdTime.from;
    from = genTime(from);

    // To
    let to = createdTime.to;
    to = genTime(to);

    let input = {
      title,
      templateFileId,
      storeId,
      includeCollectionIds: newIC,
      includeTagIds: newIT,
      excludeCollectionsIds: newEC,
      excludeTagIds: newET,
      productBaseIds,
      divisionIds: useProductDivision ? divisionIds : [],
      authorIds: newAuth,
      limit: Number(limitProduct),
      from: from,
      to: to,
      createdTimeFilter: createdTime.label ? createdTime.label : "AllTime",
      mapping,
      markProductUsed,
      excludePersonalizedProducts,
      useProductDivision,
      mode,
    };

    if (title && templateFileId && storeId && mapping.length) {
      createAmazonExport({
        variables: {
          input,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    divisionIds,
    storeId,
    productBaseIds,
    includeCollections,
    includeTags,
    excludeCollections,
    excludeTags,
    authorIds,
    createdTime,
    limitProduct,
    markProductUsed,
    excludePersonalizedProducts,
    templateFileId,
    mapping,
    useProductDivision,
    mode,
  ]);
  const handleStep = useCallback(
    (value) => {
      if (2 === value) {
        handleTitleValue(title);
        handleDivisionValue(divisionIds);
        handleStoreValue(storeId);

        if (title && storeId) {
          setStep(value);
        }
      } else {
        setStep(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title, storeId, divisionIds]
  );

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const filterPreview = {
    divisionIds,
    storeId,
    includeCollections,
    includeTags,
    excludeCollections,
    excludeTags,
    authorIds,
    createdTime,
    productBaseIds,
    limitProduct,
    useProductDivision,
  };

  return (
    <React.Fragment>
      {toastMarkup}
      {1 === step && (
        <Container>
          <Card sectioned>
            <FormLayout>
              <div>
                <ComponentLabelPolaris label="Export title" required />
                <TextField
                  value={title}
                  placeholder="Enter export title"
                  onChange={(value) => {
                    setTitle(value);
                    handleTitleValue(value);
                  }}
                  error={errors.title}
                />
              </div>
              <div>
                <Checkbox
                  checked={useProductDivision}
                  onChange={setUseProductDivision}
                  label="Getting products from a division"
                />
                <div style={{ marginTop: "0.5rem" }}>
                  <Collapsible
                    id="use-product-division"
                    open={!!useProductDivision}
                    transition={{ duration: "150ms", timingFunction: "ease" }}
                  >
                    <ProductDivisionSelectPolaris
                      onChange={(value) => {
                        let newValue = [];
                        if (value) {
                          newValue = value === "All" ? [] : [value];
                        }
                        setDivisionIds(newValue);
                        handleDivisionValue(newValue);
                      }}
                      value={divisionIds}
                      onChangeStores={(value) => setStores(() => value)}
                      error={errors.divisionIds}
                    />
                  </Collapsible>
                </div>
              </div>
              <AccountsSelectPolaris
                onChange={handleStoreId}
                errorField={errors.storeId}
                value={storeId}
                stores={stores}
                useProductDivision={useProductDivision}
              />
              <ProductBaseSelectPolaris
                allowMultiple
                onChange={(value) => setProductBaseIds(value)}
                value={productBaseIds}
              />
            </FormLayout>
          </Card>
          <Card>
            <Card.Header title="Inclusion Products" />
            <Card.Section>
              <FormLayout>
                <CollectionSelectPolaris
                  label="Include collections"
                  onChange={handleIC}
                  allowMultiple
                  value={includeCollections}
                />
                <TagsSelectPolaris
                  label="Include tags"
                  onChange={handleIT}
                  allowMultiple
                  value={includeTags}
                />
              </FormLayout>
            </Card.Section>
            <div style={{ marginTop: "-2rem" }}>
              <Card.Header title="Exclude Products" />
            </div>
            <Card.Section>
              <FormLayout>
                <CollectionSelectPolaris
                  value={excludeCollections}
                  onChange={handleEC}
                  allowMultiple
                  label="Exclude collections"
                />
                <TagsSelectPolaris
                  value={excludeTags}
                  onChange={handleET}
                  allowMultiple
                  label="Exclude tags"
                />
                <Checkbox
                  label="Exclude all matched personalized products."
                  checked={excludePersonalizedProducts}
                  onChange={(value) => setExcludePersonalizedProducts(value)}
                />
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <ExportModePolaris value={mode} onChange={setMode} />
            </Card.Section>
          </Card>
          <Card sectioned>
            <FormLayout>
              <AuthorsSelectPolaris
                label="Product created by"
                onChange={handleAuthorChange}
                value={authorIds}
              />
              <CreatedTimeFieldPolaris
                onChange={onCreatedTimeChange}
                value={createdTime}
              />
              <TextField
                label="Limit Products"
                value={limitProduct}
                onChange={(value) => setLimitProduct(value)}
                type="number"
                min={0}
              />
              <Checkbox
                label="Mark exported products status are used for this account."
                checked={markProductUsed}
                onChange={(value) => setMarkProductUsed(value)}
              />
            </FormLayout>
          </Card>
          {!viewConfig ? (
            <div className="btns_wrap">
              <div className="btn-preview">
                <PreviewMatchedProductsPolaris
                  filter={filterPreview}
                  title={title}
                  excludePersonalizedProducts={excludePersonalizedProducts}
                />
              </div>
              <ButtonGroup>
                <Button
                  children="Cancel"
                  onClick={() => history.push("/seller/export-amazon")}
                />
                <Button
                  children="Mapping export fields"
                  primary
                  onClick={() => handleStep(2)}
                />
              </ButtonGroup>
            </div>
          ) : null}
        </Container>
      )}
      {2 === step && (
        <MappingExportFieldsPolaris
          onChange={(data) => setMapping(() => [...data])}
          handleStep={handleStep}
          templateFileId={templateFileId}
          handleTemplateFileId={(value) => setTemplateFileId(() => value)}
          onSubmit={handleSubmit}
          disableBtnSubmit={disableBtnSubmit}
          loading={loading}
          templateFile={templateFile}
          mapping={mapping}
        />
      )}
    </React.Fragment>
  );
};

function arrInvalid(arr) {
  return !arr || !Array.isArray(arr) || arr.length === 0;
}

function genIds(arr, key = "id") {
  if (arrInvalid(arr)) return [];
  return arr.map((item) => {
    if (item && typeof item === "object") {
      return item[key];
    }
    return item;
  });
}

function genTime(obj) {
  if (!obj || typeof obj !== "object" || !obj._d) return obj;
  return obj._d;
}

function getObj(arr, fn = ({ id, name }) => ({ id, name })) {
  if (arrInvalid(arr)) return [];
  return arr.filter(Boolean).map(fn);
}

function getTime(obj) {
  if (!obj) return obj;
  let res = moment(obj);
  res = {
    ...res,
    _i: res._d,
  };
  return res;
}
