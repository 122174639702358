import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Badge, Button, Link, Stack, TextStyle } from "@shopify/polaris";
import { ImportMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { get } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import noImg from "../../../assets/images/unnamed.jpeg";
import { AppContext } from "../../../context";
import { REPORT_SALE_PRODUCTS } from "../../../graphql/queries";
import useToggle from "../../../hooks/useToggle";
import { TEAM_ROLE, TEAM_ROLE_PARAMS } from "../../../variable";
import { ViewModalImage } from "../../product/ViewModalImage";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";
import { ReportProductProvider, useReportProductCtx } from "./ProductContext";
import ViewOrderByProductID from "./ViewOrderByProductID";

const collapseKey = "products";

export function Products({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();
  const { currentUser } = useContext(AppContext);

  let currentParam = TEAM_ROLE_PARAMS.Seller;
  const role = get(currentUser, "teamUser.role");
  if ([TEAM_ROLE.StoreManager].includes(role)) {
    currentParam = TEAM_ROLE_PARAMS.StoreManager;
  }

  if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
    currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
  }

  // State
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 0,
    search: null,
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    storeID: null,
    storeManagerID: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalSale: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
  });
  const [mergedLoading, setMergedLoading] = useState(true);

  // Queries
  const { data, loading, error } = useQuery(REPORT_SALE_PRODUCTS, {
    variables: {
      filter,
    },
  });
  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_SALE_PRODUCTS);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      setFilter((prev) => ({ ...prev, ...filterCtx }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportSaleByProducts?.nodes;
    const total = data?.reportSaleByProducts?.total;
    const totalSale = data?.reportSaleByProducts?.totalSale;
    const totalSystemProducts = data?.reportSaleByProducts?.totalSystemProducts;
    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              const totalSale = node?.totalSale;
              const product = node?.product;
              const images = product?.images || [];
              const name = product?.title;
              const sku = product?.sku;
              const bases = product?.productBases;
              const id = product?.id;
              const personalized = product?.personalized;

              return {
                totalSale,
                name,
                imageUrl: getImageSrc(product),
                sku,
                bases,
                productId: id,
                personalized,
                images: images,
              };
            })
            .filter(Boolean)
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalSale,
      totalSystemProducts,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportSaleByProducts?.totalSale;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
      }));
    } else {
      setStateCompare({ totalSale: 0 });
    }
  }, [dataC, isCompare]);

  const redirectPage = useCallback(
    (item) => {
      const link =
        item?.bases?.length > 1
          ? `/${currentParam}/products/campaign`
          : `/${currentParam}/products/edit`;
      return `${link}/${item.productId}`;
    },
    [currentParam],
  );

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          const url = redirectPage(item);
          const personalizedMarkup = item?.personalized && (
            <Badge status="success" children="Personalized" />
          );

          return (
            <Stack key={`marketplace-manager-${index}`} wrap={false}>
              <span className="index-wrap">{index + 1}</span>
              <Stack.Item fill>
                <ContentWrap>
                  <ImgComp item={item} src={item.imageUrl} />
                  <div className="title-wrap">
                    <Link url={url} children={item.name} />
                    <div>{personalizedMarkup}</div>
                    <div>
                      <Badge children={item.sku} />
                    </div>
                    <ViewProductDetail productID={item.productId} />
                  </div>
                </ContentWrap>
              </Stack.Item>
              <span>{item.totalSale}</span>
            </Stack>
          );
        })
      : null;
  }, [state.items, redirectPage]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Name</TextStyle>
      </Stack.Item>
      <TextStyle variation="strong">Sales</TextStyle>
    </Stack>
  );

  const subTitle = useMemo(
    () => (
      <span>
        <span>{state.totalSale} Line items</span>
        <ComparedPercent
          originalVal={state.totalSale}
          newVal={stateCompare.totalSale}
        />
      </span>
    ),
    [state.totalSale, stateCompare.totalSale],
  );

  return (
    <ReportProductProvider>
      <CardBox
        title="Products"
        collapseKey={collapseKey}
        subTitle={
          <SubTitleWrap>
            <ExportProduct
              {...filter}
              {...filterCtx}
              disabled={!Boolean(state.totalSale)}
            />
            {subTitle}
          </SubTitleWrap>
        }
        headMarkup={headMarkup}
        contentMarkup={contentMarkup}
        filter={filter}
        total={state.total}
        loading={mergedLoading}
        error={error || errorC}
        setFilter={setFilter}
        isProduct
        state={state}
      />
      <ViewOrderByProductID
        saleChannel={saleChanel}
        filterByTime={filter.filterByTime}
      />
    </ReportProductProvider>
  );
}

function ImgComp({ src, item }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <ImgWrap>
        <img
          src={src}
          alt=""
          onClick={toggleOpen}
          style={{ cursor: "pointer" }}
        />
        <ViewModalImage
          open={open}
          toggleShowModal={toggleOpen}
          item={item}
          large
        />
      </ImgWrap>
    </>
  );
}

const EXPORT = gql`
  query exportSaleByProducts($filter: ExportSaleByProductFilter!) {
    exportSaleByProducts(filter: $filter) {
      file {
        id
        url
      }
    }
  }
`;

function ExportProduct({ limit, offset, disabled, ...filter }) {
  const [loading, setLoading] = React.useState(false);
  // Actions
  const handleSubmit = useCallback(async () => {
    const { __apolloClient__: client } = window;
    if (!client) return;
    setLoading(true);

    try {
      const res = await client.query({
        query: EXPORT,
        variables: {
          filter,
        },
      });

      const url = res?.data?.exportSaleByProducts?.file?.url;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (_e) {
    } finally {
      setLoading(false);
    }
  }, [filter]);

  return (
    <Button
      plain
      icon={ImportMinor}
      onClick={handleSubmit}
      loading={loading}
      disabled={disabled}
    />
  );
}

function ViewProductDetail({ productID }) {
  const { selectProduct } = useReportProductCtx();

  return (
    <div>
      <Button children="View stats" onClick={selectProduct(productID)} plain />
    </div>
  );
}

function getIgSrc(file) {
  const { thumbnailUrl, url } = file || {};
  if (thumbnailUrl) return thumbnailUrl;
  if (url) return url;
}

function getImageSrc(product) {
  let { mainImage, images, mainImageId } = product || {};

  let image = mainImage;
  if (!mainImage) {
    if (mainImageId && mainImageId.length > 0) {
      const m = (images || []).find((item) => item?.file?.id === mainImageId);
      if (m?.file) {
        image = m.file;
      }
    }
  }

  if (!image) {
    const file = (images || []).find((item) => !!item.file);
    if (file.file) {
      image = file.file;
    }
  }

  let url = getIgSrc(image);
  return url || noImg;
}

const SubTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.6rem;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;

  .title-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
`;

const ImgWrap = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 3px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
      rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
  }
`;
