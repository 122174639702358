import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card, Spin, Collapse } from "antd";
import { PRODUCT_BASE_TIME_LINE } from "../../../graphql/queries";
import {
  arrInvalid,
  formatTime,
  handleError,
  objectInvalid,
} from "../../../helper";
import styled from "styled-components";

function BaseTimeLine({ value }) {
  const [rows, setRows] = React.useState([]);
  const { data, loading, error } = useQuery(PRODUCT_BASE_TIME_LINE, {
    variables: {
      id: value?.id,
    },
    skip: !value || !value.id,
    fetchPolicy: "no-cache",
  });

  const dpRef = React.useRef(matchObj(value?.designPositions));
  const variantRef = React.useRef(matchObj(value?.variants));

  React.useEffect(() => {
    dpRef.current = matchObj(value?.designPositions);
    variantRef.current = matchObj(value?.variants);
  })

  React.useEffect(() => {
    const nodes = data?.productBaseTimeline || [];
    const rows = nodes.map(genNode(variantRef, dpRef));
    setRows(rows);
  }, [data?.productBaseTimeline]);

  return rows?.length > 0 ? (
    <Wrapper>
      <Card title="Timeline" bodyStyle={{ padding: 0 }}>
        {loading ? (
          <Spin />
        ) : error ? (
          <div>Error: {handleError(error)}</div>
        ) : rows?.length > 0 ? (
          <Collapse>
            {rows.map((item, index) => {
              return (
                <Collapse.Panel header={genHeader(item)} key={index}>
                  {genBody(item)}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        ) : null}
      </Card>
    </Wrapper>
  ) : null;
}

function genHeader(item) {
  if (objectInvalid(item)) return null;

  return (
    <h3>
      {item.name} - Updated at: {item.time}
    </h3>
  );
}

function genBody(item) {
  if (objectInvalid(item)) return null;
  const { fulfillment, variants, designPositions } = item;

  return (
    <div className="timeline-body">
      {fulfillment ? (
        <div className="fulfillment">
          <h3>Fulfillment:</h3>
          <span>{fulfillment}</span>
        </div>
      ) : null}
      {Object.keys(variants).length > 0 ? (
        <div className="timeline-variants">
          <h3>Variant:</h3>
          {Object.entries(variants).map(([key, value], index) => {
            const [name, id] = (key || "").split(/\|/);
            const label = `${name} ${id ? "( " + id + " )" : ""}`;
            const valMarkup = (value || []).join(", ");
            return (
              <div className="item" key={`variant-${index}`}>
                <span>{label}:</span>
                <p>{valMarkup}</p>
              </div>
            );
          })}
        </div>
      ) : null}
      {Object.keys(designPositions).length > 0 ? (
        <div className="timeline-design-positions">
          <h3>Design Position:</h3>
          {Object.entries(designPositions).map(([key, value], index) => {
            const [name = "", id] = (key || "").split(/\|/);
            const label = `${name} ${id ? "( " + id + " )" : ""}`;
            const valMarkup = (value || []).join(", ");
            return (
              <div className="item" key={`design-position-${index}`}>
                <span>{label}:</span>
                <p>{valMarkup}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

// Utils
function matchObj(data) {
  if (arrInvalid(data)) return data;

  return data.reduce((acc, item) => {
    if (objectInvalid(item) || !"id" in item) return acc;
    acc[item.id] = item;
    return acc;
  }, {});
}

function getVariantName(variant) {
  if (objectInvalid(variant)) return "";
  const { attributes } = variant;
  if (arrInvalid(attributes)) return "";

  const name = attributes
    .reduce((acc, attr) => {
      if (objectInvalid(attr) || !"option" in attr) return acc;
      acc.push(attr.option);
      return acc;
    }, [])
    .join(" / ");

  return name;
}

function mapObject(obj, objTarget, getName = () => {}) {
  if (objectInvalid(obj) || objectInvalid(objTarget)) return {};

  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];
    const match = objTarget[key];
    let newK = key;
    if (match) {
      newK = `${getName(match)}|${key}`;
    }
    acc[newK] = val;
    return acc;
  }, {});
}

function genNode(variantRef, dpRef) {
  return (node) => {
    const { createdAt, user, data } = node || {};
    const { design_position: dp, fulfillment, variant } = data || {};
    const { firstName, lastName } = user || {};
    const fName = [firstName, lastName].filter(Boolean).join(" ");

    return {
      name: fName,
      fulfillment,
      time: formatTime(createdAt),
      variants: mapObject(variant, variantRef.current, getVariantName),
      designPositions: mapObject(dp, dpRef.current, (match) => match.name),
    };
  };
}

const Wrapper = styled.div`
  margin-top: 2rem;

  .timeline-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 2.5rem;

    .fulfillment {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      color: rgb(34, 47, 62);
    }

    h3 {
      font-weight: 600;
      color: #222f3e;
      font-size: 1.15em;
    }

    .item {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-left: 1.5rem;
      color: #222f3e;

      span {
        font-weight: 500;
      }
    }
  }

  .ant-collapse {
    border: none;

    > .ant-collapse-item {
      border-bottom: none;

      > .ant-collapse-header {
        background-color: #fff;
        border: none;
      }
    }
  }

  .ant-collapse-content {
    border-top: none;

    > .ant-collapse-content-box {
      padding-top: 0;
    }
  }
`;
export { BaseTimeLine };
