import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { ClaimsPolaris } from "../../components/admin/claim/ClaimsPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ClaimsPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page title="All Claim" fullWidth>
                <ClaimsPolaris path={path} />
            </Page>
        </Container>
    );
};
