import { Query } from "@apollo/react-components";
import { Card, Input, Skeleton, Table, Tag } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context";
import { getParam } from "../../helper";
import { USER_ROLE } from "../../variable";
import ActionCloneTemplate from "./ActionCloneTemplate";
import ActionRemoveExportTemplate from "./ActionRemoveExportTemplate";

export const EXPORT_TEMPLATE = gql`
  query exportTemplates($search: String) {
    exportTemplates(search: $search) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      author {
        id
      }
    }
  }
`;

export default class ListExportTemplates extends Component {
  state = {
    search: null,
  };
  render() {
    const { isAdmin } = this.props;
    const { currentUser } = this.context || {};
    const param = getParam(currentUser);
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <Card
            bodyStyle={{ padding: 0 }}
            title={
              <div className="flex flex-row justify-between">
                <div className="flex-1">All Templates</div>
                <div className="flex-1" style={{ maxWidth: 300 }}>
                  <Input.Search
                    className="w-full"
                    placeholder="Search for templates"
                    onSearch={(e) => {
                      this.setState({ search: e });
                    }}
                    onChange={(e) => {
                      if (!e.target.value) {
                        this.setState({ search: null });
                      }
                    }}
                  />
                </div>
              </div>
            }
          >
            <Query
              query={EXPORT_TEMPLATE}
              variables={{ search: this.state.search }}
              fetchPolicy="cache-and-network"
            >
              {({ data, error, loading }) => {
                const columns = [
                  {
                    title: "Template Name",
                    key: "templateName",
                    dataIndex: "name",
                    width: 300,
                    render: (name, record) => (
                      <Link
                        to={`/${
                          isAdmin ? "admin" : param
                        }/manager-files/edit-template/${record.id}`}
                      >
                        {name}
                      </Link>
                    ),
                  },
                  ...(isAdmin
                    ? [
                        {
                          title: "Supplier",
                          key: "suppliers",
                          dataIndex: "suppliers",
                          width: 300,
                          render: (suppliers) =>
                            suppliers &&
                            suppliers.map((sup) => (
                              <Tag
                                key={sup.id}
                              >{`${sup.firstName} ${sup.lastName}`}</Tag>
                            )),
                        },
                      ]
                    : []),
                  {
                    title: "Actions",
                    key: "action",
                    width: 138,
                    fixed: "right",
                    align: "center",
                    render: (_, record) => {
                      return (currentUser && currentUser.id) ===
                        (record.author && record.author.id) ||
                        (currentUser &&
                          (currentUser.roles.includes("Administrator") ||
                            currentUser.roles.includes(
                              USER_ROLE.Supporter
                            ))) ? (
                        <ActionRemoveExportTemplate id={record.id} />
                      ) : (
                        <ActionCloneTemplate id={record.id} />
                      );
                    },
                  },
                ];
                if (loading)
                  return (
                    <div className="p-4">
                      <Skeleton />
                    </div>
                  );
                if (error) return error.toString();
                const dataSource = (data && data.exportTemplates) || [];
                return (
                  <Table
                    columns={columns}
                    scroll={{ x: "maxWidth" }}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={false}
                  />
                );
              }}
            </Query>
          </Card>
        )}
      </AppContext.Consumer>
    );
  }
}

ListExportTemplates.contextType = AppContext;
