import React, { Component } from "react";
import PropTypes from "prop-types";
import Customize from "./Customize";
import { Button, Modal, notification } from "antd";
import FieldsLiveView from "./FieldsLiveView";

class CustomizeButton extends Component {
  state = {
    visible: false,
    fields: [],
  };
  componentDidMount() {
    const { value } = this.props;
    if (value && value.length) {
      this.setState({
        fields: JSON.parse(JSON.stringify(this.props.value)),
      });
    }
  }
  onChange = () => {
    this.props.onChange(JSON.parse(JSON.stringify(this.state.fields)));
  };
  render() {
    const { showPreview, value } = this.props;
    const { fields } = this.state;
    return (
      <div>
        {showPreview && (!value || value.length === 0) && (
          <Button
            onClick={() => this.setState({ visible: true })}
            type={"primary"}
          >
            Add Personalized Options
          </Button>
        )}
        {this.state.visible && (
          <Modal
            onOk={() => {
              if (!canSubmit(fields)) {
                notification.error({ message: "Field Name must be unique." });
                return;
              }
              this.setState({ visible: false }, () => {
                this.onChange();
              });
            }}
            onCancel={() => this.setState({ visible: false })}
            bodyStyle={{ padding: 0 }}
            className={"customize-modal"}
            width={"80%"}
            title={"Personalized"}
            visible={true}
          >
            <Customize
              value={value ? value : []}
              onChange={(fields) => {
                this.setState({ fields });
              }}
            />
          </Modal>
        )}
        {showPreview && value && value.length > 0 && (
          <FieldsLiveView
            onEdited={() => {
              this.setState({
                visible: true,
              });
            }}
            onDeleted={() => {
              this.setState(
                {
                  fields: [],
                },
                () => {
                  this.onChange();
                }
              );
            }}
            fields={value}
          />
        )}
      </div>
    );
  }
}

function canSubmit(fields) {
  if (!fields || fields.length === 0) return false;

  const count = fields.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: (acc[cur.name] || 0) + 1,
    };
  }, {});

  return Object.values(count).every((val) => val === 1);
}

CustomizeButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  showPreview: PropTypes.bool,
};

export default CustomizeButton;
