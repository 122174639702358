import React from "react";
import { Select, FormLayout } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { StoresSelectPolaris } from "../StoresSelectPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

const QUERY_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const FieldStorePolaris = ({ value, onChange, type }) => {
  //State
  const [typeStore, setTypeStore] = React.useState(type);
  const [stores, setStores] = React.useState([]);

  // Query
  const { data, error } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        limit: 3000,
        offset: 0,
        search: null,
        platformSelected:
          typeStore === "online-store"
            ? ["woocommerce", "shopify", "shopbase"]
            : [typeStore],
      },
    },
  });

  // Get data
  React.useEffect(() => {
    let newStores =
      data?.stores?.nodes?.length > 0
        ? data.stores.nodes.map((store) => ({
            value: store.id,
            label: store.title,
          }))
        : [];
    setStores(newStores);
  }, [data]);
  React.useEffect(() => setTypeStore(type), [type]);

  // Variables
  const STORE_TYPES = React.useMemo(
    () => [
      {
        value: "online-store",
        label: "Online Store",
      },
      { value: "amazon", label: "Amazon" },
      { value: "ebay", label: "Ebay" },
      { value: "etsy", label: "Etsy" },
      { value: "facebook", label: "Facebook" },
      { value: "tiktok", label: "Tiktok" },
    ],
    []
  );

  // Handle actions
  const handleStoreChange = React.useCallback(
    (selected) => {
      setTypeStore(selected);
      if (onChange) {
        onChange([]);
      }
    },
    [onChange]
  );
  return (
    <div style={{ paddingTop: "1px" }}>
      <FormLayout>
        <Select
          value={typeStore}
          options={STORE_TYPES}
          onChange={handleStoreChange}
          label="Type store"
        />
        <div>
          <ComponentLabelPolaris label="Stores" />
          <StoresSelectPolaris
            stores={stores}
            allowMultiple
            value={value}
            onChange={onChange}
            error={error}
            labelHidden
          />
        </div>
      </FormLayout>
    </div>
  );
};
