import React, { Component } from "react";
import { Table, Card, Button, Row, Col, Select, Input, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import styled from "styled-components";
import moment from "moment";

const LOAD_BILLINGS = gql`
  query deposits {
    deposits {
      nodes {
        id
        amount
        transactionId
        comment
        supplier {
          id
          firstName
          lastName
        }
        status
        createdAt
      }
      total
    }
  }
`;
const { Search } = Input;
const { Option } = Select;
const Container = styled.div``;
class ListBilling extends Component {
  state = {
    dataSource: [],
  };
  render() {
    const columns = [
      // { title: "ID", dataIndex: "id", key: "id" },
      { title: "Amount", dataIndex: "amount", key: "amount" },
      {
        title: "Transaction Id",
        dataIndex: "transactionId",
        key: "transactionId",
      },
      { title: "Note", dataIndex: "comment", key: "comment" },
      {
        title: "Target",
        dataIndex: "supplier",
        key: "supplier",
        render: (text, { firstName, lastName }) => (
          <div>
            {text.firstName} {text.lastName}
          </div>
        ),
      },
      { title: "Status", dataIndex: "status", key: "status" },
      {
        title: "Created Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
      },
    ];
    return (
      <Container>
        <Card
          title="All Deposit"
          extra={
            <Link to="/seller/billings/add-deposit">
              <Button type="primary" htmlType="submit">
                New Deposit
              </Button>
            </Link>
          }
        >
          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col span={6}>
              <div style={{ border: "1px solid #999", padding: 10 }}>
                <h3>Account Balance</h3>
                <p>$250.00</p>
              </div>
            </Col>
            <Col span={6}>
              <div style={{ border: "1px solid #999", padding: 10 }}>
                <h3>Pending Cost</h3>
                <p>$250.00</p>
              </div>
            </Col>
            <Col span={6}>
              <div style={{ border: "1px solid #999", padding: 10 }}>
                <h3>Paid Amount</h3>
                <p>$250.00</p>
              </div>
            </Col>
            <Col span={6}>
              <div style={{ border: "1px solid #999", padding: 10 }}>
                <h3>Pending Deposit</h3>
                <p>$250.00</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Select
              defaultValue={"Filter by status"}
              style={{ marginRight: 10 }}
            >
              <Option value="approved">Approved</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancelled">Cancelled</Option>
            </Select>
            <Select
              defaultValue={"Filter by supplier"}
              style={{ marginRight: 10 }}
            >
              <Option value="approved">Approved</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancelled">Cancelled</Option>
            </Select>
            <Search
              className="pt-2 sm:pt-0 mx-auto sm:max-w-xs"
              placeholder="Search text"
              enterButton
            />
          </Row>
          <Query query={LOAD_BILLINGS}>
            {({ error, loading, data }) => {
              if (loading)
                return (
                  <div className="m-4">
                    <Skeleton active />
                  </div>
                );
              if (error) return <div>{error.toString()}</div>;
              const dataSource = data.deposits.nodes;
              return (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={(_, index) => index}
                />
              );
            }}
          </Query>
        </Card>
      </Container>
    );
  }
}

export default ListBilling;
