import { Page } from "@shopify/polaris";
import React, { useMemo } from "react";
import styled from "styled-components";
import { RecreateMockupsPolaris } from "../../components/recreate-mockup/RecreateMockupsPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const RecreateMockupPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page
                title="Product Re-Mockup Conditions"
                fullWidth
                primaryAction={{
                    content: "Add New Condition",
                    url: "/seller/re-mockups/add",
                }}
            >
                <RecreateMockupsPolaris path={path} />
            </Page>
        </Container>
    );
};
