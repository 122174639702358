import React, { Component } from "react";
import { notification } from "antd";
import ExportTemplateForm from "../../components/admin/ExportTemplateForm";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import history from "../../history";

const CREATE_TEMPLATE = gql`
  mutation createExportTemplate($input: NewExportTemplate!) {
    createExportTemplate(input: $input) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      columns {
        name
        type
        value
      }
    }
  }
`;

export default class AddExportTemplate extends Component {
  state = {
    loading: false,
  };

  render() {
    const { link, isAdmin } = this.props;
    return (
      <Mutation
        mutation={CREATE_TEMPLATE}
        onCompleted={() => {
          this.setState({ loading: false });
          notification.success({ message: "Create export template success." });
          history.push(link);
        }}
        onError={(err) => {
          notification.error({ message: err.toString() });
          this.setState({ loading: false });
        }}
      >
        {(createTemplate) => (
          <ExportTemplateForm
            isAdmin={isAdmin}
            loading={this.state.loading}
            onSubmit={(values) => {
              this.setState({ loading: true });
              createTemplate({ variables: { input: values } });
            }}
          />
        )}
      </Mutation>
    );
  }
}
