import React from "react";
import { ChoiceList } from "@shopify/polaris";

const options = [
  { label: "Do not add variant title at the end of title", value: false },
  {
    label: `Add all options like color, size, etc behind the title
  (example: t-shirt red S)`,
    value: true,
  },
];

export const VariantTitlePolaris = ({ onChange, value }) => {
  return (
    <ChoiceList
      title={"Variant title"}
      choices={options}
      onChange={onChange}
      selected={[value]}
    />
  );
};
