import { useMutation } from "@apollo/react-hooks";
import { TopBar } from "@shopify/polaris";
import {
  BlogMajorMonotone,
  CalendarTickMajorMonotone,
  LogOutMinor,
  ProfileMajorMonotone,
  RefreshMajorMonotone,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import React, { useCallback, useContext, useState } from "react";
import { getTokenObject } from "../../client";
import { Update2FA } from "../../components/Regen2FACode";
import ChangeUserPasswordLinkPolaris from "../../components/shared/ChangeUserPasswordLinkPolaris";
import { AppContext } from "../../context";
import history from "../../history";
import useToggle from "../../hooks/useToggle";
import { ActivityHistory } from "./ActivityHistory";

const LOG_OUT = gql`
  mutation logout {
    logout
  }
`;

const UserMenuPolaris = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [open, toggleOpen] = useToggle(false);
  const [openActivity, toggleOpenActivity] = useToggle(false);
  const context = useContext(AppContext);

  // Queries
  const [logout, { client }] = useMutation(LOG_OUT);
  const {
    currentUser: info,
    setStore,
    setCurrentUser,
    logout: logoutContext,
  } = context;
  const av =
    info &&
    (info.firstName ? info.firstName.split("") : info.lastName.split(""));

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const handleModalChange = useCallback(
    () => setIsActiveModal((isActiveModal) => !isActiveModal),
    []
  );

  const actionLogout = useCallback(async () => {
    await logout();
    localStorage.clear();
    setStore(null);
    setCurrentUser(null);
    client.resetStore();
    logoutContext();
    history.push(`/login`);
    window.location.reload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = React.useMemo(() => {
    const regenAction = {
      content: "Update 2FA Code",
      icon: RefreshMajorMonotone,
      onAction: toggleOpen,
    };

    const { enable2fa } = getTokenObject() || {};
    const acts = [
      {
        content: "Profile",
        icon: ProfileMajorMonotone,
        onAction: () => history.push("/edit-profile"),
      },
      {
        content: "Change Password",
        icon: BlogMajorMonotone,
        onAction: handleModalChange,
      },
      {
        content: "Activity History",
        icon: CalendarTickMajorMonotone,
        onAction: toggleOpenActivity,
      },
      { content: "Logout", icon: LogOutMinor, onAction: actionLogout },
    ];

    if (enable2fa) {
      const index = acts.findIndex(({ content }) => content === "Profile");
      if (index !== -1) {
        acts.splice(index + 1, 0, regenAction);
      }
    }

    return acts;
  }, [history, handleModalChange, actionLogout, toggleOpen]);

  return (
    <>
      <TopBar.UserMenu
        actions={[
          {
            items: actions,
          },
        ]}
        name={(info && info.firstName) || ""}
        detail={(info && info.lastName) || ""}
        initials={(av && av[0].toUpperCase()) || ""}
        avatar={(info && info.avatar && info.avatar.url) || null}
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
      {isActiveModal && (
        <ChangeUserPasswordLinkPolaris
          isActive={isActiveModal}
          handleModalChange={handleModalChange}
        />
      )}
      <Update2FA open={open} onClose={toggleOpen} />
      <ActivityHistory open={openActivity} onClose={toggleOpenActivity} />
    </>
  );
};

export default UserMenuPolaris;
