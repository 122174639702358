import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { GET_STORES } from "../../graphql/queries";
import { isEmpty } from "../../helper";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";

export const ExportTrackingFilter = ({ filter, onChange }) => {
  const [stores, setStores] = useState([]);
  const [search, setSearch] = useState(filter.search || "");
  const [store, setStore] = useState({
    value: null,
    label: null,
    search: null,
  });
  const typingTimeoutRef = useRef();
  const searchRef = useRef(null);

  // Query
  const { data, loading } = useQuery(GET_STORES, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        platformSelected: ["amazon"],
        search: store.search,
      },
    },
  });

  // Get data
  useEffect(() => {
    let storeId = filter?.storeId;
    if (storeId) {
      let currentS =
        stores?.length > 0 ? stores.find((s) => s.id === storeId) : null;
      if (currentS) {
        let { value, label } = currentS;
        setStore({
          value,
          label,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.storeId, stores]);

  useEffect(() => {
    typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);

    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          storeId: store.value,
          search: (search || "").trim(),
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, search]);

  useEffect(() => {
    let newData =
      data?.stores?.nodes?.length > 0
        ? data.stores.nodes.map((store) => ({
            value: store.id,
            label: store.title,
          }))
        : [];
    setStores(newData);
  }, [data]);

  // Handle actions
  const handleQueryChange = useCallback((s) => {
    setSearch(s);
  }, []);

  const handleQueryClear = useCallback(() => {
    setSearch("");
  }, []);
  const handleStoreRemove = useCallback(
    () => setStore({ value: null, label: null }),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleStoreRemove();
    handleQueryClear();
  }, [handleStoreRemove, handleQueryClear]);

  const handleSearchChange = useCallback((search) => {
    searchRef.current && clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      setStore((prev) => ({ ...prev, search }));
    }, 500);
  }, []);

  // Markup
  const filters = [
    {
      key: "stores",
      label: "Stores",
      filter: (
        <FilterHasSearchPolaris
          // <FilterNoSearchPolaris
          options={stores}
          value={store}
          onChangeSearch={handleSearchChange}
          loading={loading}
          onChange={({ value, label }) => {
            setStore((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(store.label)) {
    const key = "stores";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }

  return (
    <Filters
      queryValue={search}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      queryPlaceholder="Search ..."
      filters={filters}
      appliedFilters={appliedFilters}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "stores":
        return `Store: ${value}`;
      default:
        return value;
    }
  }
};
