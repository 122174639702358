import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, notification } from "antd";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { handleError } from "../../helper";

const Container = styled.div`
  button {
    .add-prefix {
      font-weight: 700;
      margin-right: 5px;
    }
    :hover {
      background-color: #f5f5f5;
    }
  }
`;

const ADD_MUTATION = gql`
  mutation createTag($name: String!) {
    createTag(name: $name) {
      id
      name
    }
  }
`;
class QuickAddTag extends Component {
  state = {
    loading: false,
  };
  render() {
    const { value } = this.props;
    return (
      <Container>
        <Mutation mutation={ADD_MUTATION}>
          {(createTag) => (
            <Button
              onClick={() => {
                this.setState({ loading: true });
                createTag({
                  variables: {
                    name: value,
                  },
                })
                  .then((res) => {
                    this.setState({
                      loading: false,
                    });
                    if (this.props.onCompleted) {
                      this.props.onCompleted(res.data.createTag);
                    }
                  })
                  .catch((e) => {
                    notification.error({ message: handleError(e.toString()) });
                    this.setState({
                      loading: false,
                    });
                    if (this.props.onError) {
                      this.props.onError(e);
                    }
                  });
              }}
              icon={<LegacyIcon type={"plus"} />}
              style={{ width: "100%", textAlign: "left", border: 0 }}
            >
              <span className={"add-prefix"}>Add</span>
              {value}
            </Button>
          )}
        </Mutation>
      </Container>
    );
  }
}

QuickAddTag.propTypes = {
  value: PropTypes.string,
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default QuickAddTag;
