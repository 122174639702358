import React, { useCallback, useState, useEffect } from "react";
import { Page, Loading, Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import history from "../../history";

import { handleError } from "../../helper";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { RecreateMockupFormPolaris } from "./RecreateMockupFormPolaris";

const RECREATE_MOCKUP_BY_ID = gql`
  query recreateMockup($id: ID!) {
    recreateMockup(id: $id) {
      title
      productBaseIds
      divisionId
      totalOrder
      limit
      hasDesign
      authorId
    }
  }
`;

const UPDATE_RECREATE_MOCKUPS = gql`
  mutation updateProductRecreateMockup($input: EditProductRecreateMockup!) {
    updateProductRecreateMockup(input: $input) {
      id
      title
    }
  }
`;

export const RecreateMockupEditPolaris = () => {
  const { id } = useParams();
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Query
  const { data, loading, error } = useQuery(RECREATE_MOCKUP_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  // Mutation
  const [updateProductRecreateMockup, { loading: loadingM }] = useMutation(
    UPDATE_RECREATE_MOCKUPS,
    {
      onCompleted: () => {
        setNotification({
          message: "Update product recreate mockup successfuly.",
          isError: false,
        });
        const id = setTimeout(() => {
          handleRedirect();
        }, 2100);
        setTimeoutId(id);
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          isError: true,
        });
      },
    }
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  // Handle actions
  const handleRedirect = useCallback(
    () => history.push("/seller/re-mockups"),
    []
  );
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  const handleSubmit = useCallback(
    (input) => {
      setNotification({
        message: null,
        isError: false,
      });
      if (input) {
        updateProductRecreateMockup({
          variables: {
            input: {
              id,
              ...input,
            },
          },
        });
      }
      toggleActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  // Markup
  const loadingMarkup = loading && <Loading />;
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  return (
    <Page
      title="Update Re-Mockup Condition"
      breadcrumbs={[
        {
          content: "Product Re-Mockup Conditions",
          url: "/seller/re-mockups/",
        },
      ]}
    >
      {loadingMarkup}
      {toastMarkup}
      {loading ? (
        <SkeletonPagePolaris />
      ) : error ? (
        <Card sectioned>Error: {handleError(error.toString())}</Card>
      ) : (
        <RecreateMockupFormPolaris
          value={data?.recreateMockup}
          onSubmit={handleSubmit}
          handleRedirect={handleRedirect}
          isEditRM
          loading={loadingM}
        />
      )}
    </Page>
  );
};
