import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";

import { isEmpty } from "../../../helper";

import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

export const FilterProductFeedsPolaris = ({
  stores,
  onChange,
  filter,
  storeLabel,
}) => {
  const [queryValue, setQueryValue] = useState(filter.search);
  const [store, setStore] = useState({
    value: null,
    label: null,
    search: null,
  });
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      value: filter.storeId,
      label: storeLabel,
    }));
  }, [filter.storeId, storeLabel]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          storeId: store.value,
          search: queryValue ? queryValue.trim() : queryValue,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, queryValue]);

  const handleQueryChange = useCallback((value) => setQueryValue(value), []);
  const handleQueryRemove = useCallback(() => setQueryValue(null), []);
  const handleStoreRemove = useCallback(
    () => setStore({ value: null, label: null }),
    []
  );
  const handleFiltersClearAll = useCallback(() => {
    handleQueryRemove();
    handleStoreRemove();
  }, [handleQueryRemove, handleStoreRemove]);

  stores =
    stores && stores.length
      ? stores.map((s) => ({ value: s.id, label: s.title }))
      : [];

  const filters = [
    {
      key: "stores",
      label: "Stores",
      filter: (
        <FilterNoSearchPolaris
          data={stores}
          value={store}
          onChangeSearch={({ search }) => {
            setStore((prevState) => {
              return {
                ...prevState,
                search,
              };
            });
          }}
          onChange={({ value, label }) => {
            setStore((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(store.label)) {
    const key = "stores";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }

  return (
    <Filters
      queryValue={queryValue}
      queryPlaceholder={"Filter product feed"}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "stores":
        return `Store: ${value}`;
      default:
        return value;
    }
  }
};
