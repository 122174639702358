import React, { useState, useCallback } from "react";
import {
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
  Checkbox,
} from "@shopify/polaris";
import { size, filter, values } from "lodash";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { AccountSelectPolaris } from "../../product/divisions/AccountsSelectPolaris";

const Container = styled.div`
  .btns-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;
  }
`;
const FIELDS_REQUIRED = { title: "Division title", storeIDs: "Accounts" };

export const DivisionFormPolaris = ({ onSubmit, toggleShowModal, loading }) => {
  // State
  const [basicFields, setBasicFields] = useState({
    title: null,
    storeIDs: [],
    divideAllAccount: false,
  });
  const [errors, setErrors] = useState({});

  // Handle actions
  const handleSubmit = useCallback(() => {
    for (let [key] of Object.entries(FIELDS_REQUIRED)) {
      validateField(basicFields[key], key);
    }

    let { title, storeIDs } = basicFields;
    let input = {
      ...basicFields,
      onlyRecreateMockup: true,
      enableUpdate: true,
    };

    const count = size(filter(values(errors), (e) => e !== null));
    if (title && storeIDs?.length && count === 0) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicFields]);
  const validateField = useCallback((value, id) => {
    let error = null;
    let label;
    for (let [key, newLabel] of Object.entries(FIELDS_REQUIRED)) {
      if ([key].includes(id)) {
        label = newLabel;
      }
    }

    if (!value || !value.length) {
      error = `${label} is required.`;
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);
  const handleFieldChange = useCallback((value, id) => {
    setBasicFields((prev) => ({ ...prev, [id]: value }));
    validateField(value, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormLayout>
        <div>
          <ComponentLabelPolaris label="Title" required />
          <TextField
            value={basicFields["title"]}
            onChange={handleFieldChange}
            id="title"
            placeholder="Enter division title"
            error={errors["title"]}
          />
        </div>
        <AccountSelectPolaris
          value={basicFields["storeIDs"]}
          onChange={(value) => handleFieldChange(value, "storeIDs")}
          error={errors["storeIDs"]}
        />
        <Checkbox
          label="Divide all matches products to all account"
          checked={basicFields["divideAllAccount"]}
          onChange={(value) =>
            setBasicFields((prev) => ({ ...prev, divideAllAccount: value }))
          }
        />
        <div className="btns-wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={toggleShowModal} />
            <Button
              children="Submit"
              onClick={handleSubmit}
              primary
              loading={loading}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
