import React, { useContext } from "react";
import { Page } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { AppContext } from "../../context";
import { USER_ROLE, TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../variable";

import { ExportAmazonFormPolaris } from "./ExportAmazonFormPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const ExportAmazonAddPolaris = () => {
  const { currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);

  let leader = [
    TEAM_ROLE.Admin,
    TEAM_ROLE.StoreManager,
    TEAM_ROLE.MarketplaceManager,
  ];
  let isLeader = false;
  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }
    if (leader.includes(teamRole)) {
      isLeader = true;
    }
  }

  return isLeader ? (
    <Container>
      <Page
        title="Export for Amazon"
        fullWidth
        breadcrumbs={[
          {
            content: "All Amazon Export Files",
            url: `/${currentParam}/export-amazon`,
          },
        ]}
      >
        <ExportAmazonFormPolaris isNewExport />
      </Page>
    </Container>
  ) : null;
};
