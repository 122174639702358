import { useMutation, useQuery } from "@apollo/react-hooks";
import { ChoiceList, Stack } from "@shopify/polaris";
import { notification } from "antd";
import React from "react";
import { SETTING_VARIABLES } from "../../../constants";
import { useAppContext } from "../../../context";
import { SET_VARIABLE } from "../../../graphql/mutations";
import { GET_VARIABLES } from "../../../graphql/queries";
import { getTeamID, handleError } from "../../../helper";

export default React.forwardRef(function VerifyShippingCost(
  { setLoading },
  ref,
) {
  const [selected, setSelected] = React.useState(["false"]);

  const { currentUser } = useAppContext();
  const teamId = getTeamID(currentUser);

  const variableKey = React.useMemo(
    () => (teamId ? `${teamId}${SETTING_VARIABLES.VSC}` : null),
    [teamId],
  );

  const { data } = useQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
    skip: !variableKey,
  });

  const [setVariables] = useMutation(SET_VARIABLE, {
    onCompleted: (_res) => {
      notification.success({ message: "Update background image success" });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  const changed = React.useRef(false);

  React.useEffect(() => {
    const val = data?.variables?.length > 0 ? data.variables[0] : null;
    if (val?.value) {
      const color = String(val.value).trim();
      if (color.length > 0 && ["true", "false"].includes(color)) {
        setSelected([color]);
      }
    }
  }, [data]);

  const handleSubmit = React.useCallback(async () => {
    if (!changed.current) return;

    setLoading(true);
    await setVariables({
      variables: {
        input: {
          key: variableKey,
          value: selected?.length > 0 ? selected[0] : "false",
        },
      },
    }).finally(() => {
      setLoading(false);
      changed.current = false;
    });
  }, [variableKey, setVariables, setLoading, selected]);

  const handleChange = React.useCallback((value) => {
    setSelected(value);
    changed.current = true;
  }, []);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return (
    <Stack vertical>
      <ChoiceList
        title=" Verify Tiktok Label shipping costs when fees exceed 50% of order revenue."
        choices={[
          { label: "Enable", value: "true" },
          { label: "Disable", value: "false" },
        ]}
        selected={selected}
        onChange={handleChange}
      />
    </Stack>
  );
});
