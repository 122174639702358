import React, { useState, useCallback, useEffect, useRef } from "react";

import { Filters } from "@shopify/polaris";

import FilterUserAccountAdminStatusPolaris from "./FilterUserAccountAdminStatusPolaris";
import FilterUserAccountAdminRolePolaris from "./FilterUserAccountAdminRolePolaris";
import { isEmpty } from "../../../helper";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_ALL_TEAM_USERS_WITH_ROOT_USER,
  GET_TEAM_MEMBERS,
} from "../../../graphql/queries";
import { FilterHasSearchPolaris } from "../../filters/FilterHasSearchPolaris";

export default function FilterUserPolarisAccountAdmin({ onChange, filter }) {
  const [queryValue, setQueryValue] = useState(filter.search);

  const [status, setStatus] = useState({
    value: filter.status,
    label:
      filter.status === true
        ? "Active"
        : filter.status === false
        ? "Inactive"
        : filter.status,
  });

  const [role, setRole] = useState({
    value: filter.role,
    label: filter.role,
  });
  const typingTimeoutRef = useRef(null);

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({
    value: filter.teamId,
    label: null,
    search: null,
  });

  const { data, loading, error } = useQuery(GET_ALL_TEAM_USERS_WITH_ROOT_USER, {
    variables: {
      filter: {
        search: team.search || "",
      },
    },
  });

  useEffect(() => {
    let newData =
      data?.getAllTeamUsersWithRootUser?.length > 0
        ? data.getAllTeamUsersWithRootUser
            .map((tm) => {
              let label = `${tm.name} - ${tm.id}`;
              if (tm.createdBy) {
                label = `${label} (${tm.createdBy})`;
              }
              return {
                value: tm.id,
                label,
              };
            })
            .filter(Boolean)
        : [];

    setTeams(newData);
  }, [data]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange(
          true === status.value
            ? "true"
            : false === status.value
            ? "false"
            : status.value,
          role.value,
          queryValue ? queryValue.trim() : queryValue,
          team.value?.length > 0 ? team.value.trim() : "",
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, role, queryValue, team.value]);

  const handleStatusUserRemove = useCallback(
    () => setStatus({ value: null, label: null }),
    [],
  );

  const handleRoleUserRemove = useCallback(
    () => setRole({ value: null, label: null }),
    [],
  );

  const handleFiltersQueryChange = useCallback((valueCheck) => {
    setQueryValue(valueCheck);
  }, []);

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);

  const handleTeamRemove = useCallback(
    () => setTeam(() => ({ value: null, label: null, search: null })),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleRoleUserRemove();
    handleStatusUserRemove();
    handleQueryValueRemove();
    handleTeamRemove();
  }, [
    handleRoleUserRemove,
    handleTeamRemove,
    handleStatusUserRemove,
    handleQueryValueRemove,
  ]);

  const filters = [
    {
      key: "team",
      label: "Teams",
      filter: (
        <FilterHasSearchPolaris
          value={team}
          options={teams}
          loading={loading}
          error={error}
          onChange={({ value, label }) =>
            setTeam((prev) => ({ ...prev, value, label }))
          }
          onChangeSearch={(search) =>
            setTeam((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      filter: (
        <FilterUserAccountAdminStatusPolaris
          status={
            "true" === filter.status
              ? true
              : "false" === filter.status
              ? false
              : filter.status
          }
          onChange={(value, label) => {
            setStatus((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },
    {
      key: "role",
      label: "Role",
      filter: (
        <FilterUserAccountAdminRolePolaris
          role={role.value}
          onChange={(value, label) => {
            setRole((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (!isEmpty(status.label)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status.label),
      onRemove: handleStatusUserRemove,
    });
  }
  if (!isEmpty(role.value)) {
    const key = "role";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, role.value),
      onRemove: handleRoleUserRemove,
    });
  }
  if (!isEmpty(team.value)) {
    const key = "team";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, team.label),
      onRemove: handleTeamRemove,
    });
  }

  return (
    <div>
      <Filters
        queryValue={queryValue}
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
        queryPlaceholder="Filter user"
      />
    </div>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return `Status: ${value}`;
      case "role":
        return `Role: ${value}`;
      case "team":
        return `Team: ${value}`;
      default:
        return value;
    }
  }
}
