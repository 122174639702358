import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, buttonFrom, Modal, Popover, Stack } from "@shopify/polaris";
import { useMutation, useSubscription } from "@apollo/react-hooks";

import {
    BULK_DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
    CREATE_CUSTOM_MOCKUP_FOR_IDEA_TASK,
    CREATE_TASK_BASE_MOCKUP_FOR_SUBMISSION,
    DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
    REVIEW_IDEA_TASK,
    RE_GEN_MOCKUP_FOR_IDEA_TASK,
    SEND_REVIEW_IDEA_TASK,
    SET_MAIN_TASK_BASE_MOCKUP,
} from "../../../../../graphql/mutations";
import {
    checkRole,
    elementContains,
    genStrToBtoA,
    handleError,
} from "../../../../../helper";
import { omitProduct, omitUser } from "./SubmitToReview2";
import { Mockup2DApp } from "../../../../../config";
import { TASK_STATUS } from "../../utils";
import { useToastContext } from "../../../../shared/ToastContext";
import { useCreateIdea } from "../../context/createIdea";
import { GENERATED_MOCKUP_FOR_IDEA_TASK } from "../../../../../graphql/subscription";
import { IFRAME_MESSAGE_TYPE } from "../../../../../constants";
import { useAppContext } from "../../../../../context";
import useToggle from "../../../../../hooks/useToggle";
import { getProductsHaveSub } from "./ReviewTaskInner";
import { STATUS } from "./ReviewTask";

const STATUS_VALID = [
    TASK_STATUS["Need Review"],
    TASK_STATUS["File Generating"],
];

export function LivePreviewMockup({
    open,
    toggleOpen,
    onClose,
    onCloseParent,
    id,
    handleResubmit,
    taskBaseGroups,
    assignee,
    handleBackToDetail,
    isFileGenerating,
    item,
}) {
    // Context
    const { products } = useCreateIdea();
    const { setNotify, toggleToast, setFilter, refetch } = useToastContext();
    const { currentUser } = useAppContext();
    const { id: userId } = currentUser || {};
    const { isDesignLeader } = checkRole(currentUser);

    // Props
    const assigneeId = assignee?.id;
    const canSubmit = assigneeId === userId;

    // Ref
    const iframeRef = useRef(null);
    const onCloseRef = useRef(null);
    const clearTimeoutRef = useRef(null);
    const taskBaseGroupsRef = useRef(taskBaseGroups);
    taskBaseGroupsRef.current = taskBaseGroups;
    const [openIframeSuccess, setOpenIframeSuccess] = useState(false);
    const [changedLayer, setChangedLayer] = useState(false);

    // Subscription
    const { data } = useSubscription(GENERATED_MOCKUP_FOR_IDEA_TASK);

    // Mutations
    const [sendReview, { loading }] = useMutation(SEND_REVIEW_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: "Submit to review for this task successfully.",
                err: false,
            });

            handleClose();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    const [reGen] = useMutation(RE_GEN_MOCKUP_FOR_IDEA_TASK);
    const [createCustomMockup] = useMutation(
        CREATE_CUSTOM_MOCKUP_FOR_IDEA_TASK,
        {
            onCompleted: (res) => {
                if (res?.createCustomMockupForIdeaTask) {
                }
            },
            onError: () => {},
        }
    );

    const [createMockupSubmission] = useMutation(
        CREATE_TASK_BASE_MOCKUP_FOR_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Create mockups successfully.`,
                    err: false,
                });
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );
    const [deleteTaskBaseMockups] = useMutation(
        DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Delete mockups successfully.`,
                    err: false,
                });
                // handleClose();
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );
    const [bulkDeleteTaskBaseMockups] = useMutation(
        BULK_DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Delete mockups successfully.`,
                    err: false,
                });
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );
    const [setMainMockup] = useMutation(SET_MAIN_TASK_BASE_MOCKUP, {
        onCompleted: () => {
            setNotify({
                msg: `Update main mockup successfully.`,
                err: false,
            });
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    const [reviewTask, { loading: loadingR }] = useMutation(REVIEW_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: "Approved to this tasks successfully.",
                err: false,
            });
            handleClose();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Actions
    useEffect(() => {
        if (!openIframeSuccess) return;
        if (taskBaseGroups?.length > 0 && products?.length > 0) {
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                const baseGroups = omitProduct(
                    (taskBaseGroups || []).filter((i) =>
                        STATUS_VALID.includes(i.status)
                    ),
                    products,
                    true
                );

                const newBaseGroups = getProductsHaveSub(baseGroups);
                const baseGroupString = JSON.stringify(newBaseGroups);
                // const msg = window.btoa(baseGroupString);
                const msg = genStrToBtoA(baseGroupString);
                if (!msg || msg.length === 0) return;

                const type = IFRAME_MESSAGE_TYPE.SendReview;

                const msgBtoA = genStrToBtoA(
                    JSON.stringify({ type, message: msg })
                );
                // const msgBtoA = window.btoa(
                //     JSON.stringify({ type, message: msg })
                // );
                iframeRef.current &&
                    iframeRef.current.contentWindow.postMessage(msgBtoA, "*");
            }, 100);
        }
    }, [openIframeSuccess, products, taskBaseGroups]);

    const handleSendData = useCallback((open) => {
        setOpenIframeSuccess(open);
    }, []);

    const checkChanged = useCallback(
        (cb) => {
            if (changedLayer) {
                const confirm = window.confirm(
                    "It looks like you have been editing something. If you leave before saving, your changes will be lost."
                );
                if (!confirm) return;
            }
            cb && cb();
        },
        [changedLayer]
    );

    const handleClose = useCallback(() => {
        toggleOpen(false);
        onClose && onClose(false);
        onCloseParent && onCloseParent(false);

        clearTimeoutRef.current && clearTimeout(clearTimeoutRef.current);
        clearTimeoutRef.current = setTimeout(() => {
            refetch && refetch();
            setFilter && setFilter((prev) => ({ ...prev }));
        }, 1000);
    }, [toggleOpen, onClose, onCloseParent, setFilter, refetch]);

    const handleSubmitToReview = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify({ msg: null, err: false });
            sendReview({
                variables: {
                    id,
                },
            });
        }
    }, [sendReview, id, setNotify, toggleToast]);

    // Markup
    const iframeStyle = {
        border: "none",
        width: "100%",
        height: "calc(100% - 0.5rem)",
    };

    useEffect(() => {
        let domCD = document.querySelector("#submit-to-review");
        let domModal = document.querySelectorAll(".Polaris-Modal-Section");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );

        for (let domChild of domModal) {
            if (elementContains(domChild, domCD)) {
                domChild.setAttribute("style", "padding: 0; height: 100%");
            }
        }

        for (let domParent of domParents) {
            if (elementContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100vw - 2rem); height: 100vh; max-height: calc(100vh - 2rem);"
                );
            }
        }
    });

    // Actions
    const handleReviewTask = useCallback(() => {
        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });

        const cloneProducts = [...(taskBaseGroupsRef.current || [])].filter(
            (i) => i.status !== TASK_STATUS.Approved
        );

        const baseGroups = genInput(cloneProducts);

        if (baseGroups?.length === 0) {
            setNotify({
                msg: "Select least at one item to handle.",
                err: true,
            });
            return;
        }

        if (id != null) {
            const input = {
                ideaTaskId: id,
                baseGroups,
                status: STATUS.Accept,
            };

            reviewTask({ variables: { input } });
        }
    }, [id, reviewTask, toggleToast, setNotify]);

    const handleRegenerate = useCallback(
        (baseGroups) => {
            if (id != null) {
                const input = {
                    ideaId: id,
                    baseGroups,
                };

                reGen({
                    variables: {
                        input,
                    },
                })
                    .then((res) => {
                        setChangedLayer(false);
                        if (res?.data?.reGenMockupForIdeaTask != null) {
                            const msg = res.data.reGenMockupForIdeaTask;
                            if (!msg || msg.length === 0) return;

                            const type = IFRAME_MESSAGE_TYPE.Regenerate;
                            // const msgBtoA = window.btoa(
                            //     JSON.stringify({ type, message: msg })
                            // );

                            const msgBtoA = genStrToBtoA(
                                JSON.stringify({ type, message: msg })
                            );
                            iframeRef.current &&
                                iframeRef.current.contentWindow.postMessage(
                                    msgBtoA,
                                    "*"
                                );
                        }
                    })
                    .catch(() => {});
            }
        },
        [id, reGen]
    );

    const handleCreateCustomMockup = useCallback(
        (input) => {
            if (input != null) {
                createCustomMockup({
                    variables: { ...input },
                });
            }
        },
        [createCustomMockup]
    );

    const deleteMockups = useCallback(
        (input) => {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            deleteTaskBaseMockups({
                variables: {
                    ...input,
                },
            });
        },
        [deleteTaskBaseMockups, toggleToast, setNotify]
    );

    const chooseOtherTemplate = useCallback(
        (input) => {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            createMockupSubmission({
                variables: {
                    ...input,
                },
            });
        },
        [createMockupSubmission, toggleToast, setNotify]
    );

    const bulkDeleteMockups = useCallback(
        (input) => {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            bulkDeleteTaskBaseMockups({
                variables: {
                    ...input,
                },
            });
        },
        [toggleToast, setNotify, bulkDeleteTaskBaseMockups]
    );

    const handleUpdateMainMockup = useCallback(
        (ids) => {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            if (ids?.length > 0) {
                (async function () {
                    await Promise.all(
                        ids.map(async (id) => {
                            try {
                                const value = await setMainMockup({
                                    variables: {
                                        id,
                                    },
                                });
                                return value;
                            } catch (err) {
                                return handleError(err.toString());
                            }
                        })
                    );
                    if (isDesignLeader) {
                        handleReviewTask();
                    } else {
                        handleSubmitToReview();
                    }
                })();
                return;
            }
            if (isDesignLeader) {
                handleReviewTask();
            } else {
                handleSubmitToReview();
            }
        },
        [
            setMainMockup,
            isDesignLeader,
            handleReviewTask,
            handleSubmitToReview,
            setNotify,
            toggleToast,
        ]
    );

    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            if (typeof data === "object" && "source" in data) return;

            const objData = typeof data === "string" ? JSON.parse(data) : null;
            if (objData != null) {
                const { type, baseGroups, input, open } = objData;
                switch (type) {
                    case IFRAME_MESSAGE_TYPE.Regenerate: {
                        if (baseGroups?.length > 0) {
                            handleRegenerate(baseGroups);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.CreateCustomMockup: {
                        handleCreateCustomMockup(input);
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.OpenSuccess: {
                        handleSendData(open);
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.ChangedLayer: {
                        setChangedLayer(true);
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.DeleteMockups: {
                        if (input != null) {
                            deleteMockups(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.ChooseOtherTemplate: {
                        if (input != null) {
                            chooseOtherTemplate(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.BulkDeleteMockups: {
                        if (input != null) {
                            bulkDeleteMockups(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.GetMainMockupId: {
                        handleUpdateMainMockup(input);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [
            handleRegenerate,
            handleCreateCustomMockup,
            bulkDeleteMockups,
            chooseOtherTemplate,
            deleteMockups,
            handleSendData,
            handleUpdateMainMockup,
        ]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage, false);
        };
    }, [handleReceiveMessage]);

    useEffect(() => {
        if (data?.subscriptionGeneratedMockupForIdeaTask != null) {
            const msg = data.subscriptionGeneratedMockupForIdeaTask;
            const newData = {
                type: IFRAME_MESSAGE_TYPE.Subscriptions,
                message: msg,
            };

            // const msgBtoA = window.btoa(JSON.stringify(newData));
            const msgBtoA = genStrToBtoA(JSON.stringify(newData));

            iframeRef.current &&
                iframeRef.current.contentWindow.postMessage(msgBtoA, "*");
        }
    }, [data]);

    const userInfo = omitUser();
    const userInfoString = JSON.stringify(userInfo);
    const userInfoProp = genStrToBtoA(userInfoString);

    const cancelAction = {
        content: "Close",
        onAction: () => checkChanged(handleClose),
    };
    let primaryAction = null;
    let reUploadFilesAction = null;
    if (canSubmit) {
        // TODO:
        const content = isDesignLeader ? "Approve" : "Submit to review";
        primaryAction = {
            content,
            onAction: () => {
                function onSubmit() {
                    const newData = {
                        type: IFRAME_MESSAGE_TYPE.GetMainMockupId,
                    };
                    const msgBtoA = genStrToBtoA(JSON.stringify(newData));
                    iframeRef.current &&
                        iframeRef.current.contentWindow.postMessage(
                            msgBtoA,
                            "*"
                        );
                }
                checkChanged(onSubmit);
            },
            loading: loading || loadingR,
        };

        if (handleResubmit != null && !isDesignLeader) {
            reUploadFilesAction = {
                content: "Re-upload Files",
                onAction: () => checkChanged(handleResubmit),
            };
        }
    }

    const footerMarkup = (
        <Stack spacing="tight" distribution="trailing">
            <Stack.Item fill>{buttonFrom(cancelAction)}</Stack.Item>
            {isFileGenerating &&
                buttonFrom({
                    content: "View Detail",
                    onAction: () => checkChanged(handleBackToDetail),
                })}
            {reUploadFilesAction && buttonFrom(reUploadFilesAction)}
            {primaryAction && buttonFrom(primaryAction, { primary: true })}
        </Stack>
    );

    return (
        <Modal
            title="Preview Mockup"
            open={open}
            onClose={() => checkChanged(handleClose)}
            sectioned
            footer={footerMarkup}
        >
            <iframe
                ref={iframeRef}
                id="submit-to-review"
                title="Review Design"
                // src={`http://localhost:3000/design?user=${userInfoProp}&pathname=design`}
                src={`${Mockup2DApp}/design?user=${userInfoProp}&pathname=design`}
                style={iframeStyle}
            />
        </Modal>
    );
}

export function GenButton({ action, overrides, changedLayer }) {
    // State
    const [open, toggleOpen] = useToggle(false);

    if (Object.keys(action).length === 0) return null;
    const onAction = changedLayer ? toggleOpen : action.onAction;
    const loading = changedLayer ? false : action.loading;
    const activator = buttonFrom({ ...action, onAction, loading }, overrides);

    const btnMarkup = (
        <Popover
            activator={activator}
            active={open}
            onClose={toggleOpen}
            sectioned
        >
            <Stack vertical>
                <p>
                    It looks like you have been editing something. If you leave
                    before saving, your changes will be lost.
                </p>
                <Button
                    children="Continue"
                    onClick={action.onAction}
                    loading={action.loading}
                />
            </Stack>
        </Popover>
    );

    return btnMarkup;
}

const Pending = TASK_STATUS.Pending;
const Reject = "Reject";

function genInput(group) {
    if (!group || group.length === 0) return [];

    const result = group.map((g) => {
        const { id, baseConfigs } = g || {};

        const newBaseConfigs = (baseConfigs || [])
            .map((cf) => {
                const { taskBaseSubmissions } = cf || {};
                const [taskBaseSubmission] = taskBaseSubmissions || [];
                const { id: submissionId, taskBaseConfigId } =
                    taskBaseSubmission || {};

                if (![Pending, Reject].includes(taskBaseSubmission?.status))
                    return null;

                return {
                    taskBaseConfigId,
                    submissionId,
                };
            })
            .filter(Boolean)
            .filter((i) => i.submissionId != null);

        return {
            taskBaseGroupId: id,
            baseConfigs: newBaseConfigs,
        };
    });

    return result;
}
