import React, { useCallback, useState, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { get } from "lodash";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { FETCH_MERCHIZE_TRACKING } from "../../../graphql/mutations";

export const FetchTrackingPolaris = (props) => {
    const { value, open, toggleShowModal, refetch } = props;
    const orderId = get(value, "fulfillmentOrders[0].id");

    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [notification, setNotification] = useState({
        message: null,
        error: false,
    });
    // useLockBodyScroll(open);

    const [fetchMerchizeTracking, { loading }] = useMutation(
        FETCH_MERCHIZE_TRACKING,
        {
            onError: (error) => {
                setNotification({
                    message: handleError(error.toString()),
                    error: true,
                });
            },
            onCompleted: () => {
                setNotification({
                    message: "Fetch tracking success.",
                    erorr: false,
                });
                const id = setTimeout(() => {
                    toggleShowModal(false);
                    if (refetch) {
                        refetch();
                    }
                }, 2100);
                setTimeoutId(id);
            },
        }
    );

    const handleSubmit = useCallback(() => {
        if (orderId) {
            fetchMerchizeTracking({
                variables: {
                    fulfillmentOrderIDs: [orderId],
                },
            });
        }
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(
        () => setActiveToast((activeToast) => !activeToast),
        []
    );

    const toastMarkup = activeToast
        ? notification &&
          notification.message && (
              <Toast
                  content={notification.message}
                  error={notification.error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            <Modal
                title="Fetch tracking"
                open={open}
                onClose={toggleShowModal}
                sectioned
                secondaryActions={[
                    { content: "Cancel", onAction: toggleShowModal },
                ]}
                primaryAction={{
                    content: "Fetch",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <p>Do you want to fetch tracking for this order?</p>
            </Modal>
        </>
    );
};
