import React from "react";
import { Avatar } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  width: 220px;
  display: flex;
  flex-wrap: wrap;
`;

const StoreContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transform: ${(props) =>
    props.index
      ? `translateX(-${(props.index % 5) * 12}px)`
      : "translateX(0px)"};
  .Polaris-Avatar {
    border: 2px solid #fff;
    width: calc(4rem + 4px);
  }
  .tolltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    z-index: 2;
    .tolltip {
      visibility: visible;
    }
  }
`;

export const ColAccountPolaris = (props) => {
  const { stores } = props;
  return (
    <Container>
      {stores && stores.length > 0
        ? stores.map((s, idx) => {
            let title = s.title && s.title.trim();
            if (!title) {
              title = "M";
            }
            return (
              <StoreContainer key={idx} index={idx}>
                <Avatar initials={title.charAt(0)} />
                <span className="tolltip">{s.title}</span>
              </StoreContainer>
            );
          })
        : null}
    </Container>
  );
};
