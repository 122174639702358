import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { TIKTOK_FETCH_ORDERS_BY_STORE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export default function FetchOrders({ open, onClose, store }) {
  const [fetchOrdersByStore, { loading }] = useMutation(
    TIKTOK_FETCH_ORDERS_BY_STORE,
  );

  const { toggleToast, setNotify } = useToastContext();

  const handleSubmit = React.useCallback(() => {
    if (!store.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ err: false, msg: null });
    fetchOrdersByStore({
      variables: {
        storeID: store.id,
      },
    })
      .then(() => {
        setNotify({ err: false, msg: "Fetch orders success" });
      })
      .catch((err) => {
        setNotify({ err: true, msg: handleError(err.toString()) });
      })
      .finally(() => {
        onClose && onClose();
      });
  }, [fetchOrdersByStore, store.id, onClose, toggleToast, setNotify]);

  return (
    <Modal
      title="Fetch orders"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{ content: "Fetch", onAction: handleSubmit, loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>
        Are you sure to fetch orders from store <strong>{store.name}</strong>
      </p>
    </Modal>
  );
}
