import { Button } from "@shopify/polaris";
import get from "lodash/get";
import React, { Fragment, useCallback } from "react";
import { useAppContext } from "../../../context";
import { arrInvalid, reducerFn } from "../../../helper";
import useTimeout from "../../../hooks/useTimeout";
import { ORDER_STATUS, TEAM_ROLE } from "../../../variable";
import { BulkActions as BulkActs } from "../../shared/BulkActions";
import SuspenseComp from "../../shared/SuspenseComp";

const SwitchStoreModal = React.lazy(() => import("./SwitchStoreModal"));
const ResubmitOrders = React.lazy(() => import("./ReSubmitOrders"));
const BuyTiktokLabel = React.lazy(() => import("./BuyTiktokLabel"));

export function BulkActions({
  idsChecked,
  handleCheckedAll,
  handleCompleted,
  status,
  ids,
}) {
  // Context
  const { currentUser } = useAppContext();
  const role = get(currentUser, "teamUser.role");

  // Actions
  const [setTimeoutRef] = useTimeout();
  const onCompleted = useCallback(() => {
    setTimeoutRef(() => {
      handleCompleted && handleCompleted();
    }, 1500);
  }, [handleCompleted]);

  const [state, setState] = React.useReducer(reducerFn, {
    action: null,
    open: false,
  });

  const handleActionTrigger = React.useCallback(
    (key) => () => {
      setState({ action: key, open: true });
    },
    [],
  );

  const actions = React.useMemo(() => {
    const fn = genAction({ status, role, onAction: handleActionTrigger });
    return ACTIONS.map(fn).filter(Boolean);
  }, [status, role, handleActionTrigger]);

  const handleClose = React.useCallback(() => {
    setState({ open: false, action: null });
  }, []);

  const actionsMarkup = React.useMemo(() => {
    const props = {
      ids: idsChecked,
      open: state.open,
      orderIDs: ids,
      onClose: handleClose,
      handleCompleted: onCompleted,
    };

    return genActionsMarkup(actions, props);
  }, [idsChecked, ids, state.open, handleClose, onCompleted]);

  const ActionObj = state.action ? actionsMarkup[state.action] : null;

  return actions?.length > 0 ? (
    <Fragment>
      <BulkActs idsChecked={idsChecked} onClose={() => handleCheckedAll(false)}>
        {actions.map((act) => {
          return (
            <Button
              children={act.content}
              onClick={act.onAction}
              key={`action-${act.key}`}
            />
          );
        })}
      </BulkActs>
      <SuspenseComp fallback={null}>
        {ActionObj ? <ActionObj.comp {...ActionObj.props} /> : null}
      </SuspenseComp>
    </Fragment>
  ) : null;
}

const genActionsMarkup = (actions, props) => {
  return actions.reduce((acc, action) => {
    if (!action || !action.key || !action.comp) return acc;
    acc[action.key] = {
      ...action,
      props,
    };

    return acc;
  }, {});
};

const genAction =
  ({ status, role, onAction }) =>
  (item) => {
    let sValid = true;
    let rValid = true;
    if (!arrInvalid(item.status)) sValid = item.status.includes(status);
    if (!arrInvalid(item.roles)) rValid = item.roles.includes(role);

    if (!sValid || !rValid) return false;
    return {
      ...item,
      onAction: onAction(item.key),
    };
  };

const ACTIONS = [
  {
    content: "Switch Store for Orders",
    key: "switchStore",
    status: [
      ORDER_STATUS.Error,
      ORDER_STATUS.PendingDesign,
      ORDER_STATUS.Pending,
      ORDER_STATUS.OnHold,
      ORDER_STATUS.PendingPayment,
    ],
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager],
    comp: SwitchStoreModal,
  },
  {
    content: "Re-submit to Fulfillment",
    key: "resubmit",
    status: [ORDER_STATUS.Error],
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager],
    comp: ResubmitOrders,
  },
  {
    content: "Buy Label",
    key: "buyLabel",
    status: [
      ORDER_STATUS.Pending,
      ORDER_STATUS.PendingDesign,
      ORDER_STATUS.Processing,
      ORDER_STATUS.OnHold,
      ORDER_STATUS.Error,
    ],
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.Supplier],
    comp: BuyTiktokLabel,
  },
];
