import React, { useCallback, useState } from "react";
import { Loading, Page, Card } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import styled from "styled-components";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { handleError } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

import { Filter } from "./Filter";
import { DataTableComp } from "./DataTable";
import { ProductDuplicateProvider } from "./context";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const PRODUCTS_BY_TITLES = gql`
    query productsByTitles($titles: [String!]) {
        productsByTitles(titles: $titles) {
            total
            nodes {
                id
                mainImageId
                images {
                    file {
                        id
                        url
                        thumbnailUrl
                    }
                }
                mainImage {
                    id
                    url
                    thumbnailUrl
                }
                title
                sku
            }
        }
    }
`;

export function ProductDuplicate() {
    // State
    const [filter, setFilter] = useState({
        titles: [""],
    });

    // Query
    const { data, loading, error } = useQuery(PRODUCTS_BY_TITLES, {
        variables: {
            ...filter,
        },
    });

    // Loading
    const loadingMarkup = loading && <Loading />;

    // Handle action
    const handleChange = useCallback(({ titles }) => {
        setFilter((prev) => ({ ...prev, titles }));
    }, []);

    return (
        <Container>
            <Page fullWidth title="Product by Tittle">
                {loadingMarkup}
                <Card sectioned>
                    <Filter filter={filter} onChange={handleChange} />
                </Card>
                <Card>
                    <ProductDuplicateProvider filter={filter}>
                        {loading ? (
                            <SkeletonPagePolaris />
                        ) : error ? (
                            <div>Error: {handleError(error.toString())}</div>
                        ) : data?.productsByTitles?.nodes.length > 0 ? (
                            <DataTableComp data={data} />
                        ) : (
                            <EmptyStatePolaris />
                        )}
                    </ProductDuplicateProvider>
                </Card>
            </Page>
        </Container>
    );
}
