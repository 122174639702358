import get from "lodash/get";
import { useAppContext } from "../context";
import { arrInvalid } from "../helper";

const PRIVILEGES = {
  Create: "create_user",
  Edit: "edit_user",
  ActiveOrDeactive: "active_deactive_user",
  Set: "set_user_privilege",
};

const usePrivileges = () => {
  const { currentUser } = useAppContext();
  const isRootUser = get(currentUser, "isRootUser") || false;
  const privileges = get(currentUser, "privileges") || [];

  const fn = (type) => {
    return isRootUser
      ? true
        ? arrInvalid(privileges)
        : false
      : privileges.includes(type);
  };

  return {
    canCreate: fn(PRIVILEGES.Create),
    canEdit: fn(PRIVILEGES.Edit),
    canActiveOrDeactive: fn(PRIVILEGES.ActiveOrDeactive),
    canSetPrivileges: fn(PRIVILEGES.Set),
  };
};

export { usePrivileges };
