import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState, useMemo } from "react";
import { REPORT_IDEA_TASK_BY_STATUS } from "../../../graphql/queries";
import { useReportsContext } from "../context";
import { Stack, TextStyle } from "@shopify/polaris";

const LABELS = {
  all: "All Tasks",
  cancel: "Cancel",
  declined: "Declined",
  denied: "Denied",
  doing: "Doing",
  done: "Done",
  draft: "Draft",
  mockup_generating: "Mockup Generating",
  need_review: "Need review",
  partially_completed: "Partially Completed",
  pending: "Pending",
  rejected: "Rejected",
  unassigned: "Un-assigned",
};

export function IdeaTasksByStatus() {
  // Context
  const { range, filter: filterCtx } = useReportsContext();
  const { collectionIds, tagIds } = filterCtx || {};

  // State
  const [filter, setFilter] = useState({
    search: null,
    filterByTime: range,
    tagIds,
    collectionIds,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalIdeas: 0,
  });

  // Query
  const { data } = useQuery(REPORT_IDEA_TASK_BY_STATUS, {
    variables: {
      filter,
    },
  });

  useEffect(() => {
    if (filterCtx != null) {
      let ignoreFilter = filterCtx;
      if ("productBaseIds" in filterCtx || "viewWholeTeamReport" in filterCtx) {
        let productBaseIds, viewWholeTeamReport;
        ({ productBaseIds, viewWholeTeamReport, ...ignoreFilter } = filterCtx);
      }
      setFilter((prev) => ({ ...prev, ...ignoreFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      collectionIds,
      tagIds,
    }));
  }, [range, collectionIds, tagIds]);

  useEffect(() => {
    const agg = data?.reportIdeaTaskByStatus?.aggregation;
    if (agg != null) {
      const sortAgg = {};
      sortAgg.all = agg.all;
      sortAgg.cancel = agg.cancel;
      sortAgg.declined = agg.declined;
      sortAgg.denied = agg.denied;
      sortAgg.doing = agg.doing;
      sortAgg.done = agg.done;
      sortAgg.draft = agg.draft;
      sortAgg.mockup_generating = agg.mockup_generating;
      sortAgg.need_review = agg.need_review;
      sortAgg.partially_completed = agg.partially_completed;
      sortAgg.pending = agg.pending;
      sortAgg.rejected = agg.rejected;
      sortAgg.unassigned = agg.unassigned;

      const result = Object.entries(sortAgg).reduce((acc, [key, value]) => {
        const item = {
          label: LABELS[key],
          value,
        };

        return LABELS[key] != null ? acc.concat(item) : acc;
      }, []);

      setState((prev) => ({
        ...prev,
        items: result,
        total: sortAgg.all,
      }));
    }
  }, [data]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items.map((item, index) => (
      <Stack key={`task-${index}`}>
        <Stack.Item fill>{item.label}</Stack.Item>
        <span>{item.value}</span>
      </Stack>
    ));
  }, [state.items]);
  const headMarkup = (
    <Stack>
      <Stack.Item fill>
        <TextStyle variation="strong">Status</TextStyle>
      </Stack.Item>
      <TextStyle variation="strong">Count</TextStyle>
    </Stack>
  );
  // const subTitle = <span>{state.total} Tasks</span>;
  // const headerMarkup = useMemo(
  //     () => (
  //         <Stack spacing="loose">
  //             <Stack.Item fill>
  //                 <Heading>Tasks</Heading>
  //             </Stack.Item>
  //             <span>{subTitle}</span>
  //         </Stack>
  //     ),
  //     [subTitle]
  // );

  return (
    <div style={{ marginTop: "3rem" }}>
      <Stack vertical spacing="loose">
        {headMarkup}
        {contentMarkup}
      </Stack>
    </div>
  );
}
