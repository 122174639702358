import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { Input, Skeleton, Table, Card } from "antd";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import {
  handleError,
  setCookie,
  getCookie,
  convertStringToObject,
  convertObjectToParams,
} from "../../helper";
import SupplierTransactionsFilter from "../../components/supplier/SupplierTransactionsFilter";
import Stats from "../../components/billings/Stats";
import _ from "lodash";
import history from "../../history";

const LOAD = gql`
  query transactons($filter: TransactionFilter) {
    transactions(filter: $filter) {
      total
      nodes {
        id
        amount
        createdAt
        journal {
          id
          refId
          comment
          status
          type
        }
        account {
          id
          firstName
          lastName
        }
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ACTIONS = [
  { key: "Order", name: "Paid For Item" },
  { key: "Refund", name: "Refund" },
];
const DEFAULT_FILTER = {
  limit: 30,
  offset: 0,
  status: null,
  search: null,
  kind: ACTIONS.map((action) => action.key),
};

class SupplierTransactionsPage extends Component {
  state = {
    filter: {
      ...DEFAULT_FILTER,
      limit: +getCookie("perPageTransactions") || 30,
      ...convertStringToObject(history.location.search),
    },
    search: "",
    page: 1,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      const params = convertObjectToParams({
        limit: nextState.filter.limit,
        offset: nextState.filter.offset,
        search: nextState.filter.search,
      });
      history.push(history.location.pathname + "?" + params);
    }
  }

  render() {
    const { filter } = this.state;
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 120,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: 100,
        render: (_, record) =>
          record.amount < 0
            ? `-$${Math.abs(record.amount)}`
            : `$${record.amount}`,
      },
      {
        title: "Action",
        dataIndex: ["journal", "type"],
        width: 120,
      },
      {
        title: "Note",
        dataIndex: ["journal", "comment"],
        width: 250,
      },
      {
        title: "Status",
        dataIndex: ["journal", "status"],
        width: 120,
      },
      {
        title: "Created",
        width: 90,
        render: (_, record) => (
          <div>{moment(record.createdAt).format("LL")}</div>
        ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div>
        <PageTitle title={"Transactions"} />
        <Stats />
        <Card bodyStyle={{ padding: 0 }}>
          <div className="p-4">
            <Input.Search
              defaultValue={filter.search}
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      search: null,
                    },
                  });
                }
              }}
              onSearch={(v) =>
                this.setState({ filter: { ...filter, search: v } })
              }
              placeholder={"Search transactions"}
              addonBefore={
                <SupplierTransactionsFilter
                  value={{
                    status: this.state.filter.status,
                    kind: this.state.filter.kind,
                  }}
                  onChange={(v) => {
                    this.setState({
                      filter: {
                        ...this.state.filter,
                        status: v.status,
                        kind: v.kind,
                      },
                    });
                  }}
                />
              }
            />
          </div>
          <div className={"content"}>
            <Query
              query={LOAD}
              variables={{
                filter,
              }}
            >
              {({ error, loading, data }) => {
                if (error)
                  return <div>Error: {handleError(error.toString())}</div>;
                if (loading)
                  return (
                    <div className="p-4">
                      <Skeleton active />
                    </div>
                  );
                return (
                  <Table
                    pagination={{
                      current: filter.offset / filter.limit + 1,
                      pageSize: filter.limit,
                      total: data.transactions.total,
                      onChange: (page, pageSize) => {
                        this.setState({
                          page: page,
                          filter: {
                            ...filter,
                            offset: pageSize * (page - 1),
                          },
                        });
                      },
                      onShowSizeChange: (_, size) => {
                        this.setState({
                          page: 1,
                          filter: {
                            ...filter,
                            limit: size,
                            offset: 0,
                          },
                        });
                        setCookie("perPageTransactions", size, 100);
                      },
                    }}
                    dataSource={data.transactions.nodes}
                    rowKey={(_, index) => index}
                    columns={columns}
                    scroll={{ x: tableWidth }}
                  />
                );
              }}
            </Query>
          </div>
        </Card>
      </div>
    );
  }
}

export default SupplierTransactionsPage;
