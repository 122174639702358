import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback } from "react";
import { GET_TEAM_STATISTICS } from "../../../graphql/queries";

export function useTeamStatistics() {
  const [get, { data, loading, error, fetchMore }] = useLazyQuery(GET_TEAM_STATISTICS);

  const getActions = useCallback(
    (filter) => {
      get({
        variables: {
          filter,
        },
      });
    },
    [get]
  );

  return [getActions, { data, loading, error, fetchMore }];
}
