import React, { useState, useCallback, useEffect } from "react";
import { Stack, RadioButton, TextContainer, Button } from "@shopify/polaris";

import { CollectionConditionsPolaris } from "./CollectionConditionsPolaris";

export const CollectionTypePolaris = ({ onChange }) => {
  const [value, setValue] = useState("all-conditions");
  const [conditions, setConditions] = useState([
    {
      field: "title",
      operator: "Equal",
      value: "",
    },
  ]);

  useEffect(() => {
    let formatValue = "all-conditions" === value ? false : true;
    if (onChange) {
      onChange({
        anyCondition: formatValue,
        conditions: conditions.filter((c) => c.value),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, conditions]);

  const handleConditionsChange = useCallback(
    (__, newValue) => setValue(newValue),
    []
  );

  const handleAddCondition = useCallback(() => {
    setConditions((prevState) => {
      return [
        ...prevState,
        {
          field: "title",
          operator: "Equal",
          value: "",
        },
      ];
    });
  }, []);

  const handleClickRemove = useCallback(
    (idx) => {
      let newValue = conditions.filter((__, index) => index !== idx);
      setConditions(newValue);
    },
    [conditions]
  );

  const handleOnChange = useCallback(
    (value, index) => {
      let newValue = conditions.map((c, idx) => {
        if (idx === index) {
          return value;
        }
        return c;
      });
      setConditions(newValue);
    },
    [conditions]
  );

  return (
    <TextContainer>
      <Stack vertical>
        <Stack alignment="center">
          <p>Products must match:</p>
          <Stack alignment="center">
            <RadioButton
              label="all conditions"
              checked={value === "all-conditions"}
              id="all-conditions"
              name="conditions"
              onChange={handleConditionsChange}
            />
            <RadioButton
              label="any conditions"
              checked={value === "any-conditions"}
              id="any-conditions"
              name="condtions"
              onChange={handleConditionsChange}
            />
          </Stack>
        </Stack>
        <Stack alignment="fill" distribution="fillEvenly" vertical>
          <TextContainer>
            {conditions.map((c, idx) => (
              <CollectionConditionsPolaris
                condition={c}
                key={idx}
                showBtnDelete={conditions.length > 1 ? true : false}
                handleClickRemove={handleClickRemove}
                index={idx}
                onChange={(value, index) => handleOnChange(value, index)}
              />
            ))}
            <div>
              <Button onClick={handleAddCondition}>
                Add another condition
              </Button>
            </div>
          </TextContainer>
        </Stack>
      </Stack>
    </TextContainer>
  );
};
