import React, { useState, useEffect, useCallback, useContext } from "react";
import { Modal, FormLayout, Select, TextField, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

import { handleError } from "../../../helper";
import { AppContext } from "../../../context";
import history from "../../../history";
import { TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../../variable";
import { UploadFilePolaris } from "../../file/UploadFilePolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { LOAD_CLAIMS } from "../claims/ListClaimPolaris";
import { CREATE_CLAIM } from "../../../graphql/mutations";
// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";

const TYPE_OPTIONS = [
    { value: "Need Resend", label: "Need Resend" },
    { value: "Need Refund", label: "Need Refund" },
];

export const AddClaimPolaris = (props) => {
    // Props
    const { open, toggleShowModal, value } = props;
    const orderId = value?.id;
    // useLockBodyScroll(open);

    const [type, setType] = useState("Need Resend");
    const [note, setNote] = useState("");
    const [files, setFiles] = useState([]);
    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const { currentUser } = useContext(AppContext);

    let currentParam = TEAM_ROLE_PARAMS.Seller;
    const role = _.get(currentUser, "teamUser.role");
    if ([TEAM_ROLE.StoreManager].includes(role)) {
        currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
        currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }

    const [createClaim, { data, loading, error, client }] = useMutation(
        CREATE_CLAIM,
        {
            onError: () => {},
            onCompleted: (res) => {
                if (res) {
                    const claim = res.createClaim;
                    handleAddClaim(claim);
                    const id = setTimeout(() => {
                        toggleShowModal();
                        history.push(`/${currentParam}/claims`);
                    }, 2100);
                    setTimeoutId(id);
                }
            },
        }
    );

    const handleAddClaim = useCallback((claim) => {
        try {
            const variables = {
                filter: {
                    limit: 30,
                    offset: 0,
                    status: null,
                    search: "",
                    type: null,
                },
            };
            const cache = client.readQuery({
                query: LOAD_CLAIMS,
                variables,
            });

            client.writeQuery({
                query: LOAD_CLAIMS,
                variables,
                data: {
                    ...cache,
                    claims: {
                        ...cache.claims,
                        total: cache.claims.total + 1,
                        nodes: [claim, ...cache.claims.nodes],
                    },
                },
            });
        } catch (e) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTypeChange = useCallback((value) => setType(value), []);
    const handleNoteChange = useCallback((value) => {
        setNote(value);
    }, []);
    const handleImgChange = useCallback((value) => {
        let ids = value && value.length > 0 ? value.map((i) => i.id) : [];
        setFiles(ids);
    }, []);

    const handleSubmit = useCallback(() => {
        let input = {
            orderId,
            type,
            note,
            files,
        };

        if (orderId && type) {
            createClaim({
                variables: {
                    input,
                },
            });
            toggleActive();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, note, files, orderId]);

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(
        () => setActiveToast((activeToast) => !activeToast),
        []
    );

    const toastMarkup = activeToast
        ? (error || (data && data.createClaim)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data && data.createClaim && `Add claim success.`
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            <Modal
                title={`Claim order: #${orderId ? orderId : ""}`}
                open={open}
                onClose={toggleShowModal}
                sectioned
                secondaryActions={[
                    { content: "Cancel", onAction: toggleShowModal },
                ]}
                primaryAction={{
                    content: "Save",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <div id="add-claim_modal">
                    <FormLayout>
                        <div>
                            <Select
                                options={TYPE_OPTIONS}
                                onChange={handleTypeChange}
                                value={type}
                                label={"Type"}
                            />
                        </div>
                        <div>
                            <TextField
                                value={note}
                                label="Note"
                                onChange={handleNoteChange}
                                multiline={3}
                            />
                        </div>
                        <div>
                            <ComponentLabelPolaris label="Evidence" />
                            <UploadFilePolaris
                                onChange={handleImgChange}
                                // accept={"image/*"}
                                // type="image"
                            />
                        </div>
                    </FormLayout>
                </div>
            </Modal>
        </>
    );
};
