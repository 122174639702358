import { Mutation, Query } from "@apollo/react-components";
import { notification, Skeleton } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import AddProductForm from "../../components/seller/AddProductForm";
import { AppContext } from "../../context";
import { convertStringToObject, getBaseTitle, getParam, handleError } from "../../helper";
import history from "../../history";
import { QUERY_DP_BY_BASE_SELECTED } from "./AddCampaignProduct";

const LOAD_QUERY = gql`
  query load($id: ID!) {
    productBasesForSeller(filter: { limit: 1000, offset: 0 }) {
      total
      nodes {
        id
        title
        slug
        baseCostDescription
        defaultContent
        defaultShortDescription
        details
        regularPrice
        salePrice
        sellerPrice
        teamId
        images {
          id
          name
          url
          thumbnailUrl
          createdAt
        }
        suppliers {
          id
          firstName
          lastName
        }
        carriers {
          id
          code
          name
        }
        categories {
          id
          name
          description
        }
        carrierPricing {
          id
          carrierId
          price
        }
        attributes {
          name
          slug
          options
        }
        designPositions {
          id
          image {
            id
            thumbnailUrl
            url
            createdAt
          }
          name
          displayName
          description
        }
        variants {
          id
          attributes {
            name
            slug
            option
          }
          regularPrice
          salePrice
          sellerPrice
          sorting
          status
        }
        status
      }
    }
    productByID(id: $id) {
      id
      refURL
      productBases {
        id
        title
        teamId
        designPositions {
          id
          image {
            id
            thumbnailUrl
            url
            createdAt
          }
          name
          displayName
          description
        }
        attributes {
          name
          slug
          options
        }
        variants {
          id
          attributes {
            name
            slug
            option
          }
          regularPrice
          salePrice
          sellerPrice
          sorting
          status
          supplierPricing {
            id
            price
          }
        }
      }
      mainImageId
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
          createdAt
        }
      }
      collections {
        id
        name
      }
      tags {
        id
        name
      }
      title
      shortTitle
      amzTitle
      sku
      regularPrice
      salePrice
      description
      designStatus
      personalized
      variants {
        id
        productBaseVariantId
        regularPrice
        salePrice
        image {
          id
          url
          thumbnailUrl
          createdAt
        }
        amazonImages {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
        disabled
        sku
        sorting
      }
      shortDescription
      designPositions {
        id
        designPosition {
          id
          image {
            id
            name
            thumbnailUrl
            url
          }
          name
          displayName
          sorting
          description
          productBaseId
          artworkGuidelines {
            description
            file {
              url
              thumbnailUrl
              name
            }
          }
        }
        file {
          id
          name
          thumbnailUrl
          url
          createdAt
        }
      }
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
          createdAt
        }
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
      productStores {
        id
        store {
          id
          title
          platform
        }
        permalink
        originId
        createdAt
        updatedAt
      }
      psd
      psdFiles {
        id
        bucket
        key
        name
        size
        mimeType
        url
        thumbnailUrl
        alt
        caption
        createdAt
      }
      fakeProductDesignPositions {
        productTitle
        productId
        productBases {
          title
          fulfillment {
            id
            name
          }
        }
        psd
        psdFiles {
          id
          name
          size
          mimeType
          url
          thumbnailUrl
        }
        designPositions {
          id
          designPosition {
            id
            image {
              id
              name
              thumbnailUrl
              url
            }
            name
            description
            productBaseId
            artworkGuidelines {
              description
              file {
                url
                thumbnailUrl
                name
              }
            }
          }
          file {
            id
            name
            thumbnailUrl
            url
            createdAt
          }
        }
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation updateProduct($input: EditProduct!) {
    updateProduct(input: $input) {
      id
      title
      sku
      description
      designStatus
      personalized
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
        }
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      fields {
        id
        title
        type
        extraFee
        configure
        sorting
      }
      fakeProductDesignPositions {
        productTitle
        productId
        designPositions {
          id
          designPosition {
            id
            image {
              id
              name
              thumbnailUrl
              url
            }
            name
            description
            productBaseId
            artworkGuidelines {
              description
              file {
                url
                thumbnailUrl
                name
              }
            }
          }
          file {
            id
            name
            thumbnailUrl
            url
            createdAt
          }
        }
      }
    }
  }
`;

class EditProductPage extends Component {
  static contextType = AppContext;
  state = {
    loading: false,
    refetchTimeline: false,
    data: undefined,
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  preLoadDesignPositionByBase = async (base) => {
    if (!base) return base;
    const { __apolloClient__: client } = window || {};
    if (!client) return base;
    const { id } = base;
    const { data } = await client.query({
      query: QUERY_DP_BY_BASE_SELECTED,
      variables: { id },
    });
    const newBase = data?.productBaseByID;
    if (newBase && newBase.designPositions) {
      base.designPositions = newBase.designPositions;
    }

    return newBase;
  };

  render() {
    const { match } = this.props;
    const { id } = match.params;
    let { handleBtnSave, clickSave } = this.props;

    const { currentUser } = this.context;
    let currentParam = getParam(currentUser);

    // Check param have product ignored.
    const paramSearch = { ...convertStringToObject(history.location.search) };
    let isProductIgnored = false;
    Object.keys(paramSearch).forEach((i) => {
      if (i === "productIgnored") {
        isProductIgnored = true;
      }
    });

    return (
      <Query
        // fetchPolicy="cache-and-network"
        fetchPolicy="no-cache"
        query={LOAD_QUERY}
        variables={{ id }}
        onCompleted={async (res) => {
          const { productByID, ...rest } = res || {};
          if (productByID) {
            const bases = productByID.productBases;
            const base = (bases || [])[0];
            const newBase = await this.preLoadDesignPositionByBase(base);
            const newBases = bases.map((b) => {
              if (b.id === newBase.id) {
                return {
                  ...b,
                  designPositions: newBase.designPositions,
                };
              }
              return b;
            });
            productByID.productBases = newBases;
          }

          this.setState({ data: { ...rest, productByID } });
        }}
      >
        {({ loading, error, refetch }) => {
          if (loading) return <Skeleton active />;
          if (error) return <div>Error: {handleError(error.toString())}</div>;
          const { data } = this.state;
          if (!data) return null;

          const productBase = data.productByID.productBases[0];
          const value = data.productByID;
          let mapProductBaseVariant = {};
          let mapVariantsMissing = {};
          if (productBase.variants && productBase.variants.length > 0) {
            for (let i = 0; i < productBase.variants.length; i++) {
              const v = productBase.variants[i];
              mapProductBaseVariant[v.id] = v;
              mapVariantsMissing[v.id] = {
                ...v,
                count: 0,
              };
            }
            if (value.variants && value.variants.length) {
              value.variants = value.variants.map((v) => {
                v.productBaseVariant =
                  mapProductBaseVariant[v.productBaseVariantId];

                if (mapVariantsMissing[v.productBaseVariantId]) {
                  mapVariantsMissing[v.productBaseVariantId]["count"]++;
                }
                return v;
              });
            }
          }

          if (productBase?.teamId === "") {
            productBase.title = getBaseTitle(productBase);
          }

          const productBases = (data?.productBasesForSeller?.nodes || []).map(
            (item) => {
              item.title = getBaseTitle(item);
              return item;
            }
          );

          const variantsMissing = Object.values(mapVariantsMissing)
            .map((value) => {
              const { count, ...rest } = value;
              if (count === 0) {
                return {
                  productBaseVariantId: rest.id,
                  productBaseVariant: rest,
                  image: null,
                  regularPrice: rest.regularPrice,
                  salePrice: rest.regularPrice,
                  disabled: false,
                };
              }
              return;
            })
            .filter(Boolean);

          return (
            <Mutation
              onCompleted={() => {
                notification.success({
                  message: "Edit product has been success!",
                });
                this.setState({
                  loading: false,
                  refetchTimeline: !this.state.refetchTimeline,
                });
                // history.push(`/${currentParam}/products`);
                if (refetch) refetch();
              }}
              onError={(e) => {
                notification.error({ message: handleError(e.toString()) });
                this.setState({ loading: false });
              }}
              mutation={UPDATE_MUTATION}
            >
              {(updateProduct) => (
                <AddProductForm
                  refetch={refetch}
                  isProductIgnored={isProductIgnored}
                  refetchTimeline={this.state.refetchTimeline}
                  currentParam={currentParam}
                  isEditProduct
                  sku={value.sku}
                  loading={this.state.loading}
                  onSubmit={(values) => {
                    let { pushProductToStores, pushToStores, ...rest } = values;
                    rest.id = id;
                    if (typeof rest.productBaseId !== "undefined") {
                      delete rest.productBaseId;
                    }
                    this.setState(
                      {
                        loading: true,
                      },
                      () => {
                        updateProduct({
                          variables: {
                            input: rest,
                          },
                        });
                      }
                    );
                  }}
                  variantsMissing={variantsMissing}
                  productBases={productBases}
                  productBase={productBase}
                  value={value}
                  title={"Edit Product"}
                  clickSave={clickSave}
                  handleBtnSave={(value, aggregations) => {
                    aggregations = {
                      ...aggregations,
                      link: "/seller/products",
                    };
                    if (handleBtnSave) {
                      handleBtnSave(value, aggregations);
                    }
                  }}
                  productId={id}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default EditProductPage;
