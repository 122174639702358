import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { getUnique } from "../../../../helper";
import { useCreateIdea } from "../context/createIdea";
import { IdeaSection } from "./IdeaSection";
import { ProductSection } from "./ProductSection";

export const FIELDS_REQUIRED = {
  title: "Title",
  collectionIds: "Collections",
  tagIds: "Tags",
};

const initialValue = {
  title: "",
  description: "",
  collectionIds: [],
  tagIds: [],
  nicheIds: [],
  relatedProductIds: [],
  medias: [],
  ideaKpi: "0.5",
  priority: false,
};

const DEFAULT_PRIORITY_NO = 10_000;

export const Layout = forwardRef(function Layout(
  {
    value,
    isAdminSeller,
    isRoleIdea,
    onCloseParent,
    refetchIdea,
    isIdeaLeader,
  },
  ref,
) {
  // Context
  const {
    products,
    removeProduct,
    updateProduct,
    setChanged,
  } = useCreateIdea();
  const productsRef = useRef(products);
  productsRef.current = products;

  // State
  const [fields, setFields] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const layoutRef = useRef(null);

  // Get data
  useEffect(() => {
    if (value != null) {
      const {
        ideaTitle,
        ideaDesc,
        ideaKpi,
        medias,
        collections,
        tags,
        niches,
        relatedProducts,
        priority,
      } = value || {};

      const newFields = {};
      newFields.title = ideaTitle;
      newFields.description = ideaDesc;
      newFields.ideaKpi = ideaKpi != null ? `${ideaKpi}` : "0.5";

      newFields.medias = medias;
      newFields.collectionIds = collections;
      newFields.tagIds = tags;
      newFields.nicheIds = niches;
      newFields.relatedProductIds = relatedProducts;
      newFields.priority = !!priority;

      setFields((prev) => ({ ...prev, ...newFields }));
    }
  }, [value]);

  // Action
  const validateFields = useCallback((value, id) => {
    let error = null;
    let label = FIELDS_REQUIRED[id];

    if (label) {
      if ((!value || !value.length) && label) {
        error = `${label} is required.`;
      }
    }

    if (id === "title" && value.length > 254) {
      error = "Maximum 255 characters.";
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);

  const typingRef = React.useRef(null);
  const handleFieldsChange = useCallback(
    (value, id) => {
      setChanged && setChanged(true);
      const clone = productsRef.current;
      const object = { id, value };
      const newProducts = mapValueForProducts(clone, object);

      if (newProducts?.length > 0) {
        for (let i = 0; i < newProducts.length; i++) {
          updateProduct(i, { ...newProducts[i] });
        }
      }

      setFields((prev) => ({ ...prev, [id]: value }));
      typingRef.current && clearTimeout(typingRef.current);
      typingRef.current = setTimeout(() => {
        validateFields(value, id);
      }, 350);
    },
    [validateFields, updateProduct, setChanged],
  );

  const handleSubmit = useCallback(() => {
    const fieldsRequired = {};
    fieldsRequired.title = fields.title;
    fieldsRequired.collectionIds = fields.collectionIds;
    fieldsRequired.tagIds = fields.tagIds;

    for (let [key, value] of Object.entries(fields)) {
      validateFields(value, key);
    }

    const noError = Object.values(errors).every((i) => i == null);
    const canSubmit = Object.values(fieldsRequired).every((i) =>
      Array.isArray(i) ? i.length > 0 : i != null,
    );

    if (noError && canSubmit && products?.length > 0) {
      const {
        ideaKpi,
        medias,
        collectionIds,
        tagIds,
        nicheIds,
        relatedProductIds,
        priority,
        ...rest
      } = fields;
      const mediaIds = (medias || []).map((i) => i.id);
      const newKpi = parseFloat(ideaKpi);
      const newColIds = formatTaxonomy(collectionIds);
      const newTagIds = formatTaxonomy(tagIds);
      const newNicheIds = formatTaxonomy(nicheIds);
      const newRelatedProducts = formatTaxonomy(relatedProductIds);

      const input = {
        ...rest,
        mediaIds,
        ideaKpi: newKpi,
        designKpi: 0.5,
        collectionIds: newColIds,
        tagIds: newTagIds,
        nicheIds: newNicheIds,
        relatedProductIds: newRelatedProducts,
        priority: priority ? DEFAULT_PRIORITY_NO : 0, // checkbox = true => default priority value, else value = 0
      };

      const layoutRefCur = layoutRef.current;
      const canSubmitProduct = layoutRefCur && layoutRefCur.onSubmit();
      if (!canSubmitProduct) return;

      // const clone = cloneDeep(products) || [];
      const clone = [...products] || [];

      input.baseGroups = clone.map((p, idx) => {
        const {
          bases,
          fulfillmentId,
          isCampaign,
          medias,
          psdFiles,
          collectionIds,
          tagIds,
          relatedProductIds,
          position,
          niches,
          status,
          fields,
          productId,
          __typename,
          isMainCustomMockup,
          mainMockups,
          psd,
          ...restProduct
        } = p || {};

        const newPosition = position != null ? position : idx;
        restProduct.collectionIds = formatTaxonomy(collectionIds);
        restProduct.tagIds = formatTaxonomy(tagIds);
        restProduct.relatedProductIds = formatTaxonomy(relatedProductIds);

        restProduct.fields = (
          fields || []
        ).map(({ id, sorting, __typename, ...rest }) => ({ ...rest }));

        const mediaIds = (medias || []).map((i) => i.id);
        const psdFileIds = (psdFiles || []).map((i) => i.id);
        const baseConfigs = (bases || []).map((b, index) => {
          const { id, variants, position, baseConfigId } = b || {};

          const productBaseId = id;
          const positionBase = position != null ? position : index;

          const variantsActive = (variants || [])
            .filter((i) => !i.disabled && i.productBaseVariant)
            .sort(
              (a, b) =>
                a.productBaseVariant.sorting - b.productBaseVariant.sorting,
            );

          const activeProductBaseVariantIds = variantsActive.map(
            (v) => v.productBaseVariantId,
          );

          // Get `attributes name` by `slug`.
          const activeAttributes = variantsActive.reduce((acc, cur) => {
            const { productBaseVariant } = cur || {};
            const { attributes } = productBaseVariant || {};

            (attributes || []).forEach((attr) => {
              if (attr && attr.slug) {
                const options = [attr.option];
                if (acc[attr.slug]) {
                  const curOptions = [...acc[attr.slug]];
                  const contains = curOptions.includes(attr.option);

                  const newOptions = contains
                    ? curOptions
                    : [...curOptions, ...options];
                  acc[attr.slug] = newOptions;
                } else {
                  acc[attr.slug] = options;
                }
              }
            });

            return acc;
          }, {});
          const newActiveAttr = Object.entries(
            activeAttributes,
          ).map(([slug, options]) => ({ slug, options }));

          return {
            id: baseConfigId,
            productBaseId,
            position: positionBase,
            activeProductBaseVariantIds,
            activeAttributes: newActiveAttr,
          };
        });

        let newPsd = (psd || "").split(/\n/g).join("|");
        return {
          ...restProduct,
          position: newPosition,
          mediaIds,
          psdFiles: psdFileIds,
          baseConfigs,
          psd: newPsd,
        };
      });

      return input;
    }
  }, [fields, validateFields, errors, products]);

  const handleClose = useCallback(() => {
    for (let i = 0; i < products.length; i++) {
      removeProduct(i);
    }
  }, [products, removeProduct]);

  const handleClearField = useCallback(() => {
    setFields(() => ({ ...initialValue }));
    handleClose();
  }, [handleClose]);

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit,
      close: handleClose,
      clearField: handleClearField,
    }),
    [handleSubmit, products, handleClose, handleClearField],
  );

  const props = {
    handleFieldsChange,
    fields,
    setFields,
    errors,
    setErrors,
    isAdminSeller,
    isRoleIdea,
    value,
    refetchIdea,
    isIdeaLeader,
  };

  const status = value?.status;
  return (
    <Wrapper>
      <IdeaSection {...props} status={status} />
      <ProductSection
        {...props}
        layoutRef={layoutRef}
        onCloseParent={onCloseParent}
      />
    </Wrapper>
  );
});

const priority = "priority";
const TAXONOMY = ["collectionIds", "tagIds"];
const KEYS_MAP = ["title", priority, ...TAXONOMY];

function mapValueForProducts(products, { id, value }) {
  if (!products || products.length === 0) return;

  const newProducts = products
    .map((p) => {
      const isKeyPriority = id === priority;

      if (isKeyPriority) {
        if (!KEYS_MAP.includes(id)) return null;
        p["isPriority"] = value;
      } else {
        const canMap = Array.isArray(value)
          ? value?.length > 0
          : value
          ? value != null
          : null;
        if (!canMap || !KEYS_MAP.includes(id)) return null;
        if (TAXONOMY.includes(id)) {
          const curVal = p[id] || [];
          const newVal = value || [];
          const uniqueArr = getUnique([...curVal, ...newVal], "id");
          p[id] = uniqueArr;
        } else {
          p[id] = value;
        }
      }

      return p;
    })
    .filter(Boolean);

  return newProducts;
}

export function formatTaxonomy(data) {
  if (!data || data.length === 0) return [];
  return data.map((i) => {
    if (typeof i === "object") {
      return i.id;
    }
    return i;
  });
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  min-height: 100%;

  .idea-section {
    border-right: 1px solid var(--p-border, #c4cdd5);
    height: inherit;
    max-width: 40rem;
    width: 35%;
  }

  .product-section {
    flex: 1 1;
    padding-right: 2rem;

    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .Polaris-Label__Text,
  .label-inline {
    font-weight: 600;
  }
`;
