import React from "react";
import { reducerFn } from "../../../helper";

const ReportProductContext = React.createContext({});
export const ReportProductProvider = ({ children }) => {
  const [state, setState] = React.useReducer(reducerFn, {
    open: false,
    selected: null, // select product_id
  });

  const selectProduct = (id) => () => {
    setState({ open: true, selected: id });
  };

  const closeModal = () => {
    setState({ open: false, selected: null });
  };

  return (
    <ReportProductContext.Provider
      value={{ ...state, selectProduct, closeModal }}
    >
      {children}
    </ReportProductContext.Provider>
  );
};

export const useReportProductCtx = () => React.useContext(ReportProductContext);
