import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const DELETE_PRODUCT_BASES = gql`
  mutation deleteProductBases($ids: [String!]!) {
    deleteProductBases(ids: $ids) {
      deleted
    }
  }
`;

export const DeleteBasePolaris = (props) => {
  // Props
  const {
    open,
    toggleShowModal,
    idsChecked,
    onNotificationChange,
    toggleActive,
    onCompleted,
  } = props;

  // Mutation
  const [deleteProductBases, { loading }] = useMutation(DELETE_PRODUCT_BASES, {
    onCompleted: () => {
      if (toggleShowModal) {
        toggleShowModal();
      }
      if (onNotificationChange) {
        onNotificationChange({
          message: "Delete product bases successfuly.",
          isError: false,
        });
      }
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle actions
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    if (idsChecked.length) {
      deleteProductBases({
        variables: {
          ids: idsChecked,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsChecked]);

  return (
    <Modal
      title={"Delete product bases"}
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>
        Are you sure to delete {idsChecked.length > 1 ? "bases" : "base"}{" "}
        selected?
      </p>
    </Modal>
  );
};
