import { ActionList, Button, Popover } from "@shopify/polaris";
import React, { useReducer } from "react";
import { reducerFn } from "../../../helper";

const MarkAwaitingBuyerInfo = React.lazy(() =>
  import("./MarkAwaitingBuyerInfo"),
);
const UpdateNote = React.lazy(() => import("./UpdateNote"));

export default function MoreActions({ record, refetch }) {
  const [state, setState] = useReducer(reducerFn, {
    active: false,
    openModal: false,
    action: null,
  });

  const toggleActive = React.useCallback(() => {
    setState((p) => ({ ...p, active: !p.active }));
  }, []);

  const handleClose = React.useCallback(() => {
    setState({ active: false });
  }, []);

  const onAction = React.useCallback(
    (action) => () => {
      setState({ openModal: true, action });
    },
    [],
  );

  const activator = (
    <Button disclosure children="Actions" onClick={toggleActive} />
  );

  const label = record.label;
  let markLabel = "Mark as Awaiting Buyer Info";
  let action = "";
  if (label?.length > 0) {
    markLabel = "Unmark as Awaiting Buyer Info";
    action = "remove";
  }

  const actions = [
    {
      content: markLabel,
      onAction: onAction("mark"),
    },
    {
      content: "Update Note",
      onAction: onAction("updateNote"),
    },
  ];

  const restAction = React.useCallback(() => {
    setState({ active: false, action: null, openModal: false });
  }, []);

  const actionProps = {
    open: state.openModal,
    onClose: restAction,
    mappingOrderIDs: [record.id],
    refetch,
    note: record.note,
  };

  const actionMarkup = {
    mark: <MarkAwaitingBuyerInfo action={action} {...actionProps} />,
    updateNote: <UpdateNote note={record.note} {...actionProps} />,
  };

  return (
    <>
      <Popover
        activator={activator}
        active={state.active}
        onClose={handleClose}
      >
        <ActionList items={actions} />
      </Popover>
      <React.Suspense fallback={null}>
        {actionMarkup[state.action]}
      </React.Suspense>
    </>
  );
}
