import React from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { DataTableFulfillmentPolaris } from "./DataTableFulfillmentPolaris";

export const LIST_FULFILLMENT = gql`
  query fulfillments {
    fulfillments {
      id
      name
      slug
      products {
        title
        originId
      }
    }
  }
`;

export const FulfillmentsPolaris = () => {
  // Query
  const { data, loading, error, refetch } = useQuery(LIST_FULFILLMENT, {
    fetchPolicy: "no-cache",
  });

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.fulfillments?.length > 0 ? (
          <DataTableFulfillmentPolaris data={data} refetch={refetch} />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
