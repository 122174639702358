import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Form,
  FormLayout,
  Icon,
  Link,
  Modal,
  TextField,
  Toast,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import bgRegister from "../assets/images/photo-register.jpeg";
import { handleError, isEmail } from "../helper";
import history from "../history";
import useToggle from "../hooks/useToggle";
import NotFoundPage from "./NotFoundPage";

const REGISTER = gql`
  mutation register($input: NewSeller!) {
    register(input: $input) {
      id
      firstName
      lastName
      email
      roles
    }
    # login(email: $email, password: $password) {
    #   id
    #   expiredAt
    #   user {
    #     id
    #     firstName
    #     lastName
    #     email
    #     phone
    #     address
    #     avatar {
    #       id
    #       name
    #       url
    #     }
    #     teamUser {
    #       team {
    #         id
    #       }
    #       role
    #     }
    #     roles
    #   }
    # }
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #edf2f7;
  align-items: center;
  .Polaris-Link + .Polaris-Link {
    margin-top: 1rem;
  }
  .wrap-icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .Polaris-FormLayout {
    min-height: 600px;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  .Polaris-FormLayout__Item {
    margin-top: 2.5rem;
  }
`;

export const RegisterPolaris = () => {
  const [iconView, setIconView] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [open, toggleOpen] = useToggle(false);

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
  });

  const [register, { loading, error }] = useMutation(REGISTER, {
    onCompleted: (res) => {
      if (res?.register?.id) {
        toggleOpen(true);
      }
      // authenticate(res.login);
      // history.push(`/`);
    },
    onError: () => {},
  });

  const handleValueFirstName = (value) => {
    setErrors((prevState) => {
      let fn = null;
      if (!value) {
        fn = "Please enter first name.";
      }
      return {
        ...prevState,
        firstName: fn,
      };
    });
  };

  const handleValueLastName = (value) => {
    setErrors((prevState) => {
      let ln = null;
      if (!value) {
        ln = "Please enter last name.";
      }
      return {
        ...prevState,
        lastName: ln,
      };
    });
  };

  const handleValueEmail = (value) => {
    setErrors((prevState) => {
      let e = null;
      if (value) {
        if (!isEmail(value)) {
          e = "Email must be a valid email address.";
        }
      } else {
        e = "Please enter email.";
      }
      return {
        ...prevState,
        email: e,
      };
    });
  };

  const handleValuePassword = (value) => {
    setErrors((prevState) => {
      let pw = null;
      if (!value) {
        pw = "Please choose a password.";
      }
      return {
        ...prevState,
        password: pw,
      };
    });
  };

  const handleValueConfirmPassword = (value) => {
    setErrors((prevState) => {
      let cpw = null;
      if (!value) {
        cpw = "Please confirm your password.";
      } else {
        if (!_.isEqual(value, password)) {
          cpw = "The two passwords that you entered do not match.";
        }
      }
      return {
        ...prevState,
        confirmPassword: cpw,
      };
    });
  };

  const handleFirstNameChange = useCallback((value) => {
    handleValueFirstName(value);
    setFirstName(value);
  }, []);

  const handleLastNameChange = useCallback((value) => {
    handleValueLastName(value);
    setLastName(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    handleValueEmail(value);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    handleValuePassword(value);
    setPassword(value);
  }, []);

  const handleConfirmPassword = useCallback((value) => {
    handleValueConfirmPassword(value);
    setConfirmPassword(value);
  });

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback(() => {
    handleValueFirstName(firstName);
    handleValueLastName(lastName);
    handleValueEmail(email);
    handleValuePassword(password);
    handleValueConfirmPassword(confirmPassword);
    let count = _.size(_.filter(_.values(errors), (e) => e !== null));

    if (
      email &&
      password &&
      firstName &&
      lastName &&
      confirmPassword &&
      count === 0
    ) {
      let input = {
        firstName,
        lastName,
        email,
        password,
      };
      register({
        variables: {
          input: input,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, firstName, lastName, confirmPassword]);

  const toastMarkup =
    activeToast && error ? (
      <Toast
        content={error ? handleError(error.toString()) : null}
        error={error ? error : null}
        duration={5000}
        onDismiss={toggleActive}
      />
    ) : null;

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  const handleRedirect = useCallback(() => {
    history.push("/");
  }, []);

  //isDev
  return true ? (
    <Container>
      <Modal
        open={open}
        title="Activate your account"
        onClose={toggleOpen}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
        primaryAction={{ content: "Go to Login", onAction: handleRedirect }}
        sectioned
      >
        <p>
          Thank you for registration. Please check your email and activate your
          account to login.
        </p>
      </Modal>
      <div className="container mx-auto">
        <div className="flex justify-center px-8">
          <div className="container bg-white register-card w-full xl:w-3/4 lg:w-11/12 flex rounded-l-lg rounded-r-lg">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
              style={{ backgroundImage: `url(${bgRegister})` }}
            ></div>
            <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <h3
                    className="pt-4 text-center font-medium"
                    style={{ fontSize: "2.25rem" }}
                  >
                    Create an Account!
                  </h3>
                  <FormLayout.Group>
                    <TextField
                      label="First Name(*)"
                      type="text"
                      onChange={handleFirstNameChange}
                      value={firstName}
                      placeholder="First name"
                      error={errors.firstName}
                    />
                    <TextField
                      label="Last Name(*)"
                      type="text"
                      onChange={handleLastNameChange}
                      value={lastName}
                      placeholder="Last name"
                      error={errors.lastName}
                    />
                  </FormLayout.Group>
                  <TextField
                    value={email}
                    onChange={handleEmailChange}
                    label="Email(*)"
                    type="email"
                    placeholder="Email"
                    error={errors.email}
                  />
                  <FormLayout.Group>
                    <TextField
                      value={password}
                      onChange={handlePasswordChange}
                      label="Password(*)"
                      type={!iconView ? "password" : "text"}
                      placeholder="***************"
                      suffix={iconViewMarkup}
                      error={errors.password}
                    />
                    <TextField
                      value={confirmPassword}
                      onChange={handleConfirmPassword}
                      label="Confirm Password(*)"
                      type={!iconView ? "password" : "text"}
                      placeholder="***************"
                      suffix={iconViewMarkup}
                      error={errors.confirmPassword}
                    />
                  </FormLayout.Group>
                  <Button fullWidth primary submit loading={loading}>
                    Register Account
                  </Button>
                  <hr className="mb-6 border-t" />
                  <div className="flex flex-col items-center">
                    <Link url="/forgot-password">Forgot Password?</Link>
                    <Link url="/login">Already have an account? Login!</Link>
                  </div>
                </FormLayout>
              </Form>
            </div>
          </div>
          {toastMarkup}
        </div>
      </div>
    </Container>
  ) : (
    <NotFoundPage />
  );
};
