import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";
import { multiSearchAnd } from "../../helper";

export const VariantsSelectEPPolaris = ({
  productBaseVariants,
  value,
  onChange,
  selectedVariants,
  enabled = false,
}) => {
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selecetedOptions, setSelelctedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    let newValue = null;
    if (value) {
      newValue =
        value && value.productBaseVariant ? value.productBaseVariant.id : null;
      setSelelctedOptions([newValue]);
      let name =
        value && value.productBaseVariant && value.productBaseVariant.attribute;
      setInputValue(name);
    }
  }, [value]);

  useEffect(() => {
    let newOptions = [];
    if (productBaseVariants?.length) {
      let popularVariants = productBaseVariants.filter((i) => !i.disabled);
      let otherVariants = productBaseVariants.filter((i) => i.disabled);

      popularVariants = popularVariants.map((item) => ({
        value: item.id,
        label: item.attribute,
        disabled: enabled ? false : selectedVariants.includes(item.id),
      }));

      otherVariants = otherVariants.map((item) => ({
        value: item.id,
        label: item.attribute,
        disabled: enabled ? false : selectedVariants.includes(item.id),
      }));

      // newOptions = productBaseVariants.map((item) => ({
      //   value: item.id,
      //   label: item.attribute,
      //   disabled: selectedVariants.includes(item.id),
      // }));
      if (popularVariants?.length) {
        newOptions = [
          {
            value: null,
            label: <span className="heading">Popular Variants</span>,
            disabled: true,
          },
          ...popularVariants,
        ];
      }

      if (otherVariants?.length) {
        newOptions = [
          ...newOptions,
          {
            value: null,
            label: <span className="heading">Other Variants</span>,
            disabled: true,
            id: "Heading",
          },
          ...otherVariants,
        ];
      }
    }
    setOptions(newOptions);
    setDeselectedOptions(newOptions);
  }, [productBaseVariants, selectedVariants, enabled]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      // const filterRegex = new RegExp(value, "i");
      let formatValue = value.replace(/\s\s+/g, " ").split(" ");
      const resultOptions = deselectedOptions.filter((option) => {
        let result = multiSearchAnd(option.label, formatValue);
        return result;
      });

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelelctedOptions(selected);
      setInputValue(_.head(selectedValue));
      value.productBaseVariant = productBaseVariants.find(
        (pv) => pv.id === _.head(selected)
      );
      if (onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textFiledMarkup = (
    <Autocomplete.TextField
      label="Variants"
      labelHidden
      value={inputValue}
      onChange={handleInputValue}
      disabled={!productBaseVariants.length}
    />
  );

  return (
    <Autocomplete
      selected={selecetedOptions}
      onSelect={handleSelection}
      options={options}
      textField={textFiledMarkup}
      emptyState={
        productBaseVariants.length ? <span>No item found!</span> : null
      }
    />
  );
};
