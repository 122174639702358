import { gql } from "apollo-boost";
import { reportTrackingResultFragment, TrackingConfigFragment } from "../fragments";

export const GET_TRACKING_CONFIG = gql`
  query getTrackingConfig($teamId: ID) {
    getTrackingConfig(teamId: $teamId) {
      ...TrackingConfigFragment
    }
  }
  ${TrackingConfigFragment}
`;

export const REPORT_TRACKING_SUBMIT_ERROR = gql`
  query reportTrackingSubmitError($filter: ReportTrackingFilter!) {
    reportTrackingSubmitError(filter: $filter) {
      ...reportTrackingResultFragment
    }
  }
  ${reportTrackingResultFragment}
`