import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";

export const VariantsSelectPolaris = (props) => {
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
      setDeselectedOptions(props.options);
    }
    if (props.value && props.options && props.options.length) {
      let matchedOptions =
        props.options && props.options.length
          ? props.options.find((option) => option.value === props.value)
          : null;
      let value =
        matchedOptions && matchedOptions.value
          ? matchedOptions.value
          : props.options[0].value;
      let label =
        matchedOptions && matchedOptions.label
          ? matchedOptions.label
          : props.options[0].label;
      setSelectedOptions([value]);
      setInputValue(label);
    } else {
      let value =
        props.options && props.options.length > 0
          ? [props.options[0].value]
          : [];
      let label =
        props.options && props.options.length > 0
          ? props.options[0].label
          : null;
      setSelectedOptions(value);
      setInputValue(label);
    }
  }, [props]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0]);
      if (props.onChange) {
        props.onChange(selected[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      label="Choose a variant"
      labelHidden
      placeholder="Search variants"
      value={inputValue}
      onChange={handleInputChange}
    />
  );

  return (
    <Autocomplete
      options={options}
      onSelect={handleSection}
      selected={selectedOptions}
      textField={textField}
      emptyState={<span>No items found!</span>}
    />
  );
};
