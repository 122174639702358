import {
  Button,
  Checkbox,
  DataTable,
  Select,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { CancelSmallMinor, CirclePlusMinor } from "@shopify/polaris-icons";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { IGNORE_PERSONALIZED_KEYS, PLATFORMS } from "../../variable";
import { ELEMENTS, genFieldsByName, initNewField } from "./Customize";
import { FieldConfigureOptionsPolaris } from "./FieldConfigureOptionsPolaris";

const TYPE_OPTIONS =
  ELEMENTS && ELEMENTS.length > 0
    ? ELEMENTS.map((item) => ({ value: item.type, label: item.title }))
    : [];

const Container = styled.div`
  margin-top: 1.6rem;

  .actions-wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }

  > .Polaris-Stack > .Polaris-Stack__Item {
    margin-top: 0;
  }

  .input-group {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2rem;
  }

  .personalized-wrap {
    > label {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .title-wrap {
    min-width: 22rem;
  }

  .meta-value-wrap {
    max-width: 15rem;
  }
`;

const None = "None";

export const SimpleCustomizePolaris = (props) => {
  let {
    value,
    isMapOrder,
    personalized,
    onErrorChange,
    item,
    isMapOrderTemplate,
    canUpdate = true,
  } = props;
  const [rows, setRows] = useState([]);
  const [editFieldIndex, setEditFieldIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [metaValue, setMetaValue] = useState({});
  const [metaDataValue, setMetaDataValue] = useState([]);
  const [errors, setErrors] = useState({});
  const timeoutRef = React.useRef(null);

  // Props
  const platform = get(item, "store.platform", null);
  const isAmazon = [PLATFORMS.Amazon].includes(platform);

  const handleAddNewField = useCallback(() => {
    const element = ELEMENTS[0];
    const index = value.length + 1;
    let newValue = initNewField(element, index);
    if (newValue && newValue.configure && newValue.configure.required != null) {
      newValue.configure.required = false;
    }
    if (isMapOrder || isMapOrderTemplate) {
      newValue["fieldValue"] = {};
      if (isAmazon) {
        setMetaValue((prev) => ({ ...prev, [value.length]: None }));
        newValue["fieldValue"] = {
          key: None,
          customValue: false,
          value: null,
        };
      }
    }
    if (props.onChange) {
      let newValues = genFieldsByName(value, newValue);
      props.onChange(value?.length > 0 ? newValues : [newValue]);
      // props.onChange([...value, { ...newValue }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isAmazon, isMapOrderTemplate, value]);

  const toggleVisibleModal = useCallback(() => {
    setOpenModal((prevState) => !prevState);
  }, []);

  // Get data
  useEffect(() => {
    let newMetaData =
      personalized?.length > 0
        ? personalized
            .filter((i) => {
              let matches = true;
              IGNORE_PERSONALIZED_KEYS.forEach((ig) => {
                let nameLower = i.name.toLowerCase();
                if (nameLower.match(ig)) {
                  matches = false;
                }
              });
              return matches;
            })
            .map((i) => ({ value: i?.name, label: i?.name }))
        : [];
    setMetaDataValue([...newMetaData, { value: None, label: None }]);
  }, [personalized]);

  useEffect(() => {
    for (let i = 0; i < value.length; i++) {
      let errLabel = null;
      let errValue = null;
      if (metaValue[i] == null) {
        errLabel = "Please choose meta value";
      } else if (metaValue[i] === None) {
        if (!value[i]["fieldValue"]["value"]) {
          errValue = "Please enter value";
        }
      }
      let prevState = {};
      timeoutRef.current && clearTimeout(timeoutRef.current);
      setErrors((prev) => {
        prevState = {
          ...prev,
          [i]: {
            ...prev[i],
            label: errLabel,
            value: errValue,
          },
        };
        timeoutRef.current = setTimeout(() => {
          if (onErrorChange) {
            onErrorChange(prevState);
          }
        }, 0);
        return prevState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, metaValue]);

  useEffect(() => {
    if (value?.length > 0) return;
    const element = ELEMENTS[0];
    const index = value.length + 1;
    let newValue = initNewField(element, index);
    if (newValue && newValue.configure && newValue.configure.required != null) {
      newValue.configure.required = false;
    }
    if (isMapOrder || isMapOrderTemplate) {
      newValue["fieldValue"] = {};
      if (isAmazon) {
        setMetaValue({ 0: None });
        newValue["fieldValue"] = {
          key: None,
          customValue: false,
          value: null,
        };
      }
    }
    if (props.onChange) {
      let newValues = genFieldsByName(value, newValue);
      props.onChange(value?.length > 0 ? newValues : [newValue]);

      // props.onChange([...value, { ...newValue }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAmazon, isMapOrderTemplate, value]);

  // Handle actions
  const handleMetaValueChange = useCallback(
    (selected, idx) => {
      setMetaValue((prev) => ({ ...prev, [idx]: selected }));
      let isNone = selected === None;
      let title = value[idx].title;
      let fieldValue = {
        key: isNone ? title : selected,
        customValue: isNone ? true : false,
        value: null,
      };
      if (props.onChange) {
        props.onChange(
          value.map((v, i) => {
            let title = isNone ? v.title : selected;
            return i === idx
              ? {
                  ...v,
                  title,
                  fieldValue,
                }
              : v;
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleValuesChange = useCallback(
    (e, v, idx, isNone, value) => {
      let input = e;
      v["fieldValue"] = {
        ...v["fieldValue"],
        customValue: isNone ? true : input.length ? true : false,
        value: input,
      };

      if (props.onChange) {
        props.onChange(value.map((i, index) => (index === idx ? v : i)));
      }
    },
    [props.onChange]
  );

  const validateTitle = useCallback((value, idx) => {
    let error = null;
    if (!value) {
      error = "Title is required.";
    }
    setErrors((prev) => ({
      ...prev,
      [idx]: { ...prev[idx], title: error },
    }));
  }, []);

  useEffect(() => {
    if (value && value.length) {
      let newRows = value.map((item, idx) => {
        return [
          <Select
            value={item.type}
            onChange={(type) => {
              item.type = type;
              const element = ELEMENTS.find((e) => e.type === type);
              const field = initNewField(element, idx);
              item.configure = field.configure;
              if (props.onChange) {
                props.onChange(value.map((v, i) => (i === idx ? item : v)));
              }
            }}
            options={TYPE_OPTIONS}
            labelHidden
            label="type"
            disabled={!canUpdate}
          />,
          ...(isMapOrder
            ? [
                <div className="meta-value-wrap">
                  <Select
                    options={metaDataValue}
                    value={metaValue[idx]}
                    onChange={(value) => handleMetaValueChange(value, idx)}
                    placeholder="Choose meta value"
                    error={errors[idx]?.label}
                    disabled={!canUpdate}
                  />
                </div>,
              ]
            : []),
          <div className="title-wrap">
            <TextField
              value={item.title}
              placeholder="Title"
              label="title"
              labelHidden
              error={errors[idx]?.title}
              disabled={(isMapOrder && metaValue[idx] !== None) || !canUpdate}
              onChange={(title) => {
                item.title = title;
                let isNone = metaValue[idx] === None;
                let newFieldValue = {
                  ...item["fieldValue"],
                  key: isNone ? title : item["fieldValue"]?.key,
                };
                if (isNone) {
                  validateTitle(title, idx);
                }
                if (isMapOrder || isMapOrderTemplate) {
                  if (personalized) {
                    let fieldValue = "";
                    personalized.length &&
                      personalized.forEach((p) => {
                        if (
                          p.name &&
                          p.name.toLowerCase().trim() ===
                            title.toLowerCase().trim()
                        ) {
                          fieldValue = p.value;
                        }
                      });
                    newFieldValue = {
                      ...newFieldValue,
                      value: fieldValue,
                      customValue: isNone
                        ? fieldValue?.length > 0
                          ? true
                          : false
                        : newFieldValue?.customValue,
                    };
                  }
                  item["fieldValue"] = newFieldValue;
                }
                if (props.onChange) {
                  props.onChange(value.map((v, i) => (i === idx ? item : v)));
                }
              }}
            />
          </div>,
          <TextField
            value={item.configure && item.configure.placeholder}
            placeholder="Placeholder"
            label="placeholder"
            labelHidden
            disabled={!canUpdate}
            onChange={(placeholder) => {
              item.configure.placeholder = placeholder;
              if (props.onChange) {
                props.onChange(value.map((v, i) => (i === idx ? item : v)));
              }
            }}
          />,
          <TextField
            value={item.configure?.settings?.defaultValue}
            placeholder="Default value"
            label="default value"
            labelHidden
            disabled={!canUpdate}
            onChange={(defaultValue) => {
              item.configure.settings.defaultValue = defaultValue;
              if (props.onChange) {
                props.onChange(value.map((v, i) => (i === idx ? item : v)));
              }
            }}
          />,
          <Checkbox
            label="required"
            labelHidden
            disabled={!canUpdate}
            checked={item.configure && item.configure.required}
            onChange={(checked) => {
              item.configure.required = checked;
              if (props.onChange) {
                props.onChange(value.map((v, i) => (i === idx ? item : v)));
              }
            }}
          />,
          <div className="actions-wrap">
            <Button
              icon={CancelSmallMinor}
              plain
              disabled={value.length <= 1 || !canUpdate}
              onClick={() => {
                if (props.onChange) {
                  if (value.length > 1) {
                    props.onChange(value.filter((_value, i) => idx !== i));
                  }
                }
              }}
            />
            {!["text", "textarea"].includes(item.type) && (
              <Button
                plain
                children={"Edit"}
                onClick={() => {
                  setEditFieldIndex(idx);
                  toggleVisibleModal();
                }}
              />
            )}
            <FieldConfigureOptionsPolaris
              open={editFieldIndex === idx && openModal}
              item={item}
              toggleVisibleModal={toggleVisibleModal}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange(value.map((vv, i) => (i === idx ? v : vv)));
                }
              }}
            />
          </div>,
        ];
      });
      setRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, editFieldIndex, openModal, metaValue, errors, canUpdate]);

  // Variable
  const cols = [];
  const n = isMapOrder ? 6 : 5;
  for (let i = 0; i < n; i++) {
    cols.push("text");
  }

  const headings = [
    "Type",
    ...(isMapOrder ? ["Default meta value"] : []),
    "Title",
    "Placeholder",
    "Default value",
    "Required",
    "Actions",
  ];

  return (
    <Container>
      <Stack alignment="fill" distribution="fillEvenly" vertical>
        <TextContainer>
          <DataTable
            columnContentTypes={cols}
            headings={headings}
            rows={rows}
            verticalAlign="middle"
          />
          <div>
            <Button
              icon={CirclePlusMinor}
              onClick={handleAddNewField}
              children="Add Field"
              disabled={!canUpdate}
            />
          </div>
          <div className="personalized-wrap">
            {(isMapOrder || isMapOrderTemplate) && value && value.length > 0 ? (
              <>
                <label>Personalized values</label>
                <div className="input-group">
                  {value.map((v, idx) => {
                    let isNone = metaValue[idx] === None;
                    let val = value[idx]["fieldValue"]["value"];
                    const personalized = item?.personalized;
                    if (
                      Array.isArray(personalized) &&
                      personalized?.length > 0 &&
                      !val
                    ) {
                      const title = value[idx]["title"];
                      for (let per of personalized) {
                        if (per?.name === title) {
                          val = per?.value;
                        }
                      }
                    }

                    let err = isNone && !val ? errors[idx]?.value : null;

                    return (
                      <div key={idx}>
                        <TextField
                          label={v.title}
                          value={val}
                          error={err}
                          onChange={(e) => {
                            handleValuesChange(e, v, idx, isNone, value);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        </TextContainer>
      </Stack>
    </Container>
  );
};

export default SimpleCustomizePolaris;