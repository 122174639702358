import { Mutation, Query } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Skeleton, notification } from "antd";
import { gql } from "apollo-boost";
import get from "lodash/get";
import React, { Component, useCallback } from "react";
import ProductBaseForm from "../../components/base/ProductBaseForm";
import PageTitle from "../../components/shared/PageTitle";
import { PUBLISH_PRODUCT_BASES } from "../../graphql/mutations";
import { convertStringToObject, handleError } from "../../helper";

export const QUERY = gql`
  fragment cat on Category {
    id
    name
    description
    image {
      id
      name
      thumbnailUrl
      url
    }
  }
  query load($id: ID!) {
    fulfillments {
      id
      name
      slug
      products {
        title
        originId
        status
        attributes {
          name
          slug
          options
        }
        variants {
          regularPrice
          salePrice
          sellerPrice
          fulfillmentProductId
          sorting
          attributes {
            name
            slug
            option
          }
        }
      }
    }
    productBaseCategories {
      ...cat
      children {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
            }
          }
        }
      }
    }
    suppliers {
      id
      firstName
      lastName
    }
    carriers {
      id
      name
      code
    }
    productBaseHasProduct(id: $id)
    productBaseByID(id: $id) {
      id
      title
      niceName
      slug
      marketplaceIdentifyKey
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      kpi
      passOnHold
      hasAdditionalShipping
      requiredDesign
      mockupTemplates {
        id
        productBaseID
        originTemplateID
        sorting
        isDefault
      }
      colorCodes {
        name
        value
        patternImageId
        patternImage {
          id
          url
          name
          thumbnailUrl
        }
      }
      fulfillment {
        fulfillmentId
        productId
        presetId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
        default
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        id
        extraFee
        image {
          id
          thumbnailUrl
          url
        }
        name
        displayName
        description
        productBaseVariants {
          id
          attributes {
            name
            slug
            option
          }
        }
        artworkGuidelines {
          id
          file {
            id
            name
          }
          description
        }
        resize
        position
        scalablePressCustomize
      }
      variants {
        id
        fulfillmentProductId
        fulfillmentSku
        fulfillmentShippingCost
        fulfillmentShippingInternationalCost
        additionalPrice
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          id
          userId
          price
        }
        carrierPricing {
          id
          carrierId
          price
          default
          usingAdditionalPrice
          additionalPriceType
          additionalPrice
        }
        regularPrice
        salePrice
        sellerPrice
        fulfillmentWarehouses
      }
      status
      customcatShiping {
        isSingleShipment
        usFirstFee
        usAdditionalFee
        canadaFirstFee
        canadaAdditionalFee
        internationalFirstFee
        internationalAdditionalFee
        shippingRate
      }
      scalablePressType
      scalableOriginID
      scalablePressDesignType
      nasFileNameSyntax
      isDigitalProduct
      printifyPrintProviderID
      printifyPrintProviderName
      printifyBlueprintID
    }
  }
`;

const MUTATION = gql`
  mutation updateProductBase($input: EditProductBase!) {
    updateProductBase(input: $input) {
      id
      title
      slug
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      hasAdditionalShipping
      colorCodes {
        name
        value
        patternImageId
        patternImage {
          id
          url
          name
          thumbnailUrl
        }
      }
      mockupTemplates {
        id
        productBaseID
        originTemplateID
        isDefault
      }
      fulfillment {
        fulfillmentId
        productId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
        default
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        image {
          id
          thumbnailUrl
          url
        }
        name
        description
      }
      variants {
        id
        fulfillmentProductId
        fulfillmentSku
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          userId
          price
        }
        carrierPricing {
          carrierId
          price
          default
          usingAdditionalPrice
          additionalPriceType
          additionalPrice
        }
        regularPrice
        salePrice
        sellerPrice
      }
      status
      isDigitalProduct
    }
  }
`;

class EditProductBase extends Component {
  state = {
    loading: false,
    isPublish: undefined,
    open: false,
    value: null,
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  toggleOpen = () => {
    this.setState((p) => ({ ...p, open: !p.open }));
  };

  onCompleted = () => {
    this.refetch && this.refetch();
  };

  render() {
    const id = this.props.match.params.id;
    let { handleBtnSave, clickSave, location } = this.props;

    const { isPublish, open, value } = this.state;
    let searchProp = get(location, "search", {});
    searchProp = convertStringToObject(searchProp);
    if (searchProp.hasTeam) {
      searchProp.hasTeam = JSON.parse(searchProp.hasTeam);
    }
    let action = null;
    if (typeof isPublish === "boolean" && !isPublish && searchProp.hasTeam) {
      action = (
        <Button children="Publish" type="primary" onClick={this.toggleOpen} />
      );
    }
    let ids = [];
    if (value?.id) {
      ids = [value.id];
    }
    return (
      <div>
        <PageTitle
          subTitle={"Products"}
          title={"Product bases"}
          link="/admin/product-bases"
          action={action}
        />
        <Query
          query={QUERY}
          variables={{ id }}
          fetchPolicy="cache-and-network"
          onCompleted={(res) => {
            const value = get(res, "productBaseByID", null);
            const status = get(value, "status", null);
            if (status != null) {
              this.setState({ isPublish: status, value });
            }
          }}
        >
          {({ error, loading, data, refetch }) => {
            if (error) return <div>Error: {handleError(error.toString())}</div>;
            if (loading) return <Skeleton active />;

            // Sacable Press
            let value = data.productBaseByID;
            if (value) {
              let designPositions = value.designPositions;
              designPositions =
                designPositions && designPositions.length > 0
                  ? designPositions.map((dp) => {
                      let { scalablePressCustomize, ...rest } = dp;
                      let width = scalablePressCustomize?.dimensions?.width;
                      let height = scalablePressCustomize?.dimensions?.height;
                      let horizontal =
                        scalablePressCustomize?.position?.horizontal;
                      let top = scalablePressCustomize?.position?.offset?.top;
                      let bottom =
                        scalablePressCustomize?.position?.offset?.bottom;
                      if (
                        scalablePressCustomize &&
                        Object.keys(scalablePressCustomize).length
                      ) {
                        return {
                          ...rest,
                          width,
                          height,
                          horizontal,
                          top,
                          bottom,
                        };
                      } else {
                        return rest;
                      }
                    })
                  : [];
              value["designPositions"] = designPositions;
            }

            const fulfillments = data?.fulfillments || [];
            // .filter(
            //   ({ slug }) => slug !== CUSTOM_FULFILLMENT
            // );

            this.refetch = refetch;
            return (
              <Mutation
                onError={(err) => {
                  this.setState({ loading: false });
                  notification.error({ message: handleError(err.toString()) });
                }}
                onCompleted={() => {
                  this.setState({ loading: false }, () => {
                    notification.success({
                      message: "Product base has been saved!",
                    });
                    // history.push(`/admin/product-bases`);
                    if (refetch) refetch();
                  });
                }}
                mutation={MUTATION}
              >
                {(updateProductBase) => (
                  <ProductBaseForm
                    hasProduct={data.productBaseHasProduct}
                    onSubmit={(values) => {
                      const newCategory =
                        Array.isArray(values.categories) === true
                          ? values.categories
                          : [values.categories];
                      this.setState({ loading: true }, () => {
                        let removeTypeNames = [
                          "supplierPricing",
                          "carrierPricing",
                          "attributes",
                          "designPositions",
                          "variants",
                        ];
                        for (let i = 0; i < removeTypeNames.length; i++) {
                          const key = removeTypeNames[i];
                          if (values[key] && values[key].length) {
                            values[key] = values[key].map((v) => {
                              if (typeof v.__typename !== "undefined") {
                                delete v.__typename;
                              }
                              if (key === "designPositions") {
                                if (v.image) {
                                  v.image = v.image.id;
                                }
                                if (
                                  v.productBaseVariants &&
                                  v.productBaseVariants.length
                                ) {
                                  v.productBaseVariants.forEach((b) => {
                                    delete b.__typename;
                                    if (b.attributes && b.attributes.length) {
                                      b.attributes.forEach((a) => {
                                        delete a.__typename;
                                      });
                                    }
                                  });
                                }
                              }
                              if (key === "variants") {
                                const subKeys = [
                                  "attributes",
                                  "supplierPricing",
                                  "carrierPricing",
                                ];
                                for (let j = 0; j < subKeys.length; j++) {
                                  const subKey = subKeys[j];
                                  if (v[subKey] && v[subKey].length) {
                                    v[subKey] = v[subKey].map((s) => {
                                      if (typeof s.__typename !== "undefined") {
                                        delete s.__typename;
                                      }
                                      return s;
                                    });
                                  }
                                }
                              }
                              return v;
                            });
                          }
                        }
                        updateProductBase({
                          variables: {
                            input: { ...values, id, categories: newCategory },
                          },
                        });
                      });
                    }}
                    loading={this.state.loading}
                    fulfillments={fulfillments}
                    categories={data.productBaseCategories}
                    suppliers={data.suppliers}
                    carriers={data.carriers}
                    // value={data.productBaseByID}
                    value={value}
                    clickSave={clickSave}
                    isEditProductBase
                    handleBtnSave={(value, aggregations) => {
                      aggregations = {
                        ...aggregations,
                        link: "/admin/product-bases",
                      };
                      if (handleBtnSave) {
                        handleBtnSave(value, aggregations);
                      }
                    }}
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
        <PublishBase
          open={open}
          onClose={this.toggleOpen}
          ids={ids}
          onCompleted={this.onCompleted}
        />
      </div>
    );
  }
}

function PublishBase({ ids, open, onClose, onCompleted }) {
  // Mutation
  const [publish, { loading }] = useMutation(PUBLISH_PRODUCT_BASES, {
    onCompleted: () => {
      const msg = "Publish product bases success.";
      notification.success({ message: msg });
      onCompleted();
    },
    onError: (err) => {
      notification.error({ message: err });
    },
  });

  const handleSubmit = useCallback(() => {
    if (!ids || ids.length === 0) return;
    publish({
      variables: {
        ids,
      },
    }).finally(() => {
      onClose();
    });
  }, [ids, publish, onClose]);

  return (
    <Modal
      title="Publish Product Bases"
      visible={open}
      onCancel={onClose}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <p>Are you sure to publish this base?</p>
    </Modal>
  );
}

export default EditProductBase;
