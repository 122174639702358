import { gql } from "apollo-boost";

export const GET_USER_BY_ROLES = gql`
  query listUserTeamByRoles($roles: [String!]!) {
    listUserTeamByRoles(roles: $roles) {
      id
      user {
        id
        lastName
        firstName
        status
      }
    }
  }
`;

export const QUERY_ME = gql`
  query me {
    me {
      id
      isRootUser
      firstName
      lastName
      email
      phone
      address
      extraMenus
      viewOriginFile
      isOnline
      avatar {
        id
        name
        url
      }
      teamUser {
      designOnlineTeamID
        team {
          id
        }
        role
      }
      roles
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query suppliers {
    suppliers {
      id
      firstName
      lastName
    }
  }
`;

export const GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM = gql`
  query getUserHasPermissionAcceptAccessInTeam {
    getUserHasPermissionAcceptAccessInTeam {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_MANUAL_ORDER_CREATION_NOTICE = gql`
  query getManualOrderCreationNotice {
    getManualOrderCreationNotice {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ENABLE_2FA = gql`
  query getEnable2fa {
    getEnable2fa {
      isEnable
      keepLoginTime
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      count
      hits {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_TEAM_ROLES = gql`
  query teamRoles {
    teamRoles
  }
`;

export const LOGIN_HISTORY = gql`
  query loginHistory($userId: ID!, $paged: Int, $limit: Int) {
    loginHistory(userId: $userId, paged: $paged, limit: $limit) {
      total
      nodes {
        loggedAt
        ipAddress
        location {
          region
          city
          country
          postal
          org
        }
        deviceInfo {
          device
        }
      }
    }
  }
`;

export const GET_MEMBER_BY_DESIGN_TEAM = gql`
  query getMemberByDesignTeam($designTeamId: String!) {
    getMemberByDesignTeam(designTeamId: $designTeamId) {
      id
      firstName
      lastName
    }
  }
`;