import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import React from "react";
import { GET_OWNER_STORE_BY_IDS } from "../../../graphql/queries";
import { reducerFn } from "../../../helper";

export function useOwnerStore(filter) {
  const [state, setState] = React.useReducer(reducerFn, {
    data: {},
    loading: false,
  });

  const [getOwnerStore] = useLazyQuery(GET_OWNER_STORE_BY_IDS, {
    variables: {
      filter,
    },
    onCompleted: (res) => {
      const result = formatOwnerStore(res);
      setState({ data: result, loading: false });
    },
    onError: () => {
      setState({ loading: false });
    },
  });

  const handle = React.useCallback(
    (storeIDs) => {
      setState({ loading: true });
      getOwnerStore({
        variables: {
          filter: {
            filterByTime: filter.filterByTime,
            storeIDs,
          },
        },
      });
    },
    [filter],
  );

  return [handle, state];
}

function formatOwnerStore(data) {
  const res = {};
  if (data?.getOwnerStoreByIDs2?.length > 0) {
    for (let item of data.getOwnerStoreByIDs2) {
      if (!item || !item.storeOwners || item.storeOwners.length === 0) continue;

      const storeOwners = [];
      for (let s of item.storeOwners) {
        const user = getUser(s.user);
        storeOwners.push({
          ...s,
          user,
          createdAt: s.createdAt
            ? moment(s.createdAt).format("DD-MM-YYYY")
            : null,
          deletedAt: s.deletedAt
            ? moment(s.deletedAt).format("DD-MM-YYYY")
            : null,
        });
      }

      if (storeOwners.length == 0) continue;
      res[item.storeID] = storeOwners;
    }
  }

  return res;
}

function getUser(user) {
  return [user?.firstName, user?.lastName].filter(Boolean).join(" ");
}
