import React, { useState, useCallback } from "react";
import { Page, Modal, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../history";

import { QuickGuidePolaris } from "../../components/tools/QuickGuidePolaris";
import { ProductCrawlerFormPolaris } from "../../components/tools/ProductCrawlerFormPolaris";
import { handleError } from "../../helper";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const CREATE_CRAWL_MUTATION = gql`
  mutation createCrawl($input: NewCrawl!) {
    createCrawl(input: $input) {
      id
      title
      description
    }
  }
`;

export const AddProductCrawlerPagePolaris = () => {
  // State
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Mutation
  const [createCrawl, { loading }] = useMutation(CREATE_CRAWL_MUTATION, {
    onCompleted: (res) => {
      setNotification(() => ({
        message: "Crawl successfuly.",
        isError: false,
      }));
      setTimeout(() => {
        history.push(`/tools/product-crawlers/${res.createCrawl.id}`);
      }, 2100);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });
  // Handle action
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const toggleOpen = useCallback(() => setOpen((open) => !open), []);
  const handleSubmit = useCallback((input) => {
    setNotification({
      message: null,
      isError: false,
    });
    if (input) {
      createCrawl({
        variables: {
          input,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /// Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Product Crawler"
        breadcrumbs={[
          { content: "All Crawler", url: "/tools/product-crawlers" },
        ]}
        primaryAction={{ content: "Quick Guide", onAction: toggleOpen }}
        fullWidth
      >
        <ProductCrawlerFormPolaris
          onSubmit={handleSubmit}
          loading={loading}
          onNotificationChange={({ message, isError }) =>
            setNotification(() => ({ message, isError }))
          }
          toggleActive={toggleActive}
          isCrawler
        />
      </Page>
      <Modal
        title="Quick Guide"
        open={open}
        sectioned
        large
        onClose={toggleOpen}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      >
        <QuickGuidePolaris />
      </Modal>
    </Container>
  );
};
