import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  FormLayout,
  Popover,
  Spinner,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useState } from "react";
import { handleError, reducerFn } from "../../../helper";
import { ORDER_STATUS } from "../../../variable";

const EXPORT_MUTATION = gql`
  mutation exportOrders($filter: ExportOrderFilter!) {
    exportOrders(filter: $filter) {
      id
      file {
        id
        url
      }
      createdAt
    }
  }
`;

const LIST_EXPORT_TEMPLATE = gql`
  query exportTemplates {
    exportTemplates {
      id
      name
    }
  }
`;

export const ExportButtonPolaris = (props) => {
  const { filter } = props;
  const [popoverActive, setPopoverActive] = useState(false);
  const [templateId, setTemplateId] = useState(["default"]);
  const [options, setOptions] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [disbaled, setDisabled] = useState(false);

  const [state, setState] = React.useReducer(reducerFn, {
    splitPrintUrl: true,
  });

  const [getTeamplate, { data, loading, error }] = useLazyQuery(
    LIST_EXPORT_TEMPLATE,
    {
      fetchPolicy: "cache-and-network",
    },
  );
  const [exportOrders, { data: dataE, loading: loadingE, error: errorE }] =
    useMutation(EXPORT_MUTATION, {
      onError: () => {},
      onCompleted: () => {},
    });

  useEffect(() => {
    if (popoverActive && !options.length) {
      getTeamplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverActive]);

  useEffect(() => {
    if (data) {
      let newData =
        data.exportTemplates && data.exportTemplates.length > 0
          ? data.exportTemplates.map((t) => ({
              value: t.id,
              label: t.name,
            }))
          : [];
      setOptions([{ value: "default", label: "Default Template" }, ...newData]);
    }
  }, [data]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((prev) => !prev),
    [],
  );

  const handleChange = useCallback((value) => setTemplateId(value), []);

  const handleSubmit = useCallback(() => {
    setClicked(true);
    let { supplierId } = filter;
    let newTemplateId = templateId.length && templateId[0];
    if ("default" === newTemplateId) {
      newTemplateId = undefined;
    }

    exportOrders({
      variables: {
        filter: {
          supplierId,
          status: ORDER_STATUS.ReadyToPrint,
          templateId: newTemplateId,
          splitPrintUrl: state.splitPrintUrl,
        },
      },
    })
      .then((res) => {
        window.location.href = res.data.exportOrders.file.url;
      })
      .catch(() => {})
      .finally(() => {
        setClicked(false);
        togglePopoverActive();
      });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, templateId, state.splitPrintUrl]);

  useEffect(() => {
    if (!clicked) return;

    setDisabled(true);
    let id = setTimeout(() => {
      setDisabled(false);
    }, 1000 * 60 * 10); // 10 minutes

    return () => {
      clearTimeout(id);
    };
  }, [clicked]);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (errorE || dataE) && (
        <Toast
          content={
            errorE
              ? handleError(errorE.toString())
              : dataE && "Export order success."
          }
          error={errorE}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const handleStateChange = React.useCallback((value, id) => {
    setState({ [id]: value });
  }, []);

  const activator = (
    <Button disclosure primary onClick={togglePopoverActive}>
      Export all Ready to Print
    </Button>
  );

  return (
    <>
      {toastMarkup}
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
        preferredAlignment="right"
        fluidContent
      >
        <Card sectioned>
          <FormLayout>
            {loading ? (
              <Spinner size="small" />
            ) : (
              <>
                {error ? (
                  <div>Error: {handleError(error.toString())}</div>
                ) : (
                  <ChoiceList
                    title={"Choose export template"}
                    choices={options}
                    selected={templateId}
                    onChange={handleChange}
                  />
                )}
              </>
            )}

            <Checkbox
              id="splitPrintUrl"
              label="Split Print Url"
              checked={state.splitPrintUrl}
              onChange={handleStateChange}
            />
            <Button
              fullWidth
              primary
              onClick={handleSubmit}
              children="Export order"
              loading={loadingE}
              disabled={loadingE ? false : disbaled}
            />
          </FormLayout>
        </Card>
      </Popover>
    </>
  );
};
