import React, { useCallback } from "react";
import { Stack, TextStyle, Icon } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const Container = styled.div`
  .btn-add {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
    min-height: 3.6rem;
    padding: 0.8rem 1.4rem;
    text-align: left;
    overflow-y: hidden;
    :hover {
      background-image: linear-gradient(
        rgba(223, 227, 232, 0.3),
        rgba(223, 227, 232, 0.3)
      );
    }
    :focus {
      outline: none;
    }
    .Polaris-Icon {
      margin-right: 3px;
    }
  }
`;

const ADD_MUTATION = gql`
  mutation createTag($name: String!) {
    createTag(name: $name) {
      id
      name
    }
  }
`;

export const QuickAddTagPolaris = ({ inputValue, onCompleted }) => {
  const [createTag] = useMutation(ADD_MUTATION, {
    onError: () => {},
  });

  const handleQuickAdd = useCallback(() => {
    createTag({
      variables: {
        name: inputValue,
      },
    })
      .then((res) => {
        let data = res.data.createTag;
        if (onCompleted) {
          onCompleted(data);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Container>
      <button className="btn-add" onClick={handleQuickAdd}>
        <Stack spacing="extraTight" wrap={false}>
          <Icon source={CirclePlusMinor} color="indigo" />
          <TextStyle variation="strong">Add </TextStyle>
          <TextStyle>{inputValue}</TextStyle>
        </Stack>
      </button>
    </Container>
  );
};
