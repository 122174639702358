import React, {
    useState,
    useCallback,
    useEffect,
    Fragment,
    useMemo,
    useRef,
} from "react";
import { DataTable, Checkbox, Badge, Stack, TextStyle } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import useToggle from "../../../hooks/useToggle";

import { BulkActions } from "../../shared/BulkActions";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { ImagWrap } from "../../product/RenderProductPolaris";
import { ViewModalImage } from "../ViewModalImage";
import { STATUS_BADGE_MOCKUP_2D } from "../../../constants";
import { MoreActions, STATUS_MOCKUP_2D } from "./actions/MoreActions";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { ReGen } from "./actions/ReGen";
import { ReviewBulkAction } from "./actions/ReviewBulkAction";
import { Delete } from "./actions/Delete";

export function Table({
    filter,
    data,
    setFilter,
    refetch,
    refetchCount,
    currentParam,
}) {
    // Prop
    const mockups = data?.productUsingGen2dMockups;
    const nodes = mockups?.nodes;

    // State
    const [rows, setRows] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [itemsChecked, setItemsChecked] = useState({});
    const [idsChecked, setIdsChecked] = useState([]);
    const refetchRef = useRef(null);

    // Handle actions
    const handleIdsChecked = useCallback((object) => {
        object = Object(object);
        let result = [];

        for (let [key, value] of Object.entries(object)) {
            if (value) {
                result.push(key);
            }
        }
        return result;
    }, []);

    const handleCheckedAll = useCallback(
        (newChecked) => {
            setCheckedAll(newChecked);
            let propsP = nodes?.length > 0 ? nodes : [];

            let idsChecked = [];
            if (newChecked) {
                let item = {};
                for (let i = 0; i < propsP.length; i++) {
                    item = {
                        ...item,
                        [propsP[i].id]: true,
                    };
                }
                setItemsChecked(item);
                idsChecked = handleIdsChecked(item);
            } else {
                setItemsChecked({});
                idsChecked = [];
            }
            setIdsChecked(idsChecked);
        },
        [nodes, handleIdsChecked]
    );

    const checkAllMarkup = useMemo(
        () => (
            <Checkbox
                label=""
                checked={checkedAll}
                onChange={(newChecked) => handleCheckedAll(newChecked)}
            />
        ),
        [checkedAll, handleCheckedAll]
    );
    const handleChecked = useCallback(
        (id, newChecked) => {
            let countItemsChecked;
            let idsChecked = [];

            setItemsChecked((prev) => {
                let result = { ...prev, [id]: newChecked };
                countItemsChecked = Object.values(result).filter((i) => i)
                    .length;

                // onSelect
                idsChecked = handleIdsChecked(result);
                return result;
            });

            if (newChecked) {
                setCheckedAll(true);
            } else if (!countItemsChecked) {
                setCheckedAll(false);
            }
            setIdsChecked(idsChecked);
        },
        [handleIdsChecked]
    );

    const handleCompleted = useCallback(() => {
        refetch();

        clearTimeout(refetchRef.current);
        refetchRef.current = setTimeout(() => {
            refetchCount();
        }, 100);
    }, [refetch, refetchCount]);

    // Get data
    useEffect(() => {
        const rows =
            nodes?.length > 0
                ? nodes
                      .map((node) => {
                          if (node == null) return null;

                          const mockupStatus = node.mockup2DGenerate?.status;
                          const bases = node.productBases;

                          // Checkbox
                          const id = node.id;
                          const checkbox = (
                              <div className="check-box-wrap">
                                  <Checkbox
                                      id={id}
                                      checked={!!itemsChecked[id]}
                                      onChange={(newChecked, id) =>
                                          handleChecked(id, newChecked)
                                      }
                                  />
                              </div>
                          );

                          // Product
                          const { title, shortTitle, amzTitle } = node || {};
                          const link =
                              bases?.length > 1
                                  ? `/${currentParam}/products/campaign`
                                  : `/${currentParam}/products/edit`;

                          const isStatusDone = mockupStatus === "Done";
                          const Element = isStatusDone
                              ? CustomLinkPolaris
                              : "span";
                          const linkMarkup = (
                              <TitleComp label="Origin Title">
                                  <Element href={`${link}/${id}`}>
                                      {title}
                                  </Element>
                              </TitleComp>
                          );

                          const personalizedMarkup = node.personalized && (
                              <Badge status="success" children="Personalized" />
                          );

                          const titleMarkup = (
                              <div className="product-wrap item">
                                  <Stack vertical spacing="extraTight">
                                      {linkMarkup}
                                      <div>
                                          <TitleComp
                                              label="FB Title"
                                              value={shortTitle}
                                          />
                                      </div>
                                      <div>
                                          <TitleComp
                                              label="AMZ Title"
                                              value={amzTitle}
                                          />
                                      </div>
                                      <div>{personalizedMarkup}</div>
                                      <div>
                                          <Badge children={node.sku} />
                                      </div>
                                  </Stack>
                              </div>
                          );

                          // Product base
                          const basesTitle =
                              bases?.length > 0
                                  ? bases
                                        .map((base) => base.title)
                                        .filter(Boolean)
                                        .join(", ")
                                  : null;

                          const basesMarkup = (
                              <div className="bases-wrap item">
                                  <span>{basesTitle}</span>
                              </div>
                          );

                          // Status
                          const status = STATUS_BADGE_MOCKUP_2D[mockupStatus];
                          const statusMarkup = (
                              <div className="status-wrap item">
                                  {status && (
                                      <Badge
                                          children={mockupStatus}
                                          status={status}
                                      />
                                  )}
                              </div>
                          );

                          // Collections
                          const cols = node.collections;
                          const colMarkup = cols.map((c, idx) => (
                              <Badge
                                  key={`collection-${idx}`}
                                  children={c.name}
                              />
                          ));
                          const collectionsMarkup = (
                              <div className="collections-wrap item">
                                  {colMarkup}
                              </div>
                          );

                          // Tags
                          const tags = node.tags;
                          const tagMarkup = tags.map((c, idx) => (
                              <Badge key={`tag-${idx}`} children={c.name} />
                          ));
                          const tagsMarkup = (
                              <div className="tags-wrap item">{tagMarkup}</div>
                          );

                          // Image
                          const imageMarkup = (
                              <div className="image-wrap item">
                                  <ImageComp item={node} />
                              </div>
                          );

                          // More actions
                          const actionMarkup = (
                              <div className="actions-wrap item">
                                  <MoreActions
                                      item={node}
                                      mockupStatus={mockupStatus}
                                      handleCompleted={handleCompleted}
                                      currentParam={currentParam}
                                  />
                              </div>
                          );

                          return [
                              checkbox,
                              imageMarkup,
                              titleMarkup,
                              basesMarkup,
                              statusMarkup,
                              collectionsMarkup,
                              tagsMarkup,
                              actionMarkup,
                          ];
                      })
                      .filter(Boolean)
                : [];
        setRows(rows);
    }, [nodes, handleChecked, itemsChecked, handleCompleted, currentParam]);

    // Markup
    const headings = [
        checkAllMarkup,
        "",
        "Product",
        "Product Base",
        "Status",
        "Collections",
        "Tags",
        <div className="actions-wrap">Actions</div>,
    ];
    const colTypes = new Array(headings.length - 1)
        .fill("text")
        .concat("numeric");

    // variables
    const { limit, offset, status } = filter;
    const total = get(mockups, "total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;

    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: setFilter,
        total,
    };

    const isNeedReview = [STATUS_MOCKUP_2D.NeedReview].includes(status);
    const isReGen = ["Error", "Rejected"].includes(status);

    return (
        <Fragment>
            <BulkActions
                idsChecked={idsChecked}
                onClose={() => handleCheckedAll(false)}
            >
                {isReGen && (
                    <ReGen
                        idsChecked={idsChecked}
                        handleCompleted={handleCompleted}
                    />
                )}
                {isNeedReview && (
                    <ReviewBulkAction
                        idsChecked={idsChecked}
                        handleCompleted={handleCompleted}
                    />
                )}
                <Delete
                    idsChecked={idsChecked}
                    handleCompleted={handleCompleted}
                />
            </BulkActions>
            <Wrapper>
                <DataTable
                    rows={rows}
                    columnContentTypes={colTypes}
                    headings={headings}
                    hideScrollIndicator
                    verticalAlign="middle"
                />
            </Wrapper>
            <PaginationWrapper>
                <PaginationPolaris aggregation={aggregation} showTotal />
            </PaginationWrapper>
        </Fragment>
    );
}

function ImageComp({ item }) {
    // State
    const [open, toggleOpen] = useToggle(false);

    const images =
        item.images?.length > 0 ? item.images.filter((i) => i.file) : [];
    const image = images?.length > 0 ? images[0] : null;
    const file = image?.file;

    const imageMarkup = (
        <div
            style={{ cursor: file ? "pointer" : "initial" }}
            onClick={() => (file ? toggleOpen() : {})}
        >
            <ImagWrap file={file} />
        </div>
    );

    return (
        <Fragment>
            {imageMarkup}
            <ViewModalImage
                item={item}
                open={open}
                toggleShowModal={toggleOpen}
            />
        </Fragment>
    );
}

function TitleComp({ label, value, children }) {
    return value || children ? (
        <Fragment>
            <TextStyle variation="strong" children={`${label}: `} />
            {children ? children : <span>{value}</span>}
        </Fragment>
    ) : null;
}

const Wrapper = styled.div`
    overflow-x: auto;

    .Polaris-DataTable__ScrollContainer {
        overflow-x: inherit;
    }

    .item {
        white-space: normal;
        word-break: break-word;
    }

    .product-wrap {
        width: calc(40rem - 3.2rem);
    }

    .bases-wrap {
        width: calc(20rem - 3.2rem);
    }

    .status-wrap {
        width: calc(15rem - 3.2rem);
    }

    .actions-wrap {
        width: calc(23rem - 3.2rem);

        .Polaris-ButtonGroup {
            justify-content: end;
        }
    }

    .collections-wrap,
    .tags-wrap {
        width: calc(20rem - 3.2rem);
        margin-left: -0.7rem;
        margin-top: -0.9rem;

        > span {
            margin-top: 1rem;
            margin-left: 1rem;
        }

        .Polaris-Badge {
            max-width: 100%;
            .Polaris-Badge__Content {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
`;

const PaginationWrapper = styled.div`
    padding: 1.6rem;
    background: var(--p-surface-subdued, #ebeef1);
    text-align: center;
    border-radius: var(--p-border-radius-wide, 3px);
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-x: scroll;
`;
