import React, { Fragment, useCallback, useState } from "react";
import { Button, ButtonGroup } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import { Assign } from "./Assign";
import { Trash } from "./Trash";

export function ActionGroup({ item }) {
    const [open, toggleOpen] = useToggle(false);
    const [action, setAction] = useState(null);

    const handleAction = useCallback((action) => {
        setAction(action);
        toggleOpen();
    }, [toggleOpen]);

    const props = {
        item,
        open,
        onClose: toggleOpen,
    };
    const actionsMarkup = {
        assign: <Assign {...props} />,
        trash: <Trash {...props} />,
    };

    return (
        <Fragment>
            <ButtonGroup>
                <Button
                    children="Assign"
                    primary
                    onClick={() => handleAction("assign")}
                />
                <Button
                    children="Trash"
                    destructive
                    onClick={() => handleAction("trash")}
                />
            </ButtonGroup>
            {actionsMarkup[action]}
        </Fragment>
    );
}
