import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const productBaseVariantsQuery = gql`
  query productBaseVariantSelect($productBaseId: ID!) {
    productBaseVariantSelect(productBaseId: $productBaseId) {
      id
      name
    }
  }
`;

export const ProductBaseVariantSelectPolaris = (props) => {
  const { productBaseId, onChange, error } = props;
  const [getData, { data, loading }] = useLazyQuery(productBaseVariantsQuery);

  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productBaseId) {
      setSelectedOptions([]);
      setInputValue("");
      getData({
        variables: {
          productBaseId: productBaseId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBaseId]);

  useEffect(() => {
    let newData =
      data && data.productBaseVariantSelect
        ? data.productBaseVariantSelect.map((pb) => ({
            value: pb.id,
            label: pb.name,
          }))
        : [];
    setDeselectedOptions(newData);
    setOptions(newData);
  }, [data]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (!value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      let value = selected ? selected[0] : null;
      let label = selectedValue ? selectedValue[0] : "";
      setInputValue(label);
      if (onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      label="Product base variant"
      labelHidden
      value={inputValue}
      onChange={handleInputChange}
      disabled={!productBaseId}
      error={error}
    />
  );

  return (
    <>
      <ComponentLabelPolaris label="Product base variant" required />
      {loading ? (
        <Spinner size="small" />
      ) : (
        <Autocomplete
          textField={textField}
          selected={selectedOptions}
          onSelect={handleSection}
          options={options}
          emptyState={<span>No items found!</span>}
        />
      )}
    </>
  );
};
