import { gql } from "apollo-boost";

export const ProductGen2dMockupsFragment = gql`
    fragment ProductGen2dMockupsFragment on Product {
        id
        productBases {
            id
            title
            attributes {
                name
                slug
                options
            }
            designPositions {
                id
                name
                description
            }
            variants {
                id
                attributes {
                    name
                    slug
                    option
                }
            }
        }
        title
        shortTitle
        amzTitle
        sku
        status
        description
        personalized
        fields {
            id
            name
            title
            type
            extraFee
            configure
            sorting
        }
        collections {
            id
            name
        }
        tags {
            id
            name
        }
        mockup2DGenerate {
            id
            status
            isNeedReview
            productBaseOnlyUseDesignForGenMockup
            randomMockupTemplate {
                productBaseId
                qty
            }
        }
        images {
            id
            file {
                id
                key
                name
                url
                thumbnailUrl
                mimeType
            }
        }
        variants {
            id
            disabled
            regularPrice
            productBaseVariantId
            productBaseVariant {
                id
                productBase {
                    id
                }
                attributes {
                    name
                    slug
                    option
                }
            }
        }
        designPositions {
            id
            designPosition {
                id
            }
            file {
                id
                key
                name
                url
                thumbnailUrl
                mimeType
            }
        }
    }
`;
