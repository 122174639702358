import { Card, Page } from "@shopify/polaris";
import React from "react";
import { ReplaceFeed } from "../../components/feed/ReplaceFeed";
import { WrapperPage } from "../../components/shared/WrapperPage";
import { useAppContext } from "../../context";
import { getParam } from "../../helper";

export function ReplaceFeedPage(...props) {
  const { currentUser } = useAppContext();
  const path = getParam(currentUser);

  const pathname = `/${path}/products/feeds`;

  return (
    <WrapperPage>
      <Page
        title="Replace Feed"
        fullWidth
        breadcrumbs={[{ content: "Product Feeds", url: pathname }]}
      >
        <Card sectioned>
          <ReplaceFeed pathname={pathname} />
        </Card>
      </Page>
    </WrapperPage>
  );
}
