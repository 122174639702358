import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";

ChartJS.register(ArcElement, Tooltip, Legend);

const LABELS = {
    etsy: "Etsy",
    ebay: "Ebay",
    amazon: "Amazon",
    tiktok: "Tittok",
    facebookMarketplace: "Facebook MK",
    onlineStore: "Online stores",
};

const ORIGINAL_KEY = "original";
const COMPARE_KEY = "compare";
const DATASETS = [
    {
        label: "# of Votes",
        key: COMPARE_KEY,
        data: [],
        backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(96, 202, 61, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(12, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
    },
    {
        label: "# of Votes",
        key: ORIGINAL_KEY,
        data: [],
        backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(96, 202, 61, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(12, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
    },
];

export function PieChart({ data, dataCompare }) {
    // State
    const [dataResources, setDataResources] = useState({
        labels: Object.values(LABELS),
        datasets: DATASETS,
    });
    // Context
    const { isCompare } = useReportsContext();

    // Get data
    const reportC = dataCompare?.reportSaleChanelOverview;
    useEffect(() => {
        const report = data?.reportSaleChanelOverview;
        if (report == null) return;

        const labels = [];
        const haveCompare = isCompare && reportC != null;
        const newData = Object.keys(LABELS).reduce((acc, cur) => {
            const value = report[cur] || 0;
            const valueC = haveCompare ? reportC[cur] || 0 : "";
            const label = `${LABELS[cur]} (${value} ${
                haveCompare ? "| " + valueC : ""
            })`;
            labels.push(label);
            return [...acc, value];
        }, []);

        const newDataCompare = haveCompare
            ? Object.keys(LABELS).reduce((acc, cur) => {
                  const value = reportC[cur] || 0;
                  return [...acc, value];
              }, [])
            : [];

        setDataResources((prev) => {
            const datasets = prev.datasets.find(
                (el) => el.key === ORIGINAL_KEY
            );
            const newDatasets = haveCompare
                ? DATASETS.map((el) => {
                      const { key } = el;
                      const isOriginal = key === ORIGINAL_KEY;

                      return {
                          ...el,
                          data: isOriginal ? newData : newDataCompare,
                      };
                  })
                : [{ ...datasets, data: newData }];

            const newDataResource = {
                ...prev,
                labels,
                // datasets: [
                //     {
                //         ...datasets,
                //         data: newData,
                //     },
                // ],
                datasets: newDatasets,
            };
            return { ...newDataResource };
        });
    }, [data, reportC, isCompare]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            layout: {
                padding: 20,
            },
            legend: {
                position: "right",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || "";
                        let parsed = context.parsed || 0;
                        const dataset = context.dataset;
                        const isCompare = dataset?.key === COMPARE_KEY;

                        if (label) {
                            const pattern = /\(/;
                            const match = label.match(pattern);

                            const index = match?.index;
                            if (index != null) {
                                label = label.substring(0, index);
                            }
                            const subL = isCompare ? " - Previous" : "";
                            label = `${label}${subL}: ${parsed}`;
                        }
                        return label;
                    },
                },
            },
        },
    };

    const totalUnits = data?.reportSaleChanelOverview.totalUnits;

    return totalUnits === 0 ? (
        <EmptyStatePolaris noData />
    ) : (
        <Pie
            data={dataResources}
            options={options}
            height={isCompare && reportC != null ? 350 : 270}
        />
    );
}
