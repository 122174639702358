import React, { useState, useCallback } from "react";
import { Button, ButtonGroup, Checkbox } from "@shopify/polaris";
import styled from "styled-components";

import { DeleteProductAssortmentPolaris } from "./actions/DeleteProductAssortmentPolaris";
import { AssignButtonPolaris } from "./actions/AssignButtonPolaris";
import { AssignTemplateButtonPolaris } from "./actions/AssignTemplateButtonPolaris";
import { ChangeTitlePolaris } from "./actions/ChangeTitlePolaris";
import { useAppContext } from "../../context";

const Container = styled.div`
    .Polaris-Choice {
        padding: 0;
        .Polaris-Choice__Control {
            margin-top: 0.1rem;
        }
    }

    ${({ isMobile }) =>
        isMobile &&
        `
        .Polaris-ButtonGroup {
            flex-wrap: wrap;
            row-gap: 1rem;
        }
    `}
`;

export const BulkActionsPolaris = (props) => {
    const {
        idsChecked,
        toggleActive,
        onNotificationChange,
        onCompleted,
        currentChecked,
        onCheckAll,
        data,
    } = props;

    // context
    const { isMobile } = useAppContext();

    const [open, setOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);

    const toggleShowModal = useCallback((value) => {
        setOpen((prev) => !prev);
        setCurrentAction(value);
    }, []);

    let dataCheckeds =
        data?.productAssortment?.nodes?.length > 0
            ? data.productAssortment.nodes.filter((a) =>
                  idsChecked.includes(a.id)
              )
            : [];

    const handleCompleted = React.useCallback(() => {
        toggleShowModal();
        setCurrentAction(null);
    }, [toggleShowModal]);

    return (
        <Container isMobile={isMobile}>
            <ButtonGroup segmented>
                <Button
                    children={
                        <Checkbox
                            label={`${idsChecked.length} selected`}
                            checked={true}
                        />
                    }
                    onClick={onCheckAll}
                />
                <Button
                    children="Assign use template"
                    onClick={() => toggleShowModal("assignTemplate")}
                    disclosure
                />
                <Button
                    children={"Assign"}
                    onClick={() => toggleShowModal("assign")}
                    disclosure
                />
                <Button
                    children={"Change Titles"}
                    onClick={() => toggleShowModal("changeTitle")}
                    disclosure
                />
                <Button
                    children="Delete"
                    destructive
                    onClick={() => toggleShowModal("delete")}
                />
            </ButtonGroup>
            <AssignTemplateButtonPolaris
                open={open && "assignTemplate" === currentAction}
                toggleShowModal={toggleShowModal}
                ids={idsChecked}
                onNotificationChange={onNotificationChange}
                toggleActive={toggleActive}
                onCompleted={onCompleted}
                currentChecked={currentChecked}
                dataCheckeds={dataCheckeds}
                bulkActions
            />
            <AssignButtonPolaris
                open={open && "assign" === currentAction}
                toggleShowModal={toggleShowModal}
                ids={idsChecked}
                onNotificationChange={onNotificationChange}
                toggleActive={toggleActive}
                onCompleted={onCompleted}
                currentChecked={currentChecked}
                bulkActions
                dataCheckeds={dataCheckeds}
            />
            <ChangeTitlePolaris
                open={open && "changeTitle" === currentAction}
                toggleShowModal={toggleShowModal}
                ids={idsChecked}
                bulkSpecial
                isBulkActions
                onCompleted={handleCompleted}
            />
            <DeleteProductAssortmentPolaris
                open={open && "delete" === currentAction}
                toggleShowModal={toggleShowModal}
                ids={idsChecked}
                onNotificationChange={onNotificationChange}
                toggleActive={toggleActive}
                onCompleted={onCompleted}
            />
        </Container>
    );
};
