import React from "react";
import { EditProductBase } from "../../components/seller/components";
import { ToastContextProvider } from "../../components/shared/ToastContext";

export function EditProductBasePage() {
  return (
    <div className="p-4">
      <ToastContextProvider>
        <EditProductBase />
      </ToastContextProvider>
    </div>
  );
}
