import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

import { handleError, getUnique } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export const StoresSelectPolaris = ({
  stores,
  onChange,
  errors,
  error,
  value,
  allowMultiple,
  labelHidden,
}) => {
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);

  useEffect(() => {
    setDeselectedOptions(stores);
    setOptions(stores);
    const selectedValue =
      [value] &&
      [value].map((selectedItem) => {
        const matchedOptions =
          stores &&
          stores.find((option) => {
            return option.value === selectedItem;
          });
        return matchedOptions && matchedOptions.label;
      });
    setInputValue(_.head(selectedValue));
  }, [stores, value]);

  useEffect(() => {
    let newValue = allowMultiple ? value : [value];
    setSelected(newValue);

    let newData = stores;
    let couple =
      newData && newData.length > 0
        ? newData.filter((d) => _.isArray(value) && value.includes(d.value))
        : [];

    setDeselectedOptionsShow((prevState) => {
      let result = getUnique([...prevState, ...couple], "value");
      return result;
    });

    if (!newValue.length) {
      setDeselectedOptionsShow([]);
    }
  }, [value, allowMultiple, stores]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelected(selected);

      let newData = stores || [];
      let couple =
        newData && newData.length > 0
          ? newData.filter((d) => selected.includes(d.value))
          : [];

      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...couple], "value");
        result = result.filter((i) => selected.includes(i.value));
        return result;
      });

      if (onChange) {
        if (allowMultiple) {
          onChange(selected);
          return;
        } else {
          onChange(_.head(selected));
        }
      }
      setInputValue(_.head(selectedValue));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, stores]
  );

  const textField = (
    <Autocomplete.TextField
      label="Store(*)"
      placeholder="Search store"
      value={inputValue}
      labelHidden={true}
      onChange={handleInputChange}
      error={errors}
    />
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.value !== value
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange && allowMultiple) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, deselectedOptionsShow]
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {!labelHidden ? (
        <ComponentLabelPolaris label={"Stores"} required />
      ) : null}
      <Autocomplete
        options={options}
        selected={selected}
        onSelect={handleSelection}
        textField={textField}
        allowMultiple={allowMultiple}
        emptyState={<span>No items found!</span>}
      />
      {allowMultiple && (
        <TagContainer>
          {deselectedOptionsShow && deselectedOptionsShow.length > 0
            ? deselectedOptionsShow.map((item) => (
                <Tag
                  children={item.label}
                  onRemove={() => handleRemove(item.value)}
                  key={item.value}
                />
              ))
            : null}
        </TagContainer>
      )}
    </>
  );
};
