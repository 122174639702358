import { useMutation } from "@apollo/react-hooks";
import React, { useState, useRef, useCallback } from "react";
import { Modal, Stack, Select } from "@shopify/polaris";
import { useAppContext } from "../../../../../context";
import { ASSIGN_IDEA_TASK_TO_DESIGNER } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";
import { KPI_OPTIONS } from "./AssignToDesigner";
import { ComponentLabelPolaris } from "../../../../shared/ComponentLabelPolaris";

export function TakeTask({ item, onCloseParent, ...props }) {
    // Props
    const ideaTaskIds = item?.id != null ? [item.id] : [];

    // State
    const [kpi, setKpi] = useState("0.5");
    const onCloseRef = useRef(null);

    // Context
    const { currentUser } = useAppContext();
    const userId = currentUser?.id;
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [assignIdeaTask, { loading }] = useMutation(
        ASSIGN_IDEA_TASK_TO_DESIGNER,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Take this idea task successfully.",
                    err: false,
                });
                props.onClose && props.onClose();
                onCloseParent && onCloseParent();

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    setFilter && setFilter((prev) => ({ ...prev }));
                    refetch && refetch();
                }, 1000);
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    // Actions
    const handleKpi = useCallback((value) => setKpi(value), []);
    const handleSubmit = useCallback(() => {
        if (ideaTaskIds?.length > 0 && kpi != null) {
            const newKpi = parseFloat(kpi);
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            assignIdeaTask({
                variables: {
                    input: {
                        ideaTaskIds,
                        designerId: userId,
                        kpi: newKpi,
                    },
                },
            });
        }
    }, [userId, kpi, ideaTaskIds, assignIdeaTask, toggleToast, setNotify]);

    return (
        <Modal
            {...props}
            sectioned
            title="Task this idea task"
            large
            secondaryActions={[{ content: "Close", onAction: props.onClose }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                disabled: userId == null,
                loading: loading,
            }}
        >
            <Stack vertical>
                <ComponentLabelPolaris label="KPI" required />
                <Select
                    options={KPI_OPTIONS}
                    value={kpi}
                    onChange={handleKpi}
                />
            </Stack>
        </Modal>
    );
}
