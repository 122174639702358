import { get } from "lodash";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../../../context";
import { checkRole } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { WidgetCompProvider } from "../../widget/context";
import { ELEMENTS } from "./EtsyChannel";
import { LayoutSection, TITLE_PAGE } from "./LayoutSection";
import { TEAM_STATISTIC_ELEMENT } from "./OverviewsAdmin";

const StoreVal = "stores";
export function EbayChannel() {
  const { currentUser } = useContext(AppContext);
  const { isAdministrator } = checkRole(currentUser);
  const userRole = get(currentUser, "teamUser.role", null);
  const isMarketplaceM = [TEAM_ROLE.MarketplaceManager].includes(userRole);

  const ELEMENTS_PROPS = useMemo(() => {
    return ELEMENTS.map((el) => {
      if (isMarketplaceM && el.value === StoreVal) {
        el.default = 1;
      }
      return el;
    }).filter((el) => el.roles.includes(userRole));
  }, [userRole, isMarketplaceM]);

  return (
    <WidgetCompProvider
      title={TITLE_PAGE.Ebay}
      elements={isAdministrator ? [TEAM_STATISTIC_ELEMENT, ...ELEMENTS] : ELEMENTS_PROPS}
    >
      <LayoutSection title={TITLE_PAGE.Ebay} />
    </WidgetCompProvider>
  );
}
