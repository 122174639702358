import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REPORT_FULFILL_COUNTER } from "../../../graphql/queries";
import { formatter, getUnique } from "../../../helper";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";

const collapseKey = "fullfillCounter";
const styleColumn = {
  width: "10rem",
  display: "inline-block",
};
export function FulfillCounter({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    saleChannel: saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    totalSale: 0,
    totalBaseCost: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalSale: 0,
    totalBaseCost: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, fetchMore } = useQuery(REPORT_FULFILL_COUNTER, {
    variables: {
      filter,
    },
  });

  const [lazyReport, { data: dataC, loading: loadingC }] = useLazyQuery(
    REPORT_FULFILL_COUNTER,
  );

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      let ignoreFilter = filterCtx;
      if ("fulfillmentID" in filterCtx) {
        let fulfillmentID;
        ({ fulfillmentID, ...ignoreFilter } = filterCtx);
      }

      setFilter((prev) => ({ ...prev, ...ignoreFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamID: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportFulfillCounter?.nodes;
    const totalSale = data?.reportFulfillCounter?.totalSale;
    const totalBaseCost = data?.reportFulfillCounter?.totalBaseCost;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            const totalSale = node?.totalSale;
            const totalBaseCost = node?.totalBaseCost;
            const name = node?.fulfillName;
            return {
              name,
              totalSale,
              totalBaseCost,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      totalSale,
      totalBaseCost,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalSale = dataC?.reportFulfillCounter?.totalSale;
      const totalBaseCost = dataC?.reportFulfillCounter?.totalBaseCost;
      setStateCompare((prev) => ({
        ...prev,
        totalSale,
        totalBaseCost,
      }));
    } else {
      setStateCompare({ totalSale: 0, totalBaseCost: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportFulfillCounter.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportFulfillCounter = fetchMoreResult.reportFulfillCounter;
            const nodes = reportFulfillCounter.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;
            setLoadMore(false);
            if (nodes.length < limit) {
              setCantLoad(true);
            }

            const newNodes = [...prev.reportFulfillCounter.nodes, ...nodes];

            const result = getUnique(newNodes, "fulfillName");

            setState((p) => ({
              ...p,
            }));

            return {
              ...prev,
              reportFulfillCounter: {
                ...prev.reportFulfillCounter,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => (
          <Stack key={`group-manager-${index}`} wrap={false}>
            <span className="index-wrap">{index + 1}</span>
            <Stack.Item fill>{item.name}</Stack.Item>
            <span style={styleColumn}>{item.totalSale}</span>
            <span style={styleColumn}>
              {formatter.format(item.totalBaseCost || 0)}
            </span>
          </Stack>
        ))
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">Fulfillment Name</TextStyle>
      </Stack.Item>
      <span style={styleColumn}>Sales</span>
      <span style={styleColumn}>Base cost</span>
    </Stack>
  );

  const subTitleMarkup = useMemo(
    () => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <span>{state.totalSale} orders</span>
          <ComparedPercent
            originalVal={state.totalSale}
            newVal={stateCompare.totalSale}
          />
        </span>
      </div>
    ),
    [state.totalSale, stateCompare.totalSale],
  );

  return (
    <CardBox
      title="Fulfillment Counter"
      subTitle={subTitleMarkup}
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      total={(state.items || []).length}
      loading={mergedLoading}
      setFilter={setFilter}
      handleScroll={handleScroll}
      loadMore={loadMore}
      collapseKey={collapseKey}
    />
  );
}
