import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/mblogo_dark.png";
import logo from "../../assets/images/mb_dark_logo.png";

export default function BrandStore(props) {
  const classNameProps = props.className;
  return (
    <Link to="/" className={`lg:inline-block hidden ${classNameProps}`}>
      <img className={"mb-logo"} src={logo} alt={"MerchBridge.com"} />
    </Link>
  );
}
