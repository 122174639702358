import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { DELETE_STORE } from "../../../../graphql/mutations";
import { handleError } from "../../../../helper";

export function Delete({
  storeId,
  onClose: toggleOpen,
  open,
  setNotify,
  toggleActiveToast,
  refetch,
}) {
  // Mutation
  const timeoutRef = React.useRef(null);
  const [deleteStore, { loading }] = useMutation(DELETE_STORE, {
    onCompleted: () => {
      setNotify &&
        setNotify({ msg: `Delete account successfully.`, err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }
    },
    onError: (error) => {
      setNotify && setNotify({ msg: handleError(error.toString()), err: true });
    },
  });

  // Handle actions
  const handleSubmit = React.useCallback(() => {
    if (storeId != null) {
      toggleActiveToast && toggleActiveToast(true);
      setNotify && setNotify({ msg: null, err: false });
      deleteStore({
        variables: {
          id: storeId,
        },
      });
    }
  }, [deleteStore, storeId, toggleActiveToast, setNotify]);

  return (
    <Modal
      title="Delete account"
      open={open}
      onClose={toggleOpen}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to delete this account?</p>
    </Modal>
  );
}
