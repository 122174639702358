import React, { useState, useCallback, useRef, useEffect } from "react";
import { TextField, ChoiceList, Spinner } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

import { handleError } from "../../helper";

const Container = styled.div`
  .choicelist-wrapp {
    margin-top: 1rem;
  }
  .Polaris-ChoiceList__Choices {
    max-height: 280px;
    overflow-y: scroll;
    padding-left: 2px;
  }
`;

export const FilterHasSearchPolaris = ({
  options,
  value,
  loading,
  error,
  onChange,
  onChangeSearch,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const typingTimeoutId = useRef(null);

  useEffect(() => {
    if (value.value) {
      setSelectedOption([value.value]);
    } else {
      setSelectedOption([]);
    }
    if (value.search) {
      setInputValue(value.search);
    } else {
      setInputValue(null);
    }
  }, [value]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }
    if (onChangeSearch) {
      typingTimeoutId.current = setTimeout(() => {
        onChangeSearch(value ? value.trim() : value);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOption(selected);

      // Format label
      let firstLabel = _.head(selectedValue);
      if ("string" !== typeof firstLabel) {
        let children = _.get(firstLabel, "props.children", null);
        if (Array.isArray(children)) {
          let currentLabel = children.find((i) => {
            return i?.props?.className === "title";
          });
          firstLabel = currentLabel?.props?.children;
        } else {
          firstLabel = children?.props?.children;
        }
      }
      if (onChange) {
        onChange({
          value: _.head(selected),
          label: firstLabel,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <Container>
      <TextField
        label="input"
        labelHidden
        placeholder="Filter item"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="mt-4">
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <div className="flex justify-center">
            <Spinner
              accessibilityLabel="Spinner example"
              color="teal"
              size="small"
            />
          </div>
        ) : (
          <>
            {options.length > 0 ? (
              <div className="choicelist-wrapp">
                <ChoiceList
                  title="choice"
                  titleHidden
                  choices={options}
                  selected={selectedOption}
                  onChange={handleSelection}
                />
              </div>
            ) : (
              <span>No items found!</span>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
