import React, { Component } from "react";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { Popconfirm, notification } from "antd";
import { EXPORT_TEMPLATE } from "./ListExportTemplates";
import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import history from "../../history";

const CLONE_TEMPLATE = gql`
  mutation cloneExportTemplate($id: ID!) {
    cloneExportTemplate(id: $id) {
      id
      name
      author {
        id
        firstName
        lastName
        roles
      }
      suppliers {
        id
        firstName
        lastName
      }
    }
  }
`;
export default class ActionCloneTemplate extends Component {
  state = {
    loading: false,
  };

  render() {
    const { id, type, router } = this.props;
    return (
      <Mutation mutation={CLONE_TEMPLATE}>
        {(removeTemplate, { client }) => (
          <Popconfirm
            title="Are you sure clone this export file template?"
            onConfirm={() => {
              this.setState({ loading: true });
              removeTemplate({ variables: { id } })
                .then((res) => {
                  notification.success({
                    message: "Clone file template success.",
                  });
                  this.setState({ loading: false });
                  router && history.push(router);
                  if (res && res.data && res.data.cloneExportTemplate) {
                    try {
                      const cache = client.readQuery({
                        query: EXPORT_TEMPLATE,
                        variables: { search: null },
                      });

                      client.writeQuery({
                        query: EXPORT_TEMPLATE,
                        variables: { search: null },
                        data: {
                          ...cache,
                          exportTemplates: [
                            ...cache.exportTemplates,
                            res.data.cloneExportTemplate,
                          ],
                        },
                      });
                    } catch (_) {}
                  }
                })
                .catch((err) => {
                  notification.error({ message: err.toString() });
                  this.setState({ loading: false });
                });
            }}
          >
            <Button
              type={type ? type : "dashed"}
              loading={this.state.loading}
              icon={<CopyOutlined />}
            >
              Clone
            </Button>
          </Popconfirm>
        )}
      </Mutation>
    );
  }
}
