import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import React, { useCallback, useRef, useState } from "react";
import { ADD_NOTE_BY_DEV } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function AddNoteByDev({ onClose, open, refetch, value }) {
  const { id } = value || {};
  const [note, setNote] = useState("");
  const timeRef = useRef(null);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  const [addNote, { loading }] = useMutation(ADD_NOTE_BY_DEV, {
    onCompleted: () => {
      setNotify({ msg: "Add note success.", err: false });
      if (refetch) {
        if (timeRef.current) clearTimeout(timeRef.current);
        timeRef.current = setTimeout(() => {
          refetch();
        }, 1200);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const handleSubmit = useCallback(() => {
    if (id && note) {
      toggleToast && toggleToast(true);
      setNotify({ msg: null, err: false });

      addNote({
        variables: {
          input: { orderID: id, note },
        },
      });
    }
  }, [addNote, toggleToast, setNotify, id, note]);

  return (
    <Modal
      title="Add note by Dev"
      open={open}
      sectioned
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
        disabled: !note || note.length === 0,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <TextField
        value={note}
        onChange={setNote}
        placeholder="Enter note"
        label="Note"
        multiline={4}
        autoFocus
      />
    </Modal>
  );
}
