import { Card } from "@shopify/polaris";
import React from "react";

export function ProductHeader({ haveActions, handleDelete, handleDuplicate }) {
    const deleteAction = {
        content: "Delete",
        destructive: true,
        onAction: handleDelete,
    };
    const duplicateAction = { content: "Duplicate", onAction: handleDuplicate };
    const listActions = [deleteAction, duplicateAction];
    const actions = haveActions ? listActions : [];

    return <Card.Header title="Product Info" actions={actions} />;
}
