import { useMutation } from "@apollo/react-hooks";
import { Checkbox, Collapsible, InlineError, Modal } from "@shopify/polaris";
import React from "react";
import { CLONE_ORDER_TO_RESEND } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { ProductAutocomplete } from "../../product/components/ProductAutocomplete";
import { useToastContext } from "../../shared/ToastContext";

export function CloneOrderToResend({ open, onClose, refetch, value }) {
  const { id: orderId } = value || {};
  const [disabled, setDisabled] = React.useState(false);
  const [changeProduct, setChangeProduct] = React.useState(false);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Ref
  const formRef = React.useRef(null);

  // Mutation
  const timeoutRef = React.useRef(null);
  const [clone, { loading }] = useMutation(CLONE_ORDER_TO_RESEND, {
    onCompleted: () => {
      setNotify({ msg: "Clone order to resend success.", err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!orderId) return;
    let input = { orderId };
    if (changeProduct) {
      const fields = formRef.current?.onSubmit();
      input = {
        ...fields,
        ...input,
      };
    }

    toggleToast && toggleToast(true);
    setNotify({ msg: null, err: false });

    clone({
      variables: {
        input,
      },
    });
  }, [orderId, changeProduct, clone]);
  const handleChangeProduct = React.useCallback((val) => {
    setDisabled(!!val);
    setChangeProduct(val);
  }, []);

  return (
    <Modal
      title="Clone to Resend"
      open={open}
      onClose={onClose}
      sectioned
      large
      secondaryActions={[{ content: "Cancel", onAtion: onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading,
        disabled,
      }}
    >
      <InlineError message="Notes: This action will generate a new order with the same information as the original order." />
      <Checkbox
        label="Change product info"
        checked={changeProduct}
        onChange={handleChangeProduct}
      />
      <Collapsible
        open={!!changeProduct}
        id="clone-to-resend-collapsible"
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <ProductAutocomplete
          ref={formRef}
          setDisabled={setDisabled}
          orderId={orderId}
        />
      </Collapsible>
    </Modal>
  );
}
