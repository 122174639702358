import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { getParamByRole, isPME_TEAM } from "../../helper";
import history from "../../history";
import { usePrivileges } from "../../hooks/usePrivileges";
import { DESIGNER_SPECIAL } from "../../variable";
import { LIST_TEAM } from "../seller/ListTeamMember";
import { MemberFormPolaris } from "./MemberFormPolaris";

export const CREATE_USER_TEAM = gql`
  mutation createUserTeam($input: NewUserTeam!) {
    createUserTeam(input: $input) {
      id
      role
      team {
        id
      }
      user {
        id
        firstName
        lastName
        roles
        email
        phone
        address
        avatar {
          id
          url
        }
      }
      status
    }
  }
`;

export const loadTeamRoles = gql`
  query teamRoles {
    teamRoles
    stores(filter: { limit: 1000, offset: 0 }) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddMemberPolaris = () => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const { currentUser } = useContext(AppContext);
  const currentUserId = currentUser?.id;

  let currentParam = getParamByRole(currentUser);

  const [
    createUserTeam,
    {
      data: dataMutation,
      loading: loadingMutation,
      error: errorMutation,
      client,
    },
  ] = useMutation(CREATE_USER_TEAM, {
    onCompleted: () => {
      const id = setTimeout(() => {
        handleRedirect();
      }, 2500);
      setTimeoutId(id);
    },
    onError: () => {},
  });

  const { data, loading, error: errorQuery } = useQuery(loadTeamRoles);
  const choices =
    data && data.teamRoles && data.teamRoles.length
      ? _.map(data.teamRoles, (v) => {
          let isDS = DESIGNER_SPECIAL.accIds.includes(currentUserId);
          let apply = !DESIGNER_SPECIAL.role.includes(v);
          let disabled = isDS && apply;

          return {
            value: v,
            label: v,
            disabled,
          };
        })
      : //   .filter(({ value }) => value !== "Supplier")
        [];

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleCompleted = useCallback((input) => {
    createUserTeam({
      variables: {
        input,
      },
    })
      .then((res) => {
        const user = res.data.createUserTeam;
        let filter = {
          search: null,
          limit: 10,
          offset: 0,
          status: null,
          role: null,
        };
        try {
          const cache = client.readQuery({
            query: LIST_TEAM,
            variables: {
              filter,
            },
          });
          client.writeQuery({
            query: LIST_TEAM,
            variables: {
              filter,
            },
            data: {
              ...cache,
              teamMembers: {
                ...cache.teamMembers,
                count: cache.teamMembers.count + 1,
                hits: [user, ...cache.teamMembers.hits],
              },
            },
          });
        } catch (_) {}
      })
      .catch(() => {});
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push(`/${currentParam}/team-members`);
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess =
    dataMutation && dataMutation.createUserTeam && "Add team member success.";
  let newError;
  if (errorMutation) {
    if (errorMutation.toString().includes("uix_users_email")) {
      newError = "Email name already in use, please try another name.";
    } else {
      newError = errorMutation.toString();
    }
  }

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const isPMETeam = isPME_TEAM(currentUser);

  const { canCreate } = usePrivileges();

  if (!canCreate && isPMETeam) return null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Add Member"
        breadcrumbs={[
          {
            content: "Members",
            url: `/${currentParam}/team-members`,
          },
        ]}
      >
        <Card sectioned>
          <MemberFormPolaris
            onCompleted={(input) => handleCompleted(input)}
            dataMutation={dataMutation}
            loadingMutation={loadingMutation}
            choices={choices}
            loading={loading}
            errorQuery={errorQuery}
            teamRoles={data}
            handleRedirect={handleRedirect}
          />
        </Card>
      </Page>
    </Container>
  );
};
