import React, { useState, useCallback } from "react";
import { Button, Icon, Modal, Toast } from "@shopify/polaris";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { PRODUCTS_BY_COLLECTION } from "./ProductsByCollectionPolaris";
import { handleError } from "../../../helper";

const DELETE_PRODUCT = gql`
  mutation deleteCollectionOfProduct($collectionId: ID!, $productId: ID!) {
    deleteCollectionOfProduct(
      collectionId: $collectionId
      productId: $productId
    )
  }
`;

export const DeleteCollectionOfProductPolaris = ({
  productId,
  collectionId,
  filter,
}) => {
  const [active, setActive] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [
    deleteCollectionOfProduct,
    { client, loading, error, data },
  ] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      handleModalChange();
    },
    onError: () => {},
  });

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  let toastSuccess =
    data && data.deleteCollectionOfProduct && "Add collection success";
  let newError = error ? handleError(error.toString()) : null;

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const handleModalChange = useCallback(() => setActive((value) => !value), []);
  const handleDeleteProduct = useCallback(() => {
    deleteCollectionOfProduct({
      variables: {
        collectionId,
        productId,
      },
    })
      .then(() => {
        const cache = client.readQuery({
          query: PRODUCTS_BY_COLLECTION,
          variables: {
            filter: { ...filter, collectionId },
          },
        });

        client.writeQuery({
          query: PRODUCTS_BY_COLLECTION,
          variables: {
            filter: { ...filter, collectionId },
          },
          data: {
            ...cache,
            productsByCollection: {
              ...cache.productsByCollection,
              total: cache.productsByCollection.total - 1,
              nodes: cache.productsByCollection.nodes.filter(
                (u) => u.id !== productId
              ),
            },
          },
        });
      })
      .catch(() => {});
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {toastMarkup}
      <Button
        plain
        icon={<Icon source={CancelSmallMinor} />}
        onClick={handleModalChange}
      />
      <Modal
        open={active}
        onClose={handleModalChange}
        primaryAction={{
          content: "Delete",
          onAction: handleDeleteProduct,
          loading: loading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => handleModalChange(),
          },
        ]}
        title="Delete product"
        sectioned
      >
        <p>Are you sure delete this product?</p>
      </Modal>
    </>
  );
};
