import { gql } from "apollo-boost";
import { TaskBaseMockupFragment } from "../fragments";

export * from './store';
export * from './mapping-order';

export const GENERATED_MOCKUP_FOR_IDEA_TASK = gql`
    subscription subscriptionGeneratedMockupForIdeaTask {
        subscriptionGeneratedMockupForIdeaTask {
            ...TaskBaseMockupFragment
        }
    }
    ${TaskBaseMockupFragment}
`;
