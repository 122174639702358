import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback } from "react";
import { RE_GEN_STORE_API_KEY } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export function RegenAPIKey({
  open,
  onClose,
  storeId,
  refetch,
  setNotify,
  toggleActiveToast,
}) {
  // State
  // Mutation
  const timeoutRef = React.useRef(null);
  const [reGen, { loading }] = useMutation(RE_GEN_STORE_API_KEY, {
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
    onCompleted: () => {
      setNotify({ msg: "Re-gen store api key success", err: false });
      onClose && onClose();
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }
    },
  });

  // Handle submit
  const handleSubmit = useCallback(() => {
    if (!storeId) return;
    const ids = [storeId];

    toggleActiveToast();
    setNotify({ msg: null, err: false });
    reGen({
      variables: { ids },
    });
  }, [storeId, toggleActiveToast]);

  return (
    <Modal
      title="Re-Gen API Key"
      open={open}
      onClose={onClose}
      sectioned
      large
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to re-gen api key for this store?</p>
    </Modal>
  );
}
