import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Button } from "@shopify/polaris";
import { RefreshMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const Container = styled.div`
  padding-top: 1.6rem;
  .helpText-wrap {
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 2rem;
    text-transform: none;
    letter-spacing: normal;
    color: var(--p-text-subdued, #637381);
    margin-top: 0.4rem;
    font-size: 1.4rem;
    .Polaris-Button {
      margin-left: 0.3rem;
    }
  }
`;

export const ChooseTemplatePolaris = (props) => {
  const { data, onChange, refetch } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    let newData =
      data &&
      data.templates &&
      data.templates.nodes &&
      data.templates.nodes.length > 0
        ? data.templates.nodes.map((i) => ({ value: i.id, label: i.name }))
        : [];
    setDeselectedOptions(newData);
    setOptions(newData);
  }, [data]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue ? selectedValue[0] : null);
      if (onChange) {
        onChange(selected ? selected[0] : null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const handleRefetch = useCallback(() => {
    if (refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const helpText = (
    <p className="helpText-wrap">
      <span>
        Click
        <CustomLinkPolaris
          onClick={() => setIsClick(true)}
          href={"/seller/products/add-campaign"}
        >
          {" "}here{" "}
        </CustomLinkPolaris>{" "}
        to create new campaign template
      </span>
      {isClick && <Button icon={RefreshMinor} plain onClick={handleRefetch} />}
    </p>
  );

  const textField = (
    <Autocomplete.TextField
      label="template"
      labelHidden
      placeholder="Search template"
      value={inputValue}
      onChange={handleInputChange}
    />
  );

  return (
    <Container>
      <ComponentLabelPolaris label="Campaign templates" required />
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelection}
        textField={textField}
      />
      {helpText}
    </Container>
  );
};
