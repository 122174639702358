import React from "react";
import { Modal, TextField, FormLayout, Select, Button } from "@shopify/polaris";
import styled from "styled-components";
import { DeleteMinor, CirclePlusMinor } from "@shopify/polaris-icons";

const Container = styled.div`
  .options-wrap {
    margin-left: -2rem;
    margin-top: -1.6rem;
    .add-new {
      margin-top: 2rem;
      margin-left: 2rem;
    }
  }
  .advance-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    > .Polaris-FormLayout--grouped {
      flex: 1 auto;
    }
    .Polaris-Button {
      margin-top: 0.8rem;
      margin-right: 0;
    }
  }
  .options-list-wrap {
    .Polaris-FormLayout__Title {
      margin-bottom: 0.8rem;
      padding: 0;
    }
  }
  .options-list-inner {
    display: flex;
    flex-direction: row;
    column-gap: 1.2rem;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: 1 auto;
    }
  }
`;

export const FieldConfigureOptionsPolaris = ({
  open,
  toggleVisibleModal,
  item,
  onChange,
}) => {
  const { type } = item;
  const { settings } = item.configure;
  const { options } = settings;

  return (
    <Modal
      open={open}
      onClose={toggleVisibleModal}
      title={item && item.title}
      sectioned
      primaryAction={{ content: "Done", onAction: toggleVisibleModal }}
    >
      <Container>
        <FormLayout>
          {"number" === type && (
            <FormLayout.Group>
              <TextField
                label="Min"
                value={settings.min}
                type="number"
                onChange={(v) => {
                  settings.min = v;
                  if (onChange) {
                    onChange(item);
                  }
                }}
              />
              <TextField
                label="Max"
                value={settings.max}
                type="number"
                onChange={(v) => {
                  settings.max = v;
                  if (onChange) {
                    onChange(item);
                  }
                }}
              />
            </FormLayout.Group>
          )}
          {"dropdown" === type && (
            <Select
              label="Mode"
              value={settings.mode}
              options={[
                { value: "default", label: "Default" },
                { value: "multiple", label: "Multiple" },
              ]}
              onChange={(v) => {
                settings.mode = v;
                if (onChange) {
                  onChange(item);
                }
              }}
            />
          )}
          {["radio", "checkbox"].includes(type) && (
            <div className="options-list-wrap">
              <FormLayout.Group title={"Options"} />
              <FormLayout>
                {options.map((option, index) => (
                  <div className="options-list-inner" key={`option-${index}`}>
                    <TextField
                      placeholder={`Option ${index + 1}`}
                      label="option"
                      labelHidden
                      value={option}
                      onChange={(e) => {
                        options[index] = e;
                        if (onChange) {
                          onChange(item);
                        }
                      }}
                    />
                    <Button
                      icon={DeleteMinor}
                      plain
                      onClick={() => {
                        options.splice(index, 1);
                        if (onChange) {
                          onChange(item);
                        }
                      }}
                    />
                  </div>
                ))}
              </FormLayout>
              <div style={{ marginTop: "2rem" }}>
                <Button
                  icon={CirclePlusMinor}
                  children={"Add new option"}
                  onClick={() => {
                    const count = options.length + 1;
                    options.push(`Option ${count}`);
                    if (onChange) {
                      onChange(item);
                    }
                  }}
                />
              </div>
            </div>
          )}
          {["dropdown"].includes(type) && (
            <div className="options-wrap">
              <FormLayout.Group title={"Options"} />
              {options.map((option, index) => (
                <div className="option-inner" key={index}>
                  <div className="advance-option">
                    <FormLayout.Group>
                      <TextField
                        label="key"
                        labelHidden
                        value={option.value}
                        placeholder={`Key ${index + 1}`}
                        onChange={(e) => {
                          option.value = e;
                          if (onChange) {
                            onChange(item);
                          }
                        }}
                      />
                      <TextField
                        label="label"
                        labelHidden
                        value={option.label}
                        placeholder={`Label ${index + 1}`}
                        onChange={(e) => {
                          option.label = e;
                          if (onChange) {
                            onChange(item);
                          }
                        }}
                      />
                    </FormLayout.Group>
                    <Button
                      icon={DeleteMinor}
                      plain
                      onClick={() => {
                        options.splice(index, 1);
                        if (onChange) {
                          onChange(item);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className="add-new">
                <Button
                  icon={CirclePlusMinor}
                  children={"Add new option"}
                  onClick={() => {
                    const count = options.length + 1;
                    options.push({
                      key: `key-${count}`,
                      label: `Label ${count}`,
                    });
                    if (onChange) {
                      onChange(item);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </FormLayout>
      </Container>
    </Modal>
  );
};
