import { Checkbox, Collapsible } from "@shopify/polaris";
import React from "react";
import { StoresSelectPolaris } from "../../store/StoresSelectPolaris";

function TargetStore({ stores, ignoreStore, targetStore, onChange }) {
  const [checked, setChecked] = React.useState(!!targetStore);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (stores?.length > 0) {
      const opts = stores.filter(({ value }) => value !== ignoreStore);
      setOptions(opts);
    }
  }, [ignoreStore]);

  React.useEffect(() => {
    const t = !!targetStore;
    setChecked(t);
  }, [targetStore]);

  const handleChecked = React.useCallback((val) => {
    setChecked(val);
    if (!val) {
      onChange(null);
    }
  }, []);

  return (
    <div>
      <Checkbox
        checked={checked}
        label="Get product data from other store"
        onChange={handleChecked}
      />
      <Collapsible
        open={!!checked}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <StoresSelectPolaris
          stores={options || []}
          value={targetStore}
          labelHidden
          onChange={onChange}
        />
      </Collapsible>
    </div>
  );
}

export { TargetStore };
