import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Mutation } from "@apollo/react-components";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, notification, Upload } from "antd";
import { CREATE_FILE_MUTATION, REQUEST_MUTATION } from "../shared/CrawlerFile";
import styled from "styled-components";
import { UPLOAD_FOLDER } from "../../variable";

const Container = styled.div`
  .ant-upload.ant-upload-drag {
    border: 2px dashed gray;
  }
  margin-bottom: 20px;
`;

class ImportTrackingUpload extends Component {
  customRequest = async (options, client, createUploadUrl) => {
    const { file, onSuccess, onError } = options;
    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const data = e.target.result;
        const res = await createUploadUrl({
          variables: {
            input: {
              name: file.name,
              mimeType: file.type,
              size: file.size,
              folder: UPLOAD_FOLDER.SUPPLIER_IMPORT,
            },
          },
        });
        const { key, url } = res.data.createUploadUrl;
        await axios.put(url, file, {
          headers: { "Content-Type": file.type },
          timeout: 1000 * 60 * 30,
        });
        const fileResponse = await client.mutate({
          mutation: CREATE_FILE_MUTATION,
          variables: {
            input: {
              name: file.name,
              key: key,
              size: file.size,
              mimeType: file.type,
            },
          },
        });
        onSuccess({
          file: fileResponse.data.createFile,
          data,
        });
      };
      fileReader.onerror = () => {
        onError(fileReader.error);
      };
      fileReader.readAsText(file, "UTF-8");
    } catch (e) {
      onError(e);
    }
  };

  render() {
    return (
      <Container>
        <Mutation mutation={REQUEST_MUTATION}>
          {(createUploadUrl, { client }) => (
            <Upload.Dragger
              accept={"text/plain,text/csv"}
              beforeUpload={(file) => {
                let ext = "";
                if (!["csv", "plain"].includes(file.type) && file.name) {
                  ext = file.name.split(".").pop();
                }
                if (
                  file.type !== "text/csv" &&
                  file.type !== "text/plain" &&
                  ext !== "csv"
                ) {
                  notification.error({ message: "Allow .txt or .csv" });
                  return false;
                } else {
                  return true;
                }
              }}
              customRequest={(options) =>
                this.customRequest(options, client, createUploadUrl)
              }
              onChange={(info) => {
                let value = [];
                for (let i = 0; i < info.fileList.length; i++) {
                  if (
                    info.fileList[i].status === "done" &&
                    info.fileList[i].response
                  ) {
                    value.push(info.fileList[i].response);
                  }
                }
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}
              multiple={false}
            >
              <Button type={"primary"} icon={<LegacyIcon type={"upload"} />}>
                Add file
              </Button>
              {/* <p>Or drop file to upload</p> */}
            </Upload.Dragger>
          )}
        </Mutation>
      </Container>
    );
  }
}

ImportTrackingUpload.propTypes = {
  onChange: PropTypes.func,
};

export default ImportTrackingUpload;
