import { Select, TextContainer, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

const paymentMethods = {
  paypal: "Paypal",
  pingPong: "PingPong",
  stripe: "Stripe",
};

export const PaymentAccountForm = ({ onChangeValue, value }) => {
  const [values, setValues] = useState({
    title: null,
    method: paymentMethods.paypal,
    apiUsername: null,
    apiPassword: null,
    apiSignature: null,
    appId: null,
    appSecret: null,
    note: "",
  });

  useEffect(() => {
    setValues(value);
  }, [value]);

  const showErr = React.useRef(false);
  const handleChangeValue = React.useCallback(
    (value, key) => {
      onChangeValue(value, key);

      if (key === "method") {
        showErr.current = false;
      } else {
        showErr.current = true;
      }
    },
    [onChangeValue],
  );

  return (
    <TextContainer>
      <TextField
        label="Title"
        value={values.title}
        onChange={(value) => handleChangeValue(value, "title")}
        error={!values.title && showErr.current}
        placeholder="Enter title"
      />
      <Select
        label="Payment gateway"
        options={Object.values(paymentMethods)}
        onChange={(value) => handleChangeValue(value, "method")}
        value={values.method}
        placeholder="Enter payment gateway"
      />
      {values.method === paymentMethods.paypal ? (
        <>
          <TextField
            label="API Username"
            value={values.apiUsername}
            onChange={(value) => handleChangeValue(value, "apiUsername")}
            error={!values.apiUsername && showErr.current}
            placeholder="Enter API Username"
          />
          <TextField
            label="API Password"
            value={values.apiPassword}
            onChange={(value) => handleChangeValue(value, "apiPassword")}
            error={!values.apiPassword && showErr.current}
            placeholder="Enter API Password"
          />
          <TextField
            label="API Signature"
            value={values.apiSignature}
            onChange={(value) => handleChangeValue(value, "apiSignature")}
            error={!values.apiSignature && showErr.current}
            placeholder="Enter API Signature"
          />
        </>
      ) : values.method === paymentMethods.pingPong ? (
        <>
          <TextField
            label="App Id"
            value={values.appId}
            onChange={(value) => handleChangeValue(value, "appId")}
            error={!values.appId && showErr.current}
            placeholder="Enter App Id"
          />
          <TextField
            label="API Secret"
            value={values.appSecret}
            onChange={(value) => handleChangeValue(value, "appSecret")}
            error={!values.appSecret && showErr.current}
            placeholder="Enter API Secret"
          />
        </>
      ) : (
        <>
          <TextField
            label="Publishable key"
            value={values.apiUsername}
            onChange={(value) => handleChangeValue(value, "apiUsername")}
            error={!values.apiUsername && showErr.current}
            placeholder="Enter Publishable key"
          />
          <TextField
            label="Secret key"
            value={values.apiPassword}
            onChange={(value) => handleChangeValue(value, "apiPassword")}
            error={!values.apiPassword && showErr.current}
            placeholder="Enter Secret key"
          />
        </>
      )}
      <TextField
        label="Note"
        multiline={4}
        value={values.note}
        onChange={(value) => handleChangeValue(value, "note")}
      />
    </TextContainer>
  );
};
