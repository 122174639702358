import React, { useCallback, useContext, useEffect, useState } from "react";
import { TopBar } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import styled from "styled-components";

import { AppContext } from "../../context";
import { USER_ROLE, TEAM_ROLE } from "../../variable";

import UserMenuPolaris from "./UserMenuPolaris";
import SecondaryMenuPolaris from "./SecondaryMenuPolaris";
// import { NotificationsPolaris } from "./NotificationsPolaris";
// import { NotificationsPolaris } from "../../components/shared/NotificationsPolaris";
import { NotifyPolaris } from "./NotifyPolaris";
import { ToastContextProvider } from "../../components/shared/ToastContext";

export const LIST_STORE = gql`
  query storeListOfMe {
    storeListOfMe {
      total
      nodes {
        id
        title
        domain
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TopBarPolaris = (props) => {
  const context = useContext(AppContext);
  const { toggleMobileNavigationActive } = props;
  const { currentUser, store, setStore } = context;

  // State
  const [stores, setStores] = useState([]);
  const [deselectedOpt, setDeselectedOpt] = useState([]);

  const handleNavigationToggle = useCallback(() => {
    toggleMobileNavigationActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userRoles = currentUser && currentUser.roles;
  const teamRole =
    currentUser && currentUser.teamUser ? currentUser.teamUser.role : null;

  const arrays = [
    ...Object.values(TEAM_ROLE),
    ...Object.values(USER_ROLE),
    "Administrator",
  ];
  const result = arrays
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arrays[e])
    .map((e) => arrays[e])
    .filter(
      (e) =>
        ![TEAM_ROLE.StoreManager, TEAM_ROLE.MarketplaceManager].includes(e),
    );

  const { data } = useQuery(LIST_STORE, {
    skip: result.includes(teamRole || _.head(userRoles)),
  });

  // Get data
  useEffect(() => {
    const stores =
      data?.storeListOfMe?.nodes?.length > 0
        ? data.storeListOfMe.nodes.map((i) => ({
            label: i.title,
            value: i.id,
          }))
        : [];
    stores.unshift({ label: "All Stores", value: "all" });
    setStores(stores);
    setDeselectedOpt(stores);
  }, [data]);

  let isShowSecondaryMenu;
  if (
    store &&
    [TEAM_ROLE.StoreManager, TEAM_ROLE.MarketplaceManager].includes(teamRole)
  ) {
    isShowSecondaryMenu = true;
  }

  return (
    <ToastContextProvider>
      <TopBar
        showNavigationToggle
        userMenu={<UserMenuPolaris />}
        onNavigationToggle={handleNavigationToggle}
        secondaryMenu={
          <Container>
            {/* <NotificationsPolaris /> */}
            <NotifyPolaris />
            {isShowSecondaryMenu && (
              <SecondaryMenuPolaris
                store={store}
                setStore={setStore}
                stores={stores}
                setStores={setStores}
                deselectedOpt={deselectedOpt}
              />
            )}
          </Container>
        }
      />
    </ToastContextProvider>
  );
};

export default TopBarPolaris;
