import React, { forwardRef } from "react";
import { Table, Card, Input } from "antd";
import { sum } from "lodash";
import { Icon as LegacyIcon } from "@ant-design/compatible";

import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import useToggle from "../../../hooks/useToggle";
import { Collapsible } from "@shopify/polaris";

export const ColorCodes = forwardRef(function ColorCodes(props, _ref) {
  const { onChange, value: colors } = props;
  const [open, toggleOpen] = useToggle(true);

  const columns = [
    {
      title: "Color name",
      key: "name",
      dataIndex: "name",
      render: (value) => {
        return <Input value={value} disabled placeholder="Color name" />;
      },
    },
    {
      title: "Color code",
      key: "code",
      dataIndex: "value",
      render: (value, _r, index) => {
        return (
          <Input
            value={value}
            placeholder="#ffffff"
            onChange={(e) => {
              const input = e.target.value;
              colors[index]["value"] = input;
              onChange && onChange(colors);
            }}
          />
        );
      },
    },
    {
      title: "Pattern",
      key: "patternImage",
      dataIndex: "patternImage",
      render: (value, _r, index) => {
        return (
          <MediaSelectorButton
            accept={"image/*"}
            listType={"picture-card"}
            multiple={false}
            value={value}
            onChange={(value) => {
              colors[index]["patternImage"] = value;
              onChange && onChange(colors);
            }}
          />
        );
      },
    },
  ];

  const tableWidth = sum(columns.map((c) => c.width));
  return colors?.length > 0 ? (
    <Card
      title={
        <span onClick={toggleOpen} style={{ cursor: "pointer" }}>
          <LegacyIcon type={open ? "caretDown" : "caretRight"} />
          <span stlye={{ marginLeft: 5 }}>Color Codes</span>
        </span>
      }
    >
      <Collapsible
        id="color-codes"
        open={open}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Table
          pagination={false}
          dataSource={colors}
          columns={columns}
          rowKey={(_, index) => index}
          scroll={{ x: tableWidth }}
        />
      </Collapsible>
    </Card>
  ) : null;
});
