import { gql } from "apollo-boost";
import { mailchimpFragment } from "../fragments";

export const SAVE_MAILCHIMP = gql`
  mutation saveMailchimp($apiKey: String!, $listId: String!) {
    saveMailchimp(apiKey: $apiKey, listId: $listId) {
      ...mailchimpFragment
    }
  }
  ${mailchimpFragment}
`;

export const SET_WARNING_EMAIL = gql`
  mutation warningEmail($input: NewWarningEmail!) {
    warningEmail(input: $input)
  }
`

export const SET_VIEW_ORIGIN_FILE = gql`
  mutation setViewOriginFile($input: NewViewOriginFile!) {
    setViewOriginFile(input: $input)
  }
`;

export const ENABLE_STORE_FULFILLMENT_MUTATION = gql`
  mutation enableStoreFulfillment($teamIds: [ID!]) {
    enableStoreFulfillment(teamIds: $teamIds)
  }
`;

export const SET_USERS_PAYMENT_ACCOUNT_ACCESS = gql`
  mutation setUsersPaymentAccountAccess($userIds: [ID!]) {
    setUsersPaymentAccountAccess(userIds: $userIds)
  }
`;

export const SET_VARIABLE = gql`
  mutation setVariable($input: NewVariable!) {
    setVariable(input: $input) {
      id
      key
      value
    }
  }
`;