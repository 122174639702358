import React from "react";
import history from "../history";
import { USER_ROLE } from "../variable";
import isEqual from 'lodash/isEqual';
import SuspenseComp from "./shared/SuspenseComp";

export default function (route, currentUser) {
  class RenderRouter extends React.Component {
    state = {
      showBtnSave: false,
      clickSave: null,
      aggregations: {},
      loading: null,
    };
    componentDidMount() {
      if (
        currentUser &&
        currentUser.roles &&
        currentUser.roles.length &&
        (currentUser.roles.includes("Administrator") ||
          currentUser.roles.includes(USER_ROLE.Supporter))
      ) {
        return;
      }
      const roles = route.roles ? route.roles : [];
      if (currentUser && roles.includes("Authenticated")) {
        return;
      }
      let roleMapping = {};
      for (let i = 0; i < roles.length; i++) {
        roleMapping[roles[i]] = true;
      }
      if (roles.length) {
        if (!currentUser || !currentUser.roles || !currentUser.roles.length) {
          this.redirect();
          return;
        }
        let canAccess = false;
        for (let i = 0; i < currentUser.roles.length; i++) {
          if (roleMapping[currentUser.roles[i]]) {
            canAccess = true;
            break;
          }
        }
        if (!canAccess) {
          this.redirect();
        }
      }
    }

    redirect = () => {
      history.push(`/login`);
      window.location.reload(false);
    };

    checkTeamRoles = () =>
      route.teamRoles &&
      route.teamRoles.includes(
        currentUser && currentUser.teamUser && currentUser.teamUser.role
      );

    handleBtnSave = (value, aggregations) => {
      this.setState({
        aggregations: aggregations,
      });

      if (value) {
        this.setState({
          showBtnSave: false,
        });
      } else {
        this.setState({
          showBtnSave: true,
        });
      }
    };

    onSubmit = (value) => {
      this.setState({
        clickSave: value,
      });
    };

    handleLoading = (value) => {
      let { aggregations } = this.state;
      this.setState({
        aggregations: {
          ...aggregations,
          loading: value,
        },
      });
    };


    shouldComponentUpdate(nextProps, nextState) {
      return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render() {
      let ComposedComponent = route.component;
      // const Layout = route.layout;
      // let { showBtnSave, aggregations, clickSave } = this.state;

      if (this.checkTeamRoles() && route.customLayout) {
        ComposedComponent = route.customComponent;
      }

      return <SuspenseComp fallback={null}>
        <ComposedComponent {...this.props} />
      </SuspenseComp>;
      // <React.Fragment>
      //   {
      //     this.checkTeamRoles() && route.customLayout ? (
      //       <route.customLayout>
      //         <route.customComponent></route.customComponent>
      //       </route.customLayout>
      //     ) :
      //     Layout ? (
      //       <Layout
      //         title={route.title}
      //         {...this.props}
      //         onSubmit={(value) => this.onSubmit(value)}
      //         showBtnSave={showBtnSave}
      //         aggregations={aggregations}
      //       >
      //         <ComposedComponent
      //           {...this.props}
      //           clickSave={clickSave}
      //           handleLoading={(value) => {
      //             this.handleLoading(value);
      //           }}
      //           handleBtnSave={(value, aggregations) => {
      //             this.handleBtnSave(value, aggregations);
      //           }}
      //         />
      //       </Layout>
      //     ): (
      //       <ComposedComponent {...this.props} />
      //     )
      //   }
      // </React.Fragment>
    }
  }

  return RenderRouter;
}
