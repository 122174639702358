import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ReportsFilterPolaris } from "./ReportsFilterPolaris";
import { TableReportsPolaris } from "./TableReportsPolaris";

const QUERY_REPORTS = gql`
  query saleReports($filter: SaleReportsFilter) {
    saleReports(filter: $filter) {
      total
      nodes {
        store {
          id
          platform
          email
          title
        }
        totalBaseCost
        totalRevenue
        totalUnit
        totalOrder
      }
    }
  }
`;

const MUTATION_REPORTS = gql`
  mutation exportSaleReports($filter: SaleReportsFilter) {
    exportSaleReports(filter: $filter)
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "platform"];

export const ReportsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageReports")) || 20,
    offset: 0,
    search: null,
    range: null,
    platform: null,
    ...initFilter,
  });
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNofitication] = useState({
    message: null,
    isError: false,
  });

  // Query
  const { data, loading, error } = useQuery(QUERY_REPORTS, {
    variables: {
      filter,
    },
  });
  // Mutation
  const [exportSaleReports, { loading: loadingM }] = useMutation(
    MUTATION_REPORTS,
    {
      onError: (err) => {
        setNofitication({
          message: handleError(err.toString()),
          isError: true,
        });
      },
      onCompleted: (res) => {
        let url = res?.exportSaleReports;
        if (url) {
          window.open(url, "_blank");
        }
        setNofitication({
          message: "Export file success.",
          isError: false,
        });
      },
    },
  );
  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleExport = useCallback(() => {
    setNofitication({
      message: null,
      isError: false,
    });
    exportSaleReports({
      variables: {
        filter,
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    let { offset, limit, range, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });

    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="All reports"
        fullWidth
        primaryAction={{
          content: "Export",
          onAction: handleExport,
          loading: loadingM,
        }}
      >
        <Card sectioned>
          <ReportsFilterPolaris
            filter={filter}
            onChange={({ search, platform, range }) => {
              setFilter((prev) => {
                if (
                  !isEqual(prev.search, search) ||
                  !isEqual(prev.platform, platform) ||
                  !isEqual(prev.range, range)
                ) {
                  return {
                    ...prev,
                    search,
                    platform,
                    range,
                    offset: 0,
                  };
                }
                return prev;
              });
            }}
          />
        </Card>
        <Card>
          {loading ? (
            <SkeletonPagePolaris />
          ) : error ? (
            <div>Error: {handleError(error.toString())}</div>
          ) : data &&
            data.saleReports &&
            data.saleReports.nodes &&
            data.saleReports.nodes.length > 0 ? (
            <TableReportsPolaris
              data={data}
              filter={filter}
              setFilter={(offset, limit) => {
                setFilter((prevState) => ({
                  ...prevState,
                  offset,
                  limit,
                }));
                setCookie("perPageReports", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </React.Fragment>
  );
};
