import { useQuery } from "@apollo/react-hooks";
import { Icon, Page, Spinner, Stack } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { DashboardProvider } from "../../components/admin/dashboard/context";
import { DevStatistics } from "../../components/admin/reports";
import { ActivatorTime } from "../../components/shared/ActivatorTime";
import { AutoCompleteHasSearchPolaris } from "../../components/shared/AutoCompleteHasSearchPolaris";

const GET_TEAMS = gql`
  query teams($filter: TeamFilter) {
    teams(filter: $filter) {
      total
      nodes {
        id
        name
      }
    }
  }
`;

export function DevStatisticsPage() {
  // State
  const [state, setState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      teamID: null,
      range: { from: moment().startOf("day"), to: moment().endOf("day") },
    }
  );

  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: "",
  });
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  // Query
  const { data, loading, error } = useQuery(GET_TEAMS, {
    variables: {
      filter,
    },
  });

  useEffect(() => {
    const nodes = data?.teams?.nodes || [];
    const opt = nodes.map((node) => {
      const { id, name } = node;
      return { value: id, label: name };
    });

    setOptions(opt);
  }, [data]);

  // Actions
  const handleRangeChange = useCallback((range) => {
    setState({
      range: range,
    });
  }, []);
  const handleTeamChange = useCallback((item) => {
    const { value } = item;
    setState({ teamID: value });
    setValue(item);
  }, []);

  const searchTimeout = useRef(null);
  const handleSearchChange = useCallback((search) => {
    searchTimeout.current && clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search }));
    }, 500);
  }, []);

  const actions = (
    <Stack alignment="center">
      <AutoCompleteHasSearchPolaris
        data={options}
        value={value}
        placeholder="Filter team"
        onChange={handleTeamChange}
        onChangeSearch={handleSearchChange}
        queryError={error}
        suffix={
          loading ? <Spinner size="small" /> : <Icon source={SearchMinor} />
        }
      />
      <ActivatorTime setRange={handleRangeChange} />
    </Stack>
  );
  return (
    <Page title="Dev Statistics" fullWidth primaryAction={actions}>
      <DashboardProvider filter={state}>
        <DevStatistics />
      </DashboardProvider>
    </Page>
  );
}
