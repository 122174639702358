import React, { useState, useCallback, useEffect } from "react";
import { Select, Button, Icon, TextField } from "@shopify/polaris";
import { CirclePlusMinor, DeleteMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 1.5rem;
  .condition {
    display: flex;
    padding: 1rem 0 1.5rem;
    gap: 1rem;
    div {
      flex: 1 1 0%;
    }
  }
`;

export const IgnoreConditionsPolaris = ({ onChange }) => {
  const [valueIC, setValueIC] = useState([]);

  useEffect(() => {
    if (onChange) {
      onChange(valueIC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueIC]);

  const handleValueChange = useCallback(() => {
    setValueIC([
      ...valueIC,
      {
        name: "startsWith",
        value: "",
      },
    ]);
  }, [valueIC]);

  const handleSelectChange = useCallback(
    (value, idx) => {
      setValueIC(
        valueIC.map((valueMap, i) => {
          if (i === idx) {
            return {
              ...valueMap,
              name: value,
            };
          }
          return valueMap;
        })
      );
    },
    [valueIC]
  );

  const handleInputChange = useCallback(
    (value, idx) => {
      setValueIC(
        valueIC.map((valueMap, i) => {
          if (i === idx) {
            return {
              ...valueMap,
              value,
            };
          }
          return valueMap;
        })
      );
    },
    [valueIC]
  );

  return (
    <Container>
      <div>
        {valueIC.length > 0 &&
          valueIC.map((v, idx) => (
            <div className="condition" key={idx}>
              <div>
                <Select
                  label="condition"
                  labelHidden
                  options={[
                    { label: "SKU starts with", value: "startsWith" },
                    { label: "SKU contains", value: "contains" },
                  ]}
                  value={v.name}
                  onChange={(value) => handleSelectChange(value, idx)}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  label="input"
                  labelHidden
                  value={v.value}
                  placeholder="Enter your value"
                  onChange={(value) => handleInputChange(value, idx)}
                />
              </div>
              <Button
                onClick={() => setValueIC(valueIC.filter((_, i) => i !== idx))}
                icon={<Icon source={DeleteMajorMonotone} />}
              />
            </div>
          ))}
      </div>
      <Button
        onClick={handleValueChange}
        icon={<Icon source={CirclePlusMinor} />}
      >
        New condition
      </Button>
    </Container>
  );
};
