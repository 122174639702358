import { ChoiceList, Stack, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { REPORT_TASKS_OTHER_DONE_CANCELLED_DENIED } from "../../../graphql/queries";
import { useQueryDNode } from "../../../hooks/useQueryDNode";
import { CardBox } from "../../reports/components/CardBox";

const styleSpan = {
  display: "inline-block",
  width: "9.5rem",
  wordBreak: "break-all",
};
const styleStatus = {
  display: "inline-block",
  width: "11rem",
  wordBreak: "break-all",
};

const OPTIONS = [
  { value: "at-least-5-days", label: "At Least 5 days" },
  { value: "at-least-7-days", label: "At Least 7 days" },
  { value: "at-least-10-days", label: "At Least 10 days" },
  { value: "all", label: "All Time" },
];

// Report orders pending or pending design
export function ReportOtherDoneCancelledDenied({ filter }) {
  // Query
  const [getTasks, { data, loading, error, fetchMore }] = useQueryDNode(
    REPORT_TASKS_OTHER_DONE_CANCELLED_DENIED
  );

  // State
  const [state, setState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      loadMore: false,
      canLoad: false,
      items: [],
      total: 0,
      from: ["at-least-5-days"],
    }
  );
  const [innerFilter, setInnerFilter] = useState({
    limit: 20,
    offset: 0,
    from: moment().subtract(5, "days").startOf("day"),
    teamID: filter.teamID,
  });

  const timeoutRef = useRef(null);

  // Effect
  useEffect(() => {
    const { teamID } = filter || {};
    setInnerFilter((p) => ({
      ...p,
      teamID,
    }));
    setState({ canLoad: false });
  }, [filter.teamID]);

  useEffect(() => {
    getTasks(innerFilter);
  }, [innerFilter]);

  useEffect(() => {
    const nodes = data?.reportTasksOtherDoneCancelledDenied?.nodes || [];
    const total = data?.reportTasksOtherDoneCancelledDenied?.total || 0;

    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              if (!node) return;
              const { id, orderId, kpi, defaultKpi, status, createdAt } = node;
              return {
                id,
                orderId,
                kpi: kpi || defaultKpi,
                status,
                createdAt,
              };
            })
            .filter(Boolean)
        : [];

    setState({ items: newItems, total });
  }, [data]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => (
          <div key={`order-POrPD${index}`}>
            <Stack distribution="equalSpacing" wrap={false}>
              <span className="index-wrap">{index + 1}</span>
              <span style={{ ...styleSpan, width: "10rem" }}>{item.id}</span>
              <span style={{ ...styleSpan, width: "20rem" }}>
                {item.orderId}
              </span>
              <span style={styleSpan}>{item.kpi}</span>
              <span style={styleStatus}>{item.status}</span>
              <span style={styleSpan}>
                {moment(item.createdAt).format("YYYY-MM-DD")}
              </span>
            </Stack>
          </div>
        ))
      : null;
  }, [state.items]);

  const headerMarkup = useMemo(
    () => (
      <Stack wrap={false} distribution="equalSpacing">
        <span className="index-wrap">
          <TextStyle variation="strong">#</TextStyle>
        </span>
        <span style={{ ...styleSpan, width: "10rem" }}>
          <TextStyle variation="strong">ID</TextStyle>
        </span>
        <span style={{ ...styleSpan, width: "20rem" }}>
          <TextStyle variation="strong">Order ID</TextStyle>
        </span>
        <span style={styleStatus}>
          <TextStyle variation="strong">KPI</TextStyle>
        </span>
        <span style={styleStatus}>
          <TextStyle variation="strong">Status</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Created At</TextStyle>
        </span>
      </Stack>
    ),
    []
  );
  const handleTimeChange = useCallback((value) => {
    let from;
    switch (value[0]) {
      case "at-least-5-days":
        from = moment().subtract(5, "days").startOf("day");
        break;
      case "at-least-7-days":
        from = moment().subtract(7, "days").startOf("day");
        break;
      case "at-least-10-days":
        from = moment().subtract(10, "days").startOf("day");
        break;
      default:
        break;
    }
    setInnerFilter((prev) => ({ ...prev, from }));
    setState({ from: value, canLoad: false });
  }, []);

  const subTitleMarkup = useMemo(
    () => (
      <div>
        <span>{state.total} Tasks</span>
        <ChoiceList
          choices={OPTIONS}
          onChange={handleTimeChange}
          selected={state.from}
        />
      </div>
    ),
    [state.total, handleTimeChange]
  );

  // Actions
  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!state.canLoad) {
      setState({ loadMore: true });
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...innerFilter,
              offset: data.reportTasksOtherDoneCancelledDenied.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const nodes =
              fetchMoreResult.reportTasksOtherDoneCancelledDenied.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setState({ canLoad: true });
            }
            setState({ loadMore: false });
            let newNodes = [
              ...prev.reportTasksOtherDoneCancelledDenied.nodes,
              ...nodes,
            ].filter(Boolean);
            const newNodes2 = new Map(
              newNodes
                .map((node) => (node.id ? [node.id, node] : undefined))
                .filter(Boolean)
            );
            const result = Array.from(newNodes2, ([, value]) => value);

            return {
              ...prev,
              reportTasksOtherDoneCancelledDenied: {
                ...prev.reportTasksOtherDoneCancelledDenied,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, innerFilter, state.canLoad, setState, fetchMore]);

  return (
    <Wrapper>
      <CardBox
        title={"Design Tasks still not Done"}
        subTitle={subTitleMarkup}
        headMarkup={headerMarkup}
        contentMarkup={contentMarkup}
        filter={innerFilter}
        total={state.total}
        loading={loading}
        error={error}
        handleScroll={handleScroll}
        loadMore={state.loadMore}
        noCollapse
        collapseKey={"design-tasks"}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .index-wrap {
    width: 3rem;
    display: inline-block;
  }

  .column-wrap {
    width: 7rem;
    display: inline-block;
  }

  .name-wrap {
    display: flex;
    flex: 1 1;
  }
`;
