import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import { FilterCreatedTimePolaris } from "../product/FilterCreatedTimePolaris";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const Container = styled.div`
  .created-time {
    padding-left: 0.5rem;
  }

  @media only screen and (min-width: 992px) {
    .Polaris-ChoiceList__ChoiceChildren {
      .Polaris-FormLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 3rem;
      }
    }
  }
`;

const OPTION_TIME = [
  { value: "AllTime", label: "All Time" },
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last7Days", label: "Last 7 days" },
  { value: "ThisMonth", label: "This month" },
  { value: "LastMonth", label: "Last month" },
  { value: "Custom", label: "Custom" },
];

export const CreatedTimeFieldPolaris = (props) => {
  const { onChange, value } = props;
  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });

  const handleChange = useCallback((range, rangeLabel) => {
    let optionTime = OPTION_TIME;
    let newLabel = "";
    for (let i = 0; i < optionTime.length; i++) {
      let item = optionTime[i];
      if (item.label === rangeLabel) {
        newLabel = item.value;
      }
    }
    if (rangeLabel.includes("-")) {
      newLabel = "Custom";
    }
    if (onChange) {
      onChange(range, newLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get data
  useEffect(() => {
    if (value) {
      let optionTime = OPTION_TIME;
      let newLabel;
      for (let i = 0; i < optionTime.length; i++) {
        let item = optionTime[i];
        if (item.value === value.label) {
          newLabel = item.label;
        }
      }
      setFilterTime(() => ({
        rangeLabel: newLabel,
        range:
          value.from && value.to
            ? {
                from: value.from,
                to: value.to,
              }
            : null,
      }));
    }
  }, [value]);

  return (
    <Container>
      <ComponentLabelPolaris label="Created Time" />
      <div className="created-time">
        <FilterCreatedTimePolaris
          filterTime={filterTime}
          isExportAmazon
          onChangeRange={handleChange}
        />
      </div>
    </Container>
  );
};
