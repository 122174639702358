import React, { useCallback, useMemo, useState } from "react";
import { Button, Popover, ActionList } from "@shopify/polaris";
import {
    RefreshMajorMonotone,
    ViewMajorTwotone,
    DuplicateMinor,
    DeleteMajorTwotone,
    EditMajorTwotone,
} from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";

import useToggle from "../../../../hooks/useToggle";
import { ReviewAction } from "./ReviewAction";
import { ReGen } from "./ReGen";
import { Delete } from "./Delete";

export const STATUS_MOCKUP_2D = {
    Pending: "Pending",
    Doing: "Doing",
    NeedReview: "Need Review",
    Done: "Done",
    Error: "Error",
    Rejected: "Rejected",
};

export function MoreActions({
    item,
    mockupStatus,
    handleCompleted,
    currentParam,
}) {
    // Prop
    const id = item?.id;
    const history = useHistory();

    // State
    const [open, toggleOpen] = useToggle(false);
    const [showModal, toggleShowModal] = useToggle(false);
    const [action, setAction] = useState(null);

    // Handle action
    const onAction = useCallback(
        (action) => {
            toggleShowModal(true);
            setAction(action);
        },
        [toggleShowModal]
    );

    const handleRedirect = useCallback(
        (path) => {
            history.push(
                `/${currentParam}/product-from-print-file/${path}/${id}`
            );
        },
        [history, id, currentParam]
    );

    const actions = useMemo(() => {
        const NoDone = [
            STATUS_MOCKUP_2D.Doing,
            STATUS_MOCKUP_2D.Pending,
            STATUS_MOCKUP_2D.NeedReview,
            STATUS_MOCKUP_2D.Rejected,
            STATUS_MOCKUP_2D.Error,
        ];
        const result = [
            {
                content: "Review",
                icon: ViewMajorTwotone,
                onAction: () => onAction("review"),
                status: [STATUS_MOCKUP_2D.NeedReview],
            },
            {
                content: "Update",
                icon: EditMajorTwotone,
                onAction: () => handleRedirect("edit"),
                status: NoDone,
            },
            {
                content: "Clone product",
                icon: DuplicateMinor,
                onAction: () => handleRedirect("clone"),
                status: ["*"],
            },
            {
                content: "Re-gen Mockup",
                icon: RefreshMajorMonotone,
                onAction: () => onAction("reGenMockup"),
                status: [STATUS_MOCKUP_2D.Rejected, STATUS_MOCKUP_2D.Error],
            },
            {
                content: "Delete product",
                icon: DeleteMajorTwotone,
                onAction: () => onAction("delete"),
                destructive: true,
                status: NoDone,
            },
        ];

        return result.filter((action) =>
            action.status.some((i) => [mockupStatus, "*"].includes(i))
        );
    }, [onAction, handleRedirect, mockupStatus]);

    // Markup
    const activator = (
        <Button children="Actions" onClick={toggleOpen} disclosure />
    );

    const props = {
        open: showModal,
        toggleOpen: toggleShowModal,
        item,
        id,
        idsChecked: id != null ? [id] : [],
        handleCompleted,
    };

    const actionsMarkup = {
        reGenMockup: <ReGen {...props} isAction />,
        review: <ReviewAction {...props} />,
        delete: <Delete {...props} isAction />,
    };

    return (
        <React.Fragment>
            <Popover
                activator={activator}
                active={open}
                onClose={toggleOpen}
                preferredAlignment="right"
            >
                <ActionList items={actions} />
            </Popover>
            {actionsMarkup[action]}
        </React.Fragment>
    );
}
