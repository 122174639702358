import { Stack, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { toSlug } from "../../../helper";
import { useQueryDNode } from "../../../hooks/useQueryDNode";
import { CardBox } from "../../reports/components/CardBox";

const styleSpan = {
  display: "inline-block",
  width: "9.5rem",
  wordBreak: "break-all",
};
const styleStatus = {
  display: "inline-block",
  width: "11rem",
  wordBreak: "break-all",
};

// Report orders pending or pending design
export function ReportOrders({ filter, title, documentNode }) {
  const slug = toSlug(title);
  // Query
  const [getOrders, { data, loading, error, fetchMore }] = useQueryDNode(
    documentNode
  );

  // State
  const [state, setState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      loadMore: false,
      canLoad: false,
      items: [],
      total: 0,
      key: null,
    }
  );
  const [innerFilter, setInnerFilter] = useState({
    limit: 20,
    offset: 0,
  });

  const timeoutRef = useRef(null);

  // Effect
  useEffect(() => {
    const { range, teamID } = filter || {};
    setInnerFilter((p) => ({
      ...p,
      from: range?.from || null,
      to: range?.to || null,
      teamID,
    }));
    setState({ canLoad: false });
  }, [filter]);

  useEffect(() => {
    getOrders(innerFilter);
  }, [innerFilter]);

  useEffect(() => {
    let nodes = [];
    let total = 0;
    let key;
    if (data != null && Object.keys(data).length > 0) {
      [key] = Object.keys(data);
      if (data[key]) {
        nodes = data[key].nodes || [];
        total = data[key].total || 0;
      }
    }

    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              if (!node) return;
              const { id, originId, status, createdAt } = node;
              return { id, originId, status, createdAt };
            })
            .filter(Boolean)
        : [];

    setState({ items: newItems, total, key });
  }, [data]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => (
          <div key={`order-POrPD${index}`}>
            <Stack distribution="equalSpacing" wrap={false}>
              <span className="index-wrap">{index + 1}</span>
              <span style={{ ...styleSpan, width: "15rem" }}>{item.id}</span>
              <span style={{ ...styleSpan, width: "20rem" }}>
                {item.originId}
              </span>
              <span style={styleStatus}>{item.status}</span>
              <span style={styleSpan}>
                {moment(item.createdAt).format("YYYY-MM-DD")}
              </span>
            </Stack>
          </div>
        ))
      : null;
  }, [state.items]);

  const headerMarkup = useMemo(
    () => (
      <Stack wrap={false} distribution="equalSpacing">
        <span className="index-wrap">
          <TextStyle variation="strong">#</TextStyle>
        </span>
        <span style={{ ...styleSpan, width: "15rem" }}>
          <TextStyle variation="strong">ID</TextStyle>
        </span>
        <span style={{ ...styleSpan, width: "20rem" }}>
          <TextStyle variation="strong">Origin ID</TextStyle>
        </span>
        <span style={styleStatus}>
          <TextStyle variation="strong">Status</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Created At</TextStyle>
        </span>
      </Stack>
    ),
    []
  );

  const subTitleMarkup = useMemo(() => <span>{state.total} Orders</span>, [
    state.total,
  ]);

  // Actions
  const handleScroll = useCallback(() => {
    if (!state.key) return;
    const key = state.key;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!state.canLoad) {
      setState({ loadMore: true });
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...innerFilter,
              offset: data[key].nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const nodes = fetchMoreResult[key].nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setState({ canLoad: true });
            }
            setState({ loadMore: false });
            let newNodes = [...prev[key].nodes, ...nodes].filter(Boolean);
            const newNodes2 = new Map(
              newNodes
                .map((node) => (node.id ? [node.id, node] : undefined))
                .filter(Boolean)
            );
            const result = Array.from(newNodes2, ([, value]) => value);

            return {
              ...prev,
              [key]: {
                ...prev[key],
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, innerFilter, state.canLoad, setState, fetchMore, state.key]);

  return (
    <Wrapper>
      <CardBox
        title={title}
        subTitle={subTitleMarkup}
        headMarkup={headerMarkup}
        contentMarkup={contentMarkup}
        filter={innerFilter}
        total={state.total}
        loading={loading}
        error={error}
        setFilter={setInnerFilter}
        handleScroll={handleScroll}
        loadMore={state.loadMore}
        noCollapse
        collapseKey={slug}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .index-wrap {
    width: 3rem;
    display: inline-block;
  }

  .column-wrap {
    width: 7rem;
    display: inline-block;
  }

  .name-wrap {
    display: flex;
    flex: 1 1;
  }
`;
