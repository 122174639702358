import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  Card,
  Checkbox,
  Collapsible,
  Scrollable,
  Spinner,
  Stack,
} from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { REPORT_CREATED_IDEAS_BY_USER } from "../../../graphql/queries";
import { getUnique } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";
import { ComparedPercent } from "./ComparedPercent";
import { IdeaTasksByStatus } from "./IdeaTasksByStatus";
import { useCollapseContext } from "./LayoutSection";

const Draft = "Draft";
const Declined = "Declined";
const Done = "Done";

const DATA_TABLE = "Polaris-DataTable";
const CELL = `${DATA_TABLE}__Cell`;
const CELL_MIDDLE = `${CELL}--verticalAlignMiddle`;
const CELL_HEADER = `${CELL}--header`;
const CELL_FIRST_COLUMN = `${CELL}--firstColumn`;
const TABLE_ROW = `${DATA_TABLE}__TableRow`;
const HOVER = `${DATA_TABLE}--hoverable`;

export const headerProps = {
  className: [CELL, CELL_MIDDLE, CELL_FIRST_COLUMN, CELL_HEADER].join(" "),
  "data-polaris-header-cell": "true",
  scope: "col",
};

export const tdProps = {
  className: [CELL, CELL_MIDDLE, CELL_FIRST_COLUMN].join(" "),
};

export const trProps = {
  className: [TABLE_ROW, HOVER].join(" "),
};

const collapseKey = "ideaTasks";
export function IdeaTasks() {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();
  const { collectionIds, tagIds } = filterCtx || {};
  const { collapseActive = {}, setCollapseActive = () => {} } =
    useCollapseContext() || {};
  const open = !!collapseActive[collapseKey];

  const btnRef = useRef(null);

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    filterByTime: range,
    tagIds,
    collectionIds,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalIdeas: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalIdeas: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const [isDoneDate, setIsDoneDate] = useState(false);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, fetchMore } = useQuery(REPORT_CREATED_IDEAS_BY_USER, {
    variables: {
      filter,
    },
  });
  const [lazyReport, { data: dataC, loading: loadingC }] = useLazyQuery(
    REPORT_CREATED_IDEAS_BY_USER,
  );

  useEffect(() => {
    if (filterCtx != null) {
      let ignoreFilter = filterCtx;
      if ("productBaseIds" in filterCtx || "viewWholeTeamReport" in filterCtx) {
        let productBaseIds, viewWholeTeamReport;
        ({ productBaseIds, viewWholeTeamReport, ...ignoreFilter } = filterCtx);
      }
      setFilter((prev) => ({ ...prev, ...ignoreFilter }));
    }
  }, [filterCtx]);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime:
        range != null ? { ...(prev.filterByTime || {}), ...range } : null,
      collectionIds,
      tagIds,
      teamId: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, collectionIds, tagIds, teamID]);

  useEffect(() => {
    const nodes = data?.reportCreatedIdeasByUser?.nodes;

    const total = data?.reportCreatedIdeasByUser?.total;
    const totalIdeas = data?.reportCreatedIdeasByUser?.totalIdeas;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            // Created by
            const createBy = node?.createBy;
            const firstName = createBy?.firstName;
            const lastName = createBy?.lastName;
            const name = [firstName, lastName].filter(Boolean).join(" ");

            // Status
            const { IdeaByStatusItems } = node;
            const draft = (IdeaByStatusItems || []).find(
              (i) => i?.status === Draft,
            );
            const declined = (IdeaByStatusItems || []).find(
              (i) => i?.status === Declined,
            );
            const done = (IdeaByStatusItems || []).find(
              (i) => i?.status === Done,
            );

            return {
              name,
              draft,
              done,
              declined,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalIdeas,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalIdeas = dataC?.reportCreatedIdeasByUser?.totalIdeas;
      setStateCompare((prev) => ({
        ...prev,
        totalIdeas,
      }));
    } else {
      setStateCompare({ totalIdeas: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportCreatedIdeasByUser.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportCreatedIdeasByUser =
              fetchMoreResult.reportCreatedIdeasByUser;
            const nodes = reportCreatedIdeasByUser.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [...prev.reportCreatedIdeasByUser.nodes, ...nodes];
            const result = getUnique(newNodes, "createById");

            return {
              ...prev,
              reportCreatedIdeasByUser: {
                ...prev.reportCreatedIdeasByUser,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  const handleDoneDateChange = useCallback((value) => {
    if (value) {
      setFilter((prev) => ({
        ...prev,
        filterByTime: prev.filterByTime
          ? { ...prev.filterByTime, field: "done_date" }
          : null,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        filterByTime: prev.filterByTime
          ? { ...prev.filterByTime, field: undefined }
          : null,
      }));
    }

    setIsDoneDate(value);
  }, []);

  // Markup
  const textCenter = {
    textAlign: "center",
    width: "15rem",
  };
  const headMarkup = (
    <thead>
      <tr>
        <th
          rowSpan="2"
          {...headerProps}
          style={{ textAlign: "center", width: "7rem" }}
        >
          #
        </th>
        <th
          rowSpan="2"
          {...headerProps}
          style={{ ...textCenter, width: "20rem" }}
        >
          Name
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Draft
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Declined
        </th>
        <th colSpan="2" {...headerProps} style={textCenter}>
          Done
        </th>
      </tr>
      <tr>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
        <th {...headerProps}>Total</th>
        <th {...headerProps}>KPI</th>
      </tr>
    </thead>
  );

  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          const draft = item?.draft;
          const declined = item?.declined;
          const done = item?.done;

          return (
            <tr {...trProps} key={`item-${index}`}>
              <td {...tdProps}>{index + 1}</td>
              <td {...tdProps}>{item.name}</td>
              <td {...tdProps}>{draft?.totalIdeas}</td>
              <td {...tdProps}>{draft?.totalKpis}</td>
              <td {...tdProps}>{declined?.totalIdeas}</td>
              <td {...tdProps}>{declined?.totalKpis}</td>
              <td {...tdProps}>{done?.totalIdeas}</td>
              <td {...tdProps}>{done?.totalKpis}</td>
            </tr>
          );
        })
      : null;
  }, [state.items]);

  const subTitleMarkup = useMemo(
    () => (
      <Stack vertical spacing="none">
        <span>
          <span>{state.totalIdeas} Tasks</span>
          <ComparedPercent
            originalVal={state.totalIdeas}
            newVal={stateCompare.totalIdeas}
          />
        </span>
        {filter.filterByTime != null && (
          <Checkbox
            checked={isDoneDate}
            onChange={handleDoneDateChange}
            label="For Done Date"
          />
        )}
      </Stack>
    ),
    [
      state.totalIdeas,
      stateCompare.totalIdeas,
      filter,
      isDoneDate,
      handleDoneDateChange,
    ],
  );

  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);
  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  const headerMarkup = useMemo(
    () => (
      <Stack spacing="loose">
        <Stack.Item fill>
          <HeadingWrap>
            <span className="btn-wrap">
              <CaretRightOutlined
                rotate={open ? 90 : 0}
                style={{ color: "#000" }}
                ref={btnRef}
                onClick={handleToggle}
              />
            </span>
            <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
              Report for Idea
            </h2>
          </HeadingWrap>
        </Stack.Item>
        <span>{subTitleMarkup}</span>
      </Stack>
    ),
    [subTitleMarkup, open],
  );

  const spinnerMarkup = (
    <Stack distribution="center">
      <Spinner size="small" />
    </Stack>
  );

  const loadMoreMarkup = loadMore && (
    <div style={{ marginTop: "1rem" }}>{spinnerMarkup}</div>
  );
  return (
    <Card>
      <Card.Header title={headerMarkup} />
      <Card.Section>
        <Collapsible
          open={open}
          id={collapseKey}
          transition={{
            duration: "500ms",
            timingFunction: "ease-in-out",
          }}
          expandOnPrint
        >
          <Wrapper>
            {mergedLoading ? (
              spinnerMarkup
            ) : contentMarkup ? (
              <Scrollable
                shadow
                id={`scrollable-idea-task`}
                style={{
                  maxHeight: "48rem",
                }}
                onScrolledToBottom={handleScroll}
              >
                <table>
                  {headMarkup}
                  <tbody>{contentMarkup}</tbody>
                </table>
                {loadMoreMarkup}
              </Scrollable>
            ) : (
              <EmptyStatePolaris noData slim />
            )}
          </Wrapper>
          <IdeaTasksByStatus />
        </Collapsible>
      </Card.Section>
    </Card>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;

  table {
    table-layout: fixed;
    width: 100%;
    min-width: 65rem;

    tr,
    th,
    td {
      border: 0.1rem solid var(--p-border, #c4cdd5);
      white-space: normal;
      word-break: break-word;
    }
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;
