import React from "react";
import {
    Stack,
    TextField,
    ButtonGroup,
    Button,
    Spinner,
    Card,
    Select,
} from "@shopify/polaris";
import { size, filter, values } from "lodash";
import { useQuery } from "@apollo/react-hooks";

import { isEmail } from "../../../../helper";
import { GET_USER_BY_ROLES } from "../../../../graphql/queries";
import { USER_ROLE, TEAM_ROLE } from "../../../../variable";

import { AutoCompleteMultiplePolaris } from "../../../shared/AutoCompleteMultiplePolaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";

const FIELDS_REQUIRED = { name: "Name", email: "Email" };
const STATUS_OPTIONS = [
    { value: "Connected", label: "Connected" },
    { value: "Checkpointed", label: "Checkpointed" },
    { value: "Dead", label: "Dead" },
];

export function Form({
    onSubmit,
    handleRedirect,
    loading,
    value,
    isEdit,
    isStoreManager,
}) {
    // State
    const [fields, setFields] = React.useState({
        name: "",
        email: "",
        note: "",
        owners: [],
        facebookUid: "",
        status: "",
    });
    const [errors, setErrors] = React.useState({});

    // Get data
    React.useEffect(() => {
        if (value != null) {
            const { title, note, owners, status, facebookUid } = value;
            const ownerIds =
                owners?.length > 0
                    ? owners
                          .map((item) => {
                              if (item?.user?.id) {
                                  return item.user.id;
                              }
                              return null;
                          })
                          .filter(Boolean)
                    : [];

            setFields((prev) => ({
                ...prev,
                name: title,
                note,
                owners: ownerIds,
                status,
                facebookUid,
            }));
        }
    }, [value]);

    // Handle actions
    const validateFields = React.useCallback((value, id) => {
        let error = null;
        let label;
        for (let [key, newLabel] of Object.entries(FIELDS_REQUIRED)) {
            if ([key].includes(id)) {
                label = newLabel;
            }
        }

        if ((!value || !value.length) && label) {
            error = `${label} is required.`;
        } else {
            if ("email" === id) {
                if (!isEmail(value)) {
                    error = "Email must be a valid email address.";
                }
            }
        }
        setErrors((prev) => ({ ...prev, [id]: error }));
    }, []);

    const handleFieldsChange = React.useCallback(
        (value, id) => {
            validateFields(value, id);
            setFields((prev) => ({ ...prev, [id]: value }));
        },
        [validateFields]
    );

    const handleSubmit = React.useCallback(() => {
        for (let [key] of Object.entries(FIELDS_REQUIRED)) {
            validateFields(fields[key], key);
        }
        let { name, email, owners, note, facebookUid, status } = fields;

        let countErr = size(filter(values(errors), (e) => e !== null));
        let canSubmit = false;
        if (isEdit) {
            canSubmit = name && countErr === 0;
        } else {
            canSubmit = name && email && countErr === 0;
        }

        // input
        const newOwners =
            owners.length > 0 ? owners.map((item) => ({ userId: item })) : [];
        const input = {
            name,
            email,
            note,
            owners: newOwners,
            facebookUid,
            status: isEdit ? status : undefined,
        };

        if (canSubmit) {
            onSubmit && onSubmit({ ...input });
        }
    }, [fields, errors, onSubmit, isEdit, validateFields]);

    return (
        <Card sectioned>
            <Stack vertical spacing="loose">
                <div>
                    <ComponentLabelPolaris label="Name" required />
                    <TextField
                        id="name"
                        value={fields["name"]}
                        onChange={handleFieldsChange}
                        error={errors["name"]}
                        placeholder="Enter account name"
                    />
                </div>
                {!isEdit && (
                    <div>
                        <ComponentLabelPolaris label="Email" required />
                        <TextField
                            id="email"
                            value={fields["email"]}
                            onChange={handleFieldsChange}
                            error={errors["email"]}
                            placeholder="Enter account email"
                        />
                    </div>
                )}
                {isEdit && !isStoreManager && (
                    <StoreManager
                        value={fields["owners"]}
                        onChange={handleFieldsChange}
                    />
                )}
                {isEdit && (
                    <Select
                        id="status"
                        label="Status"
                        onChange={handleFieldsChange}
                        value={fields["status"]}
                        options={STATUS_OPTIONS}
                    />
                )}
                <TextField
                    label="Facebook UID"
                    placeholder="Enter facebook uid"
                    value={fields["facebookUid"]}
                    onChange={handleFieldsChange}
                    id="facebookUid"
                    multiline={2}
                />
                <div>
                    <ComponentLabelPolaris label="Note" />
                    <TextField
                        id="note"
                        value={fields["note"]}
                        onChange={handleFieldsChange}
                        multiline={4}
                        placeholder="Enter note aaa"
                    />
                </div>
                <div className="actions-wrap">
                    <ButtonGroup>
                        <Button children="Cancel" onClick={handleRedirect} />
                        <Button
                            children="Submit"
                            primary
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </ButtonGroup>
                </div>
            </Stack>
        </Card>
    );
}

function StoreManager({ onChange, value, error }) {
    // State
    const [options, setOptions] = React.useState([]);

    // Query
    const { data, loading, error: err } = useQuery(GET_USER_BY_ROLES, {
        variables: {
            roles: [
                USER_ROLE.Admin,
                USER_ROLE.Seller,
                USER_ROLE.StoreManager,
                TEAM_ROLE.Supporter,
            ],
        },
    });

    // Get data

    React.useEffect(() => {
        let newData =
            data?.listUserTeamByRoles?.length > 0
                ? data.listUserTeamByRoles
                      .map((item) => {
                          if (item && item.user) {
                              return {
                                  label: `${item.user.firstName} ${item.user.lastName}`,
                                  value: item.user.id,
                              };
                          }
                          return null;
                      })
                      .filter(Boolean)
                : [];
        setOptions(newData);
    }, [data]);

    if (loading) return <Spinner size="small" />;
    if (err) return <div>Error: {err.toString()}</div>;

    return (
        <AutoCompleteMultiplePolaris
            data={options}
            value={value}
            label="Store manager"
            placeholder="Search for store manager"
            onChange={(value) => onChange(value, "owners")}
            error={error}
        />
    );
}
