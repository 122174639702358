import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { BULK_UPDATE_TASK } from "../../../graphql/mutations";
import { DesignTaskFormPolaris } from "./DesignTaskFormPolaris";

export const UpdateTaskBulkActionsPolaris = (props) => {
    const {
        open,
        toggleShowModal,
        onNotificationChange,
        toggleActive,
        idsChecked,
        onCompleted,
    } = props;

    const [updateDesignTask, { loading }] = useMutation(BULK_UPDATE_TASK, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: "Update task success.",
                    isError: false,
                });
            }
            if (onCompleted) {
                onCompleted();
            }
        },
    });

    const handleSubmit = useCallback(
        (input) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            }
            if (input) {
                updateDesignTask({
                    variables: {
                        id: idsChecked,
                        input,
                    },
                });
                if (toggleActive) {
                    toggleActive();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [idsChecked]
    );

    return (
        <Modal
            title="Update task"
            open={open}
            onClose={toggleShowModal}
            sectioned
        >
            <DesignTaskFormPolaris
                onSubmit={handleSubmit}
                loadingM={loading}
                toggleShowModal={toggleShowModal}
            />
        </Modal>
    );
};
