import { Mutation, Query } from "@apollo/react-components";
import { Checkbox, Modal, Spinner } from "@shopify/polaris";
import get from "lodash/get";
import React from "react";
import styled from "styled-components";
import { AppContext } from "../../../context";
import {
  DELETE_FILE_AMZ,
  UPLOAD_DESIGN_TASK,
} from "../../../graphql/mutations";
import { GET_DESIGN_POSITION_FROM_ORDER_TASK } from "../../../graphql/queries";
import {
  getDesignPositionName,
  handleError,
  isPME_TEAM,
  sortDesignPosition,
} from "../../../helper";
import { FILE_SIZE_200MB, FILE_SIZE_50MB, PLATFORMS } from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { isChinaOrCustomFulfill } from "../../order/_utils";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";

const STORE_IDS_HAVE_NEED_BUYER_CONFIRM = ["K7cmWsVAYg"];

const Container = styled.div`
  .upload-design_wrap {
    display: flex;
    flex-direction: column;
    column-gap: 2rem;

    h2,
    h3 {
      font-weight: 500;
    }

    .design-file_wrap {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;

      .design-file_left {
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 50%;
      }
      .design-file_right {
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem 1.6rem;
        .design-position_item {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          text-align: center;
          width: 48%;
        }
      }
      .Polaris-DropZone {
        min-height: 11rem;
      }
    }
  }
  .message_wrap {
    &.success {
      color: #2abd0e;
    }
    &.error {
      color: #bd3b2f;
    }
  }
  .checking-dimensions {
    display: flex;
    align-self: center;
    column-gap: 0.5rem;
  }
`;

class UploadDesignByDesignerPolaris extends React.Component {
  state = {
    designs: {},
    currentDesignId: {},
    disableSubmit: true,
    dimensions: {},
    checkingDimensions: {},
    changeToBuyerConfirm: true,
    maxSize: FILE_SIZE_50MB,
  };

  componentDidUpdate(_prevProps, prevState) {
    // Check dimension image
    let { currentDesignId, dimensions, checkingDimensions } = this.state;
    if (prevState.currentDesignId !== currentDesignId) {
      for (let [key, value] of Object.entries(currentDesignId)) {
        if (value === null) {
          this.setState((prev) => {
            return {
              dimensions: {
                ...prev.dimensions,
                [key]: {
                  message: null,
                },
              },
            };
          });
        }
      }
    }

    // Set disabled button
    if (prevState.dimensions !== dimensions) {
      let disabledBtn = Object.values(dimensions).filter((i) => i.message);
      if (disabledBtn.length) {
        disabledBtn = disabledBtn.every((i) => i.type === "success");
      } else {
        disabledBtn = false;
      }
      this.setState({
        disableSubmit: !disabledBtn,
      });
    }

    if (prevState.checkingDimensions !== checkingDimensions) {
      let disabledBtn = true;
      let someTruthy = Object.values(checkingDimensions).some(
        (i) => i === true,
      );
      if (!someTruthy) {
        disabledBtn = this.state.disableSubmit;
      }
      this.setState({
        disableSubmit: disabledBtn,
      });
    }

    // Apply disable button
    // if (!isEqual(prevState.designs, designs)) {
    //   let haveDs = designs.find((i) => {
    //     if (i.designPosition.description) {
    //       return i;
    //     }
    //   });
    //   if (!haveDs) {
    //     this.setState({
    //       disableSubmit: false,
    //     });
    //   }
    // }
  }

  componentDidMount() {
    const { currentUser } = this.context || {};
    const isPMETeam = isPME_TEAM(currentUser);

    const { task } = this.props;
    const platform = get(task, "order.store.platform");
    const storeID = get(task, "order.store.id");
    const canBuyerConfirm =
      STORE_IDS_HAVE_NEED_BUYER_CONFIRM.includes(storeID) ||
      [PLATFORMS.Etsy].includes(platform);

    this.setState(
      {
        isPMETeam,
        canBuyerConfirm,
      },
      async () => {
        let maxSize = this.state.maxSize;
        const isChinaOrCF = await isChinaOrCustomFulfill(task?.orderId);
        if (isChinaOrCF) {
          maxSize = FILE_SIZE_200MB;
        }

        this.setState({ maxSize });
      },
    );
  }

  // Check dimension image
  handleCheckDimensions = (dimensions, idx) => {
    let { currentDesignId } = this.state;
    if (Object.keys(currentDesignId).length) {
      for (let [key, value] of Object.entries(dimensions)) {
        if (key === currentDesignId[idx]) {
          let isRealDimensionOk = value && value.isRealDimensionOk;
          let message = isRealDimensionOk
            ? "You can upload this file."
            : "The dimensions of file uploaded is not correct.";
          this.setState({
            dimensions: {
              ...this.state.dimensions,
              [idx]: {
                message,
                type: isRealDimensionOk ? "success" : "error",
              },
            },
          });
        }
      }
    }
  };

  deleteFileOutAmz = (id, client) => {
    if (id) {
      client
        .mutate({
          mutation: DELETE_FILE_AMZ,
          variables: {
            id,
            deleteAmz: true,
          },
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  // Handle action submit
  handleSubmit = (newUploadDesign) => {
    let { designs } = this;
    let { task, toggleActive } = this.props;
    const { changeToBuyerConfirm, isPMETeam, canBuyerConfirm } = this.state;

    if (Object.keys(designs).length) {
      newUploadDesign({
        variables: {
          input: {
            id: task.id,
            designs: Object.values(designs),
            changeToBuyerConfirm:
              isPMETeam && canBuyerConfirm ? changeToBuyerConfirm : undefined,
          },
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
  };

  designs = {};

  onChangeStatus = (newVal) => {
    this.setState({ changeToBuyerConfirm: newVal });
  };

  timer;
  handleCheckingDimensions = (index) => (value) => {
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        checkingDimensions: {
          ...this.state.checkingDimensions,
          [index]: value,
        },
      });
    }, 500);
  };

  render() {
    const {
      open,
      toggleShowModal,
      onNotificationChange,
      reUpload,
      task,
      refetch,
      isDesigner,
      userId,
    } = this.props;

    const {
      disableSubmit,
      dimensions,
      checkingDimensions,
      changeToBuyerConfirm,
      isPMETeam,
      canBuyerConfirm,
    } = this.state;
    const { onChangeStatus } = this;

    const productBaseId = task?.order?.productVariant?.productBaseVariant
      ?.productBase
      ? task?.order.productVariant.productBaseVariant.productBase.id
      : task?.order?.product?.productBases[0]?.id;

    return (
      <React.Fragment>
        <Mutation
          mutation={UPLOAD_DESIGN_TASK}
          onCompleted={() => {
            toggleShowModal();
            if (onNotificationChange) {
              onNotificationChange({
                message: "Design has been saved.",
                isError: false,
              });
            }
            if (refetch) {
              refetch();
            }
          }}
          onError={(error) => {
            toggleShowModal();
            if (onNotificationChange) {
              onNotificationChange({
                message: handleError(error.toString()),
                isError: true,
              });
            }
          }}
        >
          {(newUploadDesign, { loading, client }) => (
            <Modal
              title={`${reUpload ? "Re-upload" : "Upload"} design for task: #${
                task.id
              }`}
              open={open}
              onClose={toggleShowModal}
              sectioned
              large
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: () => {
                    let currentDesignId = this.state.currentDesignId;
                    for (let [, value] of Object.entries(currentDesignId)) {
                      if (value) {
                        this.deleteFileOutAmz(value, client);
                      }
                    }
                    if (toggleShowModal) {
                      toggleShowModal();
                    }
                  },
                },
              ]}
              primaryAction={{
                content: "Submit",
                onAction: () => this.handleSubmit(newUploadDesign),
                loading: loading,
                disabled: disableSubmit,
              }}
            >
              <Container>
                <Query
                  query={GET_DESIGN_POSITION_FROM_ORDER_TASK}
                  variables={{ taskId: task.id }}
                  fetchPolicy="network-only"
                >
                  {({ data, loading, error }) => {
                    if (loading) return <SkeletonPagePolaris />;
                    if (error)
                      return <div>Error: {handleError(error.toString())}</div>;

                    let designPositions =
                      data && data.getDesignPositionFromOrderTask;
                    if (
                      !designPositions ||
                      (designPositions && designPositions.length === 0)
                    )
                      return <EmptyStatePolaris noData />;
                    // const idx = designPositions.findIndex(
                    //   (d) => d.name.toLowerCase() === "master",
                    // );
                    // if (idx > 0) {
                    //   [designPositions[0], designPositions[idx]] = [
                    //     designPositions[idx],
                    //     designPositions[0],
                    //   ];
                    // }

                    // convert to sort
                    designPositions = sortDesignPosition(
                      designPositions.map((i) => ({ designPosition: i })),
                    );

                    designPositions = (designPositions || []).map(
                      (item) => item.designPosition,
                    );
                    const firstDB = (designPositions || [])[0];
                    const dpName = getDesignPositionName(firstDB);

                    return (
                      <div className="upload-design_wrap">
                        <h2>Design Files</h2>
                        {isPMETeam && canBuyerConfirm ? (
                          <div
                            stlye={{
                              marginTop: 16,
                              marginBottom: 16,
                            }}
                          >
                            <Checkbox
                              label="Change status to Need Buyer Confirm"
                              checked={changeToBuyerConfirm}
                              onChange={onChangeStatus}
                            />
                          </div>
                        ) : null}
                        <div className="design-file_wrap">
                          <div className="design-file_left">
                            <h3>{dpName}</h3>
                            <MediaSelectorButtonPolaris
                              prefix="print_files/"
                              folder={"print_files"}
                              userId={isDesigner ? userId : null}
                              multiple={false}
                              typeSpecial
                              singleUpload={true}
                              accept={"image/*"}
                              placeholderImage={designPositions[0].image}
                              productBaseId={productBaseId}
                              isPrintFile={isDesigner}
                              maxSize={this.state.maxSize}
                              onChange={(files) => {
                                if (files.length) {
                                  const design = {
                                    designPositionId: designPositions[0].id,
                                    fileId: files[0].id,
                                  };
                                  this.designs[designPositions[0].id] = design;
                                  this.setState((prev) => {
                                    return {
                                      currentDesignId: {
                                        ...prev.currentDesignId,
                                        [0]: files[0].id,
                                      },
                                    };
                                  });
                                } else {
                                  this.setState((prev) => {
                                    return {
                                      currentDesignId: {
                                        ...prev.currentDesignId,
                                        [0]: null,
                                      },
                                    };
                                  });
                                }
                              }}
                              // Check dimensions
                              isCompareDesign
                              isUploadDesign
                              designPosition={designPositions[0]}
                              onCheckDimensions={(value) => {
                                // Disable check dimension.
                                if (value === true) {
                                  this.setState({
                                    disableSubmit: false,
                                  });
                                  return;
                                }
                                return this.handleCheckDimensions(value, 0);
                              }}
                              onCheckingDimensions={this.handleCheckingDimensions(
                                0,
                              )}
                              // onCheckingDimensions={(value) =>
                              // this.setState({
                              //   checkingDimensions: {
                              //     ...this.state.checkingDimensions,
                              //     [0]: value,
                              //   },
                              // })
                              // }
                              onRemove={(id) =>
                                this.deleteFileOutAmz(id, client)
                              }
                            />

                            {this.state.checkingDimensions[0] ? (
                              <Spinner size="small" />
                            ) : dimensions[0] && dimensions[0].message ? (
                              <p
                                className={`message_wrap ${
                                  dimensions[0].type === "success"
                                    ? "success"
                                    : "error"
                                }`}
                              >
                                {dimensions[0].message}
                              </p>
                            ) : null}
                            <i className="description_wrap">
                              {designPositions[0].description}
                            </i>
                          </div>
                          <div className="design-file_right">
                            {designPositions.map((ds, index) => {
                              if (!index) return null;
                              const dpName = getDesignPositionName(ds);
                              return (
                                <div
                                  className="design-position_item"
                                  key={`design-position-${index}`}
                                >
                                  <h3>{dpName}</h3>
                                  <MediaSelectorButtonPolaris
                                    prefix="print_files/"
                                    folder={"print_files"}
                                    userId={isDesigner ? userId : null}
                                    isPrintFile={isDesigner}
                                    multiple={false}
                                    singleUpload={true}
                                    accept={"image/*"}
                                    typeSpecial
                                    placeholderImage={ds.image}
                                    productBaseId={productBaseId}
                                    onChange={(files) => {
                                      if (files.length) {
                                        const design = {
                                          designPositionId:
                                            designPositions[index].id,
                                          fileId: files[0].id,
                                        };
                                        this.designs[
                                          designPositions[index].id
                                        ] = design;
                                        this.setState((prev) => {
                                          return {
                                            currentDesignId: {
                                              ...prev.currentDesignId,
                                              [index]: files[0].id,
                                            },
                                          };
                                        });
                                      } else {
                                        this.setState((prev) => {
                                          return {
                                            currentDesignId: {
                                              ...prev.currentDesignId,
                                              [index]: null,
                                            },
                                          };
                                        });
                                      }
                                    }}
                                    // Check dimensions
                                    isCompareDesign
                                    isUploadDesign
                                    designPosition={designPositions[index]}
                                    onCheckDimensions={(value) => {
                                      if (value === true) {
                                        this.setState({
                                          disableSubmit: false,
                                        });
                                        return;
                                      }
                                      return this.handleCheckDimensions(
                                        value,
                                        index,
                                      );
                                    }}
                                    onCheckingDimensions={this.handleCheckingDimensions(
                                      index,
                                    )}
                                    // onCheckingDimensions={(value) =>
                                    //   this.setState({
                                    //     checkingDimensions: {
                                    //       ...this.state.checkingDimensions,
                                    //       [index]: value,
                                    //     },
                                    //   })
                                    // }
                                    onRemove={(id) =>
                                      this.deleteFileOutAmz(id, client)
                                    }
                                  />
                                  {checkingDimensions[index] ? (
                                    <div className="checking-dimensions">
                                      <Spinner size="small" />
                                      <span>Checking dimensions...</span>
                                    </div>
                                  ) : dimensions[index] &&
                                    dimensions[index].message ? (
                                    <p
                                      className={`message_wrap ${
                                        dimensions[index].type === "success"
                                          ? "success"
                                          : "error"
                                      }`}
                                    >
                                      {dimensions[index].message}
                                    </p>
                                  ) : null}
                                  <i className="description_wrap">
                                    {ds.description}
                                  </i>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Query>
              </Container>
            </Modal>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

UploadDesignByDesignerPolaris.contextType = AppContext;

export default UploadDesignByDesignerPolaris;
