import { gql } from "apollo-boost";
import { UserFragment } from "../fragments";

export const ADMIN_GET_USER_TOKEN = gql`
  mutation adminGetUserToken($userID: ID!) {
    adminGetUserToken(userID: $userID) {
      id
      expiredAt
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export const UPDATE_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM = gql`
  mutation updateUserHasPermissionAcceptAccessInTeam($userIds: [ID]!) {
    updateUserHasPermissionAcceptAccessInTeam(userIds: $userIds) {
      id
    }
  }
`;

export const UPDATE_MANUAL_ORDER_CREATION_NOTICE = gql`
  mutation updateManualOrderCreationNotice($userIds: [ID]!) {
    updateManualOrderCreationNotice(userIds: $userIds) {
      id
    }
  }
`;

export const VERIFY_2FA_USER = gql`
  mutation verify2faUser($code: String) {
    verify2faUser(code: $code)
  }
`;

export const ENABLE_2FA = gql`
  mutation enable2fa($input: NewEnable2FA!) {
    enable2fa(input: $input)
  }
`;

export const RE_GEN_2FA_CODE = gql`
  mutation reGen2faCode {
    reGen2faCode {
      Secret
      Url
    }
  }
`;

export const VERIFY_UPDATE_2FA_USER = gql`
  mutation verifyUpdate2faUser($input: VerifyUpdate2faUser!) {
    verifyUpdate2faUser(input: $input)
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($input: NewLogin!) {
    login(input: $input) {
      id
      expiredAt
      use2fa
      enable2fa
      otpUrl
      twoFASecret
      user {
        id
        firstName
        lastName
        email
        phone
        address
        extraMenus
        isRootUser
        isOnline
        viewOriginFile
        ignoreTwoFA
        privileges
        avatar {
          id
          name
          url
        }
        teamUser {
          team {
            id
          }
          role
          designOnlineTeamID
          team {
            id
            privateFeatures
            isRoot
          }
        }
        teams {
          id
          privateFeatures
          isRoot
        }
        roles
      }
    }
  }
`;

export const SEND_ACTIVATION_MAIL_TO_USERS = gql`
  mutation sendActivationMaiToUser($userID: ID!) {
    sendActivationMaiToUser(userID: $userID)
  }
`;