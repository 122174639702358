import React from "react";
import { Button } from "@shopify/polaris";
import { useThumbnailFileCtx } from "../context/ThumbnailFileContext";

export function ThumbnailFileControl() {
    const { toggleActive, active } = useThumbnailFileCtx();
    return (
        <Button
            children="Compare by thumbnail"
            primary={active}
            onClick={toggleActive}
            size="slim"
        />
    );
}
