import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { COMPLEX_PRODUCT_ASSORTMENT } from "../../graphql/queries";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ToastContextProvider } from "../shared/ToastContext";
import { DataTableComp, Filter } from "./components";

const FIELD_FILTER = [...COMMON_FILTER, "domain", "authorId", "storeId"];

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export function ComplexAssortment({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      initialFilter.limit = Number(initialFilter.limit) || 10;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // State
  const [filter, setFilter] = useState({
    search: "",
    limit: +getCookie("perPageComplexAssortment") || 20,
    offset: 0,
    createdTime: null,
    domain: null,
    authorId: null,
    storeId: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error } = useQuery(COMPLEX_PRODUCT_ASSORTMENT, {
    variables: {
      filter,
    },
  });

  // Handle action
  const handleFilterChange = useCallback(
    ({ search, createdTime, domain, authorId, storeId }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.createdTime, createdTime) ||
          !isEqual(prev.domain, domain) ||
          !isEqual(prev.authorId, authorId) ||
          !isEqual(prev.storeId, storeId)
        ) {
          return {
            ...prev,
            search,
            createdTime,
            domain,
            authorId,
            storeId,
            offset: 0,
          };
        }
        return prev;
      });
    },
    [],
  );
  const handlePaginationChange = useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageComplexAssortment", limit, 100);
  }, []);

  useEffect(() => {
    let { offset, limit, createdTime, ...rest } = filter;
    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <ToastContextProvider filter={filter}>
        <Page fullWidth title="Complex Assortment">
          <Card sectioned>
            <Filter filter={filter} onChange={handleFilterChange} />
          </Card>
          <Card>
            {loading ? (
              <SkeletonPagePolaris />
            ) : error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : data?.complexProductAssortment?.nodes?.length > 0 ? (
              <DataTableComp
                data={data}
                filter={filter}
                setFilter={handlePaginationChange}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        </Page>
      </ToastContextProvider>
    </Container>
  );
}
