import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REPORT_CREATED_PRODUCTS_BY_USER } from "../../../graphql/queries";
import { getUnique } from "../../../helper";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";

const collapseKey = "createdProduct";

export function CreatedProductByUser({ saleChanel }) {
  // Context
  const {
    range,
    filter: filterCtx,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    search: null,
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    storeManagerID: null,
    storeID: null,
  });
  const [state, setState] = useState({
    items: [],
    total: 0,
    totalSale: 0,
    totalProducts: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalProducts: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, error, fetchMore } = useQuery(
    REPORT_CREATED_PRODUCTS_BY_USER,
    {
      variables: {
        filter,
      },
    },
  );

  const [lazyReport, { data: dataC, loading: loadingC, error: errorC }] =
    useLazyQuery(REPORT_CREATED_PRODUCTS_BY_USER);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    if (filterCtx != null) {
      let ignoreFilter = filterCtx;
      if ("fulfillmentID" in filterCtx || "viewWholeTeamReport" in filterCtx) {
        let fulfillmentID, viewWholeTeamReport;
        ({ fulfillmentID, viewWholeTeamReport, ...ignoreFilter } = filterCtx);
      }
      setFilter((prev) => ({ ...prev, ...ignoreFilter }));
    }
  }, [filterCtx]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ?? undefined,
    }));
    setCantLoad(false);
  }, [range, teamID]);

  useEffect(() => {
    const nodes = data?.reportCreatedProductsByUser?.nodes;
    const total = data?.reportCreatedProductsByUser?.total;
    const totalProducts = data?.reportCreatedProductsByUser?.totalProducts;
    const newItems =
      nodes?.length > 0
        ? nodes.map((node) => {
            const totalProducts = node?.totalProducts;
            const user = node?.user;
            const firstName = user?.firstName;
            const lastName = user?.lastName;
            const name = [firstName, lastName].filter(Boolean).join(" ");

            return {
              name,
              totalProducts,
            };
          })
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalProducts,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const totalProducts = dataC?.reportCreatedProductsByUser?.totalProducts;
      setStateCompare((prev) => ({
        ...prev,
        totalProducts,
      }));
    } else {
      setStateCompare({ totalProducts: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportCreatedProductsByUser.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportCreatedProductsByUser =
              fetchMoreResult.reportCreatedProductsByUser;
            const nodes = reportCreatedProductsByUser.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [
              ...prev.reportCreatedProductsByUser.nodes,
              ...nodes,
            ];
            const result = getUnique(newNodes, "userId");

            return {
              ...prev,
              reportCreatedProductsByUser: {
                ...prev.reportCreatedProductsByUser,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <Stack
              key={`assignee-${index}`}
              distribution="equalSpacing"
              wrap={false}
            >
              <span className="index-wrap">{index + 1}</span>
              <Stack.Item fill>{item.name}</Stack.Item>
              <span>{item.totalProducts}</span>
            </Stack>
          );
        })
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <TextStyle variation="strong">User</TextStyle>
      </Stack.Item>
      <TextStyle variation="strong">Created products</TextStyle>
    </Stack>
  );

  const subTitleMarkup = useMemo(
    () => (
      <span>
        <span>{state.totalProducts} New products</span>
        <ComparedPercent
          originalVal={state.totalProducts}
          newVal={stateCompare.totalProducts}
        />
      </span>
    ),
    [state.totalProducts, stateCompare.totalProducts],
  );

  return (
    <CardBox
      title="New product staffs"
      subTitle={subTitleMarkup}
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      total={state.total}
      loading={mergedLoading}
      error={error || errorC}
      setFilter={setFilter}
      handleScroll={handleScroll}
      loadMore={loadMore}
      collapseKey={collapseKey}
    />
  );
}
