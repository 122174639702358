import React, { Component } from "react";
import ListUser from "../../components/admin/ListUser";

class Users extends Component {
  render() {
    return <ListUser />;
  }
}

export default Users;
