import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { Tabs } from "antd";

const Container = styled.div`
  .ant-tabs-nav-scroll {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default class FilterOrders extends Component {
  state = {
    defaultValue: this.props.defaultValue,
  };

  onChange = (item) => {
    if (this.props.onChange) {
      this.props.onChange(item);
      this.setState({ defaultValue: item });
    }
  };

  getMachineName = (s) => {
    if (!s) {
      return "";
    }
    return s.toLowerCase().replace(/ /g, "_");
  };

  getCount(aggregations, slug) {
    return _.get(aggregations, `${slug}.doc_count`);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(nextProps, this.props) ||
      nextProps.defaultValue !== this.props.defaultValue ||
      this.state.defaultValue !== nextState.defaultValue
    ) {
      this.setState({ defaultValue: nextProps.defaultValue });
      return true;
    }
    return false;
  }

  render() {
    const { value, aggregations, total } = this.props;
    const { defaultValue } = this.state;

    return (
      <Container className="bg-white flex order-tabs">
        <Tabs
          className="w-full order"
          defaultActiveKey={defaultValue}
          activeKey={defaultValue || "null"}
          onChange={(e) => {
            if (this.props.onChange) {
              this.props.onChange(e === "null" ? null : e);
              this.setState({ defaultValue: e === "null" ? null : e });
            }
          }}
        >
          {value &&
            value.map((item) => {
              const slug = this.getMachineName(item.key);
              return (
                <Tabs.TabPane
                  tab={`${item.name} ${
                    aggregations
                      ? "(" +
                        (!item.key
                          ? total || 0
                          : this.getCount(aggregations, slug) || 0) +
                        ")"
                      : ""
                  }`}
                  key={item.key}
                />
              );
            })}
        </Tabs>
      </Container>
    );
  }
}

FilterOrders.propTypes = {
  value: PropTypes.array,
  defaultValue: PropTypes.string,
  aggregations: PropTypes.any,
};
