import React from "react";
import OrderStats from "../../components/order/stats";
import { WrapperPage } from "../../components/shared/WrapperPage";

export default function OrderStatsPage() {
  return (
    <WrapperPage>
      <OrderStats />
    </WrapperPage>
  );
}
