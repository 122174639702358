import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { notification, Skeleton } from "antd";
import { gql } from "apollo-boost";
import styled from "styled-components";
import history from "../../history";
import { Query, Mutation } from "@apollo/react-components";
import FormTask from "./FormTask";
import { handleError } from "../../helper";
import { AppContext } from "../../context";

import _ from "lodash";
import { TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../variable";

const Container = styled.div``;
const EDIT_TASK = gql`
  mutation updateTask($input: EditTask!) {
    updateTask(input: $input) {
      id
      title
      body
    }
  }
`;
const TASKBYID = gql`
  query taskById($id: ID!) {
    taskById(id: $id) {
      id
      title
      body
      assignee {
        id
      }
      product {
        id
      }
    }
  }
`;
class EditTask extends Component {
  static contextType = AppContext;
  state = {
    loading: false,
  };
  render() {
    const id = this.props.match.params.id;
    const { onCompleted } = this.props;

    const { currentUser } = this.context;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    const role = _.get(currentUser, "teamUser.role");
    if ([TEAM_ROLE.StoreManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    return (
      <Container style={{ maxWidth: "700px" }}>
        <Mutation
          mutation={EDIT_TASK}
          onCompleted={(res) => {
            this.setState({ loading: false }, () => {
              notification.success({ message: "Edit task success!" });
              if (onCompleted) {
                onCompleted(res.updateTask);
              }
            });
            history.push(`/${currentParam}/tasks`);
          }}
          onError={(err) => {
            this.setState({ loading: false }, () => {
              notification.error({ message: "Error" });
            });
          }}
        >
          {(updateTask, { client }) => (
            <Query query={TASKBYID} variables={{ id: id }}>
              {({ data, error, loading }) => {
                if (loading)
                  return (
                    <div className="p-4">
                      <Skeleton active />
                    </div>
                  );
                if (error)
                  return <div>Error:{handleError(error.toString())}</div>;
                return (
                  <FormTask
                    onSubmit={(values) => {
                      this.setState({ loading: false });
                      updateTask({
                        variables: {
                          input: { ...values, id },
                        },
                      });
                    }}
                    value={data.taskById}
                  />
                );
              }}
            </Query>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default Form.create({ name: "form" })(EditTask);
