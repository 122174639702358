import React, { useCallback, useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { FORCE_CANCEL_TASK } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";

export const CancelPolaris = (props) => {
    // Props
    const {
        open,
        toggleShowModal,
        task,
        refetch,
        toggleActive,
        onNotificationChange,
    } = props;
    // Block body scroll
    // useLockBodyScroll(open);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [forceCancelTask, { loading }] = useMutation(FORCE_CANCEL_TASK, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: "Cancel task success.",
                    isError: false,
                });
            }

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
    });

    // Handle action
    const handleSubmit = useCallback(() => {
        if (onNotificationChange) {
            onNotificationChange({
                message: null,
                isError: true,
            });
        }
        let id = task.id;
        if (id) {
            forceCancelTask({
                variables: {
                    id,
                },
            });
        }
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    return (
        <Modal
            title="Cancel task"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to cancel this task?</p>
        </Modal>
    );
};
