import React from "react";
import { REPORT_ORDERS_ERROR } from "../../../graphql/queries";
import { ReportOrders } from "./ReportOrders";

export function ReportError({ filter }) {
  return (
    <ReportOrders
      filter={filter}
      documentNode={REPORT_ORDERS_ERROR}
      title="Orders has status Error"
    />
  );
}
