import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const ROLES = gql`
  query systemRoles {
    systemRoles
  }
`;

export default function FilterUserAccountAdminRolePolaris(props) {
  const { role, onChange } = props;

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(["All"]);

  const {
    loading: loadingRoles,
    error: errorRoles,
    data: dataRoles,
  } = useQuery(ROLES);

  useEffect(() => {
    let result;
    if (dataRoles && dataRoles.systemRoles) {
      result = dataRoles.systemRoles;
      if (result.length) {
        let newResult = [{ value: "All", label: "All" }];
        result.forEach((i) => {
          let item = {};
          item["value"] = i;
          item["label"] = i;
          newResult.push(item);
        });
        setOptions(newResult);
      }
    }
  }, [dataRoles, role]);

  useEffect(() => {
    if (null === role) {
      setSelected(["All"]);
    } else {
      setSelected([role]);
    }
  }, [role]);

  const handleChoicesChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOptions = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOptions && matchedOptions.label;
    });
    if (onChange) {
      onChange(
        "All" !== selected[0] ? selected[0] : null,
        "All" !== selectedValue[0] ? selectedValue[0] : null
      );
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loadingRoles && (
        <Spinner
          accessibilityLabel="Spinner example"
          size="small"
          color="teal"
        />
      )}
      {errorRoles && <div>{errorRoles.toString()}</div>}
      {!loadingRoles && !errorRoles && (
        <ChoiceList
          title="Role"
          titleHidden={true}
          selected={selected}
          choices={options}
          onChange={handleChoicesChange}
        />
      )}
    </div>
  );
}
