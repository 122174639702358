import React, { useState, useCallback, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { ORDERS_REFUND } from "../../../graphql/mutations";

export const RefundOrderPolaris = ({
    id,
    toggleShowModal,
    open,
    refetch,
    changeStatusCount,
    convertOS,
}) => {
    // useLockBodyScroll(open);

    // State
    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    // Mutation
    const [ordersRefund, { loading, data, error }] = useMutation(
        ORDERS_REFUND,
        {
            onCompleted: () => {
                const idTimeout = setTimeout(() => {
                    toggleShowModal(false);
                    if (changeStatusCount) {
                        changeStatusCount(
                            convertOS && [
                                convertOS.ReadyToPrint,
                                convertOS.PendingPayment,
                                convertOS.Printing,
                                convertOS.Fulfilled,
                                convertOS.Cancelled,
                                convertOS.DesignRejected,
                                convertOS.Error,
                                convertOS.Refunded,
                            ]
                        );
                    }
                    if (refetch) {
                        refetch();
                    }
                }, 2100);
                setTimeoutId(idTimeout);
            },
            onError: () => {},
        }
    );

    // Handle action
    const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
    const handleSubmit = useCallback(() => {
        if (id) {
            ordersRefund({
                variables: {
                    ids: [id],
                },
            });
            toggleActive();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toastMarkup = activeToast
        ? (error || (data && data.ordersRefund)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data &&
                            data.ordersRefund &&
                            "The order is updated to Refunded."
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <React.Fragment>
            {toastMarkup}
            <Modal
                title={`Set status Refunded for order #${id}`}
                open={open}
                sectioned
                onClose={toggleShowModal}
                secondaryActions={[
                    { content: "Cancel", onAction: toggleShowModal },
                ]}
                primaryAction={{
                    content: "Submit",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <p>Are you sure to change status to Refunded for this order?</p>
            </Modal>
        </React.Fragment>
    );
};
