import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { AutoComplete, Input, notification, Tag } from "antd";
import { ApolloConsumer } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";
import _ from "lodash";
import QuickAddTag from "./QuickAddTag";

const TAGS_QUERY = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      total
      nodes {
        id
        name
      }
    }
  }
`;

class TagsAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      loading: false,
      result: [],
      cache: [],
    };
    this.searchDebounce = _.debounce(this.onSearch, 250);
    this.onSearch = this.onSearch.bind(this);
  }
  onSearch = (client, search) => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await client.query({
          query: TAGS_QUERY,
          fetchPolicy: "network-only",
          variables: {
            filter: {
              search: search,
              limit: search ? 500 : 20,
              offset: 0,
            },
          },
        });
        this.setState({
          loading: false,
          result:
            res.data && res.data.tags && res.data.tags.nodes
              ? res.data.tags.nodes
              : [],
          cache:
            !search && res.data && res.data.tags && res.data.tags.nodes
              ? res.data.tags.nodes
              : this.state.cache,
        });
      } catch (e) {
        this.setState(
          {
            loading: false,
          },
          () => {
            notification.error({ message: handleError(e.toString()) });
          }
        );
      }
    });
  };

  handleSelect = (tag) => {
    const { value } = this.props;
    if (this.props.onChange) {
      this.props.onChange([...value, tag]);
    }
  };
  render() {
    const { value } = this.props;
    const { result, loading, search } = this.state;
    const options = result
      .filter((r) => !value.find((v) => v.id === r.id))
      .map((item, index) => (
        <AutoComplete.Option value={item.id} key={index}>
          {item.name}
        </AutoComplete.Option>
      ));

    if (
      !loading &&
      search &&
      (result.length === 0 ||
        !result.find((r) => r.name.toLowerCase() === search.toLowerCase()))
    ) {
      options.push(
        <AutoComplete.Option key={"add-new"} value={"add new"} className="p-0">
          <QuickAddTag
            onCompleted={(tag) => {
              this.setState(
                {
                  search: "",
                  result: [],
                  cache: [],
                },
                () => {
                  this.handleSelect(tag);
                }
              );
            }}
            value={search}
          />
        </AutoComplete.Option>
      );
    }

    return (
      <div>
        <ApolloConsumer>
          {(client) => (
            <AutoComplete
              style={{ width: "100%" }}
              onSelect={(selected) => {
                if ("add new" === selected) {
                  return;
                }
                const tag = result.find((r) => r.id === selected);
                this.handleSelect(tag);
                this.setState({
                  search: "",
                  result: this.state.cache,
                });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
              value={search}
              dataSource={options}
              onSearch={(s) => {
                if (this.state.search !== s) {
                  this.setState({ search: s, loading: true }, () => {
                    this.searchDebounce(client, s);
                  });
                }
              }}
              onFocus={() => {
                this.setState({ result: [] });
                this.searchDebounce(client);
              }}
            >
              <Input.Search
                placeholder={"Search for tag"}
                prefix={<LegacyIcon type={loading ? "loading" : "search"} />}
              />
            </AutoComplete>
          )}
        </ApolloConsumer>
        {/* {!loading &&
          search &&
          (result.length === 0 ||
            !result.find(
              (r) => r.name.toLowerCase() === search.toLowerCase()
            )) && (
            <QuickAddTag
              onCompleted={(tag) => {
                this.setState(
                  {
                    search: "",
                    result: [],
                    cache: [],
                  },
                  () => {
                    this.handleSelect(tag);
                  }
                );
              }}
              value={search}
            />
          )} */}
        {value &&
          value.map((tag, index) => (
            <Tag
              style={{ marginTop: 8, marginRight: 8 }}
              closable={true}
              onClose={() => {
                value.splice(index, 1);
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }}
              key={tag.id}
            >
              {tag.name}
            </Tag>
          ))}
      </div>
    );
  }
}
TagsAutoComplete.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabledQuickAdd: PropTypes.bool,
};

export default TagsAutoComplete;
