import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Mutation, Query } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Skeleton,
} from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import CustomizeButton from "../../components/customize/CustomizeButton";
import { genHelpText } from "../../components/product/components/ShortTitle";
import ProductPrintFiles from "../../components/product/ProductPrintFiles";
import { validateDimensions } from "../../components/product/_utils";
import CampaignProductBaseItems from "../../components/seller/CampaignProductBaseItems";
import ProductBaseSelect from "../../components/seller/ProductBaseSelect";
import { ProductTimeline } from "../../components/seller/ProductTimeline";
import PushHistory from "../../components/seller/PushHistory";
import PushProduct from "../../components/seller/PushProduct";
import CollectionsAutoComplete from "../../components/shared/CollectionsAutoComplete";
import PageTitle from "../../components/shared/PageTitle";
import TagsAutoComplete from "../../components/shared/TagsAutoComplete";
import MediaSelectorButton from "../../components/supplier/MediaSelectorButton";
import Wysiwyg from "../../components/Wysiwyg";
import { fieldsCampaign } from "../../constants";
import { AppContext } from "../../context";
import { GET_PRODUCT_BY_TITLE } from "../../graphql/queries";
import {
  convertStringToObject,
  getParam,
  getProductMockupFolder,
  handleCampaignFields,
  handleDeepEqual,
  handleError,
  objectInvalid,
} from "../../helper";
import history from "../../history";
import {
  AMZ_MESSAGE_ERROR,
  // TEAM_ROLE,
  // TEAM_ROLE_PARAMS,
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
  WHICH_FIELD,
} from "../../variable";
import { QUERY_DP_BY_BASE_SELECTED } from "./AddCampaignProduct";

const LOAD_QUERY = gql`
  query productById($id: ID!) {
    productByID(id: $id) {
      id
      refURL
      productBases {
        id
        title
        teamId
        fulfillment {
          fulfillmentId
          productId
        }
        attributes {
          name
          slug
          options
        }
        images {
          id
          name
          thumbnailUrl
          url
        }
        designPositions {
          id
          image {
            id
            thumbnailUrl
            url
          }
          name
          displayName
          sorting
          description
        }
        variants {
          id
          attributes {
            name
            slug
            option
          }
          regularPrice
          salePrice
          sellerPrice
          sorting
          status
          supplierPricing {
            id
            price
          }
        }
      }
      mainImageId
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
        }
      }
      collections {
        id
        name
      }
      tags {
        id
        name
      }
      title
      shortTitle
      amzTitle
      sku
      regularPrice
      salePrice
      description
      shortDescription
      designStatus
      variants {
        id
        productBaseVariantId
        regularPrice
        disabled
        salePrice
        sku
        sorting
        image {
          id
          thumbnailUrl
          url
        }
        amazonImages {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
      }
      shortDescription
      designPositions {
        id
        designPosition {
          id
          image {
            id
            name
            thumbnailUrl
            url
          }
          name
          displayName
          sorting
          description
          productBaseId
          artworkGuidelines {
            description
            file {
              url
              thumbnailUrl
              name
            }
          }
        }
        file {
          id
          name
          thumbnailUrl
          url
        }
      }
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
        }
      }
      personalized
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
      productStores {
        id
        store {
          id
          title
          platform
        }
        permalink
        originId
        createdAt
        updatedAt
      }
      psd
      psdFiles {
        id
        bucket
        key
        name
        size
        mimeType
        url
        thumbnailUrl
        alt
        caption
        createdAt
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation updateProduct($input: EditProduct!) {
    updateProduct(input: $input) {
      id
      title
      sku
      description
      shortDescription
      designStatus
      personalized
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
        }
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
    }
  }
`;

class EditProductCampaign extends Component {
  static contextType = AppContext;
  state = {
    step: 2,
    selected: "",
    productBases: [],
    data: null,
    changed: false,
    loading: false,
    fulfillmentId: null,
    defaultFieldsValue: null,
    handleBtnSave: null,
    showBtnSave: null,
    pushProductToStores: null,
    updatePrices: false,
    currentParam: "seller",
    refetchTimeline: false,
    mainImgId: {},
    baseIdsDefault: new Set(),
  };

  timer = null;

  componentDidMount() {
    // this.timer = setTimeout(() => {
    //   let { productBases, data } = this.state;
    //   for (let i = 0; i < productBases.length; i++) {
    //     fieldsCampaign.push(`designPositions__${i}`);
    //     fieldsCampaign.push(`mockup__${i}`);
    //   }
    //   let defaultFieldsValue = JSON.parse(
    //     JSON.stringify(this.props.form.getFieldsValue(fieldsCampaign))
    //   );

    //   // props;
    //   let newSelected =
    //     productBases && productBases.length && productBases[0].id;

    //   // active bases by mockups file
    //   const { images } = data || {};
    //   const countImage = (images || []).reduce((acc, cur) => {
    //     const { productBaseId } = cur || {};
    //     return {
    //       ...acc,
    //       [productBaseId]: (acc[productBaseId] || 0) + 1,
    //     };
    //   }, {});
    //   const values = Object.values(countImage);
    //   const largest = Math.max(0, ...values);
    //   const [baseId] =
    //     Object.entries(countImage).find(([_k, value]) => value === largest) ||
    //     [];
    //   if (baseId) {
    //     newSelected = baseId;
    //   }

    //   this.setState({
    //     selected: newSelected,
    //     defaultFieldsValue: defaultFieldsValue,
    //   });
    // }, 1000);
    const { currentUser } = this.context;
    let currentParam = getParam(currentUser);
    this.setState({
      currentParam,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let { handleBtnSave, updateProduct, loading } = this.state;
    let { clickSave, handleLoading } = this.props;

    if (handleBtnSave !== prevState.handleBtnSave) {
      this.handleShowBtnSave();
    }
    if (clickSave !== prevProps.clickSave) {
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          this.setState({ loading: true }, () => {
            if (updateProduct) {
              this.handleUpdate(updateProduct, values);
            }
          });
        } else {
          this.handleError(errors);
        }
      });
    }
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleShowBtnSave = () => {
    let { productBases } = this.state;
    let { form, handleBtnSave } = this.props;
    let defaultFieldsValue = this.state.defaultFieldsValue && {
      ...this.state.defaultFieldsValue,
    };
    let newFieldsValue = { ...form.getFieldsValue(fieldsCampaign) };
    newFieldsValue = JSON.parse(JSON.stringify(newFieldsValue));

    if (newFieldsValue && defaultFieldsValue) {
      let values = handleCampaignFields(
        newFieldsValue,
        defaultFieldsValue,
        productBases,
      );
      newFieldsValue = values.newFieldsValue;
      defaultFieldsValue = values.defaultFieldsValue;
      // let formatEmptyStr = ["description", "shortDescription"];
      let formatEmptyStr = ["description"];
      formatEmptyStr.forEach((i) => {
        if (null === newFieldsValue[i]) {
          newFieldsValue[i] = "";
        }
      });

      let title = form.getFieldValue("title");
      let shortTitle = form.getFieldValue("shortTitle");
      let amzTitle = form.getFieldValue("amzTitle");
      let btnName = "Save Changes";
      let step = this.state.step;
      let link = "/seller/products";
      let aggregations = { title, shortTitle, amzTitle, btnName, step, link };

      if (false === this.state.onChangeValue) {
        this.setState({
          showBtnSave: false,
          isPrompt: false,
        });
        if (handleBtnSave) {
          handleBtnSave(false, aggregations);
        }
      } else {
        if (handleDeepEqual(newFieldsValue, defaultFieldsValue)) {
          this.setState({
            showBtnSave: true,
            isPrompt: true,
          });
          if (handleBtnSave) {
            handleBtnSave(true, aggregations);
          }
        } else {
          this.setState({
            showBtnSave: false,
            isPrompt: false,
          });
          if (handleBtnSave) {
            handleBtnSave(false, aggregations);
          }
        }
      }
    }
  };

  handleUpdateForm = () => {
    const { data } = this.state;
    let mapProductBaseVariant = {};
    let variants = [];
    for (let i = 0; i < data.productBases.length; i++) {
      const base = data.productBases[i];
      for (let j = 0; j < base.variants.length; j++) {
        const variant = base.variants[j];
        variant.productBase = base;
        mapProductBaseVariant[variant.id] = variant;
      }
    }
    for (let i = 0; i < data.variants.length; i++) {
      const variant = data.variants[i];
      const baseVariant = mapProductBaseVariant[variant.productBaseVariantId];
      if (baseVariant) {
        variant.productBaseVariant = baseVariant;
        variants.push(variant);
      }
    }
    let fieldValue = {};
    let products = [];
    for (let i = 0; i < data.productBases.length; i++) {
      const base = data.productBases[i];
      const productDesignPosition = data.designPositions.filter(
        (dp) => dp.designPosition.productBaseId === base.id,
      );
      if (productDesignPosition.length === 0) {
        fieldValue[`designPositions__${i}`] = base.designPositions.map((dp) => {
          return {
            designPosition: dp,
            designPositionId: dp.id,
            file: null,
          };
        });
      } else {
        fieldValue[`designPositions__${i}`] = productDesignPosition;
      }
      let productVariants = variants.filter(
        (v) => v.productBaseVariant.productBase.id === base.id,
      );
      if (productVariants.length === 0) {
        // can be new product base added
        products.push({
          productBase: base,
          collections: [],
          tags: [],
          variants: base.variants.map((variant) => {
            return {
              productBaseVariant: variant,
              productBaseVariantId: variant.id,
              image: null,
              regularPrice: variant.regularPrice,
              salePrice: variant.salePrice,
            };
          }),
        });
      } else {
        products.push({
          productBase: base,
          variants: productVariants,
          collections: [],
          tags: [],
        });
      }
    }

    fieldValue.products = products;

    this.props.form.setFieldsValue(fieldValue);
  };

  renderSelectProductBases = (currentParam) => {
    return (
      <div>
        <PageTitle
          title={"Products"}
          subTitle={"Edit Campaign"}
          action={
            <Button
              onClick={async () => {
                const { baseIdsDefault, productBases, data } = this.state;
                const baseNeedGetDS = [];
                for (let i = 0; i < productBases.length; i++) {
                  const base = productBases[i];
                  if (!baseIdsDefault.has(base.id)) {
                    baseNeedGetDS.push(base);
                  }
                }

                let newBases = await this.preLoadDesignPositionByBases(
                  baseNeedGetDS,
                );
                newBases = newBases.reduce((acc, base) => {
                  acc[base.id] = base;
                  return acc;
                }, {});

                const mergeBases = productBases.map((base) => {
                  const matched = newBases[base.id];
                  if (matched) {
                    return {
                      ...base,
                      designPositions: matched.designPositions,
                    };
                  }

                  return base;
                });

                const newVariants = baseNeedGetDS.reduce(
                  (acc, { variants }) => {
                    acc.push(...variants);
                    return acc;
                  },
                  [],
                );

                const newData = data;
                if (newData?.productBases?.length > 0) {
                  newData.productBases = mergeBases;
                  newData.variants.push(...newVariants);
                }

                this.setState(
                  {
                    step: 2,
                    productBases: mergeBases,
                    data: newData,
                    handleBtnSave: true,
                  },
                  () => {
                    this.handleUpdateForm();
                  },
                );
              }}
              type="primary"
            >
              Continue Edit Campaign
            </Button>
          }
          link={`/${currentParam}/products`}
        />
        <ProductBaseSelect
          fulfillmentId={this.state.fulfillmentId}
          selectedProductBases={this.state.productBases}
          onChange={(selected) => {
            this.setState({
              productBases: selected,
            });
          }}
          value={this.state.productBases}
          noVariantDisabled={true}
          multiple={true}
        />
      </div>
    );
  };
  handleFormChange = () => {
    this.setState({ changed: true });
  };
  handleUpdate = async (updateFunc, values, client) => {
    const { id } = this.props.match.params;
    const { productBases } = this.state;
    // let data = this.state.data;
    // let firstItemHaveDP =
    //   data &&
    //   data.designPositions &&
    //   data.designPositions.length &&
    //   data.designPositions.find((i) => i.file);

    let productBaseIds = [];
    let printFiles = [];
    let variants = [];

    for (let i = 0; i < productBases.length; i++) {
      const base = productBases[i];
      productBaseIds.push(base.id);
      const designPositions = values[`designPositions__${i}`];
      for (let j = 0; j < designPositions.length; j++) {
        const dp = designPositions[j];
        let fileId =
          undefined !== dp.fileId
            ? dp.fileId
            : dp.file && dp.file.id
            ? dp.file.id
            : null;
        printFiles.push({
          id: dp.id ? dp.id : null,
          designPositionId: dp.designPositionId,
          fileId: fileId,
        });
      }
    }
    let mockup = [];
    let checkMockup = false;
    let sortBases = [];
    for (let i = 0; i < values.products.length; i++) {
      const p = values.products[i];
      const productBaseId = p.productBase.id;

      // Sort base
      sortBases.push({ productBaseId, position: i });

      for (let j = 0; j < p.variants.length; j++) {
        const v = p.variants[j];
        variants.push({
          id: v.id ? v.id : null,
          image: v.image ? v.image.id : null,
          amazonImages:
            v.amazonImages?.length > 0
              ? v.amazonImages
                  .map((i) => (i.file ? i.file.id : i.id))
                  .filter(Boolean)
              : undefined,
          productBaseVariantId: v.productBaseVariantId,
          regularPrice: v.regularPrice,
          salePrice: v.salePrice,
          disabled: v.disabled,
        });
      }

      const images = values[`mockup__${i}`];
      if (images && images.length) {
        checkMockup = true;
        for (let j = 0; j < images.length; j++) {
          const fileId = images[j].id;
          mockup.push({
            productBaseId,
            fileId,
          });
        }
      }
    }
    if (!checkMockup) {
      this.setState({ loading: false });
      notification.error({ message: "Please select at least one image" });
      return;
    }

    let psdFileIds = [];
    if (values.psdFiles && values.psdFiles.length > 0) {
      let inputPsdFiles = values.psdFiles;
      inputPsdFiles.forEach((v) => {
        if (v && v.id && v.id.length > 0) {
          psdFileIds.push(v.id);
        }
      });
    }

    let canSubmit = true;
    // if (firstItemHaveDP) {
    //   let newDP =
    //     printFiles && printFiles.length > 0
    //       ? printFiles.find((i) => i.fileId)
    //       : null;
    //   if (!newDP) {
    //     canSubmit = false;
    //     notification.error({
    //       message: "Please select at least one design position.",
    //     });
    //   }
    // }

    let mainImageId = values.mainImageId;
    let imgIds = [];
    if (mainImageId) {
      for (let [, value] of Object.entries(mainImageId)) {
        if (value) {
          imgIds.push(value);
        }
      }
    }

    let newMainImageId = imgIds.length > 0 ? imgIds[imgIds.length - 1] : null;
    const inMockups = (mockup || []).find((m) => m?.fileId === newMainImageId);
    if (!newMainImageId || inMockups == null) {
      const [firstImg] = mockup || [];
      newMainImageId = firstImg?.fileId;
    }

    let input = {
      id: id,
      productBases: productBaseIds,
      title: values.title,
      shortTitle: values.shortTitle,
      amzTitle: values.amzTitle,
      description: values.description,
      // shortDescription: values.shortDescription,
      collections: values.collections.map((c) => c.id),
      tags: values.tags.map((t) => t.id),
      mockup,
      designPositions: printFiles,
      variants,
      personalized: values.personalized,
      fields: values.fields,
      updateProductStore: values.updateProductStore,
      psd: values.psd,
      psdFiles: psdFileIds.length > 0 ? psdFileIds : [],
      mainImageId: newMainImageId,
      sortBases,
      hasSetIgnoredIsFalse: values.hasSetIgnoredIsFalse,
    };

    // Check amazon title
    const prevAmzTitle = this.productByID?.amzTitle;
    const productID = this.productByID?.id || undefined;
    if (values.amzTitle && values.amzTitle !== prevAmzTitle) {
      const { data: dataAmzTitle } = await client.query({
        query: GET_PRODUCT_BY_TITLE,
        variables: {
          title: values.amzTitle,
          whichField: WHICH_FIELD.AmzTitle,
          productID,
        },
      });

      if (dataAmzTitle?.getProductsByTitle?.total > 0) {
        notification.error({
          message: AMZ_MESSAGE_ERROR,
          duration: 3000,
        });
        this.setState({
          loading: false,
        });
        return;
      }
    }

    // if (canSubmit) {
    updateFunc({
      variables: {
        input,
      },
    })
      .then((res) => {
        notification.success({ message: `Product has been saved.` });
        this.setState({
          loading: false,
          refetchTimeline: !this.state.refetchTimeline,
        });
        if (this.refetch) {
          this.refetch();
        }
        // history.push(`/${this.state.currentParam}/products/campaign/${id}`);
      })
      .catch((e) => {
        notification.error({ message: handleError(e.toString()) });
        this.setState({
          loading: false,
        });
      });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  };

  handleError = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er.message.toLowerCase());
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er.message) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };

  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  preLoadDesignPositionByBases = async (bases) => {
    if (!bases || bases.length === 0) return bases;
    const { __apolloClient__: client } = window || {};
    if (!client) return bases;
    const baseIds = (bases || []).map(({ id }) => id).filter(Boolean);
    const newBases = await Promise.all(
      baseIds.map(async (id) => {
        const { data } = await client.query({
          query: QUERY_DP_BY_BASE_SELECTED,
          variables: { id },
        });

        return data?.productBaseByID;
      }),
    );

    const mapBase = newBases.reduce((acc, { id, ...rest }) => {
      acc[id] = { ...rest };
      return acc;
    }, {});

    const newBaseSelected = bases.map((base) => {
      const { id } = base;
      const current = mapBase[id];
      if (current && current.designPositions) {
        base.designPositions = current.designPositions;
      }

      return base;
    });

    return newBaseSelected;
  };

  handleUpdateState = (bases, data) => {
    if (!bases || bases.length === 0) return;
    for (let i = 0; i < bases.length; i++) {
      fieldsCampaign.push(`designPositions__${i}`);
      fieldsCampaign.push(`mockup__${i}`);
    }
    let defaultFieldsValue = JSON.parse(
      JSON.stringify(this.props.form.getFieldsValue(fieldsCampaign)),
    );

    // props;
    let newSelected = bases[0].id;

    // active bases by mockups file
    const { images } = data || {};
    const countImage = (images || []).reduce((acc, cur) => {
      const { productBaseId } = cur || {};
      return {
        ...acc,
        [productBaseId]: (acc[productBaseId] || 0) + 1,
      };
    }, {});
    const values = Object.values(countImage);
    const largest = Math.max(0, ...values);
    const [baseId] =
      Object.entries(countImage).find(([_k, value]) => value === largest) || [];
    if (baseId) {
      newSelected = baseId;
    }

    this.setState({
      selected: newSelected,
      defaultFieldsValue: defaultFieldsValue,
    });
  };

  designPositionRefs = {};

  render() {
    const { handleBtnSave, currentParam } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { id } = this.props.match.params;
    const personalized = this.props.form.getFieldValue("personalized");

    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const paramSearch = { ...convertStringToObject(history.location.search) };
    let isProductIgnored = false;
    Object.keys(paramSearch).forEach((i) => {
      if (i === "productIgnored") {
        isProductIgnored = true;
      }
    });

    // Facebook title
    const fbTitleValue =
      this.props.form && this.props.form.getFieldValue("shortTitle");
    const fbTitleHelp = genHelpText(fbTitleValue, MAX_TITLE_CHARACTERS.fbTitle);

    // Amazon Title
    const aTitleValue =
      this.props.form && this.props.form.getFieldValue("amzTitle");
    const aTitleHelp = genHelpText(aTitleValue, MAX_TITLE_CHARACTERS.amzTitle);

    // Origin product title
    const titleValue =
      this.props.form && this.props.form.getFieldValue("title");
    const titleHelp = genHelpText(titleValue);

    const { designPositionRefs } = this;

    return (
      <React.Fragment>
        <Prompt
          when={false === this.state.isPrompt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <AppContext.Consumer>
          {({ store }) => (
            <Query
              onCompleted={async (res) => {
                const data = res.productByID;
                const bases = await this.preLoadDesignPositionByBases(
                  data?.productBases,
                );

                // Base IDs defualt
                const baseIdsDefault = new Set(bases.map(({ id }) => id));
                this.setState(
                  {
                    productBases: bases,
                    data: data,
                    fulfillmentId: _.get(
                      data,
                      "productBases[0].fulfillment.fulfillmentId",
                      null,
                    ),
                    baseIdsDefault,
                  },
                  () => {
                    this.handleUpdateState(bases, data);
                    this.handleUpdateForm();
                  },
                );
              }}
              query={LOAD_QUERY}
              fetchPolicy="cache-and-network"
              variables={{ id }}
              skip={!id}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) return <Skeleton active />;
                if (error)
                  return <div>Error: {handleError(error.toString())}</div>;
                const value = data.productByID;

                if (this.state.step === 1) {
                  return this.renderSelectProductBases(currentParam);
                }
                this.refetch = refetch;
                this.productByID = data.productByID;
                return (
                  <Mutation mutation={UPDATE_MUTATION}>
                    {(updateProduct, { client }) => (
                      <Form
                        {...layout}
                        onChange={() => {
                          this.setState({
                            updateProduct: updateProduct,
                          });
                          this.handleFormChange();
                        }}
                        onSubmit={(e) => {
                          this.setState({
                            updatePrices: !this.state.updatePrices,
                          });
                          e.preventDefault();
                          this.props.form.validateFields((errors, values) => {
                            let { mainImgId } = this.state;
                            values["mainImageId"] = mainImgId;

                            // Validate file design
                            if (!objectInvalid(designPositionRefs)) {
                              let result;
                              for (let item of Object.values(
                                designPositionRefs,
                              )) {
                                const dimensions =
                                  item?.state?.dimensions || {};
                                const error = validateDimensions({
                                  dimensions,
                                });
                                if (error != null) {
                                  result = error;
                                  break;
                                }
                              }

                              if (result != null) {
                                notification.error({ message: result.message });
                                return;
                              }
                            }
                            if (!errors) {
                              this.setState(
                                { loading: true, isPrompt: true },
                                () => {
                                  this.handleUpdate(
                                    updateProduct,
                                    values,
                                    // store,
                                    client,
                                  );
                                },
                              );
                            } else {
                              this.handleError(errors);
                            }
                          });
                        }}
                        scrollToFirstError
                      >
                        <PageTitle
                          title={"Edit Campaign"}
                          subTitle={"Products"}
                          link={`/${currentParam}/products`}
                          // Add actions clone product
                          // action={<CloneProductActions id={id} />}
                          action={
                            <Button
                              children="Clone campaign"
                              onClick={() => {
                                const ignoreKeys = [
                                  "designPositions__",
                                  "mockup__",
                                  "products",
                                ];
                                let value = this.props.form.getFieldsValue();
                                value = Object.keys(value).reduce(
                                  (acc, key) => {
                                    if (
                                      ignoreKeys.some((k) =>
                                        new RegExp(k).test(key),
                                      )
                                    )
                                      return acc;
                                    acc[key] = value[key];
                                    return acc;
                                  },
                                  {},
                                );

                                const props = {
                                  productBases: (
                                    this.state.productBases || []
                                  ).map((item) => {
                                    const { variants } = item || {};
                                    return {
                                      ...item,
                                      variants: variants.map(
                                        ({ productBase, ...va }) => va,
                                      ),
                                    };
                                  }),
                                };

                                const values = JSON.stringify({ value, props });
                                history.push(
                                  `/${currentParam}/products/clone-campaign`,
                                  values,
                                );
                              }}
                            />
                          }
                        />
                        <Row gutter={16}>
                          <Col span={17}>
                            <Card>
                              <Form.Item label={"Title"} help={titleHelp}>
                                {getFieldDecorator("title", {
                                  initialValue: value.title,
                                  rules: [
                                    {
                                      required: true,
                                      validator: this.validateTitle,
                                    },
                                  ],
                                })(
                                  <Input
                                    placeholder={"Product title"}
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <Form.Item
                                label="Facebook Title"
                                help={fbTitleHelp}
                              >
                                {getFieldDecorator("shortTitle", {
                                  initialValue: value.shortTitle,
                                })(
                                  <Input
                                    placeholder="Enter facebook title"
                                    maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <Form.Item label="Amazon Title" help={aTitleHelp}>
                                {getFieldDecorator("amzTitle", {
                                  initialValue: value.amzTitle,
                                })(
                                  <Input
                                    placeholder="Enter amazon title"
                                    maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <Form.Item label={"Description"}>
                                {getFieldDecorator("description", {
                                  initialValue: value.description,
                                })(
                                  <Wysiwyg
                                    onChange={
                                      (this.handleFormChange,
                                      () => {
                                        this.setState({
                                          handleBtnSave: !handleBtnSave,
                                        });
                                      })
                                    }
                                  />,
                                )}
                              </Form.Item>
                              {/* <Form.Item label={"Short Description"}>
                                {getFieldDecorator("shortDescription", {
                                  initialValue: value.shortDescription,
                                })(
                                  <Wysiwyg
                                    onChange={
                                      (this.handleFormChange,
                                      () => {
                                        this.setState({
                                          handleBtnSave: !handleBtnSave,
                                        });
                                      })
                                    }
                                  />
                                )}
                              </Form.Item> */}
                              <Form.Item label="SKU">
                                <Input value={value.sku} disabled />
                              </Form.Item>
                              <Form.Item label={"Collections"}>
                                {getFieldDecorator("collections", {
                                  initialValue: value.collections
                                    ? value.collections
                                    : [],
                                  rules: [
                                    {
                                      required: true,
                                      message: "Collections is required",
                                    },
                                  ],
                                })(
                                  <CollectionsAutoComplete
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <Form.Item label={"Tags"}>
                                {getFieldDecorator("tags", {
                                  initialValue: value.tags ? value.tags : [],
                                  rules: [
                                    {
                                      required: true,
                                      message: "Tags is required",
                                    },
                                  ],
                                })(
                                  <TagsAutoComplete
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <Form.Item>
                                {getFieldDecorator("products", {
                                  initialValue: [],
                                })(
                                  <CampaignProductBaseItems
                                    isEditProduct
                                    sku={data.sku}
                                    updatePrices={this.state.updatePrices}
                                    onChange={() => {
                                      this.handleFormChange();
                                    }}
                                    grouped={true}
                                    selectedIndex={this.state.selected}
                                    onSelected={(pbaseId) => {
                                      this.setState({ selected: pbaseId });
                                    }}
                                    productId={id}
                                    onDelete={(index) => {
                                      let productBases = this.state
                                        .productBases;

                                      //
                                      const basesLen = productBases.length;
                                      if (basesLen <= 1) {
                                        notification.error({
                                          message:
                                            "Can't delete this base. Please keep at least Base for product.",
                                        });
                                        return;
                                      }

                                      productBases.splice(index, 1);
                                      this.setState(
                                        {
                                          changed: true,
                                          productBases: productBases,
                                        },
                                        () => {
                                          this.handleUpdateForm();
                                        },
                                      );
                                    }}
                                    onChangeValue={(v) => {
                                      this.setState({
                                        onChangeValue: v,
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  />,
                                )}
                              </Form.Item>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    this.setState({ step: 1 });
                                  }}
                                  type="primary"
                                >
                                  Add More Products
                                </Button>
                              </div>
                            </Card>
                          </Col>
                          <Col span={7}>
                            {isProductIgnored && (
                              <Card
                                bodyStyle={{
                                  padding: "15px",
                                  marginBottom: "2rem",
                                }}
                              >
                                <Form.Item style={{ marginBottom: 0 }}>
                                  {getFieldDecorator("hasSetIgnoredIsFalse", {
                                    initialValue: true,
                                    valuePropName: "checked",
                                  })(
                                    <Checkbox
                                      children={
                                        "Also remove this product from ignored list"
                                      }
                                      onChange={() => {
                                        this.setState({
                                          showBtnSave: false,
                                        });
                                      }}
                                    />,
                                  )}
                                </Form.Item>
                              </Card>
                            )}
                            {value &&
                              value.productStores &&
                              value.productStores.length > 0 && (
                                <Card
                                  title="Update to stores"
                                  style={{ marginBottom: 15 }}
                                >
                                  <Form.Item>
                                    {getFieldDecorator("updateProductStore", {
                                      initialValue: false,
                                      valuePropName: "checked",
                                    })(
                                      <Checkbox
                                        onChange={() => {
                                          this.setState({
                                            showBtnSave: false,
                                          });
                                        }}
                                      >
                                        Also update changes to store
                                      </Checkbox>,
                                    )}
                                  </Form.Item>
                                </Card>
                              )}
                            {this.state.productBases.map((pb, index) => {
                              const pBaseId = pb && pb.id;
                              return (
                                <Card
                                  hidden={this.state.selected !== pBaseId}
                                  key={`card-print-files-${index}`}
                                  style={{ marginBottom: 20 }}
                                  title={`${pb.title}`}
                                >
                                  <Card
                                    style={{ marginBottom: 15 }}
                                    title={"Print Files"}
                                  >
                                    {getFieldDecorator(
                                      `designPositions__${index}`,
                                      {
                                        initialValue: [],
                                      },
                                    )(
                                      <ProductPrintFiles
                                        uploadFolder="undefined"
                                        onChange={() => {
                                          this.setState({
                                            changed: true,
                                            handleBtnSave: !handleBtnSave,
                                          });
                                        }}
                                        ref={(el) => {
                                          designPositionRefs[index] = el;
                                          return el;
                                        }}
                                      />,
                                    )}
                                  </Card>

                                  <Card
                                    title={"Mockup"}
                                    hidden={this.state.selected !== pBaseId}
                                  >
                                    <Form.Item>
                                      {getFieldDecorator(`mockup__${index}`, {
                                        initialValue: value.images
                                          ? value.images
                                              .filter(
                                                (img) =>
                                                  img.productBaseId === pb.id,
                                              )
                                              .map((f) => f.file)
                                          : [],
                                        rules: [
                                          // {
                                          //   required: true,
                                          //   message: "Please select at least one image",
                                          // },
                                        ],
                                      })(
                                        <MediaSelectorButton
                                          isMockup
                                          isCampaign
                                          folder={
                                            value.sku
                                              ? getProductMockupFolder(
                                                  value.sku,
                                                )
                                              : UPLOAD_FOLDER.MOCKUPS
                                          }
                                          onChange={
                                            (this.handleFormChange,
                                            () => {
                                              this.setState({
                                                handleBtnSave: !handleBtnSave,
                                              });
                                            })
                                          }
                                          multiple={true}
                                          listType="picture-card"
                                          mainImg={value && value.mainImageId}
                                          onChangeMainImg={(value) =>
                                            this.setState((prevState) => {
                                              return {
                                                ...prevState,
                                                showBtnSave: false,
                                                mainImgId: {
                                                  ...prevState.mainImgId,
                                                  [`mockup__${index}`]: value,
                                                },
                                              };
                                            })
                                          }
                                        />,
                                      )}
                                    </Form.Item>
                                  </Card>
                                </Card>
                              );
                            })}
                            {value && (
                              <Card
                                style={{ marginTop: 15 }}
                                title={"Push Product"}
                              >
                                <Form.Item name={"pushProductToStores"}>
                                  <PushProduct
                                    isEditProduct
                                    store={store}
                                    productId={value.id}
                                    checked={!!store}
                                    refetch={refetch}
                                    value={this.state.pushProductToStores}
                                    onChange={(value) => {
                                      this.setState({
                                        pushProductToStores: value,
                                      });
                                      this.handleShowBtnSave();
                                    }}
                                  />
                                </Form.Item>
                              </Card>
                            )}
                            {value &&
                              value.productStores &&
                              value.productStores.length > 0 && (
                                <Card
                                  title={"Push History"}
                                  style={{ marginTop: 15 }}
                                  bodyStyle={{ paddingBottom: 0 }}
                                >
                                  <Form.Item>
                                    <PushHistory value={value.productStores} />
                                  </Form.Item>
                                </Card>
                              )}
                            {value && (
                              <>
                                <Card
                                  title={"Design Resource Files"}
                                  style={{ marginTop: 15 }}
                                >
                                  <Form.Item>
                                    {getFieldDecorator(`psdFiles`, {
                                      initialValue:
                                        value && value.psdFiles
                                          ? value.psdFiles
                                          : null,
                                    })(
                                      <MediaSelectorButton
                                        prefix={
                                          UPLOAD_FOLDER.PSD_RESOURCE + "/"
                                        }
                                        folder={UPLOAD_FOLDER.PSD_RESOURCE}
                                        multiple={true}
                                        listType={"picture-card"}
                                        buttonType="primary"
                                        accept={"image/*"}
                                        onChange={() => {
                                          this.setState({
                                            handleBtnSave: !handleBtnSave,
                                          });
                                        }}
                                      />,
                                    )}
                                  </Form.Item>
                                </Card>
                                <Card
                                  title={"Design Resource Urls"}
                                  style={{ marginTop: 15 }}
                                >
                                  <Form.Item>
                                    {getFieldDecorator("psd", {
                                      initialValue: value.psd ? value.psd : "",
                                    })(
                                      <Input.TextArea
                                        rows={4}
                                        onChange={() => {
                                          this.setState({
                                            handleBtnSave: !handleBtnSave,
                                          });
                                        }}
                                      />,
                                    )}
                                  </Form.Item>
                                </Card>
                                {value.refURL != null ? (
                                  <Card
                                    title={"Product Resource Urls"}
                                    style={{ marginTop: 15 }}
                                  >
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <a
                                        href={value.refURL}
                                        className="Polaris-Link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        children={value.refURL}
                                      />
                                    </div>
                                  </Card>
                                ) : null}
                              </>
                            )}
                          </Col>
                          <Col span={24}>
                            <Card
                              style={{ marginTop: 20 }}
                              title={"Personalized"}
                            >
                              <Form.Item>
                                {getFieldDecorator("personalized", {
                                  initialValue: value.personalized,
                                  valuePropName: "checked",
                                })(
                                  <Checkbox
                                    onChange={() => {
                                      this.setState({
                                        handleBtnSave: !handleBtnSave,
                                      });
                                    }}
                                  >
                                    This is a personalized product
                                  </Checkbox>,
                                )}
                              </Form.Item>
                              {getFieldDecorator("fields", {
                                initialValue:
                                  value &&
                                  value.fields &&
                                  value.fields.length > 0
                                    ? value.fields.map((f) => {
                                        if (
                                          typeof f.__typename !== "undefined"
                                        ) {
                                          delete f.__typename;
                                        }
                                        if (typeof f.sorting !== "undefined") {
                                          delete f.sorting;
                                        }
                                        return f;
                                      })
                                    : [],
                              })(
                                <CustomizeButton
                                  onChange={() => {
                                    this.handleFormChange();
                                    this.setState({
                                      handleBtnSave: !handleBtnSave,
                                    });
                                  }}
                                  showPreview={!!personalized}
                                />,
                              )}
                            </Card>
                            <Form.Item
                              style={{
                                marginTop: 20,
                                textAlign: "right",
                                marginBottom: 0,
                              }}
                            >
                              <Button
                                onClick={() => {
                                  if (
                                    null === this.state.showBtnSave
                                      ? false
                                      : !this.state.showBtnSave
                                  ) {
                                    Modal.confirm({
                                      title: "Cancel all unsaved changes?",
                                      icon: <ExclamationCircleOutlined />,
                                      content:
                                        "If Ok, you’ll delete any edits you made.",
                                      onOk() {
                                        history.push(
                                          `/${currentParam}/products`,
                                        );
                                      },
                                      cancelText: "Continue",
                                    });
                                  } else {
                                    history.push(`/${currentParam}/products`);
                                  }
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="ml-4"
                                type="primary"
                                icon={<LegacyIcon type={"save"} />}
                                loading={this.state.loading}
                                htmlType={"submit"}
                                disabled={
                                  null === this.state.showBtnSave
                                    ? true
                                    : this.state.showBtnSave
                                }
                              >
                                Save Changes
                              </Button>
                            </Form.Item>
                            {id && (
                              <ProductTimeline
                                productId={id}
                                refetchTimeline={this.state.refetchTimeline}
                              />
                            )}
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

export default Form.create({ name: "edit-product-campaign" })(
  EditProductCampaign,
);
