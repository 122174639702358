import React, {
    forwardRef,
    useState,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { Button, Checkbox } from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { isEqual } from "lodash";

import noImg from "../../../assets/images/unnamed.jpeg";
import useToggle from "../../../hooks/useToggle";
import { useFetch } from "../../../hooks/useFetch";
import { Mockup2DApi } from "../../../config";
import { getTokenObject } from "../../../client";

import { ConfigNew } from "./ConfigNew";
import { SelectTemplate } from "./SelectTemplate";
import { UpdateMockupTemplate } from "./UpdateMockupTemplate";
import { genStrToBtoA } from "../../../helper";

export const MockupTemplate = forwardRef(function MockupTemplate(props, _ref) {
    // Props
    const { designPositions, onChange, mockupTemplates } = props;

    // State
    const [selected, setSelected] = useState([]);
    const [open, toggleOpen] = useToggle(false);
    const [data, setData] = useState({
        nodes: [],
        total: 0,
    });
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState({
        limit: 1000,
        page: 1,
        search: "",
    });
    const [defaultTemplate, setDefaultTemplate] = useState([]);
    const timeoutRef = useRef(false);

    // Query
    const [getTemplates, { loading }] = useFetch({
        url: `${Mockup2DApi}/template`,
        method: "get",
    });

    useEffect(() => {
        if (data?.nodes?.length > 0 && mockupTemplates?.length > 0) {
            if (!timeoutRef.current) {
                const templateIds = mockupTemplates.sort(
                    (a, b) => a.sorting - b.sorting
                );
                // .map((node) => node.originTemplateID)
                // .filter(Boolean);

                const matched = templateIds
                    .reduce((acc, cur) => {
                        const item = data.nodes.find(
                            (node) => node.id === cur.originTemplateID
                        );

                        const newItem =
                            item != null
                                ? {
                                      ...item,
                                      isDefault: cur.isDefault,
                                      originId: item?.id,
                                  }
                                : null;

                        return [...acc, newItem].filter(Boolean);
                    }, [])
                    .filter(Boolean);

                setSelected(matched);
                timeoutRef.current = true;
            }
        }
    }, [mockupTemplates, data]);

    useEffect(() => {
        (async function () {
            try {
                const { data, error } = await getTemplates({
                    params: filter,
                });

                if (error) {
                    setError(error);
                } else if (data != null) {
                    const nodes = data.nodes?.length > 0 ? data.nodes : [];
                    setSelected((prev) =>
                        prev.map((i) => {
                            let item = i;
                            const newItem = nodes.find(
                                (item) => item.id === i.id
                            );
                            if (newItem != null) {
                                item = {
                                    ...item,
                                    ...newItem,
                                };
                            }
                            return item;
                        })
                    );
                    setData(data);
                }
            } catch (err) {
                setError(err);
            }
        })();
    }, [filter, getTemplates]);

    const handlePagination = useCallback((offset, limit) => {
        const paged = offset / limit + 1;
        setFilter((prev) => {
            if (!isEqual(prev.limit, limit)) {
                return {
                    ...prev,
                    limit,
                    page: 1,
                };
            }

            if (!isEqual(prev.page, paged)) {
                return { ...prev, page: paged };
            }
            return prev;
        });
    }, []);

    // Handle action
    const handleDone = useCallback(
        (value = true) => {
            toggleOpen(value);
            setFilter((prev) => ({ ...prev }));
        },
        [toggleOpen]
    );

    useEffect(() => {
        const clone = selected.filter(Boolean);
        if (!isEqual(mockupTemplates, clone) && onChange) {
            onChange(clone?.length > 0 ? clone : null);
        }
    }, [onChange, selected]);

    return (
        <Wrapper>
            <div className="templates-wrap">
                {selected?.length > 0
                    ? selected.map((node, index) => (
                          <ItemSelect
                              key={`select-${index}`}
                              item={node}
                              setSelected={setSelected}
                              handleDone={handleDone}
                              designPositions={designPositions}
                              defaultTemplate={defaultTemplate}
                              setDefaultTemplate={setDefaultTemplate}
                              selected={selected}
                          />
                      ))
                    : null}
                <SelectTemplate
                    toggleOpen={toggleOpen}
                    open={open}
                    onChange={setSelected}
                    value={selected}
                    data={data}
                    error={error}
                    filter={filter}
                    handlePagination={handlePagination}
                    loading={loading}
                />
            </div>
            <div className="action-wrap">
                <ConfigNew
                    designPositions={designPositions}
                    handleDone={handleDone}
                />
            </div>
        </Wrapper>
    );
});

function ItemSelect({
    item,
    setSelected,
    handleDone: handleDoneProp,
    designPositions,
}) {
    const id = item?.id;
    const file = item?.file;
    const src = file
        ? file.thumbnail_url
            ? file.thumbnail_url
            : file.url
            ? file.url
            : null
        : null;

    // State
    const [hover, setHover] = useState(false);
    const [canUpdate, toggleCanUpdate] = useToggle(false);

    // Handle action
    const handleMouseOver = useCallback(() => setHover(true), []);
    const handleMouseLeave = useCallback(() => setHover(false), []);
    const handleDone = useCallback(
        (value) => {
            toggleCanUpdate();
            handleDoneProp(value);
        },
        [handleDoneProp, toggleCanUpdate]
    );
    const handleRemove = useCallback(
        (event) => {
            event.stopPropagation();
            setSelected((prev) => {
                const clone = [...prev];
                const index = clone.findIndex((i) => i.id === id);
                if (index !== -1) {
                    clone.splice(index, 1);
                }
                return [...clone];
            });
        },
        [id, setSelected]
    );

    const handleEdit = useCallback(
        (event) => {
            event.stopPropagation();
            toggleCanUpdate(true);
        },
        [toggleCanUpdate]
    );

    // const isDefault = defaultTemplate.includes(id);
    const isDefault = item.isDefault;
    const handleClick = useCallback(
        (event) => {
            event.stopPropagation();
            setSelected((prev) =>
                prev.map((i) => {
                    if (i.id === id) {
                        return {
                            ...i,
                            isDefault: !i.isDefault,
                        };
                    }
                    return i;
                })
            );
        },
        [id, setSelected]
    );

    const visibleCls = hover ? "visible" : "";

    return (
        <ItemWrapper>
            <div className="default-template-wrap">
                {isDefault && <Checkbox checked />}
            </div>
            <div
                className="box-image"
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <img src={src || noImg} alt="" />
                <div className={`btn-remove ${visibleCls}`}>
                    <Button icon={EditMinor} plain onClick={handleEdit} />
                    <Button icon={DeleteMinor} plain onClick={handleRemove} />
                </div>
            </div>
            <span
                title={item.nam}
                onClick={handleClick}
                className="template-title"
            >
                {item.name}
            </span>
            <UpdateMockupTemplate
                open={canUpdate}
                toggleOpen={toggleCanUpdate}
                id={id}
                handleDone={handleDone}
                designPositions={designPositions}
            />
        </ItemWrapper>
    );
}

export function getInfosForIFrame(designPositions) {
    /// Design positions
    const dps =
        designPositions?.length > 0
            ? designPositions.map((i) => i.name).filter(Boolean)
            : [];
    const dpsString = JSON.stringify(dps);

    const dpsProp = genStrToBtoA(dpsString);

    /// User info
    const userInfo = getTokenObject();
    const userInfoString = JSON.stringify(userInfo);
    const userInfoProp = genStrToBtoA(userInfoString);

    return {
        dpsProp,
        userInfoProp,
    };
}

const Wrapper = styled.div`
    .action-wrap {
        display: flex;
        flex-direction: row-reverse;
    }

    .btn-select-template {
        .Polaris-Button {
            min-height: 12rem;
            min-width: 12rem;
            border-style: dashed;
            border-width: 0.2rem;
        }
    }

    .templates-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1.6rem;
        margin-bottom: 1rem;
    }
`;

const ItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 120px;

    .default-template-wrap {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        z-index: 10;

        .Polaris-Choice {
            padding: 0;

            .Polaris-Choice__Control {
                margin-top: 0;
            }
        }
    }

    .box-image,
    .template-title {
        cursor: pointer;
    }

    .box-image {
        position: relative;

        img {
            width: 100%;
            height: 120px;
            object-fit: cover;
            border-radius: 3px;
        }

        .btn-remove {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding-top: 1rem;
            padding-right: 1rem;
            display: flex;
            justify-content: flex-end;
            align-items: start;
            flex-direction: row;
            column-gap: 0.55rem;
            visibility: hidden;
            border-radius: 5px;

            background: linear-gradient(
                180deg,
                rgba(33, 43, 54, 0.55),
                hsla(0, 0%, 100%, 0)
            );

            .Polaris-Icon {
                svg {
                    fill: hsla(0, 0%, 100%, 0.8);
                }
            }

            &.visible {
                visibility: visible;
            }
        }
    }
`;
