import { useMutation, useQuery } from "@apollo/react-hooks";
import { Loading, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../../../context";
import { UPDATE_STORE } from "../../../../graphql/mutations";
import { checkRole, getParamByRole, handleError } from "../../../../helper";
import useToggle from "../../../../hooks/useToggle";
import { SkeletonPagePolaris } from "../../../shared/SkeletonPagePolaris";
import { Form } from "./Form";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

const STORE_BY_ID = gql`
    query storeById($id: ID!) {
        storeById(id: $id) {
            id
            title
            note
            facebookUid
            status
            owners {
                user {
                    id
                }
            }
        }
    }
`;

export function Edit() {
    const { id } = useParams();

    // Context
    const { currentUser } = React.useContext(AppContext);
    const currentParam = getParamByRole(currentUser);
    const { isStoreManager } = checkRole(currentUser);

    const url = `/${currentParam}/stores/fb-marketplace`;

    // History
    const history = useHistory();

    // State
    const [toastActive, toggleToast] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const timeoutRef = React.useRef(null);

    // Query
    const { data, loading: loadingQ, error } = useQuery(STORE_BY_ID, {
        variables: {
            id,
        },
    });

    // Mutation
    const [updateStore, { loading }] = useMutation(UPDATE_STORE, {
        onCompleted: () => {
            if (setNotify) {
                setNotify({ msg: `Update account successfully.`, err: false });
            }
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                handleRedirect();
            }, 1500);
        },
        onError: (error) => {
            if (setNotify) {
                setNotify({ msg: handleError(error.toString()), err: true });
            }
        },
    });

    // Handle action
    const handleRedirect = React.useCallback(() => {
        history.push(url);
    }, [history, url]);

    const handleSubmit = React.useCallback(
        (input) => {
            if (id != null && input != null) {
                const { name, note, owners, status, facebookUid } = input;
                toggleToast(true);
                if (setNotify) {
                    setNotify({ msg: null, err: false });
                }
                updateStore({
                    variables: {
                        input: {
                            id,
                            title: name,
                            note,
                            owners,
                            facebookUid,
                            status,
                        },
                    },
                });
            }
        },
        [updateStore, id, toggleToast, setNotify]
    );

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            duration={1500}
            onDismiss={toggleToast}
        />
    );

    const loadingMarkup = loadingQ && <Loading />;

    const btnLabel = isStoreManager
        ? "Update FB Marketplace Account"
        : "Update Marketplace Account";

    return (
        <Container>
            {loadingMarkup}
            {toastMarkup}
            <Page
                title={btnLabel}
                fullWidth
                breadcrumbs={[{ content: "Facebook Marketplace", url: url }]}
            >
                {loadingQ ? (
                    <SkeletonPagePolaris />
                ) : error ? (
                    <div>Error: {handleError(error.toString())}</div>
                ) : (
                    <Form
                        value={data?.storeById || null}
                        onSubmit={handleSubmit}
                        loading={loading}
                        isEdit
                        handleRedirect={handleRedirect}
                        isStoreManager={isStoreManager}
                    />
                )}
            </Page>
        </Container>
    );
}
