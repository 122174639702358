import React, { Component } from "react";
import DeleteSelectProduct from "../seller/DeleteSelectProduct";
import styled from "styled-components";
import AddTagsProducts from "./AddTagsProducts";
import DeleteTagsProducts from "./DeleteTagsProducts";
import AddCollectionsProducts from "./AddCollectionsProducts";
import DeleteCollectionsProducts from "./DeleteCollectionsProducts";
import AvailableProducts from "./AvailableProducts";
import { Menu } from "antd";

const Container = styled.div`
  .ant-menu-vertical {
    border: none;
  }
`;
class ActionProduct extends Component {
  state = {
    selectedRows: this.props.selectedRows,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.selectedRows !== this.props.selectedRows) {
      this.setState({
        selectedRows: this.props.selectedRows,
      });
    }
  }

  render() {
    const { onDelete, onClose, filter, selectedRowKeys } = this.props;
    return (
      <Container>
        <Menu>
          <Menu.Item>
            <AvailableProducts
              selectedRows={this.state.selectedRows}
              filter={filter}
            />
          </Menu.Item>
          <Menu.Item>
            <DeleteSelectProduct
              onCompleted={this.props.onCompleted}
              onDelete={(data) => onDelete(data)}
              selectedRows={this.state.selectedRows}
              onPopOverClose={onClose}
              filter={filter}
            />
          </Menu.Item>
          <hr></hr>
          <Menu.Item>
            <AddTagsProducts
              selectedRowKeys={selectedRowKeys}
              selectedRows={this.state.selectedRows}
              onPopOverClose={onClose}
              filter={filter}
            />
          </Menu.Item>
          <Menu.Item>
            <DeleteTagsProducts
              selectedRows={this.state.selectedRows}
              filter={filter}
              onPopOverClose={onClose}
            />
          </Menu.Item>
          <hr></hr>
          <Menu.Item>
            <AddCollectionsProducts
              selectedRows={this.state.selectedRows}
              onPopOverClose={onClose}
              filter={filter}
            />
          </Menu.Item>
          <Menu.Item>
            <DeleteCollectionsProducts
              selectedRows={this.state.selectedRows}
              filter={filter}
              onPopOverClose={onClose}
            />
          </Menu.Item>
        </Menu>
      </Container>
    );
  }
}

export default ActionProduct;
