import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import React from "react";
import { ADD_ORDER_NOTE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function SellerNote({ title, open, onClose, value, refetch }) {
  const [note, setNote] = React.useState("");

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutation
  const timeoutRef = React.useRef(null);
  const [createOrUpdate, { loading }] = useMutation(ADD_ORDER_NOTE, {
    onCompleted: () => {
      setNotify({ msg: "Create/Update note success.", err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const sellerNote = value?.sellerNote;
  React.useEffect(() => {
    if (sellerNote) {
      setNote(sellerNote);
    }
  }, [sellerNote]);

  const handleSubmit = React.useCallback(() => {
    if (!value?.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    createOrUpdate({
      variables: {
        id: value.id,
        note: note ? note : null,
      },
    });
  }, [note, value?.id]);

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <TextField
        label="Note"
        value={note}
        onChange={setNote}
        placeholder="Enter note"
        multiline={3}
      />
    </Modal>
  );
}
