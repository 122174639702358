import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { split, ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { ls } from "./security";
import { API_DOMAIN, LiveApi, LocalApi, Production } from "./config";
import { onError } from "apollo-link-error";
import history from "./history";
import { QUERY_ME } from "./graphql/queries";

const TOKEN_KEY = "app_tk";

export const getTokenObject = () => {
  return ls.get(TOKEN_KEY);
};

export const setTokenObject = (obj) => {
  if (!obj) {
    ls.remove(TOKEN_KEY);
  } else {
    ls.set(TOKEN_KEY, obj);
  }
};

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "production" || Production
      ? `${LiveApi}/query`
      : `${LocalApi}/query`,
});

function redirectToPath(path = "/") {
  history.push(path);
  window.location.reload(false);
}

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  // status = 403
  const ctx =
    (operation?.getContext &&
      typeof operation.getContext === "function" &&
      operation.getContext()) ||
    {};

  if (ctx?.response != null) {
    const { status } = ctx.response || {};
    if (status === 403) {
      redirectToPath("/login");
      return;
    }
  }

  const accessDenied = "access denied";
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (message === accessDenied) {
        const client = window.__apolloClient__;
        if (client != null) {
          client
            .query({
              query: QUERY_ME,
            })
            .then((res) => {
              if (res?.data) {
                redirectToPath();
              }
            })
            .catch(() => {
              redirectToPath("/login");
            });
        } else {
          redirectToPath("/login");
        }
      }
    });
  }

  if (networkError) {
    const nEStr = networkError.toString();
    if (new RegExp(accessDenied).test(nEStr)) {
      redirectToPath("/login");
      return;
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === "production" || Production
      ? `wss://${API_DOMAIN}/query`
      : `ws://localhost:8080/query`,
  options: {
    reconnect: true,
    connectionParams: () => {
      let token = "";
      const tokenObject = getTokenObject();
      if (tokenObject) {
        token = tokenObject.id;
      }
      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  },
});

const authLink = setContext(({operationName}, { headers , ...args}) => {
  // get the authentication token from local storage if it exists
  let token = "";
  const tokenObject = getTokenObject();
  if (tokenObject) {
    token = tokenObject.id;
    //@todo handle check token expired
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && operationName !== "login" ? `Bearer ${token}` : "",
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, link]),
  cache: new InMemoryCache(),
});

window.__apolloClient__ = apolloClient;
