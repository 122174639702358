import React from "react";
import { useBaseFulfillmentCtx } from "./BaseFulfillmentProvider";
import styled from "styled-components";
import { Radio } from "antd";
import { MANGOTEEPRINTS_CATALOG_TYPE } from "../../tasks/artist/constants";

const OPTIONS = MANGOTEEPRINTS_CATALOG_TYPE.map((i) => ({
  value: i,
  label: i,
}));

export default function WrapBaseFulfillmentID({ children }) {
  const { isMangoTeePrints } = useBaseFulfillmentCtx();

  const [state, setState] = React.useState(MANGOTEEPRINTS_CATALOG_TYPE[0]);
  const handleChange = React.useCallback((evt) => {
    const val = evt?.target?.value;
    setState(val);
  }, []);

  return isMangoTeePrints ? (
    <Wrap>
      <div className="printer-wrap">
        <label htmlFor="printer">Printers</label>
        <Radio.Group
          id="printer"
          value={state}
          defaultValue={state}
          onChange={handleChange}
          options={OPTIONS}
        />
      </div>

      {children(state)}
    </Wrap>
  ) : (
    children()
  );
}

const Wrap = styled.div`
  .printer-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`;
