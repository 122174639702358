import React from 'react';
import styled from 'styled-components';

import useToggle from '../../../../hooks/useToggle';

import { ModalImagePolaris } from '../../../shared/ModalImagePolaris';

const Container = styled.div`
    margin-top: 1rem;

    .container {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
    }

    .img-wrap {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
            rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
    }

    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ProductImage = ({ product }) => {
    // State
    const [open, toggleOpen] = useToggle(false);

    // Handle actions
    const imageMarkup = React.useMemo(() => {
        if (product == null) return null;

        const mainImageId = product?.mainImageId;
        const images = product?.images;
        let mainImages = [];
        if (mainImageId || mainImageId !== 'undefined') {
            mainImages =
                images?.length > 0
                    ? images
                          .filter((img) => img?.file?.id === mainImageId)
                          .map((item) => item.file)
                    : [];
        }
        if (mainImages.length === 0) {
            mainImages =
                images?.length > 0
                    ? images.filter(Boolean).map((item) => item.file)
                    : [];
        }

        const image = mainImages?.length > 0 ? mainImages[0] : null;
        let urlSelectedItem;
        let thumbnail;
        if (image) {
            urlSelectedItem = image.url ? image.url : image.thumbnailUrl;
            thumbnail = image.thumbnailUrl ? image.thumbnailUrl : image.url;
        }

        return (
            <div className='container'>
                <p className='title'>{product.title ?? ''}</p>
                <img
                    className='img-wrap'
                    alt=''
                    src={thumbnail}
                    onClick={toggleOpen}
                    style={{ cursor: 'pointer' }}
                />
                <ModalImagePolaris
                    toggleShowModal={toggleOpen}
                    open={open}
                    source={urlSelectedItem}
                    title={'Product image'}
                />
            </div>
        );
    }, [product, toggleOpen, open]);

    return <Container>{imageMarkup}</Container>;
};
