import React, { useCallback, useMemo } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { ASSIGN_COMPLEX_PRODUCT } from "../../../graphql/mutations";
import { FormAssign } from "../components/FormAssign";
import { useToastContext } from "../../shared/ToastContext";
import { handleError } from "../../../helper";
import {
    COMPLEX_PRODUCT_ASSORTMENT_BY_ID,
    COMPLEX_PRODUCT_ASSORTMENT,
} from "../../../graphql/queries";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

export function Assign({ open, onClose, item }) {
    const id = item?.id;
    // Context
    const { toggleToast, setNotify, filter } = useToastContext();

    // Query
    const { data, loading: loadingM, error, client } = useQuery(
        COMPLEX_PRODUCT_ASSORTMENT_BY_ID,
        {
            variables: {
                id,
            },
            skip: id == null,
        }
    );

    // Mutation
    const [assignComplexProduct, { loading }] = useMutation(
        ASSIGN_COMPLEX_PRODUCT,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Assign this product successfully.",
                    err: false,
                });
                onClose && onClose();
            },
            onError: (err) => {
                setNotify({ msg: handleError(err.toString()), err: true });
            },
        }
    );

    // Handle action
    const handleUpdateCache = useCallback(() => {
        if (id != null) {
            const query = COMPLEX_PRODUCT_ASSORTMENT;
            const variables = { filter };

            const cache = client.readQuery({
                query,
                variables,
            });

            client.writeQuery({
                query,
                variables,
                data: {
                    ...cache,
                    complexProductAssortment: {
                        ...cache.complexProductAssortment,
                        total: cache.complexProductAssortment.total - 1,
                        nodes: cache.complexProductAssortment.nodes.filter(
                            (node) => node.id !== id
                        ),
                    },
                },
            });
        }
    }, [client, filter, id]);

    const handleSubmit = useCallback(
        (input) => {
            if (input != null) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });

                assignComplexProduct({
                    variables: {
                        input,
                    },
                })
                    .then((res) => {
                        const data = res?.data?.assignComplexProduct;
                        if (data != null) {
                            handleUpdateCache();
                        }
                    })
                    .catch(() => {});
            }
        },
        [setNotify, toggleToast, assignComplexProduct, handleUpdateCache]
    );

    const value = useMemo(() => {
        if (data?.getComplexProductAssortmentById != null) {
            return data.getComplexProductAssortmentById;
        }
    }, [data]);

    return (
        <Modal
            large
            title="Assign Product"
            sectioned
            open={open}
            onClose={onClose}
        >
            {loadingM ? (
                <SkeletonPagePolaris />
            ) : error ? (
                <div>Error: {handleError(error.toString())}</div>
            ) : value != null ? (
                <FormAssign
                    onSubmit={handleSubmit}
                    loading={loading}
                    value={value}
                />
            ) : (
                <EmptyStatePolaris noData />
            )}
        </Modal>
    );
}
