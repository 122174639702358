import React from "react";

import { FacebookMK } from "../../components/reports";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function FacebookMKPage() {
    return (
        <WrapperPage>
            <FacebookMK />
        </WrapperPage>
    );
}
