import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { buttonFrom, Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { ADMIN_GET_USER_TOKEN } from "../../../graphql/mutations/user";
import { useToastContext } from "../../shared/ToastContext";
import { handleError, setCookie } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { useAppContext } from "../../../context";

export function GetUserToken({ userId, overrides }) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const timeoutRef = useRef(null);

    // Context
    const { toggleToast, setNotify } = useToastContext();
    const { authenticate } = useAppContext();

    // Mutation
    const [getUserToken, { loading }] = useMutation(ADMIN_GET_USER_TOKEN, {
        onError: (error) => {
            setNotify({ msg: handleError(error?.toString()), err: true });
        },
        onCompleted: (res) => {
            setNotify({ msg: "Login as this user successfully.", err: false });
            toggleOpen();

            setCookie("userId", userId, 100);
            if (res?.adminGetUserToken != null) {
                timeoutRef.current = setTimeout(() => {
                    authenticate && authenticate(res.adminGetUserToken);
                }, 1500);
            }
        },
    });

    // Actions
    const handleSubmit = useCallback(() => {
        if (userId != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });

            getUserToken({
                variables: {
                    userID: userId,
                },
            });
        }
    }, [userId, getUserToken, toggleToast, setNotify]);

    useEffect(
        () => () => {
            clearTimeout(timeoutRef.current);
        },
        []
    );

    return (
        <Fragment>
            {buttonFrom(
                {
                    content: "Login as this user",
                    onAction: toggleOpen,
                    onTouchStart: toggleOpen,
                },
                overrides
            )}
            <Modal
                title="Login as this user"
                open={open}
                onClose={toggleOpen}
                sectioned
                primaryAction={{
                    content: "Submit",
                    onAction: handleSubmit,
                    onTouchStart: handleSubmit,
                    loading: loading,
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: toggleOpen,
                        onTouchStart: toggleOpen,
                    },
                ]}
            >
                <p>Are you sure to login as this user?</p>
            </Modal>
        </Fragment>
    );
}
