import { Page } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import { SellersPolaris } from "../../components/admin/seller/SellersPolaris";
import { getPathFromRouter } from "../../helper";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const SellerPagePolaris = (...props) => {
    const path = React.useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Container>
            <Page title="All Seller" fullWidth>
                <SellersPolaris path={path} />
            </Page>
        </Container>
    );
};
