import React, { Component } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { handleError } from "../../helper";

import { ACCEPT_DESIGN } from "../../graphql/mutations";

export default class AcceptDesignAction extends Component {
    state = {
        visible: false,
    };

    render() {
        const { visible } = this.state;
        const { task } = this.props;
        return (
            <div>
                <div
                    className="cursor-pointer flex items-center"
                    onClick={(e) => {
                        this.setState({ visible: true });
                    }}
                >
                    <CheckCircleOutlined />
                    <div className="ml-2">Accept Design</div>
                </div>
                <Mutation
                    onError={(err) => {
                        notification.error({
                            message: handleError(err.toString()),
                        });
                    }}
                    onCompleted={() => {
                        notification.success({
                            message: "Design has been approved!",
                        });
                        if (this.props.refetch) {
                            this.props.refetch();
                        }
                    }}
                    mutation={ACCEPT_DESIGN}
                    variables={{ id: task.id }}
                >
                    {(acceptDesign, { loading }) => {
                        return (
                            <Modal
                                visible={visible}
                                okButtonProps={{ loading: loading }}
                                onOk={() => {
                                    acceptDesign();
                                }}
                                onCancel={() =>
                                    this.setState({ visible: false })
                                }
                                title={"Accept Design Confirmation"}
                            >
                                <p>
                                    Are you sure want to accept this design for
                                    order #{task.orderId}
                                </p>
                            </Modal>
                        );
                    }}
                </Mutation>
            </div>
        );
    }
}
