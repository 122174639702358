import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { CategoriesPolaris } from "../../components/admin/category/CategoriesPolaris";
import { getParamByUserRole } from "../../helper";
import { useAppContext } from "../../context";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const CategoriesPagePolaris = () => {
  const { currentUser } = useAppContext();
  const param = getParamByUserRole(currentUser);

  return (
    <Container>
      <Page
        title="All Categories"
        fullWidth
        primaryAction={{ content: "New Category", url: `/${param}/add-category` }}
      >
        <CategoriesPolaris />
      </Page>
    </Container>
  );
};
