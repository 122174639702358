import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, notification, Collapse } from "antd";
import styled from "styled-components";

import Price from "../Price";
const { Panel } = Collapse;

const Container = styled.div`
  padding: 2rem 0 1.5rem;
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin: 2rem 0;
  }
  .ant-collapse > .ant-collapse-item > {
    .ant-collapse-header {
      padding: 0;
      width: 120px;
      margin-bottom: 2rem;
      
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }.
`;

export const UpdatePriceForVariants = ({ labels, onChange, onSubmit }) => {
  const [dataSource, setDataSource] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (labels && labels.length) {
      setDataSource(labels);
    }
  }, [labels]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = useCallback((value) => setDataSource(value), []);
  const handleClickUpdate = useCallback(() => {
    setLoading(true);
    if (onSubmit) {
      const id = setTimeout(() => {
        onSubmit();
        notification.success({ message: "Update items success" });
        setLoading(false);
      }, 700);
      setTimeoutId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const columns = [
    {
      title: "Size",
      key: "size",
      dataIndex: "label",
    },
    {
      title: "Regular Price",
      key: "regularPrice",
      dataIndex: "regularPrice",
      render: (_, record, index) => (
        <Price
          value={record.regularPrice}
          onChange={(newValue) => {
            dataSource[index].regularPrice = newValue;
            handleOnChange(dataSource);
          }}
        />
      ),
    },
    {
      title: "Sale Price",
      key: "salePrice",
      dataIndex: "salePrice",
      render: (_, record, index) => (
        <Price
          value={record.salePrice}
          onChange={(newValue) => {
            dataSource[index].salePrice = newValue;
            handleOnChange(dataSource);
          }}
        />
      ),
    },
  ];

  return (
    <Container>
      <Collapse style={{ background: "none" }} bordered={false}>
        <Panel key="1" showArrow={false} extra={<Button>Set Prices</Button>}>
          <Table
            dataSource={dataSource}
            rowKey={(_, index) => index}
            pagination={false}
            columns={columns}
          />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={handleClickUpdate}
              loading={loading}
            >
              Apply Changes
            </Button>
          </div>
        </Panel>
      </Collapse>
    </Container>
  );
};
