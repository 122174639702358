import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback } from "react";

export function useQueryDNode(documentNode) {
  const [get, { data, loading, error, fetchMore }] = useLazyQuery(documentNode);

  const queryLazy = useCallback(
    (filter) => {
      get({
        variables: {
          filter,
        },
      });
    },
    [get]
  );

  return [queryLazy, { data, loading, error, fetchMore }];
}
