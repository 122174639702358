import React, { Component } from "react";
import { Skeleton, Select } from "antd";
import { Query } from "@apollo/react-components";
import { handleError } from "../../helper";
import { LIST_TEAM } from "./ListTeamMember";

const { Option } = Select;

class AssigneeSelect extends Component {
  render() {
    const { value } = this.props;
    return (
      <Query fetchPolicy={"network-only"} query={LIST_TEAM}>
        {({ data, error, loading }) => {
          if (loading) return <Skeleton active />;
          if (error) return <div>Error:{handleError(error.toString())}</div>;
          const teamMembers = data.teamMembers.hits;
          return (
            <div>
              <Select
                value={value}
                className="w-40 mr-2"
                onChange={(value) => this.props.onChange(value)}
                placeholder="Filter by Assignee"
              >
                {teamMembers.map((member) => {
                  const detailMember = member.user;
                  return (
                    <Option key={detailMember.id} value={detailMember.id}>
                      {detailMember.firstName} {detailMember.lastName}
                    </Option>
                  );
                })}
                <Option value="all">All</Option>
              </Select>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AssigneeSelect;
