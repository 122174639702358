import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { handleError } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

const GOOGLE_CATEGORIES = gql`
  query googleCategories {
    googleCategories {
      id
      title
    }
  }
`;

const TitleContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GoogleProductCategoryPolaris = ({
  onChange,
  value,
  labelHidden,
}) => {
  const { data, loading, error } = useQuery(GOOGLE_CATEGORIES);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (data && data.googleCategories && data.googleCategories.length) {
      let newData = data.googleCategories.map((gc) => {
        return {
          label: <TitleContainer>{gc.title}</TitleContainer>,
          value: gc.title,
        };
      });
      setOptions(_.slice(newData, 0, 30));
      setDeselectedOptions(newData);
    }
  }, [data]);

  useEffect(() => {
    setSelectedOptions([value]);
    setInputValue(value);
  }, [value]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => {
        let newValue = _.get(option, "label.props.children");
        return newValue.match(filterRegex);
      });
      setOptions(_.slice(resultOptions, 0, 30));
    },
    [deselectedOptions]
  );

  const handleSelectionChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      let newValue = _.get(_.head(selectedValue), "props.children");
      setInputValue(newValue);
      setSelectedOptions(selected);
      if (onChange) {
        onChange(_.head(selected));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textFiled = (
    <Autocomplete.TextField
      label={"Google product category"}
      placeholder="Search for categories"
      value={inputValue}
      onChange={handleInputChange}
      labelHidden={true}
      helpText="Required if you sell apprel and you need to set gender and age-group too"
    />
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {!labelHidden ? (
        <ComponentLabelPolaris label={"Google product category"} />
      ) : null}
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelectionChange}
        textField={textFiled}
        emptyState={<span>No items found!</span>}
        loading={loading}
      />
    </>
  );
};
