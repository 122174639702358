import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { matchValue } from "../../../../helper";

const CreateIdeaContext = createContext(null);
CreateIdeaContext.displayName = "CreateIdeaContext";

const ActionTypes = {
    UpdateProduct: "UpdateProduct",
    AddProducts: "AddProducts",
    UpdateBase: "UpdateBase",
    UpdateStateIdea: "UpdateStateIdea",
};

const reducers = {
    [ActionTypes.UpdateStateIdea](state, action) {
        const { payload } = action;

        return {
            ...state,
            ...payload,
        };
    },
    [ActionTypes.UpdateProduct](state, action) {
        const { index, newValue } = action.payload;

        return {
            ...state,
            products: state.products.map((e, i) =>
                i === index ? { ...e, ...newValue } : e
            ),
        };
    },
    [ActionTypes.AddProducts](state, action) {
        return {
            ...state,
            products: action.payload,
        };
    },
    [ActionTypes.UpdateBase](state, action) {
        const { index, base } = action.payload;
        const baseId = base?.id;

        return {
            ...state,
            products: state.products.map((e, i) => {
                if (i === index) {
                    return {
                        ...e,
                        bases: e.bases.map((b) => {
                            if (b.id === baseId) {
                                return {
                                    ...b,
                                    ...base,
                                };
                            }
                            return b;
                        }),
                    };
                }
                return e;
            }),
        };
    },
};

function stateReducer(state, action) {
    return matchValue(action.type, reducers, state, action);
}

const initialValue = {
    products: [],
};

export function CreateIdeaProvider({
    children,
    setChanged,
    setCanContinue,
    ...props
}) {
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    // Action
    const products = state.products;
    const addProduct = useCallback(
        (product) => {
            setChanged && setChanged(true);
            products.push(product);

            dispatch({ type: ActionTypes.UpdateProduct, payload: products });
        },
        [dispatch, products, setChanged]
    );

    const removeProduct = useCallback(
        (index) => {
            if (index <= -1) return;
            setChanged && setChanged(true);
            products.splice(index, 1);

            dispatch({ type: ActionTypes.UpdateProduct, payload: products });
        },
        [dispatch, products, setChanged]
    );

    const duplicateProduct = useCallback(
        (index) => {
            if (index <= -1) return;
            setChanged && setChanged(true);
            const cur = products.find((_, idx) => index === idx);
            if (cur != null) {
                const { id, ...rest } = cur;

                const newProduct = Object.assign(
                    {},
                    JSON.parse(JSON.stringify(rest))
                );
                products.splice(index + 1, 0, newProduct);
            }

            dispatch({ type: ActionTypes.UpdateProduct, payload: products });
            return products.length;
        },
        [dispatch, products, setChanged]
    );

    const updateProduct = useCallback(
        (index, newValue) => {
            if (index <= -1) return;

            dispatch({
                type: ActionTypes.UpdateProduct,
                payload: { index, newValue },
            });
        },
        [dispatch]
    );

    const addProducts = useCallback(
        (newProducts) => {
            if (newProducts?.length > 0) {
                dispatch({
                    type: ActionTypes.AddProducts,
                    payload: newProducts,
                });
            }
        },
        [dispatch]
    );

    const updateBase = useCallback(
        (index, base) => {
            if (index <= -1) return;

            dispatch({
                type: ActionTypes.UpdateBase,
                payload: { index, base },
            });
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch({
            type: ActionTypes.UpdateStateIdea,
            payload: { products: [] },
        });
    }, [dispatch]);

    useEffect(() => {
        const haveMockupTemplate = (products || []).every((p) =>
            p.bases?.length > 0
                ? p.bases.some((b) => b?.mockupTemplates?.length > 0)
                : false
        );
        setCanContinue(haveMockupTemplate);
    }, [products, setCanContinue]);

    // Provider
    const bag = useMemo(
        () => ({
            ...state,
            addProduct,
            removeProduct,
            duplicateProduct,
            updateProduct,
            addProducts,
            updateBase,
            setChanged,
            ...props,
        }),
        [
            state,
            addProduct,
            removeProduct,
            duplicateProduct,
            updateProduct,
            addProducts,
            updateBase,
            setChanged,
            props,
        ]
    );
    return (
        <CreateIdeaContext.Provider value={bag}>
            {children}
        </CreateIdeaContext.Provider>
    );
}

export function useCreateIdea() {
    return useContext(CreateIdeaContext);
}
