import React, { Component } from "react";
import styled from "styled-components";
import AddExportTemplate from "../../components/admin/AddExportTemplate";
import PageTitle from "../../components/shared/PageTitle";
import { AppContext } from "../../context";
import { getParam } from "../../helper";

const Container = styled.div`
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
export default class AddTemplate extends Component {
  render() {
    const { currentUser } = this.context;
    const param = getParam(currentUser);

    return (
      <Container>
        <PageTitle
          title="Add Export File Template"
          subTitle="Export Templates"
          link={`/${param}/manager-files/export-templates`}
        />
        <AddExportTemplate link={`/${param}/manager-files/export-templates`} />
      </Container>
    );
  }
}

AddTemplate.contextType = AppContext;
