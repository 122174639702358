import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
    .Polaris-Filters-ConnectedFilterControl__CenterContainer
    * {
    border-radius: var(--p-border-radius-base, 3px) !important;
  }
`;

export const FilterTagPolaris = ({ filter, onChange }) => {
  const [queryValue, setQueryValue] = useState(filter.search);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search:
            "" === queryValue || null === queryValue ? null : queryValue.trim(),
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((value) => {
    setQueryValue(value);
  }, []);
  const handleQueryRemove = useCallback(() => setQueryValue(null), []);

  return (
    <Container>
      <Filters
        filters={[]}
        queryValue={queryValue}
        queryPlaceholder="Filter tag"
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryRemove}
        onClearAll={() => {}}
      />
    </Container>
  );
};
