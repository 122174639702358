import React, { Component } from "react";
import { gql } from "apollo-boost";
import "@ant-design/compatible/assets/index.css";
import { Modal, Button, notification, Form } from "antd";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import { Mutation } from "@apollo/react-components";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const ADD_COLLECTION = gql`
  mutation addCollectionsToProducts(
    $productIds: [ID!]!
    $collectionIds: [ID!]
    $add: Boolean!
  ) {
    addCollectionsToProducts(
      productIds: $productIds
      collectionIds: $collectionIds
      add: $add
    )
  }
`;
class AddCollectionsProducts extends Component {
  state = {
    showCollection: false,
  };
  handAddCollections = (client, collections) => {
    try {
      const { selectedRows, filter } = this.props;
      const variables = {
        filter: filter,
      };
      const cache = client.readQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
      });
      client.writeQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
        data: {
          ...cache,
          products: {
            ...cache.products,
            nodes: [
              ...cache.products.nodes.map(
                (collec) => (
                  selectedRows.find((s) => s === collec.id)
                    ? (collec.collections = [
                        ...collec.collections,
                        ...collections,
                      ])
                    : null,
                  collec
                )
              ),
            ],
          },
        },
      });
    } catch (e) {}
  };
  render() {
    const { showCollection } = this.state;
    const { selectedRows, onPopOverClose } = this.props;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <div>
        <Mutation mutation={ADD_COLLECTION}>
          {(addCollectionsToProducts, { client }) => (
            <Modal
              title="Add Collections"
              visible={showCollection}
              footer={null}
              onCancel={() => this.setState({ showCollection: false })}
            >
              <Form
                {...layout}
                initialValues={{
                  collectionIds: [],
                }}
                onFinish={(e) => {
                  if (e) {
                    addCollectionsToProducts({
                      variables: {
                        productIds: selectedRows,
                        add: true,
                        collectionIds:
                          e.collectionIds && e.collectionIds.length > 0
                            ? e.collectionIds.map((c) => c.id)
                            : null,
                      },
                    }).then((res) => {
                      this.setState({ loading: false });
                      notification.success({
                        message: "Add collections success!",
                      });
                      this.handAddCollections(client, e.collectionIds);
                      this.setState({ showCollection: false });
                    });
                  }
                }}
              >
                <Form.Item name="collectionIds" label="Collections">
                  <CollectionsAutoComplete />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    className="mr-2"
                    onClick={() => this.setState({ showCollection: false })}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          )}
        </Mutation>
        <main
          style={{ cursor: "pointer" }}
          onClick={() => {
            onPopOverClose();
            this.setState({ showCollection: true });
          }}
        >
          Add Collections
        </main>
      </div>
    );
  }
}

export default AddCollectionsProducts;
