import React, { Component } from "react";
import { Popconfirm, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const CHANGE = gql`
  mutation changeProductSelected($id: [ID!], $status: String!) {
    changeProductSelected(id: $id, status: $status) {
      failed
      successed
    }
  }
`;
class AvailableProducts extends Component {
  render() {
    const { selectedRows, onCompleted, filter } = this.props;
    return (
      <Mutation
        mutation={CHANGE}
        onCompleted={(res) => {
          notification.success({ message: "Change status success!" });
          if (onCompleted) {
            onCompleted(res);
          }
        }}
        onError={(err) => {
          notification.error({ message: err.toString() });
        }}
      >
        {(changeProductSelected, { client }) => (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            title="Are you sure it will change?"
            placement="right"
            onConfirm={() => {
              changeProductSelected({
                variables: {
                  id: selectedRows,
                  status: "Available",
                },
              }).then((res) => {
                try {
                  const variables = {
                    filter: filter,
                  };
                  const cache = client.readQuery({
                    query: LIST_SELLER_PRODUCTS_QUERY,
                    variables,
                  });
                  client.writeQuery({
                    query: LIST_SELLER_PRODUCTS_QUERY,
                    variables,
                    data: {
                      ...cache,
                      products: {
                        ...cache.products,
                        nodes: [
                          ...cache.products.nodes.map(
                            (v) => (
                              selectedRows.find((s) => s === v.id)
                                ? (v.status = "Available")
                                : null,
                              v
                            )
                          ),
                        ],
                      },
                    },
                  });
                } catch (e) {}
              });
            }}
          >
            <div style={{ cursor: "pointer" }}>Make products available</div>
          </Popconfirm>
        )}
      </Mutation>
    );
  }
}

export default AvailableProducts;
