import { Page } from "@shopify/polaris";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { TeamMembersPolaris } from "../../components/members/TeamMembersPolaris";
import { AppContext } from "../../context";
import { getParamByRole, getPathFromRouter, isPME_TEAM } from "../../helper";
import { usePrivileges } from "../../hooks/usePrivileges";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const TeamMembersPagePolaris = (...props) => {
  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const path = useMemo(() => {
    return getPathFromRouter(props);
  }, [props]);

  const isPMETeam = isPME_TEAM(currentUser);

  const { canCreate } = usePrivileges();
  const primaryAction = {
    primaryAction: {
      content: "Add member",
      url: `/${currentParam}/add-team-member`,
    },
  };
  const action = isPMETeam ? (canCreate ? primaryAction : {}) : primaryAction;

  return (
    <Container>
      <Page title="All Members" fullWidth {...action}>
        <TeamMembersPolaris path={path} />
      </Page>
    </Container>
  );
};
