import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, notification, Radio, Card } from "antd";
import XpathSelector from "./XpathSelector";
import CrawlerFile from "../shared/CrawlerFile";
import { decodeCSV, handleError, validateUrl } from "../../helper";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import history from "../../history";
import _ from "lodash";

const CREATE_CRAWL_MUTATION = gql`
  mutation createCrawl($input: NewCrawl!) {
    createCrawl(input: $input) {
      id
      title
      description
    }
  }
`;

class ProductCrawlForm extends Component {
  state = {
    items: [],
    loading: false,
    crawler: {},
  };

  processUrls = (files) => {
    let items = [];
    let mapByUrl = {};
    const titleXPath = this.props.form.getFieldValue("titleXPath");
    const imageXPath = this.props.form.getFieldValue("imageXPath");
    for (let i = 0; i < files.length; i++) {
      const text = files[i].data;
      const lines = text.split(/[\r\n]+/g);
      let hasItem = false;
      for (let j = 0; j < lines.length; j++) {
        const url = lines[j].trim().toLowerCase();
        if (validateUrl(url)) {
          if (typeof mapByUrl[url] === "undefined") {
            mapByUrl[url] = true;
            hasItem = true;
            items.push({
              url: url,
              title: null,
              titleXpath: titleXPath,
              imageXpath: imageXPath,
              imageUrl: null,
              productBaseSlug: null,
              type: "Url",
            });
          }
        }
      }
      if (!hasItem) {
        notification.error({
          message: `File ${files[i].file.name} contain wrong urls`,
        });
      }
    }
    this.setState({ items });
    return items;
  };
  processStructUrls = (files) => {
    let items = [];
    let mapByUrl = {};
    for (let i = 0; i < files.length; i++) {
      const text = files[i].data;
      if (text) {
        let moreItems = this.getStructItemByText(text, mapByUrl);
        if (moreItems.length === 0) {
          notification.error({
            message: `File ${files[i].file.name} contain wrong struct!`,
          });
        }
        items = [...items, ...moreItems];
      }
    }
    this.setState({ items });
    return items;
  };

  getStructItemByText = (text, mapByUrl) => {
    let items = [];
    let arr = decodeCSV(text);
    for (let i = 0; i < arr.length; i++) {
      const line = arr[i];
      const title = _.trim(_.get(line, "[0]", null));
      const imageUrl = _.trim(_.get(line, "[1]", null));
      if (typeof mapByUrl[imageUrl] !== "undefined" || !validateUrl(imageUrl)) {
        continue;
      }
      const productBaseSlug = _.get(line, "[2]", "");
      const templateSlug = _.get(line, "[3]");
      const collections = _.split(_.get(line, "[4]", []), ",").map((c) =>
        _.trim(c)
      );
      const tags = _.split(_.get(line, "[5]", []), ",").map((t) => _.trim(t));
      let storeUrl = _.get(line, "[6]", null);
      if (storeUrl) {
        storeUrl = _.trim(storeUrl);
      }
      if (storeUrl === "site_crawler") {
        storeUrl = null;
      }
      let storeProductId = _.get(line, "[7]", null);
      if (storeProductId) {
        storeProductId = _.trim(storeProductId);
      }
      if (storeProductId === "0") {
        storeProductId = null;
      }
      let designFiles = _.get(line, "[8]", null);
      const originSku = _.get(line, "[9]", null);
      const relateSku = _.get(line, "[10]", null);
      if (designFiles) {
        designFiles = JSON.parse(designFiles);
      }

      if (title && imageUrl && (productBaseSlug || templateSlug)) {
        mapByUrl[imageUrl] = imageUrl;
        items.push({
          title,
          imageUrl,
          productBaseSlug,
          templateSlug,
          type: "Struct",
          tags,
          collections,
          storeUrl,
          storeProductId,
          designFiles: designFiles ? designFiles : null,
          originSku,
          relateSku,
        });
      }
    }
    return items;
  };
  calculateItems = (files) => {
    const crawlType = this.props.form.getFieldValue("type");
    if (files && files.length) {
      if (crawlType === "Url") {
        return this.processUrls(files);
      } else {
        return this.processStructUrls(files);
      }
    } else {
      return [];
    }
  };

  render() {
    const { items, crawler } = this.state;
    const { getFieldDecorator } = this.props.form;
    const crawlType = this.props.form.getFieldValue("type");
    let url = items && items.length ? items[0].url : null;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Card>
        <Mutation
          onError={(e) => {
            notification.error({ message: handleError(e.toString()) });
            this.setState({ loading: false });
          }}
          onCompleted={(res) => {
            this.setState({ loading: false }, () => {
              history.push(`/tools/product-crawlers/${res.createCrawl.id}`);
            });
          }}
          mutation={CREATE_CRAWL_MUTATION}
        >
          {(createCrawl) => (
            <Form
              {...layout}
              onSubmit={(e) => {
                e.preventDefault();
                this.props.form.validateFields((err, values) => {
                  if (!err) {
                    const files = this.props.form.getFieldValue("files");
                    let theItems = this.calculateItems(files);
                    if (!theItems || theItems.length === 0) {
                      notification.error({
                        message:
                          "No urls for crawl. Please check crawler file again!",
                      });
                      return;
                    }
                    theItems = theItems.map((i) => ({ ...i, ...crawler }));
                    this.setState({ loading: true }, () => {
                      let data = {
                        title: values.title,
                        description: null,
                        type: crawlType,
                        domain:
                          crawlType === "Url"
                            ? theItems[0].url
                            : theItems[0].imageUrl,
                        items: theItems,
                        files: files.map((f) => f.file.id),
                      };
                      createCrawl({
                        variables: {
                          input: data,
                        },
                      });
                    });
                  }
                });
              }}
            >
              <Form.Item label={"Title"}>
                {getFieldDecorator("title", {
                  rules: [{ required: true, message: "Title is required!" }],
                })(<Input placeholder={"Enter crawler title"} />)}
              </Form.Item>
              <Form.Item label={"Type"}>
                {getFieldDecorator("type", {
                  initialValue: "Struct",
                })(
                  <Radio.Group
                    onChange={() => {
                      const files = this.props.form.getFieldValue("files");
                      this.calculateItems(files);
                    }}
                  >
                    <Radio value={"Struct"}>Product Base Struct</Radio>
                    <Radio value={"Url"}>Only Urls</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label={"Crawler File"}>
                {getFieldDecorator("files", {
                  initialValue: [],
                  rules: [
                    {
                      required: true,
                      message: "Crawler file is required!",
                    },
                  ],
                })(
                  <CrawlerFile
                    onChange={(newFiles) => this.calculateItems(newFiles)}
                  />
                )}
              </Form.Item>
              {crawlType === "Url" && (
                <React.Fragment>
                  <Form.Item label={"Title XPath"}>
                    {getFieldDecorator("titleXPath", {
                      rules: [
                        { required: true, message: "TitleXPath is required!" },
                      ],
                    })(
                      <XpathSelector
                        url={url}
                        crawler={crawler}
                        onChangeAttribute={(v) => {
                          this.setState({
                            crawler: { ...crawler, titleXpathAttribute: v },
                          });
                        }}
                        onChangeItems={(items) => {
                          this.setState({
                            crawler: { ...crawler, titleReplacement: items },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label={"Image XPath"}>
                    {getFieldDecorator("imageXPath", {
                      rules: [
                        { required: true, message: "ImageXpath is required!" },
                      ],
                    })(
                      <XpathSelector
                        isImage={true}
                        crawler={crawler}
                        url={url}
                        onChangeAttribute={(v) => {
                          this.setState({
                            crawler: { ...crawler, imageXpathAttribute: v },
                          });
                        }}
                        onChangeItems={(items) => {
                          this.setState({
                            crawler: { ...crawler, imageReplacement: items },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </React.Fragment>
              )}
              <Form.Item
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  loading={this.state.loading}
                  className="btn-green"
                  htmlType={"submit"}
                >
                  Process Now
                </Button>
              </Form.Item>
            </Form>
          )}
        </Mutation>
      </Card>
    );
  }
}

ProductCrawlForm.propTypes = {
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default Form.create({ name: "form" })(ProductCrawlForm);
