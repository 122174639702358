import React, { Component } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { Button, notification } from "antd";
import { handleError } from "../../helper";

const DELETE_MUTATION = gql`
  mutation deleteProductCrawlItem($id: ID!) {
    deleteProductCrawlItem(id: $id)
  }
`;

class DeleteProductCrawlItemButton extends Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { value, onError, onCompleted } = this.props;
    return (
      <Mutation
        onCompleted={() => {
          this.setState({ loading: false });
          notification.success({ message: `${value.title} has been deleted!` });
          if (onCompleted) {
            onCompleted();
          }
        }}
        onError={(e) => {
          notification.error({ message: handleError(e.toString()) });
          this.setState({ loading: false });
          if (onError) {
            onError(e);
          }
        }}
        mutation={DELETE_MUTATION}
      >
        {(deleteProductCrawlItem) => (
          <Button
            size="small"
            loading={loading}
            onClick={() => {
              this.setState({ loading: true });
              deleteProductCrawlItem({
                variables: {
                  id: value.id,
                },
              });
            }}
          >
            Trash
          </Button>
        )}
      </Mutation>
    );
  }
}

DeleteProductCrawlItemButton.propTypes = {
  value: PropTypes.object,
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default DeleteProductCrawlItemButton;
