import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

const Container = styled.div`
    .Polaris-ChoiceList__Choices {
        max-height: 280px;
        overflow-y: scroll;
        padding-left: 2px;
    }
`;

export const FilterListRadioPolaris = ({
    options,
    value,
    onChange,
    defaultValue = "All",
}) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (!value) return setSelected([defaultValue]);
        return setSelected([value]);
    }, [value, defaultValue]);

    const handleChange = useCallback(
        (selected) => {
            const selectedValue = selected.map((selectedItem) => {
                const matchedOptions = options.find((option) => {
                    return option.value === selectedItem;
                });
                return matchedOptions && matchedOptions.label;
            });
            let newValue = _.head(selected);
            let newLabel = _.head(selectedValue);
            setSelected(selected);
            if (onChange) {
                onChange({
                    value: defaultValue !== newValue ? newValue : null,
                    label: defaultValue !== newLabel ? newLabel : null,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options, defaultValue]
    );

    return (
        <Container>
            <ChoiceList
                title="role"
                titleHidden
                selected={selected}
                choices={options}
                onChange={handleChange}
            />
        </Container>
    );
};
