import React, { Component } from "react";
import { Popover, Space, Input, Button, Table } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";

export default class FindReplaceXPath extends Component {
  state = {
    items: [
      {
        find: "",
        replace: ""
      }
    ]
  };

  onChange = items => {
    this.props.onChange(items);
    this.setState({ items });
  };

  render() {
    const { url } = this.props;
    const { items } = this.state;

    const columns = [
      {
        key: "find",
        dataIndex: "find",
        render: (find, _, index) => (
          <Input
            placeholder="Find"
            value={find}
            onChange={e => {
              items[index].find = e.target.value;
              this.onChange(items);
            }}
          />
        )
      },
      {
        key: "replace",
        dataIndex: "replace",
        render: (replace, _, index) => (
          <Input
            placeholder="Replace"
            value={replace}
            onChange={e => {
              items[index].replace = e.target.value;
              this.onChange(items);
            }}
          />
        )
      },
      {
        key: "action",
        render: (_, __, index) =>
          items.length > 1 && (
            <DeleteOutlined
              onClick={e => {
                items.splice(index, 1);
                this.onChange([...items]);
              }}
            />
          )
      }
    ];
    return (
      <Popover
        title="Find and Replace"
        content={
          <Space direction="vertical" style={{ width: "100%" }}>
            <Table
              title={false}
              showHeader={false}
              className="w-full"
              columns={columns}
              dataSource={items}
              pagination={false}
              rowKey={(_, idx) => idx}
            />
            <Button
              onClick={() => {
                this.setState({
                  items: [
                    ...items,
                    {
                      find: "",
                      replace: ""
                    }
                  ]
                });
              }}
            >
              Add New
            </Button>
          </Space>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button disabled={!url}>
          <SearchOutlined />
        </Button>
      </Popover>
    );
  }
}
