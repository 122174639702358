import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Carousel, Modal } from "antd";
import styled from "styled-components";
import { CustomLinkPolaris } from "./CustomLinkPolaris";

const Container = styled.div`
  display: flex !important;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto;
  }
`;
const SlideContainer = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .ant-carousel .slick-dots li button {
    background: #1a202c;
  }
  .p-target {
    text-align: right;
    margin-top: 20px;
    a {
      color: #00bfff;
    }
  }
`;
class ProductThumbnailImage extends Component {
  state = {
    showModal: false,
    showModalSimilarProducts: false,
    etsyReverseImages: false,
  };
  render() {
    const { product, isSpecial, isSimilarProducts } = this.props; //limit
    if (product && product.source && "etsy" === product.source.toLowerCase()) {
      if (
        Array.isArray(product.images) &&
        product.images.length > 2 &&
        !this.state.etsyReverseImages
      ) {
        let arrayLength = product.images.length;
        let lastItem = product.images[arrayLength - 1];
        product.images.pop();
        product.images.unshift(lastItem);
        this.setState({ etsyReverseImages: true });
      }
    }
    let images = [];
    let mainFileId = null;
    if (product.mainImage) {
      images = [{ file: product.mainImage }];
      mainFileId = product.mainImage.id;
    }
    if (product.images) {
      images = [
        ...images,
        ...product.images.filter((f) =>
          f.file ? f.file.id !== mainFileId : f.id !== mainFileId
        ),
      ];
    }
    let imageSrc = "";
    if (isSpecial) {
      if (images[0] && images[0].file) {
        let file = images[0].file;
        imageSrc = file.thumbnailUrl ? file.thumbnailUrl : file.url;
      }
    } else if (images[0] && images[0].file && images[0].file.thumbnailUrl) {
      imageSrc = images[0].file.thumbnailUrl;
    } else if (images[0] && images[0].file && images[0].file.url) {
      imageSrc = images[0].file.url;
    }
    return (
      <>
        <div>
          <Avatar
            style={
              isSpecial || isSimilarProducts
                ? {
                    margin: 5,
                    cursor: "pointer",
                  }
                : { margin: 5 }
            }
            onClick={() =>
              isSpecial
                ? this.setState({ showModal: true })
                : null || isSimilarProducts
                ? this.setState({ showModalSimilarProducts: true })
                : null
            }
            shape="square"
            key={`product-${product.id}`}
            size={200}
            src={imageSrc}
            alt={""}
          />
        </div>
        <Modal
          bodyStyle={{ textAlign: "center" }}
          footer={null}
          onCancel={() => this.setState({ showModal: false })}
          title={product.title}
          visible={this.state.showModal}
        >
          <SlideContainer>
            <Carousel>
              {images.map((image, index) => {
                let imageUrl = "";
                if (isSpecial && image && image.url) {
                  imageUrl = image.url;
                } else if (image && image.file && image.file.url) {
                  imageUrl = image.file.url;
                }

                const href =
                  isSpecial && image && image.file && image.file.url
                    ? image.file.url
                    : null;
                return (
                  <div key={`slide-${index}`}>
                    <Container key={`slide-${index}`}>
                      <img
                        style={{ height: "500px", width: "auto" }}
                        src={imageUrl}
                        alt={""}
                      />
                    </Container>
                    <div className="p-target">
                      <CustomLinkPolaris href={href} file={image.file}>
                        View origin image
                      </CustomLinkPolaris>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </SlideContainer>
        </Modal>
        <Modal
          visible={this.state.showModalSimilarProducts}
          footer={null}
          onCancel={() => this.setState({ showModalSimilarProducts: false })}
          bodyStyle={{ textAlign: "center" }}
          centered
          width="70%"
        >
          <SlideContainer>
            <Carousel>
              {images.map((image, index) => {
                let imageUrl = "";
                if (isSpecial && image && image.url) {
                  imageUrl = image.url;
                } else if (image && image.file && image.file.url) {
                  imageUrl = image.file.url;
                }
                return (
                  <div key={`slide-${index}`}>
                    <Container key={`slide-${index}`}>
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={imageUrl}
                        alt={""}
                      />
                    </Container>
                  </div>
                );
              })}
            </Carousel>
          </SlideContainer>
        </Modal>
      </>
    );
  }
}

ProductThumbnailImage.propTypes = {
  product: PropTypes.object,
  limit: PropTypes.number,
  isSpecial: PropTypes.bool,
};

export default ProductThumbnailImage;
