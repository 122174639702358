import { Layout, Stack } from "@shopify/polaris";
import React from "react";
import {
  ReportError,
  ReportErrorFromMerchize,
  ReportErrorWithoutFFOrSupplier,
  ReportOnHold,
  ReportPOrPD,
} from "../../order/components";
import { ReportOtherDoneCancelledDenied } from "../../tasks/components/ReportOtherDoneCancelledDenied";
import { ReportSubmitError } from "../../tracking/components";
import { useDashboard } from "../dashboard/context";

export function DevStatistics() {
  const { filter } = useDashboard();
  return (
    <Layout>
      <Layout.Section oneHalf>
        <Stack vertical>
          <ReportOtherDoneCancelledDenied filter={filter} />
          <ReportSubmitError filter={filter} />
          <ReportOnHold filter={filter} />
        </Stack>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Stack vertical>
          <ReportPOrPD filter={filter} />
          <ReportError filter={filter} />
          <ReportErrorFromMerchize filter={filter} />
          <ReportErrorWithoutFFOrSupplier filter={filter} />
        </Stack>
      </Layout.Section>
    </Layout>
  );
}
