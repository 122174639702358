import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { Apps } from "../../components/app/apps";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AppsPagePolaris = (props) => {
  const path = props.location.search;
  return (
    <Container>
      <Page title="All Apps" fullWidth>
        <Apps
          status={
            path.includes("status") ? props.location.search.includes(0) : null
          }
        />
      </Page>
    </Container>
  );
};
