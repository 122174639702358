import React, { Component } from "react";
import { Skeleton, Select } from "antd";
import { Query } from "@apollo/react-components";
import { handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const { Option } = Select;

class ProductSelect extends Component {
  render() {
    const { value } = this.props;
    return (
      <Query query={LIST_SELLER_PRODUCTS_QUERY}>
        {({ error, loading, data }) => {
          if (error) return <div>Error: {handleError(error.toString())}</div>;
          if (loading) return <Skeleton active />;
          return (
            <Select
              value={value}
              className="w-40 mr-2"
              onChange={(value) => this.props.onChange(value)}
              placeholder="Filter by Product"
            >
              {data.products.nodes.map((product) => {
                return (
                  <Option key={product.id} value={product.id}>
                    {product.title}
                  </Option>
                );
              })}
              <Option value="all">All</Option>
            </Select>
          );
        }}
      </Query>
    );
  }
}

export default ProductSelect;
