import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CLAIM_FILTER } from "../../../constants";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { FiltersOrderPolaris } from "../../order/FiltersOrderPolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { ClaimsFilterPolaris } from "./ClaimsFilterPolaris";
import { ClaimsTableDataPolaris } from "./ClaimsTableDataPolaris";

export const LOAD_CLAIMS = gql`
  query claims($filter: ClaimFilter) {
    claims(filter: $filter) {
      total
      nodes {
        id
        order {
          id
          store {
            id
            title
          }
        }
        orderId
        approveBy {
          id
          firstName
          lastName
        }
        approveById
        authorId
        author {
          id
          firstName
          lastName
        }
        type
        comment
        status
        files {
          id
          url
          thumbnailUrl
        }
        createdAt
        rejectReason
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status"];

export const ClaimsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageAdminClaims")) || 20,
    offset: 0,
    status: null,
    search: null,
    type: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(LOAD_CLAIMS, {
    variables: {
      filter,
    },
  });

  // Lifecycle
  useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <ClaimsFilterPolaris
          filter={filter}
          onChange={({ search }) => {
            setFilter((prev) => {
              if (!isEqual(prev.search, search)) {
                return {
                  ...prev,
                  search,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        <FiltersOrderPolaris
          value={CLAIM_FILTER}
          defaultValue={filter.status}
          onChange={({ status }) => {
            setFilter((prev) => {
              if (!isEqual(prev.status, status)) {
                return {
                  ...prev,
                  status,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data &&
          data.claims &&
          data.claims.nodes &&
          data.claims.nodes.length > 0 ? (
          <ClaimsTableDataPolaris
            data={data}
            refetch={refetch}
            filter={filter}
            setFilter={(offset, limit) => {
              setFilter((prev) => {
                if (prev.offset !== offset || prev.limit !== limit) {
                  return {
                    ...prev,
                    offset,
                    limit,
                  };
                }
                return prev;
              });
              setCookie("perPageAdminClaims", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
