import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { BULK_DELETE_STORE } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";
import { useSelectRowsCtx } from "../SelectRowsProvider";

export default function BulkDeleteStore({ open, onClose }) {
  const { toggleToast, setNotify } = useToastContext();
  const { ids, onCompleted } = useSelectRowsCtx();

  const [deleteStores, { loading }] = useMutation(BULK_DELETE_STORE, {
    onCompleted: () => {
      setNotify({ msg: "Delete stores success", err: false });
      onCompleted && onCompleted();
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!ids.length) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ err: false, msg: null });
    deleteStores({
      variables: {
        ids,
      },
    }).finally(() => {
      onClose();
    });
  }, [ids, deleteStores, toggleToast, setNotify]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Delete Stores"
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to delete stores?</p>
    </Modal>
  );
}
