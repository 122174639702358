import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Loading,
  FormLayout,
  TextField,
  Button,
  Spinner,
  Toast,
  ButtonGroup,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { size, filter, values } from "lodash";
import styled from "styled-components";

import { handleError } from "../../../helper";
import history from "../../../history";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { AutoCompletePolaris } from "../../shared/AutoCompletePolaris";

const Container = styled.div`
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const LIST_SUPPLIER = gql`
  query suppliers {
    suppliers {
      id
      firstName
      lastName
    }
  }
`;

const CREATE_DEPOSIT = gql`
  mutation createDeposit($input: NewDeposit!) {
    createDeposit(input: $input) {
      id
      amount
      journal {
        id
        refId
        comment
        status
      }
      createdAt
      account {
        id
        firstName
        lastName
      }
      creator {
        id
        firstName
        lastName
      }
    }
  }
`;

const FIELD_REQUIRED = {
  amount: "Amount is required",
  supplierId: "Supplier is required",
};

export const AddDepositPolaris = () => {
  const [basicFields, setBasicFields] = useState({
    amount: null,
    refId: null,
    comment: null,
    supplierId: null,
  });
  const [suppliers, setSuppliers] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [errors, setErrors] = useState({});

  // Query
  const { data, loading, error } = useQuery(LIST_SUPPLIER);

  // Mutation
  const [createDeposit, { loading: loadingM }] = useMutation(CREATE_DEPOSIT, {
    onCompleted: () => {
      setNotification({
        message: "Create deposit successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  // Get data
  useEffect(() => {
    let newData =
      data?.suppliers?.length > 0
        ? data.suppliers.map((s) => ({
            value: s.id,
            label: `${s.firstName} ${s.lastName}`,
          }))
        : [];
    setSuppliers(newData);
  }, [data]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle actions
  const handleRedirect = useCallback(() => {
    history.push("/admin/billings/deposits");
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  const validateField = useCallback((value, id) => {
    let error = null;
    let newMessage;
    for (let [key, message] of Object.entries(FIELD_REQUIRED)) {
      if ([key].includes(key)) {
        newMessage = message;
      }
    }
    if (!value || !value.length) {
      error = newMessage;
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);

  const handleInputChange = useCallback((value, id) => {
    setBasicFields((prev) => ({
      ...prev,
      [id]: value,
    }));
    validateField(value, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(() => {
    for (let [key] of Object.entries(FIELD_REQUIRED)) {
      validateField(basicFields[key], key);
    }

    const { amount, supplierId } = basicFields;

    const count = size(filter(values(errors), (e) => e !== null));
    if (amount && supplierId && supplierId.length && count === 0) {
      setNotification({
        message: null,
        isError: false,
      });
      createDeposit({
        variables: {
          input: basicFields,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, basicFields]);

  // Markup
  const loadingMarkup = loading && <Loading />;
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <ComponentLabelPolaris label="Amount" required />
              <TextField
                type="number"
                min={0}
                value={basicFields["amount"]}
                onChange={handleInputChange}
                id="amount"
                error={errors.amount}
                placeholder="Enter amount"
              />
            </div>
            <div>
              <ComponentLabelPolaris label="Payoneer transaction ID" />
              <TextField
                value={basicFields["refId"]}
                onChange={handleInputChange}
                id="refId"
                placeholder="Enter Payoneer transaction"
              />
            </div>
          </FormLayout.Group>
          <div>
            <ComponentLabelPolaris label="Supplier" required />
            {loading ? (
              <Spinner size="small" />
            ) : error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : (
              <AutoCompletePolaris
                options={suppliers}
                labelHidden
                placeholder="Choose supplier"
                onChange={(value) => handleInputChange(value, "supplierId")}
                error={errors["supplierId"]}
              />
            )}
          </div>

          <TextField
            label="Message"
            multiline={3}
            value={basicFields["comment"]}
            id="comment"
            onChange={handleInputChange}
          />
          <div className="btn-wrap">
            <ButtonGroup>
              <Button children="Cancel" onClick={handleRedirect} />
              <Button
                children="Submit"
                primary
                onClick={handleSubmit}
                loading={loadingM}
              />
            </ButtonGroup>
          </div>
        </FormLayout>
      </Card>
    </Container>
  );
};
