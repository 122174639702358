// import logo from "../assets/images/mblogo_dark.png";
import logo from "../assets/images/mb_dark_logo.png";

export const theme = {
  colors: {
    topBar: {
      background: "#fff",
    },
  },
  logo: {
    width: 140,
    topBarSource: logo,
    url: "/",
  },
};
