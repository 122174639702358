import React, { useState } from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { handleError, setCookie, getCookie } from "../../../helper";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { TableProductsIgnoredPolaris } from "./TableProductsIgnoredPolaris";
// import { FilterProductIgnoredPolaris } from "./FilterProductIgnoredPolaris";

const QUERY_PRODUCT_IGNORED = gql`
  query getProductsIgnored($filter: ProductIgnoredFilter) {
    getProductsIgnored(filter: $filter) {
      total
      nodes {
        id
        title
        productBases {
          id
        }
        collections {
          id
          name
        }
        tags {
          id
          name
        }
        images {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
        divisions {
          id
          ignored
          reason
          used
        }
      }
    }
  }
`;

export const ProductsIgnoredPolaris = () => {
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductsIgnored")) || 20,
    offset: 0,
    search: null,
  });
  const { data, loading, error } = useQuery(QUERY_PRODUCT_IGNORED, {
    variables: filter,
    fetchPolicy: "cache-and-network",
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      {/* <Card sectioned>
        <FilterProductIgnoredPolaris
          filter={filter}
          onChange={({ search }) => setFilter((prev) => ({ ...prev, search }))}
        />
      </Card> */}
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : (
          <>
            {error ? (
              <div style={{ padding: "1.6rem" }}>
                Error: {handleError(error.toString())}
              </div>
            ) : (
              <>
                {data &&
                data.getProductsIgnored &&
                data.getProductsIgnored.nodes &&
                data.getProductsIgnored.nodes.length > 0 ? (
                  <TableProductsIgnoredPolaris
                    data={data.getProductsIgnored.nodes}
                    total={
                      data.getProductsIgnored.total
                        ? Number(data.getProductsIgnored.total)
                        : 0
                    }
                    filter={filter}
                    setFilter={(offset, limit) => {
                      setFilter((prevState) => ({
                        ...prevState,
                        offset,
                        limit,
                      }));
                      setCookie("perPageProductsIgnored", limit, 100);
                    }}
                  />
                ) : (
                  <EmptyStatePolaris />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </React.Fragment>
  );
};
