import React, { useCallback, useEffect, useState } from "react";
import { DataTable, Thumbnail, Badge, Button, Tooltip } from "@shopify/polaris";
import { get, capitalize } from "lodash";
import styled from "styled-components";

import noItem from "../../../assets/images/unnamed.jpeg";

import { ColActionsPolaris } from "./ColActionsPolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { CircleMinusMinor, CirclePlusMinor } from "@shopify/polaris-icons";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }

  .condition-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.5rem;
  }
`;

const IdWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  padding-left: ${({ level }) => `${level * 1.5}rem`};
  .expaned-wrap {
    margin-right: 0.5rem;
  }
`;

export const TableCollectionsPolaris = ({
  data,
  filter,
  setFilter,
  currentParam,
}) => {
  // State
  const [rows, setRows] = useState([]);

  // Get data
  const { offset, limit } = filter;

  const [currentParent, setCurrentParent] = useState({});
  const handleCurrentParentChange = useCallback(
    (parent) => () => {
      setCurrentParent((prev) => ({
        ...prev,
        [parent]: !prev[parent],
      }));
    },
    [],
  );

  useEffect(() => {
    const nodes = data?.collections?.nodes || [];
    // const dataSliced = nodes.slice(offset, offset + limit);
    const newData = buildCollections(nodes);

    const newRows = newData.map((c) => {
      const { image, name, id, automated, conditions, children, parent } =
        c || {};

      const hasChild = children?.length > 0;
      if (parent && !!!currentParent[parent.id]) return [];

      const idMarkup = (
        <IdWrapper level={c?.level || 0}>
          {hasChild ? (
            <div className="expaned-wrap">
              <Button
                plain
                icon={!!currentParent[id] ? CircleMinusMinor : CirclePlusMinor}
                onClick={handleCurrentParentChange(id)}
              />
            </div>
          ) : (
            <div style={{ width: "2.5rem" }}></div>
          )}
          <span>{id}</span>
        </IdWrapper>
      );

      const imageMarkup = (
        <Thumbnail
          source={image ? image.url : noItem}
          alt={image ? image.name : ""}
        />
      );

      const nameMarkup = <span>{name}</span>;
      const actionsMarkup = (
        <ColActionsPolaris
          collection={c}
          filter={filter}
          currentParam={currentParam}
        />
      );

      const visibilityStatus = c?.private ? "success" : "info";
      const visibilityTxt = c?.private ? "Private" : "Public";
      const visibilityMarkup = (
        <div className="status-wrap">
          {c?.private != null && (
            <Badge children={visibilityTxt} status={visibilityStatus} />
          )}
        </div>
      );

      // Automated
      const automatedMarkup = (
        <div className="automated-wrap">
          {automated && conditions?.length > 0
            ? conditions.map((c, idx) => {
                const { field, value, operator } = c || {};
                return (
                  <div key={`condition-${idx}`} className="condition-inner">
                    <span>{idx + 1}.</span>
                    <strong>{capitalize(field || "")}</strong>
                    <span>{operator}</span>
                    <strong>{value}</strong>
                  </div>
                );
              })
            : null}
        </div>
      );

      const countMarkup = (
        <Tooltip content="Count of product used">
          <Badge status="info">{c?.count || 0}</Badge>
        </Tooltip>
      );

      return [
        idMarkup,
        imageMarkup,
        nameMarkup,
        visibilityMarkup,
        automatedMarkup,
        countMarkup,
        actionsMarkup,
      ];
    });

    setRows(newRows);
  }, [data, handleCurrentParentChange, currentParent]);

  useEffect(() => {
    const nodes = data?.collections?.nodes || [];
    const init = initCurrentParent(nodes);
    setCurrentParent(init);
  }, [data]);

  const total = get(data, "collections.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "ID",
          "Image",
          "Name",
          "Visibility",
          "Automated Condition",
          "Count",
          "Actions",
        ]}
        rows={rows}
        verticalAlign={"middle"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};

function buildCollections(data, level = 0) {
  if (!data || data.length === 0) return [];

  const res = [];
  for (let item of data) {
    let { __typename, ...rest } = item;
    rest.level = level;

    res.push(rest);
    if (rest.children?.length > 0) {
      res.push(...buildCollections(rest.children, level + 1));
    }
  }

  return res;
}

function initCurrentParent(nodes) {
  if (!nodes || nodes.length === 0) return [];

  const res = {};
  for (let item of nodes) {
    if (item?.parent?.id) {
      res[item.parent.id] = true;
    }

    if (item.children?.length > 0) {
      Object.assign(res, { ...initCurrentParent(item.children) });
    }
  }
  return res;
}
