import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";

class SellerAddProductBasePage extends Component {
  render() {
    return (
      <div>
        <PageTitle subTitle={"Product Bases"} title={"Add Product Base"} />
      </div>
    );
  }
}

export default SellerAddProductBasePage;
