import {
  Administrator,
  ORDER_STATUS,
  TASK_STATUS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../../variable";

export const SCREEN_OPTIONS = [
  { value: "id", label: "ID", priority: 0 },
  { value: "orderID", label: "Order ID", priority: 5 },
  {
    value: "originId",
    label: "Origin Order ID",
    priority: 6,
  },
  { value: "platform", label: "Platform", priority: 10 },
  {
    value: "store",
    label: "Store",
    priority: 10,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.IdeaLeader,
    ],
  },
  { value: "product", label: "Product", priority: 15 },
  { value: "personalizedData", label: "Personalized Data", priority: 16 },
  {
    value: "defaultKPI",
    label: "Default KPI",
    priority: 20,
    roles: [Administrator, USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.IdeaLeader,
    ],
  },
  { value: "kpi", label: "KPI", priority: 25 },
  { value: "createdAt", label: "Created at", priority: 30 },
  {
    value: "assignDate",
    label: "Assign Date",
    priority: 35,
  },
  {
    value: "doneDate",
    label: "Done Date",
    priority: 35,
    conditional: ({ status }) => [TASK_STATUS.Done].includes(status),
  },
  {
    value: "originalProduct",
    label: "Original Product",
    priority: 36,
  },
  { value: "deadline", label: "Deadline", priority: 40 },
  {
    value: "taskResources",
    label: "Task Resource",
    priority: 45,
  },
  {
    value: "designResourceUrls",
    label: "Design Resource Urls",
    priority: 46,
  },
  {
    value: "artistFiels",
    label: "Artist Files",
    priority: 47,
  },
  {
    value: "orderStatus",
    label: "Order status",
    priority: 50,
    roles: [USER_ROLE.Seller],
    teamRoles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
      TEAM_ROLE.IdeaLeader,
    ],
    conditional: ({ status }) =>
      [ORDER_STATUS.PendingDesign].includes(status) || status == null,
  },
  {
    value: "status",
    label: "Status",
    priority: 55,
  },
  { value: "artists", label: "Artists", priority: 60 },
];

export const idsSorttedByPriority = (optionValues) => {
  const optsSortted = SCREEN_OPTIONS.sort((a, b) => a.priority - b.priority)
    .map((item) => item.value)
    .filter(Boolean);

  const res = [];
  for (const opt of optsSortted) {
    if (optionValues.includes(opt)) res.push(opt);
  }

  return res;
};

export const MANGOTEEPRINTS_CATALOG_TYPE = ["US1", "TIKTOK", "FASTUS"];
