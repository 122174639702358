import React from 'react';
import { Page } from '@shopify/polaris';
import styled from 'styled-components';

import { ManualOrder } from '../../components/order/ManualOrder';

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export function CreateManualOrderPagePolaris() {
    return (
        <Container>
            <Page title='Create Manual Order' fullWidth>
                <ManualOrder />
            </Page>
        </Container>
    );
}
