import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Avatar,
  TextField,
  Button,
  Modal,
  Icon,
  Toast,
  Spinner,
} from "@shopify/polaris";
import {
  LinkMinor,
  ImageMajorMonotone,
  CancelSmallMinor,
  MobileVerticalDotsMajorTwotone,
} from "@shopify/polaris-icons";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { isEqual } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { AppContext } from "../../../context";
import { TEAM_ROLE, USER_ROLE } from "../../../variable";
import { handleError } from "../../../helper";
import { GET_COMMENTS } from "../../../graphql/queries";
import {
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
} from "../../../graphql/mutations";

import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import useToggle from "../../../hooks/useToggle";
import { gql } from "apollo-boost";

const Container = styled.div`
  .created_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin-top: 1rem;
  }
  .input_wrap {
    flex: 1 1;
    position: relative;
    transition-duration: 85ms;
    transition-timing-function: ease;
    transition-property: padding-bottom;
    padding-bottom: ${(props) => (props.isFocus ? "56px" : "")};
    box-shadow: ${(props) =>
      props.isFocus
        ? "0 4px 8px -2px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08)"
        : "0 1px 2px -1px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08)"};
    background-color: #fff;
    border-radius: 3px;
    transition: box-shadow 85ms ease;
    .Polaris-TextField__Backdrop {
      border: none;
      &::after {
        border: none;
      }
    }
    .actions_wrap {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 2.3rem);
      position: absolute;
      bottom: 8px;
      left: 12px;
      transition-duration: 85ms;
      transition-timing-function: ease;
      transition-property: opacity, transform;
      opacity: ${(props) => (props.isFocus ? "1" : "0")};
      transform: ${(props) =>
        props.isFocus ? "translateY(0)" : "translateY(48px)"};
    }
  }
  .list-comment {
    margin-top: 2rem;
    z-index: 100;
    display: flex;
    flex-flow: column;
    row-gap: 0.5rem;
    .author-item {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      align-items: flex-start;
      .user_wrap {
        display: flex;
        column-gap: 1rem;
        align-items: center;
        .time_wrap {
          font-size: 13px;
        }
      }
      .avatar_wrap {
        margin-top: 0.5rem;
      }
      .action_wrap {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        .ant-btn {
          padding: 0;
          font-size: 13px;
        }
      }
      .info_wrap {
        width: 100%;
        .content_wrap {
          width: 100%;
          position: relative;
          padding: 6px 10px;
          display: inline-block;
          border-radius: 3px;
          box-shadow: 0 1px 2px -1px rgba(9, 30, 66, 0.25),
            0 0 0 1px rgba(9, 30, 66, 0.08);
          background-color: #fff;
          margin: 0.5rem 0;
          transition-duration: 85ms;
          transition-timing-function: ease;
          transition-property: padding-bottom;
          background-color: #fff;
          transition: box-shadow 85ms ease;
          &.edit {
            width: 100%;
            padding-bottom: 45px;
            box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
              0 0 0 1px rgba(9, 30, 66, 0.08);
            .actions_wrap {
              display: flex;
              opacity: 1;
              transform: translateY(0);
            }
          }
          .Polaris-TextField__Backdrop {
            border: none;
            box-shadow: none;
            &::after {
              box-shadow: none;
              border: none;
            }
          }
          .actions_wrap {
            display: none;
            justify-content: space-between;
            width: calc(100% - 2.3rem);
            position: absolute;
            bottom: 8px;
            left: 12px;
            transition-duration: 85ms;
            transition-timing-function: ease;
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(48px);
            .close-btn {
              border: none;
              box-shadow: none;
              height: 25px;
            }
          }
          .show-img {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            padding: 0.5rem 0 1rem 0.5rem;
            .Polaris-Icon {
              margin: 0;
            }
          }
          .content_inner {
            display: flex;
            .Polaris-Button {
              padding: 1rem 0 0 0;
              min-width: 2.5rem;
              min-height: 2.5rem;
            }
            .reject-reason {
              margin-top: 0.5rem;
              h4 {
                font-weight: 500;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
  .Polaris-Button--plain.Polaris-Button--iconOnly:focus,
  .Polaris-Button--plain.Polaris-Button--iconOnly:active {
    background: transparent;
  }
`;

const ModalContainer = styled.div`
  .list-file_wrap {
    width: 150px;
    height: 150px;
    .file_wrap {
      height: 100%;
    }
  }
`;

const SLASH_PT = /\|/;

export const CommentsPolaris = ({ taskId, hideTitle }) => {
  // Context
  const { currentUser } = useContext(AppContext);
  const currentUserId = currentUser?.id;
  const firstName = currentUser?.firstName?.trim();
  const lastName = currentUser?.lastName?.trim();
  const charAtF = firstName?.charAt(0);
  const charAtL = lastName?.charAt(0);
  const userRole = currentUser?.roles;
  const teamRole = currentUser?.teamUser?.role;

  let haveAddComment = false;
  let isAdmin = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    if (
      [
        TEAM_ROLE.DesignLeader,
        TEAM_ROLE.Designer,
        TEAM_ROLE.Admin,
        TEAM_ROLE.MarketplaceManager,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.Idea,
        TEAM_ROLE.IdeaLeader,
      ].includes(teamRole)
    ) {
      haveAddComment = true;
    }
    if ([TEAM_ROLE.Admin].includes(teamRole)) {
      isAdmin = true;
    }
  }

  // State
  const [filter, setFilter] = useState({
    limit: 4,
    offset: 0,
  });
  const [isFocus, setIsFocus] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [commentState, setCommentState] = useState({});
  const [loadingUpdate, setLoadingUpdate] = useState({});
  const [loadingDelete, setLoadingDelete] = useState({});
  // const [openModal, setOpenModal] = useState(false);
  const [openModal, toggleShowModal] = useToggle(false);
  const [file, setFile] = useState([]);
  const [showFile, setShowFile] = useState({});
  const [fileModal, setFileModal] = useState({});
  const [deleteState, setDeleteState] = useState({});
  const [showReject, setShowReject] = useState({});
  // const [activeToast, setActiveToast] = useState(false);
  const [activeToast, toggleActive] = useToggle(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Query
  const [getComments, { data, loading, error }] = useLazyQuery(GET_COMMENTS);

  // Mutation
  const [createComment, { loading: loadingM, client }] = useMutation(
    CREATE_COMMENT,
    {
      onCompleted: (res) => {
        let newItem = res?.createComment;
        if (newItem) {
          const variables = {
            filter: {
              ...filter,
              taskId,
            },
          };
          const cache = client.readQuery({
            query: GET_COMMENTS,
            variables: variables,
          });
          client.writeQuery({
            query: GET_COMMENTS,
            variables: variables,
            data: {
              ...cache,
              getComments: {
                ...cache.getComments,
                total: cache.getComments.total + 1,
                nodes: [newItem, ...cache.getComments.nodes],
              },
            },
          });
          setComments((prev) => [newItem, ...prev]);
        }
        setComment("");
        setIsFocus(false);
        setFile([]);
        setNotification({
          message: "Add comment successfully.",
          isError: false,
        });
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          isError: true,
        });
      },
    }
  );
  const [updateComment] = useMutation(UPDATE_COMMENT, {
    onCompleted: (res) => {
      let newItem = res?.updateComment;
      let id = newItem?.id;
      if (newItem) {
        const variables = {
          filter: {
            ...filter,
            taskId,
          },
        };
        const cache = client.readQuery({
          query: GET_COMMENTS,
          variables: variables,
        });
        client.writeQuery({
          query: GET_COMMENTS,
          variables: variables,
          data: {
            ...cache,
            getComments: {
              ...cache.getComments,
              nodes: cache.getComments.nodes.map((c) => {
                if (c.id === newItem?.id) {
                  c.content = newItem?.content;
                }
                return c;
              }),
            },
          },
        });
        setLoadingUpdate((prev) => ({ ...prev, [id]: false }));
        setCommentState((prev) => ({ ...prev, [id]: false }));
      }
    },
    onError: () => {},
  });

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onError: () => {},
  });

  useEffect(() => {
    if (taskId) {
      getComments({
        variables: {
          filter: {
            ...filter,
            taskId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, filter]);

  useEffect(() => {
    let newData = data?.getComments?.nodes;
    if (newData && newData.length) {
      setComments(newData);
    }
  }, [data]);

  // Handle actions
  const handleCreateComment = useCallback(() => {
    let files = file?.length > 0 ? file.map((f) => f.id) : [];
    setNotification({
      message: null,
      isError: false,
    });
    if (taskId) {
      createComment({
        variables: {
          input: {
            content: comment,
            taskId,
            files,
          },
        },
      });
    }
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, taskId, file]);

  const handleUpdateComment = useCallback((id, value, files = []) => {
    setLoadingUpdate((prev) => ({ ...prev, [id]: true }));
    let fileIds = files.length > 0 ? files.map((f) => f.id) : [];
    if (id) {
      updateComment({
        variables: {
          input: {
            content: value,
            id,
            files: fileIds,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteComment = useCallback(
    (id) => {
      setLoadingDelete((prev) => ({ ...prev, [id]: true }));
      if (id) {
        deleteComment({
          variables: {
            id,
          },
        })
          .then(() => {
            const variables = {
              filter: {
                ...filter,
                taskId,
              },
            };
            const cache = client.readQuery({
              query: GET_COMMENTS,
              variables: variables,
            });
            client.writeQuery({
              query: GET_COMMENTS,
              variables: variables,
              data: {
                ...cache,
                getComments: {
                  ...cache.getComments,
                  total: cache.getComments.total - 1,
                  nodes: cache.getComments.nodes.filter((c) => c.id !== id),
                },
              },
            });
            setLoadingDelete((prev) => ({ ...prev, [id]: false }));
          })
          .catch(() => {
            setLoadingDelete((prev) => ({ ...prev, [id]: false }));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, taskId]
  );

  const toggleEditComment = useCallback((id) => {
    setCommentState((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  // const toggleShowModal = useCallback(() => setOpenModal((prev) => !prev), []);
  const toggleFileModal = useCallback((id) => {
    setFileModal((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);
  const toggleDeleteModal = useCallback((id) => {
    setDeleteState((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);
  // const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  const handlePagination = useCallback((offset) => {
    setFilter((prev) => ({ ...prev, offset }));
  }, []);

  // Show notification
  const toastMarkup = activeToast
    ? notification?.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Variables
  let disabledSave = true;
  if (comment || file.length) {
    disabledSave = false;
  }

  let total = data?.getComments.total;
  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handlePagination,
  };

  return (
    <Container isFocus={isFocus}>
      {toastMarkup}
      {!hideTitle && <h3>Comments:</h3>}
      {haveAddComment ? (
        <div
          className="created_wrap"
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        >
          <div>
            <Avatar initials={charAtF || charAtL} size={"small"} />
          </div>
          <div className="input_wrap">
            <TextField
              value={comment}
              onChange={(value) => setComment(value)}
              placeholder="Write a comment..."
            />
            <div className="actions_wrap">
              <Button
                children="Save"
                disabled={disabledSave}
                primary
                onClick={() => {
                  handleCreateComment();
                  setIsFocus(true);
                }}
                loading={loadingM}
              />
              <Button
                icon={LinkMinor}
                plain
                onClick={() => {
                  toggleShowModal();
                  setIsFocus(true);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {loading ? (
        <SkeletonPagePolaris sizeSmall />
      ) : error ? (
        <div>Error: {handleError(error.toString())}</div>
      ) : comments && comments.length > 0 ? (
        <div className="list-comment_wrap">
          <div className="list-comment">
            {comments.map((c, index) => {
              let id = c?.id;
              let user = c?.author;
              let userId = user?.id;
              let firstName = user?.firstName;
              let lastName = user?.lastName;
              let content = c?.content;
              let createdAt = c?.createdAt;
              let updatedAt = c?.updatedAt;
              let createdAtMarkup = createdAt
                ? moment(createdAt).fromNow()
                : null;
              let file = c?.files && c?.files.length && c?.files[0];
              let filesName = file && file.name ? file.name : "";
              let src = file && (file.thumbnailUrl || file.url);

              let rejectReason = c?.rejectReason;
              let fullName = "";
              if (user) {
                fullName = `${firstName} ${lastName}`;
              } else if (["Bot"].includes(c?.authorId)) {
                fullName = c?.authorId;
              }

              const hasFileId = SLASH_PT.test(rejectReason);
              let fileIds = ""
              if (hasFileId) {
                ([rejectReason, fileIds] = rejectReason.split(SLASH_PT));
              }

              return (
                <div className="author-item" key={index}>
                  <div className="avatar_wrap">
                    <Avatar
                      initials={
                        firstName?.trim()?.charAt(0) ||
                        lastName?.trim()?.charAt(0)
                      }
                      size="small"
                    />
                  </div>
                  <div className="info_wrap">
                    <div className="user_wrap">
                      <strong>{fullName}</strong>
                      <i className="time_wrap">{`${createdAtMarkup}${
                        !isEqual(createdAt, updatedAt) ? " (edited)." : "."
                      }`}</i>
                    </div>
                    <div
                      className={`content_wrap ${
                        !!commentState[id] ? "edit" : ""
                      }`}
                    >
                      {!!commentState[id] ? (
                        <>
                          <TextField
                            multiline={2}
                            value={content}
                            onChange={(value) => {
                              let newComments = comments;
                              newComments[index].content = value;
                              setComments(() => [...newComments]);
                            }}
                            autoFocus
                            placeholder="Enter content"
                          />
                          {file ? (
                            <div className="show-img">
                              <Icon source={ImageMajorMonotone} />
                              <p>{filesName}</p>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="content_inner">
                          <div>
                            {/* <p>{content}</p> */}
                            <div>
                              <p>{content}</p>
                              {hasFileId ? (
                                <ReasonFile fileIds={fileIds} />
                              ) : null}
                            </div>
                            <p
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setShowFile(() => ({
                                  [id]: file,
                                }))
                              }
                            >
                              {filesName}
                            </p>
                            {!!showReject[id] ? (
                              <div className="reject-reason">
                                <h4>Reject reason:</h4>
                                <span>{rejectReason}</span>
                              </div>
                            ) : null}
                          </div>
                          {rejectReason ? (
                            <div>
                              <Button
                                plain
                                icon={MobileVerticalDotsMajorTwotone}
                                onClick={() =>
                                  setShowReject((prev) => ({
                                    ...prev,
                                    [id]: !prev[id],
                                  }))
                                }
                              />
                            </div>
                          ) : null}
                          <Modal
                            open={!!showFile[id]}
                            onClose={() =>
                              setShowFile(() => ({
                                [id]: false,
                              }))
                            }
                            title="File image"
                            sectioned
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={src}
                                alt=""
                                style={{
                                  width: "auto",
                                  height: "auto",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </Modal>
                        </div>
                      )}
                      {!!commentState[id] ? (
                        <div className="actions_wrap">
                          <div
                            style={{
                              display: "flex",
                              columnGap: "1rem",
                            }}
                          >
                            <Button
                              children="Save"
                              primary
                              onClick={() =>
                                handleUpdateComment(id, content, c.files)
                              }
                              loading={!!loadingUpdate[id]}
                            />
                            <Button
                              icon={CancelSmallMinor}
                              onClick={() => toggleEditComment(id)}
                              plain
                            />
                          </div>
                          <Button
                            icon={LinkMinor}
                            plain
                            onClick={() => toggleFileModal(id)}
                          />
                          <Modal
                            open={!!fileModal[id]}
                            onClose={() => toggleFileModal(id)}
                            title="File comment"
                            primaryAction={{
                              content: "Done",
                              onAction: () => toggleFileModal(id),
                            }}
                            sectioned
                          >
                            <ModalContainer>
                              <MediaSelectorButtonPolaris
                                accept={"image/*"}
                                value={c?.files}
                                multiple={false}
                                singleUpload={true}
                                isUploadDesign={true}
                                sizeSmall
                                onChange={(files) => {
                                  let newComments = comments;
                                  newComments[index].files = files;
                                }}
                              />
                            </ModalContainer>
                          </Modal>
                        </div>
                      ) : null}
                    </div>
                    {([currentUserId].includes(userId) || isAdmin) && (
                      <div className="action_wrap">
                        <Button
                          children="Edit"
                          onClick={() => toggleEditComment(id)}
                          plain
                        />
                        <Button
                          children="Delete"
                          destructive
                          plain
                          onClick={() => toggleDeleteModal(id)}
                        />
                        <Modal
                          title={`Delete comment`}
                          open={!!deleteState[id]}
                          onClose={() => toggleDeleteModal(id)}
                          sectioned
                          secondaryActions={[
                            {
                              content: "Cancel",
                              onAction: () => toggleDeleteModal(id),
                            },
                          ]}
                          primaryAction={{
                            content: "Delete",
                            onAction: () => handleDeleteComment(id),
                            loading: !!loadingDelete[id],
                          }}
                        >
                          <p>Are you sure to delete this comment?</p>
                        </Modal>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-8">
            <PaginationPolaris aggregation={aggregation} />
          </div>
        </div>
      ) : null}
      <Modal
        open={openModal}
        title={"Attach file"}
        onClose={() => {
          toggleShowModal();
          setIsFocus(() => false);
        }}
        sectioned
        primaryAction={{
          content: "Done",
          onAction: () => {
            toggleShowModal();
            setIsFocus(() => true);
          },
        }}
      >
        <ModalContainer>
          <MediaSelectorButtonPolaris
            accept={"image/*"}
            multiple={false}
            isUpdateDesign
            sizeSmall
            onChange={(file) => {
              setFile(() => file);
            }}
          />
        </ModalContainer>
      </Modal>
    </Container>
  );
};

const GET_FILE_BY_ID = gql`
  query fileById($id: ID!) {
    fileById(id: $id) {
      id
      thumbnailUrl
      url
    }
  }
`;

function ReasonFile({ fileIds }) {
  const idsSplitted = (fileIds || "").split(/\,/);

  // State
  const [fileInfos, setFileInfos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let fileInfos = [];
    (async function () {
      setLoading(true);
      const { __apolloClient__: client } = window;
      fileInfos = await Promise.allSettled(
        (idsSplitted || []).map((id) =>
          client.query({
            query: GET_FILE_BY_ID,
            variables: {
              id,
            },
          })
        )
      ).then((res) => {
        const fileInfos = (res || [])
          .filter(({ status }) => status === "fulfilled")
          .map(({ value }) => {
            const {
              data: { fileById },
            } = value || {};
            const { url } = fileById || {};
            const [href] = (url || "").split(/\?/);
            return {
              href,
              url,
            };
          });

        return fileInfos;
      });
      setFileInfos(fileInfos);
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Spinner size="small" />
  ) : fileInfos?.length > 0 ? (
    <FileWrapper className="url-wrap">
      {fileInfos.map(({ href, url }, index) => (
        <li key={`file-${href}-${index}`}>
          <a target="_blank" children={href} href={url} />
        </li>
      ))}
    </FileWrapper>
  ) : null;
}

const FileWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    word-break: break-word;
  }
`;
