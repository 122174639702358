import React, { useState, useCallback, useEffect, useContext } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { AppContext } from "../context";
import { EditProfileFormPolaris } from "./shared/EditProfileFormPolaris";
import history from "../history";

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: EditProfile!) {
    updateProfile(input: $input) {
      id
      firstName
      lastName
      email
      roles
      phone
      status
      address
      avatar {
        id
        name
        url
      }
      teamUser {
        id
        team {
          id
        }
        role
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const EditProfilePolaris = () => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const { setCurrentUser, currentUser } = useContext(AppContext);

  const [
    updateProfile,
    { data: dataMutation, loading: loadingMutaion, error: errorMutation },
  ] = useMutation(UPDATE_PROFILE, {
    onCompleted: (res) => {
      const id = setTimeout(() => {
        setCurrentUser(res.updateProfile);
        history.push(`/`);
      }, 2500);
      setTimeoutId(id);
    },
    onError: () => {},
  });

  const handleSubmit = useCallback((input) => {
    updateProfile({
      variables: {
        input,
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastMarkup = activeToast
    ? (errorMutation || dataMutation) && (
        <Toast
          content={
            errorMutation ? errorMutation : "Your profile has been saved."
          }
          error={errorMutation}
          duration={5000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page title="Edit Profile" breadcrumbs={[{ content: "Home", url: "/" }]}>
        <EditProfileFormPolaris
          data={currentUser}
          onSubmit={handleSubmit}
          loading={loadingMutaion}
        />
      </Page>
    </Container>
  );
};
