import React, { useCallback, useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { ACCEPT_DESIGN } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";

export const AcceptDesignPolaris = (props) => {
    // Props
    const {
        open,
        toggleShowModal,
        refetch,
        toggleActive,
        onNotificationChange,
        task,
        toggleModalParent,
    } = props;
    // Block body scroll
    // useLockBodyScroll(open);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [acceptDesign, { loading }] = useMutation(ACCEPT_DESIGN, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: `Design has been approved.`,
                    isError: false,
                });
            }
            if (toggleModalParent) {
                toggleModalParent();
            }

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
    });

    // Handle actions
    const handleSubmit = useCallback(() => {
        let id = task?.id;
        if (id) {
            onNotificationChange &&
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            toggleActive && toggleActive();
            acceptDesign({
                variables: {
                    id,
                },
            });
        }
    }, [task, acceptDesign, onNotificationChange, toggleActive]);

    return (
        <Modal
            title="Accept design confirmation"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>
                Are you sure want to accept this design for order #
                {task.orderId}
            </p>
        </Modal>
    );
};
