import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/react-components";
import { Button, Modal, TextContainer } from "@shopify/polaris";
import { gql } from "apollo-boost";

class DeleteEtsyButton extends Component {
  state = {
    visible: false,
    loading: false,
  };
  render() {
    const { store, refetch, open, onClose } = this.props;
    return (
      <Mutation
        mutation={gql`
          mutation deleteStore($id: ID!) {
            deleteStore(id: $id)
          }
        `}
      >
        {(deleteStore) => (
          <React.Fragment>
            {/* <Button
              onClick={() => {
                this.setState({ visible: true });
              }}
              size={"slim"}
              plain
              destructive
            >
              Delete
            </Button> */}
            <Modal
              // open={visible}
              // onClose={() => {
              //   this.setState({ visible: false });
              // }}
              open={open}
              onClose={onClose}
              title="Delete Etsy Account confirmation"
              primaryAction={{
                content: "Delete",
                loading: this.state.loading,
                onAction: () => {
                  this.setState({ loading: true })
                  deleteStore({
                    variables: {
                      id: store.id,
                    },
                  }).then(() => {
                    if (refetch) {
                      refetch();
                    }
                  }).finally(() => {
                    this.setState({ loading: false })
                    onClose && onClose();
                  });
                },
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  // onAction: () => {
                  //   this.setState({ visible: false });
                  // },
                  onAction: onClose
                },
              ]}
            >
              <Modal.Section>
                <TextContainer>
                  <p>
                    Are you sure want to delete <strong>{store.title}</strong>
                  </p>
                </TextContainer>
              </Modal.Section>
            </Modal>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

DeleteEtsyButton.propTypes = {
  store: PropTypes.any,
  refetch: PropTypes.any,
};

export default DeleteEtsyButton;
