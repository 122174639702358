import React, { Component } from "react";
import { Button, Table, Tag } from "antd";
import { Card } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import styled from "styled-components";
import { handleError } from "../../helper";
import { Link } from "react-router-dom";
import PageTitle from "../shared/PageTitle";
import image from "../../assets/images/unnamed.jpeg";
import _ from "lodash";
import DeleteProductBase from "./DeleteProductBase";

import { FilterProductBasePolaris } from "./FilterProductBasePolaris";

const Container = styled.div`
  .header {
    margin-bottom: 20px;
  }
  h1 {
    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }
  .add-btn {
    text-align: end;
  }
`;

export const LIST_PRODUCT_BASE = gql`
  query productBases($filter: ProductBaseFilter) {
    productBases(filter: $filter) {
      total
      nodes {
        id
        title
        baseCostDescription
        images {
          id
          name
          url
          thumbnailUrl
        }
        suppliers {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

class ListProductBase extends Component {
  state = {
    filter: {
      limit: 50,
      offset: 0,
      search: null,
    },
    page: 1,
    selectedRows: [],
    selectedRowKeys: [],
  };

  render() {
    const { filter } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows, selectedRowKeys });
      },
    };

    const columns = [
      {
        title: "Image",
        key: "image",
        width: 100,
        render: (_, record) => {
          const link =
            record.length > 1
              ? "/admin/product-bases"
              : "/admin/product-bases/edit";
          return (
            <Link to={`${link}/${record.id}`}>
              {record.images && record.images.length ? (
                <img
                  src={
                    record && record.images && record.images.length
                      ? record.images[0].thumbnailUrl
                      : null
                  }
                  alt={""}
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                />
              ) : (
                <img
                  alt={""}
                  src={image}
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                />
              )}
            </Link>
          );
        },
      },
      {
        title: "Product Base",
        dataIndex: "title",
        key: "product",
        width: 300,
        render: (title, record) => {
          const link =
            record.length > 1
              ? "/admin/product-bases"
              : "/admin/product-bases/edit";
          return <Link to={`${link}/${record.id}`}>{title}</Link>;
        },
      },
      {
        title: "Prices",
        key: "price",
        dataIndex: "baseCostDescription",
        width: 150,
      },
      {
        title: "Supplier Cost",
        key: "cost",
        width: 200,
        render: (_, record) => {
          let mapSupplierById = {};
          if (record.suppliers && record.suppliers.length) {
            for (let i = 0; i < record.suppliers.length; i++) {
              mapSupplierById[record.suppliers[i].id] = record.suppliers[i];
            }
          }

          return (
            <div>
              {record.supplierPricing &&
                record.supplierPricing.length > 0 &&
                record.supplierPricing.map((sp, index) => {
                  const supplier = mapSupplierById[sp.userId];
                  if (!supplier) {
                    return null;
                  }
                  return (
                    <div key={index}>
                      {`${supplier.firstName} ${supplier.lastName}`}: $
                      {sp.price}
                    </div>
                  );
                })}
            </div>
          );
        },
      },
      {
        title: "Supplier",
        key: "supplier",
        width: 150,
        render: (_, record) =>
          record.suppliers && record.suppliers.length
            ? record.suppliers.map((supplier) => (
                <Tag color="blue" key={supplier.id}>
                  {supplier.firstName} {supplier.lastName}
                </Tag>
              ))
            : null,
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container>
        <PageTitle
          title={"Product Bases"}
          action={
            <Link to={"/admin/product-bases/add"}>
              <Button type="primary">Add Base</Button>
            </Link>
          }
        />
        <Card sectioned>
          <FilterProductBasePolaris
            search={filter.search}
            onChange={({ search }) => {
              this.setState({
                filter: {
                  ...filter,
                  offset: 0,
                  search,
                },
              });
            }}
          />
        </Card>
        <Card>
          {this.state.selectedRows.length ? (
            <div className="p-4">
              <Button>{`${this.state.selectedRowKeys.length} Items selected`}</Button>
              <DeleteProductBase
                ids={this.state.selectedRows.map((selected) => selected.id)}
                onCompleted={() => {
                  this.setState({ selectedRows: [], selectedRowKeys: [] });
                }}
                refetch={this.refetch}
              />
            </div>
          ) : null}
          <Query
            query={LIST_PRODUCT_BASE}
            variables={{ filter }}
            fetchPolicy={"cache-and-network"}
          >
            {({ error, loading, data, refetch }) => {
              /*if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );*/
              if (error)
                return <div>Error: {handleError(error.toString())}</div>;
              const dataSource = data ? data.productBases.nodes : [];
              const total = data ? data.productBases.total : 0;
              this.refetch = refetch;
              return (
                <Table
                  loading={loading}
                  pagination={{
                    pageSize: filter.limit,
                    total,
                    current: this.state.page,
                    onShowSizeChange: (_, size) => {
                      this.setState({
                        currentPage: 1,
                        filter: {
                          ...filter,
                          limit: size,
                          offset: 0,
                        },
                      });
                    },
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  columns={columns}
                  rowKey={(_, index, text) => index}
                  scroll={{ x: tableWidth }}
                />
              );
            }}
          </Query>
        </Card>
      </Container>
    );
  }
}

export default ListProductBase;
