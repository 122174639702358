import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  img {
    width: 100%;
  }
  .guide-image {
    text-align: center;
    margin: 30px 0;
  }
  .api-quick-guide h2,
  .api-quick-guide h3,
  .api-quick-guide h4 {
    color: #222;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .api-quick-guide p {
    margin-bottom: 10px;
  }
  .api-quick-guide h2 {
    font-size: 22px;
  }
  .api-quick-guide h3 {
    font-size: 20px;
  }
  .api-quick-guide h4 {
    font-size: 18px;
  }
`;

export default class QuickGuideApi extends Component {
  render() {
    return (
      <Container>
        <div className="api-quick-guide">
          <h3>1. API Keys cho Venus Addon</h3>
          <h4>1.1. Mục đích của Venus Addon</h4>
          <div className="guide-section">
            <p>
              <b>Venus Addon</b> là một chrome addon giúp lấy thông tin sản phẩm
              bao gồm: Tiêu đề và mockup từ <b>Google Shopping</b> gửi về{" "}
              <b>Product Assortment</b> ở <b>MerchBridge</b>
            </p>
          </div>
          <h4>1.2. Hướng dẫn cài đặt</h4>
          <div className="guide-section">
            <p>
              <b>Bước 1:</b> Download addon tại địa chỉ:{" "}
              <a
                href="https://github.com/khactien98/chrome-extension-venus"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://github.com/khactien98/chrome-extension-venus
              </a>
            </p>
            <p>
              Click vào <b>Code</b> và chọn <b>Download ZIP</b>
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/download-zip-file.png"
              alt=""
            />
            <p>
              <b>Bước 2:</b> Giải nén tập tin vừa download, mở{" "}
              <b>Google Chrome</b> vào cài đặt chọn <b>Extensions</b>
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/setting-extensions.png"
              alt=""
            />
            <p>
              <b>Bước 3:</b> Bật <b>Developer mode</b>, rồi chọn{" "}
              <b>Load unpacked</b>
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/load-addon.png"
              alt=""
            />
            <p>
              Chọn đến folder vừa giản nén và chọn <b>Select Folder</b>
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/addon-location.png"
              alt=""
            />
          </div>
          <h4>1.3. Hướng dẫn sử dụng</h4>
          <div className="guide-section">
            <p>
              Click vào biểu tượng trên thanh công cụ của <b>Google Chrome</b>{" "}
              nhập <b>API Key</b> rồi chọn <b>Submit</b>.
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/add-api-key.png"
              alt=""
            />
            <p>
              Vào Google Search từ khóa muốn tìm kiếm, chọn vào phần{" "}
              <b>Shopping</b>
            </p>
            <p>
              Tìm sản phẩm cần thêm vào <b>MerchBridge</b> rồi click vào
              Checkbox.
            </p>
            <p>
              Sau khi chọn xong, nhập niche cho sản phẩm rồi chọn{" "}
              <b>Push Product</b> để thực thi công việc.
            </p>
            <img
              className="guide-image"
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://pmmedia.nyc3.digitaloceanspaces.com/guides/push-data.png"
              alt=""
            />
          </div>
        </div>
      </Container>
    );
  }
}
