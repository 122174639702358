import React, { useState, useEffect } from "react";
import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const QUERY_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
        email
      }
    }
  }
`;

export const AccountsSelectPolaris = (props) => {
  const {
    onChange,
    errorField,
    value,
    stores: storesProps,
    useProductDivision,
  } = props;
  // State
  const [stores, setStores] = useState([]);

  // Query
  const { data } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        limit: 1000,
        offset: 0,
        platformSelected: ["amazon"],
      },
    },
    skip: useProductDivision,
  });

  // Format data
  useEffect(() => {
    let nodes = storesProps || [];
    if (!useProductDivision) {
      nodes = data?.stores?.nodes || [];
    }

    let newData =
      nodes?.length > 0
        ? nodes.map((s) => {
            let label = s.title + (s.email ? ` (${s.email})` : "");
            return {
              value: s.id,
              label,
            };
          })
        : [];
    setStores(newData);
  }, [storesProps, useProductDivision, data]);

  // useEffect(() => {
  //   let newData =
  //     data && data.stores && data.stores.nodes && data.stores.nodes.length > 0
  //       ? data.stores.nodes.map((s) => {
  //           let label = s.title + (s.email ? ` (${s.email})` : "");
  //           return {
  //             value: s.id,
  //             label,
  //           };
  //         })
  //       : [];
  //   setStores(newData);
  // }, [data]);

  return (
    <>
      <AutoCompletePolaris
        options={stores}
        label={useProductDivision ? "Products are divided for" : "Choose store"}
        placeholder="Filter item"
        onChange={onChange}
        required
        value={value}
        error={errorField}
      />
    </>
  );
};
