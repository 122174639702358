import React, { useEffect, useRef, useState } from "react";
import { buttonFrom, Modal, Stack } from "@shopify/polaris";

import { elementContains } from "../../../../helper";
import { CreateIdeaProvider } from "../context/createIdea";
import useToggle from "../../../../hooks/useToggle";

export function ModalComp({
    open,
    title,
    primaryAction,
    handleClose,
    children,
    domId,
    isUpdate,
    setProductChecked,
    isClone,
    setChanged,
    secondaryActions,
}) {
    // State
    const [errorDimensions, setErrorDimensions] = useState({});
    const [canContinue, setCanContinue] = useState(true);
    const [forceUpdate, toggleForceUpdate] = useToggle(false);
    const openRef = useRef(open);
    openRef.current = open;

    useEffect(() => {
        setTimeout(() => {
            const domI = document.querySelector(`#${domId}`);
            if (domI?.style) {
                domI.style.height = "100%";
            }

            const modalCls = ".Polaris-Modal-Dialog__Modal";
            const domParents = document.querySelectorAll(modalCls);

            for (const domP of domParents) {
                if (elementContains(domP, domI)) {
                    domP.setAttribute(
                        "style",
                        "max-width: calc(100% - 5rem); max-height: calc(100vh - 5rem); height: calc(100vh)"
                    );
                }
            }
        }, 0);
    });

    const footerMarkup = (
        <Stack>
            <Stack.Item fill>
                <Stack>
                    {buttonFrom({ content: "Close", onAction: handleClose })}
                    {Array.isArray(secondaryActions) &&
                    secondaryActions.length > 0
                        ? secondaryActions.map((act, index) => {
                              const { overrides = {}, ...props } = act || {};
                              overrides.disabled = !canContinue;
                              return buttonFrom(
                                  { ...props },
                                  overrides,
                                  `secondary-action-${index}`
                              );
                          })
                        : null}
                </Stack>
            </Stack.Item>
            {Array.isArray(primaryAction) && primaryAction.length > 0
                ? primaryAction.length > 1
                    ? primaryAction.map((act, index) => {
                          const { overrides = {}, ...props } = act || {};
                          overrides.disabled = !canContinue;
                          return buttonFrom(
                              { ...props },
                              overrides,
                              `action-${index}`
                          );
                      })
                    : buttonFrom(
                          { ...primaryAction[0] },
                          { primary: true, disabled: !canContinue }
                      )
                : null}
        </Stack>
    );

    useEffect(() => {
        const handleKeyUp = (event) => {
            const { keyCode } = event || {};

            // Escape
            if (keyCode === 27) {
                if (openRef.current) {
                    event.stopImmediatePropagation();
                }
            }
        };

        document.addEventListener("keyup", handleKeyUp, { capture: false });

        return () => {
            document.addEventListener("keyup", handleKeyUp, { capture: false });
        };
    }, []);

    return (
        <Modal
            title={title}
            large
            open={open}
            onClose={handleClose}
            footer={footerMarkup}
        >
            <div id={domId}>
                <CreateIdeaProvider
                    errorDimensions={errorDimensions}
                    setErrorDimensions={setErrorDimensions}
                    isUpdate={isUpdate}
                    setProductChecked={setProductChecked}
                    isClone={isClone}
                    setChanged={setChanged}
                    setCanContinue={setCanContinue}
                    canContinue={canContinue}
                    forceUpdate={forceUpdate}
                    toggleForceUpdate={toggleForceUpdate}
                >
                    {children}
                </CreateIdeaProvider>
            </div>
        </Modal>
    );
}
