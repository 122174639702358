import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Labelled,
    TextStyle,
    Stack,
    Checkbox,
    Spinner,
    TextField,
    Button,
    Modal,
    RangeSlider,
    Heading,
} from "@shopify/polaris";
import styled from "styled-components";

import { MediaSelectorButton2Polaris } from "../../../file/MediaSelectorButton2Polaris";
import { useCreateProduct } from "./context";
import { ImageTypes } from "../../../../variable";
import useToggle from "../../../../hooks/useToggle";
import { elementContains } from "../../../../helper";
import { Approve } from "../../../tasks/idea/components/actions/Approve";

export function PrintFileComp({ base, setErrorDimensions }) {
    // Context
    const {
        state: { selectedBases },
        updateState,
    } = useCreateProduct();
    const selectedBasesRef = useRef(selectedBases || []);

    // Handle actions
    useEffect(() => {
        selectedBasesRef.current = selectedBases;
    }, [selectedBases]);

    const toggleChecked = useCallback(
        (value) => {
            const newBases = updateBases([...selectedBases], base, {
                key: "useDesign",
                value,
            });
            updateState({ selectedBases: newBases });
        },
        [selectedBases, updateState, base]
    );
    const toggleRandomTemplate = useCallback(
        (value) => {
            const newBases = updateBases([...selectedBases], base, {
                key: "isRandomTemplate",
                value,
            });
            updateState({ selectedBases: newBases });
        },
        [selectedBases, updateState, base]
    );

    const handleTemplateQuantityChange = useCallback(
        (value) => {
            const newBases = updateBases([...selectedBases], base, {
                key: "templateQuantity",
                value,
            });
            updateState({ selectedBases: newBases });
        },
        [selectedBases, updateState, base]
    );

    // dpId: design position id
    const handleFileChange = useCallback(
        (value, dpId) => {
            const fieldValue =
                base.designPositions?.length > 0
                    ? base.designPositions.map((dp) => {
                          if (dp.id === dpId) {
                              dp.file = value;
                          }

                          return dp;
                      })
                    : null;

            const selectedBases = selectedBasesRef.current;
            const newBase = updateBases([...selectedBases], base, {
                key: "designPositions",
                value: fieldValue,
            });

            updateState({ selectedBases: newBase });
        },
        [base, updateState]
    );

    // Markup
    const checked = base?.useDesign;
    const isRandomTemplate = base?.isRandomTemplate;
    const templateQuantity = base?.templateQuantity;

    const designPositions = base?.designPositions || [];
    const targetId = base?.id;

    return (
        <Stack vertical spacing="tight">
            <TextStyle variation="strong" children="Print files" />
            <Checkbox
                label="Only use these files for generating mockups"
                checked={checked}
                onChange={toggleChecked}
            />
            <Checkbox
                label="Using random mockup template(s)?"
                checked={isRandomTemplate}
                onChange={toggleRandomTemplate}
            />
            {isRandomTemplate && (
                <TextField
                    onChange={handleTemplateQuantityChange}
                    label="Number of random template(s)"
                    placeholder="Enter template quantity"
                    type="number"
                    min="1"
                    value={templateQuantity || "3"}
                />
            )}
            {/* <Wrapper className="dp-wrap">{designPositionMarkup}</Wrapper> */}
            <DesignPosition
                checked={checked}
                setErrorDimensions={setErrorDimensions}
                handleFileChange={handleFileChange}
                designPositions={designPositions}
                targetId={targetId}
            />
        </Stack>
    );
}

export function DesignPosition({
    checked,
    setErrorDimensions,
    handleFileChange: handleFileChangeProp,
    designPositions,
    targetId,
    hideBtnRemove,
    isAdminSeller = false,
    showArtGuide = false,
    approveProps,
}) {
    // Ref
    const designIdRef = useRef({});
    const updateFileRef = useRef(null);
    const dimensionsRef = useRef(null);

    // State
    const [checkingDimensions, setCheckingDimensions] = useState({});
    const [dimensions, setDimensions] = useState({});

    // Actions
    // Check dimension image
    const handleCheckDimensions = useCallback(
        (dimensions, idx, dpId) => {
            const designId = designIdRef.current;
            if (Object.keys(designId).length) {
                for (let [key, value] of Object.entries(dimensions)) {
                    if (key === designId[idx]) {
                        let isRealDimensionOk =
                            value && value.isRealDimensionOk;
                        let message = isRealDimensionOk
                            ? "You can upload this file."
                            : "The dimensions of file uploaded is not correct.";

                        dimensionsRef.current &&
                            clearTimeout(dimensionsRef.current);
                        dimensionsRef.current = setTimeout(() => {
                            setDimensions((prev) => ({
                                ...prev,
                                [idx]: {
                                    message,
                                    type: isRealDimensionOk
                                        ? "success"
                                        : "error",
                                },
                            }));
                            setErrorDimensions((prev) => ({
                                ...prev,
                                [`${targetId}-${dpId}`]: isRealDimensionOk
                                    ? null
                                    : message,
                            }));
                        }, 100);
                    }
                }
            }
        },
        [setErrorDimensions, targetId]
    );
    const handleCheckingDimensions = useCallback((value, index) => {
        setCheckingDimensions((prev) => ({ ...prev, [index]: value }));
    }, []);

    // dpId: design position id
    const handleFileChange = useCallback(
        (value, dpId, index) => {
            const [first] = value;
            const id = first?.id;
            designIdRef.current = {
                ...designIdRef.current,
                [index]: id != null ? id : null,
            };

            // if `value` is empty => remove error message.
            if (!value || value.length === 0) {
                setErrorDimensions((prev) => {
                    return {
                        ...prev,
                        [`${targetId}-${dpId}`]: null,
                    };
                });
                setDimensions((prev) => {
                    return {
                        ...prev,
                        [index]: null,
                    };
                });
            }

            updateFileRef.current && clearTimeout(updateFileRef.current);
            updateFileRef.current = setTimeout(() => {
                handleFileChangeProp(value, dpId, index);
            }, 100);
        },
        [handleFileChangeProp, targetId, setErrorDimensions]
    );

    useEffect(() => {
        if (checked) {
            setCheckingDimensions({});
            setDimensions({});
            designIdRef.current = {};

            const pattern = new RegExp(targetId);
            setErrorDimensions((prev) => {
                const removedTargetId = Object.keys(prev).reduce((acc, cur) => {
                    if (pattern.test(cur)) {
                        return acc;
                    }

                    return { ...acc, [cur]: prev[cur] };
                }, {});

                return removedTargetId;
            });
        }
    }, [checked, setErrorDimensions, targetId]);

    return (
        <Wrapper className="dp-wrap">
            {designPositions?.length > 0
                ? [...designPositions]
                      .filter((dp) => (isAdminSeller ? dp?.file != null : true))
                      .map((dp, index) => {
                          if (dp == null) return null;
                          const dpId = dp.id;
                          const value = dp.file?.length > 0 ? dp.file : [];
                          const description = dp.description;

                          const checkingDimension = checkingDimensions[index];
                          const message =
                              value?.length > 0
                                  ? dimensions[index]?.message
                                  : "";
                          const typeSuccess =
                              dimensions[index]?.type === "success";
                          const msgCls = `message-wrap ${
                              typeSuccess ? "success" : "error"
                          }`;

                          return (
                              <div className="dp-inner" key={`dp-${index}`}>
                                  <Labelled label={dp.name} />
                                  <MediaSelectorButton2Polaris
                                      //   prefix="print_files/"
                                      multiple={false}
                                      singleUpload={true}
                                      accept={"image/*"}
                                      value={value}
                                      onChange={(newValue) =>
                                          handleFileChange(
                                              newValue,
                                              dpId,
                                              index
                                          )
                                      }
                                      // Check dimensions
                                      isCompareDesign={!checked}
                                      isUploadDesign
                                      designPosition={dp}
                                      onCheckDimensions={(value) =>
                                          handleCheckDimensions(
                                              value,
                                              index,
                                              dpId
                                          )
                                      }
                                      onCheckingDimensions={(value) =>
                                          handleCheckingDimensions(value, index)
                                      }
                                      hideBtnRemove={hideBtnRemove}
                                  />
                                  {!checked && (
                                      <div className="design-position-info">
                                          {checkingDimension ? (
                                              <Spinner size="small" />
                                          ) : message ? (
                                              <p className={msgCls}>
                                                  {message}
                                              </p>
                                          ) : null}
                                          {description && (
                                              <span>{`(${description})`}</span>
                                          )}
                                      </div>
                                  )}
                                  {showArtGuide && (
                                      <div className="artwork-guide">
                                          <CompareArtGuide
                                              designPosition={dp}
                                              approveProps={approveProps}
                                          />
                                      </div>
                                  )}
                              </div>
                          );
                      })
                : null}
        </Wrapper>
    );
}

function CompareArtGuide({ designPosition, approveProps }) {
    // Props
    const { artworkGuidelines, file } = designPosition || {};
    const artworkGuide = (artworkGuidelines || [])
        .filter((i) => i.file)
        .find((i) => ImageTypes.includes(i.file.mimeType));
    const artworkGuideUrl = artworkGuide?.file?.url || "";

    const image = (file || []).filter((i) => i?.url)[0];
    const imageUrl = image?.url || "";

    // State
    const [open, toggleOpen] = useToggle(false);
    const [input, setInput] = useState(100);

    // Action
    const handleInputChange = useCallback((value) => setInput(value), []);

    useEffect(() => {
        const domCls = "compare-art-guide";
        const modalCls = ".Polaris-Modal-Dialog__Modal";

        const domChild = document.querySelector(`#${domCls}`);
        const domParents = document.querySelectorAll(modalCls);

        for (const domP of domParents) {
            if (elementContains(domP, domChild)) {
                domP.setAttribute(
                    "style",
                    "max-width: 100%; max-height: calc(100vh - 5rem); height: calc(100vh)"
                );
            }
        }

        let domContent = document.querySelector("#content-wrap");
        let domImg = document.querySelector("#imgs-wrap");
        if (domContent?.offsetHeight && domImg) {
            let height = domContent.offsetHeight;
            domImg.style.padding = `${height}px 0 0`;
        }
    });

    return (
        imageUrl &&
        artworkGuideUrl && (
            <>
                <Button
                    children="Compare with artguide"
                    onClick={toggleOpen}
                    size="slim"
                    primary
                />
                <Modal
                    title="Compare with artguide"
                    open={open}
                    onClose={toggleOpen}
                    sectioned
                >
                    <CompareWrapper id="compare-art-guide">
                        <div className="content-wrap" id="content-wrap">
                            <div className="header-wrap">
                                <div className="header">
                                    <Heading element={"h3"}>
                                        Compare with artguide
                                    </Heading>
                                </div>
                                <div className="change-opacity">
                                    <div className="change-opacity-inner">
                                        <RangeSlider
                                            label={"Change opacity"}
                                            min={1}
                                            max={100}
                                            onChange={handleInputChange}
                                            value={input}
                                        />
                                    </div>
                                </div>
                                <Approve
                                    {...approveProps}
                                    onClose={toggleOpen}
                                />
                            </div>
                        </div>

                        <div className="imgs-wrap" id="imgs-wrap">
                            <div style={{ position: "relative" }}>
                                <div>
                                    <img
                                        alt=""
                                        src={artworkGuideUrl}
                                        style={{
                                            position: "static",
                                            opacity: "0.25",
                                            zIndex: 0,
                                        }}
                                        className="img-item"
                                    />
                                </div>
                                <img
                                    src={imageUrl}
                                    alt=""
                                    style={{
                                        opacity: `${input * 0.01}`,
                                        position: "absolute",
                                        zIndex: 1,
                                        top: 0,
                                    }}
                                    className="img-item"
                                />
                            </div>
                        </div>
                    </CompareWrapper>
                </Modal>
            </>
        )
    );
}

export function updateBases(bases = [], baseTarget, { key, value }) {
    if (bases?.length === 0) return [];
    const result = bases.map((base) => {
        if (base.id === baseTarget.id) {
            base[key] = value;
        }
        return base;
    });

    return result;
}

const CompareWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;

    .content-wrap {
        position: fixed;
        background: #fff;
        width: calc(100% - 4rem);
        padding-top: 2rem;
        padding-bottom: 2rem;
        z-index: 10;
        margin-top: -2rem;
    }

    .header-wrap {
        display: flex;
        flex-direction: row;
        column-gap: 3rem;
        align-items: center;

        .header h2 {
            font-size: 1.2em;
        }

        .change-opacity {
            flex: 1 1;
            display: flex;
            justify-content: flex-end;

            h3 {
                margin-left: 6px;
            }

            .change-opacity-inner {
                width: 300px;
            }
        }
    }
    .imgs-wrap {
        position: relative;

        .img-item {
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 8900px;
            max-height: 10000px;
        }
    }
`;

const Wrapper = styled.div`
    .design-position-info {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        word-break: break-word;
        margin-top: 1.5rem;
        row-gap: 1rem;
    }

    .artwork-guide {
        margin-top: 0.5rem;

        .Polaris-Button {
            padding-inline: 0.5rem;
        }
    }

    .message-wrap {
        &.success {
            color: #2abd0e;
        }
        &.error {
            color: #bd3b2f;
        }
    }
`;
