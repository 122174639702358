import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getTokenObject } from "../../../../../client";
import {
    GEN_MOCKUP_FOR_IDEA_TASK,
    UPDATE_PSD_FILE_TASK_BASE_GROUP,
} from "../../../../../graphql/mutations";
import { genStrToBtoA, handleError } from "../../../../../helper";
import useToggle from "../../../../../hooks/useToggle";
import { useToastContext } from "../../../../shared/ToastContext";
import { useCreateIdea } from "../../context/createIdea";
import { TASK_STATUS } from "../../utils";
import { LivePreviewMockup } from "./LivePreviewMockup";
import { genInput as genInputUpdatePsd } from "./UpdatePsd";

const Approved = "Approved";

export function SubmitToReview2({
    item,
    onCloseParent,
    isFileGenerating,
    ...props
}) {
    // Props
    const ideaTaskId = item?.id;
    const onCloseRef = useRef(null);

    // State
    const [open, toggleOpen] = useToggle(isFileGenerating || false);
    const [openReview, toggleOpenReview] = useToggle(props.open);
    const [taskBaseGroups, setTaskBaseGroups] = useState(undefined);
    const [onlyCustomMockup, setOnlyCustomMockup] = useState(false);

    // Context
    const {
        products,
        errorDimensions,
        updateProduct,
        setErrorDimensions,
    } = useCreateIdea();
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [genMockupForIdeaTask, { loading }] = useMutation(
        GEN_MOCKUP_FOR_IDEA_TASK,
        {
            onCompleted: (res) => {
                setNotify({
                    msg:
                        "Submit to generate mockup for this task successfully.",
                    err: false,
                });
                const data = res?.genMockupForIdeaTask;

                if (data != null) {
                    // check if have `mockups` => open review
                    const taskBaseGroups = getBaseGroupsHaveSub(
                        data.taskBaseGroups
                    );

                    if (taskBaseGroups.length > 0 && !onlyCustomMockup) {
                        toggleOpenReview();
                        toggleOpen(true);
                        setTaskBaseGroups(taskBaseGroups);
                    } else {
                        onCloseParent && onCloseParent();

                        // onClose parent modal
                        onCloseRef.current && clearTimeout(onCloseRef.current);
                        onCloseRef.current = setTimeout(() => {
                            refetch && refetch();
                            setFilter && setFilter((prev) => ({ ...prev }));
                        }, 1000);
                    }
                }
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );
    const [updatePsdFile] = useMutation(UPDATE_PSD_FILE_TASK_BASE_GROUP, {
        onError: () => {},
    });

    // Get data
    useEffect(() => {
        toggleOpenReview(props.open);
    }, [props.open]);

    useEffect(() => {
        if (isFileGenerating && item != null) {
            const taskBaseGroups = getBaseGroupsHaveSub(item.taskBaseGroups);
            if (taskBaseGroups?.length > 0) {
                toggleOpen(isFileGenerating);
                setTaskBaseGroups(taskBaseGroups);
            }
        }
    }, [isFileGenerating, item]);

    // Actions
    // Toggle open modal resubmit to review
    const handleReviewClose = useCallback(() => {
        toggleOpenReview(false);
        props.onClose && props.onClose();
    }, [props.onClose]);

    const handleBackToDetail = useCallback(() => {
        toggleOpen(false);
        props.onClose && props.onClose(false);
    }, [toggleOpen]);

    // Handle resubmit action
    const handleResubmit = useCallback(() => {
        const cloneProducts = [...products];
        for (let i = 0; i < cloneProducts.length; i++) {
            const product = cloneProducts[i];
            if (product.status === Approved) {
                continue;
            }

            let cloneProduct = clearFileDesign(product);
            updateProduct(i, cloneProduct);
        }

        toggleOpen(false);
        handleReviewClose(false);
        setErrorDimensions({});
    }, [
        products,
        updateProduct,
        toggleOpen,
        props.onClose,
        setErrorDimensions,
        refetch,
    ]);

    // Submit send need review
    const noError = Object.values(errorDimensions).every((i) => i == null);
    const handleSubmit = useCallback(async () => {
        toggleToast && toggleToast(true);
        setNotify({ msg: null, err: false });

        const newInput = genInputUpdatePsd(products);
        const inputValid = (newInput || []).filter(
            (i) => i?.psdFiles?.length > 0 || i?.psd
        );
        if (inputValid.length > 0) {
            await updatePsdFile({ variables: { input: inputValid } });
        }

        const designs = genInput(products);
        const canSubmit = designs.some((d) => d?.baseConfigs?.length > 0);
        if (!canSubmit) {
            setNotify({
                msg: "Each product must have least at one file mockup.",
                err: true,
            });
            handleReviewClose();
            return;
        }

        // Check if only custom mockup => close preview design.
        const onlyCustomMockup = designs.every((d) => {
            const { baseConfigs } = d || {};
            return (baseConfigs || []).every((b) => b.isCustomMockup);
        });
        setOnlyCustomMockup(onlyCustomMockup);

        if (noError) {
            genMockupForIdeaTask({
                variables: {
                    input: {
                        ideaTaskId,
                        designs,
                    },
                },
            });
        }
    }, [
        products,
        ideaTaskId,
        setNotify,
        toggleToast,
        noError,
        handleReviewClose,
        genMockupForIdeaTask,
        updatePsdFile,
    ]);

    return (
        <>
            <Modal
                title="Submit to generate mockup"
                sectioned
                secondaryActions={[
                    { content: "Close", onAction: handleReviewClose },
                ]}
                primaryAction={{
                    content: "Submit",
                    onAction: handleSubmit,
                    disabled: !noError,
                    loading: loading,
                }}
                open={props.open && openReview}
                onClose={handleReviewClose}
            >
                <p>Are you sure to submit to generate mockup?</p>
            </Modal>
            <LivePreviewMockup
                open={open}
                toggleOpen={toggleOpen}
                onClose={props.onClose}
                onCloseParent={onCloseParent}
                id={ideaTaskId}
                handleResubmit={handleResubmit}
                handleBackToDetail={handleBackToDetail}
                taskBaseGroups={taskBaseGroups}
                assignee={item?.assignee}
                isFileGenerating={isFileGenerating}
                item={item}
            />
        </>
    );
}

function genInput(products) {
    if (!products || products.length === 0) return [];

    const result = products
        .filter((p) => p?.status !== TASK_STATUS.Approved)
        .map((p) => {
            const { id, bases, mainMockups } = p || {};
            const baseGroupId = id;
            const baseConfigs = [];
            const newMainMockups = mainMockups || [];

            (bases || []).forEach((b) => {
                const {
                    baseConfigId,
                    designPositions,
                    designPositionsForGen,
                    customMockups,
                } = b || {};

                const customMockupIds = (customMockups || [])
                    .map((i) => i.id)
                    .filter(Boolean);
                const isCustomMockup = customMockupIds?.length > 0;
                const item = {
                    baseConfigId,
                    isCustomMockup,
                    customMockupIds,
                    mainCustomMockupId: undefined,
                };

                const designPositionsInput = [];

                let newDp = designPositions || [];
                // Item must have least at one file or file for gen mockup.
                newDp = newDp.filter((i) => {
                    const { file, id } = i || {};
                    const dpGen = designPositionsForGen || [];
                    const mapDp = dpGen.find((i) => i.id === id);
                    return file?.length > 0 || mapDp?.file?.length > 0;
                });
                newDp.forEach((dp) => {
                    // Map generate mockup by design position id.
                    const dpGen = designPositionsForGen || [];
                    const mapDp = dpGen.find((i) => i.id === dp.id);

                    const [fileGen] = mapDp?.file || [];
                    const fileGenerateMockupId = fileGen?.id;

                    const [file] = dp.file || [];
                    const fileId = file?.id;

                    const designPosition = {
                        designPositionId: dp.id,
                        fileId,
                        fileGenerateMockupId,
                    };

                    designPositionsInput.push(designPosition);
                });
                item.designPositions = designPositionsInput;

                if (item.designPositions.length > 0) {
                    item.customMockupIds = [];
                    item.isCustomMockup = false;
                }

                if (item.customMockupIds.length > 0) {
                    const [id] = newMainMockups;
                    const [firstCustomMockup] = item.customMockupIds;
                    const mainMockupIncludes = item.customMockupIds.some((i) =>
                        newMainMockups.includes(i)
                    );

                    // If main mockup includes customMockups or designPositions length = 0
                    if (
                        mainMockupIncludes ||
                        item.designPositions.length === 0
                    ) {
                        item.mainCustomMockupId = id || firstCustomMockup;
                    }
                }

                if (
                    item.designPositions.length > 0 ||
                    item.customMockupIds.length > 0
                ) {
                    baseConfigs.push(item);
                }
            });

            return {
                baseGroupId,
                baseConfigs,
            };
        })
        .filter((i) => i?.baseConfigs?.length > 0);

    return result;
}
const KEYS_REMOVE = [
    "__typename",
    "rejectReason",
    "outputMockupId",
    "designPositionId",
    "generateMockupFileId",
    "mimeType",
    "description",
];

// Get base groups have submission
function getBaseGroupsHaveSub(groups) {
    if (!groups || groups.length === 0) return [];

    return (groups || [])
        .map((g) => {
            const { baseConfigs } = g;

            const newConfigs = (baseConfigs || [])
                .map((cf) => {
                    const { taskBaseSubmissions } = cf || {};

                    if (
                        !taskBaseSubmissions ||
                        taskBaseSubmissions?.length === 0
                    )
                        return null;

                    const [sub] = taskBaseSubmissions;
                    const { customMockups, taskBaseSubmissionFiles } =
                        sub || {};

                    if (
                        (!customMockups || customMockups?.length === 0) &&
                        taskBaseSubmissionFiles?.length > 0
                    ) {
                        return cf;
                    }

                    return null;
                })
                .filter(Boolean);

            // return newConfigs.length > 0;
            if (newConfigs.length > 0) {
                return {
                    ...g,
                    baseConfigs: newConfigs,
                };
            }
            return null;
        })
        .filter(Boolean);
}

export function omitProduct(taskBaseGroups, products, getObject) {
    if (!taskBaseGroups || taskBaseGroups.length === 0) return {};

    // Base groups
    const cloneProducts = [...(products || [])];
    const newBaseGroups = taskBaseGroups.map((p) => {
        const { title, id, medias, baseConfigs } = p || {};
        const file = (medias || []).find((i) => i);
        const mapBaseGroup = findFunc(cloneProducts, id);

        let newBases = (baseConfigs || []).map((b) => {
            const { id, taskBaseGroupId, productBaseId, taskBaseSubmissions } =
                b || {};

            const { title, baseConfigId, mockupTemplates } = findFunc(
                mapBaseGroup?.bases,
                productBaseId
            );

            const newTmp = (mockupTemplates || []).map((m) =>
                omit(m, KEYS_REMOVE)
            );

            const newSubmission = (taskBaseSubmissions || []).map((s) => {
                const { taskBaseMockups, taskBaseSubmissionFiles } = s || {};
                const newMockups = (taskBaseMockups || []).map((m) =>
                    omit(m, KEYS_REMOVE)
                );
                const newSubFiles = (taskBaseSubmissionFiles || []).map(
                    (subFile) => {
                        const { file, generateMockupFile, designPosition } =
                            subFile || {};
                        const newFile = omit(file, KEYS_REMOVE);
                        const newGenFile = omit(
                            generateMockupFile,
                            KEYS_REMOVE
                        );
                        const newDp = omit(designPosition, KEYS_REMOVE);

                        return omit(
                            {
                                ...subFile,
                                file: newFile,
                                generateMockupFile: newGenFile,
                                designPosition: newDp,
                            },
                            KEYS_REMOVE
                        );
                    }
                );

                return omit(
                    {
                        ...s,
                        taskBaseMockups: newMockups,
                        taskBaseSubmissionFiles: newSubFiles,
                    },
                    [...KEYS_REMOVE, "status"]
                );
            });

            return {
                id,
                taskBaseGroupId,
                productBaseId,
                taskBaseSubmissions: newSubmission,
                title,
                baseConfigId,
                mockupTemplates: newTmp,
            };
        });

        if (mapBaseGroup?.isMainCustomMockup) {
            newBases = newBases?.map((b) => {
                const { taskBaseSubmissions } = b || {};

                const subMission = (taskBaseSubmissions || []).map((s) => {
                    const { taskBaseMockups } = s || {};
                    const mockups = (taskBaseMockups || []).map((m) => ({
                        ...m,
                        isMainMockup: false,
                    }));
                    return {
                        ...s,
                        taskBaseMockups: mockups,
                    };
                });

                return {
                    ...b,
                    taskBaseSubmissions: subMission,
                };
            });
        }

        return {
            id,
            title,
            file,
            isCampaign: mapBaseGroup?.isCampaign,
            bases: newBases,
            isMainCustomMockup: mapBaseGroup?.isMainCustomMockup,
        };
    });

    if (getObject) {
        return newBaseGroups;
    }

    const baseGroupString = JSON.stringify(newBaseGroups);
    // const baseGroupProp = window.btoa(baseGroupString);
    const baseGroupProp = genStrToBtoA(baseGroupString);
    return baseGroupProp;
}

export function omitUser() {
    const userInfo = getTokenObject();
    const keepKeys = ["firstName", "lastName", "avatar", "teamUser"];

    const filterKey = (key) => !keepKeys.includes(key);
    const keysToRemove =
        userInfo != null ? Object.keys(userInfo.user).filter(filterKey) : [];
    const newUser = omit(userInfo?.user, keysToRemove);

    return omit({ ...userInfo, user: newUser }, ["expiredAt", "__typename"]);
}

function omit(object, keysToRemove = []) {
    const clone = {};

    if (typeof object === "object" && object !== null) {
        for (let key of Object.keys(object)) {
            if (!keysToRemove.includes(key)) {
                clone[key] = object[key];
            }
        }
    }

    return clone;
}

function findFunc(arr, id) {
    if (!arr || arr.length === 0) return false;

    return arr.find((el) => el.id === id) || {};
}

function clearFileDesign(product) {
    const { bases } = product || {};
    const newBases = (bases || []).map((b) => {
        const { designPositionsForGen: dpGen, designPositions, ...rest } = b;
        return {
            ...rest,
            designPositions: resetDesign(designPositions),
            designPositionsForGen: resetDesign(dpGen),
        };
    });
    product.bases = newBases;
    return product;
}

function resetDesign(arr) {
    if (!arr || arr.length === 0) return [];
    return arr.map((d) => ({ ...d, file: [], fileGenerateMockupId: [] }));
}
