import React from "react";
import styled from "styled-components";
import { Labelled, Spinner } from "@shopify/polaris";

import { FilterCustomTimePolaris } from "../../tasks/FilterCustomTimePolaris";

const Container = styled.div`
    > .Polaris-FormLayout {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
    }
`;

export const TimeRange = ({ onChange, value, isEditDivision, label, canSelectOne }) => {
    const [loading, setLoading] = React.useState(false);
    const onChangeRef = React.useRef(null);

    const handleClear = React.useCallback(() => {
        setLoading(true);
        onChangeRef.current && clearTimeout(onChangeRef.current);
        onChangeRef.current = setTimeout(() => {
            if (onChange) {
                onChange({});
            }
            setLoading(false);
        }, 500);
    }, [onChange]);

    const timeMarkup = React.useMemo(() => {
        return loading ? (
            <Spinner size="small" />
        ) : (
            <FilterCustomTimePolaris
                customTime={{
                    range: Object.keys(value).length === 2 ? value : null,
                    rangeLabel: null,
                }}
                onChange={onChange}
                isField={isEditDivision}
                canSelectOne={canSelectOne}
            />
        );
    }, [value, loading, isEditDivision, onChange]);

    return (
        <>
            <Labelled
                label={label ? label : "Has last sale at"}
                action={
                    Object.values(value).some((i) => i)
                        ? { content: "Reset to default", onAction: handleClear }
                        : {}
                }
            />
            <Container>{timeMarkup}</Container>
        </>
    );
};
