import { useMutation } from "@apollo/react-hooks";
import { Modal, TextField } from "@shopify/polaris";
import React, { useCallback, useRef, useState } from "react";
import { IDEA_LEADER_DECLINE_IDEA_TASK } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";

export function Decline({ item, onCloseParent, ...props }) {
    // Props
    const id = item?.id;
    const onCloseRef = useRef(null);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // State
    const [reason, setReason] = useState("");

    // Mutation
    const [declineIdeaTask, { loading }] = useMutation(
        IDEA_LEADER_DECLINE_IDEA_TASK,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Declined this task successfully.",
                    err: false,
                });
                props.onClose && props.onClose();
                onCloseParent && onCloseParent();

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    refetch && refetch();
                    setFilter && setFilter((prev) => ({ ...prev }));
                }, 1000);
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    // Actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            declineIdeaTask({
                variables: {
                    id,
                    reason,
                },
            });
        }
    }, [id, declineIdeaTask, toggleToast, setNotify, reason]);

    const handleReasonChange = useCallback((value) => setReason(value), []);

    return (
        <Modal
            title="Decline this Idea Task"
            sectioned
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            secondaryActions={[
                {
                    content: "Close",
                    onAction: props.onClose,
                },
            ]}
            {...props}
        >
            <TextField
                value={reason}
                multiline={4}
                label="Reason"
                placeholder="Enter reason decline"
                onChange={handleReasonChange}
            />
        </Modal>
    );
}
