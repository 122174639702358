import React, { useState, useCallback } from "react";
import { Popover, Button, ActionList, Toast } from "@shopify/polaris";
import {
  EditMinor,
  RefreshMinor,
  LinkMinor,
  DeleteMinor,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import history from "../../../history";

import { PRODUCT_FEEDS } from "./ProductFeedsPolaris";
import { copyToClipboard } from "../../../helper";

const REGENERATE = gql`
  mutation regenerate($id: ID!) {
    regenerate(id: $id)
  }
`;

const DELETE_FEED = gql`
  mutation deleteFeed($id: ID!) {
    deleteFeed(id: $id)
  }
`;

export const FeedActionsPolaris = ({
  feed,
  client,
  filter,
  urlStoreManager,
}) => {
  const [active, setActive] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [actions, setActions] = useState(null);
  const [regenerate, { error, data }] = useMutation(REGENERATE, {
    onCompleted: () => {
      try {
        const cache = client.readQuery({
          query: PRODUCT_FEEDS,
          variables: {
            filter,
          },
        });
        client.writeQuery({
          query: PRODUCT_FEEDS,
          variables: { filter },
          data: {
            ...cache,
            feeds: {
              ...cache.feeds,
              nodes: cache.feeds.nodes.map((n) => {
                if (n.id === feed.id) {
                  return {
                    ...n,
                    status: "Processing",
                  };
                }
                return n;
              }),
            },
          },
        });
      } catch (_) {}
    },
    onError: () => {},
  });

  const [deleteFeed, { error: errorDelete, data: dataDelete }] = useMutation(
    DELETE_FEED,
    {
      onCompleted: () => {
        try {
          const cache = client.readQuery({
            query: PRODUCT_FEEDS,
            variables: { filter },
          });
          client.writeQuery({
            query: PRODUCT_FEEDS,
            variables: { filter },
            data: {
              ...cache,
              feeds: {
                ...cache.feeds,
                total: cache.feeds.total - 1,
                nodes: cache.feeds.nodes.filter((n) => n.id !== feed.id),
              },
            },
          });
        } catch (_) {}
      },
      onError: () => {},
    }
  );
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleReGenerate = useCallback(() => {
    regenerate({
      variables: {
        id: feed.id,
      },
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteFeed = useCallback(() => {
    deleteFeed({
      variables: { id: feed.id },
    });
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleContentToast = () => {
    if (error || errorDelete) {
      return "An error regenerating feed.";
    }
    if (data && data.regenerate) return "Feed being generated.";
    if (dataDelete && dataDelete.deleteFeed)
      return `${feed.name} has been deleted.`;

    switch (actions) {
      case "linkCsv":
        return "CSV URL has been copied to your clipboard.";
      case "linkXml":
        return "XML URL has been copied to your clipboard.";
      case "linkTsv":
        return "TSV URL has been copied to your clipboard.";
      default:
        break;
    }
  };

  const isLink = ["linkCsv", "linkXml", "linkTsv"].includes(actions);
  const toastMarkup = activeToast
    ? (error ||
        errorDelete ||
        (data && data.regenerate) ||
        (dataDelete && dataDelete.deleteFeed) ||
        isLink) && (
        <Toast
          content={handleContentToast()}
          error={error || errorDelete}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Actions
    </Button>
  );

  const handleActionEdit = useCallback(() => {
    history.push(`/${urlStoreManager}/products/feeds/edit/${feed.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyAction = useCallback(
    (url, action) => () => {
      copyToClipboard(url);
      setActions(action);
      toggleActiveToast();
      toggleActive();
    },
    []
  );

  return (
    <>
      {toastMarkup}
      <Popover active={active} activator={activator} onClose={toggleActive}>
        <ActionList
          items={[
            {
              content: "Edit",
              icon: EditMinor,
              onAction: handleActionEdit,
            },
            {
              content: "ReGenerate",
              icon: RefreshMinor,
              disabled: feed.status === "Processing",
              onAction: () => {
                handleReGenerate();
              },
            },
            {
              content: "Link CSV File",
              icon: LinkMinor,
              disabled: feed.status === "Processing",
              onAction: handleCopyAction(feed.csvUrl, "linkCsv"),
            },
            {
              content: "Link XML File",
              icon: LinkMinor,
              disabled: feed.status === "Processing",
              onAction: handleCopyAction(feed.xmlUrl, "linkXml"),
            },
            {
              content: "Link TSV File",
              icon: LinkMinor,
              disabled: feed.status === "Processing",
              onAction: handleCopyAction(feed.tsvUrl, "linkTsv"),
            },
            {
              content: "Delete",
              icon: DeleteMinor,
              onAction: () => handleDeleteFeed(),
            },
          ]}
        />
      </Popover>
    </>
  );
};
