import { Page } from "@shopify/polaris";
import _ from "lodash";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { ProductDivisionsPolaris } from "../../components/product/divisions/ProductDivisionsPolaris";
import { AppContext } from "../../context";
import { getPathFromRouter } from "../../helper";
import { TEAM_ROLE, TEAM_ROLE_PARAMS, USER_ROLE } from "../../variable";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ProductDivisionPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    const { currentUser } = useContext(AppContext);
    const userRole = _.get(currentUser, "roles", []);
    const teamRole = _.get(currentUser, "teamUser.role", null);

    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (userRole.includes(USER_ROLE.Seller)) {
        if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
        }
    }

    return (
        <Container>
            <Page
                title="Product Divisions"
                breadcrumbs={[
                    { content: "Products", url: `/${currentParam}/products` },
                ]}
                fullWidth
                primaryAction={{
                    content: "Add product division",
                    url: `/${currentParam}/product-divisions/add`,
                }}
            >
                <ProductDivisionsPolaris
                    currentParam={currentParam}
                    path={path}
                />
            </Page>
        </Container>
    );
};
