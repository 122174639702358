import React from "react";
import { DataTable } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { convertToOffset, convertToPaged } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

const Container = styled.div`
  .designer-wrap {
    width: calc(18rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const ReportDT = ({ data, filter, setFilter }) => {
  //State
  const [rows, setRows] = React.useState([]);

  // Get data
  React.useEffect(() => {
    const newRows =
      data?.getCountTaskByUser?.nodes?.length > 0
        ? data.getCountTaskByUser.nodes
            .map((node) => {
              if (!node) {
                return null;
              }
              const {
                pending,
                doing,
                needReview,
                rejected,
                done,
                cancel,
                denied,
                expired,
                expiredSoon,
                firstName,
                lastName,
                kpi,
              } = node;

              const designerMarkup = [firstName, lastName]
                .filter(Boolean)
                .join(" ");

              return [
                <div className="designer-wrap">
                  <span>{designerMarkup}</span>
                </div>,
                <div className="pending-wrap">
                  <span>{pending}</span>
                </div>,
                <div className="doing-wrap">
                  <span>{doing}</span>
                </div>,
                <div className="need-review-wrap">
                  <span>{needReview}</span>
                </div>,
                <div className="reject-wrap">
                  <span>{rejected}</span>
                </div>,
                <div className="expired-soon-wrap">
                  <span>{expiredSoon}</span>
                </div>,
                <div className="denied-wrap">
                  <span>{denied}</span>
                </div>,
                <div className="expired-wrap">
                  <span>{expired}</span>
                </div>,
                <div className="cancel-wrap">
                  <span>{cancel}</span>
                </div>,
                <div className="done-wrap">
                  <span>{done}</span>
                </div>,
                <div className="kpi-wrap">
                  <span>{kpi}</span>
                </div>,
              ];
            })
            .filter(Boolean)
        : [];
    setRows(newRows);
  }, [data]);

  const handleFilter = React.useCallback((offset, limit) => {
    const paged = convertToPaged(limit, offset);
    if (setFilter) {
      setFilter({ paged, limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Variable
  const cols = [];
  const n = 10;
  for (let i = 0; i < n; i++) {
    cols.push("text");
  }

  const headings = [
    "Designer",
    "Pending",
    "Doing",
    "Need Review",
    "Rejected",
    "Expired Soon",
    "Denied",
    "Expired",
    "Cancel",
    "Done",
    "KPI"
  ];

  let { limit, paged } = filter;
  let offset = convertToOffset(limit, paged);
  const total = get(data, "getCountTaskByUser.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handleFilter,
    total,
  };

  return (
    <React.Fragment>
      <Container>
        <DataTable
          rows={rows}
          columnContentTypes={cols}
          headings={headings}
          verticalAlign="middle"
          hideScrollIndicator
        />
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </React.Fragment>
  );
};
