import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { Modal, ChoiceList } from "@shopify/polaris";
import styled from "styled-components";
import { elementContains } from "../../../../helper";

export const ViewVariants = forwardRef(function ViewVariants(
    { open, onClose, base, updateBase },
    ref
) {
    // State
    const [choices, setChoices] = useState([]);
    const [selected, setSelected] = useState([]);

    // Actions
    const getChoices = useCallback(() => {
        const variants = base?.variants || [];
        const clone = [...variants];
        const newSelected = [];

        const newChoices =
            clone.length > 0
                ? clone
                      .filter((i) => i?.productBaseVariant)
                      .sort(
                          (a, b) =>
                              a.productBaseVariant.sorting -
                              b.productBaseVariant.sorting
                      )
                      .map((i) => {
                          const {
                              disabled,
                              productBaseVariant,
                              productBaseVariantId,
                          } = i || {};

                          const attributes = productBaseVariant?.attributes;
                          const label =
                              attributes?.length > 0
                                  ? attributes.map((i) => i.option).join(" / ")
                                  : null;

                          if (!disabled) {
                              newSelected.push(productBaseVariantId);
                          }
                          return {
                              value: productBaseVariantId,
                              label,
                          };
                      })
                : [];

        setChoices(newChoices);
        setSelected(newSelected);
    }, [base]);

    React.useImperativeHandle(ref, () => ({
        update: () => {
            getChoices();
        },
    }));

    useEffect(() => {
        getChoices();
    }, [getChoices]);

    // Actions
    const handleChange = useCallback(
        (selected) => {
            if (selected.length === 0) {
                alert("You must keep at least one option for each attributes");
                return;
            }
            setSelected(selected);

            if (base != null) {
                const newVariants = (base.variants || []).map((variant) => {
                    const variantId = variant.productBaseVariantId;
                    const includes = selected.includes(variantId);

                    return {
                        ...variant,
                        disabled: !includes,
                    };
                });

                updateBase({ ...base, variants: newVariants });
            }
        },
        [base, updateBase]
    );

    // Mounted
    useEffect(() => {
        const domI = document.querySelector("#view-variants");
        const modalCls = ".Polaris-Modal-Dialog__Modal";
        const domParents = document.querySelectorAll(modalCls);

        for (const domP of domParents) {
            if (elementContains(domP, domI)) {
                domP.setAttribute(
                    "style",
                    "max-height: calc(100vh - 5rem); height: calc(100vh)"
                );
            }
        }
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="View all Variants"
            sectioned
            primaryAction={{ content: "Done", onAction: onClose }}
            large
        >
            <Wrapper id="view-variants">
                <ChoiceList
                    choices={choices}
                    allowMultiple
                    selected={selected}
                    onChange={handleChange}
                />
            </Wrapper>
        </Modal>
    );
});

const Wrapper = styled.div`
    .Polaris-ChoiceList__Choices {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        flex-wrap: wrap;

        > li {
            width: calc(34% - 2rem);
        }
    }
`;
