import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Input, notification } from "antd";
import { handleError } from "../../helper";
import styled from "styled-components";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
const updateMutation = gql`
  mutation updateProductCrawlItemTitle($id: ID!, $title: String!) {
    updateProductCrawlItemTitle(id: $id, title: $title)
  }
`;

const Container = styled.div`
  max-width: 500px;
`;

class CrawlTitle extends Component {
  state = {
    showEdit: false,
    title: "",
    loading: false,
	theTitleLength: this.props.value.title.length,
  };

  render() {
    const { showEdit } = this.state;
    const { value } = this.props;

    const SelectAfter = ({ originUrl }) => {
      const href = originUrl && originUrl.length > 0 ? originUrl : "#";
      return (
        <CustomLinkPolaris href={href}>
          <LinkOutlined />
        </CustomLinkPolaris>
      );
    };

    return (
      <Container>
        <Mutation mutation={updateMutation}>
          {(updateProductCrawlItemTitle) =>
            showEdit ? (
              <div style={{ minWidth: "480px" }}>
                <Input.TextArea
                  rows={5}
                  defaultValue={value.title}
                  onChange={(e) => {
                    this.setState({ title: e.target.value, theTitleLength: e.target.value.length });
                  }}
                />
				<p>{this.state.theTitleLength} characters.</p>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        showEdit: false,
						theTitleLength: this.props.value.title.length,
                      });
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={this.state.loading}
                    onClick={() => {
                      let title = this.state.title;
                      if (!title) {
                        title = value.title;
                      }
                      this.setState({ loading: true }, () => {
                        updateProductCrawlItemTitle({
                          variables: {
                            id: value.id,
                            title,
                          },
                        })
                          .then(() => {
                            this.setState({
                              showEdit: false,
                              loading: false,
                              title: "",
                            });
                            if (this.props.onChange) {
                              this.props.onChange(title);
                            }
                          })
                          .catch((e) => {
                            notification.error({
                              message: handleError(e.toString()),
                            });
                          });
                      });
                    }}
                    style={{ marginLeft: 5 }}
                    size="small"
                    type="primary"
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ minWidth: "480px" }}>
                {value.title}
                <Button
                  onClick={() => this.setState({ showEdit: true })}
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  size={"small"}
                  icon={<LegacyIcon type={"edit"} />}
                  type="link"
                />
                {value.url && value.url.length > 0 && (
                  <div style={{ font: "14px italic", marginTop: "5px" }}>
                    <Input
                      disabled={true}
                      addonAfter={<SelectAfter originUrl={value.url} />}
                      value={value.url}
                    />
                  </div>
                )}
              </div>
            )
          }
        </Mutation>
      </Container>
    );
  }
}

CrawlTitle.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default CrawlTitle;
