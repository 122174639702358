import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextField, DatePicker } from "@shopify/polaris";
import moment from "moment";

export const FilterCustomTimeInnerPolaris = ({
    labelName,
    onChange,
    value,
    haveShow,
    isField,
    isExportAmazon,
    canSelectOne,
}) => {
    const [startInput, setStartInput] = useState("");
    const [{ month, year }, setDate] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [selectedDates, setSelectedDates] = useState({
        start: new Date(),
        end: new Date(),
    });
    const timeoutRef = useRef(false);

    useEffect(() => {
        if (value && !timeoutRef.current) {
            const newValue = new Date(value);
            if ("Starting" !== labelName && !isExportAmazon) {
                newValue.setDate(newValue.getDate() - 1);
            }
            setSelectedDates({ start: newValue, end: newValue });

            const month = newValue.getMonth();
            const year = newValue.getFullYear();

            setDate({ month, year });
            timeoutRef.current = true;
        } else if (canSelectOne && !value) {
            setSelectedDates(null);
        }
    }, [value, labelName, isExportAmazon, canSelectOne]);

    useEffect(() => {
        onStartChange();
        const valueStart = selectedDates
            ? moment(selectedDates.start).startOf("day")
            : null;
        const valueEnd = selectedDates
            ? moment(selectedDates.start).endOf("day")
            : null;

        let value;
        if (isField != null) {
            value = valueEnd;
        } else if ("Starting" === labelName) {
            value = valueStart;
        } else {
            value = valueEnd;
        }

        if (onChange) {
            onChange(value, moment(selectedDates?.start).format("YYYY-MM-DD"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates, labelName, isField]);

    const onStartChange = useCallback(() => {
        const val = selectedDates?.start
            ? moment(selectedDates.start).format("YYYY-MM-DD")
            : "";
        setStartInput(val);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates]);

    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        []
    );

    const handleDateChange = useCallback(
        (range) => {
            if (canSelectOne) {
                const isSame = moment(selectedDates?.start).isSame(
                    range.start,
                    "day"
                );
                if (isSame) {
                    setSelectedDates(null);
                    return;
                }
            }

            setSelectedDates(range);
            if (haveShow) {
                haveShow(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canSelectOne, selectedDates]
    );

    return (
        <span
            onClick={(evt) => {
                evt.stopPropagation();
            }}
        >
            <TextField
                label={labelName}
                placeholder="YYYY-MM-DD"
                value={startInput}
            />
            <DatePicker
                month={month}
                year={year}
                selected={selectedDates}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
            />
        </span>
    );
};
