import React, { useState } from "react";
import { Modal, Form, Input, notification } from "antd";

import { toSlug } from "../../helper";
import MediaSelectorButton from "../supplier/MediaSelectorButton";
import { UPLOAD_FOLDER } from "../../variable";

export const AddProductTemplate = ({
    visible,
    onChange,
    handleCreateTemplate,
    template,
}) => {
    const [{ name, slug, defaultMockups }, setState] = useState({
        name: null,
        slug: null,
        defaultMockups: [],
    });

    return (
        <Modal
            title="Create a new Template."
            visible={visible}
            onCancel={onChange}
            onOk={() => {
                if (!name || !slug) {
                    notification.error({
                        message: "Template name and slug are required!",
                    });
                    return;
                }
                handleCreateTemplate({ name, slug, defaultMockups });
            }}
            okText="Save"
            confirmLoading={template.loading}
        >
            <Form.Item required={true} label="Template name">
                <Input
                    onChange={(e) => {
                        setState({
                            name: e.target.value,
                            slug: toSlug(e.target.value),
                        });
                    }}
                    value={name}
                />
            </Form.Item>
            <Form.Item required={true} label="Template slug">
                <Input
                    onChange={(e) => {
                        setState({
                            name,
                            slug: e.target.value,
                        });
                    }}
                    value={slug}
                />
            </Form.Item>
            <Form.Item label="Default Mockup Files">
                <MediaSelectorButton
                    prefix="mockups/"
                    folder={UPLOAD_FOLDER.MOCKUPS}
                    multiple={true}
                    listType={"picture-card"}
                    buttonType="primary"
                    accept={"image/*"}
                    onChange={(value) => {
                        setState((prev) => ({
                            ...prev,
                            defaultMockups: value,
                        }));
                    }}
                />
            </Form.Item>
        </Modal>
    );
};
