import React from "react";
import { Select } from "@shopify/polaris";

const options = [
  { value: "all products", label: "All products" },
  { value: "in stock", label: "In stock" },
];

export const StockPolaris = ({ value, onChange }) => {
  return (
    <Select options={options} label="Stock" value={value} onChange={onChange} />
  );
};
