import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { LOAD_QUERY, ACTIVE_USERS } from "../ListUser";
import history from "../../../history";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

export const ActionUserAccountAdminPolaris = ({ user, filter }) => {
  const [actionModal, setActionModal] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [activeUser, { data, loading, error, client }] = useMutation(
    ACTIVE_USERS,
    {
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleActionModal();
        }, 2300);
        setTimeoutId(id);
      },
      onError: () => {},
    }
  );
  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(() => {
    history.push(`/admin/users/edit/${user.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActionModal = useCallback(
    () => setActionModal((open) => !open),
    []
  );
  const handleAction = useCallback(() => {
    activeUser({
      variables: { id: user.id },
    })
      .then(() => {
        try {
          const cache = client.readQuery({
            query: LOAD_QUERY,
            variables: { filter },
          });
          const timer = setTimeout(() => {
            client.writeQuery({
              query: LOAD_QUERY,
              variables: { filter },
              data: {
                ...cache,
                users: {
                  ...cache.users,
                  total: cache.users.total - 1,
                  nodes: cache.users.nodes.map((u) => {
                    if (u.id === user.id) {
                      return {
                        ...u,
                        status: true,
                      };
                    }
                    return u;
                  }),
                },
              },
            });
          }, 500);
          return () => {
            clearTimeout(timer);
          };
        } catch (_) {}
      })
      .catch((e) => {});
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleContentToast = () => {
    if (data && data.activeUser) return "Active this user success.";
  };

  const toastMarkup = activeToast
    ? (error || (data && data.activeUser)) && (
        <Toast
          content={handleContentToast()}
          error={error}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Button onClick={handleClick} plain>
        Edit
      </Button>
      <Button plain onClick={toggleActionModal}>
        Actions
      </Button>
      <Modal
        title="Active user"
        open={actionModal}
        onClose={toggleActionModal}
        sectioned
        secondaryActions={[
          {
            content: "No",
            onAction: toggleActionModal,
          },
        ]}
        primaryAction={{
          content: "Yes",
          onAction: handleAction,
          loading: loading,
        }}
      >
        <TextContainer>Are you sure to active this user?</TextContainer>
      </Modal>
    </Container>
  );
};
