import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "@shopify/polaris";
import React from "react";
import { SEND_ACTIVATION_MAIL_TO_USERS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { useToastContext } from "../../shared/ToastContext";

const SendEmailActivation = ({ id }) => {
  const [open, onClose] = useToggle(false);

  // Context
  const { toggleToast, setNotify } = useToastContext();
  const [sendEmail, { loading }] = useMutation(SEND_ACTIVATION_MAIL_TO_USERS, {
    onCompleted: () => {
      setNotify({
        msg: "Send email activation to this user success.",
        err: false,
      });
      onClose();
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
      onClose();
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!id) return;

    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });
    sendEmail({
      variables: {
        userID: id,
      },
    });
  }, [sendEmail, id, toggleToast, setNotify]);

  return (
    <>
      <Button onClick={onClose} children="Send Email Activation" primary />
      <Modal
        open={open}
        onClose={onClose}
        title="Send Email Activation"
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p>Are you sure to send email activation to this user?</p>
      </Modal>
    </>
  );
};

export default SendEmailActivation;
