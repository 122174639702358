import React, { useCallback, useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { DESIGNER_START_TASK } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";

export const StartActionPolaris = (props) => {
    const {
        open,
        toggleShowModal,
        idsChecked,
        toggleActive,
        onNotificationChange,
        onCompleted,
    } = props;
    // Block body scroll
    // useLockBodyScroll(open);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter, refetch } = useToastContext();

    const [designerStartTask, { loading }] = useMutation(DESIGNER_START_TASK, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: `Start ${
                        idsChecked.length > 1 ? "tasks" : "task"
                    } success.`,
                    isError: false,
                });
            }
            if (onCompleted) {
                onCompleted();
            }

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
    });

    const handleSubmit = useCallback(() => {
        if (onNotificationChange) {
            onNotificationChange({
                message: null,
                isError: false,
            });
        }

        if (idsChecked) {
            designerStartTask({
                variables: {
                    taskIds: idsChecked,
                },
            });
            toggleActive();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idsChecked]);

    return (
        <Modal
            title={`Start task`}
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Start",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>
                Are you sure to start {idsChecked.length > 1 ? "tasks" : "task"}{" "}
                selected?
            </p>
        </Modal>
    );
};
