import React, { Component } from "react";
import { Row, Col } from "antd";
import LayoutHeader from "./header/Header";
import { Helmet } from "react-helmet";

class CustomLayout extends Component {
  state = {
    collapsed: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { title } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title> {`${title ? `${title} - ` : ""}`}Merch Bridge</title>
        </Helmet>
        <LayoutHeader />
        <Row style={{ flexWrap: "nowrap" }}>
          <Col
            className="p-4 sm:p-6 mb-custom-content"
            style={{
              minHeight: "calc(100vh - 64px)",
              background: "#f0f2f5",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            {this.props.children}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CustomLayout;
