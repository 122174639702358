import { useMutation } from "@apollo/react-hooks";
import {
    Button,
    Collapsible,
    Icon,
    Stack,
    TextField,
    TextStyle,
} from "@shopify/polaris";
import { LinkMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import React, {
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import styled from "styled-components";
import { GET_PRODUCT_BY_TITLE } from "../../graphql/queries";
import { formatText, handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import {
    AMZ_MESSAGE_ERROR,
    MAX_LENGTH,
    MAX_TITLE_CHARACTERS,
    TITLE_LENGTH_MESS,
    WHICH_FIELD,
} from "../../variable";
import { genHelpText, ShortTitleComp } from "../product/components/ShortTitle";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { useAssortmentContext } from "./context/AssortmentContextPolaris";
import { QUERY_PRODUCT_ASSORTMENT } from "./ProductAssortmentPolaris";
import { TITLES } from "./ScreenOptionsPAPolaris";

export const UPDATE_PRODUCT_TITLE = gql`
    mutation updateProductCrawlItemTitle(
        $id: ID!
        $title: String!
        $fbTitle: String
        $amzTitle: String
    ) {
        updateProductCrawlItemTitle(
            id: $id
            title: $title
            fbTitle: $fbTitle
            amzTitle: $amzTitle
        )
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .url_wrap {
        margin-top: 2rem;

        .Polaris-TextField__Input {
            border-right: 0.1rem solid var(--p-border, #c4cdd5);
        }
        .Polaris-TextField__Suffix {
            margin-left: 0.6em;
        }
    }
`;

export const ProductAssortmentTitlePolaris = ({
    product,
    toggleActive,
    onNotificationChange,
}) => {
    const href = product.url && product.url.length > 0 ? product.url : "foo";
    const suffixMarkup = (
        <CustomLinkPolaris href={href}>
            <Icon source={LinkMinor} />
        </CustomLinkPolaris>
    );

    return (
        <Container>
            <ProductTitleProvider value={product}>
                <Titles
                    value={product}
                    onNotificationChange={onNotificationChange}
                    toggleActive={toggleActive}
                    haveSubmit
                />
                {product.url && product.url.length > 0 ? (
                    <div className="url_wrap">
                        <TextField
                            disabled
                            value={product.url}
                            suffix={suffixMarkup}
                        />
                    </div>
                ) : null}
                <TitlesBtn />
            </ProductTitleProvider>
        </Container>
    );
};

const ProductTitleContext = createContext(null);
export function useProductTitleContext() {
    return useContext(ProductTitleContext);
}

export function ProductTitleProvider({ children, value }) {
    const [edit, toggleEdit] = useToggle(false);
    const { title, fbTitle, amzTitle } = value;
    const haveValue = [title, fbTitle, amzTitle].some((i) => !!i);
    const btnLabel = haveValue ? "Update Titles" : "Add Titles";

    // Context
    const { screenOptions } = useAssortmentContext();
    const showTitles = (screenOptions || []).includes(TITLES);

    // Provider
    const bag = useMemo(() => ({ edit, toggleEdit, btnLabel, showTitles }), [
        edit,
        toggleEdit,
        btnLabel,
        showTitles,
    ]);

    return (
        <ProductTitleContext.Provider value={bag}>
            {children}
        </ProductTitleContext.Provider>
    );
}

export function TitlesBtn() {
    const { toggleEdit, btnLabel, showTitles } = useProductTitleContext();

    return (
        showTitles && (
            <div style={{ marginTop: "1.6rem" }}>
                <Button children={btnLabel} size="slim" onClick={toggleEdit} />
            </div>
        )
    );
}

export const TitleForm = forwardRef(function (
    { value, haveSubmit, onSubmit, loading },
    ref
) {
    // Context
    const { edit } = useProductTitleContext();

    let { title, fbTitle, amzTitle, id } = value || {};
    title = formatTextFn(title);
    fbTitle = formatTextFn(fbTitle);
    amzTitle = formatTextFn(amzTitle);

    // State
    const [state, setState] = useState({
        title,
        fbTitle,
        amzTitle,
    });
    const [errors, setErrors] = useState({});

    // Actions
    const validateTitle = useCallback((title) => {
        let error = null;
        if (title === "") {
            error = "Product title is required.";
        } else if (title.length > MAX_LENGTH) {
            error = TITLE_LENGTH_MESS;
        }
        setErrors((prev) => ({ ...prev, title: error }));
    }, []);

    const handleTitleChange = useCallback(
        (value, id) => {
            if (id === "title") {
                validateTitle(value);
            }
            setState((prev) => ({ ...prev, [id]: value }));
        },
        [validateTitle]
    );

    const handleSubmit = useCallback(() => {
        if (id == null) return;
        const { title } = state;
        validateTitle(title);

        let noError = Object.values(errors).every((e) => e == null);
        const canSubmit = title && noError;

        if (canSubmit) {
            onSubmit && onSubmit({ id, ...state });
        }
    }, [state, errors, id, onSubmit]);

    useImperativeHandle(ref, () => ({
        onSubmit: handleSubmit,
    }));

    // Markup
    const disabled =
        title === state.title &&
        fbTitle === state.fbTitle &&
        amzTitle === state.amzTitle;

    const titleHelp = genHelpText(state.title);

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <Stack spacing="tight" vertical>
                {!edit && (
                    <div>
                        <TextStyle
                            children="Origin Title: "
                            variation="strong"
                        />
                        <span>{title}</span>
                    </div>
                )}
                {!edit && fbTitle && (
                    <div>
                        <TextStyle children="FB Title: " variation="strong" />
                        <span>{fbTitle}</span>
                    </div>
                )}
                {!edit && amzTitle && (
                    <div>
                        <TextStyle children="AMZ Title: " variation="strong" />
                        <span>{amzTitle}</span>
                    </div>
                )}
                <Collapsible
                    id={`titles-${id}`}
                    open={edit}
                    transition={{
                        duration: "550ms",
                        timingFunction: "ease",
                    }}
                >
                    <Stack vertical spacing="tight">
                        <TextField
                            value={state.title}
                            id="title"
                            onChange={handleTitleChange}
                            placeholder="Enter origin title"
                            error={errors.title}
                            multiline={4}
                            autoFocus
                            helpText={titleHelp}
                            label={
                                <TextStyle
                                    variation="strong"
                                    children="Origin Title: "
                                />
                            }
                        />
                        <ShortTitleComp
                            value={state.fbTitle}
                            onChange={handleTitleChange}
                            id="fbTitle"
                            multiline={4}
                            maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                            placeholder="facebook title"
                            label={
                                <TextStyle
                                    variation="strong"
                                    children="FB Title: "
                                />
                            }
                        />
                        <ShortTitleComp
                            value={state.amzTitle}
                            onChange={handleTitleChange}
                            id="amzTitle"
                            multiline={4}
                            maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                            placeholder="amazon title"
                            label={
                                <TextStyle
                                    variation="strong"
                                    children="AMZ Title: "
                                />
                            }
                        />
                        {haveSubmit && (
                            <Button
                                children="Submit"
                                primary
                                disabled={disabled}
                                onClick={handleSubmit}
                                size="slim"
                                loading={loading}
                            />
                        )}
                    </Stack>
                </Collapsible>
            </Stack>
        </span>
    );
});

function Titles({ value, onNotificationChange, toggleActive }) {
    let { title, id, amzTitle } = value || {};
    title = formatTextFn(title);

    // Context
    const { btnLabel, toggleEdit, showTitles } = useProductTitleContext();
    const { filter } = useAssortmentContext();

    // State
    const [loading, setLoading] = useState(false);

    // Mutation
    const [updateProductCrawlItemTitle, { client }] = useMutation(
        UPDATE_PRODUCT_TITLE,
        {
            onError: (error) => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: handleError(error.toString()),
                        isError: true,
                    });
                }
                setLoading(false);
            },
            onCompleted: () => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: `${btnLabel} successfully`,
                        isError: false,
                    });
                }
                setLoading(false);
                toggleEdit();
            },
        }
    );

    // Actions
    const updateCache = useCallback(
        ({ title, fbTitle, amzTitle }) => {
            const cache = client.readQuery({
                query: QUERY_PRODUCT_ASSORTMENT,
                variables: {
                    filter,
                },
            });
            client.writeQuery({
                query: QUERY_PRODUCT_ASSORTMENT,
                variables: {
                    filter,
                },
                data: {
                    ...cache,
                    productAssortment: {
                        ...cache.productAssortment,
                        nodes: cache.productAssortment.nodes.map((node) => {
                            if (node.id === id) {
                                return {
                                    ...node,
                                    title,
                                    fbTitle: fbTitle,
                                    amzTitle,
                                };
                            }
                            return node;
                        }),
                    },
                },
            });
        },
        [client, filter, id]
    );

    const handleSubmit = useCallback(
        async (input) => {
            if (input == null) return;
            setLoading(true);

            if (onNotificationChange)
                onNotificationChange({ message: null, isError: false });
            if (toggleActive) toggleActive();

            if (input.amzTitle && amzTitle !== input.amzTitle) {
                const { data } = await client.query({
                    query: GET_PRODUCT_BY_TITLE,
                    variables: {
                        title: input.amzTitle,
                        whichField: WHICH_FIELD.AmzTitle,
                    },
                });
                if (data?.getProductsByTitle?.total > 0) {
                    onNotificationChange({
                        message: AMZ_MESSAGE_ERROR,
                        isError: true,
                    });
                    setLoading(false);
                    return;
                }
            }

            updateProductCrawlItemTitle({
                variables: input,
            })
                .then(() => {
                    updateCache(input);
                })
                .catch(() => {});
        },
        [
            updateCache,
            onNotificationChange,
            toggleActive,
            updateProductCrawlItemTitle,
            amzTitle,
        ]
    );

    return showTitles ? (
        <TitleForm
            value={value}
            onSubmit={handleSubmit}
            loading={loading}
            haveSubmit
        />
    ) : (
        <span className="title" title={title}>
            {title}
        </span>
    );
}

function formatTextFn(text) {
    if (!text) return "";
    let value = formatText(text);
    value = value.trim();
    return value;
}
