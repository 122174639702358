import React from "react";
import { DataTable, Badge } from "@shopify/polaris";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";

import { NumberToFixed } from "../../helper";

import { USER_ROLE } from "../../variable";
import { PaginationPolaris } from "../shared/PaginationPolaris";

const CreatedContainer = styled.div`
  width: 100px;
  white-space: break-spaces;
`;

const Container = styled.div`
  overflow: hidden;
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const TableTransactionPolaris = ({
  data,
  filter,
  setFilter,
  isTransactions,
}) => {
  const rows = data
    ? data.transactions.nodes.map((t) => {
        let status = _.get(t, "journal.status", null);
        let color = null;
        if ("Approved" === status) {
          color = "success";
        } else if ("Pending" === status) {
          color = "info";
        } else if ("Cancelled" === status) {
          color = "critical";
        }
        t.amount = NumberToFixed(t.amount, 2);
        if (isTransactions) {
          return [
            t.id,
            <span>
              {t.amount < 0 ? `-$${Math.abs(t.amount)}` : `$${t.amount}`}
            </span>,
            _.get(t, "journal.type", null),
            _.get(t, "journal.comment", null),
            <Badge status={color}>{status}</Badge>,
            <CreatedContainer>
              {moment(t.createdAt).format("LL")}
            </CreatedContainer>,
          ];
        } else {
          return [
            t.id,
            <span>${t.amount}</span>,
            _.get(t, "journal.refId", null),
            _.get(t, "journal.comment", null),
            <div>
              {t.account &&
              t.account.teamUser &&
              t.account.teamUser.role === USER_ROLE.Supplier
                ? `${_.get(t, "account.firstName", "")} ${_.get(
                    t,
                    "account.lastName",
                    ""
                  )}`
                : "MerchBridge"}
            </div>,
            <Badge status={color}>{status}</Badge>,
            <CreatedContainer>
              {moment(t.createdAt).format("LL")}
            </CreatedContainer>,
          ];
        }
      })
    : [];

  let { limit, offset } = filter;
  const total = _.get(data, "transactions.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregations = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  let columnTransaction = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];
  let headings = [
    "ID",
    "Amount",
    "Transaction ID",
    "Note",
    "Target",
    "Status",
    "Created",
  ];
  if (isTransactions) {
    columnTransaction.pop();
    headings = ["ID", "Amount", "Action", "Note", "Status", "Created"];
  }

  return (
    <Container>
      <DataTable
        columnContentTypes={columnTransaction}
        headings={headings}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregations} showTotal />
        }
      />
    </Container>
  );
};
