import React, { Component } from "react";
import styled from "styled-components";
import {
  Page,
  Card,
  FormLayout,
  Form,
  TextField,
  Button,
} from "@shopify/polaris";
import history from "../../history";
import { isEmail } from "../../helper";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import Error from "../../components/shared/Error";
import { AMAZON_STORE_QUERY, INIT_AMAZON_VARIABLE } from "./Amazon";
import _ from "lodash";

import { AppContext } from "../../context";
import { TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../variable";
import { ComponentLabelPolaris } from "../../components/shared/ComponentLabelPolaris";

const Container = styled.div`
  .Polaris-Page-Header {
    padding-top: 0;
  }
  .actions {
    display: flex;
    margin: 10px 0;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;

const CREATE_SALE_CHANNEL_MUTATION = gql`
  mutation createAccountSaleChannel($input: NewAccount!) {
    createAccountSaleChannel(input: $input) {
      id
      title
      email
      platform
      apiKey
    }
  }
`;

class AddAmazonAccount extends Component {
  static contextType = AppContext;
  state = {
    name: "",
    email: "",
    error: null,
    errors: {
      name: null,
      email: null,
    },
    loading: false,
  };

  render() {
    const { errors, name, email } = this.state;
    const { currentUser } = this.context;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    const role = _.get(currentUser, "teamUser.role");
    if ([TEAM_ROLE.StoreManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }

    return (
      <Container>
        <Page
          breadcrumbs={[
            {
              content: "Amazon",
              onAction: () => {
                history.push(`/${currentParam}/stores/amazon`);
              },
            },
          ]}
          title="Add Amazon Account"
        >
          <Mutation
            onError={(err) => {
              console.log("e", err);
              this.setState({ error: err, loading: false });
            }}
            mutation={CREATE_SALE_CHANNEL_MUTATION}
          >
            {(createAccountSaleChannel) => (
              <Card>
                <Card.Section>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!name.trim() && !email.trim()) {
                        this.setState({
                          errors: {
                            ...errors,
                            name: "Full Name is required!",
                            email: "Email is required!",
                          },
                        });
                        return;
                      } else {
                        if (!email.trim()) {
                          this.setState({
                            errors: {
                              ...errors,
                              email: "Email is required!",
                            },
                          });
                          return;
                        }
                        if (!name.trim()) {
                          this.setState({
                            errors: {
                              ...errors,
                              name: "Full Name is required!",
                            },
                          });
                          return;
                        }
                      }
                      // handle submit add store
                      this.setState(
                        {
                          loading: true,
                        },
                        () => {
                          createAccountSaleChannel({
                            variables: {
                              input: {
                                platform: "amazon",
                                name: name,
                                email: email,
                              },
                            },
                            update: (
                              proxy,
                              { data: { createAccountSaleChannel } }
                            ) => {
                              this.setState({
                                loading: false,
                              });
                              try {
                                const cache = proxy.readQuery({
                                  query: AMAZON_STORE_QUERY,
                                  variables: INIT_AMAZON_VARIABLE,
                                });
                                proxy.writeQuery({
                                  query: AMAZON_STORE_QUERY,
                                  variables: INIT_AMAZON_VARIABLE,
                                  data: {
                                    ...cache,
                                    stores: {
                                      ...cache.stores,
                                      total: cache.stores.total + 1,
                                      notes: [
                                        ...cache.stores.nodes,
                                        createAccountSaleChannel,
                                      ],
                                    },
                                  },
                                });
                              } catch (e) {
                                console.log(e);
                              }

                              history.push(`/${currentParam}/stores/amazon`);
                            },
                          });
                        }
                      );
                    }}
                  >
                    <FormLayout>
                      <Error error={this.state.error} />
                      <div>
                        <ComponentLabelPolaris label="Full name" required />
                        <TextField
                          onChange={(value) => {
                            let newError = { ...errors, name: null };
                            if (!value.trim()) {
                              newError = {
                                ...errors,
                                name: "Full Name is required!",
                              };
                            }
                            this.setState({
                              name: value,
                              errors: newError,
                            });
                          }}
                          value={name}
                          error={errors.name}
                          // label={"Full Name(*)"}
                        />
                      </div>
                      <div>
                        <ComponentLabelPolaris label="Email" required />
                        <TextField
                          onChange={(value) => {
                            if (!isEmail(value)) {
                              this.setState({
                                email: value,
                                errors: {
                                  ...errors,
                                  email: "Invalid email address!",
                                },
                              });
                            } else {
                              this.setState({
                                email: value,
                                errors: {
                                  ...errors,
                                  email: null,
                                },
                              });
                            }
                          }}
                          value={email}
                          error={errors.email}
                          // label={"Email(*)"}
                        />
                      </div>
                    </FormLayout>
                    <div className={"actions"}>
                      <Button
                        onClick={() => {
                          history.push(`/${currentParam}/stores/amazon`);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button primary submit loading={this.state.loading}>
                        Add Account
                      </Button>
                    </div>
                  </Form>
                </Card.Section>
              </Card>
            )}
          </Mutation>
        </Page>
      </Container>
    );
  }
}

AddAmazonAccount.propTypes = {};

export default AddAmazonAccount;
