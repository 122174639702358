import React, { Component } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Select, Popover, Form } from "antd";

const ACTIONS = [
  { key: "Order", name: "Paid For Item" },
  { key: "Deposit", name: "Deposit" },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class SupplierTransactionsFilter extends Component {
  state = {
    status: null,
    kind: ACTIONS.map((action) => action.key),
  };
  onChange = () => {
    const { status, kind } = this.state;
    if (this.props.onChange) {
      this.props.onChange({
        status,
        kind,
      });
    }
  };
  render() {
    const { status } = this.props.value;
    return (
      <Popover
        placement={"bottom"}
        content={
          <Form
            initialValues={{
              action: null,
              status: null,
            }}
            {...layout}
          >
            <Form.Item label="Show by action" name="action">
              <Select
                style={{ width: "100%" }}
                onChange={(v) =>
                  this.setState(
                    {
                      kind: ACTIONS.filter((action) => action.key === v).map(
                        (ac) => ac.key
                      ),
                    },
                    this.onChange
                  )
                }
              >
                <Select.Option value={null}>All action</Select.Option>
                {ACTIONS.map((ac) => (
                  <Select.Option key={ac.key}>{ac.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Show by status" name="status">
              <Select
                style={{ width: "100%" }}
                onChange={(v) => this.setState({ status: v }, this.onChange)}
                value={status}
              >
                <Select.Option value={null}>All statuses</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Cancelled">Cancelled</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        }
      >
        <div className="cursor-pointer">
          Filter <CaretDownOutlined />
        </div>
      </Popover>
    );
  }
}

export default SupplierTransactionsFilter;
