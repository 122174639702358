import { LoadingOutlined } from "@ant-design/icons";
import { Query, Subscription } from "@apollo/react-components";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { MAPPING_EVENTS } from "../../graphql/subscription";
import { getTeamID, needGetTotalMapping } from "../../helper";
import { TEAM_ROLE, USER_ROLE } from "../../variable";

const Container = styled.span`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin: -3px 0 -1px 2px;
  padding: 1px 5px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ce3400;
  color: #fff;
  font-size: 11px;
  line-height: 1.6;
  text-align: center;
  z-index: 26;
`;

export const GET_TOTAL = gql`
  query totalMappingOrder($storeId: ID) {
    totalMappingOrder(storeId: $storeId)
  }
`;
class TotalOrderMapping extends Component {
  static contextType = AppContext;

  render() {
    const { currentUser, store, totalMappingOrder, setTotalMappingOrder } =
      this.context;
    const userRole = _.get(currentUser, "roles", []);
    const teamRole = _.get(currentUser, "teamUser.role", null);
    let filter = { storeId: store ? store.id : null };
    if (userRole.includes(USER_ROLE.Seller)) {
      if (
        [TEAM_ROLE.MarketplaceManager].includes(teamRole) &&
        store &&
        store.id === "all"
      ) {
        delete filter["storeId"];
      }
    }

    return (
      <Query query={GET_TOTAL} variables={{ ...filter }} fetchPolicy="no-cache">
        {({ data, error, loading }) => {
          if (loading) return <LoadingOutlined />;
          if (error) return null;

          // const total = data && data.totalMappingOrder;
          // if (total != null) {
          //   setTotalMappingOrder(Number(total));
          // }
          return data && data.totalMappingOrder ? (
            <Container> {data.totalMappingOrder}</Container>
          ) : (
            <Container>0</Container>
          );
        }}
      </Query>
    );
    // return (
    //   <AppContext.Consumer>
    //     {({ currentUser }) => {
    //       const teamID = getTeamID(currentUser);

    //       return (
    //         <Subscription subscription={MAPPING_EVENTS}>
    //           {({ data }) => {
    //             const valid = needGetTotalMapping(data, teamID);

    //           }}
    //         </Subscription>
    //       );
    //       return totalMappingOrder !== undefined ? (
    //         <Container>{totalMappingOrder || 0}</Container>
    //       ) : (
    //         <Query
    //           query={GET_TOTAL}
    //           variables={{ ...filter }}
    //           fetchPolicy="no-cache"
    //         >
    //           {({ data, error, loading }) => {
    //             if (loading) return <LoadingOutlined />;
    //             if (error) return null;

    //             const total = data && data.totalMappingOrder;
    //             if (total != null) {
    //               setTotalMappingOrder(Number(total));
    //             }
    //             return data && data.totalMappingOrder ? (
    //               <Container> {data.totalMappingOrder}</Container>
    //             ) : (
    //               <Container>0</Container>
    //             );
    //           }}
    //         </Query>
    //       );
    //     }}
    //   </AppContext.Consumer>
    // );
  }
}

TotalOrderMapping.propTypes = {};

export default TotalOrderMapping;
