import { lazy } from "react";

import { DocumentationsPage } from "../components/documentations";
import { ExportAmazonAddPolaris } from "../components/export-amazon/ExportAmazonAddPolaris";
import { ExportAmazonEditPolaris } from "../components/export-amazon/ExportAmazonEditPolaris";
import { ExportAmazonViewConfig } from "../components/export-amazon/ExportAmazonViewConfig";
import { ViewProductsPolaris } from "../components/export-amazon/ViewProductsPolaris";
import { AddMemberPolaris } from "../components/members/AddMemberPolaris";
import { EditMemberPolaris } from "../components/members/EditMemberPolaris";
import { AddCollectionPolaris } from "../components/product/collections/AddCollectionPolaris";
import { EditCollectionPolaris } from "../components/product/collections/EditCollectionPolaris";
import { AddProductFeedPolaris } from "../components/product/feeds/AddProductFeedPolaris";
import { EditProductFeedPolaris } from "../components/product/feeds/EditProductFeedPolaris";
import { ProductFeedsPolaris } from "../components/product/feeds/ProductFeedsPolaris";
import { Clone as CloneProductFromPrintFile } from "../components/product/print-file/Clone";
import { Create as CreateProductFromPrintFile } from "../components/product/print-file/Create";
import { Edit as EditProductFromPrintFile } from "../components/product/print-file/Edit";
import { TemplatesPolaris } from "../components/product/templates/TemplatesPolaris";
import { EditStorePolaris } from "../components/store/EditStorePolaris";
import { CreateStore } from "../components/store/fb-marketplace/components/CreateStore";
import { Edit } from "../components/store/fb-marketplace/components/Edit";
import { Reports } from "../components/tasks/report/Reports";
import { EditPushProductsPolaris } from "../components/tools/EditPushProductsPolaris";
import { NewPushProductsPolaris } from "../components/tools/NewPushProductsPolaris";
import CustomLayout from "../layout/CustomLayout";
import MainLayout from "../layout/MainLayout";
import { MediaPagePolaris } from "../pages/design-leader/MediaPagePolaris";
import { OverviewsPage } from "../pages/reports";
import AddCampaignProduct from "../pages/seller/AddCampaignProduct";
import AddProductPage from "../pages/seller/AddProduct";
import AddTiktokAccountPage from "../pages/seller/AddTiktokAccount";
import ArtistTaskPage from "../pages/seller/ArtistTaskPage";
import { SellerClaimsPagePolaris } from "../pages/seller/ClaimsPolaris";
import { CloneProduct } from "../pages/seller/CloneProduct";
import { CloneProductCampaign } from "../pages/seller/CloneProductCampaign";
import Collections from "../pages/seller/Collections";
import { CreateManualOrderPagePolaris } from "../pages/seller/CreateManualOrderPagePolaris";
import { DesignTasksPagePolaris } from "../pages/seller/DesignTasksPagePolaris";
import EditProductPage from "../pages/seller/EditProduct";
import EditProductCampaign from "../pages/seller/EditProductCampaign";
import { ExportAmazonPagePolaris } from "../pages/seller/ExportAmazonPagePolaris";
import { ExportTrackingPagePolaris } from "../pages/seller/ExportTrackingPagePolaris";
import { FBMarketplacePagePolaris } from "../pages/seller/FBMarketplacePagePolaris";
import { HistoryExportTrackingPagePolaris } from "../pages/seller/HistoryExportTrackingPagePolaris";
import { IdeaTaskPagePolaris } from "../pages/seller/IdeaTaskPagePolaris";
import { MappingOrderPagePolaris } from "../pages/seller/MappingOrdersPagePolaris";
import { OrdersPagePolaris } from "../pages/seller/OrdersPagePolaris";
import PersonalizedPage from "../pages/seller/PersonalizedPage";
import ProductAssortment from "../pages/seller/ProductAssortment";
import { ProductCrawlerPagePolaris } from "../pages/seller/ProductCrawlerPagePolaris";
import { ProductCrawlersPagePolaris } from "../pages/seller/ProductCrawlersPagePolaris";
import { ProductFromPrintFilePage } from "../pages/seller/ProductFromPrintFilePage";
import { ProductsIgnoredPagePolaris } from "../pages/seller/ProductsIgnoredPagePolaris";
import { ProductsPagePolaris } from "../pages/seller/ProductsPagePolaris";
import { ProductsPushPagePolaris } from "../pages/seller/ProductsPushPagePolaris";
import { ProductsRMPagePolaris } from "../pages/seller/ProductsRMPagePolaris";
import { ReplaceFeedPage } from "../pages/seller/ReplaceFeedPage";
import { StatisticPagePolaris } from "../pages/seller/StatisticPagePolaris";
import Tags from "../pages/seller/Tags";
import { TeamMembersPagePolaris } from "../pages/seller/TeamMembersPagePolaris";
import TiktokPage from "../pages/seller/TiktokPage";
import AddTaskPage from "../pages/store-manager/AddTask";
import { StoresPolaris } from "../pages/store-manager/StoresPolaris";

export default [
  {
    path: "/store-manager/stores",
    exact: true,
    // component: ListStoreManager,
    component: StoresPolaris,
    roles: ["Seller"],
    teamRole: "Store Manager",
    layout: CustomLayout,
  },
  {
    path: "/store-manager/store/edit/:id",
    exact: true,
    // component: EditStore,
    component: EditStorePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/orders",
    exact: true,
    // component: StoreManagerOrders,
    component: OrdersPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/mapping",
    exact: true,
    // component: StoreMannagerMappingOrders,
    component: MappingOrderPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/design-tasks",
    exact: true,
    // component: DesignTasksPage,
    component: DesignTasksPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/artist-tasks",
    exact: true,
    component: ArtistTaskPage,
    title: "Artist Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/design-tasks/ideas",
    exact: true,
    component: IdeaTaskPagePolaris,
    title: "Idea Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/design-tasks/report",
    exact: true,
    component: Reports,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/design-tasks/statistic",
    exact: true,
    component: StatisticPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/media",
    exact: true,
    component: MediaPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/re-mockups-tasks",
    exact: true,
    component: ProductsRMPagePolaris,
    title: "Product recreate mockup",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/team-members",
    exact: true,
    // component: TeamMembers,
    component: TeamMembersPagePolaris,
    layout: MainLayout,
    title: "Members",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/add-team-member",
    exact: true,
    // component: AddTeamMember,
    component: AddMemberPolaris,
    layout: MainLayout,
    title: "Add New Member",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/edit-team-member/:id",
    exact: true,
    // component: EditTeamMember,
    component: EditMemberPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/seller/add-task",
    exact: true,
    component: AddTaskPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products",
    exact: true,
    // component: SellerProductsPage,
    component: ProductsPagePolaris,
    layout: MainLayout,
    title: "Products",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/edit/:id",
    exact: true,
    component: EditProductPage,
    layout: MainLayout,
    title: "Edit Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/add",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/add/:id",
    exact: true,
    component: AddProductPage,
    layout: MainLayout,
    title: "Add Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/clone",
    exact: true,
    component: CloneProduct,
    layout: MainLayout,
    title: "Clone Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/feeds",
    exact: true,
    // component: ProductFeeds,
    component: ProductFeedsPolaris,
    layout: MainLayout,
    title: "Product Feeds",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/feeds/edit/:id",
    exact: true,
    // component: EditProductFeed,
    component: EditProductFeedPolaris,
    layout: MainLayout,
    title: "Edit Product Feed",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/feeds/add",
    exact: true,
    // component: AddProductFeed,
    component: AddProductFeedPolaris,
    layout: MainLayout,
    title: "Add Product Feed",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/add-campaign",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/add-campaign/:id",
    exact: true,
    component: AddCampaignProduct,
    layout: MainLayout,
    title: "Add Campaign Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/clone-campaign",
    exact: true,
    component: CloneProductCampaign,
    layout: MainLayout,
    title: "Clone Campaign Product",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/campaign/:id",
    exact: true,
    component: EditProductCampaign,
    layout: MainLayout,
    title: "Edit Product Campaign",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/collections",
    exact: true,
    component: Collections,
    layout: MainLayout,
    title: "Collections",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/templates",
    exact: true,
    component: TemplatesPolaris,
    layout: MainLayout,
    title: "Templates",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/edit/collection/:id",
    exact: true,
    // component: EditCollection,
    component: EditCollectionPolaris,
    layout: MainLayout,
    title: "Edit Collection",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/add/collection",
    exact: true,
    // component: AddCollections,
    component: AddCollectionPolaris,
    layout: MainLayout,
    title: "Add Collection",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/tags",
    exact: true,
    component: Tags,
    layout: MainLayout,
    title: "Tags",
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/claims",
    exact: true,
    // component: SellerClaimsPage,
    component: SellerClaimsPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/product-push",
    exact: true,
    // component: ProductsPush,
    component: ProductsPushPagePolaris,
    title: "Product Push",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products-ignored",
    exact: true,
    component: ProductsIgnoredPagePolaris,
    title: "Product Ignored",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/export-amazon",
    exact: true,
    component: ExportAmazonPagePolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-amazon/add",
    exact: true,
    component: ExportAmazonAddPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-amazon/:id",
    exact: true,
    component: ExportAmazonEditPolaris,
    title: "Export for Amazon",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-amazon/view-products/:id",
    exact: true,
    component: ViewProductsPolaris,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-amazon/view-configs/:id",
    exact: true,
    component: ExportAmazonViewConfig,
    title: "View products",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-tracking",
    exact: true,
    component: ExportTrackingPagePolaris,
    title: "Export tracking group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/export-tracking-history",
    exact: true,
    component: HistoryExportTrackingPagePolaris,
    title: "History export tracking group",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/artworks",
    exact: true,
    component: PersonalizedPage,
    title: "Artworks",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/cliparts",
    exact: true,
    component: PersonalizedPage,
    title: "Cliparts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/personalized/fonts",
    exact: true,
    component: PersonalizedPage,
    title: "Fonts",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/create-manual-order",
    exact: true,
    component: CreateManualOrderPagePolaris,
    title: "Create Manual Order",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/stores/fb-marketplace",
    exact: true,
    component: FBMarketplacePagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/stores/fb-marketplace/add",
    exact: true,
    component: CreateStore,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/stores/fb-marketplace/edit/:id",
    exact: true,
    component: Edit,
    title: "Create store",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/reports/overviews",
    exact: true,
    component: OverviewsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/product-from-print-file",
    exact: true,
    component: ProductFromPrintFilePage,
    title: "Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/product-from-print-file/create",
    exact: true,
    component: CreateProductFromPrintFile,
    title: "Create Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/product-from-print-file/edit/:id",
    exact: true,
    component: EditProductFromPrintFile,
    title: "Edit Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/product-from-print-file/clone/:id",
    exact: true,
    component: CloneProductFromPrintFile,
    title: "Clone Product From Print File Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/tools/product-crawlers",
    exact: true,
    // component: ProductCrawlersPage,
    component: ProductCrawlersPagePolaris,
    layout: MainLayout,
    title: "All Crawlers",
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/tools/product-crawlers/:id",
    exact: true,
    // component: ProductCrawlerByIdPage,
    component: ProductCrawlerPagePolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/tools/product-assortment",
    exact: true,
    component: ProductAssortment,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
    private: true,
  },
  {
    path: "/store-manager/products/product-push/add",
    exact: true,
    component: NewPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/product-push/:id",
    exact: true,
    component: EditPushProductsPolaris,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/products/replace-feed",
    exact: true,
    component: ReplaceFeedPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/stores/tiktok",
    exact: true,
    roles: ["Seller"],
    teamRole: "Store Manager",
    component: TiktokPage,
    layout: MainLayout,
  },
  {
    path: "/store-manager/stores/tiktok/add",
    exact: true,
    component: AddTiktokAccountPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/tiktok-hub/promotions",
    exact: true,
    component: lazy(() => import("../pages/seller/tiktok/promotion")),
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
  {
    path: "/store-manager/tiktok-hub/promotions/create",
    exact: true,
    component: lazy(() => import("../pages/seller/tiktok/createPromotion")),
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Store Manager",
  },
];
