import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Skeleton,
  Tabs,
  Select,
  Checkbox,
  DatePicker,
  Input,
  Row,
  Col,
} from "antd";
import { Query, ApolloConsumer } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";
import styled from "styled-components";
import ImageUpload from "./ImageUpload";
import MediaFileDetails from "./MediaFileDetails";
import InfiniteScroll from "react-infinite-scroller";
import MediaImportUrls from "./MediaImportUrls";

export const LOAD_MEDIA_QUERY = gql`
  query mediaLibrary($filter: FileFilter) {
    mediaLibrary(filter: $filter) {
      total
      nodes {
        id
        name
        alt
        caption
        thumbnailUrl
        url
        mimeType
        size
        createdAt
      }
    }
  }
`;

const ImageTypes = ["image/png", "image/jpeg", "image/gif"];
const Container = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
  .inner {
    padding: 10px 20px;
  }
  .ant-tabs-bar {
    margin: 0;
  }
  .filter {
    margin-bottom: 20px;
    margin-top: 10px;
    @media (min-width: 768px) {
      display: flex;
    }
    .filter-left {
      flex-grow: 1;
      .ant-calendar-picker {
        width: 250px;
      }
    }
  }
  .files-container {
    display: flex;
    flex-wrap: wrap;
    .file {
      width: 150px;
      height: 150px;
      background: rgb(214, 224, 233);
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: rgb(137, 157, 173);
      margin-right: 5px;
      margin-bottom: 5px;
      position: relative;
      .check {
        position: absolute;
        right: 0;
        top: -5px;
        color: rgb(0, 114, 216);
      }
    }
  }
  .media-library-tab {
    .ant-tabs-top-bar {
      padding-left: 20px;
    }
  }
  .picture-outlined {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 20px;
    }
  }
`;

const Thumbnail = styled.div`
  width: 150px;
  height: 150px;
  background: url("${(props) => props.url}");
  background-size: cover;
  background-color: var(--omg-custom-bg, inherit) !important;
`;

class Media extends Component {
  state = {
    filter: {
      search: null,
      mimeType: [],
      dateFilter: {
        from: null,
        to: null,
      },
      limit: 20,
      offset: 0,
      prefix: this.props.prefix,
      ...(this.props.userId ? { userId: this.props.userId } : null),
    },
    mimeTypeIndex: 0,
  };
  isImage = (file) => {
    return ImageTypes.includes(file.mimeType);
  };
  handleUpdateCache = (file, client) => {
    const { filter } = this.state;
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total - 1,
            nodes: cache.mediaLibrary.nodes.map((f) => {
              if (f.id === file.id) {
                return { ...f, file };
              }
              return f;
            }),
          },
        },
      });
    } catch (e) {}
  };
  handleWriteCache = (file, client, adding = false) => {
    const { filter } = this.state;
    try {
      const cache = client.readQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
      });
      client.writeQuery({
        query: LOAD_MEDIA_QUERY,
        variables: {
          filter,
        },
        data: {
          ...cache,
          mediaLibrary: {
            ...cache.mediaLibrary,
            total: cache.mediaLibrary.total - 1,
            nodes: adding
              ? [...file, ...cache.mediaLibrary.nodes]
              : cache.mediaLibrary.nodes.filter((f) => f.id !== file.id),
          },
        },
      });
    } catch (e) {}
  };

  render() {
    const { filter } = this.state;
    const { value, multiple } = this.props;
    const mimeTypeOptions = [[], ImageTypes];

    const len = value.length;
    return (
      <Container>
        <Tabs
          className={"media-library-tab"}
          animated={false}
          defaultActiveKey="2"
          onChange={(activeKey) => this.props.activeTab(activeKey)}
        >
          <Tabs.TabPane tab={"Import from Urls"} key="0">
            <div className="inner">
              <MediaImportUrls
                onChange={(v) => {
                  this.props.onImportUrlsChange(v);
                }}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Upload Files" key="1">
            <div className={"inner"}>
              <ApolloConsumer>
                {(client) => (
                  <ImageUpload
                    accept={this.props.accept}
                    folder={this.props.folder}
                    isPrintFile={this.props.isPrintFile}
                    onChange={(files) => {
                      let newValue = [];
                      if (files && files.length) {
                        if (multiple) {
                          for (let i = 0; i < files.length; i++) {
                            if (!value.find((v) => v.id === files[i].id)) {
                              newValue.push(files[i]);
                            }
                          }
                          newValue = [...value, ...newValue];
                        } else {
                          newValue = files.length ? [files[0]] : [];
                        }
                      }
                      this.handleWriteCache(files, client, true);
                      this.props.onChange(newValue);
                    }}
                    multiple={multiple}
                    uploadDescription={"Drop files to upload"}
                    uploadButtonTitle={"Select File"}
                    handleChangeOkBtnDisabled={
                      this.props.handleChangeOkBtnDisabled
                    }
                  />
                )}
              </ApolloConsumer>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Media Library" key="2">
            <Row type="flex" gutter={15} style={{ margin: 0 }}>
              <Col span={18} style={{ padding: 0, margin: 0 }}>
                <div className={"inner"}>
                  <div className={"filter"}>
                    <div className={"filter-left"}>
                      <Select
                        style={{ width: 150, marginRight: 10 }}
                        onChange={(v) => {
                          this.setState({
                            mimeTypeIndex: v,
                            filter: {
                              ...filter,
                              mimeType: mimeTypeOptions[v],
                            },
                          });
                        }}
                        value={this.state.mimeTypeIndex}
                      >
                        <Select.Option value={0}>All</Select.Option>
                        <Select.Option value={1}>Images</Select.Option>
                      </Select>
                      <DatePicker.RangePicker
                        onChange={(d) => {
                          this.setState({
                            filter: {
                              ...filter,
                              dateFilter: {
                                from: d[0],
                                to: d[1],
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    <div className={"filter-right"}>
                      <Input.Search
                        onSearch={(val) => {
                          this.setState({
                            filter: {
                              ...filter,
                              search: val,
                            },
                          });
                        }}
                        placeholder={"Search media items."}
                        onChange={(e) => {
                          if (!e.target.value) {
                            this.setState({
                              filter: {
                                ...filter,
                                search: null,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <Query
                    // fetchPolicy="network-only"
                    query={LOAD_MEDIA_QUERY}
                    variables={{
                      filter,
                    }}
                  >
                    {({ loading, error, data, fetchMore }) => {
                      if (loading) return <Skeleton active />;
                      if (error)
                        return <div>Error:{handleError(error.toString())}</div>;
                      return (
                        <div style={{ height: 620, overflow: "auto" }}>
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={() => {
                              if (
                                data.mediaLibrary.nodes.length <
                                data.mediaLibrary.total
                              ) {
                                fetchMore({
                                  variables: {
                                    filter: {
                                      ...filter,
                                      offset: data.mediaLibrary.nodes.length,
                                    },
                                  },
                                  updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev;
                                    return {
                                      ...prev,
                                      mediaLibrary: {
                                        ...prev.mediaLibrary,
                                        nodes: [
                                          ...prev.mediaLibrary.nodes,
                                          ...fetchMoreResult.mediaLibrary.nodes,
                                        ],
                                      },
                                    };
                                  },
                                });
                              }
                            }}
                            hasMore={
                              data.mediaLibrary.nodes.length <
                              data.mediaLibrary.total
                            }
                            loader={
                              <div className="loader" key={0}>
                                <Skeleton active />
                              </div>
                            }
                            useWindow={false}
                          >
                            <div className={"files-container"}>
                              {data.mediaLibrary.nodes.map((file, index) => (
                                <div
                                  onClick={() => {
                                    let newValue;
                                    if (value.find((v) => v.id === file.id)) {
                                      newValue = value.filter(
                                        (v) => v.id !== file.id,
                                      );
                                    } else {
                                      if (multiple) {
                                        newValue = [...value, file];
                                      } else {
                                        newValue = [file];
                                      }
                                    }
                                    this.props.onChange(newValue);
                                  }}
                                  title={file.name}
                                  key={index}
                                  className={"file"}
                                >
                                  <div
                                    style={{
                                      visibility: value.find(
                                        (v) => v.id === file.id,
                                      )
                                        ? "visible"
                                        : "hidden",
                                    }}
                                    className={"check"}
                                  >
                                    <Checkbox checked={true} />
                                  </div>
                                  {this.isImage(file) ? (
                                    <Thumbnail
                                      url={
                                        file.thumbnailUrl
                                          ? file.thumbnailUrl
                                          : file.url
                                      }
                                    />
                                  ) : (
                                    <>
                                      <div className="picture-outlined">
                                        <span>
                                          {file && file.name
                                            ? file.name.split(".").pop()
                                            : null}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </InfiniteScroll>
                        </div>
                      );
                    }}
                  </Query>
                </div>
              </Col>
              <Col
                style={{
                  borderLeft: "1px solid #e2e8f0",
                  padding: 0,
                  margin: 0,
                }}
                span={6}
              >
                {len > 0 && (
                  <div className={"inner"}>
                    {value.length > 0 && (
                      <MediaFileDetails
                        onChange={(file, client) => {
                          value[len - 1] = file;
                          this.props.onChange(value);
                          this.handleUpdateCache(file, client);
                        }}
                        onDeleted={(file, client) => {
                          value.splice(len - 1, 1);
                          this.props.onChange(value);
                          this.handleWriteCache(file, client);
                        }}
                        isImage={this.isImage(value[len - 1])}
                        value={value[len - 1]}
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Container>
    );
  }
}

Media.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  folder: PropTypes.string,
  prefix: PropTypes.string,
  onImportUrlsChange: PropTypes.func,
  accept: PropTypes.string,
  isPrintFile: PropTypes.bool,
};

export default Media;
