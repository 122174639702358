import React, { useState, useCallback, useEffect } from "react";
import { DatePicker, TextField, Popover, Button, Icon } from "@shopify/polaris";
import { CalendarMajorMonotone } from "@shopify/polaris-icons";
import moment from "moment";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export const DesignTaskDeadlinePolaris = (props) => {
  const { value, onChange } = props;

  // State
  const [input, setInput] = useState("");
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: value || new Date(),
    end: value || new Date(),
  });

  // Get data
  useEffect(() => {
    let input = value ? moment(value).format("YYYY-MM-DD") : null;
    setInput(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [popoverActive, setPopoverActive] = useState(false);

  // Handle action
  const togglePopoverActive = useCallback(
    () => setPopoverActive((prev) => !prev),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );
  const handleDateChange = useCallback((range) => {
    setSelectedDates(range);
    setInput(moment(range.start).format("YYYY-MM-DD"));
    setPopoverActive(false);
    if (onChange) {
      onChange(moment(range.start).startOf("day"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToday = useCallback((clear) => {
    let range = {
      start: new Date(),
      end: new Date(),
    };
    setSelectedDates(range);
    let value = moment(range.start).startOf("day");
    if (clear === "clear") {
      value = null;
      setPopoverActive(false);
    }
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activator = (
    <TextField
      label="Deadline"
      labelHidden
      placeholder="Select date"
      value={input}
      onFocus={() => setPopoverActive(true)}
      clearButton
      onClearButtonClick={() => {
        handleChangeToday("clear");
        setInput("");
      }}
      suffix={
        input ? null : (
          <div style={{ cursor: "pointer" }}>
            <Icon source={CalendarMajorMonotone} color="inkLighter" />
          </div>
        )
      }
    />
  );

  let yesterday = new Date(new Date());
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <React.Fragment>
      <ComponentLabelPolaris label="Deadline" />
      <Popover
        activator={activator}
        active={popoverActive}
        onClose={togglePopoverActive}
        fullWidth
        fullHeight
        sectioned
      >
        <>
          <DatePicker
            month={month}
            year={year}
            selected={selectedDates}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            disableDatesBefore={yesterday}
          />
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "center",
            }}
          >
            <Button
              children="Today"
              plain
              onClick={() => {
                handleChangeToday();
                setInput(moment(new Date()).format("YYYY-MM-DD"));
              }}
            />
          </div>
        </>
      </Popover>
    </React.Fragment>
  );
};
