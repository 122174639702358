import React, { useState, useCallback, useEffect } from "react";
import { Modal, Toast } from "@shopify/polaris";
import { Mutation } from "@apollo/react-components";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { RETRACK_FULFILLMENT_ORDER } from "../../../graphql/mutations";

export const RetrackPolaris = (props) => {
    const { open, toggleShowModal, value, refetch } = props;
    const orderId = value?.id;
    // useLockBodyScroll(open);

    const [activeToast, setActiveToast] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [notifications, setNotifications] = useState({
        message: null,
        isError: null,
    });

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(() => {
        setActiveToast((activeToast) => !activeToast);
    }, []);

    const toastMarkup = activeToast
        ? notifications &&
          notifications.message && (
              <Toast
                  content={notifications.message}
                  error={notifications.isError}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            <Mutation
                mutation={RETRACK_FULFILLMENT_ORDER}
                onCompleted={() => {
                    setNotifications(() => ({
                        message: "Retrack success",
                        isError: false,
                    }));
                    const id = setTimeout(() => {
                        toggleShowModal(false);
                        if (refetch) refetch();
                    }, 2100);
                    setTimeoutId(id);
                }}
                onError={(err) => {
                    setNotifications(() => ({
                        message: err.toString(),
                        isError: true,
                    }));
                    const id = setTimeout(() => {
                        toggleShowModal(false);
                    }, 2100);
                    setTimeoutId(id);
                }}
            >
                {(reTrackFulfillmentOrder, { loading }) => (
                    <Modal
                        title={`Order #${orderId ? orderId : ""}`}
                        open={open}
                        onClose={toggleShowModal}
                        sectioned
                        primaryAction={{
                            content: "Re track",
                            onAction: () => {
                                reTrackFulfillmentOrder({
                                    variables: {
                                        orderId,
                                    },
                                });
                                toggleActive();
                            },
                            loading: loading,
                        }}
                    >
                        <p id="retrack_modal">
                            Are you sure to re track this order?
                        </p>
                    </Modal>
                )}
            </Mutation>
        </>
    );
};
