import { gql } from 'apollo-boost';

export const STORE_EVENTS = gql`
  subscription storeEvents {
    storeEvents {
      event
      store {
        id
        domain
      }
    }
  }
`;