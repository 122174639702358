import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback } from "react";
import { PUBLISH_PRODUCT_BASES } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export function PublishBase({
  open,
  onClose,
  ids,
  onCompleted,
  toggleActive: toggleToast,
  onNotificationChange: setNotify,
}) {
  // Mutation
  const [publish, { loading }] = useMutation(PUBLISH_PRODUCT_BASES, {
    onCompleted: () => {
      setNotify({ message: "Publish product bases success.", isError: false });

      onCompleted && onCompleted();
    },
    onError: (err) => {
      setNotify({ message: handleError(err.toString()), isError: true });
    },
  });

  const handleSubmit = useCallback(() => {
    if (!ids || ids.length === 0) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ message: null, isError: false });
    publish({
      variables: {
        ids,
      },
    });
  }, [ids, publish, toggleToast, setNotify]);

  return (
    <Modal
      title="Publish Product Bases"
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      sectioned
    >
      <p>Are you sure to publish this base?</p>
    </Modal>
  );
}
