import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList, TextField, Spinner } from "@shopify/polaris";
import styled from "styled-components";
import { handleError } from "../../helper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .Polaris-ChoiceList__Choices {
    max-height: 280px;
    overflow-y: scroll;
    padding-left: 2px;
  }
`;

export const FilterNoSearchMVPolaris = React.forwardRef((props, ref) => {
  const {
    data,
    value,
    onChange,
    onChangeSearch,
    noSearch,
    loading,
    error,
    limit: limitProp,
  } = props;
  const [inputValue, setInputValue] = useState(null);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  const limit = limitProp ?? 20;
  useEffect(() => {
    let newData = data && data.length > 0 ? data.slice(0, limit) : [];
    setOptions(newData);
    setDeselectedOptions(data);
  }, [data, limit]);

  useEffect(() => {
    if (value) {
      setSelected(value.value);
      if (value.search) {
        setInputValue(value.search);
        const filterRegex = new RegExp(value.search, "i");
        const resultOptions = deselectedOptions.filter((option) =>
          option.label.match(filterRegex),
        );
        let newOptions = resultOptions.slice(0, limit);
        setOptions(newOptions);
      }
    }
  }, [deselectedOptions, value, limit]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (onChangeSearch) {
        onChangeSearch({ search: value });
      }

      if (!value) {
        let newDO = deselectedOptions.slice(0, limit);
        setOptions(newDO);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      );
      let newOptions = resultOptions.slice(0, limit);
      setOptions(newOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions, limit],
  );

  const handleSelect = useCallback(
    (selected) => {
      setSelected(selected);
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = deselectedOptions.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      if (onChange) {
        onChange({ value: selected, label: selectedValue });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions],
  );

  React.useImperativeHandle(ref, () => ({
    getOptions: () => options,
  }));

  return (
    <Container>
      {!noSearch && (
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Filter item"
        />
      )}
      {loading ? (
        <Spinner size="small" />
      ) : error ? (
        <div>{handleError(error.toString())}</div>
      ) : options.length > 0 ? (
        <ChoiceList
          choices={options}
          onChange={handleSelect}
          selected={selected}
          allowMultiple
        />
      ) : (
        <span>No item found!</span>
      )}
    </Container>
  );
});
