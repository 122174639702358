import React, { useState, useEffect } from "react";
import { DataTable, Button } from "@shopify/polaris";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const ExportsDataTablePolaris = ({ data, filter, setFilter }) => {
  // State
  const [rows, setRows] = useState([]);

  // Get data
  useEffect(() => {
    let newRows =
      data?.exports?.nodes?.length > 0
        ? data.exports.nodes.map((item) => {
            // File
            const file = item?.file;
            const fileName = file?.name;
            const fileUrl = file?.url;

            // Supplier
            const supplier = item?.supplier;
            const fullName = `${supplier?.firstName} ${supplier?.lastName}`;

            // Template
            const template = item?.template;
            const templateName = template?.name;

            // Created at
            const createdAt = moment(item?.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            return [
              <div className="file-name-wrap">
                <span>{fileName}</span>
              </div>,
              <div className="supplier-wrap">
                <span>{fullName}</span>
              </div>,
              <div className="template-wrap">
                <span>{templateName}</span>
              </div>,
              <div className="export-date-wrap">
                <span>{createdAt}</span>
              </div>,
              <div className="actions-wrap">
                <Button plain children="Download" url={fileUrl} />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
  }, [data]);

  let { limit, offset } = filter;
  const total = get(data, "exports.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  const colType = [];
  for (let i = 0; i < 4; i++) {
    colType.push("text");
  }
  colType.push("numeric");

  return (
    <Container>
      <DataTable
        rows={rows}
        columnContentTypes={colType}
        headings={[
          "File name",
          "Supplier",
          "Template",
          "Export date",
          "Action",
        ]}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
