import React from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";

import { TEAM_MEMBERS } from "../report/ReportFilters";

import { FilterTimeByPolaris } from "../FilterTimeByPolaris";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

const All = "All";

export const StatisticFilter = ({ onChange, filter }) => {
    const assigneeId = filter?.userId;
    // State
    const [search, setSearch] = React.useState(filter?.search ?? "");
    const [dataDesigner, setDataDesigner] = React.useState([]);
    const [designer, setDesigner] = React.useState({
        value: assigneeId,
        label: null,
        search: null,
    });

    const [filterTime, setFilterTime] = React.useState({
        range: null,
        rangeLabel: null,
        field: "task_submissions.created_at",
        fieldLabel: null,
    });

    // Ref
    const typingTimeoutRef = React.useRef(null);

    // Query
    const { data, loading, error } = useQuery(TEAM_MEMBERS);

    // Get data
    React.useEffect(() => {
        const newData =
            data?.teamMembers?.hits?.length > 0
                ? data.teamMembers.hits
                      .filter((i) => i?.user)
                      .filter(Boolean)
                      .map(({ user }) => ({
                          value: user.id,
                          label: `${user.firstName} ${user.lastName}`,
                      }))
                : [];
        setDataDesigner(newData);

        if (assigneeId) {
            let currentD =
                newData && newData.length > 0
                    ? newData.find((i) => i.value === assigneeId)
                    : null;
            if (currentD) {
                setDesigner((prev) => ({
                    ...prev,
                    label: currentD.label,
                    value: currentD.value,
                }));
            }
        }
    }, [data, assigneeId]);

    // Handle actions
    React.useEffect(() => {
        typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);

        const newFieldByTime = { field: filterTime.field };
        if (filterTime?.range?.from) {
            newFieldByTime["from"] = filterTime.range.from;
        }
        if (filterTime?.range?.to) {
            newFieldByTime["to"] = filterTime.range.to;
        }

        typingTimeoutRef.current = setTimeout(() => {
            if (onChange) {
                onChange({
                    search: search ? search.trim() : null,
                    userId: designer.value,
                    fieldByTime:
                        Object.keys(newFieldByTime).length > 1
                            ? { ...newFieldByTime }
                            : null,
                });
            }
        }, 300);
    }, [search, onChange, designer, filterTime]);

    const handleSearchChange = React.useCallback(
        (value) => setSearch(value),
        []
    );
    const handleFilterTimeRemove = React.useCallback(
        () =>
            setFilterTime((prev) => ({
                ...prev,
                range: null,
                rangeLabel: null,
            })),
        []
    );

    // Remove action
    const handleSearchRemove = React.useCallback(() => setSearch(null), []);
    const handleDesignerRemove = React.useCallback(
        () => setDesigner({ value: null, label: null }),
        []
    );

    const filtersClear = [
        handleSearchRemove,
        handleDesignerRemove,
        handleFilterTimeRemove,
    ];

    const handleFilterClearAll = React.useCallback(() => {
        for (let fn of filtersClear) {
            fn && fn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...filtersClear]);

    // Markup
    const filters = [
        {
            key: "designer",
            label: "Designer",
            tagLabel: designer.label,
            onRemove: handleDesignerRemove,
            filter: (
                <FilterNoSearchPolaris
                    data={dataDesigner}
                    value={designer}
                    loading={loading}
                    error={error}
                    onChange={({ value, label }) =>
                        setDesigner((prev) => ({ ...prev, value, label }))
                    }
                    onChangeSearch={({ search }) =>
                        setDesigner((prev) => ({ ...prev, search }))
                    }
                />
            ),
        },
        {
            key: "filterTime",
            label: "Filter Time",
            tagLabel: filterTime.rangeLabel,
            onRemove: handleFilterTimeRemove,
            filter: (
                <FilterTimeByPolaris
                    filterTime={filterTime}
                    ignoreForVal
                    onChangeRange={(range, rangeLabel) => {
                        setFilterTime((filterTime) => ({
                            ...filterTime,
                            range,
                            rangeLabel,
                        }));
                    }}
                />
            ),
        },
    ];

    const appliedFilters = [];
    for (let { key, label, onRemove, tagLabel } of filters) {
        if (!isEmpty(tagLabel) && All !== tagLabel) {
            appliedFilters.push({
                key,
                label: disambiguateLabel(
                    key === "filterTime" ? "Time" : label,
                    tagLabel
                ),
                onRemove,
            });
        }
    }

    return (
        <Filters
            queryValue={search}
            filters={filters}
            appliedFilters={appliedFilters}
            queryPlaceholder="Filter designer"
            onQueryChange={handleSearchChange}
            onQueryClear={handleSearchRemove}
            onClearAll={handleFilterClearAll}
        />
    );
    function disambiguateLabel(label, value) {
        if (!label) {
            return value;
        }
        return `${label}: ${value}`;
    }
};
