import { useQuery } from "@apollo/react-hooks";
import { Autocomplete, Labelled, Spinner, Stack } from "@shopify/polaris";
import React from "react";
import { GET_DESIGN_ONLINE_TEAMS } from "../../../graphql/queries";
import { arrInvalid, objectInvalid, reducerFn } from "../../../helper";

const OnlineDesignTeamsSelect = React.forwardRef(OnlineDesignTeamsSelectImpl);
export default OnlineDesignTeamsSelect;

function OnlineDesignTeamsSelectImpl({ value }, ref) {
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    search: "",
    status: true,
  });

  const { data, loading, error } = useQuery(GET_DESIGN_ONLINE_TEAMS, {
    variables: {
      filter,
    },
  });

  const [state, setState] = React.useReducer(reducerFn, {
    input: "",
    options: [],
    selectedOptions: [],
  });

  React.useEffect(() => {
    const nodes = data?.getDesignOnlineTeams?.nodes || [];
    const opts = nodes
      .map((item) => {
        if (objectInvalid(item)) return null;
        return {
          value: item.id,
          label: item.name,
        };
      })
      .filter(Boolean);

    setState((p) => {
      if (!arrInvalid(p.selectedOptions)) {
        const match = opts.find((i) => p.selectedOptions.includes(i?.value));
        if (match) {
          p.input = match.label;
        }
      }
      return { ...p, options: opts };
    });
  }, [data]);

  React.useEffect(() => {
    if (value) {
      setState((p) => {
        if (!arrInvalid(p.options)) {
          const match = p.options.find((i) => value === i?.value);
          if (match) {
            p.input = match.label;
          }
        }
        return { ...p, selectedOptions: [value] };
      });
    }
  }, [value]);

  const inputRef = React.useRef(null);
  const handleInputChange = React.useCallback((input) => {
    setState({ input });

    inputRef.current && clearTimeout(inputRef.current);
    inputRef.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search: input }));
    }, 500);
  }, []);

  const handleSelectedChange = React.useCallback(
    (selected) => {
      const match = state.options.find((opt) => selected.includes(opt.value));
      let newState = { selectedOptions: selected };

      if (match) {
        newState.input = match.label;
      }

      setState(newState);
    },
    [state.options],
  );

  React.useImperativeHandle(ref, () => ({
    getTeamId: () =>
      state.selectedOptions?.length > 0 ? state.selectedOptions[0] : null,
  }));

  const textFieldMarkup = (
    <Autocomplete.TextField
      placeholder="Choose Design Online Team"
      value={state.input}
      onChange={handleInputChange}
    />
  );

  return (
    <div style={{ marginTop: 10 }}>
      <Stack vertical spacing="tight">
        <Labelled children="Design Online Teams" />
        {loading ? (
          <Spinner size="small" />
        ) : error ? (
          <div>Error: {error?.toString()}</div>
        ) : (
          <Autocomplete
            textField={textFieldMarkup}
            options={state.options}
            selected={state.selectedOptions}
            onSelect={handleSelectedChange}
            emptyState={<span>No items found!</span>}
          />
        )}
      </Stack>
    </div>
  );
}
