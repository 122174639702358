import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
} from "../../../helper";
import history from "../../../history";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { ExportTemplateDataTablePolaris } from "./ExportTemplateDataTablePolaris";
import { ExportTemplateFilterPolaris } from "./ExportTemplateFilterPolaris";

export const EXPORT_TEMPLATE = gql`
  query exportTemplates($search: String) {
    exportTemplates(search: $search) {
      id
      name
      suppliers {
        id
        firstName
        lastName
      }
      author {
        id
      }
    }
  }
`;

export const ExportTemplatePolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      initialFilter = removeFieldWithoutFilter(initialFilter, ["search"]);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);
  // State
  const [filter, setFilter] = useState({
    search: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(EXPORT_TEMPLATE, {
    variables: {
      search: filter.search,
    },
    fetchPolicy: "no-cache",
  });

  // Handle action
  useEffect(() => {
    let params = null;
    params = convertObjectToParams(filter);
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <ExportTemplateFilterPolaris
          filter={filter}
          onChange={({ search }) => {
            setFilter((prev) => {
              if (!isEqual(prev.search, search)) {
                return {
                  ...prev,
                  search,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        {error ? (
          <div style={{ padding: "1.6rem" }}>
            {handleError(error.toString())}
          </div>
        ) : null}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data?.exportTemplates?.length > 0 ? (
          <ExportTemplateDataTablePolaris data={data} refetch={refetch} />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
