import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal } from "@shopify/polaris";

import { Mockup2DApp } from "../../../config";
import { getInfosForIFrame } from "./MockupTemplate";

const Completed = "Completed";
const Submit = "Submit";

export function UpdateMockupTemplate({
    open,
    toggleOpen,
    id,
    handleDone,
    designPositions,
}) {
    // Props
    const { dpsProp, userInfoProp } = getInfosForIFrame(designPositions);

    // State
    const [loading, setLoading] = useState(undefined);
    const iframeRef = useRef(null);
    const timeRef = useRef(null);

    // Markup
    const iframeStyle = {
        border: "none",
        width: "100%",
        height: "calc(100% - 1rem)",
    };

    //
    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            const parse = typeof data === "string" ? JSON.parse(data) : null;
            if (parse != null) {
                const { loading, msg } = parse;
                setLoading(loading);

                if (msg === Completed && open) {
                    clearTimeout(timeRef.current);
                    timeRef.current = setTimeout(() => {
                        handleDone(false);
                    }, 500);
                }
            }
        },
        [handleDone, open]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () =>
            window.removeEventListener("message", handleReceiveMessage, false);
    }, [handleReceiveMessage]);

    const handlePrimaryAction = useCallback(() => {
        const message = Submit;
        iframeRef.current &&
            iframeRef.current.contentWindow.postMessage(message, "*");
    }, []);

    useEffect(() => {
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );
        for (let domParent of domParents) {
            domParent.setAttribute(
                "style",
                "max-width: calc(100% - 20rem); height: calc(100vh)"
            );
            domParent.classList.add("mockup-2d");
        }
    });

    return (
        <Modal
            open={open}
            onClose={toggleOpen}
            title="Update Mockup Template"
            secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            primaryAction={{
                content: "Done",
                onAction: handlePrimaryAction,
                loading: loading,
            }}
        >
            <iframe
                ref={iframeRef}
                id="2d-mockup"
                title="Mockup Template"
                style={iframeStyle}
                src={`${Mockup2DApp}/mockup-templates/update/${id}?dps=${dpsProp}&user=${userInfoProp}`}
            />
        </Modal>
    );
}
