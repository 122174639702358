import React, { useState, useCallback, useRef } from "react";
import { Modal, TextField } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { UPLOAD_FOLDER } from "../../../variable";
import { UPDATE_PRODUCT_PSD_FILES } from "../../../graphql/mutations";

import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { useToastContext } from "../../shared/ToastContext";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    label {
        font-weight: 500;
    }
    .Polaris-DropZone {
        min-height: 11rem;
    }
`;

export const DesignResourceFilesPolaris = (props) => {
    // Prop
    const {
        open,
        toggleShowModal,
        onNotificationChange,
        toggleActive,
        refetch,
        task,
    } = props;
    // Block body scroll
    // useLockBodyScroll(open);
    const productId = task?.product?.id;
    const value = task?.product?.psdFiles;

    // State
    const [urls, setUrls] = useState(task?.product?.psd);
    const [fileIds, setFileIds] = useState([]);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    // Mutation
    const [updateProductPSDFiles, { loading }] = useMutation(
        UPDATE_PRODUCT_PSD_FILES,
        {
            onError: (error) => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: handleError(error.toString()),
                        isError: true,
                    });
                }
            },
            onCompleted: () => {
                toggleShowModal();
                if (onNotificationChange) {
                    onNotificationChange({
                        message: "Update design resource file success.",
                        isError: false,
                    });
                }

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    setFilter && setFilter((prev) => ({ ...prev }));
                    refetch && refetch();
                }, 1000);
            },
        }
    );

    // Handle action
    const handleSubmit = useCallback(() => {
        if (onNotificationChange) {
            onNotificationChange({
                message: null,
                isError: false,
            });
        }
        updateProductPSDFiles({
            variables: {
                productId,
                files: fileIds,
                psd: urls,
            },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, fileIds, urls]);

    const handleFileChange = useCallback((value) => {
        let fileIds =
            value && value.length > 0 ? value.map((file) => file.id) : [];
        setFileIds(fileIds);
    }, []);

    return (
        <Modal
            title="Update design resource"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Container>
                <div>
                    <label>Files:</label>
                    <MediaSelectorButtonPolaris
                        value={value}
                        prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                        folder={UPLOAD_FOLDER.PSD_RESOURCE}
                        multiple={true}
                        // accept={`image/*`}
                        onChange={handleFileChange}
                    />
                </div>
                <span className="or_wrap">Or</span>
                <div>
                    <label>Urls:</label>
                    <TextField
                        value={urls}
                        onChange={(value) => setUrls(value)}
                        multiline={4}
                    />
                </div>
            </Container>
        </Modal>
    );
};
