import { Banner, Heading, Stack, Tooltip } from "@shopify/polaris";
import get from "lodash/get";
import React from "react";
import styled from "styled-components";

export default function Layout({
  store,
  children,
  isDoingOrReject,
  invalidToken,
}) {
  const extraInfo = get(store, "extraInfo");
  const storeName = get(extraInfo, "friendly");
  const storeUrl = get(extraInfo, "url");
  const emailContact = get(extraInfo, "emailContact");
  const instagramLink = get(extraInfo, "instagramLink");
  const youtubeLink = get(extraInfo, "youtubeLink");
  const facebookLink = get(extraInfo, "facebookLink");

  // context
  return (
    <DesignProvider>
      <Wrapper>
        <div className="layout-header">
          <Stack distribution="fill" alignment="center">
            {storeName ? (
              <a href={storeUrl} target="_blank">
                <Heading element="h1" children={storeName} />
              </a>
            ) : null}
            <Stack distribution="trailing">
              {storeUrl ? (
                <a
                  href={storeUrl}
                  data-polaris-unstyled="true"
                  className="Polaris-Button om-external"
                  target="_blank"
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">View on Etsy</span>
                  </span>
                </a>
              ) : null}
            </Stack>
          </Stack>
        </div>

        {invalidToken ? null : (
          <div style={{ marginTop: "3rem", marginBottom: "1.6rem" }}>
            <Heading children="Your Custom Artwork is Ready! Review and Provide Your Feedback" />
          </div>
        )}

        {isDoingOrReject ? null : <StatusComp />}

        {children}

        <div className="layout-footer">
          {emailContact ? (
            <>
              <Heading element="h3" children="Have a question?" />
              <span>{emailContact}</span>
            </>
          ) : null}

          <div className="social-icons">
            {facebookLink ? (
              <SocialItem title="Facebook" link={facebookLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                >
                  <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                </svg>
              </SocialItem>
            ) : null}
            {instagramLink ? (
              <SocialItem title="Instagram" link={instagramLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                >
                  <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                </svg>
              </SocialItem>
            ) : null}
            {youtubeLink ? (
              <SocialItem title="Youtube" link={youtubeLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                >
                  <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                </svg>
              </SocialItem>
            ) : null}
          </div>
        </div>
      </Wrapper>
    </DesignProvider>
  );
}

function SocialItem({ children, title, link }) {
  return (
    <div className="social-item">
      <a href={link} data-polaris-unstyled="true" target="_blank">
        <Tooltip content={title || "social icon"}>{children}</Tooltip>
      </a>
    </div>
  );
}

function StatusComp() {
  const { status } = useDesignCtx();
  const bannerProps = bannerMessage[status];

  return bannerProps != null ? (
    <div style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}>
      <Banner {...bannerProps} status="success" />
    </div>
  ) : null;
}

// Context
const DesignContext = React.createContext();
const DesignProvider = ({ children }) => {
  const [status, setStatus] = React.useState("");

  return (
    <DesignContext.Provider value={{ status, setStatus }}>
      {children}
    </DesignContext.Provider>
  );
};

export const useDesignCtx = () => React.useContext(DesignContext);

const bannerMessage = {
  approval: {
    title:
      "Thank you for your feedback! We appreciate you taking the time to review the artwork.",
    children: (
      <p>
        You input is valuable, and we'll proceed with processing your order
        right away.
      </p>
    ),
  },
  requestChanges: {
    title:
      "Thank you for your feedback! We appreciate you taking the time to review the artwork.",
    children: (
      <p>
        We'll make the request adjustments and get back to you with an updated
        artwork shortly.
      </p>
    ),
  },
  done: {
    title: "Your order is being processed",
    children: (
      <p>
        Your order is being processed by our team. We'll be working on your
        artwork and will keep you updated with the next steps soon. Thanks for
        your patience!
      </p>
    ),
  },
  take: {
    title: "Thank you for your feedback!",
    children: (
      <p>
        We appreciate you taking the time to review the artwork. Your response
        is valuable and will help us ensure everything meets your expectations.
      </p>
    ),
  },
};

const Wrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 0px;

  @media (min-width: 1024px) {
    margin-top: 30px;
    padding: 0;
  }

  a {
    color: initial;
  }

  .Polaris-Stack__Item:has(.om-external) {
    margin-left: 1.6rem;
    margin-right: auto;

    @media (min-width: 350px) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  .layout-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    margin-bottom: 16px;

    h1.Polaris-Heading {
      font-size: 24px;
    }
  }

  .layout-footer {
    margin-top: 2rem;
    width: 100vw;
    left: 50%;
    position: relative;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #ddd;
    // z-index: 100;

    @media (min-width: 1024px) {
      padding: 30px 0;
    }
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin-top: 1rem;
  }

  .social-item {
    width: 50px;
    height: 50px;
    padding: 5px;
    border: 1px solid #b9b9b9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #8c8c8c;
    }

    > a {
      display: block;
      width: 32px;
      height: 32px;
    }
  }
`;
