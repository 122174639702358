import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Select, Stack, TextStyle } from "@shopify/polaris";

import { KPI } from "../../../../../constants/task";
import { useMutation } from "@apollo/react-hooks";
import { ComponentLabelPolaris } from "../../../../shared/ComponentLabelPolaris";
import { IDEA_LEADER_PUBLISH_IDEA_TASK } from "../../../../../graphql/mutations";
import { useToastContext } from "../../../../shared/ToastContext";
import { handleError } from "../../../../../helper";

const KPI_OPTIONS = KPI.map((i) => ({ value: String(i), label: String(i) }));

export function Publish({ item, onCloseParent, ...props }) {
    // Props
    const ideaTaskId = item?.id;
    const ideaKpi = item?.ideaKpi != null ? String(item.ideaKpi) : "";

    // State
    const [kpi, setKpi] = useState(ideaKpi || "0.5");
    const onCloseRef = useRef(null);

    // Query

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [publishIdeaTask, { loading }] = useMutation(
        IDEA_LEADER_PUBLISH_IDEA_TASK,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Published idea task successfully.",
                    err: false,
                });
                props.onClose && props.onClose();
                onCloseParent && onCloseParent();

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    refetch && refetch();
                    setFilter && setFilter((prev) => ({ ...prev }));
                }, 1000);
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    // Get data
    useEffect(() => {
        setKpi(() => ideaKpi);
    }, [ideaKpi]);

    // Actions
    const handleKpi = useCallback((value) => setKpi(value), []);
    const handleSubmit = useCallback(() => {
        if (ideaTaskId != null && kpi) {
            const newKpi = parseFloat(kpi);
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });

            publishIdeaTask({
                variables: {
                    id: ideaTaskId,
                    kpi: newKpi,
                },
            });
        }
    }, [ideaTaskId, kpi, publishIdeaTask, toggleToast, setNotify]);

    return (
        <Modal
            {...props}
            sectioned
            title="Publish Idea"
            large
            secondaryActions={[{ content: "Close", onAction: props.onClose }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Stack vertical>
                <TextStyle children="Are you sure to publish this idea?" />
                <div>
                    <ComponentLabelPolaris label="Idea KPI" required />
                    <Select
                        options={KPI_OPTIONS}
                        value={kpi}
                        onChange={handleKpi}
                    />
                </div>
            </Stack>
        </Modal>
    );
}
