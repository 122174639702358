import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { HistoryExportTrackingPolaris } from "../../components/export-tracking/HistoryExportTrackingPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const HistoryExportTrackingPagePolaris = () => {
  return (
    <Container>
      <Page title="History Export Tracking" fullWidth>
        <HistoryExportTrackingPolaris />
      </Page>
    </Container>
  );
};
