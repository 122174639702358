import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, Tag, Checkbox } from "@shopify/polaris";
import _ from "lodash";
import styled from "styled-components";

import { getUnique } from "../../helper";
import { TEAM_ROLE } from "../../variable";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const Container = styled.div`
  div[role="combobox"]:focus {
    outline: none;
  }
`;

const CheckAllContainer = styled.div`
  label.Polaris-Choice {
    display: flex;
    width: 100%;
    min-height: 3.6rem;
    padding: 0.8rem 1.6rem;
    text-align: left;
    overflow-y: hidden;
    cursor: default;
    :hover {
      background-image: linear-gradient(
        rgba(223, 227, 232, 0.3),
        rgba(223, 227, 232, 0.3)
      );
    }
  }
  .Polaris-Choice__Label {
    :hover {
      cursor: default;
    }
  }
`;

export const FieldExtraPolaris = ({
  data,
  label,
  onChange,
  storeIds,
  isMarketplaceManager,
  role,
  labelHidden,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);
  const [checked, setChecked] = useState(false);

  let showSelectAllStore = false;
  if (role && role.length) {
    if (role.includes(TEAM_ROLE.Supporter)) {
      showSelectAllStore = true;
    }
  }

  useEffect(() => {
    const newData =
      data && data.stores && data.stores.nodes && data.stores.nodes.length
        ? data.stores.nodes.map((u) => {
            return {
              value: u.id,
              label: u.title,
            };
          })
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
    setSelectedOptions(storeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = useCallback(
    (newChecked) => {
      setChecked(newChecked);
      let checkAll = newChecked ? options.map((i) => i.value) : [];
      handleSelectionChange(checkAll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  useEffect(() => {
    handleSelectionChange(storeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, deselectedOptions]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectionChange = useCallback(
    (selected) => {
      setSelectedOptions(selected);
      let newData = _.get(data, "stores.nodes", []);
      let couple =
        newData && newData.length > 0
          ? newData.filter((d) => selected.includes(d.id))
          : [];

      setDeselectedOptionsShow(() => {
        let result = getUnique([...couple], "id");
        return result;
      });

      if (onChange) {
        onChange(selected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, data]
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.id !== value
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptionsShow]
  );

  const textField = (
    <Autocomplete.TextField
      label={isMarketplaceManager ? "Assign To Stores" : label}
      value={inputValue}
      placeholder="Search store"
      onChange={handleInputChange}
      labelHidden={true}
    />
  );

  const contentBeforeMarkup = (
    <CheckAllContainer>
      <Checkbox
        label="Select all stores"
        checked={checked}
        onChange={handleChange}
      />
    </CheckAllContainer>
  );

  return (
    <Container>
      {!labelHidden ? (
        <ComponentLabelPolaris
          label={isMarketplaceManager ? "Assign To Stores" : label}
        />
      ) : null}
      <Autocomplete.ComboBox
        allowMultiple
        options={options}
        selected={selectedOptions}
        textField={textField}
        onSelect={handleSelectionChange}
        emptyState={<span>No items found</span>}
        contentBefore={showSelectAllStore ? contentBeforeMarkup : null}
      />
      <TagContainer>
        {deselectedOptionsShow && deselectedOptionsShow.length > 0
          ? deselectedOptionsShow.map((item) => (
              <Tag
                children={item.title}
                onRemove={() => handleRemove(item.id)}
                key={item.id}
              />
            ))
          : null}
      </TagContainer>
    </Container>
  );
};
