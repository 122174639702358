import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, DataTable, Badge } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import styled from "styled-components";

import { handleError } from "../../helper";

import { PaginationPolaris } from "../shared/PaginationPolaris";
import ModalImageClaimPolaris from "../seller/ModalImageClaimPolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";

const QUERY_PREVIEW_PRODUCTS = gql`
    query previewProducts($filter: ProductPreviewFilter) {
        previewProducts(filter: $filter) {
            total
            nodes {
                id
                title
                sku
                productBases {
                    id
                    title
                }
                images {
                    id
                    file {
                        id
                        thumbnailUrl
                        url
                    }
                }
            }
        }
    }
`;

const Container = styled.div`
    .Polaris-DataTable__Footer {
        overflow-x: scroll;
    }
    .product_wrap {
        width: calc(400px - 3.2rem);
        white-space: normal;
        word-break: break-word;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }
    .image_wrap,
    .id_wrap,
    .product-base_wrap {
        width: calc(150px - 3.2rem);
        white-space: normal;
        word-break: break-word;
    }
`;

export const PreviewMatchedProductsPolaris = (props) => {
    // Prop
    const { filter: filterProp, title, excludePersonalizedProducts } = props;

    // State
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [canQuery, setCanQuery] = useState(false);
    const [filter, setFilter] = useState({
        limit: 20,
        offset: 0,
    });

    // Get data
    const { loading, data, error } = useQuery(QUERY_PREVIEW_PRODUCTS, {
        variables: {
            filter: {
                ...filter,
                excludePersonalizedProducts: excludePersonalizedProducts
                    ? excludePersonalizedProducts
                    : undefined,
            },
        },
        fetchPolicy: "no-cache",
        skip: !canQuery || !open,
    });

    useEffect(() => {
        let newData =
            data?.previewProducts?.nodes?.length > 0
                ? data.previewProducts.nodes.map((product) => {
                      // Id
                      let id = product?.id;
                      // Image
                      let image =
                          product?.images?.length > 0
                              ? product.images.map((i) => i.file)
                              : [];
                      // Title
                      let title = product?.title;
                      // Bases
                      let pBase = product?.productBases;
                      if (pBase?.length > 0) {
                          pBase = pBase.map((i) => i.title);
                          pBase = pBase.join(", ");
                      }
                      return [
                          <div className="id_wrap">{id}</div>,
                          <div className="image_wrap">
                              <ModalImageClaimPolaris
                                  files={{ files: image }}
                                  limit={1}
                                  width={100}
                                  height={100}
                              />
                          </div>,
                          <div className="product_wrap">
                              <span>{title}</span>
                              <div>
                                  <Badge children={product?.sku} />
                              </div>
                          </div>,
                          <div className="product-base_wrap">
                              <span>{pBase}</span>
                          </div>,
                      ];
                  })
                : [];
        setRows(newData);
    }, [data]);

    useEffect(() => {
        let {
            divisionIds,
            storeId,
            includeCollections,
            includeTags,
            excludeCollections,
            excludeTags,
            authorIds,
            productBaseIds,
            createdTime,
            limitProduct,
            useProductDivision,
        } = filterProp;

        // let canQuery = false;
        // if (title && storeId && divisionIds?.length) {
        //     canQuery = true;
        // }
        let canQuery = title && storeId;
        if (useProductDivision) {
            canQuery = canQuery && divisionIds?.length;
        }
        setCanQuery(canQuery);

        // Collections
        let newIC =
            includeCollections?.length > 0
                ? includeCollections.map((i) => {
                      if (typeof i === "object") {
                          return i.id;
                      }
                      return i;
                  })
                : [];
        let newEC =
            excludeCollections?.length > 0
                ? excludeCollections.map((i) => {
                      if (typeof i === "object") {
                          return i.id;
                      }
                      return i;
                  })
                : [];

        // Tags
        let newIT =
            includeTags?.length > 0
                ? includeTags.map((i) => {
                      if (typeof i === "object") {
                          return i.id;
                      }
                      return i;
                  })
                : [];
        let newET =
            excludeTags?.length > 0
                ? excludeTags.map((i) => {
                      if (typeof i === "object") {
                          return i.id;
                      }
                      return i;
                  })
                : [];

        // Author
        let newAuth =
            authorIds?.length > 0
                ? authorIds.map((i) => {
                      if (typeof i === "object") {
                          return i.value;
                      }
                      return i;
                  })
                : [];

        // From
        let from = createdTime.from;
        if (from && typeof from === "object") {
            if (from._d) {
                from = from._d;
            }
        }

        // To
        let to = createdTime.to;
        if (to && typeof to === "object") {
            if (to._d) {
                to = to._d;
            }
        }

        let range;
        if (from && to) {
            range = {
                from,
                to,
            };
        }

        // Limit product
        limitProduct = Number(limitProduct);
        limitProduct = limitProduct > 0 ? limitProduct : null;

        setFilter((prev) => ({
            ...prev,
            storeId,
            divisionIds,
            includeCollectionIds: newIC,
            excludeCollectionIds: newEC,
            includeTagIds: newIT,
            excludeTagIds: newET,
            productBaseIds,
            authorIds: newAuth,
            useProductDivision,
            ...(range ? { range } : {}),
            limitProduct,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterProp]);

    // Handle action
    const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);
    const handleFilter = useCallback(
        (offset, limit) => setFilter((prev) => ({ ...prev, offset, limit })),
        []
    );

    // Variable
    let { limit, offset } = filter;
    const total = get(data, "previewProducts.total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: handleFilter,
        total,
    };

    return (
        <React.Fragment>
            <Button
                children="Preview Matched Products"
                primary
                onClick={toggleShowModal}
                disabled={!canQuery}
            />
            <Modal
                open={open}
                title="Preview matched products"
                onClose={toggleShowModal}
                large
            >
                <Container>
                    {loading ? (
                        <SkeletonPagePolaris />
                    ) : error ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : data?.previewProducts?.nodes?.length > 0 ? (
                        <DataTable
                            columnContentTypes={[
                                "text",
                                "text",
                                "text",
                                "text",
                            ]}
                            headings={["ID", "Image", "Title", "Product bases"]}
                            rows={rows}
                            verticalAlign="middle"
                            hideScrollIndicator={true}
                            footerContent={
                                <PaginationPolaris
                                    aggregation={aggregation}
                                    showTotal
                                />
                            }
                        />
                    ) : (
                        <EmptyStatePolaris />
                    )}
                </Container>
            </Modal>
        </React.Fragment>
    );
};
