import React, { Component } from "react";
import PropTypes from "prop-types";
import { LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import history from "../../history";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  margin-bottom: 24px;
  @media (min-width: 768px) {
    .heading-left {
      flex-grow: 1;
    }
    display: flex;
    align-items: center;
  }
  h2 {
    font-weight: 400;
    font-size: 23px;
    letter-spacing: -0.3px;
    margin-bottom: 0px;
  }
  a {
    color: rgba(0, 0, 0, 0.45);
  }
  a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  svg {
    max-width: 12px;
    margin-left: -2px;
    margin-right: 2px;
  }
  p {
    color: rgb(47, 59, 71);
    font-weight: 500;
    margin-bottom: 0;
  }
`;
class PageTitle extends Component {
  render() {
    const { action, subTitle, title, link } = this.props;
    return (
      <Container className={"page-title-container"}>
        <div className={"heading-left"}>
          {subTitle && (
            <p>
              <a
                className={link ? "cursor-pointer" : "cursor-default"}
                onClick={(e) => {
                  e.preventDefault();
                  link && history.push(link);
                }}
              >
                <LeftOutlined />
                {subTitle}
              </a>
            </p>
          )}
          <h2>{title}</h2>
        </div>
        {action && <div className={"heading-right"}>{action}</div>}
      </Container>
    );
  }
}

PageTitle.propTypes = {
  action: PropTypes.any,
  subTitle: PropTypes.any,
  title: PropTypes.any,
};

export default withRouter(PageTitle);
