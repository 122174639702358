import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Stack, TextField } from "@shopify/polaris";
import React from "react";
import { REPORT_PLATFORM_SALE_STATS } from "../../../graphql/mutations";
import { getCookie, handleError, setCookie } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

const initValues = {
  open: false,
  act: null,
  email: "",
  disabled: false,
};
const COOKIE_EXPORT_ORDER_STATS = "click_export_order_stats";

export const BulkActionOrderStats = ({ ids, total, filter, onCompleted }) => {
  const [state, setState] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initValues,
  );

  const toggleOpen = React.useCallback(
    (key) => () => {
      setState({ open: !state.open, act: key });
    },
    [state],
  );

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutation
  const [exportOrderStats, { loading }] = useMutation(
    REPORT_PLATFORM_SALE_STATS,
    {
      onCompleted: (res) => {
        if (res?.reportPlatformSaleStats) {
          setNotify({ msg: "Export order stats success", err: false });

          if (typeof res.reportPlatformSaleStats === "string") {
            window.location.href = res.reportPlatformSaleStats;
          }

          onCompleted && onCompleted();
        }
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleClose = React.useCallback(() => {
    setState({ open: false, act: null });
  }, []);

  const handleEmailChange = React.useCallback((email) => {
    setState({ email });
  }, []);

  const handleSubmit = React.useCallback(() => {
    const { limit, offset, ...rest } = filter;

    const expiredClick = new Date().getTime() + 10 * 60 * 1000; // 10 minutes
    setCookie(COOKIE_EXPORT_ORDER_STATS, expiredClick, 1);

    let canSubmit = false;
    if (state.act === "limit") {
      rest.ids = ids;
      canSubmit = Array.isArray(ids) && ids.length > 0;
    } else {
      rest.email = state.email;
      canSubmit = !!state.email;
    }

    if (canSubmit) {
      toggleToast && toggleToast();
      setNotify({ msg: null, err: false });
      exportOrderStats({
        variables: {
          input: rest,
        },
      }).finally(() => {
        setState({ initValues });
      });
    }
  }, [
    ids,
    filter,
    state.act,
    state.email,
    exportOrderStats,
    setNotify,
    toggleToast,
  ]);

  React.useEffect(() => {
    const expiredClick = getCookie(COOKIE_EXPORT_ORDER_STATS);
    if (expiredClick && parseInt(expiredClick) > new Date().getTime()) {
      setState({ disabled: true });
    }
  }, []);

  return (
    <>
      <Button
        children="Export"
        onClick={toggleOpen("limit")}
        disabled={state.disabled}
        primary
      />
      <span style={{ marginLeft: 10, display: "inline-block" }}>
        <Button
          children={`Select all ${total} items to export`}
          plain
          onClick={toggleOpen("unlimit")}
          disabled={state.disabled}
        />
      </span>

      <Modal
        title="Export Order Stats"
        open={state.open}
        onClose={handleClose}
        sectioned
        primaryAction={{
          content: "Export",
          onAction: handleSubmit,
          loading: loading,
        }}
        secondaryActions={[{ content: "Cancel", onAction: handleClose }]}
      >
        <Stack vertical>
          <p>Are you sure to export order stats?</p>
          {state.act === "unlimit" ? (
            <TextField
              value={state.email}
              onChange={handleEmailChange}
              placeholder="Enter email retrieve message"
              error={!state.email && "Email is required."}
            />
          ) : null}
        </Stack>
      </Modal>
    </>
  );
};
