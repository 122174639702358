import { Page } from "@shopify/polaris";
import { get } from "lodash";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { ExportAmazonPolaris } from "../../components/export-amazon/ExportAmazonPolaris";
import { AppContext } from "../../context";
import { getPathFromRouter } from "../../helper";
import { TEAM_ROLE, TEAM_ROLE_PARAMS, USER_ROLE } from "../../variable";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const ExportAmazonPagePolaris = (...props) => {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    const { currentUser } = useContext(AppContext);
    const userRole = get(currentUser, "roles", []);
    const teamRole = get(currentUser, "teamUser.role", null);

    let leader = [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.MarketplaceManager,
    ];
    let isLeader = false;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (userRole.includes(USER_ROLE.Seller)) {
        if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
        }
        if ([USER_ROLE.StoreManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.StoreManager;
        }
        if (leader.includes(teamRole)) {
            isLeader = true;
        }
    }

    return isLeader ? (
        <Container>
            <Page
                title="All Amazon Export Files"
                fullWidth
                breadcrumbs={[
                    { content: "Products", url: `/${currentParam}/products` },
                ]}
                primaryAction={{
                    content: "New Export",
                    url: `/${currentParam}/export-amazon/add`,
                }}
            >
                <ExportAmazonPolaris currentParam={currentParam} path={path} />
            </Page>
        </Container>
    ) : null;
};
