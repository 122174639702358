import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const fulfillmentQuery = gql`
    query fulfillments {
        fulfillments {
            id
            name
            slug
        }
    }
`;

export const FulfillmentSelectPolaris = (props) => {
    const { exclude, onChange, setFulfillment = () => {} } = props;
    const { data, loading } = useQuery(fulfillmentQuery);

    useEffect(() => {
        let newData =
            data?.fulfillments?.length > 0
                ? data.fulfillments.map(({ id: value, name: label, slug }) => ({
                      value,
                      label,
                      slug,
                  }))
                : [];
        newData = [{ value: "All", label: "Merchbridge" }, ...newData];
        newData = newData.filter((i) => i.value !== exclude);
        setDeselectedOptions(newData);
        setOptions(newData);
    }, [data, exclude]);

    const [inputValue, setInputValue] = useState("Merchbridge");
    const [selectedOptions, setSelectedOptions] = useState(["All"]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [options, setOptions] = useState([]);

    const handleInputChange = useCallback(
        (value) => {
            setInputValue(value);
            if (!value) {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, "i");
            const resultOptions = deselectedOptions.filter((option) =>
                option.label.match(filterRegex)
            );

            setOptions(resultOptions);
        },
        [deselectedOptions]
    );

    const handleSection = useCallback(
        (selected) => {
            const selectedValue = selected.map((selectedItem) => {
                const { label, slug } =
                    options.find((option) => {
                        return option.value === selectedItem;
                    }) || {};
                setFulfillment({ label, slug });
                return label;
            });
            setSelectedOptions(selected);

            let value = selected ? selected[0] : null;
            let label = selectedValue ? selectedValue[0] : "";
            setInputValue(label);
            if (onChange) {
                onChange(value === "All" ? null : value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options]
    );

    const textField = (
        <Autocomplete.TextField
            label="Fulfillment"
            labelHidden
            value={inputValue}
            onChange={handleInputChange}
        />
    );

    return (
        <>
            <ComponentLabelPolaris label="Fulfillment" />
            {loading ? (
                <Spinner size="small" />
            ) : (
                <Autocomplete
                    textField={textField}
                    selected={selectedOptions}
                    onSelect={handleSection}
                    options={options}
                    emptyState={<span>No items found!</span>}
                />
            )}
        </>
    );
};
