import { useMutation } from "@apollo/react-hooks";
import { Modal, Stack, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { FETCH_ORDER } from "../../../graphql/mutations";

export function FetchSingleOrder({
   open,
   onClose,
   storeId,
   refetch,
   setNotify,
   toggleActiveToast,
}) {
   // State
   const [input, setInput] = useState("");
   // Mutation
   const [fetchOrder, { loading }] = useMutation(FETCH_ORDER, {
      onCompleted: () => {
         setNotify((prev) => ({
            ...prev,
            msg: "Fetch single order successfully.",
            err: false,
         }));
         refetch && refetch();
      },
      onError: (err) => {
         setNotify((prev) => ({ ...prev, msg: err?.toString(), err: true }));
      },
   });

   // Handle submit
   const handleSubmit = useCallback(() => {
      if (!input || !storeId) return;
      toggleActiveToast();
      fetchOrder({ variables: { storeId, orderId: input } });
   }, [storeId, input, toggleActiveToast]);

   return (
      <Modal
         title="Fetch Order"
         open={open}
         onClose={onClose}
         sectioned
         large
         primaryAction={{
            content: "Submit",
            onAction: handleSubmit,
            loading: loading,
            disabled: !input,
         }}
         secondaryActions={[{ content: "Cancel", onAction: onClose }]}
      >
         <Stack vertical>
            <TextField
               value={input}
               onChange={(v) => setInput(v)}
               label="Order ID"
            />
         </Stack>
      </Modal>
   );
}
