import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Input, Row, Select } from "antd";
import styled from "styled-components";

const Container = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 10px;
  }
`;

const Operators = [
  {
    value: "equal",
    label: "Is equal to",
    group: ["*"]
  },
  {
    value: "notEqual",
    label: "Is not equal to",
    group: ["*"]
  },
  {
    value: "startsWith",
    label: "Starts with",
    group: ["checkbox", "text", "textarea", "dropdown", "radio"]
  },
  {
    value: "endsWith",
    label: "Ends with",
    group: ["checkbox", "text", "textarea", "dropdown", "radio"]
  },
  {
    value: "contains",
    label: "Contains",
    group: ["checkbox", "text", "textarea", "dropdown", "radio"]
  },
  {
    value: "notContains",
    label: "Does not contains",
    group: ["checkbox", "text", "textarea", "dropdown", "radio"]
  },
  {
    value: "greaterThan",
    label: "Is greater than",
    group: ["number"]
  },
  {
    value: "lessThan",
    label: "Is less than",
    group: ["number"]
  }
];
class FieldVisibilitySettings extends Component {
  onChange = d => {
    this.props.onChange(d);
  };
  getAvailableOperators = name => {
    const field = this.getFieldByName(name);
    if (!field) {
      return [];
    }
    return Operators.filter(
      o => o.group.includes("*") || o.group.includes(field.type)
    );
  };
  getFieldByName = name => {
    const { fields } = this.props;
    return fields.find(f => f.name === name);
  };
  render() {
    const { value, index } = this.props;
    const { configure } = value;
    const { visibility } = configure;
    const { rules } = visibility;
    let fields = this.props.fields.filter((f, idx) => idx !== index);
    return (
      <Container>
        <Form.Item>
          <Checkbox
            onChange={e => {
              visibility.enabled = e.target.checked;
              this.onChange(value);
            }}
            checked={visibility.enabled}
          >
            Enable Conditions
          </Checkbox>
        </Form.Item>

        {visibility.enabled && (
          <React.Fragment>
            <Form.Item>
              <Select
                onChange={v => {
                  visibility.type = v;
                  this.onChange(value);
                }}
                value={visibility.type}
                style={{ width: 100 }}
              >
                <Select.Option value={"show"}>Show</Select.Option>
                <Select.Option value={"hide"}>Hide</Select.Option>
              </Select>
              <span style={{ marginLeft: 10 }}>This field when</span>
            </Form.Item>
            <Form.Item>
              <Select
                onChange={v => {
                  visibility.match = v;
                  this.onChange(value);
                }}
                style={{ width: 100 }}
                value={visibility.match}
              >
                <Select.Option value={"all"}>All</Select.Option>
                <Select.Option value={"any"}>Any</Select.Option>
              </Select>
              <span style={{ marginLeft: 10 }}>
                of the following rules match
              </span>
            </Form.Item>
            {rules.map((rule, index) => (
              <Card
                style={{ marginBottom: 10 }}
                bodyStyle={{ padding: 8 }}
                headStyle={{ textAlign: "left" }}
                key={`card-${index}`}
                extra={
                  <Button
                    onClick={() => {
                      rules.splice(index, 1);
                      this.onChange(value);
                    }}
                    type={"link"}
                    size={"small"}
                    icon={<LegacyIcon type={"close"} />}
                  />
                }
                className={"rule-card"}
                title={`Rule ${index + 1}`}
              >
                <Row gutter={15} style={{ marginBottom: 10 }}>
                  <Col span={24}>
                    <Select
                      onChange={v => {
                        rule.field = v;
                        this.onChange(value);
                      }}
                      value={rule.field}
                      style={{ width: "100%" }}
                    >
                      {fields.map((field, index) => (
                        <Select.Option
                          key={`field-${index}`}
                          value={field.name}
                        >
                          {field.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={15}>
                  <Col span={12}>
                    <Select
                      style={{ width: "100%" }}
                      onChange={op => {
                        rule.operator = op;
                        this.onChange(value);
                      }}
                      value={rule.operator}
                    >
                      {this.getAvailableOperators(rule.field).map(
                        (operator, index) => (
                          <Select.Option
                            key={`operator-${index}`}
                            value={operator.value}
                          >
                            {operator.label}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Input
                      onChange={e => {
                        rule.value = e.target.value;
                        this.onChange(value);
                      }}
                      placeholder={"Value"}
                      value={rule.value}
                    />
                  </Col>
                </Row>
              </Card>
            ))}
            <Button
              style={{ width: "100%" }}
              disabled={fields.length === 0}
              onClick={() => {
                const fieldName = fields[0].name;
                const availableOperators = this.getAvailableOperators(
                  fieldName
                );
                rules.push({
                  field: fieldName,
                  operator: availableOperators.length
                    ? availableOperators[0].value
                    : null,
                  value: null
                });
                this.onChange(value);
              }}
              icon={<PlusOutlined />}
            >
              {rules.length > 0 ? "Add another rule" : "Add new rule"}
            </Button>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

FieldVisibilitySettings.propTypes = {
  fields: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  index: PropTypes.number
};

export default FieldVisibilitySettings;
