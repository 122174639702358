import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
} from "react";
import { matchValue } from "../../../../helper";

const ProductSectionContext = createContext(null);
ProductSectionContext.displayName = "ProductSectionContext";

const ActionTypes = {
    ToggleView: "ToggleView",
};

const reducers = {
    [ActionTypes.ToggleView](state) {
        return {
            ...state,
            toggleView: !state.toggleView,
        };
    },
};

function stateReducer(state, action) {
    return matchValue(action.type, reducers, state, action);
}

const initialValue = {
    toggleView: true,
};

export function ProductSectionProvider({ children }) {
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    // Actions
    const handleToggleView = useCallback(() => {
        dispatch({
            type: ActionTypes.ToggleView,
        });
    }, [dispatch]);

    // Provider value
    const bag = useMemo(
        () => ({
            ...state,
            handleToggleView,
        }),
        [state, handleToggleView]
    );

    return (
        <ProductSectionContext.Provider value={bag}>
            {children}
        </ProductSectionContext.Provider>
    );
}
export function useProductSection() {
    return useContext(ProductSectionContext);
}
