import React from "react";
import { REPORT_ORDERS_ERROR_WITHOUT_FF_OR_SUPPLIER } from "../../../graphql/queries";
import { ReportOrders } from "./ReportOrders";

export function ReportErrorWithoutFFOrSupplier({ filter }) {
  return (
    <ReportOrders
      filter={filter}
      title="Orders have no Fulfillment/Supplier"
      documentNode={REPORT_ORDERS_ERROR_WITHOUT_FF_OR_SUPPLIER}
    />
  );
}
