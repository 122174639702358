import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { BULK_PASS_TASK_TO_NEW_STATUS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export default function BulkChangeStatus({
  open,
  toggleShowModal,
  toggleActive,
  onNotificationChange,
  idsChecked,
  onCompleted,
  newStatus,
  label,
}) {
  let timeoutRef = React.useRef(null);
  const [exportDesignURL, { loading }] = useMutation(
    BULK_PASS_TASK_TO_NEW_STATUS,
    {
      onError: (error) => {
        onNotificationChange &&
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
      },
      onCompleted: () => {
        onNotificationChange &&
          onNotificationChange({
            message: "Export design urls successfully.",
            isError: false,
          });

        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          onCompleted && onCompleted();
        }, 2000);
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    onNotificationChange &&
      onNotificationChange({
        message: null,
        isError: false,
      });

    toggleActive && toggleActive();

    exportDesignURL({
      variables: {
        taskIDs: idsChecked,
        newStatus: newStatus,
      },
    });
  }, [exportDesignURL, idsChecked, newStatus, toggleActive, toggleShowModal]);

  return (
    <Modal
      title={label || "Change status"}
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to change task's status to {newStatus}?</p>
    </Modal>
  );
}
