import React, { useState, useCallback, useEffect } from "react";
import { Popover, Button, TextField, DataTable } from "@shopify/polaris";
import {
  DeleteMajorMonotone,
  SearchMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
  .add-new_wrap {
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FindReplaceXPathPolaris = (props) => {
  // Props
  const { url, onChange: onChangeProp } = props;

  // State
  const [activePopover, setActivePopover] = useState(false);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([
    {
      find: "",
      replace: "",
    },
  ]);

  // Get data
  useEffect(() => {
    let newRows =
      items && items.length > 0
        ? items.map((item, index) => {
            return [
              <div className="find_wrap">
                <TextField
                  value={item.find}
                  placeholder="Find"
                  onChange={(value) => {
                    items[index].find = value;
                    onChange(items);
                  }}
                />
              </div>,
              <div className="replace_wrap">
                <TextField
                  value={item.replace}
                  placeholder="Replace"
                  onChange={(value) => {
                    items[index].replace = value;
                    onChange(items);
                  }}
                />
              </div>,
              <div className="actions_wrap">
                {items.length > 1 && (
                  <Button
                    icon={DeleteMajorMonotone}
                    plain
                    onClick={() => {
                      let newItems = items.filter(
                        (_value, idx) => index !== idx
                      );
                      onChange(newItems);
                    }}
                  />
                )}
              </div>,
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // Handle action
  const toggleActive = useCallback(() => setActivePopover((prev) => !prev), []);
  const onChange = useCallback((value) => {
    setItems([...value]);
    if (onChangeProp) {
      onChangeProp([...value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Markup
  const activator = (
    <Button icon={SearchMajorMonotone} onClick={toggleActive} disabled={!url} />
  );
  return (
    <Popover
      activator={activator}
      active={activePopover}
      onClose={toggleActive}
    >
      <Container>
        <DataTable
          rows={rows}
          columnContentTypes={["text", "text", "numeric"]}
          headings={["Find and replace", "", ""]}
          verticalAlign="middle"
          hideScrollIndicator
        />
        <div className="add-new_wrap">
          <Button
            children="Add new"
            onClick={() => {
              setItems((prev) => [
                ...prev,
                {
                  find: "",
                  replace: "",
                },
              ]);
            }}
            size="slim"
          />
          <Button
            children="Done"
            onClick={toggleActive}
            size={"slim"}
            primary
          />
        </div>
      </Container>
    </Popover>
  );
};
