import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import get from "lodash/get";
import React, { useCallback } from "react";
import { CHANGE_PRODUCT_OF_ORDER } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { ProductAutocomplete } from "../../product/components/ProductAutocomplete";
import { useToastContext } from "../../shared/ToastContext";

export function ChangeProduct({ open, onClose, refetch, value }) {
  const { id: orderId } = value || {};
  const personalized = get(value, "product.personalized", false);
  const [disabled, setDisabled] = React.useState(true);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Ref
  const formRef = React.useRef(null);

  // Mutation
  const timeoutRef = React.useRef(null);
  const [update, { loading }] = useMutation(CHANGE_PRODUCT_OF_ORDER, {
    onCompleted: () => {
      setNotify({ msg: "Change product for order success.", err: false });
      if (refetch) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }

      onClose && onClose(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const handleSubmit = useCallback(() => {
    const input = formRef.current?.onSubmit();
    if (orderId || typeof input === "object") {
      toggleToast && toggleToast();
      setNotify({ msg: null, err: false });

      update({
        variables: {
          input: {
            orderId,
            ...input,
          },
        },
      });
    }
  }, [orderId, update]);

  return (
    <Modal
      title="Change Product for Order"
      open={open}
      onClose={onClose}
      sectioned
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        disabled,
        loading,
      }}
      secondaryActions={[{ content: "Cancel", onaction: onClose }]}
      large
    >
      <ProductAutocomplete
        personalized={personalized}
        orderId={orderId}
        ref={formRef}
        setDisabled={setDisabled}
      />
    </Modal>
  );
}
