import React from "react";

import { LayoutSection, TITLE_PAGE, ELEMENTS_DATA } from "./LayoutSection";
import { WidgetCompProvider } from "../../widget/context";
import { useAppContext } from "../../../context";
import { checkRole } from "../../../helper";
import { TEAM_STATISTIC_ELEMENT } from "./OverviewsAdmin";

const ELEMENTS = [
  {
    ...ELEMENTS_DATA.overviews,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.storeManagers,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.stores,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.productBase,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.fulfillCounter,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.products,
    default: 2,
  },
  {
    ...ELEMENTS_DATA.groups,
    default: 1,
  },
  {
    ...ELEMENTS_DATA.trackingStatus,
    default: 2,
  },
];

export function OnlineStores() {
  const { currentUser } = useAppContext();
  const { isAccountant, isAdministrator } = checkRole(currentUser); //products

  return (
    <WidgetCompProvider
      title={TITLE_PAGE.OnlineStores}
      elements={
        isAccountant
          ? ELEMENTS.filter(({ value }) => value !== "products")
          : isAdministrator
          ? [TEAM_STATISTIC_ELEMENT, ...ELEMENTS]
          : ELEMENTS
      }
    >
      <LayoutSection title={TITLE_PAGE.OnlineStores} />
    </WidgetCompProvider>
  );
}
