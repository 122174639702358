import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Badge,
  // Button,
  // ButtonGroup,
  Card,
  Heading,
  Checkbox,
  InlineError,
} from "@shopify/polaris";
import { Tabs } from "antd";
import styled from "styled-components";

import useToggle from "../../../../hooks/useToggle";
// import { SelectProducts } from "./SelectProducts";
import { useCreateIdea } from "../context/createIdea";
import { ProductItem } from "./ProductItem";
import { checkStatusTask, TASK_STATUS } from "../utils";
import { useAppContext } from "../../../../context";
import { TEAM_ROLE, TEAM_ROLE_PARAMS, USER_ROLE } from "../../../../variable";
import { Activities } from "./Activities";
import { CustomLinkPolaris } from "../../../shared/CustomLinkPolaris";
// import { ProductSlideItem } from "./ProductSlideItem";
import {
  ProductSectionProvider,
  // useProductSection,
} from "../context/productSection";
import { TemplatesSection } from "./TemplatesSection";

const { TabPane } = Tabs;

export function ProductSection({
  layoutRef,
  value,
  isAdminSeller,
  isRoleIdea,
  onCloseParent,
  fields,
  isIdeaLeader,
}) {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [isCampaign, setIsCampaign] = useState(false);
  const [state, setState] = useState({
    fulfillmentId: null,
    selectedProductBases: null,
  });
  const [activeKey, setActiveKey] = useState("product-0");

  // Ref
  const productRef = useRef(false);
  const productTimeoutRef = useRef(null);

  // Context
  const {
    products,
    addProduct,
    addProducts,
    updateProduct,
    setProductChecked,
    isClone,
    canContinue,
  } = useCreateIdea();

  // Get data

  useEffect(() => {
    if (products?.length > 0) {
      const productChecked = products.some((p) => p.checked);
      setProductChecked && setProductChecked(productChecked);
    }
  }, [products, setProductChecked]);

  useEffect(() => {
    if (value != null) {
      const { taskBaseGroups } = value || {};
      if (
        Array.isArray(taskBaseGroups) &&
        taskBaseGroups?.length > 0 &&
        !productRef.current
      ) {
        let products = [];
        for (let i = 0; i < taskBaseGroups.length; i++) {
          const product = taskBaseGroups[i];
          let {
            collections,
            tags,
            medias,
            relatedProducts,
            baseConfigs,
            psdFiles,
            psd,
            ...rest
          } = product || {};

          if (rest?.product != null) {
            psd = rest.product.psd;
            psdFiles = rest.product.psdFiles;
          }

          const newColIds = collections;
          const newTagIds = tags;
          const newRelatedProducts = relatedProducts;

          const basesHaveSubmission = [];
          const baseNoSubmission = [];

          if (baseConfigs?.length > 0) {
            for (let i = 0; i < baseConfigs.length; i++) {
              const cf = baseConfigs[i];
              if (cf?.taskBaseSubmissions?.length > 0) {
                basesHaveSubmission.push(cf);
              } else {
                baseNoSubmission.push(cf);
              }
            }
          }

          const newBases = [...basesHaveSubmission, ...baseNoSubmission].map(
            (i) => {
              const { taskBaseSubmissions } = i || {};
              const submission = (taskBaseSubmissions || [])[0];
              const { customMockups } = submission || {};

              if (customMockups?.length > 0) {
                return {
                  ...i,
                  customMockups,
                };
              }
              return i;
            },
          );

          // baseConfigs
          const mergedBases = newBases.map((b) => ({
            ...b,
            baseConfigId: b.id,
            id: b.productBaseId,
          }));
          const { mainMockups, isMainCustomMockup } =
            getBaseAndCustomMockups(mergedBases);

          let newPsd = psd ? psd : null;
          let psdSplitted = newPsd ? newPsd.split("|") : null;
          if (Array.isArray(psdSplitted)) {
            if (psdSplitted.length > 1) {
              psdSplitted = psdSplitted.join("\n");
            } else {
              psdSplitted = psdSplitted[0];
            }
          }

          const newProduct = {
            ...rest,
            collectionIds: newColIds,
            tagIds: newTagIds,
            relatedProductIds: newRelatedProducts,
            medias: medias || [],
            psdFiles: psdFiles || [],
            psd: psdSplitted,
            isCampaign: newBases?.length > 1,
            bases: mergedBases,
            mainMockups,
            isMainCustomMockup,
          };
          products.push(newProduct);
        }

        products.sort((a, b) => a.position - b.position);
        addProducts(products);

        // Set active key
        productRef.current = true;
        clearTimeout(productTimeoutRef.current);
        productTimeoutRef.current = setTimeout(() => {
          for (let i = 0; i < products.length; i++) {
            const key = `product-${i}`;
            setActiveKey(key);
          }
        }, 500);
      }
    }
  }, [value, addProducts]);

  // Action
  const handleToggle = useCallback(
    (value = false) => {
      return () => {
        setIsCampaign(value);
        toggleOpen();
      };
    },
    [toggleOpen],
  );

  const handleFulfillmentChange = useCallback((fulfillmentId) => {
    setState((prev) => ({ ...prev, fulfillmentId }));
  }, []);

  const handleChange = useCallback((selectedProductBases) => {
    setState((prev) => ({ ...prev, selectedProductBases }));
  }, []);

  const handleContinue = useCallback(() => {
    const selectedProductBases = state.selectedProductBases;
    const fulfillmentId = state.fulfillmentId;
    const ideaCols = fields.collectionIds;
    const ideaTags = fields.tagIds;
    const ideaTitle = fields.title;

    let description = "";
    if (!isCampaign) {
      const [base] = selectedProductBases;
      const { defaultContent } = base || {};
      description = defaultContent;
    } else {
      description =
        selectedProductBases?.length > 0
          ? selectedProductBases.reduce((acc, curr) => {
              const { title, niceName, defaultContent } = curr || {};
              let mergedTitle = niceName ? niceName : title;

              let content = `<div><strong>${mergedTitle}<strong></div>${defaultContent}`;
              acc += content;
              return acc;
            }, "")
          : "";
    }

    const newProduct = {
      title: ideaTitle,
      shortTitle: "",
      amzTitle: "",
      description,
      collectionIds: ideaCols,
      tagIds: ideaTags,
      isPersonalized: false,
      fields: [],
      isCampaign,
      bases: selectedProductBases,
      fulfillmentId,
      medias: [],
      psdFiles: [],
      relatedProductIds: [],
      ideaDescription: "",
      psd: "",
      isPriority: !!fields.priority,
    };

    addProduct(newProduct);

    // Set active key
    const last = Math.max(0, products.length - 1);
    setActiveKey(`product-${last}`);
  }, [state, isCampaign, addProduct, products, fields]);

  const handleActiveKeyChange = useCallback((key) => setActiveKey(key), []);
  const status = value?.status;
  const assignee = value?.assignee;
  const taskId = value?.id;
  const taskValue = value;

  const { isPending, isDone, isDraft } = checkStatusTask(status);
  const canUpdate =
    (isAdminSeller || isRoleIdea) &&
    (status == null || isPending || isDone || isDraft || isClone);

  return (
    <ProductSectionProvider>
      <div className="product-section">
        <SectionHeader />
        {!canContinue && (
          <InlineError message="At least one product base with a mockup template must be included in each group configuration." />
        )}
        <ProductsSection
          products={products}
          ref={layoutRef}
          activeKey={activeKey}
          handleActiveKeyChange={handleActiveKeyChange}
          status={status}
          isAdminSeller={isAdminSeller}
          isRoleIdea={isRoleIdea}
          updateProduct={updateProduct}
          assignee={assignee}
          taskId={taskId}
          taskValue={taskValue}
          onCloseParent={onCloseParent}
          isIdeaLeader={isIdeaLeader}
          isClone={isClone}
        />
        {/* {canUpdate && (
                    <>
                        <Card.Section>
                            <ButtonGroup>
                                <Button
                                    children="Add Campaign"
                                    onClick={handleToggle(true)}
                                />
                                <Button
                                    children="Add Product"
                                    onClick={handleToggle()}
                                />
                            </ButtonGroup>
                        </Card.Section>
                        {products?.length === 0 && (
                            <InlineError
                                message={
                                    "Please choose at least one product for idea task."
                                }
                            />
                        )}
                        <SelectProducts
                            isCampaign={isCampaign}
                            open={open}
                            onClose={toggleOpen}
                            handleContinue={handleContinue}
                            handleChange={handleChange}
                            handleFulfillmentChange={handleFulfillmentChange}
                            fulfillmentId={state.fulfillmentId}
                        />
                    </>
                )} */}
        {canUpdate && (
          <TemplatesSection
            fields={fields}
            products={products}
            setActiveKey={setActiveKey}
          />
        )}

        {products?.length === 0 && (
          <InlineError
            message={"Please choose at least one product for idea task."}
          />
        )}
        {taskId && !isAdminSeller && !isRoleIdea && (
          <Activities taskId={taskId} />
        )}
      </div>
    </ProductSectionProvider>
  );
}

const statusColor = {
  [TASK_STATUS.Approved]: {
    label: "Approved",
    color: "success",
  },
  [TASK_STATUS.Rejected]: {
    label: "Rejected",
    color: "warning",
  },
  [TASK_STATUS.Pending]: {
    label: TASK_STATUS.Pending,
    color: "info",
  },
  [TASK_STATUS["Need Review"]]: {
    label: TASK_STATUS["Need Review"],
    color: "attention",
  },
};
const ProductsSection = forwardRef(function ProductsSection(
  {
    products,
    activeKey,
    handleActiveKeyChange,
    status: statusProp,
    isAdminSeller,
    isRoleIdea,
    updateProduct,
    assignee,
    taskId,
    taskValue,
    onCloseParent,
    isIdeaLeader,
    isClone,
  },
  ref,
) {
  // State
  const [errorRoot, setErrorRoot] = useState({});
  const productsSectionRef = useRef(null);

  // context
  const { currentUser } = useAppContext();
  const userRole = currentUser?.roles || [];
  const teamRole = currentUser?.teamUser?.role;
  const isSeller = userRole.includes(USER_ROLE.Seller);
  const isDesigner = isSeller && [TEAM_ROLE.Designer].includes(teamRole);

  // const { toggleView } = useProductSection();

  // Actions
  const handleSubmit = useCallback(() => {
    productsSectionRef.current && productsSectionRef.current.onSubmit();

    const result = [];
    function haveValues(i) {
      return Array.isArray(i) ? i.length > 0 : i != null;
    }
    function checkError(err) {
      return Object.values(err).every((i) => i == null);
    }

    // Check required fields of each product.
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const err = errorRoot[i];

      const fieldsRequired = {};
      fieldsRequired.title = product.title;
      fieldsRequired.collectionIds = product.collectionIds;
      fieldsRequired.tagIds = product.tagIds;

      const canSubmit = Object.values(fieldsRequired).every(haveValues);
      const noError = err ? checkError(err) : true;

      result.push({
        canSubmit,
        noError,
      });
    }

    const canSubmitAll = result.every((i) => Object.values(i).every(Boolean));

    return canSubmitAll;
  }, [errorRoot, products]);

  const handleCheckedChange = useCallback(
    (index) => {
      return (checked) => {
        updateProduct(index, { checked });
      };
    },
    [updateProduct],
  );

  useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  const { isNeedReview } = checkStatusTask(statusProp);

  return (
    products?.length > 0 && (
      <Card.Section>
        <Wrapper>
          <Tabs
            onChange={handleActiveKeyChange}
            type="card"
            tabPosition="left"
            activeKey={activeKey}
          >
            {products.map((product, index) => {
              const tab = (
                <TabContent
                  item={product}
                  index={index}
                  handleCheckedChange={handleCheckedChange}
                  isDesigner={isDesigner}
                  isNeedReview={isNeedReview}
                  isClone={isClone}
                />
              );
              const isActive = activeKey === `product-${index}`;

              return (
                <TabPane tab={tab} key={`product-${index}`}>
                  {/* {toggleView ? (
                                        <ProductSlideItem
                                            ref={productsSectionRef}
                                            product={product}
                                            isActive={isActive}
                                        />
                                    ) : ( */}
                  <ProductItem
                    item={product}
                    index={index}
                    ref={productsSectionRef}
                    setErrorRoot={setErrorRoot}
                    status={statusProp}
                    isAdminSeller={isAdminSeller}
                    isRoleIdea={isRoleIdea}
                    isIdeaLeader={isIdeaLeader}
                    assignee={assignee}
                    taskId={taskId}
                    taskValue={taskValue}
                    onCloseParent={onCloseParent}
                    isClone={isClone}
                    activeKey={isActive}
                    handleActiveKeyChange={handleActiveKeyChange}
                  />
                  {/* )} */}
                </TabPane>
              );
            })}
          </Tabs>
        </Wrapper>
      </Card.Section>
    )
  );
});

function TabContent({
  item,
  index,
  handleCheckedChange,
  isDesigner,
  // isNeedReview,
  isClone,
}) {
  const { title, isCampaign, checked = false, status, productId } = item || {};

  // Context
  const { currentUser } = useAppContext();
  const userRole = currentUser?.teamUser?.role;
  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if ([TEAM_ROLE.StoreManager].includes(userRole)) {
    currentParam = TEAM_ROLE_PARAMS.StoreManager;
  }

  const linkEdit = `/${currentParam}/products/edit`;
  const linkCampaign = `/${currentParam}/products/campaign`;

  let productUrl = isCampaign ? linkCampaign : linkEdit;
  productUrl = productId ? `${productUrl}/${productId}` : "";

  const badgeText = isCampaign ? "Campaign" : "Product";
  const badgeMarkup = <Badge status="info">{badgeText}</Badge>;

  const indexMarkup = <Badge status="success">{index + 1}</Badge>;
  const statusMarkup = statusColor[status];
  const { isNeedReview } = checkStatusTask(status);

  const labelMarkup = (
    <span className="product-title" title={title}>
      {title || "Untitled"}
    </span>
  );

  return (
    <div>
      {!isClone && statusMarkup && (
        <Badge children={statusMarkup.label} status={statusMarkup.color} />
      )}
      <div className="tab-header">
        <div className="tab-title">
          <span>{indexMarkup}</span>
          {productUrl ? (
            <CustomLinkPolaris href={productUrl}>
              {labelMarkup}
            </CustomLinkPolaris>
          ) : (
            labelMarkup
          )}
        </div>
        <span onClick={(e) => e.stopPropagation()}>
          {!isDesigner && isNeedReview && !isClone && (
            <Checkbox checked={checked} onChange={handleCheckedChange(index)} />
          )}
        </span>
      </div>
      {badgeMarkup}
    </div>
  );
}

function getBaseAndCustomMockups(bases) {
  if (!(bases instanceof Array) || bases.length === 0) return {};

  let mergedCustomMockups = {};
  const mockups = bases.reduce((prev, b) => {
    const { taskBaseSubmissions } = b || {};
    const [sub] = taskBaseSubmissions || [];

    const { customMockups, mainCustomMockupId, taskBaseMockups } = sub || {};
    const newCustomMockups = (customMockups || []).reduce((previous, cur) => {
      if (cur == null) return previous;
      return {
        ...previous,
        [cur.id]: cur.id === mainCustomMockupId,
      };
    }, {});

    mergedCustomMockups = {
      ...mergedCustomMockups,
      ...newCustomMockups,
    };
    const newBaseMockups = (taskBaseMockups || []).reduce((previous, cur) => {
      return {
        ...previous,
        [cur.id]: !!cur.isMainMockup,
      };
    }, {});

    return {
      ...prev,
      ...newBaseMockups,
      ...newCustomMockups,
    };
  }, {});

  let keyActive;
  for (let [key, value] of Object.entries(mockups)) {
    if (value) {
      keyActive = key;
      break;
    }
  }

  return {
    mainMockups: keyActive ? [keyActive] : [],
    isMainCustomMockup: Object.values(mergedCustomMockups).some(Boolean),
  };
}

function SectionHeader() {
  // const { handleToggleView } = useProductSection();

  return (
    <Card.Header
      title={<Heading children="Products/Campaigns" />}
      // children={
      //     <Button
      //         primary
      //         children="Toggle View"
      //         onClick={handleToggleView}
      //     />
      // }
    />
  );
}

const Wrapper = styled.div`
  .ant-tabs-nav-wrap {
    padding-left: 0;
  }

  .tab {
    &-header {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      justify-content: space-between;
      margin-right: -1rem;
      max-width: 500px;

      .Polaris-Choice__Control {
        margin: 0;
      }

      .product-title {
        white-space: normal;
      }
    }

    &-title {
      display: flex;
      width: 100%;
    }
  }

  .ant-tabs {
    color: inherit;
    .ant-tabs-tab {
      margin-bottom: 0 !important;
      text-align: unset;
      border: 1px solid #c4cdd5 !important;
      border-radius: 0 !important;
      border-right: none !important;
      padding-top: 1.6rem !important;
      padding-bottom: 1.6rem !important;

      &:not(:last-child) {
        border-bottom: none !important;
      }
    }

    .ant-tabs-left-bar {
      margin-right: unset;
      border-right: 0;
    }

    .ant-tabs-left-content {
      padding: 0;
      border: 1px solid #c4cdd5;
    }
  }

  .ant-tabs-bar {
    width: 18rem;

    .product-title,
    .Polaris-Link {
      word-break: break-word;
      white-space: normal;
      margin-left: 0.5rem;
    }

    .tab-title {
      color: #212b36;
    }

    .product-title {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: 0;
  }
`;
