import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { Button, Modal } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import { Mockup2DApp } from "../../../config";
import { getInfosForIFrame } from "./MockupTemplate";

const Completed = "Completed";
const Submit = "Submit";

export function ConfigNew({ designPositions, handleDone }) {
    /// Props
    const { dpsProp, userInfoProp } = getInfosForIFrame(designPositions);

    // State
    const [open, toggleOpen] = useToggle(false);
    const [loading, setLoading] = useState(undefined);
    const timeRef = useRef(null);
    const timeoutDoneRef = useRef(null);
    const iframeRef = useRef(null);

    // Markup
    const iframeStyle = {
        border: "none",
        width: "100%",
        height: "calc(100% - 1rem)",
    };

    useEffect(() => {
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );
        for (let domParent of domParents) {
            domParent.setAttribute(
                "style",
                "max-width: calc(100% - 20rem); height: calc(100vh)"
            );
            domParent.classList.add("mockup-2d");
        }
    });

    //
    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            const parse = typeof data === "string" ? JSON.parse(data) : null;
            if (parse != null) {
                const { loading, msg } = parse;
                setLoading(loading);

                if (msg === Completed && open) {
                    clearTimeout(timeRef.current);
                    timeRef.current = setTimeout(() => {
                        toggleOpen(false);

                        clearTimeout(timeoutDoneRef.current);
                        timeoutDoneRef.current = setTimeout(() => {
                            handleDone();
                        }, 500);
                    }, 500);
                }
            }
        },
        [handleDone, open, toggleOpen]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () =>
            window.removeEventListener("message", handleReceiveMessage, false);
    }, [handleReceiveMessage]);

    const handlePrimaryAction = useCallback(() => {
        const message = Submit;
        iframeRef.current &&
            iframeRef.current.contentWindow.postMessage(message, "*");
    }, []);

    return (
        <Fragment>
            <Button
                children="Config new one"
                plain
                size="slim"
                onClick={toggleOpen}
            />

            <Modal
                open={open}
                onClose={toggleOpen}
                title="Create Mockup Template"
                primaryAction={{
                    content: "Done",
                    onAction: handlePrimaryAction,
                    loading: loading,
                }}
                secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            >
                <iframe
                    ref={iframeRef}
                    id="2d-mockup"
                    title="Mockup Template"
                    style={iframeStyle}
                    src={`${Mockup2DApp}/mockup-templates/create?dps=${dpsProp}&user=${userInfoProp}`}
                />
            </Modal>
        </Fragment>
    );
}
