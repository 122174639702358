import React, { useState, useEffect, useCallback, useContext } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { getParamByRole, handleError } from "../../../helper";
import { AppContext } from "../../../context";

import { CollectionFormPolaris } from "./CollectionFormPolaris";

const ADD_COLLECTION = gql`
  mutation createCollection($input: NewCollection!) {
    createCollection(input: $input) {
      id
      name
      image {
        id
        name
        url
      }
      private
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const AddCollectionPolaris = () => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const [createCollection, { loading, error, data }] = useMutation(
    ADD_COLLECTION,
    {
      onCompleted: () => {
        const id = setTimeout(() => {
          handleRedirect();
        }, 2500);
        setTimeoutId(id);
      },
      onError: () => {},
    }
  );

  const handleSubmit = useCallback((input) => {
    createCollection({
      variables: {
        input,
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleRedirect = useCallback(() => {
    history.push(`/${currentParam}/collections`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess = data && data.createCollection && "Add collection success";
  let newError = error ? handleError(error.toString()) : null;

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="Add Collection"
        breadcrumbs={[
          { content: "Collections", url: `/${currentParam}/collections` },
        ]}
        fullWidth
      >
        <CollectionFormPolaris
          onSubmit={handleSubmit}
          loading={loading}
          currentParam={currentParam}
        />
      </Page>
    </Container>
  );
};
