import { gql } from "apollo-boost";

export const reportOrdersResultFragment = gql`
  fragment reportOrdersResultFragment on ReportOrdersResult {
    total
    nodes {
      id
      originId
      status
      createdAt
    }
  }
`;

export const ProductHasSaleStatFragment = gql`
  fragment ProductHasSaleStatFragment on ProductHasSaleStat {
    productID
    totalOrders
    topStores {
      store {
        id
        title
      }
      totalOrders
    }
    product {
      id
      title
      sku
      personalized
      images {
        id
        file {
          id
          thumbnailUrl
          url
        }
      }
      mainImage {
        id
        thumbnailUrl
        url
      }

      fields {
        id
        name
        title
        type
        extraFee
        configure
        sorting
      }
      productBases {
        id
      }

      collections {
        id
        name
      }
    }
  }
`;
