import React, { useState, useCallback, useEffect } from "react";
import { TextField, Button, Modal } from "@shopify/polaris";
import {
  SettingsMajorMonotone,
  ViewMajorMonotone,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { ApolloConsumer } from "@apollo/react-components";
import styled from "styled-components";

import { LiveApi, LocalApi, Production } from "../../config";
import { elementContains } from "../../helper";
import { FindReplaceXPathPolaris } from "./FindReplaceXPathPolaris";
import XPathIframe from "../seller/XPathIframe";

const CRAWL_TITLE_QUERY = gql`
  query getCrawlTitle(
    $url: String!
    $xPath: String!
    $attribute: String
    $replacement: [ReplacementData!]
  ) {
    getCrawlTitle(
      url: $url
      xPath: $xPath
      replacement: $replacement
      attribute: $attribute
    )
  }
`;

const CRAWL_IMAGES_QUERY = gql`
  query getCrawlImages(
    $url: String!
    $xPath: String!
    $attribute: String
    $replacement: [ReplacementData!]
  ) {
    getCrawlImages(
      url: $url
      xPath: $xPath
      attribute: $attribute
      replacement: $replacement
    )
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  .xpath_wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
    .xpath,
    .attribute {
      flex: 1 1;
    }
    .actions_inner {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }
  }
  .title_wrap {
    h3 {
      display: inline-block;
      margin-right: 0.5rem;
      font-weight: 500;
    }
  }
  .actions_wrap {
    .Polaris-Icon {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
  .image_inner {
    display: flex;
    flex-direction: row;
    column-gap: 1.6rem;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    margin-top: 1.6rem;
    .image-result {
      box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
      padding: 10px;
    }
  }
`;

export const XPathSelectorPolaris = (props) => {
  // Props
  const {
    value,
    onChange,
    onChangeAttribute,
    onChangeItems,
    isImage,
    url,
    crawler,
    error,
    onNotificationChange,
    toggleActive: toggleActiveProp,
  } = props;

  let srcUrl = Production
    ? `${LiveApi}/viewer?url=${url}`
    : `${LocalApi}/viewer?url=${url}`;

  // State
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [visible, setVisible] = useState(false);

  // Markup
  useEffect(() => {
    let xPathDom = document.querySelector("#xpath-iframe");
    let modalsDom = document.querySelectorAll(".Polaris-Modal-Dialog__Modal");

    for (let modalDom of modalsDom) {
      if (elementContains(modalDom, xPathDom)) {
        modalDom.setAttribute("style", "max-width: calc(100% - 10rem);");
      }
    }
  });

  // Handle preview
  const handlePreview = useCallback(
    (client) => {
      const variables = {
        url,
        xPath: value,
        attribute: isImage
          ? crawler.imageXpathAttribute || null
          : crawler.titleXpathAttribute || null,
        replacement: isImage
          ? crawler.imageReplacement || []
          : crawler.titleReplacement || [],
      };

      setLoading(true);
      if (isImage) {
        client
          .query({
            query: CRAWL_IMAGES_QUERY,
            variables: variables,
            fetchPolicy: "no-cache",
          })
          .then((res) => {
            setLoading(false);
            setImages(() => res.data.getCrawlImages);
          })
          .catch(() => {
            setLoading(false);
            if (onNotificationChange) {
              onNotificationChange({
                message: "No found.",
                isError: true,
              });
            }
          });
      } else {
        client
          .query({
            query: CRAWL_TITLE_QUERY,
            variables: variables,
            fetchPolicy: "no-cache",
          })
          .then((res) => {
            setLoading(false);
            setTitle(() => res.data.getCrawlTitle);
          })
          .catch(() => {
            setLoading(false);
            if (onNotificationChange) {
              onNotificationChange({
                message: "No found.",
                isError: true,
              });
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url, value, isImage, crawler]
  );

  // Handle actions
  const toggleActive = useCallback(() => setVisible((prev) => !prev), []);

  return (
    <ApolloConsumer>
      {(client) => (
        <Container>
          <div className="xpath_wrap">
            <div className="xpath">
              <TextField
                value={value}
                onChange={(value) => {
                  if (onChange) {
                    onChange(value);
                  }
                }}
                error={error}
              />
            </div>
            <div className="attribute">
              <TextField
                placeholder={
                  isImage
                    ? "Attribute (default: src)"
                    : "Attribute (default: text)"
                }
                onChange={(value) => {
                  if (onChangeAttribute) {
                    onChangeAttribute(value);
                  }
                }}
                value={
                  isImage
                    ? crawler.imageXpathAttribute
                    : crawler.titleXpathAttribute
                }
              />
            </div>
            <div className="actions_wrap">
              <div className="actions_inner">
                <Button
                  disabled={!url}
                  icon={SettingsMajorMonotone}
                  onClick={toggleActive}
                />
                <FindReplaceXPathPolaris
                  url={url}
                  onChange={(items) => {
                    if (onChangeItems) {
                      onChangeItems(items);
                    }
                  }}
                />
                <Button
                  disabled={!url || !value}
                  icon={ViewMajorMonotone}
                  loading={loading}
                  onClick={() => {
                    handlePreview(client);
                    if (toggleActiveProp) {
                      toggleActiveProp();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {title && (
            <div className="title_wrap">
              <h3>Result:</h3>
              <label>{title}</label>
            </div>
          )}
          {isImage && images && images.length > 0 ? (
            <div className="image_wrap">
              <h3>Result:</h3>
              <div className="image_inner">
                {images.map((img, index) => (
                  <div className="image-result" key={index}>
                    <img
                      style={{
                        width: 200,
                        height: 200,
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      key={index}
                      onClick={() => {
                        setPreviewImage(() => img);
                      }}
                      src={img}
                      alt={""}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <Modal
            open={visible}
            onClose={toggleActive}
            title="XPath Iframe"
            sectioned
          >
            <div id="xpath-iframe">
              <XPathIframe
                onChange={(path) => {
                  toggleActive();
                  if (onChange) {
                    onChange(path);
                  }
                }}
                src={srcUrl}
              />
            </div>
          </Modal>
          <Modal
            open={previewImage}
            onClose={() => setPreviewImage(() => null)}
            title="Preview image"
            sectioned
            large
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={previewImage} alt={""} />
            </div>
          </Modal>
        </Container>
      )}
    </ApolloConsumer>
  );
};
