import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Checkbox,
  Modal,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { UPDATE_TEEZILY_AUTO_PRODUCTION } from "../../graphql/mutations";
import { handleError } from "../../helper";
import { useToastContext } from "../shared/ToastContext";

const CRETATE_FULFILLMENT = gql`
  mutation createFulfillmentConfigure($input: NewFulfillmentConfig!) {
    createFulfillmentConfigure(input: $input) {
      id
      fulfillmentId
      apiKey
      apiSecret
    }
  }
`;

const UNINSTALL = gql`
  mutation deleteFulfillmentConfigure($slug: String!) {
    deleteFulfillmentConfigure(slug: $slug)
  }
`;

export const TeezilyFulfillmentConfigurePolaris = ({
  refetch,
  installed: installedProps,
  value,
}) => {
  const id = get(value, "id");

  const isAutoProductionProp =
    get(value, "settings.teezily_auto_production") || false;

  const [open, setOpen] = useState(false);
  const [actionCurrent, setActionCurrent] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [installed, setInstalled] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [isAutoProduction, setIsAutoProduction] = useState(
    isAutoProductionProp,
  );

  // Context
  const { toggleToast, setNotify } = useToastContext();

  React.useEffect(() => {
    setIsAutoProduction((p) => {
      if (isAutoProductionProp != null && p !== isAutoProductionProp) {
        return isAutoProductionProp;
      }

      return p;
    });
  }, [isAutoProductionProp]);

  const [createFulfillmentConfigure, { data, loading, error }] = useMutation(
    CRETATE_FULFILLMENT,
    {
      onError: () => {},
      onCompleted: () => {
        toggleInstall();
        if (refetch) refetch();
        const id = setTimeout(() => {
          toggleModal();
        }, 2500);
        setTimeoutId(id);
      },
    },
  );
  const [
    deleteFulfillmentConfigure,
    { data: dataDelete, loading: loadingDelete, error: errorDelete },
  ] = useMutation(UNINSTALL, {
    onError: () => {},
    onCompleted: () => {
      toggleInstall();
      if (refetch) refetch();
      const id = setTimeout(() => {
        toggleModal();
      }, 2500);
      setTimeoutId(id);
    },
  });

  const refetchTimeout = React.useRef(null);
  const [updateAutoProd, { loading: loadingUA }] = useMutation(
    UPDATE_TEEZILY_AUTO_PRODUCTION,
    {
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
      onCompleted: () => {
        setNotify({
          msg: "Update auto production success.",
          err: false,
        });
        if (!refetch) return;
        refetchTimeout.current && clearTimeout(refetchTimeout.current);
        refetchTimeout.current = setTimeout(() => {
          refetch();
        }, 1500);
      },
    },
  );

  useEffect(() => {
    setInstalled(installedProps);
  }, [installedProps]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toggleModal = useCallback((value) => {
    setOpen((open) => !open);
    setActionCurrent(value);
  }, []);
  const toggleInstall = useCallback(
    () => setInstalled((installed) => !installed),
    [],
  );

  const handleApiKeyValue = useCallback((value) => {
    if (!value) {
      setApiKeyError("API Key is required.");
    } else {
      setApiKeyError(null);
    }
  }, []);

  const handleApiKeyChange = useCallback((value) => {
    setApiKey(value);
    handleApiKeyValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCreate = useCallback(() => {
    handleApiKeyValue(apiKey);
    if (apiKey && !apiKeyError) {
      createFulfillmentConfigure({
        variables: {
          input: {
            apiKey,
            fulfillmentId: "teezily",
            teezilyAutoProduction: isAutoProduction,
          },
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeyError, apiKey, isAutoProduction]);

  const handleDelete = useCallback(() => {
    deleteFulfillmentConfigure({
      variables: {
        slug: "teezily",
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess = data && data.createFulfillmentConfigure;
  let toastSuccessDelete = dataDelete && dataDelete.deleteFulfillmentConfigure;

  const handleContentToast = () => {
    if (error) return handleError(error.toString());
    if (errorDelete) return handleError(errorDelete.toString());
    if (toastSuccess) return "Create fulfillment success.";
    if (toastSuccessDelete) return "Uninstall fulfillment success.";
  };

  const toastMarkup = activeToast
    ? (error || toastSuccess || toastSuccessDelete || errorDelete) && (
        <Toast
          content={handleContentToast()}
          error={error || errorDelete}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const handleAutoProductionChange = React.useCallback((value) => {
    setIsAutoProduction(value);
  }, []);

  const handleSaveChange = React.useCallback(() => {
    if (!id) return;

    toggleToast();
    setNotify({ msg: null, err: false });

    updateAutoProd({
      variables: {
        appIntegrationID: id,
        isAutoProduction: isAutoProduction,
      },
    });
  }, [updateAutoProd, toggleToast, setNotify, id, isAutoProduction]);

  return (
    <>
      {toastMarkup}
      {installed ? (
        <div style={{ marginBottom: 10 }}>
          <Stack>
            <Checkbox
              label="Auto send orders to In Production"
              onChange={handleAutoProductionChange}
              checked={isAutoProduction}
            />
            <Button
              size="slim"
              onClick={handleSaveChange}
              children="Save change"
              loading={loadingUA}
            />
          </Stack>
        </div>
      ) : null}
      {!installed ? (
        <Button primary onClick={() => toggleModal("install")}>
          Install
        </Button>
      ) : (
        <Button
          primary
          onClick={() => toggleModal("uninstall")}
          children="Uninstall"
        />
      )}
      <Modal
        title="Install Teezily"
        open={open && "install" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleModal }]}
        primaryAction={{
          content: "Install",
          onAction: handleCreate,
          loading: loading,
        }}
      >
        <Stack vertical>
          <TextField
            label="API Key(*)"
            value={apiKey}
            onChange={handleApiKeyChange}
            placeholder="Enter your API Key"
            error={apiKeyError}
          />
          <div>
            <Checkbox
              label="Auto send orders to In Production"
              checked={isAutoProduction}
              onChange={handleAutoProductionChange}
            />
          </div>
        </Stack>
      </Modal>
      <Modal
        title="Uninstall customcat"
        open={open && "uninstall" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleDelete,
          loading: loadingDelete,
        }}
      >
        <TextContainer>
          Are you sure uninstall this config fulfillment?
        </TextContainer>
      </Modal>
    </>
  );
};
