import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card, Stack, TextField, Icon, Select } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { getCookie, setCookie, handleError } from "../../../helper";
import { TableProductByDivisionPolaris } from "./TableProductByDivisionPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

const QUERY_PRODUCT_BY_DIVISION = gql`
  query getListProductByDivision($filter: ProductDivisionFilter!) {
    getListProductByDivision(filter: $filter) {
      total
      nodes {
        id
        title
        images {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
        divisions {
          id
          used
        }
      }
    }
  }
`;

const options = [
  {
    label: "Best selling",
    value: "best_selleing",
  },
  {
    label: "Product title A-Z",
    value: "title-asc",
  },
  {
    label: "Product title Z-A",
    value: "title-desc",
  },
  {
    label: "Highest price",
    value: "regular_price-asc",
  },
  {
    label: "Lowest price",
    value: "regular_price-desc",
  },
  {
    label: "Newest",
    value: "created_at-desc",
  },
  {
    label: "Oldest",
    value: "created_at-asc",
  },
];

export const ProductsByDivisionPolaris = (props) => {
  const { divisionId } = props;
  const [inputValue, setInputValue] = useState(null);
  const [sort, setSort] = useState("best_selleinge");
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductByDivision")) || 20,
    offset: 0,
    search: null,
    sortBy: null,
    sortOrder: null,
  });
  const [getList, { data, loading, error }] = useLazyQuery(
    QUERY_PRODUCT_BY_DIVISION
  );
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    getList({
      variables: {
        filter: {
          divisionId,
          ...filter,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionId, filter]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setFilter((prev) => ({
        ...prev,
        search: value,
        offset: 0,
      }));
    }, 300);
  }, []);

  const handleSortChange = useCallback((value) => {
    setSort(value);
    let arr = value.split("-");
    if (1 === arr.length) {
      setFilter((prevState) => {
        return {
          ...prevState,
          sortBy: null,
          sortOrder: null,
          offset: 0,
        };
      });
    } else if (2 === arr.length) {
      setFilter((prevState) => {
        return {
          ...prevState,
          sortBy: _.head(arr),
          sortOrder: arr[1],
          offset: 0,
        };
      });
    }
  }, []);

  const listProdudcts =
    data &&
    data.getListProductByDivision &&
    data.getListProductByDivision.nodes &&
    data.getListProductByDivision.nodes.length > 0
      ? data.getListProductByDivision.nodes
      : [];
  const total =
    data && data.getListProductByDivision && data.getListProductByDivision.total
      ? data.getListProductByDivision.total
      : 0;

  return (
    <Card>
      <Card.Header title="Products" />
      <Card.Section>
        <Stack distribution="fill">
          <TextField
            label="Filter product"
            labelHidden
            prefix={<Icon source={SearchMinor} />}
            onChange={handleInputChange}
            value={inputValue}
          />
          <Stack.Item fill>
            <Select
              label="Sort:"
              labelInline
              options={options}
              onChange={handleSortChange}
              value={sort}
            />
          </Stack.Item>
        </Stack>
      </Card.Section>
      <div>
        {loading ? (
          <SkeletonPagePolaris />
        ) : (
          <>
            {error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : (
              <>
                {listProdudcts.length > 0 ? (
                  <TableProductByDivisionPolaris
                    data={listProdudcts}
                    filter={filter}
                    total={Number(total)}
                    setFilter={(offset, limit) => {
                      setFilter((prevState) => {
                        return {
                          ...prevState,
                          offset,
                          limit,
                        };
                      });
                      setCookie("perPageProductByDivision", limit, 100);
                    }}
                  />
                ) : (
                  <EmptyStatePolaris />
                )}
              </>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
