import React, { useCallback } from "react";

import { copyUrlImage } from "../../helper";

export const CustomLinkPolaris = ({
    children,
    href,
    file,
    target = "_blank",
    ...props
}) => {
    // Copy href
    const handleCopyRef = useCallback(() => {
        if (file) {
            copyUrlImage(file);
        }
    }, [file]);

    return (
        <a
            href={href}
            target={target}
            rel="noopener noreferrer"
            className="Polaris-Link"
            onClick={handleCopyRef}
            {...props}
        >
            {children}
        </a>
    );
};
