import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 1.35rem;
`;

const DIVISION_OPTIONS = [
  { label: "All product", value: "all" },
  { label: "Divided", value: true },
];

const STATUS_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Used", value: true },
  { label: "Unused", value: false },
];

export const FilterProductByDivisionPolaris = (props) => {
  const { onChange, value: propsValue } = props;
  const [selected, setSelected] = useState({
    division: [],
    status: [],
  });
  const handleChange = useCallback(
    (value, name) => {
      setSelected((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      let coupleDivision = {
        value: _.get(propsValue, "division.value", null),
        label: _.get(propsValue, "division.label", null),
      };
      let coupleStatus = {
        value: _.get(propsValue, "status.value", null),
        label: _.get(propsValue, "status.label", null),
      };

      if ("division" === name) {
        const divisionLabel = DIVISION_OPTIONS.find((i) =>
          value.includes(i.value)
        );
        if (value && value.length) {
          coupleDivision = {
            value: value[0],
            label: divisionLabel && divisionLabel.label,
          };
          if ("all" === value[0]) {
            coupleDivision = {
              value: null,
              label: null,
            };
          }
        }
      }
      if ("status" === name) {
        const statusValue = STATUS_OPTIONS.find((i) => value.includes(i.value));
        if (value && value.length) {
          coupleStatus = {
            value: value[0],
            label: statusValue && statusValue.label,
          };
          if ("all" === value[0]) {
            coupleStatus = {
              value: null,
              label: null,
            };
          }
        }
      }
      if (onChange) {
        onChange({
          division: coupleDivision,
          status: coupleDivision.value
            ? coupleStatus
            : { value: null, label: null },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propsValue]
  );

  useEffect(() => {
    let { division, status } = propsValue;
    setSelected((prevState) => {
      if (
        [division.value] !== prevState.division ||
        [status.value] !== prevState.status
      ) {
        return {
          ...prevState,
          division: null !== division.value ? [division.value] : ["all"],
          status: null !== status.value ? [status.value] : ["all"],
        };
      }
      return { ...prevState };
    });
  }, [propsValue]);

  return (
    <Container>
      <ChoiceList
        title="Division"
        name="division"
        choices={DIVISION_OPTIONS}
        selected={selected.division}
        onChange={handleChange}
      />
      {selected.division && selected.division[0] === true && (
        <ChoiceList
          title="Used status"
          name="status"
          choices={STATUS_OPTIONS}
          selected={selected.status}
          onChange={handleChange}
        />
      )}
    </Container>
  );
};
