import { Heading, Labelled, Stack, TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import styled from "styled-components";
import { MediaSelectorButton2Polaris } from "../../../file/MediaSelectorButton2Polaris";
import {
    genMediaChild,
    ItemWrap,
    MediaComp,
    RelatedProductComp,
} from "./IdeaSection";

export function ProductAdditionalInfo({
    canUpdate,
    item,
    index,
    updateProduct,
    setChanged,
}) {
    // Props
    const { medias = [], relatedProductIds = [], ideaDescription, status } =
        item || {};

    const mediasHaveFile = (medias || []).map((f) => ({ file: f }));
    const filesMarkup = genMediaChild(medias);
    const itemStatusNull = status === null;

    // Actions
    const handleUpdate = useCallback(
        (newValue) => {
            setChanged && setChanged(true);
            updateProduct && updateProduct(index, newValue);
        },
        [index, setChanged, updateProduct]
    );

    const handleFilesChange = useCallback(
        (value) => {
            handleUpdate({ medias: value });
        },
        [handleUpdate]
    );

    const handleExistProducts = useCallback(
        (value) => {
            handleUpdate({ relatedProductIds: value });
        },
        [handleUpdate]
    );

    const handleChange = useCallback(
        (value, id) => {
            handleUpdate({ [id]: value });
        },
        [handleUpdate]
    );

    return (
        <Wrapper className="additional-info">
            <Heading children="Additional idea info" />
            <div className="section-vertical">
                {canUpdate || itemStatusNull ? (
                    <>
                        <div className="left">
                            <Stack vertical spacing="tight">
                                <div>
                                    <Heading children="Media" />
                                    <MediaSelectorButton2Polaris
                                        singleUpload={true}
                                        multiple
                                        accept={"image/*"}
                                        sizeSmall
                                        value={medias}
                                        onChange={handleFilesChange}
                                        showTitle
                                        width={120}
                                        height={120}
                                        fromIdeaTask
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div className="right">
                            <Stack vertical spacing="tight">
                                <RelatedProductComp
                                    value={relatedProductIds}
                                    onChange={handleExistProducts}
                                    canUpdate={canUpdate || itemStatusNull}
                                />
                                <TextField
                                    label="Description"
                                    id="ideaDescription"
                                    value={ideaDescription}
                                    onChange={handleChange}
                                    multiline={4}
                                />
                            </Stack>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="right">
                            <Stack vertical spacing="tight">
                                <RelatedProductComp
                                    value={relatedProductIds}
                                    onChange={handleExistProducts}
                                    canUpdate={canUpdate}
                                />
                                <ItemWrap label="Description">
                                    <span>{ideaDescription}</span>
                                </ItemWrap>
                            </Stack>
                        </div>
                        <div className="left">
                            <Stack vertical spacing="tight">
                                <div>
                                    <Labelled label="Media:" />
                                    <MediaComp files={mediasHaveFile}>
                                        {filesMarkup}
                                    </MediaComp>
                                </div>
                            </Stack>
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .list-file_wrap {
        &.multiple {
            flex-direction: row;
            align-items: start;

            .file {
                &-item {
                    flex-direction: column;
                    row-gap: 0.5rem;
                    height: auto;
                }

                &-name {
                    display: -webkit-box;
                    max-width: 200px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    word-break: break-all;
                }
            }
        }
    }
`;
