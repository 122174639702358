import React, { useCallback, useContext, useRef, useState } from "react";
import { Popover, Badge, Button, Spinner } from "@shopify/polaris";
import { NotificationMajorTwotone } from "@shopify/polaris-icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import styled from "styled-components";

import useToggle from "../../hooks/useToggle";
import { checkRole, handleError } from "../../helper";
import { AppContext } from "../../context";
import { TEAM_ROLE, USER_ROLE } from "../../variable";

const NOTIFY = gql`
    query getNotificationFacebook($limit: Int, $offset: Int) {
        getNotificationFacebook(limit: $limit, offset: $offset) {
            total
            totalUnRead
            nodes
        }
    }
`;

const SEE_NOTIFY = gql`
    mutation seenNotification($id: ID!) {
        seenNotification(id: $id)
    }
`;

const QUERY_NOTIFICATIONS = gql`
    query notifications($filter: NotificationFilter) {
        notifications(filter: $filter) {
            total
            nodes
        }
    }
`;

const Container = styled.div`
    .notifications-btn {
        position: relative;
        padding-right: 1.7rem;
        border-right: 1px solid #b8bec5;
        margin-right: 0.8rem;

        .Polaris-Badge {
            padding: 0 0.6rem;
            position: absolute;
            top: -1rem;
            left: 1rem;
        }
    }
`;

const ActionListContainer = styled.div`
    min-width: 40rem;

    .no-item {
        padding: 1.6rem;
        display: inline-block;
    }

    .list-content {
        flex: 1 1;
    }

    .spinner-wrap {
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;
    }

    .Polaris-ActionList__Item {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        align-items: center;
        user-select: text;

        .dot {
            width: 10px;
            height: 10px;
            background: rgba(164, 232, 242, 1);
            display: inline-block;
            border-radius: 50%;

            &.seen {
                background-color: rgba(223, 227, 232, 1);
            }
        }
    }
`;

export function NotifyPolaris() {
    // Context
    const { currentUser } = useContext(AppContext);
    const userRoles = currentUser?.roles;
    const teamRole = currentUser?.teamUser?.role;

    const acceptQuery =
        userRoles?.includes(USER_ROLE.Seller) &&
        [TEAM_ROLE.StoreManager, TEAM_ROLE.MarketplaceManager].includes(
            teamRole
        );

    // State
    const [active, toggleActive] = useToggle(false);
    const [loadMore, setLoadMore] = useState(false);
    const [filter] = useState({
        limit: 20,
        offset: 0,
    });

    // Ref
    const loadRef = useRef(null);
    const { isSeller } = checkRole(currentUser);

    // Query
    const { data, loading, error, fetchMore, client } = useQuery(NOTIFY, {
        variables: {
            ...filter,
        },
        skip: !acceptQuery || isSeller,
        fetchPolicy: "cache-and-network",
    });
    const { data: dataN } = useQuery(QUERY_NOTIFICATIONS, {
        variables: {
            filter: {
                limit: 10,
                offset: 0,
            },
        },
        skip: true, //!isSeller,
    });
    // Mutation
    const [seemNotify, { loading: loadingM }] = useMutation(SEE_NOTIFY);

    // Variables
    const total = data?.getNotificationFacebook?.total || 0;
    const totalUnRead = data?.getNotificationFacebook?.totalUnRead || 0;
    const nodes = data?.getNotificationFacebook?.nodes || [];
    const nodesN = dataN?.notifications?.nodes || [];

    // Markup
    const activator = (
        <div className="notifications-btn">
            <Button
                icon={NotificationMajorTwotone}
                plain
                loading={loading}
                onClick={toggleActive}
            />
            {totalUnRead > 0 && <Badge children={totalUnRead} status="info" />}
        </div>
    );

    const errorMarkup = error && (
        <div>Error: {handleError(error.toString())}</div>
    );

    const spinnerMarkup = loadMore && (
        <div className="spinner-wrap">
            <Spinner size="small" />
        </div>
    );

    // Handle actions
    const handleUpdateCache = useCallback(
        (id) => {
            const variables = {
                ...filter,
            };
            const cache = client.readQuery({
                query: NOTIFY,
                variables,
            });
            client.writeQuery({
                query: NOTIFY,
                variables,
                data: {
                    ...cache,
                    getNotificationFacebook: {
                        ...cache.getNotificationFacebook,
                        totalUnRead:
                            cache.getNotificationFacebook.totalUnRead - 1,
                        nodes: cache.getNotificationFacebook.nodes.map((i) => {
                            if (i.id === id) {
                                return {
                                    ...i,
                                    seen: true,
                                };
                            }
                            return i;
                        }),
                    },
                },
            });
        },
        [client, filter]
    );

    const onAction = useCallback(
        (i) => {
            const id = i?.id;
            const seen = i?.seen;

            if (!seen && id != null) {
                seemNotify({
                    variables: {
                        id,
                    },
                })
                    .then((res) => {
                        const result = res?.data?.seenNotification;
                        if (result) {
                            handleUpdateCache(id);
                        }
                    })
                    .catch((err) => {});
            }
        },
        [seemNotify, handleUpdateCache]
    );

    const handleScroll = useCallback(() => {
        if (nodes.length < total) {
            setLoadMore(true);
            loadRef.current = setTimeout(() => {
                fetchMore({
                    variables: {
                        ...filter,
                        offset: nodes.length,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        setLoadMore(false);
                        if (!fetchMoreResult) return prev;

                        return {
                            ...prev,
                            getNotificationFacebook: {
                                ...prev.getNotificationFacebook,
                                nodes: [
                                    ...prev.getNotificationFacebook.nodes,
                                    ...fetchMoreResult.getNotificationFacebook
                                        .nodes,
                                ],
                            },
                        };
                    },
                });
            }, 500);
        }
    }, [nodes, total, filter, fetchMore]);

    const messagesMarkup = (nodes) => {
        return (nodes || []).map((i, index) => {
            if (!i) return null;
            const message = i.data?.message;
            const err = i.data?.error;
            const seen = i?.seen;
            const msg = message || err;

            return msg ? (
                <li key={`message-${index}`}>
                    <button
                        type="button"
                        className="Polaris-ActionList__Item"
                        disabled={loadingM}
                        onClick={() => onAction(i)}
                    >
                        <span className={`dot ${!!seen ? "seen" : ""}`}></span>
                        <p className="list-content" title={msg}>
                            {msg}
                        </p>
                    </button>
                </li>
            ) : null;
        });
    };

    return (
        <Container>
            <Popover
                activator={activator}
                active={active}
                onClose={() => toggleActive(false)}
                preferredAlignment="center"
            >
                <Popover.Pane fixed>
                    <Popover.Section>
                        <span className="notifications-heading">
                            Notifications
                        </span>
                    </Popover.Section>
                </Popover.Pane>
                {nodesN?.length > 0 ? (
                    <Popover.Pane>
                        <ActionListContainer id="list-notify">
                            <ul className="Polaris-ActionList__Actions">
                                {messagesMarkup(nodesN)}
                            </ul>
                            {spinnerMarkup}
                        </ActionListContainer>
                    </Popover.Pane>
                ) : null}
                {nodes?.length > 0 && (
                    <Popover.Pane onScrolledToBottom={handleScroll}>
                        <ActionListContainer id="list-notify">
                            <ul className="Polaris-ActionList__Actions">
                                {messagesMarkup(nodes)}
                            </ul>
                        </ActionListContainer>
                    </Popover.Pane>
                )}
            </Popover>
        </Container>
    );
}
