import React from "react";
import { DataTable, Thumbnail, Link, Badge } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { DeleteCollectionOfProductPolaris } from "./DeleteCollectionOfProductPolaris";
import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";

const Container = styled.div`
    .product-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        flex-wrap: wrap;
        align-items: start;

        .Polaris-Link {
            white-space: break-spaces;
        }
    }

    .Polaris-DataTable__Footer {
        background-color: #fff;
        padding: 1.6rem 0 0;
        overflow-x: auto;
    }
`;

export const TableProductPolaris = ({
    data,
    filter,
    setFilter,
    total,
    collectionId,
}) => {
    const rows = data
        ? data.map((d, idx) => {
              const thumbnailMarkup = _.get(
                  _.head(d.images),
                  "file.thumbnailUrl",
                  null
              );

              return [
                  `${++idx}.`,
                  <Thumbnail alt="" source={thumbnailMarkup} />,
                  <ProductComp value={d} />,
                  <DeleteCollectionOfProductPolaris
                      filter={filter}
                      productId={d.id}
                      collectionId={collectionId}
                  />,
              ];
          })
        : [];

    let { limit, offset } = filter;
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: setFilter,
        total,
    };

    return (
        <Container>
            <DataTable
                columnContentTypes={["text", "text", "text", "numeric"]}
                headings={["No.", "Image", "Title", "Actions"]}
                rows={rows}
                verticalAlign={"middle"}
                footerContent={
                    <PaginationPolaris aggregation={aggregation} showTotal />
                }
            />
        </Container>
    );
};

function ProductComp({ value }) {
    const { sku } = value || {};

    return (
        <div className="product-wrap">
            <LinkToProduct value={value} />
            {sku && <Badge children={sku} />}
        </div>
    );
}

function LinkToProduct({ value }) {
    // Props
    const { title } = value || {};
    // Context
    const { currentUser } = useAppContext();
    const currentParam = getParamByRole(currentUser);

    const redirectPage = (value) => {
        const link =
            value.productBases?.length > 1
                ? `/${currentParam}/products/campaign`
                : `/${currentParam}/products/edit`;
        return `${link}/${value.id}`;
    };

    const url = value != null ? redirectPage(value) : null;

    return <Link url={url} children={title} />;
}
