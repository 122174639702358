import React, { useState, useCallback, useEffect } from "react";
import { Tabs, Badge } from "@shopify/polaris";
// import _ from "lodash";

import { STATUS_BADGE_MOCKUP_2D } from "../../../constants";

const TABS = [
    { label: "All", value: null },
    ...Object.keys(STATUS_BADGE_MOCKUP_2D).map((key) => ({
        value: key,
        label: key,
    })),
];

export const TabsComp = ({ defaultValue, onChange, aggregations }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabs, setTabs] = useState([]);

    const getMachineName = useCallback((s) => {
        if (!s) return "";
        return s.replace(/\s+/g, "");
    }, []);

    const getCount = useCallback((aggregations, slug) => {
        const result = aggregations[slug];
        return result || 0;
    }, []);

    useEffect(() => {
        if (TABS.length > 0) {
            let newTabs = TABS.map((item) => {
                if (!item) return null;
                let slug = getMachineName(item.label);
                return {
                    id: slug,
                    content: (
                        <span>
                            <span style={{ marginRight: "0.5rem" }}>
                                {item.label}
                            </span>
                            {aggregations ? (
                                <Badge>{getCount(aggregations, slug)}</Badge>
                            ) : null}
                        </span>
                    ),
                    accessibilityLabel: item.label,
                    panelID: slug,
                };
            }).filter(Boolean);
            setTabs(newTabs);
        }
    }, [getMachineName, aggregations, getCount]);

    useEffect(() => {
        let defaultV = defaultValue;
        if (!defaultV) {
            setSelectedTab(0);
            return;
        }
        if (tabs?.length > 0) {
            let index = tabs.findIndex(
                (tab) => tab.accessibilityLabel === defaultV
            );
            setSelectedTab(index);
        }
    }, [tabs, defaultValue]);

    const handleTabChange = useCallback(
        (value) => {
            setSelectedTab(value);

            let isTab =
                tabs && tabs.length
                    ? tabs.find((_, idx) => idx === value)
                    : null;
            let content = isTab && isTab.accessibilityLabel;
            if (onChange) {
                onChange({ status: "All" !== content ? content : null });
            }
        },
        [tabs, onChange]
    );

    return (
        <Tabs tabs={tabs} onSelect={handleTabChange} selected={selectedTab} />
    );
};
