import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Loading, Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../../context";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  getParamByRole,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { FilterCollectionsPolaris } from "./FilterCollectionsPolaris";
import { TableCollectionsPolaris } from "./TableCollectionsPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LIST_COLLECTION = gql`
  fragment cat on Collection {
    id
    name
    private
    automated
    count
    parent {
      id
    }
    conditions {
      id
      field
      operator
      value
    }
    image {
      id
      name
      url
      thumbnailUrl
    }
  }
  query collections($filter: CollectionFilter!) {
    collections(filter: $filter) {
      total
      nodes {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
              children {
                ...cat
              }
            }
          }
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status"];

export const ListCollectionPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageCollections")) || 20,
    offset: 0,
    search: null,
    status: null,
    ...initFilter,
  });

  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  useEffect(() => {
    let { offset, limit, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const { data, loading, error } = useQuery(LIST_COLLECTION, {
    variables: { filter: { ...filter } },
    fetchPolicy: "cache-and-network",
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Page
        title="All Collections"
        fullWidth
        primaryAction={
          <Button
            primary
            onClick={() => history.push(`/${currentParam}/add/collection`)}
          >
            New Collection
          </Button>
        }
      >
        <Card sectioned>
          <FilterCollectionsPolaris
            filter={filter}
            onChange={(rest) => {
              let { search, status } = rest;
              setFilter((prevState) => {
                if (
                  !_.isEqual(prevState.search, search) ||
                  !_.isEqual(prevState.status, status)
                ) {
                  prevState.offset = 0;
                }
                return {
                  ...prevState,
                  ...rest,
                };
              });
            }}
          />
        </Card>
        <Card>
          {error && <div>Error: {error.toString()}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data &&
            data.collections &&
            data.collections.nodes &&
            data.collections.nodes.length ? (
            <TableCollectionsPolaris
              data={data}
              filter={filter}
              currentParam={currentParam}
              setFilter={(offset, limit) => {
                setFilter((prevState) => ({
                  ...prevState,
                  offset,
                  limit,
                }));
                setCookie("perPageCollections", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
