import React, { useState, useCallback, useEffect } from "react";
import { Page, Card, Button, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { get, size } from "lodash";
import styled from "styled-components";

import history from "../../../history";
import { handleError, decodeCSV } from "../../../helper";
import { CrawlerFilePolaris } from "../../tools/CrawlerFilePolaris";

const Container = styled.div`
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;
  }
`;

const IMPORT = gql`
  mutation adminImportTrackingNumber($input: NewImport!) {
    adminImportTrackingNumber(input: $input) {
      id
      files {
        id
        url
        name
        mimeType
      }
      createdAt
    }
  }
`;

export const ImportTrackingPolaris = () => {
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [files, setFiles] = useState([]);
  const [orders, setOrders] = useState([]);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  const [errors, setErrors] = useState({});

  // Mutation
  const [importTracking, { loading }] = useMutation(IMPORT, {
    onCompleted: () => {
      setNotification({
        message: "Import successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle action
  const handleFileValue = useCallback((value) => {
    let f = null;
    if (!value.length) {
      f = "File is required.";
    }
    setErrors((prev) => ({
      ...prev,
      file: f,
    }));
  }, []);

  const handleFileChange = useCallback((files) => {
    setFiles(files);
    handleFileValue(files);
    toggleActive();

    // Handle file
    let orders = [];
    let mapOrderById = {};
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const data = files[i].data;
        let items = decodeCSV(data);
        if (!items) {
          return;
        }
        if (items.length <= 1) {
          return;
        }
        const index = items[0].findIndex(
          (item) =>
            (item && item.toLowerCase().trim()) === "order number" ||
            (item && item.toLowerCase().trim()) === "order id"
        );
        const index1 = items[0].findIndex(
          (item) => (item && item.toLowerCase().trim()) === "tracking code"
        );
        const index2 = items[0].findIndex(
          (item) =>
            (item && item.toLowerCase().trim()) === "new tracking code" ||
            (item && item.toLowerCase().trim()) === "tracking code 2"
        );

        if (index === -1 || index1 === -1) {
          return;
        }

        for (let j = 1; j < items.length; j++) {
          const item = items[j];
          const orderId = get(item, index);
          const trackingCode1 = get(item, index1, null);
          const trackingCode2 = get(item, index2, "");

          if (!trackingCode1) {
            continue;
          }

          if (size(trackingCode1) < 8) {
            continue;
          }
          if (mapOrderById[orderId]) {
            const index = mapOrderById[orderId];
            if (trackingCode1) {
              orders[index].trackingCode = trackingCode1;
            }

            if (trackingCode2) {
              orders[index].trackingCode2 = trackingCode2;
              if (!orders[index].trackingCode) {
                orders[index].trackingCode = trackingCode2;
              }
            }
          } else {
            mapOrderById[orderId] = orders.length;
            orders.push({
              id: orderId,
              trackingCode: trackingCode1,
              trackingCode2,
            });
          }
        }
      }
    }
    setOrders(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push("/admin/manager-files/import");
  }, []);
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback(() => {
    handleFileValue(files);
    setNotification({
      message: null,
      isError: false,
    });
    if (files.length) {
      let input = {
        files: files.map((v) => v.file.id),
        orders,
      };
      importTracking({
        variables: {
          input,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, orders]);

  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      <Page title="Import Tracking">
        {toastMarkup}
        <Card sectioned>
          <CrawlerFilePolaris
            label="Tracking file"
            toggleActive={toggleActive}
            error={errors.file}
            onNotificationChange={({ message, isError }) =>
              setNotification(() => ({ message, isError }))
            }
            onChange={(newFiles) => handleFileChange(newFiles)}
          />
        </Card>
        <div className="btn-wrap">
          <Button
            children="Import"
            primary
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </Page>
    </Container>
  );
};
