import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Button } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { Group } from "../context";

export function Item({
    type,
    index,
    id,
    label,
    moveCard,
    group,
    component,
    handleClick,
}) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: type,
            index,
            id,
            label,
            group,
            component,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const removeMarkup = group !== Group.All && (
        <Button icon={CircleCancelMinor} plain onClick={handleClick} />
    );
    return (
        <ItemWrap
            className="element-wrap"
            ref={ref}
            style={{ opacity }}
            data-handler-id={handlerId}
        >
            <span className="label-wrap">{label}</span>
            {removeMarkup}
        </ItemWrap>
    );
}

const ItemWrap = styled.div`
    display: flex;

    .label-wrap {
        flex-grow: 1;
    }
`;
