import React, { useState, useEffect, useCallback } from "react";
import { Timeline } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import moment from "moment";

import { handleError } from "../../../helper";
import { QUERY_TASK_LOG } from "../../../graphql/queries";

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";

const Container = styled.div`
    .ant-timeline-item-content {
        display: flex;
        width: calc(100% - 26px);
        justify-content: space-between;
    }

    .ant-timeline-item.ant-timeline-item-last {
        padding: 0px;
    }

    .ant-timeline-item-head-blue {
        border-color: #3f4eae;
        color: #3f4eae;
    }
`;

export const GetTaskLogPolaris = ({ taskId }) => {
    // State
    const [filter, setFilter] = useState({
        limit: 20,
        offset: 0,
    });

    // Query
    const [getTask, { data, loading, error }] = useLazyQuery(QUERY_TASK_LOG);

    // Get data
    useEffect(() => {
        if (taskId) {
            getTask({
                variables: {
                    filter: {
                        taskId,
                        ...filter,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, filter]);

    const handleChange = useCallback(
        (offset, limit) => setFilter((prev) => ({ ...prev, offset, limit })),
        []
    );

    let value = data?.getTaskLog?.nodes;
    let total = data?.getTaskLog?.total;

    let { limit, offset } = filter;
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: handleChange,
        total,
    };

    return (
        <Container>
            {loading ? (
                <SkeletonPagePolaris />
            ) : error ? (
                <div>Error: {handleError(error.toString())}</div>
            ) : value?.length > 0 ? (
                <div className="mt-8">
                    <strong>Task Timelines:</strong>
                    <Timeline className="pt-5">
                        {value.map((item) => {
                            const { id, log, createdAt } = item;
                            let createdAtMarkup = moment(createdAt);
                            createdAtMarkup = createdAtMarkup.format("LLL");

                            return (
                                <Timeline.Item key={id}>
                                    {/* <div className="flex-1">{log}</div> */}
                                    <div className="flex-1" dangerouslySetInnerHTML={{ __html: `<span>${log}</span>`}}></div>
                                    <div>{createdAtMarkup}</div>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                    <div className="pagination_wrap">
                        <PaginationPolaris aggregation={aggregation} />
                    </div>
                </div>
            ) : (
                <EmptyStatePolaris noData />
            )}
        </Container>
    );
};
