import React, { Component } from "react";
import { Input, Avatar, Row, Col, Menu, Dropdown, Modal, Button } from "antd";
import { AppContext } from "../../context";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import BrandStore from "./BrandStore";
import MenuMobile from "./MenuMobile";
import Logout from "./Logout";
import history from "../../history";
import { Link } from "react-router-dom";
import ChangeUserPasswordLink from "../../components/shared/ChangeUserPasswordLink";
import StoreSwitcher from "../../components/seller/StoreSwitcher";

const { Search } = Input;

class LayoutHeader extends Component {
  state = {
    clickSave: false,
  };

  handleCancel = () => {
    let {
      showBtnSave,
      aggregations: { link },
    } = this.props;
    if (showBtnSave) {
      Modal.confirm({
        title: "Cancel all unsaved changes?",
        icon: <ExclamationCircleOutlined />,
        content: "If Ok, you'll delete any edits you made.",
        onOk() {
          history.push(link);
        },
        cancelText: "Continue",
      });
    } else {
      history.push(link);
    }
  };

  handleSave = () => {
    let { clickSave } = this.state;
    let { onSubmit } = this.props;
    this.setState(
      {
        clickSave: !clickSave,
      },
      () => {
        if (onSubmit) {
          onSubmit(clickSave);
        }
      }
    );
  };

  render() {
    const { showSticky, showBtnSave, aggregations } = this.props;
    const { title, btnName, loading } = aggregations ? aggregations : {};
    let classPosition = "";
    let classCSS = "header-right flex items-center";
    if (showSticky) {
      classPosition = "header-sticky";
      if (title) {
        classCSS += " sticky-right";
      }
    }

    return (
      <Row id="header" className={`items-center ${classPosition}`}>
        <Col className="header-left">
          <BrandStore className="ml-20" />
          <MenuMobile />
        </Col>
        <Row className={classCSS} style={{ paddingTop: 6, paddingBottom: 6 }}>
          {showSticky ? (
            <>
              <Col className="ml-4 lg:ml-6 sticky-title">{title}</Col>
              <Col className="mr-0 lg:mr-6 mr-4" style={{ marginLeft: "auto" }}>
                <Button className="mr-4" onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  icon={<LegacyIcon type={"save"} />}
                  disabled={!showBtnSave}
                  loading={loading}
                  onClick={this.handleSave}
                >
                  {btnName ? btnName : "Save"}
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col
                className="flex-1 flex justify-left"
                //  xs={{ span: 16 }} md={{ span: 12 }} xl={{ span: 14 }}
              >
                {
                  <div className="header-search">
                    <Search
                      placeholder="Search stores, orders, products and more..."
                      onSearch={(value) => {}}
                      className="header-search"
                    />
                  </div>
                }
              </Col>
              <Col
                // xs={{ span: 8 }}
                // md={{ span: 12 }}
                // xl={{ span: 10 }}
                className=""
              >
                <AppContext.Consumer>
                  {({ currentUser, logout }) => (
                    <div className="flex items-center lg:mr-6 mr-4">
                      {currentUser ? (
                        <div className="w-full">
                          <div
                            style={{ display: "flex" }}
                            className="relative ml-auto hidden justify-end lg:inline-block items-center"
                          >
                            {currentUser.roles[0] !== "Seller" ? null : (
                              <StoreSwitcher />
                            )}
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item>
                                    <Link
                                      to="/edit-profile"
                                      rel="noopener noreferrer"
                                    >
                                      Edit Profile
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <ChangeUserPasswordLink />
                                  </Menu.Item>
                                  <Menu.Item>
                                    <Logout
                                      onCompleted={() => {
                                        logout();
                                        history.push(`/login`);
                                      }}
                                    />
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <span className="submenu-title-wrapper ml-4 cursor-pointer">
                                {currentUser.avatar ? (
                                  <Avatar src={currentUser.avatar.url} />
                                ) : (
                                  <Avatar>{currentUser.firstName}</Avatar>
                                )}
                                <span
                                  className="hidden lg:inline-block"
                                  style={{ marginLeft: 5 }}
                                >{`${currentUser.firstName} ${currentUser.lastName}`}</span>
                                {/* <DownOutlined className="ml-1 icon-arrow hidden lg:inline-block" /> */}
                              </span>
                            </Dropdown>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </AppContext.Consumer>
              </Col>
            </>
          )}
        </Row>
      </Row>
    );
  }
}

export default LayoutHeader;
