import React, { useState, useEffect } from "react";
import { DataTable } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../shared/PaginationPolaris";
import { NumberToFixed, formatter } from "../../helper";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .title_wrap,
  .email_wrap {
    width: calc(25rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .platform_wrap,
  .total-base-cost_wrap,
  .total-revenue_wrap,
  .total-unit_wrap,
  .total-order_wrap {
    width: calc(15rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const TableReportsPolaris = (props) => {
  // Props
  const { data, filter, setFilter } = props;

  // State
  const [rows, setRows] = useState([]);

  // Get data
  useEffect(() => {
    let newData =
      data &&
      data.saleReports &&
      data.saleReports.nodes &&
      data.saleReports.nodes.length > 0
        ? data.saleReports.nodes.map((i) => {
            let title = i?.store?.title;
            let platform = i?.store.platform;
            let email = i?.store.email;
            let totalBaseCost = formatter.format(i?.totalBaseCost || 0);
            let totalRevenue = formatter.format(i?.totalRevenue || 0);
            let totalUnit = Number.parseInt(i?.totalUnit || 0);
            let totalOrder = Number.parseInt(i?.totalOrder || 0);
            return [
              <div className="title_wrap">
                <span>{title}</span>
              </div>,
              <div className="platform_wrap">
                <span>{platform}</span>
              </div>,
              <div className="email_wrap">
                <span>{email}</span>
              </div>,
              <div className="total-base-cost_wrap">
                <span>{totalBaseCost}</span>
              </div>,
              <div className="total-revenue_wrap">
                <span>{totalRevenue}</span>
              </div>,
              <div className="total-unit_wrap">
                <span>{totalUnit}</span>
              </div>,
              <div className="total-order_wrap">
                <span>{totalOrder}</span>
              </div>,
            ];
          })
        : [];
    setRows(newData);
  }, [data]);

  let { limit, offset } = filter;
  const total = get(data, "saleReports.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          "Title",
          "Platform",
          "Email",
          "Total base cost",
          "Total revenue",
          "Total Unit",
          "Total Order",
        ]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
