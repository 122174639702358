import React, { useCallback, useState, useEffect } from "react";
import { Button, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";
import { CHARGE_BASE_COST } from "../../graphql/mutations";

export const ChargeBaseCostPolaris = (props) => {
    const { orderIds, disabled, refetch, open, isAction, toggleShowModal } = props;

    const [activeToast, setActiveToast] = useState(false);
    const [chargeBaseCost, { data, loading, error }] = useMutation(
        CHARGE_BASE_COST,
        {
            onError: () => {},
            onCompleted: () => {
                if (refetch) {
                    setTimeout(() => {
                        refetch();
                    }, 1000);
                }

                toggleShowModal && toggleShowModal(false);
            },
        }
    );

    const hanldeSubmit = useCallback(() => {
        chargeBaseCost({
            variables: {
                orderIds,
            },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIds]);

    const toggleActive = useCallback(
        () => setActiveToast((activeToast) => !activeToast),
        []
    );

    useEffect(() => {
        if (open) {
            hanldeSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const toastMarkup = activeToast
        ? (error || (data && data.chargeBaseCost)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data &&
                            data.chargeBaseCost &&
                            `Charge base cost success.`
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            {!isAction && (
                <Button
                    size="slim"
                    loading={loading}
                    disabled={disabled}
                    primary={!disabled}
                    onClick={hanldeSubmit}
                    children="Check & Charge base cost"
                />
            )}
        </>
    );
};
