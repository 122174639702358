import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Loading, Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../../context";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  getPathFromRouter,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER, TEAM_ROLE } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { FilterProductFeedsPolaris } from "./FilterProductFeedsPolaris";
import { TableProductFeedsPolaris } from "./TableProductFeedsPolaris";

export const PRODUCT_FEEDS = gql`
  query feeds($filter: FeedFilter) {
    stores(filter: { limit: 3000, offset: 0 }) {
      nodes {
        id
        title
      }
    }
    feeds(filter: $filter) {
      total
      nodes {
        id
        name
        store {
          id
          title
        }
        collections {
          id
          name
        }
        productBases {
          id
          title
        }
        filter
        mode
        productTitlePrefix
        variantTitle
        category
        gender
        ageGroup
        brand
        tax
        shipping
        condition
        status
        total
        xmlUrl
        csvUrl
        useUpc
        createdAt
        updatedAt
        hasDesign
        hasSale
        tsvUrl
      }
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "storeId"];

export const ProductFeedsPolaris = (...props) => {
  const isMatchPathName = useMemo(() => {
    const path = getPathFromRouter([props]);
    return matchPathName(path);
  }, [props]);

  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const { store } = useContext(AppContext);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductFeeds")) || 20,
    offset: 0,
    storeId: null,
    search: null,
    ...initFilter,
  });
  const { currentUser } = useContext(AppContext);
  const role = _.get(currentUser, "teamUser.role", null);
  const isStoreManager = [TEAM_ROLE.StoreManager].includes(role);
  const urlStoreManager = isStoreManager ? "store-manager" : "seller";

  useEffect(() => {
    let { offset, limit, ...rest } = filter;
    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const { data, error, loading, client } = useQuery(PRODUCT_FEEDS, {
    variables: {
      filter: {
        ...filter,
        storeId: store ? store.id : filter.storeId,
      },
    },
    fetchPolicy: "no-cache",
  });

  const loadingMarkup = loading && <Loading />;
  const stores =
    data && data.stores && data.stores.nodes && data.stores.nodes.length
      ? data.stores.nodes
      : [];

  const storeLabel =
    filter.storeId && stores && stores.length
      ? stores.find((s) => s.id === filter.storeId)
      : null;

  return (
    <Container>
      {loadingMarkup}
      <Page
        title="Product Feeds"
        fullWidth
        primaryAction={
          <Button
            primary
            onClick={() =>
              history.push(`/${urlStoreManager}/products/feeds/add`)
            }
          >
            New Product Feed
          </Button>
        }
      >
        <Card sectioned>
          <FilterProductFeedsPolaris
            stores={stores}
            filter={filter}
            storeLabel={storeLabel && storeLabel.title}
            onChange={({ storeId, search }) =>
              setFilter((prevState) => {
                if (
                  !_.isEqual(prevState.search, search) ||
                  !_.isEqual(prevState.storeId, storeId)
                ) {
                  prevState.offset = 0;
                }
                return { ...prevState, storeId, search };
              })
            }
          />
        </Card>
        <Card>
          {error && <div>Error: {error.toString()}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data &&
            data.feeds &&
            data.feeds.nodes &&
            data.feeds.nodes.length ? (
            <TableProductFeedsPolaris
              client={client}
              urlStoreManager={urlStoreManager}
              data={data}
              filter={filter}
              fixedColumn
              setFilter={(offset, limit) => {
                setFilter((prevState) => ({
                  ...prevState,
                  offset,
                  limit,
                }));
                setCookie("perPageProductFeeds", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
