import React, { Component } from "react";
import { DollarOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import styled from "styled-components";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { NumberToFixed } from "../../helper";

export const LOAD_STATS = gql`
  query statistic {
    statistic {
      balance
      paidAmount
      pendingCost
      pendingDeposit
    }
  }
`;
const Container = styled.div`
  margin-bottom: 20px;
  .s {
    border: 1px solid #e8e8e8;
    background: #fff;
    display: flex;
    padding: 20px;
    .s-content {
      flex-grow: 1;
      h2 {
        font-weight: normal;
        margin-bottom: 5px;
        font-size: 17px;
      }
      .value {
        font-weight: 700;
      }
    }
  }
`;

class Stats extends Component {
  render() {
    return (
      <Query query={LOAD_STATS} fetchPolicy="cache-and-network">
        {({ data }) => {
          let balance,
            pendingCost,
            paidAmount,
            pendingDeposit = 0;
          if (data) {
            const s = data.statistic;
            balance = s.balance;
            paidAmount = s.paidAmount;
            pendingCost = s.pendingCost;
            pendingDeposit = s.pendingDeposit;
          }
          return (
            <Container>
              <Row gutter={30}>
                <Col span={6}>
                  <div className={"s"}>
                    <div className={"s-content"}>
                      <h2>Account Balance</h2>
                      <div className={"value"}>
                        ${NumberToFixed(balance, 2)}
                      </div>
                    </div>
                    <div className={"s-icon"}>
                      <DollarOutlined style={{ fontSize: 30 }} />
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={"s"}>
                    <div className={"s-content"}>
                      <h2>Pending Cost</h2>
                      <div className={"value"}>
                        ${NumberToFixed(pendingCost, 2)}
                      </div>
                    </div>
                    <div className={"s-icon"}>
                      <DollarOutlined style={{ fontSize: 30 }} />
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={"s"}>
                    <div className={"s-content"}>
                      <h2>Paid Amount</h2>
                      <div className={"value"}>
                        ${NumberToFixed(paidAmount, 2)}
                      </div>
                    </div>
                    <div className={"s-icon"}>
                      <DollarOutlined style={{ fontSize: 30 }} />
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={"s"}>
                    <div className={"s-content"}>
                      <h2>Pending Deposit</h2>
                      <div className={"value"}>
                        ${NumberToFixed(pendingDeposit, 2)}
                      </div>
                    </div>
                    <div className={"s-icon"}>
                      <DollarOutlined style={{ fontSize: 30 }} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default Stats;
