import { Query } from "@apollo/react-components";
import {
  Avatar,
  Badge,
  Button,
  Card,
  DataTable,
  Icon,
  Page,
} from "@shopify/polaris";
import { CircleInformationMajorMonotone } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import _, { get, isEqual } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import CopyBox from "../../components/shared/CopyBox";
import Error from "../../components/shared/Error";
import { PaginationPolaris } from "../../components/shared/PaginationPolaris";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { Actions } from "../../components/store/amazon/Actions";
import { SelectRowsProvider } from "../../components/store/SelectRowsProvider";
import { StoresFilterPolaris } from "../../components/store/StoresFilterPolaris";
import { AppContext } from "../../context";
import history from "../../history";
import { TEAM_ROLE, TEAM_ROLE_PARAMS } from "../../variable";

export const AMAZON_STORE_QUERY = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
        email
        platform
        apiKey
        status
        suspended
        group {
          id
          name
        }
      }
    }
  }
`;

export const INIT_AMAZON_VARIABLE = {
  filter: {
    limit: 1000,
    offset: 0,
    platformSelected: ["amazon"],
  },
};

class Amazon extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      active: false,
      checked: false,
      ebayAuthUrl: "",
      data: {
        total: 0,
        nodes: [],
      },
      filter: {
        limit: 20,
        offset: 0,
        platformSelected: ["amazon"],
        status: null,
        search: null,
      },
    };
    this.inputRef = React.createRef();
  }

  handleFilterChange = (filter) => {
    const { search, status } = filter;
    this.setState((prev) => {
      if (
        !isEqual(prev.filter.search, search) ||
        !isEqual(prev.filter.status, status)
      ) {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            search,
            status,
            offset: 0,
          },
        };
      }
    });
  };

  handlePaginationChange = (offset, limit) => {
    this.setState((prev) => {
      return {
        ...prev,
        filter: {
          ...prev.filter,
          offset,
          limit,
        },
      };
    });
  };

  render() {
    const { data } = this.state;
    const { currentUser } = this.context;
    const role = _.get(currentUser, "teamUser.role");
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if ([TEAM_ROLE.StoreManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }

    if ([TEAM_ROLE.MarketplaceManager].includes(role)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    const isMarketplaceManager = [TEAM_ROLE.MarketplaceManager].includes(role);

    return (
      <Container>
        <Page
          fullWidth
          breadcrumbs={
            isMarketplaceManager
              ? []
              : [
                  {
                    content: "Sales Channels",
                    url: `/${currentParam}/stores`,
                  },
                ]
          }
          title="All Amazon Accounts"
          primaryAction={
            <Button
              onClick={() => {
                history.push(`/${currentParam}/stores/amazon/add`);
              }}
              size="medium"
              primary
            >
              Add Amazon Account
            </Button>
          }
        >
          <Query
            fetchPolicy={"cache-and-network"}
            onCompleted={(res) => {
              this.setState({
                data: res.stores,
              });
            }}
            variables={{ filter: this.state.filter }}
            query={AMAZON_STORE_QUERY}
          >
            {({ _, loading, error, refetch }) => {
              return (
                <div>
                  <Card sectioned>
                    <StoresFilterPolaris
                      filter={this.state.filter}
                      onChange={this.handleFilterChange}
                    />
                  </Card>
                  <Error error={error} />
                  <Card>
                    {loading ? (
                      <SkeletonPagePolaris />
                    ) : (
                      <SelectRowsProvider
                        data={data?.nodes || []}
                        refetch={refetch}
                      >
                        {({ checkAllMarkup, checkItemMarkup }) => {
                          const dataSources = data.nodes.map((s) => {
                            let status = s.status;
                            let statusColor = null;
                            if (status === "Connected") {
                              statusColor = "success";
                            }
                            if (status === "Suspended" || s.suspended) {
                              statusColor = "critical";
                              status = "Suspended";
                            }
                            if (status === "Deactivated") {
                              status = "Deactivated";
                              statusColor = "attention";
                            }

                            // Group
                            const groupName = s?.group?.name;
                            return [
                              checkItemMarkup(s.id),
                              <div className="id-wrap">
                                <span>{s.id}</span>
                              </div>,
                              <div className={"avatar-container item"}>
                                <div>
                                  <Avatar customer name="John Smith" />
                                </div>
                                <div className={"avatar-right"}>
                                  <div>{s.title}</div>
                                  {s.email}
                                </div>
                              </div>,
                              <div className="group-wrap item">
                                {groupName}
                              </div>,
                              <div className="api-key-wrap">
                                {s.apiKey && <CopyBox text={s.apiKey} />}
                              </div>,
                              <div className={"status-container item"}>
                                <Button
                                  size="slim"
                                  plain
                                  icon={
                                    <Icon
                                      source={CircleInformationMajorMonotone}
                                    />
                                  }
                                />
                                <Badge status={statusColor}>{status}</Badge>
                              </div>,
                              <div className={"actions item"}>
                                <Actions
                                  store={s}
                                  currentParam={currentParam}
                                  refetch={refetch}
                                />
                              </div>,
                            ];
                          });

                          // Variables
                          let { limit, offset } = this.state.filter;
                          const total = get(data, "total", 0);
                          const totalPage = Math.ceil(total / limit);
                          const page = offset / limit + 1;
                          const aggregation = {
                            page,
                            totalPage,
                            offset,
                            limit,
                            onChange: this.handlePaginationChange,
                            total,
                          };
                          return (
                            <>
                              <div className="table-wrapper">
                                <DataTable
                                  columnContentTypes={[
                                    "text",
                                    "text",
                                    "text",
                                    "text",
                                    "text",
                                    "text",
                                    "numeric",
                                  ]}
                                  headings={[
                                    checkAllMarkup,
                                    "ID",
                                    "Account",
                                    "Group",
                                    "API Key",
                                    "Status",
                                    "Action",
                                  ]}
                                  rows={dataSources}
                                  verticalAlign={"middle"}
                                  hideScrollIndicator
                                />
                              </div>
                              <PaginationContainer>
                                <PaginationPolaris
                                  aggregation={aggregation}
                                  showTotal
                                />
                              </PaginationContainer>
                            </>
                          );
                        }}
                      </SelectRowsProvider>
                    )}
                  </Card>
                </div>
              );
            }}
          </Query>
        </Page>
      </Container>
    );
  }
}

Amazon.propTypes = {};

export default Amazon;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
    .Polaris-DataTable__Cell--header {
      white-space: nowrap;
    }

    .Polaris-DataTable {
      .Polaris-DataTable__ScrollContainer {
        overflow-x: initial;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .item {
    white-space: normal;
    word-break: break-word;
  }

  .Polaris-Page-Header {
    padding-top: 0;
  }

  .avatar-container {
    display: flex;
    width: 40rem;

    .avatar-right {
      margin-left: 10px;
    }
  }

  .status-container {
    display: flex;
    width: 20rem;
    align-items: center;

    button {
      margin-right: 5px;
    }
  }

  .actions {
    width: 11rem;

    button {
      margin-right: 8px;
    }
  }

  .group-wrap,
  .id-wrap {
    white-space: normal;
    width: 20rem;
  }

  .api-key-wrap {
    width: 30rem;
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;
