import React, { useState, useCallback } from "react";
import { Button, ButtonGroup } from "@shopify/polaris";

import { MAX_LENGTH, TITLE_LENGTH_MESS } from "../../variable";
import { AssignButtonPolaris } from "./actions/AssignButtonPolaris";
import { AssignTemplateButtonPolaris } from "./actions/AssignTemplateButtonPolaris";
import { DeleteProductAssortmentPolaris } from "./actions/DeleteProductAssortmentPolaris";

export const ProductAssortmentActionsPolaris = (props) => {
  const {
    currentChecked,
    toggleActive,
    onNotificationChange,
    onCompleted,
    currentValue,
  } = props;

  const [currentActive, setCurrentActive] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = useCallback(
    (value) => {
      let title = currentValue.title;
      let error = null;
      if (title === "" || title == null) {
        error = "Product title is required.";
      } else if (title.length > MAX_LENGTH) {
        error = TITLE_LENGTH_MESS;
      }
      if (error) {
        toggleActive();
        onNotificationChange({ message: error, isError: true });
        return;
      }
      setCurrentActive(value);
      setShowModal((prev) => !prev);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue]
  );

  return (
    <>
      <ButtonGroup>
        <Button
          children="Assign use Template"
          onClick={() => toggleShowModal("assignTemplate")}
          size="slim"
          primary
        />
        <Button
          children="Assign"
          onClick={() => toggleShowModal("assign")}
          size="slim"
          primary
        />
        <Button
          children="Trash"
          onClick={() => toggleShowModal("delete")}
          size="slim"
          destructive
        />
      </ButtonGroup>
      <AssignTemplateButtonPolaris
        open={showModal && "assignTemplate" === currentActive}
        toggleShowModal={toggleShowModal}
        ids={[currentChecked?.id]}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
        onCompleted={onCompleted}
        currentChecked={currentChecked}
        currentValue={currentValue}
        dataCheckeds={[currentChecked]}
      />
      <AssignButtonPolaris
        open={showModal && "assign" === currentActive}
        toggleShowModal={toggleShowModal}
        ids={[currentChecked?.id]}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
        onCompleted={onCompleted}
        currentChecked={currentChecked}
        currentValue={currentValue}
        dataCheckeds={[currentChecked]}
      />
      <DeleteProductAssortmentPolaris
        open={showModal && "delete" === currentActive}
        toggleShowModal={toggleShowModal}
        ids={currentChecked?.id}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
        onCompleted={onCompleted}
        deleteOneProduct
      />
    </>
  );
};
