import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { STORE_LIST_OF_ME } from "../../graphql/queries";
import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";

export function StoreOfMeSelect({ onChange, error, value }) {
    // State
    const [stores, setStores] = useState([]);

    // Query
    const { data } = useQuery(STORE_LIST_OF_ME);

    // Get data
    useEffect(() => {
        const nodes = data?.storeListOfMe?.nodes;
        const newStores =
            nodes?.length > 0
                ? nodes.map((node) => {
                      const { id, title } = node || {};

                      return { value: id, label: title };
                  })
                : [];

        setStores(newStores);
    }, [data]);

    return (
        <AutoCompletePolaris
            options={stores}
            placeholder="Choose store"
            label="Stores"
            required
            onChange={onChange}
            error={error}
            value={value}
        />
    );
}
