import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList, InlineError, Modal, Stack, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { CANCEL_ORDER } from "../../../graphql/mutations";
import { handleError } from "../../../helper";

export const SellerCancelOrderPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    value,
    refetch,
    changeStatusCount,
    convertOS,
  } = props;
  const orderId = value?.id;
  const orderOriginId = value?.originId;
  // useLockBodyScroll(open);

  const [selected, setSelected] = useState([1]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const [cancelOrder, { data, loading, error }] = useMutation(CANCEL_ORDER, {
    onError: () => {},
    onCompleted: () => {
      const id = setTimeout(() => {
        toggleShowModal(false);
        if (changeStatusCount) {
          changeStatusCount(
            convertOS && [
              convertOS.PendingDesign,
              convertOS.PendingPayment,
              convertOS.OnHold,
              convertOS.Pending,
            ],
          );
        }
        if (refetch) {
          refetch();
        }
      }, 2100);
      setTimeoutId(id);
    },
  });
  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);
  const handleSubmit = useCallback(() => {
    const value = selected?.length > 0 ?selected[0] : 1
    const input = mapOption({ value, orderId, orderOriginId });
    cancelOrder({
      variables: input,
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderOriginId, selected, orderId]);

  const toastMarkup = activeToast
    ? (error || (data && data.cancelOrder)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.cancelOrder && "Cancel order success."
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const handleChange = useCallback((value) => setSelected(value), []);

  return (
    <>
      {toastMarkup}
      <Modal
        open={open}
        onClose={toggleShowModal}
        title={`Order: #${orderId ? orderId : ""}`}
        sectioned
        primaryAction={{
          content: "Cancel now",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        {/* <Stack vertical spacing="tight">
          <p id="seller-cancel_modal">Are you sure to cancel this order?</p>
          <InlineError message="This option can be cancelled your order in your store" />
        </Stack> */}
        <ChoiceList
          title={`Are you sure to cancel order #${orderId ? orderId : ""}`}
          titleHidden
          choices={[
            {
              label: "Cancel this order and cancel in store",
              value: 1,
            },
            {
              label: "Cancel this order, without cancel in store",
              value: 2,
            },
            {
              label: "Cancel this order & related orders and cancel in store",
              value: 3,
            },
            {
              label:
                "Cancel this order & related orders, without cancel in store",
              value: 4,
            },
          ]}
          selected={selected}
          onChange={handleChange}
        />
      </Modal>
    </>
  );
};

function mapOption({ value, orderId, orderOriginId }) {
  let newOrderId = null;
  let originId = null;
  let related = false;
  let cancelOrderStore = false;

  switch (value) {
    case 1:
      newOrderId = orderId;
      cancelOrderStore = true;
      break;
    case 2:
      newOrderId = orderId;
      break;
    case 3:
      related = true;
      originId = orderOriginId;
      cancelOrderStore = true;
      break;
    case 4:
      related = true;
      originId = orderOriginId;
      break;
    default:
      break;
  }

  return {
    orderId: newOrderId,
    originId,
    related,
    cancelOrderStore,
  };
}
