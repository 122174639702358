import React, { Component } from "react";
import { Row, Col } from "antd";
import LayoutHeader from "./header/Header";
import LayoutSidebar from "./sidebar/Sidebar";
import { Helmet } from "react-helmet";

class MainLayout extends Component {
  state = {
    collapsed: false,
    showSticky: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidUpdate(prevProps) {
    let {
      match: { path },
      showBtnSave,
      aggregations: { step },
    } = this.props;
    if (showBtnSave !== prevProps.showBtnSave) {
      if (
        path.includes("/product-bases/edit") ||
        path.includes("/products/edit") ||
        path.includes("/product-bases/add")
      ) {
        if (true === showBtnSave) {
          this.setState({
            showSticky: true,
          });
        } else {
          this.setState({
            showSticky: false,
          });
        }
      }
      if (
        path.includes("/products/add") ||
        path.includes("/products/add-campaign") ||
        (path.includes("/products/campaign") && 2 === step)
      ) {
        if (true === showBtnSave) {
          this.setState({
            showSticky: true,
          });
        } else {
          this.setState({
            showSticky: false,
          });
        }
      }
    }
  }

  render() {
    const { title, showBtnSave, aggregations, onSubmit } = this.props;
    const { showSticky } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title> {`${title ? `${title} - ` : ""}`}Merch Bridge</title>
        </Helmet>
        <LayoutHeader
          onSubmit={(value) => onSubmit(value)}
          aggregations={aggregations}
          showSticky={showSticky}
          showBtnSave={showBtnSave}
        />
        <Row style={{ flexWrap: "nowrap" }}>
          <Col>
            <div className="hidden lg:block">
              <LayoutSidebar {...this.props} />
            </div>
          </Col>
          <Col
            className="p-4 sm:p-6 mb-main-content"
            style={{
              minHeight: "calc(100vh - 64px)",
              background: "#f0f2f5",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            {this.props.children}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

MainLayout.propTypes = {};

export default MainLayout;
