import React, { useState, useCallback, useEffect } from "react";
import {
    FormLayout,
    TextField,
    Button,
    Card,
    ButtonGroup,
    Checkbox,
    Stack,
    Collapsible,
} from "@shopify/polaris";
import styled from "styled-components";
import { Prompt } from "react-router-dom";
import _ from "lodash";

import history from "../../../history";
import useToggle from "../../../hooks/useToggle";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { ProductBaseSelectPolaris } from "../../base/ProductBaseSelectPolaris";
import { CollectionSelectPolaris } from "../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../product/tags/TagsSelectPolaris";
import { AccountSelectPolaris } from "./AccountsSelectPolaris";
import { AuthorsSelectPolaris } from "./AuthorsSelectPolaris";
import { ProductsByDivisionPolaris } from "./ProductsByDivisionPolaris";
import { TimeRange } from "./TimeRange";
import { FieldStore } from "./FieldStore";
import moment from "moment";
import { CreatedAtComp } from "./CreatedAtComp";

const Container = styled.div`
    .btn-wrap {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 2rem;
    }

    .card-header {
        margin-top: -2rem;
    }

    .advanced-options {
        padding-left: 2rem;
        margin-top: 0;
    }
`;

export const ProductDivisionsFormPolaris = (props) => {
    const {
        onSubmit,
        loadingM,
        value,
        isEditDivision,
        divisionId,
        currentParam,
        onlyRecreateMockup,
    } = props;
    const [title, setTitle] = useState("");
    const [productBaseIDs, setProductBaseIDs] = useState([]);
    const [includeAuthors, setIncludeAuthors] = useState([]);
    const [excludeAuthors, setExcludeAuthors] = useState([]);
    const [includeCollections, setIncludeCollections] = useState([]);
    const [excludeCollections, setExcludeCollections] = useState([]);
    const [includeTags, setIncludeTags] = useState([]);
    const [excludeTags, setExcludeTags] = useState([]);
    const [storeIDs, setStoreIDs] = useState([]);
    const [enableUpdate, setEnableUpdate] = useState(true);
    const [divideAllAccount, setDivideAllAccount] = useState(false);
    const [range, setRange] = useState({});
    const [lastSaleStoreIds, setLastSaleStoreIds] = useState([]);
    const [advancedActive, toggleAdvancedActive] = useToggle(false);
    const [createdAt, setCreatedAt] = useState({
        has: false,
        value: {},
    });

    // Only divide ...
    const [showOnlyDUP, setShowOnlyDUP] = useState(false);
    const [onlyDivideUnUsedProducts, setOnlyDivideUnUsedProducts] = useState(
        true
    );
    const [errors, setErrors] = useState({
        title: null,
        storeId: null,
    });
    const [isPrompt, setIsPrompt] = useState(false);

    useEffect(() => {
        if (value) {
            const from = value.from ? moment(value.from) : null;
            const to = value.to ? moment(value.to) : null;
            setRange({ from, to });

            let authors = _.get(value, "authors", []);
            let collections = _.get(value, "collections", []);
            let tags = _.get(value, "tags", []);
            let stores = _.get(value, "stores", []);
            stores = stores.map((s) => s.id);
            let divideAllAccount = _.get(value, "divideAllAccount", null);
            let lastSaleStoreIDs = _.get(value, "lastSaleStoreIDs", []);

            let newIA = getValue(authors, "author", true, true);
            let newEA = getValue(authors, "author", false, true);

            let newIC = getValue(collections, "collection", true);
            let newEC = getValue(collections, "collection", false);

            let newIT = getValue(tags, "tag", true);
            let newET = getValue(tags, "tag", false);

            setTitle(_.get(value, "title", ""));
            setProductBaseIDs(
                value && value.productBases && value.productBases.length
                    ? value.productBases.map((b) => b.id)
                    : []
            );
            setIncludeAuthors(newIA);
            setExcludeAuthors(newEA);

            setIncludeCollections(newIC);
            setExcludeCollections(newEC);
            setIncludeTags(newIT);
            setExcludeTags(newET);
            setStoreIDs(stores);
            setEnableUpdate(value?.enableUpdate);
            setDivideAllAccount(divideAllAccount);
            setLastSaleStoreIds(lastSaleStoreIDs ?? []);

            // Filter created at
            const {
                filterByCreatedAt,
                filterByCreatedAtFrom,
                filterByCreatedAtTo,
            } = value;

            const to2 = moment(filterByCreatedAtTo).format(
                "YYYY-MM-DD[T]HH:mm:ss-07:00"
            );
            const to3 = filterByCreatedAtTo ? moment(to2) : null;
            setCreatedAt({
                has: !!filterByCreatedAt,
                value: {
                    from: filterByCreatedAtFrom
                        ? moment(filterByCreatedAtFrom)
                        : null,
                    to: to3,
                },
            });
        }
    }, [value]);

    const handleTitleChange = useCallback((value) => {
        setTitle(value);
        handleTitleValue(value);
        setIsPrompt(true);
    }, []);

    const handleTitleValue = (value) => {
        let t = null;
        if (!value) {
            t = "Division title is required.";
        }
        setErrors((prevState) => ({
            ...prevState,
            title: t,
        }));
    };
    const handleStoreValue = (value) => {
        let s = null;
        if (!value.length) {
            s = "Store is required.";
        }
        setErrors((prevState) => ({
            ...prevState,
            storeId: s,
        }));
    };
    const handlePB = useCallback((value) => {
        setProductBaseIDs(value);
        setIsPrompt(true);
    }, []);

    const handleIA = useCallback((value) => {
        setIncludeAuthors(value);
        setIsPrompt(true);
    }, []);
    const handleEA = useCallback((value) => {
        setExcludeAuthors(value);
        setIsPrompt(true);
    }, []);
    const handleIC = useCallback((value) => {
        setIncludeCollections(value);
        setIsPrompt(true);
    }, []);
    const handleEC = useCallback((value) => {
        setExcludeCollections(value);
        setIsPrompt(true);
    }, []);
    const handleIT = useCallback((value) => {
        setIncludeTags(value);
        setIsPrompt(true);
    }, []);
    const handleET = useCallback((value) => {
        setExcludeTags(value);
        setIsPrompt(true);
    }, []);
    const handleStoreIDs = useCallback((value) => {
        setStoreIDs(value);
        handleStoreValue(value);
        setIsPrompt(true);
        setShowOnlyDUP(true);
    }, []);
    const handleDivideChange = useCallback((value) => {
        setDivideAllAccount(value);
        setIsPrompt(true);
    }, []);
    const handleTimeChange = useCallback((value) => {
        if (typeof value === "object" && Object.keys(value).length > 0) {
            const { from, to } = value;
            setRange({ from, to });
            setIsPrompt(true);
        } else {
            setRange(() => ({}));
            setIsPrompt(true);
        }
    }, []);
    const handleStoreChange = useCallback((value) => {
        if (Array.isArray(value)) {
            setLastSaleStoreIds(value);
            setIsPrompt(true);
        }
    }, []);

    const handleSubmit = useCallback(() => {
        handleTitleValue(title);
        setIsPrompt(false);
        handleStoreValue(storeIDs);

        let newIA = getValueForSubmit(includeAuthors, true, true);
        let newEA = getValueForSubmit(excludeAuthors, false, true);
        let authors = [...newIA, ...newEA];

        let newIC = getValueForSubmit(includeCollections, true);
        let newEC = getValueForSubmit(excludeCollections, false);
        let collections = [...newIC, ...newEC];

        let newIT = getValueForSubmit(includeTags, true);
        let newET = getValueForSubmit(excludeTags, false);
        let tags = [...newIT, ...newET];

        const newRange = Object.entries(range).reduce((acc, [key, value]) => {
            if (value) {
                return {
                    ...acc,
                    [key]: value,
                };
            }
            return null;
        }, {});

        const filterByCreatedAt = !!createdAt.has;
        const filterByCreatedAtRange =
            filterByCreatedAt && Object.keys(createdAt.value).length > 1
                ? createdAt.value
                : undefined;

        let input = {
            title,
            productBaseIDs,
            collections,
            tags,
            storeIDs,
            authors,
            enableUpdate,
            ...(isEditDivision && showOnlyDUP
                ? { onlyDivideUnUsedProducts }
                : {}),
            range:
                newRange != null && Object.keys(newRange).length === 2
                    ? newRange
                    : undefined,
            lastSaleStoreIds,
            filterByCreatedAt,
            filterByCreatedAtRange,
        };

        if (!isEditDivision) {
            input = {
                ...input,
                onlyRecreateMockup: false,
            };
        }

        if (onlyRecreateMockup) {
            let { title, storeIDs } = input;
            input = {
                title,
                storeIDs,
                enableUpdate: true,
                divideAllAccount,
                ...(isEditDivision && showOnlyDUP
                    ? { onlyDivideUnUsedProducts }
                    : {}),
            };
        }
        if (title && storeIDs.length) {
            if (onSubmit) {
                onSubmit(input);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        title,
        productBaseIDs,
        includeAuthors,
        excludeAuthors,
        includeCollections,
        excludeCollections,
        includeTags,
        excludeTags,
        storeIDs,
        enableUpdate,
        divideAllAccount,
        onlyDivideUnUsedProducts,
        isEditDivision,
        showOnlyDUP,
        range,
        lastSaleStoreIds,
        createdAt,
    ]);

    return (
        <Container>
            <Prompt
                when={isPrompt}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <Card sectioned>
                <FormLayout>
                    <div className="title-wrap">
                        <ComponentLabelPolaris
                            label="Division title"
                            required
                        />
                        <TextField
                            value={title}
                            labelHidden
                            onChange={handleTitleChange}
                            error={errors.title}
                            placeholder="Enter division title"
                        />
                    </div>
                    {!onlyRecreateMockup && (
                        <div className="pBase-wrap">
                            <ProductBaseSelectPolaris
                                value={productBaseIDs}
                                allowMultiple
                                onChange={handlePB}
                                error={errors.productBase}
                            />
                        </div>
                    )}
                </FormLayout>
            </Card>
            {!onlyRecreateMockup && (
                <Card>
                    <Card.Header title="Inclusion products" />
                    <Card.Section>
                        <FormLayout>
                            <AuthorsSelectPolaris
                                label="Include authors"
                                onChange={handleIA}
                                value={includeAuthors}
                            />
                            <CollectionSelectPolaris
                                value={includeCollections}
                                onChange={handleIC}
                                allowMultiple
                                label="Include collections"
                            />
                            <TagsSelectPolaris
                                value={includeTags}
                                onChange={handleIT}
                                allowMultiple
                                label="Include tags"
                            />
                        </FormLayout>
                    </Card.Section>
                    <div className="card-header">
                        <Card.Header title="Exclude Products" />
                    </div>
                    <Card.Section>
                        <FormLayout>
                            <AuthorsSelectPolaris
                                label="Exclude authors"
                                onChange={handleEA}
                                value={excludeAuthors}
                            />
                            <CollectionSelectPolaris
                                value={excludeCollections}
                                onChange={handleEC}
                                allowMultiple
                                label="Exclude collections"
                            />
                            <TagsSelectPolaris
                                value={excludeTags}
                                onChange={handleET}
                                allowMultiple
                                label="Exclude tags"
                            />
                        </FormLayout>
                    </Card.Section>
                    <div className="advanced-options">
                        <Button
                            children="Advanced options"
                            onClick={toggleAdvancedActive}
                            disclosure
                        />
                    </div>
                    <Card.Section>
                        <Collapsible
                            open={advancedActive}
                            transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                        >
                            <Stack spacing="loose" vertical>
                                <TimeRange
                                    onChange={handleTimeChange}
                                    value={range}
                                    isEditDivision={isEditDivision}
                                />
                                <FieldStore
                                    value={lastSaleStoreIds}
                                    onChange={handleStoreChange}
                                />
                                <CreatedAtComp
                                    createdAt={createdAt}
                                    setCreatedAt={setCreatedAt}
                                    setIsPrompt={setIsPrompt}
                                    isEditDivision={isEditDivision}
                                />
                            </Stack>
                        </Collapsible>
                    </Card.Section>
                </Card>
            )}
            <Card sectioned>
                <FormLayout>
                    <AccountSelectPolaris
                        value={storeIDs}
                        onChange={handleStoreIDs}
                        error={errors.storeId}
                    />
                    {showOnlyDUP && isEditDivision && (
                        <Checkbox
                            label="Only divide unused products"
                            checked={onlyDivideUnUsedProducts}
                            onChange={(newValue) => {
                                setOnlyDivideUnUsedProducts(newValue);
                                setIsPrompt(true);
                            }}
                        />
                    )}
                    {!onlyRecreateMockup ? (
                        <Checkbox
                            label="Enable update"
                            checked={enableUpdate}
                            onChange={(newValue) => {
                                setEnableUpdate(newValue);
                                setIsPrompt(true);
                            }}
                        />
                    ) : (
                        <Checkbox
                            label="Divide all matches products to all account"
                            checked={divideAllAccount}
                            onChange={handleDivideChange}
                        />
                    )}
                </FormLayout>
            </Card>
            {isEditDivision && (
                <ProductsByDivisionPolaris divisionId={divisionId} />
            )}
            <div className="btn-wrap">
                <ButtonGroup>
                    <Button
                        onClick={() =>
                            history.push(`/${currentParam}/product-divisions`)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={handleSubmit}
                        loading={loadingM}
                        disabled={!isPrompt}
                    >
                        Calculate product divisions
                    </Button>
                </ButtonGroup>
            </div>
        </Container>
    );
};

function getValue(data = [], pattern, included, isAuthor) {
    if (data.length === 0 || !pattern) return [];

    let result = data
        .filter((i) => i.included === included)
        .filter((i) => i[pattern])
        .map((i) => i[pattern]);

    if (isAuthor) {
        return result.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName} ${lastName}`,
        }));
    }
    return result.map(({ id, name }) => ({ id, name }));
}

function getValueForSubmit(data = [], include, isAuthor = false) {
    if (data.length === 0) return [];
    return data.map((i) => {
        let id = i;
        if (i != null && typeof i === "object") {
            id = isAuthor ? i.value : i.id;
        }
        return { id, include };
    });
}
