import React from "react";
import { Modal } from "@shopify/polaris";

import { AssignButtonFormPolaris } from "./AssignButtonFormPolaris";

export const AssignButtonPolaris = (props) => {
  const { open, toggleShowModal } = props;

  return (
    <Modal
      open={open}
      onClose={toggleShowModal}
      fluidContent
      sectioned
      title="Assign product"
      large
    >
      <AssignButtonFormPolaris {...props} />
    </Modal>
  );
};
