import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { ProductCrawlersPolaris } from "../../components/tools/ProductCrawlersPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const ProductCrawlersPagePolaris = () => {
  return (
    <Container>
      <Page
        title="All Crawlers"
        fullWidth
        primaryAction={{
          content: "New Product Crawler",
          url: "/tools/product-crawler",
        }}
      >
        <ProductCrawlersPolaris />
      </Page>
    </Container>
  );
};
