import {
  Badge,
  Button,
  Card,
  Checkbox,
  Heading,
  Labelled,
  Select,
  Stack,
  Tag,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import noImg from "../../../../assets/images/unnamed.jpeg";
import { KPI } from "../../../../constants/task";
import { useAppContext } from "../../../../context";
import { checkRole } from "../../../../helper";
import useToggle from "../../../../hooks/useToggle";
import { ImageTypes, TASK_STATUS_COLORS_POLARIS } from "../../../../variable";
import { MediaSelectorButton2Polaris } from "../../../file/MediaSelectorButton2Polaris";
import { CollectionSelectPolaris } from "../../../product/collections/CollectionSelectPolaris";
import { NicheSelect } from "../../../product/niches";
import { ProductsSelectPolaris } from "../../../product/ProductsSelectPolaris";
import { TagsSelectPolaris } from "../../../product/tags/TagsSelectPolaris";
import { ViewModalImage } from "../../../product/ViewModalImage";
import ModalImageClaimPolaris from "../../../seller/ModalImageClaimPolaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";
import { CustomLinkPolaris } from "../../../shared/CustomLinkPolaris";
import { useCreateIdea } from "../context/createIdea";
// import { ModalImagePolaris } from "../../../shared/ModalImagePolaris";
import { checkStatusTask } from "../utils";
import { UpdateKpi } from "./actions/UpdateKpi";
import { Activities } from "./Activities";
import { ProductDescription } from "./ProductDescription";
import SuspenseComp from "../../../shared/SuspenseComp";
import DesignKPI from "./DesignKPI";

export function IdeaSection({
  fields,
  handleFieldsChange,
  errors,
  status,
  value,
  isAdminSeller,
  isRoleIdea,
  refetchIdea,
}) {
  // Context
  const { isClone } = useCreateIdea() || {};

  // Actions
  const handleFilesChange = useCallback(
    (value) => {
      handleFieldsChange(value, "medias");
    },
    [handleFieldsChange],
  );

  // Markup
  const statusVal = TASK_STATUS_COLORS_POLARIS.find((o) => o.name === status);
  const headerMarkup = (
    <Stack>
      <Stack.Item fill>
        <Heading children="Idea Info" />
      </Stack.Item>
      {statusVal != null && !isClone && (
        <Badge children={statusVal.name} {...statusVal} />
      )}
    </Stack>
  );
  const medias = fields["medias"];
  const mediasHaveFile = (medias || []).map((i) => ({ file: i }));
  const filesMarkup = genMediaChild(medias);

  // Owner
  const createBy = value?.createBy;
  const ownerMarkup = genUser(createBy);

  // Designer
  const assignee = value?.assignee;
  const designerMarkup = genUser(assignee);

  const { collectionIds, tagIds, nicheIds } = fields || {};

  const { isPending, isDraft, isDeclined } = checkStatusTask(status);
  const canUpdate =
    (isAdminSeller || isRoleIdea) &&
    (status == null || isPending || isDraft || isClone || isDeclined);

  let collectionsMarkup, tagsMarkup, nichesMarkup;
  if (!canUpdate) {
    collectionsMarkup = genTagMarkup(collectionIds);
    tagsMarkup = genTagMarkup(tagIds);
    nichesMarkup = genTagMarkup(nicheIds);
  }
  const { declineReason } = value || {};

  return (
    <Wrapper className="idea-section">
      <Card.Section title={headerMarkup}>
        <Stack spacing="loose" vertical>
          {canUpdate ? (
            <div>
              <ComponentLabelPolaris label="Title" required />
              <TextField
                value={fields["title"]}
                placeholder="Idea title"
                id="title"
                onChange={handleFieldsChange}
                error={errors["title"]}
              />
            </div>
          ) : (
            <ItemWrap label="Title">
              <span>{fields["title"]}</span>
            </ItemWrap>
          )}
          {canUpdate ? (
            <ProductDescription
              value={fields["description"]}
              canEdit
              onChange={(value) => handleFieldsChange(value, "description")}
            />
          ) : (
            <ProductDescription
              value={fields["description"]}
              onChange={(value) => handleFieldsChange(value, "description")}
            />
          )}
          {canUpdate ? (
            <CollectionSelectPolaris
              label="Collections"
              required
              allowMultiple
              getCoupleValue
              value={fields["collectionIds"]}
              errorMessage={errors["collectionIds"]}
              haveQuickAdd
              onChange={(value) => handleFieldsChange(value, "collectionIds")}
            />
          ) : (
            <ItemWrap label="Collections">{collectionsMarkup}</ItemWrap>
          )}
          {canUpdate ? (
            <TagsSelectPolaris
              label="Tags"
              required
              allowMultiple
              getCoupleValue
              haveQuickAdd
              value={fields["tagIds"]}
              errorMessage={errors["tagIds"]}
              onChange={(value) => handleFieldsChange(value, "tagIds")}
            />
          ) : (
            <ItemWrap label="Tags">{tagsMarkup}</ItemWrap>
          )}
          {canUpdate ? (
            <NicheSelect
              label="Niches"
              allowMultiple
              haveQuickAdd
              value={fields["nicheIds"]}
              getCoupleValue
              onChange={(value) => handleFieldsChange(value, "nicheIds")}
            />
          ) : (
            <ItemWrap label="Niches">{nichesMarkup}</ItemWrap>
          )}
          <RelatedProductComp
            value={fields["relatedProductIds"]}
            onChange={handleFieldsChange}
            canUpdate={canUpdate}
          />
          {canUpdate ? (
            <div>
              <Labelled label="Media" />
              <MediaSelectorButton2Polaris
                value={fields["medias"]}
                multiple
                singleUpload={true}
                accept={"image/*"}
                sizeSmall
                onChange={handleFilesChange}
                showTitle
                width={120}
                height={120}
                fromIdeaTask
              />
            </div>
          ) : (
            <div>
              <Labelled label="Media:" />
              <MediaComp files={mediasHaveFile}>{filesMarkup}</MediaComp>
            </div>
          )}
          {ownerMarkup && !isClone && (
            <div>
              <span className="label-inline">Idea owner: </span>
              <span>{ownerMarkup}</span>
            </div>
          )}
          {designerMarkup && !isClone && (
            <div>
              <span className="label-inline">Designer: </span>
              <span>{designerMarkup}</span>
            </div>
          )}
          <KPIComp
            selected={fields["ideaKpi"]}
            onChange={handleFieldsChange}
            assignee={assignee}
            canUpdate={canUpdate}
            value={value}
            refetchIdea={refetchIdea}
            isClone={isClone}
          />
          <DesignKPI value={value} refetchIdea={refetchIdea} />
          <Checkbox
            label="Prioritize this task"
            checked={fields["priority"]}
            id="priority"
            onChange={handleFieldsChange}
          />
          {isDeclined && declineReason != null && (
            <div>
              <span className="label-inline">Decline Reason: </span>
              <span>{declineReason}</span>
            </div>
          )}
          {value && !isClone && (isAdminSeller || isRoleIdea) && (
            <Activities taskId={value?.id} />
          )}
        </Stack>
      </Card.Section>
    </Wrapper>
  );
}

const DIV_ITEM_PT = "img-item";
export function genMediaChild(
  images,
  // canUpdateMainImage,
  // handleChecked,
  mainImageId,
) {
  if (!images || images.length === 0) return null;

  return images.map((image, index) => {
    const { thumbnailUrl, url, mockupId, name, id, mimeType } = image || {};
    const src = thumbnailUrl || url || noImg;
    const isImg = ImageTypes.includes(mimeType);

    return (
      <ImageWrapper className="image-wrap" key={`media-${index}`}>
        <div
          className={`img-box-shadow ${DIV_ITEM_PT}`}
          key={`media-${index}`}
          style={{ cursor: "pointer" }}
        >
          {(mainImageId || []).includes(mockupId) && (
            <Checkbox checked={true} />
          )}
          {isImg && (
            <img
              src={src}
              thumbnail-url={thumbnailUrl}
              url={url}
              width={150}
              height={150}
              id={id}
              alt=""
            />
          )}
        </div>
        <span title={name} className="image-name">
          {isImg ? (
            name
          ) : (
            <CustomLinkPolaris href={url || thumbnailUrl}>
              {name}
            </CustomLinkPolaris>
          )}
        </span>
      </ImageWrapper>
    );
  });
}

const ImageWrapper = styled.div`
  --width: 15rem;

  width: var(--width, 150px);

  .image-name {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;

    margin-top: 0.5rem;
  }
`;

export function MediaComp({ children, suffixEl, files }) {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [source, setSource] = useState([]);

  const items = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: () => {
        toggleOpen();

        const childrenProp = child?.props?.children;
        const divItem = (childrenProp || []).find((i) =>
          new RegExp(DIV_ITEM_PT).test(i?.props?.className),
        );
        const divChild = divItem?.props?.children;
        const deepChildren = (divChild || []).filter(Boolean);
        const imgEl =
          deepChildren instanceof Array
            ? deepChildren.find((item) => item.type === "img")
            : deepChildren;

        if (imgEl != null) {
          const { props } = imgEl;
          const { id } = props || {};

          const indexCur = source.findIndex((f) => f?.file?.id === id);
          if (indexCur > -1) {
            const newSource = [
              source[indexCur],
              ...source.filter((_el, index) => index !== indexCur),
            ];

            setSource(newSource);
          }
        }
      },
    });
  });

  useEffect(() => {
    if (files?.length > 0) {
      setSource(files);
    }
  }, [files]);

  const images = (source || []).filter((f) => f?.file);
  return (
    <MediaWrapper className="media-wrapper">
      {items}
      {/* <ModalImagePolaris
                open={open}
                toggleShowModal={toggleOpen}
                source={source}
                title="View image"
            /> */}
      <ViewModalImage
        item={{ images }}
        open={open}
        toggleShowModal={toggleOpen}
        large
      />
      {suffixEl}
    </MediaWrapper>
  );
}

export function RelatedProductComp({ value = [], onChange, canUpdate }) {
  // Actions
  const handleSpecialProducts = useCallback(
    (value) => {
      onChange(value, "relatedProductIds");
    },
    [onChange],
  );

  const label = "Related with exists products";
  const productsMarkup = (value || []).map((p, index) => {
    const { title, images, sku, hasPsdInNas, designPositions, productBases } =
      p || {};

    const newBases = (productBases || []).map((b) => {
      const { id } = b || {};
      const dps = (designPositions || []).filter((dp) => {
        const { designPosition, file } = dp || {};
        const { productBaseId } = designPosition || {};

        return productBaseId === id && file != null;
      });

      return {
        ...b,
        designPositions: dps,
      };
    });

    // Image
    let image = images?.length > 0 ? images.map((i) => i.file) : [];
    const printFileMockup =
      newBases?.length > 0 ? (
        <div>
          <Heading>Print file:</Heading>
          <div className="bases-wrap">
            {newBases?.map((b, index, arr) => {
              const { title, designPositions } = b || {};

              const baseLen = arr.length;
              const showTitle = baseLen > 1;
              const titleMarkup = <span title={title}>{title}</span>;
              return (
                <div key={`base-${index}`}>
                  {showTitle && titleMarkup}
                  <div>
                    {(designPositions || []).map((dp, idx) => {
                      return (
                        <DesignPositionItem
                          key={`design-position-${idx}`}
                          value={dp}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null;

    return (
      <div className="product-wrap" key={`related-product-${index}`}>
        <ModalImageClaimPolaris
          files={{ files: image }}
          limit={1}
          width={150}
          height={150}
        />
        <div className="info">
          <span>{title}</span>
          <Badge children={sku} />
          {hasPsdInNas && <Badge children="Has PSD in Nas" status="success" />}
          {printFileMockup}
        </div>
      </div>
    );
  });

  return canUpdate ? (
    <ProductsSelectPolaris
      label={label}
      value={value}
      allowMultiple
      onChange={handleSpecialProducts}
      hasSku
    />
  ) : (
    <ItemWrap label={label}>
      <ProductWrapper>{productsMarkup}</ProductWrapper>
    </ItemWrap>
  );
}

function KPIComp({
  selected,
  onChange,
  assignee,
  canUpdate,
  value,
  refetchIdea,
  isClone,
}) {
  const { currentUser } = useAppContext();
  const { isSeller, isStoreManager, isIdea, isIdeaLeader, isDesignLeader } =
    checkRole(currentUser);
  const isAdminSeller = isSeller || isStoreManager;
  const isRoleIdea = isIdea || isIdeaLeader;

  const options = useMemo(() => {
    return KPI.map((i) => {
      const iStr = String(i);

      return {
        value: iStr,
        label: iStr,
        disabled: selected === iStr,
      };
    });
  }, [selected]);
  const { ideaKpi } = value || {};

  return isAdminSeller || isRoleIdea ? (
    <div className="kpi-wrap">
      {(assignee == null || isClone) && canUpdate ? (
        isAdminSeller ? (
          <Select
            label="Idea KPI"
            options={options}
            value={selected}
            id="ideaKpi"
            onChange={onChange}
          />
        ) : (
          // <span>
          //     <TextStyle variation="strong">Idea KPI: </TextStyle>
          //     <span>{ideaKpi}</span>
          // </span>
          <span />
        )
      ) : (
        <UpdateKPIAction value={value} refetchIdea={refetchIdea}>
          <span className="label-inline">Idea KPI: </span>
          <span>{selected}</span>
        </UpdateKPIAction>
      )}
    </div>
  ) : isDesignLeader && ideaKpi ? (
    <span>
      <TextStyle variation="strong">Idea KPI: </TextStyle>
      <span>{ideaKpi}</span>
    </span>
  ) : null;
}

function UpdateKPIAction({ children, value, refetchIdea }) {
  // State
  const [open, toggleOpen] = useToggle(false);

  return (
    <Stack alignment="center">
      <Stack.Item fill>{children}</Stack.Item>
      <Button children="Update KPI" onClick={toggleOpen} size="slim" />
      <UpdateKpi
        open={open}
        onClose={toggleOpen}
        item={value}
        refetchIdea={refetchIdea}
      />
    </Stack>
  );
}

function genUser(user) {
  if (!user) return null;
  const { firstName, lastName } = user || {};

  return [firstName, lastName].filter(Boolean).join(" ");
}

export function ItemWrap({ label, children }) {
  return (
    <div>
      <Labelled label={`${label}: `} />
      {children}
    </div>
  );
}

function DesignPositionItem({ value }) {
  const { designPosition, file } = value || {};
  const { name } = designPosition || {};
  const href = file?.url
    ? file.url
    : file?.thumbnailUrl
    ? file.thumbnailUrl
    : null;

  return (
    <span className="design-positions-item">
      <CustomLinkPolaris href={href}>{name}</CustomLinkPolaris>
    </span>
  );
}

export function genTagMarkup(data, key = "name") {
  if (!data || data.length === 0) return null;

  const result = data
    .map((v, index) => {
      if (typeof v === "object" && key in v) {
        const keyProp = `${key}-${v[key]}-${index}`;
        return <Tag children={v[key]} key={keyProp} />;
      }
      return null;
    })
    .filter(Boolean);

  return <Stack spacing="tight">{result}</Stack>;
}

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  .product-wrap {
    display: flex;
    flex-direction: row;

    .bases-wrap {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.7rem;

      .design-positions-item + .design-positions-item {
        margin-left: 0.5rem;
        display: inline-block;
      }
    }

    img {
      width: 15rem;
      height: 15rem;
    }

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      word-break: break-word;

      .Polaris-Badge {
        align-self: baseline;
      }

      .Polaris-Badge.Polaris-Badge--statusSuccess {
        background: rgb(0, 24, 51);
        color: rgb(45, 170, 255);
      }
    }
  }
`;

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.6rem;
`;

const Wrapper = styled.div`
  .list-file_wrap {
    &.multiple {
      flex-direction: row;
      align-items: start;

      .file {
        &-item {
          flex-direction: column;
          row-gap: 0.5rem;
          height: auto;
        }

        &-name {
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
`;
