import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";

import { multiSearchAnd, sortAttributeForMap } from "../../helper";

export const VariantsSelectPolaris = ({
  productBaseVariants,
  value,
  onChange,
  selectedVariants,
  enabled = false,
}) => {
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [selecetedOptions, setSelelctedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    let newValue = null;
    if (value) {
      newValue =
        value && value.productBaseVariant ? value.productBaseVariant.id : null;
      setSelelctedOptions([newValue]);
      let name = getAttributeName(
        value &&
          value.productBaseVariant &&
          value.productBaseVariant.attributes,
      );
      setInputValue(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    let newOptions = [];
    if (productBaseVariants && productBaseVariants.length) {
      newOptions = productBaseVariants.map((item) => {
        let label = getAttributeName(item.attributes);
        if (item?.fulfillmentSku && item.fulfillmentSku.length > 0) {
          label += ` (${item.fulfillmentSku})`;
        }

        return {
          value: item.id,
          label,
          disabled: enabled ? false : selectedVariants.includes(item.id),
        };
      });
    }
    setOptions(newOptions);
    setDeselectedOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBaseVariants, selectedVariants, enabled]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      // const filterRegex = new RegExp(value, "i");
      let formatValue = value.replace(/\s\s+/g, " ").split(" ");
      const resultOptions = deselectedOptions.filter((option) => {
        let result = multiSearchAnd(option.label, formatValue);
        return result;
      });

      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelelctedOptions(selected);
      setInputValue(_.head(selectedValue));
      value.productBaseVariant = productBaseVariants.find(
        (pv) => pv.id === _.head(selected),
      );
      if (onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const textFiledMarkup = (
    <Autocomplete.TextField
      label="Variants"
      labelHidden
      value={inputValue}
      onChange={handleInputValue}
      disabled={!productBaseVariants.length}
    />
  );

  // return title of attribute and sort a-z
  const getAttributeName = useCallback((attributes = []) => {
    if (!attributes) {
      return "";
    }

    return sortAttributeForMap(attributes);
  }, []);

  return (
    <Autocomplete
      selected={selecetedOptions}
      onSelect={handleSelection}
      options={options}
      textField={textFiledMarkup}
      emptyState={
        productBaseVariants.length ? <span>No item found!</span> : null
      }
    />
  );
};
