import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page, Tabs } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER, USER_ROLE } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { DataTableDepositsPolaris } from "./DataTableDepositsPolaris";
import { DepositsFilterPolaris } from "./DepositsFilterPolaris";

export const QUERY_TRANSACTIONS = gql`
  query transactons($filter: TransactionFilter) {
    transactions(filter: $filter) {
      total
      nodes {
        id
        amount
        journal {
          id
          refId
          comment
          status
        }
        createdAt
        account {
          id
          firstName
          lastName
        }
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status", "creatorId", "accountId"];

export const DepositsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [selected, setSelected] = useState(0);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageDeposits")) || 20,
    offset: 0,
    search: null,
    status: null,
    creatorId: null,
    accountId: null,
    kind: ["Deposit"],
    viewBySeller: true,
    ...initFilter,
  });

  // Query
  const { data, loading, error, refetch } = useQuery(QUERY_TRANSACTIONS, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  // Handle actions
  useEffect(() => {
    let { offset, limit, kind, viewBySeller, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setFilter((prev) => ({
      ...prev,
      status: null,
      creatorId: null,
      accountId: null,
      offset: 0,
      viewBySeller: selectedTabIndex ? false : true,
    }));
  }, []);

  const tabs = [
    {
      id: "seller-deposits",
      content: "Seller deposits",
      accessibilityLabel: "Seller deposits",
    },
    {
      id: "supplier-deposits",
      content: "Supplier deposits",
      accessibilityLabel: "Supplier deposits",
    },
  ];

  const role = selected ? USER_ROLE.Supplier : USER_ROLE.Seller;

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Page
      title="All Deposits"
      fullWidth
      {...(selected
        ? {
            primaryAction: {
              content: "Add new Deposit",
              url: "/admin/billings/deposits/add",
            },
          }
        : {})}
    >
      {loadingMarkup}
      <Card sectioned>
        <DepositsFilterPolaris
          role={role}
          filter={filter}
          onChange={({ search, selectId, status }) => {
            let selectedId = selected ? "accountId" : "creatorId";
            setFilter((prev) => {
              if (
                !isEqual(prev[selectedId], selectId) ||
                !isEqual(prev.search, search) ||
                !isEqual(prev.status, status)
              ) {
                return {
                  ...prev,
                  [selectedId]: selectId,
                  search,
                  status,
                  offset: 0,
                };
              }
              return prev;
            });
          }}
        />
      </Card>
      <Card>
        {error && (
          <div style={{ padding: "1.6rem" }}>
            {handleError(error.toString())}
          </div>
        )}
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {loading ? (
            <SkeletonPagePolaris />
          ) : data?.transactions?.nodes?.length > 0 ? (
            <DataTableDepositsPolaris
              data={data}
              filter={filter}
              role={role}
              refetch={refetch}
              setFilter={(offset, limit) => {
                setFilter((prev) => {
                  if (
                    !isEqual(prev.offset, offset) ||
                    !isEqual(prev.limit, limit)
                  ) {
                    return {
                      ...prev,
                      offset,
                      limit,
                    };
                  }
                  return prev;
                });
                setCookie("perPageDeposits", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Tabs>
      </Card>
    </Page>
  );
};
