import { useMutation, useQuery } from "@apollo/react-hooks";
import { Autocomplete, Spinner, Stack } from "@shopify/polaris";
import { gql } from "apollo-boost";
import get from "lodash/get";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context";
import { FieldFragment } from "../../../graphql/fragments";
import { checkRole, getAttributeName, getParam } from "../../../helper";
import useLayoutUpdateEffect from "../../../hooks/useLayoutUpdateEffect";
import { useProductVariants } from "../../../hooks/useProductVariants";
import useToggle from "../../../hooks/useToggle";
import { SelectedItem } from "../../mapping_order/MapOrderEPFormPolaris";
import { VariantsSelectEPPolaris } from "../../mapping_order/VariantsSelectEPPolaris";
import { PersonalizedOptionPolaris } from "../../order/actions/PersonalizedOptionPolaris";
import { GEN_THUMBNAIL_IMAGE } from "../../seller/ModalImageClaimPolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { ModalImagePolaris } from "../../shared/ModalImagePolaris";
import { useFetchProducts } from "../hooks/useFetchProducts";

const GET_FIELD_VALUE_FOR_ORDER = gql`
  query getFieldValueForOrder($orderId: ID!) {
    getFieldValueForOrder(orderId: $orderId) {
      id
      createdAt
      field {
        ...FieldFragment
      }
      value
    }
  }
  ${FieldFragment}
`;

const TYPE = "Type";
function InternalProductAutocomplete(
  { personalized, orderId, setDisabled },
  ref,
) {
  const [options, setOptions] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, toggleOpen] = useToggle(false);
  const [variantSelected, setVariantSelected] = React.useState({});
  const [fields, setFields] = React.useState({});
  const [isPersonalized, setIsPersonalized] = React.useState(personalized);
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    search: "",
  });

  // Context
  const { currentUser } = useAppContext();
  const {
    isAdministrator,
    isStoreManager,
    isSeller,
    isMarketplaceManager,
  } = checkRole(currentUser);
  const param = getParam(currentUser);

  // Hooks
  const { data, loading } = useFetchProducts(filter);
  const [getVariants] = useProductVariants();
  const [genThumbnail] = useMutation(GEN_THUMBNAIL_IMAGE, {
    onError: () => {},
  });

  // Query
  const { data: datafield } = useQuery(GET_FIELD_VALUE_FOR_ORDER, {
    variables: {
      orderId,
    },
    skip: !orderId || !isPersonalized,
  });

  React.useEffect(() => {
    if (datafield?.getFieldValueForOrder?.length > 0) {
      const f = datafield.getFieldValueForOrder.reduce((acc, { field, value }) => {
        if (!field || typeof field !== "object" || !field.id) return acc;
        const [val] = value.filter(Boolean);
        acc[field.id] = val;
        return acc;
      }, {});
      setFields(f);
    }
  }, [datafield]);

  // Actions
  const handleImgErr = useCallback(
    (file) => () => {
      if (file != null) {
        genThumbnail({
          variables: {
            files: [file.id],
            fileType: "id",
          },
        });
      }
    },
    [genThumbnail],
  );

  const typingTimeout = React.useRef(null);
  const handleInputChange = React.useCallback((s) => {
    setInput(s);
    typingTimeout.current && clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, search: s }));
    }, 500);
  }, []);

  const handleSelection = React.useCallback(
    (selected) => {
      const selectedValue = selected.map((item) => {
        const matchedOptions = options.find((opt) => opt.value === item);
        let children =
          matchedOptions && get(matchedOptions, "label.props.children");
        let label = "";

        for (let child of children) {
          if (child?.props?.className && "title" === child.props.className) {
            label = child.props.children;
          }
        }

        return label;
      });

      setVariantSelected({});
      setDisabled(true);
      setSelected(selected);

      //
      if (selected?.length > 0 && options?.length > 0) {
        const match = options.find((item) => selected.includes(item.value));
        if (match) {
          setIsPersonalized(!!match.personalized);
        }
      }
    },
    [options],
  );

  const handleRemoveProduct = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleFieldChange = useCallback(
    (id) => (newValue) => {
      setFields((prev) => {
        return {
          ...prev,
          [id]: newValue,
        };
      });
    },
    [],
  );

  useEffect(() => {
    const nodes = data?.products?.nodes || [];
    if (nodes?.length > 0) {
      const opts = nodes.map((p) => genOption(p, handleImgErr));
      setOptions(opts);
    }
  }, [data, handleImgErr]);

  useLayoutUpdateEffect(() => {
    if (!data) return;
    if (selected?.length > 0 && data.products?.nodes?.length > 0) {
      const nodes = data.products.nodes;
      const [f] = selected;
      const cur = nodes.find(({ id }) => id === f);

      if (cur) {
        (async function (cur) {
          const newItem = await getVariants(cur);
          setSelectedItem(newItem);
        })(cur);
      }
    }
  }, [data, selected]);

  const handleSubmit = useCallback(() => {
    const productVariantId = get(variantSelected, "productBaseVariant.id");
    const personalItems = Object.entries(
      fields || {},
    ).map(([fieldId, value]) => ({ fieldId, value: value ? value : "" }));

    return {
      productVariantId,
      personalItems: personalItems?.length > 0 ? personalItems : undefined,
    };
  }, [selectedItem, variantSelected, fields]);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  // Markup
  const textField = (
    <Autocomplete.TextField
      value={input}
      onChange={handleInputChange}
      placeholder="Search ..."
    />
  );

  const showUrlProduct = [
    isAdministrator,
    isSeller,
    isStoreManager,
    isMarketplaceManager,
  ].some(Boolean);

  const urlSelectedItem = getImageUrl(selectedItem);
  const urlProduct = getProductUrl(selectedItem, param);
  const originalSku = selectedItem ? selectedItem.sku : null;

  const productBaseVariants = getProductVariants(selectedItem);

  return (
    <Wrapper>
      <ComponentLabelPolaris label="Choose a product" />
      <Stack vertical>
        <Stack.Item fill>
          <Autocomplete
            options={options}
            onSelect={handleSelection}
            selected={selected}
            textField={textField}
            emptyState={<span>No item found!</span>}
            id="mp__choose-product"
            willLoadMoreResults
            loading={loading}
          />
        </Stack.Item>
        {selectedItem && (
          <SelectedItem
            urlSelectedItem={urlSelectedItem}
            showUrlProduct={showUrlProduct}
            urlProduct={urlProduct}
            selectedItem={selectedItem}
            toggleShowModal={toggleOpen}
            handleRemoveProduct={handleRemoveProduct}
          />
        )}
        <Stack.Item fill>
          <div>
            <ComponentLabelPolaris label="Product Variants" />
            <VariantsSelectEPPolaris
              productBaseVariants={productBaseVariants}
              selectedVariants={[]}
              value={variantSelected}
              onChange={(value) => {
                setVariantSelected(value);
                setDisabled(false);
              }}
            />
          </div>
        </Stack.Item>
        {personalized && datafield?.getFieldValueForOrder != null
          ? datafield.getFieldValueForOrder.map((v, i) => (
              <PersonalizedOptionPolaris
                key={`option-${i}`}
                value={v}
                isDesigner={false}
                onChange={handleFieldChange(v?.id)}
              />
            ))
          : null}
        ∏
      </Stack>
      <ModalImagePolaris
        toggleShowModal={toggleOpen}
        open={open}
        source={urlSelectedItem}
        title={originalSku}
      />
    </Wrapper>
  );
}

function genOption(product, handleImgErr) {
  if (typeof product !== "object") return product;

  let ffTitle = "";
  const { productBases, images, title, sku, personalized } = product;
  if (productBases?.length > 0) {
    ffTitle = productBases
      .map((b) => {
        let t = b.fulfillment?.fulfillmentTitle || "";
        t = t ? `${t} - ` : "Merch Bridge - ";
        return `${t}${b.title}`;
      })
      .filter(Boolean)
      .join(", ");
  }

  const imgLen = images?.length || 0;
  const { file } = (images.filter(Boolean) || [])[0] || {};
  const { thumbnailUrl, url } = file || {};
  const imgSrc = thumbnailUrl ? thumbnailUrl : url;

  const label = (
    <div className="label-wrap">
      {imgLen > 0 ? (
        <img alt={`product image`} src={imgSrc} onError={handleImgErr(file)} />
      ) : null}
      <div className="title">{title?.trim() || ""}</div>
      <div>{`(${ffTitle ? ffTitle : "Merch Bridge"})`}</div>
      <div>{`(${sku})`}</div>
    </div>
  );

  return {
    value: product.id,
    label,
    personalized,
  };
}

function getImageUrl(item) {
  if (!item || !item.images || item.images.length === 0) return "";

  let res = "";
  const { mainImageId, images } = item;
  let image = null;

  let newImages = images?.length > 0 ? images.filter(Boolean) : [];
  if (mainImageId || mainImageId !== "undefined") {
    image = newImages.find(({ file }) => file?.id === mainImageId);
  }

  if (!image) {
    [image] = newImages;
  }
  const { file } = image || {};
  const { thumbnailUrl, url } = file || {};

  res = url ? url : thumbnailUrl ? thumbnailUrl : null;
  return res;
}

function getProductUrl(item, param) {
  if (!item || !item.id) return null;

  const { id, productBases } = item;
  if (!productBases || productBases.length === 0) return null;

  const link =
    productBases.length > 1
      ? `/${param}/products/campaign`
      : `/${param}/products/edit`;

  return `${link}/${id}`;
}

function getProductVariants(product) {
  if (
    !product ||
    !product.variants ||
    !product.productBases ||
    product.variants.length === 0 ||
    product.productBases.length === 0
  )
    return [];

  const variants = product.variants;
  const bases = product.productBases;
  const baseLen = bases.length;
  const isCampaign = baseLen > 1;
  function getAttributes(baseVariant, index) {
    let attr = baseVariant
      ? getAttributeName(baseVariant.attributes)
      : getAttributeName(get(bases[0]?.variants[index], "attributes"));
    if (isCampaign) {
      attr =
        baseVariant &&
        getAttributeName([
          ...baseVariant.attributes,
          {
            option: baseVariant?.productBase?.title || "",
            id: 0,
            name: TYPE,
          },
        ]);
    }

    return attr;
  }

  return variants.map((v, i) => {
    return {
      id: v.id,
      disabled: v.disabled,
      attribute: getAttributes(v.productBaseVariant, i),
    };
  });
}

export const ProductAutocomplete = React.forwardRef(
  InternalProductAutocomplete,
);

const Wrapper = styled.div`
  .selected-wrap {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;

    .design-positions-wrap {
      flex: 1 1 auto;
      margin-top: -0.6rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.6rem 2rem;

      .Polaris-Tabs__Title {
        padding-bottom: 1rem;
        padding-top: 0;
      }
    }

    .design-position-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.875rem;

      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
          rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
        border-radius: 5px;
      }
    }
  }

  .selected-info {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: calc(50% - 1.5rem);

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
      border-radius: 5px;
    }
    .image-wrap {
      width: 120px;
      height: 120px;
      position: relative;

      .action-remove {
        position: absolute;
        right: 0;
        display: inline-block;
        top: 3px;
      }
    }
  }
`;
