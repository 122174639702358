import { useQuery } from "@apollo/react-hooks";
import { Autocomplete } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React from "react";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const GET_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

function StoreAutocompleteInternal(
  { label, error, allowMultiple, required, onChange },
  ref
) {
  // State
  const [options, setOptions] = React.useState([]);
  const [inputVal, setInputVal] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    search: "",
  });

  // Query
  const { data, loading, error: err } = useQuery(GET_STORES, {
    variables: {
      filter,
    },
  });

  React.useEffect(() => {
    const nodes = data?.stores?.nodes || [];
    const opts = nodes.map(({ id, title }) => ({ value: id, label: title }));

    setOptions(opts);
  }, [data]);

  // Actions
  const timeoutRef = React.useRef(null);
  const handleInputChange = React.useCallback((search) => {
    setInputVal(search);
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search }));
    }, 500);
  }, []);

  const handleSelection = React.useCallback(
    (selected) => {
      const selectedValue = selected.map((item) => {
        const matchedOption = options.find((opt) => {
          return opt.value === item;
        });

        return matchedOption && matchedOption.label;
      });
      setSelected(selected);
      onChange && onChange(selected);
      const [input] = selectedValue || [];
      setInputVal(input);
    },
    [options]
  );

  // Markup
  const textFieldMarkup = (
    <Autocomplete.TextField
      placeholder="Search store"
      value={inputVal}
      onChange={handleInputChange}
      error={error}
    />
  );

  // Ref
  const getSelected = React.useCallback(() => selected, [selected]);

  React.useImperativeHandle(ref, () => ({
    getSelected,
  }));

  return (
    <>
      <ComponentLabelPolaris label={label ?? "Store"} required={required} />
      {err ? (
        <div>{err?.toString()}</div>
      ) : (
        <Autocomplete
          options={options}
          selected={selected}
          onSelect={handleSelection}
          textField={textFieldMarkup}
          allowMultiple={allowMultiple}
          emptyState={<span children="No items found!" />}
          loading={loading}
        />
      )}
    </>
  );
}

export const StoreAutocomplete = React.forwardRef(StoreAutocompleteInternal);
