import React from "react";
import { Timeline } from "antd";
import styled from "styled-components";
import moment from "moment";

const Container = styled.div`
  .ant-timeline-item-content {
    display: flex;
    width: calc(100% - 26px);
    justify-content: space-between;
  }
  .ant-timeline-item-head-blue {
    border-color: #3f4eae;
    color: #3f4eae;
  }
  .ant-timeline-item.ant-timeline-item-last {
    padding: 0px;
  }
`;

export const CustomTimeline = (props) => {
  const { data } = props;
  return (
    <Container>
      <Timeline className="pt-5">
        {data.map((history) => (
          <Timeline.Item key={history.id}>
            <div
              className="flex-1"
              dangerouslySetInnerHTML={{ __html: history.title }}
            />
            <div style={{ marginLeft: "1rem" }}>
              {moment(history.createdAt).format("LLL")}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
};
