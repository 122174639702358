import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback } from "react";
import { FETCH_ETSY_ORDERS } from "../../../graphql/mutations";

export function FetchOrder({
    open,
    onClose,
    storeId,
    toggleActiveToast,
    setNotify,
    refetch,
}) {
    // Mutation
    const [fetchOrder, { loading }] = useMutation(FETCH_ETSY_ORDERS, {
        onCompleted: () => {
            setNotify((prev) => ({
                ...prev,
                msg: "Fetch orders successfully.",
                err: false,
            }));
            refetch && refetch();
        },
        onError: (err) => {
            setNotify((prev) => ({ ...prev, msg: err?.toString(), err: true }));
        },
    });

    // Handle action
    const handleSubmit = useCallback(() => {
        if (storeId == null) return;
        toggleActiveToast();
        fetchOrder({ variables: { storeId, limit: 100 } });
    }, [storeId, toggleActiveToast]);

    return (
        <Modal
            title="Fetch Etsy's orders."
            open={open}
            onClose={onClose}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            large
            sectioned
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        >
            <p>Are you sure to fetch Etsy's orders?</p>
        </Modal>
    );
}
