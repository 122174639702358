import React, { useState, useCallback, useEffect, useContext } from "react";
import { Autocomplete, Tag, Spinner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { TEAM_ROLE, USER_ROLE } from "../../../variable";
import { AppContext } from "../../../context";
import { getUnique, reducerFn } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export const QUERY_AUTHORS = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      hits {
        user {
          id
          firstName
          lastName
          roles
          teamUser {
            role
          }
        }
      }
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const initialFilter = {
  limit: 20,
  offset: 0,
  search: null,
  teamRoles: [
    TEAM_ROLE.Admin,
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
    TEAM_ROLE.Supporter,
  ],
};

export const AuthorsSelectPolaris = (props) => {
  const { value, label, onChange, getCoupleValue } = props;
  const [inputValue, setInputValue] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);
  const [filter, setFilter] = React.useReducer(reducerFn, initialFilter);

  // Context
  const { currentUser } = useContext(AppContext);

  const { data, loading, error } = useQuery(QUERY_AUTHORS, {
    variables: {
      filter,
    },
  });

  useEffect(() => {
    let newData =
      data &&
      data.teamMembers &&
      data.teamMembers.hits &&
      data.teamMembers.hits.length > 0
        ? data.teamMembers.hits.map((tm) => {
            const user = tm && tm.user;
            const userRole = user && user.roles ? user.roles : [];
            const teamRole =
              user && user.teamUser && user.teamUser.role
                ? user.teamUser.role
                : null;
            if (user) {
              if (
                userRole.includes(USER_ROLE.Seller) &&
                [
                  TEAM_ROLE.Admin,
                  TEAM_ROLE.MarketplaceManager,
                  TEAM_ROLE.StoreManager,
                  TEAM_ROLE.Supporter,
                ].includes(teamRole)
              ) {
                return {
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                };
              }
            }
            return null;
          })
        : [];
    newData = newData.filter(Boolean);

    // Current user
    let userCurrent = {};
    if (currentUser) {
      userCurrent = {
        value: currentUser?.id,
        label: `${currentUser?.firstName} ${currentUser.lastName}`,
      };
    }
    let hasCurrent = newData.find((i) => [i.value].includes(currentUser?.id));
    if (!hasCurrent) {
      newData = [userCurrent, ...newData];
    }
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [data, currentUser]);

  useEffect(() => {
    let newValue = [];
    let newData = [];
    if (value && _.isArray(value)) {
      newData = value.map((v) => {
        if (typeof v === "object") {
          newValue.push(v.value);
          return {
            label: v.label,
            value: v.value,
          };
        } else {
          newValue.push(v);
          return null;
        }
      });
    }
    if (newData.length && newData.filter(Boolean).length > 0) {
      setOptions((prevState) => {
        let result = getUnique([...prevState, ...newData], "value");
        return result;
      });

      // Show selected label
      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...newData], "value");
        return result;
      });
    }
    setSelectedOptions(newValue);
  }, [value, deselectedOptions]);

  const timeoutRef = React.useRef(null);
  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);

      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setFilter({ search: value });
      }, 500);
      return;
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => {
        return option.label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const handleSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);

      // Show tag.
      let couple =
        deselectedOptions && deselectedOptions.length > 0
          ? deselectedOptions
              .filter((d) => selected.includes(d.value))
              .map((i) => {
                return {
                  value: i.value,
                  label: i.label,
                };
              })
          : [];

      setDeselectedOptionsShow((prevState) => {
        let result = getUnique([...prevState, ...couple], "value");
        result = result.filter((i) => selected.includes(i.value));
        return result;
      });

      if (onChange) {
        onChange(getCoupleValue ? couple : selected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, deselectedOptions],
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.value !== value,
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptionsShow],
  );

  const textField = (
    <Autocomplete.TextField
      label="Accounts"
      labelHidden
      value={inputValue}
      onChange={handleInputValue}
      placeholder="Search for author"
      error={error}
    />
  );

  return (
    <>
      <ComponentLabelPolaris label={label} />
      {/* {loading ? (
        <Spinner size="small" />
      ) : ( */}
      <>
        <Autocomplete
          options={options}
          onSelect={handleSelection}
          selected={selectedOptions}
          textField={textField}
          allowMultiple
          emptyState={<span>No items found!</span>}
          loading={loading}
        />
        <TagContainer>
          {deselectedOptionsShow && deselectedOptionsShow.length > 0
            ? deselectedOptionsShow.map((item) => (
                <Tag
                  children={item.label}
                  onRemove={() => handleRemove(item.value)}
                  key={item.value}
                />
              ))
            : null}
        </TagContainer>
      </>
      {/* )} */}
    </>
  );
};
