import React, { useCallback } from "react";
import { TextField, Button, TextContainer, TextStyle } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import noImg from "../../assets/images/unnamed.jpeg";
import { formatFileSize } from "../../helper";
import { DELETE_FILE } from "../../graphql/mutations";
import { GET_PERSONAL_FILE_URL } from "../../graphql/queries";
import CopyBox from "../shared/CopyBox";

const UPDATE_FILE = gql`
  mutation updateFile($input: UpdateFile!) {
    updateFile(input: $input) {
      id
      name
      alt
      caption
      thumbnailUrl
      url
      mimeType
      size
    }
  }
`;

const Container = styled.div`
  padding: 1.6rem;

  .desc {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    word-break: break-word;

    .Polaris-Button {
      justify-content: start;
    }
  }

  .input_wrap {
    margin-top: 0.5rem;
  }
`;

export const MediaFileDetailsPolaris = (props) => {
  const { value, isImage, onDelete } = props;

  const [personalizedFileUrl, setPersonalizedFileUrl] = React.useState("");
  const [deleteFile, { client }] = useMutation(DELETE_FILE, {
    onError: () => {},
  });

  const [updateFile] = useMutation(UPDATE_FILE, {
    onError: () => {},
  });
  const [getPersonalFileUrl] = useLazyQuery(GET_PERSONAL_FILE_URL, {
    onCompleted: (res) => {
      if (res?.getPersonalFileUrl) {
        setPersonalizedFileUrl(res.getPersonalFileUrl);
      }
    },
  });

  React.useEffect(() => {
    if (!value || !value.key) {
      setPersonalizedFileUrl("");
    }

    getPersonalFileUrl({
      variables: {
        key: value.key,
      },
    });
  }, [value]);

  const handleDelete = useCallback(() => {
    let id = value && value.id;
    if (id) {
      deleteFile({
        variables: {
          id,
        },
      });
      if (onDelete) {
        onDelete(value, client);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = useCallback((value) => {
    updateFile({
      variables: {
        input: {
          id: value.id,
          name: value.name,
          alt: value.alt,
          caption: value.caption,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <TextContainer>
        <div className="thumbnail">
          {isImage ? (
            <img
              style={{ width: "100%" }}
              alt=""
              src={
                value
                  ? value.thumbnailUrl
                    ? value.thumbnailUrl
                    : value.url
                  : null
              }
            />
          ) : (
            <img style={{ width: "100%" }} alt={""} src={noImg} />
          )}
        </div>
        <div className="desc">
          <TextStyle>{value && value.name}</TextStyle>
          <TextStyle>{value && formatFileSize(value.size)}</TextStyle>
          <Button
            children="Delete permanently"
            plain
            destructive
            onClick={handleDelete}
          />
          <div className="input_wrap">
            <TextField
              value={value && value.alt}
              onChange={(input) => {
                value.alt = input;
                onChange(value);
                if (props.onChange) {
                  props.onChange(value, client);
                }
              }}
              name="alt"
              label="Alt text"
            />
            <TextField
              value={value && value.caption}
              onChange={(input) => {
                value.caption = input;
                onChange(value);
                if (props.onChange) {
                  props.onChange(value, client);
                }
              }}
              name="caption"
              label="Caption"
            />
          </div>
        </div>

        {personalizedFileUrl && (
          <div>
            <label>File Share</label>
            <CopyBox text={personalizedFileUrl} />
          </div>
        )}
      </TextContainer>
    </Container>
  );
};
