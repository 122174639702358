import React from "react";
import styled from "styled-components";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { useDrag } from "react-dnd";
const Container = styled.div`
  width: 60px;
  height: 60px;
  cursor: move;
  @media (min-width: 992px) {
    width: 90px;
    height: 90px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
  .el-title {
    font-weight: 700;
  }
  &.dragging {
    opacity: 0.4;
    background: rgba(223, 230, 1237, 0.5);
  }
`;

const Element = ({ data, onDrop }) => {
  const { icon, title, type } = data;
  const [{ isDragging }, drag] = useDrag({
    item: { name: title, type },
    options: {
      dropEffect: "copy"
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (onDrop) {
          onDrop();
        }
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <Container
      ref={drag}
      className={`${isDragging ? "dragging" : "not-dragging"}`}
    >
      <div className="el-icon">
        {typeof icon === "string" ? (
          <LegacyIcon style={{ fontSize: 30 }} type={icon} />
        ) : (
          icon
        )}
      </div>
      <div className={"el-title"}>{title}</div>
    </Container>
  );
};

export default Element;
