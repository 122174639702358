import React, { useState, useEffect, useCallback, useContext } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { AppContext } from "../../context";
import { handleError, getUnique, checkRole } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

export const PRODUCTS_QUERY = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        personalized
        mainImageId
        fields {
          id
          name
          title
        }
        images {
          id
          file {
            id
            url
            thumbnailUrl
          }
        }
        productBases {
          id
          title
          slug
          fulfillment {
            fulfillmentTitle
            fulfillmentId
            productId
            presetId
          }
          variants {
            id
            attributes {
              name
              slug
              option
            }
          }
        }
        variants {
          id
          disabled
          productBaseVariantId
          productBaseVariant {
            id
            fulfillmentProductId
            attributes {
              name
              slug
              option
            }
            productBase {
              id
              title
            }
          }
        }
      }
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const TitleContainer = styled.p`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductsSelectPolaris = ({
  label,
  allowMultiple,
  onChange,
  value,
  labelHidden,
  required = false,
  error: errorProp = null,
  prefix,
  hasSku,
}) => {
  const { store, currentUser } = useContext(AppContext);
  const { isStoreManager } = checkRole(currentUser);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const typingTimeoutRef = React.useRef(null);

  const newStore = store ? store.id || "all" : null;
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    storeId: isStoreManager ? newStore : null,
    search: null,
  });
  const { data, loading, error } = useQuery(PRODUCTS_QUERY, {
    variables: { filter },
  });

  useEffect(() => {
    if (data?.products?.nodes?.length > 0) {
      let newData = data.products.nodes.map((p) => {
        let title = p.title;
        if (hasSku) {
          title += ` (${p.sku ? p.sku : ""})`;
        }
        return {
          value: p.id,
          data: p,
          label: <TitleContainer>{title}</TitleContainer>,
        };
      });

      // TODO:
      setOptions(newData);
    }
  }, [data, hasSku, value]);

  useEffect(() => {
    let newValue = [];
    let newData = [];
    if (value && value.length) {
      newData = value.map((v) => {
        if (typeof v === "object") {
          newValue.push(v.id);
          let { id, title, sku } = v;
          title += sku ? ` (${sku})` : "";

          return {
            label: title,
            value: id,
          };
        } else {
          newValue.push(v);
          return undefined;
        }
      });
    }

    newValue = allowMultiple
      ? newValue
      : Array.isArray(value)
      ? value
      : [value];
    newData = newData.filter(Boolean);
    if (newData?.length > 0) {
      setOptions((prevState) => {
        let result = getUnique([...prevState, ...newData], "value");
        return result;
      });
      setDeselectedOptions((options) => {
        let result = getUnique([...options, ...value], "id");
        result = (result || []).map((i) => {
          let { title, sku } = i;
          title += sku ? ` (${sku})` : "";

          return {
            ...i,
            title,
          };
        });
        return result;
      });
    } else if (newValue?.length > 0) {
      let matchValue = [];
      setOptions((prev) => {
        matchValue = prev
          .filter((i) => newValue.includes(i.value))
          .map((i) => ({ ...i, id: i.value, title: i.label }));
        return prev;
      });

      setDeselectedOptions((opts) => {
        let result = getUnique([...opts, ...matchValue], "id");
        return result;
      });
    }
    setSelectedOptions(newValue);
  }, [value, allowMultiple]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
    typingTimeoutRef.current = clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setFilter((prevState) => {
        return {
          ...prevState,
          search: value,
        };
      });
    }, 400);
  }, []);

  const handleSelected = useCallback(
    (selected) => {
      let dataSelected = null;
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        dataSelected = matchedOptions;
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);

      let newData = _.get(data, "products.nodes", []).map((node) => {
        let { title, sku } = node;
        if (hasSku) {
          title += sku ? ` (${sku})` : "";
        }
        return {
          ...node,
          title,
        };
      });

      let couple =
        newData && newData.length > 0
          ? newData.filter((d) => selected.includes(d.id))
          : [];

      setDeselectedOptions((prevState) => {
        let result = getUnique([...prevState, ...couple], "id");
        result = result.filter((i) => selected.includes(i.id));
        return result;
      });

      if (onChange) {
        if (allowMultiple) {
          onChange(selected);
          return;
        } else {
          onChange(_.head(selected), dataSelected);
        }
      }
      setInputValue(_.head(selectedValue));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, data, hasSku]
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptions.filter((item) => item.id !== value);
      setDeselectedOptions(newDeselected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptions]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={handleInputChange}
      label={label ? label : "Products"}
      value={inputValue}
      placeholder="Search for product"
      labelHidden={true}
      error={errorProp}
      prefix={prefix}
      helpText="This option only use for search task"
    />
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {!labelHidden ? (
        <ComponentLabelPolaris
          label={label ? label : "Products"}
          required={required}
        />
      ) : null}
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelected}
        textField={textField}
        loading={loading}
        willLoadMoreResults
        allowMultiple={allowMultiple}
      />
      <TagContainer>
        {deselectedOptions && deselectedOptions.length > 0
          ? deselectedOptions.map((item) => (
              <Tag
                children={item.title}
                onRemove={() => handleRemove(item.id)}
                key={item.id}
              />
            ))
          : null}
      </TagContainer>
    </>
  );
};
