import React, { useCallback, useState, useEffect, useContext } from "react";
import { Page, Loading, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import history from "../../../history";
import { CollectionFormPolaris } from "./CollectionFormPolaris";
import { getParamByRole, handleError } from "../../../helper";
import { AppContext } from "../../../context";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const COLLECTION_BY_ID = gql`
  # fragment cat on Collection {
  #   id
  #   name
  #   parent {
  #     id
  #     name
  #   }
  #   image {
  #     id
  #     url
  #     thumbnailUrl
  #     createdAt
  #   }
  # }
  query collectionById($id: ID!) {
    # collections {
    #   total
    #   nodes {
    #     ...cat
    #     children {
    #       ...cat
    #       children {
    #         ...cat
    #         children {
    #           ...cat
    #           children {
    #             ...cat
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    collectionById(id: $id) {
      id
      name
      parent {
        id
        name
      }
      image {
        id
        name
        url
        thumbnailUrl
        createdAt
      }
      private
      automated
      conditions {
        id
        field
        operator
        value
      }
      description
      isPopular
    }
  }
`;
const UPDATE_COLLECTION = gql`
  mutation updateCollection($input: EditCollection!) {
    updateCollection(input: $input) {
      id
      name
      image {
        id
        url
        thumbnailUrl
      }
    }
  }
`;

export const EditCollectionPolaris = (props) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const { id } = props.match.params;
  const { data, loading, error } = useQuery(COLLECTION_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  const [
    updateCollection,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(UPDATE_COLLECTION, {
    onCompleted: () => {
      const id = setTimeout(() => {
        handleRedirect();
      }, 2500);
      setTimeoutId(id);
    },
    onError: () => {},
  });

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback((input) => {
    updateCollection({
      variables: {
        input: {
          ...input,
          id,
        },
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push(`/${currentParam}/collections`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess =
    dataMutation && dataMutation.updateCollection && "Edit collection success";
  let newError = errorMutation ? handleError(errorMutation.toString()) : null;

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const loadingMarkup = loading && <Loading />;
  if (error) return <div>Error:{handleError(error.toString())}</div>;
  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Edit collection"
        breadcrumbs={[
          { content: "Collections", url: `/${currentParam}/collections` },
        ]}
        fullWidth
      >
        <CollectionFormPolaris
          value={data?.collectionById}
          onSubmit={handleSubmit}
          loading={loadingMutation}
          editCollection
          currentParam={currentParam}
        />
      </Page>
    </Container>
  );
};
