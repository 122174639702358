import React, { useRef, useState, useEffect } from "react";
import Menu from "../sidebar/Sidebar";
import { MenuUnfoldOutlined, CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

function MenuMobile(props) {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  let classMenuMobile = "";
  if (isOpen) {
    classMenuMobile = "show-menu";
  }
  const handleClickOutside = (event) => {
    if (!btnRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, []);

  return (
    <>
      <div ref={btnRef} className="block lg:hidden menu-mobile-wrap">
        <button
          className="focus:outline-none ml-2 cursor-pointer"
          onClick={handleClick}
        >
          {isOpen ? (
            <CloseOutlined style={{ fontSize: 16, color: "#000" }} />
          ) : (
            <MenuUnfoldOutlined style={{ fontSize: 16, color: "#000" }} />
          )}
        </button>
        <div className={`menu-mobile ${classMenuMobile}`}>
          <Menu menuMobile={isOpen} match={props.match} />
        </div>
      </div>
      <div
        className={
          "ant-modal-mask" + ((!isOpen && " ant-modal-mask-hidden") || "")
        }
      ></div>
    </>
  );
}

export default withRouter(MenuMobile);
