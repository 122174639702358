import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page, Toast } from "@shopify/polaris";
import isEqual from "lodash/isEqual";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../context";
import { GET_STORES } from "../../../../graphql/queries";
import { checkRole, getParamByRole, handleError } from "../../../../helper";
import useToggle from "../../../../hooks/useToggle";
import { PLATFORM_FACEBOOK as FFaceBook } from "../../../../variable";
import { EmptyStatePolaris } from "../../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../../shared/SkeletonPagePolaris";
import { SelectRowsProvider } from "../../SelectRowsProvider";
import { StoresFilterPolaris } from "../../StoresFilterPolaris";
import { FBMContext } from "../context";
import { default as DataTable } from "./DataTable";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export function FBMarketplace() {
  // Context
  const { currentUser } = useAppContext();
  const { isStoreManager, isMarketplaceManager: isMM } = checkRole(currentUser);
  const currentParam = getParamByRole(currentUser);
  const userId = currentUser?.id;

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    platformSelected: [FFaceBook],
    search: null,
    status: null,
    seller: null,
  });
  const [toastActive, toggleToast] = useToggle(false);
  const [notify, setNotify] = useState({ msg: null, err: false });

  // Query
  const { data, loading, error, refetch } = useQuery(GET_STORES, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  // Handle actions
  const handleFilterChange = useCallback(({ search, status, seller }) => {
    setFilter((prev) => {
      if (
        !isEqual(prev.search, search) ||
        !isEqual(prev.status, status) ||
        !isEqual(prev.seller, seller)
      ) {
        return {
          ...prev,
          search,
          status,
          seller,
          offset: 0,
        };
      }
      return prev;
    });
  }, []);

  const handlePaginationChange = useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      duration={1500}
      onDismiss={toggleToast}
    />
  );

  // initialValue
  const provider = {
    filter,
    toggleToast,
    setNotify,
  };

  const dataProps = {
    value: data?.stores?.nodes,
    total: data?.stores?.total,
  };

  const btnLabel = isStoreManager
    ? "Create FB Marketplace Account"
    : "Add Marketplace Account";

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <FBMContext.Provider value={provider}>
        <Page
          title="Facebook Marketplace"
          fullWidth
          breadcrumbs={[
            { content: "Sales Channels", url: `/${currentParam}/stores` },
          ]}
          primaryAction={{
            content: btnLabel,
            url: `/${currentParam}/stores/fb-marketplace/add`,
          }}
        >
          <Card sectioned>
            <StoresFilterPolaris
              filter={filter}
              isMarketPlaceM={isMM}
              onChange={handleFilterChange}
            />
          </Card>
          <Card>
            {loading ? (
              <SkeletonPagePolaris />
            ) : error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : data?.stores?.nodes?.length > 0 ? (
              <SelectRowsProvider
                data={data.stores.nodes || []}
                refetch={refetch}
                isChildNode
              >
                <DataTable
                  data={dataProps}
                  filter={filter}
                  userId={userId}
                  isStoreManager={isStoreManager}
                  setFilter={handlePaginationChange}
                  refetch={refetch}
                />
              </SelectRowsProvider>
            ) : (
              <EmptyStatePolaris />
            )}
          </Card>
        </Page>
      </FBMContext.Provider>
    </Container>
  );
}
