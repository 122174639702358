import React, { useCallback, useEffect, useState, useContext } from "react";
import { Modal, Banner, Stack, TextField } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import styled from "styled-components";

import { MOrderContext } from "../context";
import { handleError } from "../../../helper";

export const SPLIT_ORDER = gql`
    mutation splitMappingOrderFacebook($input: SplitMappingOrderFacebook!) {
        splitMappingOrderFacebook(input: $input)
    }
`;

const Container = styled.div`
    .orders-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem 2rem;
        justify-content: space-between;
        margin: 3rem 0 2rem;

        .order-item {
            flex: 1 1 auto;
            width: calc(50% - 1rem);
            max-width: calc(50% - 1rem);
        }
    }
`;

export function SplitOrder({ open, onClose, item, onCompleted }) {
    // Props
    const id = item.id;
    const quantity = item.quantity || 2;

    // Context
    const { notifyChange, toggleToast } = useContext(MOrderContext);

    // State
    const [exceptOrders, setExceptOrders] = useState(`2`);
    const [state, setState] = useState({});
    const [accept, setAccept] = useState(undefined);

    // Mutation
    const [splitOrder, { loading }] = useMutation(SPLIT_ORDER, {
        onError: (error) => {
            if (notifyChange) {
                notifyChange({
                    msg: handleError(error.toString()),
                    err: true,
                });
            }
        },
        onCompleted: () => {
            if (notifyChange) {
                notifyChange({
                    msg: "Split mapping item selected successfully.",
                    err: false,
                });
            }

            onCompleted && onCompleted();
            onClose && onClose();
        },
    });

    const handleItemsChange = useCallback((quantity, except) => {
        let index = 0;
        let i = 1;
        let state = {};

        while (index++ < quantity) {
            state[i] = state[i] ? state[i] + 1 : 1;
            i = i === except ? 1 : i + 1;
        }

        const newState = Object.entries(state).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [key]: `${value}`,
            };
        }, {});
        setState(newState);
    }, []);

    // Get data
    useEffect(() => {
        if (quantity != null) {
            setExceptOrders(`${quantity}`);
            handleItemsChange(quantity, quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    useEffect(() => {
        if (state) {
            const sum = Object.values(state).reduce((acc, cur) => {
                const value = typeof cur === "number" ? cur : parseInt(cur);
                return (acc = acc + value);
            }, 0);
            setAccept(sum > quantity);
        }
    }, [state, quantity]);

    // Handle actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            if (notifyChange) {
                notifyChange({ msg: null, err: false });
            }

            const qtyItems = Object.values(state)
                .filter(Boolean)
                .map((i) => parseInt(i));

            splitOrder({
                variables: {
                    input: {
                        mappingOrderId: id,
                        expectOrder: parseInt(exceptOrders),
                        qtyItems,
                    },
                },
            });
        }
    }, [toggleToast, splitOrder, id, exceptOrders, state, notifyChange]);

    const handleItemChange = useCallback((value, id) => {
        setState((prev) => ({ ...prev, [id]: value }));
    }, []);

    const handleExceptOrder = useCallback(
        (value) => {
            if (parseInt(value) <= quantity) {
                setExceptOrders(value);
                handleItemsChange(quantity, parseInt(value));
            }
        },
        [quantity, handleItemsChange]
    );

    const max = parseInt(quantity) - 1;

    return (
        <Modal
            open={open}
            title="Split mapping item via quantity"
            onClose={onClose}
            sectioned
            primaryAction={{
                content: "Split",
                onAction: handleSubmit,
                disabled: accept,
                loading: loading,
            }}
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        >
            <Container>
                <Stack alignment="center">
                    <Stack.Item fill>Except mapping item:</Stack.Item>
                    <TextField
                        value={exceptOrders}
                        placeholder={"" + quantity}
                        align="right"
                        type="number"
                        id="exceptOrders"
                        onChange={handleExceptOrder}
                        min={2}
                        max={quantity}
                    />
                </Stack>
                <div className="orders-wrap">
                    {new Array(parseInt(exceptOrders)).fill("").map((_, i) => {
                        const index = i + 1;
                        return (
                            <div className="order-item" key={`order-${index}`}>
                                <TextField
                                    id={`${index}`}
                                    value={state[index] ?? ""}
                                    label={`Mapping item ${index}: `}
                                    type="number"
                                    prefix={"Quantity"}
                                    autoComplete={"off"}
                                    onChange={handleItemChange}
                                    placeholder={"1"}
                                    min={1}
                                    max={max}
                                />
                            </div>
                        );
                    })}
                </div>
                {accept && (
                    <Banner
                        status="critical"
                        children={`Sum quantity in items must be less or equal quantity of mapping item (${quantity} units.)`}
                    />
                )}
            </Container>
        </Modal>
    );
}
