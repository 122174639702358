import React from 'react';
import { Modal, Toast } from '@shopify/polaris';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import useToggle from '../../../hooks/useToggle';
import { handleError } from '../../../helper';

export const GEN_THUMBNAIL_IMAGE = gql`
    mutation genThumbnailImage($ids: [ID!]!) {
        genThumbnailImage(ids: $ids)
    }
`;

export const GenThumbnailImage = ({
    items,
    open,
    toggleOpen,
    onCompleted,
    refetch,
}) => {
    // State
    const [toastActive, toggleToastActive] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const timeoutRef = React.useRef(null);

    // Mutation
    const [genThumbnail, { loading }] = useMutation(GEN_THUMBNAIL_IMAGE, {
        onCompleted: () => {
            setNotify({ msg: 'Refetch total sale successfully.', err: false });

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                onCompleted && onCompleted();
                toggleOpen && toggleOpen();
                refetch && refetch();
            }, 1500);
        },
        onError: (error) => {
            setNotify({ msg: handleError(error.toString()), err: true });
        },
    });

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        const ids =
            items?.length > 0
                ? items
                      .reduce((acc, item) => {
                          // Main image
                          let mainImageId = item?.mainImageId;
                          if (
                              mainImageId != null &&
                              mainImageId !== 'undefined'
                          ) {
                              return [...acc, mainImageId];
                          }
                          const images = item?.images;
                          const [firstImg] =
                              images?.length > 0
                                  ? images.filter((i) => i?.file?.id)
                                  : [];
                          const id = firstImg?.file?.id;

                          if (id != null) {
                              return [...acc, id];
                          }
                          return [...acc];
                      }, [])
                      .filter(Boolean)
                : [];
        if (ids.length) {
            toggleToastActive(true);
            setNotify({ msg: null, err: false });
            genThumbnail({
                variables: {
                    ids,
                },
            });
        }
    }, [genThumbnail, toggleToastActive, setNotify, items]);

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            onDismiss={toggleToastActive}
            duration={1500}
        />
    );

    return (
        <React.Fragment>
            {toastMarkup}
            <Modal
                title='Gen Thumbnail Image'
                open={open}
                onClose={toggleOpen}
                sectioned
                secondaryActions={[{ content: 'Cancel', onAction: toggleOpen }]}
                primaryAction={{
                    content: 'Submit',
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <p>Are you sure to gen thumbnail image for this products?</p>
            </Modal>
        </React.Fragment>
    );
};
