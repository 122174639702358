import React from "react";
import Personalized from "../../components/personalized/personalized";

const PersonalizedPage = () => {
  return (
    <div>
      <Personalized />
    </div>
  );
};

export default PersonalizedPage;
