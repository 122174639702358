import React, { Component } from "react";
import "@ant-design/compatible/assets/index.css";
import { Modal, Button, notification, Form } from "antd";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const ADD_TAGS = gql`
  mutation addTagsToProducts(
    $productIds: [ID!]!
    $tagIds: [ID!]
    $add: Boolean!
  ) {
    addTagsToProducts(productIds: $productIds, tagIds: $tagIds, add: $add)
  }
`;
const Container = styled.div``;
class AddTagsProducts extends Component {
  state = {
    showTag: false,
    onPopOverClose: this.props.onPopOverClose,
    loading: false,
  };
  handleAddTag = (client, tags) => {
    try {
      const { selectedRows } = this.props;
      const variables = {
        filter: this.props.filter,
      };
      const cache = client.readQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
      });
      client.writeQuery({
        query: LIST_SELLER_PRODUCTS_QUERY,
        variables,
        data: {
          ...cache,
          products: {
            ...cache.products,
            nodes: [
              ...cache.products.nodes.map(
                (product) => (
                  selectedRows.find((s) => s === product.id)
                    ? ((product.tags = [...product.tags, ...tags]),
                      (product.tagLength = 1))
                    : null,
                  product
                )
              ),
            ],
          },
        },
      });
    } catch (_) {}
  };
  render() {
    const { selectedRows, onPopOverClose } = this.props;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Container>
        <Mutation mutation={ADD_TAGS}>
          {(addTagsToProducts, { client }) => (
            <Modal
              visible={this.state.showTag}
              onCancel={() => this.setState({ showTag: false })}
              title="Add Tag"
              footer={null}
            >
              <Form
                {...layout}
                initialValues={{
                  tagIds: [],
                }}
                onFinish={(e) => {
                  if (e) {
                    this.setState({ loading: true });
                    addTagsToProducts({
                      variables: {
                        productIds: selectedRows,
                        tagIds:
                          e.tagIds && e.tagIds.length > 0
                            ? e.tagIds.map((t) => t.id)
                            : [],
                        add: true,
                      },
                    }).then((res) => {
                      this.setState({ loading: false });
                      notification.success({
                        message: "Add tags success!",
                      });
                      this.handleAddTag(client, e.tagIds);
                      this.setState({ showTag: false });
                    });
                  }
                }}
              >
                <Form.Item name="tagIds" label="Tags">
                  <TagsAutoComplete />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    className="mr-2"
                    onClick={() => this.setState({ showTag: false })}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          )}
        </Mutation>
        <main
          style={{ cursor: "pointer" }}
          onClick={() => {
            onPopOverClose();
            this.setState({ showTag: true });
          }}
        >
          Add Tags
        </main>
      </Container>
    );
  }
}

export default AddTagsProducts;
