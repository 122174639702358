import { DocumentationsPage } from "../components/documentations";
import ImportTracking from "../components/supplier/ImportTracking";
import MainLayout from "../layout/MainLayout";
import { OrdersSupplierPagePolaris } from "../pages/seller/OrdersSupplierPagePolaris";
import AddTemplate from "../pages/supplier/AddTemplate";
import SupplierDepositsPage from "../pages/supplier/Deposits";
import EditTemplatePage from "../pages/supplier/EditTemplate";
import ExportsPage from "../pages/supplier/Exports";
import ExportTemplatesPage from "../pages/supplier/ExportTemplate";
import ImportPage from "../pages/supplier/Import";
import SupplierTransactionsPage from "../pages/supplier/Transactions";

const routes = [
  {
    path: "/supplier/orders",
    exact: true,
    component: OrdersSupplierPagePolaris,
    layout: MainLayout,
    title: "Orders",
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/import-tracking",
    exact: true,
    component: ImportTracking,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/imports",
    exact: true,
    component: ImportPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/documentations",
    exact: true,
    component: DocumentationsPage,
    title: "Documentations Page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/manager-files/export-templates",
    exact: true,
    component: ExportTemplatesPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/manager-files/add-template",
    exact: true,
    component: AddTemplate,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/manager-files/edit-template/:id",
    exact: true,
    component: EditTemplatePage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/export-tracking",
    exact: true,
    component: ExportsPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/billings/deposits",
    exact: true,
    component: SupplierDepositsPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
  {
    path: "/supplier/billings/transactions",
    exact: true,
    component: SupplierTransactionsPage,
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Supplier",
  },
];

export default routes;
