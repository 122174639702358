import { useMutation, useQuery } from "@apollo/react-hooks";
import { Loading, Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { handleError } from "../../helper";
import history from "../../history";
import { NewPushProductsFormPolaris } from "./NewPushProductsFormPolaris";


const PUSH_BY_ID = gql`
  query pushByID($id: ID!) {
    pushByID(id: $id) {
      id
      title
      continue
      limit
      order
      orderBy
      isPersonalize
      onlyGenerateCsv
      limitType
      tags {
        id
        tag {
          id
          name
        }
        included
      }
      collections {
        id
        collection {
          id
          name
        }
        included
      }
      stores {
        id
        title
      }
      specialProducts
      productBases
      matchAnyCollections
      matchAnyTags
      onlyPushHasSale
      csvFiles {
        url
        name
      }
      setting {
        ignoreSalePrice
        modifyVariantPrice
        modifyVariantType
        baseVariants {
          id
          productBaseId
          productBase {
            id
            title
          }
          productBaseVariantId
          productBaseVariant {
            id
            regularPrice
            salePrice
            attributes {
              name
              slug
              option
            }
          }
          regularPrice
          salePrice
          sorting
        }
      }
    }
  }
`;

const EDIT_PUSH = gql`
  mutation updatePush($input: EditPush!) {
    updatePush(input: $input) {
      id
      title
      continue
      tags {
        id
        tag {
          id
          name
        }
        included
      }
      collections {
        id
        collection {
          id
          name
        }
        included
      }
      stores {
        id
        title
      }
      specialProducts
      productBases
      onlyGenerateCsv
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const EditPushProductsPolaris = (props) => {
  const { id } = props.match.params;
  const [completed, setCompleted] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { data, loading, error } = useQuery(PUSH_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [updatePush, { loading: loadingMutation, client }] = useMutation(
    EDIT_PUSH,
    {
      onError: () => { },
      onCompleted: () => {
        setCompleted(true);
        const id = setTimeout(() => {
          client.resetStore();
          history.push(`/seller/products/product-push`);
        }, 2500);
        setTimeoutId(id);
      },
    }
  );

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = data && data.pushByID;
  const handleSubmit = useCallback((input) => {
    updatePush({
      variables: {
        input: {
          ...input,
          id: id,
        },
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  let errorMutation = null;
  const toastMarkup = activeToast
    ? (errorMutation || completed) && (
      <Toast
        content={
          errorMutation
            ? handleError(errorMutation.toString())
            : completed && "Edit product push ssucces."
        }
        error={errorMutation}
        duration={2000}
        onDismiss={toggleActive}
      />
    )
    : null;

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Edit Product Push"
        fullWidth
        breadcrumbs={[
          { content: "Push Product", url: "/seller/products/product-push" },
        ]}
      >
        {error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : (
          <NewPushProductsFormPolaris
            btnLabel="Save change"
            value={value}
            onSubmit={handleSubmit}
            loading={loadingMutation}
          />
        )}
      </Page>
    </Container>
  );
};
