import React, { useState, useCallback, useEffect, useContext } from "react";
import { Page, Loading, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import history from "../../../history";
import { handleError } from "../../../helper";
import { AppContext } from "../../../context";
import { TEAM_ROLE, USER_ROLE, TEAM_ROLE_PARAMS } from "../../../variable";
import { ProductDivisionsFormPolaris } from "./ProductDivisionsFormPolaris";

const QUERY_DIVISION = gql`
    query productDivisionById($id: ID!) {
        productDivisionById(id: $id) {
            id
            title
            enableUpdate
            onlyRecreateMockup
            divideAllAccount
            from
            to
            lastSaleStoreIDs
            productBases {
                id
                title
            }
            authors {
                id
                included
                author {
                    id
                    firstName
                    lastName
                }
            }
            collections {
                id
                included
                collection {
                    id
                    name
                }
            }
            tags {
                id
                included
                tag {
                    id
                    name
                }
            }
            stores {
                id
                title
                domain
                email
            }
            filterByCreatedAt
            filterByCreatedAtFrom
            filterByCreatedAtTo
        }
    }
`;

const UPDATE_DIVISIONS = gql`
    mutation updateProductDivision($input: EditProductDivision!) {
        updateProductDivision(input: $input) {
            id
            title
            filterByCreatedAtFrom
            filterByCreatedAtTo
            from
            to
        }
    }
`;

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const EditProductDivisionPolaris = (props) => {
    const { id } = props.match.params;
    const { currentUser } = useContext(AppContext);
    const userRole = _.get(currentUser, "roles", []);
    const teamRole = _.get(currentUser, "teamUser.role", null);

    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (userRole.includes(USER_ROLE.Seller)) {
        if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
        }
    }

    const [timeoutId, setTimeoutId] = useState(null);
    const [activeToast, setActiveToast] = useState(false);
    const [getData, { data, loading, error }] = useLazyQuery(QUERY_DIVISION);
    const [
        updateDivistion,
        { data: dataUD, loading: loadingUD, error: errorUD, client },
    ] = useMutation(UPDATE_DIVISIONS, {
        onError: () => {},
        onCompleted: () => {
            const id = setTimeout(() => {
                client.resetStore();
                history.push(`/${currentParam}/product-divisions`);
            }, 2100);
            setTimeoutId(id);
        },
    });

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(() => {
        setActiveToast((activeToast) => !activeToast);
    }, []);

    useEffect(() => {
        if (id) {
            getData({ variables: { id } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubmit = useCallback(
        (input) => {
            updateDivistion({
                variables: {
                    input: {
                        id,
                        ...input,
                    },
                },
            });
            toggleActive();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    let toastSuccess =
        dataUD &&
        dataUD.updateProductDivision &&
        "Update product division success.";
    let newError = errorUD ? handleError(errorUD.toString()) : null;

    const toastMarkup = activeToast
        ? (newError || toastSuccess) && (
              <Toast
                  content={newError ? newError : toastSuccess}
                  error={newError}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    const loadingMarkup = loading && <Loading />;

    return (
        <Container>
            {loadingMarkup}
            {toastMarkup}
            <Page
                title="Edit Product Division"
                breadcrumbs={[
                    {
                        content: "Product divisions",
                        url: `/${currentParam}/product-divisions`,
                    },
                ]}
            >
                {error ? <div>Error: {error.toString()}</div> : null}
                <ProductDivisionsFormPolaris
                    value={data && data.productDivisionById}
                    onlyRecreateMockup={
                        data?.productDivisionById?.onlyRecreateMockup
                    }
                    onSubmit={handleSubmit}
                    loadingM={loadingUD}
                    isEditDivision
                    divisionId={id}
                    currentParam={currentParam}
                />
            </Page>
        </Container>
    );
};
