import React, { useState, useCallback } from "react";
import { gql } from "apollo-boost";
import {
  Form,
  FormLayout,
  TextField,
  ChoiceList,
  Icon,
  ButtonGroup,
  Button,
  Toast,
  Spinner,
  Checkbox,
} from "@shopify/polaris";
import { ViewMinor, HideMinor } from "@shopify/polaris-icons";

import styled from "styled-components";
import { Query } from "@apollo/react-components";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import MediaSelectorButton from "../../supplier/MediaSelectorButton";
import history from "../../../history";
import { isEmail } from "../../../helper";
import { useMutation } from "@apollo/react-hooks";
import { handleError } from "../../../helper";

const Container = styled.div`
  .Polaris-FormLayout__Item {
    width: 100px;
  }
  .Polaris-ChoiceList__Choices {
    display: flex;
    gap: 0 2rem;
    flex-wrap: wrap;
  }
  .Polaris-ButtonGroup {
    flex-direction: row-reverse;
  }
  .icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
`;

const MUTATION = gql`
  mutation createUser($input: NewUser!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      email
      roles
      phone
      status
      verified
      address
      avatar {
        id
        url
        thumbnailUrl
      }
    }
  }
`;

export const AddUserFormPolaris = ({ redirect, onCompleted }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [phone, setPhone] = useState(null);
  const [address, setAddress] = useState(null);
  const [role, setRole] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [click, setClick] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(false);
  const [hiddenConfirm, setHiddenConfirm] = useState(false);
  const [ignoreTwoFA, setIgnoreTwoFA] = useState(false);

  const handleFirstNameChange = useCallback((value) => setFirstName(value), []);
  const handlLastNameChange = useCallback((value) => setLastName(value), []);
  const handlEmailChange = useCallback((value) => setEmail(value), []);
  const handlPasswordChange = useCallback((value) => setPassword(value), []);
  const handlConfirmPasswordChange = useCallback(
    (value) => setConfirm(value),
    []
  );
  const handlPhoneChange = useCallback((value) => setPhone(value), []);
  const handlAddressChange = useCallback((value) => setAddress(value), []);
  const handleRoleChange = (value) => {
    setRole(value);
  };

  const handleButtonClick = useCallback(() => setClick(true), []);

  const [activeToast, setActiveToast] = useState(false);

  const iconViewMarkup = (
    <div className="icon">
      <Button
        plain
        onClick={() => setHiddenPassword((hiddenPassword) => !hiddenPassword)}
      >
        <Icon source={!hiddenPassword ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  const iconViewMarkupConfirm = (
    <div className="icon">
      <Button
        plain
        onClick={() => setHiddenConfirm((hiddenConfirm) => !hiddenConfirm)}
      >
        <Icon source={!hiddenConfirm ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );
  const [createUser, { data, error, client, loading }] = useMutation(MUTATION);
  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback(() => {
    if (
      firstName &&
      firstName.length > 0 &&
      lastName &&
      lastName.length > 0 &&
      email &&
      isEmail(email) &&
      role &&
      role.length > 0 &&
      password &&
      password.length > 0 &&
      password === confirm
    ) {
      let dataPostUserCreate = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        roles: role,
        phone: phone,
        address: address,
        password: password,
        avatar: avatar && avatar.length > 0 ? avatar[0].id : "",
        ignoreTwoFA,
      };
      createUser({
        variables: {
          input: { ...dataPostUserCreate },
        },
      })
        .then((res) => {
          if (onCompleted) {
            onCompleted(client, res.data.createUser);
          }
          const timer = setTimeout(() => {
            history.push("/admin/users");
          }, 1000);
          return () => {
            clearTimeout(timer);
          };
        })
        .catch((err) => {});
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    email,
    password,
    confirm,
    phone,
    address,
    role,
    avatar,
    ignoreTwoFA,
  ]);

  const toastMarkup = activeToast
    ? (error || (data && data.createUser)) && (
        <Toast
          content={
            error
              ? handleError(
                  error.toString().includes("uix_users_email")
                    ? "Email name already in use, please try another name."
                    : ""
                )
              : data && data.createUser && "Create user success!"
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="First Name(*)"
              placeholder="First name"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              error={
                (firstName === "" && "Please input first name!") ||
                (click && firstName === null && "Please input first name!")
              }
            />
            <TextField
              label="Last Name(*)"
              placeholder="Last name"
              value={lastName}
              onChange={handlLastNameChange}
              type="text"
              error={
                (lastName === "" && "Please input last name!") ||
                (click && lastName === null && "Please input last name!")
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              type="email"
              value={email}
              onChange={handlEmailChange}
              label="Email(*)"
              placeholder="Email"
              error={
                (email === "" && "Please input  E-mail!") ||
                (email &&
                  !isEmail(email) &&
                  "Email must be a valid email address.") ||
                (click && email === null && "Please input  E-mail!")
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Password(*)"
              placeholder="Password"
              value={password}
              onChange={handlPasswordChange}
              type={hiddenPassword === false ? "password" : "text"}
              suffix={iconViewMarkup}
              error={
                (password === "" && "Please input password!") ||
                (click && password === null && "Please input password!")
              }
            />
            <TextField
              label="Confirm Password(*)"
              value={confirm}
              onChange={handlConfirmPasswordChange}
              type={hiddenConfirm === false ? "password" : "text"}
              suffix={iconViewMarkupConfirm}
              error={
                (confirm === "" && "Please confirm your password!") ||
                (click &&
                  confirm === null &&
                  "Please confirm your password!") ||
                (confirm &&
                  password !== confirm &&
                  "The two passwords that you entered do not match!") ||
                (click &&
                  password !== confirm &&
                  "The two passwords that you entered do not match!")
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Phone"
              placeholder="Phone"
              value={phone}
              onChange={handlPhoneChange}
              type="text"
            />
            <TextField
              label="Address"
              placeholder="Address"
              value={address}
              onChange={handlAddressChange}
              type="text"
            />
          </FormLayout.Group>
          <FormLayout.Group title="Roles(*)" key="role">
            <Query
              query={gql`
                query systemRoles {
                  systemRoles
                }
              `}
            >
              {({
                error: errorRole,
                loading: loadingRole,
                data: dataRoles,
              }) => {
                if (loadingRole)
                  return (
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="small"
                      color="teal"
                    />
                  );
                if (errorRole) return <EmptyStatePolaris />;

                const dataRoleValue = [];
                dataRoles.systemRoles.forEach((i) => {
                  if (i !== "Seller") {
                    let item = {};
                    item["value"] = i;
                    item["label"] = i;
                    dataRoleValue.push(item);
                  }
                });
                return (
                  dataRoleValue && (
                    <ChoiceList
                      allowMultiple
                      title="User role"
                      choices={dataRoleValue}
                      selected={role ? role : ""}
                      onChange={handleRoleChange}
                      titleHidden={true}
                      error={
                        (role && role.length === 0 && "Please select role!") ||
                        (click && role === null && "Please select role!")
                      }
                    />
                  )
                );
              }}
            </Query>
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              label="Ignore Two-Factor Authentication (2FA)"
              checked={!!ignoreTwoFA}
              onChange={setIgnoreTwoFA}
            />
          </FormLayout.Group>
          <FormLayout.Group title="Avatar" name="avatar">
            <MediaSelectorButton
              accept={"image/*"}
              listType={"picture-card"}
              singleUpload={true}
              multiple={false}
              onChange={(newValue) => {
                setAvatar(newValue);
              }}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <ButtonGroup>
              <Button
                submit="submit"
                primary="primary"
                onClick={handleButtonClick}
                loading={loading}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  history.push(redirect ? redirect : "/admin/users");
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </FormLayout.Group>
        </FormLayout>
      </Form>
      {toastMarkup}
    </Container>
  );
};
