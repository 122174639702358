import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";

class AdminReportsPage extends Component {
  render() {
    return (
      <div>
        <PageTitle title={"Reports"} />
        <p>Admin reports...</p>
      </div>
    );
  }
}

export default AdminReportsPage;
