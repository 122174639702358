import React, { Component } from "react";
import { Button, Modal } from "antd";
import { CLAIM_TYPE, CLAIM_STATUS } from "../../constants";
import ActionResend from "./ActionResend";
import ActionRefund from "./ActionRefund";

export default class ActionsClaim extends Component {
  state = {
    isResend: false,
    isRefund: false
  };

  handleClose = () => {
    this.setState({ isResend: false, isRefund: false });
  };

  render() {
    const { claim } = this.props;
    const { isResend } = this.state;
    return (
      <div>
        {claim.type === CLAIM_TYPE.NeedResend && claim.status !== CLAIM_STATUS.Resent && (
          <>
            <Button
              className="mx-1"
              onClick={() => {
                this.setState({ isResend: true });
              }}
            >
              Resend
            </Button>
            <Modal
              title={"Resend for order #" + claim.order.id}
              onCancel={this.handleClose}
              visible={isResend}
              footer={false}
            >
              <ActionResend claim={claim} />
            </Modal>
          </>
        )}
        {claim.type === CLAIM_TYPE.NeedRefund && claim.status !== CLAIM_STATUS.Refunded && (
          <>
            <ActionRefund claim={claim} />
          </>
        )}
      </div>
    );
  }
}
