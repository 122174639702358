import React, { useState, useEffect, useContext } from "react";
import { Loading, Card } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import history from "../../../history";
import { AppContext } from "../../../context";
import { TEAM_ROLE } from "../../../variable";
import { CLAIM_FILTER } from "../../../constants/index";
import {
  convertObjectToParams,
  convertStringToObject,
  setCookie,
  getCookie,
} from "../../../helper";

import { FilterClaimPolaris } from "./FiltersClaimPolaris";
import { FiltersOrderPolaris } from "../FiltersOrderPolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { TableClaimPolaris } from "./TableClaimPolaris";

export const LOAD_CLAIMS = gql`
  query claims($filter: ClaimFilter) {
    claims(filter: $filter) {
      total
      nodes {
        id
        order {
          id
          store {
            id
            title
          }
        }
        orderId
        approveBy {
          id
          firstName
          lastName
        }
        approveById
        authorId
        author {
          id
          firstName
          lastName
        }
        type
        comment
        status
        files {
          id
          url
          thumbnailUrl
        }
        createdAt
      }
    }
  }
`;

export const ListClaimPolaris = () => {
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageClaims")) || 20,
    offset: 0,
    status: null,
    search: null,
    type: null,
    ...convertStringToObject(history.location.search),
  });

  const { store, currentUser } = useContext(AppContext);
  const role = _.get(currentUser, "teamUser.role", null);
  const isStoreManager = [TEAM_ROLE.StoreManager].includes(role);
  let storeId = _.get(store, "id", null);
  if (storeId === "all") {
    storeId = null;
  }

  const { data, loading, error, refetch } = useQuery(LOAD_CLAIMS, {
    variables: {
      filter: {
        ...filter,
        ...(isStoreManager ? { storeId: storeId } : null),
      },
    },
  });

  useEffect(() => {
    let { offset, limit, ...rest } = filter;
    let params = null;
    if (!filter.offset) {
      params = convertObjectToParams(rest);
    } else {
      params = convertObjectToParams(filter);
    }
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;
  const claimFilter = CLAIM_FILTER;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <FilterClaimPolaris
          search={filter && filter.search}
          onChange={({ search }) => {
            setFilter((prevState) => {
              if (!_.isEqual(prevState.search, search)) {
                prevState.offset = 0;
              }
              return { ...prevState, search };
            });
          }}
        />
      </Card>
      <Card>
        <FiltersOrderPolaris
          value={claimFilter}
          defaultValue={filter && filter.status}
          onChange={({ status }) => {
            setFilter((prevState) => {
              if (!_.isEqual(prevState.status, status)) {
                prevState.offset = 0;
              }
              return {
                ...prevState,
                status,
              };
            });
          }}
        />
        {error && <div>Error: {error.toString()}</div>}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data &&
          data.claims &&
          data.claims.nodes &&
          data.claims.nodes.length ? (
          <TableClaimPolaris
            data={data}
            filter={filter}
            refetch={refetch}
            fixedColumn
            setFilter={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageClaims", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};
