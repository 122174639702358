import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { RecreateMockupFilterPolaris } from "./RecreateMockupFilterPolaris";
import { RecreateMockupTablePolaris } from "./RecreateMockupTablePolaris";

const RECREATE_MOCKUPS = gql`
  query recreateMockups($filter: RecreateMockupFilter) {
    recreateMockups(filter: $filter) {
      total
      nodes {
        id
        title
        productBases {
          id
          title
        }
        division {
          id
          title
        }
        totalOrder
        status
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "productBaseIds", "divisionId"];

export const RecreateMockupsPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let field = "productBaseIds";
      if (initialFilter[field]) {
        initialFilter[field] = initialFilter[field].split(",");
      }
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }

    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageRecreateMockups")) || 20,
    offset: 0,
    search: null,
    divisionId: null,
    productBaseIds: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error } = useQuery(RECREATE_MOCKUPS, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let { offset, limit, ...rest } = filter;
    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  // Handle actions
  const handleFilterChange = useCallback(
    ({ search, productBaseIds, divisionId }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.productBaseIds, productBaseIds) ||
          !isEqual(prev.divisionId, divisionId)
        ) {
          return {
            ...prev,
            search,
            productBaseIds,
            divisionId,
            offset: 0,
          };
        }
        return prev;
      });
    },
    [],
  );

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Fragment>
      {loadingMarkup}
      <Card sectioned>
        <RecreateMockupFilterPolaris
          onChange={handleFilterChange}
          filter={filter}
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.recreateMockups?.nodes?.length > 0 ? (
          <RecreateMockupTablePolaris
            data={data}
            filter={filter}
            setFilter={(offset, limit) => {
              setFilter((prev) => ({
                ...prev,
                offset,
                limit,
              }));
              setCookie("perPageRecreateMockups", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </Fragment>
  );
};
