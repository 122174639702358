import React, { Fragment } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../hooks/useToggle";

import { Trash } from "./actions/Trash";

export const BulkActionMapping = ({ ids, onCompleted }) => {
    // State
    const [open, toggleOpen] = useToggle(false);

    return (
        <Fragment>
            <Button children="Trash" onClick={toggleOpen} />
            <Trash
                open={open}
                onClose={toggleOpen}
                ids={ids}
                onCompleted={onCompleted}
            />
        </Fragment>
    );
};
