import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ResourceList } from '@shopify/polaris';
import styled from 'styled-components';
import _ from 'lodash';

import { AppContext } from '../../context';

import { RenderProductPolaris } from './RenderProductPolaris';
import { PaginationPolaris } from '../shared/PaginationPolaris';
import { AvailableProductsPolaris } from './AvailableProductsPolaris';
import { DeleteProductsPolaris } from './DeleteProductsPolaris';
import { AddTagsProductsPolaris } from './AddTagsProductsPolaris';
import { DeleteTagsProductsPolaris } from './DeleteTagsProductsPolaris';
import { AddCollectionsProductsPolaris } from './AddCollectionsProductsPolaris';
import { DeleteCollectionsProductsPolaris } from './DeleteCollectionsProductsPolaris';
import { ViewModalImage } from './ViewModalImage';
import { RefetchTotalSalePolaris } from './RefetchTotalSalePolaris';
import { GenThumbnailImage } from './components/GenThumbnailImage';
import { getParamByRole } from '../../helper';
import { PUSHED_SALE_CHANNELS, useProductsPolarisContext } from '../../pages/seller/ProductsPagePolaris';

const Container = styled.div`
  .pagination-wrap {
    padding: 1.6rem;
    background: var(--p-surface-subdued, #ebeef1);
    text-align: center;
    border-radius: var(--p-border-radius-wide, 3px);
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-x: scroll;
  }

  &.pm_table-has-select.has-media {

  .Polaris-ResourceList__ResourceListWrapper > * {
    min-width: 180rem;
  }
    .header-wrap, .content-wrap {
      ${({ pushedSaleChannels }) =>
        pushedSaleChannels &&
        `
        grid-template-columns: minmax(30%,1fr) 18% 15% 7% 10% 10% 10%;
      `}
    }
  }
  
`;

export const TableProductPolaris = ({
  data,
  filter,
  setFilter,
  networkStatus,
  refetch,
}) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isAction, setIsAction] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const [selectedItemData, setSelectedItemData] = useState({}); //

  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  // Context
  const {screenOptions} = useProductsPolarisContext();
  const pushedSaleChannels = (screenOptions || []).includes(PUSHED_SALE_CHANNELS);

  useEffect(() => {
    if (data) {
      setItems(data.products.nodes);
    }
  }, [data]);

  const handleActions = useCallback((action) => {
    setIsAction(action);
    toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowModal = useCallback((item) => {
    setCurrentItem(item);
    setIsAction('view-img');
    toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedItemChange = useCallback(
    (value) => {
      setSelectedItem(value);
      const selectedItem =
        items?.length > 0
          ? items.filter(({ id }) => Array.isArray(value) && value.includes(id))
          : [];
      setSelectedItemData(selectedItem);
    },
    [items]
  );

  const toggleModal = useCallback(() => setOpenModal((open) => !open), []);
  const handleCompletedAction = useCallback(() => setSelectedItem([]), []);

  const bulkActionsMarkup = [
    {
      content: 'Make products available',
      onAction: () => handleActions('available'),
    },
    {
      content: 'Delete Selected product',
      onAction: () => handleActions('delete-product'),
    },
    {
      content: 'Refetch total sale',
      onAction: () => handleActions('refetch-total-sale'),
    },
    {
      content: 'Gen Thumbnail Image',
      onAction: () => handleActions('gen-thumbnail-image'),
    },
    {
      content: 'Add Tags',
      id: 'add-tags',
      onAction: () => handleActions('add-tag'),
    },
    {
      content: 'Remove Tags',
      onAction: () => handleActions('remove-tag'),
    },
    {
      content: 'Add Collections',
      id: 'add-collections',
      onAction: () => handleActions('add-collection'),
    },
    {
      content: 'Remove Collections',
      onAction: () => handleActions('remove-collection'),
    },
  ];

  let { limit, offset } = filter;
  const totalPage = Math.ceil(_.get(data, 'products.total', 0) / limit);
  const page = offset / limit + 1;
  const total = _.get(data, 'products.total', 0);
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container className='pm_table-has-select has-media' pushedSaleChannels={pushedSaleChannels}>
      <ResourceList
        items={items}
        renderItem={(item) =>
          RenderProductPolaris(item, currentParam, toggleShowModal, pushedSaleChannels, refetch)
        }
        selectedItems={selectedItem}
        onSelectionChange={handleSelectedItemChange}
        bulkActions={bulkActionsMarkup}
        loading={networkStatus === 4}
        alternateTool={
          <div className='header-wrap'>
            <dt>Product</dt>
            {pushedSaleChannels && <dt>Pushed Sale Channels</dt>}
            <dt>Product base</dt>
            <dt>Total orders</dt>
            <dt>Has design</dt>
            <dt>Collections</dt>
            <dt>Tags</dt>
          </div>
        }
      />
      <div className='pagination-wrap'>
        <PaginationPolaris showTotal aggregation={aggregation} />
      </div>
      <AvailableProductsPolaris
        open={openModal && 'available' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        filter={filter}
        onCompleted={handleCompletedAction}
      />
      <DeleteProductsPolaris
        open={openModal && 'delete-product' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        filter={filter}
        onCompleted={handleCompletedAction}
      />
      <AddTagsProductsPolaris
        open={openModal && 'add-tag' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        refetch={refetch}
        onCompleted={handleCompletedAction}
        items={items}
      />
      <DeleteTagsProductsPolaris
        open={openModal && 'remove-tag' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        filter={filter}
        onCompleted={handleCompletedAction}
      />
      <AddCollectionsProductsPolaris
        open={openModal && 'add-collection' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        refetch={refetch}
        onCompleted={handleCompletedAction}
        items={items}
      />
      <DeleteCollectionsProductsPolaris
        open={openModal && 'remove-collection' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        filter={filter}
        onCompleted={handleCompletedAction}
      />
      <RefetchTotalSalePolaris
        open={openModal && 'refetch-total-sale' === isAction}
        toggleModal={toggleModal}
        selectedItem={selectedItem}
        refetch={refetch}
        onCompleted={handleCompletedAction}
      />
      <GenThumbnailImage
        open={openModal && 'gen-thumbnail-image' === isAction}
        toggleOpen={toggleModal}
        items={selectedItemData}
        refetch={refetch}
        onCompleted={handleCompletedAction}
      />
      {openModal && 'view-img' === isAction && (
        <ViewModalImage
          open={true}
          toggleShowModal={toggleModal}
          item={currentItem}
          large
        />
      )}
    </Container>
  );
};
