import React from "react";
import { Select } from "@shopify/polaris";

const options = [
  { value: "", label: "" },
  { value: "newborn", label: "Newborn" },
  { value: "infant", label: "Infant" },
  { value: "toddle", label: "Toddle" },
  { value: "kids", label: "Kids" },
  { value: "adult", label: "Adult" },
];

export const AgeGroupPolaris = ({ onChange, value }) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      label="Default age group"
      helpText="Required for apparel items in feeds that are focused on people
      in Brazil,France,Germany,Japan, the UK,and the US as well as
      all products with assigned age groups"
    />
  );
};
