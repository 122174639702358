import { Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { REPORT_TRACKING_SUBMIT_ERROR } from "../../../graphql/queries";
import { useQueryDNode } from "../../../hooks/useQueryDNode";
import { CardBox } from "../../reports/components/CardBox";

const styleSpan = {
  display: "inline-block",
  width: "9.5rem",
  wordBreak: "break-all",
};
const styleMessage = {
  display: "inline-block",
  width: "20rem",
  wordBreak: "break-all",
};
export function ReportSubmitError({ filter }) {
  const [get, { data, loading, error, fetchMore }] = useQueryDNode(
    REPORT_TRACKING_SUBMIT_ERROR
  );
  // State
  const [state, setState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      loadMore: false,
      canLoad: false,
      items: [],
      total: 0,
    }
  );
  const [innerFilter, setInnerFilter] = useState({
    limit: 20,
    offset: 0,
  });
  const timeoutRef = useRef(null);

  // Query
  useEffect(() => {
    const { range, teamID } = filter || {};
    setInnerFilter((p) => ({
      ...p,
      from: range?.from || null,
      to: range?.to || null,
      teamID,
    }));
    setState({ canLoad: false });
  }, [filter]);

  useEffect(() => {
    get(innerFilter);
  }, [innerFilter]);

  useEffect(() => {
    const nodes = data?.reportTrackingSubmitError?.nodes || [];
    const total = data?.reportTrackingSubmitError?.total || 0;

    setState({
      items: nodes,
      total,
    });
  }, [data]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <div key={`team-${index}`} data-team-id={item.id}>
              <Stack distribution="equalSpacing" wrap={false}>
                <span className="index-wrap">{index + 1}</span>
                {/* <span style={styleMessage}>{item.message}</span> */}
                <Stack.Item fill>
                  <span>{item.message}</span>
                </Stack.Item>
                <span style={styleSpan}>{item.count}</span>
              </Stack>
            </div>
          );
        })
      : null;
  }, [state.items]);

  const headerMarkup = useMemo(
    () => (
      <Stack wrap={false}>
        <span className="index-wrap">
          <TextStyle variation="strong">#</TextStyle>
        </span>
        <Stack.Item fill>
          {/* <span style={styleMessage}> */}
          <span>
            <TextStyle variation="strong">Message</TextStyle>
          </span>
        </Stack.Item>
        <span style={styleSpan}>
          <TextStyle variation="strong">Count</TextStyle>
        </span>
      </Stack>
    ),
    []
  );
  const subTitleMarkup = useMemo(() => <span>{state.total} Items</span>, [
    state.total,
  ]);

  // Actions
  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!state.canLoad) {
      setState({ loadMore: true });
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...innerFilter,
              offset: data.reportTrackingSubmitError.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportTrackingSubmitError =
              fetchMoreResult.reportTrackingSubmitError;
            const nodes = reportTrackingSubmitError.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setState({ canLoad: true });
            }
            setState({ loadMore: false });
            let newNodes = [
              ...prev.reportTrackingSubmitError.nodes,
              ...nodes,
            ].filter(Boolean);
            const newNodes2 = new Map(
              newNodes
                .map((node) =>
                  node.message ? [node.message, node] : undefined
                )
                .filter(Boolean)
            );
            const result = Array.from(newNodes2, ([, value]) => value);

            return {
              ...prev,
              reportTrackingSubmitError: {
                ...prev.reportTrackingSubmitError,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, innerFilter, state.canLoad, setState, fetchMore]);

  return (
    <Wrapper>
      <CardBox
        title="TrackingMore trackings submited Error"
        subTitle={subTitleMarkup}
        headMarkup={headerMarkup}
        contentMarkup={contentMarkup}
        filter={innerFilter}
        total={state.total}
        loading={loading}
        error={error}
        setFilter={setInnerFilter}
        handleScroll={handleScroll}
        loadMore={state.loadMore}
        noCollapse
        collapseKey="tracking-error"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .index-wrap {
    width: 3rem;
    display: inline-block;
  }

  .column-wrap {
    width: 7rem;
    display: inline-block;
  }

  .name-wrap {
    display: flex;
    flex: 1 1;
  }
`;
