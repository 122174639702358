import React, { useState, useCallback } from "react";
import { ButtonGroup, Button, Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

const DELETE_FULLFILLMENT = gql`
  mutation deleteFulfillment($id: ID!) {
    deleteFulfillment(id: $id)
  }
`;

export const FulfillmentActionsPolaris = ({
  item,
  refetch,
  onNotificationChange,
  toggleActive,
}) => {
  const id = item?.id;
  // State
  const [open, setOpen] = useState(false);

  // Mutation
  const [deleteFulfillment, { loading }] = useMutation(DELETE_FULLFILLMENT, {
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: "Delete fulfillment successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle action
  const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);
  const handleSubmit = useCallback(() => {
    if (id) {
      deleteFulfillment({
        variables: {
          id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <ButtonGroup>
        <Button
          children="Edit"
          plain
          size="slim"
          url={`/admin/fulfillment/${item?.id}`}
        />
        <Button children="Delete" plain size="slim" onClick={toggleShowModal} />
      </ButtonGroup>
      <Modal
        title="Delete fulfillment"
        open={open}
        onClose={toggleShowModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: loading,
        }}
      >
        <p>Are you sure to delete this fulfillment?</p>
      </Modal>
    </React.Fragment>
  );
};
