import { gql } from "apollo-boost";

export const UPDATE_REPORT_WIDGETS = gql`
    mutation updateReportWidgets(
        $page: String
        $col1Widgets: String!
        $col2Widgets: String!
    ) {
        updateReportWidgets(
            page: $page
            col1Widgets: $col1Widgets
            col2Widgets: $col2Widgets
        ) {
            col1
            col2
        }
    }
`;

export const RESET_REPORT_WIDGETS = gql`
    mutation resetReportWidgets($page: String) {
        resetReportWidgets(page: $page)
    }
`;
