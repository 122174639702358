import React, { useCallback, useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { UPDATE_DESIGN_TASK } from "../../../graphql/mutations";
import { DesignTaskFormPolaris } from "./DesignTaskFormPolaris";
import { useToastContext } from "../../shared/ToastContext";

export const UpdateTaskPolaris = (props) => {
    const {
        open,
        toggleShowModal,
        onNotificationChange,
        toggleActive,
        refetch,
        task,
    } = props;
    const id = task?.id;
    // useLockBodyScroll(open);

    // Ref
    const onCloseRef = useRef(null);

    // Context
    const { setFilter } = useToastContext();

    const [updateDesignTask, { loading }] = useMutation(UPDATE_DESIGN_TASK, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: () => {
            toggleShowModal();
            if (onNotificationChange) {
                onNotificationChange({
                    message: "Update task success.",
                    isError: false,
                });
            }

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
    });

    const handleSubmit = useCallback(
        (input) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            }
            if (input && id) {
                updateDesignTask({
                    variables: {
                        input: {
                            ...input,
                            id,
                        },
                    },
                });
                if (toggleActive) {
                    toggleActive();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    return (
        <Modal
            title="Update task"
            open={open}
            onClose={toggleShowModal}
            sectioned
        >
            <DesignTaskFormPolaris
                onSubmit={handleSubmit}
                loadingM={loading}
                toggleShowModal={toggleShowModal}
                value={task}
            />
        </Modal>
    );
};
