import React from "react";
import { Select } from "@shopify/polaris";

const options = [
  { label: "", value: "" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Unisex", value: "unisex" },
];

export const GenderPolaris = ({ value, onChange }) => {
  return (
    <Select
      label="Default gender"
      options={options}
      onChange={onChange}
      value={value}
      helpText="Required for apparel items in feeds that are focused on people
      in Brazil, France, Germany, Japan, the UK,and the US as well as
      gender-specific products."
    />
  );
};
