import { Page } from "@shopify/polaris";
import React from "react";
import { Settings } from "../../components/seller/Settings";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function SettingsPage() {
  return (
    <WrapperPage>
      <Page title="Settings" fullWidth>
        <Settings />
      </Page>
    </WrapperPage>
  );
}
