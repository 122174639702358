import { Card, Page } from "@shopify/polaris";
import React from "react";
import { WrapperPage } from "../../components/shared/WrapperPage";
import AddTiktokAccount from "../../components/tiktok/AddTiktokAccount";
import { useAppContext } from "../../context";
import { getParamByRole } from "../../helper";

export default function AddTiktokAccountPage() {
  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  return (
    <WrapperPage>
      <Page
        title="Add Tiktok Account"
        breadcrumbs={[
          { content: "All Tiktok Accounts", url: `/${param}/stores/tiktok` },
        ]}
      >
        <Card sectioned>
          <AddTiktokAccount />
        </Card>
      </Page>
    </WrapperPage>
  );
}
