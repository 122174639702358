import { Page } from "@shopify/polaris";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ProductPushPolaris } from "../../components/tools/ProductPushPolaris";
import { useAppContext } from "../../context";
import { getParamByRole, getPathFromRouter } from "../../helper";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const ProductsPushPagePolaris = (...props) => {
  const path = useMemo(() => {
    return getPathFromRouter(props);
  }, [props]);

  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  return (
    <Container>
      <Page
        title="All Push Product"
        fullWidth
        primaryAction={{
          content: "New Push Product",
          url: `/${param}/products/product-push/add`,
        }}
      >
        <ProductPushPolaris path={path} />
      </Page>
    </Container>
  );
};
