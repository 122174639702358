import React, { useState, useCallback, useEffect } from "react";
import { Modal, Spinner } from "@shopify/polaris";
import styled from "styled-components";

import { ImageTypes } from "../../variable";
import fileIcon from "../../assets/images/file.png";
import { CustomLinkPolaris } from "../../components/shared/CustomLinkPolaris";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-around;
  .image {
    width: 175px;
    height: 175px;
    padding: 10px;
    box-shadow: inset 0 0 0 1px rgba(33, 43, 54, 0.1);
    border-radius: 3px;
    .image-inner {
      width: 100%;
      height: 100%;
      background-size: cover;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  label {
    font-size: 1.25em;
    font-weight: 400;
    display: block;
    margin-bottom: 2rem;
  }
  .col-image {
    width: 60%;
    .image-inner {
      padding: 10px;
      box-shadow: inset 0 0 0 1px rgba(33, 43, 54, 0.1);
      margin-right: 2rem;
      border-radius: 3px;
      div {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
`;

export const MediaLibraryPolaris = ({ data, onChange, loadMore }) => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (data.mediaLibrary.nodes) {
      setImages(data.mediaLibrary.nodes);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      if (onChange) {
        onChange(isBottom);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 150 >= scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  const handleCurrentImage = useCallback((value) => {
    setImage(value);
    toggleOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {images.map((image, index) => (
          <div key={`${index} - ${image.id}`} className="image">
            <div
              className="image-inner"
              style={{
                backgroundImage: `url(${
                  image.thumbnailUrl ? image.thumbnailUrl : image.url
                })`,
              }}
              onClick={() => handleCurrentImage(image)}
            >
              {!ImageTypes.includes(image.mimeType.toLowerCase()) && (
                <div
                  style={{
                    width: "100%",
                    wordBreak: "break-word",
                    whiteSpace: "pre",
                    overflow: "hidden",
                  }}
                >
                  <img
                    alt=""
                    src={fileIcon}
                    style={{ width: 20, height: 20, marginRight: "0.5rem" }}
                  />
                  <span>{image.name}</span>
                </div>
              )}
            </div>
          </div>
        ))}

        <Modal
          open={open}
          onClose={toggleOpen}
          title="Image details"
          size="Large"
          sectioned
          primaryAction={{ content: "OK", onAction: () => toggleOpen() }}
        >
          <ModalContainer>
            <div className="col-image">
              <label>Image</label>
              <div className="image-inner">
                {image && (
                  <div
                    style={{
                      backgroundImage: `url(${
                        image.thumbnailUrl ? image.thumbnailUrl : image.url
                      })`,
                      width: 300,
                      height: 300,
                      backgroundSize: "cover",
                    }}
                  >
                    {!ImageTypes.includes(image.mimeType.toLowerCase()) && (
                      <div
                        style={{
                          width: "100%",
                          wordBreak: "break-word",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          alt=""
                          src={fileIcon}
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: "0.5rem",
                          }}
                        />
                        <span>{image.name}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-information">
              <label>Information</label>
              <div>
                {image && (
                  <div>
                    <label>{image.name}</label>
                    <CustomLinkPolaris
                      href={image.url ? image.url : image.thumbnailUrl}
                      file={image}
                    >
                      View origin
                    </CustomLinkPolaris>
                  </div>
                )}
              </div>
            </div>
          </ModalContainer>
        </Modal>
      </Container>
      {loadMore && (
        <div
          style={{
            padding: "4rem 0 2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner size="large" hasFocusableParent />
        </div>
      )}
    </>
  );
};
