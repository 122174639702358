import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

const options = [
  { label: "All", value: "all" },
  { label: "Print Files", value: "print_files/" },
  { label: "Mockup", value: "mockups/" },
];

export const MediaFilterByPrefixPolaris = ({ prefix, onChange }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (null === prefix.value) {
      setSelected(["all"]);
    } else {
      setSelected([prefix.value]);
    }
  }, [prefix]);

  const handleSelectedChange = useCallback((selected) => {
    setSelected(selected);
    const selectedValue = selected.map((selectedItem) => {
      const matchedOptions = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOptions && matchedOptions.label;
    });

    if (onChange) {
      onChange({
        label: "All" !== _.head(selectedValue) ? _.head(selectedValue) : null,
        value: "all" !== _.head(selected) ? _.head(selected) : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Prefix"
      titleHidden
      choices={options}
      selected={selected}
      onChange={handleSelectedChange}
    />
  );
};
