import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Stack } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UPDATE_DESIGN_TASK } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { UPLOAD_FOLDER } from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { useToastContext } from "../../shared/ToastContext";

export const UpdateTaskResource = (props) => {
  const {
    open,
    toggleShowModal,
    onNotificationChange,
    toggleActive,
    refetch,
    task,
  } = props;
  const id = task?.id;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (task) {
      setFiles(task.resources?.length > 0 ? task.resources : []);
    }
  }, [task]);
  // Ref
  const onCloseRef = useRef(null);

  // Context
  const { setFilter } = useToastContext();

  const [updateDesignTask, { loading }] = useMutation(UPDATE_DESIGN_TASK, {
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: "Update task resource success.",
          isError: false,
        });
      }

      // onClose parent modal
      onCloseRef.current && clearTimeout(onCloseRef.current);
      onCloseRef.current = setTimeout(() => {
        setFilter && setFilter((prev) => ({ ...prev }));
        refetch && refetch();
      }, 1000);
    },
  });

  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (id) {
      const fileIds =
        files?.length > 0 ? files.map((item) => item.id).filter(Boolean) : [];

      updateDesignTask({
        variables: {
          input: {
            id,
            resources: fileIds,
          },
        },
      });
      if (toggleActive) {
        toggleActive();
      }
    }
  }, [id, files]);

  const handleFileChange = useCallback((value) => {
    let files = value && value.length > 0 ? value : [];
    setFiles(files);
  }, []);

  return (
    <Modal title="Update task resource" open={open} onClose={toggleShowModal} sectioned>
      <Stack vertical>
        <div>
          <label>Resource Files:</label>
          <MediaSelectorButtonPolaris
            value={files}
            prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
            folder={UPLOAD_FOLDER.PSD_RESOURCE}
            accept={`image/*`}
            onChange={handleFileChange}
            multiple
            sizeSmall
          />
        </div>
        <Button
          children="Submit"
          onClick={handleSubmit}
          primary
          loading={loading}
        />
      </Stack>
    </Modal>
  );
};
