import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { UPDATE_PSD_FILE_TASK_BASE_GROUP } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";
import { useCreateIdea } from "../../context/createIdea";

export function UpdatePsd({ onCloseParent, ...props }) {
    // State
    const onCloseRef = useRef(null);

    // Context
    const { products } = useCreateIdea();
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [updatePsdFile, { loading }] = useMutation(
        UPDATE_PSD_FILE_TASK_BASE_GROUP,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Take this idea task successfully.",
                    err: false,
                });
                props.onClose && props.onClose();
                onCloseParent && onCloseParent();

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    setFilter && setFilter((prev) => ({ ...prev }));
                    refetch && refetch();
                }, 1000);
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    // Actions
    const handleSubmit = useCallback(() => {
        if (products?.length > 0) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });

            const input = genInput(products);
            updatePsdFile({
                variables: {
                    input,
                },
            });
        }
    }, [products, updatePsdFile, toggleToast, setNotify]);

    return (
        <Modal
            {...props}
            sectioned
            title="Update PSD File"
            large
            secondaryActions={[{ content: "Close", onAction: props.onClose }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to update PSD File for this idea task?</p>
        </Modal>
    );
}

export function genInput(products) {
    return products.map((p) => {
        const { id, psdFiles, psd } = p || {};
        const newPsdFiles = (psdFiles || []).map((i) => i.id);
        let newPsd = (psd || "").split(/\n/g).join("|");

        return {
            taskBaseGroupId: id,
            psdFiles: newPsdFiles,
            psd: newPsd,
        };
    });
}
