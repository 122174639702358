import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { TextField } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div``;

export function Filter({ filter, onChange }) {
    const search = getTitles(filter.titles);

    const [query, setQuery] = useState("");
    const typingTimeoutRef = useRef(null);

    // handle action
    useLayoutEffect(() => {
        if (search != null) {
            setQuery(search);
        }
    }, [search]);

    useEffect(() => {
        typingTimeoutRef.current = setTimeout(() => {
            onChange && onChange({ titles: genTitles(query) });
        }, 350);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        };
    }, [query, onChange]);

    const handleQueryChange = useCallback((value) => {
        setQuery(value);
    }, []);

    const handleQueryRemove = useCallback(() => setQuery(""), []);

    return (
        <Container>
            <TextField
                value={query}
                onChange={handleQueryChange}
                onClearButtonClick={handleQueryRemove}
                autoFocus
                multiline={4}
                clearButton
                placeholder="Filter products"
            />
        </Container>
    );
}

function getTitles(value) {
    if (value == null || value.length === 0) return "";
    return value.join("\n");
}

function genTitles(value) {
    if (value == null) return "";
    const result = value
        .split(/\n/g)
        .map((i) => i.trim())
        .filter(Boolean);

    return result?.length === 0 ? [""] : result;
}
