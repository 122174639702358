import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Spinner } from "@shopify/polaris";
import { head, get } from "lodash";

import { handleError } from "../../helper";
import { ComponentLabelPolaris } from "./ComponentLabelPolaris";

export const AutoCompleteHasSearchPolaris = (props) => {
  const {
    data,
    value,
    onChange,
    onChangeSearch,
    // search,
    label,
    placeholder,
    required,
    labelHidden,
    error,
    queryError,
    loading,
    onFocus,
    suffix,
  } = props;

  // State
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSearch, setIsSearch] = useState(null);

  // Get data
  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (value && !isSearch) {
      setSelectedOptions(value ? [value.value] : []);
      setInputValue(value ? value.label : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Handle action
  const handleInputValue = useCallback((value) => {
    setInputValue(value);
    setIsSearch(() => true);
    if (onChangeSearch) {
      onChangeSearch(value ? value.trim() : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        let label = matchedOptions && matchedOptions.label;
        if (typeof label === "object") {
          label = get(label, "props.children", "");
        }
        return label;
      });
      setSelectedOptions(selected);
      setInputValue(head(selectedValue));
      if (onChange) {
        onChange({ value: head(selected), label: head(selectedValue) });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textFieldMarkup = (
    <Autocomplete.TextField
      value={inputValue}
      onChange={handleInputValue}
      placeholder={placeholder ? placeholder : "Filter item"}
      error={error}
      onFocus={onFocus}
      suffix={suffix}
    />
  );

  return (
    <React.Fragment>
      {!labelHidden ? (
        <ComponentLabelPolaris label={label} required={required} />
      ) : null}
      {loading ? (
        <Spinner size="small" />
      ) : (
        <>
          {queryError ? (
            <div>Error: {handleError(queryError.toString())}</div>
          ) : (
            <Autocomplete
              options={options}
              selected={selectedOptions}
              onSelect={handleSelection}
              textField={textFieldMarkup}
              emptyState={<span>No items found!</span>}
              // loading={loading}
              willLoadMoreResults
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
