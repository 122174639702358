import React, { useContext } from "react";
import { DataTable, Thumbnail, Badge, Link } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import { AppContext } from "../../../context";
import { TEAM_ROLE, USER_ROLE, TEAM_ROLE_PARAMS } from "../../../variable";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import noImg from  "../../../assets/images/unnamed.jpeg";

const Container = styled.div`
  .title-wrap {
    max-width: 50rem;
    white-space: break-spaces;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const TableProductByDivisionPolaris = (props) => {
  const { data, filter, setFilter, total } = props;
  const { currentUser } = useContext(AppContext);

  const userRole = _.get(currentUser, "roles", []);
  const teamRole = _.get(currentUser, "teamUser.role", null);

  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }
    if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
  }

  const rows =
    data && data.length > 0
      ? data.map((d, idx) => {
        let thumbnailMarkup = _.get(_.head(d.images), "file");
          thumbnailMarkup =
            thumbnailMarkup ? thumbnailMarkup.thumbnailUrl
              ? thumbnailMarkup.thumbnailUrl
              : thumbnailMarkup.url : null ;
          let status = _.get(d, "divisions[0].used");
          let statusLabel = "";
          let statusColor = null;
          if (status) {
            statusLabel = "Used";
            statusColor = "success";
          } else {
            statusLabel = "Unused";
            statusColor = "critical";
          }
          let url = `/${currentParam}/products/edit/${d.id}`;
          return [
            `${++idx}.`,
            <Thumbnail alt="" source={thumbnailMarkup || noImg} size="large" />,
            <div className="title-wrap">
              <Link url={url} children={d.title} />
            </div>,
            <div className="status-wrap">
              <Badge status={statusColor} children={statusLabel} />
            </div>,
          ];
        })
      : [];

  let { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={["No.", "Image", "Title", "Status"]}
        rows={rows}
        verticalAlign={"middle"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
        hideScrollIndicator
      />
    </Container>
  );
};
