import React, { useCallback, useState, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";

const options = [
  {
    value: "All",
    label: "All",
  },
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
];
export default function FilterUserAccountAdminStatusPolaris(props) {
  const { status, onChange } = props;

  const [selected, setSelected] = useState(["All"]);

  useEffect(() => {
    if (null === status) {
      setSelected(["All"]);
    } else {
      setSelected([status]);
    }
  }, [status]);

  const handleChoicesChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOptions = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOptions && matchedOptions.label;
    });
    if (onChange) {
      onChange(
        "All" !== selected[0] ? selected[0] : null,
        "All" !== selectedValue[0] ? selectedValue[0] : null
      );
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ChoiceList
      title="Status"
      titleHidden={true}
      selected={selected}
      choices={options}
      onChange={handleChoicesChange}
    />
  );
}
