import React, { Component } from "react";
import { Input, Button, notification, Form } from "antd";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";

export const UPDATE_STATUS_CLAIM = gql`
  mutation updateTracking($input: UpdateTracking!) {
    updateTracking(input: $input) {
      id
      orderId
      type
      comment
      status
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class ActionResent extends Component {
  state = {
    loading: false,
  };
  render() {
    const { claim } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Mutation
          mutation={UPDATE_STATUS_CLAIM}
          onCompleted={() => {
            this.setState({ loading: false });
            notification.success({ message: "Resent claim success!" });
          }}
          onError={(err) => {
            this.setState({ loading: false });
            notification.error({ message: err.toString() });
          }}
        >
          {(updateTracking) => (
            <Form
              {...layout}
              onFinish={(values) => {
                if (values) {
                  this.setState({ loading: true });
                  updateTracking({
                    variables: {
                      input: {
                        ...values,
                        claimId: claim.id,
                      },
                    },
                  });
                }
              }}
            >
              <Form.Item
                label="Tracking Code"
                name="newCode"
                rules={[
                  {
                    required: true,
                    message: "Tracking code is requied.",
                  },
                ]}
              >
                <Input placeholder="Enter tracking code" />
              </Form.Item>
              <Form.Item
                label="Note"
                name="note"
                rules={[
                  {
                    required: true,
                    message: "Note is required.",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item className="flex justify-end">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default ActionResent;
