import React, { useCallback, Fragment } from "react";
import { Button, Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { RE_GEN_2D_MOCKUP } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import useToggle from "../../../../hooks/useToggle";
import { handleError } from "../../../../helper";

export function ReGen({
    idsChecked,
    handleCompleted,
    isAction = false,
    open: openProp,
    toggleOpen: toggleOpenProp,
}) {
    // Context
    const { toggleToast, setNotify } = useToastContext();

    // State
    const [open, toggleOpen] = useToggle(false);

    // Props
    const onClose = isAction ? toggleOpenProp : toggleOpen;
    const suffix = idsChecked?.length > 1 ? "s" : "";

    // Mutation
    const [reGenMockup, { loading }] = useMutation(RE_GEN_2D_MOCKUP, {
        onCompleted: () => {
            setNotify({
                msg: `Re-gen mockup${suffix} successfully.`,
                err: false,
            });
            onClose && onClose();
            handleCompleted && handleCompleted();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err.toString()), err: true });
        },
    });

    // Handle actions
    const handleSubmit = useCallback(() => {
        if (idsChecked?.length > 0) {
            toggleToast && toggleToast(true);
            setNotify({ msg: null, err: false });
            reGenMockup({
                variables: {
                    ids: idsChecked,
                },
            });
        }
    }, [idsChecked, reGenMockup, setNotify, toggleToast]);

    return (
        <Fragment>
            {!isAction && (
                <Button children="Re-gen Mockups" onClick={toggleOpen} />
            )}
            <Modal
                title={`Re-gen Mockup${suffix}`}
                open={isAction ? openProp : open}
                onClose={onClose}
                sectioned
                primaryAction={{
                    loading: loading,
                    content: "Re-gen",
                    onAction: handleSubmit,
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: onClose,
                    },
                ]}
            >
                <p>Are you sure to re-gen mockup{suffix}?</p>
            </Modal>
        </Fragment>
    );
}
