import React, { useState, useEffect, Fragment } from "react";
import { DataTable, Button, Badge } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import history from "../../../history";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { ColAccountPolaris } from "./ColAccountPolaris";

const Container = styled.div`
    overflow-x: auto;

    .Polaris-DataTable__ScrollContainer {
        overflow-x: inherit;
    }
    .title-wrap {
        width: calc(25rem - 3.2rem);
    }
    .pbTitle-wrap {
        width: calc(55rem - 3.2rem);
        white-space: break-spaces;
    }

    .stores-wrap,
    .total-wrap,
    .status-wrap,
    .action-wrap {
        width: calc(18rem - 3.2rem);
    }

    .Polaris-DataTable__TableRow {
        &:last-child {
            .stores-wrap {
                padding-bottom: 2rem;
            }
        }
    }
`;

const PaginationContainer = styled.div`
    padding: 1.6rem;
    background: var(--p-surface-subdued, #ebeef1);
    text-align: center;
    border-radius: var(--p-border-radius-wide, 3px);
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-x: scroll;
`;

export const TableProductDivisionsPolaris = (props) => {
    const { data, filter, setFilter, currentParam } = props;
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let newData =
            data &&
            data.listProductDivision &&
            data.listProductDivision.nodes &&
            data.listProductDivision.nodes.length > 0
                ? data.listProductDivision.nodes.map((i) => {
                      let pbTitle =
                          i.productBases && i.productBases.length > 0
                              ? i.productBases.map((i) => i.title).join(", ")
                              : "";

                      let total = i.total;
                      let totalUsed = i.totalUsed;
                      if (!total) {
                          total = 0;
                      }
                      if (!totalUsed) {
                          totalUsed = 0;
                      }
                      let status = i.status;
                      let statusColor = null;
                      if (!status || status === "Done") {
                          status = "Done";
                          statusColor = "success";
                      }
                      if ("Processing" === status) {
                          statusColor = "attention";
                      }

                      return [
                          <div className="title-wrap">
                              <span>{i.title}</span>
                          </div>,
                          <div className="pbTitle-wrap">
                              <span>{pbTitle}</span>
                          </div>,
                          <div className="stores-wrap">
                              <ColAccountPolaris
                                  stores={i.stores ? i.stores : []}
                              />
                          </div>,
                          <div className="total-wrap">
                              <span>
                                  {totalUsed}/{total}
                              </span>
                          </div>,
                          <div className="status-wrap">
                              <Badge status={statusColor} children={status} />
                          </div>,
                          <div className="action-wrap">
                              <Button
                                  plain
                                  children={"Edit"}
                                  onClick={() =>
                                      history.push(
                                          `/${currentParam}/product-divisions/edit/${i.id}`
                                      )
                                  }
                              />
                          </div>,
                      ];
                  })
                : [];
        setRows(newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let { limit, offset } = filter;
    const total = _.get(data, "listProductDivision.total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: setFilter,
        total,
    };

    return (
        <Fragment>
            <Container>
                <DataTable
                    columnContentTypes={[
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                        "numeric",
                    ]}
                    headings={[
                        "Title",
                        "Product base",
                        "Account",
                        "Total products",
                        "Status",
                        "Action",
                    ]}
                    rows={rows}
                    verticalAlign={"middle"}
                    hideScrollIndicator
                />
            </Container>
            <PaginationContainer>
                <PaginationPolaris aggregation={aggregation} showTotal />
            </PaginationContainer>
        </Fragment>
    );
};
