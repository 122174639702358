import { gql } from 'apollo-boost';

export const MAPPING_EVENTS = gql`
  subscription mappingEvents {
    mappingEvents {
      event # mapping_item_is_created  | mapping_item_is_deleted | mapping_item_is_mapped
      mappingItem {
        id
        teamId
      }
    }
  }
`;