import React, { useCallback, Fragment, useState } from "react";
import { Button, Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import useToggle from "../../../../hooks/useToggle";
import { BULK_REVIEW_PRODUCT_GEN_2D_MOCKUP } from "../../../../graphql/mutations";
import { useToastContext } from "../../../shared/ToastContext";
import { handleError } from "../../../../helper";

export function ReviewBulkAction({ idsChecked, handleCompleted }) {
    // Context
    const { toggleToast, setNotify } = useToastContext();

    // State
    const [open, toggleOpen] = useToggle(false);
    const [isApprove, setIsApprove] = useState(null);
    const msg =
        typeof isApprove === "boolean" &&
        `${isApprove ? "Accept" : "Reject"} Design successfully.`;

    // Mutation
    const [review, { loading }] = useMutation(
        BULK_REVIEW_PRODUCT_GEN_2D_MOCKUP,
        {
            onCompleted: () => {
                setNotify({ msg, err: false });
                toggleOpen();
                handleCompleted && handleCompleted();
            },
            onError: (err) => {
                setNotify({ msg: handleError(err.toString()), err: true });
            },
        }
    );

    // Handle actions
    const handleSubmit = useCallback(
        (value) => {
            if (idsChecked?.length > 0) {
                setIsApprove(value);
                toggleToast && toggleToast(true);
                setNotify({ msg: null, err: false });
                review({
                    variables: {
                        ids: idsChecked,
                        isApprove: value,
                    },
                });
            }
        },
        [idsChecked, review, setNotify, toggleToast]
    );

    const handleAccept = useCallback(() => {
        handleSubmit(true);
    }, [handleSubmit]);

    const handleReject = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);

    const primaryAction = {
        content: "Accept",
        onAction: handleAccept,
        loading: isApprove === true && loading,
    };
    const secondaryActions = {
        content: "Reject",
        onAction: handleReject,
        loading: isApprove === false && loading,
    };

    return (
        <Fragment>
            <Button children="Review" onClick={toggleOpen} />
            <Modal
                title="Review Mockups"
                open={open}
                onClose={toggleOpen}
                sectioned
                primaryAction={primaryAction}
                secondaryActions={[secondaryActions]}
            >
                <p>Are you sure to review to mockups?</p>
            </Modal>
        </Fragment>
    );
}
