export const TASK_STATUS = {
    Doing: "Doing",
    Pending: "Pending",
    Rejected: "Rejected",
    Denied: "Denied",
    "Partially Completed": "Partially Completed",
    "Need Review": "Need Review",
    Approved: "Approved",
    Done: "Done",
    Draft: "Draft",
    "File Generating": "File Generating",
    Declined: "Declined",
};

export function checkStatusTask(status) {
    function fn(arr) {
        return arr.includes(status);
    }

    return {
        isDoing: fn([TASK_STATUS.Doing]),
        isPending: fn([TASK_STATUS.Pending]),
        isNeedReview: fn([TASK_STATUS["Need Review"]]),
        isRejected: fn([TASK_STATUS.Rejected]),
        isApproved: fn([TASK_STATUS.Approved]),
        isDenied: fn([TASK_STATUS.Denied]),
        isDone: fn([TASK_STATUS.Done]),
        isDraft: fn([TASK_STATUS.Draft]),
        isFileGenerating: fn([TASK_STATUS["File Generating"]]),
        isDeclined: fn([TASK_STATUS.Declined]),
    };
}

function getFiles(file) {
    if (!file) return [];

    return Array.isArray(file) ? file : [file];
}

export function mapSubmission(
    baseConfigId,
    taskBaseSubmissions,
    designPositions,
    first
) {
    if (
        !baseConfigId ||
        !taskBaseSubmissions ||
        taskBaseSubmissions.length === 0
    )
        return first
            ? (
                  designPositions || []
              ).map(({ file, generateMockupFile, ...rest }) => ({ ...rest }))
            : designPositions;

    let clone = [...designPositions];

    const filterSubmission = taskBaseSubmissions.filter(
        (t) => t.taskBaseConfigId === baseConfigId
    );
    filterSubmission.forEach((s) => {
        const { taskBaseSubmissionFiles } = s || {};

        (taskBaseSubmissionFiles || []).forEach((f) => {
            const { designPositionId, file, generateMockupFile } = f || {};
            clone = clone.map((item) => {
                if (item.id === designPositionId) {
                    const newFiles = first ? file : item.file;
                    const newGFiles = first
                        ? generateMockupFile
                        : item.generateMockupFile;

                    return {
                        ...item,
                        file: getFiles(newFiles),
                        generateMockupFile: getFiles(newGFiles),
                    };
                }

                return item;
            });
        });
    });

    return clone;
}
