import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { TEAM_ROLE } from "../../variable";
import { Select, Spin } from "antd";
import styled from "styled-components";

import { GET_USER_BY_ROLES } from "../../graphql/queries";

const Container = styled.div`
  .ant-select-selection-item {
    cursor: pointer;
  }
`;

export default class DesignerSelect extends Component {
  render() {
    const { value, onChange, showAll } = this.props;

    return (
      <Query
        query={GET_USER_BY_ROLES}
        variables={{
          roles: [TEAM_ROLE.Designer],
        }}
      >
        {({ data, error, loading }) => {
          if (loading) return <Spin />;
          if (error) return error.toString();
          return (
            <Container>
              <Select
                className="cursor-pointer"
                style={{ width: showAll ? 150 : "maxWidth" }}
                value={value}
                showSearch={!showAll}
                filterOption={
                  !showAll
                    ? (v, s) => s.children.toLowerCase().includes(v)
                    : false
                }
                onSelect={(v, o) => onChange && onChange(v, o.data)}
              >
                {showAll && (
                  <Select.Option value={null}>All Designer</Select.Option>
                )}
                {data &&
                  data.listUserTeamByRoles &&
                  data.listUserTeamByRoles.map(({ user }) => (
                    <Select.Option
                      key={user.id}
                      value={user.id}
                      data={user}
                    >{`${user.firstName} ${user.lastName}`}</Select.Option>
                  ))}
              </Select>
            </Container>
          );
        }}
      </Query>
    );
  }
}
