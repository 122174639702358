import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal, Stack } from "@shopify/polaris";
import React from "react";
import { RE_ASSIGN_DESIGN_TEAM_FOR_IDEA_TASK } from "../../../../../graphql/mutations";
import { GET_DESIGN_TEAMS } from "../../../../../graphql/queries";
import { arrInvalid, handleError, reducerFn } from "../../../../../helper";
import { AutoCompletePolaris } from "../../../../shared/AutoCompletePolaris";
import { useToastContext } from "../../../../shared/ToastContext";

const ReAssignToDesignTeam = ({ item, onCloseParent, ...props }) => {
  const taskId = item?.id;
  const [state, setState] = React.useReducer(reducerFn, {
    designTeamId: "",
    designTeams: [],
  });

  const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

  const { data } = useQuery(GET_DESIGN_TEAMS, {
    variables: { filter: {} },
    skip: !props.open,
  });

  React.useEffect(() => {
    const curDSID = item?.designTeamID;
    const nodes = data?.getDesignTeams?.nodes || [];
    const designTeams = genDesignTeams(nodes, curDSID);
    setState({ designTeams });
  }, [data, item?.designTeamID]);

  const timeoutRef = React.useRef();
  const [reAssignToDesignTeam, { loading }] = useMutation(
    RE_ASSIGN_DESIGN_TEAM_FOR_IDEA_TASK,
    {
      onCompleted: () => {
        setNotify({
          msg: "Assign Idea Task to Design Team succss",
          err: false,
        });

        props.onClose && props.onClose();
        onCloseParent && onCloseParent();
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setFilter && setFilter((p) => ({ ...p }));
          refetch && refetch();
        }, 1000);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    if (taskId.length > 0 && state.designTeamId) {
      toggleToast && toggleToast();
      setNotify && setNotify({ err: false, msg: "" });

      reAssignToDesignTeam({
        variables: {
          input: {
            taskID: taskId,
            designTeamID: state.designTeamId,
          },
        },
      });
    }
  }, [
    reAssignToDesignTeam,
    toggleToast,
    setNotify,
    taskId,
    state.designTeamId,
  ]);
  const handleDesignTeamChange = React.useCallback((value) => {
    setState({ designTeamId: value });
  }, []);

  const curDesignTeam = item?.designTeam?.name;

  return (
    <Modal
      {...props}
      sectioned
      title="Re-assign to Design Team"
      large
      secondaryActions={[{ content: "Close", onAction: props.onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        disabled: state.designTeamId == null,
        loading,
      }}
    >
      <Stack vertical>
        <div>
          <label>
            <b>Current Design Team: </b>
          </label>
          <span>{curDesignTeam}</span>
        </div>
        <AutoCompletePolaris
          options={state.designTeams}
          onChange={handleDesignTeamChange}
          label="Design Team"
          required
          value={state.designTeamId}
        />
      </Stack>
    </Modal>
  );
};

function genDesignTeams(nodes, exId) {
  if (arrInvalid(nodes)) return [];

  return nodes
    .filter((item) => item?.id !== exId)
    .map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
}

export default ReAssignToDesignTeam;
