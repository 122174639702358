import React from "react";

const BaseFulfillmentContext = React.createContext({});

export default function BaseFulfillmentProvider({ children, ...props }) {
  return (
    <BaseFulfillmentContext.Provider value={props}>
      {children}
    </BaseFulfillmentContext.Provider>
  );
}

export function useBaseFulfillmentCtx() {
  return React.useContext(BaseFulfillmentContext);
}
