import React, { useMemo } from "react";
import styled from "styled-components";
import { ProductFromPrintFile } from "../../components/product/print-file";
import { ToastContextProvider } from "../../components/shared/ToastContext";
import { getPathFromRouter } from "../../helper";

export function ProductFromPrintFilePage(...props) {
    const path = useMemo(() => {
        return getPathFromRouter(props);
    }, [props]);

    return (
        <Wrapper>
            <ToastContextProvider>
                <ProductFromPrintFile path={path} />
            </ToastContextProvider>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;
