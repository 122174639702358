import React, { useState, useCallback, useEffect, useContext } from "react";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import history from "../../../history";
import { handleError } from "../../../helper";
import { AppContext } from "../../../context";
import { USER_ROLE, TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../../variable";
import { ProductDivisionsFormPolaris } from "./ProductDivisionsFormPolaris";

export const MUTATION_PRODUCT_DIVISIONS = gql`
    mutation createProductDivision($input: NewProductDivision!) {
        createProductDivision(input: $input) {
            id
            title
        }
    }
`;

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const AddProductDivisionPolaris = () => {
    const [timeoutId, setTimeoutId] = useState(null);
    const [activeToast, setActiveToast] = useState(false);

    const { currentUser } = useContext(AppContext);
    const userRole = _.get(currentUser, "roles", []);
    const teamRole = _.get(currentUser, "teamUser.role", null);

    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (userRole.includes(USER_ROLE.Seller)) {
        if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
        }
    }

    const [createProductDivision, { data, loading, error }] = useMutation(
        MUTATION_PRODUCT_DIVISIONS,
        {
            onError: () => {},
            onCompleted: () => {
                const id = setTimeout(() => {
                    history.push(`/${currentParam}/product-divisions`);
                }, 2100);
                setTimeoutId(id);
            },
        }
    );

    useEffect(() => {
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleActive = useCallback(() => {
        setActiveToast((activeToast) => !activeToast);
    }, []);

    const handleSubmit = useCallback((input) => {
        createProductDivision({
            variables: { input },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let toastSuccess =
        data && data.createProductDivision && "Add product division success.";
    let newError = error ? handleError(error.toString()) : null;

    const toastMarkup = activeToast
        ? (newError || toastSuccess) && (
              <Toast
                  content={newError ? newError : toastSuccess}
                  error={newError}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <Container>
            {toastMarkup}
            <Page
                title="Add Product Division"
                breadcrumbs={[
                    {
                        content: "Product divisions",
                        url: `/${currentParam}/product-divisions`,
                    },
                ]}
            >
                <ProductDivisionsFormPolaris
                    onSubmit={handleSubmit}
                    loadingM={loading}
                    currentParam={currentParam}
                    isEditDivision={false}
                />
            </Page>
        </Container>
    );
};
