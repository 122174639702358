import { gql } from "apollo-boost";
import isEqual from "lodash/isEqual";
import React from "react";
import { arrInvalid, objectInvalid, reducerFn } from "../../../helper";
import { useCallbackRef } from "../../../hooks/useCallbackRef";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { getUsersMissing } from "../../user/hooks";

const useTeamMembers = (filter = {}, fragment, skip) => {
  const filterStr = JSON.stringify(filter);
  const queryOptions = React.useMemo(() => {
    const filter = JSON.parse(filterStr);
    return {
      variables: { filter },
      skip,
      query: gql`
        query teamMembers($filter: TeamUserFilter) {
          teamMembers(filter: $filter) {
            ${fragment}
          }
        }
      `,
    };
  }, [filterStr, fragment, skip]);

  return useCustomQuery({
    queryOptions,
    path: "data.teamMembers",
  });
};

const useTeamMembersFilter = ({
  value,
  skip,
  filter: filterProp,
  fragment = "id user {id firstName lastName }",
}) => {
  const [filter, setFilter] = React.useReducer(reducerFn, {
    limit: 20,
    offset: 0,
    search: "",
  });

  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    selectedOptions: [],
  });

  const { data, loading, error } = useTeamMembers(
    React.useMemo(
      () => ({ ...filter, ...filterProp }),
      Object.values({ ...filter, ...filterProp })
    ),
    `hits {${fragment}}`,
    skip
  );

  React.useEffect(() => {
    if (objectInvalid(data)) return;

    const options = getOptions(data.hits);
    setState({ options });
  }, [data]);

  const optionsStr = JSON.stringify(state.options);
  const prevValue = React.useRef();
  React.useEffect(() => {
    let options = JSON.parse(optionsStr);
    if (
      arrInvalid(options) ||
      arrInvalid(value) ||
      isEqual(prevValue.current, value)
    )
      return;

    (async function () {
      const ids = getOptions(options, ({ value }) => value);
      const optionsOther = await getUsersMissing(
        ids,
        value,
        "id firstName lastName"
      );
      const optionsOtherFormat = getOptions(optionsOther);
      options = [...options, ...optionsOtherFormat];
      const selectedOptions = options.filter(({ value: val }) =>
        value.includes(val)
      );
      setState({ options, selectedOptions });
      prevValue.current = value;
    })();
  }, [optionsStr, value]);

  return [
    React.useMemo(
      () => ({
        loading,
        error,
        options: state.options,
        selectedOptions: state.selectedOptions,
      }),
      [loading, error, state.options, state.selectedOptions]
    ),
    useCallbackRef(setFilter),
  ];
};

/** -----------  */
const getOptions = (
  data,
  fn = (item) => {
    if (objectInvalid(item)) return null;
    const { id, firstName, lastName } = item.user ? item.user : item;
    const fn = [firstName, lastName].filter(Boolean).join(" ");
    return { value: id, label: fn };
  }
) => {
  if (arrInvalid(data)) return [];

  return data.map(fn).filter(Boolean);
};

export { useTeamMembers, useTeamMembersFilter };
