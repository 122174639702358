import React, { useState, useCallback } from "react";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { LIST_TEAM } from "../seller/ListTeamMember";

import { handleError } from "../../helper";
import history from "../../history";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

const DELETE_MEMBER = gql`
  mutation deleteUserTeam($id: ID!) {
    deleteUserTeam(id: $id)
  }
`;
export const DeleteMemberPolaris = ({ member, filter }) => {
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [deleteUserTeam, { data, error, client, loading }] = useMutation(
    DELETE_MEMBER,
    {
      onCompleted: () => {
        handleModalChange();
      },
    }
  );
  const handleClick = useCallback(() => {
    history.push(`/seller/edit-team-member/${member.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalChange = useCallback(() => setOpen((open) => !open), []);
  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.deleteUserTeam)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.deleteUserTeam && "Member has been deleted!"
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const deleteMemberState = (members, id) => {
    for (let i = 0; i < members.length; i++) {
      const c = members[i];
      if (c.id === id) {
        c.status = !c.status;
        // members.splice(i, 1);
        // break;
      }
      if (c.children && c.children.length) {
        c.children = deleteMemberState(c.children, id);
      }
    }
    return members;
  };
  return (
    <Container style={{ padding: 9 }}>
      <Button onClick={handleClick} plain>
        Edit
      </Button>
      <Button onClick={handleModalChange} plain>
        Delete
      </Button>
      <Modal
        title={"Delete member"}
        open={open}
        onClose={handleModalChange}
        primaryAction={{
          content: "Delete",
          loading: loading,
          onAction: () => {
            deleteUserTeam({
              variables: { id: member.id },
            })
              .then((res) => {
                if (res) {
                  try {
                    const cache = client.readQuery({
                      query: LIST_TEAM,
                      variables: {
                        filter: { ...filter },
                      },
                    });
                    const cat = deleteMemberState(
                      JSON.parse(JSON.stringify(cache.teamMembers.hits)),
                      member.id
                    );
                    const timer = setTimeout(() => {
                      client.writeQuery({
                        query: LIST_TEAM,
                        variables: {
                          filter: { ...filter },
                        },
                        data: {
                          ...cache,
                          teamMembers: {
                            ...cache.teamMembers,
                            hits: cat,
                          },
                        },
                      });
                    }, 500);
                    return () => {
                      clearTimeout(timer);
                    };
                  } catch (_) {}
                }
              })
              .catch(() => {});
            toggleActive();
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure to delete this member?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Container>
  );
};
