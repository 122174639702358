import React, { useState, useEffect, useContext, useCallback } from "react";
import { DataTable, Link, Badge, Toast } from "@shopify/polaris";
import styled from "styled-components";

import { AppContext } from "../../../context";
import { USER_ROLE } from "../../../variable";
import { ExportTemplateActionsPolaris } from "./ExportTemplateActionsPolaris";

const Container = styled.div`
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
  .author-wrap {
    width: calc(35rem - 3.2rem);
    white-space: normal;
    word-break: break-all;
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
  }
`;
export const ExportTemplateDataTablePolaris = ({ data, refetch }) => {
  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const { currentUser } = useContext(AppContext);

  useEffect(() => {
    let newRows =
      data?.exportTemplates.length > 0
        ? data?.exportTemplates.map((item) => {
            // Supplier
            const suppliers = item?.suppliers;
            const supplierMarkup =
              suppliers?.length > 0
                ? suppliers.map((s, index) => (
                    <Badge
                      key={index}
                      children={`${s.firstName} ${s.lastName}`}
                    />
                  ))
                : null;

            // Actions
            let authorId = item?.author?.id;
            let currentUserId = currentUser?.id;
            let currentUserRoles = currentUser?.roles;

            let hasActionsRemove = false;
            if (
              (currentUserId && currentUserId === authorId) ||
              ["Administrator", USER_ROLE.Supporter].includes(
                currentUserRoles[0]
              )
            ) {
              hasActionsRemove = true;
            }

            return [
              <div className="file-name-wrap">
                <Link
                  url={`/admin/manager-files/edit-template/${item.id}`}
                  children={item?.name}
                />
              </div>,
              <div className="author-wrap">{supplierMarkup}</div>,
              <div className="actions-wrap">
                <ExportTemplateActionsPolaris
                  hasActionsRemove={hasActionsRemove}
                  item={item}
                  refetch={refetch}
                  toggleActive={toggleActive}
                  onNotificationChange={({ message, isError }) =>
                    setNotification(() => ({ message, isError }))
                  }
                />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentUser]);

  // Handle actions
  const toggleActive = useCallback(
    () => setActiveToast((prev) => !prev),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const colType = [];
  for (let i = 0; i < 2; i++) {
    colType.push("text");
  }
  colType.push("numeric");

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={colType}
        headings={["Template name", "Supplier", "Action"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
      />
    </Container>
  );
};
