import React from "react";
import { Heading, Stack, TextField } from "@shopify/polaris";
import { ComponentLabelPolaris } from "../../../shared/ComponentLabelPolaris";

import { CountrySelectPolaris } from "../../actions/CountrySelectPolaris";

export function ShippingAddress({ onChange, errors, value }) {
  // State
  const [fields, setFields] = React.useState({
    firstName: null,
    lastName: null,
    company: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    country: "US",
    state: null,
    postalCode: null,
    email: null,
  });
  const typingTimeoutRef = React.useRef(null);

  // Get data
  React.useCallback(() => {
    if (value != null) {
      setFields((prev) => ({ ...prev, ...value }));
    }
  }, [value]);

  // Variables
  const shippingFields = [
    [
      {
        id: "email",
        label: "Email",
        placeholder: "Enter email",
      },
      {
        id: "phone",
        label: "Phone",
        placeholder: "Enter phone",
      },
    ],
    [
      {
        id: "country",
        label: "Country",
        required: true,
        placeholder: "Enter country",
      },
    ],
    [
      {
        id: "firstName",
        label: "First name",
        required: true,
        placeholder: "Enter first name",
      },
      {
        id: "lastName",
        label: "Last name",
        required: true,
        placeholder: "Enter last name",
      },
    ],
    [
      {
        id: "company",
        label: "Company",
        placeholder: "Enter company",
      },
    ],
    [
      {
        id: "address1",
        label: "Address 1",
        required: true,
        multiline: 2,
        placeholder: "Enter address 1",
      },
      {
        id: "address2",
        label: "Address 2",
        multiline: 2,
        placeholder: "Enter address 2",
      },
    ],
    [
      {
        id: "city",
        label: "City",
        required: true,
        placeholder: "Enter city",
      },

      {
        id: "state",
        label: "State",
        required: fields.country === "US",
        placeholder: "Enter state",
      },
      {
        id: "postalCode",
        label: "Postal Code",
        required: true,
        placeholder: "Enter postal code",
      },
    ],
  ];

  // Handle actions
  const handleFieldChange = React.useCallback(
    (value, id) => {
      setFields((prev) => ({ ...prev, [id]: value }));
      typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        if (onChange) {
          onChange(value, id);
        }
      }, 10);
    },
    [onChange],
  );

  const renderField = React.useCallback(
    ({ id, placeholder, type = "text", multiline = null }) => (
      <TextField
        id={id}
        placeholder={placeholder}
        type={type}
        multiline={multiline}
        error={errors[id]}
        value={fields[id]}
        onChange={handleFieldChange}
      />
    ),
    [errors, fields, handleFieldChange],
  );

  return (
    <React.Fragment>
      {shippingFields.map((group, index) => {
        if (Array.isArray(group) && group.length > 0) {
          return (
            <Stack key={`group-${index}`} distribution="fillEvenly">
              {group.map(
                ({ required = false, label, id, placeholder, multiline }) => {
                  return (
                    <div className="field-wrap" key={id}>
                      <ComponentLabelPolaris
                        label={label}
                        required={required}
                      />
                      {id === "country" ? (
                        <CountrySelectPolaris
                          error={errors[id]}
                          value={fields[id]}
                          onChange={(value) => handleFieldChange(value, id)}
                        />
                      ) : (
                        renderField({ id, placeholder, multiline })
                      )}
                    </div>
                  );
                },
              )}
            </Stack>
          );
        }

        return null;
      })}
    </React.Fragment>
  );
}
