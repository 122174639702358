import React, { useState, useCallback, useEffect } from "react";
import { ChoiceList } from "@shopify/polaris";
import _ from "lodash";

const options = [
  { value: "all", label: "Export all variants of product" },
  { value: "first", label: "Export only first variant of a product" },
  { value: "smallest", label: "Export only the smallest price" },
  { value: "biggest", label: "Export only the biggest price" },
];

export const ExportModePolaris = ({ value, onChange }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(null === value ? ["all"] : [value]);
  }, [value]);

  const handleChange = useCallback((value) => {
    setSelected(value);
    if (onChange) {
      onChange(_.head(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      choices={options}
      title="Export mode"
      selected={selected}
      onChange={handleChange}
    />
  );
};
