import React, { useEffect, useState } from "react";
import { Spinner } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import { LIST_STORE } from "../../tools/NewPushProductsPolaris";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { StoresSelectPolaris } from "../../store/StoresSelectPolaris";
import { handleError } from "../../../helper";

export const FieldStore = ({ value, onChange }) => {
    // State
    const [stores, setStores] = useState([]);

    // Query
    const { data, loading, error } = useQuery(LIST_STORE);

    // Get data
    useEffect(() => {
        const newStores =
            data?.stores?.nodes?.length > 0
                ? data.stores.nodes.map((store) => {
                      const { id, title } = store;
                      return {
                          value: id,
                          label: title,
                      };
                  })
                : [];
        setStores(newStores);
    }, [data]);

    return (
        <>
            <ComponentLabelPolaris label="Has last sale at stores" />
            {loading ? (
                <Spinner size="small" />
            ) : error ? (
                <div>{handleError(error.toString())}</div>
            ) : (
                <StoresSelectPolaris
                    stores={stores}
                    value={value}
                    onChange={onChange}
                    labelHidden
                    allowMultiple
                />
            )}
        </>
    );
};
