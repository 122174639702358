import React, { useState, useEffect } from "react";
import { DataTable, Badge } from "@shopify/polaris";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";

import { USER_ROLE } from "../../../variable";
import { NumberToFixed } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .note-wrap {
    width: calc(20rem - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

const STATUS_BADGE = {
  Pending: "info",
  Approved: "success",
  Cancelled: "critical",
};

export const DataTableTransactionsPolaris = ({
  data,
  role,
  filter,
  setFilter,
}) => {
  // State
  const [rows, setRows] = useState([]);
  const [columnContentTypes, setColumnContentTypes] = useState([]);
  const [headings, setHeadings] = useState([]);

  // Get data
  useEffect(() => {
    const isSeller = [USER_ROLE.Seller].includes(role);

    // Column type
    const colType = [];
    for (let i = 0; i < 8; i++) {
      colType.push("text");
    }

    // Heading
    const headings = [
      "ID",
      "Amount",
      "Type",
      "Note",
      ...(isSeller ? [USER_ROLE.Seller] : [USER_ROLE.Supplier]),
      "Status",
      "Created",
    ];

    setColumnContentTypes(colType);
    setHeadings(headings);

    let newRows =
      data?.transactions?.nodes?.length > 0
        ? data?.transactions.nodes.map((item) => {
            // journal
            const journal = item?.journal;
            const type = journal?.type;
            const note = journal?.comment;
            const status = journal?.status;

            let statusColor;
            for (let [key, value] of Object.entries(STATUS_BADGE)) {
              if (status === key) {
                statusColor = value;
              }
            }

            // User
            const user = isSeller ? item?.creator : item?.account;

            // Created
            const createdAt = moment(item?.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            // Mount
            let amount = NumberToFixed(item?.amount, 2);
            let amountMarkup =
              amount < 0 ? `-$${Math.abs(amount)}` : `$${amount}`;

            return [
              <div className="id-wrap">
                <span>{item?.id}</span>
              </div>,
              <div className="amount-wrap">
                <span>{amountMarkup}</span>
              </div>,
              <div className="transaction-wrap">
                <span>{type}</span>
              </div>,
              <div className="note-wrap">
                <span>{note}</span>
              </div>,
              <div className="user-wrap">
                <span>{`${user?.firstName} ${user?.lastName}`}</span>
              </div>,
              <div className="status-wrap">
                <Badge children={status} status={statusColor} />
              </div>,
              <div className="created-wrap">
                <span>{createdAt}</span>
              </div>,
            ];
          })
        : [];
    setRows(newRows);
  }, [data, role]);

  let { limit, offset } = filter;
  const total = get(data, "transactions.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
