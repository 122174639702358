import React from 'react';
import { AutoCompletePolaris } from '../../shared/AutoCompletePolaris';
import { COUNTRIES } from '../../../constants/countries';

export const CountrySelectPolaris = ({ value, onChange, error }) => {
    let countries = COUNTRIES;
    countries = countries.map((c) => ({
        value: c.code,
        label: c.name,
    }));

    return (
        <AutoCompletePolaris
            options={countries}
            value={value}
            onChange={onChange}
            error={error}
        />
    );
};
