import React, { Component } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button } from "antd";
import history from "../../history";
import _ from "lodash";
import ProductSelect from "./ProductSelect";
import AssigneeSelect from "./AssigneeSelect";

const { TextArea } = Input;

class FormTask extends Component {
  state = {
    assigneeId: null,
  };

  onSelectAssignee = (value) => {
    this.setState({ assigneeId: value });
  };
  render() {
    const { form, onClose, onSubmit, value } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props.form.validateFields((err, values) => {
            if (!err) {
              if (onSubmit) {
                onSubmit(values);
              }
            }
          });
        }}
      >
        <Form.Item label="Title">
          {getFieldDecorator("title", {
            initialValue: _.get(value, "title", null),
            rules: [{ required: true, message: "Please input title!" }],
          })(<Input placeholder="Title" />)}
        </Form.Item>
        <Form.Item label="Body">
          {getFieldDecorator("body", {
            initialValue: _.get(value, "body", null),
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="Assignee">
          {getFieldDecorator("assigneeId", {
            initialValue: _.get(value, "assignee.id", null),
          })(<AssigneeSelect />)}
        </Form.Item>
        {value ? (
          <Form.Item label="Product">
            {getFieldDecorator("productId", {
              initialValue: _.get(value, "product.id", null),
            })(<ProductSelect />)}
          </Form.Item>
        ) : null}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 5 }}>
            <SaveOutlined />
            Save
          </Button>
          <Button
            onClick={
              onClose
                ? onClose
                : () => {
                    history.push("/seller/tasks");
                  }
            }
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "create" })(FormTask);
