import React, { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  TextContainer,
  Toast,
} from "@shopify/polaris";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";
import { LIST_CRAWLER_QUERY } from "./ProductCrawlersPolaris";

const START_MUTATION = gql`
  mutation startCrawl($id: ID!) {
    startCrawl(id: $id)
  }
`;

const STOP_MUTATION = gql`
  mutation stopCrawl($id: ID!) {
    stopCrawl(id: $id)
  }
`;

const DELETE_MUTATION = gql`
  mutation deleteCrawler($id: ID!) {
    deleteCrawler(id: $id)
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 0px;
  margin-left: auto;
`;

export const ProductCrawlerActionsPolaris = ({ value, isCompleted }) => {
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [startCrawl, { loading, client }] = useMutation(START_MUTATION, {
    onError: () => {},
    onCompleted: () => {
      handleUpdateCrawler({ ...value, status: "Pending" });
    },
  });

  const [stopCrawl, { loading: loadingStopCrawl }] = useMutation(
    STOP_MUTATION,
    {
      onError: () => {},
      onCompleted: () => {
        handleUpdateCrawler({ ...value, status: "Stopped" });
      },
    }
  );

  const [
    deleteCrawler,
    { loading: loadingDeleteCrawl, error, data },
  ] = useMutation(DELETE_MUTATION, {
    onError: () => {
      toggleOpenModal();
    },
    onCompleted: () => {
      handleDeleteCrawl();
      toggleOpenModal();
    },
  });

  let filter = {
    limit: 20,
    offset: 0,
    search: null,
  };

  const handleUpdateCrawler = useCallback((record) => {
    try {
      const cache = client.readQuery({
        query: LIST_CRAWLER_QUERY,
        variables: { filter },
      });
      client.writeQuery({
        query: LIST_CRAWLER_QUERY,
        variables: { filter },
        data: {
          ...cache,
          productCrawlers: {
            ...cache.productCrawlers,
            nodes: cache.productCrawlers.nodes.map((node) => {
              if (node.id === record.id) {
                return {
                  ...node,
                  status: record.status,
                };
              }
              return node;
            }),
          },
        },
      });
    } catch (__) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteCrawl = useCallback(() => {
    try {
      const cache = client.readQuery({
        query: LIST_CRAWLER_QUERY,
        variables: { filter },
      });
      client.writeQuery({
        query: LIST_CRAWLER_QUERY,
        variables: { filter },
        data: {
          ...cache,
          productCrawlers: {
            ...cache.productCrawlers,
            total: cache.productCrawlers.total - 1,
            nodes: cache.productCrawlers.nodes.filter((p) => p.id !== value.id),
          },
        },
      });
    } catch (__) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartCrawl = useCallback(() => {
    startCrawl({
      variables: {
        id: value.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStopCrawl = useCallback(() => {
    stopCrawl({
      variables: {
        id: value.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(() => {
    deleteCrawler({
      variables: {
        id: value.id,
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOpenModal = useCallback(() => setOpen((open) => !open), []);
  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  let toastSuccess = data && data.deleteCrawler && "Add collection success";
  let newError = error ? handleError(error.toString()) : null;

  const toastMarkup = activeToast
    ? (newError || toastSuccess) && (
        <Toast
          content={newError ? newError : toastSuccess}
          error={newError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <ButtonGroup>
        <Button
          size="slim"
          primary
          onClick={handleStartCrawl}
          loading={loading}
          disabled={isCompleted || "Pending" === value.status}
        >
          Start
        </Button>
        <Button
          size="slim"
          disabled={isCompleted || "Stopped" === value.status}
          onClick={handleStopCrawl}
          loading={loadingStopCrawl}
        >
          Stop
        </Button>
        <Button size="slim" destructive onClick={toggleOpenModal}>
          Delete
        </Button>
      </ButtonGroup>
      <Modal
        title="Delete crawler"
        open={open}
        sectioned
        onClose={toggleOpenModal}
        secondaryActions={[{ content: "Cancel", onAction: toggleOpenModal }]}
        primaryAction={{
          content: "Delete",
          onAction: handleDelete,
          loading: loadingDeleteCrawl,
        }}
      >
        <TextContainer>Are you delete this crawler?</TextContainer>
      </Modal>
    </Container>
  );
};
