import React from "react";
import { DataTable, Button } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { useAppContext } from "../../../context";
import { getParamByRole } from "../../../helper";
import GroupDelete from "./GroupDelete";

const Container = styled.div`
    .stores-wrap {
        width: fit-content;
    }

    .Polaris-DataTable__Footer {
        overflow-x: scroll;
    }
    
    .item {
        white-space: normal;
        word-break: break-word;
    }
    
    .name-wrap {
        width: calc(18rem - 3.2rem);
    }

    .description-wrap {
        width: calc(20rem - 3.2rem);
    }

    .actions-wrap {
        display: flex;
        justify-content: end;
        column-gap: 10px;
        width: 10rem;
    }
`;

export const GroupsDataTablePolaris = ({ data, filter, setFilter, refetch }) => {
    // State
    const [rows, setRows] = React.useState([]);

    // Context
    const { currentUser } = useAppContext();
    const currentParam = getParamByRole(currentUser);

    React.useEffect(() => {
        let newRows =
            data?.groups?.nodes?.length > 0
                ? data.groups.nodes.map((group) => {
                    let { name, description, stores, id } = group;
                    // Stores
                    const storeMarkup =
                        stores?.length > 0
                            ? stores.map((store) => store.title).join(", ")
                            : null;

                    return [
                        <div className="name-wrap item">
                            <span>{name}</span>
                        </div>,
                        <div className="description-wrap item">
                            <span>{description}</span>
                        </div>,
                        <div className="stores-wrap item">
                            <span>{storeMarkup}</span>
                        </div>,
                        <div className="actions-wrap item">
                            <Button
                                children="Edit"
                                plain
                                url={`/${currentParam}/stores/groups/edit/${id}`}
                            />
                            <GroupDelete id={id} refetch={refetch} />
                        </div>,
                    ];
                })
                : [];
        setRows(newRows);
    }, [data, refetch]);

    // Variables
    let { limit, offset } = filter;
    const total = get(data, "groups.total", 0);
    const totalPage = Math.ceil(total / limit);
    const page = offset / limit + 1;
    const aggregation = {
        page,
        totalPage,
        offset,
        limit,
        onChange: setFilter,
        total,
    };

    return (
        <Container>
            <DataTable
                columnContentTypes={["text", "text", "text", "numeric"]}
                headings={["Name", "Description", "Stores", "Actions"]}
                rows={rows}
                verticalAlign="middle"
                hideScrollIndicator
                footerContent={
                    <PaginationPolaris aggregation={aggregation} showTotal />
                }
            />
        </Container>
    );
};
