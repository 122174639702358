import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import Exports from "../../components/supplier/Exports";
import { AppContext } from "../../context";
import { checkRole } from "../../helper";
import { USER_ROLE } from "../../variable";

export default class ExportsPage extends Component {
  static contextType = AppContext;
  render() {
    const { currentUser } = this.context;
    const { isSeller } = checkRole(currentUser);
    return (
      <div>
        <PageTitle
          title={isSeller ? "Export Order Files" : "Exports Tracking"}
        />
        <Exports role={USER_ROLE.Supplier} />
      </div>
    );
  }
}
