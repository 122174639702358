import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { ListClaimPolaris } from "../../components/order/claims/ListClaimPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const SellerClaimsPagePolaris = () => {
  return (
    <Container>
      <Page title={"Claims"} fullWidth>
        <ListClaimPolaris />
      </Page>
    </Container>
  );
};
