import React, { useCallback, useState, useEffect, useContext } from "react";
import { Page, Loading, Card, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { ProductFeedFormPolaris } from "./ProductFeedFormPolaris";
import { PRODUCT_FEEDS } from "./ProductFeedsPolaris";

import _ from "lodash";
import history from "../../../history";
import { handleError } from "../../../helper";
import { TEAM_ROLE } from "../../../variable";
import { AppContext } from "../../../context";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const LOAD = gql`
  query load($id: ID!) {
    productBasesForSeller(filter: { limit: 1000, offset: 0 }) {
      nodes {
        id
        title
      }
    }
    stores(filter: { limit: -1, offset: 0 }) {
      nodes {
        id
        title
      }
    }
    feed(id: $id) {
      id
      name
      limit
      sortType
      sortBy
      store {
        id
        title
      }
      storeTargetDataId
      collections {
        id
        name
      }
      productBases {
        id
        title
      }
      excludeCollections {
        id
        name
      }
      excludeTags {
        id
        name
      }
      excludeProducts {
        id
        title
      }
      filter
      mode
      productTitlePrefix
      productTitleSuffix
      descriptionPrefix
      descriptionSuffix
      variantTitle
      category
      gender
      ageGroup
      brand
      tax
      shipping
      condition
      stock
      identifierExists
      status
      excludeWords
      total
      xmlUrl
      csvUrl
      useUpc
      createdAt
      updatedAt
      excludeItems
      hasDesign
      hasSale
      alternativeDomain
      usingFixedColor
      fixedColor
      tags {
        id
        name
      }
      maxTitleLength
      maxContentLength

      modifyPrice
      modifyPriceValue
      promotionId
      modifyStateInTitle
      modifyStateInTitleByAcronym
      IsRegenEveryDay
      modifyVariantPrice {
        productBaseId
        productBaseVariantId
        price
      }
    }
  }
`;

const UPDATE_FEED_MUTATION = gql`
  mutation updateFeed($input: UpdateFeed!) {
    updateFeed(input: $input) {
      id
      name
      store {
        id
        title
      }
      collections {
        id
        name
      }
      productBases {
        id
        title
      }
      excludeCollections {
        id
        name
      }
      excludeTags {
        id
        name
      }
      excludeProducts {
        id
        title
      }
      filter
      mode
      productTitlePrefix
      productTitleSuffix
      variantTitle
      category
      gender
      ageGroup
      brand
      condition
      status
      excludeWords
      total
      createdAt
      updatedAt
      excludeItems
      useUpc
    }
  }
`;

export const EditProductFeedPolaris = (props) => {
  const { id } = props.match.params;
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const { data, loading, error } = useQuery(LOAD, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  const { currentUser } = useContext(AppContext);
  const role = _.get(currentUser, "teamUser.role", null);
  const isStoreManager = [TEAM_ROLE.StoreManager].includes(role);
  const urlStoreManager = isStoreManager ? "store-manager" : "seller";

  const [
    updateFeed,
    {
      data: dataMutation,
      loading: loadingMutation,
      error: errorMutation,
      client,
    },
  ] = useMutation(UPDATE_FEED_MUTATION, {
    onCompleted: (res) => {
      const id = setTimeout(() => {
        history.push(`/${urlStoreManager}/products/feeds`);
      }, 2500);
      setTimeoutId(id);

      let filter = {
        limit: 20,
        offset: 0,
        storeId: null,
        search: null,
      };
      try {
        const cache = client.readQuery({
          query: PRODUCT_FEEDS,
          variables: {
            filter,
          },
        });
        if (cache) {
          client.writeQuery({
            query: PRODUCT_FEEDS,
            variables: {
              filter,
            },
            data: {
              ...cache,
              feeds: {
                ...cache.feeds,
                nodes: cache.feeds.nodes.map((n) => {
                  if (n.id === id) {
                    const update = res.updateFeed;
                    return { ...n, ...update };
                  }
                  return n;
                }),
              },
            },
          });
        }

        const findCache = client.readQuery({
          query: LOAD,
          variables: { id },
        });
        if (findCache) {
          client.writeQuery({
            query: LOAD,
            variables: { id },
            data: {
              ...findCache,
              feed: {
                ...findCache.feed,
                ...res.updateFeed,
              },
            },
          });
        }
      } catch (_) {}
    },
    onError: () => {},
  });

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingMarkup = loading && <Loading />;

  const handleSubmit = useCallback((input) => {
    updateFeed({
      variables: {
        input: {
          id,
          ...input,
        },
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (errorMutation || (dataMutation && dataMutation.updateFeed)) && (
        <Toast
          content={
            errorMutation
              ? handleError(errorMutation.toString())
              : dataMutation &&
                dataMutation.updateFeed &&
                "Edit your feed success"
          }
          error={errorMutation}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {loadingMarkup}
      {toastMarkup}
      <Page
        title="Edit product feed"
        breadcrumbs={[
          {
            content: "Product feeds",
            url: `/${urlStoreManager}/products/feeds`,
          },
        ]}
      >
        <Card sectioned>
          <ProductFeedFormPolaris
            value={data && data.feed}
            btnLabel="Save feed"
            error={error}
            data={data}
            onSubmit={handleSubmit}
            loading={loadingMutation}
            urlStoreManager={urlStoreManager}
          />
        </Card>
      </Page>
    </Container>
  );
};
