import React from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

export default function useLayoutUpdateEffect(callback, deps) {
  const firstMountRef = React.useRef(true);

  useIsomorphicLayoutEffect(() => {
    if (!firstMountRef.current) {
      return callback();
    }
  }, [...deps]);

  // We tell React that first mount has passed
  useIsomorphicLayoutEffect(() => {
    firstMountRef.current = false;

    return () => {
      firstMountRef.current = true;
    };
  }, []);
}
