import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { MUTATION_PRODUCT_DIVISIONS } from "../../product/divisions/AddProductDivisionPolaris";
import { DivisionFormPolaris } from "./DivisionFormPolaris";

export const AddDivisionPolaris = ({
  open,
  toggleShowModal,
  toggleActive,
  onNotificationChange,
  onCompleted,
}) => {
  // Mutation
  const [createProductDivision, { loading }] = useMutation(
    MUTATION_PRODUCT_DIVISIONS,
    {
      onCompleted: (res) => {
        if (toggleShowModal) {
          toggleShowModal();
        }
        let newDivisions = [];
        if (res?.createProductDivision) {
          newDivisions.push({
            value: res.createProductDivision.id,
            label: res.createProductDivision.title,
          });
        }
        if (res && onCompleted) {
          onCompleted(newDivisions);
        }
        if (onNotificationChange) {
          onNotificationChange({
            message: "Add division successfully.",
            isError: false,
          });
        }
      },
      onError: (error) => {
        if (onNotificationChange) {
          onNotificationChange({
            message: handleError(error.toString()),
            isErorr: true,
          });
        }
      },
    }
  );

  // Handle actions
  const handleSubmit = useCallback((input) => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isErorr: false,
      });
    }
    if (input) {
      createProductDivision({
        variables: {
          input,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal title="Add Division" open={open} onClose={toggleShowModal} sectioned>
      <DivisionFormPolaris
        onSubmit={handleSubmit}
        toggleShowModal={toggleShowModal}
        loading={loading}
      />
    </Modal>
  );
};
