import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { USER_ROLE } from "../../../variable";
import { isEmpty } from "../../../helper";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

const FILTER_QUERY = gql`
  query listUserByRole($role: String!) {
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

export const ImportFilesFilterPolaris = ({ filter, onChange }) => {
  const selectedProp = filter?.supplierId;
  // State
  const [queryValue, setQueryValue] = useState(
    filter?.search?.length > 0 ? filter.search[0] : null
  );
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState({
    value: selectedProp,
    label: null,
    search: null,
  });
  const typingTimeoutRef = useRef(null);

  // Query
  const { data, loading, error } = useQuery(FILTER_QUERY, {
    variables: {
      role: USER_ROLE.Supplier,
    },
  });

  // Get data
  useLayoutEffect(() => {
    let newData = data?.listUserByRole || [];

    let newOptions =
      newData?.length > 0
        ? newData.map((i) => ({
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          }))
        : [];
    setOptions(newOptions);
    if (selectedProp) {
      const currentUser =
        newOptions?.length > 0
          ? newOptions.find((i) => i.value === selectedProp)
          : null;
      if (currentUser) {
        setUser((prev) => ({
          ...prev,
          value: currentUser.value,
          label: currentUser.label,
        }));
      }
    } else {
      setUser({
        value: null,
        label: null,
      });
    }
  }, [data, selectedProp]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          supplierId: user.value,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, queryValue]);

  // Handle actions
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );

  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleUserRemove = useCallback(() => {
    setUser(() => ({ value: null, label: null, search: null }));
  }, []);

  const handleFilterClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleUserRemove();
  }, [handleQueryValueRemove, handleUserRemove]);

  const filters = [
    {
      key: "supplier",
      label: "Supplier",
      filter: (
        <FilterNoSearchPolaris
          data={options}
          value={user}
          loading={loading}
          error={error}
          onChange={({ value, label }) => {
            setUser((prev) => ({ ...prev, value, label }));
          }}
          onChangeSearch={({ search }) =>
            setUser((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
  ];
  const appliedFilters = [];
  if (!isEmpty(user.label)) {
    const key = "supplier";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, user.label),
      onRemove: handleUserRemove,
    });
  }

  return (
    <Filters
      queryPlaceholder={`Filter item`}
      queryValue={queryValue}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFilterClearAll}
      filters={filters}
      appliedFilters={appliedFilters}
    />
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "supplier":
        return `Supplier: ${value}`;
      default:
        return value;
    }
  }
};
