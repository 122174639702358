import React from "react";
import { Filters } from "@shopify/polaris";

export function Filter({ filter, onChange }) {
  // State
  const [state, setState] = React.useReducer(
    (p, state) => ({ ...p, ...state }),
    {
      query: filter.search || "",
    }
  );

  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (onChange) {
      timeoutRef.current = setTimeout(() => {
        onChange({ search: state.query.trim() });
      }, 500);
    }
  }, [state.query, onChange]);

  // Action
  const handleQueryChange = React.useCallback((value) => {
    setState({ query: value });
  }, []);

  const handleQueryRemove = React.useCallback(() => {
    setState({ query: "" });
  }, []);

  const handleFilterClear = React.useCallback(() => {
    handleQueryRemove();
  }, [handleQueryRemove]);

  return (
    <Filters
      filters={[]}
      queryPlaceholder="Search team"
      queryValue={state.query}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFilterClear}
    />
  );
}
