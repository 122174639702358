import React, { useState, useCallback } from "react";
import {
  Form,
  FormLayout,
  TextField,
  Button,
  Link,
  Toast,
} from "@shopify/polaris";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError, isEmail } from "../helper";
import bgForgot from "../assets/images/photo-forgot-password.jpeg";

const PASSWORD = gql`
  mutation forgotPassword($mail: String!) {
    forgotPassword(mail: $mail)
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #edf2f7;
  align-items: center;
  .Polaris-Link + .Polaris-Link {
    margin-top: 1rem;
  }
  .Polaris-FormLayout {
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  .Polaris-FormLayout__Item {
    margin-top: 2.5rem;
  }
`;

export const ForgotPasswordPolaris = () => {
  const [email, setEmail] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const [forgotPassword, { loading, error, data }] = useMutation(PASSWORD, {
    onCompleted: () => {},
    onError: () => {},
  });

  const handleValueEmail = (value) => {
    if (value) {
      if (!isEmail(value)) {
        setErrorEmail("Email must be a valid email address.");
      } else {
        setErrorEmail(null);
      }
    } else {
      setErrorEmail("Email is required.");
    }
  };

  const hanldeEmailChange = useCallback((value) => {
    handleValueEmail(value);
    setEmail(value);
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback(() => {
    handleValueEmail(email);
    if (email && !errorEmail) {
      forgotPassword({
        variables: {
          mail: email,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, errorEmail]);

  const toastMarkup = activeToast
    ? (error || (data && data.forgotPassword)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data &&
                data.forgotPassword &&
                "A forgot password message has been sent to your email address."
          }
          error={error ? "Email not exist" : false}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      <div className="container mx-auto">
        <div className="flex justify-center px-8">
          <div className="container bg-white register-card w-full xl:w-3/4 lg:w-11/12 flex rounded-l-lg rounded-r-lg">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
              style={{ backgroundImage: `url(${bgForgot})` }}
            />
            <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <div className="px-8 mb-4 text-center">
                    <h3
                      className="pt-4 mb-2 font-medium"
                      style={{ fontSize: "2.25rem" }}
                    >
                      Forgot Your Password?
                    </h3>
                    <p className="mb-4 text-gray-700">
                      We get it, stuff happens. Just enter your email address
                      below and we'll send you a link to reset your password!
                    </p>
                  </div>
                  <TextField
                    value={email}
                    onChange={hanldeEmailChange}
                    label="Email(*)"
                    type="email"
                    placeholder="Email"
                    error={errorEmail}
                  />
                  <Button fullWidth primary submit loading={loading}>
                    Reset Password
                  </Button>
                  <hr className="mb-6 border-t" />
                  <div className="flex flex-col items-center">
                    <Link url="/register">Create an Account!</Link>
                    <Link url="/login">Already have an account? Login!</Link>
                  </div>
                </FormLayout>
              </Form>
            </div>
          </div>
          {toastMarkup}
        </div>
      </div>
    </Container>
  );
};
