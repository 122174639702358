import React, { createContext, useContext, useMemo, useState } from "react";
import { Toast } from "@shopify/polaris";
import useToggle from "../../hooks/useToggle";

const ToastContext = createContext({
  toggleToast: (val) => {},
  setNotify: ({ msg, err}) => {},
});
ToastContext.displayName = "ToastContext";

export function ToastContextProvider({ children, ...props }) {
  const [toastActive, toggleToast] = useToggle(false);
  const [notify, setNotify] = useState({ msg: null, err: false });

  // Markup
  const toastMarkup = toastActive && notify.msg && (
    <Toast
      content={notify.msg}
      error={notify.err}
      duration={5000}
      onDismiss={toggleToast}
    />
  );

  const bag = useMemo(
    () => ({
      toggleToast,
      setNotify,
    }),
    [toggleToast, setNotify]
  );

  return (
    <ToastContext.Provider
      value={{
        ...bag,
        ...props,
      }}
    >
      {toastMarkup}
      {children}
    </ToastContext.Provider>
  );
}

export function useToastContext() {
  return useContext(ToastContext);
}
