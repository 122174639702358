import { Badge, DataTable, Tooltip } from "@shopify/polaris";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { ColActionsPolaris } from "./ColActionsPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
`;

export const TableTagsPolaris = ({ data, filter, setFilter }) => {
  // let newRows = [];
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const nodes = data?.tags?.nodes || [];
    const rows = nodes.map((t) => {
      const countMarkup = (
        <Tooltip content="Count of product used">
          <Badge status="info">{t?.count || 0}</Badge>
        </Tooltip>
      );

      return [
        <span>{t?.name}</span>,
        countMarkup,
        <ColActionsPolaris tag={t} filter={filter} />,
      ];
    });

    setRows(rows);
  }, [data, filter]);

  let { limit, offset } = filter;
  const total = _.get(data, "tags.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregations = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  React.useEffect(() => {
    function handler(e) {
      if (e?.keyCode === 27) {
        e.stopImmediatePropagation && e.stopImmediatePropagation();
      }
    }

    document.addEventListener("keyup", handler);
    return () => {
      document.removeEventListener("keyup", handler, false);
    };
  }, []);

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "numeric"]}
        headings={["Name", "Count", "Actions"]}
        rows={rows}
        verticalAlign={"middle"}
        footerContent={
          <PaginationPolaris aggregation={aggregations} showTotal />
        }
      />
    </Container>
  );
};
