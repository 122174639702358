import React, { useCallback } from "react";
import { Button } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { LOAD_QUERY } from "./ProductCrawlerPolaris";

const RETRY_MUTATION = gql`
  mutation crawlRetry($id: ID!) {
    crawlRetry(id: $id) {
      id
      url
      title
      titleXpath
      imageXpath
      imageUrl
      productBaseSlug
      type
      status
    }
  }
`;

export const CrawlerActionsPolaris = ({ itemId, crawlId, filter }) => {
  const [crawlRetry, { loading, client }] = useMutation(RETRY_MUTATION, {
    onError: () => {},
    onCompleted: (res) => {
      handleRetryCompleted(res.data.crawlRetry);
    },
  });

  const handleRetry = useCallback((id) => {
    crawlRetry({
      variables: {
        id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRetryCompleted = useCallback((newData) => {
    try {
      const cache = client.readQuery({
        query: LOAD_QUERY,
        variables: {
          crawlId,
          ...filter,
        },
      });

      client.readQuery({
        query: LOAD_QUERY,
        variables: { crawlId, ...filter },
        data: {
          ...cache,
          crawlItems: {
            ...cache.crawlItems,
            nodes: cache.crawlItems.nodes.map((item) => {
              if (item.id === newData.id) {
                return {
                  ...item,
                  newData,
                };
              }
              return item;
            }),
          },
        },
      });
    } catch (__) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button primary loading={loading} onClick={() => handleRetry(itemId)}>
      Retry
    </Button>
  );
};
