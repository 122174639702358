import React, { useState, useCallback } from "react";
import { Thumbnail } from "@shopify/polaris";
import styled from "styled-components";

import { ViewModalImage } from "../product/ViewModalImage";

const Container = styled.div`
  .Polaris-Thumbnail {
    width: 20rem;
    margin: 1rem 0;
    .Polaris-Thumbnail__Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    :after {
      box-shadow: none;
    }
  }
`;

export const ProductAssortmentImagePolaris = (props) => {
  const { product, isSpecial } = props;
  const [etsyReverseImages, setEtsyReverseImages] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);

  if (product && product.source && "etsy" === product.source.toLowerCase()) {
    if (
      Array.isArray(product.images) &&
      product.images.length > 2 &&
      !etsyReverseImages
    ) {
      let arrayLength = product.images.length;
      let lastItem = product.images[arrayLength - 1];
      product.images.pop();
      product.images.unshift(lastItem);
      setEtsyReverseImages(() => true);
    }
  }

  let images = [];
  let mainFileId = null;
  if (product.mainImage) {
    images = [{ file: product.mainImage }];
    mainFileId = product.mainImage.id;
  }
  if (product.images) {
    images = [
      ...images,
      ...product.images.filter((f) =>
        f.file ? f.file.id !== mainFileId : f.id !== mainFileId
      ),
    ];
  }

  let imageSrc = "";
  if (isSpecial) {
    if (images[0] && images[0].file) {
      let file = images[0].file;
      imageSrc = file.thumbnailUrl ? file.thumbnailUrl : file.url;
    }
  } else if (images[0] && images[0].file && images[0].file.thumbnailUrl) {
    imageSrc = images[0].file.thumbnailUrl;
  } else if (images[0] && images[0].file && images[0].file.url) {
    imageSrc = images[0].file.url;
  }

  let item = {
    images,
  };

  return (
    <Container>
      <div onClick={toggleShowModal} style={{ cursor: "pointer" }}>
        <Thumbnail size="large" alt="" source={imageSrc} />
      </div>
      <ViewModalImage
        open={open}
        toggleShowModal={toggleShowModal}
        item={item}
      />
    </Container>
  );
};
