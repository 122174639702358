import { Query } from "@apollo/react-components";
import { notification, Skeleton } from "antd";
import get from "lodash/get";
import React, { Component } from "react";
import { CUSTOM_FULFILLMENT } from "../../../constants";
import { handleError } from "../../../helper";
import history from "../../../history";
import { LIST_PRODUCT_BASE } from "../../admin/ListProductBase";
import PageTitle from "../../shared/PageTitle";
import { CREATE_PRDUCT_BASE, GET_FULFILLMENTS } from "./graphqls";
import ProductBaseFormV2 from "./ProductBaseFormV2";

export class AddProductBase extends Component {
  state = { loading: false };

  handleSetCache = (obj) => {
    try {
      const { __apolloClient__: client } = window;
      const variables = {
        filter: {
          limit: 50,
          offset: 0,
        },
      };
      const cache = client.readQuery({
        query: LIST_PRODUCT_BASE,
        variables,
      });

      client.writeQuery({
        query: LIST_PRODUCT_BASE,
        variables,
        data: {
          ...cache,
          productBases: {
            ...cache.productBases,
            total: cache.productBases.total + 1,
            nodes: [obj, ...cache.productBases.nodes],
          },
        },
      });
    } catch (err) {}
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  render() {
    let { handleBtnSave, clickSave } = this.props;
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <PageTitle
            title={"Add Product base"}
            subTitle={"Product bases"}
            link="/seller/product-bases"
          />
        </div>
        <Query
          query={GET_FULFILLMENTS}
          fetchPolicy="network-only"
          variables={{
            filter: { limit: 200, offset: 0, role: "Supplier", status: true },
          }}
        >
          {({ error, loading, data, client }) => {
            if (error) return <div>{handleError(error.toString())}</div>;
            if (loading)
              return (
                <div className="p-4">
                  <Skeleton active />
                </div>
              );

            const teamMembers = get(data, "teamMembers.hits", []).map(
              ({ user }) => user,
            );
            const ffIds = (data?.fulfillmentConfigs || []).map(
              ({ fulfillmentId }) => fulfillmentId,
            );
            const fulfillments = (data?.fulfillments || []).filter(
              ({ id, slug }) =>
                ffIds.includes(id) || slug === CUSTOM_FULFILLMENT,
            );

            return (
              <ProductBaseFormV2
                // <ProductBaseForm
                onSubmit={(values) => {
                  if (values.designPositions && values.designPositions.length) {
                    values.designPositions = values.designPositions.map(
                      (dp) => {
                        if (dp.image && dp.image.id) {
                          dp.image = dp.image.id;
                        }
                        return dp;
                      },
                    );
                  }
                  this.setState({ loading: true }, () => {
                    client
                      .mutate({
                        mutation: CREATE_PRDUCT_BASE,
                        variables: {
                          input: { ...values, categories: [values.categories] },
                        },
                      })
                      .then((res) => {
                        this.setState({
                          loading: false,
                        });
                        notification.success({
                          message: "Product base has been saved!",
                        });
                        this.handleSetCache(client, res.data.createProductBase);
                        history.push(`/seller/product-bases`);
                      })
                      .catch((e) => {
                        this.setState({ loading: false });
                        notification.error({
                          message: handleError(e.toString()),
                        });
                      });
                  });
                }}
                fulfillments={fulfillments}
                loading={this.state.loading}
                categories={data.productBaseCategories}
                suppliers={data.suppliers}
                carriers={data.carriers}
                teamMembers={teamMembers}
                clickSave={clickSave}
                handleBtnSave={(value, aggregations) => {
                  aggregations = {
                    ...aggregations,
                    link: "/seller/product-bases",
                  };
                  if (handleBtnSave) {
                    handleBtnSave(value, aggregations);
                  }
                }}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}
