import React, { Component } from "react";
import { ListStorePolaris } from "../../components/store/ListStorePolaris";
import { getPathFromRouter } from "../../helper";

class Stores extends Component {
    render() {
        const path = getPathFromRouter(this.props != null ? [this.props] : []);
        return <ListStorePolaris path={path} />;
    }
}

export default Stores;
