import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

import { isEmpty } from "../../helper";
import { TEAM_ROLE, USER_ROLE } from "../../variable";

import { FilterPlatformPolaris } from "./FilterPlatformPolaris";
import { FilterStatusPolaris } from "./FilterStatusPolaris";
// import { FilterSellerPolaris } from "./FilterSellerPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";

const ROLES = gql`
  query listUserTeamByRole($role: String!) {
    listUserTeamByRole(role: $role) {
      id
      user {
        id
        lastName
        firstName
      }
    }
  }
`;

const PLATFORM_OPTIONS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "woocommerce",
    label: "Woocommerce",
  },
  {
    value: "shopify",
    label: "Shopify",
  },
  {
    value: "shopbase",
    label: "Shopbase",
  },
];

const STATUS_OPTIONS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "Connected",
    label: "Connected",
  },
  {
    value: "Checkpointed",
    label: "Checkpointed",
  },
  {
    value: "Suspended",
    label: "Suspended",
  },
];

export const StoresFilterPolaris = ({ onChange, filter, isStoreManager, isOnlineStore, isMarketPlaceM }) => {
  const [queryValue, setQueryValue] = useState(filter.search);
  const [platform, setPlatform] = useState({
    value: filter.platform,
    label: null,
  });
  const [seller, setSeller] = useState({
    value: filter.seller,
    label: null,
    search: null,
  });
  const [dataSeller, setDataSeller] = useState([]);
  const [status, setStatus] = useState(filter.status);
  const typingTimeoutRef = useRef(null);

  const [getSellers, { data, loading: loadingData, error }] = useLazyQuery(
    ROLES
  );

  useEffect(() => {
    if (isOnlineStore || isMarketPlaceM) {
      const role = isMarketPlaceM ? TEAM_ROLE.StoreManager : USER_ROLE.Admin;
      getSellers({
        variables: {
          role,
        },
      });
    }
  }, [isOnlineStore, isMarketPlaceM, getSellers]);

  useEffect(() => {
    let newData =
      data && data.listUserTeamByRole && data.listUserTeamByRole.length > 0
        ? data.listUserTeamByRole.map(({ user }) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }))
        : [];
    setDataSeller(newData);
  }, [data]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          platform: platform.value,
          seller: seller.value,
          status,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, platform, status, seller]);

  useEffect(() => {
    if (filter.platform) {
      let currentP = PLATFORM_OPTIONS.find((i) => i.value === filter.platform);
      if (currentP) {
        setPlatform(() => ({
          value: currentP.value,
          label: currentP.label,
        }));
      }
    }
    if (filter.status) {
      let currentS = STATUS_OPTIONS.find((i) => i.value === filter.status);
      if (currentS) {
        setStatus(() => currentS.value);
      }
    }
    if (filter.seller) {
      let currentSeller = dataSeller.find((i) => i.value === filter.seller);
      if (currentSeller) {
        setSeller((prev) => ({
          ...prev,
          value: currentSeller.value,
          label: currentSeller.label,
        }));
      }
    }
  }, [filter, dataSeller]);

  const handleQueryChange = useCallback((value) => setQueryValue(value), []);

  const handleQueryRemove = useCallback(() => setQueryValue(null), []);
  const handlePlatformRemove = useCallback(
    () => setPlatform({ value: null, label: null }),
    []
  );
  const handleSellerRemove = useCallback(
    () => setSeller({ value: null, label: null }),
    []
  );
  const handleStatusRemove = useCallback(() => setStatus(null), []);

  const handleFiltersClearAll = useCallback(() => {
    handleQueryRemove();
    handlePlatformRemove();
    handleSellerRemove();
    handleStatusRemove();
  }, [
    handleQueryRemove,
    handlePlatformRemove,
    handleSellerRemove,
    handleStatusRemove,
  ]);

  const filters = [
    ...(isOnlineStore ? [{
      key: "platform",
      label: "Platform",
      filter: (
        <FilterPlatformPolaris
          platform={platform}
          options={PLATFORM_OPTIONS}
          onChange={(value, label) => {
            setPlatform((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },] : []),
    ...(((!isStoreManager && isOnlineStore ) || isMarketPlaceM)
      ? [
          {
            key: "seller",
            label: isMarketPlaceM ? "Store Manager" : "Seller",
            filter: (
              <FilterNoSearchPolaris
                value={seller}
                data={dataSeller && dataSeller.length > 0 ? dataSeller : []}
                error={error}
                loading={loadingData}
                onChangeSearch={({ search }) =>
                  setSeller((prevState) => ({ ...prevState, search }))
                }
                onChange={({ value, label }) =>
                  setSeller((prevState) => ({ ...prevState, value, label }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "status",
      label: "Status",
      filter: (
        <FilterStatusPolaris
          status={status}
          options={STATUS_OPTIONS}
          onChange={(value) => {
            setStatus(value);
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(platform.label)) {
    const key = "platform";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, platform.label),
      onRemove: handlePlatformRemove,
    });
  }
  if (!isEmpty(seller.label)) {
    const key = "seller";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, seller.label),
      onRemove: handleSellerRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      queryValue={queryValue}
      queryPlaceholder={"Filter store"}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "platform":
        return `Platform: ${value}`;
      case "seller":
        return `Seller: ${value}`;
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
