import { gql } from "apollo-boost";

export const TeamItemStatisticsFragment = gql`
  fragment TeamItemStatisticsFragment on TeamItemStatistics {
    totalOrder
    totalProduct
    totalStore
    totalMappingOrder
    totalMember
    rootEmail
    team {
      id
      name
      createdAt
    }
  }
`