import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Loading, Page } from "@shopify/polaris";
import { useHistory, useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { PRODUCT_USING_GEN2D_MOCKUPS } from "../../../../graphql/queries";
import {
    ToastContextProvider,
    useToastContext,
} from "../../../shared/ToastContext";
import { CreateProductProvider, useCreateProduct } from "../Create/context";
import { Form } from "../Create/Form";
import { ADD_PRODUCT_MUTATION } from "../Create/";
import { getParamByRole, handleError } from "../../../../helper";
import { UPDATE_PRODUCT_GEN2D_MOCKUP } from "../../../../graphql/mutations";
import { AppContext } from "../../../../context";

export function Edit() {
    const { id } = useParams();
    return <EditComp id={id} />;
}

export function EditComp({ id, title = "Update Product", isClone = false }) {
    // Query
    const { data, loading } = useQuery(PRODUCT_USING_GEN2D_MOCKUPS, {
        variables: {
            id,
        },
        fetchPolicy: "no-cache",
        skip: !id,
    });

    const loadingMarkup = loading ? <Loading /> : null;
    return (
        <CreateProductProvider>
            <ToastContextProvider>
                {loadingMarkup}
                <EditWrapper
                    value={data?.productByID}
                    isClone={isClone}
                    title={title}
                    id={id}
                />
            </ToastContextProvider>
        </CreateProductProvider>
    );
}

function EditWrapper({ value, isClone, title, id }) {
    // Context
    const { updateField, updateState } = useCreateProduct();
    const { toggleToast, setNotify } = useToastContext();
    const history = useHistory();
    const { currentUser } = useContext(AppContext);
    let currentParam = getParamByRole(currentUser);

    // Ref
    const timeRef = useRef(null);
    const MUTATION = isClone
        ? ADD_PRODUCT_MUTATION
        : UPDATE_PRODUCT_GEN2D_MOCKUP;

    // Mutation
    const [createProduct, { loading }] = useMutation(MUTATION, {
        onCompleted: () => {
            setNotify({ msg: "Product has been saved.", err: false });

            timeRef.current && clearTimeout(timeRef.current);
            timeRef.current = setTimeout(() => {
                history.push(`/${currentParam}/product-from-print-file`);
            }, 1500);
        },
        onError: (error) => {
            setNotify({ msg: handleError(error.toString()), err: true });
        },
    });

    // Get data
    useEffect(() => {
        if (value != null) {
            const fields = {};
            if (!isClone) {
                fields.title = value.title;
                fields.shortTitle = value.shortTitle;
                fields.amzTitle = value.amzTitle;
            }
            fields.collections = value.collections || [];
            fields.tags = value.tags || [];
            fields.description = value.description;
            fields.personalized = value.personalized;
            fields.fields = value.fields || [];
            const mockup2DGenerate = value.mockup2DGenerate;
            const isReview = mockup2DGenerate?.isNeedReview;
            const productBaseOnlyUseDesignForGenMockup =
                mockup2DGenerate?.productBaseOnlyUseDesignForGenMockup;
            const randomMockupTemplate =
                mockup2DGenerate?.randomMockupTemplate || [];

            fields.using2dMockup = {
                isReview,
                productBaseOnlyUseDesignForGenMockup,
            };

            Object.entries(fields).forEach(([key, value]) => {
                updateField(value, key);
            });

            const variants = value.variants;
            const designPositions = value.designPositions;
            let selectedBases = (value.productBases || [])
                .filter(Boolean)
                .map((base) => {
                    const baseVariants =
                        base.variants?.length > 0
                            ? base.variants.map((val) => {
                                  const id = val.id;
                                  const mapVariants = variants.find(
                                      (item) => item.productBaseVariantId === id
                                  );
                                  if (mapVariants != null) {
                                      val.disabled = mapVariants.disabled;
                                      val.regularPrice =
                                          mapVariants.regularPrice;
                                      val.productBaseVariant = {
                                          attributes: val.attributes,
                                      };
                                      val.id = mapVariants.id;
                                      val.productBaseVariantId =
                                          mapVariants.productBaseVariantId;
                                  }
                                  return val;
                              })
                            : [];

                    if (base.designPositions?.length > 0 && !isClone) {
                        base.designPositions.forEach((dp) => {
                            const id = dp.id;
                            const mapDesignPosition = designPositions.find(
                                (item) => item?.designPosition?.id === id
                            );
                            if (mapDesignPosition != null) {
                                const file = mapDesignPosition.file;
                                dp.file = file ? [file] : [];
                            }
                            return dp;
                        });
                    }

                    const randomTemplateById = randomMockupTemplate.find(
                        (i) => i.productBaseId === base.id
                    );
                    return {
                        ...base,
                        useDesign: !!productBaseOnlyUseDesignForGenMockup?.includes(
                            base.id
                        ),
                        variants: baseVariants,
                        isRandomTemplate: !!randomTemplateById?.productBaseId,
                        templateQuantity: randomTemplateById?.qty
                            ? randomTemplateById.qty?.toString()
                            : "",
                    };
                });
            updateState({
                selectedBases,
                isEdit: true,
                canUpdateDescription: false,
            });
        }
    }, [value, isClone]);

    const URL_REDIRECT = `/${currentParam}/product-from-print-file`;
    // Handle action
    const handleSubmit = useCallback(
        (input) => {
            const newInput = { ...input };
            if (!isClone) {
                newInput.id = id;
            }
            if (newInput != null) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });

                createProduct({ variables: { input: newInput } });
            }
        },
        [createProduct, id, isClone, setNotify, toggleToast]
    );

    const handleCancel = useCallback(() => {
        history.push(URL_REDIRECT);
    }, [history, URL_REDIRECT]);

    return (
        <Wrapper>
            <Page
                title={title}
                fullWidth
                breadcrumbs={[
                    {
                        content: "Product From Print File",
                        url: URL_REDIRECT,
                    },
                ]}
            >
                <Form
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    loading={loading}
                    isEdit={!isClone}
                    isClone={isClone}
                />
            </Page>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;
