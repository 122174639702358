import React from "react";
import { ChoiceList } from "@shopify/polaris";
import dayjs from "dayjs";

const MONTHS = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const MAP_MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const MONTH_NAMES = Object.keys(MAP_MONTHS);

export default function PreDefinedMonth({ value, onChange }) {
  const [choiceValue, setChoiceValue] = React.useState([]);

  const handleChange = React.useCallback((selected) => {
    const m = MAP_MONTHS[selected[0]];
    onChange(
      {
        from: dayjs().month(m).startOf("month"),
        to: dayjs().month(m).endOf("month"),
      },
      selected[0],
    );

    setChoiceValue(selected);
  }, []);

  React.useEffect(() => {
    if (MONTH_NAMES.includes(value)) {
      setChoiceValue([value]);
    }
  }, [value]);

  const options = React.useMemo(() => {
    const currentM = dayjs().get("month");
    const opts = [];
    for (let i = 0; i <= currentM; i++) {
      opts.push(MONTHS[i]);
    }
    return opts;
  }, []);

  return (
    <ChoiceList
      title="Filter time"
      titleHidden
      choices={options}
      selected={choiceValue}
      onChange={handleChange}
    />
  );
}
