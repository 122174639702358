import React, { useState, useCallback } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Page,
  Card,
  DataTable,
  Pagination,
  Spinner,
  Modal,
  TextContainer,
  TextField,
  Button,
  Toast,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";

const UPCS_QUERY = gql`
  query upcs($filter: UpcFilter) {
    upcs(filter: $filter) {
      total
      nodes {
        id
        code
        used
        createdAt
      }
    }
  }
`;

const IMPORT_UPC_MUTATION = gql`
  mutation importUpc($codes: [String!]!) {
    importUpc(codes: $codes)
  }
`;

const Container = styled.div`
  position: relative;
  .Polaris-Page-Header {
    padding-top: 0;
  }
  .pager {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    align-items: center;
  }
  .spinner {
    margin-right: 20px;
  }
  .filter {
    padding: 15px;
  }
`;
const Upcs = () => {
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState(null);
  const toggleMessage = useCallback((msg) => setMessage(msg), []);

  const toastMarkup = message ? (
    <Toast
      content={message.message}
      error={message.type === "error"}
      onDismiss={toggleMessage}
    />
  ) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({
    limit: 100,
    offset: 0,
    search: null,
  });
  const [importUpc] = useMutation(IMPORT_UPC_MUTATION);
  const { loading, data, refetch } = useQuery(UPCS_QUERY, {
    variables: {
      filter,
    },
  });
  const rows = data
    ? data.upcs.nodes.map((n) => {
        return [
          n.code,
          n.used ? n.used : "No",
          moment(n.createdAt).format("LLL"),
        ];
      })
    : [];

  const totalPage = Math.ceil(_.get(data, "upcs.total", 0) / filter.limit);
  const page = filter.offset / filter.limit + 1;
  return (
    <Container>
      <Page
        title="Products UPCs"
        primaryAction={{
          content: "Import new UPCs",
          onAction: () => {
            setVisible(true);
          },
        }}
      >
        <Card>
          <div className={"filter"}>
            <TextField
              onChange={(text) => {
                setSearch(text);
                if (!text) {
                  setFilter((prevState) => {
                    return {
                      ...prevState,
                      offset: 0,
                      search: null,
                    };
                  });
                }
              }}
              value={search}
              suffix={
                <Button
                  onClick={() => {
                    setFilter((prevState) => {
                      return {
                        ...prevState,
                        search: search,
                      };
                    });
                  }}
                  plain
                  icon={SearchMinor}
                />
              }
            />
          </div>
          <DataTable
            columnContentTypes={["text", "text", "text"]}
            headings={["Code", "Used", "Created"]}
            rows={rows}
          />
          <div className="pager">
            {loading && (
              <div className="spinner">
                <Spinner
                  accessibilityLabel="Small spinner example"
                  size="small"
                  color="teal"
                />
              </div>
            )}
            <Pagination
              label={`${page}/${totalPage}`}
              hasPrevious={page > 1}
              onPrevious={() => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    offset: prevState.offset - prevState.limit,
                  };
                });
              }}
              hasNext={page < totalPage}
              onNext={() => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    offset: prevState.offset + prevState.limit,
                  };
                });
              }}
            />
          </div>
        </Card>
      </Page>
      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        title="Import new Upcs"
        primaryAction={{
          content: "Import",
          disabled: !value,
          loading: isLoading,
          onAction: () => {
            setIsLoading(true);
            const codes = value.split("\n").map((code) => code.trim());
            importUpc({
              variables: {
                codes,
              },
            })
              .then(() => {
                refetch();
                setIsLoading(false);
                setVisible(false);
                setValue("");
              })
              .catch((e) => {
                toggleMessage({
                  message: e.toString(),
                  type: "error",
                });
                setIsLoading(false);
              });
          },
        }}
      >
        <Modal.Section>
          <TextContainer>
            <TextField
              label="Codes"
              value={value}
              onChange={(text) => {
                setValue(text);
              }}
              multiline={20}
            />
            <p>Enter each upc code per line.</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Container>
  );
};

export default Upcs;
