import { Button, Modal } from "antd";
import React from "react";
import useToggle from "../../../hooks/useToggle";
import CSVReader from "react-csv-reader";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

export default function AddVariantsFromCSV({ onChange }) {
  const [open, toggleOpen] = useToggle(false);

  const handleForce = React.useCallback(
    (rows) => {
      if (!rows || rows.length === 0) return;
      const attrs = new Map();
      const vars = new Map();

      /**
     * {
      color: "ASH",
      product_title: "Youth T-shirt Gildan 5000B",
      size: "XS",
      sku: "G5000BUS_101",
      }
     */
      for (let r of rows) {
        vars.set(`${r.color}|${r.size}`, r);
      }

      const newVariants = [];
      const newVars = Array.from(vars.values());
      for (let i = 0; i < newVars.length; i++) {
        const r = newVars[i];
        const attributes = [
          { name: "Size", slug: "size", option: r.size },
          { name: "Color", slug: "color", option: r.color },
        ];
        newVariants.push({
          attributes,
          fulfillmentProductId: r.sku,
          regularPrice: 0,
          salePrice: 0,
          sellerPrice: 0,
          sorting: i,
        });

        for (let v of ["color", "size"]) {
          const attr = attrs.get(v);
          if (attr) {
            attrs.set(v, attr.add(r[v]));
          } else {
            attrs.set(v, new Set([r[v]]));
          }
        }
      }

      const newAttributes = [];
      for (let [slug, options] of Array.from(attrs.entries())) {
        const name = slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase();
        const opts = Array.from(options.values());

        newAttributes.push({ name, slug, options: opts });
      }
      onChange &&
        onChange({ variants: newVariants, attributes: newAttributes });
      toggleOpen(false);
    },
    [onChange],
  );

  return (
    <>
      <Button children="Add Variants by CSV" onClick={toggleOpen} />
      <Modal
        title="Choose file"
        visible={open}
        onCancel={toggleOpen}
        footer={false}
      >
        <CSVReader
          cssClass="csv-reader-input"
          label={
            <div style={{ display: "block" }}>
              <span>Choose file CSV</span>
            </div>
          }
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
          inputId="ObiWan"
          inputName="ObiWan"
        />
      </Modal>
    </>
  );
}
