import React, { useState, useCallback, useEffect } from "react";
import { Page, Loading, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import history from "../../../history";

import { CategoryFormPolaris } from "./CategoryFormPolaris";
import { getParamByUserRole, handleError } from "../../../helper";
import { useAppContext } from "../../../context";

const CATEGORY_BY_ID = gql`
  query categoryById($id: ID!) {
    categoryById(id: $id) {
      id
      name
      description
      image {
        id
        name
        url
        thumbnailUrl
        mimeType
      }
      parentId
      children {
        id
        name
        parentId
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateProductBaseCategory($input: UpdateProductBaseCategory!) {
    updateProductBaseCategory(input: $input) {
      id
      name
      description
      parentId
      image {
        id
        name
        url
        thumbnailUrl
      }
    }
  }
`;

export const EditCategoryPolaris = () => {
  const { id } = useParams();

  //State
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Context
  const { currentUser } = useAppContext();
  const param = getParamByUserRole(currentUser);

  // Queries
  const { data, loading } = useQuery(CATEGORY_BY_ID, {
    variables: {
      id,
    },
  });

  // Mutation
  const [updateCategory, { loading: loadingM }] = useMutation(UPDATE_CATEGORY, {
    onCompleted: () => {
      setNotification({
        message: "Update category successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        handleRedirect();
      }, 2100);
      setTimeoutId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const handleSubmit = useCallback(
    (input) => {
      setNotification({
        message: null,
        isError: false,
      });
      if (input) {
        updateCategory({
          variables: {
            input: {
              ...input,
              id,
            },
          },
        });
      }
      toggleActive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const handleRedirect = useCallback(
    () => history.push(`/${param}/categories/`),
    []
  );

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;
  const loadingMarkup = loading && <Loading />;

  return (
    <Page
      title="Edit Category"
      breadcrumbs={[{ content: "Categories", url: `/${param}/categories` }]}
    >
      {loadingMarkup}
      {toastMarkup}
      <CategoryFormPolaris
        value={data?.categoryById}
        onSubmit={handleSubmit}
        loading={loadingM}
        editCategory
        handleRedirect={handleRedirect}
      />
    </Page>
  );
};
