import { useMutation, useQuery } from "@apollo/react-hooks";
import { Checkbox, notification, Radio, Spin } from "antd";
import React from "react";
import { ENABLE_2FA } from "../../../graphql/mutations";
import { GET_ENABLE_2FA } from "../../../graphql/queries";
import { useLogout } from "../../../hooks/useLogout";

const KEEP_TIMES = [
  {
    value: 0,
    label: "None",
  },

  {
    value: 7,
    label: "7 days",
  },
  {
    value: 14,
    label: "14 days",
  },
];

const keepTimesStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: "1.5rem",
  gap: "0.5rem",
  paddingBottom: "0.5rem",
};

function Enable2FAImpl({ setLoading = () => {} }, ref) {
  const [checked, setChecked] = React.useState(false);
  const [keepLoginTime, setKeepLoginTime] = React.useState(0);
  const changed = React.useRef(false);
  const { logout } = useLogout();

  // Query
  const { data, loading, error } = useQuery(GET_ENABLE_2FA);
  const [update] = useMutation(ENABLE_2FA, {
    onCompleted: () => {
      notification.success({ message: "Update enabled 2FA success" });
    },
    onError: (err) => {
      notification.error({ message: err?.toString() });
    },
  });

  React.useEffect(() => {
    if (data?.getEnable2fa != null) {
      const { isEnable, keepLoginTime } = data.getEnable2fa;
      setChecked(isEnable);
      if (KEEP_TIMES.map(({ value }) => value).includes(keepLoginTime)) {
        setKeepLoginTime(keepLoginTime);
      }
    }
  }, [data]);

  React.useEffect(
    () => () => {
      changed.current = false;
    },
    [],
  );

  const handleCheckedChange = React.useCallback((event) => {
    const checked = event.target.checked;
    setChecked(checked);
    changed.current = true;
  }, []);

  const handleKeepLoginTimeChange = React.useCallback((event) => {
    const value = event.target.value;
    setKeepLoginTime(value);
    changed.current = true;
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (!changed.current) return;
    setLoading(true);
    await update({
      variables: {
        input: {
          isEnable: checked,
          keepLoginTime,
        },
      },
    }).finally(() => {
      setLoading(false);
    });

    if (checked) {
      await logout();
    }
  }, [checked, update, setLoading, logout, keepLoginTime]);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit,
  }));

  return loading ? (
    <Spin />
  ) : error ? (
    <div>Error: {error?.toString()}</div>
  ) : (
    <div>
      <Checkbox
        children="Enable 2FA"
        checked={checked}
        onChange={handleCheckedChange}
      />
      <div style={keepTimesStyle}>
        <label>Don't ask 2fa code in same browser</label>
        <Radio.Group
          options={KEEP_TIMES}
          value={keepLoginTime}
          onChange={handleKeepLoginTimeChange}
        />
      </div>
    </div>
  );
}

const Enable2FA = React.forwardRef(Enable2FAImpl);

export { Enable2FA };
