import React from "react";
import { AddProductBase } from "../../components/seller/components";
import { ToastContextProvider } from "../../components/shared/ToastContext";

export function AddProductBasePage() {
  return (
    <div className="p-4">
      <ToastContextProvider>
        <AddProductBase />
      </ToastContextProvider>
    </div>
  );
}
