import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
    DataTable,
    Page,
    Card,
    Modal,
    TextContainer,
    TextField,
    Button,
    // Select,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import QuickGuideApi from "./QuickGuideApi";
import { TEAM_ROLE, USER_ROLE } from "../../variable";

import { UserSelectPolaris } from "./UserSelectPolaris";

const Container = styled.div`
    position: relative;
    .Polaris-Page-Header {
        padding-top: 0;
    }
    .pager {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        align-items: center;
    }
    .spinner {
        margin-right: 20px;
    }
    .filter {
        padding: 15px;
    }
`;

const APIS_QUERY = gql`
    query apis($roles: [String!]!) {
        apis {
            id
            key
            description
            user {
                id
                firstName
                lastName
            }
        }
        users: listUserTeamByRoles(roles: $roles) {
            id
            role
            user {
                id
                lastName
                firstName
            }
        }
    }
`;

const CREATE_API_MUTATION = gql`
    mutation createApi($description: String, $userId: ID!) {
        createApi(description: $description, userId: $userId) {
            id
            key
            description
        }
    }
`;

const REVOKE_API_MUTATION = gql`
    mutation revokeApi($id: ID!) {
        revokeApi(id: $id)
    }
`;

const Apis = () => {
    const [visible, setVisible] = useState(false);
    const [quickGuideVisible, setQuickGuideVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const { data, refetch } = useQuery(APIS_QUERY, {
        variables: {
            roles: [
                USER_ROLE.Admin,
                USER_ROLE.Seller,
                USER_ROLE.StoreManager,
                TEAM_ROLE.Supporter,
                TEAM_ROLE.MarketplaceManager,
                TEAM_ROLE.Idea,
                TEAM_ROLE.IdeaLeader,
            ],
        },
    });
    const [, setMessage] = useState(null);
    const toggleMessage = useCallback((msg) => setMessage(msg), []);
    const [createApi] = useMutation(CREATE_API_MUTATION);
    const [deleteId, setDeleteId] = useState(null);
    const [revokeApi] = useMutation(REVOKE_API_MUTATION);

    const rows = data
        ? data.apis.map((r) => {
              return [
                  r.key,
                  r.user ? `${r.user.firstName} ${r.user.lastName}` : "",
                  r.description,
                  <div>
                      <Button
                          onClick={() => setDeleteId(r)}
                          size="slim"
                          destructive
                      >
                          Revoke
                      </Button>
                  </div>,
              ];
          })
        : [];

    return (
        <Container>
            <Page
                title="API Keys"
                primaryAction={{
                    content: "Create new API Key",
                    onAction: () => {
                        setVisible(true);
                    },
                }}
                secondaryActions={[
                    {
                        content: "Quick Guide",
                        onAction: () => {
                            setQuickGuideVisible(true);
                        },
                    },
                ]}
            >
                <Card>
                    <DataTable
                        columnContentTypes={["text", "text", "text", "text"]}
                        headings={["Venus API Key", "User", "Description", ""]}
                        rows={rows}
                    />
                </Card>
            </Page>
            <Modal
                open={visible}
                onClose={() => setVisible(false)}
                title="Create new Venus API Key"
                primaryAction={{
                    content: "Create",
                    disabled: !value || !selectedUser,
                    loading: isLoading,
                    onAction: () => {
                        setIsLoading(true);
                        createApi({
                            variables: {
                                description: value,
                                userId: selectedUser,
                            },
                        })
                            .then(() => {
                                refetch();
                                setIsLoading(false);
                                setVisible(false);
                                setValue("");
                            })
                            .catch((e) => {
                                toggleMessage({
                                    message: e.toString(),
                                    type: "error",
                                });
                                setIsLoading(false);
                            });
                    },
                }}
            >
                <Modal.Section>
                    <TextContainer>
                        {/* <Select
              value={selectedUser}
              onChange={(v) => {
                setSelectedUser(v);
              }}
              options={
                data
                  ? [
                      { label: "Please select user", value: "" },
                      ...data.users.map((row) => {
                        const user = row.user;
                        return {
                          label: `${user.firstName} ${user.lastName} - [${row.role}]`,
                          value: user.id,
                        };
                      }),
                    ]
                  : []
              }
            /> */}
                        <UserSelectPolaris
                            value={selectedUser}
                            data={data}
                            onChange={(v) => setSelectedUser(v)}
                        />

                        <TextField
                            label="Description"
                            value={value}
                            onChange={(text) => {
                                setValue(text);
                            }}
                            multiline={5}
                        />
                    </TextContainer>
                </Modal.Section>
            </Modal>
            {deleteId && (
                <Modal
                    open={true}
                    onClose={() => setDeleteId(null)}
                    title={"Revoke api confirmation"}
                    primaryAction={{
                        content: "Revoke",
                        loading: isLoading,
                        onAction: () => {
                            setIsLoading(true);
                            revokeApi({
                                variables: {
                                    id: deleteId.id,
                                },
                            })
                                .then((res) => {
                                    refetch();
                                    setIsLoading(false);
                                    setDeleteId(null);
                                })
                                .catch((e) => {
                                    setIsLoading(false);
                                    toggleMessage(e.toString());
                                });
                        },
                    }}
                >
                    <Modal.Section>
                        <TextContainer>
                            <p>
                                Are you sure want to revoke api: {deleteId.key}
                            </p>
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            )}
            <Modal
                open={quickGuideVisible}
                onClose={() => setQuickGuideVisible(false)}
                title="Quick guide"
                large={true}
            >
                <Modal.Section>
                    <QuickGuideApi />
                </Modal.Section>
            </Modal>
        </Container>
    );
};

export default Apis;
