import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { PASS_TASK_TO_NEW_STATUS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export const PassToAwaitingBuyerConfirm = (props) => {
  // Props
  const {
    open,
    toggleShowModal,
    refetch,
    toggleActive,
    onNotificationChange,
    task,
    toggleModalParent,
  } = props;

  // Ref
  const onCloseRef = useRef(null);

  // Context
  const { setFilter } = useToastContext();

  // Mutation
  const [passToAwaitingBuyerConfirm, { loading }] = useMutation(
    PASS_TASK_TO_NEW_STATUS,
    {
      onError: (error) => {
        if (onNotificationChange) {
          onNotificationChange({
            message: handleError(error.toString()),
            isError: true,
          });
        }
      },
      onCompleted: () => {
        toggleShowModal();
        if (onNotificationChange) {
          onNotificationChange({
            message: `Design has been passed to Awaiting Buyer Confirm.`,
            isError: false,
          });
        }
        if (toggleModalParent) {
          toggleModalParent();
        }

        // onClose parent modal
        onCloseRef.current && clearTimeout(onCloseRef.current);
        onCloseRef.current = setTimeout(() => {
          setFilter && setFilter((prev) => ({ ...prev }));
          refetch && refetch();
        }, 1000);
      },
    },
  );

  // Handle actions
  const handleSubmit = useCallback(() => {
    let id = task?.id;
    if (id) {
      onNotificationChange &&
        onNotificationChange({
          message: null,
          isError: false,
        });
      toggleActive && toggleActive();
      passToAwaitingBuyerConfirm({
        variables: {
          taskID: id,
          newStatus: "Awaiting Buyer Confirm",
        },
      });
    }
  }, [task, passToAwaitingBuyerConfirm, onNotificationChange, toggleActive]);

  return (
    <Modal
      title="Pass to Awaiting Buyer Confirm"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>
        Are you sure want to pass this design to Awaiting Buyer Confirm for
        order #{task.orderId}
      </p>
    </Modal>
  );
};
