import React from "react";
import { Select } from "@shopify/polaris";

const options = [
  { value: "false", label: "No" },
  { value: "true", label: "Yes" },
];

export const UseUpcPolaris = ({ value, onChange }) => {
  return (
    <Select
      options={options}
      label="Use Upc"
      value={value}
      onChange={onChange}
    />
  );
};
