import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, Tag } from "@shopify/polaris";
import styled from "styled-components";
import { isArray } from "lodash";

import { getUnique } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const TagContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
  > * {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

export const AutoCompleteMultiplePolaris = (props) => {
  const {
    data,
    value,
    onChange,
    label,
    labelHidden,
    placeholder,
    error,
    required,
    isRemoveOptionAll,
    defaultValue,
  } = props;
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [deselectedOptionsShow, setDeselectedOptionsShow] = useState([]);

  useEffect(() => {
    let newData =
      data.length > 0
        ? data.map((d) => ({ ...d, value: d.value, label: d.label }))
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [data]);

  useEffect(() => {
    setSelectedOptions(value);
    let couple =
      data.length > 0
        ? data.filter((d) => isArray(value) && value.includes(d.value))
        : [];
    setDeselectedOptionsShow((prev) => {
      let result = getUnique([...prev, ...couple], "value");
      if (isRemoveOptionAll) {
        if (result && result.length > 1) {
          result = result.filter((i) => i.value !== "*");
        }
      }
      if (!!defaultValue) {
        if (result && result.length > 1) {
          result = result.filter((i) => i.value !== defaultValue);
        }
      }
      return result;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectedChange = useCallback(
    (selected) => {
      if (!!defaultValue) {
        if (selected[0] === defaultValue || !selected.length) {
          selected = [defaultValue];
        } else {
          selected = selected.filter((i) => ![defaultValue].includes(i));
        }
      }

      setSelectedOptions(selected);
      let couple =
        data.length > 0 ? data.filter((d) => selected.includes(d.value)) : [];

      setDeselectedOptionsShow((prev) => {
        let result = getUnique([...prev, ...couple], "value");
        result = result.filter((i) => selected.includes(i.value));
        return result;
      });

      if (onChange) {
        onChange(selected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, data, defaultValue]
  );

  const handleRemove = useCallback(
    (value) => {
      let newSelected = selectedOptions.filter((s) => s !== value);
      if (!!defaultValue) {
        if (!newSelected.length) {
          newSelected = [defaultValue];
        }
      }
      setSelectedOptions(newSelected);
      let newDeselected = deselectedOptionsShow.filter(
        (item) => item.value !== value
      );
      setDeselectedOptionsShow(newDeselected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions, deselectedOptions, defaultValue]
  );

  const textField = (
    <Autocomplete.TextField
      label={label}
      labelHidden={true}
      placeholder={placeholder}
      value={inputValue}
      onChange={handleInputChange}
      error={error}
    />
  );

  return (
    <>
      {!labelHidden && label ? (
        <ComponentLabelPolaris label={label} required={required} />
      ) : null}
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={handleSelectedChange}
        textField={textField}
        // loading={loading}
        allowMultiple={true}
        emptyState={<span>No items found!</span>}
      />
      {deselectedOptionsShow && deselectedOptionsShow.length > 0 ? (
        <TagContainer>
          {deselectedOptionsShow.map((item) => (
            <Tag
              children={item.label}
              onRemove={() => handleRemove(item.value)}
              key={item.value}
            />
          ))}
        </TagContainer>
      ) : null}
    </>
  );
};
