import React, { useCallback, Fragment, useState, useEffect } from "react";
import {
  Card,
  Heading,
  Stack,
  Thumbnail,
  TextStyle,
  Button,
  Modal,
  Icon,
  Toast,
  Checkbox,
} from "@shopify/polaris";
import { RefreshMajorMonotone } from "@shopify/polaris-icons";

import useToggle from "../../hooks/useToggle";
// import mbLogo from "../../assets/images/favicon.png";
import mbLogo from "../../assets/images/mb_dark_favicon.png";
import PODLogo from "../../assets/images/POD.ico";
import { handleError } from "../../helper";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { PODApp } from "../../config";

const textAppContent =
  "Config personalized design with PersonalBridge app to generate design files automatically";

const textConfig =
  "Config your personalized orders with PersonalBridge app to generate design files automatically";

const AUTH_PERSONALIZED_POD_APP = gql`
  mutation authPersonalizedPODApp($reAuth: Boolean!) {
    authPersonalizedPODApp(reAuth: $reAuth)
  }
`;

const DISABLE_APP = gql`
  mutation disableApp($id: ID!) {
    disableApp(id: $id)
  }
`;

const CREATE_URL_TOKEN = gql`
  mutation createUrlToken {
    createUrlToken
  }
`;

export const GET_CONNECTED_APP = gql`
  query getConnectedApp {
    getConnectedApp {
      id
      teamId
      app
      token
    }
  }
`;

export const Apps = (props) => {
  const { status } = props;
  // State
  const [activeToast, setActiveToast] = useState(false);
  const [exitedAcc, setExitedAcc] = useState(false);
  const [openModal, toggleOpenModal] = useToggle(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  useEffect(() => {
    if (status !== null) {
      setActiveToast(!activeToast);
      setNotification({
        message: `Auth PersonalBridge app ${status ? "success" : "fail"}!`,
        isError: !status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // Query
  const { data, refetch } = useQuery(GET_CONNECTED_APP);

  // Mutation
  const [authPersonalizedPODApp, { loading }] = useMutation(
    AUTH_PERSONALIZED_POD_APP,
    {
      onCompleted: () => {
        setNotification({
          message: "Auth PersonalBridge app success",
          isError: false,
        });
        toggleOpenModal();
        refetch();
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          isError: true,
        });
      },
    }
  );

  const [disableApp, { loading: disableLoading }] = useMutation(DISABLE_APP, {
    onCompleted: () => {
      setNotification({
        message: "Disable PersonalBridge app success",
        isError: false,
      });
      refetch();
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  const [createUrlToken, { loading: createLoading }] = useMutation(
    CREATE_URL_TOKEN,
    {
      onCompleted: (res) => {
        window.open(`${PODApp}/?return_url=${res.createUrlToken}`);
      },
      onError: (error) => {
        setNotification({
          message: handleError(error.toString()),
          isError: true,
        });
      },
    }
  );

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  const PODAppConnected = data?.getConnectedApp?.find(
    (appItem) => appItem.app === "POD"
  );

  const handleAuth = () => {
    if (exitedAcc) {
      createUrlToken();
    } else {
      setNotification({
        message: null,
        isError: false,
      });
      authPersonalizedPODApp({
        variables: {
          reAuth: !!PODAppConnected,
        },
      });
    }
    toggleActive();
  };

  const handleDisableApp = () => {
    setNotification({
      message: null,
      isError: false,
    });
    disableApp({
      variables: {
        id: PODAppConnected.id,
      },
    });
    toggleActive();
  };

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const titleModalMarkup = (
    <TextStyle variation="strong">Connect to PersonalBridge</TextStyle>
  );

  return (
    <Fragment>
      {toastMarkup}
      <Card sectioned>
        <Stack>
          <Card>
            <Card.Section>
              <Stack vertical>
                <Stack alignment="center">
                  <Thumbnail source={PODLogo} alt="POD" />
                  <Heading children={"PersonalBridge"} element="h3" />
                </Stack>
                <TextStyle children={textAppContent} />
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack distribution="equalSpacing">
                <Button
                  onClick={handleDisableApp}
                  plain
                  children="Disable now"
                  destructive
                  disabled={!PODAppConnected}
                  loading={disableLoading || createLoading}
                />
                <Button
                  children={!PODAppConnected ? "Auth Now" : "Re auth"}
                  onClick={toggleOpenModal}
                />
              </Stack>
            </Card.Section>
          </Card>
          <Card />
        </Stack>
      </Card>
      <Modal
        open={openModal}
        onClose={toggleOpenModal}
        sectioned
        title={titleModalMarkup}
        size="Large"
      >
        <Stack vertical alignment="center" spacing="loose">
          <TextStyle children={textConfig} />
          <Stack alignment="center">
            <Thumbnail source={mbLogo} alt="Logo Merch Bridge" />
            <Icon source={RefreshMajorMonotone} />
            <Thumbnail source={PODLogo} alt="POD" />
          </Stack>
          <Checkbox
            label="Auth to PersonalBridge app with my exits account"
            checked={exitedAcc}
            onChange={(value) => setExitedAcc(value)}
          />
          <Button
            children={!PODAppConnected ? "Auth Now" : "Re auth"}
            primary
            onClick={handleAuth}
            loading={loading}
            fullWidth
          />
        </Stack>
      </Modal>
    </Fragment>
  );
};
