import React, { Component } from "react";
// import ListTag from "../../components/seller/ListTag";
import { ListTagPolaris } from "../../components/product/tags/ListTagPolaris";
import { getPathFromRouter } from "../../helper";

class Tags extends Component {
    render() {
        // return <ListTag />;
        const path = getPathFromRouter(this.props != null ? [this.props] : []);
        return <ListTagPolaris path={path} />;
    }
}

export default Tags;
