import { useMutation } from '@apollo/react-hooks';
import { Button, Modal } from '@shopify/polaris';
import { gql } from 'apollo-boost';
import React from 'react';
import useToggle from '../../../hooks/useToggle';
import { useToastContext } from '../../shared/ToastContext';

const MUTATION_DELETE_GROUP = gql`
    mutation deleteGroup($id: ID!) {
        deleteGroup(id: $id) 
    }
`;

export default function GroupDelete({ id, refetch }) {
  const { toggleToast, setNotify } = useToastContext();

  // State
  const [open, onClose] = useToggle(false);

  // Mutation
  let timeoutId = null;
  const [deleteGroup, { loading }] = useMutation(MUTATION_DELETE_GROUP, {
    onCompleted: () => {
      setNotify((p) => ({ ...p, msg: 'Delete group success', err: false }));
        refetch && refetch();
    },
    onError: (error) => {
      setNotify({ msg: error?.toString(), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    if (!id) return;
    toggleToast && toggleToast(true);
    setNotify && setNotify({ msg: null, err: false });

    deleteGroup({
      variables: {
        id
      }
    }).finally(() => {
      onClose()
    })

  }, [toggleToast, setNotify, deleteGroup, id]);

  return <>
    <Button children="Delete" destructive onClick={onClose} plain />
    <Modal
      open={open}
      onClose={onClose}
      title="Delete Group"
      sectioned
      primaryAction={{ content: "Delete", onAction: handleSubmit, destructive: true, loading: loading }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to delete this group?</p>
    </Modal>
  </>
}