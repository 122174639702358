import React, { Component } from "react";
import { notification, Skeleton } from "antd";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import { handleError } from "../../helper";
import ProductBaseForm from "../base/ProductBaseForm";
import history from "../../history";
import { LIST_PRODUCT_BASE } from "./ListProductBase";
import PageTitle from "../shared/PageTitle";
import { CUSTOM_FULFILLMENT } from "../../constants";

const MUTATION = gql`
  mutation createProductBase($input: NewProductBase!) {
    createProductBase(input: $input) {
      id
      title
      niceName
      slug
      baseCostDescription
      defaultContent
      defaultShortDescription
      details
      regularPrice
      salePrice
      sellerPrice
      hasVariant
      mockupTemplates {
        id
        productBaseID
        originTemplateID
        isDefault
      }
      fulfillment {
        fulfillmentId
        productId
      }
      images {
        id
        name
        url
        thumbnailUrl
      }
      suppliers {
        id
        firstName
        lastName
      }
      carriers {
        id
        code
        name
      }
      categories {
        id
        name
        description
      }
      supplierPricing {
        id
        userId
        price
      }
      carrierPricing {
        id
        carrierId
        price
      }
      attributes {
        name
        slug
        options
      }
      designPositions {
        id
        image {
          id
          thumbnailUrl
          url
        }
        name
        description
        productBaseVariants {
          id
          attributes {
            name
            slug
            option
          }
        }
      }
      variants {
        id
        fulfillmentProductId
        fulfillmentShippingCost
        attributes {
          name
          slug
          option
        }
        supplierPricing {
          id
          userId
          price
        }
        carrierPricing {
          id
          carrierId
          price
          default
        }
        regularPrice
        salePrice
        sellerPrice
        sorting
        status
      }
      status
      isDigitalProduct
    }
  }
`;
const QUERY = gql`
  fragment cat on Category {
    id
    name
    description
    image {
      id
      name
      thumbnailUrl
      url
    }
  }
  query load {
    fulfillments {
      id
      name
      slug
      products {
        title
        originId
        status
        attributes {
          name
          slug
          options
        }
        variants {
          regularPrice
          salePrice
          sellerPrice
          fulfillmentProductId
          sorting
          attributes {
            name
            slug
            option
          }
        }
      }
    }
    productBaseCategories {
      ...cat
      children {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
            }
          }
        }
      }
    }
    suppliers {
      id
      firstName
      lastName
    }
    carriers {
      id
      name
      code
    }
  }
`;

class AddProductBase extends Component {
  state = { loading: false };
  handleSetCache = (client, obj) => {
    try {
      const cache = client.readQuery({
        query: LIST_PRODUCT_BASE,
        variables: {
          filter: { limit: 50, offset: 0 },
        },
      });

      client.writeQuery({
        query: LIST_PRODUCT_BASE,
        variables: { filter: { limit: 50, offset: 0 } },
        data: {
          ...cache,
          productBases: {
            ...cache.productBases,
            total: cache.productBases.total + 1,
            nodes: [obj, ...cache.productBases.nodes],
          },
        },
      });
    } catch (e) {}
  };

  componentDidUpdate(_, prevState) {
    let { loading } = this.state;
    let { handleLoading } = this.props;
    if (loading !== prevState.loading) {
      if (handleLoading) {
        handleLoading(loading);
      }
    }
  }

  render() {
    let { handleBtnSave, clickSave } = this.props;
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <PageTitle
            title={"Add product base"}
            subTitle={"Product bases"}
            link="/admin/product-bases"
          />
        </div>
        <Query query={QUERY} fetchPolicy="network-only">
          {({ error, loading, data, client }) => {
            if (error) return <div>{handleError(error.toString())}</div>;
            if (loading)
              return (
                <div className="p-4">
                  <Skeleton active />
                </div>
              );

            const fulfillments = (data?.fulfillments || []);
            // .filter(
            //   ({ slug }) => slug !== CUSTOM_FULFILLMENT
            // );
            return (
              <ProductBaseForm
                onSubmit={(values) => {
                  if (values.designPositions && values.designPositions.length) {
                    values.designPositions = values.designPositions.map(
                      (dp) => {
                        if (dp.image && dp.image.id) {
                          dp.image = dp.image.id;
                        }
                        return dp;
                      }
                    );
                  }
                  this.setState({ loading: true }, () => {
                    client
                      .mutate({
                        mutation: MUTATION,
                        variables: {
                          input: { ...values, categories: [values.categories] },
                        },
                      })
                      .then((res) => {
                        this.setState({
                          loading: false,
                        });
                        notification.success({
                          message: "Product base has been saved!",
                        });
                        this.handleSetCache(client, res.data.createProductBase);
                        history.push(`/admin/product-bases`);
                      })
                      .catch((e) => {
                        this.setState({ loading: false });
                        notification.error({
                          message: handleError(e.toString()),
                        });
                      });
                  });
                }}
                fulfillments={fulfillments}
                loading={this.state.loading}
                categories={data.productBaseCategories}
                suppliers={data.suppliers}
                carriers={data.carriers}
                clickSave={clickSave}
                handleBtnSave={(value, aggregations) => {
                  aggregations = {
                    ...aggregations,
                    link: "/admin/product-bases",
                  };
                  if (handleBtnSave) {
                    handleBtnSave(value, aggregations);
                  }
                }}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

export default AddProductBase;
