import React, { Component } from "react";
import { Button } from "antd";
import { Mutation } from "@apollo/react-components";
import { ReloadOutlined } from "@ant-design/icons";
import { gql } from "apollo-boost";
import { LOAD_QUERY } from "./CronJobs";

const RETRY_CON = gql`
  mutation retryCron($id: ID!) {
    retryCron(id: $id)
  }
`;

export default class ActionRetryCron extends Component {
  state = {
    loading: false,
  };
  render() {
    const { id, filter } = this.props;
    return (
      <Mutation mutation={RETRY_CON}>
        {(retryCron, { client }) => (
          <Button
            onClick={() => {
              this.setState({ loading: true });
              retryCron({ variables: { id } })
                .then(() => {
                  this.setState({ loading: false });
                  try {
                    const cache = client.readQuery({
                      query: LOAD_QUERY,
                      variables: { filter },
                    });

                    client.writeQuery({
                      query: LOAD_QUERY,
                      variables: { filter },
                      data: {
                        ...cache,
                        crons: {
                          ...cache.crons,
                          nodes: [
                            ...cache.crons.nodes.map(
                              (item) => (
                                item.id === id
                                  ? ((item.status = "Pending"),
                                    (item.statuss = "Pendding"))
                                  : null,
                                item
                              )
                            ),
                          ],
                        },
                      },
                    });
                  } catch (_) {
                    // console.log(error);
                  }
                })
                .catch((err) => {
                  this.setState({ loading: false });
                });
            }}
            icon={<ReloadOutlined />}
            loading={this.state.loading}
          />
        )}
      </Mutation>
    );
  }
}
