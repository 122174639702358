import { TextField } from "@shopify/polaris";
import React from "react";

export function ShortTitleComp({
    value,
    onChange,
    multiline,
    autoFocus,
    label,
    id,
    maxLength,
    placeholder,
}) {
    const helpText = genHelpText(value, maxLength);

    return (
        <TextField
            value={value}
            onChange={onChange}
            multiline={multiline}
            maxLength={maxLength}
            autoFocus={autoFocus}
            label={label}
            id={id}
            placeholder={`Enter ${placeholder}`}
            helpText={helpText}
        />
    );
}

export function genHelpText(value, maxLength) {
    const vLen = value?.length;
    const suffix = vLen === 1 ? "character" : vLen > 1 ? "characters" : "";
    const helpText = maxLength
        ? `Maximum ${maxLength} characters${
              vLen ? " (" + vLen + " " + suffix + ")" : ""
          }.`
        : vLen
        ? `${vLen} ${suffix}.`
        : "";

    return helpText;
}
