import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PictureOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input } from "antd";
import { formatFileSize } from "../../helper";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import _ from "lodash";

import { DELETE_FILE } from "../../graphql/mutations";
const Container = styled.div`
  .thumbnail {
    min-height: 100px;
    background: rgb(214, 224, 233);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: rgb(137, 157, 173);
  }
  .danger-btn {
    color: rgb(219, 27, 70);
    padding: 0;
  }
  .desc {
    font-weight: 500;
  }
`;

const UPDATE_FILE = gql`
  mutation updateFile($input: UpdateFile!) {
    updateFile(input: $input) {
      id
      name
      alt
      caption
      thumbnailUrl
      url
      mimeType
      size
    }
  }
`;

class MediaFileDetails extends Component {
  constructor(props) {
    super(props);
    this.onChange = _.debounce(this.handleChange, 300);
  }
  handleChange = (client, data) => {
    client.mutate({
      mutation: UPDATE_FILE,
      variables: {
        input: {
          id: data.id,
          name: data.name,
          alt: data.alt,
          caption: data.caption,
        },
      },
    });
  };
  render() {
    const { value, isImage, onDeleted } = this.props;
    return (
      <Container>
        <Mutation mutation={DELETE_FILE}>
          {(deleteFile, { client }) => (
            <div>
              <div className={"thumbnail"}>
                {isImage ? (
                  <img
                    style={{
                      width: "100%",
                    }}
                    alt={value.thumbnailUrl}
                    src={value.thumbnailUrl ? value.thumbnailUrl : value.url}
                  />
                ) : (
                  <PictureOutlined style={{ fontSize: 30 }} />
                )}
              </div>
              <div className={"desc"}>
                <p>{value.name}</p>
                <p>{formatFileSize(value.size)}</p>
                <p>
                  <Button
                    onClick={() => {
                      deleteFile({
                        variables: {
                          id: value.id,
                        },
                      });
                      onDeleted(value, client);
                    }}
                    className={"danger-btn"}
                    type={"link"}
                  >
                    Delete Permanently
                  </Button>
                </p>
              </div>
              <div className={"form"}>
                <Form.Item label={"Alt text"}>
                  <Input
                    onChange={(e) => {
                      value.alt = e.target.value;
                      this.onChange(client, value);
                      this.props.onChange(value, client);
                    }}
                    value={value.alt}
                  />
                </Form.Item>
                <Form.Item label={"Caption"}>
                  <Input
                    onChange={(e) => {
                      value.caption = e.target.value;
                      this.onChange(client, value);
                      this.props.onChange(value, client);
                    }}
                    value={value.caption}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </Mutation>
      </Container>
    );
  }
}

MediaFileDetails.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  onDeleted: PropTypes.func,
  isImage: PropTypes.bool,
};

export default MediaFileDetails;
