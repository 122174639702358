import React, { useCallback, useRef } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";

import { DELETE_IDEA_TASK } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";

export function Delete({ item, open, onClose }) {
    // Props
    const id = item?.id;
    const timeoutRef = useRef(null);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [deleteIdeaTask, { loading }] = useMutation(DELETE_IDEA_TASK, {
        onCompleted: () => {
            setNotify({ msg: "Create Idea Task successfully.", err: false });
            onClose && onClose();

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setFilter && setFilter((prev) => ({ ...prev }));
                refetch && refetch();
            }, 1000);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err.toString()), err: true });
        },
    });

    // Actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            deleteIdeaTask({
                variables: {
                    id,
                },
            });
        }
    }, [id, deleteIdeaTask, toggleToast, setNotify]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Delete Idea Task"
            sectioned
            primaryAction={{
                content: "Delete",
                onAction: handleSubmit,
                loading: loading,
                destructive: true,
            }}
            secondaryActions={[{ content: "Close", onAction: onClose }]}
        >
            <p>Are you sure to delete this idea?</p>
        </Modal>
    );
}
