import React, { useState, useCallback, useEffect, useRef } from "react";
import { Autocomplete, Spinner } from "@shopify/polaris";
import _ from "lodash";

export const FilterPDByStore = (props) => {
  const { data, onChange, value } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const timeoutRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(true);
    if (data) {
      timeoutRef.current = setTimeout(() => {
        setOptions(data);
        setDeselectedOptions(data);
        setLoading(false);
        setSelectedOptions(value ? [value.value] : []);
        setInputValue(value ? value.search : null);
      }, 1000);
    }
  }, [data, value]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => {
        let label = _.get(option, "label.props.children");
        if (Array.isArray(label)) {
          label = label.join("");
        }
        return label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );
  const handleSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);

      // Show tag.
      let couple =
        deselectedOptions && deselectedOptions.length > 0
          ? deselectedOptions
              .filter((d) => selected.includes(d.value))
              .map((i) => {
                let label = _.get(i, "label.props.children", null);
                if (Array.isArray(label)) {
                  label = label.join("");
                }
                return {
                  value: i.value,
                  label,
                };
              })
          : [];
      if (onChange) {
        onChange({
          value: couple && couple.length ? couple[0].value : null,
          label: couple && couple.length ? couple[0].label : null,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions]
  );
  const textField = (
    <Autocomplete.TextField
      label="Accounts"
      labelHidden
      value={inputValue}
      onChange={handleInputValue}
      placeholder="Select store"
      autoFocus={false}
    />
  );

  return (
    <>
      {loading ? (
        <Spinner size="small" />
      ) : (
        <Autocomplete
          options={options}
          onSelect={handleSelection}
          selected={selectedOptions}
          textField={textField}
          id="account-select"
        />
      )}
    </>
  );
};
