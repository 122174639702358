import React from "react";
import { DataTable, Badge } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { HistoryETActionPolaris } from "./HistoryETActionPolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";

const Container = styled.div``;

export const HistoryETDTPolaris = ({ data, filter, setFilter, refetch }) => {
    // State
    const [rows, setRows] = React.useState([]);

    // Get data
    React.useEffect(() => {
        const newRows =
            data?.getListExportConfirmShipment?.nodes?.length > 0
                ? data.getListExportConfirmShipment.nodes
                      .map((node) => {
                          if (!node) {
                              return null;
                          }
                          let {
                              createdAt,
                              store,
                              author,
                              id,
                              status,
                              isExport,
                          } = node;

                          // Author
                          const fn = author?.firstName;
                          const ln = author?.lastName;
                          const authorMarkup = [fn, ln].join(" ");

                          // Store
                          const storeTitle = store?.title;

                          // Created at
                          const createdAtMarkup = createdAt
                              ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss")
                              : null;

                          // Status
                          const statusBadge = {
                              Done: "success",
                              Error: "critical",
                              Processing: "attention",
                          };

                          const statusMarkup = status ? (
                              <Badge
                                  children={status}
                                  status={statusBadge[status]}
                              />
                          ) : null;
                          let labelLinkExport =
                              isExport === false
                                  ? "Download"
                                  : "Re-download tracking";

                          const disabled = status && status === "Processing";
                          const isErrorStatus = status === "Error";
                          labelLinkExport = isErrorStatus
                              ? "Re-export tracking"
                              : labelLinkExport;

                          return [
                              <div className="created-at-wrap">
                                  <span>{createdAtMarkup}</span>
                              </div>,

                              <div className="store-wrap">
                                  <span>{storeTitle}</span>
                              </div>,
                              <div className="author-wrap">
                                  <span>{authorMarkup}</span>
                              </div>,
                              <div className="status-wrap">{statusMarkup}</div>,
                              <div className="actions-wrap">
                                  <HistoryETActionPolaris
                                      id={id}
                                      labelLinkExport={labelLinkExport}
                                      disabled={disabled}
                                      refetch={refetch}
                                      isErrorStatus={isErrorStatus}
                                  />
                              </div>,
                          ];
                      })
                      .filter(Boolean)
                : [];
        setRows(newRows);
    }, [data, refetch]);

    let { limit, paged } = filter;
    const total = get(data, "getListExportConfirmShipment.total", 0);
    const totalPage = Math.ceil(total / limit);
    const aggregation = {
        page: paged,
        totalPage,
        offset: paged * limit - limit,
        limit,
        onChange: setFilter,
        total,
    };

    return (
        <Container>
            <DataTable
                columnContentTypes={["text", "text", "text", "text", "numeric"]}
                headings={[
                    "Created At",
                    "Store",
                    "Author",
                    "Status",
                    "Actions",
                ]}
                rows={rows}
                verticalAlign="middle"
                hideScrollIndicator
                footerContent={
                    <PaginationPolaris aggregation={aggregation} showTotal />
                }
            />
        </Container>
    );
};
