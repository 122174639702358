import React, { useState, useEffect } from "react";
import { Card, Timeline, Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import moment from "moment";

import { handleError } from "../../helper";

const LIST_TIMELINE = gql`
  query activityTimeline($filter: ActivityFilter) {
    activityTimeline(filter: $filter) {
      total
      nodes {
        id
        createdAt
        message
      }
    }
  }
`;

const Container = styled.div`
  margin-top: 30px;
  .timeline-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .message {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 70%;
    }
    .created {
      flex: 30%;
      text-align: right;
    }
  }
  .pagination {
    margin-top: -2rem;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ProductTimeline = (props) => {
  const { data, loading, error, refetch } = useQuery(LIST_TIMELINE, {
    variables: {
      filter: {
        entityID: props.productId,
      },
    },
  });
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 0,
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (
      data &&
      data.activityTimeline &&
      data.activityTimeline.nodes &&
      data.activityTimeline.nodes.length
    ) {
      let newData = data.activityTimeline.nodes;
      let itemsPerPage = newData.slice(
        filter.offset,
        filter.offset + filter.limit
      );
      setOptions(itemsPerPage);
    }
  }, [data, filter]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refetchTimeline]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let records = data && data.activityTimeline;

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <>
      {loading ? (
        <Card>
          <Spin indicator={antIcon} />
        </Card>
      ) : null}
      {records && records.nodes && records.nodes.length > 0 ? (
        <Container>
          <Card title="History">
            <Timeline>
              {options.map((item) => (
                <Timeline.Item key={item.id}>
                  <div className="timeline-line">
                    <span className="message">{item.message}</span>
                    <span className="created">
                      {moment(item.createdAt).format("LLL")}
                    </span>
                  </div>
                </Timeline.Item>
              ))}
            </Timeline>
            <div className="pagination">
              <Pagination
                total={(records && records.total) || 0}
                pageSize={filter.limit}
                current={filter.offset / filter.limit + 1}
                onChange={(e) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    offset: (e - 1) * prevState.limit,
                  }));
                }}
              />
            </div>
          </Card>
        </Container>
      ) : null}
    </>
  );
};
