import React, { createContext, useContext, useMemo } from "react";

const DashboardContext = createContext(null);

export function DashboardProvider({ children, ...props }) {
  const bag = useMemo(() => props, [props]);

  return (
    <DashboardContext.Provider value={bag}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboard() {
  return useContext(DashboardContext);
}
