import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Checkbox, Collapse, Input, notification } from "antd";
import styled from "styled-components";
import FieldVisibilitySettings from "./FieldVisibilitySettings";

const Container = styled.div`
  .ant-collapse {
    border-radius: 0;
    background-color: rgba(223, 230, 237, 0.2);
    border: 0 none;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px 16px 5px 40px;
    font-weight: 700;
  }
  .ant-collapse-content,
  .ant-collapse > .ant-collapse-item {
    border: 0 none;
  }
  .ant-form-item label {
    color: rgba(0, 0, 0, 0.5);
  }
`;

class FieldSettings extends Component {
  state = {
    defaultActiveKey: ["basic"],
    err: false,
  };

  onChange = (data) => {
    if (this.props.onChange) {
      this.props.onChange(data);
    }
  };
  render() {
    const { defaultActiveKey, err } = this.state;
    let { data, fields } = this.props;
    const { title, configure } = data;
    const { settings } = configure;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    return (
      <Container>
        <Collapse defaultActiveKey={defaultActiveKey}>
          <Collapse.Panel key={"basic"} header="Basic">
            <Form.Item {...layout} label={"Title"}>
              <Input
                onChange={(e) => {
                  data.title = e.target.value;
                  this.onChange(data);
                }}
                value={title}
                placeholder={"Custom area title"}
              />
            </Form.Item>
            <Form.Item {...layout} label={"Description"}>
              <Input.TextArea
                onChange={(e) => {
                  configure.description = e.target.value;
                  this.onChange(data);
                }}
                value={configure.description}
                placeholder={"Custom area description"}
              />
            </Form.Item>
            <Form.Item
              {...layout}
              label={"Field Name"}
              validateStatus={err ? "error" : undefined}
            >
              <Input
                value={data.name}
                onChange={(e) => {
                  const names = (fields || [])
                    .filter((i) => i.title !== data.title)
                    .map((f) => f.name)
                    .filter(Boolean);

                  let err = names.includes(e.target.value);
                  if (err) {
                    notification.error({
                      message: "Fiela Name must be unique.",
                    });
                  }

                  this.setState({ err: !!err });
                  data.name = e.target.value;
                  this.onChange(data);
                }}
              />
            </Form.Item>
            <Form.Item {...layout} label={"Placeholder"}>
              <Input
                onChange={(e) => {
                  configure.placeholder = e.target.value;
                  this.onChange(data);
                }}
                value={configure.placeholder}
              />
            </Form.Item>
            <Form.Item {...layout} label={"Error Message"}>
              <Input.TextArea
                onChange={(e) => {
                  configure.errorMessage = e.target.value;
                  this.onChange(data);
                }}
                value={configure.errorMessage}
              />
            </Form.Item>
            <Form.Item {...layout} label={"Default Value"}>
              <Input
                onChange={(e) => {
                  settings.defaultValue = e.target.value;
                  this.onChange(data);
                }}
                value={settings.defaultValue}
              />
            </Form.Item>
            <Form.Item {...layout}>
              <Checkbox
                checked={configure.required === true}
                onChange={(e) => {
                  configure.required = e.target.checked;
                  this.onChange(data);
                }}
              >
                Required
              </Checkbox>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel key={"rules"} header={"Conditions"}>
            <FieldVisibilitySettings
              index={this.props.index}
              fields={this.props.fields}
              value={data}
              onChange={(d) => {
                this.onChange(d);
              }}
            />
          </Collapse.Panel>
        </Collapse>
      </Container>
    );
  }
}

FieldSettings.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  fields: PropTypes.array,
  index: PropTypes.number,
};

export default FieldSettings;
