import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  ChoiceList,
  Modal,
  Spinner,
  Stack,
  TextField,
  Checkbox,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { PRINTIFY_SLUG } from "../../constants";
import { UPDATE_PRITIFY_AUTO_PRODUCTION } from "../../graphql/mutations";
import { GET_PRINTIFY_AVAIABLE_SHOPS } from "../../graphql/queries";
import { handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { useToastContext } from "../shared/ToastContext";
import { CREATE_FULFILLMENT, UNINSTALL } from "./MerchizeFulfillmentConfigure";
import get from "lodash/get";

const CUSTOM_INTEGRATION = "custom_integration";

const initialState = {
  action: null,
  apiKey: "",
  getStore: false,
  stores: [],
  selectedStore: [],
  disabledBtn: true,
  loading: false,
  isPrintifyAutoInProduction: true,
  isAutoProduction: false,
};

export function PrintifyFulfillmentConfigurePolaris({
  refetch,
  installed,
  value,
}) {
  const { id, settings } = value || {};
  const isAutoProduction = get(settings, "printify_auto_production") || false;

  // State
  const [state, setState] = React.useReducer(
    (prev, state) => ({ ...prev, ...state }),
    initialState,
  );
  const [open, toggleOpen] = useToggle(false);
  const refetchTimeout = useRef(null);

  // Context
  const { toggleToast, setNotify } = useToastContext();

  // Mutations
  const [create, { loading }] = useMutation(CREATE_FULFILLMENT, {
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
    onCompleted: () => {
      setNotify({
        msg: "Install Printify fulfillment configure success.",
        err: false,
      });
      toggleOpen();
      setState(initialState);
      if (!refetch) return;
      refetchTimeout.current && clearTimeout(refetchTimeout.current);
      refetchTimeout.current = setTimeout(() => {
        refetch();
      }, 1500);
    },
  });

  const [updateAutoProd, { loading: loadingUA }] = useMutation(
    UPDATE_PRITIFY_AUTO_PRODUCTION,
    {
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
      onCompleted: () => {
        setNotify({
          msg: "Update auto production success.",
          err: false,
        });
        setState(initialState);
        if (!refetch) return;
        refetchTimeout.current && clearTimeout(refetchTimeout.current);
        refetchTimeout.current = setTimeout(() => {
          refetch();
        }, 1500);
      },
    },
  );

  const [uninstall, { loading: loadingU }] = useMutation(UNINSTALL, {
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
    onCompleted: () => {
      setNotify({
        msg: "Uninstall Printify fulfillment configure success.",
        err: false,
      });
      toggleOpen();
      setState(initialState);
      if (!refetch) return;
      refetchTimeout.current && clearTimeout(refetchTimeout.current);
      refetchTimeout.current = setTimeout(() => {
        refetch();
      }, 1500);
    },
  });

  // Query
  const [
    getStores,
    { data: dataShop, loading: loadingShop, error: errorShop },
  ] = useLazyQuery(GET_PRINTIFY_AVAIABLE_SHOPS);

  // Effects
  useEffect(() => {
    const nodes = dataShop?.getPrintifyAvaiableShops || [];
    if (nodes?.length > 0) {
      const nodesFiltered = nodes
        .filter(({ salesChannel }) => salesChannel === CUSTOM_INTEGRATION)
        .map(({ id, title }) => ({ label: title, value: id }));
      setState({ stores: nodesFiltered, disabledBtn: true });
    }
  }, [dataShop]);

  useEffect(() => {
    if (isAutoProduction != null) {
      setState({ isAutoProduction });
    }
  }, [isAutoProduction]);

  // Actions
  const handleUnorInstall = useCallback(
    (type) => () => {
      setState({ action: type });
      toggleOpen();
    },
    [],
  );

  const handleApiKeyChange = useCallback((apiKey) => {
    setState({ apiKey, disabledBtn: false, getStore: false });
  }, []);

  const handleUninstall = useCallback(() => {
    toggleToast();
    setNotify({ msg: null, err: false });
    uninstall({
      variables: {
        slug: PRINTIFY_SLUG,
      },
    });
  }, [uninstall]);

  const handleGetStore = useCallback(() => {
    if (!state.apiKey) return;
    setState({ getStore: true });
    getStores({
      variables: {
        apiKey: state.apiKey,
      },
    });
  }, [getStores, state.apiKey]);

  const handleInstall = useCallback(() => {
    const [storeID] = state.selectedStore || [];
    if (!storeID) return;
    toggleToast();
    setNotify({ msg: null, err: false });
    create({
      variables: {
        input: {
          apiKey: state.apiKey,
          fulfillmentId: PRINTIFY_SLUG,
          storeID: storeID + "",
          printifyAutoProduction: state.isPrintifyAutoInProduction,
        },
      },
    });
  }, [
    create,
    state.selectedStore,
    state.apiKey,
    state.isPrintifyAutoInProduction,
  ]);

  const handleStoreChange = useCallback((value) => {
    setState({ selectedStore: value, disabledBtn: false });
  }, []);

  const mergeLoading = useMemo(() => loading || loadingU, [loading, loadingU]);
  const primaryAction = useMemo(() => {
    let content = "",
      onAction = () => {},
      disabled = state.disabledBtn;
    if (installed) {
      content = "Uninstall";
      onAction = handleUninstall;
      disabled = false;
    } else {
      if (state.getStore) {
        content = "Install";
        onAction = handleInstall;
      } else {
        content = "Get stores";
        onAction = handleGetStore;
      }
    }

    return {
      content,
      onAction,
      disabled,
      loading: mergeLoading,
    };
  }, [
    installed,
    state.getStore,
    state.disabledBtn,
    handleUninstall,
    handleInstall,
    handleGetStore,
    mergeLoading,
  ]);

  const handleCheckboxAutoInProductionChange = useCallback(
    (newChecked) => setState({ isPrintifyAutoInProduction: newChecked }),
    [],
  );

  const handleSaveChange = React.useCallback(() => {
    if (!id) return;

    toggleToast();
    setNotify({ msg: null, err: false });

    updateAutoProd({
      variables: {
        appIntegrationID: id,
        isAutoProduction: state.isAutoProduction,
      },
    });
  }, [updateAutoProd, toggleToast, setNotify, id, state.isAutoProduction]);

  const handleAutoProductionChange = React.useCallback((value) => {
    setState({ isAutoProduction: value });
  }, []);

  return (
    <React.Fragment>
      {installed ? (
        <div style={{ marginBottom: 10 }}>
          <Stack>
            <Checkbox
              label="Auto send orders to In Production"
              onChange={handleAutoProductionChange}
              checked={state.isAutoProduction}
            />
            <Button
              size="slim"
              onClick={handleSaveChange}
              children="Save change"
              loading={loadingUA}
            />
          </Stack>
        </div>
      ) : null}
      <Button
        primary
        children={installed ? "Uninstall" : "Install"}
        onClick={handleUnorInstall(installed ? "uninstall" : "install")}
      />
      <Modal
        title={installed ? "Uninstall Printify" : "Install Printify"}
        open={open}
        onClose={toggleOpen}
        sectioned
        secondaryActions={[
          { content: "Cancel", onAction: handleUnorInstall(null) },
        ]}
        primaryAction={primaryAction}
      >
        {installed ? (
          <p>Are you sure uninstall Printify fulfillment?</p>
        ) : (
          <Stack vertical>
            <div>
              <ComponentLabelPolaris label="Api Key" required />
              <TextField
                value={state.apiKey}
                onChange={handleApiKeyChange}
                placeholder="Enter API Key"
              />
            </div>

            <div>
              <Checkbox
                label="Auto send orders to In Production"
                checked={state.isPrintifyAutoInProduction}
                onChange={handleCheckboxAutoInProductionChange}
              />
            </div>

            <div>
              {loadingShop ? (
                <Spinner size="small" />
              ) : errorShop ? (
                <div>{handleError(errorShop.toString())}</div>
              ) : dataShop ? (
                <div>
                  <ComponentLabelPolaris label="Stores" required />
                  <ChoiceList
                    title="Stores"
                    titleHidden
                    onChange={handleStoreChange}
                    selected={state.selectedStore}
                    choices={state.stores}
                  />
                </div>
              ) : null}
            </div>
          </Stack>
        )}
      </Modal>
    </React.Fragment>
  );
}
