import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  FormLayout,
  TextField,
  ButtonGroup,
  Button,
} from "@shopify/polaris";

import { isEmpty } from "../../../helper";

export const FormTagPolaris = ({
  handleSubmitProps,
  handleSheetChange,
  loading,
  tag,
  buttonLabel,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (tag) {
      setInputValue(tag.name);
    }
  }, [tag]);

  const handleInputChange = useCallback((value) => setInputValue(value), []);

  const handleSubmit = useCallback(() => {
    if (isEmpty(inputValue)) {
      setError(true);
    } else {
      setError(false);
      if (handleSubmitProps) {
        handleSubmitProps(inputValue);
        !tag && setInputValue(null);
      }
    }
  }, [handleSubmitProps, inputValue, tag]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          label={"Name"}
          placeholder="name"
          error={error && "Please input name tag!"}
        />
        <ButtonGroup>
          <Button loading={loading} primary submit>
            {buttonLabel ? buttonLabel : "Submit"}
          </Button>
          <Button onClick={handleSheetChange}>Cancel</Button>
        </ButtonGroup>
      </FormLayout>
    </Form>
  );
};
