import React from "react";
import { TextField, FormLayout, Button, ButtonGroup } from "@shopify/polaris";
import { filter, size, values, isEqual } from "lodash";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { FieldStorePolaris } from "./FieldStorePolaris";

const Container = styled.div`
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3rem;
  }
`;

export const GroupFormPolaris = ({
  btnSubmitLabel,
  onSubmit,
  onRedirect,
  loading,
  isEditGroup,
  value,
}) => {
  // State
  const [fields, setFields] = React.useState({
    name: null,
    description: null,
    stores: [],
  });
  const [errors, setErrors] = React.useState({});
  const [typeStore, setTypeStore] = React.useState("online-store");

  // Variables
  const FIELDS_REQUIRED = React.useMemo(
    () => ({
      name: "Name",
      description: "Description",
    }),
    []
  );

  // Get value
  React.useEffect(() => {
    if (value) {
      let { name, description, stores } = value;
      let type = stores?.length > 0 ? stores.find((s) => s.platform) : null;
      let platform = type?.platform;
      if (["woocommerce", "shopify", "shopbase"].includes(platform)) {
        platform = "online-store";
      }
      let storeIds = stores?.length > 0 ? stores.map((s) => s.id) : [];

      if (platform) {
        setTypeStore(platform);
      }
      setFields((prev) => ({ ...prev, name, description, stores: storeIds }));
    }
  }, [value]);

  // Handle actions
  const validateFields = React.useCallback(
    (value, id) => {
      let error = null;
      let label;
      for (let [key, value] of Object.entries(FIELDS_REQUIRED)) {
        if ([key].includes(id)) {
          label = value;
        }
      }
      if ((!value || !value.length) && label) {
        error = `${label} is required.`;
      }
      setErrors((prev) => ({ ...prev, [id]: error }));
    },
    [FIELDS_REQUIRED]
  );

  const handleInputChange = React.useCallback(
    (value, id) => {
      setFields((prev) => ({ ...prev, [id]: value }));
      validateFields(value, id);
    },
    [validateFields]
  );

  const handleSubmit = React.useCallback(() => {
    for (let key of Object.keys(FIELDS_REQUIRED)) {
      validateFields(fields[key], key);
    }
    let count = size(filter(values(errors), (e) => e !== null));
    let { name, description, stores } = fields;

    let input = {
      name,
      description,
    };
    if (isEditGroup) {
      input = {
        ...input,
        name: !isEqual(value.name, name) ? name : null,
        newGroupStores: stores,
      };
    } else {
      input.newGroupStores = undefined;
      input.storeIds = stores;
    }

    if (name && description && count === 0) {
      if (onSubmit) {
        onSubmit({ ...input });
      }
    }
  }, [
    fields,
    errors,
    onSubmit,
    validateFields,
    FIELDS_REQUIRED,
    isEditGroup,
    value,
  ]);

  return (
    <Container>
      <FormLayout>
        <div>
          <ComponentLabelPolaris label="Name" required />
          <TextField
            id="name"
            value={fields["name"]}
            placeholder="Enter group name"
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </div>
        <div>
          <ComponentLabelPolaris label="Description" required />
          <TextField
            id="description"
            value={fields["description"]}
            placeholder="Enter group description"
            onChange={handleInputChange}
            error={errors["description"]}
            multiline={4}
          />
        </div>
        {/* {isEditGroup && ( */}
          <FieldStorePolaris
            value={fields["stores"]}
            type={typeStore}
            onChange={(value) => handleInputChange(value, "stores")}
          />
        {/* )} */}
        <div className="actions-wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={onRedirect} />
            <Button
              children={btnSubmitLabel}
              primary
              onClick={handleSubmit}
              loading={loading}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
