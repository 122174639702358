import React, { useCallback, useState, useEffect } from "react";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import { handleError } from "../../helper";
import { PASS_ON_HOLD } from "../../graphql/mutations";

export const PassOnHoldPolaris = (props) => {
    const {
        disabled,
        orderIds,
        updateCount,
        reload,
        isAction,
        open: openProps,
        toggleShowModal,
    } = props;

    const [open, setOpen] = useState(false);
    const [activeToast, setActiveToast] = useState(false);
    // Lock body scroll
    // useLockBodyScroll(open)

    const toggleModal = useCallback(
        () => setOpen((prevState) => !prevState),
        []
    );
    const [passOnHold, { data, loading, error }] = useMutation(PASS_ON_HOLD, {
        onError: () => {
            toggleModal();
            if (reload) {
                setTimeout(() => {
                    reload();
                }, 1000);
            }
        },
        onCompleted: () => {
            if (updateCount) {
                updateCount("on_hold");
            }
            if (reload) {
                setTimeout(() => {
                    reload();
                }, 1000);
            }
            toggleModal();
            toggleShowModal && toggleShowModal(false);
        },
    });

    const handleSubmit = useCallback(() => {
        passOnHold({
            variables: {
                orderIds,
            },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIds]);

    const toggleActive = useCallback(
        () => setActiveToast((activeToast) => !activeToast),
        []
    );

    useEffect(() => {
        setOpen(openProps);
    }, [openProps]);

    const toastMarkup = activeToast
        ? (error || (data && data.passOnHold)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data && data.passOnHold && `Pass on hold success.`
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <>
            {toastMarkup}
            {!isAction && (
                <Button
                    size="slim"
                    disabled={disabled}
                    primary={!disabled}
                    onClick={toggleModal}
                    children="Pass on hold"
                />
            )}
            <Modal
                title="Pass on hold"
                open={open}
                onClose={isAction ? toggleShowModal : toggleModal}
                sectioned
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: isAction ? toggleShowModal : toggleModal,
                    },
                ]}
                primaryAction={{
                    content: "Ok",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <TextContainer>
                    <p id="pass-hold_modal">
                        Are you sure to pass on hold this order?
                    </p>
                </TextContainer>
            </Modal>
        </>
    );
};
