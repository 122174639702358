import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { AppContext } from "../../context";

class Logout extends Component {
  render() {
    const { onError, onCompleted } = this.props;
    return (
      <AppContext.Consumer>
        {({ setStore, setCurrentUser }) => (
          <Mutation
            onCompleted={onCompleted}
            onError={onError}
            mutation={gql`
              mutation logout {
                logout
              }
            `}
          >
            {(logout, { client }) => (
              <Link
                to="/login"
                href={"#"}
                onClick={async () => {
                  try {
                    await logout();
                  } catch (e) {}
                  if (onCompleted) {
                    onCompleted();
                  }
                  localStorage.clear();
                  setStore(null);
                  setCurrentUser(null);
                  // remove client data
                  client.resetStore();
                }}
              >
                Logout
              </Link>
            )}
          </Mutation>
        )}
      </AppContext.Consumer>
    );
  }
}

Logout.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};

export default Logout;
