import React, { Component } from "react";
import { Page, Card, Button } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import {
  handleError,
  getCookie,
  setCookie,
  convertStringToObject,
  convertObjectToParams,
} from "../../helper";
import _ from "lodash";
import styled from "styled-components";
import history from "../../history";
import FilterUserPolarisAccountAdmin from "./user/FilterUserPolarisAccountAdmin";
import { TableUserPolarisAccountAdmin } from "./user/TableUserPolarisAccountAdmin";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ToastContextProvider } from "../../components/shared/ToastContext";
import { AppContext } from "../../context";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LOAD_QUERY = gql`
  query load($filter: FindFilter) {
    users(filter: $filter) {
      total
      nodes {
        id
        firstName
        lastName
        email
        roles
        phone
        status
        verified
        address
        teams {
          id
          name
        }
        teamUser {
          id
          role
          team {
            id
            name
          }
        }
        avatar {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`;
export const DELETE_USERS = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export const ACTIVE_USERS = gql`
  mutation activeUser($id: ID!) {
    activeUser(id: $id)
  }
`;

const initFilter = convertStringToObject(history.location.search);

class ListUser extends Component {
  state = {
    loading: false,
    deleteUser: null,
    filter: {
      search: null,
      status: null,
      role: null,
      limit: Number(getCookie("perPageUsers")) || 20,
      offset: 0,
      teamID: "",
      ...convertStringToObject(history.location.search),
    },
    page: 1,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      let { status, ...rest } = nextState.filter;
      if (true === status) {
        status = "Active";
      }
      if (false === status) {
        status = "Inactive";
      }
      const params = convertObjectToParams({ status, ...rest });
      history.push(history.location.pathname + "?" + params);
    }
  }

  componentWillMount() {
    const url = convertStringToObject(window.location.search);
    if (url && url.status) {
      this.setState({
        filter: {
          ...this.state.filter,
          status:
            initFilter && initFilter.status === "Active"
              ? true
              : initFilter && initFilter.status === "Inactive"
              ? false
              : null,
        },
      });
    }
  }

  onChangeRole = (value, role, search, teamID) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        role: role,
        status: value === "true" ? true : value === "false" ? false : value,
        search: search === null ? null : search,
        offset: 0,
        teamID,
      },
      page: 1,
    });
  };
  render() {
    const { filter } = this.state;
    const { currentUser } = this.context || {};
    const execute = ["Seller", "Administrator"].some((r) =>
      (currentUser?.roles || []).includes(r),
    );

    return (
      <Container>
        <ToastContextProvider>
          <Page
            title={"All Users"}
            fullWidth={true}
            primaryAction={
              <Button url="/admin/users/add" primary={true}>
                Add New
              </Button>
            }
          >
            <Card sectioned bodyStyle={{ padding: 0 }}>
              <FilterUserPolarisAccountAdmin
                filter={filter}
                onChange={(status, role, search, teamID) => {
                  this.onChangeRole(status, role, search, teamID);
                }}
              />
            </Card>
            <Card>
              <Query
                query={LOAD_QUERY}
                variables={{ filter: this.state.filter }}
                skip={!execute}
              >
                {({ data, error, loading }) => {
                  if (loading) return <SkeletonPagePolaris />;
                  if (error)
                    return <div>Error:{handleError(error.toString())}</div>;
                  if (data?.users.nodes.length === 0) {
                    return <EmptyStatePolaris />;
                  } else {
                    return (
                      <TableUserPolarisAccountAdmin
                        data={data}
                        filter={filter}
                        setValuePage={(offset, limit) => {
                          if (offset !== null) {
                            this.setState({
                              filter: {
                                ...filter,
                                offset: parseInt(offset),
                                limit,
                              },
                            });
                          }
                          setCookie("perPageUsers", limit, 100);
                        }}
                      />
                    );
                  }
                }}
              </Query>
            </Card>
          </Page>
        </ToastContextProvider>
      </Container>
    );
  }
}

ListUser.contextType = AppContext;

export default ListUser;
