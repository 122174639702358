import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { CarriersPolaris } from "../../components/admin/carriers/CarriersPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const CarriersPagePolaris = () => {
  return (
    <Container>
      <Page
        title="All Carriers"
        fullWidth
        primaryAction={{ content: "New Carrier", url: "/admin/add-carriers" }}
      >
        <CarriersPolaris />
      </Page>
    </Container>
  );
};
