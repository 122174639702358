import React, { createContext, useContext, useMemo } from "react";

const FulfillmentShippingOptionsCtx = createContext({});

export function SwitchFulfillmentProvider({ children, ...props }) {
  const bag = useMemo(() => props, [props]);

  return (
    <FulfillmentShippingOptionsCtx.Provider value={bag}>
      {children}
    </FulfillmentShippingOptionsCtx.Provider>
  );
}

export function useSwitchFulfillment() {
  return useContext(FulfillmentShippingOptionsCtx);
}
