import React, { Component } from "react";
import styled from "styled-components";
import { Alert, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import AccountActivation from "../components/AccountActivation";
import { gql } from "apollo-boost";
import history from "../history";
import { AppContext } from "../context";

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #edf2f7;
  align-items: center;
`;

class Activation extends Component {
  state = {
    loading: true,
    error: null,
  };

  render() {
    const { match } = this.props;
    return (
      <Container>
        {this.state.error && <Alert message={this.state.error} type="error" />}
        {this.state.loading && (
          <AppContext.Consumer>
            {({ currentUser }) => (
              <Mutation
                onCompleted={(e) => {
                  notification.success({
                    message:
                      "Thank you for verification. Your account has been verified!",
                  });
                  if (currentUser) {
                    history.push(`/`);
                  } else {
                    history.push(`/login`);
                  }
                }}
                onError={() => {
                  this.setState({
                    loading: false,
                    error:
                      "Sorry the url has expired or wrong. Please try again!",
                  });
                }}
                mutation={gql`
                  mutation verifyUser($id: ID!) {
                    verifyUser(id: $id)
                  }
                `}
              >
                {(verifyUser) => (
                  <AccountActivation
                    onMutate={() => {
                      verifyUser({
                        variables: {
                          id: match.params.token,
                        },
                      });
                    }}
                  />
                )}
              </Mutation>
            )}
          </AppContext.Consumer>
        )}
      </Container>
    );
  }
}

export default Activation;
