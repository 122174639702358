import React from "react";
import { useLocation } from "react-router-dom";
import { ProductCampaignForm } from "../../components/product/components/ProductCampaignForm";
import { useAppContext } from "../../context";
import { getParam } from "../../helper";

function CloneProductCampaign() {
  const location = useLocation();

  // Props
  const values = React.useMemo(() => {
    let res = {
      props: {},
      value: {},
    };

    if (location?.state != null && typeof location.state === "string") {
      res = JSON.parse(location.state);
    }
    return res;
  }, [location?.state]);

  // Context
  const { currentUser } = useAppContext();
  const currentParam = getParam(currentUser);

  return (
    <ProductCampaignForm
      currentParam={currentParam}
      value={values.value}
      {...values.props}
    />
  );
}

export { CloneProductCampaign };
