import React, { Component } from "react";
import { gql } from "apollo-boost";
import "@ant-design/compatible/assets/index.css";
import { Card, Input, notification, Button, Form } from "antd";
import { Mutation } from "@apollo/react-components";
import { apolloClient, getTokenObject, setTokenObject } from "../client";
import history from "../history";

const CHANGEPASSWORD = gql`
  mutation changePassword($input: NewPassword!) {
    changePassword(input: $input)
  }
`;
class ChangePassword extends Component {
  state = {
    loading: false,
    currentPassword: "",
    currentUser: null,
    authenticate: this.handleAuthenticate,
    setCurrentUser: this.handleSetCurrentUser,
  };
  handleAuthenticate = (obj) => {
    setTokenObject(obj);
    this.setState({
      currentUser: obj.user,
    });
  };

  handleSetCurrentUser = (currentUser) => {
    this.setState({ currentUser });
  };
  componentDidMount() {
    const token = getTokenObject();
    if (token) {
      this.setState({ loading: true }, () => {
        apolloClient
          .query({
            query: gql`
              query me {
                me {
                  id
                  firstName
                  lastName
                  email
                  roles
                  phone
                }
              }
            `,
          })
          .then((res) => {
            this.setState({
              currentUser: res.data.me,
              loading: false,
            });
          })
          .catch(() => {
            this.setState({ loading: false });
            setTokenObject(null);
          });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Confirm your password!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    const { onCompleted } = this.props;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Mutation
        onError={(err) => {
          this.setState({ loading: false }, () => {
            notification.error({ message: "Current password incorrect" });
          });
        }}
        onCompleted={(res) => {
          this.setState({ loading: false }, () => {
            notification.success({ message: "Password changed success!" });
            if (onCompleted) {
              onCompleted(res.changePassword);
            }
          });
        }}
        mutation={CHANGEPASSWORD}
      >
        {(changePassword) => {
          return (
            <Form
              {...layout}
              hideRequiredMark
              onFinish={(e) => {
                const { currentUser } = this.state;
                delete e.confirm;
                delete e.current;
                if (e.pass === "") {
                  delete e.pass;
                }
                if (e) {
                  if (currentUser.id) {
                    changePassword({
                      variables: {
                        input: {
                          currentPassword: e.currentPassword,
                          password: e.password,
                        },
                      },
                    });
                  }
                }
              }}
              initialValues={{
                currentPassword: "",
                password: "",
                confirmPassword: "",
              }}
            >
              <Card style={{ maxWidth: "700px" }} title="Change Password">
                <Form.Item
                  label="Current Password"
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input current password",
                    },
                  ]}
                >
                  <Input type="password" autoComplete="currentPassword" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password",
                    },
                  ]}
                >
                  <Input type="password" autoComplete="password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      validator: this.compareToFirstPassword,
                    },
                  ]}
                >
                  <Input type="password" onBlur={this.handleConfirmBlur} />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{
                      marginRight: "5px",
                      color: "#fff",
                      backgroundColor: "#718096",
                    }}
                    onClick={() => history.push("/")}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-green"
                    htmlType="submit"
                    style={{ marginRight: 5 }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Card>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default ChangePassword;
