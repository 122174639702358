import React, { useState, useEffect } from "react";
import { DataTable } from "@shopify/polaris";
import { get } from "lodash";
import styled from "styled-components";

import { NumberToFixed } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const Container = styled.div`
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const DataTableOverViewPolaris = ({ data, role, filter, setFilter }) => {
  // State
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let newRows =
      data?.overview?.nodes?.length > 0
        ? data.overview.nodes.map((item) => {
            // User;
            let user = item?.user;
            let userMarkup = `${user?.firstName} ${user?.lastName}`;

            return [
              <div className="user-wrap">
                <span>{userMarkup}</span>
              </div>,
              <div className="amount-balance-wrap">
                <span>${NumberToFixed(item?.balance, 2)}</span>
              </div>,
              <div className="pending-cost-wrap">
                <span>${NumberToFixed(item?.pendingCost, 2)}</span>
              </div>,
              <div className="paid-amount-wrap">
                <span>${NumberToFixed(item?.paidAmount, 2)}</span>
              </div>,
              <div className="pending-deposit-wrap">
                <span>${NumberToFixed(item?.pendingDeposit, 2)}</span>
              </div>,
              <div className="total-deposit-wrap">
                <span>${NumberToFixed(item?.totalDeposit, 2)}</span>
              </div>,
            ];
          })
        : [];

    setRows(newRows);
  }, [data]);

  const colType = [];
  for (let i = 0; i < 7; i++) {
    colType.push("text");
  }

  let { limit, offset } = filter;
  const total = get(data, "overview.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={colType}
        headings={[
          `${role}`,
          "Amount Balance",
          "Pending Cost",
          "Paid Amount",
          "Pending Deposit",
          "Total Deposit",
        ]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
