import React from "react";
import { Filters } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty, cloneDeep } from "lodash";
import { gql } from "apollo-boost";

import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";
import { FilterListRadioPolaris } from "../../filters/FilterListRadioPolaris";
import { STATUS_OPTIONS } from "../../tasks/FilterControlPolaris";
import { FilterTimeByPolaris } from "../../tasks/FilterTimeByPolaris";
import { FilterSortOrderPolaris } from "../FilterSortOrderPolaris";

const TASK_STATUS = [];
const Need_Review = "Need Review";
const Expired_Soon = "Expired Soon";

const CLONE_STATUS = cloneDeep([...STATUS_OPTIONS]);
for (let i = 0; i < CLONE_STATUS.length; i++) {
  const item = CLONE_STATUS[i];
  const value = item?.value;
  if (!["All", "Pending Design"].includes(value)) {
    if ([Need_Review].includes(value)) {
      item["value"] = "Need_Review";
      item["label"] = Need_Review;
    }
    if ([Expired_Soon].includes(value)) {
      item["value"] = "Expired_Soon";
      item["label"] = Expired_Soon;
    }
    TASK_STATUS.push(item);
  }
}

const All = "All";
const Doing = "Doing";

export const TEAM_MEMBERS = gql`
  query teamMembers {
    teamMembers(
      filter: {
        limit: 1000
        offset: 0
        search: null
        status: true
        role: "Designer"
      }
    ) {
      hits {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ReportFilters = ({ onChange, filter }) => {
  const assigneeId = filter?.userId;
  const statusProp = filter.sortStatus;
  const orderProp = filter.order;
  // State
  const [search, setSearch] = React.useState(filter?.search || null);
  const [dataDesigner, setDataDesigner] = React.useState([]);
  const [designer, setDesigner] = React.useState({
    value: assigneeId,
    label: null,
    search: null,
  });
  const [status, setStatus] = React.useState({
    value: statusProp || null,
    label: statusProp || null,
  });
  const [filterTime, setFilterTime] = React.useState({
    range: null,
    rangeLabel: null,
    field: "tasks.created_at",
    fieldLabel: null,
  });
  const [order, setOrder] = React.useState(orderProp);
  // Ref
  const typingTimeoutRef = React.useRef(null);

  // Query
  const { data: dataD, loading: loadingD, error: errorD } = useQuery(
    TEAM_MEMBERS
  );

  // Get data
  React.useEffect(() => {
    if (statusProp) {
      let currentS = TASK_STATUS.find((i) => i.value === statusProp);
      if (currentS) {
        setStatus({
          value: currentS.value,
          label: currentS.label,
        });
      }
    } else {
      setStatus({
        value: Doing,
        label: Doing,
      });
    }
  }, [statusProp]);

  React.useEffect(() => {
    let newData =
      dataD?.teamMembers?.hits?.length > 0
        ? dataD.teamMembers.hits
            .filter((i) => i?.user)
            .filter(Boolean)
            .map(({ user }) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))
        : [];
    setDataDesigner(newData);

    if (assigneeId) {
      let currentD =
        newData && newData.length > 0
          ? newData.find((i) => i.value === assigneeId)
          : null;
      if (currentD) {
        setDesigner((prev) => ({
          ...prev,
          label: currentD.label,
          value: currentD.value,
        }));
      }
    }
  }, [dataD, assigneeId]);

  // Handle action
  React.useEffect(() => {
    typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);

    const newFieldByTime = { field: filterTime.field };
    if (filterTime?.range?.from) {
      newFieldByTime["from"] = filterTime.range.from;
    }
    if (filterTime?.range?.to) {
      newFieldByTime["to"] = filterTime.range.to;
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: search ? search.trim() : null,
          userId: designer.value,
          sortStatus: status.value,
          order,
          fieldByTime:
            Object.keys(newFieldByTime).length > 1
              ? { ...newFieldByTime }
              : null,
        });
      }
    }, 300);
  }, [search, onChange, designer, filterTime, status, order]);

  const handleSearchChange = React.useCallback((value) => setSearch(value), []);
  const handleFilterTimeRemove = React.useCallback(
    () =>
      setFilterTime(() => ({
        range: null,
        rangeLabel: null,
        field: null,
        fieldLabel: null,
      })),
    []
  );
  const handleOrderRemove = React.useCallback(() => setOrder(null), []);

  // Remove action
  const handleSearchRemove = React.useCallback(() => setSearch(null), []);
  const handleDesignerRemove = React.useCallback(
    () => setDesigner({ value: null, label: null }),
    []
  );
  const handleStatusRemove = React.useCallback(() => {
    setStatus({ value: Doing, label: Doing });
  }, []);

  const filtersClear = [
    handleSearchRemove,
    handleDesignerRemove,
    handleFilterTimeRemove,
    handleStatusRemove,
    handleOrderRemove,
  ];

  const handleFilterClearAll = React.useCallback(() => {
    for (let fn of filtersClear) {
      fn && fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filtersClear]);

  // Markup
  const filters = [
    {
      key: "designer",
      label: "Designer",
      tagLabel: designer.label,
      onRemove: handleDesignerRemove,
      filter: (
        <FilterNoSearchPolaris
          data={dataDesigner}
          value={designer}
          loading={loadingD}
          error={errorD}
          onChange={({ value, label }) =>
            setDesigner((prev) => ({ ...prev, value, label }))
          }
          onChangeSearch={({ search }) =>
            setDesigner((prev) => ({ ...prev, search }))
          }
        />
      ),
    },
    {
      key: "order",
      label: "Order",
      tagLabel: order,
      onRemove: handleOrderRemove,
      filter: (
        <FilterSortOrderPolaris
          order={order}
          onChange={(v) => {
            setOrder(v);
          }}
        />
      ),
    },
    {
      key: "status",
      label: "Sort By Status",
      tagLabel: status.label,
      onRemove: handleStatusRemove,
      filter: (
        <FilterListRadioPolaris
          value={status && status.value}
          options={TASK_STATUS}
          onChange={({ value, label }) =>
            setStatus((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    {
      key: "filterTime",
      label: "Filter Time",
      tagLabel: filterTime.rangeLabel,
      onRemove: handleFilterTimeRemove,
      filter: (
        <FilterTimeByPolaris
          filterTime={filterTime}
          isReport
          onChange={(field, fieldLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              field,
              fieldLabel,
            }));
          }}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  for (let { key, label, onRemove, tagLabel } of filters) {
    if (key === "filterTime") {
      if (
        (!isEmpty(filterTime.fieldLabel) &&
          "tasks.created_ad" !== filterTime.field) ||
        (!isEmpty(filterTime.rangeLabel) && filterTime.range)
      ) {
        const key = "filterTime";
        let { fieldLabel, rangeLabel, range, field } = filterTime;
        let value = "";
        if (
          null !== fieldLabel &&
          null !== rangeLabel &&
          "Task Created Date" !== fieldLabel
        ) {
          value = `${fieldLabel} & ${rangeLabel}`;
        } else if (
          null !== field &&
          null !== fieldLabel &&
          "Task Created Date" !== fieldLabel
        ) {
          value = `${fieldLabel}`;
        } else if (null !== range) {
          value = `${rangeLabel}`;
        }
        appliedFilters.push({
          key,
          label: disambiguateLabel("Time", value),
          onRemove,
        });
      }
      continue;
    }
    if (!isEmpty(tagLabel) && All !== tagLabel) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(label, tagLabel),
        onRemove,
      });
    }
  }

  return (
    <Filters
      queryValue={search}
      filters={filters}
      appliedFilters={appliedFilters}
      queryPlaceholder="Filter designer"
      onQueryChange={handleSearchChange}
      onQueryClear={handleSearchRemove}
      onClearAll={handleFilterClearAll}
    />
  );
  function disambiguateLabel(label, value) {
    if (!label) {
      return value;
    }
    return `${label}: ${value}`;
  }
};
