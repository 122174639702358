import React, { useContext } from "react";
import { Page, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import styled from "styled-components";

import { AppContext } from "../../context";
import { USER_ROLE, TEAM_ROLE_PARAMS, TEAM_ROLE } from "../../variable";

import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { handleError } from "../../helper";
import { ExportAmazonFormPolaris } from "./ExportAmazonFormPolaris";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

const QUERY_AMAZON_EXPORT = gql`
    query amazonExportById($id: ID!) {
        amazonExportById(id: $id) {
            id
            teamId
            title
            templateFileId
            useProductDivision
            mode
            templateFile {
                id
                name
                size
                mimeType
                url
                thumbnailUrl
            }
            storeId
            store {
                id
                email
                title
            }
            includeCollections {
                id
                name
            }
            excludeCollections {
                id
                name
            }
            includeTags {
                id
                name
            }
            excludeTags {
                id
                name
            }
            productBases {
                id
                title
            }
            divisionIds
            authors {
                id
                firstName
                lastName
            }
            limit
            from
            to
            createdTimeFilter
            mapping {
                field
                value
                targetField
                type
                active
            }
            markProductUsed
            excludePersonalizedProducts
        }
    }
`;

export const ExportAmazonEditPolaris = (props) => {
    const { match, viewConfig } = props;

    const id = match && match.params && match.params.id;

    const { data, loading, error } = useQuery(QUERY_AMAZON_EXPORT, {
        variables: {
            id,
        },
        skip: !id,
    });

    const { currentUser } = useContext(AppContext);
    const userRole = get(currentUser, "roles", []);
    const teamRole = get(currentUser, "teamUser.role", null);

    let leader = [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.MarketplaceManager,
    ];
    let isLeader = false;
    let currentParam = TEAM_ROLE_PARAMS.Seller;
    if (userRole.includes(USER_ROLE.Seller)) {
        if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
        }
        if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
            currentParam = TEAM_ROLE_PARAMS.StoreManager;
        }
        if (leader.includes(teamRole)) {
            isLeader = true;
        }
    }

    const loadingMarkup = loading && <Loading />;

    return isLeader ? (
        <Container>
            {loadingMarkup}
            <Page
                fullWidth
                title="Export for Amazon"
                breadcrumbs={[
                    {
                        content: "All Amazon Export Files",
                        url: `/${currentParam}/export-amazon`,
                    },
                ]}
            >
                {loading ? (
                    <SkeletonPagePolaris />
                ) : error ? (
                    <div>Error: {handleError(error.toString())}</div>
                ) : data && data.amazonExportById ? (
                    <ExportAmazonFormPolaris
                        value={data.amazonExportById}
                        viewConfig={viewConfig}
                    />
                ) : null}
            </Page>
        </Container>
    ) : null;
};
