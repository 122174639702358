import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { ProductCrawlerPolaris } from "../../components/tools/ProductCrawlerPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const ProductCrawlerPagePolaris = (props) => {
  const { id } = props.match.params;
  return (
    <Container>
      <Page
        title="Crawler"
        breadcrumbs={[
          { content: "All Crawlers", url: "/tools/product-crawlers" },
        ]}
      >
        <ProductCrawlerPolaris id={id} />
      </Page>
    </Container>
  );
};
