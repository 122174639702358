import React from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { CategoryDataTablePolaris } from "./CategoryDataTablePolaris";

export const LIST_CATEGORY = gql`
  fragment cat on Category {
    id
    parentId
    name
    description
    count
    image {
      id
      name
      thumbnailUrl
      url
    }
  }

  query categories {
    productBaseCategories {
      ...cat
      children {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
            }
          }
        }
      }
    }
  }
`;

export const CategoriesPolaris = () => {
  // Query
  const { data, loading, error, refetch } = useQuery(LIST_CATEGORY, {
    fetchPolicy: "no-cache",
  });

  // Markup
  const loadingMarkup = loading && <Loading />;
  return (
    <React.Fragment>
      {loadingMarkup}
      <Card>
        {loading ? (
          <div style={{ padding: "2rem" }}>
            <SkeletonPagePolaris />
          </div>
        ) : error ? (
          <div style={{ padding: "2rem" }}>
            Error: {handleError(error.toString())}
          </div>
        ) : data?.productBaseCategories?.length > 0 ? (
          <CategoryDataTablePolaris data={data} refetch={refetch} />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
