import { gql } from "apollo-boost";
import { ComplexProductCrawlItemFragment } from "../fragments";

export const COMPLEX_PRODUCT_ASSORTMENT = gql`
    query complexProductAssortment($filter: ProductAssortmentFilter) {
        complexProductAssortment(filter: $filter) {
            total
            nodes {
                ...ComplexProductCrawlItemFragment
            }
        }
    }
    ${ComplexProductCrawlItemFragment}
`;

export const COMPLEX_PRODUCT_ASSORTMENT_BY_ID = gql`
    query getComplexProductAssortmentById($id: ID!) {
        getComplexProductAssortmentById(id: $id) {
            ...ComplexProductCrawlItemFragment
        }
    }
    ${ComplexProductCrawlItemFragment}
`;

export const COMPLEX_PRODUCT_ASSORTMENT_DOMAINS = gql`
    query complexProductAssortmentDomains {
        complexProductAssortmentDomains {
            total
            nodes {
                domain
            }
        }
    }
`;

export const GET_PRODUCT_ASSORTMENT_TAXONOMY = gql`
    query getProductAssortmentTaxonomy {
        getProductAssortmentTaxonomy {
            term
            taxonomies
        }
    }
`;

export const TEMPLATES = gql`
    query templates($filter: TemplateFilter) {
        templates(filter: $filter) {
            nodes {
                id
                name
                data {
                    productBases {
                        id
                        title
                        designPositions {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
