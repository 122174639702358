import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  TextField,
  Toast,
  TextContainer,
  ButtonGroup,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";
import DreamshipShopModal from "./DreamshipShopModal";

const CREATE_FULFILLMENT = gql`
  mutation createFulfillmentConfigure($input: NewFulfillmentConfig!) {
    createFulfillmentConfigure(input: $input) {
      id
      fulfillmentId
      apiKey
      apiSecret
    }
  }
`;

const UNINSTALL = gql`
  mutation deleteFulfillmentConfigure($slug: String!) {
    deleteFulfillmentConfigure(slug: $slug)
  }
`;

const Container = styled.div`
  > div + div {
    margin-top: 1.6rem;
  }
`;

export const DreamshipFulfillmentConfigurePolaris = ({
  refetch,
  installed: installedProps,
  app,
}) => {
  const [open, setOpen] = useState(false);
  const [actionCurrent, setActionCurrent] = useState(null);
  const [installed, setInstalled] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);

  const [openShop, toggleOpenShop] = useToggle(false);
  const [errors, setErrors] = useState({
    apiKey: null,
    apiSecret: null,
  });

  const [createFulfillmentConfigure, { data, loading, error }] = useMutation(
    CREATE_FULFILLMENT,
    {
      onError: () => {},
      onCompleted: () => {
        toggleInstall();
        if (refetch) refetch();
        const id = setTimeout(() => {
          toggleModal();
        }, 2500);
        setTimeoutId(id);
      },
    },
  );

  const [
    deleteFulfillmentConfigure,
    { data: dataDelete, loading: loadingDelete, erorr: errorDelete },
  ] = useMutation(UNINSTALL, {
    onError: () => {},
    onCompleted: () => {
      toggleInstall();
      if (refetch) refetch();
      const id = setTimeout(() => {
        toggleModal();
      }, 2500);
      setTimeoutId(id);
    },
  });

  useEffect(() => {
    setInstalled(installedProps);
  }, [installedProps]);
  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toggleModal = useCallback((value) => {
    setOpen((open) => !open);
    setActionCurrent(value);
  }, []);

  const toggleInstall = useCallback(
    () => setInstalled((installed) => !installed),
    [],
  );

  const handleApiKeyValue = useCallback((value) => {
    let ak = null;
    if (!value) {
      ak = "API Key is required.";
    }
    setErrors((prevState) => ({ ...prevState, apiKey: ak }));
  }, []);
  // const handleApiSecretValue = useCallback((value) => {
  //   let as = null;
  //   if (!value) {
  //     as = "API signature is required.";
  //   }
  //   setErrors((prevState) => ({ ...prevState, apiSecret: as }));
  // }, []);

  const handleApiKeyChange = useCallback((value) => {
    setApiKey(value);
    handleApiKeyValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = useCallback(() => {
    handleApiKeyValue(apiKey);
    let currentErrors = errors;
    let errorCounter = Object.values(currentErrors).every((el) => el === null);
    if (apiKey && errorCounter) {
      createFulfillmentConfigure({
        variables: {
          input: {
            apiKey,
            fulfillmentId: "dreamship",
          },
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, apiKey]);

  const handleDelete = useCallback(() => {
    deleteFulfillmentConfigure({
      variables: {
        slug: "dreamship",
      },
    });
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toastSuccess = data && data.createFulfillmentConfigure;
  let toastSuccessDelete = dataDelete && dataDelete.deleteFulfillmentConfigure;

  const handleContentToast = () => {
    if (error) return handleError(error.toString());
    if (errorDelete) return handleError(errorDelete.toString());
    if (toastSuccess) return "Create fulfillment success.";
    if (toastSuccessDelete) return "Uninstall fulfillment success.";
  };

  const toastMarkup = activeToast
    ? (error || toastSuccess || toastSuccessDelete || errorDelete) && (
        <Toast
          content={handleContentToast()}
          error={error || errorDelete}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      {!installed ? (
        <Button
          primary
          children="Install"
          onClick={() => toggleModal("install")}
        />
      ) : (
        <ButtonGroup>
          <Button children="Choose default shop" onClick={toggleOpenShop} />
          <Button
            children="Uninstall"
            onClick={() => toggleModal("uninstall")}
          />
        </ButtonGroup>
      )}
      <Modal
        title="Install dreamship"
        open={open && "install" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleModal }]}
        primaryAction={{
          content: "Install",
          onAction: handleCreate,
          loading: loading,
        }}
      >
        <Container>
          <TextField
            label="API Key(*)"
            value={apiKey}
            onChange={handleApiKeyChange}
            placeholder="Enter your API Key"
            error={errors.apiKey}
          />
        </Container>
      </Modal>
      <Modal
        title="Uninstall dreamship"
        open={open && "uninstall" === actionCurrent}
        onClose={toggleModal}
        sectioned
        secondaryActions={[{ content: "No", onAction: toggleModal }]}
        primaryAction={{
          content: "Yes",
          onAction: handleDelete,
          loading: loadingDelete,
        }}
      >
        <TextContainer>
          Are you sure uninstall dreamship fulfillment?
        </TextContainer>
      </Modal>

      <DreamshipShopModal open={openShop} onClose={toggleOpenShop} item={app} />
    </>
  );
};
