import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList } from "@shopify/polaris";

const options = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Pending",
    label: "Pending Design",
  },
  {
    value: "Designed",
    label: "Designed",
  },
];

export const FilterProductDesignStatusPolaris = ({
  designStatus,
  setDesignStatus,
}) => {
  const [selected, setSelected] = useState(["All"]);

  useEffect(() => {
    if (null === designStatus.value) {
      setSelected(["All"]);
    } else {
      setSelected([designStatus.value]);
    }
  }, [designStatus]);

  const handleChoiceChange = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOptions = options.find((option) => {
        return option.value === selectedItem;
      });
      return matchedOptions && matchedOptions.label;
    });
    if (setDesignStatus) {
      setDesignStatus(
        "All" !== selected[0] ? selected[0] : null,
        "All" !== selectedValue[0] ? selectedValue[0] : null
      );
    }
    setSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChoiceList
      title="Design Status"
      titleHidden={true}
      selected={selected}
      choices={options}
      onChange={handleChoiceChange}
    />
  );
};
