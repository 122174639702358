import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { DELETE_COMPLEX_PRODUCT_CRAWL_ITEM } from "../../../graphql/mutations";
import { COMPLEX_PRODUCT_ASSORTMENT } from "../../../graphql/queries";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export const Trash = ({ open, onClose, item }) => {
    const id = item?.id;

    // Context
    const { toggleToast, setNotify, filter } = useToastContext();

    // Mutation
    const [deleteProduct, { loading, client }] = useMutation(
        DELETE_COMPLEX_PRODUCT_CRAWL_ITEM,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Remove this product successfully.",
                    err: false,
                });
                onClose && onClose();
            },
            onError: (err) => {
                setNotify({ msg: handleError(err.toString()), err: true });
            },
        }
    );
    // Handle action
    const handleUpdateCache = useCallback(() => {
        if (id != null) {
            const query = COMPLEX_PRODUCT_ASSORTMENT;
            const variables = { filter };

            const cache = client.readQuery({
                query,
                variables,
            });

            client.writeQuery({
                query,
                variables,
                data: {
                    ...cache,
                    complexProductAssortment: {
                        ...cache.complexProductAssortment,
                        total: cache.complexProductAssortment.total - 1,
                        nodes: cache.complexProductAssortment.nodes.filter(
                            (node) => node.id !== id
                        ),
                    },
                },
            });
        }
    }, [client, filter, id]);

    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            deleteProduct({
                variables: {
                    id: id,
                },
            })
                .then((res) => {
                    const data = res?.data?.deleteComplexProductCrawlItem;
                    if (data != null) {
                        handleUpdateCache();
                    }
                })
                .catch(() => {});
        }
    }, [id, deleteProduct, toggleToast, setNotify, handleUpdateCache]);

    return (
        <Modal
            open={open}
            title={`Delete product selected`}
            onClose={onClose}
            sectioned
            primaryAction={{
                content: "Trash",
                onAction: handleSubmit,
                loading: loading,
                destructive: true,
            }}
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        >
            <p>Are you sure to delete to this product?</p>
        </Modal>
    );
};
