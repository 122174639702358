import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { QUERY_FULFILLMENTS } from "../order/FilterOrdersControlPolaris";

const STATUS_OPTIONS = ["Publish", "Draft"].map((i) => ({
  value: i,
  label: i,
}));

export const FilterProductBasePolaris = ({ filter, onChange }) => {
  // Props
  const search = filter?.search;
  const fulfillmentId = filter?.fulfillmentId;
  const statusProp = filter?.status;

  // State
  const [queryValue, setQueryValue] = useState(search);
  const [fulfillments, setFulfillments] = useState([]);
  const [fulfillment, setFulfillment] = useState({
    value: fulfillmentId,
    label: null,
    search: null,
  });
  const typingTimeoutRef = useRef(null);

  const [status, setStatus] = useState({
    value: statusProp,
    label: null,
    search: null,
  });

  // query
  const { data, loading, error } = useQuery(QUERY_FULFILLMENTS);

  // Get data
  useEffect(() => {
    const newFF =
      data?.fulfillments?.length > 0
        ? data.fulfillments.map((f) => ({ value: f.id, label: f.name }))
        : [];
    setFulfillments(newFF);

    if (fulfillmentId != null) {
      const currentFF = newFF.find((i) => i.value === fulfillmentId);
      if (currentFF) {
        const { value, label } = currentFF;
        setFulfillment((prev) => ({
          ...prev,
          value,
          label,
        }));
      }
    }
  }, [data, fulfillmentId]);

  useEffect(() => {
    if (typeof statusProp !== "boolean") return;

    let state = {};
    if (statusProp) {
      state.value = "Publish";
      state.label = "Publish";
    } else {
      state.value = "Draft";
      state.label = "Draft";
    }

    setStatus((p) => ({ ...p, ...state }));
  }, [statusProp]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      const sVal = status.value;
      const convertS = {
        All: null,
        Publish: true,
        Draft: false,
      };

      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          fulfillmentId: fulfillment.value,
          status: sVal ? convertS[sVal] : null,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, fulfillment, status]);

  const handleQueryValueChange = useCallback(
    (value) => {
      setQueryValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryValue]
  );

  // Actions
  const handleFFRemove = useCallback(
    () => setFulfillment(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleStatusRemove = useCallback(() => {
    setStatus({ value: null, label: null, search: null });
  }, []);

  const clearAllField = [
    handleQueryValueRemove,
    handleFFRemove,
    handleStatusRemove,
  ];

  const handleFiltersClearAll = useCallback(() => {
    for (let fn of clearAllField) {
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...clearAllField]);

  // Variables
  const filters = [
    {
      key: "fulfillment",
      label: "Fulfillment by",
      tagLabel: fulfillment.label,
      onRemove: handleFFRemove,
      filter: (
        <FilterNoSearchPolaris
          data={fulfillments}
          value={fulfillment}
          loading={loading}
          error={error}
          onChange={({ value, label }) =>
            setFulfillment((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
          onChangeSearch={({ search }) =>
            setFulfillment((prevState) => ({
              ...prevState,
              search,
            }))
          }
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      tagLabel: status.label,
      onRemove: handleStatusRemove,
      filter: (
        <FilterNoSearchPolaris
          data={STATUS_OPTIONS}
          value={status}
          onChange={({ value, label }) =>
            setStatus((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
          onChangeSearch={({ search }) =>
            setStatus((prevState) => ({
              ...prevState,
              search,
            }))
          }
        />
      ),
    },
  ];

  const appliedFilters = [];
  for (let { key, label, onRemove, tagLabel } of filters) {
    if (!isEmpty(tagLabel) && tagLabel) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(label, tagLabel),
        onRemove,
      });
    }
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder={"Filter product base"}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(label, value) {
    if (!label) {
      return value;
    }
    return `${label}: ${value}`;
  }
};
