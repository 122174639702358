import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { ASSIGN_IDEA_TASK_TO_DESIGN_TEAM } from "../../../../../graphql/mutations";
import { GET_DESIGN_TEAMS } from "../../../../../graphql/queries";
import { arrInvalid, handleError, reducerFn } from "../../../../../helper";
import { AutoCompletePolaris } from "../../../../shared/AutoCompletePolaris";
import { useToastContext } from "../../../../shared/ToastContext";

const AssignToTeam = ({ ids: ideaTaskIds, onCloseParent, ...props }) => {
  const [state, setState] = React.useReducer(reducerFn, {
    designTeamId: "",
    designTeams: [],
  });

  const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

  const { data } = useQuery(GET_DESIGN_TEAMS, {
    variables: {
      filter: {},
    },
    skip: !props.open,
  });

  React.useEffect(() => {
    const nodes = data?.getDesignTeams?.nodes || [];
    const designTeams = genDesignTeams(nodes);
    setState({ designTeams });
  }, [data]);

  const timeoutRef = React.useRef();
  const [assignToDesignTeam, { loading }] = useMutation(
    ASSIGN_IDEA_TASK_TO_DESIGN_TEAM,
    {
      onCompleted: () => {
        setNotify({
          msg: "Assign Idea Task to Design Team succss",
          err: false,
        });

        props.onClose && props.onClose();
        onCloseParent && onCloseParent();
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setFilter && setFilter((p) => ({ ...p }));
          refetch && refetch();
        }, 1000);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    if (ideaTaskIds.length > 0 && state.designTeamId) {
      toggleToast && toggleToast();
      setNotify && setNotify({ err: false, msg: "" });

      assignToDesignTeam({
        variables: {
          input: {
            ideaTaskIds,
            designTeamId: state.designTeamId,
          },
        },
      });
    }
  }, [
    assignToDesignTeam,
    toggleToast,
    setNotify,
    ideaTaskIds,
    state.designTeamId,
  ]);
  const handleDesignTeamChange = React.useCallback((value) => {
    setState({ designTeamId: value });
  }, []);

  return (
    <Modal
      {...props}
      sectioned
      title="Assign to Design Team"
      large
      secondaryActions={[{ content: "Close", onAction: props.onClose }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        disabled: state.designTeamId == null,
        loading,
      }}
    >
      <AutoCompletePolaris
        options={state.designTeams}
        onChange={handleDesignTeamChange}
        label="Design Team"
        required
        value={state.designTeamId}
      />
    </Modal>
  );
};

function genDesignTeams(nodes) {
  if (arrInvalid(nodes)) return [];

  return nodes.map((item) => ({ label: item?.name, value: item?.id }));
}

export default AssignToTeam;
