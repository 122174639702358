import React, { useState, useCallback, useLayoutEffect } from "react";
import { DataTable, Button, TextField, Checkbox } from "@shopify/polaris";
import { DeleteMajorMonotone } from "@shopify/polaris-icons";

export const DataTableProductPolaris = ({
  value,
  onChange: onChangeProp,
  disabled,
}) => {
  // State
  const [rows, setRows] = useState([]);

  // Handle action
  const onChange = useCallback((value) => {
    if (onChangeProp) {
      onChangeProp(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get data
  useLayoutEffect(() => {
    let newRows =
      value?.length > 0
        ? value.map((_product, index) => {
            return [
              <div className="product-title-wrap">
                <TextField
                  value={value[index].title}
                  onChange={(newValue) => {
                    value[index].title = newValue;
                    onChange(value);
                  }}
                  disabled={disabled}
                />
              </div>,
              <div className="product-id-wrap">
                <TextField
                  value={value[index].originId}
                  onChange={(newValue) => {
                    value[index].originId = newValue;
                    onChange(value);
                  }}
                  disabled={disabled}
                />
              </div>,
              <div className="status-wrap">
                <Checkbox
                  label="Available"
                  checked={value[index].status}
                  onChange={(newValue) => {
                    value[index].status = newValue;
                    onChange(value);
                  }}
                />
              </div>,
              <div className="action-wrap">
                <Button
                  plain
                  icon={DeleteMajorMonotone}
                  onClick={() => {
                    let newValue = value.filter((_i, idx) => idx !== index);
                    onChange(newValue);
                  }}
                  disabled={disabled}
                />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <DataTable
      columnContentTypes={["text", "text", "text", "numeric"]}
      headings={["Product title", "Product id", "Status", "Action"]}
      rows={rows}
      verticalAlign="middle"
      hideScrollIndicator
    />
  );
};
