import {
  Autocomplete, Button,
  ButtonGroup, Card, Form, FormLayout, TextField
} from "@shopify/polaris";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { checkRole, handleError } from "../../helper";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";

const Container = styled.div`
  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const AddDepositFormPolaris = ({
  suppliers,
  onSubmit,
  error,
  loading,
  handleRedirect,
}) => {
  const [amount, setAmount] = useState("0");
  const [refId, setRefId] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [comment, setComment] = useState(null);
  const [errors, setErrors] = useState({
    amount: null,
    transactionId: null,
  });

  const { currentUser } = useAppContext();
  const { isTeamRoot } = checkRole(currentUser);

  useEffect(() => {
    let newData =
      isTeamRoot && suppliers && suppliers.length > 0
        ? suppliers.map((s) => {
            return {
              value: s.id,
              label: `${s.firstName} ${s.lastName}`,
            };
          })
        : [];
    let result = [{ value: "all", label: "MerchBridge" }, ...newData];
    setOptions(result);
    setDeselectedOptions(result);
    setSelectedOptions(["all"]);
    setInputValue("MerchBridge");
  }, [suppliers, isTeamRoot]);

  const handleAmountValue = (value, key) => {
    let a = null;
    if (!value) {
      a = `${_.startCase(key)} is required.`;
    }
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: a,
      };
    });
  };

  const handleAmountChange = useCallback((value) => {
    setAmount(value);
    handleAmountValue(value, "amount");
  }, []);
  const handleRefIdChange = useCallback((value) => {
    setRefId(value);
    handleAmountValue(value, "transactionId");
  }, []);
  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filerRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filerRegex),
      );
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );
  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
    },
    [options],
  );
  const handleCommentChange = useCallback((value) => setComment(value), []);
  const handleSubmit = useCallback(() => {
    handleAmountValue(amount, "amount");
    handleAmountValue(refId, "transactionId");

    if (Object.values(errors).every((err) => !err) && amount && refId) {
      let supplierId =
        "all" === _.head(selectedOptions) ? null : _.head(selectedOptions);
      const input = {
        amount: Number(amount),
        refId,
        supplierId,
        comment,
      };
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, amount, refId, selectedOptions, comment]);

  const textFieldMarkup = (
    <Autocomplete.TextField
      label="Target"
      value={inputValue}
      onChange={handleInputChange}
      placeholder="Choose target"
    />
  );

  return (
    <Container>
      <Card sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <FormLayout.Group>
              <div>
                <ComponentLabelPolaris label="Amount" required />
                <TextField
                  // label="Amount(*)"
                  value={amount}
                  onChange={handleAmountChange}
                  type="number"
                  min={0}
                  error={errors && errors.amount}
                />
              </div>
              <div>
                <ComponentLabelPolaris
                  label="Payoneer Transaction ID"
                  required
                />
                <TextField
                  // label="Payoneer Transaction ID(*)"
                  value={refId}
                  type="text"
                  onChange={handleRefIdChange}
                  error={errors && errors.transactionId}
                />
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              {error && <div>{handleError(error.toString())}</div>}
              <Autocomplete
                options={options}
                selected={selectedOptions}
                onSelect={handleSelection}
                textField={textFieldMarkup}
                emptyState={<span>No items found!</span>}
              />
              <div></div>
            </FormLayout.Group>
            <TextField
              label="Message"
              multiline={3}
              value={comment}
              onChange={handleCommentChange}
            />
            <div className="btn-wrap">
              <ButtonGroup>
                <Button onClick={handleRedirect}>Cancel</Button>
                <Button primary submit loading={loading}>
                  Save
                </Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </Form>
      </Card>
    </Container>
  );
};
