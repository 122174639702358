import React from "react";
import { Select } from "@shopify/polaris";

const options = [
  { value: "new", label: "New" },
  { value: "refurbished", label: "Refurbished" },
  { value: "used", label: "Used" },
];
export const ConditionPolaris = ({ value, onChange }) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      label="Product condition"
    />
  );
};
