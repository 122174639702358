import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { REFETCH_PRINTIFY_ORDER_INFO } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

export function RefetchPrintifyOrder({
  open,
  toggleShowModal,
  value,
  refetch,
}) {
  const orderID = value?.id;
  const { toggleToast, setNotify } = useToastContext();
  const timeRef = useRef(null);

  // Mutation
  const [refetchOrder, { loading }] = useMutation(REFETCH_PRINTIFY_ORDER_INFO, {
    onCompleted: () => {
      setNotify({ msg: "Refetch order info success.", err: false });

      if (refetch) {
        if (timeRef.current) clearTimeout(timeRef.current);
        timeRef.current = setTimeout(() => {
          refetch();
        }, 1200);
      }

      toggleShowModal && toggleShowModal(false);
    },
    onError: (err) => {
      setNotify({ msg: handleError(err.toString()), err: true });
    },
  });

  const handleSubmit = useCallback(() => {
    if (!orderID) return;
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    refetchOrder({
      variables: {
        orderID,
      },
    }).finally(() => {
      toggleShowModal();
    });
  }, [orderID, toggleShowModal, refetchOrder, toggleToast, setNotify]);

  return (
    <Modal
      open={open}
      onClose={toggleShowModal}
      sectioned
      title="Refetch Printify Order Info"
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>Are you sure to refetch this order?</p>
    </Modal>
  );
}
