import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useMemo } from "react";
import { handleError } from "../../helper";

const STORES_INFOMATION = gql`
  query storeInfo($storeId: ID!) {
    storeInfo(storeId: $storeId) {
      totalOrders
      pending
      pendingCost
      onHold
      inProduction
      fulfilled
      cancel
      error
      totalProducts
      pendingDesign
      paidAmount
    }
  }
`;

export const StoreInfoPolaris = ({ storeId }) => {
  const id = useMemo(() => storeId, [storeId]);
  const { loading, error, data } = useQuery(STORES_INFOMATION, {
    variables: {
      storeId: id,
    },
  });
  if (loading) return "Loading..";
  if (error) return <div>Error:{handleError(error.toString())}</div>;

  const dataInfo = data.storeInfo;

  const styleText = {
    fontSize: "13px",
    fontWeight: "500",
  };
  return (
    <div style={{ width: 300 }}>
      <h4 style={{ fontWeight: 600, marginBottom: 0 }}>Store info</h4>
      <hr style={{ marginBottom: 3 }} />
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "50% 50%",
        }}
      >
        <div>
          <div style={styleText}>
            Total Orders:
            <span className="b"> {dataInfo.totalOrders}</span>
          </div>
          <div style={styleText}>
            Pending design:
            <span className="b"> {dataInfo.pendingDesign}</span>
          </div>
          <div style={styleText}>
            Pending :<span className="b"> {dataInfo.pending}</span>
          </div>
          <div style={styleText}>
            On hold:
            <span className="b"> {dataInfo.onHold}</span>
          </div>
          <div style={styleText}>
            In Production:
            <span className="b"> {dataInfo.inProduction}</span>
          </div>
          <div style={styleText}>
            Fulfilled:
            <span className="b"> {dataInfo.fulfilled}</span>
          </div>
        </div>
        <div>
          <div style={styleText}>
            Cancel:
            <span className="b"> {dataInfo.cancel}</span>
          </div>
          <div style={styleText}>
            Error:
            <span className="b"> {dataInfo.error}</span>
          </div>
          <div style={styleText}>
            Total Product:
            <span className="b"> {dataInfo.totalProducts}</span>
          </div>
          <div style={styleText}>
            Pending Cost:
            <span className="b"> {dataInfo.pendingCost}</span>
          </div>
          <div style={styleText}>
            Paid Amount:
            <span className="b"> {dataInfo.paidAmount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
