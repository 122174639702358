import { Filters } from "@shopify/polaris";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { isEmpty } from "../../helper";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterBoolStatusPolaris } from "../shared/FilterBoolStatusPolaris";

const ROLES_OPTIONS = [
    {
        label: "All",
        value: "All",
    },
    {
        label: "Admin",
        value: "Admin",
    },
    {
        label: "Seller",
        value: "Seller",
    },
    {
        label: "Store Manager",
        value: "Store Manager",
    },
    {
        label: "Marketplace Manager",
        value: "Marketplace Manager",
    },
    {
        label: "Supporter",
        value: "Supporter",
    },
    {
        label: "Designer",
        value: "Designer",
    },
    {
        label: "Supplier",
        value: "Supplier",
    },
    {
        label: "Design Leader",
        value: "Design Leader",
    },
    {
        label: "Idea",
        value: "Idea",
    },
    {
        label: "Idea Leader",
        value: "Idea Leader",
    },
];

const ROLES_FOR_IDEA = ["All", "Idea", "Idea Leader", "Designer"];

export const FiltersMembersPolaris = ({
    filter,
    onChange,
    isLeadSpecial,
    isIdeaLeader,
}) => {
    const [inputValue, setInputValue] = useState(null);
    const [status, setStatus] = useState({
        value: filter.status,
        label:
            true === filter.status
                ? "Active"
                : false === filter.status
                ? "Inactive"
                : null,
    });
    const [role, setRole] = useState({
        value: filter.role,
        label: filter.role,
    });

    const typingTimeoutRef = useRef(null);

    useEffect(() => {
        if (filter && filter.search) {
            setInputValue(filter.search);
        }
    }, [filter]);

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        if (onChange) {
            typingTimeoutRef.current = setTimeout(() => {
                onChange({
                    search: inputValue ? inputValue.trim() : inputValue,
                    status: status.value,
                    role: role.value,
                });
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, status, role]);

    const handleInputChange = useCallback((value) => setInputValue(value), []);
    const handleInputRemove = useCallback(() => setInputValue(null), []);
    const handleStatusRemove = useCallback(
        () => setStatus({ value: null, label: null }),
        []
    );
    const handleRoleRemove = useCallback(
        () => setRole({ value: null, label: null }),
        []
    );
    const handleFiltersClearAll = useCallback(() => {
        handleInputRemove();
        handleStatusRemove();
        handleRoleRemove();
    }, [handleInputRemove, handleStatusRemove, handleRoleRemove]);

    // Markup
    const roleOptions = useMemo(
        () =>
            isIdeaLeader
                ? ROLES_OPTIONS.filter((i) => ROLES_FOR_IDEA.includes(i.value))
                : ROLES_OPTIONS,
        [isIdeaLeader]
    );

    const filters = [
        {
            key: "status",
            label: "Status",
            filter: (
                <FilterBoolStatusPolaris
                    status={status && status.value}
                    onChange={(value, label) =>
                        setStatus((prevState) => ({
                            ...prevState,
                            value,
                            label,
                        }))
                    }
                />
            ),
        },
        ...(!isLeadSpecial
            ? [
                  {
                      key: "roles",
                      label: "Roles",
                      filter: (
                          <FilterListRadioPolaris
                              value={role && role.value}
                              options={roleOptions}
                              onChange={({ value, label }) =>
                                  setRole((prevState) => ({
                                      ...prevState,
                                      value,
                                      label,
                                  }))
                              }
                          />
                      ),
                  },
              ]
            : []),
    ];

    const appliedFilters = [];
    if (!isEmpty(status.label)) {
        const key = "status";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, status.label),
            onRemove: handleStatusRemove,
        });
    }

    if (!isEmpty(role.label)) {
        const key = "roles";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, role.label),
            onRemove: handleRoleRemove,
        });
    }

    return (
        <Filters
            queryValue={inputValue}
            queryPlaceholder="Search member"
            onQueryChange={handleInputChange}
            onQueryClear={handleInputRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
        />
    );

    function disambiguateLabel(key, value) {
        switch (key) {
            case "status":
                return `Status: ${value}`;
            case "roles":
                return `Role: ${value}`;
            default:
                return value;
        }
    }
};
