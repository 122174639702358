import React from "react";
import { Modal } from "@shopify/polaris";
import { Carousel } from "antd";
import styled from "styled-components";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const Container = styled.div`
    display: flex !important;
    justify-content: center;
    img {
        max-width: 100%;
        height: auto;
        box-shadow: rgb(0 0 0 / 10%) 0px -1px 15px -3px,
            rgb(0 0 0 / 10%) 0px 4px 6px 2px;
    }
`;

const SlideContainer = styled.div`
    .ant-carousel .slick-slide {
        text-align: center;
        overflow: hidden;
    }
    .ant-carousel .slick-dots {
        bottom: 20px;
        li button {
            background: #1a202c;
            height: 5px;
        }
    }
    .p-target {
        text-align: center;
        padding-top: 2rem;
        margin-top: 20px;
    }
    .style-arrow {
        font-size: 22px;
        line-height: 1.5;
        color: #1a202c;
        right: -13px;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        background: #fff;
        border-radius: 50%;
        z-index: 10;
        box-shadow: rgb(0 0 0 / 10%) 0px -1px 15px -3px,
            rgb(0 0 0 / 10%) 0px 4px 6px 2px;
    }
    .ant-carousel {
        .slick-prev,
        .slick-next {
            &:hover,
            &:focus {
                background: #fff;
                color: #1a202c;
            }
        }
    }
`;

export const ViewModalImage = ({
    open,
    toggleShowModal,
    item,
    large = false,
}) => {
    const images = item && item.images ? item.images : [];

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} style-arrow`}
                style={{ ...style, display: "flex", right: "-13px" }}
                onClick={onClick}
            />
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} style-arrow`}
                style={{ ...style, display: "flex", left: "-13px" }}
                onClick={onClick}
            />
        );
    };

    const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <Modal
            title={`Product image`}
            open={open}
            onClose={toggleShowModal}
            sectioned
            large={large}
        >
            <SlideContainer>
                <Carousel arrows {...settings}>
                    {images
                        .filter((i) => i.file)
                        .filter(Boolean)
                        .map((image, index) => {
                            let imageUrl = "";
                            imageUrl =
                                image.file &&
                                (image.file.url
                                    ? image.file.url
                                    : image.file.thumbnailUrl
                                    ? image.file.thumbnailUrl
                                    : null);

                            return (
                                <div key={`slide-${index}`}>
                                    <Container key={`slide-${index}`} className="preview-file">
                                        <img
                                            style={{
                                                maxHeight: "500px",
                                                width: "auto",
                                                objectFit: "cover",
                                            }}
                                            src={imageUrl}
                                            alt={""}
                                        />
                                    </Container>
                                    <div className="p-target">
                                        <CustomLinkPolaris
                                            href={imageUrl}
                                            file={image.file}
                                        >
                                            View origin image
                                        </CustomLinkPolaris>
                                    </div>
                                </div>
                            );
                        })}
                </Carousel>
            </SlideContainer>
        </Modal>
    );
};
