import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LOAD_QUERY } from "./ListUser";
import { Page, Card } from "@shopify/polaris";
import { AddUserFormPolaris } from "./user/AddUserFormPolaris";

const Container = styled.div`
  .Polaris-FormLayout__Item {
    width: 100px;
  }
  .Polaris-ChoiceList__Choices {
    display: flex;
    gap: 0 2rem;
    flex-wrap: wrap;
  }
  .Polaris-ButtonGroup {
    flex-direction: row-reverse;
  }
  .icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
`;
class AddUser extends React.Component {
  state = {
    loading: false,
    filter: {
      status: true,
    },
  };
  handleSetCache = (client, user) => {
    const variables = {
      filter: {
        search: "",
        status: true,
        role: null,
        limit: 30,
        offset: 0,
      },
    };
    try {
      const cache = client.readQuery({
        query: LOAD_QUERY,
        variables,
      });
      client.writeQuery({
        query: LOAD_QUERY,
        variables,
        data: {
          ...cache,
          users: {
            ...cache.users,
            total: cache.users.total + 1,
            nodes: [user, ...cache.users.nodes],
          },
        },
      });
    } catch (e) {}
  };
  render() {
    return (
      <Container>
        <Page
          title="Add User"
          breadcrumbs={[{ content: "Users", url: "/admin/users" }]}
        >
          {/* <AddUserForm
            title={'Create New User'}
            redirect={`/admin/users`}
            onCompleted={(client, user) => {
              this.handleSetCache (client, user);
            }}
          /> */}
          <Card sectioned>
            <AddUserFormPolaris
              redirect={`/admin/users`}
              onCompleted={(client, user) => {
                this.handleSetCache(client, user);
              }}
            />
          </Card>
        </Page>
      </Container>
    );
  }
}

AddUser.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};
export default AddUser;
