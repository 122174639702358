import React, { useState, useCallback, useRef, useEffect } from "react";
import { Filters } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
    .Polaris-Filters-ConnectedFilterControl__CenterContainer
    * {
    border-radius: var(--p-border-radius-base, 3px) !important;
  }
`;

export const FilterClaimPolaris = ({ search, onChange }) => {
  const [inputValue, setInputValue] = useState(null);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    setInputValue(search);
  }, [search]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({ search: inputValue ? inputValue.trim() : inputValue, });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputChange = useCallback((value) => setInputValue(value), []);
  const handleInputRemove = useCallback(() => setInputValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleInputRemove();
  }, [handleInputRemove]);

  return (
    <Container>
      <Filters
        queryValue={inputValue}
        queryPlaceholder={"Search..."}
        onQueryChange={handleInputChange}
        onQueryClear={handleInputRemove}
        onClearAll={handleFiltersClearAll}
        filters={[]}
      />
    </Container>
  );
};
