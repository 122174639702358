import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import { FETCH_FULFILLMENT_ORDER_STATS } from "../../graphql/mutations";
import { REPORT_TOTAL_COST_BY_FULFILLMENT } from "../../graphql/queries";
import {
  convertStringToObject,
  convertToOffset,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import SuspenseComp from "../shared/SuspenseComp";
import { useToastContext } from "../shared/ToastContext";
import ReportTotalCostFilter from "./components/ReportTotalCostFilter";
import ReportTotalCostTable from "./components/ReportTotalCostTable";

const FIELD_FILTER = [...COMMON_FILTER, "order", "orderBy"];

export default function ReportTotalCost({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 20;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = React.useState({
    search: "",
    order: null,
    orderBy: null,
    filterByTime: null,
    limit: Number(getCookie("perPageTotalCost")) || 20,
    offset: 0,
    ...initFilter,
  });

  const { data, loading, error } = useQuery(REPORT_TOTAL_COST_BY_FULFILLMENT, {
    variables: {
      filter,
    },
  });

  const { setNotify, toggleToast } = useToastContext();
  const [fetchFulfillmentOrderStats, { loading: l2 }] = useMutation(
    FETCH_FULFILLMENT_ORDER_STATS,
    {
      onCompleted: () => {
        setNotify({ msg: "Fetch order information success", err: false });
      },
      onError: (err) => {
        setNotify({ msg: err?.toString(), err: true });
      },
    },
  );

  const loadingMarkup = loading && <Loading />;

  const handleFilterChange = React.useCallback(
    ({ search, order, orderBy, filterByTime }) =>
      setFilter((prevState) => {
        if (
          !isEqual(prevState.search, search) ||
          !isEqual(prevState.order, order) ||
          !isEqual(prevState.orderBy, orderBy) ||
          !isEqual(prevState.filterByTime, filterByTime)
        ) {
          prevState.offset = 0;
        }
        return {
          ...prevState,
          search,
          order,
          orderBy,
          filterByTime,
        };
      }),
    [],
  );

  const handlePaginationChange = React.useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageTotalCost", limit, 100);
  }, []);

  const handleFetch = React.useCallback(() => {
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: "", err: false });
    fetchFulfillmentOrderStats();
  }, [fetchFulfillmentOrderStats, toggleToast, setNotify]);

  return (
    <Page
      title="Report Total Cost"
      fullWidth
      primaryAction={{
        content: "Fetch order info",
        onAction: handleFetch,
        loading: l2,
      }}
    >
      {loadingMarkup}
      <Card sectioned>
        <SuspenseComp>
          <ReportTotalCostFilter
            filter={filter}
            onChange={handleFilterChange}
          />
        </SuspenseComp>
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : (
          <>
            {error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : data?.reportTotalCostByFulfillment?.nodes?.length > 0 ? (
              <ReportTotalCostTable
                data={data}
                filter={filter}
                onChange={handlePaginationChange}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </>
        )}
      </Card>
    </Page>
  );
}
