import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "@shopify/polaris";
import React, { Fragment, useCallback, useRef } from "react";
import { STATUS } from ".";
import { REVIEW_IDEA_TASK } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import useToggle from "../../../../../hooks/useToggle";
import { useToastContext } from "../../../../shared/ToastContext";

export function Approve({ onClose, item, taskId }) {
    const ideaTaskId = taskId;
    // State
    const [open, toggleOpen] = useToggle(false);
    const onCloseRef = useRef(null);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [approveIdea, { loading }] = useMutation(REVIEW_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: "Approved to this tasks successfully.",
                err: false,
            });
            onClose && onClose();
            toggleOpen && toggleOpen();

            // onClose parent modal
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                refetch && refetch();
                setFilter && setFilter((prev) => ({ ...prev }));
            }, 1000);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Actions
    const handleSubmit = useCallback(() => {
        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });

        const { taskBaseGroupId, baseConfigs } = genInput(item);
        if (ideaTaskId != null) {
            const input = {
                ideaTaskId,
                baseGroups: [{ taskBaseGroupId, baseConfigs }],
                status: STATUS.Accept,
            };

            approveIdea({ variables: { input } });
        }
    }, [ideaTaskId, toggleToast, setNotify, approveIdea, item]);

    return (
        <Fragment>
            <Button children="Approve" onClick={toggleOpen} />
            <Modal
                title="Approve Idea Task"
                open={open}
                onClose={toggleOpen}
                sectioned
                secondaryActions={[{ content: "Close", onAction: toggleOpen }]}
                primaryAction={{
                    onAction: handleSubmit,
                    content: "Submit",
                    loading: loading,
                }}
            >
                <p>Are you sure to approve this idea?</p>
            </Modal>
        </Fragment>
    );
}

function genInput(product) {
    if (!product) return null;

    const { id, bases } = product || {};

    const taskBaseGroupId = id;
    const baseConfigs = (bases || [])
        .map((b) => {
            const { baseConfigId, taskBaseSubmissions } = b || {};
            const [taskBaseSubmission] = taskBaseSubmissions || [];
            const submissionId = taskBaseSubmission?.id;

            return {
                taskBaseConfigId: baseConfigId,
                submissionId,
            };
        })
        .filter((i) => i.submissionId != null);

    return {
        taskBaseGroupId,
        baseConfigs,
    };
}
