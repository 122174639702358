import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { isEmpty } from "../../helper";
import { TEAM_ROLE, USER_ROLE } from "../../variable";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { FilterOrderByTimePolaris } from "../order/FilterOrderByTimePolaris";
import { FILTER_TIME } from "../order/FilterOrdersControlPolaris";
import { QUERY_GROUPS } from "../store/group/GroupsPolaris";

const Container = styled.div`
  .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
    .Polaris-Filters-ConnectedFilterControl__CenterContainer
    * {
    border-radius: ${(props) =>
      props.hasFilterStore
        ? `var(--p-border-radius-base, 3px)!important`
        : null};
  }
`;

const LIST_STORE = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const MappingOrdersFiltersPolaris = ({ onChange, filter }) => {
  const [inputValue, setInputValue] = useState(filter.search || null);
  const [dataStore, setDataStore] = useState([]);
  const timer = useRef(null);
  const { currentUser } = useContext(AppContext);
  const userRole = _.get(currentUser, "roles", []);
  const teamRole = _.get(currentUser, "teamUser.role", null);
  const userSeller = userRole.includes(USER_ROLE.Seller);
  const roleSeller = userSeller && [TEAM_ROLE.Admin].includes(teamRole);
  const isStoreManager =
    userSeller && [TEAM_ROLE.StoreManager].includes(teamRole);

  let hasFilterStore = isStoreManager;

  const storeProp = filter?.storeId;
  const [store, setStore] = useState({
    value: storeProp,
    label: null,
    search: null,
  });
  const [time, setTime] = useState({
    range: null,
    rangeLabel: null,
  });

  const groupIdProp = filter?.groupId;
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({
    value: filter.groupId,
    label: null,
    search: null,
  });

  const [filterStore, setFilterStore] = useState({
    limit: 20,
    offset: 0,
    search: null,
  });

  // Queries
  const { data, loading, error } = useQuery(LIST_STORE, {
    skip: !roleSeller,
    variables: {
      filter: filterStore,
    },
  });

  const {
    data: dataGroup,
    loading: loadingG,
    error: errorG,
  } = useQuery(QUERY_GROUPS, {
    variables: {
      filter: {
        limit: 1000,
        offset: 0,
      },
    },
  });

  useEffect(() => {
    if (dataGroup) {
      const nodes = dataGroup?.groups?.nodes || [];
      let newGroups =
        nodes?.length > 0
          ? nodes.map((g) => ({
              value: g.id,
              label: g.name,
            }))
          : [];
      setGroups(newGroups);
    }
  }, [dataGroup]);

  useEffect(() => {
    if (groupIdProp && groups.length > 0) {
      let currentG = groups.find((g) => [groupIdProp].includes(g.value));
      if (currentG) {
        let { value, label } = currentG;
        setGroup((prev) => ({ ...prev, value, label }));
      }
    }
  }, [groupIdProp, groups]);

  useEffect(() => {
    let newData = data;
    if (newData?.stores?.nodes?.length > 0) {
      newData = newData.stores.nodes.map((store) => {
        return {
          value: store.id,
          label: store.title,
        };
      });
    }
    setDataStore(newData);
  }, [data, filter]);

  useEffect(() => {
    if (dataStore?.length > 0) {
      let currentStore =
        dataStore && dataStore.find((item) => item.value === filter.storeId);
      setStore((prevState) => ({
        ...prevState,
        value: currentStore ? currentStore.value : null,
        label: currentStore ? currentStore.label : null,
      }));
    }
  }, [dataStore, storeProp]);

  // Change filter for query
  useEffect(() => {
    if (onChange) {
      let newTime = time.range;
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        onChange({
          search: inputValue ? inputValue.trim() : inputValue,
          storeId: store.value,
          time: newTime,
          groupId: group.value,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, store.value, time.range, group.value]);

  const handleQueryChange = useCallback((value) => setInputValue(value), []);
  const handleQueryRemove = useCallback(() => setInputValue(null), []);
  const handleStoreRemove = useCallback(
    () => setStore({ value: null, label: null, search: null }),
    [],
  );
  const handleTimeRemove = useCallback(
    () => setTime(() => ({ range: null, rangeLabel: null })),
    [],
  );
  const handleGroupRemove = useCallback(
    () => setGroup(() => ({ value: null, label: null, search: null })),
    [],
  );

  const handleGroupChange = useCallback((newValue = {}) => {
    setGroup((prev) => ({ ...prev, ...newValue }));
  }, []);

  const searchRef = useRef(null);
  const handleSearchStore = useCallback((search) => {
    setStore((prev) => ({ ...prev, search }));
    searchRef.current && clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      setFilterStore((prev) => ({ ...prev, search }));
    }, 500);
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleQueryRemove();
    handleStoreRemove();
    handleTimeRemove();
    handleGroupRemove();
  }, [
    handleQueryRemove,
    handleStoreRemove,
    handleTimeRemove,
    handleGroupRemove,
  ]);

  const filters = [
    roleSeller && {
      key: "stores",
      label: "Stores",
      filter: (
        <FilterHasSearchPolaris
          value={store}
          error={error}
          loading={loading}
          options={dataStore && dataStore.length > 0 ? dataStore : []}
          onChangeSearch={handleSearchStore}
          onChange={({ value, label }) =>
            setStore((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    {
      key: "filter-time",
      label: "Filter time by",
      filter: (
        <FilterOrderByTimePolaris
          options={FILTER_TIME}
          filterTime={time}
          onChangeRange={(range, rangeLabel) => {
            setTime((prevState) => ({
              ...prevState,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "group",
      label: "Group",
      filter: (
        <FilterNoSearchPolaris
          data={groups}
          value={group}
          loading={loadingG}
          error={errorG}
          onChange={handleGroupChange}
          onChangeSearch={handleGroupChange}
        />
      ),
    },
  ].filter(Boolean);

  const appliedFilters = [];
  if (!isEmpty(store.label)) {
    const key = "stores";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }
  if (!isEmpty(time.rangeLabel) && time.range) {
    const key = "filter-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, time.rangeLabel),
      onRemove: handleTimeRemove,
    });
  }

  if (!isEmpty(group.label)) {
    const key = "group";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, group.label),
      onRemove: handleGroupRemove,
    });
  }

  return (
    <Container hasFilterStore={hasFilterStore}>
      <Filters
        queryValue={inputValue}
        queryPlaceholder="Filter item"
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryRemove}
        onClearAll={handleFiltersClearAll}
        filters={hasFilterStore ? [] : filters}
        appliedFilters={hasFilterStore ? [] : appliedFilters}
      />
    </Container>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "stores":
        return `Store: ${value}`;
      case "filter-time":
        return `Time: ${value}`;
      case "group":
        return `Group: ${value}`;
      default:
        return value;
    }
  }
};
