import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import { GET_COUNT_TASK_BY_USER } from "../../../graphql/queries";
import {
  convertObjectToParams,
  convertStringToObject,
  getCookie,
  getPathFromRouter,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { ReportDT } from "./ReportDT";
import { ReportFilters } from "./ReportFilters";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const Doing = "Doing";

const FIELD_FILTER = [...COMMON_FILTER, "userId", "sortStatus", "order"];

export const Reports = (...props) => {
  const path = useMemo(() => {
    return getPathFromRouter(props);
  }, [props]);

  const isMatchPathName = useMemo(() => {
    return matchPathName(path);
  }, [path]);

  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      let paged = initialFilter.paged;
      paged = Number(paged);
      if (!paged) {
        paged = 1;
      }
      initialFilter.paged = paged;
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // State
  const [filter, setFilter] = React.useState({
    limit: Number(getCookie("perPageReportTask")) || 20,
    paged: 1,
    userId: null,
    sortStatus: Doing,
    order: null,
    fieldByTime: null,
    search: null,
    ...initFilter,
  });

  // Query
  const { data, loading, error } = useQuery(GET_COUNT_TASK_BY_USER, {
    variables: {
      filter,
    },
  });

  // Handle actions
  const handleFilterChange = React.useCallback(
    ({ search, userId, order, sortStatus, fieldByTime }) => {
      setFilter((prev) => {
        if (
          !isEqual(prev.search, search) ||
          !isEqual(prev.userId, userId) ||
          !isEqual(prev.order, order) ||
          !isEqual(prev.sortStatus, sortStatus) ||
          !isEqual(prev.fieldByTime, fieldByTime)
        ) {
          return {
            ...prev,
            paged: 1,
            search,
            sortStatus,
            order,
            userId,
            fieldByTime,
          };
        }
        return prev;
      });
    },
    [],
  );

  React.useEffect(() => {
    let { paged, limit, fieldByTime, ...rest } = filter;
    let params = null;

    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const handlePagination = React.useCallback(({ paged, limit }) => {
    setFilter((prev) => {
      if (!isEqual(prev.limit, limit)) {
        return {
          ...prev,
          limit,
          paged: 1,
        };
      }
      return { ...prev, paged };
    });
    setCookie("perPageReportTask", limit, 100);
  }, []);
  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      <Page title="Report by Status" fullWidth>
        {loadingMarkup}
        <Card sectioned>
          <ReportFilters filter={filter} onChange={handleFilterChange} />
        </Card>
        <Card>
          {loading ? (
            <SkeletonPagePolaris />
          ) : error ? (
            <div>Error: {handleError(error.toString())}</div>
          ) : data?.getCountTaskByUser?.nodes?.length > 0 ? (
            <ReportDT
              data={data}
              filter={filter}
              setFilter={handlePagination}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
