import React from "react";

import { EbayChannel } from "../../components/reports";
import { WrapperPage } from "../../components/shared/WrapperPage";

export function EbayChannelPage() {
    return (
        <WrapperPage>
            <EbayChannel />
        </WrapperPage>
    );
}
