import { useMutation } from "@apollo/react-hooks";
import { Page, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { getParamByRole, handleError } from "../../helper";
import history from "../../history";
import { NewPushProductsFormPolaris } from "./NewPushProductsFormPolaris";
import { LIST_PRODUCT_PUSH } from "./ProductPushPolaris";

export const LIST_STORE = gql`
  query load {
    stores(filter: { limit: -1, offset: 0 }) {
      nodes {
        id
        title
      }
    }
  }
`;

const ADD_PRODUCT_PUSH = gql`
  mutation createPush($input: NewPush!) {
    createPush(input: $input) {
      id
      title
      continue
      tags {
        id
        tag {
          id
          name
        }
        included
      }
      collections {
        id
        collection {
          id
          name
        }
        included
      }
      stores {
        id
        title
      }
      specialProducts
      productBases
    }
  }
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const NewPushProductsPolaris = () => {
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [completed, setCompleted] = useState(null);

  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  const [createPush, { loading: loadingMutation, client }] = useMutation(
    ADD_PRODUCT_PUSH,
    {
      onError: () => {},
      onCompleted: () => {
        setCompleted(true);
        const id = setTimeout(() => {
          client.resetStore();
          history.push(`/${param}/products/product-push`);
        }, 2500);
        setTimeoutId(id);
      },
    }
  );

  const handleSubmit = useCallback((input) => {
    createPush({
      variables: {
        input,
      },
    })
      .then((res) => {
        let productPush = res && res.data && res.data.createPush;
        let filter = {
          limit: 20,
          offset: 0,
          search: null,
          collections: null,
          tags: null,
          storeId: null,
        };

        const cache = client.readQuery({
          query: LIST_PRODUCT_PUSH,
          variables: { filter },
        });
        client.writeQuery({
          query: LIST_PRODUCT_PUSH,
          variables: { filter },
          data: {
            ...cache,
            pushList: {
              ...cache.pushList,
              total: cache.pushList.total + 1,
              nodes: [...cache.pushList.nodes, productPush],
            },
          },
        });
      })
      .catch(() => {});
    toggleActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  let errorMutation = null;
  const toastMarkup = activeToast
    ? (errorMutation || completed) && (
        <Toast
          content={
            errorMutation
              ? handleError(errorMutation.toString())
              : completed && "Add product push success."
          }
          error={errorMutation}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <Page
        title="New Push Products"
        fullWidth
        breadcrumbs={[
          { content: "Push products", url: `/${param}/products/product-push` },
        ]}
      >
        <NewPushProductsFormPolaris
          btnLabel="Create new push product"
          onSubmit={handleSubmit}
          loading={loadingMutation}
          param={param}
        />
      </Page>
    </Container>
  );
};
