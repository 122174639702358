import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  Page,
  DataTable,
  Button,
  Modal,
  TextContainer,
  Toast,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { handleError } from "../../helper";

const switchFulfillmentListQuery = gql`
  query switchFulfillmentList {
    switchFulfillmentList {
      id
      title
      productBase {
        id
        name
        variants {
          id
          name
        }
      }
      fulfillment {
        id
        name
      }
      items {
        id
        variant {
          id
          name
        }
        targetProductBase {
          id
          name
        }
        targetFulfillment {
          id
          name
        }
        targetVariant {
          id
          name
        }
      }
    }
  }
`;

const deleteSwitchFulfillmentMutation = gql`
  mutation deleteSwitchFulfillment($id: ID!) {
    deleteSwitchFulfillment(id: $id)
  }
`;
const Container = styled.div`
  .b-link {
    color: #454f5b;
  }
  .actions {
    button {
      margin-right: 10px;
    }
  }
`;
const SwitchFulfillment = () => {
  const { data, loading, refetch } = useQuery(switchFulfillmentListQuery, {
    fetchPolicy: "cache-and-network",
  });
  const [deleteSwitchFulfillment] = useMutation(
    deleteSwitchFulfillmentMutation
  );
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const nodes =
    data && data.switchFulfillmentList ? data.switchFulfillmentList : [];
  const rows = nodes.map((n) => {
    let fulfillmentName = "MerchBridge";
    if (n.fulfillment && n.fulfillment.name) {
      fulfillmentName = n.fulfillment.name;
    }
    return [
      <div>
        <p>
          <strong>{n.title}</strong>
          <br />
          <b>{fulfillmentName}: </b>
          {n.productBase.name}
        </p>
      </div>,
      <div className="actions">
        <Link to={`/seller/fulfillment/switch/edit/${n.id}`}>
          <Button size="slim">Edit</Button>
        </Link>
        <Button
          size={"slim"}
          onClick={() => {
            setDeleteId(n.id);
          }}
          destructive
        >
          Delete
        </Button>
      </div>,
    ];
  });
  return (
    <Container>
      <Page
        fullWidth={false}
        primaryAction={
          <Link to={`/seller/fulfillment/switch/add`}>
            <Button primary>Add new</Button>
          </Link>
        }
        breadcrumbs={[
          {
            content: (
              <Link className={"b-link"} to={"/seller/fulfillment"}>
                Fulfillment
              </Link>
            ),
          },
        ]}
        title="Switch Fulfillment"
      >
        <Card>
          <DataTable
            loading={loading}
            columnContentTypes={["text", "text"]}
            headings={["Title", "Action"]}
            rows={rows}
          />
        </Card>
      </Page>
      {deleteId && (
        <Modal
          open={true}
          onClose={() => {
            setDeleteId(null);
            setMessage(null);
            setIsLoading(null);
          }}
          title="Delete confirmation"
          primaryAction={{
            content: "Delete",
            loading: isLoading,
            onAction: () => {
              setIsLoading(true);
              deleteSwitchFulfillment({
                variables: {
                  id: deleteId,
                },
              })
                .then(() => {
                  setIsLoading(false);
                  setDeleteId(null);
                  refetch();
                })
                .catch((e) => {
                  setMessage(handleError(e.toString()));
                });
            },
          }}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure want to delete ?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
      {message && (
        <Toast content={message} onDismiss={() => setMessage(null)} />
      )}
    </Container>
  );
};

export default SwitchFulfillment;
