import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Popover,
  ActionList,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor, RefreshMinor } from "@shopify/polaris-icons";
import { isObject } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { PaymentAccountForm } from "./PaymentAccountForm";
import { mapField, validatevalues } from "../../pages/seller/PaymentAccount";

const UPDATE_PAYMENT_ACCOUNT = gql`
  mutation updatePaymentAccount($input: UpdatePaymentAccount!) {
    updatePaymentAccount(input: $input) {
      id
      title
      method
      apiUsername
      apiPassword
      apiSignature
      balance
    }
  }
`;

const DELETE_PAYMENT_ACCOUNT = gql`
  mutation deletePaymentAccount($id: ID!) {
    deletePaymentAccount(id: $id)
  }
`;

const paymentMethods = {
  paypal: "Paypal",
  pingPong: "PingPong",
};

export const ActionPaymentAccount = ({
  paymentAccount,
  notification,
  handleRefreshBalance,
  refetch,
}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    id: null,
    title: null,
    method: paymentMethods.paypal,
    apiUsername: null,
    apiPassword: null,
    apiSignature: null,
    appId: null,
    appSecret: null,
  });

  const [updatePaymentAccount] = useMutation(UPDATE_PAYMENT_ACCOUNT);
  const [deletePaymentAccount] = useMutation(DELETE_PAYMENT_ACCOUNT);

  const toggle = useCallback(
    (setState) => () =>
      setState((active) => {
        return !active;
      }),
    [],
  );

  const handleChangeValue = useCallback(
    (value, key) => {
      setValues((prev) => {
        const newValues = {
          ...prev,
          [key]: value,
        };
        if (key !== "method") {
          return newValues;
        }
        // if (value === "paypal") {
        //    newValues.appId = null;
        //    newValues.appSecret = null;

        //    return newValues;
        // }
        // newValues.apiUsername = null;
        // newValues.apiPassword = null;
        // newValues.apiSignature = null;

        const fields = mapField[value];
        for (let f of Object.keys(newValues)) {
          if (![...fields, "title", "note", "method"].includes(f)) {
            newValues[f] = undefined;
          }
        }

        return newValues;
      });
    },
    [setValues],
  );

  const handleUpdatePayment = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await updatePaymentAccount({
        variables: {
          input: {
            id: values.id,
            title: values.title,
            apiUsername: values.apiUsername,
            apiPassword: values.apiPassword,
            apiSignature: values.apiSignature,
            appId: values.appId,
            appSecret: values.appSecret,
            note: values.note,
          },
        },
      });
      setLoading(false);
      if (data?.updatePaymentAccount) {
        refetch();
        toggle(setVisibleUpdate)();
        notification("Update payment account successfully", false);
      }
    } catch (error) {
      notification(error.message, true);
    }
  });

  useEffect(() => {
    if (isObject(paymentAccount)) {
      setValues(paymentAccount);
    }
  }, [paymentAccount, setValues]);

  const disableSubmit = validatevalues(values);

  return (
    <>
      <Popover
        active={popoverActive}
        activator={
          <Button onClick={toggle(setPopoverActive)} disclosure>
            Actions
          </Button>
        }
        autofocusTarget="first-node"
        onClose={toggle(setPopoverActive)}
      >
        <ActionList
          actionRole="menuitem"
          sections={[
            {
              items: [
                {
                  content: "Refresh balance",
                  icon: RefreshMinor,
                  onAction: handleRefreshBalance([values.id]),
                  loading: true,
                },
                {
                  content: "Edit",
                  icon: EditMinor,
                  onAction: toggle(setVisibleUpdate),
                },
              ],
            },
            {
              items: [
                {
                  destructive: true,
                  content: "Delete",
                  icon: DeleteMinor,
                  onAction: toggle(setVisibleDelete),
                },
              ],
            },
          ]}
        />
      </Popover>

      {/* modal update */}
      <Modal
        open={visibleUpdate}
        onClose={toggle(setVisibleUpdate)}
        title="Update payment account"
        primaryAction={{
          content: "Update",
          loading: loading,
          disabled: disableSubmit,
          onAction: handleUpdatePayment,
        }}
      >
        <Modal.Section>
          <PaymentAccountForm
            onChangeValue={handleChangeValue}
            value={values}
          />
        </Modal.Section>
      </Modal>

      {/* modal delete */}
      <Modal
        open={visibleDelete}
        onClose={toggle(setVisibleDelete)}
        title={"Delete payment account"}
        primaryAction={{
          content: "Delete",
          loading: loading,
          disabled: !values.id,
          onAction: async () => {
            setLoading(true);
            try {
              const { data } = await deletePaymentAccount({
                variables: {
                  id: values.id,
                },
              });

              if (data?.deletePaymentAccount) {
                setLoading(false);
                toggle(setVisibleDelete)();
                refetch();
                notification("Delete payment account successfully", false);
              }
            } catch (error) {
              notification(error.message, true);
            }
          },
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure want to delete payment account: {values.title}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
