import React, { useState, useCallback, useEffect } from "react";
import { Checkbox, Button, FormLayout } from "@shopify/polaris";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import styled from "styled-components";

import { handleError, getUnique } from "../../../helper";
import { TemplateSelectPolaris } from "../TemplateSelectPolaris";
import { MainImageSelectorPolaris } from "../MainImageSelectorPolaris";
import { CollectionSelectPolaris } from "../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../product/tags/TagsSelectPolaris";
import { SimpleCustomizePolaris } from "../../customize/SimpleCustomizePolaris";
import { UsingProductDesignFilePolaris } from "./UsingProductDesignFilePolaris";
import { FieldTitlePolaris } from "../components/FieldTitlePolaris";
import useToggle from "../../../hooks/useToggle";
import { TEMPLATES } from "../../../graphql/queries";
import { ASSIGN_TO_TEMPLATE } from "../../../graphql/mutations";
import { PME_TEAM_ID } from "../../../variable";
import { useAppContext } from "../../../context";

const LabelContainer = styled.p`
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    cursor: default !important;
`;

const TitleComponent = styled.div`
    span.title-content {
        opacity: 0.8;
    }
`;

const MERCHKING = "MerchKing";

export const AssignTemplatePolaris = (props) => {
    const {
        open,
        toggleShowModal,
        currentChecked,
        ids,
        toggleActive,
        onNotificationChange,
        onCompleted,
        currentValue,
        bulkActions,
        dataCheckeds,
    } = props;

      // Context
  const { currentUser } = useAppContext();
  const { teamUser } = currentUser || {};
  const { team } = teamUser || {};
  const { id: teamId } = team || {};

    // State
    const [options, setOptions] = useState([]);
    const [templateId, setTemplateId] = useState(null);
    const [mainImageId, setMainImageId] = useState(null);
    const [deleteImgs, setDeleteImgs] = useState([]);
    const [collections, setCollections] = useState(PME_TEAM_ID === teamId ? ["hGypw"] : []);
    const [tags, setTags] = useState([]);
    const [fields, setFields] = useState([]);
    const [checkedPersonalized, setCheckedPersonalized] = useState(false);
    const [designPositions, setDesignPositions] = useState([]);
    const [designs, setDesigns] = useState([]);
    const [designUrls, setDesignUrls] = useState([]);
    const [detectSubmit, toggleDetectSubmit] = useToggle(false);

    const [errors, setErrors] = useState({
        template: null,
        collections: null,
        tags: null,
    });

    // Queries
    const [
        getTemplate,
        { data: dataCampaign, loading: loadingCampaign },
    ] = useLazyQuery(TEMPLATES);
    const [getProductTemplate, { data, loading }] = useLazyQuery(TEMPLATES);

    // Mutation
    const [assignToTemplate, { loading: loadingA }] = useMutation(
        ASSIGN_TO_TEMPLATE,
        {
            onCompleted: (res) => {
                toggleShowModal();
                if (onNotificationChange) {
                    onNotificationChange({
                        message: `Total ${res.assignToTemplate.success} items assigned success.`,
                        isError: false,
                    });
                }
                if (onCompleted) {
                    onCompleted();
                }
            },
            onError: (error) => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: handleError(error.toString()),
                        isError: true,
                    });
                }
                toggleDetectSubmit(false);
            },
        }
    );

    useEffect(() => {
        if (open) {
            getTemplate({
                variables: {
                    filter: {
                        grouping: true,
                    },
                },
            });
            getProductTemplate({
                variables: {
                    filter: {
                        product: true,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // Get data
    useEffect(() => {
        let newData =
            data?.templates?.nodes?.length > 0
                ? data.templates.nodes.map((t) => {
                      let designPositions = [];
                      if (t && t.data && t.data.productBases) {
                          let pBases = t.data.productBases;
                          if (pBases && pBases.length) {
                              for (let i = 0; i < pBases.length; i++) {
                                  designPositions = [
                                      ...designPositions,
                                      ...pBases[i].designPositions,
                                  ];
                              }
                          }
                      }
                      return { value: t.id, label: t.name, designPositions };
                  })
                : [];

        let newDataCampaign =
            dataCampaign?.templates?.nodes?.length > 0
                ? dataCampaign.templates.nodes.map((t) => {
                      let designPositions = [];
                      if (t && t.data && t.data.productBases) {
                          let pBases = t.data.productBases;
                          if (pBases && pBases.length) {
                              for (let i = 0; i < pBases.length; i++) {
                                  designPositions = [
                                      ...designPositions,
                                      ...pBases[i].designPositions,
                                  ];
                              }
                          }
                      }
                      return {
                          value: t.id,
                          label: t.name,
                          designPositions,
                      };
                  })
                : [];
        let mergeData = [...newData, ...newDataCampaign];
        let currentTemplate =
            mergeData && mergeData.length > 0
                ? mergeData.find((i) => i.value === templateId)
                : [];
        if (currentTemplate) {
            let newDP =
                currentTemplate && currentTemplate.designPositions
                    ? currentTemplate.designPositions
                    : [];
            let masterArr = [];
            let frontArr = [];
            let restArr = [];

            newDP.forEach((dp) => {
                if (dp.name) {
                    let lowerName = dp.name.toLowerCase();
                    if ("master" === lowerName) {
                        masterArr.push(dp);
                    } else if ("front" === lowerName) {
                        frontArr.push(dp);
                    } else {
                        restArr.push(dp);
                    }
                }
            });
            let originDP = [...masterArr, ...frontArr, ...restArr];
            originDP =
                originDP && originDP.length > 0
                    ? originDP
                          .map((i) => ({ value: i.id, label: i.name }))
                          .filter(Boolean)
                    : [];

            setDesignPositions(() => originDP);
        }
    }, [data, dataCampaign, templateId]);

    // Get data
    useEffect(() => {
        if (dataCheckeds?.length) {
            const newDesignUrls = dataCheckeds
                .map((item) => {
                    const isMerchKing = [MERCHKING].includes(item?.source);

                    let designUrl =
                        item?.designUrls?.length > 0
                            ? item.designUrls.map((i) => {
                                  const splitted = i.split("||");
                                  if (splitted.length > 1) {
                                      return splitted[1];
                                  }
                                  return splitted[0];
                              })
                            : [];

                    if (isMerchKing) {
                        return {
                            productCrawlItemId: item?.id,
                            designUrl,
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            setDesignUrls(newDesignUrls);
        }
    }, [dataCheckeds]);

    useEffect(() => {
        let originOptions = [];
        let labelProductTemplate = [
            {
                value: "productTemplate",
                label: <LabelContainer>-- Product template --</LabelContainer>,
                disabled: true,
            },
        ];
        let newProductTemplate =
            data?.templates?.nodes?.length > 0
                ? data.templates.nodes.map((t) => ({
                      value: t.id,
                      label: t.name,
                  }))
                : [];
        let labelCampaignTemplate = [
            {
                value: "campaignTemplate",
                label: <LabelContainer>-- Campaign Template --</LabelContainer>,
                disabled: true,
            },
        ];
        let newCampaignTemplate =
            dataCampaign?.templates?.nodes?.length > 0
                ? dataCampaign.templates.nodes.map((t) => ({
                      value: t.id,
                      label: t.name,
                  }))
                : [];
        originOptions = [
            ...labelProductTemplate,
            ...newProductTemplate,
            ...labelCampaignTemplate,
            ...newCampaignTemplate,
        ];
        setOptions(originOptions);
    }, [dataCampaign, data]);

    useEffect(() => {
        if (currentChecked) {
            setMainImageId(get(currentChecked, "images[0][file].id", null));
        }
    }, [currentChecked]);

    const handleFieldsChange = useCallback(
        (value) => setFields(() => [...value]),
        []
    );

    const handleTemplateValue = useCallback((value) => {
        let tmp = null;
        if (!value) {
            tmp = "Template is required.";
        }
        setErrors((prev) => ({ ...prev, template: tmp }));
    }, []);

    const handleCollectionsValue = useCallback((value) => {
        let c = null;
        if (!value.length) {
            c = "Collections is required.";
        }
        setErrors((prev) => ({
            ...prev,
            collections: c,
        }));
    }, []);

    const handleTagsValue = useCallback((value) => {
        let t = null;
        if (!value.length) {
            t = "Tags is required.";
        }
        setErrors((prev) => ({ ...prev, tags: t }));
    }, []);

    // Assign production assortment after change title
    const handAssign = useCallback(() => {
        if (onNotificationChange) {
            onNotificationChange({
                message: null,
                isError: false,
            });
        }

        handleTemplateValue(templateId);
        handleCollectionsValue(collections);
        handleTagsValue(tags);

        let newDesigns =
            designs?.length > 0
                ? designs.filter((i) => i.designPositionId)
                : [];
        newDesigns = getUnique(newDesigns, "designPositionId");

        let input = {
            productCrawlItems: ids,
            templateId,
            collections,
            tags,
            mainImageId,
            deleteImages: deleteImgs,
            fields,
            designs: newDesigns,
            designUrls: designUrls?.length > 0 ? designUrls : undefined,
        };

        if (templateId && collections.length && tags.length) {
            assignToTemplate({
                variables: {
                    input,
                },
            });
            toggleActive();
        }
    }, [
        ids,
        templateId,
        tags,
        collections,
        mainImageId,
        deleteImgs,
        fields,
        designs,
        designUrls,
        assignToTemplate,
        handleCollectionsValue,
        handleTagsValue,
        handleTemplateValue,
        onNotificationChange,
        toggleActive,
    ]);

    const handleSubmit = useCallback(() => {
        toggleDetectSubmit(true);
    }, [toggleDetectSubmit]);

    return (
        <FormLayout>
            <TitleComponent>
                <div className="title-content">
                    <FieldTitlePolaris
                        currentValue={currentValue}
                        toggleActive={toggleActive}
                        onNotificationChange={onNotificationChange}
                        isBulkActions={bulkActions}
                        ids={ids}
                        detectSubmit={detectSubmit}
                        handAssign={handAssign}
                        toggleDetectSubmit={toggleDetectSubmit}
                    />
                </div>
            </TitleComponent>
            <TemplateSelectPolaris
                options={options}
                error={errors.template}
                loading={loadingCampaign || loading}
                onChange={(value) => {
                    setTemplateId(value);
                    handleTemplateValue(value);
                }}
            />
            {currentChecked && get(currentChecked, "images[0]", null) && (
                <MainImageSelectorPolaris
                    images={currentChecked.images.map((i) => i.file)}
                    value={mainImageId}
                    deleteImgs={deleteImgs}
                    onChangeDeleteImgs={(ids) => setDeleteImgs(ids)}
                    onChange={(value) => setMainImageId(value)}
                />
            )}
            <CollectionSelectPolaris
                label="Collections"
                required
                allowMultiple
                value={collections}
                hasPopular
                errorMessage={errors.collections}
                haveQuickAdd
                limit={200}
                isAssortment
                onChange={(value) => {
                    setCollections(value);
                    handleCollectionsValue(value);
                }}
            />
            <TagsSelectPolaris
                label="Tags"
                required
                allowMultiple
                value={tags}
                errorMessage={errors.tags}
                haveQuickAdd
                limit={200}
                onChange={(value) => {
                    setTags(value);
                    handleTagsValue(value);
                }}
            />
            <Checkbox
                label="This is a personalized product"
                checked={checkedPersonalized}
                onChange={(newChecked) => {
                    setCheckedPersonalized(newChecked);
                    if (!newChecked) {
                        setFields([]);
                    }
                }}
            />
            {checkedPersonalized ? (
                <SimpleCustomizePolaris
                    value={fields}
                    onChange={handleFieldsChange}
                />
            ) : null}
            {!bulkActions && currentChecked?.designUrls?.length > 0 ? (
                <UsingProductDesignFilePolaris
                    crawlId={currentChecked.id}
                    designUrls={currentChecked.designUrls}
                    designPositions={designPositions}
                    onChange={(newValue) => {
                        newValue =
                            newValue?.length > 0
                                ? newValue.map((i) => {
                                      let { thumbnailUrl, ...rest } = i;
                                      return rest;
                                  })
                                : [];
                        setDesigns(newValue);
                    }}
                />
            ) : null}
            <Button
                children={"Submit"}
                primary
                onClick={handleSubmit}
                fullWidth
                loading={loadingA}
            />
        </FormLayout>
    );
};
