import { useMutation } from "@apollo/react-hooks";
import { ActionList, Button, Popover } from "@shopify/polaris";
import {
  DeleteMajorMonotone,
  ManagedStoreMajorTwotone,
  MarkPaidMinor,
  RefreshMajorTwotone,
} from "@shopify/polaris-icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context";
import { RE_GEN_STORE_API_KEY } from "../../../../graphql/mutations";
import useToggle from "../../../../hooks/useToggle";
import { TEAM_ROLE, TEAM_ROLE_PARAMS, USER_ROLE } from "../../../../variable";
import { RegenAPIKey } from "../../../etsy/actions/RegenAPIKey";
import MarkStoreStatus from "../../MarkStoreStatus";
import { Delete } from "./Delete";
import { MarkCheckpointed } from "./MarkCheckpointed";

export function Actions({ value, refetch }) {
  // Context
  const { currentUser } = React.useContext(AppContext);
  const userRole = currentUser?.roles || [];
  const teamRole = currentUser?.teamUser.role || null;

  const isSeller = userRole.includes(USER_ROLE.Seller);
  const isStoreManager =
    isSeller && [TEAM_ROLE.StoreManager].includes(teamRole);
  const isMM = isSeller && [TEAM_ROLE.MarketplaceManager].includes(teamRole);

  // Mutation
  const [reGen, { loading }] = useMutation(RE_GEN_STORE_API_KEY);

  // Param
  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if (isStoreManager) {
    currentParam = TEAM_ROLE_PARAMS.StoreManager;
  }
  if (isMM) {
    currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
  }

  // Props
  const id = value?.id;
  const history = useHistory();

  // State
  const [open, toggleOpen] = useToggle(false);
  const [showModal, toggleShowModal] = useToggle(false);
  const [action, setAction] = React.useState(null);

  // Handle action
  const onAction = React.useCallback(
    (action) => {
      toggleShowModal(true);
      setAction(action);
    },
    [toggleShowModal, id],
  );

  // const labelMark = value?.suspended
  //     ? "Unmark Checkpointed"
  //     : "Mark Checkpointed";

  const isDeactivated = value?.status === "Deactivated";
  const actions = React.useMemo(
    () => [
      {
        content: "Edit",
        icon: ManagedStoreMajorTwotone,
        onAction: () =>
          history.push(`/${currentParam}/stores/fb-marketplace/edit/${id}`),
      },
      {
        content: "Re-gen API Key",
        loading: loading,
        icon: RefreshMajorTwotone,
        onAction: () => onAction("reGen"),
      },
      // {
      //     content: labelMark,
      //     onAction: () => onAction("mark"),
      // },
      {
        content: `Mark as ${isDeactivated ? "Connected" : "Deactivated"}`,
        icon: MarkPaidMinor,
        onAction: () => onAction("markStatus"),
      },
      {
        content: "Delete",
        destructive: true,
        icon: DeleteMajorMonotone,
        onAction: () => onAction("delete"),
      },
    ],
    [onAction, currentParam, history, id, loading], //labelMark
  );

  // Markup
  const activator = (
    <Button children="Actions" onClick={toggleOpen} disclosure />
  );

  const props = {
    open: showModal,
    toggleOpen: toggleShowModal,
    onClose: toggleShowModal,
    value,
    isDeactivated,
    value,
    storeId: value?.id,
    refetch,
  };

  const actionsMarkup = {
    delete: <Delete {...props} />,
    mark: <MarkCheckpointed {...props} />,
    markStatus: <MarkStoreStatus {...props} />,
    reGen: (
      <RegenAPIKey
        open={showModal}
        onClose={toggleShowModal}
        storeId={value?.id}
        refetch={refetch}
      />
    ),
  };

  return (
    <React.Fragment>
      <Popover active={open} onClose={toggleOpen} activator={activator}>
        <ActionList items={actions} />
      </Popover>
      {actionsMarkup[action]}
    </React.Fragment>
  );
}
