import { Icon } from "@shopify/polaris";
import { CircleTickMajorFilled } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";

export function BaseTitle({ base }) {
    if (!base) return null;

    const { title, mockupTemplates } = base;
    const prefixTitle =
        mockupTemplates?.length > 0 ? (
            <span className="prefix-wrap">
                <Icon source={CircleTickMajorFilled} color="primary" />
            </span>
        ) : null;
    const titleCls = "base-info-title";
    const titleMergedCls = prefixTitle ? `${titleCls} has-prefix` : titleCls;

    const titleStyle = {
        wordBreak: "break-word",
        flex: "1 1",
    };

    if (prefixTitle) {
        titleStyle.paddingLeft = "2rem";
    }
    const baseInfo = (
        <div className={titleMergedCls}>
            {prefixTitle}
            <span title={title} style={titleStyle}>
                {title}
            </span>
        </div>
    );

    return <Wrapper>{baseInfo}</Wrapper>;
}

const Wrapper = styled.div`
    .base-info-title.has-prefix {
        position: relative;
        display: inline-block;

        .prefix-wrap {
            .Polaris-Icon {
                width: 1.6rem;
                position: absolute;
                top: 3px;
            }
        }
    }
`;
