import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_TYPE = gql`
  mutation updateClaimType($id: ID!, $claimType: String!) {
    updateClaimType(id: $id, claimType: $claimType)
  }
`;

const TYPE = {
  needResend: "Need Resend",
  needRefund: "Need Refund",
};

export const UpdateTypePolaris = (props) => {
  // Props
  const {
    open,
    toggleShowModal,
    claim,
    onNotificationChange,
    toggleActive,
    refetch,
    handleError,
  } = props;

  let typeProp = claim?.type;
  let type;
  if ([TYPE.needResend].includes(typeProp)) {
    type = TYPE.needRefund;
  } else if ([TYPE.needRefund].includes(typeProp)) {
    type = TYPE.needResend;
  }

  // Mutation
  const [updateType, { loading }] = useMutation(UPDATE_TYPE, {
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
    onCompleted: () => {
      if (toggleShowModal) {
        toggleShowModal();
      }
      if (onNotificationChange) {
        onNotificationChange({
          message: `Update claim type successfuly.`,
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
  });

  // Hanlde action
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    let id = claim?.id;
    if (id) {
      updateType({
        variables: {
          id,
          claimType: type,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim, type]);

  return (
    <Modal
      title="Update claim type"
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <p>
        Are you sure to change the claim type to{" "}
        {[TYPE.needResend].includes(typeProp)
          ? TYPE.needRefund
          : TYPE.needResend}
        ?
      </p>
    </Modal>
  );
};
