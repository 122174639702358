import { CheckCircleTwoTone } from "@ant-design/icons";
import { Query } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import {
  Badge,
  Button,
  ColorPicker,
  Heading,
  Modal,
  RangeSlider,
  SkeletonThumbnail,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import { Carousel } from "antd";
import { gql } from "apollo-boost";
import { includes } from "lodash";
import React, { Component, useEffect } from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { GET_VALUE_FOR_ORDER } from "../../graphql/queries";
import {
  copyUrlImage,
  elementContains,
  handleError,
  hsbToRgb,
  isURLDriveLink,
  replaceSizeForDriveLink,
} from "../../helper";
import useToggle from "../../hooks/useToggle";
import { ImageTypes, META_DATA } from "../../variable";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import SuspenseComp from "../shared/SuspenseComp";
import { AcceptDesignPolaris } from "../tasks/actions/AcceptDesignPolaris";
import { RejectDesignPolaris } from "../tasks/actions/RejectDesignPolaris";
import { useThumbnailFileCtx } from "../tasks/context/ThumbnailFileContext";

const ModalWithImageZoomInOut = React.lazy(() =>
  import("../shared/ModalWithImageZoomInOut"),
);

export const GEN_THUMBNAIL_IMAGE = gql`
  mutation regenerateThumbnailByAPI($fileType: String!, $files: [String!]!) {
    regenerateThumbnailByAPI(fileType: $fileType, files: $files)
  }
`;

const RE_GENERATE_PRINT_FILE = gql`
  mutation regeneratePrintfileThumbnailByAPI($fileKey: String!) {
    regeneratePrintfileThumbnailByAPI(fileKey: $fileKey) {
      success
      message
      thumbUrl
    }
  }
`;

class ModalImageClaimPolaris extends Component {
  state = {
    showModal: false,
    loaded: false,
    dimensions: {},
    showModalCompareDesign: false,
    inputSlider: 100,
    showAcceptDesign: false,
    showRejectDesign: false,

    bg: "transparent",
  };

  checkRealDimension = (designPosition, realWidth, realHeight) => {
    let realDmOk = false;

    if (
      designPosition &&
      designPosition.description &&
      designPosition.description.length > 0 &&
      realWidth &&
      realHeight
    ) {
      let originDesc = designPosition.description;

      realWidth = parseInt(realWidth);
      realHeight = parseInt(realHeight);

      if (originDesc) {
        let splitDesc = originDesc.split("|");
        if (
          Array.isArray(splitDesc) &&
          splitDesc[0] &&
          splitDesc[0].length > 0
        ) {
          let dimensionDesc = splitDesc[0];

          // Case: no dimension
          const dimensionValid = /px|cm|in/i;
          if (!dimensionValid.test(dimensionDesc)) {
            return true;
          }

          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/px/g, "");
          dimensionDesc = dimensionDesc.replace(/cm/g, "");
          dimensionDesc = dimensionDesc.replace(/\s+/g, "");
          if (dimensionDesc.length > 0) {
            let dmDesc = dimensionDesc.split("x");
            if (Array.isArray(dmDesc) && 2 !== dmDesc.length) {
              dmDesc = dimensionDesc.split("×");
            }
            if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
              let splitWidth = dmDesc && dmDesc[0] ? parseInt(dmDesc[0]) : 0;
              let splitHeight = dmDesc && dmDesc[1] ? parseInt(dmDesc[1]) : 0;
              if (
                splitWidth > 0 &&
                splitHeight > 0 &&
                splitWidth === realWidth &&
                splitHeight === realHeight
              ) {
                realDmOk = true;
              }
            }
          }
        }
      }
    }
    return realDmOk;
  };

  imageLoadHandler = (isCompareDesign, originUrl, imgIndex, designPosition) => {
    if (isCompareDesign) {
      let img = new Image();
      img.src = originUrl;
      img.onload = () => {
        let newDimensions = this.state.dimensions;
        if (!newDimensions[imgIndex]) {
          let height = img && img.height ? img.height : "";
          let width = img && img.width ? img.width : "";

          newDimensions[imgIndex] = {
            id: imgIndex,
            height: height,
            width: width,
            isRealDimensionOk: this.checkRealDimension(
              designPosition,
              width,
              height,
            ),
          };
          this.setState({ dimensions: newDimensions });
        }
      };
    }
  };

  toggleModalCD = () => {
    this.setState((prev) => ({
      showModalCompareDesign: !prev.showModalCompareDesign,
    }));
  };
  toggleModalAD = () => {
    this.setState((prev) => ({
      showAcceptDesign: !prev.showAcceptDesign,
    }));
  };

  toggleModalRD = () => {
    this.setState((p) => ({
      showRejectDesign: !p.showRejectDesign,
    }));
  };

  onChangeSlider = (value) => {
    this.setState({
      inputSlider: value,
    });
  };

  componentDidUpdate(_prevProps, prevState) {
    let { showModalCompareDesign, personalizedInfo } = this.state;
    if (showModalCompareDesign !== prevState.showModalCompareDesign) {
      let domCWG = document.querySelector("#compare-with-guide");
      let domParents = document.querySelectorAll(
        ".Polaris-Modal-Dialog__Modal",
      );
      for (let domParent of domParents) {
        if (elementContains(domParent, domCWG)) {
          domParent.setAttribute("style", "max-width: 100%");
        }
      }
    }
    if (personalizedInfo !== prevState.personalizedInfo) {
      // Fixed info
      let domContent = document.querySelector("#content-wrap");
      let domImg = document.querySelector("#imgs-wrap");
      if (domContent?.offsetHeight && domImg) {
        let height = domContent.offsetHeight;
        domImg.style.padding = `${height}px 0 0`;
      }
    }
  }

  handleClickOutside = (event) => {
    let domModals = document.querySelectorAll(".Polaris-Modal-Dialog__Modal");
    let domMI = document.getElementById("modal-image-wrap");
    for (let domModal of domModals) {
      if (elementContains(domModal, domMI)) {
        if (!elementContains(domModal, event.target)) {
          this.setState({ showModal: false });
        }
      }
    }
  };

  // onclick Image.
  onClickImg = (file) => () => {
    copyUrlImage(file); // copy url
    this.setState({
      showModal: true,
      imageUrl: file?.url || file?.thumbnailUrl,
    });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setBg = (bg) => {
    this.setState({ bg });
  };

  render() {
    const {
      files,
      limit,
      width,
      maxWidth,
      maxHeight,
      height,
      inline,
      isCompareDesign,
      designPosition,
      hasShadow,
      isCompare,

      hasCompareDesign,
      orderId,
      taskId,
      refetch,
      toggleActive,
      onNotificationChange,
      hasAcceptDesign,
      hasRejectDesign,
      includesBlack,
      toggleModalParent,

      attributes,
      taskRequirement,
      isDLead,
      userConfigDesign,
      isDesignOnline,
    } = this.props;

    const {
      inputSlider,
      showModalCompareDesign,
      showAcceptDesign,
      showRejectDesign,
    } = this.state;
    const images = files.files ? files.files : [];

    let artworkGuide;
    if (
      designPosition &&
      designPosition.artworkGuidelines &&
      designPosition.artworkGuidelines.length
    ) {
      artworkGuide = designPosition.artworkGuidelines
        .filter((i) => i.file)
        .filter(Boolean)
        .find((i) => ImageTypes.includes(i.file.mimeType));
    }
    let artworkGuideUrl =
      artworkGuide && artworkGuide.file && artworkGuide.file.url
        ? artworkGuide.file.url
        : "";

    let urlImage = images && images.filter((i) => i?.url).filter(Boolean)[0];
    urlImage = urlImage?.url;

    // Attributes
    let attributeMarkup;
    if (Array.isArray(attributes) && attributes.length) {
      attributeMarkup = attributes.map((att, index) => {
        if (!["size", "sizes"].includes(att?.name?.toLowerCase())) {
          return null;
        }
        return (
          <React.Fragment key={index}>
            <div className="attribute-item" key={index}>
              <TextStyle variation="strong">{att.name}: </TextStyle>
              <span>{att.option}</span>
            </div>
          </React.Fragment>
        );
      });
    }

    // taskRequirement
    const requirementMarkup =
      taskRequirement != null ? (
        <div className="task-requirement">
          <Heading element="h3">Requirements: </Heading>
          <span>{taskRequirement}</span>
        </div>
      ) : null;

    // Carousel arrow
    const SampleNextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={`${className} style-arrow`}
          style={{ ...style, display: "flex", right: "-13px" }}
          onClick={onClick}
        />
      );
    };

    const SamplePrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={`${className} style-arrow`}
          style={{ ...style, display: "flex", left: "-13px" }}
          onClick={onClick}
        />
      );
    };

    const settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    // Markup
    const imgItemCls = `cursor-pointer ${hasShadow ? "img-wrap" : ""} ${
      inline ? "inline-block" : "block"
    }`;
    const imgItemStyle = {
      width: width || 60,
      height: height || 60,
      objectFit: "cover",
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      backgroundColor: includesBlack ? "#000" : undefined,
    };
    const imgCls = designPosition ? displayFlexCSS : null;
    const dpCls = `modal-image-info ${isCompareDesign ? "compare" : ""}`;
    const dpStyle = designPosition ? modalImageInfoStyle : null;

    return (
      <ImageContainer>
        <ImgMarkup
          files={files}
          images={images}
          imgCls={imgCls}
          isCompare={isCompare}
          isDLead={isDLead}
          imgItemStyle={imgItemStyle}
          imgItemCls={imgItemCls}
          designPosition={designPosition}
          dimensions={this.state.dimensions}
          dpCls={dpCls}
          dpStyle={dpStyle}
          limit={limit}
          onClickImg={this.onClickImg}
          bg={this.state.bg}
          setBg={this.setBg}
          handImgLoader={(file) =>
            this.imageLoadHandler(
              isCompareDesign,
              file.url,
              file.id,
              designPosition,
            )
          }
        />
        {userConfigDesign ? (
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <label>Configured by:</label>
            <Badge status="success" children={userConfigDesign} />
          </div>
        ) : null}
        {
          hasCompareDesign && artworkGuideUrl ? (
            <div className="compare-design_btn pt-4">
              <Button onClick={this.toggleModalCD}>
                Compare with artguide
              </Button>
            </div>
          ) : null
          // (
          //   <CompareDesignContainer style={{ marginTop: "2rem" }}>
          //     <Query
          //       query={GET_VALUE_FOR_ORDER}
          //       variables={{ orderId }}
          //       onCompleted={(res) => {
          //         this.setState({
          //           personalizedInfo: res,
          //         });
          //       }}
          //       skip={!orderId}
          //       fetchPolicy="no-cache"
          //     >
          //       {({ data }) => {
          //         let value = data?.getFieldValueForOrder;
          //         return (
          //           <>
          //             {value && value.length > 0 ? (
          //               <div className="personalized-info">
          //                 <Heading element="h3">Personalized info:</Heading>
          //                 <div className="personalized-inner">
          //                   {value.map((item, index) => {
          //                     let label =
          //                       item && item.field
          //                         ? item.field.title
          //                           ? item.field.title
          //                           : item.field.name
          //                           ? item.field.name
          //                           : item.field.name
          //                         : null;
          //                     let value = item.value;
          //                     let hasImg = includes(
          //                       value.length && value[0],
          //                       META_DATA,
          //                     );
          //                     value =
          //                       value && Array.isArray(value) && value.join(", ");

          //                     return (
          //                       <div key={index}>
          //                         <label className="label">{label}:</label>
          //                         {!hasImg ? <span>{value}</span> : null}
          //                       </div>
          //                     );
          //                   })}
          //                 </div>
          //               </div>
          //             ) : null}
          //           </>
          //         );
          //       }}
          //     </Query>
          //   </CompareDesignContainer>
          // )
        }
        {isCompare ? (
          <SuspenseComp>
            <ModalWithImageZoomInOut
              open={this.state.showModal}
              onClose={() => this.setState({ showModal: false })}
              imageUrl={this.state.imageUrl}
              bg={this.state.bg}
            />
          </SuspenseComp>
        ) : (
          <Modal
            open={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            centered
            large
            sectioned
            title={"Product image"}
          >
            <SlideContainer id="modal-image-wrap">
              <Carousel arrows {...settings}>
                {images && images.length > 0
                  ? images.filter(Boolean).map((file, index) => {
                      let thumbnailUrl = file?.thumbnailUrl || "";
                      const isDriveLink = isURLDriveLink(thumbnailUrl);
                      if (isDriveLink) {
                        thumbnailUrl = replaceSizeForDriveLink(thumbnailUrl);
                      }

                      const imgUrl = file.url
                        ? file.url
                        : file.thumbnailUrl
                        ? file.thumbnailUrl
                        : null;

                      const src = isDriveLink ? thumbnailUrl : imgUrl;

                      // case: file is psd => downwload file
                      const hasOriginUrl = !!file?.originUrl;

                      const rejectReason = file?.rejectReason
                        ? ` - [${file.rejectReason}]`
                        : "";

                      let fileNameCustom = (file.name || "") + rejectReason;

                      const filePsd = hasOriginUrl ? (
                        <CustomLinkPolaris href={file.originUrl || "#"}>
                          <span
                            style={{ color: file?.style?.color || "inherit" }}
                          >
                            {fileNameCustom}
                          </span>
                        </CustomLinkPolaris>
                      ) : null;

                      return (
                        <div key={`file-${index}`}>
                          <Container>
                            {!this.state.loaded && <Spinner />}
                            <div className="media-wrapper">
                              <img
                                src={src}
                                alt={""}
                                style={{
                                  height: "auto",
                                  maxWidth: "760px",
                                }}
                                onLoad={() =>
                                  this.setState({
                                    loaded: true,
                                  })
                                }
                                onError={() =>
                                  this.setState({
                                    loaded: true,
                                  })
                                }
                              />
                              {file.name && (
                                <div
                                  className="media-meta mt-8"
                                  // style={{ alignSelf: "flex-end" }}
                                >
                                  <div
                                    className="media-meta-name"
                                    style={file?.style ? file.style : {}}
                                  >
                                    {filePsd ? filePsd : file.name}
                                  </div>
                                </div>
                              )}
                            </div>
                          </Container>
                          {file.url ? (
                            <div className="media-meta-link">
                              {/* <CustomLinkPolaris href={imgUrl} file={file}>
                              View origin
                            </CustomLinkPolaris> */}
                              {!!filePsd ? null : (
                                <ViewOriginFile file={file} />
                              )}
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </Carousel>
            </SlideContainer>
          </Modal>
        )}

        {/* Compare with guide */}
        <Modal
          title={"Compare with artguide"}
          open={showModalCompareDesign}
          onClose={this.toggleModalCD}
          sectioned
        >
          <CompareDesignContainer id="compare-with-guide">
            <Query
              query={GET_VALUE_FOR_ORDER}
              variables={{ orderId }}
              onCompleted={(res) => {
                this.setState({
                  personalizedInfo: res,
                });
              }}
              fetchPolicy="no-cache"
            >
              {({ data, error, loading }) => {
                let value = data?.getFieldValueForOrder;
                return (
                  <React.Fragment>
                    <div className="content_wrap" id="content-wrap">
                      <div className="header_wrap">
                        <div className="header">
                          <Heading element={"h3"}>
                            Compare with artguide
                          </Heading>
                        </div>
                        <div className="change-opacity">
                          <div className="change-opacity_inner">
                            <RangeSlider
                              label={"Change opacity"}
                              min={1}
                              max={100}
                              onChange={this.onChangeSlider}
                              value={
                                typeof inputSlider === "number"
                                  ? inputSlider
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        <div className="actions-wrapper">
                          {!isDesignOnline && hasAcceptDesign && (
                            <div className="action-accept">
                              <Button
                                onClick={this.toggleModalAD}
                                className="custom-btn-accpet"
                                children="Accept design"
                              />
                            </div>
                          )}
                          {!isDesignOnline && hasRejectDesign && (
                            <div className="action-reject">
                              <Button
                                onClick={this.toggleModalRD}
                                children="Reject design"
                                destructive
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {loading ? (
                        <Spinner size="small" />
                      ) : error ? (
                        <div>Error: {handleError(error.toString())}</div>
                      ) : (
                        <React.Fragment>
                          {value && value.length > 0 ? (
                            <div className="personalized-info">
                              <Heading element="h3">Personalized info:</Heading>
                              <div className="personalized-inner">
                                {value.map((item, index) => {
                                  let label =
                                    item && item.field
                                      ? item.field.title
                                        ? item.field.title
                                        : item.field.name
                                        ? item.field.name
                                        : item.field.name
                                      : null;
                                  let value = item.value;
                                  let hasImg = includes(
                                    value.length && value[0],
                                    META_DATA,
                                  );
                                  value =
                                    value &&
                                    Array.isArray(value) &&
                                    value.join(", ");

                                  return (
                                    <div key={index}>
                                      <label className="label">{label}:</label>
                                      {!hasImg ? (
                                        <span
                                          style={{ wordBreak: "break-all" }}
                                        >
                                          {value}
                                        </span>
                                      ) : null}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                          {attributeMarkup ? (
                            <div className="attributes-wrap">
                              <Heading element="h3">Attributes:</Heading>
                              {attributeMarkup}
                            </div>
                          ) : null}
                          {requirementMarkup}
                        </React.Fragment>
                      )}
                    </div>
                    <CompareArtworkContent
                      artworkGuideUrl={artworkGuideUrl}
                      urlImage={urlImage}
                      inputSlider={inputSlider}
                      isDLead={isDLead}
                    />
                    {/* <div className="imgs_wrap" id="imgs-wrap">
                      <div style={{ position: "relative" }}>
                        <div>
                          <img
                            alt=""
                            src={artworkGuideUrl}
                            style={{
                              position: "static",
                              opacity: "0.25",
                              zIndex: 0,
                            }}
                            className="img_item"
                          />
                        </div>
                        <img
                          src={urlImage}
                          alt=""
                          style={{
                            opacity: `${inputSlider * 0.01}`,
                            position: "absolute",
                            zIndex: 1,
                            top: 0,
                          }}
                          className="img_item"
                        />
                      </div>
                    </div> */}
                  </React.Fragment>
                );
              }}
            </Query>
            <AcceptDesignPolaris
              toggleShowModal={this.toggleModalAD}
              task={{ id: taskId, orderId }}
              refetch={refetch}
              open={showAcceptDesign}
              toggleActive={toggleActive}
              onNotificationChange={onNotificationChange}
              toggleModalParent={toggleModalParent}
            />
            <RejectDesignPolaris
              toggleShowModal={this.toggleModalRD}
              task={{ id: taskId, orderId }}
              refetch={refetch}
              open={showRejectDesign}
              toggleActive={toggleActive}
              onNotificationChange={onNotificationChange}
              toggleModalParent={toggleModalParent}
            />
          </CompareDesignContainer>
        </Modal>
      </ImageContainer>
    );
  }
}

function ViewOriginFile({ file }) {
  let thumbnailUrl = file?.thumbnailUrl;
  const isDriveLink = isURLDriveLink(thumbnailUrl);
  if (isDriveLink) {
    thumbnailUrl = replaceSizeForDriveLink(thumbnailUrl);
  }

  const imgUrl = isDriveLink ? thumbnailUrl : file?.url || file?.thumbnailUrl;
  const [open, toggleOpen] = useToggle(false);

  const modalRef = React.useRef(null); /// Polaris-Modal-Dialog__Modal

  // Context
  const { currentUser } = useAppContext();
  const isViewOriginFile = !!currentUser.viewOriginFile;

  const handleClick = React.useCallback(() => {
    toggleOpen();
    copyUrlImage(file);
  }, [toggleOpen, file]);

  const btnMakrup = isViewOriginFile ? (
    <CustomLinkPolaris href={imgUrl} file={file}>
      View origin
    </CustomLinkPolaris>
  ) : (
    <Button children="View origin" plain onClick={handleClick} />
  );

  React.useLayoutEffect(() => {
    if (!modalRef.current) return;
    const dialog = modalRef.current.closest(".Polaris-Modal-Dialog__Modal");
    if (dialog) {
      dialog.style.maxWidth = "100vw";
      dialog.style.width = "100vw";
      dialog.style.maxHeight = "100vh";
      dialog.style.height = "100vh";
      dialog.style.overflow = "scroll";
    }
  });

  return (
    <>
      {btnMakrup}
      <Modal
        title="View Origin File"
        open={open}
        onClose={toggleOpen}
        sectioned
      >
        <div ref={modalRef}>
          <img
            alt="view-original-file"
            style={{ width: "auto", height: "auto", padding: 5 }}
            src={imgUrl}
          />
        </div>
      </Modal>
    </>
  );
}

function ImgMarkup({
  files,
  images,
  imgCls,
  isCompare,
  imgItemStyle,
  imgItemCls,
  designPosition,
  dimensions,
  dpCls,
  dpStyle,
  limit,
  onClickImg,
  handImgLoader,
  isDLead,
  bg,
  setBg,
}) {
  return (
    files &&
    images &&
    images.filter(Boolean).map((file, index) => (
      <React.Fragment key={index}>
        {limit && limit > 0 && limit < index + 1 ? null : (
          <div key={file.id} className="modal-image-item" style={imgCls}>
            <ImgItem
              onClickImg={onClickImg(file)}
              imgItemCls={imgItemCls}
              imgItemStyle={imgItemStyle}
              file={file}
              isCompare={isCompare}
              isDLead={isDLead}
              onLoad={() => handImgLoader(file)}
              bg={bg}
              setBg={setBg}
            />
            {designPosition && (
              <div className={dpCls} style={dpStyle}>
                {designPosition.description && (
                  <p style={marginBottomCSS}>
                    Requirement: <strong>{designPosition.description}</strong>
                  </p>
                )}
                {dimensions &&
                  dimensions[file.id] &&
                  dimensions[file.id].height && (
                    <p>
                      Real Dimensions:{" "}
                      <strong>
                        {dimensions[file.id].width} x{" "}
                        {dimensions[file.id].height}
                        px
                        {dimensions[file.id].isRealDimensionOk && (
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "16px",
                            }}
                          >
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          </span>
                        )}
                      </strong>
                    </p>
                  )}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    ))
  );
}

function ImgItem({
  imgItemCls,
  onClickImg,
  imgItemStyle,
  file,
  isCompare,
  onLoad,
  isDLead,
  bg,
  setBg,
}) {
  // File id
  const id = file.id;

  const context = useThumbnailFileCtx?.();
  const active = context?.active;

  // Set img url
  const [url, setUrl] = React.useState(file.thumbnailUrl || file.url);
  // const [bg, setBg] = React.useState("transparent");
  const [loadImage, setLoadImage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Did mount
  React.useEffect(() => {
    let url = file.thumbnailUrl || file.url;
    if (isCompare) {
      url = file.url || file.thumbnailUrl;
    }
    setUrl(url);
  }, [isCompare, file, active]);
  // Mutation
  const [genThumbnail] = useMutation(GEN_THUMBNAIL_IMAGE);

  // Handle actions
  const handleGenThumbnail = React.useCallback(() => {
    if (id != null) {
      genThumbnail({
        variables: {
          files: [id],
          fileType: "id",
        },
      });
    }
  }, [id, genThumbnail]);

  return (
    <div className="modal-image img-box-shadow">
      {active && (
        <ThumbnailUrlByFile
          file={file}
          setUrl={setUrl}
          setLoading={setLoading}
        />
      )}
      {loading ? (
        <Spinner size="small" />
      ) : (
        <ImageItemWrap>
          {isDLead && loadImage && <ColorPickerComp setBg={setBg} />}
          {loadImage ? null : (
            <div className="img-skeleton">
              <SkeletonThumbnail />
            </div>
          )}
          <img
            className={imgItemCls}
            src={url}
            alt={""}
            key={file.id}
            style={{
              "--omg-custom-bg": bg,
              ...imgItemStyle,
              backgroundColor: bg ? bg : "transparent",
            }}
            onClick={onClickImg}
            onLoad={(...args) => {
              onLoad(...args);
              setLoadImage(true);
            }}
            onError={() => {
              if (file?.thumbnailUrl) {
                fetch(file.thumbnailUrl)
                  .then((res) => {
                    if (res?.status === 404) {
                      handleGenThumbnail();
                    }
                  })
                  .catch(() => {});
              }
              setUrl(file.url || file.thumbnailUrl);
            }}
          />
        </ImageItemWrap>
      )}
    </div>
  );
}

function ThumbnailUrlByFile({ file, setUrl, setLoading }) {
  const key = file?.key;

  // Query
  const [reGeneratePrintFile] = useMutation(RE_GENERATE_PRINT_FILE, {
    onError: () => {},
  });

  useEffect(() => {
    if (key != null) {
      setUrl(null);
      setLoading(true);
      reGeneratePrintFile({
        variables: {
          fileKey: key,
        },
      })
        .then((res) => {
          const data = res?.data?.regeneratePrintfileThumbnailByAPI;
          if (data?.success) {
            const url = data?.thumbUrl;
            setUrl((prev) => (url ? url : prev));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [key, setUrl, reGeneratePrintFile, setLoading]);
  return null;
}

export function ColorPickerComp({ setBg }) {
  const [color, setColor] = React.useState({
    hue: 0,
    brightness: 100,
    saturation: 0,
  });
  const colorRef = React.useRef(false);

  const setBgTimeout = React.useRef(null);
  React.useEffect(() => {
    setBgTimeout.current && clearTimeout(setBgTimeout.current);
    if (color != null && colorRef.current) {
      const res = hsbToRgb(color);
      if (res != null) {
        const { green, red, blue } = res;
        const result = `rgb(${red},${green},${blue})`;
        setBgTimeout.current = setTimeout(() => {
          setBg(result);
        }, 500);
      }
    }
  }, [setBg, color]);

  return (
    <div className="color-picker-wrap">
      <ColorPicker
        onChange={(...args) => {
          setColor(...args);
          colorRef.current = true;
        }}
        color={color}
      />
    </div>
  );
}

function CompareArtworkContent({
  artworkGuideUrl,
  urlImage,
  inputSlider,
  isDLead,
}) {
  const [showCP, setShowCP] = React.useState(false);
  const [bg, setBg] = React.useState("transparent");

  return (
    <ImageContainer2>
      {isDLead && showCP && <ColorPickerComp setBg={setBg} />}
      <div className="modal-image">
        <div className="imgs_wrap" id="imgs-wrap">
          <div style={{ position: "relative" }}>
            <div>
              <img
                alt=""
                src={artworkGuideUrl}
                style={{
                  position: "static",
                  opacity: "0.25",
                  zIndex: 0,
                }}
                className="img_item"
              />
            </div>
            <img
              src={urlImage}
              alt=""
              className={`img_item`}
              onLoad={() => {
                setShowCP(true);
              }}
              style={{
                "--omg-custom-bg": bg,
                opacity: `${inputSlider * 0.01}`,
                position: "absolute",
                zIndex: 1,
                top: 0,
                backgroundColor: bg ? bg : "transparent",
              }}
            />
          </div>
        </div>
      </div>
    </ImageContainer2>
  );
}

const ImageItemWrap = styled.div`
  .img-skeleton {
    position: absolute;
    inset: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow: hidden;
    background: #fff;

    .Polaris-SkeletonThumbnail {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex !important;
  justify-content: center;
  max-height: 90vh;
  width: 100%;
  overflow: auto;
`;

export const SlideContainer = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .ant-carousel .slick-dots li button {
    background: #1a202c;
  }
  .ant-carousel .slick-dots li,
  .ant-carousel .slick-dots li.slick-active,
  .ant-carousel .slick-dots li button {
    width: 16px;
    height: 16px;
  }
  .ant-carousel .slick-dots li button {
    border-radius: 100%;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 25px;
  }
  .media-wrapper {
    padding: 1rem;
    img {
      box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
    }
  }

  .style-arrow {
    font-size: 22px;
    line-height: 1.5;
    color: #1a202c;
    right: -13px;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: #fff;
    border-radius: 50%;
    z-index: 10;
    box-shadow: rgb(0 0 0 / 10%) 0px -1px 15px -3px,
      rgb(0 0 0 / 10%) 0px 4px 6px 2px;
  }
  .ant-carousel {
    .slick-prev,
    .slick-next {
      &:hover,
      &:focus {
        background: #fff;
        color: #1a202c;
      }
    }
  }

  .media-meta-link {
    text-align: center;
    padding-top: 2rem;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  .modal-image {
    padding: 1rem;
    position: relative;

    .color-picker-wrap {
      position: absolute;
      top: -17rem;
      right: 0;

      .Polaris-ColorPicker__MainColor {
        height: 12rem;
        width: 12rem;
      }

      .Polaris-ColorPicker__HuePicker,
      .Polaris-ColorPicker__AlphaPicker {
        height: 12rem;
        width: 1.8rem;
      }
    }
  }
  .img-wrap {
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
      rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
  }

  .Polaris-Badge {
    font-weight: 600;
  }
`;

const ImageContainer2 = styled.div`
  .color-picker-wrap {
    position: fixed;
    right: 0;
    top: 20.5rem;
    z-index: 1000;

    .Polaris-ColorPicker__MainColor {
      height: 12rem;
      width: 12rem;
    }

    .Polaris-ColorPicker__HuePicker,
    .Polaris-ColorPicker__AlphaPicker {
      height: 12rem;
      width: 1.8rem;
    }
  }
`;

const CompareDesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  .content_wrap {
    position: fixed;
    background: #fff;
    width: calc(100% - 4rem);
    padding-top: 2rem;
    padding-bottom: 2rem;
    z-index: 10;
    margin-top: -2rem;
  }
  .header_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
    .header {
      h2 {
        font-size: 1.2em;
      }
    }
    .change-opacity {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      h3 {
        margin-left: 6px;
      }
      .change-opacity_inner {
        width: 300px;
      }
    }
  }
  .imgs_wrap {
    position: relative;
    .img_item {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 8900px;
      max-height: 10000px;
    }
  }
  .personalized-info {
    h4 {
      font-weight: 500;
      font-size: 1.2em;
      display: inline-block;
    }
    .personalized-inner {
      display: flex;
      flex-direction: row;
      column-gap: 1.6rem;
      row-gap: 1.6rem;
      flex-wrap: wrap;
      justify-content: flex-start;
      label.label {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        font-size: 1em;
        margin-right: 1rem;
      }
    }
  }
  .attributes-wrap,
  .task-requirement {
    margin-top: 1.6rem;
  }

  .personalized-info,
  .attributes-wrap {
    margin-right: 15rem;
  }

  .actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const displayFlexCSS = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};
const modalImageInfoStyle = {
  fontSize: "12px",
};
const marginBottomCSS = {
  marginBottom: "5px",
};

export default ModalImageClaimPolaris;
