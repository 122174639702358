import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Popconfirm, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { LIST_PRODUCT_BASE } from "./ListProductBase";

const DELETE_PRODUCT_BASES = gql`
  mutation deleteProductBases($ids: [String!]!) {
    deleteProductBases(ids: $ids) {
      deleted
    }
  }
`;

export default class DeleteProductBase extends Component {
  static propTypes = {
    ids: PropTypes.array,
    onCompleted: PropTypes.func,
  };
  state = {
    loading: false,
  };

  render() {
    const { ids, onCompleted, refetch } = this.props;
    return (
      <Mutation
        mutation={DELETE_PRODUCT_BASES}
        onCompleted={() => {
          this.setState({ loading: false });
          notification.success({
            message: "Delete product bases success!",
          });
          if (refetch) {
            refetch();
          }
        }}
        onError={(err) => {
          this.setState({ loading: false });
          notification.error({ message: err.toString() });
        }}
      >
        {(deleteProductBase, { client }) => {
          return (
            <Popconfirm
              title="Are you sure delete this product bases?"
              onConfirm={() => {
                this.setState({ loading: true });
                deleteProductBase({ variables: { ids } }).then((res) => {
                  if (res && res.data) {
                    if (onCompleted) onCompleted();
                    try {
                      const query = {
                        query: LIST_PRODUCT_BASE,
                        variables: {
                          filter: {
                            limit: 50,
                            offset: 0,
                          },
                        },
                      };
                      const cache = client.readQuery(query);

                      client.writeQuery({
                        ...query,
                        data: {
                          ...cache,
                          productBases: {
                            ...cache.productBases,
                            total: cache.productBases.total - ids.length,
                            nodes: [
                              ...cache.productBases.nodes.filter(
                                (product) =>
                                  !res.data.deleteProductBases.deleted.includes(
                                    product.id
                                  )
                              ),
                            ],
                          },
                        },
                      });
                    } catch (_) {}
                  }
                });
              }}
            >
              <Button icon={<DeleteOutlined />} loading={this.state.loading}>
                Delete
              </Button>
            </Popconfirm>
          );
        }}
      </Mutation>
    );
  }
}
