import React, { useCallback, useState, useEffect, useRef } from "react";
import { Button, buttonFrom, Modal, Stack, TextField } from "@shopify/polaris";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import styled from "styled-components";

import useToggle from "../../../../../hooks/useToggle";
import {
    BULK_DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
    CREATE_TASK_BASE_MOCKUP_FOR_SUBMISSION,
    DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
    REVIEW_IDEA_TASK,
    RE_GEN_MOCKUP_FOR_IDEA_TASK,
    SET_MAIN_TASK_BASE_MOCKUP,
} from "../../../../../graphql/mutations";
import { GENERATED_MOCKUP_FOR_IDEA_TASK } from "../../../../../graphql/subscription";
import {
    elementContains,
    genStrToBtoA,
    handleError,
} from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";
import { STATUS } from "./ReviewTask";
import { checkStatusTask } from "../../utils";
import { omitProduct, omitUser } from "./SubmitToReview2";
import { useCreateIdea } from "../../context/createIdea";
import { Mockup2DApp } from "../../../../../config";
import { IFRAME_MESSAGE_TYPE } from "../../../../../constants";

export function ReviewTaskInner({
    item,
    taskId,
    onCloseParent,
    taskValue,
    activeKey,
}) {
    const ideaTaskId = taskId;
    const { isNeedReview } = checkStatusTask(item.status);

    // State
    const [open, toggleOpen] = useToggle(false);
    const [isRejected, setIsRejected] = useState(null);
    const [rejectReason, setRejectReason] = useState("");
    const status = isRejected ? STATUS.Reject : STATUS.Accept;
    const onCloseRef = useRef(null);

    // Context
    const { products } = useCreateIdea();
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [reviewTask, { loading }] = useMutation(REVIEW_IDEA_TASK, {
        onCompleted: () => {
            setNotify({
                msg: `${
                    isRejected ? "Rejected" : "Approved"
                } to this tasks successfully.`,
                err: false,
            });
            handleClose();
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    const handleClose = useCallback(() => {
        toggleOpen(false);
        onCloseParent && onCloseParent();

        // onClose parent modal
        onCloseRef.current && clearTimeout(onCloseRef.current);
        onCloseRef.current = setTimeout(() => {
            refetch && refetch();
            setFilter &&
                setFilter((prev) => {
                    const clone = Object.assign({}, prev);
                    return { ...clone };
                });
        }, 300);
    }, [toggleOpen, onCloseParent, refetch, setFilter]);

    // Actions
    const handleSubmit = useCallback(() => {
        toggleToast && toggleToast(true);
        setNotify && setNotify({ msg: null, err: false });

        const { taskBaseGroupId, baseConfigs, mainImageId } = genInput(
            item,
            isRejected ? rejectReason : undefined
        );

        if (ideaTaskId != null) {
            const input = {
                ideaTaskId,
                baseGroups: [{ taskBaseGroupId, baseConfigs, mainImageId }],
                status,
            };

            reviewTask({ variables: { input } });
        }
    }, [
        reviewTask,
        isRejected,
        toggleToast,
        setNotify,
        rejectReason,
        item,
        ideaTaskId,
        status,
    ]);

    const handleAction = useCallback(
        (act) => {
            return () => {
                setIsRejected(act);
                toggleOpen();
            };
        },
        [toggleOpen]
    );

    const handleReasonChange = useCallback((value) => {
        setRejectReason(value);
    }, []);

    // Did mount
    useEffect(() => setRejectReason(""), [open]);

    // Only have custom mockup
    const noTmpDefault = (item?.bases || [])
        .filter((i) => i?.taskBaseSubmissions?.length > 0)
        .every((b) => {
            const { taskBaseSubmissions } = b;
            const [sub] = taskBaseSubmissions || [];

            const customMockups =
                sub?.isCustomMockup && sub?.customMockups?.length > 0;
            return customMockups;
        });

    return (
        isNeedReview && (
            <Wrapper>
                {noTmpDefault ? (
                    <Button
                        children="Approve"
                        onClick={handleAction()}
                        primary
                    />
                ) : (
                    <ButtonApprove
                        handleSubmit={handleSubmit}
                        setIsRejected={setIsRejected}
                        taskValue={taskValue}
                        loading={loading}
                        item={item}
                        products={products}
                        setNotify={setNotify}
                        toggleToast={toggleToast}
                        activeKey={activeKey}
                    />
                )}
                <Button children="Reject" onClick={handleAction(true)} />
                <Modal
                    title={`${isRejected ? "Reject" : "Approve"} this task`}
                    sectioned
                    large
                    primaryAction={{
                        content: "Submit",
                        onAction: handleSubmit,
                        loading: loading,
                    }}
                    secondaryActions={[
                        { content: "Cancel", onAction: toggleOpen },
                    ]}
                    open={open}
                    onClose={toggleOpen}
                >
                    {isRejected ? (
                        <TextField
                            value={rejectReason}
                            multiline={4}
                            label="Reason"
                            placeholder="Enter reason reject"
                            onChange={handleReasonChange}
                        />
                    ) : (
                        <span>Are you sure to approve this idea task?</span>
                    )}
                </Modal>
            </Wrapper>
        )
    );
}

function ButtonApprove({
    handleSubmit,
    taskValue,
    setIsRejected,
    loading,
    item,
    products,
    setNotify,
    toggleToast,
    activeKey,
}) {
    const ideaId = taskValue?.id;

    // State
    const [open, toggleOpen] = useToggle(false);
    const [openIframeSuccess, setOpenIframeSuccess] = useState(false);
    const [changedLayer, setChangedLayer] = useState(false);
    const iframeRef = useRef(null);
    const onCloseRef = useRef(null);
    const activeKeyRef = useRef(activeKey);
    activeKeyRef.current = activeKey;

    // Subscription
    const { data } = useSubscription(GENERATED_MOCKUP_FOR_IDEA_TASK);

    // Mutation
    const [reGen] = useMutation(RE_GEN_MOCKUP_FOR_IDEA_TASK);
    const [deleteTaskBaseMockups] = useMutation(
        DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Delete mockup successfully.`,
                    err: false,
                });
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );
    const [createMockupSubmission] = useMutation(
        CREATE_TASK_BASE_MOCKUP_FOR_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Create mockups successfully.`,
                    err: false,
                });
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    const [bulkDeleteTaskBaseMockups] = useMutation(
        BULK_DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION,
        {
            onCompleted: () => {
                setNotify({
                    msg: `Delete mockups successfully.`,
                    err: false,
                });
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    const [setMainMockup] = useMutation(SET_MAIN_TASK_BASE_MOCKUP, {
        onCompleted: () => {
            setNotify({
                msg: `Update main mockup successfully.`,
                err: false,
            });
        },
        onError: (err) => {
            setNotify({ msg: handleError(err?.toString()), err: true });
        },
    });

    // Actions
    const itemId = item?.id;

    useEffect(() => {
        if (!openIframeSuccess) return;
        let taskBaseGroups = (taskValue?.taskBaseGroups || []).filter(
            (group) => group.id === itemId
        );
        let newProducts = (products || []).filter((p) => p.id === itemId);

        if (taskBaseGroups?.length > 0 && newProducts?.length > 0) {
            onCloseRef.current && clearTimeout(onCloseRef.current);
            onCloseRef.current = setTimeout(() => {
                const baseGroups = omitProduct(
                    taskBaseGroups,
                    newProducts,
                    true
                );
                const newBaseGroups = getProductsHaveSub(baseGroups);
                let msgStr = JSON.stringify(newBaseGroups);

                let msg = genStrToBtoA(msgStr);
                // let msg = window.btoa(msgStr);

                if (!msg || msg.length === 0) return;

                const type = IFRAME_MESSAGE_TYPE.SendReview;
                const msgBtoA = genStrToBtoA(
                    JSON.stringify({ type, message: msg })
                );
                // const msgBtoA = window.btoa(
                //     JSON.stringify({ type, message: msg })
                // );
                iframeRef.current &&
                    iframeRef.current.contentWindow.postMessage(msgBtoA, "*");
            }, 500);
        }
    }, [openIframeSuccess, taskValue, products, itemId]);

    const handleSendData = useCallback(
        (open) => {
            setOpenIframeSuccess(open);
        },
        [taskValue, products]
    );

    const handleClick = useCallback(() => {
        toggleOpen();
        setIsRejected(false);
        setOpenIframeSuccess(false);
    }, [toggleOpen, setIsRejected]);

    const handleRegenerate = useCallback(
        (baseGroups) => {
            if (ideaId != null) {
                const input = {
                    ideaId,
                    baseGroups,
                };

                reGen({
                    variables: {
                        input,
                    },
                })
                    .then((res) => {
                        setChangedLayer(false);
                        if (res?.data?.reGenMockupForIdeaTask != null) {
                            const msg = res.data.reGenMockupForIdeaTask;
                            if (!msg || msg.length === 0) return;

                            const type = IFRAME_MESSAGE_TYPE.Regenerate;
                            const msgBtoA = genStrToBtoA(
                                JSON.stringify({ type, message: msg })
                            );
                            // const msgBtoA = window.btoa(
                            //     JSON.stringify({ type, message: msg })
                            // );
                            iframeRef.current &&
                                iframeRef.current.contentWindow.postMessage(
                                    msgBtoA,
                                    "*"
                                );
                        }
                    })
                    .catch(() => {});
            }
        },
        [ideaId, reGen]
    );

    const deleteMockups = useCallback(
        (input) => {
            const activeKey = activeKeyRef.current;
            if (activeKey) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });
                deleteTaskBaseMockups({
                    variables: {
                        ...input,
                    },
                });
            }
        },
        [deleteTaskBaseMockups, toggleToast, setNotify]
    );

    const bulkDeleteMockups = useCallback(
        (input) => {
            const activeKey = activeKeyRef.current;
            if (activeKey) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });
                bulkDeleteTaskBaseMockups({
                    variables: {
                        ...input,
                    },
                });
            }
        },
        [bulkDeleteTaskBaseMockups, toggleToast, setNotify]
    );
    const chooseOtherTemplate = useCallback(
        (input) => {
            const activeKey = activeKeyRef.current;
            if (activeKey) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });
                createMockupSubmission({
                    variables: {
                        ...input,
                    },
                });
            }
        },
        [createMockupSubmission, toggleToast, setNotify]
    );

    const handleUpdateMainMockup = useCallback(
        (ids) => {
            const activeKey = activeKeyRef.current;
            if (activeKey) {
                toggleToast && toggleToast(true);
                setNotify && setNotify({ msg: null, err: false });
                if (ids?.length > 0) {
                    (async function () {
                        await Promise.all(
                            ids.map(async (id) => {
                                try {
                                    const value = await setMainMockup({
                                        variables: {
                                            id,
                                        },
                                    });
                                    return value;
                                } catch (err) {
                                    return handleError(err.toString());
                                }
                            })
                        );
                        handleSubmit();
                    })();
                    return;
                }
                handleSubmit();
            }
        },
        [setMainMockup, handleSubmit, setNotify, toggleToast]
    );

    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            if (typeof data === "object" && "source" in data) return;

            const objData = typeof data === "string" ? JSON.parse(data) : null;
            if (objData != null) {
                const { type, baseGroups, open, input } = objData;
                switch (type) {
                    case IFRAME_MESSAGE_TYPE.Regenerate: {
                        if (baseGroups?.length > 0) {
                            handleRegenerate(baseGroups);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.OpenSuccess: {
                        handleSendData(open);
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.ChangedLayer: {
                        setChangedLayer(true);
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.DeleteMockups: {
                        if (input != null) {
                            deleteMockups(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.ChooseOtherTemplate: {
                        if (input != null) {
                            chooseOtherTemplate(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.BulkDeleteMockups: {
                        if (input != null) {
                            bulkDeleteMockups(input);
                        }
                        break;
                    }
                    case IFRAME_MESSAGE_TYPE.GetMainMockupId: {
                        handleUpdateMainMockup(input);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [
            handleRegenerate,
            handleSendData,
            bulkDeleteMockups,
            chooseOtherTemplate,
            deleteMockups,
            handleUpdateMainMockup,
        ]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage, false);
        };
    }, [handleReceiveMessage]);

    useEffect(() => {
        if (data?.subscriptionGeneratedMockupForIdeaTask != null) {
            const msg = data.subscriptionGeneratedMockupForIdeaTask;

            const newData = {
                type: IFRAME_MESSAGE_TYPE.Subscriptions,
                message: msg,
            };
            const msgBtoA = genStrToBtoA(JSON.stringify(newData));
            // const msgBtoA = window.btoa(JSON.stringify(newData));

            iframeRef.current &&
                iframeRef.current.contentWindow.postMessage(msgBtoA, "*");
        }
    }, [data]);

    // Markup
    const iframeStyle = {
        border: "none",
        width: "100%",
        height: "calc(100% - 0.5rem)",
    };

    const userInfo = omitUser();
    const userInfoString = JSON.stringify(userInfo);
    const userInfoProp = window.btoa(userInfoString);

    const checkChanged = useCallback(
        (cb) => {
            if (changedLayer) {
                const confirm = window.confirm(
                    "It looks like you have been editing something. If you leave before saving, your changes will be lost."
                );
                if (!confirm) return;
            }
            cb();
        },
        [changedLayer]
    );

    const footerMarkup = (
        <Stack>
            <Stack.Item fill>
                {buttonFrom({
                    content: "Close",
                    onAction: () => checkChanged(toggleOpen),
                })}
            </Stack.Item>
            {buttonFrom(
                {
                    content: "Approve",
                    onAction: () => {
                        function onApprove() {
                            const newData = {
                                type: IFRAME_MESSAGE_TYPE.GetMainMockupId,
                            };
                            const msgBtoA = genStrToBtoA(
                                JSON.stringify(newData)
                            );
                            iframeRef.current &&
                                iframeRef.current.contentWindow.postMessage(
                                    msgBtoA,
                                    "*"
                                );
                        }

                        checkChanged(onApprove);
                    },
                    loading: loading,
                },
                { primary: true }
            )}
        </Stack>
    );

    useEffect(() => {
        let domCD = document.querySelector("#approve-idea-task");
        let domModal = document.querySelectorAll(".Polaris-Modal-Section");
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );

        for (let domChild of domModal) {
            if (elementContains(domChild, domCD)) {
                domChild.setAttribute("style", "padding: 0; height: 100%");
            }
        }

        for (let domParent of domParents) {
            if (elementContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100vw - 2rem); height: 100vh; max-height: calc(100vh - 2rem);"
                );
            }
        }
    });

    return (
        <>
            <Button children="View Mockups" primary onClick={handleClick} />
            <Modal
                open={open}
                onClose={() => checkChanged(toggleOpen)}
                sectioned
                large
                title="Mockup preview"
                footer={footerMarkup}
            >
                <iframe
                    ref={iframeRef}
                    id="approve-idea-task"
                    title="Review Design"
                    // src={`http://localhost:3000/design?user=${userInfoProp}&pathname=design`}
                    src={`${Mockup2DApp}/design?user=${userInfoProp}&pathname=design`}
                    style={iframeStyle}
                />
            </Modal>
        </>
    );
}

function genInput(product, rejectReason) {
    if (!product) return null;

    const { id, bases, mainMockups } = product || {};
    const taskBaseGroupId = id;
    const [mainImageId] = mainMockups || [];
    const baseConfigs = (bases || [])
        .map((b) => {
            const { baseConfigId, taskBaseSubmissions } = b || {};
            const [taskBaseSubmission] = taskBaseSubmissions || [];
            const submissionId = taskBaseSubmission?.id;

            return {
                taskBaseConfigId: baseConfigId,
                submissionId,
                rejectReason,
            };
        })
        .filter((i) => i.submissionId != null);

    return {
        taskBaseGroupId,
        baseConfigs,
        mainImageId,
    };
}

export function getProductsHaveSub(products) {
    if (!products || products.length === 0) return [];

    return products
        .map((p) => {
            const { bases } = p;
            if (!bases || bases.length === 0) return null;

            const newBases = bases
                .map((b) => {
                    const { taskBaseSubmissions, mockupTemplates } = b || {};
                    const noTmpDefault = (mockupTemplates || []).every(
                        (i) => !i.isDefault
                    );

                    if (noTmpDefault) return null;

                    if (
                        !taskBaseSubmissions ||
                        taskBaseSubmissions?.length === 0
                    )
                        return null;

                    const [sub] = taskBaseSubmissions;
                    const { customMockups } = sub || {};

                    if (customMockups?.length === 0) {
                        return b;
                    }

                    return null;
                })
                .filter(Boolean);

            if (newBases.length === 0) return null;

            return {
                ...p,
                bases: newBases,
            };
        })
        .filter(Boolean);
}

const Wrapper = styled.div`
    margin-top: 1.6rem;
    padding-top: 1.6rem;
    border-top: 1px solid #c4cdd5;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 1.6rem;
`;
