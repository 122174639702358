import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER, TEAM_ROLE } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ProductPushFiltersPolaris } from "./ProductPushFiltersPolaris";
import { TablePushProductPolaris } from "./TablePushProductPolaris";

export const LIST_PRODUCT_PUSH = gql`
  query pushList($filter: PushFilter) {
    pushList(filter: $filter) {
      total
      nodes {
        id
        title
        continue
        paused
        onlyGenerateCsv
        generateCsvStatus
        csvFiles {
          name
          url
        }
        tags {
          id
          tag {
            id
            name
          }
          included
        }
        collections {
          id
          collection {
            id
            name
          }
          included
        }
        stores {
          id
          title
        }
        info {
          total
          done
          error
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "collections", "storeId", "tags"];

export const ProductPushPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      if (initialFilter.collections) {
        initialFilter.collections = [initialFilter.collections];
      }
      if (initialFilter.tags) {
        initialFilter.tags = [initialFilter.tags];
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const { store, currentUser } = useContext(AppContext);
  const userRole = _.get(currentUser, "teamUser.role", null);
  const isSM = [TEAM_ROLE.StoreManager].includes(userRole);
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageProductPush")) || 20,
    offset: 0,
    search: null,
    collections: null,
    tags: null,
    storeId: null,
    ...initFilter,
  });

  const { data, loading, error } = useQuery(LIST_PRODUCT_PUSH, {
    variables: {
      filter: {
        ...filter,
        storeId: store
          ? isSM && store.id === "all"
            ? null
            : store.id
          : filter.storeId,
      },
    },
  });

  useEffect(() => {
    let { offset, limit, ...rest } = filter;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;
  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <ProductPushFiltersPolaris
          filter={filter}
          onChange={({ search, collections, tags, storeId }) =>
            setFilter((prevState) => {
              if (
                !_.isEqual(prevState.search, search) ||
                !_.isEqual(prevState.collections, collections) ||
                !_.isEqual(prevState.tags, tags) ||
                !_.isEqual(prevState.storeId, storeId)
              ) {
                prevState.offset = 0;
              }
              return {
                ...prevState,
                search,
                collections,
                tags,
                storeId,
              };
            })
          }
        />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {error.toString()}</div>
        ) : data?.pushList?.nodes?.length > 0 ? (
          <TablePushProductPolaris
            data={data}
            filter={filter}
            setFilter={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageProductPush", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};
