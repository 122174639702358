import { ButtonGroup, Page } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { ToastContextProvider } from "../shared/ToastContext";
import { EditUserFormPolaris } from "./user/EditUserFormPolaris";
import { GetUserToken } from "./user/GetUserToken";
import SendEmailActivation from "./user/SendEmailActivation";

const Container = styled.div``;

class EditUser extends Component {
  state = {
    user: null,
  };

  getUser = (user) => {
    this.setState({ user });
  };

  render() {
    const userId = this.props.match.params.id;

    const { user } = this.state;
    const action = (
      <ButtonGroup>
        {user && !user.verified && <SendEmailActivation id={user?.id} />}
        <GetUserToken userId={userId} overrides={{ primary: true }} />
      </ButtonGroup>
    );

    return (
      <Container>
        <ToastContextProvider>
          <Page
            breadcrumbs={[{ content: "Users", url: "/admin/users" }]}
            title={"Edit User"}
            primaryAction={action}
          >
            <EditUserFormPolaris
              redirect={`/admin/users`}
              id={this.props.match.params.id}
              getUser={this.getUser}
            />
          </Page>
        </ToastContextProvider>
      </Container>
    );
  }
}

export default EditUser;
