import React from "react";
import { Heading, Button, Scrollable } from "@shopify/polaris";
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .sheet-header {
    align-items: center;
    border-bottom: 1px solid #dfe3e8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
  }
  .sheet-footer {
    align-items: center;
    border-top: 1px solid #dfe3e8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
  }
`;

export const SheetPolaris = ({ title, handleSheetChange, children }) => {
  return (
    <Container>
      <div className="sheet-header">
        <Heading>{title ? title : "heading"} </Heading>
        <Button
          accessibilityLabel="Cancel"
          icon={MobileCancelMajorMonotone}
          onClick={handleSheetChange}
          plain
        />
      </div>
      <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
        {children}
      </Scrollable>
    </Container>
  );
};
