import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  ButtonGroup,
  ChoiceList,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { KPI } from "../../../constants/task";
import { useAppContext } from "../../../context";
import { GET_USER_BY_ROLES } from "../../../graphql/queries";
import { checkRole, isRootTeam } from "../../../helper";
import { TEAM_ROLE, UPLOAD_FOLDER } from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import OnlineDesignTeamsSelect from "../../members/components/OnlineDesignTeamsSelect";
import { AutoCompletePolaris } from "../../shared/AutoCompletePolaris";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { DesignTaskDeadlinePolaris } from "./DesignTaskDeadlinePolaris";
import { isArtistTask } from "../utils";

const Container = styled.div`
  .btns_wrap {
    padding-top: 1.6rem;
    display: flex;
    flex-direction: row-reverse;
  }
`;

const KPI_OPTIONS = KPI.map((i) => ({ value: String(i), label: String(i) }));

export const DesignTaskFormPolaris = (props) => {
  // Context
  const { currentUser } = useAppContext();
  const { isOnline } = checkRole(currentUser);
  const isRoot = isRootTeam(currentUser);

  const { loadingM, toggleShowModal, onSubmit, value } = props;
  const { data } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [TEAM_ROLE.Designer],
    },
  });
  // State
  const [designers, setDesigners] = useState([]);
  const [assigneeId, setAssigneeId] = useState(null);
  const [kpi, setKpi] = useState("0.25");
  const [deadline, setDeadline] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [description, setDescription] = useState(null);
  const [files, setFiles] = useState([]);
  const [asssignType, setAssignType] = useState(["designer"]);
  const [errors, setErrors] = useState({
    assignee: null,
  });

  useEffect(() => {
    let newData =
      data && data.listUserTeamByRoles && data.listUserTeamByRoles.length > 0
        ? data.listUserTeamByRoles
            .map(({ user }) => {
              if (user) {
                return {
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                };
              }
              return null;
            })
            .filter(Boolean)
        : [];
    setDesigners(newData);
  }, [data]);
  useEffect(() => {
    if (value) {
      let designerId = value.assignee?.id;
      let kpi = value.kpi || "0.25";
      let requirement = value.requirement;
      let description = value.description;
      let deadline = value.deadline;
      let designOnlineTeamID = value.designOnlineTeamID;

      setAssigneeId(designerId);
      setKpi(String(kpi));
      setRequirement(requirement);
      setDescription(description);
      setDeadline(deadline);

      let resourceVal = [];
      if (!isArtistTask(value) && value.resources?.length > 0 ) {
        resourceVal = value.resources
      }

      setFiles(resourceVal);
      // setFiles(value.resources?.length > 0 ? value.resources : []);
      if (designOnlineTeamID && !isOnline) {
        setAssignType(["team"]);
      }
    }
  }, [value, designers, isOnline]);

  useEffect(() => {
    document.addEventListener("keydown", handleClickEnter);
    return () => {
      document.removeEventListener("keydown", handleClickEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeId, kpi, deadline, requirement, description]);

  // Handle actions
  const handleAssigneeValue = useCallback((value) => {
    let a = null;
    if (!value) {
      a = "Designer is required.";
    }
    setErrors((prev) => ({ ...prev, assignee: a }));
  }, []);

  const teamIdRef = React.useRef(null);
  const isAssignToDesigner = asssignType.includes("designer");

  const handleSubmit = useCallback(() => {
    if (isAssignToDesigner) handleAssigneeValue(assigneeId);

    const fileIds =
      files?.length > 0 ? files.map((item) => item.id).filter(Boolean) : null;

    let input = {
      deadline,
      requirement,
      description,
      resources: fileIds,
    };
    let canSubmit = false;
    if (isAssignToDesigner) {
      input.designerId = assigneeId;
      canSubmit = !!assigneeId;
    } else {
      const teamId = teamIdRef.current?.getTeamId();
      input.designOnlineTeamID = teamId;
      canSubmit = !!teamId;
    }

    if (!isOnline) {
      // if not online design => require kpi
      canSubmit = canSubmit && kpi;
      input.kpi = Number(kpi);
    }

    if (canSubmit) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assigneeId,
    kpi,
    deadline,
    requirement,
    description,
    files,
    isAssignToDesigner,
    isOnline,
  ]);

  const handleClickEnter = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit],
  );
  let deadline2 = value?.deadline;

  const handleFileChange = useCallback((value) => {
    let files = value && value.length > 0 ? value : [];
    setFiles(files);
  }, []);

  return (
    <Container>
      <FormLayout>
        {!isOnline && (
          <ChoiceList
            title="Assign to"
            onChange={setAssignType}
            selected={asssignType}
            choices={[
              { label: "Designer", value: "designer" },
              { label: "Design Online Team", value: "team" },
            ]}
          />
        )}
        {isAssignToDesigner ? (
          <AutoCompletePolaris
            options={designers}
            onChange={(value) => setAssigneeId(value)}
            label="Choose designer"
            required
            error={errors.assignee}
            value={assigneeId}
          />
        ) : (
          <OnlineDesignTeamsSelect
            ref={teamIdRef}
            value={value?.designOnlineTeamID}
          />
        )}
        {!isOnline && (
          <div>
            <ComponentLabelPolaris label="KPI" required />
            <Select
              label="Kpi"
              labelHidden
              options={KPI_OPTIONS}
              value={kpi}
              onChange={(newValue) => {
                setKpi(() => newValue);
              }}
            />
          </div>
        )}
        {isRoot && (
          <DesignTaskDeadlinePolaris
            onChange={(value) => setDeadline(() => value)}
            value={deadline2 ? new Date(moment(deadline2)) : null}
          />
        )}
        <TextField
          label="Requirements"
          onChange={(value) => setRequirement(value)}
          value={requirement}
          multiline={5}
        />
        <TextField
          label="Resources"
          onChange={(value) => setDescription(value)}
          value={description}
          multiline={5}
        />
        <div>
          <label>Resource Files:</label>
          <MediaSelectorButtonPolaris
            value={files}
            prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
            folder={UPLOAD_FOLDER.PSD_RESOURCE}
            accept={`image/*`}
            onChange={handleFileChange}
            multiple
            sizeSmall
            isArtistTask={value ? isArtistTask(value): false}
          />
        </div>
        <div className="btns_wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={toggleShowModal} />
            <Button
              children="Update task"
              primary
              onClick={handleSubmit}
              loading={loadingM}
            />
          </ButtonGroup>
        </div>
      </FormLayout>
    </Container>
  );
};
