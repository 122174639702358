import React, { useState, useEffect } from "react";
import { Card, Button, Checkbox, Select } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { capitalize } from "lodash";
import styled from "styled-components";

import { FIELDS_EXPORT_AMAZON, TEMPLATES_EXPORT_AMAZON } from "../../variable";
import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { TableMappingExportPolaris } from "./TableMappingExportPolaris";
import { UploadTemplateFilePolaris } from "./UploadTemplateFilePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";

const Container = styled.div`
  .header_wrap {
    padding: 2rem 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .btns_wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3rem;
  }
  .choose-template {
    padding: 1.6rem 1.6rem 0;
    width: 50%;
  }
`;

const TEMPLATES_OPTIONS = [{ value: "default", label: "Choose a template" }];
for (let [key] of Object.entries(TEMPLATES_EXPORT_AMAZON)) {
  TEMPLATES_OPTIONS.push({ value: key, label: capitalize(key) });
}

const MUTATION_EXPORT_MAPPING = gql`
  mutation amazonExportMappingFieldSuggestion($fileId: ID!) {
    amazonExportMappingFieldSuggestion(fileId: $fileId) {
      fields {
        field
        value
        type
        targetField
        active
      }
      labels
    }
  }
`;

export const MappingExportFieldsPolaris = (props) => {
  // Props
  const {
    onChange,
    // handleStep,
    handleTemplateFileId,
    onSubmit,
    disableBtnSubmit,
    loading: loadingProps,
    templateFileId,
    templateFile,
    mapping,
  } = props;

  // State
  const [fileId, setFileId] = useState(null);
  const [deselectedOptions, setDeseletedOptions] = useState([]);
  const [optionsActive, setOptionsActive] = useState([]);
  const [checkAllField, setCheckAllField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("default");
  const [isChangeValues, setIsChangeValues] = useState(false);

  // Mutation
  const [exportMapping, { data }] = useMutation(MUTATION_EXPORT_MAPPING, {
    onError: () => {},
    onCompleted: () => {
      setLoading(() => false);
    },
  });

  // Get data
  useEffect(() => {
    let newData =
      mapping && mapping.length > 0
        ? mapping.map((item, index) => ({
            ...item,
            originIndex: index,
          }))
        : [];
    setDeseletedOptions(() => [...newData]);
    setFileId(() => templateFileId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      let newData =
        data.amazonExportMappingFieldSuggestion?.fields?.length > 0
          ? data.amazonExportMappingFieldSuggestion.fields.map(
              (item, index) => ({
                ...item,
                originIndex: index,
              })
            )
          : [];
      newData = newData.map((i) => {
        if ("relationship_type" === i.field) {
          i.type = "defined";
          i.targetField = "relationship_type";
        }
        return i;
      });

      if (fileId) {
        setDeseletedOptions(newData);
      } else {
        setDeseletedOptions([]);
      }
    }
  }, [data, fileId]);

  // Filter actived
  useEffect(() => {
    // let activedOptions = [...deselectedOptions].filter((i) => i.active);
    // Reverve item: manufacturer vs brand_name
    // if (deselectedOptions.length) {
    //   let indexBN =
    //     [...deselectedOptions].findIndex((i) => "brand_name" === i.field) + 1;
    //   let indexM = [...deselectedOptions].findIndex(
    //     (i) => "manufacturer" === i.field
    //   );
    //   [deselectedOptions[indexBN], deselectedOptions[indexM]] = [
    //     deselectedOptions[indexM],
    //     deselectedOptions[indexBN],
    //   ];
    // }

    // Add custom value for verise
    const templatesEA = TEMPLATES_EXPORT_AMAZON;
    if ("default" !== selectedTemplate && !isChangeValues) {
      let currentTemplate;
      for (let [key] of Object.entries(templatesEA)) {
        if (key === selectedTemplate) {
          currentTemplate = templatesEA[key];
        }
      }
      if (currentTemplate && Object.keys(currentTemplate).length) {
        for (let [key, value] of Object.entries(currentTemplate)) {
          deselectedOptions.forEach((i) => {
            if (i.field === key) {
              i.value = value;
              i.type = "custom";
            }
          });
        }
      }
    }

    // Get options active.
    let primaryFields = [...deselectedOptions].filter(
      (i) => FIELDS_EXPORT_AMAZON.includes(i.field) || i.newItem
    );
    let allFields = [...deselectedOptions].filter(
      (i) => FIELDS_EXPORT_AMAZON.includes(i.field) || i.active
    );
    setOptionsActive(checkAllField ? allFields : primaryFields);
    if (onChange) {
      let newData = deselectedOptions
        .map((item) => {
          if (item) {
            let { newItem, originIndex, __typename, ...rest } = item;
            return rest;
          }
          return null;
        })
        .filter(Boolean);
      onChange(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deselectedOptions, checkAllField, selectedTemplate, isChangeValues]);

  return (
    <Container>
      <Card sectioned>
        <UploadTemplateFilePolaris
          templateFile={templateFile}
          onChange={(value) => {
            if (value) {
              setFileId(() => value);
              setLoading(() => true);
              exportMapping({
                variables: {
                  fileId: value,
                  // fileId: "QvokpfcPHP",
                },
              });
              handleTemplateFileId(value);
            } else {
              setFileId(null);
              setDeseletedOptions([]);
            }
          }}
        />
      </Card>
      {loading ? (
        <Card>
          <SkeletonPagePolaris />
        </Card>
      ) : deselectedOptions && deselectedOptions.length > 0 ? (
        <Card>
          <div className="header_wrap">
            <ComponentLabelPolaris label="Mapping export fields" />
            <div className="check-all-fields">
              <Checkbox
                checked={checkAllField}
                onChange={(newValue) => setCheckAllField(newValue)}
                label="Show all columns"
              />
            </div>
          </div>
          <div className="choose-template">
            <Select
              label={"Choose template:"}
              options={TEMPLATES_OPTIONS}
              value={selectedTemplate}
              onChange={(value) => {
                setIsChangeValues(false);
                setSelectedTemplate(value);
              }}
            />
          </div>
          <TableMappingExportPolaris
            data={optionsActive}
            originData={deselectedOptions}
            checkAllField={checkAllField}
            onChange={(value) => {
              let originIndex = value?.originIndex;

              if (originIndex != null) {
                let newData = deselectedOptions.map((item) => {
                  if (item.originIndex === originIndex) {
                    item = value;
                  }
                  return item;
                });
                setIsChangeValues(true);
                setDeseletedOptions(newData);
              }
            }}
            onAddColumn={(value) => {
              if (value) {
                setIsChangeValues(true);
                setDeseletedOptions((prev) => [...prev, value]);
              }
            }}
          />
        </Card>
      ) : null}

      <div className="btns_wrap">
        {/* TODO: config */}
        {/* <Button
          children="Config filter products"
          onClick={() => handleStep(1)}
        /> */}
        <Button
          children="Export"
          primary
          onClick={onSubmit}
          disabled={disableBtnSubmit}
          loading={loadingProps}
        />
      </div>
    </Container>
  );
};
