import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Sheet, TextContainer, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { handleError } from "../../../helper";
import { EditTagPolaris } from "./EditTagPolaris";
import { LIST_TAG } from "./ListTagPolaris";



const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id)
  }
`;

export const ColActionsPolaris = ({ tag, filter }) => {
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);

  const [deleteTag, { data, error, client, loading }] = useMutation(
    DELETE_TAG,
    {
      onCompleted: () => {
        handleModalChange();
        toggleActive();
      },
      onError: () => {
        handleModalChange();
        toggleActive();
      },
    }
  );

  const handleModalChange = useCallback(() => setOpen((o) => !o), []);
  const toggleActive = useCallback(() => {
    setActiveToast((a) => !a);
  }, []);

  const handleSheetChange = useCallback(() => {
    setSheetActive((s) => !s);
  }, []);

  const toastMarkup = activeToast
    ? (error || (data && data.deleteTag)) && (
      <Toast
        content={
          error
            ? handleError(error.toString())
            : data && data.deleteTag && "Tag has been deleted!"
        }
        error={error}
        duration={2000}
        onDismiss={toggleActive}
      />
    )
    : null;

  return (
    <Container>
      <Button plain onClick={handleSheetChange}>
        Edit
      </Button>
      {/* <Button onClick={handleModalChange} plain>
        Delete
      </Button> */}
      <Modal
        title={"Delete tag"}
        open={open}
        onClose={handleModalChange}
        primaryAction={{
          content: "Delete",
          loading: loading,
          onAction: () => {
            deleteTag({
              variables: { id: tag.id },
            })
              .then((res) => {
                if (res) {
                  try {
                    let variables = {
                      filter,
                    };
                    const cache = client.readQuery({
                      query: LIST_TAG,
                      variables: variables,
                    });
                    client.writeQuery({
                      query: LIST_TAG,
                      variables: variables,
                      data: {
                        ...cache,
                        tags: {
                          ...cache.tags,
                          total: cache.tags.total - 1,
                          nodes: cache.tags.nodes.filter(
                            (t) => t.id !== tag.id
                          ),
                        },
                      },
                    });
                  } catch (e) { }
                }
              })
              .catch(() => { });
          },
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure to delete this tag?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Sheet onClose={handleSheetChange} open={sheetActive}>
        <EditTagPolaris
          handleSheetChange={handleSheetChange}
          filter={filter}
          tag={tag}
        />
      </Sheet>
      {toastMarkup}
    </Container>
  );
};
