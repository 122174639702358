import React, { Component } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Skeleton, Tag, Button, Popover } from "antd";
import { Card } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import {
  handleError,
  setCookie,
  getCookie,
  convertObjectToParams,
  convertStringToObject,
} from "../../helper";
import { Table } from "antd";
import ProductThumbnailImage from "../../components/shared/ProductThumbnailImage";
import styled from "styled-components";
import PageTitle from "../../components/shared/PageTitle";
import { Link } from "react-router-dom";
import _ from "lodash";
import noImage from "../../assets/images/unnamed.jpeg";
import ActionProduct from "../../components/seller/ActionProduct";
// import ProductsFilter from "../../components/seller/ProductsFilter";
import history from "../../history";
import { AppContext } from "../../context";

import ProductFiltersPolaris from "./ProductFiltersPolaris";

export const LIST_SELLER_PRODUCTS_QUERY = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        designStatus
        personalized
        status
        sku
        productBases {
          id
          title
        }
        images {
          id
          productBaseId
          file {
            id
            url
            thumbnailUrl
          }
        }
        mainImage {
          id
          thumbnailUrl
          url
        }
        tags {
          id
          name
        }
        collections {
          id
          name
        }
      }
    }
  }
`;

const Container = styled.div`
  .ant-dropdown::before {
    position: absolute;
    border: 1px solid;
    background: pink !important;
  }
  .ant-table-default {
    border: 0 none;
    border-top: 1px solid rgb(223, 229, 237);
  }
  .ant-table-thead > tr > th {
    background: none;
  }
`;

export const DEFAULT_PRODUCT_LIST_FILTER = {
  limit: +getCookie("perPageProducts") || 20,
  offset: 0,
  search: "",
  productBaseId: null,
  storeId: null,
  collections: null,
  tags: null,
  personalized: null,
};

let initFilter = {
  ...DEFAULT_PRODUCT_LIST_FILTER,
  limit: +getCookie("perPageProducts") || 20,
};
const urlQueryFilter = convertStringToObject(history.location.search);
if (urlQueryFilter && urlQueryFilter.personalized) {
  if ("personalized" === urlQueryFilter.personalized) {
    urlQueryFilter.personalized = true;
  }
  if ("normal" === urlQueryFilter.personalized) {
    urlQueryFilter.personalized = false;
  }
}

initFilter = { ...initFilter, ...urlQueryFilter };

class SellerProductsPage extends Component {
  state = {
    currentPage:
      _.get(initFilter, "offset", 0) /
        _.get(initFilter, "limit", DEFAULT_PRODUCT_LIST_FILTER.limit) +
      1,
    filter: initFilter,
    visible: false,
    showDrawerProductId: null,
    selectedRows: [],
    selectedRowKeys: [],
    showAction: false,
    deleteSelect: [],
    productsFilter: null,
    resetValue: 0,
    arrayValue: null,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      let { personalized, ...rest } = nextState.filter;
      if (true === personalized) {
        personalized = "personalized";
      }
      if (false === personalized) {
        personalized = "normal";
      }
      const params = convertObjectToParams({ ...rest, personalized });
      history.push(history.location.pathname + "?" + params);
    }
  }

  componentDidMount() {
    this.setState({
      filter: {
        ...this.state.filter,
        ...convertStringToObject(history.location.search),
      },
    });
  }

  handleChange = (value) => {};

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };
  onDelete = (id) => {
    this.setState({
      deleteSelect: [...this.state.deleteSelect, ...id],
      selectedRows: [],
    });
  };
  goToEditPage = (record) => {
    const link =
      record.productBases.length > 1
        ? "/seller/products/campaign"
        : "/seller/products/edit";

    return `${link}/${record.id}`;
  };

  render() {
    const { deleteSelect } = this.state;
    const { filter, visible, currentPage } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows,
          selectedRowKeys,
        });
      },
    };
    const columns = [
      {
        dataIndex: "images",
        width: 100,
        render: (_, record) => (
          <Link to={this.goToEditPage(record)}>
            {record.images && record.images.length > 0 ? (
              <ProductThumbnailImage limit={1} product={record} />
            ) : (
              <img
                src={noImage}
                alt="noImage"
                style={{ width: 70, height: 70 }}
              />
            )}
          </Link>
        ),
      },
      {
        title: "Product",
        dataIndex: "title",
        width: 200,
        render: (text, record) => (
          <div>
            <Link to={this.goToEditPage(record)}>{text}</Link>
            {record.personalized && <Tag color="green">Personalized</Tag>}
          </div>
        ),
      },
      {
        title: "Product Base",
        key: "product-base",
        width: 200,
        render: (_, record) => (
          <div>
            {record.productBases.map((p, index) => (
              <span key={index}>{`${index !== 0 ? ", " : ""}${p.title}`}</span>
            ))}
          </div>
        ),
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        width: 200,
      },
      {
        title: "Collections",
        width: 180,
        render: (_, record) =>
          record.collections &&
          record.collections.map((collection, index) => (
            <Tag style={{ margin: 5 }} key={index}>
              {collection.name}
            </Tag>
          )),
      },
      {
        title: "Tags",
        width: 160,
        render: (_, record) =>
          record.tags &&
          record.tags.map((tag, index) => (
            <Tag style={{ margin: 5 }} key={index}>
              {tag.name}
            </Tag>
          )),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <AppContext.Consumer>
        {({ store }) => (
          <Container>
            <PageTitle
              action={
                <div>
                  <Button type="primary" style={{ marginRight: 5 }}>
                    <Link to={"/seller/products/add"}>Add Product</Link>
                  </Button>
                  <Button type="primary">
                    <Link to={"/seller/products/add-campaign"}>
                      Add Campaign
                    </Link>
                  </Button>
                </div>
              }
              title={"All Products"}
            />
            <Card sectioned>
              <ProductFiltersPolaris
                propsFilters={this.state.filter}
                setProductFilters={(
                  productBaseId,
                  storeId,
                  collectionId,
                  tagId,
                  search,
                  productType
                ) => {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      productBaseId: productBaseId,
                      storeId: storeId,
                      collections: collectionId,
                      tags: tagId,
                      search: search,
                      personalized: productType.value,
                    },
                  });
                }}
              />
            </Card>
            <Card>
              {this.state.selectedRowKeys.length > 0 && (
                <div className="p-6">
                  <Button.Group>
                    <Button>{`${this.state.selectedRowKeys.length} product selected`}</Button>
                    <Popover
                      placement="bottom"
                      trigger="click"
                      visible={this.state.showAction}
                      onVisibleChange={(bool) =>
                        this.setState({ showAction: bool })
                      }
                      content={
                        <ActionProduct
                          onCompleted={() => {
                            this.setState({
                              selectedRows: [],
                              selectedRowKeys: [],
                            });
                          }}
                          selectedRowKeys={this.state.selectedRowKeys}
                          filter={this.state.filter}
                          visible={visible}
                          onDelete={(data) => this.onDelete(data)}
                          selectedRows={rowSelection.selectedRowKeys}
                          onClose={() => this.setState({ showAction: false })}
                        />
                      }
                    >
                      <Button>
                        Actions
                        <CaretDownOutlined />
                      </Button>
                    </Popover>
                  </Button.Group>
                </div>
              )}
              <Query
                query={LIST_SELLER_PRODUCTS_QUERY}
                variables={{
                  filter: {
                    ...filter,
                    storeId: store ? store.id : filter.storeId,
                  },
                }}
              >
                {({ error, loading, data }) => {
                  if (error)
                    return <div>Error: {handleError(error.toString())}</div>;
                  if (loading)
                    return (
                      <div className="p-4">
                        <Skeleton active />
                      </div>
                    );
                  const dataSource =
                    deleteSelect.length > 0
                      ? data.products.nodes.filter(
                          (node) => !deleteSelect.includes(node.id)
                        )
                      : data.products.nodes;

                  return (
                    <Table
                      bordered={false}
                      scroll={{ x: tableWidth }}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      columns={columns}
                      pagination={{
                        current: currentPage,
                        total: data.products.total,
                        pageSize: filter.limit,
                        showTotal: (total, range) =>
                          `${range.join(" to ")} of ${total}`,
                        onShowSizeChange: (_, size) => {
                          this.setState({
                            currentPage: 1,
                            filter: {
                              ...filter,
                              limit: size,
                              offset: 0,
                            },
                          });
                          setCookie("perPageProducts", size, 100);
                        },
                        onChange: (page, pageSize) => {
                          this.setState({
                            currentPage: page,
                            filter: {
                              ...filter,
                              limit: pageSize,
                              offset: (page - 1) * filter.limit,
                            },
                          });
                        },
                      }}
                      dataSource={dataSource}
                    />
                  );
                }}
              </Query>
            </Card>
          </Container>
        )}
      </AppContext.Consumer>
    );
  }
}

export default SellerProductsPage;
