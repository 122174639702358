import React from "react";
import { Modal, Scrollable } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import get from "lodash/get";

import { handleError } from "../../../helper";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import useToggle from "../../../hooks/useToggle";
import { GET_SIMILAR_PRODUCT } from "../../../graphql/queries";

import { ViewModalImage } from "../../product/ViewModalImage";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    .image-compare {
        width: 25%;
        height: auto;
    }
    .similar-container {
        flex: 1 1;
        border-right: 2px solid var(--p-border-subdued, #dfe3e8);
        margin-right: 2rem;
        .paginatation-wrap {
            padding: 2rem 0;
        }
    }
    .design-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        max-width: 20rem;
        img {
            width: 200px;
            height: auto;
            cursor: pointer;
        }
        .label {
            font-size: 0.875em;
            word-break: break-all;
        }
    }
    .similar-product-wrap {
        display: flex;
        flex-direction: colum;
        row-gap: 2rem;
        flex-wrap: wrap;
        padding: 2rem 0 2rem 0.8rem;
        flex-direction: column;
        border-bottom: 1px solid var(--p-border-subdued, #dfe3e8);
    }
    .designs-wrap {
        display: flex;
        flex-direction: row;
        gap: 2rem 3rem;
        flex-wrap: wrap;
    }
    .design-wrap,
    .image-compare {
        img {
            border-radius: 5px;
            box-shadow: rgb(0 0 0 / 10%) 0px -1px 15px -3px,
                rgb(0 0 0 / 10%) 0px 4px 6px 2px;
        }
    }
`;

export const GetSimilarProduct = ({ task, open, toggleShowModal }) => {
    const id = task?.product?.id;

    // State
    const [openImgModal, setOpenImgModal] = React.useState({});
    const [filter, setFilter] = React.useState({
        productIds: [],
        pLimit: 10,
        pOffset: 0,
    });
    const [openOI, toggleOpenOI] = useToggle(false);

    // Query
    const { data, loading, error } = useQuery(GET_SIMILAR_PRODUCT, {
        variables: {
            id,
            ...filter,
        },
        // skip: !id || !open,
        skip: true,
    });
    useLockBodyScroll(open);

    // Handle action
    const handleFilterChange = React.useCallback(
        (offset, limit) => {
            let productIds = data?.getSimilarProduct?.productIds;
            setFilter((prev) => ({
                ...prev,
                pLimit: limit,
                pOffset: offset,
                productIds,
            }));
        },
        [data]
    );

    const handleToggleModal = React.useCallback((idx) => {
        setOpenImgModal((prev) => ({
            ...prev,
            [idx]: !prev[idx],
        }));
    }, []);

    let { pLimit, pOffset } = filter;
    const total = data?.getSimilarProduct?.total;
    const totalPage = Math.ceil(total / pLimit);
    const page = pOffset / pLimit + 1;
    const aggregation = {
        page,
        totalPage,
        offset: pOffset,
        limit: pLimit,
        onChange: handleFilterChange,
        total,
    };

    // Main image
    const order = task?.order;
    const product = order?.product;
    const mainImageId = product?.mainImageId;
    const images = product?.images;
    const isCampaign = product?.productBases?.length > 1;
    let mainImages = [];
    const pBaseId = get(
        order,
        "productVariant.productBaseVariant.productBase.id",
        null
    );
    const haveMainImageId = mainImageId || mainImageId !== "undefined";

    if (pBaseId && isCampaign) {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.productBaseId === pBaseId)
                      .map((item) => item.file)
                : [];
        if (mainImages.length > 1 && haveMainImageId) {
            mainImages =
                images?.length > 0
                    ? images
                          .filter((img) => img?.file?.id === mainImageId)
                          .map((item) => item.file)
                    : [];
        }
    } else if (haveMainImageId) {
        mainImages =
            images?.length > 0
                ? images
                      .filter((img) => img?.file?.id === mainImageId)
                      .map((item) => item.file)
                : [];
    }
    if (mainImages.length === 0) {
        mainImages =
            images?.length > 0
                ? images.filter(Boolean).map((item) => item.file)
                : [];
    }
    const image = mainImages?.length > 0 ? mainImages[0] : null;
    const url = image
        ? image?.thumbnailUrl
            ? image?.thumbnailUrl
            : image?.url
            ? image?.url
            : null
        : null;

    // Markup
    const renderImages = (array) => {
        return array?.length > 0
            ? array.filter(Boolean).map(({ url, thumbUrl }) => {
                  let file = {
                      thumbnailUrl: thumbUrl,
                      url,
                  };
                  return {
                      file,
                  };
              })
            : null;
    };
    const renderSku = (sku) => {
        return sku ? (
            <span className="label">
                <strong>SKU:</strong> {sku}
            </span>
        ) : null;
    };

    const renderDesigner = (designer) => {
        return designer ? (
            <span className="label">
                <strong>Designer:</strong> {designer}
            </span>
        ) : null;
    };
    const renderOrderId = (orderId) => {
        return orderId ? (
            <span className="label">
                <strong>Order ID:</strong> {orderId}
            </span>
        ) : null;
    };

    const designContent = (ds, idx, skuMarkup) => {
        if (!ds) {
            return null;
        }
        let { orderId, designUrl, designer } = ds;
        let images = renderImages(designUrl);
        // Designer
        const fn = designer?.firstName;
        const ln = designer?.lastName;
        const designerFullname = [fn, ln].filter(Boolean).join(" ");
        const imgSrc = designUrl?.length > 0 ? designUrl[0].thumbUrl : null;

        return (
            <div className="design-wrap" key={idx}>
                <img
                    src={imgSrc}
                    alt=""
                    onClick={() => handleToggleModal(idx)}
                />
                {skuMarkup}
                {renderDesigner(designerFullname)}
                {renderOrderId(orderId)}
                <ViewModalImage
                    item={{ images }}
                    open={!!openImgModal[idx]}
                    toggleShowModal={() => handleToggleModal(idx)}
                />
            </div>
        );
    };
    const contentMarkup =
        data?.getSimilarProduct?.nodes?.length > 0
            ? data.getSimilarProduct.nodes.map((item, index) => {
                  if (!item) {
                      return null;
                  }
                  let { sku, designs } = item;
                  const skuMarkup = renderSku(sku);
                  return (
                      <div className="similar-product-wrap" key={index}>
                          <div className="designs-wrap">
                              {designs?.length > 0
                                  ? designs.map((ds, idx) => (
                                        <React.Fragment key={idx}>
                                            {designContent(ds, idx, skuMarkup)}
                                        </React.Fragment>
                                    ))
                                  : null}
                          </div>
                      </div>
                  );
              })
            : null;

    return (
        <Modal
            title="Get Similar Design"
            onClose={toggleShowModal}
            open={open}
            sectioned
            large
        >
            <Container>
                <div className="similar-container">
                    {loading ? (
                        <SkeletonPagePolaris />
                    ) : error ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : data?.getSimilarProduct?.nodes?.length > 0 ? (
                        <div>
                            <Scrollable shadow style={{ height: "70rem" }}>
                                {contentMarkup}
                                <div className="paginatation-wrap">
                                    <PaginationPolaris
                                        aggregation={aggregation}
                                    />
                                </div>
                            </Scrollable>
                        </div>
                    ) : (
                        <EmptyStatePolaris noData slim />
                    )}
                </div>
                <div className="image-compare">
                    <div onClick={toggleOpenOI}>
                        <img
                            src={url}
                            alt=""
                            style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <ViewModalImage
                        item={{ images }}
                        open={openOI}
                        toggleShowModal={toggleOpenOI}
                    />
                </div>
            </Container>
        </Modal>
    );
};
