import React, { useState, useCallback } from "react";
import { Modal, Stack, RadioButton } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { useProductDuplicateContext } from "../context";
import { DELETE_PRODUCT_SELECT } from "../../DeleteProductsPolaris";
import { PRODUCTS_BY_TITLES } from "../index";
import { handleError } from "../../../../helper";

export default function Delete({ ids, open, onClose, onCompleted }) {
    // Context
    const { toggleToast, setNotify, filter } = useProductDuplicateContext();

    // State
    const [removeStore, setRemoveStore] = useState("true");

    // Mutation
    const [deleteProduct, { loading, client }] = useMutation(
        DELETE_PRODUCT_SELECT
    );

    // Handle action
    const handleSubmit = useCallback(() => {
        if (ids?.length > 0) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });

            deleteProduct({
                variables: {
                    id: ids,
                    removeStore: "true" === removeStore,
                },
            })
                .then((res) => {
                    const deleted = res?.data?.deleteProductSelected?.deleted;

                    if (deleted?.length > 0) {
                        setNotify && setNotify({ msg: "", err: false });
                        onClose && onClose();
                        onCompleted && onCompleted();
                        setNotify &&
                            setNotify({
                                msg: `Deleted ${deleted.length}/${ids.length} products success.`,
                                err: false,
                            });
                        try {
                            const cache = client.readQuery({
                                query: PRODUCTS_BY_TITLES,
                                variables: { ...filter },
                            });
                            client.writeQuery({
                                query: PRODUCTS_BY_TITLES,
                                variables: { ...filter },
                                data: {
                                    ...cache,
                                    productsByTitles: {
                                        ...cache.productsByTitles,
                                        total:
                                            cache.productsByTitles.total -
                                            deleted.length,
                                        nodes: cache.productsByTitles.nodes.filter(
                                            (u) => !ids.includes(u.id)
                                        ),
                                    },
                                },
                            });
                        } catch (e) {}
                    }
                })
                .catch((err) => {
                    setNotify &&
                        setNotify({ msg: handleError(err), err: true });
                });
        }
    }, [
        deleteProduct,
        ids,
        onClose,
        onCompleted,
        toggleToast,
        setNotify,
        removeStore,
        client,
        filter,
    ]);

    const handleChange = useCallback(
        (_checked, newValue) => setRemoveStore(newValue),
        []
    );

    return (
        <Modal
            title="Delete selected product"
            sectioned
            open={open}
            onClose={onClose}
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
            primaryAction={{
                content: "Delete",
                destructive: true,
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Stack vertical>
                <RadioButton
                    label="Are you sure to delete this selected?"
                    checked={removeStore === "false"}
                    id="false"
                    name="removeStore"
                    onChange={handleChange}
                />
                <RadioButton
                    label="Are you sure to delete this selected in store?"
                    checked={removeStore === "true"}
                    id="true"
                    name="removeStore"
                    onChange={handleChange}
                />
            </Stack>
        </Modal>
    );
}
