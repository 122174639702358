import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, Input, Spin } from "antd";
import * as React from "react";
import { SET_WARNING_EMAIL } from "../../../graphql/mutations";
import { GET_WARNING_EMAIL } from "../../../graphql/queries";

function WarningEmailImpl({ setLoading }, ref) {
  const [form] = Form.useForm();

  //
  const { data, loading, error } = useQuery(GET_WARNING_EMAIL);
  const [update, { loading: l2 }] = useMutation(SET_WARNING_EMAIL);

  React.useEffect(() => {
    if (data?.getWarningEmail) {
      const { address, receiverEmails } = data.getWarningEmail || {};
      const addressStr = convertToStr(address);
      const emailsStr = convertToStr(receiverEmails);

      form.setFieldsValue({ receiverEmails: emailsStr, address: addressStr });
    }
  }, [data, form]);

  const handleFinish = React.useCallback(
    (values) => {
      setLoading(true);
      const { receiverEmails, address } = values || {};
      const receiverEmailsArr = splitStr(receiverEmails || "");
      const addressArr = splitStr(address || "");
      update({
        variables: {
          input: { address: addressArr, receiverEmails: receiverEmailsArr },
        },
      }).finally(() => {
        setLoading(false);
      });
    },
    [setLoading],
  );

  React.useImperativeHandle(ref, () => ({
    onSubmit: form.submit,
  }));

  return loading || l2 ? (
    <Spin />
  ) : error ? (
    <div>Error: {error?.toString()}</div>
  ) : (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={handleFinish}
    >
      <Form.Item
        label="Add email lists to get notifications whenever detected order's address that cannot be shipped"
        name="receiverEmails"
      >
        <Input.TextArea placeholder="Enter emails receiver" rows={3} />
      </Form.Item>
      <Form.Item
        label="List addresses that cannot be shipped"
        name="address"
        help="Address, City, State separate by `|`"
      >
        <Input.TextArea placeholder="Address|City|State" rows={5} />
      </Form.Item>
    </Form>
  );
}

/** -----  */
function convertToStr(arr) {
  if (!arr | !Array.isArray(arr) || arr.length === 0) return arr;

  return arr.filter(Boolean).join("\n");
}

function splitStr(str) {
  if (!str || typeof str !== "string") return str;

  return str.split(/\n|\,/).filter(Boolean);
}

const WarningEmail = React.forwardRef(WarningEmailImpl);

export { WarningEmail };
