import React, { useState, useCallback, useEffect } from "react";
import { DatePicker } from "@shopify/polaris";
import moment from "moment";

export const DatePickerPolaris = (props) => {
  const { value, onChange } = props;
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    if (value) {
      setSelectedDates((prevState) => ({
        ...prevState,
        start: value.from ? value.from._i : new Date(),
        end: value.to ? value.to._i : new Date(),
      }));
    }
  }, [value]);

  const handleMonthChange = useCallback((month, year) =>
    setDate({ month, year }), []
  );
  const handledChange = useCallback((date) => {
    setSelectedDates(date);
    if (onChange) {
      onChange({
        start: moment(date.start).startOf("day"),
        end: moment(date.end).endOf("day"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePicker
      month={month}
      year={year}
      selected={selectedDates}
      onChange={handledChange}
      onMonthChange={handleMonthChange}
      multiMonth
      allowRange
    />
  );
};
