import { BULK_REJECT_DESIGN } from "../../../graphql/mutations";

import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import { RejectDesignFormPolaris } from "./RejectDesignFormPolaris";
import { handleError } from "../../../helper";

export default function BulkRejectDesign({
  open,
  toggleShowModal,
  onNotificationChange,
  toggleActive,
  idsChecked,
  onCompleted,
}) {
  let timeoutRef = React.useRef(null);
  const [rejectDesign, { loading }] = useMutation(BULK_REJECT_DESIGN, {
    onError: (error) => {
      onNotificationChange &&
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
    },
    onCompleted: (res) => {
      const bulkRejectDesign = res?.bulkRejectDesign;
      if (bulkRejectDesign) {
        onNotificationChange &&
          onNotificationChange({
            message: "Reject designs successfully.",
            isError: false,
          });
      }

      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onCompleted && onCompleted();
      }, 2000);
    },
  });

  const handleSubmit = React.useCallback(
    (values) => {
      const { rejectReason, rejectResources } = values;
      if (!rejectReason || !idsChecked || idsChecked.length === 0) return;

      onNotificationChange &&
        onNotificationChange({
          message: null,
          isError: false,
        });
      toggleActive && toggleActive();

      rejectDesign({
        variables: {
          ids: idsChecked,
          rejectReason,
          rejectResources,
        },
      });
    },
    [idsChecked, rejectDesign, toggleActive, onNotificationChange],
  );

  return (
    <Modal
      title="Reject designs"
      open={open}
      onClose={toggleShowModal}
      sectioned
    >
      <RejectDesignFormPolaris
        onSubmit={handleSubmit}
        toggleShowModal={toggleShowModal}
        loading={loading}
      />
    </Modal>
  );
}
