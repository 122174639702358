import { Button } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/shared/PageTitle";
import Imports from "../../components/supplier/Imports";
import { AppContext } from "../../context";
import { getParam } from "../../helper";
import { USER_ROLE } from "../../variable";

export default class ImportPage extends Component {
  render() {
    const { currentUser } = this.context;
    let param = getParam(currentUser);
    return (
      <div>
        <PageTitle
          title={"Import Files"}
          action={
            <Link to={`/${param}/import-tracking`}>
              <Button type="primary">Import Tracking</Button>
            </Link>
          }
        />
        <Imports role={USER_ROLE.Supplier} />
      </div>
    );
  }
}

ImportPage.contextType = AppContext;
