export const STATUS_DESIGN_TASK = {
  Pending: "Pending",
  Doing: "Doing",
  NeedReview: "Need Review",
  Rejected: "Rejected",
  Done: "Done",
  Cancel: "Cancel",
  Denied: "Denied",
  Expired: "Expired",
  ExpiredSoon: "Expired Soon",
  NeedBuyerConfirm: "Need Buyer Confirm",
  AwaitingBuyerConfirm: "Awaiting Buyer Confirm",
  ArtworkConfigurable: "Artwork Configurable",
};

// let KPI_VALUES = [];
// for (let i = 0.5; i <= 10; i += 0.5) {
//   KPI_VALUES.push(i);
// }

// const index = KPI_VALUES.findIndex((i) => i === 0.5);
// if (index > -1) {
//   KPI_VALUES.splice(index + 1, 0, 0.75);
// }
// KPI_VALUES.unshift(0.3);
// KPI_VALUES.unshift(0.25);

// KPI_VALUES.filter((el, i, arr) => i === arr.lastIndexOf(el));

// let indexOne = KPI_VALUES.findIndex((i) => i === 1);
// KPI_VALUES.splice(indexOne + 1, 0, 1.25)

let KPI_VALUES = [];
for (let i = 0.25; i <= 10; i += 0.25) {
  KPI_VALUES.push(i);
}
KPI_VALUES.splice(1, 0, 0.3)
export const KPI = KPI_VALUES;


export const TASK_MENU_BOTH = "design_idea_task";
export const DESIGN_TASK_MENU = "design_task";
export const IDEA_TASK_MENU = "idea_task";
export const EXTRA_TASKS_KEYS = [TASK_MENU_BOTH, DESIGN_TASK_MENU, IDEA_TASK_MENU];
export const MAP_EXTRA_TASKS = {
  [TASK_MENU_BOTH]: ["/design-tasks", "/design-tasks/ideas"],
  [DESIGN_TASK_MENU]: ["/design-tasks"],
  [IDEA_TASK_MENU]: ["/design-tasks/ideas"],
}
