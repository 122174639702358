import { Card, Checkbox, Form, Select } from "antd";
import React from "react";
import Price from "../Price";

const noop = () => {};
function BoxShipping({ isCustomcat, form, showShipping }, ref) {
  const { getFieldValue = noop, setFieldsValue = noop } = form || {};
  const [isSingleShipment, setIsSingleShipment] = React.useState(
    getFieldValue("isSingleShipment"),
  );

  const handleSingleShipmentChange = React.useCallback(
    (e) => {
      let checked = e.target.checked;
      setIsSingleShipment(checked);
      if (checked) {
        setFieldsValue({
          shippingRate: null,
        });
      }
    },
    [setFieldsValue],
  );

  return (
    <Card style={{ marginTop: 20 }} title={"Shipping"}>
      <Form.Item
        name="shippingRate"
        label={"Customcat shipping rate"}
        hidden={!isCustomcat}
      >
        <CustomShippingRate />
      </Form.Item>

      <Form.Item
        style={{ marginTop: 0 }}
        name="isSingleShipment"
        valuePropName="checked"
      >
        <Checkbox onChange={handleSingleShipmentChange}>
          Is single shipment?
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="US First product fee"
        name="usFirstFee"
        rules={[
          {
            required: showShipping,
            message: "US First product fee is required",
          },
        ]}
      >
        <Price />
      </Form.Item>
      <Form.Item
        name="usAdditionalFee"
        label="US Additional products fee"
        hidden={isSingleShipment}
      >
        <Price />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: showShipping,
            message: "Canada First product fee is required",
          },
        ]}
        label="Canada First product fee"
        name="canadaFirstFee"
      >
        <Price />
      </Form.Item>
      <Form.Item
        name="canadaAdditionalFee"
        label="Canada Additional products fee"
        hidden={isSingleShipment}
      >
        <Price />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: showShipping,
            message: "International First product fee is required",
          },
        ]}
        label="International First product fee"
        name="internationalFirstFee"
      >
        <Price />
      </Form.Item>
      <Form.Item
        hidden={isSingleShipment}
        label="International Additional product fee"
        name="internationalAdditionalFee"
      >
        <Price />
      </Form.Item>
    </Card>
  );
}

const CustomShippingRate = React.forwardRef(function CustomShippingRate() {
  const shippingRate = [
    {
      title: "Default",
      value: null,
    },
    {
      title: "Supper Lightweight",
      value: "Supper Lightweight",
    },
    {
      title: "LightWeight",
      value: "LightWeight",
    },

    {
      title: "HeavyWeight",
      value: "HeavyWeight",
    },
    {
      title: "LightWeight DrinkWare",
      value: "LightWeight DrinkWare",
    },
    {
      title: "HeavyWeight DrinkWare",
      value: "HeavyWeight DrinkWare",
    },
  ];

  return (
    <Select>
      {shippingRate.map((op) => (
        <Select.Option value={op.value} key={op.value}>
          {op.title}
        </Select.Option>
      ))}
    </Select>
  );
});

export default React.forwardRef(BoxShipping);
