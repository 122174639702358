import { useQuery } from "@apollo/react-hooks";
import { Checkbox, Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FULFILLMENT_SS_OPTIONS, TRACKING_STATUS } from "../../constants";
import { formatDataTree, genLabelTree, isEmpty, checkRole } from "../../helper";
import { useAppContext } from "../../context";
import { GET_SUPPLIERS, GET_TEAMS } from "../../graphql/queries";
import {
  COLLECTIONS_QUERY,
  TAGS_QUERY,
} from "../../pages/seller/ProductFiltersPolaris";
import { ORDER_STATUS, TEAM_ROLE, USER_ROLE } from "../../variable";
import { FilterHasSearchMVPolaris } from "../filters/FilterHasSearchMVPolaris";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterNoSearchMVPolaris } from "../filters/FilterNoSearchMVPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { LIST_TEAM } from "../members/TeamMembersPolaris";
import { QUERY_GROUPS } from "../store/group/GroupsPolaris";
import { ChargeBaseCostPolaris } from "./ChargeBaseCostPolaris";
import { FilterOrderByTimePolaris } from "./FilterOrderByTimePolaris";
import { PassOnHoldPolaris } from "./PassOnHoldPolaris";

export const QUERY_FULFILLMENTS = gql`
  query fulfillments {
    fulfillments {
      id
      name
    }
  }
`;

const QUERY_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
        domain
        email
        platform
        owners {
          id
          user {
            id
            email
          }
        }
      }
    }
  }
`;

const QUERY_PRODUCT_BASE = gql`
  query productBasesForSeller($filter: ProductBaseFilter) {
    productBasesForSeller(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const QUERY_CARRIER = gql`
  query carriers {
    carriers {
      id
      name
    }
  }
`;

const QUERY_SUPPLIERS = gql`
  query listUserByRole($role: String!) {
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

const GET_ORDER_REFERRING_SITES = gql`
  query getOrderReferringSites {
    getOrderReferringSites
  }
`;

const GET_ORDER_SOURCE_NAMES = gql`
  query getOrderSourceNames {
    getOrderSourceNames
  }
`;

const ORDER_TYPE = [
  { value: "All", label: "All" },
  { value: "normal", label: "Normal" },
  { value: "personalized", label: "Personalized" },
];

const ORDER_SOURCE = [
  // { value: "All", label: "All" },
  { value: "online store", label: "Online stores" },
  { value: "ebay", label: "Ebay" },
  { value: "amazon", label: "Amazon" },
  { value: "etsy", label: "Etsy" },
  { value: "facebook", label: "Facebook" },
];

const DESIGN_TASK = [
  { value: "All", label: "All" },
  { value: "unassigned", label: "Unassigned" },
  { value: "assigned", label: "Assigned" },
];

export const FILTER_TIME = [
  { value: "All", label: "All Time" },
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "This month", label: "This month" },
  { value: "Last month", label: "Last month" },
];

const YES = "Yes";
const All = "All";
const NO = "No";

const BULK_ACCEPT = [
  { value: All, label: All },
  { value: YES, label: YES },
];

const USING_PRODUCT_DESIGN = [
  { value: All, label: All },
  { value: YES, label: YES },
];

const HAS_NOTE_OPTIONS = [
  { value: All, label: All },
  { value: YES, label: YES },
  { value: NO, label: NO },
];

const FULFILLMENT_SS_OPTIONS_OB = Object.values(
  FULFILLMENT_SS_OPTIONS
).map((v) => ({ value: v, label: v }));

const Container = styled.div`
  .actions-wrap {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    margin-top: 1.2rem;
    label.Polaris-Choice,
    .Polaris-Choice__Label {
      cursor: default;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  .domain {
    font-size: 0.9em;
    padding-right: 5px;
    color: var(--p-interactive, #006fbb);
  }
`;

const stringToBool = { Yes: true, No: false };
const designTaskToBool = {
  unassigned: false,
  assigned: true,
};

const orderTypeToBool = {
  normal: false,
  personalized: true,
};

export const FilterOrdersControlPolaris = (props) => {
  const {
    filter,
    onChange,
    userRole,
    teamRole,
    itemsChecked,
    role,
    refetch,
    updateCount,
    reload,
    isAdmin,
    isAdministrator,
  } = props;

  // Context
  const { currentUser } = useAppContext();
  const { isSupplier, isSeller, isStoreManager } = checkRole(currentUser);

  let roles = userRole ? userRole[0] : null;
  let hideFilterStore = false;
  let showFilterSM = false;
  let isSupplierFulfilled = isSupplier || [USER_ROLE.Supplier].includes(role);
  let OPTIONS_TRACKING_STATUS = TRACKING_STATUS;
  if (isSupplierFulfilled && filter.status === ORDER_STATUS.Fulfilled) {
    OPTIONS_TRACKING_STATUS = [
      ...OPTIONS_TRACKING_STATUS,
      { key: "10daysnotfound", name: "10 Days no info" },
      { key: "12daysnotfound", name: "12 Days no info" },
    ];
  }

  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.Admin, TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
      showFilterSM = true;
    }
    if (
      [TEAM_ROLE.MarketplaceManager, TEAM_ROLE.StoreManager].includes(teamRole)
    ) {
      hideFilterStore = true;
    }
  }

  let status = filter && filter.status;
  const [queryValue, setQueryValue] = useState(null);
  const typingTimeoutRef = useRef(null);

  const [fulfillments, setFulfillments] = useState([]);
  const [fulfillment, setFulfillment] = useState({
    value: filter.fulfilmentId,
    label: null,
    search: null,
  });
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({
    value: filter.storeId,
    label: null,
    search: null,
  });

  const [productBases, setProductBases] = useState([]);
  const [productBase, setProductBase] = useState({
    value: filter.productBaseId,
    label: null,
    search: null,
  });

  const [carriers, setCarriers] = useState([]);
  const [carrier, setCarrier] = useState({
    value: filter.carrierId,
    label: null,
    search: null,
  });

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState({
    value: filter.supplierId,
    label: null,
    search: null,
  });
  const [listTrackingStatus, setListTrackingStatus] = useState([]);
  const [trackingStatus, setTrackingStatus] = useState({
    value: filter.trackingStatus,
    label: null,
  });
  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });
  const [readyToPrintTime, setReadyToPrintTime] = useState({
    range: null,
    rangeLabel: null,
  });
  const [orderType, setOrderType] = useState({
    value: null,
    label: null,
  });
  const [orderSource, setOrderSource] = useState({
    // value: filter.orderSource,
    // label: null,
    value: [],
    label: [],
  });
  const [designTaskCreated, setDesignTaskCreated] = useState({
    value: null,
    label: null,
  });
  const [storeManagers, setStoreManagers] = useState([]);
  const [storeManager, setStoreManager] = useState({
    value: filter.storeManagerId,
    label: null,
    search: null,
  });

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({
    value: filter.groupId,
    label: null,
    search: null,
  });

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({
    value: filter.teamId,
    label: null,
    search: null,
  });

  const filterBulkAccept = filter?.usingTaskBulkDesign;
  const [bulkAcceptDesign, setBulkAcceptDesign] = useState({
    value: null,
    label: null,
  });

  const manualOrderProp = filter?.manualOrder;
  const [manualOrder, setManualOrder] = useState({
    value: null,
    label: null,
  });

  const hasNote = filter?.orderHasNote;
  const [orderHasNote, setOrderHasNote] = useState({
    value: null,
    label: null,
  });

  const filterUsingPD = filter?.usingProductDesign;
  const [usingPD, setUsingPD] = useState({
    value: null,
    label: null,
  });

  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({
    value: [],
    label: [],
    search: null,
  });

  const referringSiteProp = filter.referringSite;
  const [referringSites, setReferringSites] = useState([]);
  const [referringSite, setReferringSite] = useState({
    value: referringSiteProp,
    label: null,
    search: null,
  });

  const sourceNameProp = filter.sourceName;
  const [sourceNames, setSourceNames] = useState([]);
  const [sourceName, setSourceName] = useState({
    value: sourceNameProp,
    label: null,
    search: null,
  });

  const fulfillmentSSOProp = filter.fulfillmentServiceShippingOption;
  const [fulfillmentSSOption, setFulfillmentSSOption] = useState({
    value: fulfillmentSSOProp,
    label: null,
    search: null,
  });

  // Queries
  const { data: dataFF, loading: loadingFF, error: errorFF } = useQuery(
    QUERY_FULFILLMENTS,
    {
      skip: userRole.includes(USER_ROLE.Supplier),
    }
  );

  const {
    data: dataStore,
    loading: loadingStore,
    error: errorStore,
  } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        limit: store.search ? 1000 : 20,
        offset: 0,
        search: store.search,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: userRole.includes(USER_ROLE.Supplier),
  });

  const { data: dataPB, loading: loadingPB, errorPB } = useQuery(
    QUERY_PRODUCT_BASE,
    {
      variables: {
        filter: {
          limit: -1,
          offset: -1,
          fulfillmentId: fulfillment.value,
        },
      },
      skip: userRole.includes(USER_ROLE.Supplier),
    }
  );

  const {
    data: dataCarrier,
    loading: loadingCarrier,
    error: errorCarrier,
  } = useQuery(QUERY_CARRIER, {
    skip: userRole.includes(USER_ROLE.Supplier),
  });

  const { data: dataSup, loading: loadingSup, error: errorSup } = useQuery(
    QUERY_SUPPLIERS,
    {
      variables: {
        role: USER_ROLE.Supplier,
      },
      skip:
        ![USER_ROLE.Admin, USER_ROLE.Supplier].includes(role) ||
        [USER_ROLE.Supplier].includes(roles),
    }
  );

  const { data: dSup, loading: lSup, error: eSup } = useQuery(GET_SUPPLIERS, {
    skip: ![USER_ROLE.Seller].includes(role),
  });

  const lSupMerge = isSeller ? lSup : loadingSup;
  const eSupMerge = isSeller ? eSup : errorSup;

  const { data: dataTM, loading: loadingTM, error: errorTM } = useQuery(
    LIST_TEAM,
    {
      variables: {
        filter: {
          limit: 1000,
          offset: 0,
          status: true,
          role: TEAM_ROLE.StoreManager,
        },
      },
      skip: [USER_ROLE.Supplier, "Administrator", USER_ROLE.Supporter].includes(
        roles
      ),
    }
  );

  const { data: dataMM, loading: loadingMM, error: errorMM } = useQuery(
    LIST_TEAM,
    {
      variables: {
        filter: {
          limit: 1000,
          offset: 0,
          status: true,
          role: TEAM_ROLE.MarketplaceManager,
        },
      },
      skip: [USER_ROLE.Supplier, "Administrator", USER_ROLE.Supporter].includes(
        roles
      ),
    }
  );

  const { data: dataGroup, loading: loadingG, error: errorG } = useQuery(
    QUERY_GROUPS,
    {
      variables: {
        filter: {
          limit: 1000,
          offset: 0,
        },
      },
      skip: ![USER_ROLE.Seller].includes(roles),
    }
  );

  const { data: dataT, loading: loadingT, error: errorT } = useQuery(
    GET_TEAMS,
    {
      variables: {
        filter: {
          limit: team.search ? 1000 : 20,
          offset: 0,
          hasOrder: true,
          search: team.search,
        },
      },
      skip: !isAdministrator,
    }
  );

  const {
    data: dataCollection,
    loading: loadingCollection,
    error: errorCollection,
  } = useQuery(COLLECTIONS_QUERY, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: collection.search,
      },
    },
    skip: [USER_ROLE.Supplier, "Administrator", USER_ROLE.Supporter].includes(
      roles
    ),
  });

  const { data: dataTag, loading: loadingTag, error: errorTag } = useQuery(
    TAGS_QUERY,
    {
      variables: {
        filter: {
          limit: 20,
          offset: 0,
          search: tag.search,
        },
      },
      skip: [USER_ROLE.Supplier, "Administrator", USER_ROLE.Supporter].includes(
        roles
      ),
    }
  );

  const { data: dataRS, loading: loadingRS, error: errorRS } = useQuery(
    GET_ORDER_REFERRING_SITES
  );
  const { data: dataSN, loading: loadingSN, error: errorSN } = useQuery(
    GET_ORDER_SOURCE_NAMES
  );

  // Get data
  //getOrderReferringSites
  useEffect(() => {
    if (dataRS != null) {
      const nodes = dataRS.getOrderReferringSites;
      const referringSites =
        nodes?.length > 0
          ? nodes.map((node) => ({ value: node, label: node }))
          : [];
      setReferringSites(referringSites);
    }
  }, [dataRS]);
  useEffect(() => {
    if (referringSiteProp != null && referringSites?.length > 0) {
      const current = referringSites.find((i) => i.value === referringSiteProp);
      if (current != null) {
        const { value, label } = current;
        setReferringSite((prev) => ({ ...prev, value, label }));
      }
    }
  }, [referringSiteProp, referringSites]);

  // getOrderSourceNames
  useEffect(() => {
    if (dataSN != null) {
      const nodes = dataSN.getOrderSourceNames;
      const sourceNames =
        nodes?.length > 0
          ? nodes.map((node) => ({ value: node, label: node }))
          : [];
      setSourceNames(sourceNames);
    }
  }, [dataSN]);

  useEffect(() => {
    if (sourceNameProp != null && sourceNames?.length > 0) {
      const current = sourceNames.find((i) => i.value === sourceNameProp);
      if (current != null) {
        const { value, label } = current;
        setSourceName((prev) => ({ ...prev, value, label }));
      }
    }
  }, [sourceNameProp, sourceNames]);
  useEffect(() => {
    if (fulfillmentSSOProp != null) {
      const current = FULFILLMENT_SS_OPTIONS_OB.find(
        (i) => i.value === fulfillmentSSOProp
      );
      if (current != null) {
        const { value, label } = current;
        setFulfillmentSSOption((prev) => ({ ...prev, value, label }));
      }
    }
  }, [fulfillmentSSOProp]);

  useEffect(() => {
    if (true === filterBulkAccept) {
      setBulkAcceptDesign({
        value: YES,
        label: YES,
      });
    }
  }, [filterBulkAccept]);

  useEffect(() => {
    if (true === manualOrderProp) {
      setManualOrder({
        value: YES,
        label: YES,
      });
    }
  }, [manualOrderProp]);

  useEffect(() => {
    if (typeof hasNote !== "boolean") return;
    let s = { value: NO, label: NO };
    if (true === hasNote) {
      s = {
        value: YES,
        label: YES,
      };
    }
    setOrderHasNote(s);
  }, [hasNote]);

  useEffect(() => {
    if (true === filterUsingPD) {
      setUsingPD({
        value: YES,
        label: YES,
      });
    }
  }, [filterUsingPD]);

  useEffect(() => {
    if (dataT) {
      let newTeam =
        dataT?.teams?.nodes?.length > 0
          ? dataT.teams.nodes
              .map((node) => {
                if (!node) {
                  return null;
                }
                const { id, name } = node;
                return {
                  value: id,
                  label: name,
                };
              })
              .filter(Boolean)
          : [];
      setTeams(newTeam);
      const teamId = filter.teamId;
      if (teamId) {
        let currentTeam =
          newTeam?.length > 0 ? newTeam.find((i) => i.value === teamId) : null;
        if (currentTeam) {
          setTeam((prev) => ({ ...prev, ...currentTeam }));
        }
      }
    }
  }, [dataT, filter.teamId]);

  useEffect(() => {
    if (dataFF) {
      let newData =
        dataFF?.fulfillments?.length > 0
          ? dataFF.fulfillments.map((i) => ({ value: i.id, label: i.name }))
          : [];
      newData.push({ value: "merchbridge", label: "MerchBridge" });
      setFulfillments(newData);
      if (filter && filter.fulfilmentId) {
        let propsFF = filter.fulfilmentId;
        let currentFF = newData.find((i) => i.value === propsFF);
        if (currentFF) {
          setFulfillment((prevState) => ({
            ...prevState,
            value: currentFF.value,
            label: currentFF.label,
          }));
        }
      }
    }
  }, [dataFF, filter.fulfilmentId]);

  useEffect(() => {
    if (dataStore) {
      let newData =
        dataStore.stores?.nodes?.length > 0
          ? dataStore.stores.nodes.map((i) => {
              let subTitle = i.domain;
              subTitle = subTitle.replace("https://", "");
              subTitle = subTitle.replace("http://", "");

              if (i.platform && ["ebay", "amazon"].includes(i.platform)) {
                subTitle = i.email;
              }
              return {
                value: i.id,
                // label: i.title,
                label: (
                  <LabelContainer>
                    <span className="title">{i.title}</span>
                    <span className="domain">{subTitle}</span>
                  </LabelContainer>
                ),
              };
            })
          : [];
      setStores(newData);
      if (filter && filter.storeId) {
        let propsStore = filter.storeId;
        let currentStore = newData.find((i) => i.value === propsStore);
        if (currentStore) {
          setStore((prevState) => ({
            ...prevState,
            value: currentStore.value,
            label: currentStore.label,
          }));
        }
      }
    }
  }, [dataStore, filter.storeId]);

  useEffect(() => {
    if (dataPB) {
      let newData =
        dataPB.productBasesForSeller?.nodes?.length > 0
          ? dataPB.productBasesForSeller.nodes.map((i) => ({
              value: i.id,
              label: i.title,
            }))
          : [];
      setProductBases(newData);
      if (filter && filter.productBaseId) {
        let propsPB = filter.productBaseId;
        let currentPB = newData.find((i) => i.value === propsPB);
        if (currentPB) {
          setProductBase((prevState) => ({
            ...prevState,
            value: currentPB.value,
            label: currentPB.label,
          }));
        }
      }
    }
  }, [dataPB, filter.productBaseId]);

  useEffect(() => {
    if (dataCarrier) {
      let newData =
        dataCarrier.carriers?.length > 0
          ? dataCarrier.carriers.map((i) => ({ value: i.id, label: i.name }))
          : [];
      setCarriers(newData);
      if (filter && filter.carrierId) {
        let propsCarrier = filter.carrierId;
        let currentCarrier = newData.find((i) => i.value === propsCarrier);
        if (currentCarrier) {
          setCarrier((prevState) => ({
            ...prevState,
            value: currentCarrier.value,
            label: currentCarrier.label,
          }));
        }
      }
    }
  }, [dataCarrier, filter.carrierId]);

  useEffect(() => {
    let newData =
      dataSup?.listUserByRole?.length > 0
        ? dataSup.listUserByRole.map((i) => ({
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          }))
        : [];

    if (isSeller && dSup) {
      const nodes = dSup.suppliers || [];
      newData = nodes.map(({ id, firstName, lastName }) => ({
        value: id,
        label: [firstName, lastName].filter(Boolean).join(" "),
      }));
    }
    setSuppliers(newData);
    if (filter && filter.supplierId) {
      let propsSup = filter.supplierId;
      let currentSup = newData.find((i) => i.value === propsSup);
      if (currentSup) {
        setSupplier((prevState) => ({
          ...prevState,
          value: currentSup.value,
          label: currentSup.label,
        }));
      }
    }
  }, [dataSup, filter.supplierId, isSeller, dSup]);

  useEffect(() => {
    if (dataGroup) {
      let newGroups =
        dataGroup?.groups?.nodes?.length > 0
          ? dataGroup.groups.nodes.map((g) => ({ value: g.id, label: g.name }))
          : [];
      setGroups(newGroups);
    }
  }, [dataGroup]);

  useEffect(() => {
    let newGroupId = filter?.groupId;
    if (newGroupId && groups.length > 0) {
      let currentG = groups.find((g) => [newGroupId].includes(g.value));
      if (currentG) {
        let { value, label } = currentG;
        setGroup((prev) => ({ ...prev, value, label }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.groupId, groups]);

  // Get data collection
  useEffect(() => {
    const nodes =
      dataCollection?.collections?.nodes?.length > 0
        ? dataCollection.collections.nodes
        : [];
    const newCollections = formatDataTree(nodes);
    setCollections(newCollections);
  }, [dataCollection]);

  useEffect(() => {
    const nodes = dataTag?.tags?.nodes?.length > 0 ? dataTag.tags.nodes : [];
    const newTags = formatDataTree(nodes);
    setTags(newTags);
  }, [dataTag]);

  useEffect(() => {
    if (true === filter.personalized) {
      setOrderType((prevState) => ({
        ...prevState,
        value: "personalized",
        label: "Personalized",
      }));
    }
    if (false === filter.personalized) {
      setOrderType((prevState) => ({
        ...prevState,
        value: "normal",
        label: "Normal",
      }));
    }
  }, [filter]);

  useEffect(() => {
    if (filter.orderSource) {
      let propsOS = filter.orderSource;
      let currentOS = ORDER_SOURCE.filter((i) => propsOS.includes(i.value));
      let ids = [];
      let labels = [];
      if (currentOS && currentOS.length) {
        currentOS.forEach((i) => {
          ids.push(i.value);
          labels.push(i.label);
        });
      }
      if (currentOS.length) {
        setOrderSource((prevState) => ({
          ...prevState,
          value: ids,
          label: labels,
        }));
      }
    }
  }, [filter.orderSource]);

  useEffect(() => {
    if (true === filter.designTaskCreated) {
      setDesignTaskCreated((prevState) => ({
        ...prevState,
        value: "assigned",
        label: "Assigned",
      }));
    }
    if (false === filter.designTaskCreated) {
      setDesignTaskCreated((prevState) => ({
        ...prevState,
        value: "unassigned",
        label: "Unassigned",
      }));
    }
  }, [filter]);

  useEffect(() => {
    let allTM = [];
    if (dataMM) {
      let newDataMM =
        dataMM.teamMembers?.hits?.length > 0
          ? dataMM.teamMembers.hits
              .map((i) => {
                if (i.user) {
                  let fullName = `${i.user.firstName} ${i.user.lastName}`;
                  return {
                    value: i.user.id,
                    label: fullName,
                  };
                }
                return null;
              })
              .filter(Boolean)
          : [];
      allTM = [...newDataMM];
    }
    if (dataTM) {
      let newData =
        dataTM.teamMembers?.hits?.length > 0
          ? dataTM.teamMembers.hits
              .map((i) => {
                if (i.user) {
                  let fullName = `${i.user.firstName} ${i.user.lastName}`;
                  return {
                    value: i.user.id,
                    label: fullName,
                  };
                }
                return null;
              })
              .filter(Boolean)
          : [];
      allTM = [...allTM, ...newData];
    }

    if (filter && filter.storeManagerId) {
      let propsST = filter.storeManagerId;
      let currentST = allTM.find((i) => i.value === propsST);
      if (currentST) {
        setStoreManager((prevState) => ({
          ...prevState,
          value: currentST.value,
          label: currentST.label,
        }));
      }
    }

    setStoreManagers(allTM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTM, filter]);

  useEffect(() => {
    if (TRACKING_STATUS) {
      let trackingStatus = OPTIONS_TRACKING_STATUS;
      let newData =
        trackingStatus && trackingStatus.length > 0
          ? trackingStatus.map((i) => ({ value: i.key, label: i.name }))
          : [];
      newData = [
        {
          label: "All",
          value: "All",
        },
        ...newData,
      ];
      setListTrackingStatus(newData);
      if (filter && filter.trackingStatus) {
        let propsTS = filter.trackingStatus;
        let currentTS = newData.find((i) => i.value === propsTS);
        if (currentTS) {
          setTrackingStatus((prevState) => ({
            ...prevState,
            value: currentTS.value,
            label: currentTS.label,
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TRACKING_STATUS, filter]);

  useEffect(() => {
    if (filter) {
      if (filter.search) {
        let search = filter.search;
        setQueryValue(search);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    let convertTS = trackingStatus.value;
    let range = filterTime.range;
    let newReadyToPrintTime = readyToPrintTime.range;

    const fulfillmentId =
      fulfillment.value != "merchbridge" ? fulfillment.value : "";

    let fulfillmentSSOptionVal = fulfillmentSSOption.value;
    if (fulfillmentSSOptionVal === "None") {
      fulfillmentSSOptionVal = null;
    }

    const convertType = matchVal(orderType, orderTypeToBool);
    const convertDT = matchVal(designTaskCreated, designTaskToBool);
    const convertAD = matchVal(bulkAcceptDesign, stringToBool);
    const convertUPD = matchVal(usingPD, stringToBool);
    const convertHN = matchVal(orderHasNote, stringToBool);
    const manualOrderVal = matchVal(manualOrder, stringToBool);

    typingTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          fulfilmentId: fulfillmentId,
          storeId: store.value,
          productBaseId: productBase.value,
          carrierId: carrier.value,
          supplierId: supplier.value,
          trackingStatus: convertTS,
          personalized: convertType,
          orderSource: orderSource.value,
          designTaskCreated: convertDT,
          storeManagerId: storeManager.value,
          range,
          groupId: group.value,
          teamId: team.value,
          usingTaskBulkDesign: convertAD,
          collectionIds: collection.value?.length > 0 ? collection.value : null,
          tagIds: tag.value?.length > 0 ? tag.value : null,
          usingProductDesign: convertUPD,
          readyToPrintTime: newReadyToPrintTime,
          referringSite: referringSite.value,
          sourceName: sourceName.value,
          fulfillmentServiceShippingOption: fulfillmentSSOptionVal,
          orderHasNote: convertHN,
          manualOrder: manualOrderVal,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryValue,
    fulfillment.value,
    store.value,
    productBase.value,
    carrier.value,
    supplier.value,
    trackingStatus.value,
    orderType.value,
    orderSource.value,
    designTaskCreated.value,
    storeManager.value,
    filterTime,
    group,
    team.value,
    bulkAcceptDesign.value,
    collection.value,
    tag.value,
    usingPD.value,
    readyToPrintTime,
    referringSite.value,
    sourceName.value,
    fulfillmentSSOption.value,
    orderHasNote.value,
    manualOrder.value,
  ]);

  // Action change
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );

  const handleCollectionChange = useCallback(({ value, label }) => {
    setCollection((prev) => ({ ...prev, value, label }));
  }, []);

  const handleCollectionSearch = useCallback(
    (search) => setCollection((prev) => ({ ...prev, search })),
    []
  );

  const handleTagChange = useCallback(({ value, label }) => {
    setTag((prev) => ({ ...prev, value, label }));
  }, []);

  const handleTagSearch = useCallback(
    (search) => setTag((prev) => ({ ...prev, search })),
    []
  );

  // Action remove
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFulfillmentRemove = useCallback(
    () => setFulfillment(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleStoreRemove = useCallback(
    () => setStore(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleProductBaseRemove = useCallback(
    () => setProductBase(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleCarrierRemove = useCallback(
    () => setCarrier(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleSupplierRemove = useCallback(
    () => setSupplier(() => ({ value: null, label: null, search: null })),
    []
  );

  const handleTrackingStatusRemove = useCallback(
    () => setTrackingStatus(() => ({ value: null, label: null })),
    []
  );
  const handleFilterTimeRemove = useCallback(
    () => setFilterTime(() => ({ range: null, rangeLabel: null })),
    []
  );
  const handleReadyPrintTimeRemove = useCallback(
    () => setReadyToPrintTime(() => ({ range: null, rangeLabel: null })),
    []
  );
  const handleOrderTypeRemove = useCallback(
    () => setOrderType(() => ({ value: null, label: null })),
    []
  );
  const handleOrderSourceRemove = useCallback(
    () => setOrderSource(() => ({ value: [], label: [] })),
    []
  );
  const handleDesignTaskRemove = useCallback(
    () => setDesignTaskCreated(() => ({ value: null, label: null })),
    []
  );
  const handleSMRemove = useCallback(
    () => setStoreManager(() => ({ value: null, label: null, search: null })),
    []
  );

  const handleGroupRemove = useCallback(
    () => setGroup(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleTeamRemove = useCallback(
    () => setTeam({ value: null, label: null, search: null }),
    []
  );
  const fulfillmentIdHandleBulkAcceptDesignRemove = useCallback(
    () => setBulkAcceptDesign({ value: null, label: null }),
    []
  );

  const handleManualOrderRemove = useCallback(
    () => setManualOrder({ value: null, label: null }),
    []
  );
  const handleOrderHasNoteRemove = useCallback(
    () => setOrderHasNote({ value: null, label: null }),
    []
  );

  const handleCollectionRemove = useCallback(
    () => setCollection((prev) => ({ ...prev, value: [], label: [] })),
    []
  );
  const handleTagRemove = useCallback(
    () => setTag((prev) => ({ ...prev, value: [], label: [] })),
    []
  );
  const handleUsingPDRemove = useCallback(
    () => setUsingPD({ value: null, label: null }),
    []
  );
  const handleRSRemove = useCallback(
    () => setReferringSite(() => ({ value: null, label: null, search: null })),
    []
  );
  const handleSNRemove = useCallback(
    () => setSourceName(() => ({ value: null, label: null, search: null })),
    []
  );

  const handleFSSORemove = useCallback(
    () =>
      setFulfillmentSSOption(() => ({
        value: "None",
        label: "None",
        search: null,
      })),
    []
  );

  let filterClearAll = [
    handleQueryValueRemove,
    handleFulfillmentRemove,
    handleStoreRemove,
    handleProductBaseRemove,
    handleCarrierRemove,
    handleSupplierRemove,
    handleTrackingStatusRemove,
    handleFilterTimeRemove,
    handleOrderTypeRemove,
    handleOrderSourceRemove,
    handleDesignTaskRemove,
    handleSMRemove,
    handleGroupRemove,
    handleTeamRemove,
    fulfillmentIdHandleBulkAcceptDesignRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleUsingPDRemove,
    handleReadyPrintTimeRemove,
    handleRSRemove,
    handleSNRemove,
    handleFSSORemove,
    handleManualOrderRemove,
  ];

  const handleFiltersClearAll = useCallback(() => {
    for (let fn of filterClearAll) {
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filterClearAll]);

  const isAdminOrSeller =
    [USER_ROLE.Admin, USER_ROLE.Seller].includes(role) && !isSupplier;
  const filters = [
    ...(isAdminOrSeller
      ? [
          {
            key: "fulfillment",
            label: "Fulfillment by",
            filter: (
              <FilterNoSearchPolaris
                data={fulfillments}
                value={fulfillment}
                loading={loadingFF}
                error={errorFF}
                onChange={({ value, label }) => {
                  setFulfillment((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }));
                }}
                onChangeSearch={({ search }) =>
                  setFulfillment((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller && !hideFilterStore
      ? [
          {
            key: "store",
            label: "Stores",
            filter: (
              <FilterHasSearchPolaris
                options={stores}
                value={store}
                loading={loadingStore}
                error={errorStore}
                onChangeSearch={(search) =>
                  setStore((prevState) => ({ ...prevState, search }))
                }
                onChange={({ value, label }) =>
                  setStore((prevState) => ({ ...prevState, value, label }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdministrator
      ? [
          {
            key: "team",
            label: "Team",
            filter: (
              <FilterHasSearchPolaris
                options={teams}
                value={team}
                loading={loadingT}
                error={errorT}
                onChangeSearch={(search) =>
                  setTeam((prevState) => ({ ...prevState, search }))
                }
                onChange={({ value, label }) =>
                  setTeam((prevState) => ({ ...prevState, value, label }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller
      ? [
          {
            key: "product-base",
            label: "Product base",
            filter: (
              <FilterNoSearchPolaris
                data={productBases}
                value={productBase}
                loading={loadingPB}
                error={errorPB}
                onChange={({ value, label }) =>
                  setProductBase((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setProductBase((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller
      ? [
          {
            key: "carrier",
            label: "Carrier",
            filter: (
              <FilterNoSearchPolaris
                data={carriers}
                value={carrier}
                loading={loadingCarrier}
                error={errorCarrier}
                onChange={({ value, label }) =>
                  setCarrier((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setCarrier((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller || isAdmin // tab supplier of account admin
      ? [
          {
            key: "supplier",
            label: "Supplier",
            filter: (
              <FilterNoSearchPolaris
                data={suppliers}
                value={supplier}
                loading={lSupMerge}
                error={eSupMerge}
                onChange={({ value, label }) =>
                  setSupplier((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setSupplier((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...([ORDER_STATUS.Fulfilled].includes(status) || !status
      ? [
          {
            key: "tracking-status",
            label: "Tracking status",
            filter: (
              <FilterListRadioPolaris
                value={trackingStatus && trackingStatus.value}
                options={listTrackingStatus}
                onChange={({ value, label }) =>
                  setTrackingStatus((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),

    ...(isAdminOrSeller
      ? [
          {
            key: "filter-time",
            label: "Filter time by",
            filter: (
              <FilterOrderByTimePolaris
                options={FILTER_TIME}
                filterTime={filterTime}
                onChangeRange={(range, rangeLabel) => {
                  setFilterTime((prevState) => ({
                    ...prevState,
                    range,
                    rangeLabel,
                  }));
                }}
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller
      ? [
          {
            key: "order-type",
            label: "Order type",
            filter: (
              <FilterListRadioPolaris
                value={orderType.value}
                options={ORDER_TYPE}
                onChange={({ value, label }) =>
                  setOrderType((prevState) => ({ ...prevState, value, label }))
                }
              />
            ),
          },
        ]
      : []),
    ...(isAdminOrSeller
      ? [
          {
            key: "order-source",
            label: "Order source",
            filter: (
              // <FilterListRadioPolaris
              //   value={orderSource.value}
              //   options={ORDER_SOURCE}
              //   onChange={({ value, label }) =>
              //     setOrderSource((prevState) => ({
              //       ...prevState,
              //       value,
              //       label,
              //     }))
              //   }
              // />
              <FilterNoSearchMVPolaris
                value={orderSource}
                data={ORDER_SOURCE}
                noSearch
                onChange={({ value, label }) =>
                  setOrderSource((prev) => {
                    if (value !== prev.value || label !== prev.label) {
                      return { ...prev, value, label };
                    }
                  })
                }
              />
            ),
          },
        ]
      : []),
    ...([ORDER_STATUS.PendingDesign].includes(status) &&
    [USER_ROLE.Admin, USER_ROLE.Seller].includes(roles)
      ? [
          {
            key: "design-task",
            label: "Design Task",
            filter: (
              <FilterListRadioPolaris
                value={designTaskCreated.value}
                options={DESIGN_TASK}
                onChange={({ value, label }) =>
                  setDesignTaskCreated((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    ...(showFilterSM
      ? [
          {
            key: "store-manager",
            label: "Store manager",
            filter: (
              <FilterNoSearchPolaris
                data={storeManagers}
                value={storeManager}
                loading={loadingMM || loadingTM}
                error={errorTM || errorMM}
                onChange={({ value, label }) =>
                  setStoreManager((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setStoreManager((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...(showFilterSM
      ? [
          {
            key: "group",
            label: "Group",
            filter: (
              <FilterNoSearchPolaris
                data={groups}
                value={group}
                loading={loadingG}
                error={errorG}
                onChange={({ value, label }) =>
                  setGroup((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setGroup((prevState) => ({ ...prevState, search }))
                }
              />
            ),
          },
        ]
      : []),
    ...([USER_ROLE.Seller].includes(roles) && !isSupplier
      ? [
          {
            key: "bulk-ad",
            label: "Bulk Accept Design",
            filter: (
              <FilterListRadioPolaris
                value={bulkAcceptDesign.value}
                options={BULK_ACCEPT}
                onChange={({ value, label }) =>
                  setBulkAcceptDesign((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "using-pd", // Using product design
            label: "Using Product Design",
            filter: (
              <FilterListRadioPolaris
                value={usingPD.value}
                options={USING_PRODUCT_DESIGN}
                onChange={({ value, label }) =>
                  setUsingPD((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "collection",
      label: "Collections",
      filter: (
        <FilterHasSearchMVPolaris
          value={collection}
          options={collections}
          loading={loadingCollection}
          error={errorCollection}
          onChange={handleCollectionChange}
          onChangeSearch={handleCollectionSearch}
        />
      ),
    },
    {
      key: "tag",
      label: "Tags",
      filter: (
        <FilterHasSearchMVPolaris
          value={tag}
          options={tags}
          loading={loadingTag}
          error={errorTag}
          onChange={handleTagChange}
          onChangeSearch={handleTagSearch}
        />
      ),
    },
    ...(isAdminOrSeller
      ? [
          {
            key: "readyToPrintTime",
            label: "Submitted to fulfillment time",
            filter: (
              <FilterOrderByTimePolaris
                options={FILTER_TIME}
                filterTime={readyToPrintTime}
                onChangeRange={(range, rangeLabel) => {
                  setReadyToPrintTime((prevState) => ({
                    ...prevState,
                    range,
                    rangeLabel,
                  }));
                }}
              />
            ),
          },
        ]
      : []),
    {
      key: "referring-site",
      label: "Referring Site",
      filter: (
        <FilterNoSearchPolaris
          data={referringSites}
          value={referringSite}
          loading={loadingRS}
          error={errorRS}
          onChange={({ value, label }) =>
            setReferringSite((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
          onChangeSearch={({ search }) =>
            setReferringSite((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "source-name",
      label: "Source Name",
      filter: (
        <FilterNoSearchPolaris
          data={sourceNames}
          value={sourceName}
          loading={loadingSN}
          error={errorSN}
          onChange={({ value, label }) =>
            setSourceName((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
          onChangeSearch={({ search }) =>
            setSourceName((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "fulfillment-sso",
      label: "Using Fulfillment Shipping Option",
      filter: (
        <FilterNoSearchPolaris
          data={FULFILLMENT_SS_OPTIONS_OB}
          value={fulfillmentSSOption}
          hasAll
          defaultValue="None"
          onChange={({ value, label }) =>
            setFulfillmentSSOption((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
          onChangeSearch={({ search }) =>
            setFulfillmentSSOption((prevState) => ({ ...prevState, search }))
          }
        />
      ),
    },
    {
      key: "hasNote",
      label: "Has Note",
      filter: (
        <FilterListRadioPolaris
          value={orderHasNote.value}
          options={HAS_NOTE_OPTIONS}
          onChange={({ value, label }) =>
            setOrderHasNote((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    (isSeller || isStoreManager || isSupplier) && {
      key: "manualOrder",
      label: "Manual Order",
      filter: (
        <FilterListRadioPolaris
          value={manualOrder.value}
          options={HAS_NOTE_OPTIONS}
          onChange={({ value, label }) =>
            setManualOrder((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
  ].filter(Boolean);

  const appliedFilters = [];
  if (!isEmpty(fulfillment.label)) {
    const key = "fulfillment";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, fulfillment.label),
      onRemove: handleFulfillmentRemove,
    });
  }
  if (!isEmpty(store.label)) {
    const key = "store";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }
  if (!isEmpty(productBase.label)) {
    const key = "product-base";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productBase.label),
      onRemove: handleProductBaseRemove,
    });
  }
  if (!isEmpty(carrier.label)) {
    const key = "carrier";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, carrier.label),
      onRemove: handleCarrierRemove,
    });
  }
  if (!isEmpty(supplier.label)) {
    const key = "supplier";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, supplier.label),
      onRemove: handleSupplierRemove,
    });
  }
  if (!isEmpty(trackingStatus.label)) {
    const key = "tracking-status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, trackingStatus.label),
      onRemove: handleTrackingStatusRemove,
    });
  }
  if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
    const key = "filter-time";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filterTime.rangeLabel),
      onRemove: handleFilterTimeRemove,
    });
  }
  if (!isEmpty(readyToPrintTime.rangeLabel) && readyToPrintTime.range) {
    const key = "readyToPrintTime";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, readyToPrintTime.rangeLabel),
      onRemove: handleReadyPrintTimeRemove,
    });
  }
  if (!isEmpty(orderType.label)) {
    const key = "order-type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderType.label),
      onRemove: handleOrderTypeRemove,
    });
  }
  if (orderSource.label.length) {
    const key = "order-source";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderSource.label),
      onRemove: handleOrderSourceRemove,
    });
  }
  if (!isEmpty(designTaskCreated.label)) {
    const key = "design-task";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, designTaskCreated.label),
      onRemove: handleDesignTaskRemove,
    });
  }
  if (!isEmpty(storeManager.label)) {
    const key = "store-manager";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, storeManager.label),
      onRemove: handleSMRemove,
    });
  }
  if (!isEmpty(group.label)) {
    const key = "group";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, group.label),
      onRemove: handleGroupRemove,
    });
  }
  if (!isEmpty(team.label)) {
    const key = "team";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, team.label),
      onRemove: handleTeamRemove,
    });
  }
  if (!isEmpty(bulkAcceptDesign.label)) {
    const key = "bulk-ad";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, bulkAcceptDesign.label),
      onRemove: fulfillmentIdHandleBulkAcceptDesignRemove,
    });
  }
  if (!isEmpty(orderHasNote.label)) {
    const key = "hasNote";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderHasNote.label),
      onRemove: handleOrderHasNoteRemove,
    });
  }
  if (!isEmpty(manualOrder.label)) {
    const key = "manualOrder";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, manualOrder.label),
      onRemove: handleManualOrderRemove,
    });
  }
  if (!isEmpty(usingPD.label)) {
    const key = "using-pd";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, usingPD.label),
      onRemove: handleUsingPDRemove,
    });
  }
  if (!isEmpty(collection.label)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection.label),
      onRemove: handleCollectionRemove,
    });
  }

  if (!isEmpty(tag.label)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag.label),
      onRemove: handleTagRemove,
    });
  }

  if (!isEmpty(referringSite.label)) {
    const key = "referring-site";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, referringSite.label),
      onRemove: handleRSRemove,
    });
  }
  if (!isEmpty(sourceName.label)) {
    const key = "source-name";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sourceName.label),
      onRemove: handleSNRemove,
    });
  }
  if (
    !isEmpty(fulfillmentSSOption.label) &&
    fulfillmentSSOption.label !== "None"
  ) {
    const key = "fulfillment-sso";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, fulfillmentSSOption.label),
      onRemove: handleFSSORemove,
    });
  }

  return (
    <Container>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        onClearAll={handleFiltersClearAll}
        queryValue={queryValue}
        queryPlaceholder={"Filter order"}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
      />
      {[USER_ROLE.Admin].includes(role) &&
        [ORDER_STATUS.Pending, ORDER_STATUS.OnHold].includes(status) && (
          <div className="actions-wrap">
            {itemsChecked.length > 0 ? (
              <Checkbox
                checked={true}
                label={`${
                  itemsChecked.length === 1
                    ? itemsChecked.length + " order"
                    : itemsChecked.length + " orders"
                } selected`}
              />
            ) : null}
            {[ORDER_STATUS.Pending].includes(status) && (
              <ChargeBaseCostPolaris
                orderIds={itemsChecked}
                refetch={refetch}
                disabled={!itemsChecked.length}
              />
            )}
            {[ORDER_STATUS.OnHold].includes(status) && (
              <PassOnHoldPolaris
                orderIds={itemsChecked}
                reload={reload}
                disabled={!itemsChecked.length}
                updateCount={updateCount}
              />
            )}
          </div>
        )}
    </Container>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "fulfillment":
        return `Fulfillment: ${value}`;
      case "store":
        if ("string" !== typeof value) {
          let children = get(value, "props.children", null);
          if (Array.isArray(children)) {
            let currentLabel = children.find((i) => {
              return i?.props?.className === "title";
            });
            value = currentLabel?.props?.children;
          } else {
            value = children?.props.children;
          }
        }
        return `Store: ${value}`;
      case "product-base":
        return `Product base: ${value}`;
      case "carrier":
        return `Carrier: ${value}`;
      case "supplier":
        return `Supplier: ${value}`;
      case "tracking-status":
        return `Tracking status: ${value}`;
      case "filter-time":
        return `Time: ${value}`;
      case "readyToPrintTime":
        return `Submitted to fulfillment: ${value}`;
      case "order-type":
        return `Order type: ${value}`;
      case "order-source":
        let oSLabels = value && value.length > 0 ? value.join(", ") : "";
        return `Order source: ${oSLabels}`;
      case "design-task":
        return `Design task: ${value}`;
      case "store-manager":
        return `Store Manager: ${value}`;
      case "group":
        return `Group: ${value}`;
      case "team":
        return `Team: ${value}`;
      case "bulk-ad":
        return `Bulk Accept Design: ${value}`;
      case "using-pd":
        return `Using Product Design: ${value}`;
      case "collection":
        let collectionLabel = genLabelTree(value);
        return `Collections: ${collectionLabel}`;
      case "tag":
        let tagLabel = genLabelTree(value);
        return `Tags: ${tagLabel}`;
      case "source-name":
        return `Source name: ${value}`;
      case "fulfillment-sso":
        return `Fulfillment Shipping Option: ${value}`;
      case "manualOrder":
        return `Manual Order: ${value}`;
      default:
        return value;
    }
  }
};

function matchVal(data, object) {
  const val = get(data, "value");
  return object[val] || null;
}
