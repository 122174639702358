import React, { useState, useEffect, useCallback } from "react";
import { Popover, Button, OptionList, TextField } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
    .Polaris-OptionList__Options {
        .Polaris-OptionList-Option__SingleSelectOption > div {
            width: 100%;
        }
    }
`;

const SecondaryMenuPolaris = (props) => {
    const [selected, setSelected] = useState([]);
    const [popoverActive, setPopoverActive] = useState(false);
    const [stores, setStores] = useState([]);
    const [currentStoreName, setCurrentStoreName] = useState("");
    const [inputValue, setInputValue] = useState("");

    // Props
    const deselectedOpt = props.deselectedOpt;

    useEffect(() => {
        if (props.stores) {
            setStores(props.stores);
        }
        if (props.store) {
            props.store.id && setSelected([props.store.id]);
            props.store.title && setCurrentStoreName(props.store.title);
        }
    }, [props]);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );

    const onChangeStore = useCallback(
        (selected) => {
            if (selected[0] == null) {
                return;
            }

            setInputValue("");
            const selectedValue = selected.map((selectedItem) => {
                const matchedOption = stores.find((store) => {
                    return store.value === selectedItem;
                });
                return matchedOption && matchedOption.label;
            });
            props.setStore({ id: selected[0], title: selectedValue });
            setCurrentStoreName(selectedValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stores]
    );

    const activator = (
        <div className="secondary-menu" style={{ marginRight: 10 }}>
            <Button onClick={togglePopoverActive} disclosure>
                {currentStoreName ? currentStoreName : "Choose store"}
            </Button>
        </div>
    );

    const handleSearch = useCallback(
        (value) => {
            setInputValue(value);

            if (value === "") {
                setStores(deselectedOpt);
                return;
            }

            const filterRegex = new RegExp(value, "i");
            const resultOptions = deselectedOpt.filter((option) =>
                option.label.match(filterRegex)
            );

            const result =
                resultOptions?.length > 0
                    ? resultOptions
                    : [{ label: "Could not found store", value: null }];
            setStores(result);
        },
        [deselectedOpt]
    );

    // Markup
    const searchWrap = {
        label: (
            <TextField
                onChange={handleSearch}
                placeholder="Search store"
                value={inputValue}
            />
        ),
        value: null,
        active: true,
    };

    useEffect(() => {
        const containerDom = document.getElementById("list-store");
        const PopoverContent = containerDom?.closest(
            ".Polaris-Popover__Content"
        );

        if (PopoverContent != null) {
            PopoverContent.classList.add("list-store-wrap");
        }
    });

    return (
        <Popover
            active={popoverActive}
            activator={activator}
            onClose={togglePopoverActive}
        >
            <Container id="list-store">
                <OptionList
                    onChange={onChangeStore}
                    options={[searchWrap, ...stores]}
                    selected={selected}
                />
            </Container>
        </Popover>
    );
};

export default SecondaryMenuPolaris;
