import React, { useState, useCallback } from "react";
import { Button, Modal, Icon } from "@shopify/polaris";
import {
  ViewMajorMonotone,
  DeleteMajorMonotone,
  RefreshMajorMonotone,
  MaximizeMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import classNames from "classnames";

import { ComponentLabelPolaris } from "../shared/ComponentLabelPolaris";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  .item_wrap {
    width: 200px;
    height: 200px;
    position: relative;
    .item {
      border: 2px solid transparent;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      &.selected {
        border: 2px solid #5b69c4;
        + .actions_wrap {
          visibility: visible;
        }
      }
      &.overlay {
        opacity: 0.2;
      }
      &:not(.overlay) {
        cursor: pointer;
      }
      img {
        display: block;
        max-width: 100%;
        height: 100%;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
          rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
      }
    }
    .actions_wrap {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: start;
      flex-direction: row;
      column-gap: 0.75rem;
      visibility: hidden;
      .Polaris-Button {
        padding: 0.4rem;
        min-width: 3rem;
        .Polaris-Icon {
          height: 1.75rem;
          width: 1.75rem;
        }
      }
    }
    &:hover {
      .actions_wrap {
        visibility: visible;
      }
    }
  }
`;

const ViewOriginContainer = styled.div`
  position: relative;
  .view-origin_wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-right: 1rem;
    visibility: hidden;
    background: linear-gradient(
      180deg,
      rgba(33, 43, 54, 0.55),
      hsla(0, 0%, 100%, 0)
    );
    .view-origin_inner {
      display: inline-block;
      .Polaris-Icon {
        svg {
          fill: hsla(0, 0%, 100%, 0.8);
        }
      }
    }
  }
  &:hover {
    .view-origin_wrap {
      visibility: visible;
    }
  }
`;

export const MainImageSelectorPolaris = (props) => {
  const { images, value, deleteImgs, onChange, onChangeDeleteImgs } = props;
  const [currentImgShow, setCurrentImgShow] = useState(null);
  const [open, setOpen] = useState(false);
  const [, setDeleteImages] = useState({});

  const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);
  const handleDelete = useCallback((id) => {
    setDeleteImages((prev) => {
      let ids = [];
      let result = {
        ...prev,
        [id]: !prev[id],
      };
      for (let [key, value] of Object.entries(result)) {
        if (value) {
          ids.push(key);
        }
      }
      if (onChangeDeleteImgs) {
        onChangeDeleteImgs(ids);
      }

      return result;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ComponentLabelPolaris label="Main image" />
      <Container>
        {images.map((img, index) => {
          let src;
          if (img.file) {
            src = img.file.thumbnailUrl ? img.file.thumbnailUrl : img.file.url;
          } else if (img.thumbnailUrl || img.url) {
            src = img.thumbnailUrl ? img.thumbnailUrl : img.url;
          }

          let srcOrigin;
          if (img.file) {
            srcOrigin = img.file.url ? img.file.url : img.file.thumbnailUrl;
          } else if (img.url || img.thumbnailUrl) {
            srcOrigin = img.url ? img.url : img.thumbnailUrl;
          }

          return (
            <React.Fragment key={`img-${index}`}>
              <div className="item_wrap">
                <div
                  onClick={() => {
                    if (!deleteImgs.includes(img.id)) {
                      onChange(img.id);
                    }
                  }}
                  className={classNames("item", {
                    selected: img.id === value,
                    overlay:
                      deleteImgs.length &&
                      deleteImgs.includes(img.id) &&
                      img.id !== value,
                  })}
                >
                  <img src={src} alt={""} />
                </div>
                <div className={`actions_wrap`}>
                  <Button
                    icon={ViewMajorMonotone}
                    onClick={() => {
                      setCurrentImgShow(img.id);
                      toggleShowModal();
                    }}
                    size="slim"
                  />
                  {value && img.id !== value && (
                    <Button
                      icon={
                        deleteImgs.length && deleteImgs.includes(img.id)
                          ? RefreshMajorMonotone
                          : DeleteMajorMonotone
                      }
                      onClick={() => handleDelete(img.id)}
                      size="slim"
                    />
                  )}
                </div>
              </div>
              <Modal
                title="Image"
                open={open && currentImgShow === img.id}
                onClose={toggleShowModal}
                sectioned
              >
                <ViewOriginContainer>
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    src={srcOrigin}
                    alt=""
                  />
                  <div className="view-origin_wrap">
                    <div className="view-origin_inner">
                      <CustomLinkPolaris href={srcOrigin}>
                        <Icon source={MaximizeMajorMonotone}></Icon>
                      </CustomLinkPolaris>
                    </div>
                  </div>
                </ViewOriginContainer>
              </Modal>
            </React.Fragment>
          );
        })}
      </Container>
    </div>
  );
};
