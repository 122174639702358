import React from "react";
import styled from "styled-components";

import { ToastContextProvider } from "../shared/ToastContext";

export function WrapperPage({ children }) {
    return (
        <Wrapper>
            <ToastContextProvider>{children}</ToastContextProvider>
        </Wrapper>
    );
}
const Wrapper = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;
