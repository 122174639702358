import { useQuery } from "@apollo/react-hooks";
import React from "react";
import styled from "styled-components";
import history from "../../history";
import { GET_CONNECTED_APP } from "../app/apps";
import { PODApp } from "../../config";

const Container = styled.div`
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

const Personalized = () => {
  // Query
  const { data } = useQuery(GET_CONNECTED_APP, {
    fetchPolicy: "network-only",
  });

  const getPODApp = data?.getConnectedApp.find(
    (appItem) => appItem.app === "POD"
  );

  const listRedirect = ["artworks", "fonts", "cliparts"];
  const redirectTo = listRedirect.find((item) =>
    history.location.pathname.includes(item)
  );

  return (
    <Container>
      <iframe
        title="Personalized"
        style={{
          border: "none",
          width: "100%",
          height: "calc(100vh - 61px)",
        }}
        src={`${PODApp}/${redirectTo}?token=${getPODApp?.token}`}
      />
    </Container>
  );
};

export default Personalized;
