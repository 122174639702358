import React from "react";
import { Card, Loading } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import ExportTrackingDTPolaris from "./ExportTrackingDTPolaris";
import { getCookie, handleError } from "../../helper";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { ExportTrackingFilter } from "./ExportTrackingFilter";
import { COOKIE_EXPORT_TRACKING } from "../../pages/seller/ExportTrackingPagePolaris";
import { GET_ORDER_EXPORT_CONFIRM_SHIPMENT_TO_DAY_V2 } from "../../graphql/queries";

const QUERY_EXPORT_TRACKINGS = gql`
  query getOrderExportConfirmShipmentToDay(
    $limit: Int
    $paged: Int
    $storeId: ID
  ) {
    getOrderExportConfirmShipmentToDay(
      limit: $limit
      paged: $paged
      storeId: $storeId
    ) {
      total
      nodes {
        status
        order {
          id
          originId
          store {
            id
            title
          }
        }
      }
    }
  }
`;

const ExportTracking = ({
  handleBtnDisabeld,
  handleFilterChange,
  filter,
  handlePagination,
}) => {
  // Query
  const { data, loading, error } = useQuery(GET_ORDER_EXPORT_CONFIRM_SHIPMENT_TO_DAY_V2, {
    variables: {
      ...filter,
    },
    fetchPolicy: "no-cache",
  });

  // Markup
  const loadingMarkup = loading && <Loading />;

  React.useEffect(() => {
    let length = data?.getOrderExportConfirmShipmentToDayV2?.nodes?.length > 0;
    if (handleBtnDisabeld && filter?.storeId) {
      let val = !length;
      const expiredClick = getCookie(COOKIE_EXPORT_TRACKING + filter.storeId);
      if (expiredClick && parseInt(expiredClick) > new Date().getTime()) {
        val = true;
      }
      handleBtnDisabeld(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter?.storeId]);

  return (
    <React.Fragment>
      {loadingMarkup}
      <Card sectioned>
        <ExportTrackingFilter filter={filter} onChange={handleFilterChange} />
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.getOrderExportConfirmShipmentToDayV2?.nodes?.length > 0 ? (
          <ExportTrackingDTPolaris
            data={data}
            filter={filter}
            handlePagination={handlePagination}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};

export default ExportTracking;
