import React, { useCallback, useRef } from "react";
import { Modal } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { handleError } from "../../../helper";
import { SET_TASK_STATUS_TO_DOING } from "../../../graphql/mutations";
import { useToastContext } from "../../shared/ToastContext";

export const UpdateToDoingPolaris = (props) => {
    // Props
    const {
        task,
        open,
        toggleShowModal,
        refetch,
        toggleActive,
        onNotificationChange,
    } = props;
    // useLockBodyScroll(open);

     // Ref
     const onCloseRef = useRef(null);

     // Context
     const { setFilter } = useToastContext();

    // Mutation
    const [setTaskStatusToDoing, { loading }] = useMutation(
        SET_TASK_STATUS_TO_DOING,
        {
            onError: (error) => {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: handleError(error.toString()),
                        isError: true,
                    });
                }
            },
            onCompleted: () => {
                toggleShowModal();
                if (onNotificationChange) {
                    onNotificationChange({
                        message: "Task status is updated to Doing.",
                        isError: false,
                    });
                }

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    setFilter && setFilter((prev) => ({ ...prev }));
                    refetch && refetch();
                }, 1000);
            },
        }
    );

    // Handle action
    const handleSubmit = useCallback(() => {
        if (onNotificationChange) {
            onNotificationChange({
                message: null,
                isError: null,
            });
        }
        setTaskStatusToDoing({
            variables: {
                taskIds: [task.id],
            },
        });
        toggleActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    return (
        <Modal
            title="Set status to Doing"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure set status to Doing?</p>
        </Modal>
    );
};
