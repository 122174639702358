import { Query } from "@apollo/react-components";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  notification,
  Select,
  Skeleton,
  Spin,
} from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import styled from "styled-components";
import { Enable2FA } from "../../components/seller/components/Enable2FA";
import { formatUser, labelStyle } from "../../components/seller/Settings";
import PageTitle from "../../components/shared/PageTitle";
import { SETTING_VARIABLES } from "../../constants";
import { AppContext } from "../../context";
import {
  SAVE_MAILCHIMP,
  UPDATE_MANUAL_ORDER_CREATION_NOTICE,
} from "../../graphql/mutations";
import {
  GET_MAILCHIMP,
  GET_MANUAL_ORDER_CREATION_NOTICE,
} from "../../graphql/queries";
import { checkRole, handleError } from "../../helper";

const EnableStoreFulfillment = React.lazy(() =>
  import("../../components/settings/EnableStoreFulfillment"),
);

const AccessPaymentAccount = React.lazy(() =>
  import("../../components/settings/AccessPaymentAccount"),
);

const PushProductsSetting = React.lazy(() =>
  import("../../components/settings/PushProducts"),
);

const MailNotification = React.lazy(() =>
  import("../../components/settings/MailNotification"),
);

const FulfillmentConfig = React.lazy(() =>
  import("../../components/settings/FulfillmentConfig"),
);

const LOAD = gql`
  query variables($filter: VariableFilter) {
    variables(filter: $filter) {
      id
      key
      value
    }
  }
`;

const Container = styled.div`
  .actions {
    margin-top: 20px;
  }
`;

class AdminSettingsPage extends Component {
  state = {
    loading: false,
  };

  mailchimpRef = React.createRef();
  emailNotification = React.createRef();
  enable2fa = React.createRef();
  enableStoreFulfillment = React.createRef();
  accessPayment = React.createRef();
  pushProducts = React.createRef();
  mailNotification = React.createRef();
  fulfillmentConfig = React.createRef();

  render() {
    const { currentUser } = this.context || {};
    const { isRootUser } = currentUser || {};
    const { isAdministrator } = checkRole(currentUser);
    const showAccessPayment = isAdministrator && isRootUser;

    return (
      <Container>
        <PageTitle title={"Settings"} />
        <Query query={LOAD}>
          {({ error, loading, data, client, refetch }) => {
            if (error) return <div>Error: {handleError(error.toString())}</div>;
            if (loading) return <Skeleton active />;
            let variables = {};
            if (data.variables && data.variables.length) {
              for (let i = 0; i < data.variables.length; i++) {
                variables[data.variables[i].key] = data.variables[i].value;
              }
            }
            const keys = [
              "on_hold_order_days",
              "submit_tracking_delay",
              "google_categories",
              "shopify_api",
              "shopify_secret",
              "trackingmore_api",
              "shopbase_key",
              "shopbase_secret",
              "mail_notification",
            ];
            let initialValues = {};
            for (let i = 0; i < keys.length; i++) {
              initialValues[keys[i]] = variables[keys[i]];
            }

            const pushProductsVar = {};
            pushProductsVar["push_product_jobs_priority_by"] =
              variables["push_product_jobs_priority_by"];
            pushProductsVar["push_product_jobs_priority_special_ids"] =
              variables["push_product_jobs_priority_special_ids"];

            const mailNotification = variables[SETTING_VARIABLES.MN];
            const teamFulfillmentConfig = variables[SETTING_VARIABLES.FC];

            return (
              <Card>
                <Form
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  initialValues={initialValues}
                  onFinish={(values) => {
                    this.mailchimpRef.current &&
                      this.mailchimpRef.current.onSubmit();
                    this.emailNotification.current &&
                      this.emailNotification.current.onSubmit();
                    this.enable2fa.current && this.enable2fa.current.onSubmit();
                    this.enableStoreFulfillment.current &&
                      this.enableStoreFulfillment.current.onSubmit();
                    this.accessPayment.current &&
                      this.accessPayment.current.onSubmit();

                    this.pushProducts.current &&
                      this.pushProducts.current.onSubmit();

                    this.mailNotification.current &&
                      this.mailNotification.current.onSubmit();

                    this.fulfillmentConfig.current &&
                      this.fulfillmentConfig.current.onSubmit();

                    if (Object.keys(values).length !== keys.length) {
                      values = { ...initialValues, ...values };
                    }

                    let args = keys.map((c) => `$input__${c}: NewVariable!`);
                    let mutationContent = keys.map((c, index) => {
                      return `mutation__${index}:setVariable(input: $input__${c}){id}`;
                    });
                    const q = `mutation setVariable(${args.join(
                      ",",
                    )}){ ${mutationContent.join("\n")} }`;
                    let input = {};
                    for (let i = 0; i < keys.length; i++) {
                      const key = keys[i];
                      const value = `${values[key]}`;
                      input[`input__${key}`] = { key, value };
                    }
                    const mutate = gql`
                      ${q}
                    `;
                    this.setState({ loading: true }, () => {
                      client
                        .mutate({
                          mutation: mutate,
                          variables: input,
                        })
                        .then(() => {
                          notification.success({
                            message: "Settings has been saved!",
                          });
                          this.setState({ loading: false });
                        })
                        .catch((e) => {
                          this.setState({ loading: false });
                          notification.error({
                            message: handleError(e.toString()),
                          });
                        });
                    });
                  }}
                >
                  <Collapse defaultActiveKey={["general"]}>
                    <Collapse.Panel key={"general"} header={"General settings"}>
                      <Form.Item
                        label={"On Hold Order"}
                        name={"on_hold_order_days"}
                      >
                        <Select>
                          {[
                            "12 hours",
                            "1 day",
                            "2 days",
                            "3 days",
                            "4 days",
                            "5 days",
                            "6 days",
                            "7 days",
                          ].map((num) => {
                            return (
                              <Select.Option key={`${num}`} value={`${num}`}>
                                {num}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"Google shopping categories"}
                        name={"google_categories"}
                      >
                        <Input.TextArea style={{ minHeight: 200 }} />
                      </Form.Item>
                    </Collapse.Panel>

                    <Collapse.Panel key={"shopify"} header={"Shopify"}>
                      <Form.Item label={"API"} name={"shopify_api"}>
                        <Input />
                      </Form.Item>
                      <Form.Item label={"Secret"} name={"shopify_secret"}>
                        <Input />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel key={"tracking"} header={"Tracking"}>
                      <Form.Item
                        label={"Submit tracking delay"}
                        name={"submit_tracking_delay"}
                      >
                        <Select>
                          {[0, 1, 2, 3, 4, 5, 6, 7].map((num) => {
                            return (
                              <Select.Option key={`${num}`} value={`${num}`}>
                                {num} days
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"Trackingmore API"}
                        name={"trackingmore_api"}
                      >
                        <Input />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel key={"shopbase"} header={"Shopbase"}>
                      <Form.Item label={"Api key"} name={"shopbase_key"}>
                        <Input />
                      </Form.Item>
                      <Form.Item label={"Api Secret"} name={"shopbase_secret"}>
                        <Input />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel key="mailchimp" header="Mailchimp">
                      <Mailchimp ref={this.mailchimpRef} />
                    </Collapse.Panel>
                    {/* <Collapse.Panel
                      key="emailNotification"
                      header="Email notification"
                    >
                      <EmailNotification ref={this.emailNotification} />
                    </Collapse.Panel> */}
                    <Collapse.Panel
                      key="enableStoreFulfillment"
                      header="Enable Store Fulfillment"
                    >
                      <React.Suspense fallback={<Spin />}>
                        <EnableStoreFulfillment
                          ref={this.enableStoreFulfillment}
                        />
                      </React.Suspense>
                    </Collapse.Panel>
                    <Collapse.Panel
                      key="enable2fa"
                      header="Two-Factor Authentication (2FA)"
                    >
                      <Enable2FA ref={this.enable2fa} />
                    </Collapse.Panel>
                    {showAccessPayment ? (
                      <Collapse.Panel
                        key="accessPayment"
                        header="Access Payment Accounts"
                      >
                        <React.Suspense fallback={<Spin />}>
                          <AccessPaymentAccount ref={this.accessPayment} />
                        </React.Suspense>
                      </Collapse.Panel>
                    ) : null}
                    <Collapse.Panel key="pushProducts" header="Push products">
                      <React.Suspense fallback={<Spin />}>
                        <PushProductsSetting
                          value={pushProductsVar}
                          ref={this.pushProducts}
                          refetch={refetch}
                        />
                      </React.Suspense>
                    </Collapse.Panel>
                    <Collapse.Panel
                      key="mailNotification"
                      header="Mail Notification"
                    >
                      <React.Suspense fallback={<Spin />}>
                        <MailNotification
                          value={mailNotification}
                          ref={this.mailNotification}
                          refetch={refetch}
                        />
                      </React.Suspense>
                    </Collapse.Panel>
                    <Collapse.Panel
                      key="fulfillmentConfig"
                      header="Set Fulfillment Config"
                    >
                      <React.Suspense fallback={<Spin />}>
                        <FulfillmentConfig
                          value={teamFulfillmentConfig}
                          ref={this.fulfillmentConfig}
                          refetch={refetch}
                        />
                      </React.Suspense>
                    </Collapse.Panel>
                  </Collapse>

                  <div className={"actions"}>
                    <Button
                      loading={this.state.loading}
                      htmlType={"submit"}
                      type={"primary"}
                    >
                      Save Settings
                    </Button>
                  </div>
                </Form>
              </Card>
            );
          }}
        </Query>
      </Container>
    );
  }
}

const Mailchimp = React.forwardRef(function Mailchimp(props, ref) {
  const { data, loading, error } = useQuery(GET_MAILCHIMP);
  const [form] = Form.useForm();

  // Mutation
  const [save] = useMutation(SAVE_MAILCHIMP, {
    onCompleted: () => {
      notification.success({ message: "Save Mailchimp success" });
    },
    onError: (err) => {
      notification.error({ message: handleError(err.toString()) });
    },
  });

  const handleSubmit = React.useCallback(() => {
    const values = form.getFieldsValue(["apiKey", "listId"]);
    if (Object.values(values).every(Boolean)) {
      save({
        variables: {
          ...values,
        },
      });
    }
  }, [form, save]);

  React.useEffect(() => {
    if (data?.getMailchimp) {
      const { apiKey, listId } = data.getMailchimp;
      form.setFieldsValue({ apiKey, listId });
    }
  }, [data, form]);

  React.useImperativeHandle(ref, () => ({ onSubmit: handleSubmit }), []);
  return (
    <React.Fragment>
      {loading ? (
        <Spin />
      ) : error ? (
        <div>Error: {handleError(error?.toString())}</div>
      ) : null}
      <Form form={form} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
        <Form.Item label="API Key" name="apiKey">
          <Input placeholder="Enter api key" />
        </Form.Item>
        <Form.Item label="List ID" name="listId">
          <Input placeholder="Enter List ID" />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

const LIST_SELLER = gql`
  query users($filter: FindFilter) {
    users(filter: $filter) {
      nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
const EmailNotification = React.forwardRef(({}, ref) => {
  const [filter, setFilter] = React.useState({
    limit: 20,
    offset: 0,
    status: true,
    search: "",
  });
  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const changed = React.useRef(false);

  const { data } = useQuery(GET_MANUAL_ORDER_CREATION_NOTICE);
  const {
    data: dataT,
    loading,
    error,
  } = useQuery(LIST_SELLER, {
    variables: {
      filter,
    },
  });

  const [update] = useMutation(UPDATE_MANUAL_ORDER_CREATION_NOTICE, {
    onCompleted: () => {
      notification.success({
        message: "Update user has permission success.",
      });
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  React.useEffect(() => {
    const nodes = data?.getManualOrderCreationNotice || [];
    if (nodes?.length > 0) {
      const ids = nodes.map(({ id }) => id);
      setValue(ids);
    }
  }, [data]);

  React.useEffect(() => {
    const nodes = dataT?.users?.nodes || [];
    const nodesVal = data?.getManualOrderCreationNotice || [];

    const opts = formatUser(nodes);
    const values = formatUser(nodesVal);

    const mapVal = new Map(
      [...values, ...opts].map((item) => [item.value, item]),
    );
    const merged = Array.from(mapVal.values());
    setOptions(merged);
  }, [dataT, data]);

  const onSubmit = React.useCallback(() => {
    if (!changed.current) return;
    const ids = (value || []).filter(Boolean);

    update({ variables: { userIds: ids?.length > 0 ? ids : [] } }).finally(
      () => {
        changed.current = false;
      },
    );
  }, [value]);

  React.useImperativeHandle(ref, () => ({
    onSubmit,
  }));
  const handleChange = React.useCallback((val) => {
    setValue(val);
    changed.current = true;
  }, []);

  const typingTimeout = React.useRef(null);
  const handleSearch = React.useCallback((search) => {
    typingTimeout.current && clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => {
      setFilter((p) => ({ ...p, search }));
    }, 500);
  }, []);

  if (error) return <div>Error: {error.toString()}</div>;
  return (
    <>
      <label style={labelStyle}>Receivers where a manual order created</label>
      <Select
        showSearch
        onSearch={handleSearch}
        options={options}
        onChange={handleChange}
        filterOption={false}
        value={value}
        mode="multiple"
        style={{ width: "100%" }}
        loading={loading}
      />
    </>
  );
});

AdminSettingsPage.contextType = AppContext;
export default AdminSettingsPage;
