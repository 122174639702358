import { CaretRightOutlined } from "@ant-design/icons";
import {
  Card,
  Collapsible,
  Scrollable,
  Spinner,
  Stack,
} from "@shopify/polaris";
import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { useCollapseContext } from "./LayoutSection";

export function CardBox({
  title,
  subTitle,
  headMarkup,
  contentMarkup,
  filter,
  total,
  loading,
  setFilter = () => {},
  handleScroll,
  loadMore,
  isProduct,
  state,
  wrapper: Wrapper = "div",
  collapseKey,
  noCollapse = false,
  footerMarkup = "",
}) {
  // Handle actions
  const onChange = useCallback(
    (offset, limit) => setFilter((prev) => ({ ...prev, offset, limit })),
    [setFilter],
  );

  // Context
  const { collapseActive = {}, setCollapseActive = () => {} } =
    useCollapseContext() || {};
  const open = !!collapseActive[collapseKey];

  const btnRef = useRef(null);

  // Variants
  const { limit, offset } = filter;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    total,
    onChange,
  };

  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);
  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  // Markup
  const headerMarkup = useMemo(
    () => (
      <Stack spacing="loose">
        <Stack.Item fill>
          <HeadingWrap>
            {!noCollapse && (
              <span className="btn-wrap">
                <CaretRightOutlined
                  rotate={open ? 90 : 0}
                  style={{ color: "#000" }}
                  ref={btnRef}
                  onClick={handleToggle}
                />
              </span>
            )}
            <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
              {title}
            </h2>
          </HeadingWrap>
        </Stack.Item>
        <span>{subTitle}</span>
      </Stack>
    ),
    [title, subTitle, open, noCollapse],
  );

  const totalMarkup = isProduct && (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span>
        Total products has sale: <strong>{state.total}</strong>
      </span>
      <span>
        Total system products: <strong>{state.totalSystemProducts}</strong>
      </span>
    </div>
  );
  const paginationMarkup = total != null && contentMarkup && (
    <PaginationWrap>
      <PaginationPolaris
        aggregation={aggregation}
        showTotal
        size="slim"
        widthSmall
        maxPages={3}
        totalMarkup={totalMarkup}
      />
    </PaginationWrap>
  );

  const spinnerMarkup = (
    <Stack distribution="center">
      <Spinner size="small" />
    </Stack>
  );

  const scrollMarkup = handleScroll ? (
    <Scrollable
      shadow
      id={`scrollable-${title}`}
      style={{
        maxHeight: "43rem",
        paddingRight: "1.6rem",
        marginRight: "-1.6rem",
      }}
      onScrolledToBottom={handleScroll}
    >
      <Stack vertical spacing="loose" wrap={false}>
        {headMarkup}
        <Stack vertical spacing="loose" wrap={false}>
          {contentMarkup}
        </Stack>
        {loadMore && spinnerMarkup}
      </Stack>
    </Scrollable>
  ) : (
    <Stack vertical spacing="loose" wrap={false}>
      {headMarkup}
      {contentMarkup}
      {paginationMarkup}
    </Stack>
  );

  const children = (
    <Card>
      <Container>
        <Card.Header title={headerMarkup} />
        <Card.Section>
          <Wrapper>
            <Collapsible
              open={noCollapse ? true : open}
              id={collapseKey}
              transition={{
                duration: "500ms",
                timingFunction: "ease-in-out",
              }}
              expandOnPrint
            >
              {loading ? (
                spinnerMarkup
              ) : contentMarkup ? (
                scrollMarkup
              ) : (
                <EmptyStatePolaris noData slim />
              )}
            </Collapsible>
            {footerMarkup}
          </Wrapper>
        </Card.Section>
      </Container>
    </Card>
  );

  return children;
}

const Container = styled.div`
  .Polaris-Collapsible {
    overflow: scroll;
  }
`;

const PaginationWrap = styled.div`
  margin-top: 1.6rem;
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;
