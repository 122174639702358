import React, { useState, useCallback, useEffect } from "react";
import { Card, TextField, Select } from "@shopify/polaris";
import styled from "styled-components";

import { IgnoreConditionsPolaris } from "./IgnoreConditionsPolaris";

const Container = styled.div`
  .ignore-inner {
    width: 70px;
    margin: 0 10px;
    .Polaris-Select__Content {
      line-height: 2rem;
      min-height: 3rem;
    }
  }
`;

export const IgnorePolaris = ({ onChange }) => {
  const [selected, setSelected] = useState("all");
  const [ignoreSku, setIgnoreSku] = useState(null);
  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    let ignoreSkus = [];
    if (ignoreSku) {
      ignoreSkus = ignoreSku.split("\n");
      if (ignoreSkus.length) {
        ignoreSkus = ignoreSkus.map((is) => is.trim());
      }
    }
    if (onChange) {
      onChange({
        skus: ignoreSkus,
        type: selected,
        conditions: conditions.filter((c) => c.value.trim() !== ""),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreSku, selected, conditions]);

  const handleIgnoreSku = useCallback((value) => {
    setIgnoreSku(value);
  }, []);

  const handleSelectedChange = useCallback((value) => setSelected(value), []);

  const handleConditionsChange = useCallback(
    (value) => setConditions(value),
    []
  );

  return (
    <Container className="ignore">
      <Card sectioned title="Ignore Mapping Orders">
        <TextField
          label="Ignore by list SKUs"
          type="text"
          multiline={5}
          value={ignoreSku}
          onChange={handleIgnoreSku}
          placeholder="Sku1 &#13;&#10;Sku2 &#13;&#10;.."
        />
        <h4 className="mt-4">Ignore by product sku structure</h4>
        <div className="flex items-center mt-4">
          <span>Ignore If</span>
          <div className="ignore-inner">
            <Select
              label="Ignore"
              labelHidden
              options={[
                { label: "All", value: "all" },
                { label: "Any", value: "any" },
              ]}
              value={selected}
              onChange={handleSelectedChange}
            />
          </div>
          <span>conditions match</span>
        </div>
        <IgnoreConditionsPolaris onChange={(v) => handleConditionsChange(v)} />
      </Card>
    </Container>
  );
};
