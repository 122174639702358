import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import _ from "lodash";

import { isEmpty } from "../../helper";
import { TransactionsFilterByStatusPolaris } from "./TransactionsFilterByStatusPolaris";
import { TransactionsFilterByActionPolaris } from "./TransactionsFilterByActionPolaris";

const actionOptions = [
  { value: "All", label: "All" },
  { value: "Order", label: "Paid for item" },
  { value: "Deposit", label: "Deposit" },
  { value: "Refund", label: "Refund" },
];

const statusOptions = [
  { value: "All", label: "All" },
  { value: "Approved", label: "Approved" },
  { value: "Cancelled", label: "Cancelled" },
];

export const TranscactionsFiltersPolaris = ({ filter, onChange }) => {
  const [inputValue, setInputValue] = useState(filter.search);
  const [action, setAction] = useState({
    value: null,
    label: null,
  });
  const [status, setStatus] = useState(filter.status);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: inputValue ? inputValue.trim() : inputValue,
          status,
          action: action.value,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, status, action]);

  useEffect(() => {
    if (filter && filter.kind) {
      const selectedValue = actionOptions.find(
        (item) => item.value === _.head(filter.kind)
      );
      setAction((prevState) => ({
        ...prevState,
        value: _.head(filter.kind),
        label: selectedValue && selectedValue.label,
      }));
    }
  }, [filter]);

  const handleInputChange = useCallback((input) => setInputValue(input), []);
  const handleInputRemove = useCallback(() => setInputValue(null), []);
  const handleStatusRemove = useCallback(() => setStatus(null), []);
  const handleActionRemove = useCallback(
    () => setAction({ value: null, label: null }),
    []
  );

  const handleFiltersClearAll = useCallback(() => {
    handleInputRemove();
    handleStatusRemove();
    handleActionRemove();
  }, [handleInputRemove, handleStatusRemove, handleActionRemove]);

  const filters = [
    {
      key: "actions",
      label: "Actions",
      filter: (
        <TransactionsFilterByActionPolaris
          action={action}
          options={actionOptions}
          onChange={({ value, label }) =>
            setAction((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      filter: (
        <TransactionsFilterByStatusPolaris
          value={status}
          options={statusOptions}
          onChange={(value) => setStatus(value)}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(action.label)) {
    const key = "actions";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, action.label),
      onRemove: handleActionRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Filters
      queryValue={inputValue}
      queryPlaceholder="Filter transactions"
      onQueryChange={handleInputChange}
      onQueryClear={handleInputRemove}
      filters={filters}
      appliedFilters={appliedFilters}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "actions":
        return `Action: ${value}`;
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
