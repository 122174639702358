import React, { Component } from "react";
import { gql } from "apollo-boost";
import styled from "styled-components";
import {
  Query,
  Subscription,
  ApolloConsumer,
} from "@apollo/react-components";
import { handleError, setCookie, getCookie } from "../../helper";
import {
  Card,
  Input,
  notification,
  Select,
  Table,
  Tag,
  Skeleton,
  Tooltip,
} from "antd";
import PageTitle from "../../components/shared/PageTitle";
import ActionRetryCron from "./ActionRetryCron";

export const LOAD_QUERY = gql`
  query crons($filter: CronFilter) {
    crons(filter: $filter) {
      total
      nodes {
        id
        name
        action
        status
        log
      }
    }
  }
`;

const SUBSCRIPTION_QUERY = gql`
  subscription cronAdded {
    cronAdded {
      id
      name
      action
      status
      log
    }
  }
`;

const SUBSCRIPTION_UPDATE_QUERY = gql`
  subscription cronUpdated {
    cronUpdated {
      id
      name
      action
      status
      log
    }
  }
`;

const Container = styled.div`
  .ant-table-default {
    border: 0 none;
    border-top: 1px solid rgb(223, 229, 237);
  }
`;
class CronJobs extends Component {
  state = {
    filter: {
      search: "",
      limit: +getCookie("perPageCronJobs") || 20,
      offset: 0,
      status: "Pending",
    },
    currentPage: 1,
  };

  handleSetStatusColor = (status) => {
    switch (status) {
      case "Error":
        return "#f50";
      case "Running":
        return "green";
      case "Pending":
        return "yellow";

      default:
        return undefined;
    }
  };

  calculateOffset = (page, dataSource = []) => {
    const { filter } = this.state;

    const offset = (page - 1) * filter.limit;
    if (offset > dataSource.length) {
      return dataSource.length;
    }
    return offset;
  };

  onNewCron = (client, cron) => {
    const { filter } = this.state;
    const cache = client.readQuery({
      query: LOAD_QUERY,
      variables: {
        filter,
      },
    });
    client.writeQuery({
      query: LOAD_QUERY,
      variables: { filter },
      data: {
        ...cache,
        crons: {
          ...cache.crons,
          total: cache.crons.total,
          nodes: [...cache.crons.nodes, cron],
        },
      },
    });
    notification.success({ message: `New cron job: ${cron.name}` });
  };

  onCronUpdated = (client, cron) => {
    client.writeFragment({
      id: cron.id,
      fragment: gql`
        fragment cron on Cron {
          status
          log
        }
      `,
      data: {
        status: cron.status,
        log: cron.log,
      },
    });
  };
  render() {
    const { filter } = this.state;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Actions",
        dataIndex: "action",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (_, record) =>
          record.status !== "Error" ? (
            <Tag color={this.handleSetStatusColor(record.status)}>
              {record.status}
            </Tag>
          ) : (
            <Tooltip title={record.log}>
              <Tag
                className="cursor-pointer"
                color={this.handleSetStatusColor(record.status)}
              >
                {record.status}
              </Tag>
            </Tooltip>
          ),
      },
      {
        title: "",
        render: (_, action) => {
          return (
            action.status === "Error" && (
              <ActionRetryCron id={action.id} filter={this.state.filter} />
            )
          );
        },
      },
    ];
    return (
      <Container>
        <PageTitle title={"Cron Jobs"} />
        <Card bodyStyle={{ padding: 0 }}>
          <div className={"px-4 py-4"}>
            <div className={"search-container"}>
              <Input.Search
                addonBefore={
                  <Select
                    onChange={(value) =>
                      this.setState({ filter: { ...filter, status: value } })
                    }
                    value={filter.status}
                    style={{ minWidth: 150 }}
                  >
                    <Select.Option value={null}>All</Select.Option>
                    <Select.Option value={"Pending"}>Pending</Select.Option>
                    <Select.Option value={"Running"}>Running</Select.Option>
                    <Select.Option value={"Done"}>Done</Select.Option>
                    <Select.Option value={"Error"}>Error</Select.Option>
                  </Select>
                }
                onChange={(e) => {
                  if (!e.target.value) {
                    this.setState({
                      filter: {
                        ...this.state.filter,
                        search: null,
                      },
                    });
                  }
                }}
                onSearch={(v) =>
                  this.setState({ filter: { ...filter, search: v } })
                }
              />
            </div>
          </div>
          <Query query={LOAD_QUERY} variables={{ filter }}>
            {({ error, data, loading }) => {
              if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );
              if (error)
                return <div>Error: {handleError(error.toString())}</div>;
              const dataSource = data ? data.crons.nodes : [];
              const total = data ? data.crons.total : 0;
              return (
                <Table
                  bordered={false}
                  rowKey={(_, index) => index}
                  pagination={{
                    total,
                    showTotal: (total, range) => (
                      <span>
                        Showing: {`${range[0]} - ${range[1]}`} of {total}
                      </span>
                    ),
                    current: this.state.currentPage,
                    pageSize: filter.limit,
                    onChange: (page, pageSize) => {
                      this.setState({
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                        currentPage: page,
                      });
                    },
                    onShowSizeChange: (_, size) => {
                      this.setState({
                        currentPage: 1,
                        filter: {
                          ...filter,
                          limit: size,
                          offset: 0,
                        },
                      });
                      setCookie("perPageCronJobs", size, 100);
                    },
                  }}
                  columns={columns}
                  dataSource={dataSource}
                />
              );
            }}
          </Query>
          <ApolloConsumer>
            {(client) => (
              <React.Fragment>
                <Subscription
                  subscription={SUBSCRIPTION_QUERY}
                  onSubscriptionData={(res) =>
                    this.onNewCron(client, res.subscriptionData.data.cronAdded)
                  }
                />
                <Subscription
                  subscription={SUBSCRIPTION_UPDATE_QUERY}
                  onSubscriptionData={(res) =>
                    this.onCronUpdated(
                      client,
                      res.subscriptionData.data.cronUpdated
                    )
                  }
                />
              </React.Fragment>
            )}
          </ApolloConsumer>
        </Card>
      </Container>
    );
  }
}

export default CronJobs;
