import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Toast, TextContainer } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { handleError } from "../../../helper";

const UPDATE_FILES = gql`
  mutation updateEvidences($id: ID!, $files: [ID!]) {
    updateEvidences(id: $id, files: $files) {
      id
      order {
        id
      }
      orderId
      approveBy {
        id
        firstName
        lastName
      }
      approveById
      authorId
      author {
        id
        firstName
        lastName
      }
      type
      comment
      status
      files {
        id
        url
        thumbnailUrl
      }
    }
  }
`;

export const UpdateEvidencesPolaris = ({ evidence, refetch }) => {
  const [openModal, setOpenModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [timeoutId, setTimeoudId] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  const [updateEvidences, { loading }] = useMutation(UPDATE_FILES, {
    onCompleted: () => {
      setNotification({
        message: "Update evidences successfuly.",
        isError: false,
      });
      const id = setTimeout(() => {
        toggleActive();
        if (refetch) {
          refetch();
        }
      }, 2100);
      setTimeoudId(id);
    },
    onError: (error) => {
      setNotification({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(() => setOpenModal((open) => !open), []);

  const toggleActiveToast = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleUpload = useCallback(() => {
    setNotification({
      message: null,
      isError: false,
    });
    if (files) {
      const image =
        files && files.length > 0 ? files.map((file) => file.id) : null;
      updateEvidences({
        variables: {
          id: evidence.id,
          files: image,
        },
      });
    }
    toggleActiveToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  // Show notification
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActiveToast}
        />
      )
    : null;

  return (
    <>
      {toastMarkup}
      <Button primary onClick={toggleActive} size="slim">
        Upload Evidence
      </Button>
      <Modal
        open={openModal}
        title={`Upload Evidence for Order #${evidence && evidence.orderId}`}
        onClose={toggleActive}
        primaryAction={{
          content: "Upload",
          onAction: handleUpload,
          loading: loading,
        }}
        sectioned
      >
        <TextContainer>
          <MediaSelectorButtonPolaris
            accept={"image/*"}
            value={evidence?.files}
            multiple={true}
            sizeSmall
            onChange={(files) => {
              setFiles(files);
            }}
          />
        </TextContainer>
      </Modal>
    </>
  );
};
