import {
  ActionList,
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Collapsible,
  Icon,
  Popover,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import {
  CalendarMajorMonotone,
  CustomersMajorFilled,
  MobileVerticalDotsMajorMonotone,
  ToolsMajorMonotone,
} from "@shopify/polaris-icons";
import { get } from "lodash";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import imgPlaceholder from "../../../../assets/images/unnamed.jpeg";
import { useAppContext } from "../../../../context";
import { arrInvalid, checkRole, getUnique } from "../../../../helper";
import useToggle from "../../../../hooks/useToggle";
import { ImageTypes, TASK_STATUS_COLORS_POLARIS } from "../../../../variable";
import { PaginationPolaris } from "../../../shared/PaginationPolaris";
import { FileGenerating, MockupGenerating } from "../../TabsDesignTasksPolaris";
import { checkStatusTask, TASK_STATUS } from "../utils";
import { AssignToDesigner, AssignToTeam } from "./actions";
import { Delete } from "./actions/Delete";
import { Publish } from "./actions/Publish";
import { Update } from "./Update";

export function Table({
  data,
  filter,
  setFilter,
  isDesignLeader,
  isRoleIdeaLeader,
  isWriter,
  dataTotal,
}) {
  let { limit, offset } = filter;
  const total = get(data, "tasksV2.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  const totalKpi = dataTotal?.totalKpi;
  const totalKpiAssignTask = dataTotal?.totalKpiAssignTask;
  let hasTotalKpi = {};
  if (totalKpi && [TASK_STATUS.Done].includes(filter.status)) {
    hasTotalKpi = {
      ...hasTotalKpi,
      totalKpi,
    };
  }
  if (totalKpiAssignTask) {
    hasTotalKpi = {
      ...hasTotalKpi,
      totalKpiAssignTask,
    };
  }

  // State
  const [idsTake, setIdsTake] = useState([]);
  const [open, toggleOpen] = useToggle(false);
  const [openAssignTeam, toggleOpenAssignTeam] = useToggle(false);
  const [height, setHeight] = useState(200);

  const handleResetIdsTake = useCallback(() => {
    setIdsTake(() => []);
  }, [setIdsTake]);

  const itemSelected = React.useMemo(() => {
    return getSelectItem(idsTake, data.tasksV2.nodes);
  }, [idsTake, data.tasksV2.nodes]);

  return (
    <Wrapper>
      <Collapsible
        open={idsTake.length > 0}
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <ButtonGroup>
          <Button
            children="Assign to Team"
            onClick={toggleOpenAssignTeam}
            primary
            size="slim"
          />
          <Button
            children="Assign to Designer"
            onClick={toggleOpen}
            size="slim"
            primary
            disabled={itemSelected ? itemSelected.diff : false}
          />
        </ButtonGroup>
      </Collapsible>
      <div className="media-items-wrap">
        {data.tasksV2.nodes.map((item, index) => (
          <IdeaItem
            item={item}
            key={`item-${index}`}
            isRoleIdeaLeader={isRoleIdeaLeader}
            isWriter={isWriter}
            idsTake={idsTake}
            setIdsTake={setIdsTake}
            setHeight={setHeight}
            index={index}
            height={height}
          />
        ))}
      </div>
      <PaginationContainer>
        <PaginationPolaris
          aggregation={aggregation}
          showTotal
          gotoPage={isDesignLeader}
          {...hasTotalKpi}
        />
      </PaginationContainer>
      <AssignToDesigner
        ids={idsTake}
        open={open}
        onClose={toggleOpen}
        onCloseParent={handleResetIdsTake}
        item={itemSelected}
      />
      <AssignToTeam
        ids={idsTake}
        open={openAssignTeam}
        onClose={toggleOpenAssignTeam}
        onCloseParent={handleResetIdsTake}
      />
    </Wrapper>
  );
}

function IdeaItem({
  item,
  isRoleIdeaLeader,
  isWriter,
  setIdsTake,
  idsTake,
  setHeight,
  index,
  height,
}) {
  // Props
  const {
    relatedProducts,
    status,
    ideaTitle: title,
    medias,
    createdAt,
    createBy: author,
    assignee: designer,
    collections,
    hasTemplateGenMockup,
    priority,
    taskBaseGroups,
  } = item || {};

  const newImg = getMockupFiles(taskBaseGroups);
  let images = medias || [];
  if (newImg?.length > 0) {
    images = newImg;
  }

  if (!images || images.length === 0) {
    // If length of images = 0, get related products.
    images = (relatedProducts || [])
      .map((i) => {
        if (i.images?.length > 0) {
          const newImages = i.images.map((i) => i.file).filter(Boolean);
          return newImages;
        }
        return null;
      })
      .filter(Boolean)
      .reduce((acc, cur) => (cur?.length > 0 ? [...acc, ...cur] : acc), []);
  }

  const statusVal = TASK_STATUS_COLORS_POLARIS.find((o) => o.name === status);
  images = images.filter((i) => ImageTypes.includes(i.mimeType));
  const file = images[0];
  const url = file?.url
    ? file.url
    : file?.thumbnailUrl
    ? file.thumbnailUrl
    : imgPlaceholder;

  // const url = file?.thumbnailUrl
  //     ? file.thumbnailUrl
  //     : file?.url
  //     ? file.url
  //     : imgPlaceholder;

  const authorFullName = genFullName(author);
  const designerFullName = genFullName(designer);

  const noMockupTemplate =
    hasTemplateGenMockup === false ? (
      <Badge children="No Template" status="attention" />
    ) : null;

  // State
  const [open, toggleOpen] = useToggle(false);
  const [isClone, setIsClone] = useState(false);
  const itemRef = useRef(null);
  const [openPublish, toggleOpenPublish] = useToggle(false);
  const [openDelete, toggleOpenDelete] = useToggle(false);

  // Actions
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      toggleOpen();
      setIsClone(false);
    },
    [toggleOpen],
  );

  const handleClickClone = useCallback(() => {
    toggleOpen();
    setIsClone(true);
  }, [toggleOpen]);

  useEffect(() => {
    if (!itemRef.current && index) return;

    const rect = itemRef.current.getBoundingClientRect();
    if (rect?.width) {
      setHeight(Number(rect.width));
    }
  }, [index, setHeight]);

  // Handle resize event
  const resizeRef = useRef(null);
  useEffect(() => {
    if (!itemRef.current && index) return;

    const handler = () => {
      const rect = itemRef.current.getBoundingClientRect();
      resizeRef.current && clearTimeout(resizeRef.current);
      resizeRef.current = setTimeout(() => {
        if (rect?.width) {
          setHeight(Number(rect.width));
        }
      }, 100);
    };

    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler, false);
    };
  }, [index, setHeight]);

  // Markup
  let createdAtMarkup = moment(createdAt).format("LL");
  const collectionsName = (collections || [])
    .filter(Boolean)
    .map((c) => c.name);
  const taxonomyMarkup = collectionsName.map((c, index) => (
    <Badge key={`col-${index}`}>{c}</Badge>
  ));

  const { isDraft, isPending, isDeclined, isRejected } =
    checkStatusTask(status);

  const statusMarkup = statusVal
    ? statusVal.name === FileGenerating
      ? MockupGenerating
      : statusVal.name
    : null;

  const showDelete =
    (isWriter && (isDraft || isPending || isRejected)) ||
    (isRoleIdeaLeader && isDeclined);
  const showPublish = isRoleIdeaLeader && (isDraft || isDeclined);

  const designTeamName = item?.designTeam?.name;

  return (
    <Fragment>
      <div className="item-wrap" onClick={handleClick}>
        <div ref={itemRef} className="item-inner">
          <div className="idea-header-wrap">
            <TakeToAssign
              item={item}
              idsTake={idsTake}
              setIdsTake={setIdsTake}
            />
            {!!priority && (
              <div className="priority-wrap">
                <Badge status="critical" children="Prioritized" />
              </div>
            )}
            <div className="status-wrap">
              {statusVal != null && (
                <Badge children={statusMarkup} {...statusVal} />
              )}
            </div>
            <div
              className="image-wrap img-box-shadow"
              style={height ? { height } : {}}
            >
              <img src={url} alt={""} onDragStart={(e) => e.preventDefault()} />
            </div>
          </div>
          <div className="idea-content-wrap">
            <div className="created-at-wrap have-icon">
              <Icon source={CalendarMajorMonotone} />
              <span className="content">
                <TextStyle variation="subdued">{createdAtMarkup}</TextStyle>
              </span>
              <GroupKPI value={item} />
            </div>
            <div className="title-wrap">
              <span
                className="Polaris-TextStyle--variationStrong"
                title={title}
              >
                {title}
              </span>
            </div>
            {noMockupTemplate && (
              <div className="no-mockup-template">{noMockupTemplate}</div>
            )}
            <div className="taxonomy-wrap">{taxonomyMarkup}</div>
            {designTeamName && (
              <div className="design-team">
                <b>Design Team: </b>
                <span>{designTeamName}</span>
              </div>
            )}
          </div>
          {/* <Stack spacing="tight">
                        {cloneIdea}
                        {isRoleIdeaLeader && isDraft && (
                            <PublishButton item={item} />
                        )}
                        {showDelete && <DeleteButton item={item} />}
                    </Stack> */}
          <div className="idea-footer-wrap">
            <div className="idea-footer-left">
              <div className="assignee-wrap have-icon">
                <Icon source={ToolsMajorMonotone} />
                <span className="content author-item">
                  <span className="author-name" title={authorFullName}>
                    {authorFullName}
                  </span>
                </span>
              </div>
              {designerFullName && (
                <div className="assignee-wrap have-icon">
                  <Icon source={CustomersMajorFilled} />
                  <span className="content author-item">
                    <span className="author-name" title={designerFullName}>
                      {designerFullName}
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="actions-wrap">
              <MoreAction
                item={item}
                showDelete={showDelete}
                showPublish={showPublish}
                showClone={isWriter}
                handleClickClone={handleClickClone}
                handleClickPublish={toggleOpenPublish}
                handleClickDelete={toggleOpenDelete}
              />
            </div>
          </div>
        </div>
      </div>
      <Update item={item} open={open} onClose={toggleOpen} isClone={isClone} />
      <Publish open={openPublish} onClose={toggleOpenPublish} item={item} />
      <Delete open={openDelete} onClose={toggleOpenDelete} item={item} />
    </Fragment>
  );
}

function TakeToAssign({ item, idsTake, setIdsTake }) {
  const id = item?.id;
  const { isPending } = checkStatusTask(item?.status);
  const unassign = item?.assignee == null;
  const checked = idsTake.includes(id);
  const { currentUser } = useAppContext();
  const { isDesignLeader, isIdeaLeader, isSeller, isStoreManager } =
    checkRole(currentUser);

  // Actions
  const handleCheck = useCallback(() => {
    setIdsTake((prev) => {
      const index = (prev || []).findIndex((i) => i === id);
      if (index > -1) {
        prev.splice(index, 1);
      } else {
        prev.push(id);
      }

      return [...prev];
    });
  }, [id, setIdsTake]);

  const isTeamLeader =
    isDesignLeader || isIdeaLeader || isSeller || isStoreManager;

  return isTeamLeader && unassign && isPending ? (
    <div onClick={(evt) => evt.stopPropagation()} className="take-assign">
      <Tooltip content="Take to assign" preferredPosition="above">
        <Checkbox checked={checked} onChange={handleCheck} />
      </Tooltip>
    </div>
  ) : null;
}

function MoreAction({
  handleClickClone,
  handleClickPublish,
  handleClickDelete,
  showDelete,
  showPublish,
  showClone,
}) {
  // State
  const [open, toggleOpen] = useToggle(false);

  // Actions
  const onAction = useCallback(
    (evt) => {
      evt.stopPropagation();
      toggleOpen();
    },
    [toggleOpen],
  );

  const activator = (
    <Button
      // children="More actions"
      icon={MobileVerticalDotsMajorMonotone}
      plain
      size="slim"
      onClick={onAction}
    />
  );

  const items = [
    showClone && {
      content: "Clone Idea",
      onAction: handleClickClone,
    },
    showPublish && {
      content: "Publish Idea",
      onAction: handleClickPublish,
    },
    showDelete && {
      content: "Delete Idea",
      onAction: handleClickDelete,
    },
  ].filter(Boolean);

  return (
    items.length > 0 && (
      <div onClick={(evt) => evt.stopPropagation()}>
        <Popover
          active={open}
          onClose={toggleOpen}
          activator={activator}
          fluidContent
          preferredPosition="right"
        >
          <ActionList actionRole="menuitem" items={items} />
        </Popover>
      </div>
    )
  );
}

function genFullName(user) {
  if (user == null || typeof user !== "object") return null;
  const { firstName, lastName } = user;

  return [firstName, lastName].filter(Boolean).join(" ");
}

function GroupKPI({ value }) {
  // Context
  const { currentUser } = useAppContext();
  const { isIdea, isDesigner } = checkRole(currentUser);

  if (!value) return null;
  const { ideaKpi, kpi, defaultKpi, assignee } = value;
  const designKpi = kpi ? kpi : defaultKpi;

  return (
    <GroupKPIWrapper>
      {!isDesigner && (
        <KPITooltip kpi={ideaKpi} msg="Idea KPI" className="idea-kpi-tooltip" />
      )}
      {assignee != null && !isIdea ? (
        <KPITooltip
          kpi={designKpi}
          msg="Design KPI"
          className="design-kpi-tooltip"
        />
      ) : null}
    </GroupKPIWrapper>
  );
}

function KPITooltip({ kpi, msg, className }) {
  return (
    kpi && (
      <Tooltip content={msg} preferredPosition="above" light>
        <span className={className}>
          <Avatar initials={`${kpi}`} size="small" />
        </span>
      </Tooltip>
    )
  );
}

function getMockupFiles(taskBaseGroups) {
  if (!(taskBaseGroups instanceof Array) || taskBaseGroups.length === 0)
    return [];

  let mockups = taskBaseGroups.reduce((acc, cur) => {
    const { baseConfigs } = cur || {};

    let arr = [];
    if (baseConfigs?.length > 0) {
      arr = baseConfigs.reduce((prev, cf) => {
        const { taskBaseSubmissions } = cf || {};

        const [submission] = taskBaseSubmissions || [];
        const { customMockups, taskBaseMockups } = submission || {};
        const baseMockup =
          taskBaseMockups?.length > 0
            ? taskBaseMockups.reduce((prevM, m) => {
                const { outputMockup } = m || {};
                return outputMockup != null ? [...prevM, outputMockup] : prevM;
              }, [])
            : [];

        let result = [...(customMockups || []), ...baseMockup];

        return result?.length > 0 ? [...prev, ...result] : prev;
      }, []);
    }

    return arr?.length > 0 ? [...acc, ...arr] : acc;
  }, []);

  mockups = getUnique(mockups, "id");
  mockups.filter((i) => ImageTypes.includes(i.mimeType));
  return mockups;
}

function getSelectItem(idsTake, nodes) {
  if (arrInvalid(idsTake) || arrInvalid(nodes)) return null;
  const [firstId] = idsTake;
  const firstItem = nodes.find(
    (item) => firstId === item.id && item.designTeamID,
  );

  const itemSelected = nodes.filter((item) => idsTake.includes(item.id));

  const diff = itemSelected.some(
    // (item) => item.designTeamID !== firstItem?.designTeamID,
    (item) => {
      if (!firstItem) return false;
      return item.designTeamID !== firstItem.designTeamID;
    },
  );

  return {
    designTeamID: firstItem?.designTeamID,
    diff,
  };
}

const GroupKPIWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-right: -1rem;

  .Polaris-Avatar {
    color: var(--p-decorative-one-text, white);
    background: var(--p-decorative-one-surface, #47c1bf);
  }

  .idea-kpi-tooltip {
    .Polaris-Avatar {
      color: var(--p-decorative-one-text, white);
      background: var(--p-decorative-two-surface, #cfd2d6);
    }
  }
  .design-kpi-tooltip {
    .Polaris-Avatar {
      color: var(--p-decorative-one-text, white);
      background: var(--p-decorative-two-surface, #92d7d6);
    }
  }

  .Polaris-Avatar {
    min-width: 3rem;
    width: 3rem;

    text {
      font-size: 18px;
    }
  }
`;

// function PublishButton({ item }) {
//     // State
//     const [open, toggleOpen] = useToggle(false);

//     return (
//         <div onClick={(evt) => evt.stopPropagation()}>
//             <Button children="Publish Idea" onClick={toggleOpen} size="slim" />
//             <Publish open={open} onClose={toggleOpen} item={item} />
//         </div>
//     );
// }

// function DeleteButton({ item }) {
//     // State
//     const [open, toggleOpen] = useToggle(false);

//     return (
//         <div onClick={(evt) => evt.stopPropagation()}>
//             <Button children="Delete" onClick={toggleOpen} size="slim" />
//             <Delete open={open} onClose={toggleOpen} item={item} />
//         </div>
//     );
// }

const Wrapper = styled.div`
  --textColor: #637381;

  .item-wrap {
    padding: 0;
    padding-bottom: 5.6rem;
    position: relative;

    .take-assign {
      left: -0.1rem;
      right: auto;
      top: -0.7rem;
      position: absolute;
    }

    .priority-wrap {
      position: absolute;
      right: 0;
      top: 2.5rem;
      opacity: 0.8;
    }
  }

  .Polaris-Collapsible {
    margin-bottom: 2rem;
  }

  .item-inner {
    display: flex;
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;

    .author-name,
    .Polaris-TextStyle--variationSubdued {
      font-size: 1.3rem;
      color: var(--textColor);
    }

    .created-at-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .idea-header-wrap {
      position: relative;

      .status-wrap {
        position: absolute;
        right: 0;
        top: 0rem;
        opacity: 0.8;
      }

      .image-wrap {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .no-mockup-template {
        position: absolute;
        left: 0;
        top: -1rem;
      }
    }

    .idea-content-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-inline: 1.6rem;

      .title-wrap {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .taxonomy-wrap {
        margin-top: -0.75rem;
        margin-left: -0.75rem;

        .Polaris-Badge {
          margin-top: 0.75rem;
          margin-left: 0.75rem;
        }
      }
    }

    .have-icon {
      display: flex;

      .Polaris-Icon {
        height: 1.3rem;
        width: 1.3rem;

        svg {
          fill: #637381;
        }
      }

      .content {
        display: inline-block;
        flex: 1;
        margin-left: 0.5rem;
      }
    }

    .idea-footer-wrap {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      border-top: 0.1rem solid #e4e7eb;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 1rem;
      align-items: center;
      justify-content: space-between;
      padding-right: 1.3rem;

      .idea-footer-left {
        display: flex;
        flex-direction: row;
        flex: 1 1;
        column-gap: 0.5rem;
        width: 80%;
      }

      .actions-wrap {
        transform: translateY(5px);

        .Polaris-Button {
          padding: 0;
          min-height: 2rem;
          min-width: 2rem;
        }
      }

      .assignee-wrap {
        flex-wrap: nowrap;
        overflow: hidden;
        // max-width: calc(50% - 1rem);
        flex: 1 1 50%;

        .author-item {
          max-width: calc(100% - 2rem);

          .author-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }
      }
    }
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
`;
