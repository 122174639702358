import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import Price from "../Price";
import { Radio } from "antd";
import styled from "styled-components";

const Container = styled.div`
  .carrier-price-radio {
    > label {
      display: flex;
      align-items: center;
    }

    width: 100%;
    span.ant-radio + * {
      width: 100%;
    }

    ${({ usingAdditionalPrice }) =>
      usingAdditionalPrice &&
      `
      .prices-wrap {
        display: flex;
        flex-direction: row;
        column-gap: 10px;

        .price-inner {
          display: flex;
          flex-direction: column;
        }
      }

      .ant-legacy-form-item {
        align-items: center;
      }
    `}
  }
`;
class ProductBaseVariantCarrierPricing extends Component {
  render() {
    let { carriers, value } = this.props;
    let mapCarrierById = {};
    if (carriers && carriers.length) {
      for (let i = 0; i < carriers.length; i++) {
        const id = carriers[i].id;
        mapCarrierById[id] = carriers[i];
      }
    }

    const col = {
      label: 14,
      wrap: 10
    }

    const usingAdditionalPrice = (value || []).some(
      (i) => i.usingAdditionalPrice
    );
    if (usingAdditionalPrice) {
      col.label = 10;
      col.wrap =  14;
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: col.label },
        md: { span: col.label },
        lg: { span: col.label },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: col.wrap },
        md: { span: col.wrap },
        lg: { span: col.wrap },
      },
    };

    let defaultIndex = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i].default) {
        defaultIndex = i;
      }
    }
    return (
      <div>
        {value &&
          value.map((item, index) => {
            const carrier = mapCarrierById[item.carrierId];
            if (!carrier) return null;

            const { additionalPrice } = item;
            const firstItem = index === 0;
            return (
              <Container
                key={index}
                usingAdditionalPrice={usingAdditionalPrice}
                first={firstItem}
              >
                <Radio.Group
                  onChange={(v) => {
                    /*for (let i = 0; i < value.length; i++) {
                      value[i].default = false;
                    }
                    value[v.target.value].default = true;
                    if (this.props.onChange) {
                      this.props.onChange(value);
                    }*/
                    const newValue = value.map((item, i) => {
                      if (v.target.value === i) {
                        return { ...item, default: true };
                      }
                      return { ...item, default: false };
                    });
                    this.props.onChange(newValue);
                  }}
                  className={"carrier-price-radio"}
                  value={defaultIndex}
                >
                  <Radio value={index}>
                    <Form.Item {...formItemLayout} label={carrier.name}>
                      <div className="prices-wrap">
                        <div className="price-inner">
                          {usingAdditionalPrice && firstItem && (
                            <span>Original Price</span>
                          )}
                          <Price
                            onChange={(newPrice) => {
                              value[index].price = newPrice;
                              if (this.props.onChange) {
                                this.props.onChange(value);
                              }
                            }}
                            value={item.price ? item.price : 0}
                          />
                        </div>

                        {/* Additional shipping */}
                        {usingAdditionalPrice && (
                          <div className="price-inner">
                            {firstItem && <span>Additional Price</span>}
                            <Price
                              onChange={(newPrice) => {
                                value[index].additionalPrice = newPrice;
                                if (this.props.onChange) {
                                  this.props.onChange(value);
                                }
                              }}
                              value={additionalPrice ? additionalPrice : 0}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </Radio>
                </Radio.Group>
              </Container>
            );
          })}
      </div>
    );
  }
}

ProductBaseVariantCarrierPricing.propTypes = {
  value: PropTypes.array,
  carriers: PropTypes.array,
  onChange: PropTypes.func,
};

export default ProductBaseVariantCarrierPricing;
