import React, { Component } from "react";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, notification, Form, Button } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { handleError } from "../../helper";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($input: NewPassword!) {
    changePassword(input: $input)
  }
`;

class ChangeUserPasswordLink extends Component {
  state = {
    loading: false,
    showModal: false,
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { showModal } = this.state;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <React.Fragment>
        <a
          href="#changePassword"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showModal: true });
          }}
        >
          Change Password
        </a>

        <Mutation
          onError={(e) => {
            notification.error({ message: handleError(e.toString()) });
            this.setState({ loading: false });
          }}
          onCompleted={() => {
            notification.success({ message: "Password has been changed!" });
            this.setState({ showModal: false });
          }}
          mutation={CHANGE_PASSWORD_MUTATION}
        >
          {(changePassword) => (
            <Modal
              footer={null}
              onCancel={() => this.setState({ showModal: false })}
              visible={showModal}
              title={"Change Password"}
            >
              <Form
                {...layout}
                onFinish={(e) => {
                  if (e) {
                    this.setState({ loading: true }, () => {
                      changePassword({
                        variables: {
                          input: {
                            currentPassword: e.currentPassword,
                            password: e.password,
                          },
                        },
                      });
                    });
                  }
                }}
              >
                <Form.Item
                  label={"Current password"}
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Current password is required!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label={"New Password"}
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please choose a password.",
                    },
                  ]}
                >
                  <Input.Password placeholder="***************" />
                </Form.Item>
                <Form.Item
                  label={"Confirm password"}
                  name="confirm"
                  hasFeedback
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="***************"
                    onBlur={this.handleConfirmBlur}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    className="mr-2"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

ChangeUserPasswordLink.propTypes = {};

export default ChangeUserPasswordLink;
