import React, { useState, useCallback, useContext } from "react";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
// import _ from "lodash";

import history from "../../../history";
import { getParamByRole, handleError } from "../../../helper";
import { AppContext } from "../../../context";

import { LIST_TEMPLATE } from "./ProductTemplatesPolaris";
import { Update } from "./Update";

const DELETE_TEMPLATE = gql`
    mutation deleteTemplate($id: ID!) {
        deleteTemplate(id: $id)
    }
`;

const Container = styled.div`
    button + button {
        margin-left: 1.25rem;
    }
`;

export const ColActionsPolaris = ({
    template,
    onSelect,
    idx,
    handleUseTemplate,
    filter,
    allTemplate,
    selectedTab,
}) => {
    const [open, setOpen] = useState(false);
    const [activeToast, setActiveToast] = useState(false);
    const [activeBtn, setActiveBtn] = useState(false);

    const { currentUser } = useContext(AppContext);
    const currentParam = getParamByRole(currentUser);

    const [deleteTemplate, { data, error, loading, client }] = useMutation(
        DELETE_TEMPLATE,
        {
            onCompleted: () => {
                handleModalChange();
                toggleActive();
            },
            onError: () => {
                handleModalChange();
                toggleActive();
            },
        }
    );

    const handleModalChange = useCallback(
        () => setOpen((o) => !o),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [open]
    );
    const toggleActive = useCallback(() => {
        setActiveToast((a) => !a);
    }, []);

    const handleClickChange = useCallback(() => {
        onSelect(idx);
        setActiveBtn((a) => !a);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toastMarkup = activeToast
        ? (error || (data && data.deleteTemplate)) && (
              <Toast
                  content={
                      error
                          ? handleError(error.toString())
                          : data &&
                            data.deleteTemplate &&
                            "Template has been deleted!"
                  }
                  error={error}
                  duration={2000}
                  onDismiss={toggleActive}
              />
          )
        : null;

    return (
        <Container>
            <Update value={template} />
            <Button plain onClick={handleClickChange}>
                {activeBtn ? "Hide Details" : "Show Details"}
            </Button>
            <Button
                plain
                onClick={() => {
                    if (allTemplate) {
                        if (
                            1 === selectedTab ||
                            (0 === selectedTab && template.isProductTemplate)
                        ) {
                            history.push(
                                `/${currentParam}/products/add/${template.id}`
                            );
                        }
                        if (
                            2 === selectedTab ||
                            (0 === selectedTab && !template.isProductTemplate)
                        ) {
                            history.push(
                                `/${currentParam}/products/add-campaign/${template.id}`
                            );
                        }
                    } else {
                        handleUseTemplate(template);
                    }
                }}
            >
                Use Template
            </Button>
            {allTemplate && (
                <Button plain onClick={handleModalChange}>
                    Remove
                </Button>
            )}
            <Modal
                title="Remove template"
                open={open}
                onClose={handleModalChange}
                primaryAction={{
                    content: "Remove",
                    loading: loading,
                    onAction: () => {
                        deleteTemplate({
                            variables: { id: template.id },
                        })
                            .then((res) => {
                                if (res) {
                                    try {
                                        let variables = {
                                            filter: filter,
                                        };
                                        const cache = client.readQuery({
                                            query: LIST_TEMPLATE,
                                            variables,
                                        });
                                        client.writeQuery({
                                            query: LIST_TEMPLATE,
                                            variables,
                                            data: {
                                                ...cache,
                                                templates: {
                                                    ...cache.templates,
                                                    total:
                                                        cache.templates.total -
                                                        1,
                                                    nodes: cache.templates.nodes.filter(
                                                        (t) =>
                                                            t.id !== template.id
                                                    ),
                                                },
                                            },
                                        });
                                    } catch (_) {}
                                }
                            })
                            .catch(() => {});
                    },
                }}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>Are you sure to delete this template?</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
            {toastMarkup}
        </Container>
    );
};
