import { LoadingOutlined } from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import * as React from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { TOTAL_MAPPING_ORDER } from "../../graphql/queries";
import { MAPPING_EVENTS } from "../../graphql/subscription";
import { checkRole, getTeamID, needGetTotalMapping } from "../../helper";

export default function TotalOrderMappingPolaris() {
  const { currentUser, store } = useAppContext();
  const { isMarketplaceManager, isStoreManager } = checkRole(currentUser);
  const teamID = getTeamID(currentUser);
  // const [valid, setValid] = React.useState();

  let filter = { storeId: store ? store.id : isStoreManager ? "all" : null };
  if (isMarketplaceManager && store && store.id === "all") {
    filter = {};
  }

  const { data: dataSub } = useSubscription(MAPPING_EVENTS);

  const totalRef = React.useRef(false);
  const { data, loading, refetch } = useQuery(TOTAL_MAPPING_ORDER, {
    fetchPolicy: "no-cache",
    variables: filter,
  });

  React.useEffect(() => {
    if (!totalRef.current) return;

    const val = needGetTotalMapping(dataSub, teamID);
    // setValid(val);
    if (val) {
      refetch();
    }
  }, [dataSub, teamID]);

  const total = React.useMemo(() => {
    let val = 0;
    if (data?.totalMappingOrder != null) {
      totalRef.current = true;
      val = Number(data.totalMappingOrder);
    }

    return val;
  }, [data]);

  return loading ? <LoadingOutlined /> : <Container>{total}</Container>;
}

const Container = styled.span`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin: -3px 0 -1px 2px;
  padding: 1px 5px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ce3400;
  color: #fff;
  font-size: 11px;
  line-height: 1.6;
  text-align: center;
  z-index: 26;
`;
