import { Button, Stack, TextStyle } from "@shopify/polaris";
import { gql } from "apollo-boost";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { CardBox } from "../../reports/components/CardBox";
import { useReportsContext } from "../../reports/context";
import { useTeamStatistics } from "../hooks";
import { ImportMinor } from "@shopify/polaris-icons";

const styleSpan = {
  display: "inline-block",
  width: "9.5rem",
};
const styleName = {
  display: "inline-block",
  width: "12rem",
};

const styleEmail = {
  display: "inline-block",
  width: "20rem",
  wordBreak: "break-all",
};
export function TeamStatistics({ filter, saleChannel, inWrapper = true }) {
  // Context
  const { range, teamID } = useReportsContext() || {};

  const [get, { data, loading, error, fetchMore }] = useTeamStatistics();
  // State
  const [state, setState] = useReducer(
    (prev, state) => ({ ...prev, ...state }),
    {
      loadMore: false,
      canLoad: false,
      items: [],
      total: 0,
    },
  );
  const [innerFilter, setInnerFilter] = useState({
    limit: 20,
    offset: 0,
    saleChannel,
    ...(range && typeof range === "object" ? { range } : {}),
    ...filter,
  });
  const timeoutRef = useRef(null);

  // Query
  useEffect(() => {
    let state = {};
    state = {
      ...state,
      range: range ?? null,
    };

    if (filter && typeof filter === "object") {
      state = {
        ...state,
        ...filter,
      };
    }

    if (teamID) {
      state.teamID = teamID;
    }

    setInnerFilter((p) => ({ ...p, ...state }));
    setState({ canLoad: false });
  }, [filter, range, teamID, saleChannel]);

  useEffect(() => {
    get(innerFilter);
  }, [innerFilter]);

  useEffect(() => {
    const nodes = data?.teamStatistics?.nodes;
    const total = data?.teamStatistics?.total || 0;

    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              if (!node) return;
              const {
                team,
                totalOrder,
                totalProduct,
                totalStore,
                totalMappingOrder,
                totalMember,
                rootEmail,
              } = node;
              const { id, name, createdAt } = team || {};

              return {
                id,
                name,
                totalOrder,
                totalProduct,
                createdAt,
                totalStore,
                totalMappingOrder,
                totalMember,
                rootEmail,
              };
            })
            .filter(Boolean)
        : [];

    setState({
      items: newItems,
      total,
    });
  }, [data]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <div key={`team-${index}`} data-team-id={item.id}>
              <Stack
                key={`team-${index}`}
                distribution="equalSpacing"
                wrap={false}
              >
                <span className="index-wrap">{index + 1}</span>
                <span style={styleSpan}>{item.id}</span>
                <span style={styleName}>{item.name}</span>
                <span style={styleEmail}>{item.rootEmail}</span>
                <span style={styleSpan}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </span>
                <span style={styleSpan}>{item.totalOrder}</span>
                <span style={styleSpan}>{item.totalProduct}</span>
                <span style={styleSpan}>{item.totalStore}</span>
                <span style={{ ...styleSpan, width: "15rem" }}>
                  {item.totalMappingOrder}
                </span>
                <span style={styleSpan}>{item.totalMember}</span>
              </Stack>
            </div>
          );
        })
      : null;
  }, [state.items]);

  const headerMarkup = useMemo(
    () => (
      <Stack wrap={false}>
        <span className="index-wrap">
          <TextStyle variation="strong">#</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">ID</TextStyle>
        </span>
        <span style={styleName}>
          <TextStyle variation="strong">Name</TextStyle>
        </span>
        <span style={styleEmail}>
          <TextStyle variation="strong">Root Email</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Created At</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Total Order</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Total Product</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Total Store</TextStyle>
        </span>
        <span style={{ ...styleSpan, width: "15rem" }}>
          <TextStyle variation="strong">Total Mapping Order</TextStyle>
        </span>
        <span style={styleSpan}>
          <TextStyle variation="strong">Total Member</TextStyle>
        </span>
      </Stack>
    ),
    [],
  );
  const subTitleMarkup = useMemo(() => {
    const len = state.items?.length;
    return (
      <span>
        {len} / {state.total} Teams
      </span>
    );
  }, [state.total, state.items?.length]);

  // Actions
  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!state.canLoad) {
      setState({ loadMore: true });
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...innerFilter,
              offset: data.teamStatistics.nodes.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const teamStatistics = fetchMoreResult.teamStatistics;
            const nodes = teamStatistics.nodes || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setState({ canLoad: true });
            }
            setState({ loadMore: false });
            let newNodes = [...prev.teamStatistics.nodes, ...nodes].filter(
              Boolean,
            );
            const newNodes2 = new Map(
              newNodes
                .map((node) =>
                  node.team.id ? [node.team.id, node] : undefined,
                )
                .filter(Boolean),
            );
            const result = Array.from(newNodes2, ([, value]) => value);

            return {
              ...prev,
              teamStatistics: {
                ...prev.teamStatistics,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, innerFilter, state.canLoad, setState, fetchMore]);

  const child = (
    <CardBox
      title="Team Statistics"
      subTitle={
        <SubTitleWrap>
          <ExportTeam
            {...filter}
            {...innerFilter}
            disabled={!Boolean(state.total)}
          />
          {subTitleMarkup}
        </SubTitleWrap>
      }
      headMarkup={headerMarkup}
      contentMarkup={contentMarkup}
      filter={innerFilter}
      total={state.total}
      loading={loading}
      error={error}
      setFilter={setInnerFilter}
      handleScroll={handleScroll}
      loadMore={state.loadMore}
      noCollapse
      collapseKey="team-statistics"
    />
  );
  return inWrapper ? <Wrapper>{child}</Wrapper> : child;
}

const EXPORT = gql`
  mutation downloadTeamStatistics($filter: TeamStatisticsFilter!) {
    downloadTeamStatistics(filter: $filter)
  }
`;

function ExportTeam({ disabled, ...filter }) {
  const [loading, setLoading] = React.useState(false);
  // Actions
  const handleSubmit = useCallback(async () => {
    const { __apolloClient__: client } = window;
    if (!client) return;
    setLoading(true);

    try {
      const res = await client.mutate({
        mutation: EXPORT,
        variables: {
          filter,
        },
      });

      const url = res?.data?.downloadTeamStatistics;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (_e) {
    } finally {
      setLoading(false);
    }
  }, [filter]);

  return (
    <Button
      plain
      icon={ImportMinor}
      onClick={handleSubmit}
      loading={loading}
      disabled={disabled}
    />
  );
}

const Wrapper = styled.div`
  .index-wrap {
    width: 3rem;
    display: inline-block;
  }

  .column-wrap {
    width: 7rem;
    display: inline-block;
  }

  .name-wrap {
    display: flex;
    flex: 1 1;
  }

  margin-bottom: 2rem;
`;

const SubTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.6rem;
`;
