import { gql } from "apollo-boost";

export const GET_TIKTOK_PRODUCTS_SYNCED = gql`
  query getTiktokProductsSynced($filter: GetTiktokProductsSyncedFilter!) {
    getTiktokProductsSynced(filter: $filter) {
      total
      nodes {
        originID
        title
        status
        id
        isLock
        inPromotion
      }
    }
  }
`;

export const GET_TIKTOK_PRODUCT_VARIANTS_SYNCED = gql`
  query getTiktokProductVariantsSynced($productID: String!, $storeID: String!) {
    getTiktokProductVariantsSynced(productID: $productID, storeID: $storeID) {
      originID
      title
      status
      id
    }
  }
`;

export const SYNCED_TIKTOK_PRODUCT_TODAY = gql`
  query syncedTiktokProductToday($storeID: String!) {
    syncedTiktokProductToday(storeID: $storeID)
  }
`;

export const GET_TIKTOK_PROMOTIONS = gql`
  query getTiktokPromotions($filter: TiktokPromotionFilter!) {
    getTiktokPromotions(filter: $filter) {
      total
      nodes {
        id
        createdAt
        title
        activityID
        storeID
        activityType
        productLevel
        autoRenew
        beginTime
        endTime
        status
        store {
          id
          title
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TIKTOK_PROMOTION_PRODUCTS = gql`
  query getTiktokPromotionProducts($filter: TiktokPromotionProductFilter!) {
    getTiktokPromotionProducts(filter: $filter) {
      total
      nodes {
        id
        title
        originProductID
        discount
        quantityLimit
        quantityPerUser
        status
      }
    }
  }
`;
