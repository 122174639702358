import React, { useCallback, useContext, useRef } from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { SheetPolaris } from "../../shared/SheetPolaris";
import { FormTagPolaris } from "./FormTagPolaris";
import { TagContext } from "./TagContext";
import { TagFragment } from "./AddTagPolaris";

const UPDATE_TAG = gql`
    mutation updateTag($id: ID!, $name: String) {
        updateTag(id: $id, name: $name) {
            ...TagFragment
        }
    }
    ${TagFragment}
`;

export const EditTagPolaris = ({ tag, handleSheetChange }) => {
    // Context
    const { notifyChange, toggleToast } = useContext(TagContext);

    // Ref
    const timeoutRef = useRef(null);

    // Mutation
    const [updateTag, { loading }] = useMutation(UPDATE_TAG, {
        onCompleted: () => {
            if (notifyChange) {
                notifyChange({ msg: "Edit tag successfully.", err: false });
            }

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                handleSheetChange();
            }, 2000);
        },
        onError: (err) => {
            if (notifyChange) {
                notifyChange({ msg: handleError(err.toString()), err: true });
            }
        },
    });

    // Mutation
    const handleSubmit = useCallback(
        (value) => {
            toggleToast && toggleToast(true);
            if (notifyChange) {
                notifyChange({ msg: null, err: false });
            }

            updateTag({
                variables: {
                    name: value,
                    id: tag.id,
                },
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [toggleToast, notifyChange, updateTag, tag.id]
    );

    return (
        <SheetPolaris title="Edit Tag" handleSheetChange={handleSheetChange}>
            <FormTagPolaris
                handleSubmitProps={handleSubmit}
                handleSheetChange={handleSheetChange}
                loading={loading}
                tag={tag}
                buttonLabel="Edit"
            />
        </SheetPolaris>
    );
};
