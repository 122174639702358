import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Checkbox,
  Form,
  FormLayout,
  Icon,
  Labelled,
  Link,
  TextField,
  Toast,
} from "@shopify/polaris";
import {
  CustomersMajorMonotone,
  HideMinor,
  LockMajorMonotone,
  ViewMinor,
} from "@shopify/polaris-icons";
import _ from "lodash";
import QRCode from "qrcode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import bgLogin from "../assets/images/photo-login.jpeg";
import CopyBox from "../components/shared/CopyBox";
import { LiveApi } from "../config";
import { AppContext } from "../context";
import { LOGIN_MUTATION } from "../graphql/mutations";
import { arrInvalid, checkRole, handleError, isEmail, mapPathByExtraMenu, setCookie, uniqueArr } from "../helper";
import history from "../history";
import { useFingerprint } from "../hooks/useFingerprint";
import { TEAM_ROLE, USER_ROLE } from "../variable";

export const LoginPolaris = () => {
  const [iconView, setIconView] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [error, setError] = useState("");
  const [remember, setRemember] = useState(false);
  const [extraFields, setExtraFields] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    {
      step: 1,
      dataLogin: null,
      otpUrl: null,
      code: null,
      loading: false,
      twoFASecret: null,
    },
  );

  const { authenticate, setStore, currentUser } = useContext(AppContext);

  // hook
  const fingerprint = useFingerprint();

  useEffect(() => {
    // Redirect by origin url
    const search = window?.location.search;
    let redirectUrl;
    const urlPattern = "?origin_url";
    const slash = "/";
    if (search && search.startsWith(urlPattern)) {
      redirectUrl = search.replace(urlPattern + "=", "");
      if (!redirectUrl.startsWith(slash)) {
        redirectUrl = slash + redirectUrl;
      }
    }
    if (currentUser) {
      if (redirectUrl) {
        history.push(redirectUrl);
        return;
      }

      const REDIRECT_URLS = {
        Supporter: "/seller-supporter/orders",
        DesignLeader: "/design-leader/design-tasks",
        Designer: "/designer/design-tasks",
        StoreManager: "/store-manager/products",
        Home: "/",
        Idea: "/idea/tasks",
        IdeaLeader: "/idea-leader/tasks",
        Accountant: "/seller-accountant/reports/amazon",
      };

      if (
        currentUser.roles?.includes("Supplier") &&
        currentUser.teamUser == null
      ) {
        history.push("/supplier/orders");
        return;
      }

      const genPrefixDS = (isLeader) => {
        const { extraMenus } = currentUser || {};

        let newRouters = [];
        const newExtraMenus = uniqueArr(extraMenus);
        let prefix = isLeader ? undefined : "/designer";
        const { tasksMenu, pathMatch } = mapPathByExtraMenu(newExtraMenus, prefix);
        if (!arrInvalid(tasksMenu) && !arrInvalid(pathMatch)) {
          newRouters = pathMatch;
        }

        let [firstPath] = newRouters;
        let res = "";
        if(firstPath) {
          const [_prefix, _path, ...rest] = firstPath.split('/');
          const path = isLeader ? '/design-leader' : '/designer' 
          res = [path, ...rest].join('/')
        }

        return res;
      };
      switch (_.get(currentUser, "teamUser.role")) {
        case USER_ROLE.Supporter:
          history.push(REDIRECT_URLS.Supporter);
          break;
        case TEAM_ROLE.DesignLeader:
          let path = genPrefixDS(true);
          path = path ? path : REDIRECT_URLS.DesignLeader
          history.push(path);
          break;
        case TEAM_ROLE.Designer:
          let pathDS = genPrefixDS();
          pathDS = pathDS ? pathDS : REDIRECT_URLS.Designer
          history.push(pathDS);
          break;
        case TEAM_ROLE.StoreManager:
          history.push(REDIRECT_URLS.StoreManager);
          setStore({ id: "all", title: "All Stores" });
          break;
        case TEAM_ROLE.MarketplaceManager:
          history.push(REDIRECT_URLS.Home);
          setStore({ id: "all", title: "All Stores" });
          break;
        case TEAM_ROLE.Idea:
          history.push(REDIRECT_URLS.Idea);
          break;
        case TEAM_ROLE.IdeaLeader:
          history.push(REDIRECT_URLS.IdeaLeader);
          break;
        case TEAM_ROLE.Accountant:
          history.push(REDIRECT_URLS.Accountant);
          break;
        default:
          history.push(REDIRECT_URLS.Home);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const updateUserInfo = React.useCallback(
    (data) => {
      const { user } = data || {};
      const id = (user || {}).id;
      if (id) {
        setCookie("userId", id, 100);
      }

      authenticate(data);
    },
    [authenticate],
  );

  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (res) => {
      // let userId = res.login.user.id;
      // setCookie("userId", userId, 100);
      // if (res) {
      //   authenticate(res.login);
      // }
      // setTokenObject(res.login);
      if (!res.login) return;

      if (res?.login) {
        const { enable2fa, otpUrl, twoFASecret, use2fa } = res.login;
        /**
         * enable2fa = false => login
         * enable2fa = true && use2fa = false (Ignore != None) => login
         */
        if (!enable2fa || (enable2fa && !use2fa)) {
          updateUserInfo(res.login);
        } else {
          setExtraFields({
            step: 2,
            dataLogin: res.login,
            otpUrl,
            twoFASecret,
          });
        }
      }
      setError("");
    },
    onError: (err) => {
      setError(handleError(err?.toString()));
    },
  });

  const handleErrorEmail = (value) => {
    if (value) {
      if (!isEmail(value)) {
        setErrorEmail("Email must be a valid email address.");
      } else {
        setErrorEmail(null);
      }
    } else {
      setErrorEmail("Email is required.");
    }
  };

  const handleErrorPassword = (value) => {
    if (!value) {
      setErrorPassword("Please choose a password.");
    } else {
      setErrorPassword(null);
    }
  };

  const handleEmailChange = useCallback((value) => {
    handleErrorEmail(value);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    handleErrorPassword(value);
    setPassword(value);
  }, []);

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const changeRemember = useCallback((newValue) => setRemember(newValue), []);

  const handleSubmit = useCallback(() => {
    setActiveToast(true);
    setError("");
    if (extraFields.step === 1) {
      handleErrorEmail(email);
      handleErrorPassword(password);
      if (email && password && !errorEmail && !errorPassword) {
        login({
          variables: {
            input: {
              email,
              password,
              remember,
              fingerprint,
            },
          },
        });
      }
    } else if (extraFields.code) {
      setExtraFields({ loading: true });
      (async function (extraFields) {
        const { id } = extraFields.dataLogin || {};
        if (!id) return;
        const headers = {
          "content-type": "application/json",
          Authorization: "Bearer " + id,
        };

        try {
          const body = JSON.stringify({
            query: `
              mutation verify2faUser {
                verify2faUser(code: "${extraFields.code}", fingerprint: "${fingerprint}")
              }
            `,
          });
          const res = await fetch(LiveApi + "/query", {
            method: "post",
            body,
            headers,
          });

          const { data, errors } = await res.json();
          if (data?.verify2faUser) {
            updateUserInfo(extraFields.dataLogin);
          } else if (errors) {
            if (errors?.length > 0) {
              const msg = errors[0].message;
              throw new Error(msg);
            }
          }
          setError("");
        } catch (err) {
          setError(err?.toString());
        } finally {
          setExtraFields({ loading: false });
        }
      })(extraFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    password,
    errorEmail,
    errorPassword,
    remember,
    extraFields.step,
    extraFields.code,
    extraFields.dataLogin,
    fingerprint,
  ]);

  const toastMarkup =
    activeToast && error ? (
      <Toast
        content={
          error ? _.capitalize(handleError(error.toString()).trim()) : null
        }
        error={error ? error : null}
        duration={5000}
        onDismiss={toggleActive}
      />
    ) : null;

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  return (
    <Container>
      <div className="container mx-auto">
        <div className="flex justify-center px-8">
          <div className="container bg-white register-card w-full xl:w-3/4 lg:w-11/12 flex rounded-l-lg rounded-r-lg">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
              style={{ backgroundImage: `url(${bgLogin})` }}
            />
            <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
              <Form onSubmit={handleSubmit}>
                {extraFields.step === 1 ? (
                  <FormLayout>
                    <h3
                      className="pt-4 text-center font-medium"
                      style={{ fontSize: "2.25rem" }}
                    >
                      Welcome Back!
                    </h3>
                    <TextField
                      value={email}
                      onChange={handleEmailChange}
                      label="Email"
                      type="email"
                      placeholder="Email"
                      prefix={<Icon source={CustomersMajorMonotone} />}
                      error={errorEmail}
                    />
                    <TextField
                      value={password}
                      onChange={handlePasswordChange}
                      label="Password"
                      type={!iconView ? "password" : "text"}
                      placeholder="***************"
                      prefix={<Icon source={LockMajorMonotone} />}
                      suffix={iconViewMarkup}
                      error={errorPassword}
                    />
                    <Checkbox
                      label="Remember me"
                      onChange={changeRemember}
                      checked={remember}
                    />
                    <Button fullWidth primary submit loading={loading}>
                      Login
                    </Button>
                    <hr className="mb-6 border-t" />
                    <div className="flex flex-col items-center">
                      <Link url="/register">Create an Account!</Link>
                      <Link url="/forgot-password">Forgot Password?</Link>
                    </div>
                  </FormLayout>
                ) : (
                  <Verify2FA
                    extraFields={extraFields}
                    setExtraFields={setExtraFields}
                    loading={extraFields.loading}
                  />
                )}
              </Form>
            </div>
          </div>
          {toastMarkup}
        </div>
      </div>
      <p className="term">PRIVACY POLICY AND TERMS OF SERVICE</p>
    </Container>
  );
};

function Verify2FA({ extraFields, setExtraFields, loading }) {
  const [qrcodeUrl, setqrCodeUrl] = React.useState("");

  React.useEffect(() => {
    if (!extraFields.otpUrl) return;

    QRCode.toDataURL(extraFields.otpUrl).then(setqrCodeUrl);
  }, [extraFields.otpUrl]);

  const handleCodeChange = React.useCallback(
    (val) => {
      setExtraFields({ code: val });
    },
    [setExtraFields],
  );

  React.useEffect(() => {
    function handler(event) {
      event.preventDefault();
      return (event.returnValue = "");
    }
    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  return (
    <FormLayout>
      <h3
        className="pt-4 text-center font-medium"
        style={{ fontSize: "2.25rem" }}
      >
        Two-Factor Authentication (2FA)
      </h3>

      {qrcodeUrl ? (
        <div>
          <Labelled children="Scan QR Code" />
          <img
            alt="Two-Factor Authentication"
            src={qrcodeUrl}
            style={{ display: "block", margin: "0 auto" }}
          />
        </div>
      ) : null}
      {extraFields.twoFASecret ? (
        <div>
          <span>Or using secret code</span>
          <CopyBox text={extraFields.twoFASecret} />
        </div>
      ) : null}
      <TextField
        value={extraFields.code}
        onChange={handleCodeChange}
        label="Verify Code"
        placeholder="Code"
      />
      <Button
        fullWidth
        primary
        submit
        loading={loading}
        children="Verify"
        disabled={!!!extraFields.code}
      />
    </FormLayout>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #edf2f7;
  align-items: center;
  .Polaris-Link + .Polaris-Link {
    margin-top: 1rem;
  }
  .wrap-icon {
    .Polaris-Button {
      margin-top: -1px;
    }
  }
  .Polaris-FormLayout {
    min-height: 415px;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  .term {
    margin-top: 40px;
    position: absolute;
    color: gray;
    bottom: 0;
  }
  .Polaris-FormLayout__Item {
    margin-top: 2.5rem;
  }
`;
