import { Labelled } from "@shopify/polaris";
import React from "react";
import Wysiwyg from "../../../Wysiwyg";

const label = "Description";
export function ProductDescription({ value, onChange, canEdit }) {
    return (
        <div>
            <Labelled label={label} />
            <Wysiwyg value={value} onChange={onChange} disabled={!canEdit} />
        </div>
    );
}
