import React from "react";
import styled from "styled-components";
import _ from "lodash";

import AntiqueHeliconia from "../../assets/images/colors/antique-heliconia.jpg";
import AntiqueSapphire from "../../assets/images/colors/antique-sapphire.jpg";
import Aquamarine from "../../assets/images/colors/aquamarine.jpg";
import Ash from "../../assets/images/colors/ash.jpg";
import AthleticGold from "../../assets/images/colors/athletic-gold.jpg";
import Banana from "../../assets/images/colors/banana.jpg";
import AthleticHeather from "../../assets/images/colors/athletic-heather.jpg";
import BerrySoftpink from "../../assets/images/colors/berry_softpink.jpg";
import Black from "../../assets/images/colors/black.jpg";
import BlackGold from "../../assets/images/colors/black_gold.jpg";
import BlackGray from "../../assets/images/colors/black_gray.jpg";
import BlackOrange from "../../assets/images/colors/black_orange.jpg";
import BlackWhite from "../../assets/images/colors/black_white.jpg";
import BlackRed from "../../assets/images/colors/black-red.jpg";
import BlackVintageHeather from "../../assets/images/colors/black-vintage-heather.jpg";
import Blue from "../../assets/images/colors/blue.jpg";
import BlueBerry from "../../assets/images/colors/blue-berry.jpg";
import Brown from "../../assets/images/colors/brown.jpg";
import BurntOrange from "../../assets/images/colors/burnt-orange.jpg";
import Cardinal from "../../assets/images/colors/cardinal.jpg";
import CardinalRed from "../../assets/images/colors/cardinal-red.jpg";
import CarolinaBlue from "../../assets/images/colors/carolina-blue.jpg";
import Charcoal from "../../assets/images/colors/charcoal.jpg";
import CharcoalGrey from "../../assets/images/colors/charcoal-grey.jpg";
import CharityPink from "../../assets/images/colors/charity-pink.jpg";
import Chocolate from "../../assets/images/colors/chocolate.jpg";
import ColumbiaBlue from "../../assets/images/colors/columbia-blue.jpg";
import ColumbiaNavy from "../../assets/images/colors/columbia-navy.jpg";
import Coral from "../../assets/images/colors/coral.jpg";
import Daisy from "../../assets/images/colors/daisy.jpg";
import DarkCharcoal from "../../assets/images/colors/dark-charcoal.jpg";
import DarkChocolate from "../../assets/images/colors/dark-chocolate.jpg";
import DarkFuchsia from "../../assets/images/colors/dark-fuchsia.jpg";
import DarkGreen from "../../assets/images/colors/dark-green.jpg";
import DarkHeather from "../../assets/images/colors/dark-heather.jpg";
import DarkSmoke from "../../assets/images/colors/dark-smoke.jpg";
import DarkSmokeBlack from "../../assets/images/colors/dark-smoke-black.jpg";
import DeepSmoke from "../../assets/images/colors/deep-smoke.jpg";
import DeepTeal from "../../assets/images/colors/deep-teal.jpg";
import ElectricGreen from "../../assets/images/colors/electric-green.jpg";
import Forest from "../../assets/images/colors/forest.jpg";
import ForestGreen from "../../assets/images/colors/forest-green.jpg";
import Fuchsia from "../../assets/images/colors/fuchsia.jpg";
import Garnet from "../../assets/images/colors/garnet.jpg";
import Gold from "../../assets/images/colors/gold.jpg";
import GoldRoyal from "../../assets/images/colors/gold_royal.jpg";
import GoldBlack from "../../assets/images/colors/gold-black.jpg";
import GoldNavy from "../../assets/images/colors/gold-navy.jpg";
import Graphite from "../../assets/images/colors/graphite.jpg";
import GraphiteBlack from "../../assets/images/colors/graphite-black.jpg";
import Gray from "../../assets/images/colors/gray.jpg";
import GrayBlack from "../../assets/images/colors/gray-black.jpg";
import GrayNavy from "../../assets/images/colors/gray-navy.jpg";
import GrayRed from "../../assets/images/colors/gray-red.jpg";
import GrayRoyal from "../../assets/images/colors/gray-royal.jpg";
import GrayWhite from "../../assets/images/colors/gray-white.jpg";
import GreenApple from "../../assets/images/colors/green-apple.jpg";
import GreenWhite from "../../assets/images/colors/green-white.jpg";
import Greenoasis from "../../assets/images/colors/greenoasis.jpg";
import GreyHeather from "../../assets/images/colors/grey-heather.jpg";
import HeatherGrey from "../../assets/images/colors/heather-grey.jpg";
import HeatherNavy from "../../assets/images/colors/heather-navy.jpg";
import HeatherPurple from "../../assets/images/colors/heather-purple.jpg";
import HeatherRed from "../../assets/images/colors/heather-red.jpg";
import HeatherRoyal from "../../assets/images/colors/heather-royal.jpg";
import HeatherWhite from "../../assets/images/colors/heather-white.jpg";
import HeatheredNickel from "../../assets/images/colors/heathered-nickel.jpg";
import HeatheredSteel from "../../assets/images/colors/heathered-steel.jpg";
import HeavyMetal from "../../assets/images/colors/heavy-metal.jpg";
import Heliconia from "../../assets/images/colors/heliconia.jpg";
import HotPink from "../../assets/images/colors/hot-pink.jpg";
import HunterGreenGray from "../../assets/images/colors/hunter-green-gray.jpg";
import IndependenceRed from "../../assets/images/colors/independence-red.jpg";
import Indigo from "../../assets/images/colors/indigo.jpg";
import IrishGreen from "../../assets/images/colors/irish-green.jpg";
import IronGrey from "../../assets/images/colors/iron-grey.jpg";
import Kelly from "../../assets/images/colors/kelly.jpg";
import KellyGreen from "../../assets/images/colors/kelly-green.jpg";
import KeyLime from "../../assets/images/colors/key-lime.jpg";
import Kiwi from "../../assets/images/colors/kiwi.jpg";
import Leaf from "../../assets/images/colors/leaf.jpg";
import LemonYellow from "../../assets/images/colors/lemon-yellow.jpg";
import LightBlue from "../../assets/images/colors/light-blue.jpg";
import LightPink from "../../assets/images/colors/light-pink.jpg";
import Lilac from "../../assets/images/colors/lilac.jpg";
import Lime from "../../assets/images/colors/lime.jpg";
import MajesticPurple from "../../assets/images/colors/majestic-purple.jpg";
import Marleddeepred from "../../assets/images/colors/marleddeepred.jpg";
import Maroon from "../../assets/images/colors/maroon.jpg";
import MaroonWhite from "../../assets/images/colors/maroon_white.jpg";
import MaroonGray from "../../assets/images/colors/maroon-gray.jpg";
import Melon from "../../assets/images/colors/melon.jpg";
import MidnightNavy from "../../assets/images/colors/midnight-navy.jpg";
import MilitaryGreen from "../../assets/images/colors/military-green.jpg";
import MintGreen from "../../assets/images/colors/mint-green.jpg";
import Navy from "../../assets/images/colors/navy.jpg";
import NavyGold from "../../assets/images/colors/navy_gold.jpg";
import NavyOxford from "../../assets/images/colors/navy_oxford.jpg";
import NavyUniversityblue from "../../assets/images/colors/navy_universityblue.jpg";
import NavyWhite from "../../assets/images/colors/navy_white.jpg";
import NavyGray from "../../assets/images/colors/navy-gray.jpg";
import NavyNavyHeather from "../../assets/images/colors/navy-navy-heather.jpg";
import NeonOrange from "../../assets/images/colors/neon-orange.jpg";
import NeonPink from "../../assets/images/colors/neon-pink.jpg";
import OldGold from "../../assets/images/colors/old-gold.jpg";
import Orange from "../../assets/images/colors/orange.jpg";
import OrangeBlack from "../../assets/images/colors/orange_black.jpg";
import OxfordGrey from "../../assets/images/colors/oxford-grey.jpg";
import PinkRaspberry from "../../assets/images/colors/pink-raspberry.jpg";
import Purple from "../../assets/images/colors/purple.jpg";
import PurpleWhite from "../../assets/images/colors/purple-white.jpg";
import Raspberry from "../../assets/images/colors/raspberry.jpg";
import Red from "../../assets/images/colors/red.jpg";
import RedWhite from "../../assets/images/colors/red_white.jpg";
import Royal from "../../assets/images/colors/royal.jpg";
import RoyalGold from "../../assets/images/colors/royal_gold.jpg";
import RoyalWhite from "../../assets/images/colors/royal_white.jpg";
import RoyalGray from "../../assets/images/colors/royal-gray.jpg";
import Sapphire from "../../assets/images/colors/sapphire.jpg";
import Scarlet from "../../assets/images/colors/scarlet.jpg";
import Smoke from "../../assets/images/colors/smoke.jpg";
import SportGrey from "../../assets/images/colors/sport-grey.jpg";
import SpringYellow from "../../assets/images/colors/spring-yellow.jpg";
import Steel from "../../assets/images/colors/steel.jpg";
import StormGrey from "../../assets/images/colors/storm-grey.jpg";
import TahitiBlue from "../../assets/images/colors/tahiti-blue.jpg";
import Tan from "../../assets/images/colors/tan.jpg";
import Taupe from "../../assets/images/colors/taupe.jpg";
import Teal from "../../assets/images/colors/teal.jpg";
import TexasOrange from "../../assets/images/colors/texas-orange.jpg";
import TrueNavy from "../../assets/images/colors/true-navy.jpg";
import TrueRed from "../../assets/images/colors/true-red.jpg";
import TrueRoyal from "../../assets/images/colors/true-royal.jpg";
import TurfGreen from "../../assets/images/colors/turf-green.jpg";
import Turquoise from "../../assets/images/colors/turquoise.jpg";
import VintageBlack from "../../assets/images/colors/vintage-black.jpg";
import VintageGreyVintageOrange from "../../assets/images/colors/vintage-grey-vintage-orange.jpg";
import VintageHeatherBlack from "../../assets/images/colors/vintage-heather-black.jpg";
import VintageHeatherCarolinaBlue from "../../assets/images/colors/vintage-heather-carolina-blue.jpg";
import VintageHeatherForestGreen from "../../assets/images/colors/vintage-heather-forest-green.jpg";
import VintageHeatherGold from "../../assets/images/colors/vintage-heather-gold.jpg";
import VintageHeatherMaroon from "../../assets/images/colors/vintage-heather-maroon.jpg";
import VintageHeatherPurple from "../../assets/images/colors/vintage-heather-purple.jpg";
import VintageHeatherTrueNavy from "../../assets/images/colors/vintage-heather-true-navy.jpg";
import VintageHeatherTrueRed from "../../assets/images/colors/vintage-heather-true-red.jpg";
import VintageHeatherTrueRoyal from "../../assets/images/colors/vintage-heather-true-royal.jpg";
import VintageNavy from "../../assets/images/colors/vintage-navy.jpg";
import VintagePink from "../../assets/images/colors/vintage-pink.jpg";
import VintagePurple from "../../assets/images/colors/vintage-purple.jpg";
import VintageRed from "../../assets/images/colors/vintage-red.jpg";
import VintageRoyal from "../../assets/images/colors/vintage-royal.jpg";
import WhiteGold from "../../assets/images/colors/white-gold.jpg";
import WhiteGreen from "../../assets/images/colors/white-green.jpg";
import WhiteOrange from "../../assets/images/colors/white-orange.jpg";
import WhitePurple from "../../assets/images/colors/white-purple.jpg";
import White from "../../assets/images/colors/white.jpg";
import WhiteAthleticHeather from "../../assets/images/colors/white_athletic-heather.jpg";
import WhiteBlack from "../../assets/images/colors/white_black.jpg";
import WhiteMaroon from "../../assets/images/colors/white_maroon.jpg";
import WhiteNavy from "../../assets/images/colors/white_navy.jpg";
import WhiteRed from "../../assets/images/colors/white_red.jpg";
import WhiteRoyal from "../../assets/images/colors/white_royal.jpg";
import WhiteRaspberry from "../../assets/images/colors/white-raspberry.jpg";
import Yellow from "../../assets/images/colors/yellow.jpg";
import CaldetBlue from "../../assets/images/colors/caldet-blue.jpg";
import Coffee from "../../assets/images/colors/coffee.jpg";
import DustyPink from "../../assets/images/colors/dusty-pink.jpg";
import MauveTaupe from "../../assets/images/colors/mauve-taupe.jpg";
import Cream from "../../assets/images/colors/cream.jpg";
import SageGreen from "../../assets/images/colors/sage-green.jpg";
import Salmon from "../../assets/images/colors/salmon.jpg";
import Watermelon from "../../assets/images/colors/watermelon.jpg";

const COLORS = [
  {
    names: ["antique-heliconia"],
    src: AntiqueHeliconia,
  },
  {
    names: ["antique-sapphire"],
    src: AntiqueSapphire,
  },
  {
    names: ["aquamarine", "aqua-triblend"],
    src: Aquamarine,
  },
  {
    names: ["ash"],
    src: Ash,
  },
  {
    names: ["athletic-gold"],
    src: AthleticGold,
  },
  {
    names: ["athletic-heather"],
    src: AthleticHeather,
  },
  {
    names: ["banana", "banana-cream"],
    src: Banana,
  },
  {
    names: ["berry-softpink"],
    src: BerrySoftpink,
  },
  {
    names: ["black", "black-heather"],
    src: Black,
  },
  {
    names: ["black-gold"],
    src: BlackGold,
  },
  {
    names: [
      "black-gray",
      "black-athletic-heather",
      "black-grey-cosmic",
      "black-light-oxford",
    ],
    src: BlackGray,
  },
  {
    names: ["black-orange"],
    src: BlackOrange,
  },
  {
    names: ["black-white"],
    src: BlackWhite,
  },
  {
    names: ["black-red", "black-true-red"],
    src: BlackRed,
  },
  {
    names: ["black-vintage-heather", "black-graphite"],
    src: BlackVintageHeather,
  },
  {
    names: ["blue"],
    src: Blue,
  },
  {
    names: ["blue-berry"],
    src: BlueBerry,
  },
  {
    names: ["brown"],
    src: Brown,
  },
  {
    names: ["burnt-orange"],
    src: BurntOrange,
  },
  {
    names: ["cardinal"],
    src: Cardinal,
  },
  {
    names: ["cardinal-red"],
    src: CardinalRed,
  },
  {
    names: ["carolina-blue"],
    src: CarolinaBlue,
  },
  {
    names: ["charcoal"],
    src: Charcoal,
  },
  {
    names: ["charcoal-grey", "charcoal-black-triblend", "dark-grey"],
    src: CharcoalGrey,
  },
  {
    names: ["charity-pink"],
    src: CharityPink,
  },
  {
    names: ["chocolate", "macchiato"],
    src: Chocolate,
  },
  {
    names: ["columbia-blue"],
    src: ColumbiaBlue,
  },
  {
    names: ["columbia-navy", "carolina-blue-navy"],
    src: ColumbiaNavy,
  },
  {
    names: ["coral"],
    src: Coral,
  },
  {
    names: ["daisy"],
    src: Daisy,
  },
  {
    names: ["dark-charcoal"],
    src: DarkCharcoal,
  },
  {
    names: ["dark-chocolate"],
    src: DarkChocolate,
  },
  {
    names: ["dark-fuchsia", "berry"],
    src: DarkFuchsia,
  },
  {
    names: ["dark-green"],
    src: DarkGreen,
  },
  {
    names: ["dark-heather", "dark-heather-grey"],
    src: DarkHeather,
  },
  {
    names: ["dark-smoke", "dark-grey-heather"],
    src: DarkSmoke,
  },
  {
    names: ["dark-smoke-black", "dark-heather-grey-black"],
    src: DarkSmokeBlack,
  },
  {
    names: ["depp-smoke"],
    src: DeepSmoke,
  },
  {
    names: ["deep-teal", "deepa-zule"],
    src: DeepTeal,
  },
  {
    names: ["electric-green"],
    src: ElectricGreen,
  },
  {
    names: ["forest"],
    src: Forest,
  },
  {
    names: ["forest-green"],
    src: ForestGreen,
  },
  {
    names: ["fuchsia", "fuchsia-frost"],
    src: Fuchsia,
  },
  {
    names: ["garnet"],
    src: Garnet,
  },
  {
    names: ["gold"],
    src: Gold,
  },
  {
    names: ["gold-royal"],
    src: GoldRoyal,
  },
  {
    names: ["gold-black"],
    src: GoldBlack,
  },
  {
    names: ["gold-navy"],
    src: GoldNavy,
  },
  {
    names: ["graphite"],
    src: Graphite,
  },
  {
    names: ["graphite-black", "black-electric-black"],
    src: GraphiteBlack,
  },
  {
    names: ["gray", "asphalt"],
    src: Gray,
  },
  {
    names: ["gray-black", "heather-grey-black"],
    src: GrayBlack,
  },
  {
    names: ["gray-navy", "heather-grey-navy"],
    src: GrayNavy,
  },
  {
    names: ["gray-red", "heather-grey-red"],
    src: GrayRed,
  },
  {
    names: ["gray-royal", "heather-grey-royal"],
    src: GrayRoyal,
  },
  {
    names: ["gray-white", "vintage-heather-white", "graphite-white"],
    src: GrayWhite,
  },
  {
    names: ["green-apple", "green"],
    src: GreenApple,
  },
  {
    names: ["green-white", "forest-green-white"],
    src: GreenWhite,
  },
  {
    names: ["greenoasis", "green-triblend"],
    src: Greenoasis,
  },
  {
    names: ["grey-heather", "grey-frost"],
    src: GreyHeather,
  },
  {
    names: ["heather-grey", "grey", "premium-heather"],
    src: HeatherGrey,
  },
  {
    names: ["heather-navy"],
    src: HeatherNavy,
  },
  {
    names: ["heather-purple"],
    src: HeatherPurple,
  },
  {
    names: ["heather-red", "heathered-deep-red"],
    src: HeatherRed,
  },
  {
    names: ["heather-royal"],
    src: HeatherRoyal,
  },
  {
    names: ["heather-white", "white-fleck-triblend"],
    src: HeatherWhite,
  },
  {
    names: ["heathered-nickel", "grey-triblend"],
    src: HeatheredNickel,
  },
  {
    names: ["heathered-steel", "venetian-grey"],
    src: HeatheredSteel,
  },
  {
    names: ["heavy-metal"],
    src: HeavyMetal,
  },
  {
    names: ["heliconia"],
    src: Heliconia,
  },
  {
    names: ["hot-pink"],
    src: HotPink,
  },
  {
    names: ["hunter-green-gray", "hunter-green-light-oxford"],
    src: HunterGreenGray,
  },
  {
    names: ["independence-red"],
    src: IndependenceRed,
  },
  {
    names: ["indigo"],
    src: Indigo,
  },
  {
    names: ["irish-green"],
    src: IrishGreen,
  },
  {
    names: ["iron-grey"],
    src: IronGrey,
  },
  {
    names: ["kelly"],
    src: Kelly,
  },
  {
    names: ["kelly-green"],
    src: KellyGreen,
  },
  {
    names: ["key-lime"],
    src: KeyLime,
  },
  {
    names: ["kiwi"],
    src: Kiwi,
  },
  {
    names: ["leaf", "envy"],
    src: Leaf,
  },
  {
    names: ["lemon-yellow", "maize-yellow"],
    src: LemonYellow,
  },
  {
    names: ["light-blue"],
    src: LightBlue,
  },
  {
    names: ["light-pink", "soft-pink"],
    src: LightPink,
  },
  {
    names: ["lilac", "purple-frost"],
    src: Lilac,
  },
  {
    names: ["lime"],
    src: Lime,
  },
  {
    names: ["majestic-purple", "purple-triblend"],
    src: MajesticPurple,
  },
  {
    names: ["marleddeepred", "red-triblend"],
    src: Marleddeepred,
  },
  {
    names: ["maroon"],
    src: Maroon,
  },
  {
    names: ["maroon-white"],
    src: MaroonWhite,
  },
  {
    names: ["maroon-gray", "maroon-light-oxford"],
    src: MaroonGray,
  },
  {
    names: ["melon", "light-orange"],
    src: Melon,
  },
  {
    names: ["midnight-navy", "midnight"],
    src: MidnightNavy,
  },
  {
    names: ["military-green"],
    src: MilitaryGreen,
  },
  {
    names: ["mint-green", "mint"],
    src: MintGreen,
  },
  {
    names: ["navy"],
    src: Navy,
  },
  {
    names: ["navy-gold", "true-navy-gold"],
    src: NavyGold,
  },
  {
    names: ["navy-oxford"],
    src: NavyOxford,
  },
  {
    names: ["navy-universityblue"],
    src: NavyUniversityblue,
  },
  {
    names: ["navy-white", "true-navy-white"],
    src: NavyWhite,
  },
  {
    names: ["navy-gray", "bright-navy-light-oxford"],
    src: NavyGray,
  },
  {
    names: ["navy-navy-heather", "navy-royal-cosmic"],
    src: NavyNavyHeather,
  },
  {
    names: ["neon-orange", "safety-orange"],
    src: NeonOrange,
  },
  {
    names: ["neon-pink"],
    src: NeonPink,
  },
  {
    names: ["old-gold"],
    src: OldGold,
  },
  {
    names: ["orange"],
    src: Orange,
  },
  {
    names: ["orange-black"],
    src: OrangeBlack,
  },
  {
    names: ["oxford-grey"],
    src: OxfordGrey,
  },
  {
    names: ["pink-raspberry"],
    src: PinkRaspberry,
  },
  {
    names: ["purple", "team-purple", "purple-rush"],
    src: Purple,
  },
  {
    names: ["purple-white"],
    src: PurpleWhite,
  },
  {
    names: ["raspberry"],
    src: Raspberry,
  },
  {
    names: ["red", "canvas-red"],
    src: Red,
  },
  {
    names: ["red", "red-white", "true-red-white"],
    src: RedWhite,
  },
  {
    names: ["royal", "royal-triblend"],
    src: Royal,
  },
  {
    names: ["royal-gold"],
    src: RoyalGold,
  },
  {
    names: ["royal-white", "true-royal-white"],
    src: RoyalWhite,
  },
  {
    names: ["royal-gray", "royal-light-oxford"],
    src: RoyalGray,
  },
  {
    names: ["sapphire"],
    src: Sapphire,
  },
  {
    names: ["scarlet", "red-frost"],
    src: Scarlet,
  },
  {
    names: ["smoke"],
    src: Smoke,
  },
  {
    names: ["sport-grey"],
    src: SportGrey,
  },
  {
    names: ["spring-yellow"],
    src: SpringYellow,
  },
  {
    names: ["steel", "heathered-charcoal"],
    src: Steel,
  },
  {
    names: ["storm-grey"],
    src: StormGrey,
  },
  {
    names: ["tahiti-blue"],
    src: TahitiBlue,
  },
  {
    names: ["tan"],
    src: Tan,
  },
  {
    names: ["taupe", "pebble-brown"],
    src: Taupe,
  },
  {
    names: ["teal"],
    src: Teal,
  },
  {
    names: ["texas-orange"],
    src: TexasOrange,
  },
  {
    names: ["true-navy"],
    src: TrueNavy,
  },
  {
    names: ["true-red"],
    src: TrueRed,
  },
  {
    names: ["true-royal"],
    src: TrueRoyal,
  },
  {
    names: ["turf-green"],
    src: TurfGreen,
  },
  {
    names: ["turquoise"],
    src: Turquoise,
  },
  {
    names: ["vintage-black"],
    src: VintageBlack,
  },
  {
    names: ["vintage-grey-vintage-orange", "vintage-heather-deep-orange"],
    src: VintageGreyVintageOrange,
  },
  {
    names: ["vintage-heather-black"],
    src: VintageHeatherBlack,
  },
  {
    names: ["vintage-heather-carolina-blue"],
    src: VintageHeatherCarolinaBlue,
  },
  {
    names: ["vintage-heather-forest-green"],
    src: VintageHeatherForestGreen,
  },
  {
    names: ["vintage-heather-gold"],
    src: VintageHeatherGold,
  },
  {
    names: ["vintage-heather-maroon"],
    src: VintageHeatherMaroon,
  },
  {
    names: ["vintage-heather-purple"],
    src: VintageHeatherPurple,
  },
  {
    names: ["vintage-heather-true-navy"],
    src: VintageHeatherTrueNavy,
  },
  {
    names: ["vintage-heather-true-red"],
    src: VintageHeatherTrueRed,
  },
  {
    names: ["vintage-heather-true-royal"],
    src: VintageHeatherTrueRoyal,
  },
  {
    names: ["vintage-navy"],
    src: VintageNavy,
  },
  {
    names: ["vintage-pink"],
    src: VintagePink,
  },
  {
    names: ["vintage-purple"],
    src: VintagePurple,
  },
  {
    names: ["vintage-red"],
    src: VintageRed,
  },
  {
    names: ["vintage-royal"],
    src: VintageRoyal,
  },
  {
    names: ["white-gold"],
    src: WhiteGold,
  },
  {
    names: ["white-green", "white-forest"],
    src: WhiteGreen,
  },
  {
    names: ["white-orange", "white-deep-orange"],
    src: WhiteOrange,
  },
  {
    names: ["white-purple"],
    src: WhitePurple,
  },
  {
    names: ["white", "vintage-white"],
    src: White,
  },
  {
    names: ["white-athletic-heather"],
    src: WhiteAthleticHeather,
  },
  {
    names: ["white-black", "white-black-cosmic"],
    src: WhiteBlack,
  },
  {
    names: ["white-maroon"],
    src: WhiteMaroon,
  },
  {
    names: ["white-navy"],
    src: WhiteNavy,
  },
  {
    names: ["white-red"],
    src: WhiteRed,
  },
  {
    names: ["white-royal", "white-true-royal"],
    src: WhiteRoyal,
  },
  {
    names: ["white-raspberry", "white-pink-cosmic"],
    src: WhiteRaspberry,
  },
  {
    names: ["yellow"],
    src: Yellow,
  },
  {
    names: ["caldet-blue"],
    src: CaldetBlue,
  },
  {
    names: ["coffee"],
    src: Coffee,
  },
  {
    names: ["cream"],
    src: Cream,
  },
  {
    names: ["dusty-pink"],
    src: DustyPink,
  },
  {
    names: ["mauve-taupe"],
    src: MauveTaupe,
  },
  {
    names: ["sage-green"],
    src: SageGreen,
  },
  {
    names: ["salmon"],
    src: Salmon,
  },
  {
    names: ["watermelon"],
    src: Watermelon,
  },
];

const Container = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: none;
`;

export const ColorsComponent = ({ optionSlug, borderColor = "#1890ff" }) => {
  if (_.endsWith(optionSlug, "-")) {
    optionSlug = optionSlug.substring(0, optionSlug.length - 1);
  }

  const currentColor = _.find(COLORS, (c) => {
    return c.names && c.names.length && c.names.includes(optionSlug);
  });

  return (
    <>
      {currentColor ? (
        <Container
          src={currentColor && currentColor.src}
          style={{
            border: optionSlug.match("white") && `1px solid ${borderColor}`,
          }}
          alt={""}
        />
      ) : (
        <p style={{ width: 18, height: 18 }}></p>
      )}
    </>
  );
};
