import React from "react";
import { DataTable, Avatar, Tag } from "@shopify/polaris";
import _ from "lodash";

import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { DeleteUserAccountAdminPolaris } from "./DeleteUserAccountAdminPolaris";
import { ActionUserAccountAdminPolaris } from "./ActionUserAccountAdminPolaris";
import { TEAM_ROLE, USER_ROLE } from "../../../variable";
import styled from "styled-components";
import { GetUserToken } from "./GetUserToken";
import { useAppContext } from "../../../context";
import { SUPPER_ADMIN } from "../../../constants";

const Container = styled.div`
  .Polaris-DataTable--condensed tr {
    th {
      border-top: 1px solid #dfe5ed;
    }
    th:last-child,
    td:last-child {
      right: 0;
      position: sticky;
      z-index: 2;
      background: #fff;
      :after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 0;
        bottom: -1px;
        width: 30px;
        transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
        content: "";
        pointer-events: none;
        left: 0;
        transform: translateX(-100%);
      }
    }
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }

  .action-wrap {
    width: ${({ isSupperAdmin }) =>
      isSupperAdmin ? "calc(25rem - 3.2rem)" : "calc(12rem - 3.2rem)"};
    display: flex;
    flex-direction: row;
    column-gap: 1.6rem;
    margin: 0;
    justify-content: flex-end;
  }
`;

export const TableUserPolarisAccountAdmin = ({
  data,
  filter,
  setValuePage,
}) => {
  const { currentUser } = useAppContext();
  const isSupperAdmin = React.useMemo(() => {
    const email = _.get(currentUser, "email");

    return SUPPER_ADMIN.includes(email);
  }, [currentUser]);

  let newRows = [];
  if (data) {
    data.users.nodes.forEach((c) => {
      let dataUserRole = c.teamUser !== null ? c.teamUser : "null";
      let teamRole = dataUserRole === "null" ? null : dataUserRole.role;
      let isRole = teamRole;
      if (teamRole) {
        if (teamRole === TEAM_ROLE.Admin) {
          isRole = USER_ROLE.Seller;
        } else if (
          teamRole === USER_ROLE.Supplier &&
          c.roles.includes(USER_ROLE.Seller)
        ) {
          isRole = `Seller's ${USER_ROLE.Supplier}`;
        } else if (
          teamRole === USER_ROLE.Supporter &&
          c.roles.includes(USER_ROLE.Seller)
        ) {
          isRole = `Seller's ${USER_ROLE.Supporter}`;
        }
      } else {
        if (c.roles.includes(USER_ROLE.Supporter)) {
          isRole = `MB ${USER_ROLE.Supporter}`;
        } else if (c.roles.includes(USER_ROLE.Supplier)) {
          isRole = USER_ROLE.Supplier;
        } else {
          isRole = _.head(c.roles);
        }
      }

      // Team
      const { teams } = c || {};
      let teamName = _.get(c, "teamUser.team.name", "");
      let teamId = _.get(c, "teamUser.team.id", "");
      if (!teamName) {
        teamName = _.get(teams, "[0].name", "");
        teamId = _.get(teams, "[0].id", "");
      }

      newRows.push([
        <Avatar
          source={c.avatar ? c.avatar.thumbnailUrl : null}
          initials={
            c.firstName && c.firstName.length > 1
              ? c.firstName.substr(0, 1)
              : c.lastName.substr(0, 1)
          }
        />,
        <span>{c.firstName + " " + c.lastName}</span>,
        <span>{c.email}</span>,
        <span>{teamId}</span>,
        <span>{teamName}</span>,
        <span>{c.phone}</span>,
        <span>{c.address}</span>,
        <Tag color="blue">{isRole}</Tag>,
        <div className="action-wrap">
          {isSupperAdmin ? (
            <GetUserToken userId={c?.id} overrides={{ plain: true }} />
          ) : null}
          {c.status && (
            <DeleteUserAccountAdminPolaris user={c} filter={filter} />
          )}
          {!c.status && (
            <ActionUserAccountAdminPolaris user={c} filter={filter} />
          )}
        </div>,
      ]);
    });
  }
  let { limit, offset } = filter;
  const total = _.get(data, "users.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setValuePage,
    total,
  };
  return (
    <Container isSupperAdmin={isSupperAdmin}>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "Avatar",
          "Name",
          "Email",
          "Team ID",
          "Team Name",
          "Phone",
          "Address",
          "Role",
          "Actions",
        ]}
        rows={newRows}
        verticalAlign={"middle"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
