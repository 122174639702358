import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, TextContainer, Toast } from "@shopify/polaris";
import { gql } from "apollo-boost";
import get from "lodash/get";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../../context";
import { getParamByRole, handleError, isPME_TEAM } from "../../helper";
import history from "../../history";
import { usePrivileges } from "../../hooks/usePrivileges";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

const ACTIVE_MEMBER = gql`
  mutation activeUserTeam($id: ID!) {
    activeUserTeam(id: $id)
  }
`;

const DELETE_MEMBER = gql`
  mutation deleteUserTeam($id: ID!) {
    deleteUserTeam(id: $id)
  }
`;

export const ActionMemberPolaris = ({ member, refetch, hasActiveAcc }) => {
  const { currentUser } = React.useContext(AppContext);
  let currentParam = getParamByRole(currentUser);
  // const isAThien = isAccAThien(currentUser);
  // const isThuong = isAccThuong(currentUser);
  const isPMETeam = isPME_TEAM(currentUser);

  const { canEdit, canActiveOrDeactive } = usePrivileges();

  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const status = get(member, "user.status", false);
  const [activeUserTeam, { data, error, client, loading }] = useMutation(
    status ? DELETE_MEMBER : ACTIVE_MEMBER,
    {
      onCompleted: () => {
        refetch && refetch();
        handleModalChange();
      },
    }
  );

  const handleClick = useCallback(() => {
    history.push(`/${currentParam}/edit-team-member/${member.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalChange = useCallback(() => setOpen((open) => !open), []);
  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const toastMarkup = activeToast
    ? (error || data != null) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data != null && "Member has been update!"
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const newCanEdit = isPMETeam ? canEdit : true;
  const newCanActiveOrDeactive = isPMETeam ? canActiveOrDeactive : true;

  const child = (
    <Container style={{ padding: 9 }}>
      {newCanEdit && (
        <Button onClick={handleClick} plain>
          Edit
        </Button>
      )}
      {(hasActiveAcc || newCanActiveOrDeactive) && (
        <Button
          onClick={handleModalChange}
          plain
          children={status ? "Deactivate" : "Activate"}
        />
      )}
      <Modal
        title={status ? "Deactivate member" : "Activate member"}
        open={open}
        onClose={handleModalChange}
        primaryAction={{
          content: "Yes",
          loading: loading,
          onAction: () => {
            activeUserTeam({
              variables: { id: member.id },
            });
            toggleActive();
          },
        }}
        secondaryActions={[
          {
            content: "No",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Are you sure to {status ? "deactivate" : "activate"} this member?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Container>
  );
  return isPMETeam ? (canEdit || canActiveOrDeactive ? child : null) : child;
};
