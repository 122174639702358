import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import StoresAutoComplete from "../shared/StoresAutoComplete";
import _ from "lodash";

import { AppContext } from "../../context";
import { TEAM_ROLE } from "../../variable";

class PushProduct extends Component {
  static contextType = AppContext;
  state = {
    checked: this.props.checked || (this.props.isEditProduct && true),
  };

  render() {
    const { checked } = this.state;
    const { value, store, productId, isEditProduct, refetch } = this.props;
    const isStoreManager =
      _.get(this.context, "currentUser.teamUser.role") ===
      TEAM_ROLE.StoreManager;
    const isMarketplaceManager =
      _.get(this.context, "currentUser.teamUser.role") ===
      TEAM_ROLE.MarketplaceManager;
    return (
      <>
        {!isEditProduct && (
          <Checkbox
            onChange={(e) => {
              const c = e.target.checked;
              this.setState(
                {
                  checked: c,
                },
                () => {
                  if (!c) {
                    this.props.onChange([]);
                  }
                  if (c && store) {
                    this.props.onChange([store]);
                  }
                }
              );
            }}
            checked={checked}
          >
            Also push product to store
          </Checkbox>
        )}
        {((checked && !store) ||
          (isStoreManager && store) ||
          (isMarketplaceManager && store)) && (
          <div style={{ marginTop: 5 }}>
            <StoresAutoComplete
              productId={productId}
              refetch={refetch}
              isEditProduct={isEditProduct}
              onChange={(stores) => {
                this.props.onChange(stores);
              }}
              value={value ? value : []}
              isStoreManager={isStoreManager}
              isMarketplaceManager={isMarketplaceManager}
            />
          </div>
        )}
      </>
    );
  }
}

PushProduct.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default PushProduct;
