import React, { Component } from "react";
import PageTitle from "../../components/shared/PageTitle";
import AddDesignTask from "../../components/tasks/AddDesignTask";

export default class AddTaskPage extends Component {
  render() {
    return (
      <div>
        <PageTitle
          title="Add New Task"
          subTitle="Task"
          link="/seller/design-tasks"
        />
        <AddDesignTask redirect="/seller/design-tasks" />
      </div>
    );
  }
}
