import { Button, ButtonGroup, ChoiceList, Popover } from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { Fragment, useCallback, useMemo, useReducer } from "react";

// const Custom = "Custom";
const OPTIONS = [
  { value: "Today", label: "Today" },
  { value: "Yesterday", label: "Yesterday" },
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "This month", label: "This month" },
  { value: "Last month", label: "Last month" },
  // { value: Custom, label: Custom },
  { value: "All time", label: "All time" },
];

export function ActivatorTime({ setRange }) {
  // State
  const [state, setState] = useReducer(
    (data, newData) => ({
      ...data,
      ...newData,
    }),
    { label: ["Today"], active: false }
  );

  // Actions
  const toggleActive = useCallback(() => {
    setState({ active: !state.active });
  }, [state.active]);

  const handleFormatTime = useCallback(
    (selected) => {
      let range = {
        from: null,
        to: null,
      };
      if (selected[0]) {
        switch (selected[0]) {
          case "Today":
            range.from = moment().startOf("day");
            range.to = moment().endOf("day");
            break;
          case "Yesterday":
            range.from = moment().subtract(1, "days").startOf("day");
            range.to = moment().subtract(1, "days").endOf("day");
            break;
          case "Last 7 days":
            range.from = moment().subtract(7, "days").startOf("day");
            range.to = moment();
            break;
          case "This month":
            range.from = moment().startOf("month");
            range.to = moment();
            break;
          case "Last month":
            range.from = moment().subtract(1, "months").startOf("months");
            range.to = moment().subtract(1, "months").endOf("months");
            break;
          default:
            range = null;
            break;
        }
      }
      setRange(range);
    },
    [setRange]
  );

  const handleChoiceChange = useCallback(
    (selected) => {
      setState({
        label: selected,
      });

      handleFormatTime(selected);
    },
    [handleFormatTime]
  );

  // Markup
  const activator = useMemo(
    () => (
      <Button
        children={state.label}
        onClick={toggleActive}
        disclosure
        icon={CalendarMinor}
      />
    ),
    [toggleActive, state.label]
  );

  return (
    <Fragment>
      <ButtonGroup>
        <Popover
          activator={activator}
          active={state.active}
          onClose={toggleActive}
          sectioned
          fluidContent
        >
          <ChoiceList
            choices={OPTIONS}
            selected={state.label}
            onChange={handleChoiceChange}
          />
        </Popover>
      </ButtonGroup>
    </Fragment>
  );
}
