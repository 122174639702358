import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { handleError } from "../../helper";
import { LIST_SELLER_PRODUCTS_QUERY } from "../product/ProductsPolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { useToastContext } from "../shared/ToastContext";
import { MapOrderCTFormPolaris } from "./MapOrderCTFormPolaris";
import { DEFAULT_PRODUCT_LIST_FILTER } from "./MapOrderPolaris";

const Container = styled.div`
  .loading-wrap {
    margin: 0 -3rem;
  }
`;

const QUERY_TEMPLATE = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      total
      nodes {
        id
        name
        slug
        data {
          productBases {
            id
            title
            slug
            fulfillment {
              fulfillmentId
              fulfillmentTitle
            }
            attributes {
              name
              slug
              options
            }
            variants {
              id
              fulfillmentWarehouses
              attributes {
                name
                slug
                option
              }
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation mappingOrderFromTemplate($input: NewMappingTemplate!) {
    mappingOrderFromTemplate(input: $input) {
      id
      title
      sku
      description
      designStatus
      personalized
      status
      productBases {
        id
        title
      }
      images {
        id
        productBaseId
        file {
          id
          url
          thumbnailUrl
        }
      }
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      fields {
        id
        title
        name
        type
        extraFee
        configure
        sorting
      }
    }
  }
`;

export const MapOrderCTPolaris = (props) => {
  const [filter] = useState({
    limit: -1,
    offset: -1,
    product: false,
    grouping: true,
  });
  // Context
  const { toggleToast, setNotify } = useToastContext();

  const { data, loading, error, refetch } = useQuery(QUERY_TEMPLATE, {
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const timeoutRef = React.useRef(null);
  const [mappingOrderFromTemplate, { loading: loadingMutation, client }] =
    useMutation(MUTATION, {
      onCompleted: () => {
        setNotify({
          msg: "Map Order to campaign template success",
          err: false,
        });
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          props.onCompleted && props.onCompleted();
        }, 1000);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    });

  //
  React.useEffect(() => {
    if (props.onDismiss) {
      props.onDismiss(loadingMutation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMutation]);

  const handleSubmit = useCallback(
    (input) => {
      toggleToast && toggleToast();
      setNotify && setNotify({ msg: null, err: false });
      mappingOrderFromTemplate({
        variables: {
          input,
        },
      }).then((res) => {
        const product = res.mappingOrderFromTemplate;
        try {
          const variables = {
            filter: DEFAULT_PRODUCT_LIST_FILTER,
          };
          const cache = client.readQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
          });
          client.writeQuery({
            query: LIST_SELLER_PRODUCTS_QUERY,
            variables,
            data: {
              ...cache,
              products: {
                ...cache.products,
                total: cache.products.total + 1,
                nodes: [product, ...cache.products.nodes],
              },
            },
          });
        } catch (e) {}
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleToast, setNotify, mappingOrderFromTemplate],
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <Container>
      {loading ? (
        <div className="loading-wrap">
          <SkeletonPagePolaris />
        </div>
      ) : (
        <MapOrderCTFormPolaris
          data={data}
          refetch={refetch}
          {...props}
          onSubmit={handleSubmit}
          loading={loadingMutation}
        />
      )}
    </Container>
  );
};

export default MapOrderCTPolaris;