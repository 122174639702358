import React, { useState, useCallback } from "react";
import { ButtonGroup, Button } from "@shopify/polaris";

import { ExportTemplateRemoveActionPolaris } from "./ExportTemplateRemoveActionPolaris";
import { ExportTemplateCloneActionPolaris } from "./ExportTemplateCloneActionPolaris";

export const ExportTemplateActionsPolaris = ({
  item,
  refetch,
  hasActionsRemove,
  toggleActive,
  onNotificationChange,
}) => {
  // State
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const toggleShowModal = useCallback((action) => {
    setShowModal((prev) => !prev);
    setCurrentAction(action);
  }, []);

  return (
    <ButtonGroup>
      {hasActionsRemove ? (
        <Button children={"Delete"} onClick={() => toggleShowModal("remove")} />
      ) : (
        <Button children={"Clone"} onClick={() => toggleShowModal("clone")} />
      )}
      <ExportTemplateRemoveActionPolaris
        open={showModal && "remove" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        item={item}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
      />
      <ExportTemplateCloneActionPolaris
        open={showModal && "clone" === currentAction}
        toggleShowModal={toggleShowModal}
        refetch={refetch}
        item={item}
        onNotificationChange={onNotificationChange}
        toggleActive={toggleActive}
      />
    </ButtonGroup>
  );
};
