import { gql } from "apollo-boost";
import React from "react";
import { arrInvalid, objectInvalid, reducerFn } from "../../../helper";
import { useCustomQuery } from "../../../hooks/useCustomQuery";

const useUsersByRole = (role, fragment, skip) => {
  const queryOptions = React.useMemo(() => {
    return {
      skip,
      variables: {
        role,
      },
      query: gql`
        query listUserByRole($role: String!) {
          listUserByRole(role: $role) {
            ${fragment}
          }
        }
      `,
    };
  }, [fragment, skip, role]);

  return useCustomQuery({
    queryOptions,
    path: "data.listUserByRole",
  });
};

const getUsersMissing = async (userIds, currentIds, fragment) => {
  if (arrInvalid(userIds) || arrInvalid(currentIds)) return [];

  let idsMiss = [];
  for (const id of currentIds) {
    if (userIds.includes(id)) continue;
    idsMiss.push(id);
  }

  if (arrInvalid(idsMiss)) return [];

  async function getUsers(ids) {
    const { __apolloClient__: client } = window;
    if (!client) return [];

    try {
      const args = [];
      const queries = [];
      const variables = {};
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        args.push(`$id__${i}: ID!`);
        queries.push(`
          userByID__${i}: userByID(id: $id__${i}) {
            ...user
          }
        `);
        variables[`id__${i}`] = id;
      }

      const q = `
        query userByID(${args.join(",")}) {
          ${queries.join("\n")}
        }
      `;

      const query = gql`
        fragment user on User {
          ${fragment}
        }
        ${q}
      `;

      const res = await client.query({ query, variables });

      if (!objectInvalid(res?.data)) {
        return Object.values(res.data);
      }
    } catch (err) {}

    return [];
  }

  return await getUsers(idsMiss);
};

const useUsersByRoleFilter = ({
  value,
  skip,
  fragment = "id firstName lastName",
  role,
}) => {
  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    selectedOptions: [],
  });

  const { data, loading, error } = useUsersByRole(role, fragment, skip);
  const setDataRef = React.useRef(false);
  React.useEffect(() => {
    if (arrInvalid(data) || setDataRef.current) return;

    const fn = ({ id: value, firstName, lastName }) => ({
      value,
      label: [firstName, lastName].filter(Boolean).join(" "),
    });
    const options = data.map(fn);
    setState({ options });
    setDataRef.current = true;
  }, [data]);

  const optionsStr = JSON.stringify(state.options);
  React.useEffect(() => {
    const options = JSON.parse(optionsStr);
    if (arrInvalid(options) || arrInvalid(value)) return;

    const selectedOptions = options.filter(({ value: val }) =>
      value.includes(val)
    );
    setState({ selectedOptions });
  }, [optionsStr, value]);

  return React.useMemo(
    () => ({
      loading,
      error,
      options: state.options,
      selectedOptions: state.selectedOptions,
    }),
    [loading, error, state.options, state.selectedOptions]
  );
};

export { useUsersByRole, useUsersByRoleFilter, getUsersMissing };
