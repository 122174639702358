import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Autocomplete, Tag } from "@shopify/polaris";
import { isArray, get, head } from "lodash";
import styled from "styled-components";

import { GET_NICHES } from "../../../graphql/queries";
import { getUnique, handleError } from "../../../helper";
import { QuickAdd } from "./QuickAdd";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

export function NicheSelect({
    value,
    allowMultiple,
    onChange,
    label,
    labelHidden,
    getCoupleValue,
    haveQuickAdd,
    required,
    errorMessage,
    limit,
}) {
    // State
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState(null);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [showQuickAdd, setShowQuickAdd] = useState(false);

    const [filter, setFilter] = useState({
        limit: limit || 20,
        offset: 0,
        search: undefined,
    });

    const typingTimeoutRef = useRef(null);

    // Query
    const { data, loading, error } = useQuery(GET_NICHES, {
        variables: { filter },
        fetchPolicy: "network-only",
    });

    // Get data
    const buildNicheData = (input) => {
        let result = [];
        const buildData = (data, prefix = "") => {
            data.forEach((input) => {
                let build = {
                    label:
                        prefix && "" !== prefix
                            ? prefix + " " + input.name
                            : input.name,
                    value: input.id,
                };
                result.push(build);
                if (input && input.parent && input.parent.length > 0) {
                    buildData(input.parent, prefix + "--");
                }
            });
        };
        buildData(input, "");
        return result;
    };

    useEffect(() => {
        let newValue = [];
        let newData = [];
        if (value && isArray(value)) {
            newData = value.map((v) => {
                if (typeof v === "object") {
                    newValue.push(v.id);
                    return {
                        label: v.name,
                        value: v.id,
                    };
                } else {
                    newValue.push(v);
                    return null;
                }
            });
        }
        newValue = allowMultiple ? newValue : [value];
        if (newData.length && newData.filter(Boolean).length > 0) {
            setOptions((prevState) => {
                let result = getUnique([...prevState, ...newData], "value");
                return result;
            });
            setDeselectedOptions((prevState) => {
                let result = getUnique([...prevState, ...value], "id");
                return result;
            });
        }
        setSelectedOptions(newValue);
    }, [value, allowMultiple]);

    useEffect(() => {
        if (data?.niches?.nodes?.length > 0) {
            let result = buildNicheData(data.niches.nodes);
            setOptions(result);
        } else {
            setOptions([]);
        }
    }, [data]);

    // Actions
    const handleInputChange = useCallback((value) => {
        setInputValue(value);
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        typingTimeoutRef.current = setTimeout(() => {
            setFilter((prevState) => {
                return {
                    ...prevState,
                    search: value,
                };
            });
        }, 500);
    }, []);

    useEffect(() => {
        let result = showAdd(inputValue, options);
        setShowQuickAdd(result);
    }, [inputValue, options]);

    const showAdd = useCallback((inputValue, options) => {
        if (!inputValue) return;
        let matched = options.find(
            (option) => option.label?.toLowerCase() === inputValue.toLowerCase()
        );
        if (!options || !matched) return true;
        return false;
    }, []);

    const handleSelected = useCallback(
        (selected) => {
            const selectedValue = selected.map((selectedItem) => {
                const matchedOptions = options.find((option) => {
                    return option.value === selectedItem;
                });
                return matchedOptions && matchedOptions.label;
            });
            setSelectedOptions(selected);

            let newData = get(data, "niches.nodes", []);
            const buildFullData = (input) => {
                let result = [];
                const buildData = (data) => {
                    data.forEach((input) => {
                        result.push(input);
                        if (input && input.parent && input.parent.length > 0) {
                            buildData(input.parent);
                        }
                    });
                };
                buildData(input);
                return result;
            };
            newData = newData && buildFullData(newData);
            let couple =
                newData && newData.length > 0
                    ? newData.filter((d) => selected.includes(d.id))
                    : [];

            setDeselectedOptions((prevState) => {
                let result = getUnique([...prevState, ...couple], "id");
                result = result.filter((i) => selected.includes(i.id));
                return result;
            });

            if (onChange) {
                if (allowMultiple) {
                    onChange(getCoupleValue ? couple : selected);
                    return;
                } else {
                    onChange(head(selected));
                }
            }
            setInputValue(head(selectedValue));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options, data]
    );

    const handleRemove = useCallback(
        (value) => {
            let newSelected = selectedOptions.filter((s) => s !== value);
            let newDeselected = deselectedOptions.filter(
                (item) => item.id !== value
            );
            setSelectedOptions(newSelected);
            setDeselectedOptions(newDeselected);
            if (onChange && allowMultiple) {
                onChange(newSelected);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedOptions, deselectedOptions]
    );

    const handleAddCompleted = useCallback((data) => {
        if (data) {
            let item = {
                value: data.id,
                label: data.name,
            };
            let newSelected = [];
            let currentOptions = [];
            setOptions((prevState) => {
                currentOptions = [item, ...prevState];
                return currentOptions;
            });
            setSelectedOptions((prevState) => {
                newSelected = [item.value, ...prevState];
                if (onChange && allowMultiple) {
                    onChange(newSelected);
                }
                return newSelected;
            });

            setDeselectedOptions((prevState) => {
                let result = getUnique([...prevState, data], "id");
                result = result.filter((i) => newSelected.includes(i.id));
                return result;
            });

            setShowQuickAdd(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Markup
    const textField = (
        <Autocomplete.TextField
            onChange={handleInputChange}
            label={label ? label : "Parents niches"}
            labelHidden={true}
            value={inputValue}
            placeholder="Search for niches"
            error={errorMessage}
        />
    );

    if (error) return <div>Error: {handleError(error.toString())}</div>;

    const contentBeforeMarkup = haveQuickAdd && showQuickAdd && (
        <QuickAdd value={inputValue} onCompleted={handleAddCompleted} />
    );

    return (
        <div>
            {!labelHidden ? (
                <ComponentLabelPolaris
                    required={required}
                    label={label ? label : "Parents niche"}
                />
            ) : null}
            <Autocomplete.ComboBox
                options={options}
                selected={selectedOptions}
                onSelect={handleSelected}
                textField={textField}
                loading={loading}
                // willLoadMoreResults
                allowMultiple={allowMultiple}
                contentBefore={contentBeforeMarkup}
                emptyState={<span>No items found!</span>}
            />
            {allowMultiple &&
            deselectedOptions &&
            deselectedOptions.length > 0 ? (
                <TagWrapper>
                    {deselectedOptions.map((item) => (
                        <Tag
                            children={item.name}
                            onRemove={() => handleRemove(item.id)}
                            key={item.id}
                        />
                    ))}
                </TagWrapper>
            ) : null}
        </div>
    );
}

const TagWrapper = styled.div`
    display: flex;
    padding-top: 0.8rem;
    flex-wrap: wrap;
    > * {
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
    }
`;
