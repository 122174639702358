import React from "react";
import styled from "styled-components";

const Container = styled.div`
  .required {
    &:after {
      content: "*";
      color: red;
      margin-left: 0.25rem;
    }
  }
`;

export const ComponentLabelPolaris = ({ label, required, action }) => {
  const actionMarkup = React.useMemo(() => {
    if (!action || typeof action !== "object" || !action.content) return null;

    let btnCls = "Polaris-Button Polaris-Button--plain";
    if (action.disabled) {
      btnCls += " Polaris-Button--disabled";
    }

    return (
      <div className="Polaris-Labelled__Action">
        <button
          type="button"
          className={btnCls}
          onClick={action.onAction}
          disabled={!!action.disabled}
        >
          <span className="Polaris-Button__Content">
            <span className="Polaris-Button__Text">{action.content}</span>
          </span>
        </button>
      </div>
    );
  }, [action]);

  return (
    <Container>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label
            className={`Polaris-Label__Text ${required ? "required" : ""}`}
          >
            {label}
          </label>
        </div>
        {actionMarkup}
      </div>
    </Container>
  );
};
