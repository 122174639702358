import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Checkbox, Icon, Modal, Spinner, Toast } from "@shopify/polaris";
import { MaximizeMajorMonotone } from "@shopify/polaris-icons";
import { get, head } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { UPLOAD_ORDER_DESIGN } from "../../../graphql/mutations";
import { GET_ORDER_DESIGNS } from "../../../graphql/queries";
import { getDesignPositionName, handleError, reducerFn } from "../../../helper";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { FILE_SIZE_200MB, FILE_SIZE_50MB } from "../../../variable";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { UploadFileCtxProvider } from "../hooks/useUploadFile";
import {
  checkDimensions,
  checkDimensionsType,
  isChinaOrCustomFulfill,
} from "../_utils";
import { sortDataSource } from "./UpdateDesignPolaris";

const DimensionsNotify = React.lazy(() =>
  import("../components/DimensionsNotify"),
);

export const UploadDesignPolaris = (props) => {
  const {
    open,
    toggleShowModal,
    value,
    refetch,
    changeStatusCount,
    convertOS,
  } = props;
  const orderId = value && value.id;
  const productBaseId =
    value &&
    value.productVariant &&
    value.productVariant.productBaseVariant &&
    value.productVariant.productBaseVariant.productBase
      ? value.productVariant.productBaseVariant.productBase.id
      : value.product.productBases[0].id;

  const [cancelOtherTask, setCancelOtherTask] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [designPosition, setDesignPosition] = useState({});
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [maxSize, setMaxSize] = useState(FILE_SIZE_50MB);
  const [state, setState] = React.useReducer(reducerFn, {
    disableSubmit: false,
    dimensions: {},
    currentDesignId: {},
    checkingDimensions: {},
  });

  React.useEffect(() => {
    if (!value || !value.id || !open) return;

    (async () => {
      let maxSize = FILE_SIZE_50MB;
      const isChinaOrCF = await isChinaOrCustomFulfill(value.id);
      if (isChinaOrCF) {
        maxSize = FILE_SIZE_200MB;
      }

      setMaxSize(maxSize);
    })();
  }, [value?.id, open]);

  useLockBodyScroll(open);

  const [getOrderDesigns, { data }] = useLazyQuery(GET_ORDER_DESIGNS, {
    fetchPolicy: "no-cache",
  });
  const [uploadOrderDesign, { data: dataM, loading: loadingM, error: errorM }] =
    useMutation(UPLOAD_ORDER_DESIGN, {
      onError: () => {},
      onCompleted: () => {
        const id = setTimeout(() => {
          toggleShowModal(false);
          if (changeStatusCount) {
            changeStatusCount(
              convertOS && [convertOS.PendingDesign, convertOS.Pending],
            );
          }
          if (refetch) {
            refetch();
          }
        }, 2100);
        setTimeoutId(id);
      },
    });

  useEffect(() => {
    if (orderId && productBaseId && open) {
      getOrderDesigns({
        variables: {
          id: orderId,
          productBaseId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, productBaseId, open]);

  useEffect(() => {
    let newData = data?.getOrderDesigns?.orderDesigns || [];

    newData = sortDataSource(newData);
    setDataSource(newData);
  }, [data]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof state.dimensions !== "object") return;

    const val = checkDimensionsType(state.dimensions);
    setState({ disableSubmit: val });
  }, [state.dimensions]);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const handleSubmit = useCallback(
    (setLoading) => () => {
      setLoading(true);
      let newDS = dataSource;
      let input =
        newDS && newDS.length > 0
          ? newDS
              .map((ds, index) => {
                if (designPosition[index][0]) {
                  return {
                    designPositionId: ds.designPosition.id,
                    fileId: designPosition[index][0].id,
                  };
                }
                return {
                  designPositionId: ds.designPosition.id,
                  fileId: null,
                };
              })
              .filter(Boolean)
          : [];

      uploadOrderDesign({
        variables: {
          id: orderId,
          input,
          cancelOtherTask,
        },
      }).finally(() => {
        setLoading(false);
      });
      toggleActive();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [designPosition, dataSource, cancelOtherTask],
  );

  const handleDesignChange = useCallback(
    (index) => (value) => {
      setDesignPosition((prevState) => ({
        ...prevState,
        [index]: value,
      }));

      let fileId = null;
      const dimensions = {};
      if (value?.length > 0) {
        fileId = value[0] ? value[0].id : null;
      } else {
        dimensions[index] = null;
      }

      const currentDesignId = {
        ...state.currentDesignId,
        [index]: fileId,
      };

      setState((p) => {
        return {
          ...p,
          currentDesignId,
          dimensions: {
            ...p.dimensions,
            ...dimensions,
          },
        };
      });
    },
    [state.currentDesignId],
  );

  const checkDimensionsRef = React.useRef(null);
  const handleCheckDimensions = useCallback(
    (index) => (value) => {
      checkDimensionsRef.current && clearTimeout(checkDimensionsRef.current);
      checkDimensionsRef.current = setTimeout(() => {
        if (value === true) {
          setState((p) => ({ ...p, disableSubmit: false }));
          return;
        }

        setState((p) => {
          const res = checkDimensions({
            dimensions: value,
            index,
            designId: p.currentDesignId,
          });

          return {
            ...p,
            dimensions: { ...p.dimensions, ...res },
          };
        });
      }, 150);
    },
    [],
  );

  const handleCheckingDimensions = useCallback(
    (index) => (value) => {
      setState({
        checkingDimensions: { ...state.checkingDimensions, [index]: value },
      });
    },
    [state.checkingDimensions],
  );

  const toastMarkup = activeToast
    ? (errorM || (dataM && dataM.uploadOrderDesign)) && (
        <Toast
          content={
            errorM
              ? handleError(errorM.toString())
              : dataM && dataM.uploadOrderDesign && `Upload design success.`
          }
          error={errorM}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Main image
  const product = get(value, "product", null);
  const mainImageId = product?.mainImageId;
  const images = product?.images;
  let mainImages = [];
  const isCampaign = product?.productBases?.length > 1;
  const haveMainImageId = mainImageId || mainImageId !== "undefined";

  if (productBaseId && isCampaign) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.productBaseId === productBaseId)
            .map((item) => item.file)
        : [];
    if (mainImages.length > 1 && haveMainImageId) {
      mainImages =
        images?.length > 0
          ? images
              .filter((img) => img?.file?.id === mainImageId)
              .map((item) => item.file)
          : [];
    }
  } else if (haveMainImageId) {
    mainImages =
      images?.length > 0
        ? images
            .filter((img) => img?.file?.id === mainImageId)
            .map((item) => item.file)
        : [];
  }
  if (mainImages.length === 0) {
    mainImages =
      images?.length > 0 ? images.filter(Boolean).map((item) => item.file) : [];
  }

  let firstImage = mainImages.length && head(mainImages);

  let originUrl = firstImage
    ? firstImage.url
      ? firstImage.url
      : firstImage.thumbnailUrl
      ? firstImage.thumbnailUrl
      : null
    : null;

  let originThumbnail = firstImage
    ? firstImage.thumbnailUrl
      ? firstImage.thumbnailUrl
      : firstImage.url
      ? firstImage.url
      : null
    : null;

  return (
    <>
      {toastMarkup}
      <UploadFileCtxProvider>
        {(loading, setLoading) => {
          return (
            <Modal
              title="Upload design"
              open={open}
              onClose={toggleShowModal}
              sectioned
              secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
              ]}
              primaryAction={{
                content: "OK",
                onAction: handleSubmit(setLoading),
                loading: loadingM,
                disabled: loading,
              }}
            >
              <Container id="upload-design_modal">
                <div>
                  <Checkbox
                    label="Cancel related Tasks"
                    checked={cancelOtherTask}
                    onChange={() => setCancelOtherTask((prev) => !prev)}
                  />
                </div>
                <div className="content_wrap">
                  <div className="thumbnail_wrap">
                    <label>Thumbnail</label>
                    <div className="thumbnail_content">
                      <img src={originThumbnail} alt="" />
                      <div className="view-origin">
                        <div className="img-wrap">
                          <CustomLinkPolaris href={originUrl}>
                            <Icon source={MaximizeMajorMonotone}></Icon>
                          </CustomLinkPolaris>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="design-position_wrap">
                    <div className="design-position_inner">
                      {loading ? (
                        <div className="loading_wrap">
                          <Spinner />
                        </div>
                      ) : (
                        <>
                          {dataSource?.length > 0
                            ? dataSource.map((ds, idx) => {
                                const dsPo = get(ds, "designPosition");
                                let displayName = getDesignPositionName(dsPo);

                                const currentMsg =
                                  state.checkingDimensions[idx];
                                const currentDimension = state.dimensions[idx];

                                return (
                                  <div
                                    key={idx}
                                    className="design-position-content"
                                  >
                                    <label>{displayName}</label>
                                    <div className="design-position_item">
                                      <MediaSelectorButtonPolaris
                                        value={ds && (ds.file ? [ds.file] : [])}
                                        isPrintFile={true}
                                        prefix="print_files/"
                                        folder={"undefined"}
                                        singleUpload={true}
                                        multiple={false}
                                        maxSize={maxSize}
                                        // onChange={(value) =>
                                        //   setDesignPosition((prevState) => ({
                                        //     ...prevState,
                                        //     [idx]: value,
                                        //   }))
                                        // }

                                        onChange={handleDesignChange(idx)}
                                        isCompareDesign
                                        designPosition={dsPo}
                                        onCheckDimensions={handleCheckDimensions(
                                          idx,
                                        )}
                                        onCheckingDimensions={handleCheckingDimensions(
                                          idx,
                                        )}
                                      />
                                    </div>
                                    <SuspenseComp
                                      fallback={<Spinner size="small" />}
                                    >
                                      <DimensionsNotify
                                        loading={currentMsg}
                                        msg={currentDimension?.message}
                                        type={currentDimension?.type}
                                      />
                                    </SuspenseComp>
                                    <div className="design-position-info">
                                      {ds.designPosition &&
                                        ds.designPosition.description && (
                                          <span>{`(${ds.designPosition.description})`}</span>
                                        )}
                                      {ds.designPosition.artworkGuidelines &&
                                      ds.designPosition.artworkGuidelines
                                        .length ? (
                                        <div>
                                          <strong
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            Artguides:{" "}
                                          </strong>
                                          {ds.designPosition.artworkGuidelines.map(
                                            (ag, idx) => (
                                              <div
                                                key={`artworkGuidelines-${idx}`}
                                                className="artwork_inner"
                                              >
                                                <CustomLinkPolaris
                                                  href={`${
                                                    ag.file.url ||
                                                    ag.file.thumbnailUrl
                                                  }`}
                                                >
                                                  {` ${ag.file.name} `}
                                                </CustomLinkPolaris>
                                                {idx !==
                                                ds.designPosition
                                                  .artworkGuidelines.length -
                                                  1
                                                  ? "|"
                                                  : ""}
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                    {dataSource.length > 1 &&
                                      ds.designPosition.name.toLowerCase() ===
                                        "master" && (
                                        <div
                                          style={{
                                            marginTop: "1rem",
                                          }}
                                        >
                                          Or
                                        </div>
                                      )}
                                  </div>
                                );
                              })
                            : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </Modal>
          );
        }}
      </UploadFileCtxProvider>
      {/* <Modal
        title="Upload design"
        open={open}
        onClose={toggleShowModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
        primaryAction={{
          content: "OK",
          onAction: handleSubmit,
          loading: loadingM,
        }}
      >
        <Container id="upload-design_modal">
          <div>
            <Checkbox
              label="Cancel related Tasks"
              checked={cancelOtherTask}
              onChange={() => setCancelOtherTask((prev) => !prev)}
            />
          </div>
          <div className="content_wrap">
            <div className="thumbnail_wrap">
              <label>Thumbnail</label>
              <div className="thumbnail_content">
                <img src={originThumbnail} alt="" />
                <div className="view-origin">
                  <div className="img-wrap">
                    <CustomLinkPolaris href={originUrl}>
                      <Icon source={MaximizeMajorMonotone}></Icon>
                    </CustomLinkPolaris>
                  </div>
                </div>
              </div>
            </div>
            <div className="design-position_wrap">
              <div className="design-position_inner">
                {loading ? (
                  <div className="loading_wrap">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {dataSource?.length > 0
                      ? dataSource.map((ds, idx) => {
                          const dsPo = get(ds, "designPosition");
                          let dpName = get(dsPo, "name");
                          return (
                            <div key={idx} className="design-position-content">
                              <label>{dpName}</label>
                              <div className="design-position_item">
                                <MediaSelectorButtonPolaris
                                  value={ds && (ds.file ? [ds.file] : [])}
                                  isPrintFile={true}
                                  prefix="print_files/"
                                  folder={"undefined"}
                                  singleUpload={true}
                                  multiple={false}
                                  onChange={(value) =>
                                    setDesignPosition((prevState) => ({
                                      ...prevState,
                                      [idx]: value,
                                    }))
                                  }
                                />
                              </div>
                              <div className="design-position-info">
                                {ds.designPosition &&
                                  ds.designPosition.description && (
                                    <span>{`(${ds.designPosition.description})`}</span>
                                  )}
                                {ds.designPosition.artworkGuidelines &&
                                ds.designPosition.artworkGuidelines.length ? (
                                  <div>
                                    <strong
                                      style={{
                                        display: "inline-block",
                                      }}
                                    >
                                      Artguides:{" "}
                                    </strong>
                                    {ds.designPosition.artworkGuidelines.map(
                                      (ag, idx) => (
                                        <div
                                          key={`artworkGuidelines-${idx}`}
                                          className="artwork_inner"
                                        >
                                          <CustomLinkPolaris
                                            href={`${
                                              ag.file.url ||
                                              ag.file.thumbnailUrl
                                            }`}
                                          >
                                            {` ${ag.file.name} `}
                                          </CustomLinkPolaris>
                                          {idx !==
                                          ds.designPosition.artworkGuidelines
                                            .length -
                                            1
                                            ? "|"
                                            : ""}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              {dataSource.length > 1 &&
                                ds.designPosition.name.toLowerCase() ===
                                  "master" && (
                                  <div
                                    style={{
                                      marginTop: "1rem",
                                    }}
                                  >
                                    Or
                                  </div>
                                )}
                            </div>
                          );
                        })
                      : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Modal> */}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .content_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    .thumbnail_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 50%;
      label {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
    .thumbnail_content {
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 0.75rem;
      position: relative;
      &:hover {
        .view-origin {
          opacity: 1;
        }
      }
      .view-origin {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: right;
        padding-top: 1rem;
        padding-right: 1rem;
        background: linear-gradient(
          180deg,
          rgba(33, 43, 54, 0.55),
          hsla(0, 0%, 100%, 0)
        );
        opacity: 0;
        transition: 0.3s opacity ease-out;
        .img-wrap {
          display: inline-block;
        }
        .Polaris-Icon {
          svg {
            fill: hsla(0, 0%, 100%, 0.8);
          }
        }
      }
    }
      img {
        width: 100%;
        height: 28rem;
        object-fit: cover;
        border-radius: 3px;
        box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px 2px rgba(0, 0, 0, 0.1);
      }
    }
    .design-position_wrap {
      width: 50%;
      .design-position_item {
        display: flex;
      }
      .design-position_inner {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
        .design-position-content + .design-position-content {
          border-top: var(
            --p-thin-border-subdued,
            0.1rem solid var(--p-border-subdued, #dfe3e8)
          );
          padding-top: 1rem;
        }
        .loading_wrap {
          display: flex;
          justify-content: center;
        }
        label {
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 1.09rem;
          display: inline-block;
        }
        .artwork_inner {
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .design-position-info {
          display: flex;
          flex-direction: column;
          row-gap: 0.75rem;
          margin-top: 1rem;
          > div {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
          }
        }
      }
    }
  }
`;
