import { gql } from "apollo-boost";
import { FileFragment } from "./file";

export const TeamUserFragment = gql`
    fragment TeamUserFragment on TeamUser {
        id
        role
        createdAt
        updatedAt
        status
    }
`;

export const UserFragment = gql`
    fragment UserFragment on User {
        id
        firstName
        lastName
        email
        roles
        phone
        status
        verified
        address
        avatar {
            ...FileFragment
        }
        createdAt
        updatedAt
        teamUser {
            ...TeamUserFragment
        }
    }
    ${TeamUserFragment}
    ${FileFragment}
`;
