import React, { useState, useContext } from "react";
import { Page, Loading, Card } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import styled from "styled-components";

import { handleError } from "../../helper";
import { AppContext } from "../../context";
import { TEAM_ROLE } from "../../variable";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../components/shared/EmptyStatePolaris";
import { LOAD_STORES } from "../../components/store/ListStorePolaris";
import { StoresFilterPolaris } from "../../components/store/StoresFilterPolaris";
import { TableStoresPolaris } from "../../components/store/TableStoresPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const StoresPolaris = () => {
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
    status: null,
    platform: null,
    search: null,
  });
  const { currentUser } = useContext(AppContext);
  const role = _.get(currentUser, "teamUser.role", null);
  const userId = _.get(currentUser, "id", null);
  const isStoreManager = [TEAM_ROLE.StoreManager].includes(role);

  const { data, loading, error } = useQuery(LOAD_STORES, {
    variables: {
      filter,
    },
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Page title="All Stores" fullWidth>
        <Card sectioned>
          <StoresFilterPolaris
            isStoreManager={isStoreManager}
            filter={filter}
            onChange={(rest) => {
              setFilter((prevState) => {
                return {
                  ...prevState,
                  ...rest,
                };
              });
            }}
          />
        </Card>
        <Card>
          {error && <div>Error: {handleError(error.toString())}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data &&
            data.stores &&
            data.stores.nodes &&
            data.stores.nodes.length > 0 ? (
            <TableStoresPolaris
              data={data}
              isStoreManager={isStoreManager}
              userId={userId}
              filters={filter}
              setFilters={(v) =>
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    offset: v,
                  };
                })
              }
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
