import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Stack, TextStyle } from "@shopify/polaris";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REPORT_COUNT_TASK_SUBMISSION_BY_CHECKED } from "../../../graphql/queries";
import { checkRole, getUnique, objectInvalid } from "../../../helper";
import { useReportsContext } from "../context";
import { CardBox } from "./CardBox";
import { ComparedPercent } from "./ComparedPercent";
import { useAppContext } from '../../../context'

const collapseKey = "trackTaskSubmission";

export function TrackTaskSubmission() {
  // Context
  const {
    range,
    isCompare,
    rangeToCompare,
    loading: loadingRoot,
  } = useReportsContext();

  const { currentUser } = useAppContext();
  const { isTeamRoot, isSeller } = checkRole(currentUser);

  // State
  const [filter, setFilter] = useState({
    limit: 20,
    paged: 1,
    time: range,
  });

  const [state, setState] = useState({
    items: [],
    total: 0,
    totalAccepted: 0,
    totalRejected: 0,
  });
  const [stateCompare, setStateCompare] = useState({
    totalRejected: 0,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [cantLoad, setCantLoad] = useState(false);
  const [mergedLoading, setMergedLoading] = useState(true);
  const timeoutRef = useRef(null);

  // Queries
  const { data, loading, error, fetchMore } = useQuery(
    REPORT_COUNT_TASK_SUBMISSION_BY_CHECKED,
    {
      variables: {
        filter,
      },
    },
  );

  const [
    lazyReport,
    { data: dataC, loading: loadingC, error: errorC },
  ] = useLazyQuery(REPORT_COUNT_TASK_SUBMISSION_BY_CHECKED);

  // Get data
  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      time: range,
    }));
    setCantLoad(false);
  }, [range]);

  useEffect(() => {
    const nodes = data?.reportCountTaskSubmissionByChecked;
    let total = 0;
    let totalRejected = 0;
    let totalAccepted = 0;

    const newItems =
      nodes?.length > 0
        ? nodes
            .map((node) => {
              if (!node || objectInvalid(node)) return null;
              const {
                checker,
                totalCheckTimes,
                totalRejectedTimes,
                totalAcceptedTimes,
              } = node;

              total += totalCheckTimes;
              totalRejected += totalRejectedTimes;
              totalAccepted += totalAcceptedTimes;
              return {
                checker,
                total: totalCheckTimes,
                totalRejected: totalRejectedTimes,
                totalAccepted: totalAcceptedTimes,
              };
            })
            .filter(Boolean)
        : [];
    setState((prev) => ({
      ...prev,
      items: newItems,
      total,
      totalRejected,
      totalAccepted,
    }));
  }, [data]);

  useEffect(() => {
    if (isCompare) {
      let time = null;
      if (rangeToCompare?.from != null) {
        time = {
          ...filter.time,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            time,
          },
        },
      });
    }
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const nodes = dataC?.reportCountTaskSubmissionByChecked || [];
      let total = nodes.reduce((acc, item) => {
        if (!item || objectInvalid(item)) return acc;
        acc += item.totalCheckTimes;

        return acc;
      }, 0);

      setStateCompare((prev) => ({
        ...prev,
        total,
      }));
    } else {
      setStateCompare({ total: 0 });
    }
  }, [dataC, isCompare]);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef);
    }
    if (!cantLoad) {
      setLoadMore(true);
      timeoutRef.current = setTimeout(() => {
        fetchMore({
          variables: {
            filter: {
              ...filter,
              offset: data.reportCountTaskSubmissionByChecked.length,
            },
          },
          updateQuery: (prev, { fetchMoreResult, variables }) => {
            if (!fetchMoreResult) return prev;
            const reportCountTaskSubmissionByChecked =
              fetchMoreResult.reportCountTaskSubmissionByChecked;
            const nodes = reportCountTaskSubmissionByChecked || [];

            const filter = variables.filter;
            const limit = filter.limit;

            if (nodes.length < limit) {
              setCantLoad(() => true);
            }
            setLoadMore(false);
            const newNodes = [
              ...prev.reportCountTaskSubmissionByChecked,
              ...nodes,
            ];
            const result = getUnique(newNodes, "userId");

            return {
              ...prev,
              reportCountTaskSubmissionByChecked: {
                ...prev.reportCountTaskSubmissionByChecked,
                nodes: result,
              },
            };
          },
        });
      }, 500);
    }
  }, [data, filter, cantLoad, fetchMore]);

  // Markup
  const contentMarkup = useMemo(() => {
    return state.items?.length > 0
      ? state.items.map((item, index) => {
          return (
            <Stack
              key={`checker-${index}`}
              distribution="equalSpacing"
              wrap={false}
            >
              <span className="index-wrap">{index + 1}</span>
              <Stack.Item fill>
                <span style={{ wordBreak: "break-all" }}>{item.checker}</span>
              </Stack.Item>
              <span style={{ width: 105, display: "inline-block" }}>
                {item.totalAccepted}
              </span>
              <span style={{ width: 105, display: "inline-block" }}>
                {item.totalRejected}
              </span>
              <span style={{ width: 50, display: "inline-block" }}>
                {item.total}
              </span>
            </Stack>
          );
        })
      : null;
  }, [state.items]);

  const headMarkup = (
    <Stack distribution="equalSpacing" wrap={false}>
      <span className="index-wrap">
        <TextStyle variation="strong">#</TextStyle>
      </span>
      <Stack.Item fill>
        <span style={{ wordBreak: "break-all" }}>
          <TextStyle variation="strong">Checker</TextStyle>
        </span>
      </Stack.Item>
      <span style={{ width: 105, display: "inline-block" }}>
        <TextStyle variation="strong">Total Accepted</TextStyle>
      </span>
      <span style={{ width: 105, display: "inline-block" }}>
        <TextStyle variation="strong">Total Rejected</TextStyle>
      </span>
      <span style={{ width: 50, display: "inline-block" }}>
        <TextStyle variation="strong">Total</TextStyle>
      </span>
    </Stack>
  );

  const subTitleMarkup = useMemo(
    () => (
      <span>
        <span>{state.total} Total Checked Times</span>
        <ComparedPercent
          originalVal={state.total}
          newVal={stateCompare.total}
        />
      </span>
    ),
    [state.total, stateCompare.total],
  );

  if (isSeller && !isTeamRoot) return null;
  return (
    <CardBox
      title="Track Task Submission"
      subTitle={subTitleMarkup}
      headMarkup={headMarkup}
      contentMarkup={contentMarkup}
      filter={filter}
      total={state.total}
      loading={mergedLoading}
      error={error || errorC}
      setFilter={setFilter}
      handleScroll={handleScroll}
      loadMore={loadMore}
      collapseKey={collapseKey}
    />
  );
}
