import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";

import { handleError } from "../../helper";

export const OwnersPolaris = ({ listUser, error, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  useEffect(() => {
    const newData = [];
    listUser &&
      listUser.listUserTeamByRoles &&
      listUser.listUserTeamByRoles.length &&
      listUser.listUserTeamByRoles.map((u) => {
        let item = {};
        item["label"] = u && u.user && `${u.user.firstName} ${u.user.lastName}`;
        item["value"] = u && u.user && u.user.id;
        newData.push(item);
        return u;
      });
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [listUser]);

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelectionChange = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedOptions(selected);
      if (onChange) {
        onChange(selected);
      }

      let iv = "";
      if (selectedValue && selectedValue.length) {
        if (selectedValue.length > 1) {
          iv = _.join(_.reverse(selectedValue), " & ");
        } else {
          iv = _.head(selectedValue);
        }
      }
      setInputValue(iv);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      label="Choose store managers"
      value={inputValue}
      placeholder="Search owners"
      onChange={handleInputChange}
    />
  );

  if (error) return <div>Error: {handleError(error.toString())}</div>;

  return (
    <Autocomplete
      allowMultiple
      options={options}
      selected={selectedOptions}
      textField={textField}
      onSelect={handleSelectionChange}
      emptyState={<span>No items found</span>}
    />
  );
};
