import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Card, Collapsible, Spinner, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { REPORT_SALE_CHANEL_OVERVIEW } from "../../../graphql/queries";
import { formatter, handleError } from "../../../helper";
import { useReportsContext } from "../context";
import { ComparedPercent } from "./ComparedPercent";
import { useCollapseContext } from "./LayoutSection";
import { LineChart } from "./LineChart";
import { PieChart } from "./PieChart";

const collapseKey = "overviews";

export function SaleChannelsOverviews({ saleChanel, isPie = false }) {
  // Context
  const {
    range,
    filter: filterCtx,
    rangeToCompare,
    isCompare,
    loading: loadingRoot,
    teamID,
  } = useReportsContext();
  const { collapseActive, setCollapseActive } = useCollapseContext();
  const open = !!collapseActive[collapseKey];

  const btnRef = useRef(null);

  // State
  const [filter, setFilter] = useState({
    saleChanel,
    filterByTime: range,
    productBaseIds: null,
    collectionIds: null,
    tagIds: null,
    storeID: null,
    storeManagerID: null,
  });
  const [state, setState] = useState({
    revenues: 0,
    totalUnits: 0,
    totalOrders: 0,
  });
  const [stateCompare, setStateCompare] = useState(null);
  const [mergedLoading, setMergedLoading] = useState(true);

  // Queries
  const { data, loading, error } = useQuery(REPORT_SALE_CHANEL_OVERVIEW, {
    variables: {
      filter,
    },
  });

  const [lazyReport, { data: dataC, loading: loadingC, errorC }] = useLazyQuery(
    REPORT_SALE_CHANEL_OVERVIEW
  );

  useEffect(() => {
    const mergedLoading = loading || loadingC || loadingRoot;
    setMergedLoading(mergedLoading);
  }, [loading, loadingC, loadingRoot]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      filterByTime: range,
      teamId: teamID ? teamID : undefined,
    }));
  }, [range, teamID]);

  useEffect(() => {
    if (filterCtx != null) {
      setFilter((prev) => ({ ...prev, ...filterCtx }));
    }
  }, [filterCtx]);

  useEffect(() => {
    if (isCompare) {
      let filterByTime = null;
      if (rangeToCompare?.from != null) {
        filterByTime = {
          ...filter.filterByTime,
          ...rangeToCompare,
        };
      }
      lazyReport({
        variables: {
          filter: {
            ...filter,
            filterByTime,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeToCompare, filter, isCompare]);

  useEffect(() => {
    if (isCompare) {
      const report = dataC?.reportSaleChanelOverview;
      if (report == null) return;

      const result = {};
      result.revenues = report.revenues;
      result.totalUnits = report.totalUnits;
      result.totalOrders = report.totalOrders;
      result.totalBaseCost = report.totalBaseCost;
      setStateCompare((prev) => {
        const prevVal = isCompare ? { ...prev, ...result } : null;
        return prevVal;
      });
    } else {
      setStateCompare(null);
    }
  }, [dataC, isCompare]);

  // Get data
  useEffect(() => {
    const report = data?.reportSaleChanelOverview;
    if (report == null) return;

    const result = {};
    result.revenues = report.revenues;
    result.totalUnits = report.totalUnits;
    result.totalOrders = report.totalOrders;
    result.totalBaseCost = report.totalBaseCost;
    setState((prev) => ({ ...prev, ...result }));
  }, [data]);

  const chartMarkup = isPie ? (
    <Stack vertical spacing="tight">
      <PieChart data={data} dataCompare={dataC} />
      <LineChart data={data?.reportSaleChanelOverview?.chartValues || []} />
    </Stack>
  ) : (
    <LineChart data={data?.reportSaleChanelOverview?.chartValues || []} />
  );

  const handleToggle = useCallback(() => {
    setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
  }, [collapseKey, setCollapseActive]);
  const handleTriggerBtn = useCallback(() => {
    btnRef.current && btnRef.current.click();
  }, []);

  let mergedError = error || errorC;
  return (
    <Card>
      <Card.Header
        title={
          <HeadingWrap>
            <span className="btn-wrap">
              <CaretRightOutlined
                rotate={open ? 90 : 0}
                style={{ color: "#000" }}
                ref={btnRef}
                onClick={handleToggle}
              />
            </span>
            <h2 className="Polaris-Heading" onClick={handleTriggerBtn}>
              Sale channels overviews
            </h2>
          </HeadingWrap>
        }
      />
      <Card.Section>
        <Collapsible
          open={open}
          id={collapseKey}
          transition={{
            duration: "500ms",
            timingFunction: "ease-in-out",
          }}
          expandOnPrint
        >
          {mergedLoading ? (
            <Stack distribution="center">
              <Spinner size="small" />
            </Stack>
          ) : mergedError ? (
            <div>Error: {handleError(mergedError.toString())}</div>
          ) : (
            <Stack spacing="loose" vertical>
              <Stack spacing="loose">
                <Stack.Item fill>
                  <Stack vertical spacing="tight">
                    <TextStyle>
                      Revenues:{" "}
                      <TextStyle variation="strong">
                        {formatter.format(state.revenues)}
                      </TextStyle>
                      <ComparedPercent
                        originalVal={state.revenues}
                        newVal={stateCompare?.revenues}
                      />
                    </TextStyle>
                    <TextStyle>
                      Total Cost:{" "}
                      <TextStyle variation="strong">
                        {formatter.format(state.totalBaseCost)}
                      </TextStyle>
                      <ComparedPercent
                        originalVal={state.totalBaseCost}
                        newVal={stateCompare?.totalBaseCost}
                      />
                    </TextStyle>
                  </Stack>
                </Stack.Item>
                <Stack vertical spacing="tight">
                  <span>
                    <span>{state.totalUnits} Line items</span>
                    <ComparedPercent
                      originalVal={state.totalUnits}
                      newVal={stateCompare?.totalUnits}
                    />
                  </span>
                  <span>
                    <span>{state.totalOrders} Orders</span>
                    <ComparedPercent
                      originalVal={state.totalOrders}
                      newVal={stateCompare?.totalOrders}
                    />
                  </span>
                </Stack>
              </Stack>
              {chartMarkup}
            </Stack>
          )}
        </Collapsible>
      </Card.Section>
    </Card>
  );
}

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.4rem;

  .Polaris-Heading {
    cursor: pointer;
  }

  .btn-wrap {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
  }
`;
