import { useQuery } from "@apollo/react-hooks";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterHasSearchMVPolaris } from "../../components/filters/FilterHasSearchMVPolaris";
import { FilterHasSearchPolaris } from "../../components/filters/FilterHasSearchPolaris";
import { FilterListRadioPolaris } from "../../components/filters/FilterListRadioPolaris";
import { FilterNoSearchPolaris } from "../../components/filters/FilterNoSearchPolaris";
import { LIST_TEAM } from "../../components/members/TeamMembersPolaris";
import { FilterCreatedTimePolaris } from "../../components/product/FilterCreatedTimePolaris";
import { FilterProductByDivisionPolaris } from "../../components/product/FilterProductByDivisionPolaris";
import { useAppContext } from "../../context";
import {
  appliedFilter,
  checkRole,
  formatDataTree,
  genLabelTree,
  isEmpty,
} from "../../helper";
import { TEAM_ROLE } from "../../variable";
import FilterProductBasePolaris from "./FilterProductBasePolaris";
import { FilterProductDesignStatusPolaris } from "./FilterProductDesignStatusPolaris";
import { FilterProductTypePolaris } from "./FilterProductTypePolaris";
import FilterStorePolaris from "./FilterStorePolaris";

export const LIST_PRODUCT_BASE = gql`
  query productBasesForSeller {
    productBasesForSeller(filter: { limit: 1000, offset: 0 }) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

const LIST_STORE = gql`
  query stores {
    stores(filter: { limit: 3000, offset: 0 }) {
      total
      nodes {
        id
        title
      }
    }
  }
`;

export const COLLECTIONS_QUERY = gql`
  fragment cat on Collection {
    id
    name
  }
  query collections($filter: CollectionFilter) {
    collections(filter: $filter) {
      total
      nodes {
        ...cat
        children {
          ...cat
          children {
            ...cat
            children {
              ...cat
              children {
                ...cat
              }
            }
          }
        }
      }
    }
  }
`;

export const TAGS_QUERY = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      total
      nodes {
        id
        name
      }
    }
  }
`;

// All domains
const QUERY_DOMAINS = gql`
  query getAllDomain {
    getAllDomain
  }
`;

const SORTS = [
  { value: "DESC", label: "DESC" },
  { value: "ASC", label: "ASC" },
];

const createAtLabel = "Created At";
const totalOrderLabel = "Total Orders";

const SORT_BY = [
  { value: "total_orders", label: totalOrderLabel },
  { value: "created_at", label: createAtLabel },
];

const CREATED_VIA = [
  {
    value: "All",
    label: "All",
  },
  { value: "IdeaTask", label: "Idea Task" },
  { value: "Crawled", label: "Crawled" },
];

const noFilter = "noFilter";

const NOT_PUSHED_SALE_CHANNELS = [
  { value: noFilter, label: "No Filter" },
  { value: "all", label: "All" },
  { value: "online_store", label: "Online Store" },
  { value: "ebay", label: "Ebay" },
  { value: "amazon", label: "Amazon" },
  { value: "etsy", label: "Etsy" },
  { value: "facebook", label: "Facebook" },
];

const All = "All";
export const Yes = "Yes";
export const No = "No";

const HAS_FB_TITLE_OPTIONS = [
  { value: All, label: All },
  { value: Yes, label: Yes },
  { value: No, label: No },
];

const AUTO_LISTED_OPTIONS = [
  { value: noFilter, label: "No Filter" },
  { value: "facebook", label: "Facebook" },
];

export default function ProductFiltersPolaris({
  setProductFilters,
  propsFilters,
  hideFilterStore,
  isMarketplaceManager,
  isStoreManager,
  storeManagerSpecial,
  isTeamLead,
  isRoleIdea,
}) {
  const [queryValue, setQueryValue] = useState("");
  const typingTimeoutRef = useRef(null);

  const { currentUser } = useAppContext();
  const { isSupporter } = checkRole(currentUser);

  const [productBase, setProductBase] = useState(null);
  const [productBaseId, setProductBaseId] = useState(null);
  const [searchProductBase, setSearchProductBase] = useState("");
  const [dataProductBase, setDataProductBase] = useState([]);

  const [store, setStore] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [searchStore, setSearchStore] = useState("");
  const [dataStore, setDataStore] = useState([]);
  const [sort, setSort] = useState({
    value: propsFilters.sort,
    label: null,
  });
  const [sortBy, setSortBy] = useState({
    value: propsFilters.sortBy,
    label: null,
  });

  const createdViaProp = propsFilters.createdVia;
  const [createdVia, setCreatedVia] = useState({
    value: createdViaProp,
    label: null,
  });

  const notYetPushSaleChannelProp = propsFilters.notYetPushSaleChannel;
  const [notYetPushSaleChannel, setNotYetPushSaleChannel] = useState({
    value: notYetPushSaleChannelProp,
    label: null,
  });

  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [productType, setProductType] = useState({
    value: propsFilters.personalized,
    label:
      true === propsFilters.personalized
        ? "Personalized"
        : false === propsFilters.personalized
        ? "Normal"
        : propsFilters.personalized,
  });

  const [designStatus, setDesignStatus] = useState({
    value:
      "All" === propsFilters.designStatus || null === propsFilters.designStatus
        ? null
        : propsFilters.designStatus,
    label:
      "All" === propsFilters.designStatus || null === propsFilters.designStatus
        ? "All"
        : propsFilters.designStatus,
  });

  const [author, setAuthor] = useState({
    value: propsFilters.authorId,
    label: null,
    search: null,
  });
  const [dataAuthor, setDataAuthor] = useState([]);

  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
  });
  const [autoListedTime, setAutoListedTime] = useState({
    range: null,
    rangeLabel: null,
  });

  const [pDivision, setPDivision] = useState({
    division: {
      value: propsFilters.divided,
      label: null,
    },
    status: {
      value: propsFilters.usedStatus,
      label: null,
    },
  });

  const hasFBTitleProp = propsFilters?.hasShortTitle;
  const [hasFBTitle, setHasFBTitle] = useState({
    value: null,
    label: null,
  });

  const isMainTitleUpdatedProp = propsFilters?.isMainTitleUpdated;
  const [isMainTitleUpdated, setIsMainTitleUpdated] = useState({
    value: null,
    label: null,
  });

  const hasOrderProp = propsFilters?.hasOrder;
  const [hasOrder, setHasOrder] = useState({
    value: null,
    label: null,
  });

  const autoListedProp = propsFilters?.autoListed;
  const [autoListed, setAutoListed] = useState({
    value: autoListedProp,
    label: null,
  });

  // Filter domain
  const [domain, setDomain] = useState({
    value: propsFilters.refURL,
    label: null,
    search: null,
  });
  const [domains, setDomains] = useState([]);

  const {
    loading: loadingProductBases,
    error: errorProductBases,
    data: dataProductBases,
  } = useQuery(LIST_PRODUCT_BASE);

  const {
    loading: loadingStores,
    error: errorStores,
    data: dataStores,
  } = useQuery(LIST_STORE);

  const {
    loading: loadingCollection,
    error: errorCollection,
    data: dataCollection,
  } = useQuery(COLLECTIONS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        search: collection.search,
        limit: 20,
        offset: 0,
      },
    },
  });

  const {
    loading: loadingTag,
    error: errorTag,
    data: dataTag,
  } = useQuery(TAGS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        search: tag.search,
        limit: 20,
        offset: 0,
      },
    },
  });

  const {
    data: dataM,
    loading: loadingM,
    error: errorM,
  } = useQuery(LIST_TEAM, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        teamRoles: [
          TEAM_ROLE.Admin,
          TEAM_ROLE.MarketplaceManager,
          TEAM_ROLE.StoreManager,
          TEAM_ROLE.Supporter,
        ],
        search: author.search,
        ...(isMarketplaceManager || isRoleIdea
          ? { getAllTeamMembers: true }
          : null),
      },
    },
  });

  // Query All domain
  const {
    data: dataD,
    loading: loadingD,
    error: errorD,
  } = useQuery(QUERY_DOMAINS, {
    skip: !isTeamLead && !isSupporter,
  });

  // Get filter value to props.
  useEffect(() => {
    if (propsFilters) {
      setQueryValue(propsFilters.search);
      setProductBaseId(propsFilters.productBaseId);
      setStoreId(propsFilters.storeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createdViaProp != null) {
      let curVal = CREATED_VIA.find((i) => i.value === createdViaProp);

      if (curVal != null) {
        const { value, label } = curVal;
        setCreatedVia({ value, label });
      }
    }
  }, [createdViaProp]);
  useEffect(() => {
    if (notYetPushSaleChannelProp != null) {
      let curVal = NOT_PUSHED_SALE_CHANNELS.find(
        (i) => i.value === notYetPushSaleChannelProp,
      );

      if (curVal != null) {
        const { value, label } = curVal;
        setNotYetPushSaleChannel({ value, label });
      }
    }
  }, [notYetPushSaleChannelProp]);

  useEffect(() => {
    if (propsFilters.authorId) {
      let currentAuth = dataM?.teamMembers?.hits?.find(
        (i) => i?.user?.id === propsFilters.authorId,
      );
      currentAuth = currentAuth?.user;
      if (currentAuth) {
        setAuthor((prev) => ({
          ...prev,
          value: currentAuth.id,
          label: `${currentAuth.firstName} ${currentAuth.lastName}`,
        }));
      }
    }
    if (propsFilters.divided) {
      setPDivision((prev) => ({
        ...prev,
        division: {
          ...prev.division,
          label: propsFilters.divided ? "Divided" : null,
        },
      }));
      if (propsFilters.usedStatus != null) {
        let label = propsFilters.usedStatus ? "Used" : "Unused";
        setPDivision((prev) => {
          return {
            ...prev,
            status: {
              ...prev.status,
              label,
            },
          };
        });
      }
    }
  }, [propsFilters, dataM]);

  useEffect(() => {
    let result;
    if (dataProductBases?.productBasesForSeller?.nodes?.length > 0) {
      result = dataProductBases.productBasesForSeller.nodes;
      if (result.length) {
        let newResult = [];
        result.forEach((i) => {
          let item = {};
          item["value"] = i.id;
          item["label"] = i.title;
          newResult.push(item);
        });

        if (productBaseId) {
          const resultOptions = newResult.filter(
            (option) => option.value === productBaseId,
          );
          setProductBase(resultOptions[0].label);
        }
        setDataProductBase(newResult);
      }
    }
  }, [dataProductBases, productBase, productBaseId]);

  useEffect(() => {
    let result;
    if (dataStores?.stores?.nodes?.length > 0) {
      result = dataStores.stores.nodes;
      if (result.length) {
        let newResult = [];
        result.forEach((i) => {
          let item = {};
          item["value"] = i.id;
          item["label"] = i.title;
          newResult.push(item);
        });

        if (storeId) {
          const resultOptions = newResult.filter(
            (option) => option.value === storeId,
          );
          setStore(resultOptions[0].label);
        }
        setDataStore(newResult);
      }
    }
  }, [dataStores, store, storeId]);

  // Get data collection
  useEffect(() => {
    const nodes =
      dataCollection?.collections?.nodes?.length > 0
        ? dataCollection.collections.nodes
        : [];
    const newNodes = formatDataTree(nodes);
    setCollections(newNodes);
  }, [dataCollection]);

  useEffect(() => {
    const nodes = dataTag?.tags?.nodes?.length > 0 ? dataTag.tags.nodes : [];
    const newTags = formatDataTree(nodes);
    setTags(newTags);
  }, [dataTag]);

  useEffect(() => {
    let newData =
      dataM?.teamMembers?.hits?.length > 0
        ? dataM.teamMembers.hits
            // .filter((i) => isAuthors.includes(i.role))
            .map((i) => {
              if (i.user) {
                return {
                  value: i.user.id,
                  label: `${i.user.firstName} ${i.user.lastName}`,
                };
              }
              return null;
            })
        : [];
    setDataAuthor(newData);
  }, [dataM]);

  useEffect(() => {
    let newDomains =
      dataD?.getAllDomain?.length > 0
        ? dataD.getAllDomain.map((domain) => ({
            value: domain,
            label: domain,
          }))
        : [];

    setDomains(newDomains);
  }, [dataD]);

  useEffect(() => {
    let refUrl = propsFilters?.refURL;
    if (refUrl) {
      let currentD =
        domains?.length > 0
          ? domains.find((d) => [d.value].includes(refUrl))
          : null;
      if (currentD) {
        let { value, label } = currentD;
        setDomain((prev) => ({ ...prev, value, label }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, propsFilters.refURL]);

  useEffect(() => {
    if (typeof hasFBTitleProp === "boolean") {
      let newVal = { value: No, label: No };
      if (hasFBTitleProp) {
        newVal = { value: Yes, label: Yes };
      }
      setHasFBTitle(newVal);
    }
  }, [hasFBTitleProp]);

  useEffect(() => {
    if (typeof isMainTitleUpdatedProp === "boolean") {
      let newVal = { value: No, label: No };
      if (isMainTitleUpdatedProp) {
        newVal = { value: Yes, label: Yes };
      }
      setIsMainTitleUpdated(newVal);
    }
  }, [isMainTitleUpdatedProp]);

  useEffect(() => {
    if (typeof hasOrderProp === "boolean") {
      let newVal = { value: No, label: No };
      if (hasOrderProp) {
        newVal = { value: Yes, label: Yes };
      }
      setHasOrder(newVal);
    }
  }, [hasOrderProp]);

  useEffect(() => {
    if (autoListedProp != null) {
      const match = AUTO_LISTED_OPTIONS.find((i) => i.value === autoListedProp);
      if (match) {
        setAutoListed(match);
      }
    }
  }, [autoListedProp]);

  // Handle value change
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    let divided = _.get(pDivision, "division.value", null);
    let usedStatus = _.get(pDivision, "status.value", null);
    const convertST = mapBoolVal(hasFBTitle.value);
    const convertTitleUpdate = mapBoolVal(isMainTitleUpdated.value);
    const convertHasOrder = mapBoolVal(hasOrder.value);
    const newIsAutoListedFB = autoListed.value;

    if (setProductFilters) {
      typingTimeoutRef.current = setTimeout(() => {
        setProductFilters(
          productBaseId,
          storeId,
          collection.value?.length > 0 ? collection.value : null,
          tag.value?.length > 0 ? tag.value : null,
          queryValue ? queryValue.trim() : queryValue,
          productType,
          author.value,
          filterTime.range,
          divided,
          usedStatus,
          designStatus.value,
          domain.value,
          sort.value,
          sortBy.value,
          createdVia.value,
          notYetPushSaleChannel.value,
          convertST,
          convertTitleUpdate,
          newIsAutoListedFB,
          autoListedTime.range,
          convertHasOrder,
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productBaseId,
    storeId,
    queryValue,
    productType,
    author,
    filterTime,
    pDivision,
    designStatus,
    domain,
    sort.value,
    sortBy.value,
    collection.value,
    tag.value,
    createdVia.value,
    notYetPushSaleChannel.value,
    hasFBTitle.value,
    isMainTitleUpdated.value,
    autoListed.value,
    autoListedTime.range,
    hasOrder.value,
  ]);

  const handleProductBaseRemove = useCallback(() => {
    setProductBase(null);
    setProductBaseId(null);
    setSearchProductBase("");
  }, []);

  const handleCollectionChange = useCallback(({ value, label }) => {
    setCollection((prev) => ({ ...prev, value, label }));
  }, []);

  const handleCollectionSearch = useCallback(
    (search) => setCollection((prev) => ({ ...prev, search })),
    [],
  );
  const handleCollectionRemove = useCallback(
    () => setCollection((prev) => ({ ...prev, value: [], label: [] }), []),
    [],
  );

  const handleTagChange = useCallback(({ value, label }) => {
    setTag((prev) => ({ ...prev, value, label }));
  }, []);

  const handleTagSearch = useCallback(
    (search) => setTag((prev) => ({ ...prev, search })),
    [],
  );

  const handleHasFBTitleChange = useCallback(({ value, label }) => {
    setHasFBTitle((prevState) => ({
      ...prevState,
      value,
      label,
    }));
  }, []);

  const handleIsMainTitleUpdatedChange = useCallback(({ value, label }) => {
    setIsMainTitleUpdated((prevState) => ({
      ...prevState,
      value,
      label,
    }));
  }, []);

  const handleHasOrderChange = useCallback(({ value, label }) => {
    setHasOrder((prevState) => ({
      ...prevState,
      value,
      label,
    }));
  }, []);

  const handleAutoListedChange = useCallback(
    (newValue) => setAutoListed(newValue),
    [],
  );

  const handleStoreRemove = useCallback(() => {
    setStore(null);
    setStoreId(null);
    setSearchStore("");
  }, []);

  const handleTagRemove = useCallback(
    () => setTag((prev) => ({ ...prev, value: [], label: [] })),
    [],
  );

  const handleProductTypeRemove = useCallback(() => {
    setProductType({ value: null, label: null });
  }, []);

  const handleProductDesignStatusRemove = useCallback(() => {
    setDesignStatus({ value: null, label: "All" });
  }, []);

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );

  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleAuthorRemove = useCallback(
    () => setAuthor({ value: null, label: null }),
    [],
  );
  const handleFilterTimeRemove = useCallback(
    () => setFilterTime({ range: null, rangeLabel: null }),
    [],
  );
  const handleAutoListedTimeRemove = useCallback(
    () => setAutoListedTime({ range: null, rangeLabel: null }),
    [],
  );
  const handleDivisionRemove = useCallback(
    () =>
      setPDivision({
        division: { value: null, label: null },
        status: { value: null, label: null },
      }),
    [],
  );

  const handleDomainRemove = useCallback(
    () => setDomain({ value: null, label: null }),
    [],
  );
  const handleSortRemove = useCallback(
    () => setSort({ value: "DESC", label: "DESC" }),
    [],
  );
  const handleSortByRemove = useCallback(
    () => setSortBy({ value: "total_orders", label: totalOrderLabel }),
    [],
  );

  const handleHasFBTitleRemove = useCallback(
    () => setHasFBTitle({ value: null, label: null }),
    [],
  );

  const handleIsMainTitleUpdatedRemove = useCallback(
    () => setIsMainTitleUpdated({ value: null, label: null }),
    [],
  );

  const handleHasOrderRemove = useCallback(
    () => setHasOrder({ value: null, label: null }),
    [],
  );

  const handleAutoListedRemove = useCallback(
    () => setAutoListed({ value: null, label: null }),
    [],
  );

  // Created via
  const handleCreatedViaRemove = useCallback(
    () => setCreatedVia({ value: null, label: null }),
    [],
  );

  const handleNotPushRemove = useCallback(
    () => setNotYetPushSaleChannel({ value: null, label: null }),
    [],
  );

  const filtersClear = [
    handleProductBaseRemove,
    handleStoreRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleQueryValueRemove,
    handleProductTypeRemove,
    handleAuthorRemove,
    handleFilterTimeRemove,
    handleDivisionRemove,
    handleProductDesignStatusRemove,
    handleDomainRemove,
    handleSortRemove,
    handleSortByRemove,
    handleCreatedViaRemove,
    handleNotPushRemove,
    handleHasFBTitleRemove,
    handleIsMainTitleUpdatedRemove,
    handleAutoListedRemove,
    handleAutoListedTimeRemove,
    handleHasOrderRemove,
  ];

  const handleFiltersClearAll = useCallback(() => {
    for (let fn of filtersClear) {
      if (fn) fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filtersClear]);

  const filters = [
    {
      key: "productBase",
      label: "Product bases",
      filter: (
        <FilterProductBasePolaris
          loading={loadingProductBases}
          error={errorProductBases}
          productBaseId={productBaseId}
          searchProductBase={searchProductBase}
          dataProductBase={dataProductBase}
          setProductBase={(selectedId, selectedValue) => {
            setProductBaseId(selectedId);
            setProductBase(selectedValue);
          }}
          setInputValue={(v) => {
            setSearchProductBase(v);
          }}
        />
      ),
    },
    ...(!hideFilterStore
      ? [
          {
            key: "store",
            label: "Stores",
            filter: (
              <FilterStorePolaris
                loading={loadingStores}
                error={errorStores}
                storeId={storeId}
                searchStore={searchStore}
                dataStore={dataStore}
                setStore={(selectedId, selectedValue) => {
                  setStore(selectedValue);
                  setStoreId(selectedId);
                }}
                setInputValue={(v) => {
                  setSearchStore(v);
                }}
              />
            ),
          },
        ]
      : []),
    {
      key: "collection",
      label: "Collections",
      filter: (
        <FilterHasSearchMVPolaris
          value={collection}
          options={collections}
          loading={loadingCollection}
          error={errorCollection}
          onChange={handleCollectionChange}
          onChangeSearch={handleCollectionSearch}
        />
      ),
    },
    {
      key: "tag",
      label: "Tags",
      filter: (
        <FilterHasSearchMVPolaris
          value={tag}
          options={tags}
          loading={loadingTag}
          error={errorTag}
          onChange={handleTagChange}
          onChangeSearch={handleTagSearch}
        />
      ),
    },
    {
      key: "productType",
      label: "Product Type",
      filter: (
        <FilterProductTypePolaris
          productType={productType}
          setProductType={(value, label) => {
            setProductType({
              value,
              label,
            });
          }}
        />
      ),
    },
    ...(!isStoreManager || storeManagerSpecial
      ? [
          {
            key: "author",
            label: "Author",
            filter: (
              // <FilterNoSearchPolaris
              <FilterHasSearchPolaris
                options={dataAuthor}
                value={author}
                loading={loadingM}
                error={errorM}
                onChange={({ value, label }) =>
                  setAuthor((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={(search) =>
                  setAuthor((prevState) => ({
                    ...prevState,
                    search,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "createdTime",
      label: "Created Time",
      filter: (
        <FilterCreatedTimePolaris
          filterTime={filterTime}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "productDivision",
      label: "Product Division",
      filter: (
        <FilterProductByDivisionPolaris
          onChange={({ division, status }) =>
            setPDivision((prevState) => ({
              ...prevState,
              division,
              status,
            }))
          }
          value={pDivision}
        />
      ),
    },
    {
      key: "designStatus",
      label: "Design Status",
      filter: (
        <FilterProductDesignStatusPolaris
          designStatus={designStatus}
          setDesignStatus={(value, label) => {
            setDesignStatus({
              value,
              label,
            });
          }}
        />
      ),
    },
    ...(isTeamLead || isSupporter
      ? [
          {
            key: "domain",
            label: "Domain",
            filter: (
              <FilterNoSearchPolaris
                data={domains}
                value={domain}
                loading={loadingD}
                error={errorD}
                onChange={({ value, label }) =>
                  setDomain((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
                onChangeSearch={({ search }) =>
                  setDomain((prevState) => ({
                    ...prevState,
                    search,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "orderBy",
      label: "Order By",
      filter: (
        <FilterListRadioPolaris
          value={sortBy.value}
          options={SORT_BY}
          onChange={({ value, label }) => setSortBy({ value, label })}
        />
      ),
    },
    {
      key: "order",
      label: "Order",
      filter: (
        <FilterListRadioPolaris
          value={sort.value}
          options={SORTS}
          onChange={({ value, label }) => setSort({ value, label })}
        />
      ),
    },
    {
      key: "createdVia",
      label: "Created Via",
      filter: (
        <FilterListRadioPolaris
          value={createdVia.value}
          options={CREATED_VIA}
          onChange={({ value, label }) => setCreatedVia({ value, label })}
        />
      ),
    },
    {
      key: "notPushed",
      label: "Never been pushed to chanel",
      filter: (
        <FilterListRadioPolaris
          value={notYetPushSaleChannel.value}
          options={NOT_PUSHED_SALE_CHANNELS}
          defaultValue={noFilter}
          onChange={({ value, label }) =>
            setNotYetPushSaleChannel({ value, label })
          }
        />
      ),
    },
    {
      key: "hasFBTitle",
      label: "Has FB Title",
      filter: (
        <FilterListRadioPolaris
          value={hasFBTitle.value}
          options={HAS_FB_TITLE_OPTIONS}
          onChange={handleHasFBTitleChange}
        />
      ),
    },
    {
      key: "isMainTitleUpdated",
      label: "Main title has been updated",
      filter: (
        <FilterListRadioPolaris
          value={isMainTitleUpdated.value}
          options={HAS_FB_TITLE_OPTIONS}
          onChange={handleIsMainTitleUpdatedChange}
        />
      ),
    },
    {
      key: "autoListed",
      label: "Auto Listed",
      filter: (
        <FilterListRadioPolaris
          defaultValue={noFilter}
          value={autoListed.value}
          options={AUTO_LISTED_OPTIONS}
          onChange={handleAutoListedChange}
        />
      ),
    },
    {
      key: "autoListedTime",
      label: "Auto Listed Time",
      filter: (
        <FilterCreatedTimePolaris
          filterTime={autoListedTime}
          onChangeRange={(range, rangeLabel) => {
            setAutoListedTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "hasOrder",
      label: "Has Order",
      filter: (
        <FilterListRadioPolaris
          value={hasOrder.value}
          options={HAS_FB_TITLE_OPTIONS}
          onChange={handleHasOrderChange}
        />
      ),
    },
  ];

  let parameters = {
    productBase: productBase,
    handleProductBaseRemove: handleProductBaseRemove,
    store: store,
    handleStoreRemove: handleStoreRemove,
    productType: productType.label,
    handleProductTypeRemove,
    designStatus: designStatus.label,
    handleProductDesignStatusRemove,
  };
  const appliedFilters = appliedFilter(parameters);
  if (!isEmpty(author.label)) {
    const key = "author";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, author.label),
      onRemove: handleAuthorRemove,
    });
  }
  if (!isEmpty(designStatus.label) && null !== designStatus.value) {
    const key = "designStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, designStatus.label),
      onRemove: handleProductDesignStatusRemove,
    });
  }

  if (!isEmpty(filterTime.rangeLabel) && filterTime.range) {
    const key = "createdTime";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filterTime.rangeLabel),
      onRemove: handleFilterTimeRemove,
    });
  }

  if (!isEmpty(pDivision.division.label) || !isEmpty(pDivision.status.label)) {
    const key = "productDivision";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, pDivision),
      onRemove: handleDivisionRemove,
    });
  }
  if (!isEmpty(domain.label)) {
    const key = "domain";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, domain.label),
      onRemove: handleDomainRemove,
    });
  }

  if (!isEmpty(sort.label) && sort.label !== "DESC") {
    const key = "order";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sort.label),
      onRemove: handleSortRemove,
    });
  }
  if (!isEmpty(sortBy.label) && sortBy.label !== totalOrderLabel) {
    const key = "orderBy";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sortBy.label),
      onRemove: handleSortByRemove,
    });
  }

  if (!isEmpty(collection.label)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection.label),
      onRemove: handleCollectionRemove,
    });
  }

  if (!isEmpty(tag.label)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag.label),
      onRemove: handleTagRemove,
    });
  }

  if (!isEmpty(createdVia.label)) {
    const key = "createdVia";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, createdVia.label),
      onRemove: handleCreatedViaRemove,
    });
  }
  if (!isEmpty(notYetPushSaleChannel.label) && notYetPushSaleChannel.value) {
    const key = "notPushed";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, notYetPushSaleChannel.label),
      onRemove: handleNotPushRemove,
    });
  }
  if (!isEmpty(hasFBTitle.label)) {
    const key = "hasFBTitle";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, hasFBTitle.label),
      onRemove: handleHasFBTitleRemove,
    });
  }
  if (!isEmpty(isMainTitleUpdated.label)) {
    const key = "isMainTitleUpdated";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, isMainTitleUpdated.label),
      onRemove: handleIsMainTitleUpdatedRemove,
    });
  }

  if (!isEmpty(hasOrder.label)) {
    const key = "hasOrder";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, hasOrder.label),
      onRemove: handleHasOrderRemove,
    });
  }
  if (!isEmpty(autoListed.label) && autoListed.value) {
    const key = "autoListed";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, autoListed.label),
      onRemove: handleAutoListedRemove,
    });
  }

  if (!isEmpty(autoListedTime.rangeLabel) && autoListedTime.range) {
    const key = "autoListedTime";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, autoListedTime.rangeLabel),
      onRemove: handleAutoListedTimeRemove,
    });
  }

  return (
    <>
      <Filters
        queryValue={queryValue}
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "author":
        return `Author: ${value}`;
      case "designStatus":
        return `Design status: ${value}`;
      case "createdTime":
        return `Created time: ${value}`;
      case "productDivision":
        let dLabel = _.get(value, "division.label", null);
        let sLabel = _.get(value, "status.label", null);
        let label = [dLabel, sLabel].filter(Boolean).join(" - ");
        return `Product division: ${label}`;
      case "domain":
        return `Domain: ${value}`;
      case "order":
        return `Order: ${value}`;
      case "orderBy":
        return `Order By: ${value}`;
      case "collection":
        let collectionLabel = genLabelTree(value);
        return `Collections: ${collectionLabel}`;
      case "tag":
        let tagLabel = genLabelTree(value);
        return `Tags: ${tagLabel}`;
      case "createdVia":
        return `Created Via: ${value}`;
      case "notPushed":
        return `Never been pushed to chanel: ${value}`;
      case "hasFBTitle":
        return `Has FB Title: ${value}`;
      case "isMainTitleUpdated":
        return `Main title has been updated: ${value}`;
      case "autoListed":
        return `Auto Listed: ${value}`;
      case "autoListedTime":
        return `Auto Listed Time: ${value}`;
      case "hasOrder":
        return `Has Order: ${value}`;
      default:
        return value;
    }
  }
}

const boolObj = {
  [Yes]: true,
  [No]: false,
};

function mapBoolVal(key) {
  if (key in boolObj) {
    return boolObj[key];
  }

  return null;
}
