import { gql } from "apollo-boost";

import { ProductGen2dMockupsFragment } from "../fragments";

export const GET_PRODUCT_MAPPING = gql`
    fragment productBaseVariantFragment on ProductBaseVariantAttribute {
        name
        slug
        option
    }
    fragment ProductVariantFragment on ProductVariant {
        id
        productId
        productBaseVariantId
        productBaseVariant {
            id
            attributes {
                ...productBaseVariantFragment
            }
            productBase {
                id
                title
            }
        }
        image {
            id
            url
            thumbnailUrl
        }
        sku
        sorting
        disabled
        hasPsdInNas
        variantStores {
            id
            originId
            storeId
            productVariantId
            permalink
        }
    }

    query getProductMapping($id: ID!) {
        getProductMapping(id: $id) {
            product {
                id
                sku
                mainImageId
                images {
                    id
                    file {
                        id
                        name
                        url
                        thumbnailUrl
                    }
                }
                productBases {
                    id
                    title
                    slug
                    fulfillment {
                        fulfillmentTitle
                    }
                }
                variants {
                    id
                    ...ProductVariantFragment
                }
                designPositions {
                    id
                    file {
                        id
                        thumbnailUrl
                        url
                    }
                    designPosition {
                        id
                        name
                    }
                }
                fields {
                    id
                    title
                    name
                    sorting
                }
            }
            variantExistRelation {
                ...ProductVariantFragment
            }
            variantAvailableMapping {
                ...ProductVariantFragment
            }
            originVariantNeedMapping
        }
    }
`;

export const PRODUCTS_USING_GEN2D_MOCKUPS = gql`
    query productUsingGen2dMockups($filter: ProductUsingGen2dMockupFilter) {
        productUsingGen2dMockups(filter: $filter) {
            total
            nodes {
                ...ProductGen2dMockupsFragment
            }
            aggeration
        }
    }
    ${ProductGen2dMockupsFragment}
`;

export const PRODUCT_USING_GEN2D_MOCKUPS = gql`
    query productByID($id: ID!) {
        productByID(id: $id) {
            ...ProductGen2dMockupsFragment
        }
    }
    ${ProductGen2dMockupsFragment}
`;

export const GET_NICHES = gql`
    fragment niche on Niche {
        id
        name
    }

    query niches($filter: NicheFilter) {
        niches(filter: $filter) {
            total
            nodes {
                ...niche
                parent {
                    ...niche
                    parent {
                        ...niche
                        parent {
                            ...niche
                            parent {
                                ...niche
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PRODUCT_BY_TITLE = gql`
    query getProductsByTitle(
        $title: String!
        $whichField: String!
        $productID: ID
    ) {
        getProductsByTitle(
            title: $title
            whichField: $whichField
            productID: $productID
        ) {
            total
            nodes {
                id
            }
        }
    }
`;

export const PRODUCT_VARIANT_HAS_ORDER = gql`
    query productVariantHasOrder($productVariantIds: [ID!]!) {
        productVariantHasOrder(productVariantIds: $productVariantIds)
    }
`;

export const PRODUCT_BASE_OPTIONS = gql`
    query productBaseOptions {
        productBaseOptions
    }
`;

export const PRODUCT_BASE_HAS_ORDER = gql`
    query productProductBaseHasOrder($productId: ID!, $productBaseId: ID!) {
        productProductBaseHasOrder(productId: $productId, productBaseId: $productBaseId)
    }
`;