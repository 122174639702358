import React, { useState, useEffect, useCallback } from "react";
import { DataTable, Toast } from "@shopify/polaris";
import styled from "styled-components";

import { setCookie, getCookie } from "../../../helper";
import { PaginationPolaris } from "../../shared/PaginationPolaris";
import { FulfillmentActionsPolaris } from "./FulfillmentActionsPolaris";

const Container = styled.div`
  .actions-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const DataTableFulfillmentPolaris = ({ data, refetch }) => {
  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });
  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageFulfillment")) || 20,
    offset: 0,
  });

  useEffect(() => {
    let { limit, offset } = filter;
    let fulfillments = data?.fulfillments;
    let valueSliced =
      fulfillments?.length > 0
        ? fulfillments.slice(offset, limit + offset)
        : [];

    let newRows =
      valueSliced.map((ff) => {
        return [
          <div className="name-wrap">
            <span>{ff.name}</span>
          </div>,
          <div className="products-wrap">
            <span>{ff.products}</span>
          </div>,
          <div className="actions-wrap">
            <FulfillmentActionsPolaris
              item={ff}
              refetch={refetch}
              toggleActive={toggleActive}
              onNotificationChange={({ message, isError }) =>
                setNotification(() => ({ message, isError }))
              }
            />
          </div>,
        ];
      }) || [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  // Handle action
  const handleFilterChange = useCallback((offset, limit) => {
    setFilter((prev) => ({
      ...prev,
      offset,
      limit,
    }));
    setCookie("perPageFulfillment", limit, 100);
  }, []);

  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  // Variable
  let { limit, offset } = filter;
  const total = data?.fulfillments?.length;
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handleFilterChange,
    total,
  };

  return (
    <Container>
      {toastMarkup}
      <DataTable
        columnContentTypes={["text", "text", "numeric"]}
        headings={["Name", "Products", "Actions"]}
        rows={rows}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
