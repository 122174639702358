import React, { useState, useCallback, useEffect } from "react";
import { Tabs } from "@shopify/polaris";

import { toSlug } from "../../../helper";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";

export const ViewMockupTabsPolaris = ({ designPositions, productBases }) => {
  // State
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  // Get data
  useEffect(() => {
    let newDP =
      designPositions?.length > 0
        ? designPositions
            .map((dp) => {
              let item = {
                ...dp,
              };
              productBases.forEach((base) => {
                if (dp?.designPosition?.productBaseId === base.id) {
                  item = {
                    ...item,
                    baseName: base.title,
                  };
                }
              });
              return item;
            })
            .filter(Boolean)
        : [];
    let newTabs =
      newDP?.length > 0
        ? newDP
            .map((item) => {
              let label = `${item?.designPosition?.name} (${item?.baseName})`;
              if (label) {
                return {
                  id: toSlug(label),
                  content: label,
                  accessibilityLabel: label,
                };
              }
              return null;
            })
            .filter(Boolean)
        : [];
    setTabs(newTabs);
  }, [designPositions, productBases]);

  // Handle actions
  const handleTabChange = useCallback((tabIndex) => setSelectedTab(tabIndex), []);

  return (
    <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
      {designPositions?.length > 0
        ? designPositions.map((ds, index) => {
            let currentSelected = tabs[selectedTab];
            let includesBlack = false;
            if (currentSelected?.id.includes("black")) {
              includesBlack = true;
            }
            return index === selectedTab ? (
              <div
                className="task-compare-item"
                key={ds.id}
                style={{ marginTop: "1rem" }}
              >
                <div className="design_inner">
                  <ModalImageClaimPolaris
                    width="auto"
                    maxWidth="100%"
                    height="auto"
                    files={{ files: ds.file ? [ds.file] : [] }}
                    isCompare
                    hasShadow
                    includesBlack={includesBlack}
                  />
                </div>
              </div>
            ) : null;
          })
        : null}
    </Tabs>
  );
};
