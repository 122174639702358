import React, { Component } from "react";
import styled from "styled-components";
// import logo from "../assets/images/mblogo_dark.png";
import logo from "../assets/images/mb_dark_logo.png";
import { Link } from "react-router-dom";

const Container = styled.div`
  margin: 30px auto;
  padding: 10px;
  .logo-mb {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    .mb-privacy-logo {
      width: 300px;
      height: auto;
    }
  }
`;

export default class Privacy extends Component {
  render() {
    return (
      <Container className="mb-privacy container">
        <div className="logo-mb">
          <Link to="/">
            <img className="mb-privacy-logo" alt="Merch Bridge" src={logo} />
          </Link>
        </div>
        <h3>Introduction</h3>
        <p>
          We are MerchBridge, Inc. (“MerchBridge”) know you care about how your
          personal information is used and shared, and we take your privacy
          seriously. Please read the following Privacy Policy. By using or
          accessing our services, including this site, in any manner, you
          acknowledge that you accept the practices and policies outlined in
          this Privacy Policy, and you hereby consent to our collection, use,
          and sharing your information in the following ways.
        </p>
        <h3>What does this Privacy Policy cover?</h3>
        <p>
          This Privacy Policy covers our treatment of personally identifiable
          information (“Personal Information”) that we gather when you are
          accessing or using this site, but does not cover the practices of
          companies we don’t own or control, or people that we don’t manage. We
          gather various types of Personal Information from our users, as
          explained in more detail below, and we use this Personal Information
          internally in connection with the services we provide, including to
          personalize, provide, and improve our services, to allow you to set up
          a user account and profile, to contact you, to fulfill your requests
          for certain products and services, and to analyze how you use the
          services. In certain cases, we may also share some Personal
          Information with third parties, but only as described below.
        </p>
        <p>
          We do not knowingly collect or solicit personal information from
          anyone under the age of 13. If you are under 13, please do not attempt
          to register for the services or send any personal information about
          yourself to us. If we learn that we have collected personal
          information from a child under age 13, we will delete that information
          as quickly as possible. If you believe that a child under 13 may have
          provided us personal information, please contact us at&nbsp;
          <strong>friend@merchbridge.com</strong>.
        </p>
        <h3>Will We Ever change this Privacy Policy?</h3>
        <p>
          We’re constantly trying to improve our services, so we may need to
          change this Privacy Policy from time to time as well, but we will
          alert you to changes by placing a notice on our website located at ,
          by sending you an email, and/or by some other means. We will not
          reduce your rights under this Privacy Policy without your explicit
          consent. Please note that if you’ve opted not to receive legal notice
          emails from us (or you haven’t provided us with your email address),
          those legal notices will still govern your use of the services, and
          you are still responsible for reading and understanding them. These
          changes will only apply to Personal Information we collect after we
          have posted the revised Privacy Policy. If you use the services after
          any changes to the Privacy Policy have been posted, that means you
          agree to all of the changes.
        </p>
        <h3>What Information Do We Collect?</h3>
        <p>
          <em>Information You Provide to Us:</em>
        </p>
        <p>
          We receive and store any information you knowingly provide to us. For
          example, when you create an account, place an order or subscribe to
          our newsletter we may collect Personal Information such as your name,
          email address, password, phone number, third-party account credentials
          (for example, your log-in credentials for Facebook or other third
          party sites), transactional information and credit card information.
          If you provide your third-party account credentials to us, or
          otherwise sign in to our services through a third party site or
          service, you understand some content and/or information in those
          accounts (“Third Party Account Information”) may be transmitted into
          your account with us, and that Third Party Account Information
          transmitted to our services is covered by this Privacy Policy; for
          example, your name and email address. Certain information may be
          required to register with us or to take advantage of some of our
          features.
        </p>
        <p>
          We may communicate with you if you’ve provided us the means to do so.
          For example, if you’ve given us your email address, we may email you
          about your use of the services. Also, we may receive a confirmation
          when you open an email from us. This confirmation helps us make our
          communications with you more interesting and improve our services. If
          you do not want to receive communications from us, please indicate
          your preference by opting out using the link provided at the base of
          our email communications.
        </p>
        <p>
          <em>Information Collected Automatically</em>
        </p>
        <p>
          Whenever you interact with our services, we automatically receive and
          record information on our server logs from your browser or device,
          which may include your IP address, device identification, “cookie”
          information, the type of browser and/or device you’re using to access
          our services, and the page or feature you requested. “Cookies” are
          identifiers we transfer to your browser or device that allow us to
          recognize your browser or device and tell us how and when pages and
          features in our services are visited and by how many people. You may
          be able to change the preferences on your browser or device to prevent
          or limit your device’s acceptance of cookies, but this may prevent you
          from taking advantage of some of our features and services or degrade
          your user experience with features or services that you are able to
          use.
        </p>
        <p>
          Our advertising partners may also transmit cookies to your browser or
          device, when you click on ads that appear on the services. Also, if
          you click on a link to a third party website or service, a third party
          may also transmit cookies to you. Again, this Privacy Policy does not
          cover the use of cookies by any third parties, and we aren’t
          responsible for their privacy policies and practices. Please be aware
          that cookies placed by third parties may continue to track your
          activities online even after you have left our services, and those
          third parties may not honor “Do Not Track” requests you have set using
          your browser or device.
        </p>
        <p>
          We may use this data to customize content for you that we think you
          might like, based on your usage patterns. We may also use it to
          improve our services – for example, this data can tell us how often
          users use a particular feature of the services, and we can use that
          knowledge to make the services interesting to as many users as
          possible.
        </p>
        <p>
          <em>
            Information Collected From Other Websites and Do Not Track Policy:
          </em>
        </p>
        <p>
          Through cookies we place on your browser or device, we may collect
          information about your online activity after you leave our site. Just
          like any other usage information we collect, this information allows
          us to improve the services and customize your online experience, and
          otherwise as described in this Privacy Policy. We use Google Analytics
          to collect information about use of our services and site. Google
          Analytics collects certain information, such as how often users visit
          this site, what pages they visit when they do so, and what other sites
          they used prior to coming to this site. Google Analytics collects only
          the IP address assigned to you on the date you visit this site, rather
          than your name or other Personal Information. We do not combine the
          information collected through the use of Google Analytics with your
          Personal Information. Although Google Analytics plants a permanent
          cookie on your web browser to identify you as a unique user the next
          time you visit this site, the cookie cannot be used by anyone but
          Google. Google’s ability to use and share information collected by
          Google Analytics about your visits to this site is restricted by
          the&nbsp;
          <a
            href="https://www.google.com/analytics/terms/us.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google Analytics Terms of Use
          </a>
          &nbsp;and the&nbsp;
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google Privacy Policy
          </a>
          .
        </p>
        <p>
          Your browser may offer you a “Do Not Track” option, which allows you
          to signal to operators of websites and web applications and services
          (including behavioral advertising services) that you do not wish such
          operators to track certain of your online activities over time and
          across different websites. Our services do not support Do Not Track
          requests at this time, which means that we collect information about
          your online activity both while you are using the services and after
          you leave our services. However, you can opt out of having your
          activity on the website made available to Google Analytics by
          installing the Google Analytics opt-out browser add-on (
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
          ) from your web browser. The Google Analytics opt-out browser add-on
          provides visitors with the ability to prevent their data from being
          collected and used by Google Analytics.
        </p>
        <p>
          <em>Will We Share Any of the Personal Information We Receive?</em>
        </p>
        <p>
          We may share your Personal Information with third parties as described
          in this section:
        </p>
        <p>
          <strong>Information that’s no longer personally identifiable:</strong>
          &nbsp;We may anonymize your Personal Information so that you are not
          individually identified, and provide that information to our partners.
          We may also provide aggregate usage information to our partners, who
          may use such information to understand how often and in what ways
          people use our services, so that they, too, can provide you with an
          optimal online experience. However, we never disclose aggregate usage
          information to a partner in a manner that would identify you
          personally, as an individual.
        </p>
        <p>
          <strong>Advertisers:</strong>&nbsp;We allow advertisers and/or
          merchant partners (“Advertisers”) to choose the demographic
          information of users who will see their advertisements and/or
          promotional offers and you agree that we may provide any of the
          information we have collected from you in non-personally identifiable
          form to an Advertiser, in order for that Advertiser to select the
          appropriate audience for those advertisements and/or offers. For
          example, we might use the fact you are located in San Francisco to
          show you ads or offers for San Francisco businesses, but we will not
          tell such businesses who you are. Or, we might allow Advertisers to
          display their ads to users with similar usage patterns to yours, but
          we will not disclose usage information to Advertisers except in
          aggregate form, and not in a manner that would identify you
          personally. Note that if an Advertiser asks us to show an ad to a
          certain audience or audience segment and you respond to that ad, the
          Advertiser may conclude that you fit the description of the audience
          they were trying to reach.
        </p>
        <p>
          We may deliver a file to you through our services (known as a “web
          beacon”) from an ad network. Web beacons allow ad networks to provide
          anonymized, aggregated auditing, research and reporting for us and for
          advertisers. Web beacons also enable ad networks to serve targeted
          advertisements to you when you visit other websites. Because your web
          browser must request these advertisements and web beacons from the ad
          network’s servers, these companies can view, edit, or set their own
          cookies, just as if you had requested a web page from their site. You
          may be able to opt-out of web beacon tracking conducted by third
          parties through our services by adjusting the Do Not Track settings on
          your browser; please note that we don’t control whether or how these
          third parties comply with Do Not Track requests.
        </p>
        <p>
          <strong>Affiliated Businesses:</strong>&nbsp;In certain situations,
          businesses or third party websites we’re affiliated with may sell or
          provide products or services to you through or in connection with the
          services (either alone or jointly with us). We will share your
          Personal Information with such affiliated businesses only to the
          extent that it is related to such transaction or service. We have no
          control over the policies and practices of third party websites or
          businesses as to privacy or anything else, so if you choose to take
          part in any transaction or service relating to an affiliated website
          or business, please review all such business’ or websites’ policies.
        </p>
        <p>
          <strong>Agents:</strong>&nbsp;We employ other companies and people to
          perform tasks on our behalf and need to share your Personal
          Information with them to provide products or services to you; for
          example, we may use a payment processing company to receive and
          process your credit card transactions for us, and we use a third party
          to operate our website and provide us with order processing or
          fulfillment services. We do not control the privacy policies and
          practices of these companies and people.
        </p>
        <p>
          <strong>User Profiles and Submissions:</strong>&nbsp;If you sign into
          the services through a third party social networking site or service,
          your list of “friends” from that site or service may be automatically
          imported to the services, and such “friends,” if they are also
          registered users of the services, may be able to access certain
          non-public information you have entered in your services user profile.
          Again, we do not control the policies and practices of any other third
          party site or service.
        </p>
        <p>
          <strong>Business Transfers:</strong>&nbsp;We may choose to buy or sell
          assets, and may share and/or transfer customer information in
          connection with the evaluation of and entry into such transactions.
          Also, if we (or our assets) are acquired, or if we go out of business,
          enter bankruptcy, or go through some other change of control, Personal
          Information could be one of the assets transferred to or acquired by a
          third party.
        </p>
        <p>
          Protection of Company and Others: We reserve the right to access,
          read, preserve, and disclose any information that we reasonably
          believe is necessary to comply with law or court order; enforce or
          apply our Terms of Sale, located at and other agreements; or protect
          the rights, property, or safety of company, our employees, our users,
          or others.
        </p>
        <h3>Is Personal Information about me secure?</h3>
        <p>
          Your account is protected by a password for your privacy and security.
          If you access your account via a third party site or service, you may
          have additional or different sign-on protections via that third party
          site or service. You must prevent unauthorized access to your account
          and Personal Information by selecting and protecting your password
          and/or other sign-on mechanism appropriately and limiting access to
          your computer or device and browser by signing off after you have
          finished accessing your account.
        </p>
        <p>
          We endeavor to protect the privacy of your account and other Personal
          Information we hold in our records and maintain appropriate physical,
          technical and administrative safeguards (including encryption) to
          protect such Personal Information, but unfortunately, we cannot
          guarantee complete security. Unauthorized entry or use, hardware or
          software failure, and other factors, may compromise the security of
          user information at any time.
        </p>
        <h3>How long is my Personal Information stored?</h3>
        <p>
          We store your Personal Information through the life of your account
          with us. We will retain your Personal Information for a minimum of
          five years or as necessary to comply with our legal obligations.
        </p>
        <h3>What Personal Information can I access?</h3>
        <p>
          Through your account settings, you may access, and, in some cases,
          edit or delete the following information you’ve provided to us: name
          and password, email address, location, order history
        </p>
        <p>
          The information you can view, update, and delete may change as the
          services change. If you have any questions about viewing or updating
          information we have on file about you, please contact us at{" "}
          <strong>friend@merchbridge.com</strong>.
        </p>
        <p>
          Under California Civil Code Sections 1798.83-1798.84, California
          residents are entitled to ask us for a notice identifying the
          categories of Personal Information which we share with our affiliates
          and/or third parties for marketing purposes, and providing contact
          information for such affiliates and/or third parties. If you are a
          California resident and would like a copy of this notice, please
          submit a written request to:&nbsp;
          <strong>friend@merchbridge.com</strong>.
        </p>
        <h3>What choices do I have?</h3>
        <p>
          You can always opt not to disclose information to us, but keep in mind
          some information may be needed to register with us or to take
          advantage of some of our features, and non-disclosure of certain
          information may degrade or restrict your access to some
          functionalities of our services.
        </p>
        <p>
          You may be able to add, update, or delete information as explained
          above. When you update information, however, we may maintain a copy of
          the unrevised information in our records.
        </p>
        <h3>What if I have questions about this policy?</h3>
        <p>
          If you have any questions or concerns regarding our privacy policies,
          please send us a detailed message to&nbsp;
          <strong>friend@merchbridge.com</strong>, and we will try to resolve
          your concerns.
        </p>
      </Container>
    );
  }
}
