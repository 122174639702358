import React from "react";
import { Button, Popover, Card, Stack } from "@shopify/polaris";
import { CircleInformationMajorMonotone } from "@shopify/polaris-icons";
import styled from "styled-components";

import useToggle from "../../../hooks/useToggle";
import CopyBox from "../../shared/CopyBox";

const Container = styled.div`
    .copy-box {
        font-size: 0.8em;
        padding: 2px;
        padding-left: 5px;
        border-radius: var(--p-border-radius-base, 3px);
        margin-top: 0.5rem;
        border-color: #b7c1ca;
        input {
            background: transparent;
        }
    }
`;

export const SuggestFileName = ({ fileName, sku }) => {
    // State
    const [open, toggleOpen] = useToggle(false);

    const activator = (
        <Button
            plain
            icon={CircleInformationMajorMonotone}
            onClick={toggleOpen}
        />
    );

    const fileNameMarkup = fileName ? <CopyBox text={fileName} /> : null;
    const skuMarkup = sku ? <CopyBox text={sku} /> : null;

    return (
        <div className="suggest-wrap">
            <Popover activator={activator} active={open} onClose={toggleOpen}>
                <Container>
                    <Card sectioned title="You can try this File name:">
                        <Stack vertical spacing="tight">
                            {fileNameMarkup}
                            {fileNameMarkup ? <span>Or</span> : null}
                            {skuMarkup}
                        </Stack>
                    </Card>
                </Container>
            </Popover>
        </div>
    );
};
