import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import { Button, Modal } from "antd";
import {
  DeleteOutlined,
  RetweetOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  .item-wrap {
    width: 200px;
    height: 200px;
    position: relative;
    .item {
      border: 2px solid transparent;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      &.selected {
        border: 2px solid #1890ff;
      }
      &.overlay {
        opacity: 0.2;
      }
      &:not(.overlay) {
        cursor: pointer;
      }
      img {
        display: block;
        max-width: 100%;
        height: 100%;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .actions-wrap {
      top: 12px;
      right: 12px;
      position: absolute;
      display: flex;
      .ant-btn {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

class MainImageSelector extends Component {
  state = {
    deleteImages: {},
    showModal: false,
  };

  handleDelete = (id) => {
    let { deleteImages } = this.state;
    this.setState({
      deleteImages: {
        ...deleteImages,
        [id]: !deleteImages[id],
      },
    });
  };

  componentDidUpdate(__, prevState) {
    let { deleteImages } = this.state;
    let { onChangeDeleteImg } = this.props;
    if (prevState.deleteImages !== deleteImages) {
      let ids = [];
      for (let [key, value] of Object.entries(deleteImages)) {
        if (value) {
          ids.push(key);
        }
      }
      if (onChangeDeleteImg) {
        onChangeDeleteImg(ids);
      }
    }
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const { images, deleteImg } = this.props;
    if (!images) {
      return <div />;
    }

    return (
      <Container>
        {images.map((img, index) => {
          let src;
          if (img.file) {
            src = img.file.thumbnailUrl ? img.file.thumbnailUrl : img.file.url;
          } else if (img.thumbnailUrl || img.url) {
            src = img.thumbnailUrl ? img.thumbnailUrl : img.url;
          }

          let srcOrigin;
          if (img.file) {
            srcOrigin = img.file.url ? img.file.url : img.file.thumbnailUrl;
          } else if (img.url || img.thumbnailUrl) {
            srcOrigin = img.url ? img.url : img.thumbnailUrl;
          }

          return (
            <React.Fragment key={`img-${index}`}>
              <div className="item-wrap" key={`img-${index}`}>
                <div
                  onClick={() => {
                    if (!deleteImg.includes(img.id)) {
                      this.props.onChange(img.id);
                    }
                  }}
                  className={classNames("item", {
                    selected: img.id === this.props.value,
                    overlay:
                      deleteImg &&
                      deleteImg.includes(img.id) &&
                      img.id !== this.props.value,
                  })}
                >
                  <img src={src} alt={""} key={index} />
                </div>
                <div className="actions-wrap">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => {
                      this.setState({
                        currentImgShow: img.id,
                      });
                      this.toggleModal();
                    }}
                  />
                  {this.props.value && img.id !== this.props.value && (
                    <Button
                      icon={
                        deleteImg && deleteImg.includes(img.id) ? (
                          <RetweetOutlined />
                        ) : (
                          <DeleteOutlined />
                        )
                      }
                      // className="delete-btn"
                      onClick={() => this.handleDelete(img.id)}
                    />
                  )}
                </div>
              </div>
              <Modal
                footer={null}
                onCancel={this.toggleModal}
                visible={
                  this.state.showModal && this.state.currentImgShow === img.id
                }
                bodyStyle={{ textAlign: "center" }}
                centered
                width="70%"
                wrapClassName="modal-image-assgin"
              >
                <div className="modal-image-assgin-inner">
                  <img
                    style={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={srcOrigin}
                    alt=""
                  />
                  <a href={srcOrigin} target="_blank" rel="noopener noreferrer">
                    View origin
                  </a>
                </div>
              </Modal>
            </React.Fragment>
          );
        })}
      </Container>
    );
  }
}

MainImageSelector.propTypes = {
  onChange: PropTypes.func,
  images: PropTypes.any,
  value: PropTypes.any,
};

export default MainImageSelector;
