import { gql } from "apollo-boost";

export const BULK_CHANGE_TITLE_PRODUCT_CRAWL_ITEM = gql`
    mutation bulkChangeTitleProductCrawlItem(
        $ids: [ID!]!
        $title: String!
        $fbTitle: String
        $amzTitle: String
    ) {
        bulkChangeTitleProductCrawlItem(
            ids: $ids
            title: $title
            fbTitle: $fbTitle
            amzTitle: $amzTitle
        ) {
            id
            title
            fbTitle
            amzTitle
        }
    }
`;

export const CREATE_COMPLEX_ASSORTMENT = gql`
    mutation createComplexAssortment(
        $storeIds: [ID!]
        $items: [NewComplexAssortmentItem!]!
    ) {
        createComplexAssortment(storeIds: $storeIds, items: $items)
    }
`;

export const ASSIGN_COMPLEX_PRODUCT = gql`
    mutation assignComplexProduct($input: NewAssignComplexProduct!) {
        assignComplexProduct(input: $input) {
            id
            title
            sku
        }
    }
`;

export const DELETE_COMPLEX_PRODUCT_CRAWL_ITEM = gql`
    mutation deleteComplexProductCrawlItem($id: ID!) {
        deleteComplexProductCrawlItem(id: $id)
    }
`;

export const ASSIGN_TO_TEMPLATE = gql`
    mutation assignToTemplate($input: NewAssignToTemplate!) {
        assignToTemplate(input: $input) {
            products {
                id
                title
                sku
                description
                designStatus
                personalized
                status
                productBases {
                    id
                    title
                }
                images {
                    id
                    productBaseId
                    file {
                        id
                        url
                        thumbnailUrl
                    }
                }
                tags {
                    id
                    name
                }
                collections {
                    id
                    name
                }
                fields {
                    id
                    title
                    name
                    type
                    extraFee
                    configure
                    sorting
                }
            }
            success
            failed
        }
    }
`;
