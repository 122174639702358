import React, { Component } from "react";
import { Modal, Carousel, Spin, Button, Slider } from "antd";
import styled from "styled-components";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import { includes } from "lodash";

import { handleError } from "../../helper";
import { ImageTypes, META_DATA } from "../../variable";
import AcceptDesignAction from "../tasks/AcceptDesignAction";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";

const GET_VALUE = gql`
  query getFieldValueForOrder($orderId: ID!) {
    getFieldValueForOrder(orderId: $orderId) {
      id
      createdAt
      field {
        id
        name
        title
        type
        extraFee
        configure
        sorting
      }
      value
      order {
        id
        product {
          id
          images {
            id
            file {
              id
              url
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex !important;
  justify-content: center;
  max-height: 90vh;
  width: 100%;
  overflow: auto;
`;
const SlideContainer = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .ant-carousel .slick-dots li button {
    background: #1a202c;
  }
  .ant-carousel .slick-dots li,
  .ant-carousel .slick-dots li.slick-active,
  .ant-carousel .slick-dots li button {
    width: 16px;
    height: 16px;
  }
  .ant-carousel .slick-dots li button {
    border-radius: 100%;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 8px;
  }
`;

const ImageContainer = styled.div`
  .img-wrap {
    box-shadow: var(
      --p-card-shadow,
      0 0 2px 2px rgba(63, 60, 68, 0.1),
      0 1px 3px 0 rgba(63, 63, 68, 0.3)
    );
  }
  .compare-design_btn {
    margin-top: 1rem;
  }
`;

const CompareDesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .header_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    padding-right: 2rem;
    .header {
      width: 50%;
      h2 {
        font-weight: 500;
        font-size: 2rem;
      }
    }
    .change-opacity {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      h3 {
        margin-left: 6px;
      }
      .change-opacity_inner {
        width: 300px;
      }
    }
  }
  .imgs_wrap {
    position: relative;
    .img_item {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 8900px;
      max-height: 10000px;
    }
  }
  .personalized-info {
    margin-bottom: 1.6rem;
    margin-top: -1.6rem;
    h4 {
      font-weight: 500;
      font-size: 1.2em;
      display: inline-block;
    }
    .personalized-inner {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      row-gap: 2rem;
      flex-wrap: wrap;
      justify-content: flex-start;
      label.label {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        font-size: 1em;
        margin-right: 1rem;
      }
    }
  }
  .custom-btn-accpet {
    padding: 0;
    .cursor-pointer.items-center {
      padding: 4px 15px;
      span.anticon {
        display: none;
      }
    }
  }
`;

const displayFlexCSS = {
  display: "flex",
  alignItems: "center",
  justifyCntent: "center",
};
const modalImageInfoStyle = {
  fontSize: "12px",
  paddingLeft: "10px",
};
const marginBottonCSS = {
  marginBottom: "5px",
};

class ModalImageClaim extends Component {
  state = {
    showModal: false,
    loaded: false,
    dimensions: {},
    showModalCompareDesign: false,
    inputSlider: 100,
  };

  checkRealDimension = (designPosition, realWidth, realHeight) => {
    let realDmOk = false;

    if (
      designPosition &&
      designPosition.description &&
      designPosition.description.length > 0 &&
      realWidth &&
      realHeight
    ) {
      let originDesc = designPosition.description;

      realWidth = parseInt(realWidth);
      realHeight = parseInt(realHeight);

      if (originDesc) {
        let splitDesc = originDesc.split("|");
        if (
          Array.isArray(splitDesc) &&
          splitDesc[0] &&
          splitDesc[0].length > 0
        ) {
          let dimensionDesc = splitDesc[0];

          // Case: no dimension
          const dimensionValid = /px|cm|in/i;
          if (!dimensionValid.test(dimensionDesc)) {
            return true;
          }
          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/,/g, "");
          dimensionDesc = dimensionDesc.replace(/px/g, "");
          dimensionDesc = dimensionDesc.replace(/cm/g, "");
          dimensionDesc = dimensionDesc.replace(/\s+/g, "");
          if (dimensionDesc.length > 0) {
            let dmDesc = dimensionDesc.split("x");
            if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
              let splitWidth = dmDesc && dmDesc[0] ? parseInt(dmDesc[0]) : 0;
              let splitHeight = dmDesc && dmDesc[1] ? parseInt(dmDesc[1]) : 0;
              if (
                splitWidth > 0 &&
                splitHeight > 0 &&
                splitWidth === realWidth &&
                splitHeight === realHeight
              ) {
                realDmOk = true;
              }
            }
          }
        }
      }
    }
    return realDmOk;
  };

  imageLoadHandler = (isCompareDesign, originUrl, imgIndex, designPosition) => {
    if (isCompareDesign) {
      let img = new Image();
      img.src = originUrl;
      img.onload = () => {
        let newDimensions = this.state.dimensions;
        if (!newDimensions[imgIndex]) {
          let height = img && img.height ? img.height : "";
          let width = img && img.width ? img.width : "";

          newDimensions[imgIndex] = {
            id: imgIndex,
            height: height,
            width: width,
            isRealDimensionOk: this.checkRealDimension(
              designPosition,
              width,
              height
            ),
          };
          this.setState({ dimensions: newDimensions });
        }
      };
    }
  };

  toggleModalCD = () => {
    this.setState((prev) => ({
      showModalCompareDesign: !prev.showModalCompareDesign,
    }));
  };

  onChangeSlider = (value) => {
    this.setState({
      inputSlider: value,
    });
  };

  render() {
    const {
      files,
      limit,
      width,
      height,
      inline,
      isCompareDesign,
      designPosition,
      hasShadow,
      hasCompareDesign,
      orderId,
      taskId,
      refetch,
    } = this.props;

    const { inputSlider, showModalCompareDesign } = this.state;
    const images = files.files ? files.files : [];

    let artworkGuide;
    if (
      designPosition &&
      designPosition.artworkGuidelines &&
      designPosition.artworkGuidelines.length
    ) {
      artworkGuide = designPosition.artworkGuidelines
        .filter((i) => i.file)
        .filter(Boolean)
        .find((i) => ImageTypes.includes(i.file.mimeType));
    }
    let artworkGuideUrl =
      artworkGuide && artworkGuide.file && artworkGuide.file.url
        ? artworkGuide.file.url
        : "";

    let urlTest = images && images.filter((i) => i.url).filter(Boolean)[0];
    urlTest = urlTest?.url;

    return (
      <ImageContainer>
        {files &&
          images &&
          images.filter(Boolean).map((file, index) => (
            <React.Fragment key={index}>
              {limit && limit > 0 && limit < index + 1 ? null : (
                <div
                  key={file.id}
                  className="modal-image-item"
                  style={designPosition ? displayFlexCSS : null}
                >
                  <div className="modal-image">
                    <img
                      className={
                        "cursor-pointer " +
                        (hasShadow ? "img-wrap " : "") +
                        (inline ? "inline-block" : "block")
                      }
                      src={file.url || file.thumbnailUrl}
                      alt={""}
                      key={file.id}
                      style={{
                        width: width || 60,
                        height: height || 60,
                        objectFit: "cover",
                        margin: 5,
                      }}
                      onClick={() => this.setState({ showModal: true })}
                      onLoad={this.imageLoadHandler(
                        isCompareDesign,
                        file.url,
                        file.id,
                        designPosition
                      )}
                    />
                  </div>
                  {designPosition && (
                    <div
                      className="modal-image-info"
                      style={designPosition ? modalImageInfoStyle : null}
                    >
                      {designPosition.description && (
                        <p style={marginBottonCSS}>
                          Requirement:{" "}
                          <strong>{designPosition.description}</strong>
                        </p>
                      )}
                      {this.state &&
                        this.state.dimensions &&
                        this.state.dimensions[file.id] &&
                        this.state.dimensions[file.id].height && (
                          <p>
                            Real Dimissions:{" "}
                            <strong>
                              {this.state.dimensions[file.id].width} x{" "}
                              {this.state.dimensions[file.id].height}px
                              {this.state.dimensions[file.id]
                                .isRealDimensionOk && (
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "16px",
                                  }}
                                >
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </span>
                              )}
                            </strong>
                          </p>
                        )}
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        {hasCompareDesign && artworkGuideUrl && (
          <div className="compare-design_btn">
            <Button onClick={this.toggleModalCD}>Compare with artguide</Button>
          </div>
        )}
        <Modal
          visible={this.state.showModal}
          footer={null}
          onCancel={() => this.setState({ showModal: false })}
          bodyStyle={{ textAlign: "center" }}
          centered
          width="70%"
        >
          <SlideContainer>
            <Carousel>
              {images && images.length > 0
                ? images.filter(Boolean).map((file, index) => (
                    <Container key={`file-${index}`}>
                      {!this.state.loaded && <Spin />}
                      <div className="media-wraper">
                        <img
                          src={
                            file.url
                              ? file.url
                              : file.thumbnailUrl
                              ? file.thumbnailUrl
                              : null
                          }
                          alt={""}
                          style={{ height: "auto", maxWidth: "760px" }}
                          onLoad={() => this.setState({ loaded: true })}
                          onError={() => this.setState({ loaded: true })}
                        />
                        <div
                          className="media-meta mt-8"
                          // style={{ alignSelf: "flex-end" }}
                        >
                          {file.name && (
                            <div className="media-meta-name">{file.name}</div>
                          )}
                          {file.url && (
                            <div className="media-meta-link">
                              <CustomLinkPolaris
                                href={
                                  file.url
                                    ? file.url
                                    : file.thumbnailUrl
                                    ? file.thumbnailUrl
                                    : null
                                }
                                file={file}
                              >
                                View origin
                              </CustomLinkPolaris>
                            </div>
                          )}
                        </div>
                      </div>
                    </Container>
                  ))
                : null}
            </Carousel>
          </SlideContainer>
        </Modal>

        <Modal
          visible={showModalCompareDesign}
          footer={null}
          onCancel={this.toggleModalCD}
          bodyStyle={{ textAlign: "center" }}
          centered
          width="auto"
        >
          <CompareDesignContainer>
            <Query query={GET_VALUE} variables={{ orderId }}>
              {({ data, error, loading }) => {
                let value = data && data.getFieldValueForOrder;

                return (
                  <>
                    <div className="header_wrap">
                      <div className="header">
                        <h2>Compare with artguide</h2>
                      </div>
                      <div className="change-opacity">
                        <div className="change-opacity_inner">
                          <h3>Change opacity:</h3>
                          <Slider
                            min={1}
                            max={100}
                            onChange={this.onChangeSlider}
                            value={
                              typeof inputSlider === "number" ? inputSlider : 0
                            }
                          />
                        </div>
                      </div>
                      <div className="action-accept">
                        <Button
                          onClick={(e) => e.preventDefault()}
                          className="custom-btn-accpet"
                        >
                          <AcceptDesignAction
                            task={{ id: taskId, orderId }}
                            refetch={refetch}
                          />
                        </Button>
                      </div>
                    </div>
                    {loading ? (
                      <Spin />
                    ) : error ? (
                      <div>Error: {handleError(error.toString())}</div>
                    ) : (
                      <>
                        {value && value.length > 0 ? (
                          <div className="personalized-info">
                            <h4>Personalized info:</h4>
                            <div className="personalized-inner">
                              {value.map((item, index) => {
                                let label =
                                  item && item.field
                                    ? item.field.title
                                      ? item.field.title
                                      : item.field.name
                                      ? item.field.name
                                      : item.field.name
                                    : null;
                                let value = item.value;
                                let hasImg = includes(
                                  value.length && value[0],
                                  META_DATA
                                );
                                value =
                                  value &&
                                  Array.isArray(value) &&
                                  value.join(", ");

                                return (
                                  <div key={index}>
                                    <label className="label">{label}:</label>
                                    {!hasImg ? <span style={{ wordBreak: 'break-all'}}>{value}</span> : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}

                    <div className="imgs_wrap">
                      <img
                        src={urlTest}
                        alt=""
                        style={{
                          opacity: `${inputSlider * 0.01}`,
                          position: "absolute",
                          zIndex: 1,
                        }}
                        className="img_item"
                      />
                      <img
                        alt=""
                        src={artworkGuideUrl}
                        style={{
                          position: "static",
                          opacity: "0.25",
                          zIndex: 0,
                        }}
                        className="img_item"
                      />
                    </div>
                  </>
                );
              }}
            </Query>
          </CompareDesignContainer>
        </Modal>
      </ImageContainer>
    );
  }
}

export default ModalImageClaim;
