import React, { useState } from "react";
import { Modal, Input, notification } from "antd";

import MediaSelectorButton from "../supplier/MediaSelectorButton";

const { TextArea } = Input;

export const ArtWorkGuideline = ({ index, visible, showModal, onChange }) => {
  const [file, setFile] = useState({
    fileId: null,
    name: null,
    description: null,
  });

  return (
    <Modal
      key={index}
      visible={visible}
      onOk={() => {
        if (file.fileId) {
          if (onChange) {
            onChange(file);
          }
          showModal();
        } else {
          notification.error({ message: "Please choose file" });
        }
      }}
      onCancel={showModal}
      width={"850px"}
      title="Add artwork guideline"
    >
      <div className="flex">
        <div>
          <span>File</span>
          <MediaSelectorButton
            width="100"
            name="Upload"
            value={[]}
            onChange={(files) => {
              if (files.length) {
                setFile((prevState) => {
                  return {
                    ...prevState,
                    fileId: files[files.length - 1].id,
                    name: files[files.length - 1].name,
                  };
                });
              }
            }}
            accept={"image/*, application/postscript"}
            singleUpload={true}
            listType={"picture-card"}
            multiple={false}
          />
        </div>
        <div style={{ width: "60%", marginLeft: "2rem" }}>
          <label>Description</label>
          <TextArea
            rows={4}
            style={{ marginTop: 10 }}
            defaultValue={""}
            onChange={(e) => {
              setFile({
                ...file,
                description: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
