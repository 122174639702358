import { Page } from "@shopify/polaris";
import React, { useMemo } from "react";
import { ProductBases } from "../../components/seller/components";
import { WrapperPage } from "../../components/shared/WrapperPage";
import { getPathFromRouter } from "../../helper";

export function ProductBasesPage(...props) {
  const path = useMemo(() => {
    return getPathFromRouter(props);
  }, [props]);

  return (
    <WrapperPage>
      <Page
        title="Product Bases"
        fullWidth
        primaryAction={{
          content: "Add Base",
          url: "/seller/product-bases/add",
        }}
      >
        <ProductBases path={path} />
      </Page>
    </WrapperPage>
  );
}
