import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    Card,
    Button,
    Modal,
    Labelled,
    ButtonGroup,
    TextStyle,
    Checkbox,
    Stack,
} from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { forOwn } from "lodash";
import styled from "styled-components";

import useToggle from "../../../../hooks/useToggle";
import noImg from "../../../../assets/images/unnamed.jpeg";

import { ChooseBase } from "./index";
import { strToSlug } from "../../../../helper";
import { Step, useCreateProduct } from "./context";
import { ColorsComponent } from "../../../seller/ColorsComponent";
import { SimpleCustomizePolaris } from "../../../customize/SimpleCustomizePolaris";
import { PrintFileComp, updateBases } from "./PrintFileComp";

export function BasesSection({ setErrorDimensions }) {
    // Context
    const {
        state: {
            fieldsValue: { personalized, fields, using2dMockup },
            selectedBases,
            template,
            canUpdateDescription,
        },
        updateField,
        updateState,
    } = useCreateProduct();

    useEffect(() => {
        if (
            template == null &&
            selectedBases?.length > 0 &&
            canUpdateDescription
        ) {
            let description;
            if (selectedBases.length === 1) {
                description = selectedBases[0].defaultContent;
            } else {
                description = selectedBases.reduce((acc, curr) => {
                    let content = `<div><strong>${curr.title}<strong></div>${curr.defaultContent}`;
                    acc += content;
                    return acc;
                }, "");
            }
            updateField(description, "description");
        }
    }, [selectedBases, template, updateField]);

    // State
    const [open, toggleOpen] = useToggle(false);

    // Handle action
    const handleFieldsChange = useCallback(
        (value) => {
            updateField([...value], "fields");
        },
        [updateField]
    );

    const handlePersonalized = useCallback(
        (value) => {
            updateField(value, "personalized");
        },
        [updateField]
    );

    const handleReviewChange = useCallback(
        (value) => {
            const newValue = {
                ...using2dMockup,
                isReview: value,
            };

            updateField(newValue, "using2dMockup");
        },
        [using2dMockup, updateField]
    );

    const handleDelete = useCallback(
        (id) => {
            const clone = [...selectedBases];
            const index = clone.findIndex((i) => i.id === id);
            let redirect = clone.length === 1 && index !== -1;
            if (index !== -1) {
                clone.splice(index, 1);
            }

            let valueToUpdate = {
                selectedBases: clone,
                ...(redirect ? { step: Step.ChooseBase } : {}),
            };
            updateState(valueToUpdate);
        },
        [selectedBases, updateState]
    );

    return (
        <Card title="Product bases">
            {selectedBases?.length > 0
                ? selectedBases.map((base, index) => (
                      <Card.Section key={`base-${index}`}>
                          <BaseComp
                              base={base}
                              selectedBases={selectedBases}
                              handleDelete={handleDelete}
                              setErrorDimensions={setErrorDimensions}
                          />
                      </Card.Section>
                  ))
                : null}
            <Card.Section>
                <Stack vertical spacing="loose">
                    <Button
                        children="Add product base"
                        icon={CirclePlusMinor}
                        onClick={toggleOpen}
                    />
                    <Checkbox
                        label="Review mockup"
                        checked={!!using2dMockup.isReview}
                        onChange={handleReviewChange}
                    />
                    <div>
                        <Checkbox
                            label="This is a personalized product"
                            checked={personalized}
                            onChange={handlePersonalized}
                        />
                        {personalized ? (
                            <SimpleCustomizePolaris
                                value={fields}
                                onChange={handleFieldsChange}
                            />
                        ) : null}
                    </div>
                </Stack>
            </Card.Section>
            <AddBase open={open} onClose={toggleOpen} />
        </Card>
    );
}

function AddBase({ open, onClose }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Select Bases"
            large
            primaryAction={{ content: "Done", onAction: onClose }}
        >
            <ChooseBase inModal />
        </Modal>
    );
}

function BaseComp({ base, handleDelete, selectedBases, setErrorDimensions }) {
    // Props
    const baseId = base?.id;

    // Markup
    const title = base?.title;
    const images = base?.images;
    const image = images?.length > 0 ? images[0] : null;

    const url = image
        ? image.thumbnailUrl
            ? image.thumbnailUrl
            : image.url
            ? image.url
            : null
        : null;

    const buttonRemove = useMemo(
        () => (
            <Button
                children="Delete"
                destructive
                plain
                onClick={() => handleDelete(baseId)}
            />
        ),
        [handleDelete, baseId]
    );

    const action = { content: buttonRemove };
    const actionProp = { action };

    return (
        <Wrapper>
            <div className="base-content">
                <Labelled
                    label={<TextStyle children={title} variation="strong" />}
                    {...actionProp}
                />
                <div className="base-wrap">
                    <img src={url || noImg} className="base-image" alt="" />
                    <AttributesByBaseContainer base={base} />
                </div>
            </div>
            <div className="print-file">
                <PrintFileComp
                    base={base}
                    setErrorDimensions={setErrorDimensions}
                />
            </div>
        </Wrapper>
    );
}

function AttributesByBaseContainer({ base }) {
    // Context
    const {
        updateState,
        state: { selectedBases, isEdit },
    } = useCreateProduct();

    return (
        <AttributesByBase
            updateState={updateState}
            selectedBases={selectedBases}
            isEdit={isEdit}
            base={base}
        />
    );
}

const SLUG_COLOR = "color";
const SLASH_REG = /\/$/;
export const AttributesByBase = forwardRef(function AttributesByBase(
    {
        base,
        updateState,
        selectedBases,
        isEdit,
        forceUpdate,
        toggleForceUpdate,
        disabledAll,
        setChanged,
    },
    ref
) {
    // State
    const [state, setState] = useState({
        productAttributes: {},
        firstColor: null,
        variants: [],
    });
    const baseRef = useRef(false);

    // Handle actions
    const countActivatedVariants = useCallback((variants = []) => {
        return variants?.length > 0
            ? variants.reduce((acc, cur) => {
                  return cur?.productBaseVariant?.attributes?.length > 0 &&
                      !cur.disabled
                      ? acc + 1
                      : acc;
              }, 0)
            : 0;
    }, []);

    const getProductVariants = useCallback(
        (base, product = {}, enableFirst, firstColor, special) => {
            if (!base.variants) {
                return [];
            }

            return base.variants.map((variant, idx) => {
                const typeBoolean = typeof variant.disabled === "boolean";
                let disabledVal = typeBoolean ? variant.disabled : true;
                if (variant.attributes?.length > 0 && enableFirst) {
                    variant.attributes.forEach((att) => {
                        if (
                            att.slug === SLUG_COLOR &&
                            att.option?.toLowerCase() ===
                                firstColor?.toLowerCase()
                        ) {
                            disabledVal = false;
                        }
                    });
                }

                let baseVarId = variant.id;
                let i = idx;
                if (Object.keys(product).length) {
                    i = (product.variants || []).findIndex(
                        (node) => node.productBaseVariantID === baseVarId
                    );
                }

                // Fix `iterate` productBaseVariant
                let productBaseVariantId = variant.id;
                let productBaseVariant = variant;

                if (
                    productBaseVariantId == null &&
                    variant.productBaseVariantId != null
                ) {
                    productBaseVariantId = variant.productBaseVariantId;
                    productBaseVariant = variant.productBaseVariant;
                }

                const disabled =
                    (enableFirst && firstColor) || typeBoolean
                        ? disabledVal
                        : Object.keys(product).length
                        ? product.variants[i].disabled
                        : productBaseVariant?.disabled
                        ? productBaseVariant.disabled
                        : false;

                return special
                    ? {
                          ...variant,
                          disabled: disabled,
                      }
                    : {
                          productBaseVariantId,
                          productBaseVariant,
                          regularPrice: Object.keys(product).length
                              ? product.variants[i].regularPrice
                              : variant.regularPrice,
                          disabled: disabled,
                      };
            });
        },
        []
    );

    const getBaseAttrs = useCallback(
        (baseAttrs, activateAttrs, variants) => {
            let pBaseAttrs = {},
                disabledAll = false;

            if (variants != null) {
                let countActivated = countActivatedVariants(variants);
                if (!countActivated) {
                    disabledAll = true;
                }
            }

            if (baseAttrs?.length > 0) {
                pBaseAttrs = baseAttrs.reduce((accAttr, val) => {
                    if (!val) {
                        return accAttr;
                    }
                    let enableFirst =
                        !activateAttrs && !variants && SLUG_COLOR === val.slug;
                    let attrOptions = val.options.reduce((accOption, opt) => {
                        let disabledVal = false;
                        if (disabledAll) {
                            disabledVal = true;
                        }

                        if (activateAttrs?.[val.slug]?.length > 0) {
                            disabledVal = true;
                            if ([...activateAttrs[val.slug]].includes(opt)) {
                                disabledVal = false;
                            }
                        }

                        if (enableFirst) {
                            let [firstVal] = val.options;

                            disabledVal = opt !== firstVal;
                        }

                        // Replace label `/`
                        let label = opt?.trim();
                        label = label.replace(SLASH_REG, "");
                        return {
                            ...accOption,
                            [strToSlug(opt)]: {
                                label: label,
                                disabled: disabledVal,
                            },
                        };
                    }, {});

                    return {
                        ...accAttr,
                        [val.slug]: {
                            name: val.name,
                            slug: val.slug,
                            options: attrOptions,
                        },
                    };
                }, {});
            }

            return pBaseAttrs;
        },
        [countActivatedVariants]
    );

    const updateBase = useCallback(
        (value, id) => {
            const newSelectedBases =
                selectedBases?.length > 0 ? selectedBases : [];

            const newBases = updateBases([...newSelectedBases], base, {
                key: id,
                value,
            });
            updateState({
                selectedBases: newBases,
                canUpdateDescription: false,
            });
        },
        [selectedBases, base, updateState]
    );

    const toggleActivatedAttr = useCallback(
        (attrsSlug, optionSlug, optionLabel) => {
            let newAttributes = state.productAttributes;
            const options = newAttributes[attrsSlug]["options"];
            const disabledVal = options[optionSlug]["disabled"];
            options[optionSlug]["disabled"] = !disabledVal;

            let newVariants = state.variants;

            // const productAttributes = state.productAttributes;
            let countAttrs = Object.keys(newAttributes).length;

            // Get list active options of every attribute
            let attrActivatedOptions = {};
            if (countAttrs > 1) {
                attrActivatedOptions = Object.entries(newAttributes).reduce(
                    (acc, [key, el]) => {
                        const optionValues = Object.values(el.options);
                        let optionActivated = optionValues.reduce(
                            (acc, val) => {
                                return !val.disabled
                                    ? [...acc, val.label]
                                    : acc;
                            },
                            []
                        );

                        return {
                            ...acc,
                            [key]: optionActivated,
                        };
                    },
                    {}
                );
            }

            let affectKeys = [];
            newVariants = newVariants.map((el, index) => {
                const attributes = el.productBaseVariant.attributes;
                let newDisabledVar;

                if (attributes?.length > 0) {
                    attributes.forEach((v) => {
                        if (v.slug === attrsSlug && v.option === optionLabel) {
                            affectKeys.push({
                                key: index,
                                disabled: el.disabled,
                            });

                            newDisabledVar = !disabledVal;

                            // If product has more than 2 attributes, then check complex condition active variants.
                            if (countAttrs > 1 && disabledVal) {
                                // Only check case active attribute.

                                let countMatch = 0;
                                attributes.forEach((at) => {
                                    let atSlug = at.slug;
                                    let atOption = at.option;
                                    let attrOptionsActivated =
                                        attrActivatedOptions[atSlug];
                                    if (
                                        attrOptionsActivated?.includes(atOption)
                                    ) {
                                        countMatch++;
                                    }
                                });

                                if (countMatch !== countAttrs) {
                                    newDisabledVar = disabledVal;
                                }
                            }
                        }
                    });
                }

                el.disabled =
                    newDisabledVar != null ? newDisabledVar : el.disabled;
                return el;
            });

            // Count product to prevent disabled all variants
            let countActivated = countActivatedVariants(newVariants);
            if (0 === countActivated) {
                options[optionSlug]["disabled"] = disabledVal;
                if (affectKeys.length > 0) {
                    affectKeys.forEach((v) => {
                        newVariants[v.key].disabled = v.disabled;
                    });
                }

                alert("You must keep at least one option for each attributes");
            }

            setState(
                (prev) => ({
                    ...prev,
                    variants: [...newVariants],
                    productAttributes: newAttributes,
                }),
                []
            );

            updateBase(newVariants, "variants");
        },
        [state, countActivatedVariants, updateBase]
    );

    const countVariant = useCallback((variants = []) => {
        if (variants.length === 0) return null;
        let countEnabled = 0,
            countAll = variants?.length;
        countEnabled = variants.reduce((acc, cur) => {
            if (!cur.disabled) {
                acc++;
            }

            return acc;
        }, 0);

        return {
            countEnabled,
            countDisabled: countAll - countEnabled,
        };
    }, []);

    const getActivatedProductAttrs = (variants) => {
        let activatedAttrs = {};

        forOwn(variants, function (el) {
            if (
                !el.disabled &&
                el.productBaseVariant &&
                el.productBaseVariant.attributes &&
                el.productBaseVariant.attributes.length > 0
            ) {
                el.productBaseVariant.attributes.forEach((v) => {
                    if (!activatedAttrs[v.slug]) {
                        activatedAttrs[v.slug] = [];
                    }
                    activatedAttrs[v.slug].push(v.option);
                });
            }
        });
        return activatedAttrs;
    };

    // Get data
    const handleT = useCallback(
        (isEdit, special) => {
            const productAttributes = getBaseAttrs(
                base.attributes,
                isEdit ? getActivatedProductAttrs(base.variants) : undefined,
                isEdit ? base.variants : undefined
            );

            let firstColor;
            if (base?.attributes?.length > 0) {
                base.attributes.forEach((att) => {
                    if (SLUG_COLOR === att.slug && att.options?.length > 0) {
                        firstColor = att.options[0];
                    }
                });
            }

            const baseVariants = getProductVariants(
                base,
                {},
                !isEdit,
                firstColor,
                special
            );
            setState((prev) => ({
                ...prev,
                productAttributes,
                firstColor,
                variants: baseVariants,
            }));

            updateBase(baseVariants, "variants");
            baseRef.current = true;
            toggleForceUpdate && toggleForceUpdate(false);
        },
        [base, updateBase, toggleForceUpdate, getBaseAttrs, getProductVariants]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            update: () => {
                if (base != null) {
                    handleT(true, true);
                }
            },
        }),
        [base, handleT]
    );

    useEffect(() => {
        if (forceUpdate) {
            handleT(true, true);
        }
    }, [forceUpdate, handleT]);

    useEffect(() => {
        if (base != null && !baseRef.current) {
            handleT(isEdit);
        }
    }, [base, isEdit, handleT]);

    // Markup
    const attributesMarkup = useMemo(() => {
        let attributesWrap = Object.entries(state.productAttributes).map(
            ([key, { name, options, slug }], index) => {
                if (!key || !options) return null;

                const enabledAttr = SLUG_COLOR === slug;

                const optionMarkup = Object.entries(options).map(
                    ([keyOpt, { label, disabled }]) => {
                        const textContent = enabledAttr ? (
                            <div className="option-wrap">
                                <ColorsComponent
                                    optionSlug={keyOpt}
                                    borderColor="#3f4eae"
                                />
                                {label}
                            </div>
                        ) : (
                            label
                        );

                        return (
                            <Button
                                key={`option-${keyOpt}`}
                                primary={!disabled}
                                size="slim"
                                children={textContent}
                                disabled={!!disabledAll}
                                onClick={() => {
                                    setChanged && setChanged(true);
                                    toggleActivatedAttr(key, keyOpt, label);
                                }}
                            />
                        );
                    }
                );

                const optionWrap =
                    optionMarkup?.length > 1 ? (
                        <ButtonGroup>{optionMarkup}</ButtonGroup>
                    ) : (
                        optionMarkup
                    );

                return (
                    <div className="attribute-wrap" key={`attribute-${index}`}>
                        <TextStyle variation="strong" title={key}>
                            {name} :
                        </TextStyle>
                        <div className={`options-wrap`}>{optionWrap}</div>
                    </div>
                );
            }
        );

        return attributesWrap;
    }, [state.productAttributes, toggleActivatedAttr, disabledAll, setChanged]);

    const countMarkup = useMemo(() => {
        const count = countVariant(state.variants);

        return count != null ? (
            <Stack vertical spacing="extraTight">
                <TextStyle variation="subdued">
                    Total enabled: {count.countEnabled}
                </TextStyle>
                <TextStyle variation="subdued">
                    Total disabled: {count.countDisabled}
                </TextStyle>
            </Stack>
        ) : null;
    }, [state.variants, countVariant]);

    return (
        <div className="attributes-wrap">
            {attributesMarkup}
            {countMarkup}
        </div>
    );
});

const Wrapper = styled.div`
    display: flex;
    column-gap: 2rem;

    .base-content {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.6rem 1.6rem;
        border: 0.2rem solid #dfe3e8;
        border-radius: 5px;
        flex: 1 1 60%;

        .base-image {
            width: 16rem;
            height: 16rem;
            object-fit: cover;
            border-radius: 5px;
            box-shadow: var(
                --p-card-shadow,
                0 0 0 1px rgba(63, 63, 68, 0.05),
                0 1px 3px 0 rgba(63, 63, 68, 0.15)
            );
        }

        .base-wrap {
            display: flex;
            flex-direction: row;
            column-gap: 2rem;
        }

        .attributes-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;

            .option-wrap {
                border-radius: 3px;
                font-weight: 500;
                display: flex;
                align-items: center;
            }
        }
    }

    .print-file {
        flex: 1 1 40%;

        .dp-wrap {
            display: flex;
            flex-direction: row;
            gap: 1.6rem;
            flex-wrap: wrap;

            .dp-inner {
                width: 16rem;
                .list-file_wrap {
                    height: 16rem;

                    .file_wrap {
                        width: 16rem;
                        height: 16rem;
                    }
                }
            }
        }
    }
`;
