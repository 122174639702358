import React, { useEffect, useState } from "react";
import { AutoCompletePolaris } from "../shared/AutoCompletePolaris";

export const ProductBasesSelectPolaris = (props) => {
  const { data, onChange } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const ffIds =
      data?.fulfillmentConfigs?.length > 0
        ? data.fulfillmentConfigs.map(({ fulfillmentId }) => fulfillmentId)
        : [];
    let newData =
      data?.fulfillments?.length > 0
        ? data.fulfillments
            .filter((f) => ffIds.includes(f.id))
            .map((f) => ({ value: f.id, label: f.name }))
        : [];
    newData = [{ value: null, label: "MerchBridge" }, ...newData];
    setOptions(newData);
    setValue(newData.length > 0 ? newData[0].value : null);
  }, [data]);

  return (
    <AutoCompletePolaris
      options={options}
      placeholder="Filter product base"
      value={value}
      onChange={onChange}
      label="Product base"
      required
    />
  );
};
