import { EmptyState } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

import noItems from "../../assets/images/no-items-found.png";

const Container = styled.div`
  &.slim {
    .Polaris-EmptyState {
      padding: 0;
    }
  }

  .Polaris-EmptyState__Image {
    background-color: #fff;
  }
`;

export const EmptyStatePolaris = ({ noData, slim }) => {
  return (
    <Container className={slim ? "slim" : ""}>
      <EmptyState
        heading={`${noData ? "No data" : "No items found"}`}
        image={noItems}
      >
        <p>Try changing the filters or search item</p>
      </EmptyState>
    </Container>
  );
};

export default EmptyStatePolaris;
