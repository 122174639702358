import React from "react";
import { Page } from "@shopify/polaris";

import { SwitchFulfillmentPolaris } from "../../components/admin/fulfillment/SwitchFulfillmentPolaris";

export const SwitchFulfillmentPage = () => {
    return (
        <Page title="Switch fulfillment" fullWidth>
            <SwitchFulfillmentPolaris />
        </Page>
    );
};
