import { useQuery, useMutation } from "@apollo/react-hooks";
import { Collapsible, Modal, Stack, TextField } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StoresSelectPolaris } from "../store/StoresSelectPolaris";
import { cloneByJSON, handleError } from "../../helper";
import styled from "styled-components";
import { MARK_PUSHED_TO_STORES } from "../../graphql/mutations";
import { useProductsPolarisContext } from "../../pages/seller/ProductsPagePolaris";

const QUERY_STORES = gql`
    query stores {
        stores(filter: { limit: -1, offset: 0 }) {
            nodes {
                id
                title
            }
        }
    }
`;

export function MarkAsPushed({ open, toggleOpen, value, refetch }) {
    // State
    const [stores, setStores] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [selectedStores, setSelectedStores] = useState([]);
    const timeoutRef = useRef(null);

    // Context
    const { toggleActive, setNotify } = useProductsPolarisContext();

    // Props
    const productID = value?.id;

    // Query
    const { data, error } = useQuery(QUERY_STORES, {
        skip: !open,
    });

    // Mutation
    const [markPushed, { loading }] = useMutation(MARK_PUSHED_TO_STORES, {
        onCompleted: () => {
            setNotify({
                msg: `Mark as pushed to stores successfully.`,
                err: false,
            });
            setSelectedStores([]);
            toggleOpen();

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                refetch && refetch();
            }, 1500);
        },
        onError: (err) => {
            setNotify({ msg: handleError(err.toString()), err: true });
        },
    });

    // Get data
    useEffect(() => {
        const nodes = data?.stores?.nodes || [];
        const stores = nodes.filter(Boolean).map((node) => {
            const { id, title } = node || {};
            return {
                value: id,
                label: title,
            };
        });
        setStores(stores);
    }, [data]);

    useEffect(() => {
        const matchStores = (stores || []).filter((s) =>
            storeId.includes(s.value)
        );
        setSelectedStores(matchStores);
    }, [storeId]);

    // Actions
    const handleChange = useCallback((storeIds) => setStoreId(storeIds), []);
    const handlePushedProductChange = useCallback(
        (pushedProductID, id) => {
            let cloneStores = cloneByJSON(selectedStores);
            cloneStores = cloneStores.map((b) => {
                if (b?.value === id) {
                    return {
                        ...b,
                        pushedProductID,
                    };
                }
                return b;
            });

            setSelectedStores(cloneStores);
        },
        [selectedStores]
    );
    const handleSubmit = useCallback(() => {
        if (productID != null) {
            toggleActive && toggleActive();
            setNotify && setNotify({ msg: null, err: false });

            const input = selectedStores.map((store) => {
                const { value, pushedProductID } = store || {};
                return {
                    storeID: value,
                    pushedProductID,
                };
            });

            markPushed({
                variables: {
                    productID,
                    input,
                },
            });
        }
    }, [selectedStores, productID, markPushed, toggleActive, setNotify]);

    return (
        <Modal
            title="Mark as Pushed"
            open={open}
            onClose={toggleOpen}
            sectioned
            large
            secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                disabled: selectedStores.length === 0,
                loading: loading,
            }}
        >
            <Wrapper>
                <Stack vertical>
                    <StoresSelectPolaris
                        stores={stores}
                        onChange={handleChange}
                        error={error}
                        value={storeId || []}
                        allowMultiple
                    />
                    <Collapsible
                        open={selectedStores.length > 0}
                        transition={{
                            duration: "150ms",
                            timingFunction: "ease",
                        }}
                    >
                        <div className="store-wrap header">
                            <span className="label">Store Name</span>
                            <div className="input">Pushed product ID</div>
                        </div>
                        {selectedStores.map((store, index) => {
                            const { label, pushedProductID = "", value } =
                                store || {};
                            const idx = index + 1;
                            return (
                                <div className={`store-wrap store-${idx}`}>
                                    <span title={label} className="label">
                                        {label}
                                    </span>
                                    <div className="input">
                                        <TextField
                                            value={pushedProductID}
                                            id={value}
                                            placeholder={`e.g. productId${idx}`}
                                            onChange={handlePushedProductChange}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Collapsible>
                </Stack>
            </Wrapper>
        </Modal>
    );
}

const Wrapper = styled.div`
    .store-wrap {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        + .store-wrap {
            margin-top: 1.6rem;
        }

        .label,
        .input {
            width: calc(50% - 0.5rem);
        }
    }
`;
