import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  MenuOutlined,
  MoreOutlined
} from "@ant-design/icons";
import { Button, Collapse, Input, notification, Table, Typography } from "antd";
import ld, { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component, useCallback, useMemo, useRef } from "react";
// Add Drag and drop
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import styled from "styled-components";
import MediaSelectorButton from "../../components/supplier/MediaSelectorButton";
import { AppContext } from "../../context";
import { copyToClipboard, getProductMockupFolder } from "../../helper";
import Price from "../Price";
import { ModalVariantsMissing } from "./components/ModalVariantsMissing";

const type = "DragableBodyRow";

const { Panel } = Collapse;

const BulkActionWrapper = styled.div`
  .ant-collapse {
    margin-bottom: 1rem;
    background-color: #fff;

    &-item {
      border-bottom-width: 0;
    }
  }

  .ant-collapse > .ant-collapse-item {
    > .ant-collapse-header {
      padding-left: 20px;
    }
    .ant-collapse-arrow {
      left: 0;
    }
  }
`;

const PME_SELLER_ID = "WEya0"; // pme-seller@merchbridge.com

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      if (moveRow) moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const { Title } = Typography;

const Container = styled.div`
  .ant-upload-list-picture-card-container {
    width: 100px;
    height: 100px;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    margin: 0;
    border: 0 none;
    width: 100px;
    height: 100px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100px;
    height: 100px;
  }
  .ant-upload-text {
    font-size: 10px;
  }
  .product-disabled-variants {
    .icon-drag {
      display: none;
    }
  }

  .thumb-wrap {
    > div {
      width: 300px;
    }
  }
`;
const { Column, ColumnGroup } = Table;

class ProductVariants extends Component {
  state = {
    value: [],
    enabledValues: [],
    disabledValues: [],
    isDrap: false,
    open: false,
  };

  componentDidMount() {
    let { value, productAttrs } = this.props;
    let valueSorted = value;
    let enabledValues = [],
      disabledValues = [];

    valueSorted.forEach((variant) => {
      let valDisabled = variant.disabled ? variant.disabled : false;
      if (!valDisabled) {
        enabledValues.push(variant);
      } else {
        disabledValues.push(variant);
      }
    });

    enabledValues = sortAttributes(enabledValues, productAttrs);
    disabledValues = sortAttributes(disabledValues, productAttrs);
    this.setState({
      value: valueSorted,
      enabledValues,
      disabledValues,
    });
  }

  componentDidUpdate(_prevProps, _prevState) {
    let { value, productAttrs } = this.props;
    let valueSorted = value;
    let enabledValues = [],
      disabledValues = [];

    valueSorted.forEach((variant) => {
      let valDisabled = variant.disabled ? variant.disabled : false;
      if (!valDisabled) {
        enabledValues.push(variant);
      } else {
        disabledValues.push(variant);
      }
    });

    enabledValues = sortAttributes(enabledValues, productAttrs);
    disabledValues = sortAttributes(disabledValues, productAttrs);
    if (
      (!ld.isEqual(enabledValues, this.state.enabledValues) ||
        !ld.isEqual(disabledValues, this.state.disabledValues)) &&
      !this.state.isDrap
    ) {
      this.setState(
        {
          value: valueSorted,
          enabledValues: enabledValues,
          disabledValues: disabledValues,
        },
        () => {
          if (this.props.sortVariant) {
            this.props.sortVariant({
              enabledValues,
              disabledValues,
              // isDrap: true,
            });
          }
        },
      );
    }
  }
  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    let { enabledValues, disabledValues } = this.state;
    const { productAttrs } = this.props;

    [enabledValues[dragIndex], enabledValues[hoverIndex]] = [
      enabledValues[hoverIndex],
      enabledValues[dragIndex],
    ];

    enabledValues = sortAttributes(enabledValues, productAttrs);
    disabledValues = sortAttributes(disabledValues, productAttrs);
    this.setState(
      {
        enabledValues: [...enabledValues],
        isDrap: true,
      },
      () => {
        if (this.props.sortVariant) {
          this.props.sortVariant({
            enabledValues,
            disabledValues,
            isDrap: true,
          });
        }
      },
    );
  };

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.props.onVariableDisabledChanged(value);
  };

  toggleOpen = () => {
    this.setState((prev) => ({ open: !prev.open }));
  };

  render() {
    let { sku, isCampaign, variantsMissing, isEditProduct } = this.props;
    const { currentUser } = this.context || {};
    const { id: userId } = currentUser || {};
    const canShowRegularPrice = PME_SELLER_ID === userId;
    const { value, disabledValues, enabledValues } = this.state;
    const showVariantsMissing = isEditProduct && variantsMissing?.length > 0;

    // value =
    //   value &&
    //   value.sort(
    //     (a, b) => a.productBaseVariant.sorting - b.productBaseVariant.sorting
    //   );
    // let enableValues = [],
    //   disabledValues = [];

    // value.forEach((variant) => {
    //   let valDisabled = variant.disabled ? variant.disabled : false;
    //   if (!valDisabled) {
    //     enableValues.push(variant);
    //   } else {
    //     disabledValues.push(variant);
    //   }
    // });

    const columns = [
      {
        title: <MenuOutlined />,
        render: () => <MoreOutlined />,
        key: "move",
        className: "icon-drag",
      },
      {
        title: "Thumb",
        key: "thumb",
        width: 150,
        render: (_, record) => {
          let images = record.image ? [record.image] : [];
          let amazonImages =
            record.amazonImages?.length > 0
              ? record.amazonImages
                  .map((i) => (i.file ? i.file : i))
                  .filter(Boolean)
              : [];

          if (amazonImages?.length > 0) {
            amazonImages = amazonImages.filter(
              (i) => i.id != record?.image?.id,
            );
            images = [...images, ...amazonImages];
          }

          let currentBaseVarID = record.productBaseVariantId;
          let i = ld.findIndex(value, {
            productBaseVariantId: currentBaseVarID,
          });
          return (
            <div className="thumb-wrap">
              <MediaSelectorButton
                folder={sku ? getProductMockupFolder(sku) : "undefined"}
                width="100"
                name="Upload"
                size="12"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                }}
                value={images}
                onChange={(files) => {
                  value[i].image = files.length ? files[0] : null;

                  // amazonImages field
                  value[i].amazonImages = files?.length > 0 ? files : undefined;
                  this.onChange(value);
                }}
                // singleUpload={true}
                // multiple={false}
                multiple
                accept={"image/*"}
                listType="picture-card"
              />
            </div>
          );
        },
      },
      {
        title: "Variant ID",
        key: "id",
        width: 200,
        render: (_, record) => {
          const id = get(record, "id", "");
          return (
            <Input.Group compact style={{ display: "inline-flex" }}>
              <Input value={id} disabled />
              <Button
                icon={<CopyOutlined />}
                onClick={() => {
                  copyToClipboard(id);
                  notification.success({ message: "Copied!" });
                }}
              />
            </Input.Group>
          );
        },
      },
      {
        title: "Variant",
        key: "variant",
        width: 150,
        render: (_, record) => (
          <div>
            {record.productBaseVariant &&
              record.productBaseVariant.attributes
                .map((a) => a.option)
                .join("/")}
          </div>
        ),
      },
      {
        title: "Regular Price",
        key: "regularPrice",
        width: 120,
        render: (_, record, index) => {
          let currentBaseVarID = record.productBaseVariantId;
          let i = ld.findIndex(value, {
            productBaseVariantId: currentBaseVarID,
          });
          return (
            <Price
              value={record.regularPrice}
              onChange={(newPrice) => {
                value[i].regularPrice = newPrice;
                this.onChange(value);
              }}
            />
          );
        },
      },
      {
        title: "Sale Price",
        key: "salePrice",
        width: 120,
        render: (_, record, index) => {
          let currentBaseVarID = record.productBaseVariantId;
          let i = ld.findIndex(value, {
            productBaseVariantId: currentBaseVarID,
          });

          return (
            <Price
              value={record.salePrice}
              onChange={(newPrice) => {
                value[i].salePrice = newPrice;
                this.onChange(value);
              }}
            />
          );
        },
      },
      {
        title: "Base cost",
        key: "baseCost",
        width: 120,
        render: (_, record) => {
          const pVId = get(record, "productBaseVariantId", "");

          if (!pVId) return null;
          let baseCost = "";
          if (isCampaign) {
            const variants = get(
              record,
              "productBaseVariant.productBase.variants",
              [],
            );
            const { sellerPrice } =
              variants.find(({ id }) => id === pVId) || {};
            baseCost = sellerPrice;
          } else {
            baseCost = get(record, "productBaseVariant.sellerPrice", "");
          }

          return <span>{baseCost}</span>;
        },
      },
      canShowRegularPrice && {
        title: "supplier Price",
        key: "supplierPrice",
        width: 120,
        render: (_, record) => {
          const supplierPrice = get(
            record,
            "productBaseVariant.supplierPricing[0].price",
            "",
          );
          return <span>{supplierPrice}</span>;
        },
      },
      {
        title: "Action",
        render: (_, record, index) => {
          let currentBaseVarID = record.productBaseVariantId;
          let i = ld.findIndex(value, {
            productBaseVariantId: currentBaseVarID,
          });
          let disabledVal = value[i]["disabled"];

          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={(that) => {
                if (!disabledVal) {
                  let countEnabled = 0;
                  ld.forOwn(value, function (el, key) {
                    if (!el.disabled) {
                      countEnabled++;
                    }
                  });
                  if (countEnabled > 1) {
                    value[i]["disabled"] = !disabledVal;
                  } else {
                    alert("You must keep at least one variant");
                  }
                } else {
                  value[i]["disabled"] = !disabledVal;
                }
                this.setState({ isDrap: false });
                this.onChange(value);
              }}
            >
              {!disabledVal ? (
                <DeleteOutlined />
              ) : (
                <span className="ant-btn-link">Restore</span>
              )}
            </span>
          );
        },
      },
    ].filter(Boolean);

    const tableWidth = ld.sum(columns.map((c) => c.width));
    return (
      <Container>
        <div className="product-avaiabled-variants">
          <div className="flex justify-between items-center">
            <Title level={3}>All variants</Title>
            {showVariantsMissing ? (
              <Button
                children="Check variants missing"
                onClick={this.toggleOpen}
              />
            ) : null}
          </div>
          <BulkAction
            data={enabledValues}
            value={value}
            onChange={this.onChange}
          />
          <DndProvider backend={HTML5Backend}>
            <Table
              pagination={false}
              rowKey={(_, index) => index}
              columns={columns}
              dataSource={enabledValues}
              className="p-table"
              scroll={{ x: tableWidth }}
              onRow={(record, index) => ({
                "data-variants_id": record.id,
                "data-variants_sku": record.sku,
                "data-pbase_variant_id": record.productBaseVariantId,
                index,
                moveRow: this.moveRow,
              })}
              components={this.components}
            >
              <ColumnGroup title="Variants" styled={{}}>
                <Column>{columns}</Column>
              </ColumnGroup>
            </Table>
          </DndProvider>
        </div>
        {disabledValues && disabledValues.length > 0 && (
          <div
            className="product-disabled-variants"
            style={{ marginTop: "30px" }}
          >
            <Title level={3}>Trashed Variants</Title>
            <BulkAction
              data={disabledValues}
              value={value}
              onChange={this.onChange}
            />
            <Table
              pagination={false}
              rowKey={(_, index) => index}
              columns={columns}
              dataSource={disabledValues}
              className="p-table"
              scroll={{ x: tableWidth }}
              onRow={(record, index) => ({
                "data-variants_id": record.id,
                "data-variants_sku": record.sku,
                "data-pbase_variant_id": record.productBaseVariantId,
              })}
            >
              <ColumnGroup title="Variants" styled={{}}>
                <Column>{columns}</Column>
              </ColumnGroup>
            </Table>
          </div>
        )}
        <ModalVariantsMissing
          open={this.state.open}
          onClose={this.toggleOpen}
          variants={variantsMissing}
          sku={sku}
          getProductMockupFolder={getProductMockupFolder}
          onChange={(newValues) => {
            let newValue = value;
            this.setState(
              (prev) => {
                newValue = [...(prev.value || []), ...(newValues || [])];
                return {
                  enabledValues: [
                    ...(prev.enabledValues || []),
                    ...(newValues || []),
                  ],
                  value: newValue,
                };
              },
              () => {
                this.onChange(newValue);
              }
            );
          }}
        />
      </Container>
    );
  }
}

ProductVariants.contextType = AppContext;

function BulkAction({ data, value, onChange }) {
  // State
  // const [regularPrice, setRegularPrice] = use

  const indexes = useMemo(() => {
    return data.map((item) => {
      const currentBaseVarID = item.productBaseVariantId;
      return ld.findIndex(value, { productBaseVariantId: currentBaseVarID });
    });
  }, [data, value]);

  const typingRef = useRef(null);
  const handleChange = useCallback(
    (key) => (newPrice) => {
      typingRef.current && clearTimeout(typingRef.current);
      typingRef.current = setTimeout(() => {
        const keyPrice = key === "regular" ? "regularPrice" : "salePrice";
        for (let i = 0; i < indexes.length; i++) {
          value[indexes[i]][keyPrice] = newPrice;
        }
        onChange && onChange(value);
      }, 500);
    },
    [value, indexes, onChange],
  );

  return (
    <BulkActionWrapper>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
        style={{ marginBottom: "1rem" }}
      >
        <Panel
          header="Change Prices"
          key="1"
          className="site-collapse-custom-panel"
          style={{ padding: 0 }}
        >
          <div>
            <label>Regular Price</label>
            <Price onChange={handleChange("regular")} />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <label>Sale Price</label>
            <Price onChange={handleChange()} />
          </div>
        </Panel>
      </Collapse>
    </BulkActionWrapper>
  );
}

// Utitls
const sizeRegex = /size(s)*/i;
const colorRegex = /color(s)*/i;
function findAttr(reg = sizeRegex) {
  return (item) => reg.test(item?.slug);
}

function getAttrs(attr) {
  return get(attr, "productBaseVariant.attributes", []) || [];
}

function sortAttributes(attributes, productAttrsStr) {
  if (
    !attributes ||
    !Array.isArray(attributes) ||
    attributes.length === 0 ||
    typeof productAttrsStr !== "string" ||
    productAttrsStr.length === 0
  )
    return attributes;

  const productAttrs = JSON.parse(productAttrsStr);
  if (
    !productAttrs["color"] ||
    !productAttrs["color"]["options"] ||
    !productAttrs["size"] ||
    !productAttrs["size"]["options"]
  )
    return attributes;

  const colorKeys = Object.keys(productAttrs["color"]["options"]);
  const sizeKeys = Object.entries(productAttrs["size"]["options"]).reduce(
    (acc, [, opt]) => {
      if (opt?.label) {
        acc.push(opt.label);
      }
      return acc;
    },
    [],
  );
  if (colorKeys.length === 0 || sizeKeys.length === 0) return attributes;

  const [attr] = attributes;
  const attrs = getAttrs(attr);

  // Case: have size attribute
  if (!attrs.find(findAttr())) return attributes;

  const mapAttributes = {};
  for (const attr of attributes) {
    const innerAttrs = getAttrs(attr);
    const size = innerAttrs.find(findAttr());
    const color = innerAttrs.find(findAttr(colorRegex));

    if (color && size) {
      const key = [
        (color.option || "")
          .replace(/\s+/g, "-")
          .replace(/\-\-+/, "-")
          ?.toLowerCase(),
        size.option?.toLowerCase(),
      ]
        .filter(Boolean)
        .join("_");
      mapAttributes[key] = attr;
    }
  }

  const res = [];
  for (let opt of colorKeys) {
    for (let size of sizeKeys) {
      const key = [opt?.toLowerCase(), size?.toLowerCase()].join("_");
      if (mapAttributes[key]) {
        res.push(mapAttributes[key]);
      }
    }
  }

  return res;
}

ProductVariants.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  sku: PropTypes.string,
};

export default ProductVariants;
