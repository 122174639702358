import React, { Component } from "react";
import { Card, Form, Input, Select, Skeleton, Button } from "antd";
import TemplateConfigForm from "./TemplateConfigForm";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { USER_ROLE } from "../../variable";
import ActionCloneTemplate from "./ActionCloneTemplate";
import { AppContext } from "../../context";
import { getExportPath } from "../../pages/supplier/utils";

const EXPORT_TEMPLATE_COLUMN_ADMIN = gql`
  query exportTemplateColumns($role: String!) {
    exportTemplateColumns {
      label
      value
    }
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

const EXPORT_TEMPLATE_COLUMN_SUP = gql`
  query exportTemplateColumns {
    exportTemplateColumns {
      label
      value
    }
  }
`;
export default class ExportTemplateForm extends Component {
  render() {
    const { onSubmit, isAdmin, exportTemplate, disabled } = this.props;
    const { currentUser } = this.context;
    const param = getExportPath(currentUser);

    return (
      <Card>
        <Query
          query={
            isAdmin ? EXPORT_TEMPLATE_COLUMN_ADMIN : EXPORT_TEMPLATE_COLUMN_SUP
          }
          variables={{ role: isAdmin ? USER_ROLE.Supplier : undefined }}
        >
          {({ data, error, loading }) => {
            if (loading) return <Skeleton active />;
            if (error) return error.toString();
            const templateColumns = (data && data.exportTemplateColumns) || [];

            return (
              <Form
                ref={(ref) => (this.form = ref)}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  name: exportTemplate && exportTemplate.name,
                  suppliers:
                    (isAdmin &&
                      exportTemplate &&
                      exportTemplate.suppliers &&
                      exportTemplate.suppliers.map((sup) => sup.id)) ||
                    [],
                  columns: exportTemplate
                    ? exportTemplate.columns || []
                    : templateColumns.reduce(
                        (init, t) => [
                          ...init,
                          {
                            name: t.label,
                            value: t.value,
                            type: "defined_value",
                          },
                        ],
                        []
                      ),
                }}
                onFinish={(values) => {
                  onSubmit(values);
                }}
              >
                <Form.Item
                  label="Template Name"
                  name="name"
                  rules={[
                    { required: true, message: "Template name is required." },
                  ]}
                >
                  <Input disabled={disabled} placeholder="Template Name" />
                </Form.Item>
                {isAdmin && (
                  <Form.Item
                    label="Supplier"
                    name="suppliers"
                    rules={[
                      { required: true, message: "Suppliers is required." },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      placeholder="Search for supplier"
                      mode="multiple"
                      showSearch
                      filterOption={(value, option) =>
                        option.children
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      }
                    >
                      {data &&
                        data.listUserByRole.map((user) => (
                          <Select.Option
                            key={user.id}
                            value={user.id}
                          >{`${user.firstName} ${user.lastName}`}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label="Template Configs"
                  name="columns"
                  rules={[
                    {
                      required: true,
                      message: "Template configs is required.",
                    },
                  ]}
                >
                  <TemplateConfigForm
                    disabled={disabled}
                    templateColumns={templateColumns}
                  />
                </Form.Item>
                <Form.Item className="w-full text-right">
                  {disabled ? (
                    <ActionCloneTemplate
                      id={exportTemplate && exportTemplate.id}
                      type="primary"
                      router={`/${param}/manager-files/export-templates`}
                    />
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.props.loading}
                    >
                      {exportTemplate ? "Update Template" : "Create Template"}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            );
          }}
        </Query>
      </Card>
    );
  }
}

ExportTemplateForm.contextType = AppContext;