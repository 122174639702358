import React, { useCallback, useState } from "react";
import { Tabs } from "@shopify/polaris";
import styled from "styled-components";

import { CommentsPolaris } from "../../actions/CommentsPolaris";
import { GetTaskLogPolaris } from "../../actions/GetTaskLogPolaris";

export function Activities({ taskId }) {
    // State
    const [selected, setSelected] = useState(0);

    // Actions
    const handleTabChange = useCallback((index) => setSelected(index), []);

    // Markup
    const tabs = [
        {
            id: "activities",
            content: "Activities",
            panelID: "activities",
        },
        {
            id: "timelines",
            content: "Timelines",
            panelID: "timelines",
        },
    ];

    const content = [
        <CommentsPolaris taskId={taskId} hideTitle />,
        <GetTaskLogPolaris taskId={taskId} />,
    ];
    return (
        <Wrapper firstItem={selected}>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <div className="tab-content">{content[selected]}</div>
            </Tabs>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-bottom: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #c4cdd5;
    ${({ firstItem }) => !firstItem && `margin-bottom: 3rem;`}

    .tab-content {
        padding-top: 0.5rem;
    }
`;
