import { useQuery } from "@apollo/react-hooks";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context";
import {
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER, DESIGNER_SPECIAL, TEAM_ROLE } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { FiltersMembersPolaris } from "./FiltersMembersPolaris";
import { TableMemberPolaris } from "./TableMemberPolaris";

export const LIST_TEAM = gql`
  query teamMembers($filter: TeamUserFilter) {
    teamMembers(filter: $filter) {
      count
      hits {
        id
        role
        team {
          id
        }
        user {
          id
          firstName
          lastName
          roles
          email
          phone
          address
          status
          avatar {
            id
            url
          }
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status", "role"];

export const TeamMembersPolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = { ...convertStringToObject(history.location.search) };
    if (initialFilter) {
      if (initialFilter.status) {
        if ("active" === initialFilter.status) {
          initialFilter.status = true;
        }
        if ("inactive" === initialFilter.status) {
          initialFilter.status = false;
        }
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  // Context
  const { currentUser } = React.useContext(AppContext);
  const userId = currentUser?.id;
  const { isIdeaLeader } = checkRole(currentUser);
  const isLeadSpecial = DESIGNER_SPECIAL.accIds.includes(userId);

  let role = null;
  if (isLeadSpecial) {
    role = TEAM_ROLE.Designer;
  }

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageMembers")) || 20,
    offset: 0,
    search: null,
    status: null,
    role,
    ...initFilter,
  });

  const { data, loading, error, refetch } = useQuery(LIST_TEAM, {
    variables: {
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let { offset, limit, status, ...rest } = filter;
    if (true === status) {
      status = "active";
    }
    if (false === status) {
      status = "inactive";
    }

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      status,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  const loadingMarkup = loading && <Loading />;

  return (
    <>
      {loadingMarkup}
      <Card sectioned>
        <FiltersMembersPolaris
          isLeadSpecial={isLeadSpecial}
          isIdeaLeader={isIdeaLeader}
          filter={filter}
          onChange={({ search, status, role: newRole }) =>
            setFilter((prevState) => {
              if (
                !_.isEqual(prevState.search, search) ||
                !_.isEqual(prevState.status, status) ||
                !_.isEqual(prevState.role, newRole)
              ) {
                prevState.offset = 0;
              }
              return {
                ...prevState,
                search,
                status,
                role: isLeadSpecial ? role : newRole,
              };
            })
          }
        />
      </Card>
      <Card>
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <SkeletonPagePolaris />
        ) : data?.teamMembers?.hits?.length > 0 ? (
          <TableMemberPolaris
            isLeadSpecial={isLeadSpecial}
            data={data}
            filter={filter}
            refetch={refetch}
            setValuePage={(offset, limit) => {
              setFilter((prevState) => ({
                ...prevState,
                offset,
                limit,
              }));
              setCookie("perPageMembers", limit, 100);
            }}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </>
  );
};
