import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  DataTable,
  Thumbnail,
  Badge,
  Toast,
  Link,
  Avatar,
} from "@shopify/polaris";
import { get, head } from "lodash";
import styled from "styled-components";
import moment from "moment";

import { AppContext } from "../../context";
import { TEAM_ROLE, TEAM_ROLE_PARAMS, USER_ROLE } from "../../variable";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { ProductsRMActionsPolaris } from "./ProductsRMActionsPolaris";

const Container = styled.div`
  .product-wrap,
  .base-wrap {
    width: calc(25rem - 3.2rem);
    white-space: break-spaces;
  }
  .reason_wrap,
  .sku-wrap {
    width: calc(20rem - 3.2rem);
    white-space: break-spaces;
  }
  .collection-wrap,
  .tag-wrap {
    margin-left: -1rem;
    margin-top: -0.9rem;
    max-width: calc(20rem - 3.2rem);
    display: flex;
    flex-wrap: wrap;
    > span {
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .Polaris-Badge {
      max-width: 100%;
      .Polaris-Badge__Content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .actions-wrap {
    display: flex;
    flex-direction: row-reverse;
    min-width: calc(22rem - 3.2rem);
    white-space: break-spaces;
  }
  .updated-at-wrap {
    width: calc(15rem - 3.2rem);
    white-space: break-spaces;
  }
  .Polaris-DataTable__TableRow {
    &:last-child {
      .updated-by-wrap {
        padding-bottom: 2rem;
      }
    }
  }
`;

const UpdatedByContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transform: ${(props) =>
    props.index
      ? `translateX(-${(props.index % 5) * 12}px)`
      : "translateX(0px)"};
  .Polaris-Avatar {
    border: 2px solid #fff;
    width: calc(4rem + 4px);
  }
  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    z-index: 2;
    .tooltip {
      visibility: visible;
    }
  }
`;

export const ProductsRMTablePolaris = ({
  filter,
  data,
  setFilter,
  refetch,
}) => {
  // State
  const [rows, setRows] = useState([]);
  const [activeToast, setActiveToast] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    isError: false,
  });

  // Props
  const mockupUpdated = filter?.mockupUpdated;
  // Context
  const { currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);
  let currentParam = TEAM_ROLE_PARAMS.Seller;
  let isDesigner = false;
  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }
    if ([TEAM_ROLE.DesignLeader, TEAM_ROLE.Designer].includes(teamRole)) {
      isDesigner = true;
    }
  }

  useEffect(() => {
    let newRows =
      data?.getListProductRecreateMockup?.nodes?.length > 0
        ? data.getListProductRecreateMockup.nodes.map((product) => {
            const title = get(product, "title", null);
            const sku = get(product, "sku", null);
            const images = get(product, "images", []);
            let pBases = get(product, "productBases", []);
            const pBasesMarkup =
              pBases?.length > 0
                ? pBases.map((item) => item.title).join(", ")
                : null;

            let collections =
              product && product.collections && product.collections.length > 0
                ? product.collections.map((c) => c.name)
                : [];
            let collectionsMarkup = collections.map((c, idx) => (
              <Badge key={`collection-${idx}`}>{c}</Badge>
            ));

            let tags =
              product && product.tags && product.tags.length > 0
                ? product.tags.map((t) => t.name)
                : [];
            let tagsMarkup = tags.map((t, idx) => (
              <Badge key={`tag-${idx}`}> {t}</Badge>
            ));

            let src = head(images);
            src =
              src &&
              src.file &&
              (src.file.thumbnailUrl
                ? src.file.thumbnailUrl
                : src.file.url
                ? src.file.url
                : null);

            // Product
            let personalized = product?.personalized;
            const redirectPage = (item) => {
              const link =
                item && item.productBases && item.productBases.length > 1
                  ? `/${currentParam}/products/campaign`
                  : `/${currentParam}/products/edit`;
              return `${link}/${item.id}`;
            };
            const url = redirectPage(product);

            // Updated at
            let updatedAt = product?.mockupUpdatedAt;
            let updatedAtMarkup = updatedAt
              ? moment(updatedAt).format("YYYY-MM-DD")
              : null;

            // Updated by
            let updatedBy = product?.mockupUpdatedBy;
            let updatedByMarkup;
            if (updatedBy) {
              let { firstName, lastName } = updatedBy;
              let fullName = `${firstName} ${lastName}`;
              updatedByMarkup = (
                <UpdatedByContainer>
                  <Avatar initials={fullName.charAt(0)} />
                  <span className="tooltip">{fullName}</span>
                </UpdatedByContainer>
              );
            }

            return [
              <div className="image-wrap">
                <Thumbnail size="large" alt="" source={src} />
              </div>,
              <div className="product-wrap" data-product_id={product?.id}>
                {!isDesigner ? (
                  <Link url={url} children={title} />
                ) : (
                  <span>{title}</span>
                )}
                {personalized && (
                  <div>
                    <Badge status="success" children="Personalized" />
                  </div>
                )}
              </div>,
              <div className="sku-wrap">
                <span>{sku}</span>
              </div>,
              <div className="base-wrap">
                <span>{pBasesMarkup}</span>
              </div>,
              ...(mockupUpdated
                ? [
                    <div className="updated-by-wrap">{updatedByMarkup}</div>,
                    <div className="updated-at-wrap">
                      <span>{updatedAtMarkup}</span>
                    </div>,
                  ]
                : []),
              <div className="collection-wrap">{collectionsMarkup}</div>,
              <div className="tag-wrap">{tagsMarkup}</div>,
              <div className="actions-wrap">
                <ProductsRMActionsPolaris
                  item={product}
                  toggleActive={toggleActive}
                  refetch={refetch}
                  onNotificationChange={({ message, isError }) =>
                    setNotification({ message, isError })
                  }
                />
              </div>,
            ];
          })
        : [];
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, mockupUpdated]);

  // Handle actions
  const toggleActive = useCallback(() => setActiveToast((prev) => !prev), []);

  let colsType = [];
  let n = mockupUpdated ? 8 : 6;
  for (let i = 0; i < n; i++) {
    colsType.push("text");
  }
  colsType.push("numeric");

  let { limit, offset } = filter;
  const total = get(data, "getListProductRecreateMockup.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
    total,
  };

  // Markup
  const toastMarkup = activeToast
    ? notification &&
      notification.message && (
        <Toast
          content={notification.message}
          error={notification.isError}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Container>
      {toastMarkup}
      <DataTable
        rows={rows}
        columnContentTypes={colsType}
        headings={[
          "Image",
          "Product",
          "SKU",
          "Product bases",
          ...(mockupUpdated ? ["Updated By", "Updated At"] : []),
          "Collections",
          "Tags",
          "Actions",
        ]}
        verticalAlign="middle"
        hideScrollIndicator
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
