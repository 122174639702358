import { Checkbox, Collapsible, Stack } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { TimeRange } from "./TimeRange";

export function CreatedAtComp({
    createdAt,
    setCreatedAt,
    setIsPrompt,
    isEditDivision,
}) {
    // State
    const [checked, setChecked] = useState(!!createdAt.has);

    // Actions
    const handleCheckedChange = useCallback(
        (value) => {
            setChecked(value);
            setCreatedAt((prev) => ({ ...prev, has: value }));
            setIsPrompt(true);
        },
        [setIsPrompt]
    );

    const handleTimeChange = useCallback(
        (value) => {
            if (typeof value === "object" && Object.keys(value).length > 0) {
                const { from, to } = value;
                setCreatedAt((prev) => ({ ...prev, value: { from, to } }));
                setIsPrompt(true);
            } else {
                setCreatedAt((prev) => ({ ...prev, value: {} }));
                setIsPrompt(true);
            }
        },
        [setIsPrompt]
    );

    return (
        <Stack vertical>
            <Checkbox
                label="Filter products by created at"
                checked={checked}
                onChange={handleCheckedChange}
            />
            <Collapsible
                open={checked}
                transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                }}
                expandOnPrint
            >
                <Stack spacing="loose" vertical>
                    <TimeRange
                        onChange={handleTimeChange}
                        value={createdAt.value}
                        label="Created at"
                        canSelectOne
                        isEditDivision={isEditDivision}
                    />
                </Stack>
            </Collapsible>
        </Stack>
    );
}
