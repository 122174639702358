import React, { Component } from "react";
import PropTypes from "prop-types";
import { LiveApi, LocalApi, Production } from "../../config";
import { EyeInvisibleOutlined, SettingFilled } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, Modal, notification } from "antd";
import XPathIframe from "./XPathIframe";
import { ApolloConsumer } from "@apollo/react-components";
import { gql } from "apollo-boost";
import styled from "styled-components";
import FindReplaceXPath from "./FindReplaceXPath";

const CRAWL_TITLE_QUERY = gql`
  query getCrawlTitle(
    $url: String!
    $xPath: String!
    $attribute: String
    $replacement: [ReplacementData!]
  ) {
    getCrawlTitle(
      url: $url
      xPath: $xPath
      attribute: $attribute
      replacement: $replacement
    )
  }
`;

const CRAWL_IMAGES_QUERY = gql`
  query getCrawlImages(
    $url: String!
    $xPath: String!
    $attribute: String
    $replacement: [ReplacementData!]
  ) {
    getCrawlImages(
      url: $url
      xPath: $xPath
      attribute: $attribute
      replacement: $replacement
    )
  }
`;

const Container = styled.div`
  .images-result {
    display: flex;
    .image-result {
      max-width: 200px;
    }
  }
  .xpath {
    display: flex;
    button,
    input {
      margin-left: 10px;
    }
  }
  .ant-popover-disabled-compatible-wrapper {
    line-height: 0;
  }
`;
class XpathSelector extends Component {
  state = {
    visible: false,
    xpath: "",
    loading: false,
    title: "",
    images: [],
    previewImage: null,
    visibleReplace: false,
  };

  render() {
    const {
      isImage,
      url,
      value,
      onChange,
      onChangeAttribute,
      onChangeItems,
      crawler,
    } = this.props;
    let srcUrl = Production
      ? `${LiveApi}/viewer?url=${url}`
      : `${LocalApi}/viewer?url=${url}`;
    return (
      <ApolloConsumer>
        {(client) => (
          <Container>
            <div className="xpath">
              <Input
                value={value}
                onChange={(e) => {
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
              />
              <Input
                placeholder={
                  isImage
                    ? "Attribute (default: src)"
                    : "Attribute (default: text)"
                }
                onChange={(e) => {
                  onChangeAttribute(e.target.value);
                }}
              />
              <Button
                disabled={!url}
                onClick={() => {
                  this.setState({ visible: true });
                }}
                defaultChecked={true}
              >
                <SettingFilled />
              </Button>
              <FindReplaceXPath
                url={url}
                onChange={(items) => {
                  onChangeItems(items);
                }}
              />
              <Button
                disabled={!url || !value}
                loading={this.state.loading}
                onClick={() => {
                  const variables = {
                    url: url,
                    xPath: value,
                    attribute: isImage
                      ? crawler.imageXpathAttribute || null
                      : crawler.titleXpathAttribute || null,
                    replacement: isImage
                      ? crawler.imageReplacement || []
                      : crawler.titleReplacement || [],
                  };
                  this.setState(
                    {
                      loading: true,
                    },
                    () => {
                      if (this.props.isImage) {
                        client
                          .query({
                            query: CRAWL_IMAGES_QUERY,
                            variables: variables,
                            fetchPolicy: "no-cache",
                          })
                          .then((res) => {
                            this.setState({
                              loading: false,
                              images: res.data.getCrawlImages,
                            });
                          })
                          .catch((e) => {
                            this.setState({
                              loading: false,
                            });
                            notification.error({ message: "Not found" });
                          });
                      } else {
                        client
                          .query({
                            query: CRAWL_TITLE_QUERY,
                            variables: variables,
                            fetchPolicy: "no-cache",
                          })
                          .then((res) => {
                            this.setState({
                              loading: false,
                              title: res.data.getCrawlTitle,
                            });
                          })
                          .catch((e) => {
                            this.setState({
                              loading: false,
                            });
                            notification.error({ message: "Not found" });
                          });
                      }
                    }
                  );
                }}
                defaultChecked
              >
                <EyeInvisibleOutlined />
              </Button>
            </div>
            {this.state.title && (
              <Form.Item label={"Result"}>
                <h3>{this.state.title}</h3>
              </Form.Item>
            )}
            {this.props.isImage &&
              this.state.images &&
              this.state.images.length > 0 && (
                <Form.Item label={"Result"}>
                  <div className="block">
                    {this.state.images.map((img, index) => (
                      <div className={"image-result p-2"}>
                        <img
                          style={{ width: 200 }}
                          key={index}
                          onClick={() => {
                            this.setState({
                              previewImage: img,
                            });
                          }}
                          src={img}
                          alt={""}
                        />
                      </div>
                    ))}
                  </div>
                </Form.Item>
              )}
            {this.state.visible && (
              <Modal
                footer={null}
                style={{ minHeight: 600 }}
                onCancel={() => {
                  this.setState({ visible: false });
                }}
                width={"90%"}
                visible={true}
              >
                <XPathIframe
                  onChange={(path) => {
                    this.setState({ visible: false }, () => {
                      if (onChange) {
                        onChange(path);
                      }
                    });
                  }}
                  src={srcUrl}
                />
              </Modal>
            )}
            <Modal
              style={{
                height: "99%",
              }}
              width={"98%"}
              footer={null}
              onCancel={() => {
                this.setState({ previewImage: null });
              }}
              visible={this.state.previewImage}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={this.state.previewImage} alt={""} />
              </div>
            </Modal>
          </Container>
        )}
      </ApolloConsumer>
    );
  }
}
XpathSelector.propTypes = {
  url: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isImage: PropTypes.bool,
};

export default XpathSelector;
