import React, { Component } from "react";
import { Table, Input, Button, Select, Form } from "antd";
import { MenuOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

const type = "DragableBodyRow";

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      if (moveRow) moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const Container = styled.div`
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
`;
export default class TemplateConfigForm extends Component {
  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { value, onChange, disabled } = this.props;

    if (!disabled) {
      [value[dragIndex], value[hoverIndex]] = [
        value[hoverIndex],
        value[dragIndex],
      ];
      onChange([...value]);
    }
  };

  render() {
    const { value, onChange, templateColumns, disabled } = this.props;

    return (
      <Container>
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={value}
            components={this.components}
            onRow={(_, index) => ({
              index,
              moveRow: this.moveRow,
            })}
            columns={[
              {
                title: <MenuOutlined />,
                render: () => <MoreOutlined />,
                key: "move",
              },
              {
                title: "Column Name",
                key: "name",
                dataIndex: "name",
                render: (name, _, index) => (
                  <Input
                    disabled={disabled}
                    value={name}
                    onChange={(e) => {
                      value[index].name = e.target.value;
                      onChange([...value]);
                    }}
                  />
                ),
              },
              {
                title: "Type",
                key: "type",
                dataIndex: "type",
                render: (type, _, index) => (
                  <Select
                    disabled={disabled}
                    style={{ width: 200 }}
                    value={type}
                    onSelect={(e) => {
                      value[index].type = e;
                      onChange([...value]);
                    }}
                  >
                    <Select.Option value="defined_value">
                      Defined Value
                    </Select.Option>
                    <Select.Option value="custom_value">
                      Custom Value
                    </Select.Option>
                    <Select.Option value="personalized_option">
                    Personalized Option
                    </Select.Option>
                  </Select>
                ),
              },
              {
                title: "Value",
                key: "value",
                dataIndex: "value",
                render: (type, record, index) =>
                  record.type === "defined_value" ? (
                    <Select
                      disabled={disabled}
                      value={type}
                      style={{ width: 200 }}
                      onSelect={(e) => {
                        value[index].value = e;
                        onChange([...value]);
                      }}
                    >
                      {templateColumns.map((templateCol, index) => (
                        <Select.Option key={index} value={templateCol.value}>
                          {templateCol.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      disabled={disabled}
                      style={{ width: 200 }}
                      value={type}
                      onChange={(e) => {
                        value[index].value = e.target.value;
                        onChange([...value]);
                      }}
                    />
                  ),
              },
              {
                title: "Actions",
                key: "Actions",
                render: (_, __, index) => (
                  <Button
                    disabled={disabled}
                    onClick={() => {
                      value.splice(index, 1);
                      onChange([...value]);
                    }}
                    icon={<DeleteOutlined />}
                  />
                ),
              },
            ]}
            pagination={false}
            scroll={{ x: "maxWidth" }}
            rowKey={(_, index) => index}
          />
        </DndProvider>
        <Form.Item>
          <Button
            disabled={disabled}
            className="my-4"
            onClick={() => {
              onChange([
                ...value,
                { name: "", type: "custom_value", value: "" },
              ]);
            }}
          >
            Add New Column
          </Button>
        </Form.Item>
      </Container>
    );
  }
}
