import { useQuery } from "@apollo/react-hooks";
import { DataTable, Modal, Spinner } from "@shopify/polaris";
import get from "lodash/get";
import React from "react";
import styled from "styled-components";
import { PaginationPolaris } from "../../components/shared/PaginationPolaris";
import { useAppContext } from "../../context";
import { LOGIN_HISTORY } from "../../graphql/queries";
import { formatTime, handleError } from "../../helper";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";

function ActivityHistory({ open, onClose }) {
  // Context
  const { currentUser } = useAppContext();
  const [filter, setFilter] = React.useReducer((p, s) => ({ ...p, ...s }), {
    userId: currentUser?.id,
    paged: 1,
    limit: 20,
  });
  const [rows, setRows] = React.useState([]);
  const wrapperRef = React.useRef(null);

  const { data, loading, error } = useQuery(LOGIN_HISTORY, {
    skip: !open || !filter.userId,
    variables: filter,
  });

  React.useEffect(() => {
    const nodes = data?.loginHistory?.nodes || [];
    const rows = nodes.map((node) => {
      const { ipAddress, loggedAt, location, deviceInfo } = node || {};
      const { city, region, country, postal, org } = location || {};
      const { device } = deviceInfo || {};

      const ipMarkup = (
        <div className="item ip-wrap">
          <span>{ipAddress}</span>
        </div>
      );

      const orgMarkup = (
        <div className="item org-wrap">
          <span>{org}</span>
        </div>
      );

      const regionStr = postal ? "(" + postal + ")" : "";
      const regionDetail = region ? `${region} ${regionStr}` : "";
      const addr = [city, regionDetail, country].filter(Boolean).join(", ");

      const addressMarkup = (
        <div className="item address-wrap">
          <span>{addr}</span>
        </div>
      );

      const deviceMarkup = (
        <div className="item device-wrap">
          <span>{device}</span>
        </div>
      );

      const loggedAtMarkup = (
        <div className="item logged-at-wrap">
          <span>{formatTime(loggedAt, "YYYY-MM-DD HH:mm:ss")}</span>
        </div>
      );

      return [ipMarkup, orgMarkup, addressMarkup, deviceMarkup, loggedAtMarkup];
    });

    setRows(rows);
  }, [data?.loginHistory]);

  React.useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    const dialog = wrapperRef.current.closest(".Polaris-Modal-Dialog__Modal");
    if (dialog) {
      dialog.style.minHeight = "calc(100vh - 60px)";
    }
  });

  const tableProps = React.useMemo(() => {
    const headings = ["IP", "Organization", "Address", "Device", "Logged At"];
    const columnContentTypes = Array.from({ length: headings.length }).fill(
      "text"
    );
    return {
      headings,
      columnContentTypes,
    };
  }, []);

  const onChange = React.useCallback((offset, limit) => {
    const paged = offset / limit + 1;
    setFilter({ paged, limit });
  }, []);

  let { limit, paged } = filter;
  const total = get(data, "loginHistory.total", 0);
  const totalPage = Math.ceil(total / limit);
  const aggregation = {
    page: paged,
    totalPage,
    offset: (paged - 1) * limit,
    limit,
    onChange: onChange,
    total,
  };

  return (
    <Modal
      title="Activity History"
      open={open}
      onClose={onClose}
      large
      footer={<PaginationPolaris aggregation={aggregation} showTotal />}
    >
      <Wrapper ref={wrapperRef}>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error?.toString())}</div>
        ) : (
          <DataTable
            {...tableProps}
            rows={rows}
            hideScrollIndicator
            verticalAlign="middle"
          />
        )}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  overflow-x: auto;
  .Polaris-DataTable__ScrollContainer {
    overflow-x: inherit;
  }
`;

export { ActivityHistory };
