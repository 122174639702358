import React from "react";
import { Heading, TextContainer, TextStyle, Link } from "@shopify/polaris";
import styled from "styled-components";

const Container = styled.div`
  img {
    width: 100%;
  }
  .guide-image {
    text-align: center;
    margin-bottom: 30px;
  }
  p.Polaris-Heading {
    font-weight: 400;
  }
`;

export const QuickGuidePolaris = () => {
  return (
    <Container>
      <TextContainer>
        <Heading>Hướng dẫn sử dụng Tool Crawl</Heading>
        <div className="guide-section">
          <Heading element="h3">Tổng quan</Heading>
          <Heading element="p">
            <TextStyle variation="strong">Tool Crawl</TextStyle> sẽ giúp người
            dùng crawl product từ website khác thông qua 2 cấu trúc file csv
            nhau: "Only URLs" và "Definded Structure"
          </Heading>
          <Heading element="p">
            <TextStyle variation="strong">Product Base:</TextStyle> là loại sản
            phẩm mà MerchBridge hỗ trợ. VD: 3D Shirts, Quilt Blanket, Fleece
            Blanket...{" "}
          </Heading>
          <Heading element="p">
            <TextStyle variation="strong">Template:</TextStyle> Khi tạo sản
            phẩm, người dùng có thể lưu các thông tin cấu hình cho sản phẩm đó
            dưới dạng một template, những lần tạo sản phẩm tiếp theo người dùng
            có thể cấu hình lại thông tin cho sản phẩm hoặc dùng lại cấu hình cũ
            từ template.{" "}
          </Heading>
        </div>
        <div className="guide-section">
          <Heading element="h3">
            1. Tạo product thông qua file csv struct
          </Heading>
          <Heading element="p">
            Với option này, người dùng cần chuẩn bị 1 file csv chứa các thông
            tin cơ bản của product: Title, Images, Product Base, Template,
            Collections, Tags
          </Heading>
          <Heading element="p">
            File mẫu:{" "}
            <Link url="https://res.cloudinary.com/mbmedia/raw/upload/v1590112585/defined-structure_cdjjlq.csv">
              Defined-structure.csv
            </Link>
          </Heading>
          <Heading element="p">
            <TextStyle variation="strong">Lưu ý:</TextStyle> để lấy được product
            base slug người dùng có thể vào menu add product/add campaign để
            xem:
          </Heading>

          <div className="guide-image">
            <img
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590114564/add-product-campaign_xzxy8j.png"
            />
          </div>
          <div className="guide-image">
            <img
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590112101/850f3ef5-56a9-45af-89c4-36a9cb5e5886_f043od.png"
            />
          </div>

          <Heading element="p">
            Để lấy được template slug, người dùng có thể vào add campaign, chọn
            All templates:
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590112692/b9e62849-1b18-4383-9adb-6ae79f0f67e9_vcorcn.png"
            />
          </div>
          <Heading element="p">
            Tại mục All templates sẽ hiển thị tất cả các template đã được tạo
            kèm theo tên và slug:
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590112729/e21fb62f-d825-45e3-a977-865db34fb34b_at4mkj.png"
            />
          </div>
          <Heading element="h4">
            Các bước tạo crawl với file "defined-structure.csv"
          </Heading>
          <Heading element="p">
            <TextStyle variation="strong">Bước 1:</TextStyle> Truy cập menu:{" "}
            <TextStyle variation="strong">Tools -> Product Crawlers</TextStyle>,
            ấn chọn{" "}
            <TextStyle variation="strong">New Product Crawler</TextStyle>{" "}
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590112786/buoc1_cygopg.png"
            />
          </div>
          <Heading element="p">
            <TextStyle variation="strong">Bước 2:</TextStyle> Điền title, chọn
            type: <TextStyle variation="strong">Product base struct</TextStyle>,
            upload file csv theo mẫu trên, sau đó ấn{" "}
            <TextStyle variation="strong">Process Now</TextStyle>, hệ thống sẽ
            tự động tạo product dựa trên thông tin của file.
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590112805/buoc2_lfumf2.png"
            />
          </div>
        </div>

        <div className="guide-section">
          <Heading element="h3">
            2. Tạo product thông qua file csv only urls
          </Heading>
          <Heading element="p">
            Với option này, người dùng có 2 thao tác:
          </Heading>
          <ul>
            <li>
              Chuẩn bị file csv chỉ chứa các url của product cần crawl, theo mẫu
              sau:{" "}
              <Link url="https://res.cloudinary.com/mbmedia/raw/upload/v1590112866/only-urls_vrbms5.csv">
                Only-URLs.csv
              </Link>
            </li>
            <li>
              Khi crawl product về, người dùng cần tiếp tục assign product vào
              các product base hoặc template hay xoá bỏ nếu không phù hợp.
            </li>
          </ul>
          <Heading element="p">
            <TextStyle variation="strong">Bước 1:</TextStyle> Truy cập menu:{" "}
            <TextStyle variation="strong"> Tools -> Product Crawlers</TextStyle>
            , ấn chọn{" "}
            <TextStyle variation="strong">New Product Crawler</TextStyle>
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590113070/b1_l9wy8g.png"
            />
          </div>
          <Heading element="p">
            <TextStyle variation="strong">Bước 2:</TextStyle> Nhập title cho
            crawler, chọn file csv đã chuẩn bị, chọn type:{" "}
            <TextStyle variation="strong">Only urls</TextStyle>, cài đặt xpath
            cho title, xpath cho thumbs. Thao tác select expath sẽ chọn chính
            xác thẻ HTML chứa dữ liệu cần crawl về.
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590113099/b2_pysonk.png"
            />
          </div>
          <Heading element="p">
            Thao tác setup xpath cần chuẩn xác để lấy được dữ liệu mong muốn:
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590113135/xpath_fego02.png"
            />
          </div>
          <Heading element="p">
            Để kiểm tra phần xpath, người dùng có thể dùng button preview để
            kiểm tra, nếu setup chuẩn xác thì khi preview người dùng sẽ thấy dữ
            liệu mình mong muốn được hiển thị ra, ngược lại người dùng cần setup
            lại để có thể lấy chính xác dữ liệu mình mong muốn.
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590113163/check-result_uaenp8.png"
            />
          </div>
          <Heading element="p">
            <TextStyle variation="strong">Bước 3:</TextStyle> Assign product vào
            product base hoặc template: người dùng cần assign product đã được
            crawl vào product base hoặc template. Thao tác crawl chỉ lưu tạm
            thời dữ liệu, khi được assign vào product base hoặc template thì
            product mới được tạo theo dữ liệu mặc định của product base hoặc
            template.
          </Heading>
          <div className="guide-image">
            <img
              alt=""
              src="https://res.cloudinary.com/mbmedia/image/upload/v1590113195/assign_pqw3lp.png"
            />
          </div>
        </div>
      </TextContainer>
    </Container>
  );
};
