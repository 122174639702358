import { useMutation } from "@apollo/react-hooks";
import { Modal, Stack, TextField } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, { useCallback } from "react";

const UPDATE_STATUS = gql`
  mutation updateClaim($id: ID!, $status: String!, $reason: String) {
    updateClaim(id: $id, status: $status, reason: $reason)
  }
`;

export const ActionsPolaris = (props) => {
  // Props
  const {
    open,
    toggleShowModal,
    claim,
    refetch,
    onNotificationChange,
    toggleActive,
    handleError,
    status,
    isReject,
  } = props;

  const [reason, setReason] = React.useState("");
  const [updateClaim, { loading }] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      if (toggleShowModal) {
        toggleShowModal();
      }
      if (onNotificationChange) {
        onNotificationChange({
          message: "Update status successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle action
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    let id = claim?.id;
    if (id) {
      updateClaim({
        variables: {
          id,
          reason: isReject ? reason : undefined,
          status: status,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim, status, reason, isReject]);

  return (
    <Modal
      title={isReject ? "Reject claim" : "Update claim"}
      open={open}
      onClose={toggleShowModal}
      sectioned
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
    >
      <Stack vertical>
        {isReject ? (
          <TextField
            label="Reason"
            placeholder="Enter reject reason"
            value={reason}
            onChange={setReason}
          />
        ) : null}
        <p>
          Do you want to {isReject ? "reject" : "change status"} this claim?
        </p>
      </Stack>
    </Modal>
  );
};
