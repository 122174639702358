import React, { Component } from "react";
import { Overviews } from "../components/reports/components";
import { OverviewsAdmin } from "../components/reports/components/OverviewsAdmin";
import { WrapperPage } from "../components/shared/WrapperPage";
import { AppContext } from "../context";
import { checkRole } from "../helper";
import history from "../history";

class Home extends Component {
  static contextType = AppContext;

  componentDidMount() {
    const { currentUser } = this.context;
    if (!currentUser) {
      history.push("/login");
      window.location.reload(false);
    }
  }

  render() {
    const { currentUser } = this.context;
    const { isAdministrator } = checkRole(currentUser);
    return (
      <WrapperPage>
        {isAdministrator ? <OverviewsAdmin /> : <Overviews />}
      </WrapperPage>
    );
  }
}

export default Home;
