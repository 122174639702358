import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@shopify/polaris";
import _ from "lodash";

import { ComponentLabelPolaris } from "../../components/shared/ComponentLabelPolaris";

export const UserSelectPolaris = (props) => {
  const { data, onChange } = props;

  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);

  useEffect(() => {
    let newData =
      data && data.users && data.users.length
        ? data.users.map((row) => {
            const user = row.user;
            return {
              label: `${user.firstName} ${user.lastName} - [${row.role}]`,
              value: user.id,
            };
          })
        : [];
    setOptions(newData);
    setDeselectedOptions(newData);
  }, [data]);

  const handleInputValue = useCallback(
    (value) => {
      setInputValue(value);
      if ("" === value) {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = options.find(
          (option) => option.value === selectedItem
        );
        return matchedOptions && matchedOptions.label;
      });

      setSelectedOptions(selected);
      setInputValue(_.head(selectedValue));
      if (onChange) {
        onChange(_.head(selected));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const textField = (
    <Autocomplete.TextField
      value={inputValue}
      onChange={handleInputValue}
      placeholder="Filter user"
      label="Users"
      labelHidden
    />
  );

  return (
    <div>
      <ComponentLabelPolaris label="Users" />
      <Autocomplete
        options={options}
        onSelect={handleSelection}
        selected={selectedOptions}
        textField={textField}
        emptyState={<span>No found item!</span>}
      />
    </div>
  );
};
