import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Price from "../Price";
import SelectAwarehouses from "./components/SelectWarehouses";

class ProductBaseVariantQuickEdit extends Component {
  state = {
    selected: null,
    price: 0,
  };

  render() {
    const { disabled, isCustomcat, showSupplierPrice, isMerchize, hasFulfillment } = this.props;

    let items = [
      {
        title: "Regular Price",
        key: "regularPrice",
      },
      {
        title: "Sale Price",
        key: "salePrice",
      },
      {
        title: "Seller Price",
        key: "sellerPrice",
      },
      !hasFulfillment && {
        title: "Additional Price",
        key: "additionalPrice",
      },
      showSupplierPrice && {
        title: "Supplier Pricing",
        key: "supplierPricing",
      },
      ...(isCustomcat
        ? [
            {
              title: "Shipping Cost",
              key: "fulfillmentShippingCost",
            },
            {
              title: "Shipping International Cost",
              key: "fulfillmentShippingInternationalCost", //?
            },
          ]
        : []),
      isMerchize && { title: "Warehouse", key: "fulfillmentWarehouses" },
    ].filter(Boolean);

    const { selected } = this.state;
    const isWh = isSelectedWarehouse(selected?.key);

    return (
      <React.Fragment>
        <Dropdown
          disabled={disabled}
          overlay={
            <Menu>
              {items.map((item, index) => (
                <Menu.Item
                  onClick={() => {
                    this.setState({
                      selected: item,
                    });
                  }}
                  key={index}
                >
                  {item.title}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button type={"link"} icon={<CaretDownOutlined />}>
            Quick Edit
          </Button>
        </Dropdown>
        {this.state.selected && (
          <Modal
            width="520px"
            onOk={() => {
              if (this.props.onChange) {
                this.props.onChange({
                  key: this.state.selected.key,
                  value: isWh ? this.state.warehouses : this.state.price,
                });
              }
              this.setState({
                selected: null,
                price: 0,
              });
            }}
            onCancel={() => {
              this.setState({ selected: null, price: 0 });
            }}
            visible={true}
            title={`Set Bulk ${this.state.selected.title}`}
          >
            {isWh ? (
              <div>
                <SelectAwarehouses
                  onChange={(val) => {
                    this.setState({ warehouses: val });
                  }}
                  value={this.state.warehouses || []}
                />
              </div>
            ) : (
              <Price
                value={this.state.price}
                onChange={(price) => {
                  this.setState({ price });
                }}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

function isSelectedWarehouse(selected) {
  if (!selected) return false;
  return selected === "fulfillmentWarehouses";
}

ProductBaseVariantQuickEdit.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ProductBaseVariantQuickEdit;
