import React from "react";
import { Page, Card, Loading } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { USER_ROLE, TEAM_ROLE } from "../../variable";
import { GET_USER_BY_ROLES } from "../../graphql/queries";

import { AddStoreFormPolaris } from "./AddStoreFormPolaris";

const Container = styled.div`
    margin: -1rem;
    @media (min-width: 640px) {
        margin: -1.5rem;
    }
`;

export const AddStorePolaris = () => {
    const { data, loading, error } = useQuery(GET_USER_BY_ROLES, {
        variables: {
            roles: [USER_ROLE.StoreManager, TEAM_ROLE.Supporter],
        },
    });

    const loadingMarkup = loading && <Loading />;
    return (
        <Container>
            {loadingMarkup}
            <Page
                title="Add Store"
                breadcrumbs={[{ content: "Stores", url: "/seller/stores" }]}
            >
                <Card sectioned>
                    <AddStoreFormPolaris listUser={data} error={error} />
                </Card>
            </Page>
        </Container>
    );
};
