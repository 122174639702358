import React, { useState, useCallback, useRef, useEffect } from "react";
import { TextField, ChoiceList, Spinner } from "@shopify/polaris";
import styled from "styled-components";

import { handleError, getUnique } from "../../helper";

const Container = styled.div`
  .choiceList-wrap {
    margin-top: 1rem;
  }
  .Polaris-ChoiceList__Choices {
    max-height: 280px;
    overflow-y: scroll;
    padding-left: 2px;
  }
`;

export const FilterHasSearchMVPolaris = ({
  options,
  value,
  loading,
  error,
  onChange,
  onChangeSearch,
  prevOptionMarkup = null,
  nextOptionMarkup = null,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const typingTimeoutId = useRef(null);

  useEffect(() => {
    setSelectedOption(value.value);
  }, [value]);

  useEffect(() => {
    setDeselectedOptions((prev) => {
      let combined = [...prev, ...options];
      combined = getUnique(combined, "value");
      return combined;
    });
  }, [options]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }
    if (onChangeSearch) {
      typingTimeoutId.current = setTimeout(() => {
        onChangeSearch(value ? value.trim() : value);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOptions = deselectedOptions.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOptions && matchedOptions.label;
      });
      setSelectedOption(selected);

      if (onChange) {
        onChange({
          value: selected,
          label: selectedValue,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deselectedOptions]
  );

  return (
    <Container>
      <TextField
        label="input"
        labelHidden
        placeholder="Filter item"
        value={inputValue}
        onChange={handleInputChange}
      />
      {prevOptionMarkup}
      <div className="mt-4">
        {error && <div>Error: {handleError(error.toString())}</div>}
        {loading ? (
          <div className="flex justify-center">
            <Spinner
              accessibilityLabel="Spinner example"
              color="teal"
              size="small"
            />
          </div>
        ) : (
          <>
            {options.length > 0 ? (
              <div className="choiceList-wrap">
                <ChoiceList
                  title="choice"
                  titleHidden
                  allowMultiple
                  choices={options}
                  selected={selectedOption}
                  onChange={handleSelection}
                />
              </div>
            ) : (
              <span>No items found!</span>
            )}
          </>
        )}
      </div>
      {nextOptionMarkup}
    </Container>
  );
};
