import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React from "react";
import { FETCH_ORDER_FULFILLMENT_STATUS } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { useToastContext } from "../../shared/ToastContext";

const FetchOrderStatus = ({ open, onClose, value, refetch }) => {
  // Context
  const { toggleToast, setNotify } = useToastContext();

  const timeoutRef = React.useRef(null);
  const [fetchOrderStatus, { loading }] = useMutation(
    FETCH_ORDER_FULFILLMENT_STATUS,
    {
      onCompleted: () => {
        setNotify({ msg: "Fetch order's status success"})
        if (refetch) {
          timeoutRef.current && clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            refetch();
          }, 1500);
        }

        onClose && onClose(false);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    }
  );

  const handleSubmit = React.useCallback(() => {
    if (!value?.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    fetchOrderStatus({
      variables: {
        orderId: value.id,
      },
    }).finally(() => {
      onClose();
    });
  }, [value?.id, fetchOrderStatus, toggleToast, setNotify]);

  return (
    <Modal
      title="Fetch Order's Status"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <p>Are you sure to fetch status for this order?</p>
    </Modal>
  );
};

export default FetchOrderStatus;
