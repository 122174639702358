import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";
import { UploadPMFormPolaris } from "./UplodaPMFormPolaris";

const UPLOAD_MOCKUPS = gql`
  mutation uploadProductMockup($input: EditProductMockup!) {
    uploadProductMockup(input: $input) {
      id
      title
    }
  }
`;

export const UploadProductMockupPolaris = ({
  open,
  toggleShowModal,
  value,
  onNotificationChange,
  toggleActive,
  refetch,
}) => {
  // Mutation
  const [uploadProductMockup, { loading }] = useMutation(UPLOAD_MOCKUPS, {
    onCompleted: () => {
      if (toggleShowModal) {
        toggleShowModal();
      }
      if (onNotificationChange) {
        onNotificationChange({
          message: "Update mockup successfuly.",
          isError: false,
        });
        if (refetch) {
          refetch();
        }
      }
    },
    onError: (error) => {
      onNotificationChange({
        message: handleError(error.toString()),
        isError: true,
      });
    },
  });

  // Handle actions
  const handleSubmit = useCallback(
    (input) => {
      let id = value?.id;
      if (onNotificationChange) {
        onNotificationChange({ message: null, isError: false });
      }
      if (input) {
        uploadProductMockup({
          variables: {
            input: {
              ...input,
              id,
            },
          },
        });
      }
      if (toggleActive) {
        toggleActive();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <Modal
      title="Update Mockup"
      open={open}
      onClose={toggleShowModal}
      sectioned
    >
      <UploadPMFormPolaris
        value={value}
        toggleShowModal={toggleShowModal}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </Modal>
  );
};
