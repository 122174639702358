import { useQuery } from "@apollo/react-hooks";
import { Modal, Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { REPORT_SALE_CHANEL_OVERVIEW_BY_PRODUCT_ID } from "../../../graphql/queries";
import { formatter, handleError } from "../../../helper";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { LineChart } from "./LineChart";
import { useReportProductCtx } from "./ProductContext";

export default function ViewOrderByProductID({ saleChannel, filterByTime }) {
  const { open, selected: productID, closeModal } = useReportProductCtx();
  const { data, loading, error } = useQuery(
    REPORT_SALE_CHANEL_OVERVIEW_BY_PRODUCT_ID,
    {
      variables: {
        filter: {
          productID,
          saleChannel,
          filterByTime,
        },
      },
      skip: !open || !productID,
    },
  );

  const [state, setState] = React.useState({
    revenues: 0,
    totalUnits: 0,
    totalOrders: 0,
    totalBaseCost: 0,
  });

  // Get data
  React.useEffect(() => {
    const report = data?.reportSaleChannelOverviewByProductID;
    if (report == null) return;

    const result = {};
    result.revenues = report.revenues;
    result.totalUnits = report.totalUnits;
    result.totalOrders = report.totalOrders;
    result.totalBaseCost = report.totalBaseCost;
    setState((prev) => ({ ...prev, ...result }));
  }, [data]);

  const reportSaleChannelOverviewByProductID =
    data?.reportSaleChannelOverviewByProductID || {};
  return (
    <Modal
      open={open}
      onClose={closeModal}
      sectioned
      title="Sale channels overviews"
      large
    >
      {loading ? (
        <SkeletonPagePolaris />
      ) : error ? (
        <div>Erorr: {handleError(error?.toString())}</div>
      ) : (
        <>
          <Stack spacing="loose" vertical>
            <Stack spacing="loose">
              <Stack.Item fill>
                <Stack vertical spacing="tight">
                  <TextStyle>
                    Revenues:{" "}
                    <TextStyle variation="strong">
                      {formatter.format(state.revenues)}
                    </TextStyle>
                  </TextStyle>
                  <TextStyle>
                    Total Cost:{" "}
                    <TextStyle variation="strong">
                      {formatter.format(state.totalBaseCost)}
                    </TextStyle>
                  </TextStyle>
                </Stack>
              </Stack.Item>
              <Stack vertical spacing="tight">
                <span>
                  <span>{state.totalUnits} Line items</span>
                </span>
                <span>
                  <span>{state.totalOrders} Orders</span>
                </span>
              </Stack>
            </Stack>
            {!saleChannel && (
              <Stack spacing="loose" distribution="fill">
                <TextStyle>
                  <TextStyle variation="strong">Online Stores: </TextStyle>
                  {reportSaleChannelOverviewByProductID.onlineStore}
                </TextStyle>
                <TextStyle>
                  <TextStyle variation="strong">Amazon: </TextStyle>
                  {reportSaleChannelOverviewByProductID.amazon}
                </TextStyle>
                <TextStyle>
                  <TextStyle variation="strong">Ebay: </TextStyle>
                  {reportSaleChannelOverviewByProductID.ebay}
                </TextStyle>
                <TextStyle>
                  <TextStyle variation="strong">Etsy: </TextStyle>
                  {reportSaleChannelOverviewByProductID.etsy}
                </TextStyle>
                <TextStyle>
                  <TextStyle variation="strong">
                    Facebook Marketplace:{" "}
                  </TextStyle>
                  {reportSaleChannelOverviewByProductID.facebookMarketplace}
                </TextStyle>
                <TextStyle>
                  <TextStyle variation="strong">Tiktok: </TextStyle>
                  {reportSaleChannelOverviewByProductID.tiktok}
                </TextStyle>
              </Stack>
            )}
            <LineChart
              data={reportSaleChannelOverviewByProductID?.chartValues || []}
            />
          </Stack>
        </>
      )}
    </Modal>
  );
}
