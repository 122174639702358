import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Card,
  Checkbox,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import styled from "styled-components";
import _ from "lodash";

const Container = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  .btn-danger {
    color: rgba(211, 69, 91, 1);
  }
  .ant-card-head {
    border: 0 none;
    .ant-card-head-title {
      font-weight: normal;
    }
  }
  .form-preview {
    background: #f0f2f5;
    padding: 10px 20px;
  }
  .ant-card.ant-card-bordered .ant-card-head {
    padding: 0;
  }
  .ant-card.ant-card-bordered .ant-card-body {
    padding: 0;
  }
`;

class FieldsLiveView extends Component {
  componentDidMount() {
    const { fields } = this.props;
    let values = {};
    if (fields.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        values[field.name] = _.get(
          field,
          "configure.settings.defaultValue",
          null
        );
      }
    }
    this.props.form.setFieldsValue(values);
  }

  renderField = (field) => {
    const { configure } = field;
    const { settings } = configure;
    const { personalizedPreview } = this.props;
    switch (field.type) {
      case "text":
        return (
          <Input
            placeholder={configure.placeholder}
            disabled={personalizedPreview}
          />
        );
      case "textarea":
        return (
          <Input.TextArea
            placeholder={configure.placeholder}
            disabled={personalizedPreview}
          />
        );

      case "number":
        let props = {
          min: settings.min,
        };
        if (settings.max) {
          props.max = settings.max;
        }
        return (
          <InputNumber
            {...props}
            style={{ width: "100%" }}
            placeholder={configure.placeholder}
            disabled={personalizedPreview}
          />
        );

      case "checkbox":
        return (
          <Checkbox.Group
            options={settings.options}
            disabled={personalizedPreview}
          />
        );

      case "radio":
        return (
          <Radio.Group
            options={settings.options}
            disabled={personalizedPreview}
          />
        );
      case "dropdown":
        return (
          <Select
            style={{ width: "100%" }}
            mode={settings.mode}
            disabled={personalizedPreview}
          >
            {settings.options.map((op, idx) => (
              <Select.Option value={op.value} key={idx}>
                {op.label}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return <div />;
    }
  };

  fieldIsVisible = (field) => {
    const { visibility } = field.configure;

    let operatorFunc = {
      equal: (a, b) => {
        return a === b;
      },
      notEqual: (a, b) => {
        return a !== b;
      },
      startsWith: (a, b) => {
        return a.startsWith(b);
      },
      endsWith: (a, b) => {
        return a.endsWith(b);
      },
      contains: (a, b) => {
        return a.includes(b);
      },
      notContains: (a, b) => {
        return !a.includes(b);
      },
      greaterThan: (a, b) => {
        return a > b;
      },
      lessThan: (a, b) => {
        return a < b;
      },
    };
    if (visibility.enabled && visibility.rules.length) {
      let visible = visibility.type === "show";
      let matchAll = true;
      let matchAny = false;
      for (let i = 0; i < visibility.rules.length; i++) {
        const rule = visibility.rules[i];
        const a = this.props.form.getFieldValue(rule.field);
        const b = rule.value;
        const isMatched = operatorFunc[rule.operator](a, b);
        if (isMatched) {
          matchAny = true;
          if (visibility.match === "any") {
            break;
          }
        } else {
          if (visibility.match === "all") {
            matchAll = false;
            break;
          }
        }
      }
      if (!matchAll) {
        visible = !visible;
      }
      if (visibility.match === "any") {
        if (!matchAny) {
          visible = !visible;
        }
      }
      return visible;
    }
    return true;
  };
  render() {
    const { fields, personalizedPreview = false } = this.props;
    const { getFieldDecorator } = this.props.form;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Container fullWidth={personalizedPreview}>
        <Card
          headStyle={{ padding: 0, minHeight: 20 }}
          bodyStyle={{ padding: 0 }}
          extra={
            personalizedPreview
              ? []
              : [
                  <Button
                    key={"edit"}
                    type="link"
                    onClick={() => {
                      if (this.props.onEdited) {
                        this.props.onEdited();
                      }
                    }}
                  >
                    Edit
                  </Button>,
                  <Button
                    key={"delete"}
                    onClick={() => {
                      if (this.props.onDeleted) {
                        this.props.onDeleted();
                      }
                    }}
                    className={"btn-danger"}
                    type={"link"}
                  >
                    Delete
                  </Button>,
                ]
          }
          title={"Preview options"}
          bordered={0}
        >
          <div className={"form-preview"}>
            <Form {...layout}>
              {fields.filter((f) => f.name).map((field, index) => {
                const initValue = {
                  initialValue: _.get(
                    field,
                    "configure.settings.defaultValue",
                    null
                  ),
                  rules: [],
                };
                if (field.configure.required) {
                  initValue.rules = [
                    {
                      required: true,
                      message: _.get(
                        field,
                        "configure.errorMessage",
                        `${field.title} is required!`
                      ),
                    },
                  ];
                }
                let style = {};
                const visible = this.fieldIsVisible(field);
                if (!visible) {
                  style.display = "none";
                }
                return (
                  <Form.Item
                    style={style}
                    key={`field-item-${index}`}
                    label={field.title}
                    data-field_id={field.id}
                  >
                    {getFieldDecorator(
                      field.name,
                      initValue
                    )(this.renderField(field))}
                  </Form.Item>
                );
              })}
            </Form>
          </div>
        </Card>
      </Container>
    );
  }
}

FieldsLiveView.propTypes = {
  fields: PropTypes.array,
  onDeleted: PropTypes.func,
  onEdited: PropTypes.func,
};

export default Form.create({ name: "preview-form" })(FieldsLiveView);
