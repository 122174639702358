import React from "react";
import { ScreenOptionsPolaris } from "../shared/ScreenOptionsPolaris";

export const ScreenOptionsOPolaris = (props) => {
  const { options, onChangeFixedColumn } = props;
  return (
    <ScreenOptionsPolaris
      {...props}
      options={options}
      onChangeFixedColumn={onChangeFixedColumn}
      hasFixedColumn
    />
  );
};
