import { Tabs } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { toSlug } from "../../../helper";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import { getArtworkGuideUrl } from "../components/CompareDesignItem";

const CompareDesignItem = React.lazy(() =>
  import("../components/CompareDesignItem"),
);

export const CompareDesignTabsPolaris = (props) => {
  // Props
  const {
    designs,
    taskId,
    orderId,
    attributes,
    taskRequirement,
    isDLead,
    userConfigDesign = "",
  } = props;

  // State
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  // Get data
  useEffect(() => {
    let newTabs =
      designs && designs.length > 0
        ? designs
            .filter((i) => i.designPosition && i.file)
            .map((i) => {
              let dpName = i?.designPosition?.name;
              if (dpName) {
                return {
                  id: toSlug(dpName),
                  content: dpName,
                  accessibilityLabel: dpName,
                };
              }
              return null;
            })
            .filter(Boolean)
        : [];
    setTabs(newTabs);
  }, [designs]);

  // Handle action
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  }, []);

  return (
    <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
      {designs && designs.length > 0
        ? designs
            .filter((el) => el?.file)
            .map((ds, index) => {
              const dp = ds.designPosition ? ds.designPosition : null;
              const artworkGuideUrl = getArtworkGuideUrl(dp?.artworkGuidelines);

              return index === selectedTab ? (
                <div
                  className="task-compare-item"
                  key={ds.id}
                  style={{ marginTop: "1rem" }}
                >
                  <div className="design_inner">
                    {artworkGuideUrl ? (
                      <SuspenseComp>
                        <CompareDesignItem
                          designPosition={dp}
                          userConfigDesign={userConfigDesign}
                          isDLead={isDLead}
                          files={{
                            files: ds.file ? [ds.file] : [],
                          }}
                        />
                      </SuspenseComp>
                    ) : (
                      <ModalImageClaimPolaris
                        width="auto"
                        maxWidth="100%"
                        height="auto"
                        files={{
                          files: ds.file ? [ds.file] : [],
                        }}
                        isCompareDesign={true}
                        designPosition={dp}
                        hasShadow
                        isCompare
                        hasColorPicker
                        isDLead={isDLead}
                        orderId={orderId}
                        taskId={taskId}
                        attributes={attributes}
                        taskRequirement={taskRequirement}
                        userConfigDesign={userConfigDesign}
                        {...props}
                      />
                    )}
                  </div>
                </div>
              ) : null;
            })
        : null}
    </Tabs>
  );
};
