import React, { useState, useCallback } from "react";
import { Button, Modal, ButtonGroup } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../../helper";

export const DELETE_USERS = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export const ACTIVE_USERS = gql`
  mutation activeUser($id: ID!) {
    activeUser(id: $id)
  }
`;
export const SellerActionsPolaris = ({
  item,
  toggleActive,
  onNotificationChange,
  refetch,
  urlEdit,
}) => {
  const status = item?.status;
  const id = item?.id;

  // State
  const [open, setOpen] = useState(false);

  const [deleteUser, { loading: loadingD }] = useMutation(DELETE_USERS, {
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: "Change status to Active successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  const [activeUser, { loading: loadingA }] = useMutation(ACTIVE_USERS, {
    onCompleted: () => {
      toggleShowModal();
      if (onNotificationChange) {
        onNotificationChange({
          message: "Change status to Inactive successfuly.",
          isError: false,
        });
      }
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      if (onNotificationChange) {
        onNotificationChange({
          message: handleError(error.toString()),
          isError: true,
        });
      }
    },
  });

  // Handle action
  const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);
  const handleActiveSeller = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (id) {
      activeUser({
        variables: {
          id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleInactiveSeller = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (id) {
      deleteUser({
        variables: {
          id,
        },
      });
    }
    if (toggleActive) {
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <ButtonGroup>
        {!!urlEdit && (
          <Button children="Edit" plain url={`${urlEdit}/${item?.id}`} />
        )}
        <Button
          children={status ? "Inactive" : "Active"}
          size="slim"
          plain
          destructive={status}
          onClick={toggleShowModal}
        />
      </ButtonGroup>

      <Modal
        title="Change status for Seller"
        open={open}
        onClose={toggleShowModal}
        sectioned
        secondaryActions={[{ content: "Cancel", onaction: toggleShowModal }]}
        primaryAction={{
          content: "Submit",
          onAction: status ? handleInactiveSeller : handleActiveSeller,
          loading: status ? loadingD : loadingA,
        }}
      >
        <p>Are you sure to change status for this seller?</p>
      </Modal>
    </React.Fragment>
  );
};
