import { useMutation } from "@apollo/react-hooks";
import { ActionList, Button, Popover } from "@shopify/polaris";
import {
  DeleteMajorMonotone,
  ManagedStoreMajorTwotone,
  MarkPaidMinor,
  RefreshMajorTwotone,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import DeleteEbayButton from "../../components/ebay/DeleteEbayButton";
import { AppContext, useAppContext } from "../../context";
import { getParamByRole } from "../../helper";
import history from "../../history";
import useToggle from "../../hooks/useToggle";
import MarkStoreStatus from "../../components/store/MarkStoreStatus";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

const FETCH_STORE_ORDERS_MUTATION = gql`
  mutation fetchStoreOrders($storeId: ID!) {
    fetchStoreOrders(storeId: $storeId)
  }
`;

export const EbayActions = ({ store, refetch }) => {
  const [showModal, toggleShowModal] = useToggle(false);
  const [action, setAction] = useState(null);
  const [open, toggleOpen] = useToggle(false);

  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);

  const [fetchStoreOrders, { loading }] = useMutation(
    FETCH_STORE_ORDERS_MUTATION,
    {
      variables: {
        storeId: store?.id,
      },
      skip: !store?.id,
    },
  );

  const onAction = useCallback((act) => {
    setAction(act);
    toggleShowModal();
  }, []);

  const isDeactivated = store?.status === "Deactivated";
  const actions = [
    {
      content: "Manage",
      icon: ManagedStoreMajorTwotone,
      onAction: () => history.push(`/${param}/store/edit/${store.id}`),
    },
    {
      content: `Mark as ${isDeactivated ? "Connected" : "Deactivated"}`,
      icon: MarkPaidMinor,
      onAction: () => onAction("markStatus"),
    },
    {
      content: "Fetch orders",
      icon: RefreshMajorTwotone,
      loading,
      onAction: fetchStoreOrders,
    },
    {
      content: "Delete",
      icon: DeleteMajorMonotone,
      onAction: () => onAction("delete"),
    },
  ].filter(Boolean);

  const props = {
    open: showModal,
    onClose: toggleShowModal,
    storeId: store?.id,
    store: store,
    refetch,
    isDeactivated,
  };
  const actionsMarkup = {
    delete: <DeleteEbayButton {...props} />,
    markStatus: <MarkStoreStatus {...props} />,
  };

  const activator = (
    <Button children="Actions" disclosure onClick={toggleOpen} />
  );

  return (
    <Container>
      <Popover activator={activator} onClose={toggleOpen} active={open}>
        <ActionList items={actions} />
      </Popover>
      {actionsMarkup[action]}
    </Container>
  );
};
