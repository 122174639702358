import { checkRole, getParamByRole } from "../../helper";

export function getExportPath(user) {
  const { isSeller, isSupplier } = checkRole(user);
  let param = "admin";
  if (isSeller || isSupplier) {
    param = getParamByRole(user);
  }
  return param;
}
