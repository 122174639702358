import { Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { ApolloConsumer } from "@apollo/react-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
} from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
import styled from "styled-components";
import { fieldsProduct } from "../../constants";
import { AppContext } from "../../context";
import { GET_FILE_BY_ID, GET_PRODUCT_BY_TITLE } from "../../graphql/queries";
import {
  getProductMockupFolder,
  getUnique,
  handleDeepEqual,
  // getProductPrintFilesFolder,
  handleError,
  handleProductFields,
} from "../../helper";
import history from "../../history";
import {
  AMZ_MESSAGE_ERROR,
  MAX_LENGTH,
  MAX_TITLE_CHARACTERS,
  TEAM_ROLE,
  TITLE_LENGTH_MESS,
  UPLOAD_FOLDER,
  USER_ROLE,
  WHICH_FIELD,
} from "../../variable";
import CustomizeButton from "../customize/CustomizeButton";
import { FULFILLED } from "../file/hooks";
import Price from "../Price";
import { genHelpText } from "../product/components/ShortTitle";
import ProductPrintFiles from "../product/ProductPrintFiles";
import ProductVariants from "../product/ProductVariants";
import { validateDimensions } from "../product/_utils";
import CollectionsAutoComplete from "../shared/CollectionsAutoComplete";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import PageTitle from "../shared/PageTitle";
import TagsAutoComplete from "../shared/TagsAutoComplete";
import MediaSelectorButton from "../supplier/MediaSelectorButton";
import Wysiwyg from "../Wysiwyg";
import { AddProductTemplate } from "./AddProductTemplate";
import { ColorsComponent } from "./ColorsComponent";
import { ProductTimeline } from "./ProductTimeline";
import PushHistory from "./PushHistory";
import PushProduct from "./PushProduct";
import { UpdatePriceForVariants } from "./UpdatePriceForVariants";

const Container = styled.div`
  .ant-card {
    margin-bottom: 20px;
  }
  .tag-wrap {
    border-radius: 3px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .product-fake-wrap {
    .ant-tabs-nav-wrap {
      padding-left: 0;
    }
    .ant-tabs-tab {
      padding-left: 0;
      max-width: 180px;
      white-space: break-spaces;
      text-align: left;
      padding-right: 12px;
    }
    .ant-tabs-ink-bar {
      height: auto;
    }
    .ant-tabs-bar {
      height: 400px;
    }
    .ant-tabs-content {
      height: 400px;
      overflow-y: scroll;
    }
    .product-fake-item {
      label {
        display: inline-block;
      }
      &.files {
        width: 65%;
      }
      & + .product-fake-item {
        margin-top: 1rem;
      }
    }
  }
`;

const { TabPane } = Tabs;

const CREATE_PRODUCT_TEMPLATE = gql`
  mutation createProductTemplate($input: NewProductTemplate!) {
    createProductTemplate(input: $input) {
      id
      name
      slug
    }
  }
`;

class AddProductForm extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      showPreview: false,
      disabled: true,
      maxUpdate: null,
      defaultFieldsValue: null,
      showBtnSave: null,
      designPosition: null,
      productBase: null,
      template: {
        visible: false,
        loading: false,
      },
      productVariantsVisible: false,
      productAttributes: null,
      variantsState: null,
      currentProductBase: props.productBase,
      firstColor: null,
      labels: null,
      mainImgId: null,
      hasSetIgnoredIsFalse: true,
      sortVariant: {},
    };

    this.handleCloseVariants = this.handleCloseVariants.bind(this);
    this.showProductVariants = this.showProductVariants.bind(this);
    this.handleChangeAttrOption = this.handleChangeAttrOption.bind(this);
    this.onVariableDisabledChanged = this.onVariableDisabledChanged.bind(this);
  }

  handleChangeAttrOption = (
    attrSlug,
    optionSlug,
    data,
    productBaseVariants,
  ) => {
    let state = this.state;
    let newProductAttributes = state.productAttributes;
    let disabledVal =
      newProductAttributes[attrSlug]["options"][optionSlug]["disabled"];
    newProductAttributes[attrSlug]["options"][optionSlug]["disabled"] =
      !disabledVal;
    let countAttrs = Object.keys(newProductAttributes).length;

    // Get list active options of every attribute
    let attrActivatedOptions = {};
    if (countAttrs > 1) {
      _.forOwn(newProductAttributes, function (el, key) {
        let optionActivated = [];
        _.forOwn(el.options, function (v) {
          if (!v.disabled) {
            optionActivated.push(v.label);
          }
        });
        attrActivatedOptions[key] = optionActivated;
      });
    }

    let newProductBaseVariants = state.variantsState
      ? state.variantsState
      : productBaseVariants;
    let affectKeys = [];
    _.forOwn(newProductBaseVariants, function (el, key) {
      if (el?.productBaseVariant?.attributes?.length > 0) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (v.slug === attrSlug && v.option === data.label) {
            let pushVal = {
              key: key,
              disabled: el.disabled,
            };
            affectKeys.push(pushVal);

            let newDisabledVar = !disabledVal;
            // If product has more than 2 attributes, then check complex condition active variants.
            if (countAttrs > 1 && disabledVal) {
              // Only check case active attribute.
              if (el?.productBaseVariant?.attributes?.length > 0) {
                let countMatch = 0;
                el.productBaseVariant.attributes.forEach((at) => {
                  let atSlug = at.slug;
                  let atOption = at.option;
                  let attrOptionsActivated = attrActivatedOptions[atSlug];
                  if (
                    attrOptionsActivated.length > 0 &&
                    attrOptionsActivated.includes(atOption)
                  ) {
                    countMatch++;
                  }
                });
                if (countMatch !== countAttrs) {
                  newDisabledVar = disabledVal;
                }
              }
            }
            return (el.disabled = newDisabledVar);
          }
        });
      }
    });

    // Count product to prevent disable all variants
    let countActivated = this.countActivatedVariants(newProductBaseVariants);
    if (0 === countActivated) {
      newProductAttributes[attrSlug]["options"][optionSlug]["disabled"] =
        disabledVal;
      if (affectKeys.length > 0) {
        affectKeys.forEach(function (v, k) {
          newProductBaseVariants[v.key].disabled = v.disabled;
        });
      }
      alert("You must keep at least one option for each attributes");
    }
    this.setState({
      productAttributes: newProductAttributes,
      variantsState: newProductBaseVariants,
      showBtnSave: false,
    });

    this.ref.current.setFieldsValue({
      variants: newProductBaseVariants,
    });
  };

  countActivatedVariants = (variants) => {
    let countActivated = 0;
    _.forOwn(variants, function (el, key) {
      if (el?.productBaseVariant?.attributes?.length > 0) {
        if (!el.disabled) {
          countActivated++;
        }
      }
    });
    return countActivated;
  };

  handleCloseVariants = () => {
    let { sortVariant } = this.state;
    let newVariant =
      sortVariant && sortVariant.enabledValues && sortVariant.disabledValues
        ? [...sortVariant.enabledValues, ...sortVariant.disabledValues]
        : [];

    let currenVariant = this.ref.current.getFieldValue("variants");
    this.setState(
      (prevState) => ({
        productVariantsVisible: false,
        variantsState:
          newVariant.length > 0 ? newVariant : prevState.variantsState,
      }),
      () => {
        this.ref.current.setFieldsValue({
          variants: newVariant.length > 0 ? newVariant : currenVariant,
        });
      },
    );
  };

  showProductVariants = () => {
    this.setState({
      productVariantsVisible: true,
    });
  };

  strToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  getProductBaseAttrs = (productBaseAttrs, activateAttrs, variants) => {
    let pBaseAttrs = {};

    let disabledAll = false;
    if (variants) {
      let countActivated = this.countActivatedVariants(variants);
      if (0 === countActivated) {
        disabledAll = true;
      }
    }

    if (productBaseAttrs && productBaseAttrs.length > 0) {
      productBaseAttrs.forEach((val) => {
        let attrOptions = {};
        let enableFirst = !activateAttrs && !variants && "color" === val.slug;
        val.options.forEach((opt) => {
          let disableVal = false;
          if (disabledAll) {
            disableVal = true;
          }
          if (
            activateAttrs &&
            activateAttrs[val.slug] &&
            activateAttrs[val.slug].length > 0
          ) {
            disableVal = true;
            if (_.includes(activateAttrs[val.slug], opt)) {
              disableVal = false;
            }
          }
          if (enableFirst) {
            let firstValue = _.head(val.options);
            if (opt === firstValue) {
              disableVal = false;
            } else {
              disableVal = true;
            }
          }
          attrOptions[this.strToSlug(opt)] = {
            label: opt,
            disabled: disableVal,
          };
        });
        pBaseAttrs[val.slug] = {
          name: val.name,
          slug: val.slug,
          options: attrOptions,
        };
      });
    }
    return pBaseAttrs;
  };

  getActivatedProductAttrs = (variants) => {
    let activatedAttrs = {};

    _.forOwn(variants, function (el, key) {
      if (!el.disabled && el?.productBaseVariant?.attributes?.length > 0) {
        el.productBaseVariant.attributes.forEach((v) => {
          if (!activatedAttrs[v.slug]) {
            activatedAttrs[v.slug] = [];
          }
          activatedAttrs[v.slug].push(v.option);
        });
      }
    });
    return activatedAttrs;
  };

  onVariableDisabledChanged = (variants) => {
    let state = this.state;
    if (
      state &&
      state.currentProductBase &&
      state.currentProductBase.attributes
    ) {
      let activatedAttrs = this.getProductBaseAttrs(
        this.state.currentProductBase.attributes,
        this.getActivatedProductAttrs(variants),
        variants,
      );
      this.setState({
        productAttributes: activatedAttrs,
      });
    }
    this.setState({
      showBtnSave: false,
    });
  };

  componentDidMount() {
    const { value, productBase, template } = this.props;
    let isUseTemplate = false;
    if (!value && productBase) {
      let title = "",
        description = "",
        shortDescription = "";
      let tags = [],
        collections = [];

      let product = {};
      let fields = _.get(template, "data.fields", []);
      if (fields) {
        fields = fields.map((f) => {
          if (typeof f.__typename !== "undefined") {
            delete f.__typename;
          }
          if (typeof f.sorting !== "undefined") {
            delete f.sorting;
          }
          return f;
        });
      }

      if (
        template &&
        template.data &&
        template.data.products &&
        template.data.products.length
      ) {
        isUseTemplate = true;
        product = _.head(template.data.products);
        title = product.title;
        shortDescription = product.shortDescription;
        description = product.description;
        collections = _.get(product, "collections", []) || [];
        tags = _.get(product, "tags", []) || [];
      }

      let firstColor;
      if (
        productBase &&
        productBase.attributes &&
        productBase.attributes.length > 0
      ) {
        productBase.attributes.forEach((att) => {
          if ("color" === att.slug && att.options && att.options.length) {
            firstColor = _.head(att.options);
            this.setState({
              firstColor,
            });
          }
        });
        this.setState({
          productAttributes: this.getProductBaseAttrs(productBase.attributes),
        });
      }

      this.ref.current.setFieldsValue({
        productBaseId: productBase.id,
        variants: this.getProductVariants(
          productBase,
          product,
          true,
          firstColor,
        ),
        designPositions: this.getDesignPositions(productBase),
        mockup: this.getMockup(productBase),
        description: template ? description : productBase.defaultContent,
        shortDescription: template
          ? shortDescription
          : productBase.defaultShortDescription,
        tags,
        collections,
        title,
        personalized: _.get(template, "data.personalized", false),
        fields: fields ? fields : [],
      });

      this.setState({
        showPreview: _.get(template, "data.personalized", false),
      });
    }
    if (value) {
      let stateVal = {
        showPreview: _.get(value, "personalized", false),
      };
      if (
        productBase &&
        productBase.attributes &&
        productBase.attributes.length > 0
      ) {
        stateVal["productAttributes"] = this.getProductBaseAttrs(
          productBase.attributes,
          this.getActivatedProductAttrs(value.variants),
        );
      }
      if (value.variants) {
        stateVal["variantsState"] = value.variants;
      }

      this.setState(stateVal);
      const dp = _.get(value, "designPositions", []);
      if (!dp || dp.length === 0) {
        this.ref.current.setFieldsValue({
          designPositions: this.getDesignPositions(productBase),
        });
      }
    }

    let defaultFieldsValue = JSON.parse(
      JSON.stringify(this.ref.current.getFieldsValue(fieldsProduct)),
    );

    let setState = {
      defaultFieldsValue,
      mainImgId: this.props.value?.mainImageId || null,
    };
    if (productBase) {
      setState["currentProductBase"] = productBase;
    }

    if (isUseTemplate) {
      let templateProduct = _.head(template.data.products);
      let productVariants = templateProduct.variants;
      let templatePBase = _.head(template.data.productBases);
      let templatePBaseVars = _.get(templatePBase, "variants", []) || [];
      let templatePBaseAttr = _.get(templatePBase, "attributes", []) || [];
      let buildProductData = [];

      productVariants.forEach((val) => {
        let pBaseId = val.productBaseVariantID;
        templatePBaseVars.forEach((v) => {
          if (v.id === pBaseId) {
            let pVariant = {
              attributes: v.attributes,
            };
            val["productBaseVariant"] = pVariant;
            val["productBaseVariantId"] = v.id;
            val["image"] = null;
          }
        });
        buildProductData.push(val);
      });
      setState["variantsState"] = buildProductData;
      buildProductData = { ...buildProductData };

      let pBaseAttrs = this.getProductBaseAttrs(
        templatePBaseAttr,
        this.getActivatedProductAttrs(buildProductData),
      );
      if (pBaseAttrs) {
        setState["productAttributes"] = pBaseAttrs;
      }

      // Default mockups
      const defaultMockupIds = _.get(template, "defaultMockupIds", []);
      let seft = this;
      if (defaultMockupIds?.length > 0) {
        const { apolloClient: client } = this.props;

        (async function () {
          const res = await Promise.allSettled(
            defaultMockupIds.map(async (id) => {
              const value = await client.query({
                query: GET_FILE_BY_ID,
                variables: { id },
              });
              return value;
            }),
          );

          let mockup = res
            .filter(
              (item) =>
                item.status === FULFILLED && item.value?.data?.fileById != null,
            )
            .map((item) => item.value.data.fileById);

          if (mockup?.length > 0) {
            seft.setState({ mockups: mockup });
            seft.ref.current.setFieldsValue({ mockup: mockup });
          }
        })();
      }

      this.ref.current.setFieldsValue({
        variants: this.getProductVariants(templatePBase, templateProduct),
      });
    }

    this.setState(setState);
  }

  componentDidUpdate(prevProps) {
    let { clickSave } = this.props;
    if (clickSave !== prevProps.clickSave) {
      this.ref.current.submit();
    }
  }

  getMockup = (productBase) => {
    if (!productBase.mockup) {
      return [];
    }
    return productBase.mockup.map((mk) => {
      return {
        mockup: mk,
        productBaseId: mk.id,
        file: null,
      };
    });
  };
  getDesignPositions = (productBase) => {
    if (!productBase.designPositions) {
      return [];
    }
    return productBase.designPositions.map((dp) => {
      return {
        designPosition: dp,
        designPositionId: dp.id,
        file: null,
      };
    });
  };
  getProductVariants = (productBase, product = {}, enableFirst, firstColor) => {
    if (!productBase.variants) {
      return [];
    }

    return productBase.variants.map((variant, idx) => {
      let disabledVal = true;
      if (
        variant.attributes &&
        variant.attributes.length &&
        enableFirst &&
        firstColor
      ) {
        variant.attributes.forEach((att) => {
          if (
            att.slug &&
            "color" === att.slug &&
            att.option &&
            att.option.toLowerCase() === firstColor.toLowerCase()
          ) {
            disabledVal = false;
          }
        });
      }
      let currentBaseVarID = variant.id;
      let i = idx;
      if (Object.keys(product).length) {
        i = _.findIndex(product && product.variants, {
          productBaseVariantID: currentBaseVarID,
        });
      }
      return {
        productBaseVariantId: variant.id,
        productBaseVariant: variant,
        image: null,
        regularPrice: Object.keys(product).length
          ? product.variants[i].regularPrice
          : variant.regularPrice,
        salePrice: Object.keys(product).length
          ? product.variants[i].salePrice
          : variant.salePrice,
        disabled:
          enableFirst && firstColor
            ? disabledVal
            : Object.keys(product).length
            ? product.variants[i].disabled
            : variant && variant.disabled
            ? variant.disabled
            : false,
      };
    });
  };

  handleError = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er.message.toLowerCase());
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er.message) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };
  onFinish = (values, store) => {
    const { productBase, template, isEditProduct, value } = this.props;
    const thisState = this.state;

    values = JSON.parse(JSON.stringify(values));
    ["collections", "tags"].forEach((name) => {
      if (values[name] && values[name].length) {
        values[name] = values[name].map((v) => v.id);
      }
    });

    values.mockup = values.mockup.map((m) => {
      return {
        fileId: m.id,
        productBaseId: values.productBaseId,
      };
    });

    // Unique fileId
    values.mockup = getUnique(values.mockup, "fileId");

    if (values.designPositions && values.designPositions.length) {
      values.designPositions = values.designPositions.map((v) => {
        if (undefined === v.fileId) {
          v["fileId"] = v.file && v.file.id ? v.file.id : null;
        }
        if ("" === v.fileId) {
          delete v["fileId"];
        }
        ["designPosition", "__typename", "file"].forEach((name) => {
          if (typeof v[name] !== "undefined") {
            delete v[name];
          }
        });

        return v;
      });
    }

    let psdFileIds = [];
    if (values.psdFiles && values.psdFiles.length > 0) {
      let inputPsdFiles = values.psdFiles;
      inputPsdFiles.forEach((v) => {
        if (v && v.id && v.id.length > 0) {
          psdFileIds.push(v.id);
        }
      });
    }
    if (psdFileIds.length > 0) {
      values.psdFiles = psdFileIds;
    }

    if (!values.variants) {
      if (isEditProduct && value && value.variants) {
        let editVariant = [];
        value.variants.forEach((variant) => {
          const amazonImages =
            variant.amazonImages?.length > 0
              ? variant.amazonImages.map((i) => i.file)
              : [];
          let varData = {
            disabled: variant.disabled,
            id: variant.id,
            image: variant.image,
            amazonImages,
            productBaseVariantId: variant.productBaseVariantId,
            regularPrice: variant.regularPrice,
            salePrice: variant.salePrice,
          };
          editVariant.push(varData);
        });
        values["variants"] = editVariant;
      } else if (thisState && thisState.variantsState) {
        values["variants"] = thisState.variantsState;
      } else {
        let pData = {};
        if (
          template &&
          template.data &&
          template.data.products &&
          template.data.products.length
        ) {
          pData = _.head(template.data.products);
        }
        values["variants"] = this.getProductVariants(productBase, pData);
      }
    }

    if (values.variants && values.variants.length) {
      values.variants = values.variants.map((v) => {
        [
          "__typename",
          "productBaseVariant",
          "sku",
          "sorting",
          "productBaseVariantID",
        ].forEach((name) => {
          if (typeof v[name] !== "undefined") {
            delete v[name];
          }
        });
        if (v.image) {
          v.image = v.image.id;
        }
        if (v.amazonImages?.length > 0) {
          v.amazonImages = v.amazonImages
            .map((i) => (i ? (i.file ? i.file.id : i.id) : null))
            .filter(Boolean);
        }
        return v;
      });
    }

    // Sort base
    values["sortBases"] = [
      { productBaseId: values.productBaseId, position: 0 },
    ];

    values.productBases = [values.productBaseId];
    delete values.productBaseId;
    if (values.pushToStores) {
      values.pushToStores = values.pushToStores.map((s) => s.id);
    }
    if (!this.isChanged && store) {
      values.pushToStores = store && store.id !== "all" ? [store] : null;
    }
    // let canSubmit = true;
    // let propsDP = this.props.value && this.props.value.designPositions;
    // let firstItemHaveFile = propsDP && propsDP.find((i) => i.file);

    // if (firstItemHaveFile) {
    //   let newDP = values.designPositions;
    //   let countDPHaveFileId = newDP && newDP.find((i) => i.fileId);
    //   if (!countDPHaveFileId) {
    //     notification.error({
    //       message: "Please select at least one design position.",
    //     });
    //     canSubmit = false;
    //   }
    //   values.designPositions = newDP;
    // }

    let productsFake = this.state.fakeProductDesignPositions;
    let newPF = [];
    if (productsFake && productsFake.length) {
      newPF = productsFake.map((pf) => {
        return {
          productId: pf.productId,
          designPositions: pf.designPositions.map((dp) => {
            return {
              designPositionId: dp.designPosition.id,
              fileId: dp.fileId ? dp.fileId : "",
            };
          }),
          psd: pf.psd,
          psdFiles: pf.psdFiles.map((f) => f.id),
        };
      });
    }
    if (isEditProduct) {
      values["fakeProducts"] = newPF;
    }

    // if (!values.designPositions || values.designPositions?.length === 0) {
    //   notification.error({ message: "Design position is required"})
    //   return;
    // }
    // if (canSubmit) {
    this.props.onSubmit(values);
    // }
  };

  handleShowBtnSave = () => {
    let defaultFieldsValue = this.state.defaultFieldsValue && {
      ...this.state.defaultFieldsValue,
    };
    let newFieldsValue;
    let { current } = this.ref;
    if (current) {
      newFieldsValue = { ...current.getFieldsValue(fieldsProduct) };
      newFieldsValue = JSON.parse(JSON.stringify(newFieldsValue));
    }

    if (newFieldsValue && defaultFieldsValue) {
      let { value, handleBtnSave } = this.props;
      let values = handleProductFields(newFieldsValue, defaultFieldsValue);

      newFieldsValue = values.newFieldsValue;
      defaultFieldsValue = values.defaultFieldsValue;

      let { designPositions } = this.state;
      if (designPositions && designPositions.length) {
        designPositions.forEach((i) => {
          if (null === i.fileId) {
            delete i.fileId;
          }
        });
        newFieldsValue.designPositions = this.state.designPositions;
      }

      let title = current.getFieldValue("title");
      let shortTitle = current.getFieldValue("shortTitle");
      let amzTitle = current.getFieldValue("amzTitle");

      let btnName = value ? "Save Product" : "Publish Product";
      let aggregations = { title, btnName, shortTitle, amzTitle };
      if (handleDeepEqual(newFieldsValue, defaultFieldsValue)) {
        this.setState({
          showBtnSave: true,
        });
        if (handleBtnSave) {
          handleBtnSave(true, aggregations);
        }
      } else {
        this.setState({
          showBtnSave: false,
        });
        if (handleBtnSave) {
          handleBtnSave(false, aggregations);
        }
      }
    }
  };

  handleVisibleChange = () => {
    this.setState((prevState) => {
      return {
        template: {
          visible: !prevState.template.visible,
        },
      };
    });
  };

  handleCreateTemplate = ({ name, slug, defaultMockups, client }) => {
    const { productBase } = this.props;
    let { current } = this.ref;
    let values;
    if (current) {
      values = { ...current.getFieldValue() };
    }
    const defaultMockupIds = (defaultMockups || [])
      .map((val) => val.id)
      .filter(Boolean);

    const input = {
      name,
      slug,
      defaultMockupIds,
      productBaseId: productBase.id,
      fields: (values.fields || []).map(({ id, ...rest }) => rest),
      product: {
        title: values.title,
        description: values.description,
        productbaseId: values.productBaseId,
        shortDescription: values.shortDescription,
        tagIds: values.tags ? values.tags.map((t) => t.id) : [],
        variants: values.variants.map((v) => {
          return {
            salePrice: v.salePrice,
            regularPrice: v.regularPrice,
            productBaseVariantID: v.productBaseVariantId,
            disabled: v.disabled,
          };
        }),
        collectionIds: values.collections
          ? values.collections.map((c) => c.id)
          : [],
      },
    };
    client
      .mutate({
        mutation: CREATE_PRODUCT_TEMPLATE,
        variables: {
          input: input,
        },
      })
      .then((res) => {
        if (res) {
          notification.success({
            message: `Template ${name} has been saved.`,
          });
          this.setState((prevState) => {
            return {
              template: {
                ...prevState.template,
                loading: false,
              },
            };
          });
          this.handleVisibleChange();
        }
      })
      .catch((e) => {
        notification.error({
          message: handleError(e.toString()),
        });
        this.setState((prevState) => {
          return {
            template: {
              ...prevState.template,
              loading: false,
            },
          };
        });
      });
  };

  countState = (variantsState) => {
    let counEnabled = 0;
    let counDisabled = 0;
    variantsState &&
      variantsState.length &&
      variantsState.forEach((val) => {
        if (!val.disabled) {
          counEnabled++;
        } else {
          counDisabled++;
        }
      });
    return {
      counEnabled,
      counDisabled,
    };
  };

  handleUpdatePrice = (productBaseVariants) => {
    let { isEditProduct } = this.props;
    let value = this.state.labels;
    let { variantsState } = this.state;
    let newProductBaseVariants = variantsState
      ? variantsState
      : productBaseVariants;

    _.forOwn(value, (el) => {
      let label = el && el.label;
      let newRegularPrice = el && el.regularPrice;
      let newSalePrice = el && el.salePrice;
      _.forOwn(newProductBaseVariants, (v) => {
        if (
          v.productBaseVariant &&
          v.productBaseVariant.attributes &&
          v.productBaseVariant.attributes.length
        ) {
          _.forOwn(v.productBaseVariant.attributes, (att) => {
            if (att.option === label) {
              v.regularPrice = newRegularPrice
                ? newRegularPrice
                : v.regularPrice;
              v.salePrice = newSalePrice ? newSalePrice : v.salePrice;
            }
          });
        }
      });
    });

    this.setState({
      variantsState: newProductBaseVariants,
    });

    if (!isEditProduct && !this.state.isDrap) {
      newProductBaseVariants =
        newProductBaseVariants &&
        newProductBaseVariants.sort(
          (a, b) => a.productBaseVariant.sorting - b.productBaseVariant.sorting,
        );
    }

    this.ref.current.setFieldsValue({
      variants: newProductBaseVariants,
    });
  };

  showNotification = (err) => {
    let uniqueMessage = {};
    _.each(err, (e) => {
      _.each(_.get(e, "errors", []), (er) => {
        const msg = _.trim(er ? er.toLowerCase() : null);
        if (typeof uniqueMessage[msg] === "undefined") {
          notification.error({ message: handleError(er) });
        }
        uniqueMessage[msg] = true;
      });
    });
  };
  validateTitle = (_rule, value, callback) => {
    let error = null;
    if (!value) {
      error = "Product title is required.";
    } else if (value.length > MAX_LENGTH) {
      error = TITLE_LENGTH_MESS;
    }
    error ? callback(error) : callback();
  };

  designPositionRef = React.createRef(null);
  render() {
    const {
      value,
      sku,
      loading,
      productBase,
      refetch,
      isEditProduct,
      currentParam,
      refetchTimeline,
      isProductIgnored,
      productId,
    } = this.props;

    const { designPositionRef } = this;

    const max = this.state.maxUpdate
      ? this.state.maxUpdate
      : productBase.regularPrice;
    const showVariants =
      productBase && productBase.variants && productBase.variants.length;

    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    const productAttrs = this.state.productAttributes;
    const productBaseVariants = this.getProductVariants(
      productBase,
      {},
      true,
      this.state.firstColor,
    );

    let { variantsState } = this.state;
    let count = this.countState(
      variantsState ? variantsState : productBaseVariants,
    );

    let findPB = this.props.productBases.find((pb) => pb.id === productBase.id);
    let productsFake = value && value.fakeProductDesignPositions;

    let { currentUser } = this.context;
    let userRole = _.get(currentUser, "roles", []);
    let teamRole = _.get(currentUser, "teamUser.role", null);
    let isMarketplaceManager = false;
    if (userRole.includes(USER_ROLE.Seller)) {
      if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
        isMarketplaceManager = true;
      }
    }

    const SelectAfter = ({ originUrl }) => {
      const href = originUrl && originUrl.length > 0 ? originUrl : "foo";
      return (
        <CustomLinkPolaris href={href}>
          <LinkOutlined />
        </CustomLinkPolaris>
      );
    };

    // Change design resource url
    let newPsd = value?.psd ? value.psd : null;
    let psdSplitted = newPsd ? newPsd.split("|") : null;
    if (Array.isArray(psdSplitted)) {
      if (psdSplitted.length > 1) {
        psdSplitted = psdSplitted.join(" \n ");
      } else {
        psdSplitted = psdSplitted[0];
      }
    }

    // Facebook title
    const fbTitleValue =
      this.ref.current && this.ref.current.getFieldValue("shortTitle");
    const fbTitleHelp = genHelpText(fbTitleValue, MAX_TITLE_CHARACTERS.fbTitle);

    // Amazon Title
    const aTitleValue =
      this.ref.current && this.ref.current.getFieldValue("amzTitle");
    const aTitleHelp = genHelpText(aTitleValue, MAX_TITLE_CHARACTERS.amzTitle);

    // Origin product title
    const titleValue =
      this.ref.current && this.ref.current.getFieldValue("title");
    const titleHelp = genHelpText(titleValue);

    return (
      <React.Fragment>
        <Prompt
          when={false === this.state.showBtnSave}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <ApolloConsumer>
          {(client) => (
            <AppContext.Consumer>
              {({ store }) => (
                <Container>
                  <Form
                    {...layout}
                    initialValues={{
                      title: _.get(value, "title", ""),
                      shortTitle: _.get(value, "shortTitle"),
                      amzTitle: _.get(value, "amzTitle"),
                      description: _.get(
                        value,
                        "description",
                        productBase.defaultContent,
                      ),
                      shortDescription: value
                        ? value.shortDescription
                        : productBase.defaultShortDescription,
                      collections:
                        value && value.collections ? value.collections : [],
                      tags: value && value.tags ? value.tags : [],
                      productBaseId: productBase ? productBase.id : null,
                      // variants: value ? value.variants : [],

                      // Sort variants

                      variants: value
                        ? value.variants &&
                          value.variants.length &&
                          value.variants.sort((a, b) => a.sorting - b.sorting)
                        : [],

                      regularPrice: productBase
                        ? productBase.regularPrice
                        : null,
                      salePrice: productBase ? productBase.salePrice : null,
                      personalized: value ? value.personalized : false,
                      fields:
                        value && value.fields && value.fields.length > 0
                          ? value.fields.map((f) => {
                              if (typeof f.__typename !== "undefined") {
                                delete f.__typename;
                              }
                              if (typeof f.sorting !== "undefined") {
                                delete f.sorting;
                              }
                              return f;
                            })
                          : [],
                      designPositions: value ? value.designPositions : [],
                      mockup: value ? value.images.map((img) => img.file) : [],
                      pushToStores:
                        store && store.id !== "all" ? [store] : null,
                      updateProductStore: false,
                      psd: value ? psdSplitted : null,
                      psdFiles: value && value.psdFiles ? value.psdFiles : null,
                      hasSetIgnoredIsFalse: true,
                    }}
                    ref={this.ref}
                    onFinish={async (values) => {
                      // Validate file design
                      const dpRef = designPositionRef.current || {};
                      const dimensions = dpRef?.state?.dimensions || {};
                      const error = validateDimensions({ dimensions });
                      if (error != null) {
                        notification.error({ message: error.message });
                        return;
                      }

                      this.setState({
                        showBtnSave: true,
                      });
                      this.handleUpdatePrice(productBaseVariants);
                      if (!value) {
                        values.variants =
                          this.ref.current.getFieldValue("variants");
                      }
                      let { mainImgId } = this.state;
                      let mainImageId = this.props.value?.mainImageId;

                      const curMockups = values.mockup || [];
                      const [firstImg] = curMockups;
                      if (!mainImgId) {
                        if (
                          mainImageId &&
                          curMockups.includes((m) => m.id === mainImageId)
                        ) {
                          mainImgId = mainImageId;
                        }
                        if (firstImg != null) {
                          mainImgId = firstImg.id;
                        }
                      }
                      values["mainImageId"] = mainImgId;

                      // Check amazon title
                      const prevAmzTitle = _.get(value, "amzTitle");
                      const productID = _.get(value, "id", undefined);
                      const { amzTitle } = values || {};
                      if (amzTitle && amzTitle !== prevAmzTitle) {
                        const { data } = await client.query({
                          query: GET_PRODUCT_BY_TITLE,
                          variables: {
                            title: amzTitle,
                            whichField: WHICH_FIELD.AmzTitle,
                            productID,
                          },
                        });
                        if (data?.getProductsByTitle?.total > 0) {
                          notification.error({
                            message: AMZ_MESSAGE_ERROR,
                            duration: 3000,
                          });
                          this.setState({
                            showBtnSave: false,
                          });
                          return;
                        }
                      }
                      this.onFinish(values, store);
                    }}
                    scrollToFirstError
                    onFinishFailed={({ _values, errorFields }) =>
                      this.showNotification(errorFields)
                    }
                    onValuesChange={() => {
                      this.setState({
                        changedValues: true,
                      });
                    }}
                  >
                    <PageTitle
                      subTitle={`Products`}
                      title={
                        this.props.title ? this.props.title : "Add Product"
                      }
                      link={`/${currentParam}/products`}
                      action={
                        value && (
                          <Space>
                            {/* Clone product  */}
                            {isEditProduct && (
                              // <CloneProductActions id={productId} isProduct />
                              <Button
                                children="Clone Product"
                                onClick={() => {
                                  const props = {
                                    productBases: this.props.productBases,
                                    productBase: this.props.productBase,
                                  };

                                  const curRef = this.ref.current;
                                  const value = curRef.getFieldsValue();

                                  let variants =
                                    curRef.getFieldValue("variants");

                                  variants = (variants || []).map(
                                    ({ productBaseVariant, ...item }) => item,
                                  );
                                  const fields = curRef.getFieldValue("fields");

                                  const regularPrice =
                                    curRef.getFieldValue("regularPrice");

                                  const salePrice =
                                    curRef.getFieldValue("salePrice");
                                  value.regularPrice = regularPrice;
                                  value.salePrice = salePrice;
                                  value.productAttrs = productAttrs;
                                  value.variants = variants;
                                  value.fields = fields;
                                  const values = JSON.stringify({
                                    props,
                                    value,
                                  });
                                  history.push(
                                    `/${currentParam}/products/clone`,
                                    values,
                                  );
                                }}
                              />
                            )}

                            <Link to={`/${currentParam}/products/add`}>
                              <Button type="primary">Add Product</Button>
                            </Link>
                            <Link to={`/${currentParam}/products/add-campaign`}>
                              <Button type="primary">Add Campaign</Button>
                            </Link>
                          </Space>
                        )
                      }
                    />
                    <Row gutter={16}>
                      <Col span={18}>
                        <Card>
                          <Form.Item
                            name={"title"}
                            label={"Title"}
                            help={titleHelp || undefined}
                            rules={[
                              {
                                required: true,
                                validator: this.validateTitle,
                              },
                            ]}
                          >
                            <Input
                              placeholder={"Product title"}
                              onChange={this.handleShowBtnSave}
                            />
                          </Form.Item>
                          <Form.Item
                            name="shortTitle"
                            label="Facebook Title"
                            help={fbTitleHelp}
                          >
                            <Input
                              maxLength={MAX_TITLE_CHARACTERS.fbTitle}
                              placeholder="Enter facebook title"
                              onChange={this.handleShowBtnSave}
                            />
                          </Form.Item>
                          <Form.Item
                            name="amzTitle"
                            label="Amazon Title"
                            help={aTitleHelp}
                          >
                            <Input
                              maxLength={MAX_TITLE_CHARACTERS.amzTitle}
                              placeholder="Enter amazon title"
                              onChange={this.handleShowBtnSave}
                            />
                          </Form.Item>
                          <Form.Item label={"Description"} name={"description"}>
                            <Wysiwyg onChange={this.handleShowBtnSave} />
                          </Form.Item>
                          {/* <Form.Item
                            name={"shortDescription"}
                            label={"Short Description"}
                          >
                            <Wysiwyg onChange={this.handleShowBtnSave} />
                          </Form.Item> */}
                        </Card>
                        {/* <Card>
                        <Form.Item label="Design Resources" name="psd">
                          <Input.TextArea
                            rows={4}
                            onChange={this.handleShowBtnSave}
                          />
                        </Form.Item>
                      </Card> */}
                        {value && value.sku && (
                          <Card>
                            <Form.Item label="Product SKU">
                              <Input
                                disabled={true}
                                value={
                                  value && value.sku && value.sku.length > 0
                                    ? value.sku
                                    : ""
                                }
                              />
                            </Form.Item>

                            {value?.refURL?.length > 0 && (
                              <Form.Item label="Origin product URL">
                                <Input
                                  disabled={true}
                                  addonAfter={
                                    <SelectAfter originUrl={value.refURL} />
                                  }
                                  value={value.refURL}
                                />
                              </Form.Item>
                            )}
                          </Card>
                        )}
                        <Card title={"Organization"}>
                          <Form.Item
                            name={"collections"}
                            label={"Collections"}
                            rules={[
                              {
                                required: true,
                                message: "Collections is required",
                              },
                            ]}
                          >
                            <CollectionsAutoComplete
                              onChange={this.handleShowBtnSave}
                            />
                          </Form.Item>
                          <Form.Item
                            name={"tags"}
                            label={"Tags"}
                            rules={[
                              {
                                required: true,
                                message: "Tags is required",
                              },
                            ]}
                          >
                            <TagsAutoComplete
                              onChange={this.handleShowBtnSave}
                            />
                          </Form.Item>
                        </Card>
                        <Card>
                          <Form.Item
                            name={"productBaseId"}
                            label={"Product Base"}
                            rules={[
                              {
                                required: true,
                                message: "Product base is required!",
                              },
                            ]}
                          >
                            <Select
                              disabled={!!value}
                              onChange={(id, option) => {
                                const productBase = option.data;
                                let newVariants =
                                  this.getProductVariants(productBase);

                                this.ref.current.setFieldsValue({
                                  variants: newVariants,
                                  designPositions:
                                    this.getDesignPositions(productBase),
                                  description: option.data.defaultContent,
                                  shortDescription:
                                    option.data.defaultShortDescription,
                                });
                                if (this.props.onChangeProductBase) {
                                  this.props.onChangeProductBase(productBase);
                                }
                                this.handleShowBtnSave();

                                if (
                                  productBase &&
                                  productBase.attributes &&
                                  productBase.attributes.length > 0
                                ) {
                                  let pBaseAttrs = this.getProductBaseAttrs(
                                    productBase.attributes,
                                  );

                                  this.setState({
                                    productAttributes: pBaseAttrs,
                                    variantsState: newVariants,
                                    currentProductBase: productBase,
                                  });
                                }
                              }}
                            >
                              {undefined === findPB && (
                                <Select.Option
                                  value={productBase.id}
                                  key={productBase.id}
                                  data={productBase}
                                >
                                  {productBase.title}
                                </Select.Option>
                              )}
                              {this.props.productBases.map((base, index) => (
                                <Select.Option
                                  value={base.id}
                                  key={index}
                                  data={base}
                                >
                                  {base.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Card>

                        <Card
                          title={"Product Attributes"}
                          hidden={!showVariants}
                        >
                          {productAttrs &&
                            Object.keys(productAttrs).map(
                              (attribute, index) => (
                                <div key={`attribute-${attribute}`}>
                                  <div className={"attribute-title"}>
                                    {productAttrs[attribute] &&
                                      productAttrs[attribute].name}
                                    :
                                  </div>
                                  <div style={{ marginBottom: 12 }}>
                                    {productAttrs[attribute] &&
                                      productAttrs[attribute]["options"] &&
                                      Object.keys(
                                        productAttrs[attribute]["options"],
                                      ).map((option) => {
                                        let enableAttributes =
                                          "color" ===
                                          productAttrs[attribute].slug;

                                        let currentOption;
                                        if (enableAttributes) {
                                          currentOption =
                                            productAttrs[attribute][
                                              "options"
                                            ] &&
                                            productAttrs[attribute]["options"][
                                              option
                                            ];
                                        }

                                        let optionSlug =
                                          currentOption &&
                                          this.strToSlug(
                                            currentOption["label"],
                                          );

                                        let optionDisabled =
                                          currentOption &&
                                          currentOption["disabled"];

                                        let optionLabel =
                                          currentOption &&
                                          currentOption["label"];

                                        if (optionLabel) {
                                          let hasFlag =
                                            optionLabel.match("/") &&
                                            optionLabel.split("/");
                                          if (hasFlag && hasFlag.length) {
                                            let value = hasFlag.filter((v) =>
                                              v.trim(),
                                            );
                                            if (1 === value.length) {
                                              optionLabel = value;
                                            }
                                          }
                                        }

                                        return (
                                          <div
                                            style={{ display: "inline-block" }}
                                            key={`option-${option}`}
                                          >
                                            <Tag
                                              key={`option-${option}`}
                                              className={
                                                enableAttributes
                                                  ? `tag-wrap`
                                                  : ""
                                              }
                                              style={{
                                                color:
                                                  enableAttributes &&
                                                  optionDisabled
                                                    ? "#1890ff"
                                                    : "",

                                                cursor: "pointer",
                                                marginTop: 8,
                                                padding: "3px 8px",
                                              }}
                                              color={
                                                productAttrs[attribute][
                                                  "options"
                                                ][option]["disabled"]
                                                  ? ""
                                                  : "#1890ff"
                                              }
                                              onClick={() => {
                                                this.handleChangeAttrOption(
                                                  attribute,
                                                  option,
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option],
                                                  productBaseVariants,
                                                );
                                              }}
                                            >
                                              {enableAttributes && (
                                                <ColorsComponent
                                                  optionSlug={optionSlug}
                                                />
                                              )}
                                              {enableAttributes
                                                ? optionLabel
                                                : productAttrs[attribute][
                                                    "options"
                                                  ] &&
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option] &&
                                                  productAttrs[attribute][
                                                    "options"
                                                  ][option]["label"]}
                                            </Tag>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              ),
                            )}
                          <div className={"variant-total mt-6"}>
                            {count && count.counDisabled >= 0 && (
                              <div>
                                Total enabled: <span>{count.counEnabled}</span>
                              </div>
                            )}
                            {count && count.counDisabled >= 0 && (
                              <div>
                                Total disabled:{" "}
                                <span>{count.counDisabled}</span>
                              </div>
                            )}
                          </div>
                          {productAttrs &&
                            Object.keys(productAttrs).forEach((attribute) => {
                              if (
                                productAttrs[attribute] &&
                                "size" === productAttrs[attribute].slug
                              ) {
                                let labels = [];
                                productAttrs[attribute]["options"] &&
                                  Object.keys(
                                    productAttrs[attribute]["options"],
                                  ).forEach((option) => {
                                    labels.push({
                                      label:
                                        productAttrs[attribute]["options"][
                                          option
                                        ] &&
                                        productAttrs[attribute]["options"][
                                          option
                                        ]["label"],
                                      regularPrice: null,
                                      salePrice: null,
                                    });
                                  });

                                return (
                                  <UpdatePriceForVariants
                                    key={`labels-${attribute}`}
                                    labels={
                                      this.state.labels
                                        ? this.state.labels
                                        : labels
                                    }
                                    onChange={(value) =>
                                      this.setState({ labels: value })
                                    }
                                    onSubmit={() =>
                                      this.handleUpdatePrice(
                                        productBaseVariants,
                                      )
                                    }
                                  />
                                );
                              }
                            })}

                          <Button
                            onClick={() => {
                              this.showProductVariants();
                              this.handleUpdatePrice(productBaseVariants);
                            }}
                          >
                            View All Variants
                          </Button>
                          <Modal
                            title="Product Variants"
                            visible={this.state.productVariantsVisible}
                            onOk={this.handleCloseVariants}
                            onCancel={this.handleCloseVariants}
                            width={980}
                            className="modal-variants-wrap"
                          >
                            <Form.Item name={"variants"}>
                              <ProductVariants
                                productAttrs={JSON.stringify(
                                  productAttrs || {},
                                )}
                                sku={sku}
                                isEditProduct={isEditProduct}
                                variantsMissing={this.props.variantsMissing}
                                onChange={this.handleShowBtnSave}
                                onVariableDisabledChanged={
                                  this.onVariableDisabledChanged
                                }
                                sortVariant={({
                                  enabledValues,
                                  disabledValues,
                                  isDrap,
                                }) => {
                                  this.setState({
                                    sortVariant: {
                                      enabledValues,
                                      disabledValues,
                                    },
                                    showBtnSave: false,
                                    isDrap: isDrap,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Modal>
                        </Card>

                        <Card title={"Pricing"} hidden={showVariants}>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item
                                name={"regularPrice"}
                                label={"Regular Price"}
                              >
                                <Price
                                  onChange={(value) => {
                                    this.setState({ regularPrice: value });
                                    this.handleShowBtnSave();
                                  }}
                                  onPressEnter={() =>
                                    this.setState({ disabled: false })
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={"salePrice"}
                                label={"Sale Price"}
                              >
                                <Price
                                  disabled={this.state.disabled}
                                  max={this.state.regularPrice || max}
                                  onChange={this.handleShowBtnSave}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                        {productsFake && (
                          <Card title="Print Files For Fulfillments">
                            <Form.Item>
                              <div className="product-fake-wrap">
                                <Tabs defaultActiveKey="1" tabPosition={"left"}>
                                  {productsFake.map((i, index) => {
                                    let title;
                                    if (i.productBases) {
                                      title = i.productBases
                                        .map(
                                          (pb) =>
                                            `${
                                              pb.fulfillment &&
                                              pb.fulfillment.name
                                                ? pb.fulfillment.name + ": "
                                                : ""
                                            } ${pb.title ? pb.title : ""}`,
                                        )
                                        .join(", ");
                                    }

                                    let currentHref = window.location.href;
                                    let hrefNoId = currentHref.slice(
                                      0,
                                      currentHref.lastIndexOf("/") + 1,
                                    );

                                    return (
                                      <TabPane
                                        tab={title}
                                        key={i.productId}
                                        data-product_url={`${hrefNoId}${i.productId}`}
                                      >
                                        <div className="product-fake-item">
                                          <label>Design Positions</label>
                                          <ProductPrintFiles
                                            value={i.designPositions}
                                            uploadFolder="undefined"
                                            isProductForm
                                            onChange={(value) => {
                                              productsFake[index][
                                                "designPositions"
                                              ] = value;
                                              this.setState({
                                                fakeProductDesignPositions:
                                                  productsFake,
                                                showBtnSave: false,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="product-fake-item files">
                                          <label>Design Resource Files</label>
                                          <MediaSelectorButton
                                            prefix={
                                              UPLOAD_FOLDER.PSD_RESOURCE + "/"
                                            }
                                            folder={UPLOAD_FOLDER.PSD_RESOURCE}
                                            multiple={true}
                                            listType={"picture-card"}
                                            buttonType="primary"
                                            accept={"image/*"}
                                            value={i.psdFiles}
                                            onChange={(value) => {
                                              productsFake[index]["psdFiles"] =
                                                value;
                                              this.setState({
                                                fakeProductDesignPositions:
                                                  productsFake,
                                                showBtnSave: false,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="product-fake-item">
                                          <label>Design Resource Urls</label>
                                          <Input.TextArea
                                            rows={4}
                                            value={i.psd}
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              productsFake[index]["psd"] =
                                                value;

                                              this.setState({
                                                fakeProductDesignPositions:
                                                  productsFake,
                                                showBtnSave: false,
                                              });
                                            }}
                                          />
                                        </div>
                                      </TabPane>
                                    );
                                  })}
                                </Tabs>
                              </div>
                            </Form.Item>
                          </Card>
                        )}
                      </Col>
                      <Col span={6}>
                        {!value && (
                          <Card
                            style={{ marginBottom: 15 }}
                            title={"Push Product"}
                          >
                            <Form.Item name={"pushToStores"}>
                              <PushProduct
                                store={
                                  store && store.id !== "all" ? store : null
                                }
                                checked={
                                  store && store.id !== "all" ? !!store : false
                                }
                                onChange={() => (
                                  (this.isChanged = true),
                                  this.handleShowBtnSave()
                                )}
                              />
                            </Form.Item>
                          </Card>
                        )}
                        {value &&
                          value.productStores &&
                          value.productStores.length > 0 && (
                            <Card
                              title="Update to stores"
                              style={{ marginBottom: 15 }}
                            >
                              <Form.Item
                                name="updateProductStore"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  onChange={() => {
                                    this.setState({
                                      showBtnSave: false,
                                    });
                                  }}
                                >
                                  Also update changes to store
                                </Checkbox>
                              </Form.Item>
                            </Card>
                          )}
                        {isProductIgnored && (
                          <Card bodyStyle={{ padding: "15px" }}>
                            <Form.Item
                              name={"hasSetIgnoredIsFalse"}
                              valuePropName="checked"
                              style={{ marginBottom: 0 }}
                            >
                              <Checkbox
                                children={
                                  "Also remove this product from ignored list"
                                }
                                checked={this.state.hasSetIgnoredIsFalse}
                                onChange={(e) =>
                                  this.setState({
                                    hasSetIgnoredIsFalse: e.target.checked,
                                    showBtnSave: false,
                                  })
                                }
                              />
                            </Form.Item>
                          </Card>
                        )}
                        <Card title={"Print Files"}>
                          <Form.Item name={"designPositions"}>
                            <ProductPrintFiles
                              uploadFolder="undefined"
                              isProductForm
                              ref={designPositionRef}
                              onChange={(value) => {
                                this.setState(
                                  {
                                    designPositions: value,
                                  },
                                  () => {
                                    this.handleShowBtnSave();
                                  },
                                );
                              }}
                            />
                          </Form.Item>
                        </Card>
                        <Card>
                          <Form.Item
                            label="Mockup"
                            name={"mockup"}
                            rules={[
                              {
                                required: true,
                                message: "Please select at least one image",
                              },
                            ]}
                          >
                            <MediaSelectorButton
                              prefix="mockups/"
                              folder={
                                sku
                                  ? getProductMockupFolder(sku)
                                  : UPLOAD_FOLDER.MOCKUPS
                              }
                              multiple={true}
                              listType={"picture-card"}
                              buttonType="primary"
                              accept={"image/*"}
                              onChange={this.handleShowBtnSave}
                              isMockup
                              mainImg={value && value.mainImageId}
                              fileList={this.state.mockups || []}
                              hasDefaultMockup
                              onChangeMainImg={(value) =>
                                this.setState({
                                  mainImgId: value,
                                  showBtnSave: false,
                                })
                              }
                            />
                          </Form.Item>
                        </Card>
                        <Card>
                          <Form.Item
                            label="Design Resource Files"
                            name={"psdFiles"}
                          >
                            <MediaSelectorButton
                              prefix={UPLOAD_FOLDER.PSD_RESOURCE + "/"}
                              folder={UPLOAD_FOLDER.PSD_RESOURCE}
                              multiple={true}
                              listType={"picture-card"}
                              buttonType="primary"
                              accept={"image/*"}
                              onChange={() =>
                                this.setState({ showBtnSave: false })
                              }
                              value={
                                value && value.psdFiles ? value.psdFiles : null
                              }
                            />
                          </Form.Item>
                        </Card>
                        <Card>
                          <Form.Item label="Design Resource Urls" name="psd">
                            <Input.TextArea
                              rows={4}
                              onChange={() =>
                                this.setState({ showBtnSave: false })
                              }
                            />
                          </Form.Item>
                        </Card>
                        {value?.refURL != null ? (
                          <Card
                            title={"Product Resource Urls"}
                            style={{ marginTop: 15 }}
                          >
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <a
                                href={value.refURL}
                                className="Polaris-Link"
                                target="_blank"
                                children={value.refURL}
                              />
                            </div>
                          </Card>
                        ) : null}
                        {value && !isMarketplaceManager && (
                          <Card title={"Push Product "}>
                            <Form.Item name={"pushProductToStores"}>
                              <PushProduct
                                isEditProduct
                                store={store}
                                productId={value.id}
                                checked={!!store}
                                refetch={refetch}
                                onChange={() => (
                                  (this.isChanged = true),
                                  this.handleShowBtnSave()
                                )}
                              />
                            </Form.Item>
                          </Card>
                        )}
                        {value &&
                          value.productStores &&
                          value.productStores.length > 0 && (
                            <Card
                              title={"Push History"}
                              bodyStyle={{ paddingBottom: 0 }}
                            >
                              <Form.Item>
                                <PushHistory value={value.productStores} />
                              </Form.Item>
                            </Card>
                          )}
                      </Col>
                      <Col span={24}>
                        <Card title={"Personalized"}>
                          <Form.Item
                            name={"personalized"}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={(e) => {
                                this.setState({
                                  showPreview: e.target.checked,
                                });
                                this.handleShowBtnSave();
                              }}
                            >
                              This is a personalized product
                            </Checkbox>
                          </Form.Item>
                          <div>
                            <Form.Item
                              name={"fields"}
                              rules={[
                                {
                                  required: this.state.showPreview,
                                  message: "Personalized is required.",
                                },
                              ]}
                            >
                              <CustomizeButton
                                showPreview={this.state.showPreview}
                                onChange={this.handleShowBtnSave}
                              />
                            </Form.Item>
                          </div>
                        </Card>
                        <Card title={"Templates"} className="mt-5">
                          <p>
                            Save these product and settings as a template to be
                            used for future use.
                          </p>
                          <div className={"flex py-5"}>
                            <div style={{ flexGrow: 1 }}>
                              {!isEditProduct && (
                                <Button
                                  onClick={() => {
                                    if (isEditProduct) {
                                      return;
                                    } else {
                                      this.props.onViewTemplates();
                                    }
                                  }}
                                  className={"p-0"}
                                  type="link"
                                >
                                  View All Templates
                                </Button>
                              )}
                            </div>
                            <Button
                              onClick={() => {
                                this.handleVisibleChange();
                                this.handleUpdatePrice(productBaseVariants);
                              }}
                            >
                              Create Template
                            </Button>
                          </div>
                        </Card>
                        <AddProductTemplate
                          visible={this.state.template.visible}
                          onChange={this.handleVisibleChange}
                          template={this.state.template}
                          handleCreateTemplate={({
                            name,
                            slug,
                            defaultMockups,
                          }) => {
                            this.handleCreateTemplate({
                              name,
                              slug,
                              defaultMockups,
                              client,
                            });
                            this.setState((prevState) => {
                              return {
                                template: {
                                  ...prevState.template,
                                  loading: true,
                                },
                              };
                            });
                          }}
                        />
                        <Form.Item
                          style={{ marginTop: 20, textAlign: "right" }}
                        >
                          <Button
                            onClick={() => {
                              if (
                                null === this.state.showBtnSave
                                  ? false
                                  : !this.state.showBtnSave
                              ) {
                                Modal.confirm({
                                  title: "Cancel all unsaved changes?",
                                  icon: <ExclamationCircleOutlined />,
                                  content:
                                    "If Ok, you’ll delete any edits you made.",
                                  onOk() {
                                    history.push(`/${currentParam}/products`);
                                  },
                                  cancelText: "Continue",
                                });
                              } else {
                                history.push(`/${currentParam}/products`);
                              }
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="ml-4"
                            type="primary"
                            icon={<LegacyIcon type={"save"} />}
                            loading={loading}
                            htmlType={"submit"}
                            disabled={
                              null === this.state.showBtnSave
                                ? true
                                : this.state.showBtnSave
                            }
                          >
                            {value ? "Save Product" : "Publish Product"}
                          </Button>
                        </Form.Item>
                        {isEditProduct ? (
                          <ProductTimeline
                            productId={value && value.id}
                            refetchTimeline={refetchTimeline}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                </Container>
              )}
            </AppContext.Consumer>
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

AddProductForm.propTypes = {
  productBase: PropTypes.object,
  productBases: PropTypes.array,
  value: PropTypes.any,
  onSubmit: PropTypes.func,
  onChangeProductBase: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  sku: PropTypes.string,
};

export default AddProductForm;
