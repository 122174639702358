import React from 'react';
import { Modal } from '@shopify/polaris';
import { useMutation } from '@apollo/react-hooks';

import { DELETE_STORE } from '../../../../graphql/mutations';
import { GET_STORES } from '../../../../graphql/queries';
import { handleError } from '../../../../helper';
import { FBMContext } from '../context';

export function Delete({ value, toggleOpen, open }) {
    const id = value?.id;
    // Context
    const { toggleToast, setNotify, filter } = React.useContext(FBMContext);

    // Mutation
    const [deleteStore, { loading, client }] = useMutation(DELETE_STORE, {
        onCompleted: () => {
            if (setNotify) {
                setNotify({ msg: `Delete account successfully.`, err: false });
            }
            toggleOpen && toggleOpen();
            handleUpdateCache();
        },
        onError: (error) => {
            if (setNotify) {
                setNotify({ msg: handleError(error.toString()), err: true });
            }
        },
    });

    // Handle actions
    const handleUpdateCache = React.useCallback(() => {
        if (id != null) {
            const variables = {
                filter,
            };
            const cache = client.readQuery({
                query: GET_STORES,
                variables,
            });
            client.writeQuery({
                query: GET_STORES,
                variables,
                data: {
                    ...cache,
                    stores: {
                        ...cache.stores,
                        total: cache.stores.total - 1,
                        nodes: [...cache.stores.nodes].filter(
                            (item) => item.id !== id
                        ),
                    },
                },
            });
        }
    }, [id, client, filter]);

    const handleSubmit = React.useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            if (setNotify) {
                setNotify({ msg: null, err: false });
            }
            deleteStore({
                variables: {
                    id,
                },
            });
        }
    }, [deleteStore, id, toggleToast, setNotify]);

    return (
        <Modal
            title='Delete account'
            open={open}
            onClose={toggleOpen}
            sectioned
            secondaryActions={[{ content: 'Cancel', onAction: toggleOpen }]}
            primaryAction={{
                content: 'Submit',
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <p>Are you sure to delete this account?</p>
        </Modal>
    );
}
