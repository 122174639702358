import React, { useState, useEffect, useCallback, useRef } from "react";
import { Filters } from "@shopify/polaris";

import { isEmpty } from "../../../helper";

import { FilterBoolStatusPolaris } from "../../shared/FilterBoolStatusPolaris";

export const FilterCollectionsPolaris = ({ filter, onChange }) => {
  const [queryValue, setQueryValue] = useState(filter.search);
  const [status, setStatus] = useState({
    value: filter.status,
    label:
      "true" === filter.status
        ? "Active"
        : "false" === filter.status
        ? "Inactive"
        : filter.status,
  });
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: queryValue ? queryValue.trim() : queryValue,
          status:
            true === status.value
              ? "true"
              : false === status.value
              ? "false"
              : status.value,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, status]);

  const handleQueryChange = useCallback((value) => {
    setQueryValue(value);
  }, []);

  const handleQueryRemove = useCallback(() => setQueryValue(null), []);
  const handleStatusRemove = useCallback(
    () => setStatus({ value: null, label: null }),
    []
  );

  const handleFiltersClearAll = useCallback(() => {
    handleQueryRemove();
    handleStatusRemove();
  }, [handleQueryRemove, handleStatusRemove]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <FilterBoolStatusPolaris
          status={
            "true" === filter.status
              ? true
              : "false" === filter.status
              ? false
              : filter.status
          }
          onChange={(value, label) => {
            setStatus((prevState) => {
              return {
                ...prevState,
                value,
                label,
              };
            });
          }}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(status.label)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status.label),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      queryValue={queryValue}
      queryPlaceholder={"Filter collection"}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
      onClearAll={handleFiltersClearAll}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
