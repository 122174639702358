import React, {
    createContext,
    useCallback,
    useContext,
    useReducer,
} from "react";

import { ActionTypes } from "./actions";
import { stateReducer } from "./reducers";

const CreateProductContext = createContext(null);
CreateProductContext.displayName = "CreateProductContext";

export const Step = {
    ChooseBase: "ChooseBase",
    FormProduct: "FormProduct",
    ViewTemplate: "ViewTemplate",
};

export const initialValue = {
    step: Step.ChooseBase,
    fulfillmentId: null,
    selectedBases: [],
    template: null,
    fieldsValue: {
        title: "",
        // shortTitle: "",
        // amzTitle: "",
        description: "",
        collections: [],
        tags: [],
        personalized: false,
        fields: [],
        designPositions: [],
        using2dMockup: {
            isReview: true,
            productBaseOnlyUseDesignForGenMockup: [],
        },
    },
    errors: {},
    isEdit: false,
    canUpdateDescription: true,
};

const FIELDS_REQUIRED = {
    title: "Title",
};

export function CreateProductProvider({ children }) {
    // State
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    // Handle actions
    const updateState = useCallback(
        (newState) => {
            dispatch({ type: ActionTypes.SetState, payload: newState });
        },
        [dispatch]
    );
    const validateFields = useCallback(
        (value, id) => {
            let error = null;
            let label = FIELDS_REQUIRED[id];
            if ((!value || !value.length) && label) {
                error = `${label} is required.`;
            }

            updateState({
                errors: {
                    ...state.errors,
                    [id]: error,
                },
            });
        },
        [state.errors, updateState]
    );

    const updateField = useCallback(
        (value, id) => {
            validateFields(value, id);
            dispatch({
                type: ActionTypes.UpdateFieldsValue,
                payload: { [id]: value },
            });
        },
        [dispatch, validateFields]
    );

    const clearState = useCallback(() => {
        dispatch({ type: ActionTypes.ClearState });
    }, [dispatch]);

    return (
        <CreateProductContext.Provider
            value={{ state, updateState, clearState, updateField }}
        >
            {children}
        </CreateProductContext.Provider>
    );
}

export function useCreateProduct() {
    const context = useContext(CreateProductContext);

    if (!context) {
        throw new Error("No CreateProduct was provided.");
    }

    return context;
}
