import { useMutation } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useCallback, useRef } from "react";
import { IDEA_MARKER_SUBMIT_TO_DRAFT } from "../../../../../graphql/mutations";
import { handleError } from "../../../../../helper";
import { useToastContext } from "../../../../shared/ToastContext";

export function SubmitToDraft({ item, onCloseParent, ...props }) {
    // Props
    const id = item?.id;
    const onCloseRef = useRef(null);

    // Context
    const { setNotify, toggleToast, refetch, setFilter } = useToastContext();

    // Mutation
    const [submitToDraft, { loading }] = useMutation(
        IDEA_MARKER_SUBMIT_TO_DRAFT,
        {
            onCompleted: () => {
                setNotify({
                    msg: "Submit to Draft this task successfully.",
                    err: false,
                });
                props.onClose && props.onClose();
                onCloseParent && onCloseParent();

                // onClose parent modal
                onCloseRef.current && clearTimeout(onCloseRef.current);
                onCloseRef.current = setTimeout(() => {
                    refetch && refetch();
                    setFilter && setFilter((prev) => ({ ...prev }));
                }, 1000);
            },
            onError: (err) => {
                setNotify({ msg: handleError(err?.toString()), err: true });
            },
        }
    );

    // Actions
    const handleSubmit = useCallback(() => {
        if (id != null) {
            toggleToast && toggleToast(true);
            setNotify && setNotify({ msg: null, err: false });
            submitToDraft({
                variables: {
                    id,
                },
            });
        }
    }, [id, submitToDraft, toggleToast, setNotify]);

    return (
        <Modal
            title="Submit to Draft status for this Idea Task"
            sectioned
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
            secondaryActions={[
                {
                    content: "Close",
                    onAction: props.onClose,
                },
            ]}
            {...props}
        >
            <p>Are you sure to submit to Draft status for this idea task?</p>
        </Modal>
    );
}
