import React from "react";
import { Modal, TextContainer, Toast } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

import { REFETCH_TOTAL_SALE } from "../../graphql/mutations";
import { handleError } from "../../helper";
import useToggle from "../../hooks/useToggle";

export const RefetchTotalSalePolaris = ({
    open,
    toggleModal,
    selectedItem,
    onCompleted,
    refetch,
}) => {
    const [toastActive, toggleToastActive] = useToggle(false);
    const [notify, setNotify] = React.useState({ msg: null, err: false });
    const timeoutRef = React.useRef(null);

    // Mutation
    const [fetchTotalSale, { loading }] = useMutation(REFETCH_TOTAL_SALE, {
        onCompleted: () => {
            setNotify({ msg: "Refetch total sale successfully.", err: false });

            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                onCompleted && onCompleted();
                toggleModal && toggleModal();
                refetch && refetch();
            }, 1500);
        },
        onError: (error) => {
            setNotify({ msg: handleError(error.toString()), err: true });
        },
    });

    // Handle actions
    const handleSubmit = React.useCallback(() => {
        if (selectedItem.length) {
            toggleToastActive(true);
            setNotify({ msg: null, err: false });
            fetchTotalSale({
                variables: {
                    ids: selectedItem,
                },
            });
        }
    }, [fetchTotalSale, toggleToastActive, setNotify, selectedItem]);

    // Markup
    const toastMarkup = toastActive && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            onDismiss={toggleToastActive}
            duration={1500}
        />
    );

    return (
        <React.Fragment>
            {toastMarkup}
            <Modal
                title="Refetch total sale"
                open={open}
                onClose={toggleModal}
                sectioned
                secondaryActions={[
                    { content: "Cancel", onAction: toggleModal },
                ]}
                primaryAction={{
                    content: "Refetch",
                    onAction: handleSubmit,
                    loading: loading,
                }}
            >
                <TextContainer>
                    Are you sure to refetch total sale?
                </TextContainer>
            </Modal>
        </React.Fragment>
    );
};
