import React, { useState, useCallback, useEffect } from "react";
import { TextField, FormLayout, Button, Toast } from "@shopify/polaris";
import {
  EditMajorMonotone,
  TickMinor,
  MobileCancelMajorMonotone,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import _ from "lodash";

import { ImageTypes, META_DATA } from "../../../variable";
import { getPersonalizedImage, handleError } from "../../../helper";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";

const SET_VALUE = gql`
  mutation updateFieldValue($fieldValueId: ID!, $value: [String!]!) {
    updateFieldValue(fieldValueId: $fieldValueId, value: $value) {
      id
      field {
        id
        name
        title
        type
        extraFee
        configure
        sorting
      }
      createdAt
      value
    }
  }
`;

const Container = styled.div`
  .img-wrap {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  .personalized_inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1.5rem;
    .content_wrap {
      flex: 1 1;
      word-break: break-word;
      label {
        font-weight: 500;
      }
    }
    .actions_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.1rem;
      .Polaris-Icon {
        height: 1.65rem;
        width: 1.65rem;
      }
    }
  }
`;

export const PersonalizedOptionPolaris = (props) => {
  const { value, isDesigner, onChange } = props;
  let label = null,
    id = null;
  if (value) {
    label = value.field && value.field.title;
    id = value.id;
  }

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [activeToast, setActiveToast] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [updateValue, { data, loading, error }] = useMutation(SET_VALUE, {
    onError: () => {},
    onCompleted: () => {
      const id = setTimeout(() => {
        toggleEdit();
      }, 2100);
      setTimeoutId(id);
    },
  });

  useEffect(() => {
    let input = value && value.value ? value.value : null;
    input = input && input.length && input.join(", ");
    setInputValue(input);
  }, [value]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    [],
  );

  const toggleEdit = useCallback(() => setIsEdit((prev) => !prev), []);

  const toastMarkup = activeToast
    ? (error || (data && data.updateFieldValue)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.updateFieldValue && `Update field success.`
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  const metaMarkup =
    value.value && value.value.length > 0
      ? value.value.map((v, idx) => {
          let hasImg = _.includes(v, META_DATA) || getPersonalizedImage(v);
          if (hasImg) {
            return (
              <div className="img-wrap" key={idx}>
                <a href={v} target="_blank">
                  <img src={v} alt="" />
                </a>
              </div>
            );
          }
          return <span key={idx}>{v}</span>;
        })
      : null;

  const handleChange = useCallback((val) => {
    setInputValue(val);
    if (onChange) {
      onChange(val);
    }
  }, []);

  return (
    <Container className="personalized-item">
      {toastMarkup}
      <FormLayout>
        <div className="personalized_inner">
          <div className="content_wrap">
            <div onClick={!isDesigner ? toggleEdit : null}>
              <ComponentLabelPolaris label={label} />
            </div>
            {isEdit ? (
              <TextField
                label={label}
                labelHidden
                value={inputValue}
                onChange={handleChange}
              />
            ) : (
              <>{metaMarkup}</>
            )}
          </div>

          <div className="actions_wrap">
            {!isDesigner && (
              <div style={{ textAlign: "right" }}>
                {isEdit ? (
                  <Button
                    plain
                    icon={MobileCancelMajorMonotone}
                    onClick={toggleEdit}
                  />
                ) : (
                  <Button plain icon={EditMajorMonotone} onClick={toggleEdit} />
                )}
              </div>
            )}
            {isEdit && onChange == null ? (
              <Button
                primary
                icon={TickMinor}
                loading={loading}
                onClick={() => {
                  updateValue({
                    variables: {
                      fieldValueId: id,
                      value: [inputValue],
                    },
                  });
                  toggleActive();
                }}
              />
            ) : null}
          </div>
        </div>
      </FormLayout>
    </Container>
  );
};

function getImage(value) {
  if (!value || typeof value !== "string") return false;

  const [pathname] = value.split(/\?/);
  const splitted = (pathname || "").split(/\./);

  if (splitted.length === 0) return false;
  const ext = splitted[splitted.length - 1];

  return ImageTypes.some((item) => item.indexOf(ext) > -1);
}
