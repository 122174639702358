import React from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { FulfillmentsPolaris } from "../../components/admin/fulfillment/FulfillmentsPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const FulfillmentPagePolaris = () => {
  return (
    <Container>
      <Page
        title="Manage Fulfillment"
        fullWidth
        primaryAction={{
          content: "Add fulfillment",
          url: "/admin/fulfillment/add",
        }}
      >
        <FulfillmentsPolaris />
      </Page>
    </Container>
  );
};
