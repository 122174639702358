import { useMutation } from "@apollo/react-hooks";
import { InlineError, Modal, Stack, TextField } from "@shopify/polaris";
import React from "react";
import { REJECT_CURRENT_DESIGN } from "../../../graphql/mutations";
import { handleError } from "../../../helper";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { useToastContext } from "../../shared/ToastContext";

const RejectCurrentDesign = ({ open, onClose, value, refetch }) => {
  // Context
  const { toggleToast, setNotify } = useToastContext();

  const [rejectReason, setRejectReason] = React.useState("");
  const [error, setError] = React.useState("");

  const timeoutRef = React.useRef(null);
  const [rejectCurrentDesign, { loading }] = useMutation(
    REJECT_CURRENT_DESIGN,
    {
      onCompleted: () => {
        if (refetch) {
          timeoutRef.current && clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            refetch();
          }, 1500);
        }
        onClose && onClose(false);
      },
      onError: (err) => {
        setNotify({ msg: handleError(err?.toString()), err: true });
      },
    },
  );

  const handleSubmit = React.useCallback(() => {
    if (!value || !value.id) return;

    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    rejectCurrentDesign({
      variables: {
        orderID: value.id,
        rejectReason,
      },
    }).finally(() => {
      onClose();
    });
  }, [value?.id, rejectReason, rejectCurrentDesign, toggleToast, setNotify]);

  const handleReasonChange = React.useCallback((value) => {
    let err = "";
    if (!value) {
      err = "Reject reason is required";
    }

    setError(err);
    setRejectReason(value);
  }, []);

  return (
    <Modal
      title="Reject Current Designs"
      sectioned
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        onAction: handleSubmit,
        loading: loading,
        disabled: !rejectReason,
      }}
      secondaryActions={[{ content: "Cancel", onAction: onClose }]}
    >
      <Stack vertical>
        <InlineError message="Must cancel this order in the fulfillment service" />
        <div>
          <ComponentLabelPolaris label="Reject reason" required />
          <TextField
            placeholder="Enter reject reason"
            multiline={3}
            error={error}
            value={rejectReason}
            onChange={handleReasonChange}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default RejectCurrentDesign;
