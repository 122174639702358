import React, { useState, useCallback, useLayoutEffect } from "react";
import {
  Card,
  TextField,
  FormLayout,
  Button,
  ButtonGroup,
} from "@shopify/polaris";
import Wysiwyg from "../../Wysiwyg";
import styled from "styled-components";

import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { MediaSelectorButtonPolaris } from "../../file/MediaSelectorButtonPolaris";
import { CategorySelectPolaris } from "./CategorySelectPolaris";

const Container = styled.div`
  .btns-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3rem;
  }
  .list-file_wrap {
    width: 10rem;
    height: 10rem;
    .file_wrap {
      height: 100%;
    }
  }
`;

export const CategoryFormPolaris = ({
  handleRedirect,
  onSubmit,
  loading,
  value,
  editCategory,
}) => {
  // State
  const [basicFields, setBasicFields] = useState({
    name: null,
    description: null,
    parentId: null,
    image: null,
  });
  const [errors, setErrors] = useState({});

  // Get data
  useLayoutEffect(() => {
    if (value) {
      let { children, id, __typename, image, ...rest } = value;
      let newImage = image ? [image] : [];
      setBasicFields((prev) => ({ ...prev, ...rest, image: newImage }));
    }
  }, [value]);

  // Handle action
  const validateField = useCallback((value, id) => {
    let error = null;
    let label = id.charAt(0).toUpperCase() + id.slice(1);
    if (!value || !value.length) {
      error = `${label} is required.`;
    }
    setErrors((prev) => ({ ...prev, [id]: error }));
  }, []);

  const handleInputChange = useCallback((value, id) => {
    setBasicFields((prev) => ({ ...prev, [id]: value }));
    if (id === "name") {
      validateField(value, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = useCallback(() => {
    let { name, image, ...rest } = basicFields;
    validateField(name, "name");

    let newImage = image?.length > 0 ? image.map((i) => i.id) : [];
    const input = {
      ...rest,
      name,
      imageId: newImage?.length > 0 ? newImage[0] : null,
    };

    if (name) {
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, basicFields]);

  return (
    <Container>
      <Card sectioned>
        <FormLayout>
          <div>
            <ComponentLabelPolaris label="Name" required />
            <TextField
              value={basicFields["name"]}
              placeholder="Enter category name"
              id="name"
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </div>
          <div>
            <ComponentLabelPolaris label="Description" />
            <Wysiwyg
              useDebounce={true}
              pplaceholder="Enter category description"
              onChange={(value) => {
                handleInputChange(value, "description");
              }}
              value={basicFields["description"]}
            />
          </div>
          <CategorySelectPolaris
            value={basicFields["parentId"]}
            onChange={(newValue) => {
              setBasicFields((prev) => ({ ...prev, parentId: newValue }));
            }}
          />
          <div>
            <ComponentLabelPolaris label="Image" />
            {editCategory ? (
              <>
                {basicFields["image"] ? (
                  <MediaSelectorButtonPolaris
                    accept={"image/*"}
                    singleUpload={true}
                    multiple={false}
                    onChange={(value) => handleInputChange(value, "image")}
                    value={basicFields["image"]}
                    sizeSmall
                  />
                ) : null}
              </>
            ) : (
              <MediaSelectorButtonPolaris
                sizeSmall
                accept={"image/*"}
                singleUpload={true}
                multiple={false}
                onChange={(value) => handleInputChange(value, "image")}
              />
            )}
          </div>
        </FormLayout>
        <div className="btns-wrap">
          <ButtonGroup>
            <Button children="Cancel" onClick={handleRedirect} />
            <Button
              children="Submit"
              primary
              onClick={handleSubmit}
              loading={loading}
            />
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
