import React from "react";
import { Page } from "@shopify/polaris";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { CustomLinkPolaris } from "../../components/shared/CustomLinkPolaris";

const Container = styled.div`
  display: flex;
  min-height: 30rem;
  align-items: center;
  justify-content: center;
`;

export const DownloadPage = () => {
  const location = useLocation();

  const url = location?.search ? location.search.substring(1) : null;

  return (
    <Page>
      <Container>
        <p>
          If you not redirect then{" "}
          <CustomLinkPolaris href={url}>please click manual</CustomLinkPolaris>.
        </p>
      </Container>
    </Page>
  );
};
