import { useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import { PLATFORM_SALE_STATS } from "../../../graphql/queries";
import {
  convertStringToObject,
  convertToOffset,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../../helper";
import history from "../../../history";
import { COMMON_FILTER } from "../../../variable";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import SuspenseComp from "../../shared/SuspenseComp";
import OrderStatsTable from "./OrderStatsTable";
const OrderStatsFilter = React.lazy(() => import("./Filter"));

const FIELD_FILTER = [...COMMON_FILTER, "storeID"];

export default function OrderStats({ path }) {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = React.useState({
    search: "",
    platform: "amazon",
    storeID: null,
    limit: Number(getCookie("perPageOrderStats")) || 20,
    offset: 0,
    ...initFilter,
  });

  const { data, loading, error } = useQuery(PLATFORM_SALE_STATS, {
    variables: {
      filter,
    },
  });

  const loadingMarkup = loading && <Loading />;

  const handleFilterChange = React.useCallback(
    ({ search, storeId }) =>
      setFilter((prevState) => {
        if (
          !isEqual(prevState.search, search) ||
          !isEqual(prevState.storeID, storeId)
        ) {
          prevState.offset = 0;
        }
        return {
          ...prevState,
          search,
          storeID: storeId,
        };
      }),
    [],
  );

  const handlePaginationChange = React.useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageOrderStats", limit, 100);
  }, []);

  return (
    <Page title="Order Stats" fullWidth>
      {loadingMarkup}
      <Card sectioned>
        <SuspenseComp>
          <OrderStatsFilter filter={filter} onChange={handleFilterChange} />
        </SuspenseComp>
      </Card>
      <Card>
        {loading ? (
          <SkeletonPagePolaris />
        ) : (
          <>
            {error ? (
              <div>Error: {handleError(error.toString())}</div>
            ) : data?.platformSaleStats?.nodes?.length > 0 ? (
              <OrderStatsTable
                data={data}
                onChange={handlePaginationChange}
                filter={filter}
              />
            ) : (
              <EmptyStatePolaris />
            )}
          </>
        )}
      </Card>
    </Page>
  );
}
