import React, { useCallback } from "react";
import { Modal, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

import { handleError } from "../../../helper";
import { BULK_ACCEPT_DESIGN } from "../../../graphql/mutations";

const Container = styled.div`
    .item-wrap {
        display: flex;
        column-gap: 1rem;
    }
    .heading {
        font-size: 1.2em;
        font-weight: 700;
    }
`;

const ERR = "Error";
export const BulkActionAcceptDesignPolaris = (props) => {
    const {
        open,
        toggleShowModal,
        onNotificationChange,
        toggleActive,
        idsChecked,
        onCompleted,
    } = props;

    // State
    const [itemErrors, setItemErrors] = React.useState([]);

    const [acceptDesigns, { loading }] = useMutation(BULK_ACCEPT_DESIGN, {
        onError: (error) => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: handleError(error.toString()),
                    isError: true,
                });
            }
        },
        onCompleted: (res) => {
            const data = res?.bulkAcceptDesign;
            const itemError =
                data?.length > 0 ? data.filter((i) => i.status === ERR) : [];
            if (itemError?.length) {
                setItemErrors(itemError);
            } else {
                if (onNotificationChange) {
                    onNotificationChange({
                        message: "Accept designs successfully.",
                        isError: false,
                    });
                }
                if (onCompleted) {
                    onCompleted();
                }
            }
        },
    });

    const handleSubmit = useCallback(
        () => {
            if (onNotificationChange) {
                onNotificationChange({
                    message: null,
                    isError: false,
                });
            }
            acceptDesigns({
                variables: {
                    id: idsChecked,
                },
            });
            if (toggleActive) {
                toggleActive();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [idsChecked, acceptDesigns, toggleActive, onNotificationChange]
    );

    const itemErrorsMarkup =
        itemErrors?.length > 0 ? (
            <div>
                <div className="heading">ERROR</div>
                {itemErrors
                    .map((item, idx) => {
                        if (!item) {
                            return null;
                        }
                        const { id, log } = item;
                        return (
                            <div className="item-wrap" key={idx}>
                                <strong>
                                    {id}
                                    {": "}
                                </strong>
                                <span>{log}</span>
                            </div>
                        );
                    })
                    .filter(Boolean)}
            </div>
        ) : null;

    return (
        <Modal
            title="Accept designs"
            open={open}
            onClose={toggleShowModal}
            sectioned
            secondaryActions={[
                { content: "Cancel", onAction: toggleShowModal },
            ]}
            primaryAction={{
                content: "Submit",
                onAction: handleSubmit,
                loading: loading,
            }}
        >
            <Container>
                <Stack vertical spacing="loose">
                    <p>Are you sure to accept designs?</p>
                    {itemErrorsMarkup}
                </Stack>
            </Container>
        </Modal>
    );
};
