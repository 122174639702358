import React, { useState, useContext } from "react";
import { Page, Loading, Card, Button } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { productBaseFragment } from "../../../fragments/index";
import { AppContext } from "../../../context";
import { getParamByRole } from '../../../helper'

import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { TableTemplatesPolaris } from "./TableTemplatesPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LIST_TEMPLATE = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      total
      nodes {
        id
        name
        slug
        grouping
        defaultMockupIds
        data {
          personalized
          productBases {
            ...ProductBasse
          }
          ProductBaseIds
          fields {
            name
            title
            type
            extraFee
            configure
            sorting
          }
          products {
            title
            description
            productbaseId
            shortDescription
            tagIds
            tags {
              id
              name
            }
            variants {
              salePrice
              regularPrice
              productBaseVariantID
              disabled
            }
            collectionIds
            collections {
              id
              name
            }
          }
        }
      }
    }
  }
  ${productBaseFragment}
`;

export const ProductTemplatesPolaris = ({
  onChangeStep,
  handleUseTemplate,
  allTemplate = false,
}) => {
  const [filter, setFilter] = useState({
    product: allTemplate ? null : true,
    limit: 20,
    offset: 0,
  });
  const { currentUser } = useContext(AppContext);
  let currentParam = getParamByRole(currentUser);

  const { data, loading, error } = useQuery(LIST_TEMPLATE, {
    variables: {
      filter,
    },
  });

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Page
        title="Product Templates"
        fullWidth
        breadcrumbs={[
          { content: "Products", url: `/${currentParam}/products` },
        ]}
        primaryAction={
          <Button primary onClick={onChangeStep}>
            Start from Scratch
          </Button>
        }
      >
        <Card>
          {error && <div>Error: {error.toString()}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data &&
            data.templates &&
            data.templates &&
            data.templates.nodes &&
            data.templates.nodes.length ? (
            <TableTemplatesPolaris
              data={data}
              handleUseTemplate={handleUseTemplate}
              filter={filter}
              setFilter={(v) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    offset: v,
                  };
                });
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
