import React, { useState, useCallback, useEffect } from "react";
import { Checkbox } from "@shopify/polaris";
import styled from "styled-components";

import { AutoCompletePolaris } from "../../shared/AutoCompletePolaris";

const Container = styled.div`
  .design-files {
    display: flex;
    column-gap: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .design-item {
    display: flex;
    column-gap: 1.6rem;
    width: 50%;
    .image {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .design-position {
      flex: 1 1;
    }
  }
`;

export const UsingProductDesignFilePolaris = ({
  crawlId,
  designUrls,
  designPositions,
  onChange,
}) => {
  // State
  const [checked, setChecked] = useState(false);
  const [designs, setDesigns] = useState([]);

  const handleOnChange = useCallback(
    (designs) => {
      let newDesigns = designs;
      if (!checked) {
        newDesigns = [];
      }

      if (onChange) {
        onChange(newDesigns);
      }
    },
    [checked, onChange]
  );

  // Get data
  useEffect(() => {
    let dpFirst = designPositions?.length > 1 ? designPositions[1].value : null;
    let designs =
      designUrls?.length > 0
        ? designUrls.map((i, index) => {
            let thumbnailUrl = i;
            let designUrl = i;
            let urls = i.split("||");
            if (urls.length > 1) {
              thumbnailUrl = urls[0];
              designUrl = urls[1];
            }

            return {
              productCrawlItemId: crawlId,
              thumbnailUrl,
              designUrl,
              designPositionId: 0 === index ? dpFirst : null,
            };
          })
        : [];
    setDesigns(designs);
    handleOnChange(designs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designUrls, crawlId, designPositions, checked]);

  // Handle action
  const handleCheckedChange = useCallback(
    (newValue) => setChecked(newValue),
    []
  );

  designPositions = [
    { value: null, label: "Choose a design position" },
    ...designPositions,
  ];

  return (
    <Container>
      <Checkbox
        label="Using product design files"
        checked={checked}
        onChange={handleCheckedChange}
      />
      {checked && designs?.length > 0 ? (
        <div className="design-files">
          {designs.map((design, index) => (
            <div className="design-item" key={index}>
              <img
                className="image"
                src={
                  design?.thumbnailUrl ? design.thumbnailUrl : design.designUrl
                }
                alt="Design file"
              />
              <div className="design-position">
                <AutoCompletePolaris
                  label="Design position"
                  placeholder="Filter design position"
                  options={designPositions}
                  value={designs[index].designPositionId || null}
                  onChange={(newValue) => {
                    designs[index].designPositionId = newValue;
                    setDesigns(() => [...designs]);
                    handleOnChange([...designs]);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </Container>
  );
};
