import React, { useState, useEffect, useContext, useCallback } from "react";
import { Page, Loading, DataTable, Card, Badge } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import styled from "styled-components";

import { AppContext } from "../../context";
import { TEAM_ROLE, USER_ROLE, TEAM_ROLE_PARAMS } from "../../variable";
import { handleError } from "../../helper";

import { PaginationPolaris } from "../shared/PaginationPolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import ModalImageClaimPolaris from "../seller/ModalImageClaimPolaris";

const GET_EXPORT_PRODUCTS = gql`
  query getAmazonExportProducts($input: AmazonExportProductFilter!) {
    getAmazonExportProducts(input: $input) {
      total
      nodes {
        id
        title
        sku
        productBases {
          id
          title
        }
        images {
          id
          file {
            id
            thumbnailUrl
            url
          }
        }
      }
    }
  }
`;
const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
  .product_wrap {
    width: calc(400px - 3.2rem);
    white-space: normal;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .image_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .id_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
  .product-base_wrap {
    width: calc(150px - 3.2rem);
    white-space: normal;
    word-break: break-word;
  }
`;

export const ViewProductsPolaris = (props) => {
  // Props;
  const { match } = props;
  const id = match?.params?.id;

  // Context
  const { currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "roles", []);
  const teamRole = get(currentUser, "teamUser.role", null);

  let currentParam = TEAM_ROLE_PARAMS.Seller;
  if (userRole.includes(USER_ROLE.Seller)) {
    if ([TEAM_ROLE.MarketplaceManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.MarketplaceManager;
    }
    if ([TEAM_ROLE.StoreManager].includes(teamRole)) {
      currentParam = TEAM_ROLE_PARAMS.StoreManager;
    }
  }

  // State
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState({
    limit: 20,
    offset: 0,
  });

  // Query
  const [getProudcts, { data, loading, error }] = useLazyQuery(
    GET_EXPORT_PRODUCTS
  );

  useEffect(() => {
    if (id) {
      getProudcts({
        variables: {
          input: {
            amzExportId: id,
            ...filter,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filter]);

  // Fetch data
  useEffect(() => {
    let newData =
      data &&
      data.getAmazonExportProducts &&
      data.getAmazonExportProducts.nodes &&
      data.getAmazonExportProducts.nodes.length > 0
        ? data.getAmazonExportProducts.nodes.map((product) => {
            // Id
            let id = product?.id;
            // Image
            let image =
              product && product.images && product.images.length > 0
                ? product.images.map((i) => i.file)
                : [];
            // Title
            let title = product?.title;
            // Bases
            let pBase = product?.productBases;
            if (pBase && pBase.length) {
              pBase = pBase.map((i) => i.title);
              pBase = pBase.join(", ");
            }
            return [
              <div className="id_wrap">{id}</div>,
              <div className="image_wrap">
                <ModalImageClaimPolaris
                  files={{ files: image }}
                  limit={1}
                  width={100}
                  height={100}
                />
              </div>,
              <div className="product_wrap">
                <span>{title}</span>
                <div>
                  <Badge children={product?.sku} />
                </div>
              </div>,
              <div className="product-base_wrap">
                <span>{pBase}</span>
              </div>,
            ];
          })
        : [];
    setRows(newData);
  }, [data]);

  // Handle action
  const handleFilter = useCallback((offset, limit) => {
    setFilter((prev) => ({ ...prev, offset, limit }));
  }, []);

  // Markup
  let { limit, offset } = filter;
  const total = get(data, "getAmazonExportProducts.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handleFilter,
    total,
  };

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      <Page
        title="Exported Product List"
        breadcrumbs={[
          {
            content: "Export for Amazon",
            url: `/${currentParam}/export-amazon`,
          },
        ]}
        fullWidth
      >
        <Card>
          {loadingMarkup}
          {loading ? (
            <SkeletonPagePolaris />
          ) : error ? (
            <div>Error: {handleError(error.toString())}</div>
          ) : data &&
            data.getAmazonExportProducts &&
            data.getAmazonExportProducts.nodes &&
            data.getAmazonExportProducts.nodes.length > 0 ? (
            <DataTable
              columnContentTypes={["text", "text", "text", "text"]}
              headings={["ID", "Image", "Title", "Product bases"]}
              rows={rows}
              verticalAlign="middle"
              hideScrollIndicator={true}
              footerContent={
                <PaginationPolaris aggregation={aggregation} showTotal />
              }
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
