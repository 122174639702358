import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { isEmail } from "../../helper";
import history from "../../history";
import { UPLOAD_FOLDER } from "../../variable";
import MediaSelectorButton from "../supplier/MediaSelectorButton";

const Container = styled.div`
  .button-group {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const EditProfileFormPolaris = ({ data, onSubmit, loading }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [address, setAddress] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  useEffect(() => {
    if (data) {
      let me = data;
      let image = me.avatar;
      let avatar = image ? [image] : [];
      let fileList = [
        {
          uid: image ? image.id : null,
          name: image ? image.name : null,
          url: image ? image.url : null,
        },
      ];

      setFirstName(_.get(me, "firstName", null));
      setLastName(_.get(me, "lastName", null));
      setEmail(_.get(me, "email", null));
      setPhone(_.get(me, "phone", null));
      setAddress(_.get(me, "address", null));
      setAvatar(avatar);
      setFileList(fileList);
    }
  }, [data]);

  const handleFirstName = useCallback((value, id) => {
    switch (id) {
      case "firstName":
        setFirstName(value);
        handleValue(value, "firstName");
        break;
      case "lastName":
        setLastName(value);
        handleValue(value, "lastName");
        break;
      case "email":
        setEmail(value);
        handleValue(value, "email");
        break;
      case "phone":
        setPhone(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValue = useCallback((value, key) => {
    let matche = key;
    let error = null;
    if (!value) {
      error = `${_.capitalize(_.startCase(matche))} is required.`;
    } else {
      if ("email" === matche) {
        if (!isEmail(value)) {
          error = "Email must be a valid email address.";
        }
      }
    }
    setErrors((prevState) => ({ ...prevState, [matche]: error }));
  }, []);

  const handleAvatarChange = useCallback((value) => {
    let avatar = value ? value : [];
    setAvatar(avatar);
  }, []);

  const handleSubmit = useCallback(() => {
    handleValue(firstName, "firstName");
    handleValue(lastName, "lastName");
    handleValue(email, "email");

    let avatarId = avatar && avatar.length > 0 ? _.head(avatar).id : "";
    const noErrors = Object.values(errors).every((el) => el === null);

    if (noErrors && firstName && lastName && email) {
      let input = {
        firstName,
        lastName,
        email,
        phone,
        address,
        avatar: avatarId,
      };
      if (onSubmit) {
        onSubmit(input);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, firstName, lastName, email, phone, address, avatar]);

  return (
    <Container>
      <Card sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              label="First name(*)"
              value={firstName}
              id="firstName"
              type="text"
              error={errors.firstName}
              onChange={handleFirstName}
            />
            <TextField
              label="Last name(*)"
              value={lastName}
              id="lastName"
              type="text"
              error={errors.lastName}
              onChange={handleFirstName}
            />
            <TextField
              label="Email(*)"
              value={email}
              id="email"
              type="text"
              error={errors.email}
              onChange={handleFirstName}
            />
            <TextField
              label="Phone"
              value={phone}
              id="phone"
              type="text"
              onChange={handleFirstName}
            />
            <TextField
              label="Address"
              value={address}
              id="address"
              type="text"
              onChange={handleFirstName}
            />
            <div>
              <label>Avatar</label>
              {avatar ? (
                <MediaSelectorButton
                  folder={UPLOAD_FOLDER.AVATARS}
                  accept={"image/*"}
                  listType={"picture-card"}
                  singleUpload={true}
                  multiple={false}
                  fileList={fileList.length > 0 ? fileList : null}
                  value={avatar}
                  onChange={handleAvatarChange}
                />
              ) : null}
            </div>
            <div className="button-group">
              <ButtonGroup>
                <Button onClick={() => history.push(`/`)}>Cancel</Button>
                <Button submit primary loading={loading}>
                  Update my profile
                </Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </Form>
      </Card>
    </Container>
  );
};
