import React, { Component } from "react";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import {
  Table,
  Skeleton,
  Button,
  Input,
  Select,
  Popover,
  Spin,
  Card,
} from "antd";
import { gql } from "apollo-boost";
import { Query } from "@apollo/react-components";
import moment from "moment";
import { USER_ROLE } from "../../variable";
import {
  handleError,
  setCookie,
  getCookie,
  convertStringToObject,
  convertObjectToParams,
} from "../../helper";
import _ from "lodash";
import styled from "styled-components";
import history from "../../history";

const Container = styled.div`
  .ant-btn-link {
    span {
      text-align: left;
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const FILTER_QUERY = gql`
  query filter($role: String!) {
    listUserByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

export const IMPORTS = gql`
  query imports($filter: ImportFilter) {
    imports(filter: $filter) {
      total
      nodes {
        id
        files {
          id
          url
          name
          mimeType
        }
        supplier {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;
const initFilter = convertStringToObject(history.location.search);
export default class Imports extends Component {
  state = {
    filter: {
      limit: +getCookie("perPageImports") || 20,
      offset: 0,
      supplierId: null,
      ...initFilter,
      search: initFilter && initFilter.search ? [initFilter.search] : null,
    },
    page: 1,
  };

  componentWillUpdate(__, nextState) {
    if (!_.isEqual(nextState.filter, this.state.filter)) {
      const params = convertObjectToParams({
        limit: nextState.filter.limit,
        offset: nextState.filter.offset,
        search: nextState.filter.search,
      });
      history.push(history.location.pathname + "?" + params);
    }
  }

  onChange = (type, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [type]: value,
      },
    });
  };

  render() {
    const { filter } = this.state;
    const { role } = this.props;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(
        //   `selectedRowKeys: ${selectedRowKeys}`,
        //   "selectedRows: ",
        //   selectedRows
        // );
      },
    };
    const columns = [
      {
        title: "File Name",
        dataIndex: "files",
        key: "files",
        width: 300,
        render: (files) => (
          <div>
            {files.map((file) => (
              <Button
                key={file.id}
                className="block"
                type="link"
                href={file.url}
                style={{ textAlign: "left" }}
              >
                {file.name}
              </Button>
            ))}
          </div>
        ),
      },
      ...([USER_ROLE.Admin].includes(role)
        ? [
            {
              title: "Author",
              dataIndex: "supplier",
              key: "supplier",
              width: 150,
              render: (supplier) =>
                `${supplier.firstName} ${supplier.lastName}`,
            },
          ]
        : []),
      {
        title: "Import Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 150,
        render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "Actions",
        key: "action",
        fixed: "right",
        align: "right",
        width: 100,
        render: (_, record) =>
          record.files.length === 1 && (
            <Button href={record.files[0].url}>
              <DownloadOutlined />
            </Button>
          ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container>
        <Card className="bg-white" bodyStyle={{ padding: 0 }}>
          <div className="flex p-4">
            <Input.Search
              defaultValue={filter.search}
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      search: null,
                    },
                  });
                }
              }}
              onSearch={(v) =>
                this.setState({ filter: { ...filter, search: [v] } })
              }
              addonBefore={
                [USER_ROLE.Admin].includes(role) && (
                  <Query
                    query={FILTER_QUERY}
                    variables={{ role: USER_ROLE.Supplier }}
                  >
                    {({ loading, data, err }) => {
                      if (loading) return <Spin size="small" />;
                      if (err)
                        return <div>Error: {handleError(err.toString())}</div>;
                      return (
                        <Popover
                          title="Filter"
                          placement="bottom"
                          trigger="click"
                          content={
                            <Select
                              onChange={(v) => {
                                this.setState({
                                  filter: { ...filter, supplierId: v },
                                });
                              }}
                              defaultValue={null}
                              className={"w-full"}
                            >
                              <Select.Option value={null}>All</Select.Option>
                              {data &&
                                data.listUserByRole.map((user, index) => (
                                  <Select.Option
                                    key={index}
                                    value={user.id}
                                  >{`${user.firstName} ${user.lastName}`}</Select.Option>
                                ))}
                            </Select>
                          }
                        >
                          <div className="cursor-pointer">
                            Filter <DownOutlined />
                          </div>
                        </Popover>
                      );
                    }}
                  </Query>
                )
              }
            />
          </div>
          <Query
            query={IMPORTS}
            variables={{
              filter: {
                limit: filter.limit,
                offset: filter.offset,
                search: filter.search,
                supplierId: filter.supplierId,
              },
            }}
            fetchPolicy="cache-and-network"
          >
            {({ data, error, loading }) => {
              if (loading)
                return (
                  <div className="p-4">
                    <Skeleton active />
                  </div>
                );

              if (error)
                return <div>Error:{handleError(error.toString())}</div>;
              const imports = data && data.imports.nodes;

              return (
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  scroll={{ x: tableWidth }}
                  rowKey={(record) => record.id}
                  dataSource={imports}
                  pagination={{
                    pageSize: filter.limit,
                    current: filter.offset / filter.limit + 1,
                    total: data && data.imports.total,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...this.state.filter,
                          limit: pageSize,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                    onShowSizeChange: (_, size) => {
                      this.setState({
                        page: 1,
                        filter: { ...filter, offset: 0, limit: size },
                      });
                      setCookie("perPageImports", size, 100);
                    },
                  }}
                />
              );
            }}
          </Query>
        </Card>
      </Container>
    );
  }
}
