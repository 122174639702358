import React from "react";
import { DataTable, Badge } from "@shopify/polaris";
import styled from "styled-components";
import _ from "lodash";

import { PaginationPolaris } from "../shared/PaginationPolaris";
import { CrawlerActionsPolaris } from "./CrawlerActionsPolaris";

const Container = styled.div`
  .title-wrap {
    width: 200px;
  }
  .url-wrap {
    width: 400px;
    white-space: break-spaces;
  }
`;

export const ProductCrawlerDetailPolaris = ({
  data,
  filter,
  crawlId,
  setFilter,
}) => {
  const rows = data
    ? data.crawlItems.nodes.map((c) => {
        let status = c.status;
        let color = null;
        if ("Done" === status) {
          color = "success";
        }
        if ("Error" === status) {
          color = "critical";
        }
        if ("Running" === status) {
          color = "attention";
        }

        return [
          <div className="title-wrap">{c.title}</div>,
          <div className="url-wrap">{c.url ? c.url : c.imageUrl}</div>,
          <Badge status={color}>{status}</Badge>,
          <>
            {"Error" === status ? (
              <CrawlerActionsPolaris
                crawlId={crawlId}
                itemId={c.id}
                filter={filter}
              />
            ) : (
              <div></div>
            )}
          </>,
        ];
      })
    : [];

  let { limit, offset } = filter;
  const totalPage = Math.ceil(_.get(data, "crawlItems.total", 0) / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setFilter,
  };

  return (
    <Container>
      <DataTable
        columnContentTypes={["text", "text", "text", "numeric"]}
        headings={["Title", "Url", "Status", "Actions"]}
        rows={rows}
        verticalAlign="center"
        hideScrollIndicator
        footerContent={<PaginationPolaris aggregation={aggregation} />}
      />
    </Container>
  );
};
