import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { Spin, Select } from "antd";
import { gql } from "apollo-boost";
import _ from "lodash";
import { AppContext } from "../../context";
import { ORDER_STATUS } from "../../variable";

const LIST_ORDERS = gql`
  query orders($filter: OrderDropdownFilter) {
    ordersDropdown(filter: $filter) {
      id
      product {
        title
      }
    }
  }
`;
export default class OrderSelect extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = _.debounce(this.onSearch, 500);
  }

  state = {
    orders: [],
    loading: false,
  };

  onSearch = (search, client, filter) => {
    this.setState({ loading: true });
    client
      .query({
        query: LIST_ORDERS,
        variables: {
          filter: {
            ...filter,
            search,
          },
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res) {
          this.setState({
            orders: (res.data && res.data.ordersDropdown) || [],
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <AppContext.Consumer>
        {({ store }) => (
          <Query
            query={LIST_ORDERS}
            variables={{
              filter: {
                storeId: store ? store.id : null,
                status: ORDER_STATUS.PendingDesign,
              },
            }}
            onCompleted={({ ordersDropdown }) => {
              this.setState({
                orders: ordersDropdown || [],
              });
            }}
          >
            {({ data, loading, error, client }) => {
              if (loading) return <Spin />;
              if (error) return error.toString();
              return (
                <Select
                  value={value}
                  showSearch
                  onSearch={(search) =>
                    this.handleSearch(search, client, {
                      storeId: store ? store.id : null,
                      status: ORDER_STATUS.PendingDesign,
                    })
                  }
                  filterOption={false}
                  onSelect={(v) => onChange(v)}
                  loading={this.state.loading}
                >
                  {this.state.orders.map((order) => (
                    <Select.Option key={order.id} value={order.id}>
                      {`${order.id} ${
                        (order.product && order.product.title) || ""
                      }`}
                    </Select.Option>
                  ))}
                </Select>
              );
            }}
          </Query>
        )}
      </AppContext.Consumer>
    );
  }
}
