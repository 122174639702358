import React, { Component } from "react";
import { Popconfirm, notification } from "antd";
import { gql } from "apollo-boost";
import { Mutation } from "@apollo/react-components";
import { LIST_SELLER_PRODUCTS_QUERY } from "../../pages/seller/Products";

const DELETE_COLLECTION = gql`
  mutation addCollectionsToProducts($productIds: [ID!]!, $add: Boolean!) {
    addCollectionsToProducts(productIds: $productIds, add: $add)
  }
`;
class DeleteCollectionsProducts extends Component {
  render() {
    const { selectedRows, onPopOverClose } = this.props;
    return (
      <Mutation
        mutation={DELETE_COLLECTION}
        onCompleted={() => {
          notification.success({ message: "Delete collections success!" });
        }}
        onError={(err) => {
          notification.error({ message: err.toString() });
        }}
      >
        {(addCollectionsToProducts, { client }) => (
          <Popconfirm
            title="Are you sure to delete collections?"
            cancelText="No"
            okText="Yes"
            placement="right"
            onCancel={onPopOverClose}
            onConfirm={() => {
              addCollectionsToProducts({
                variables: {
                  productIds: selectedRows,
                  add: false,
                },
              }).then((res) => {
                this.setState({ loading: false });
                try {
                  const { filter } = this.props;
                  const variables = {
                    filter: filter,
                  };
                  const cache = client.readQuery({
                    query: LIST_SELLER_PRODUCTS_QUERY,
                    variables,
                  });
                  client.writeQuery({
                    query: LIST_SELLER_PRODUCTS_QUERY,
                    variables,
                    data: {
                      ...cache,
                      products: {
                        ...cache.products,
                        nodes: [
                          ...cache.products.nodes.map(
                            (product) => (
                              selectedRows.find((s) => s === product.id)
                                ? (product.collections = null)
                                : null,
                              product
                            )
                          ),
                        ],
                      },
                    },
                  });
                } catch (e) {}
              });
              onPopOverClose();
            }}
          >
            <div style={{ cursor: "pointer" }}>Remove Collections</div>
          </Popconfirm>
        )}
      </Mutation>
    );
  }
}

export default DeleteCollectionsProducts;
