import React, {
    useState,
    useCallback,
    useEffect,
    useLayoutEffect,
} from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { USER_ROLE } from "../../../variable";
import { isEmpty } from "../../../helper";
import { FilterNoSearchPolaris } from "../../filters/FilterNoSearchPolaris";

const FILTER_QUERY = gql`
    query listUserByRole($role: String!) {
        listUserByRole(role: $role) {
            id
            firstName
            lastName
        }
        ownerTeam {
            id
            firstName
            lastName
        }
    }
`;

const Container = styled.div`
    .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
        .Polaris-Filters-ConnectedFilterControl__CenterContainer
        * {
        cursor: not-allowed;
    }
`;

export const OverviewFilterPolaris = ({ role, filter, onChange }) => {
    // State
    const [queryValue, setQueryValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [user, setUser] = useState({
        value: filter.selectId,
        label: null,
        search: null,
    });

    // Query
    const { data, loading, error } = useQuery(FILTER_QUERY, {
        variables: {
            role: role,
        },
    });

    // Get data
    useLayoutEffect(() => {
        let newData = [];
        if ([USER_ROLE.Seller].includes(role)) {
            newData = data?.ownerTeam || [];
        } else {
            newData = data?.listUserByRole || [];
        }

        let newOptions =
            newData?.length > 0
                ? newData.map((i) => ({
                      value: i.id,
                      label: `${i.firstName} ${i.lastName}`,
                  }))
                : [];
        setOptions(newOptions);
        if (filter.selectId) {
            const currentUser =
                newOptions?.length > 0
                    ? newOptions.find((i) => i.value === filter.selectId)
                    : null;
            if (currentUser) {
                setUser((prev) => ({
                    ...prev,
                    value: currentUser.value,
                    label: currentUser.label,
                }));
            }
        } else {
            setUser({
                value: null,
                label: null,
            });
        }
    }, [role, data, filter.selectId]);

    useEffect(() => {
        if (onChange) {
            onChange({
                selectId: user.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Handle actions
    const handleQueryValueChange = useCallback(
        (value) => setQueryValue(value),
        []
    );
    const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
    const handleUserRemove = useCallback(() => {
        setUser({ value: null, label: null, search: null });
    }, []);

    const handleFilterClearAll = useCallback(() => {
        handleQueryValueRemove();
        handleUserRemove();
    }, [handleQueryValueRemove, handleUserRemove]);

    const filters = [
        {
            key: [USER_ROLE.Seller].includes(role) ? "seller" : "supplier",
            label: [USER_ROLE.Seller].includes(role) ? "Seller" : "Supplier",
            filter: (
                <FilterNoSearchPolaris
                    data={options}
                    value={user}
                    loading={loading}
                    error={error}
                    onChange={({ value, label }) => {
                        setUser((prev) => ({ ...prev, value, label }));
                    }}
                    onChangeSearch={({ search }) =>
                        setUser((prevState) => ({ ...prevState, search }))
                    }
                />
            ),
        },
    ];
    const appliedFilters = [];
    if (!isEmpty(user.label)) {
        const key = [USER_ROLE.Seller].includes(role) ? "seller" : "supplier";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, user.label),
            onRemove: handleUserRemove,
        });
    }

    return (
        <Container>
            <Filters
                queryPlaceholder={`Filter item`}
                queryValue={queryValue}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleFilterClearAll}
                filters={filters}
                appliedFilters={appliedFilters}
            />
        </Container>
    );
    function disambiguateLabel(key, value) {
        switch (key) {
            case "seller":
                return `Seller: ${value}`;
            case "supplier":
                return `Supplier: ${value}`;
            default:
                return value;
        }
    }
};
