import { useMutation } from "@apollo/react-hooks";
import { notification } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { ProductForm } from "../../components/product/components/ProductForm";
import { useAppContext } from "../../context";
import { getParam, handleError } from "../../helper";
import history from "../../history";
import { ADD_PRODUCT_MUTATION } from "./AddProduct";

function CloneProduct() {
  const location = useLocation();

  // Props
  const values = React.useMemo(() => {
    let res = {
      props: {},
      value: {},
    };
    if (location?.state != null && typeof location.state === "string") {
      res = JSON.parse(location.state);
    }

    return res;
  }, [location?.state]);

  // Context
  const { currentUser } = useAppContext();
  const currentParam = getParam(currentUser);

  // Mutation
  const [createProduct, { loading }] = useMutation(ADD_PRODUCT_MUTATION, {
    onCompleted: (res) => {
      const product = res.createProduct;
      notification.success({
        message: "Product has been saved!",
      });
      history.push(`/${currentParam}/products/edit/${product.id}`);
    },
    onError: (err) => {
      notification.error({ message: handleError(err?.toString()) });
    },
  });

  // Actions
  const handleSubmit = React.useCallback((input) => {
    if (input != null) {
      createProduct({
        variables: {
          input,
        },
      });
    }
  }, []);

  return (
    <ProductForm
      {...values.props}
      value={values.value}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export { CloneProduct };
