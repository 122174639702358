import React from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

import { StoreInfoPolaris } from "./StoreInfoPolaris";

export const ColStoreInfoPolaris = ({ storeId }) => {
  return (
    <>
      <Tooltip content={<StoreInfoPolaris storeId={storeId} />} light>
        <Icon source={InfoMinor} />
      </Tooltip>
    </>
  );
};
