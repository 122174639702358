import React, { useState, useCallback, useEffect, useRef } from "react";
import { Filters } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { isEmpty } from "../../helper";
import { USER_ROLE } from "../../variable";
import { DepositsFilterByTargetPolaris } from "./DepositsFilterByTargetPolaris";
import { TransactionsFilterByStatusPolaris } from "./TransactionsFilterByStatusPolaris";

const options = [
  { value: "All", label: "All" },
  { value: "Approved", label: "Approved" },
  { value: "Pending", label: "Pending" },
  { value: "Cancelled", label: "Cancelled" },
];

const LOAD = gql`
  query load($role: String!) {
    teamUsersByRole(role: $role) {
      id
      firstName
      lastName
    }
  }
`;

export const DepositsFiltersPolaris = ({ filter, onChange }) => {
  const [inputValue, setInputValue] = useState(filter.search);
  const [target, setTarget] = useState({
    value: null,
    label: null,
    search: null,
  });
  const [status, setStatus] = useState(filter.status);
  const [newData, setNewData] = useState([]);
  const typingTimeoutRef = useRef(null);

  const { data, loading, error } = useQuery(LOAD, {
    variables: {
      role: USER_ROLE.Supplier,
    },
  });

  useEffect(() => {
    let result =
      data && data.teamUsersByRole && data.teamUsersByRole.length
        ? data.teamUsersByRole.map((tm) => {
            return {
              value: tm.id,
              label: `${tm.firstName} ${tm.lastName}`,
            };
          })
        : [];
    result = [{ value: "all", label: "All" }, ...result];
    setNewData(result);
  }, [data]);

  useEffect(() => {
    if (filter && filter.accountId) {
      const selectedValue = newData.find(
        (item) => item.value === filter.accountId
      );
      setTarget((prevState) => ({
        ...prevState,
        value: filter.accountId,
        label: selectedValue && selectedValue.label,
      }));
    }
  }, [filter, newData]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (onChange) {
      typingTimeoutRef.current = setTimeout(() => {
        onChange({
          search: inputValue ? inputValue.trim() : inputValue,
          status,
          accountId: target.value,
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, status, target]);

  const handleInputChange = useCallback((value) => setInputValue(value), []);
  const handleInputRemove = useCallback(() => setInputValue(null), []);
  const handleTargetRemove = useCallback(
    () => setTarget({ value: null, label: null }),
    []
  );
  const handleStatusRemove = useCallback(() => setStatus(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleInputRemove();
    handleTargetRemove();
    handleStatusRemove();
  }, [handleInputRemove, handleTargetRemove, handleStatusRemove]);

  const filters = [
    {
      key: "target",
      label: "Target",
      filter: (
        <DepositsFilterByTargetPolaris
          target={target}
          data={newData}
          loading={loading}
          error={error}
          onChangeSearch={({ search }) =>
            setTarget((prevState) => ({ ...prevState, search }))
          }
          onChange={({ value, label }) =>
            setTarget((prevState) => ({ ...prevState, value, label }))
          }
        />
      ),
    },
    {
      key: "status",
      label: "Status",
      filter: (
        <TransactionsFilterByStatusPolaris
          value={status}
          options={options}
          onChange={(value) => setStatus(value)}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(target.label)) {
    const key = "target";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, target.label),
      onRemove: handleTargetRemove,
    });
  }

  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <Filters
      queryValue={inputValue}
      queryPlaceholder="Search deposits"
      onQueryChange={handleInputChange}
      onQueryClear={handleInputRemove}
      onClearAll={handleFiltersClearAll}
      filters={filters}
      appliedFilters={appliedFilters}
    />
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "target":
        return `Target: ${value}`;
      case "status":
        return `Status: ${value}`;
      default:
        return value;
    }
  }
};
