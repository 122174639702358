import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  Form,
  FormLayout,
  Button,
  TextField,
  Icon,
  Toast,
} from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { handleError } from "../../helper";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($input: NewPassword!) {
    changePassword(input: $input)
  }
`;

const ChangeUserPasswordLinkPolaris = (props) => {
  const [active, setActive] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [iconView, setIconView] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [changePassword, { error, data }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
    {
      onError: () => {},
      onCompleted: () => {
        const id = setTimeout(() => {
          props.handleModalChange && props.handleModalChange();
        }, 2500);
        setTimeoutId(id);
      },
    }
  );

  useEffect(() => {
    if (props && props.isActive) {
      setActive(props.isActive);
    }
  }, [props]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (props && props.handleModalChange) {
      props.handleModalChange();
    }
  };

  const toggleActive = useCallback(() => {
    setActiveToast((activeToast) => !activeToast);
  }, []);

  const handleSubmit = useCallback(
    async (_event) => {
      if (
        "" !== currentPassword &&
        "" !== newPassword &&
        newPassword === confirmNewPassword
      ) {
        await changePassword({
          variables: {
            input: {
              currentPassword: currentPassword,
              password: newPassword,
            },
          },
        });
        toggleActive();
      }
      setOnSubmit((onSubmit) => !onSubmit);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPassword, newPassword, confirmNewPassword]
  );

  const handleTextFieldChange = useCallback((value, id) => {
    if ("currentPassword" === id) {
      setCurrentPassword(value);
    }
    if ("newPassword" === id) {
      setNewPassword(value);
    }
    if ("confirmPassword" === id) {
      setConfirmNewPassword(value);
    }
  }, []);

  const iconViewMarkup = (
    <div className="wrap-icon">
      <Button plain onClick={() => setIconView((iconView) => !iconView)}>
        <Icon source={!iconView ? HideMinor : ViewMinor} />
      </Button>
    </div>
  );

  const inputFileds = [
    {
      label: "Current password",
      value: currentPassword,
      id: "currentPassword",
      error:
        onSubmit && "" === currentPassword && "Current password is required!",
    },
    {
      label: "New password",
      value: newPassword,
      id: "newPassword",
      error: onSubmit && "" === newPassword && "Please choose a password.",
    },
    {
      label: "Confirm password",
      value: confirmNewPassword,
      id: "confirmPassword",
      error:
        onSubmit &&
        newPassword !== confirmNewPassword &&
        "Please confirm your password!",
    },
  ];

  const inputMarkup = inputFileds.map((i, index) => (
    <TextField
      label={i.label}
      value={i.value}
      onChange={handleTextFieldChange}
      type={!iconView ? "password" : "text"}
      suffix={iconViewMarkup}
      placeholder="********"
      id={i.id}
      key={index}
      error={i.error}
    />
  ));

  const toastMarkup = activeToast
    ? (error || (data && data.changePassword)) && (
        <Toast
          content={
            error
              ? handleError(error.toString())
              : data && data.changePassword && "Password has been changed!"
          }
          error={error}
          duration={2000}
          onDismiss={toggleActive}
        />
      )
    : null;

  return (
    <Modal open={active} onClose={handleClose} title="Change Password">
      <div className="change-password">
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            {inputMarkup}
            <div className="btn-group">
              <Button submit primary>
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
              {toastMarkup}
            </div>
          </FormLayout>
        </Form>
      </div>
    </Modal>
  );
};

export default ChangeUserPasswordLinkPolaris;
