import { useQuery } from "@apollo/react-hooks";
import { Avatar, Badge, DataTable } from "@shopify/polaris";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../context";
import { GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM } from "../../graphql/queries";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { ActionMemberPolaris } from "./ActionMemberPolaris";
import { DeleteMemberPolaris } from "./DeleteMemberPolaris";

const Container = styled.div`
  overflow: hidden;
  @media (min-width: 992px) {
    .Polaris-DataTable--condensed tr {
      th {
        border-top: 1px solid #dfe5ed;
      }
      th:last-child,
      td:last-child {
        right: 0;
        position: sticky;
        z-index: 2;
        background: #fff;
        :after {
          box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
          position: absolute;
          top: 0;
          bottom: -1px;
          width: 30px;
          transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
          content: "";
          pointer-events: none;
          left: 0;
          transform: translateX(-100%);
        }
      }
    }
  }
  .Polaris-DataTable__Navigation {
    display: none;
  }
  .Polaris-DataTable__Footer {
    overflow-x: scroll;
  }
`;

export const TableMemberPolaris = ({
  data,
  filter,
  setValuePage,
  isLeadSpecial,
  refetch,
}) => {
  const { data: dataT } = useQuery(
    GET_USER_HAS_PERMISSION_ACCEPT_ACCESS_IN_TEAM
  );
  const { currentUser } = useAppContext();

  const users = dataT?.getUserHasPermissionAcceptAccessInTeam || [];
  const ids = users.map(({ id }) => id);
  let hasActiveAcc = ids.includes(currentUser?.id);

  let newRows = [];
  if (data) {
    data.teamMembers.hits.map((c) => {
      let role = c && c.role;
      if ("Admin" === role) {
        role = "Seller";
      }
      let status = c.user && c.user.status;
      let statusColor = null;
      if (status) {
        status = "Active";
        statusColor = "success";
      } else {
        status = "Inactive";
        statusColor = "critical";
      }

      newRows.push([
        <Avatar
          source={c.user.avatar ? c.user.avatar.url : null}
          initials={
            c.user.firstName && c.user.firstName.length > 1
              ? c.user.firstName.substr(0, 1)
              : c.user.lastName.substr(0, 1)
          }
        />,
        <span>{c.user.firstName + " " + c.user.lastName}</span>,
        <span>{c.user.email}</span>,
        <Badge>{role}</Badge>,
        <Badge status={statusColor}>{status}</Badge>,
        <span>{c.user.phone}</span>,
        <span>{c.user.address}</span>,
        <div>
          {c.status && <DeleteMemberPolaris member={c} filter={filter} />}
          {!c.status && (
            <ActionMemberPolaris
              member={c}
              refetch={refetch}
              filter={filter}
              isLeadSpecial={isLeadSpecial}
              hasActiveAcc={hasActiveAcc}
            />
          )}
        </div>,
      ]);
      return newRows;
    });
  }

  let { limit, offset } = filter;
  const total = _.get(data, "teamMembers.count", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: setValuePage,
    total,
  };
  return (
    <Container>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "Avatar",
          "Name",
          "Email",
          "Role",
          "Status",
          "Phone",
          "Address",
          "Actions",
        ]}
        rows={newRows}
        verticalAlign={"middle"}
        footerContent={
          <PaginationPolaris aggregation={aggregation} showTotal />
        }
      />
    </Container>
  );
};
