import React from "react";
import { Card, Loading } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";

import { handleError } from "../../helper";

import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { HistoryETDTPolaris } from "./HistoryETDTPolaris";
import { ExportTrackingFilter } from "./ExportTrackingFilter";

const LIST_EXPORT_CONFIRM_SHIPMENT = gql`
  query getListExportConfirmShipment(
    $filter: GetListExportConfirmShipmentFilter!
  ) {
    getListExportConfirmShipment(filter: $filter) {
      total
      nodes {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        store {
          id
          title
        }
        status
        reason
        isExport
      }
    }
  }
`;

export const HistoryExportTrackingPolaris = () => {
  const { refetch: refetchParam } = useParams();
  // State
  const [filter, setFilter] = React.useState({
    limit: 20,
    paged: 1,
    storeId: null,
    search: "",
  });

  // Query
  const { data, loading, error, refetch } = useQuery(
    LIST_EXPORT_CONFIRM_SHIPMENT,
    {
      variables: {
        filter,
      },
      fetchPolicy: "no-cache",
    },
  );

  // Refetch data
  React.useEffect(() => {
    if (refetchParam) {
      refetch();
    }
  }, [refetchParam, refetch]);

  // Handle actions
  const handleFilterChange = React.useCallback(({ storeId, search }) => {
    setFilter((prev) => {
      if (!isEqual(prev.storeId, storeId) || !isEqual(prev.search, search)) {
        return {
          ...prev,
          storeId,
          search,
          paged: 1,
        };
      }
      return prev;
    });
  }, []);

  const handlePaginationChange = React.useCallback((offset, limit) => {
    let paged = offset / limit + 1;
    setFilter((prevState) => {
      if (
        !isEqual(limit, prevState.limit) ||
        !isEqual(paged, prevState.paged)
      ) {
        return {
          ...prevState,
          paged,
          limit,
        };
      }
      return prevState;
    });
  }, []);

  // Markup
  const loadingMarkup = loading && <Loading />;

  return (
    <React.Fragment>
      <Card sectioned>
        <ExportTrackingFilter filter onChange={handleFilterChange} />
      </Card>
      <Card>
        {loadingMarkup}
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div>Error: {handleError(error.toString())}</div>
        ) : data?.getListExportConfirmShipment?.nodes?.length > 0 ? (
          <HistoryETDTPolaris
            data={data}
            filter={filter}
            setFilter={handlePaginationChange}
            refetch={refetch}
          />
        ) : (
          <EmptyStatePolaris />
        )}
      </Card>
    </React.Fragment>
  );
};
