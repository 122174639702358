import React, { useContext, useMemo } from "react";
import { get } from "lodash";

import { LayoutSection, TITLE_PAGE, ELEMENTS_DATA } from "./LayoutSection";
import { WidgetCompProvider } from "../../widget/context";
import { AppContext } from "../../../context";
import { TEAM_ROLE } from "../../../variable";

const ELEMENTS = [
  {
    ...ELEMENTS_DATA.overviews,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.marketplaceManager,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.storeManagers,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.productBase,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.designers,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.trackTaskSubmission,
    default: 1,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader, TEAM_ROLE.Accountant],
  },
  {
    ...ELEMENTS_DATA.fulfillCounter,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.products,
    default: 2,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
    ],
  },
  {
    ...ELEMENTS_DATA.stores,
    default: 2,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.tasks,
    default: 2,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.DesignLeader, TEAM_ROLE.Designer],
  },
  {
    ...ELEMENTS_DATA.createdProduct,
    default: 1,
    roles: [TEAM_ROLE.Admin],
  },
  {
    ...ELEMENTS_DATA.groups,
    default: 1,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.Accountant,
    ],
  },
  {
    ...ELEMENTS_DATA.ideaTasks,
    default: 2,
    roles: [TEAM_ROLE.Admin, TEAM_ROLE.StoreManager, TEAM_ROLE.IdeaLeader],
  },
  {
    ...ELEMENTS_DATA.trackingStatus,
    default: 2,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.StoreManager,
    ],
  },
  {
    ...ELEMENTS_DATA.ideaTasksByDesigner,
    default: 2,
    roles: [
      TEAM_ROLE.Admin,
      TEAM_ROLE.IdeaLeader,
      TEAM_ROLE.StoreManager,
      TEAM_ROLE.DesignLeader,
    ],
  },
  {
    ...ELEMENTS_DATA.kpiOfTask,
    default: 2,
    roles: [
      TEAM_ROLE.MarketplaceManager,
      TEAM_ROLE.Admin,
      TEAM_ROLE.Accountant,
      TEAM_ROLE.DesignLeader,
    ],
  },
];

const StoreVal = "stores";
const TaskVal = "tasks";
const DesignersVal = "designers";
export function Overviews() {
  // Context
  const { currentUser } = useContext(AppContext);
  const userRole = get(currentUser, "teamUser.role", null);
  const isManagers = [
    TEAM_ROLE.MarketplaceManager,
    TEAM_ROLE.StoreManager,
  ].includes(userRole);
  const isDesign = [TEAM_ROLE.DesignLeader, TEAM_ROLE.Designer].includes(
    userRole,
  );

  const ELEMENTS_PROPS = useMemo(() => {
    return ELEMENTS.map((el) => {
      if (isManagers && el.value === StoreVal) {
        el.default = 1;
      } else if (isDesign) {
        if (el.value === TaskVal) {
          el.default = 1;
        } else if (el.value === DesignersVal) {
          el.default = 2;
        }
      }
      return el;
    }).filter((el) => el.roles.includes(userRole));
  }, [userRole, isDesign, isManagers]);

  return (
    <WidgetCompProvider title={TITLE_PAGE.Overviews} elements={ELEMENTS_PROPS}>
      <LayoutSection title={TITLE_PAGE.Overviews} />
    </WidgetCompProvider>
  );
}
