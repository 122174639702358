import { gql } from "apollo-boost";

import { FieldFragment } from "./field";
import { FileFragment } from "./file";

export const TaskFragment = gql`
    fragment TaskFragment on Task {
        id
        log
        doneDate
        hasTaskSubmission
        assignDate
        isAwaitingApproval
        note
        productVariantId
        rejectedTimeCount
        ideaTitle
        hasTemplateGenMockup
        priority
        designTeamID
        label
        artistTaskID
        artistFiles
        designTeam {
            id
            name
        }
        designOnlineTeamID
        designOnlineTeam {
            name
        }
        collections {
            id
            name
        }
        createBy {
            id
            firstName
            lastName
        }
        assignee {
            id
            firstName
            lastName
        }
        medias {
            ...FileFragment
        }
        product {
            id
            title
            sku
            hasPsdInNas
            mainImageId
            hasPBArtwork
            basesHasArtwork
            baseVariantsHasArtwork
            ideaTaskId
            productBases {
                id
            }
            images {
                id
                productBaseId
                file {
                    ...FileFragment
                }
            }
            psdFiles {
                ...FileFragment
            }
            psd
            isCannotConfigInPB
            cannotConfigInPBNote
			collections {
				id
				name
			}
        }
        order {
            status
            store {
                id
                platform
                title
            }
        }
        resources {
            ...FileFragment
        }
        requirement
        status
        kpiType
        numOfCharacter
        orderId
        order {
            id
            originId
            originalProductIsCampaign
            usingTaskBulkDesign
            fulfillmentServiceShippingOption
            product {
                id
                personalized
                mainImageId
                images {
                    id
                    productBaseId
                    file {
                        ...FileFragment
                    }
                }
                productBases {
                    id
                    title
                    attributes {
                        name
                        options
                    }
                }
            }
            productVariant {
                id
                sku
                hasPsdInNas
                productBaseVariant {
                    id
                    attributes {
                        name
                        slug
                        option
                    }
                    productBase {
                        id
                        title
                        slug
                        nasFileNameSyntax
                        designPositions {
                            id
                            name
                            productBaseVariants {
                                id
                            }
                        }
                    }
                }
            }
            originalProductID
            originalProduct {
                id
                title
                sku
                hasPsdInNas
            }
            originalProductVariant {
                id
                hasPsdInNas
                sku
                productBaseVariant {
                    id
                    productBase {
                        id
                        title
                        nasFileNameSyntax
                    }
                }
            }
        }
        personalized
        kpi
        ideaKpi
        defaultKpi
        deadline
        createdAt
        updatedAt
        description
        productId
        teamId
        taskType
        relatedProducts {
            id
            images {
                id
                file {
                    ...FileFragment
                }
            }
        }
        taskBaseGroups {
            id
            fields {
                id
                name
            }
            medias {
                ...FileFragment
            }
            collections {
                id
                name
            }
            tags {
                id
                name
            }
            relatedProducts {
                id
                title
            }
            baseConfigs {
                id
                taskBaseSubmissions {
                    id
                    customMockups {
                        ...FileFragment
                    }
                    taskBaseMockups {
                        id
                        outputMockup {
                            ...FileFragment
                        }
                    }
                }
            }
        }
    }
    ${FileFragment}
`;
export const TaskAggerationFragment = gql`
    fragment TaskAggerationFragment on TaskAggeration {
        all
        pending
        doing
        needReview
        rejected
        done
        cancel
        denied
        expired
        expiredSoon
        assigned
        unAssigned
        pendingDesign
        totalKpi
        totalKpiAssignTask
        fileGenerating
    }
`;

export const TaskByIdFragment = gql`
    fragment TaskByIdFragment on Task {
        id
        orderId
        order {
            originalProduct{
                psd
            }
            id
            productVariant {
                id
                productBaseVariant {
                    id
                    attributes {
                        name
                        slug
                        option
                    }
                    productBase {
                        id
                        title
                    }
                }
            }
            personalizedPreview
        }
        product {
            id
            title
            mainImageId
            images {
                id
                productBaseId
                file {
                    ...FileFragment
                }
            }
            designPositions {
                id
                designPosition {
                    id
                    name
                    displayName
                    sorting
                    description
                    productBaseId
                    artworkGuidelines {
                        description
                        file {
                            ...FileFragment
                        }
                    }
                }
            }
            productBases {
                id
                title
            }
            psd
            psdFiles {
                ...FileFragment
            }
        }
        resources {
            ...FileFragment
        }
        assignee {
            id
        }
        requirement
        personalized
        kpi
        kpiType
        deadline
        createdAt
        status
    }
    ${FileFragment}
`;

export const TaskCommentFragment = gql`
    fragment TaskCommentFragment on TaskComment {
        id
        createdAt
        updatedAt
        content
        taskId
        rejectReason
        authorId
        author {
            id
            firstName
            lastName
        }
        files {
            ...FileFragment
        }
    }
    ${FileFragment}
`;

export const CountTaskByUserFragment = gql`
    fragment CountTaskByUserFragment on CountTaskByUser {
        id
        firstName
        lastName
        all
        pending
        doing
        needReview
        rejected
        done
        cancel
        denied
        expired
        expiredSoon
        kpi
    }
`;

export const CountTaskRejectForUserFragment = gql`
    fragment CountTaskRejectForUserFragment on CountTaskRejectForUser {
        firstName
        lastName
        reject
        doneTaskRejectedTimes
        id
        kpi
        kpiByRejectTimes
    }
`;

export const TaskBaseMockupFragment = gql`
    fragment TaskBaseMockupFragment on TaskBaseMockup {
        id
        taskBaseConfigId
        taskBaseSubmissionId
        originTemplateId
        status
        outputMockupId
        isMainMockup
        outputMockup {
            ...FileFragment
        }
        mockupLayerConfigs {
            templateId
            layers {
                name
                imageUrl
                x
                y
                width
                height
                opacity
                rotation
            }
        }
    }
    ${FileFragment}
`;

export const IdeaTaskFragment = gql`
    fragment IdeaTaskFragment on Task {
        id
        ideaTitle
        ideaDesc
        ideaKpi
        status
        declineReason
        priority
        kpi
        defaultKpi
        designTeamID
        designTeam {
            id
            name
        }
        createBy {
            id
            firstName
            lastName
        }
        assigneeId
        assignee {
            id
            firstName
            lastName
        }
        collections {
            id
            name
        }
        tags {
            id
            name
        }
        niches {
            id
            name
        }
        medias {
            ...FileFragment
        }
        relatedProducts {
            id
            title
            sku
            hasPsdInNas
            productBases {
                id
                title
            }
            designPositions {
                id
                file {
                    id
                    name
                    mimeType
                    url
                    thumbnailUrl
                }
                designPosition {
                    id
                    name
                    productBaseId
                }
            }
            images {
                id
                file {
                    id
                    thumbnailUrl
                    url
                }
            }
        }
        taskBaseGroups {
            id
            # taskId
            title
            description
            status
            isPersonalized
            ideaDescription
            position
            productId
            product {
                id
                psd
                psdFiles {
                    ...FileFragment
                }
            }
            isPriority
            shortTitle
            amzTitle
            psdFiles {
                ...FileFragment
            }
            psd
            fields {
                ...FieldFragment
            }
            baseConfigs {
                id
                taskBaseGroupId
                productBaseId
                position
                activeProductBaseVariantIds
                activeAttributes {
                    slug
                    options
                }
                taskBaseSubmissions {
                    id
                    status
                    rejectReason
                    taskBaseConfigId
                    taskBaseMockups {
                        ...TaskBaseMockupFragment
                    }
                    taskBaseSubmissionFiles {
                        id
                        designPositionId
                        designPosition {
                            id
                            name
                            description
                        }
                        taskBaseSubmissionId
                        generateMockupFileId
                        file {
                            id
                            thumbnailUrl
                            url
                            mimeType
                        }
                        generateMockupFile {
                            id
                            thumbnailUrl
                            url
                            mimeType
                        }
                    }
                    isCustomMockup
                    customMockups {
                        ...FileFragment
                    }
                    mainCustomMockupId
                    mainCustomMockup {
                        ...FileFragment
                    }
                }
            }
            collections {
                id
                name
            }
            tags {
                id
                name
            }
            niches {
                id
                name
            }
            medias {
                ...FileFragment
            }
            relatedProducts {
                id
                title
                sku
                hasPsdInNas
                productBases {
                    id
                    title
                }
                designPositions {
                    id
                    file {
                        id
                        name
                        mimeType
                        url
                        thumbnailUrl
                    }
                    designPosition {
                        productBaseId
                        id
                        name
                    }
                }
                images {
                    id
                    file {
                        id
                        thumbnailUrl
                        url
                    }
                }
            }
        }
    }
    ${FileFragment}
    ${FieldFragment}
    ${TaskBaseMockupFragment}
`;

export const TaskBaseSubmissionFragment = gql`
    fragment TaskBaseSubmissionFragment on TaskBaseSubmission {
        id
        status
        rejectReason
        taskBaseConfigId
        taskBaseSubmissionFiles {
            id
            designPosition {
                id
                name
            }
        }
        taskBaseMockups {
            ...TaskBaseMockupFragment
        }
        isCustomMockup
        customMockups {
            id
            name
            url
            thumbnailUrl
        }
    }
    ${TaskBaseMockupFragment}
`;

export const reportTasksResultFragment = gql`
    fragment reportTasksResultFragment on ReportTasksResult {
        total
        nodes {
            id
            status
            orderId
            kpi
            defaultKpi
        }
    }
`