import React, { useState, useCallback } from "react";
import { Button, ButtonGroup } from "@shopify/polaris";

import { UploadProductMockupPolaris } from "./actions/UploadProductMockupPolaris";
import { ViewMockupPolaris } from "./actions/ViewMockupPolaris";

export const ProductsRMActionsPolaris = ({
  item,
  toggleActive,
  onNotificationChange,
  refetch,
}) => {
  // State
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  // Handle actions
  const toggleShowModal = useCallback((action) => {
    setCurrentAction(action);
    setShowModal((prev) => !prev);
  }, []);

  return (
    <React.Fragment>
      <ButtonGroup>
        <Button
          children="View"
          size="slim"
          plain
          onClick={() => toggleShowModal("view")}
        />
        <Button
          children="Update Mockup"
          plain
          size="slim"
          onClick={() => toggleShowModal("updateMockup")}
        />
      </ButtonGroup>
      <UploadProductMockupPolaris
        open={showModal && "updateMockup" === currentAction}
        toggleShowModal={toggleShowModal}
        value={item}
        refetch={refetch}
        toggleActive={toggleActive}
        onNotificationChange={onNotificationChange}
      />
      <ViewMockupPolaris
        open={showModal && "view" === currentAction}
        toggleShowModal={toggleShowModal}
        value={item}
        refetch={refetch}
      />
    </React.Fragment>
  );
};
